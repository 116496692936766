import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Divider, Row } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { InformationalBlock, PrimaryButton } from '@features/ui-kit';
import { IAnnualApplicationFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { isEmpty } from '@utils/utils';

import styles from './AnnualApplicationTable.module.scss';
import { AnnualApplicationTableContainer } from './annualApplicationTableContainer';
import { AnnualApplicationTableFilters } from './annualApplicationTableFilters';
import { useAnnualApplicationTableFilters } from './useAnnualApplicationTableFilters';
import { useGetCurrentUser } from '@features/authentication';
import { saveUrl } from '@utils/returnUrl';
import { HomeOutlined } from '@ant-design/icons';

export const AnnualApplicationTable = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const saveAction = saveUrl();

  const { setHeaderTitle } = useHeaderTitle();
  const { setAnnualContractFilters } = useAnnualApplicationTableFilters();

  const onCreateButtonClick = () => {
    navigate(generatePath(Routes.AnnualApplicationCard));
    saveAction();
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);

    setAnnualContractFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
            regionId: user?.owner?.region?.id || null,
            districtId: user?.owner?.district?.id || null,
          } as unknown as IAnnualApplicationFilters)
        : (newFilters as unknown as IAnnualApplicationFilters),
    );
  }, []);

  const isCanCreateAnnualApplication = useHasUserPermission(
    ACCESS.ANNUAL_CARD_CREATE,
  );

  useEffect(() => {
    setHeaderTitle(t('annualApplication.table.homeTitle'));
  }, []);

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('annualApplication.table.homeTitle'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[0, 16]} justify="space-between" align="middle">
        <Col span={isCanCreateAnnualApplication ? 20 : 24}>
          <AnnualApplicationTableFilters />
        </Col>
        {isCanCreateAnnualApplication && (
          <Col>
            <PrimaryButton
              className={styles.createButton}
              onClick={onCreateButtonClick}
            >
              {t('annualApplication.buttons.createButton')}
            </PrimaryButton>
          </Col>
        )}
      </Row>
      <Divider className={styles.divider} />
      <InformationalBlock title={t('annualApplication.table.sectionTitle')}>
        <AnnualApplicationTableContainer />
      </InformationalBlock>
    </>
  );
};
