import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetAllBasinQuery,
  useGetSourcesQuery,
  useUpdateIrrigationSystemMutation,
} from '@store/gisproApi';
import { Col, Divider, Form, Row, Typography } from 'antd';
import { FC } from 'react';

interface ISourceModalProps {
  setShowModal: (value: boolean) => void;
  systemData: any;
}

export const SourceModal: FC<ISourceModalProps> = ({
  setShowModal,
  systemData,
}) => {
  const [form] = Form.useForm();
  const basinId = Form.useWatch('basinId', form);
  const [updateIrrigationSystem] = useUpdateIrrigationSystemMutation();

  const { data: bassins = [], isLoading: bassinsIsLoading } =
    useGetAllBasinQuery(
      systemData?.regionId ? { regionId: systemData?.regionId } : skipToken,
    );

  const { data: sources = [], isLoading: sourcesIsLoading } =
    useGetSourcesQuery(
      basinId
        ? { regionId: systemData?.regionId, basinId: basinId }
        : skipToken,
    );

  const onBassinChange = () => {
    form.setFieldValue('baseId', null);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: any) => {
    systemData?.systemId &&
      updateIrrigationSystem({ ...values, systemId: systemData?.systemId });

    setShowModal(false);
  };

  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const initialValues = {
    basinId: systemData?.bassinId,
    baseId: systemData?.baseId,
  };
  return (
    <CustomModal
      centered
      open
      onCancel={onCancel}
      width={830}
      title={'Источник'}
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Бассейн"
              name="basinId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                onBlur={onBassinChange}
                loading={bassinsIsLoading}
                options={bassins}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Источник" name="baseId">
              <TooltipedSelect
                loading={sourcesIsLoading}
                options={sources}
                disabled={!basinId}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
