import { createSelector } from '@reduxjs/toolkit';

export const businessPlanStateSelector = (state: { businessPlan: any }): any =>
  state.businessPlan;

export const getBusinessPlanStateSelector = createSelector(
  businessPlanStateSelector,
  (businessPlanState) => {
    return businessPlanState.businessPlanFilters;
  },
);

export const getBusinessPlanFiltersSelector = createSelector(
  businessPlanStateSelector,
  (businessPlanState) => {
    return businessPlanState.businessPlanFilters;
  },
);
