import { createSelector } from '@reduxjs/toolkit';
import { ReferenceBookState } from '@store/slices';

type WithReferenceBookState = {
  referenceBook: ReferenceBookState;
};

export const referenceBookStateSelector = (
  state: WithReferenceBookState,
): ReferenceBookState => state.referenceBook;

export const getReferenceBookIdSelector = createSelector(
  referenceBookStateSelector,
  (decadeAppState) => {
    return decadeAppState.referenceBookId;
  },
);
