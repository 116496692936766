import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreateWaerRateModalShownSelector, getWateringRateData } from '@store/selectors';
import { agroClimaticZoneActions } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (isShow: boolean) => void;
  setValues: (values: any) => void;
  initialValues: any;
};

export const useCreateWateringRateModal = (): ReturnType => {
  const dispatch = useDispatch();

  const initialValues = useSelector(getWateringRateData);
  const isModalShown = useSelector(getIsCreateWaerRateModalShownSelector);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(agroClimaticZoneActions.setCreateWaerRateModalShownAction(value));
  }, []);

  const setValues = useCallback((values: any) => {
    dispatch(agroClimaticZoneActions.setWateringRateDataAction(values));
  }, []);

  return {
    isModalShown,
    setShowModal,
    setValues,
    initialValues,
  };
};
