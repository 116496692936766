import { JsonRecord } from '@models/jsonRecord';

export function customAssign<R>(act: JsonRecord, input: JsonRecord): R {
  const res = {
    ...act,
  };
  Object.keys(input).forEach((key) => {
    res[key] = input[key] ?? res[key];
  });
  return res as R;
}
