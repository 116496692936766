import { createSelector } from '@reduxjs/toolkit';
import { AgroClimaticZoneState } from '@store/slices';

type WithAgroClimaticZoneState = {
  agroClimaticZone: AgroClimaticZoneState;
};

export const agroClimaticZoneStateSelector = (
  state: WithAgroClimaticZoneState,
): AgroClimaticZoneState => state.agroClimaticZone;

export const getSelectedPlantingIdSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.selectedPlantingId;
  },
);

export const getIsCreateWaerRateModalShownSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.isCreateWaerRateModalShown;
  },
);
export const getWateringRateData = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.wateringRateData;
  },
);

export const getIsCreateAgroClimaticZoneModalShownSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.isCreateAgroClimaticZoneModalShown;
  },
);

export const getIsCreateHydroModuleDistrictModalShownSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.isCreateHydroModuleDistrictModalShown;
  },
);

export const getHydroModuleFiltersSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.hydroModuleFilters;
  },
);

export const getAgroClimaticZoneFiltersSelector = createSelector(
  agroClimaticZoneStateSelector,
  (agroClimaticZoneState) => {
    return agroClimaticZoneState.agroClimaticZoneFilters;
  },
);
