import React from 'react';

import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { type JsonRecord } from '@models/jsonRecord';
import { type TableColumn } from '@models/table/interfaces/tableColumn';
import { type TableCustomActionProps, TableCustomMethodProps } from '@models/table/interfaces/tableCustomActionProps';

import { RendererHeaderCellFactory } from './renderer/rendererHeaderCellFactory';

export interface HeaderEditableCellProps<RecordType>
  extends React.HTMLAttributes<HTMLElement> {
  customColumn?: TableColumn;
  record: RecordType;
  actionProps: TableCustomActionProps<RecordType>;
  methodsProps: TableCustomMethodProps<RecordType>[];
}

export function HeaderCell<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps,
  methodsProps,
  ...restProps
}: HeaderEditableCellProps<RecordType>): React.ReactElement {
  if (!customColumn) return <td {...restProps} />;
  const renderProps = {
    customColumn,
    record,
    methodsProps,
    actionProps,
  };

  const renderedElement =
    customColumn.ytype === CustomTableColumnHeaderType.RenderCustom
      ? customColumn.renderCustom!(renderProps)
      : RendererHeaderCellFactory.createRenderer(renderProps);

  return <td {...restProps}>{renderedElement}</td>;
}
