import { api } from '../basicApi';
import { IFacility } from '@models/facility/interfaces/facility';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    facilitiesControllerFindAll: build.query<
      FacilitiesControllerFindAllApiResponse,
      FacilitiesControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/facility/all` }),
      providesTags: ['Facility'],
    }),
    facilitiesControllerFindByUser: build.query<
      FacilitiesControllerFindByUserApiResponse,
      FacilitiesControllerFindByUserApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/facility/${queryArg.userName}` }),
      providesTags: ['Facility'],
    }),
    facilitiesControllerFindByFacilitiesId: build.query<
      FacilitiesControllerFindByFacilitiesIdApiResponse,
      FacilitiesControllerFindByFacilitiesIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/facility/id/${queryArg.id}` }),
      providesTags: ['Facility'],
    }),
    facilitiesControllerFindByOrganizationId: build.query<
      FacilitiesControllerFindByOrganizationIdApiResponse,
      FacilitiesControllerFindByOrganizationIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/facility/by-user/${queryArg.id}` }),
      providesTags: ['Facility'],
    }),
    facilitiesControllerUpdate: build.mutation<
      FacilitiesControllerUpdateApiResponse,
      FacilitiesControllerUpdateApiArg
    >({
      invalidatesTags: ['Facility'],
      query: (queryArg) => ({
        url: `/api/v1/facility/update`,
        method: 'POST',
        body: queryArg.updateFacilitiesDto,
      }),
    }),
    facilitiesControllerDelete: build.mutation<
      FacilitiesControllerDeleteApiResponse,
      FacilitiesControllerDeleteApiArg
    >({
      invalidatesTags: ['Facility'],
      query: (queryArg) => ({
        url: `/api/v1/facility/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    facilitiesControllerFindByFilter: build.query<
      FacilitiesControllerFindByFilterApiResponse,
      FacilitiesControllerFindByFilterApiArg
    >({
      providesTags: ['Facility'],
      query: (queryArg) => ({
        url: `/api/v1/facility/find-by-filter`,
        params: {
          region: queryArg.region,
          district: queryArg.district,
          facility: queryArg.facility,
          organization: queryArg.organization,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FacilitiesControllerFindAllApiResponse = unknown;
export type FacilitiesControllerFindAllApiArg = void;
export type FacilitiesControllerFindByUserApiResponse = unknown;
export type FacilitiesControllerFindByUserApiArg = {
  userName: string;
};
export type FacilitiesControllerFindByFacilitiesIdApiResponse = IFacility;
export type FacilitiesControllerFindByFacilitiesIdApiArg = {
  id: number;
};
export type FacilitiesControllerFindByOrganizationIdApiResponse = unknown;
export type FacilitiesControllerFindByOrganizationIdApiArg = {
  id: number;
};
export type FacilitiesControllerUpdateApiResponse = unknown;
export type FacilitiesControllerUpdateApiArg = {
  updateFacilitiesDto: UpdateFacilitiesDto;
};
export type FacilitiesControllerDeleteApiResponse = unknown;
export type FacilitiesControllerDeleteApiArg = {
  id: number;
};
export type FacilitiesControllerFindByFilterApiResponse = unknown;
export type FacilitiesControllerFindByFilterApiArg = {
  region: number;
  district: number;
  facility: number;
  organization: number;
};
export type CreateRenterDto = {
  inn: string;
  address: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  passportNumber: string;
  gender: string;
  attachmentList: string[];
};
export type UpdateFacilitiesDto = {
  facilitiesId: number;
  systemId: number;
  channelId: number;
  okmotId: number;
  name: string;
  additionalAddress: string;
  area: number;
  outletId: number;
  agroClimatiqueZoneId: number;
  kpd: number;
  isRenter: boolean;
  renter: CreateRenterDto;
};
export const {
  useFacilitiesControllerFindAllQuery,
  useFacilitiesControllerFindByUserQuery,
  useFacilitiesControllerFindByFacilitiesIdQuery,
  useFacilitiesControllerFindByOrganizationIdQuery,
  useFacilitiesControllerUpdateMutation,
  useFacilitiesControllerDeleteMutation,
  useFacilitiesControllerFindByFilterQuery,
} = injectedRtkApi;
