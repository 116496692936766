import React from 'react';

import { Col, Row } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { CustomTableColumnType } from '@enums/table';
import { CustomCarousel, PrimaryButton } from '@features/ui-kit';
import { ImplementationTermTranslateStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import dayjs from 'dayjs';

import styles from './implementation.module.scss';

export const useImplementationColumns = () => {
  const onHandleWatch = (document: any) => {
    downloadAttachment(document);
  };

  const documents = (attachments: any) => {
    return attachments
      ?.map((item: any) => {
        const res: any = [];
        if (item.type.split(':')[0] !== DocumentTypes.Photo) {
          res.push(item);
        }
        return res;
      })
      .flat();
  };

  const attachmentsImage = (attachments: any) => {
    return attachments
      ?.map((item: any) => {
        const res: any = [];
        if (item.type.split(':')[0] === DocumentTypes.Photo) {
          res.push({ path: item.path, id: item.id });
        }
        return res;
      })
      .flat();
  };
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'index',
      key: 'index',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Мероприятие',
      align: 'center',
      dataIndex: 'event',
      key: 'event',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Сроки',
      align: 'center',
      dataIndex: 'date',
      key: 'date',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <Row gutter={16} justify="center" align="middle">
          <Col>{dayjs(record.start).format('YYYY-MM-DD')}</Col>
          <Col>{dayjs(record.end).format('YYYY-MM-DD')}</Col>
        </Row>
      ),
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <Row justify="center" align="middle">
          <Col className={`${styles[record.status]}`}>
            {
              ImplementationTermTranslateStatusEnum[
                record.status as keyof typeof ImplementationTermTranslateStatusEnum
              ]
            }
          </Col>
        </Row>
      ),
    },
    {
      title: 'Документы',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return documents(record?.attachments)?.map((item: any) => (
          <PrimaryButton onClick={() => onHandleWatch(item)} key={item.name}>
            {item.extension}
          </PrimaryButton>
        ));
      },
    },
    {
      title: 'Просмотр фотографий',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <Row className={styles.customCarousel}>
          <Col span={24}>
            <CustomCarousel
              disabled
              detailedModal
              slidesToShow={3}
              items={attachmentsImage(record?.attachments)}
            />
          </Col>
        </Row>
      ),
    },
  ];
  return { columns };
};
