import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';

import { AnnualApplicationAgreement } from '@features/annualApplication/annualApplicationAgreement';
import { AnnualApplicationAgreementModal } from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementModal/AnnualApplicationAgreementModal';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export const AnnualApplicationAgreementLayout: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle(t('annualApplication.table.homeTitle'));
  }, []);

  return (
    <>
      <Layout>
        <Row gutter={16}>
          <Col>
            <DefaultButton onClick={() => navigate(-1)} className="backBtn">
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: t('annualApplication.table.homeTitle'),
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <AnnualApplicationAgreement />
      </Layout>
      <AnnualApplicationAgreementModal />
    </>
  );
};
