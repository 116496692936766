import { Col, Form, Row, Space } from 'antd';

import {
  actualSowingName,
  cropYieldName,
} from '@constants/associationIndicatorReports';
import { EntityTypesEnum } from '@enums/entityTypes';
import { SectionTitle, TooltipedInputV2 } from '@features/ui-kit-v2';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

import styles from '../EnteringReportingIndicatorsForm.module.scss';

interface IInputData {
  label: string;
  name: string;
}

const unusedLands: IInputData[] = [
  {
    label: 'Неисправность оросительной сети',
    name: 'irrigationNetworkMalfunction',
  },
  {
    label: 'Неисправность насосной станции',
    name: 'pumpingStationMalfunction',
  },
  {
    label: 'Засоление, заболачивание земель',
    name: 'salinizationWaterloggingLands',
  },
  {
    label: 'Пары',
    name: 'steams',
  },
  {
    label: 'Неполито',
    name: 'unwatered',
  },
  {
    label: 'Неполито зерновые',
    name: 'unwateredGrains',
  },
  {
    label: 'Недостаток воды с источником орошения',
    name: 'lackOfWaterFromSources',
  },
  {
    label: 'Из-за достаточной влаги в почве',
    name: 'sufficientMoistureSoil',
  },
];

export const AreasAndLands: React.FC = () => {
  const { data: actualSowings = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.ActualSowing,
  });

  const { data: cropYields = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.CropYield,
  });

  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      <SectionTitle background="cyan" title="Земли/Участки" />
      <Row gutter={15}>
        <Col span={6}>
          <Form.Item label="Использованные земли АВП, ГА *" name="usedAvpLands">
            <TooltipedInputV2 suffix="Га" />
          </Form.Item>
        </Col>
        <Col span={18}>
          <p className={styles.label}>Неиспользованные земли АВП, ГА *</p>
          <Row gutter={15} className={styles.borderedBlock}>
            {unusedLands.map(({ label, name }) => (
              <Col span={8} key={name}>
                <Form.Item
                  label={label}
                  name={name}
                  rules={[{ required: true }]}
                >
                  <TooltipedInputV2 type="number" />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <section>
        <p className={styles.label}>Фактический сев сельхозкультур, ГА *</p>
        <Row className={styles.borderedBlock} gutter={15}>
          {actualSowings.map(({ label, id }) => (
            <Col span={4} key={id}>
              <Form.Item
                label={label}
                name={actualSowingName[label]}
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </section>
      <section>
        <p className={styles.label}>Урожайность сельхозкультур в ц/ГА *</p>
        <Row className={styles.borderedBlock} gutter={15}>
          {cropYields.map(({ label, id }) => (
            <Col span={4} key={id}>
              <Form.Item
                label={label}
                name={cropYieldName[label]}
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </section>
    </Space>
  );
};
