import { Routes } from '@enums/routes';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';
import { useDispatch, useSelector } from 'react-redux';

import { CustomCard, TableCustom } from '@features/ui-kit';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import {
  getPlantingDataSelector,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';

import { usePlannedStructureTableColumns } from './usePlannedStructureTableColumns';
import { useNavigate, useParams } from "react-router-dom";
import {useGetAnnualContractByIdQuery} from "@store/gisproApi";
import {skipToken} from "@reduxjs/toolkit/query";
import { AnnualPlantingPlanTypeEnum, IPlantingDataDto } from '@models/annualApplication/interfaces/annualApplication';
import { useMemo } from 'react';
import { annualApplicationActions } from '@store/slices';
import {
  usePlannedStructureModal
} from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/usePlannedStructureModal';

export const PlannedStructureTable: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: any }>();
  const { setShowModal } = usePlannedStructureModal();
  const navigate = useNavigate();

  const plantingData = useSelector(getPlantingDataSelector);
  const editing = !!id;

  const { data: annulContract } = useGetAnnualContractByIdQuery(
    id ? { id } : skipToken,
  );

  const columns = usePlannedStructureTableColumns(editing)
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);
  if (!editing && !waterUserType) {
    navigate(`${Routes.AnnualApplicationCard}?section=${AnnualApplicationQueryTypes.totalInformation}`);
  }
  const dataSource = useMemo(() => {
    if (editing) {
      return (annulContract?.rawAnnualPlantingPlans || []).filter(item => item.type === AnnualPlantingPlanTypeEnum.PLANNED);
    }
    return plantingData;
  }, [annulContract, plantingData]);

  const deleteItem = (item: IPlantingDataDto) => {
    dispatch(annualApplicationActions.removePlantingData(item));
  }

  const onHandleEdit = (item: IPlantingDataDto) => {
    dispatch(annualApplicationActions.setSelectedPlantingData(item));
    setShowModal(true);
  }

  return (
    <CustomCard title="Список годовых заявок">
      <TableCustom
        bordered
        size="middle"
        columns={
          (columns as any)[waterUserType] || columns[WaterUserEnum.LEGAL]
        }
        dataSource={dataSource}
        pagination={false}
        actionProps={{
          deleteItem,
          onHandleEdit,
        }}
      />
    </CustomCard>
  );
};
