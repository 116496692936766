import { Col, Form, Row, Space } from 'antd';

import { associationRank } from '@constants/associationStats';
import {
  SectionTitle,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';

import styles from '../EnteringReportingIndicatorsForm.module.scss';

export const FinancialIndicators: React.FC = () => {
  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      <SectionTitle title="Финансовые показатели" background="cyan" />
      <Row gutter={15}>
        <Col span={6}>
          <Form.Item
            label="Долг на начало года, сом"
            name="debtBeginningYear"
            rules={[{ required: true }]}
          >
            <TooltipedInputV2 type="number" suffix="сом" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Подано хоздоговор, Водоподача *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="economicContract"
              >
                <TooltipedInputV2 placeholder="тыс.м3" suffix="тыс.м3" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="economicContractSom"
              >
                <TooltipedInputV2 placeholder="тыс.сом" suffix="тыс.сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Погашение с начала года, сом *</p>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item
                required
                name="repaymentPaymentInKind"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Натуроплатой" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="repaymentCash"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Наличными" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="repaymentLaborServices"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Трудоуслугами" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Остаток задолженности, сом"
            name="balanceOfDebt"
            rules={[{ required: true }]}
          >
            <TooltipedInputV2 type="number" suffix="сом" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Получено от РУВХ тыс.сом"
            name="retrievedFromRuvh"
            rules={[{ required: true }]}
          >
            <TooltipedInputV2 type="number" suffix="тыс.сом" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Оплачено в РУВХ тыс.сом"
            name="paidInRuvh"
            rules={[{ required: true }]}
          >
            <TooltipedInputV2 type="number" suffix="тыс.сом" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Заключено договоров *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="contractsCubeMeter"
              >
                <TooltipedInputV2 placeholder="м3" suffix="м3" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="contractsSom"
              >
                <TooltipedInputV2 placeholder="сом" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Подписано актов *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="actsSignedCubeMeter"
              >
                <TooltipedInputV2 placeholder="м3" suffix="м3" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="actsSignedSom"
              >
                <TooltipedInputV2 placeholder="сом" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Общий бюджет, сом *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="totalBudgetPlan"
              >
                <TooltipedInputV2 placeholder="План" suffix="сом" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="totalBudgetFact"
              >
                <TooltipedInputV2 placeholder="Факт" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>в том числе ЭиТО, сом *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item required rules={[{ required: true }]} name="eitoPlan">
                <TooltipedInputV2 placeholder="План" suffix="сом" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item required rules={[{ required: true }]} name="eitoFact">
                <TooltipedInputV2 placeholder="Факт" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>в том числе Ремонт, сом *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="repairPlan"
              >
                <TooltipedInputV2 placeholder="План" suffix="сом" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="repairFact"
              >
                <TooltipedInputV2 placeholder="Факт" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="avpBudgetPiuWithoutRuvh"
            label="Бюджет АВП (ПИУ без учета РУВХ) факт, сом"
          >
            <TooltipedInputV2 suffix="сом" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Ремонт ашарным методом, сом *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="repairAsharMethodPlan"
              >
                <TooltipedInputV2 placeholder="План" suffix="сом" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="repairAsharMethodFact"
              >
                <TooltipedInputV2 placeholder="Факт" suffix="сом" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="arrearsCollected"
            label="Собраны недоимки"
          >
            <TooltipedInputV2 type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Space>
  );
};
