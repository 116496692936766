import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { useConclusionContractsTableFilters } from '@features/annualApplication/conclusionСontractsTable/useConclusionContractsTableFilters';
import {
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedDatePickerV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { IAnnualContractualWaterSupplyFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from '@utils/utils';

import styles from './ConclusionContractsTableFilters.module.scss';
import {
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { FilterIcon } from '@icons/FilterIcon';
import { RussianMonthIndexies } from '@enums/month';
import { useGetCurrentUser } from '@features/authentication';

export const ConclusionContractsTableFilters: FC = () => {
  const [params] = useSearchParams();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();

  const [filtersState, setFiltersState] = useState<
    Partial<IAnnualContractualWaterSupplyFilters>
  >({
    startMonth: '1',
    endMonth: currentMonth.toString(),
    year: currentYear.toString()
  });

  const { filters, setConclusionContractsFilters } =
    useConclusionContractsTableFilters();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.region
      ? {
        parentId: parseInt(filtersState.region, 10),
      }
      : skipToken,
  );

  const onFilterChange = (
    filterKey: keyof IAnnualContractualWaterSupplyFilters,
    v: string | null | Dayjs | number,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    };
    setFiltersState(updatedFilters);
  };

  const onReginChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      region: v,
      district: null,
    }));
  };

  const onStartMonthChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      startMonth: v,
      endMonth: null,
    }));
  };

  const onFilterButtonClick = () => {
    setConclusionContractsFilters(
      filtersState as IAnnualContractualWaterSupplyFilters,
    );
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      region: user?.owner?.region?.id.toString(),
      district: user?.owner?.district?.id.toString(),
      endMonth: currentMonth.toString(),
      startMonth: '1',
      year: currentYear.toString()
    });
    setConclusionContractsFilters({
      region: user?.owner?.region?.id.toString(),
      district: user?.owner?.district?.id.toString(),
      endMonth: currentMonth.toString(),
      startMonth: '1',
      year: currentYear.toString()
    });
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setFiltersState({
      ...newFilters,
      region: user?.owner?.region?.id.toString() || null,
      district: user?.owner?.district?.id.toString() || null,
      startMonth: newFilters.startMonth || '1',
      endMonth: newFilters.endMonth || currentMonth.toString(),
      year: newFilters.year || currentYear.toString()
    });
    setConclusionContractsFilters(
      isEmpty(newFilters)
        ? {
          region: user?.owner?.region?.id.toString() || null,
          district: user?.owner?.district?.id.toString() || null,
          startMonth: '1',
          endMonth: currentMonth.toString(),
          year: newFilters.year || currentYear.toString()
        }
        : (newFilters as unknown as IAnnualContractualWaterSupplyFilters),
    );
  }, []);

  const monthArray = Object.entries(RussianMonthIndexies).map(
    ([label, value]) => ({ label, value, disabled: false }),
  );

  const monthArrayEnd = useMemo(() => {
    return monthArray.map((item) => {
      return {
        ...item,
        disabled: item.value < Number(filtersState?.startMonth),
      };
    });
  }, [filtersState?.startMonth, monthArray]);

  return (
    <div className={styles.selectWrapper}>
      <Row
        className={styles.tableFilters}
        align="middle"
        gutter={15}
        justify="center"
      >
        <Col span={4}>
          <TooltipedSelect
            value={filtersState?.region && Number(filtersState?.region)}
            onChange={(v: string) => onReginChange(v)}
            allowClear
            placeholder="Область"
            options={regions}
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            allowClear
            placeholder="Район"
            options={districts}
            disabled={!filtersState.region || isDisabledDistrict}
            value={filtersState?.district && Number(filtersState?.district)}
            onChange={(v: string) => onFilterChange('district', v)}
          />
        </Col>
        <Col span={2}>
          <YearPicker
            value={filtersState.year ? dayjs(filtersState.year) : null}
            placeholder="Год"
            onChange={(date: Dayjs | null, year) =>
              onFilterChange('year', year.toString())
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            allowClear
            placeholder="Начальный месяц"
            options={monthArray}
            bordered={false}
            value={filtersState?.startMonth && Number(filtersState?.startMonth)}
            onChange={(v: string) => onStartMonthChange(v)}
          />
        </Col>

        <Col span={4}>
          <TooltipedSelect
            allowClear
            placeholder="Конечный месяц"
            options={monthArrayEnd}
            bordered={false}
            value={filtersState?.endMonth && Number(filtersState?.endMonth)}
            onChange={(v: string) => onFilterChange('endMonth', v)}
          />
        </Col>

        <Col flex={1}>
          <Row gutter={15}>
            <Col>
              <PrimaryButton
                onClick={onFilterButtonClick}
                icon={<FilterIcon />}
              />
            </Col>
            <Col>
              <PrimaryButton
                onClick={onResetFilterButtonClick}
                icon={<ResetFilterIcon />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
