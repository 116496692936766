import { CreateHydroModuleDistrictModal } from '../modals';
import { HydroModuleTable } from './hydroModuleTable';

export const AgroClimaticZoneCard: React.FC = () => {
  return (
    <>
      <HydroModuleTable />
      <CreateHydroModuleDistrictModal />
    </>
  );
};
