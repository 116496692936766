import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ACCESS, Permission } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { LoadingLayout } from '@features/layout/loading';
import { validatePermissions } from '@utils/authentication';

import { useGetCurrentUser } from './useGetCurrentUser';

type PropsType = {
  children: React.ReactElement;
  permissions: ACCESS[];
  redirectPath?: Routes;
};

export const ProtectedRoute: React.FC<PropsType> = ({
  children,
  permissions,
  redirectPath = Routes.Login,
}): React.ReactElement => {
  const { isLoading, user, userPermissions } = useGetCurrentUser();

  if (isLoading) {
    return <LoadingLayout />;
  }

  const isAccessGranted = validatePermissions({
    permissionsRequired: permissions,
    user,
    userPermissions,
  });

  if (!isAccessGranted) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};
