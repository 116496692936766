import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TableCustom, CustomCard } from '@features/ui-kit';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { getWaterObjectsSelector } from '@store/selectors/mainConduit';
import {
  removeWaterObjectAction,
  setSelectedWaterObjectAction,
} from '@store/slices';

import { useGtsObjectsTableColumns } from './useGtsObjectsPreviewTableColumns';

export const GtsObjectsPreviewTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { columns } = useGtsObjectsTableColumns();
  const dataSource = useSelector(getWaterObjectsSelector);

  const onHandleEdit = useCallback((waterObject: IWaterObject) => {
    dispatch(setSelectedWaterObjectAction(waterObject));
  }, []);

  const deleteItem = (waterObject: IWaterObject) => {
    dispatch(removeWaterObjectAction(waterObject));
  };

  return (
    <CustomCard title={t('irrigationForm.tableObject')}>
      <TableCustom
        columns={columns}
        dataSource={dataSource}
        size="small"
        scroll={{ y: 580 }}
        pagination={false}
        bordered
        actionProps={{
          onHandleEdit,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
