import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

export function renderDate<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value: string = dayjs(
    record[customColumn.dataIndex] || new Date(),
  ).format('YYYY-MM-DD');

  if (value) {
    return <div>{value}</div>;
  }

  return null;
}
