import React from 'react';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

import styles from './monitoringColumn.module.scss';

export const useMonitoringDailyColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => <div>{record?.id || record?.hpid}</div>,
      width: 5,
    },
    {
      title: 'Название',
      dataIndex: 'display_name',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <div>{record?.display_name || record?.hp_name}</div>
      ),
      width: 30,
    },
    {
      title: 'Дата измерения',
      dataIndex: 'utc',
      key: 'utc',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        let data;
        if (record.utc) {
          data = dayjs.unix(record.utc).format('DD.MM.YYYY');
        }
        return <div>{data || '-'}</div>;
      },
      width: 30,
    },
    {
      title: 'Расход л/с',
      dataIndex: 'flow',
      key: 'flow',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 30,
    },
    {
      title: 'Уровень %',
      dataIndex: 'levelqw',
      key: 'levelqw',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 30,
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      width: 40,
      align: 'center',
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
