import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const PrintIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125 9.75C13.125 10.2626 12.733 10.6786 12.25 10.6786H11.375C11.1799 9.87861 10.4396 9.28571 9.625 9.28571H4.375C3.56037 9.28571 2.82013 9.87861 2.625 10.6786H1.75C1.267 10.6786 0.875 10.2626 0.875 9.75V8.82143C0.875 8.30886 1.267 7.89286 1.75 7.89286H12.25C12.733 7.89286 13.125 8.30886 13.125 8.82143V9.75ZM12.25 6.96429H1.75C0.783562 6.96429 0 7.79582 0 8.82143V9.75C0 10.7756 0.783562 11.6071 1.75 11.6071H2.625C2.82013 12.4071 3.56037 13 4.375 13H9.625C10.4396 13 11.1799 12.4071 11.375 11.6071H12.25C13.2164 11.6071 14 10.7756 14 9.75V8.82143C14 7.79582 13.2164 6.96429 12.25 6.96429ZM11.375 1.85714C11.375 0.831536 10.5914 0 9.625 0H4.375C3.40856 0 2.625 0.831536 2.625 1.85714V6.03571H11.375V1.85714Z"
        fill="white"
      />
    </svg>
  );
};
