import { CustomCard, Mask, TableCustom } from '@features/ui-kit';
import { useWaterUserFilters } from '@features/vodokhozReport/entryData/waterUser/waterUserFilters';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGet2tpTotalWaterUseDataQuery,
  useGet2tpWaterUseDataQuery,
  useUpdate2tpWaterUseDataMutation,
} from '@store/gisproApi';
import { formatDecimal } from '@utils/formatNumber/formatNumber';

import {
  Col,
  notification,
  Row,
  Table,
  TablePaginationConfig,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useWaterTableColumns } from './columns';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const WaterUserTable: React.FC<{ report: any; refetch: () => void }> = ({
  report,
  refetch,
}) => {
  const { filters, setWaterUserFilters } = useWaterUserFilters();
  const [params, setSearchParams] = useSearchParams();
  const [update] = useUpdate2tpWaterUseDataMutation();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    refetch: refetchData,
    isLoading,
  } = useGet2tpWaterUseDataQuery(filters.year ? filters : skipToken);

  const hasPermission = useHasUserPermission(ACCESS.REPORT_2TP_CARD_EDIT);

  const { data: total } = useGet2tpTotalWaterUseDataQuery(
    filters.year ? filters : skipToken,
  );

  const dataSource = useMemo(() => {
    const waterManagementDataEntryMap = new Map<any, any>();

    content.forEach((waterManagement) => {
      let totalWaterManagment = 0;
      const waterType = waterManagement.waterUseType;
      const waterManagementWithType = {
        [`agriculturalWaterSupply_${waterType}`]:
          formatDecimal(waterManagement?.agriculturalWaterSupply) ?? 0,
        [`householdDrinking_${waterType}`]:
          formatDecimal(waterManagement.householdDrinking) ?? 0,
        [`industrial_${waterType}`]:
          formatDecimal(waterManagement.industrial) ?? 0,
        [`irrigationWater_${waterType}`]:
          formatDecimal(waterManagement.irrigationWater) ?? 0,
        [`other_${waterType}`]: formatDecimal(waterManagement.other) ?? 0,
      };

      for (const waterManagementKey in waterManagementWithType) {
        totalWaterManagment += waterManagementWithType[waterManagementKey];
      }

      if (!waterManagementDataEntryMap.has(waterManagement?.waterUser?.id)) {
        waterManagementDataEntryMap.set(waterManagement.waterUser.id, {
          ...waterManagementWithType,
          [`id_${waterType}`]: waterManagement.id,
          [`status_${waterType}`]: waterManagement.status,
          [`status`]: report.status,
          [`total_${waterType}`]: totalWaterManagment,
          id: waterManagement.waterUser.id,
        });
      } else {
        const currentWaterManagement = waterManagementDataEntryMap.get(
          waterManagement.waterUser.id,
        );
        waterManagementDataEntryMap.set(waterManagement.waterUser.id, {
          ...currentWaterManagement,
          ...waterManagementWithType,
          [`id_${waterType}`]: waterManagement.id,
          [`status_${waterType}`]: waterManagement.status,
          [`status`]: report.status,
          [`total_${waterType}`]: totalWaterManagment,
          total: currentWaterManagement.total_surface + totalWaterManagment,
          year: waterManagement.year,
          waterUser: waterManagement.waterUser,
        });
      }
    });

    const waterManagementResult: any[] = Array.from(
      waterManagementDataEntryMap.values(),
    );

    return waterManagementResult;
  }, [content]);

  const getCellKey = (record: JsonRecord, column: TableColumn) => {
    if (column.editableCell) {
      return `${column.dataIndex}-${record.waterUser.id}`;
    }
    return -1;
  };

  const onTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      if (pagination.pageSize && pagination.current) {
        const newFilters: any = {
          ...filters,
          page: pagination.current,
          size: pagination.pageSize,
        };
        setWaterUserFilters(newFilters);
        setSearchParams(newFilters);
      }
    },
    [rest],
  );

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const waterUseType = column.dataIndex.split('_')[1];
    const currentWaterManagement = {
      agriculturalWaterSupply:
        record[`agriculturalWaterSupply_${waterUseType}`],
      householdDrinking: record[`householdDrinking_${waterUseType}`],
      industrial: record[`industrial_${waterUseType}`],
      irrigationWater: record[`irrigationWater_${waterUseType}`],
      other: record[`other_${waterUseType}`],
      waterUseType,
      waterUser: record?.waterUser?.id,
    };

    update({
      body: currentWaterManagement,
      year: record.year,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        refetch();
        refetchData();
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  useEffect(() => {
    setWaterUserFilters(Object.fromEntries(params.entries()) as any);
  }, []);

  const calculateTotal = (data: readonly JsonRecord[], key: string) => {
    return data.reduce((sum, record) => sum + (record[key] || 0), 0);
  };

  return (
    <CustomCard
      title={
        <Row gutter={32} justify="space-between">
          <Col>
            <Typography.Title style={{ marginBottom: 0 }} level={4}>
              Ввод данных использования водных ресурсов по водопользованию
              (хозяйствам)
            </Typography.Title>
          </Col>
        </Row>
      }
    >
      {filters.year ? (
        <TableCustom
          rowKey={(record) => [record?.waterUser?.id, record.year].join('-')}
          bordered
          loading={isLoading}
          cellKey={getCellKey}
          columns={useWaterTableColumns(hasPermission)}
          scroll={{ y: 350, x: 1800 }}
          totalCount={totalElements}
          customPagination={rest}
          onChange={onTableChange}
          dataSource={dataSource}
          actionProps={{
            onEditDecimalSaveClick,
          }}
          summary={(data: readonly JsonRecord[]) => {
            const all = calculateTotal(data, 'total');

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Итого:</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {formatDecimal(total?.householdDrinking_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {formatDecimal(total?.industrial_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {formatDecimal(total?.irrigationWater_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {formatDecimal(total?.agriculturalWaterSupply_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  {formatDecimal(total?.other_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  {formatDecimal(total?.total_surface)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  {formatDecimal(total?.householdDrinking_underground)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  {formatDecimal(total?.industrial_underground)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  {formatDecimal(total?.irrigationWater_underground)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {formatDecimal(total?.agriculturalWaterSupply_underground)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12}>
                  {formatDecimal(total?.other_underground)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13}>
                  {formatDecimal(total?.total_underground)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      ) : (
        <Mask maskText="Выберите год" fullHeight={true} />
      )}
    </CustomCard>
  );
};
