import React, { FC, useEffect } from 'react';
import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { ProfileInformation } from '@features/profile/profileCard/ProfileInformation';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@enums/routes';

export const ProfileLayout: FC = () => {
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle('Профиль');
  }, []);

  return (
    <Layout>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.Employees)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Профиль',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <ProfileInformation />
    </Layout>
  );
};
