import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CreatingSiteModal } from '@features/waterUser/waterUserCard/modals/CreatingSiteModal';
import { WaterUserCardStepperQueryTypes } from '@models/waterUser/enums/waterUserEnums';

import { Documents } from './documents';
import { Economies } from './economies';
import { Employees } from './employees';
import { TotalInformation } from './totalInformation';
import { useCreatingSiteModal } from '../useCreatingSiteModal';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const LegalWaterUserCard = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const currentQueryType = useMemo(
    () =>
      params.get(SectionQueryKey) ||
      WaterUserCardStepperQueryTypes.totalInformation,
    [params],
  );

  const { isModalShown } = useCreatingSiteModal();

  let Component;
  switch (currentQueryType) {
    case WaterUserCardStepperQueryTypes.totalInformation:
      Component = TotalInformation;
      break;
    case WaterUserCardStepperQueryTypes.economies:
      Component = Economies;
      break;
    case WaterUserCardStepperQueryTypes.employees:
      Component = Employees;
      break;
    case WaterUserCardStepperQueryTypes.document:
      Component = Documents;
      break;
    default:
      Component = TotalInformation;
      break;
  }

  useEffect(() => {
    setHeaderTitle('Карточка водопользователя Юр. лица');
  }, []);
  return (
    <Row gutter={[0, 16]}>
      <Row gutter={16}>
        <Col>
          <DefaultButton onClick={() => navigate(-1)} className="backBtn">
            <LeftOutlined />
            <Typography.Text>Назад</Typography.Text>
          </DefaultButton>
        </Col>
        <Col>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: t('headers.water-registry-users'),
              },
              {
                title: t('headers.legalsMasterRegistration'),
              },
            ]}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Component />
      {isModalShown && <CreatingSiteModal />}
    </Row>
  );
};
