import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, notification, Row, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { DocsList, InformationalBlock } from '@features/ui-kit';
import { DocsUploader } from '@features/ui-kit/upload';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { getAnnualApplicationAttachmentsSelector } from '@store/selectors';
import {
  useSubmitAnnualContractMutation,
  useUpdateAttachmentsAnnualContractMutation,
} from '@store/gisproApi';
import { useParams } from 'react-router-dom';
import { annualApplicationActions } from '@store/slices';

export const AnnualApplicationAgreementDocumentBlock: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const currentAttachmentsList = useSelector(
    getAnnualApplicationAttachmentsSelector,
  );
  // const [submit, { isError: isErrorSubmit, error: errorSubmit }] =
  //   useSubmitAnnualContractMutation();

  const [updateAttachments, { isError, error }] =
    useUpdateAttachmentsAnnualContractMutation();

  const onAttachmentUpload = useCallback(
    (data: IAttachment) => {
      dispatch(
        annualApplicationActions.setAnnualApplicationAttachmentsAction([
          ...currentAttachmentsList,
          data,
        ]),
      );
      updateAttachments({
        id,
        attachments: [...currentAttachmentsList, data],
      });
      //   .uwrap().then(() => {
      //   submit();
      // }).catch(({data}) => {
      //   notification.error({
      //     message: data?.message ?? 'Произошла ошибка',
      //     description: data.message,
      //   });
      // });
    },
    [currentAttachmentsList],
  );

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <InformationalBlock
            title={t(
              'createIndividualWaterRegistry.thirdStep.documentsBlock.title',
            )}
          >
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <DocsUploader
                  docType={DocumentTypes.Agreement}
                  onLoad={onAttachmentUpload}
                  // documents={currentAttachmentsList}
                  title={`${t(
                    'createIndividualWaterRegistry.thirdStep.documentsBlock.placeholders.contractFile',
                  )}*`}
                />
              </Col>
              <Col span={24}>
                <DocsList
                  title={t('irrigationForm.attachedDocs')}
                  documents={currentAttachmentsList}
                />
              </Col>
            </Row>
          </InformationalBlock>
        </Col>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Row>
    </Form>
  );
};
