import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useGeoPortalManagementTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 50,
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'title',
      title: 'Название',
      width: 300,
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'sourceUrl',
      title: 'Источник',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => (
        <a href={record.sourceUrl} style={{textOverflow: 'ellipsis', width: 500, whiteSpace: 'nowrap', overflow: 'hidden', display: 'block'}}>{record.sourceUrl}</a>
      ),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      width: 200,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
    },
  ];
  return { columns };
};
