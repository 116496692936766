import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { IrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SystemBlockSegment, TotalInformationSegment } from './segments';
import {
  IrrigationSystemCardDocumentTable,
  IrrigationSystemCardObjectTable,
  IrrigationSystemCardOperationalTable,
  IrrigationSystemCardWaterUserTable,
} from './tables';

export const IrrigationSystemCardSegment: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const segment = useMemo(
    () =>
      searchParams.get(SectionQueryKey) ||
      IrrigationSystemQueryTypes.totalInformation,
    [searchParams],
  );

  useEffect(() => {
    setHeaderTitle(t('headers.irrigation-system-card'));
  }, [segment]);

  let Component;

  switch (segment) {
    case IrrigationSystemQueryTypes.totalInformation:
      Component = TotalInformationSegment;
      break;
    case IrrigationSystemQueryTypes.systemBlock:
      Component = SystemBlockSegment;
      break;
    case IrrigationSystemQueryTypes.objects:
      Component = IrrigationSystemCardObjectTable;
      break;
    case IrrigationSystemQueryTypes.waterUsers:
      Component = IrrigationSystemCardWaterUserTable;
      break;
    case IrrigationSystemQueryTypes.operationalBlock:
      Component = IrrigationSystemCardOperationalTable;
      break;
    case IrrigationSystemQueryTypes.document:
      Component = IrrigationSystemCardDocumentTable;
      break;
    default:
      Component = TotalInformationSegment;
      break;
  }

  return <Component/>;
};
