import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Table, TableProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps } from '@models/table/interfaces/tableCustomActionProps';
import { FieldData } from 'rc-field-form/es/interface';

import { EditableCell } from './EditableCell';
import { HeaderEditableCell } from './HeaderEditableCell';
import { getKeyValue } from './mappers/getKeyValue';
import { mapCutomTableColumns } from './mappers/mapCutomTableColumns';
import styles from './TableSheetCustom.module.scss';

type GetCellKey<RecordType> = (record: RecordType, index?: number) => React.Key;

interface TableSheetCustomProps<RecordType> extends TableProps<RecordType> {
  columns: TableColumn[];
  columnsOrder?: string[];
  totalCount?: number;
  highlightedRowKeys?: (React.Key | undefined)[];
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
  actionProps?: TableCustomActionProps<RecordType>;
}

export function TableSheetCustom<RecordType extends JsonRecord>({
  columns,
  dataSource = [],
  onChange,
  rowKey,
  cellKey,
  actionProps: {
    editingCellKey: editingCellKeyParent,
    getEditingCell: getEditingCellParent,
    onEditCellClick: onEditCellClickParent,
    mapDataOnSave: mapDataOnSaveParent,
    ...otherActionProps
  } = {},
  ...other
}: TableSheetCustomProps<RecordType>) {
  const [form] = Form.useForm();
  const [data, setData] = useState<RecordType[]>([]);
  const [editingCellKey, setEditingCellKey] = useState<React.Key | null>(
    editingCellKeyParent || null,
  );
  const [isFormValid, setFormValid] = useState(false);

  useEffect(() => {
    setData([...dataSource]);
  }, [dataSource]);

  const getEditingCell = useCallback(
    (record: RecordType, column: TableColumn): boolean => {
      if (getEditingCellParent) {
        return getEditingCellParent?.(record, column);
      }
      const currentCell = record[column.dataIndex];
      const keyValue = getKeyValue(currentCell as RecordType, cellKey);
      return keyValue === editingCellKey;
    },
    [editingCellKey, getEditingCellParent],
  );

  const onEditCellClick = useCallback(
    (record: RecordType, column: TableColumn) => {
      form.setFieldsValue({ ...record });
      if (onEditCellClickParent) {
        return onEditCellClickParent?.(record, column);
      }
      const currentCell = record[column.dataIndex];
      const keyValue = getKeyValue(currentCell as RecordType, cellKey);
      return setEditingCellKey(keyValue);
    },
    [cellKey, onEditCellClickParent],
  );

  const onFieldsChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      const isValid = allFields.reduce((prev, field) => {
        return prev && !(field.value === undefined || !!field.errors?.length);
      }, true);

      setFormValid(isValid);
    },
    [setFormValid],
  );

  const mapedColumns = useMemo(
    () =>
      mapCutomTableColumns({
        customColumns: columns,
        actionProps: {
          isFormValid,
          editingCellKey,
          getEditingCell,
          onEditCellClick,
          ...otherActionProps,
        },
      }),
    [columns],
  );

  const handleTableChange: TableProps<any>['onChange'] = (
    newPagination,
    newFilters,
    newSorter,
    extra,
  ) => {
    if (onChange) {
      onChange(newPagination, newFilters, newSorter, extra);
    }
  };

  return (
    <Form form={form} onFieldsChange={onFieldsChange} style={{ width: '100%' }}>
      <Table<RecordType>
        className={styles.table}
        {...other}
        components={{
          header: {
            cell: HeaderEditableCell,
          },
          body: {
            cell: EditableCell,
          },
        }}
        bordered={false}
        columns={mapedColumns}
        dataSource={data}
        onChange={handleTableChange}
        rowKey={rowKey}
      />
    </Form>
  );
}
