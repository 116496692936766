import {
  ByTypeDto,
  IByDistrictDto,
  IWaterUsePlanByGuvhDto,
  IWaterUsePlanDataDto,
  TotalsBySystemType,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { addRowIndexies } from '@utils/addRowIndexies/addRowIndexies';
import { set } from '@utils/utils';

export interface ISystemType extends ByTypeDto {
  totalsBySystemType: TotalsBySystemType;
}
export const waterChargingIrrigationPlanWaterSupplyDataMapper = (
  systemType?: ISystemType,
) => {
  if (!systemType) return undefined;
  const { systemTypeName, systemTypeId, systems, totalsBySystemType } =
    systemType;

  const total = {
    systemName: `Итого по "${systemTypeName}"`,
    planItemDtos: totalsBySystemType.planItemDtos,
    systemId: `total${systemTypeId}`,
    systemTypeId: `total${systemTypeId}`,
  };
  const allTotals = {
    systemName: 'Всего Итого',
    planItemDtos: totalsBySystemType.planItemDtos,
    systemTypeId: `allTotal${systemTypeId}`,
    systemId: `allTotal${systemTypeId}`,
  };
  const allTotalArr = Array(3).fill(allTotals);

  const totalArr = Array(3).fill(total);
  const dataArr = [...systems, ...systems, ...systems]
    .slice()
    .sort((a, b) => a.systemId - b.systemId);
  const result = [...dataArr, ...totalArr, ...allTotalArr];

  const withSystemRowIndexies = addRowIndexies(
    result,
    'systemId',
    'systemRowIndex',
  );

  return withSystemRowIndexies;
};

export const waterSupplyDataMapper = (
  obj?: IWaterUsePlanDataDto | IByDistrictDto,
) => {
  if (!obj) return undefined;
  const { bySystemDtos, totals, totalsBySystemType } = obj;

  const systemsData = bySystemDtos.reduce((acc: any[], el, index) => {
    const { systemTypeName, systemTypeId } = el;
    const totalBySystem = totalsBySystemType.find(
      (totalSystem) => totalSystem.systemTypeId === systemTypeId,
    );
    const allTotals = {
      systemName: 'Всего Итого',
      planItemDtos: totals,
      systemTypeId: `total${index + 1}`,
      systemId: `total${index + 2}`,
    };
    const allTotalArr = Array(3).fill(allTotals);
    const total = {
      systemName: `Итого "${systemTypeName}"`,
      planItemDtos: totalBySystem?.planItemDtos,
      systemTypeId: `total${index}`,
      systemId: `total${index}`,
    };
    if ('districtId' in obj) {
      set(total, 'districtId', obj.districtId);
      set(total, 'districtName', obj.districtName);
      set(allTotals, 'districtId', obj.districtId);
      set(allTotals, 'districtName', obj.districtName);
    }

    const totalArr = Array(3).fill(total);
    return [...acc, ...totalArr, ...allTotalArr];
  }, []);

  return systemsData;
};
