import { useSelector } from 'react-redux';

import { ACCESS, Permission } from '@enums/permisson';
import { AuthenticationUser } from '@models/authentication/interfaces/user';
import {
  getAuthLoadingSelector,
  getCurrentUserPermissionsSelector,
  getCurrentUserSelector,
  getIsDisabledDistrictSelector,
  getIsDisabledRegionSelector,
} from '@store/selectors';

type ReturnType = {
  isLoading: boolean;
  user: AuthenticationUser | null;
  userPermissions: ACCESS[];
  isDisabledRegion: boolean;
  isDisabledDistrict: boolean;
};

export const useGetCurrentUser = (): ReturnType => {
  const isLoading = useSelector(getAuthLoadingSelector);
  const authenticationUser = useSelector(getCurrentUserSelector);
  const userPermissions = useSelector(getCurrentUserPermissionsSelector);
  const isDisabledRegion = useSelector(getIsDisabledRegionSelector);
  const isDisabledDistrict = useSelector(getIsDisabledDistrictSelector);
  return {
    isLoading,
    user: authenticationUser,
    userPermissions,
    isDisabledRegion,
    isDisabledDistrict,
  };
};
