import { ColumnsType } from 'antd/es/table';

export const useAnnualWaterSupplyTableColumns = (content: any = []) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      fixed: 'left',
    },
    {
      title: 'Айыл окмоту',
      dataIndex: 'okmotName',
      align: 'center',
      width: 100,
      fixed: 'left',
    },
    ...content.map((item: any) => {
      return {
        title: item.name,
        dataIndex: item.id,
        align: 'center',
        width: 100,
      };
    }),
  ];

  return { columns };
};
