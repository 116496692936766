import { basicApi as api } from '../basicApi';
import { type ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { Owner } from '@models/owners/interfaces/owner';
import type { CommonReference } from '@models/commonReference/interfaces/commonReference';
import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    referenceControllerFindAll: build.query<
      ReferenceControllerFindAllApiResponse,
      ReferenceControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/reference/list` }),
      transformResponse: (response: Array<CommonReference>): any =>
        response.reduce<any>((prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        }, {}),
    }),
    referenceControllerFindDistrictById: build.query<
      ReferenceControllerFindDistrictByIdApiResponse,
      ReferenceControllerFindDistrictByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/district/${queryArg.district}`,
      }),
    }),
    referenceControllerFindByType: build.query<
      ReferenceControllerFindByTypeApiResponse,
      ReferenceControllerFindByTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/type/${queryArg['type']}`,
      }),
    }),
    referenceControllerFindBycode: build.query<
      ReferenceControllerFindBycodeApiResponse,
      ReferenceControllerFindBycodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/type-id/${queryArg.id}`,
      }),
      transformResponse: (response: Array<CommonReference>) =>
        response.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        })),
    }),
    referenceControllerFindById: build.query<
      ReferenceControllerFindByIdApiResponse,
      ReferenceControllerFindByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reference/by-id/${queryArg.id}` }),
    }),
    referenceControllerFindAllcommonReferenceTypeList: build.query<
      ReferenceControllerFindAllcommonReferenceTypeListApiResponse,
      ReferenceControllerFindAllcommonReferenceTypeListApiArg
    >({
      query: () => ({ url: `/api/v1/reference/type-list` }),
    }),
    referenceControllerFindByParentId: build.query<
      ReferenceControllerFindByParentIdApiResponse,
      ReferenceControllerFindByParentIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/reference/parent/${queryArg.id}` }),
    }),
    referenceControllerFindByParentIdAndType: build.query<
      ReferenceControllerFindByParentIdAndTypeApiResponse,
      ReferenceControllerFindByParentIdAndTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/parent-and-type`,
        params: { parentId: queryArg.parentId, typeId: queryArg.typeId },
      }),
    }),
    referenceControllerFindAllObjectCategory: build.query<
      ReferenceControllerFindAllObjectCategoryApiResponse,
      ReferenceControllerFindAllObjectCategoryApiArg
    >({
      query: () => ({ url: `/api/v1/reference/object-category` }),
    }),
    referenceControllerFindByObjectCategoryId: build.query<
      ReferenceControllerFindByObjectCategoryIdApiResponse,
      ReferenceControllerFindByObjectCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/object-category/${queryArg.id}`,
      }),
    }),
    referenceControllerFindByObjectGroupId: build.query<
      ReferenceControllerFindByObjectGroupIdApiResponse,
      ReferenceControllerFindByObjectGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/object-category/find-all-objects-by-objectGroupId/${queryArg.objectGroupId}`,
      }),
    }),
    referenceControllerFindAllObjectGroup: build.query<
      ReferenceControllerFindAllObjectGroupApiResponse,
      ReferenceControllerFindAllObjectGroupApiArg
    >({
      query: () => ({ url: `/api/v1/reference/object-group` }),
    }),
    referenceControllerFindObjectGroupByCode: build.query<
      ReferenceControllerFindObjectGroupByCodeApiResponse,
      ReferenceControllerFindObjectGroupByCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/object-group/${queryArg.code}`,
      }),
    }),
    referenceControllerListReference: build.query<
      ReferenceControllerListReferenceApiResponse,
      ReferenceControllerListReferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/reference/list/${queryArg.typeCode}`,
      }),
    }),
    referenceControllerCreateReference: build.mutation<
      ReferenceControllerCreateReferenceApiResponse,
      ReferenceControllerCreateReferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/reference/create/${queryArg.typeCode}`,
        method: 'POST',
        body: queryArg.createCommonReferenceDto,
      }),
    }),
    referenceControllerUpdateReference: build.mutation<
      ReferenceControllerUpdateReferenceApiResponse,
      ReferenceControllerUpdateReferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/reference/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCommonReferenceDto,
      }),
    }),
    referenceControllerDeleteReference: build.mutation<
      ReferenceControllerDeleteReferenceApiResponse,
      ReferenceControllerDeleteReferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/reference/reference/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ReferenceControllerFindAllApiResponse = unknown;
export type ReferenceControllerFindAllApiArg = void;
export type ReferenceControllerFindDistrictByIdApiResponse = unknown;
export type ReferenceControllerFindDistrictByIdApiArg = {
  district: string;
};
export type ReferenceControllerFindByTypeApiResponse = unknown;
export type ReferenceControllerFindByTypeApiArg = {
  type: string;
};
export type ReferenceControllerFindBycodeApiResponse = Array<CommonReference>;
export type ReferenceControllerFindBycodeApiArg = {
  id: number;
};
export type ReferenceControllerFindByIdApiResponse = unknown;
export type ReferenceControllerFindByIdApiArg = {
  id: number;
};
export type ReferenceControllerFindAllcommonReferenceTypeListApiResponse =
  unknown;
export type ReferenceControllerFindAllcommonReferenceTypeListApiArg = void;
export type ReferenceControllerFindByParentIdApiResponse = unknown;
export type ReferenceControllerFindByParentIdApiArg = {
  id: number;
};
export type ReferenceControllerFindByParentIdAndTypeApiResponse = unknown;
export type ReferenceControllerFindByParentIdAndTypeApiArg = {
  parentId: number;
  typeId: number;
};
export type ReferenceControllerFindAllObjectCategoryApiResponse = ObjectCategory[];
export type ReferenceControllerFindAllObjectCategoryApiArg = void;
export type ReferenceControllerFindByObjectCategoryIdApiResponse = ObjectCategory;
export type ReferenceControllerFindByObjectCategoryIdApiArg = {
  id: string;
};
export type ReferenceControllerFindByObjectGroupIdApiResponse = unknown;
export type ReferenceControllerFindByObjectGroupIdApiArg = {
  objectGroupId: string;
};
export type ReferenceControllerFindAllObjectGroupApiResponse = unknown;
export type ReferenceControllerFindAllObjectGroupApiArg = void;
export type ReferenceControllerFindObjectGroupByCodeApiResponse = IObjectGroup;
export type ReferenceControllerFindObjectGroupByCodeApiArg = {
  code: string;
};
export type ReferenceControllerListReferenceApiResponse = unknown;
export type ReferenceControllerListReferenceApiArg = {
  typeCode: string;
};
export type ReferenceControllerCreateReferenceApiResponse = unknown;
export type ReferenceControllerCreateReferenceApiArg = {
  typeCode: string;
  createCommonReferenceDto: CreateCommonReferenceDto;
};
export type ReferenceControllerUpdateReferenceApiResponse = unknown;
export type ReferenceControllerUpdateReferenceApiArg = {
  id: number;
  updateCommonReferenceDto: UpdateCommonReferenceDto;
};
export type ReferenceControllerDeleteReferenceApiResponse = unknown;
export type ReferenceControllerDeleteReferenceApiArg = {
  id: number;
};
export type CreateCommonReferenceDto = {
  title: string;
  code: string;
  type: string;
  parentId?: number;
};
export type UpdateCommonReferenceDto = {
  title: string;
  code: string;
};
export const {
  useReferenceControllerFindAllQuery,
  useReferenceControllerFindDistrictByIdQuery,
  useReferenceControllerFindByTypeQuery,
  useReferenceControllerFindBycodeQuery,
  useReferenceControllerFindByIdQuery,
  useReferenceControllerFindAllcommonReferenceTypeListQuery,
  useReferenceControllerFindByParentIdQuery,
  useReferenceControllerFindByParentIdAndTypeQuery,
  useReferenceControllerFindAllObjectCategoryQuery,
  useReferenceControllerFindByObjectCategoryIdQuery,
  useLazyReferenceControllerFindByObjectCategoryIdQuery,
  useReferenceControllerFindByObjectGroupIdQuery,
  useReferenceControllerFindAllObjectGroupQuery,
  useReferenceControllerFindObjectGroupByCodeQuery,
  useReferenceControllerListReferenceQuery,
  useReferenceControllerCreateReferenceMutation,
  useReferenceControllerUpdateReferenceMutation,
  useReferenceControllerDeleteReferenceMutation,
} = injectedRtkApi;
