type ReturnType = {
  data: any;
};

export const mapBalanceSheetAccountingTableData = (data: any): ReturnType => {
  const dataSource = data?.map((item: any, index: number) => {
    const res: any = {
      name: item.name,
      index: index + 1,
    };
    item.days?.map((dayArr: any) => {
      res[`${dayArr.day}.day`] = dayArr.day;
      res[`${dayArr.day}.id`] = dayArr.id;
      res[`${dayArr.day}.average`] = Number.isNaN(Number(dayArr.average))
        ? 0
        : dayArr.average;
      Object.keys(dayArr.evening).forEach((key) => {
        res[`${dayArr.day}.${key}Evening`] = dayArr.evening[key];
      });
      Object.keys(dayArr.morning).forEach((key) => {
        res[`${dayArr.day}.${key}Morning`] = dayArr.morning[key];
      });
      return res;
    });
    return res;
  });
  return { data: dataSource };
};
