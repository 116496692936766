import { createSelector } from '@reduxjs/toolkit';
import { WaterQualityState } from '@store/slices';

type WithWaterQualityState = {
  waterQuality: WaterQualityState;
};

export const waterQualityStateSelector = (
  state: WithWaterQualityState,
): WaterQualityState => state.waterQuality;

export const getWaterQualityMonitoringTypeSelector = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.monitoringType;
  },
);

export const getShowCreateCollectionPointModalSelector = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.isShowCreateCollectionPoint;
  },
);

export const getIsCreateGtsModalSelector = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.isCreateGtsModal;
  },
);

export const getGtsPointSelector = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.gtsPoint;
  },
);

export const getWaterQualityCardSelector = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.waterQualityCard;
  },
);

export const getMetricsCollectionPointType = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.metricsCollectionPointType;
  },
);

export const getDictionaryZoneTableFilters = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.dictionaryZoneTableFilters;
  },
);

export const getEnteringDataTablesFilters = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.enteringDataTablesFilters;
  },
);

export const getMonitoringPointsTableFilters = createSelector(
  waterQualityStateSelector,
  (waterQualityState) => {
    return waterQualityState.monitoringPointsTableFilters;
  },
);
