import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useReferenceBookTableColumns = (parent = false) => {
  const columns: TableColumn[] = [];
  columns.push({
    dataIndex: 'id',
    title: 'ID',
    xtype: CustomTableColumnType.String,
    align: 'center',
  });
  columns.push({
    dataIndex: 'title',
    title: 'Наименование',
    xtype: CustomTableColumnType.String,
    align: 'center',
  });
  columns.push({
    dataIndex: 'code',
    title: 'Код',
    xtype: CustomTableColumnType.String,
    align: 'center',
  });
  if (parent) {
    columns.push({
      dataIndex: 'parentId',
      title: 'ID родителя',
      xtype: CustomTableColumnType.String,
      align: 'center',
    });
  }
  columns.push({
    title: 'Действия',
    dataIndex: 'actions',
    xtype: CustomTableColumnType.ActionColumn,
    actions: [
      CustomTableAction.EditWithoutText,
      CustomTableAction.DeleteWithoutText,
    ],
    key: 'actions',
    align: 'center',
  });

  return { columns };
};
