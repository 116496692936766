import {
  IWaterQualityFilters,
  WaterQualityMonitoringType,
} from '@models/waterQuality/interfaces/waterQuality';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WaterQualityState = {
  isShowCreateCollectionPoint: boolean;
  isCreateGtsModal: boolean;
  formData: any;
  monitoringType: WaterQualityMonitoringType;
  waterQualityCard: any;
  gtsPoint: any;
  metricsCollectionPointType: {
    disabledValue?: boolean;
    value?: string;
  };
  monitoringPointsTableFilters: IWaterQualityFilters;
  dictionaryZoneTableFilters: IWaterQualityFilters;
  enteringDataTablesFilters: IWaterQualityFilters;
};

const initialState: WaterQualityState = {
  isCreateGtsModal: false,
  formData: {
    [WaterUserTypes.association]: {},
    [WaterUserTypes.physical]: {},
    [WaterUserTypes.legal]: {},
  },
  monitoringType: WaterQualityMonitoringType.MineralizationGroundwater,
  isShowCreateCollectionPoint: false,
  gtsPoint: {},
  waterQualityCard: {},
  metricsCollectionPointType: {},
  monitoringPointsTableFilters: {
    page: 1,
  },
  dictionaryZoneTableFilters: {},
  enteringDataTablesFilters: {},
};

const waterQualitySlice = createSlice({
  name: 'waterRegistry',
  initialState,
  reducers: {
    setMonitoringType: (
      state,
      action: PayloadAction<WaterQualityMonitoringType>,
    ) => {
      return {
        ...state,
        monitoringType: action.payload,
      };
    },
    setShowCreateCollectionPointModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowCreateCollectionPoint: action.payload,
      };
    },
    setCreateGtsModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCreateGtsModal: action.payload,
      };
    },
    setEditGtsPointAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        gtsPoint: action.payload,
      };
    },
    setWaterQualityCard: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        waterQualityCard: action.payload,
      };
    },
    setMetricsCollectionPointType: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        metricsCollectionPointType: action.payload,
      };
    },
    setMonitoringPointsTableFilters: (state, action: any) => {
      return {
        ...state,
        monitoringPointsTableFilters: action.payload,
      };
    },
    setDictionaryZoneTableFilters: (state, action: any) => {
      return {
        ...state,
        dictionaryZoneTableFilters: action.payload,
      };
    },
    setEnteringDataTablesFilters: (
      state,
      action: PayloadAction<IWaterQualityFilters>,
    ) => {
      return {
        ...state,
        enteringDataTablesFilters: action.payload,
      };
    },
  },
});

export const {
  setMonitoringType,
  setShowCreateCollectionPointModal,
  setCreateGtsModalAction,
  setEditGtsPointAction,
  setWaterQualityCard,
  setMetricsCollectionPointType,
  setMonitoringPointsTableFilters,
  setDictionaryZoneTableFilters,
  setEnteringDataTablesFilters,
} = waterQualitySlice.actions;

export default waterQualitySlice.reducer;
