import { TooltipedYearPickerV2 } from '@features/ui-kit-v2';
import { FC } from 'react';

import { Col, Form, Row } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomCard,
  DangerButton,
  Divider,
  InformationalBlock,
  PrimaryButton,
  SectionTitle,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useFindDictionaryByTypeIdQuery,
  useGetObjectCategoriesQuery,
} from '@store/gisproApi';
import { getIsLinearObject } from '@utils/objectType';
import dayjs from 'dayjs';
import { get } from '@utils/utils';

import { useWorkNotationModal } from '../workNotationModal';
import styles from './WorksheetFields.module.scss';
import { DeleteIcon } from '@icons/DeleteIcon';

export const WorksheetFields: FC = () => {
  const { setShowModal, setWorkNotationIndex } = useWorkNotationModal();

  const { data: workKinds = [], isLoading: workKindsIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.WorkTypes });

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useGetObjectCategoriesQuery();

  const onAddDescriptionClick = (index: number) => {
    setShowModal(true);
    setWorkNotationIndex(index);
  };

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form.List name="works">
      {(fields, { add, remove }) => (
        <div>
          <Row justify="end">
            <Col>
              <PrimaryButton
                size="large"
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Добавить запись
              </PrimaryButton>
            </Col>
          </Row>
          <Divider />
          <InformationalBlock>
            <div className={styles.root}>
              <SectionTitle title="Проведение работ" />
              {fields.map(({ key, name, ...restField }, index) => (
                <CustomCard key={key} title={`Запись №${index + 1}`}>
                  <Row
                    key={key}
                    gutter={16}
                    className={styles.formCard}
                    align="bottom"
                    title="Данные о проведении маштабных и реабилитационных работ в течении не менее 2-лет"
                  >
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'workYear']}
                        label="Год проведения работ"
                        rules={[{ required: true }]}
                        style={{ marginBottom: 10 }}
                      >
                        <TooltipedYearPickerV2
                          size="large"
                          allowClear
                          format="YYYY"
                          placeholder=""
                          picker="year"
                          disabledDate={(current) =>
                            current && current > dayjs().endOf('year')
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'workType']}
                        label="Вид работы"
                        rules={[{ required: true }]}
                        style={{ marginBottom: 10 }}
                      >
                        <TooltipedSelect
                          size="large"
                          allowClear
                          loading={workKindsIsLoading}
                          options={workKinds}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'objectType']}
                        label="Категория объекта"
                        rules={[{ required: true }]}
                        style={{ marginBottom: 10 }}
                      >
                        <TooltipedSelect
                          size="large"
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          filterOption={filterOption}
                          loading={objectCategoriesIsLoading}
                          options={objectCategories}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          get<string>(
                            prevValues,
                            ['works', name, 'objectType'],
                            'default',
                          ) !==
                          get<string>(
                            currentValues,
                            ['works', name, 'objectType'],
                            'default',
                          )
                        }
                      >
                        {({ getFieldValue }) =>
                          getIsLinearObject(
                            objectCategories,
                            getFieldValue(['works', name, 'objectType']),
                          ) ? (
                            <Form.Item
                              name={[name, 'length']}
                              label="Протяженность, км"
                              rules={[{ required: true }]}
                              style={{ marginBottom: 10 }}
                            >
                              <TooltipedInput size="large" type="number" />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name={[name, 'length']}
                              label="Колличество, шт"
                              rules={[{ required: true }]}
                              style={{ marginBottom: 10 }}
                            >
                              <TooltipedInput size="large" type="number" />
                            </Form.Item>
                          )
                        }
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, 'sum']}
                        label="Общая сумма, сом"
                        rules={[{ required: true }]}
                        style={{ marginBottom: 10 }}
                      >
                        <TooltipedInput
                          size="large"
                          type="number"
                          placeholder="Общая сумма"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          get<string>(
                            prevValues,
                            ['works', name, 'description'],
                            'default',
                          ) !==
                          get<string>(
                            currentValues,
                            ['works', name, 'description'],
                            'default',
                          )
                        }
                      >
                        {({ getFieldValue }) => {
                          const description = getFieldValue([
                            'works',
                            name,
                            'description',
                          ]);
                          return (
                            <PrimaryButton
                              style={{ width: '100%', marginBottom: 10 }}
                              onClick={() => onAddDescriptionClick(name)}
                              icon={!description && <PlusOutlined />}
                              size="large"
                            >
                              {description
                                ? 'Редактировать описание работ'
                                : 'Добавить описание работ'}
                            </PrimaryButton>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <DangerButton
                        size="large"
                        style={{ marginBottom: 10 }}
                        onClick={() => remove(index)}
                        icon={<DeleteIcon />}
                      />
                    </Col>
                  </Row>
                </CustomCard>
              ))}
            </div>
          </InformationalBlock>
        </div>
      )}
    </Form.List>
  );
};
