export enum CustomTableColumnType {
  Expand = 'expand',
  Select = 'select',
  String = 'string',

  CommonReference = 'commonReference',
  ObjectCategory = 'objectCategory',
  ObjectGroup = 'objectGroup',
  TechnicalStatus = 'technicalStatus',
  OperationalStatus = 'operationalStatus',

  BooleanColumn = 'booleancolumn',
  CheckColumn = 'checkcolumn',
  DateColumn = 'datecolumn',
  NumberColumn = 'numbercolumn',
  ActionColumn = 'actioncolumn',
  MethodsColumn = 'methodscolumn',
  TreeColumn = 'treecolumn',
  SelectColumn = 'selectcolumn',
  SelectColumntag = 'selectcolumntag',
  TagColumn = 'tagcolumn',
  RenderCustom = 'renderCustom',

  // Watering Plan
  WateringPlanModalControl = 'watering-plan-modal-control',

  // Water Accounting
  WaterAccountingActionButtons = 'water-accounting-action-buttons',
  WaterAccountingValues = 'water-accounting-values',
  WaterAccountingOutlet = 'water-accounting-outlet',

  // UpdatedItems
  GeoPortalActions = 'geoPortalActions',

  DecadeEditableCell = 'decadeEditableCell',
  DecadeCell = 'decadeCell',
  DecimalEdit = 'decimalEdit',
  Geo = 'geo',
  Control = 'control',
  IrrigationControl = 'irrigationControl',
  WaterRegistryControl = 'waterRegistryControl',
  Quentity = 'quentity',
  Status = 'status',
  Budget = 'budget',
  Deadline = 'deadline',
  WaterUser = 'waterUser',
  Count = 'count',
  GreyCell = 'greyCell',
  // Price
  PriceEditableCell = 'priceEditableCell',
}
