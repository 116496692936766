import { useState } from 'react';

import { Row, Space } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { Col } from 'antd/lib';

import {
  DashedButton,
  Divider,
  PrimaryButton,
  Segmented,
} from '@features/ui-kit';
import { WaterUsePlanTableTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { useGetAllStatusWaterUsePlanQuery } from '@store/gisproApi';

import { WaterUsePlanCAAdditionsTable } from './waterUsePlanCAAdditionsTable/WaterUsePlanCAAdditionsTable';
import { WaterUsePlanCAPivotTable } from './waterUsePlanCAPivotTable/WaterUsePlanCAPivotTable';

const options: SegmentedLabeledOption[] = [
  {
    label: 'Сводная таблица',
    value: WaterUsePlanTableTypes.PivotTable,
  },
  {
    label: 'Приложение',
    value: WaterUsePlanTableTypes.AdditionsTable,
  },
];

export const WaterUsePlanCATable: React.FC = () => {
  const { data: statuses } = useGetAllStatusWaterUsePlanQuery();
  const [selectedSegment, setSelectedSegment] =
    useState<SegmentedValue>('pivotTable');

  const onSegmentedChange = (value: SegmentedValue) => {
    setSelectedSegment(value);
  };

  let Component;
  switch (selectedSegment) {
    case WaterUsePlanTableTypes.PivotTable:
      Component = WaterUsePlanCAPivotTable;
      break;
    case WaterUsePlanTableTypes.AdditionsTable:
      Component = WaterUsePlanCAAdditionsTable;
      break;
    default:
      Component = WaterUsePlanCAPivotTable;
  }

  return (
    <Row justify="space-between">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Segmented options={options} onChange={onSegmentedChange} />
          </Col>

          <Col>
            <Space size={8}>
              <DashedButton size="large">Проект ГУВХ</DashedButton>
              <PrimaryButton size="large">Утвердить</PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider />
      </Col>

      <Col span={24}>
        <Component />
      </Col>
    </Row>
  );
};
