const kibana: any = {
  'Балансовые гидропосты дневные': '3766f7e0-81db-11ee-9996-65749f62dc8a',
  'Ирригационный фонд: Мониторинг Эксплуатационный блок': '189a5ac0-e9b7-11ee-b48d-157c259f5a27',
  'Водопользование: Хоз. Договорная водоподача Республика': '412af890-e74c-11ee-b48d-157c259f5a27',
  'Водопользование: Посевная факт/план': '4b605910-e8ee-11ee-b48d-157c259f5a27',
  'Водосброс гидропосты': '6e1d9f00-81db-11ee-9996-65749f62dc8a',
  'Водохранилища': '80fce650-e02a-11ee-9315-a9a8136220bc',
  'Гидропосты детальная карточка': '99b2e350-7646-11ee-9996-65749f62dc8a',
  'Мониторинг': '9fdd4760-c980-11ee-9315-a9a8136220bc',
  // 'Балансовый учёт': 'b3511630-7649-11ee-9996-65749f62dc8a',
  'Водоподача гидропосты': 'b96aa8e0-81db-11ee-9996-65749f62dc8a',
  'Водопользование: Хоз. Договорная водоподача системы 2': 'b97c4080-e772-11ee-b48d-157c259f5a27',
  'Водопользование: Общие данные': 'b9c00180-df75-11ee-9315-a9a8136220bc',
  'Ирригационный фонд': 'fe9723d0-e108-11ee-9315-a9a8136220bc',

  'Ирригационный фонд: Мониторинг Строительство': '4a980360-e917-11ee-b48d-157c259f5a27',
  'Водопользование: Хоз. Договорная водоподача системы': '5bd02ca0-e77a-11ee-b48d-157c259f5a27',
  // 'Балансовый водоучет': '649057a0-e1dc-11ee-9315-a9a8136220bc',
  'Мониторинг тарифов': '9c325d40-e1e9-11ee-9315-a9a8136220bc',
  'Водопользование: Хоз. Договорная водоподача Республика 2': 'b255fd00-e74a-11ee-b48d-157c259f5a27',

  'Балансовый учет (по системам)': '93209e50-e820-11ee-b48d-157c259f5a27',
  'Балансовый учёт': '93209e50-e820-11ee-b48d-157c259f5a27',
  'Балансовый водоучет': '93209e50-e820-11ee-b48d-157c259f5a27',
  'Балансовый учет (датчики)': '93209e50-e820-11ee-b48d-157c259f5a27',
  'Балансовый учет (Специалисты)': '93209e50-e820-11ee-b48d-157c259f5a27',
};
export const getKibanaUrl = (type: any, query: string = '') => {
  return process.env.REACT_APP_KIBANA_URL + '/app/dashboards#/view/' + kibana[type] + `?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:now-1y,to:now))&_a=(query:(language:kuery,query:'${query}'))&show-time-filter=true`;
}
