import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionShowIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1" height="18" rx="0.5" fill="white" />
      <path d="M4 9L1 13.3301L1 4.66987L4 9Z" fill="white" />
    </svg>
  );
};
