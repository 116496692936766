import { Typography } from 'antd';

import styles from './Mask.module.scss';

type ReservoirMonitoringMaskTypes = {
  maskText: string;
  fullHeight?: boolean;
};

export const Mask: React.FC<ReservoirMonitoringMaskTypes> = ({
  maskText,
  fullHeight,
}) => {
  return (
    <div className={`${styles.mask} ${fullHeight ? styles.fullHeight : ''} `}>
      <Typography.Title level={5}>{maskText}</Typography.Title>
    </div>
  );
};
