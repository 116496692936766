import { DefaultOptionType } from 'antd/es/select';

import { months } from '@constants/month';
import dayjs from 'dayjs';

export const yearOptions: DefaultOptionType[] = [];
for (let i = 2023; i <= 2023; i += 1) {
  yearOptions.push({ value: i.toString(), label: i.toString() });
}

export const monthOptions: DefaultOptionType[] = months.map(
  (monthName, index) => ({
    value: index.toString(),
    label: monthName,
  }),
);
export const decadeNumbers: DefaultOptionType[] = [];
for (let i = 1; i <= 12 * 3; i += 1) {
  decadeNumbers.push({ value: i.toString(), label: `Декада №${i}` });
}

export const weekOptionFn = (year: number) => {
  const weekOption: DefaultOptionType[] = [];
  const startDate = dayjs().year(year).startOf('year');
  const endDate = dayjs().year(year).month(11).date(31).format('YYYY-MM-DD');
  let currentMonday = startDate.startOf('week').add(1, 'day');
  while (currentMonday.isBefore(endDate)) {
    weekOption.push({
      value: currentMonday.format('YYYY-MM-DD'),
      label: currentMonday.format('YYYY-MM-DD'),
    });
    currentMonday = currentMonday.add(1, 'week');
  }
  return weekOption;
};
