import { FC } from 'react';

import { Layout } from 'antd';

import { AnnualApplicationTable } from '@features/annualApplication';

export const AnnualApplicationTableLayout: FC = () => {
  return (
    <Layout>
      <AnnualApplicationTable />
    </Layout>
  );
};
