import { Space, TabsProps } from 'antd';

import { Tabs } from '@features/ui-kit';
import { WaterUsePlanRuvhGeneralSummary } from '@features/waterUsePlan/shared/ruvhTables';
import { WaterUsePlanSummaryViewTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';

import { WaterUsePlanRuvhGovSummary } from './waterUsePlanRuvhGovSummary';

const items: TabsProps['items'] = [
  {
    key: WaterUsePlanSummaryViewTypes.General,
    label: 'Общее',
    children: <WaterUsePlanRuvhGeneralSummary />,
  },
  {
    key: WaterUsePlanSummaryViewTypes.State,
    label: 'Гос системы',
    children: <WaterUsePlanRuvhGovSummary />,
  },
];
export const WaterUsePlanRuvhSummaryTable: React.FC = () => {
  return <Tabs items={items} />;
};
