import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const EyeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.5C11.5083 0.5 13.7367 1.67844 15.3234 3.13719C16.1199 3.86938 16.776 4.69156 17.2386 5.51C17.6931 6.31344 18 7.18437 18 8C18 8.81563 17.694 9.68656 17.2386 10.49C16.776 11.3084 16.1199 12.1306 15.3234 12.8628C13.7367 14.3216 11.5074 15.5 9 15.5C6.4917 15.5 4.2633 14.3216 2.6766 12.8628C1.8801 12.1306 1.224 11.3084 0.7614 10.49C0.306 9.68656 0 8.81563 0 8C0 7.18437 0.306 6.31344 0.7614 5.51C1.224 4.69156 1.8801 3.86938 2.6766 3.13719C4.2633 1.67844 6.4926 0.5 9 0.5ZM9 2.375C7.0344 2.375 5.2128 3.30594 3.8682 4.5425C3.1986 5.1575 2.6694 5.82969 2.3121 6.46156C1.9467 7.10844 1.8 7.64375 1.8 8C1.8 8.35625 1.9467 8.89156 2.3121 9.53844C2.6694 10.1703 3.1986 10.8416 3.8682 11.4575C5.2128 12.6941 7.0344 13.625 9 13.625C10.9656 13.625 12.7872 12.6941 14.1318 11.4575C14.8014 10.8416 15.3306 10.1703 15.6879 9.53844C16.0533 8.89156 16.2 8.35625 16.2 8C16.2 7.64375 16.0533 7.10844 15.6879 6.46156C15.3306 5.82969 14.8014 5.15844 14.1318 4.5425C12.7872 3.30594 10.9656 2.375 9 2.375ZM9 5.1875C9.0792 5.1875 9.1575 5.19125 9.2349 5.19781C9.0395 5.55486 8.96213 5.9689 9.0147 6.37623C9.06727 6.78356 9.24687 7.16161 9.52586 7.45223C9.80485 7.74285 10.1678 7.92992 10.5588 7.98469C10.9499 8.03945 11.3473 7.95886 11.6901 7.75531C11.7374 8.31985 11.6199 8.88611 11.353 9.37989C11.0861 9.87368 10.6822 10.272 10.1943 10.5226C9.70638 10.7732 9.15715 10.8644 8.6186 10.7842C8.08005 10.7041 7.5773 10.4564 7.17621 10.0736C6.77513 9.69071 6.49442 9.19058 6.37088 8.63871C6.24734 8.08683 6.28672 7.50895 6.48386 6.98079C6.68101 6.45262 7.02672 5.99881 7.4757 5.67882C7.92468 5.35882 8.45599 5.18757 9 5.1875Z"
        fill="black"
        fillOpacity="0.7"
      />
    </svg>
  );
};
