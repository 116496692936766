import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomCard,
  DefaultButton,
  DocsList,
  Mask,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import {
  AddButtonSecondary,
  SectionTitle,
  TooltipedDatePickerV2,
  TooltipedInputV2,
  TooltipedSelectV2,
  UploadModalV2,
} from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdQuery,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import { addAttachmentToWaterRegistryUserAction } from '@store/slices';
import { innValidatorMaxLength } from '@utils/validation/innValidator';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { passportValidator } from '@utils/validation/passportValidator';
import dayjs, { Dayjs } from 'dayjs';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import { useGetCurrentUser } from '@features/authentication';

export const LegalMainInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [defaultDocType, setDefaultDocType] = useState('');
  const currentWaterRegistry = useSelector(getFormData);
  const form = Form.useFormInstance();
  const region = Form.useWatch('region', form);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    region || skipToken,
  );

  const documents: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.legal].attachmentResponseDtos || [],
    [currentWaterRegistry],
  );
  const attachments = documents?.filter(
    (att) => att.type === DocumentTypes.Regulation,
  );

  const { data: organizationForm = [], isLoading: organizationFormIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.OrganizationForm,
    });

  const { data: ownerType = [], isLoading: ownerTypeIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.OwnerType,
    });

  const onRegionChange = () => {
    form.setFieldsValue({
      district: null,
    });
  };

  const {
    data: activityOrganization = [],
    isLoading: activityOrganizationIsLoading,
  } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.TypeActivityOrganization,
  });

  const { data: economicActivity = [], isLoading: economicActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.TypeEconomicActivity,
    });

  const onAttachmentUpload = useCallback((file: IAttachment) => {
    dispatch(
      addAttachmentToWaterRegistryUserAction({
        attachment: file,
        waterRegistryUserType: WaterUserTypes.legal,
      }),
    );
    setDefaultDocType('устав');
  }, []);

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              Общие данные водопользователя
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row className={styles.individualMainInfo}>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.organizationName',
                )}
                name="name"
                rules={[{ required: true }]}
              >
                <TooltipedInput placeholder="Введите Название" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.activityType',
                )}
                name="businessActivity"
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  size="large"
                  placeholder="Введите вид деятельности организации"
                  loading={activityOrganizationIsLoading}
                  options={activityOrganization}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.organizationInn',
                )}
                name="inn"
                rules={[{ required: true }, { len: 18 }, numbersValidator]}
              >
                <TooltipedInput
                  placeholder="Введите ИНН организации"
                  size="large"
                  maxLength={18}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.organizationForm',
                )}
                name="organizationForm"
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  placeholder="Введите Форму организации"
                  size="large"
                  loading={organizationFormIsLoading}
                  options={organizationForm}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.economicActivityType',
                )}
                name="economicActivity"
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  placeholder="Введите Вид хозяйственной деятельности"
                  size="large"
                  loading={economicActivityIsLoading}
                  options={economicActivity}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.organizationAddress',
                )}
                name="address"
                rules={[{ required: true }]}
              >
                <TooltipedInput
                  placeholder="Введите Адрес организации"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.ownershipForm',
                )}
                name="ownershipForm"
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  placeholder="Введите Форму собственности"
                  size="large"
                  loading={ownerTypeIsLoading}
                  options={ownerType}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.dateRegistrationOrganization',
                )}
                name="registerDate"
                rules={[{ required: true }]}
              >
                <TooltipedDatePicker
                  disabledDate={(current) =>
                    current && current > dayjs().endOf('day')
                  }
                  size="large"
                  placeholder="Выберите Дату регистрации организации"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.regNumber',
                )}
                name="regNumber"
                rules={[{ required: true }]}
              >
                <TooltipedInput
                  placeholder="Введите Регистрационный номер"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="region"
                label={t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
                )}
                rules={[{ required: true }]}
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  size="large"
                  onChange={onRegionChange}
                  loading={regionIsLoading}
                  options={regions}
                  allowClear
                  placeholder="Область"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="district"
                label={t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
                )}
                rules={[{ required: true }]}
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  size="large"
                  options={districtByParentId}
                  placeholder="Район"
                  allowClear
                  disabled={!region}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.okpo',
                )}
                name="okpo"
                rules={[{ required: false }, numbersValidator]}
              >
                <TooltipedInput
                  type="number"
                  placeholder="Введите ОКПО"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="end" gutter={[0, 16]}>
            <Col className={styles.docsList} span={24}>
              <Typography.Title level={4}>
                Прикрепленные документы:
              </Typography.Title>
              {attachments?.length ? (
                <DocsList documents={attachments} />
              ) : (
                <Mask maskText="Нет прикрепленных документов" />
              )}
            </Col>
            <Col>
              <DefaultButton
                icon={<UploadFileIcon />}
                onClick={() => setShow(true)}
              >
                {t(
                  'createLegalWaterRegistry.firstStep.main.placeholders.regulation',
                )}
                *
              </DefaultButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <UploadModal
        onLoad={onAttachmentUpload}
        setShow={setShow}
        preSelectedDocType="устав"
        show={show}
      />
    </CustomCard>
  );
};
