import { ACCESS } from '@enums/permisson';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWaterObjectTableColumns = (isArchived = false) => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'objectId',
      key: 'objectId',
      width: 50,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Код водного объекта',
      dataIndex: 'objectCode',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'objectCode',
      width: 120,
    },
    {
      title: 'Наименование объекта',
      dataIndex: 'objectName',
      xtype: CustomTableColumnType.String,
      key: 'objectName',
      width: 150,
    },
    {
      title: 'Группа',
      dataIndex: 'objectType',
      xtype: CustomTableColumnType.ObjectGroup,
      key: 'objectType',
      width: 150,
    },
    {
      title: 'Категория',
      dataIndex: 'objectType',
      xtype: CustomTableColumnType.ObjectCategory,
      key: 'objectType',
      width: 150,
    },
    {
      title: 'Тип',
      dataIndex: 'typeCategory',
      xtype: CustomTableColumnType.String,
      key: 'objectType',
      width: 110,
    },
    {
      title: 'Год ввода в эксплуатацию',
      dataIndex: 'dateStart',
      xtype: CustomTableColumnType.DateColumn,
      format: 'YYYY',
      key: 'dateStart',
      width: 140,
    },
    {
      title: 'Общая орошаемая площадь (ГА)',
      dataIndex: 'totalArea',
      xtype: CustomTableColumnType.String,
      key: 'totalArea',
      width: 120,
    },
    {
      title: 'Геолокация',
      dataIndex: 'geo',
      width: 40,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Gis],
      actionsAccess: [ACCESS.IRRIGATION_SYSTEM_OBJECTS_GIS_VIEW],
      key: 'geo',
    },
    {
      title: 'Статус Тех.состояния',
      dataIndex: 'technicalStatusId',
      // xtype: CustomTableColumnType.String,
      xtype: CustomTableColumnType.TechnicalStatus,
      key: 'techStatus',
      width: 197,
    },
    {
      title: 'Статус Эксп.состояния',
      dataIndex: 'operationalStatusId',
      xtype: CustomTableColumnType.OperationalStatus,
      // xtype: CustomTableColumnType.String,
      key: 'expoStatus',
      width: 197,
    },
    {
      title: '...',
      dataIndex: 'actions',
      width: 60,
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: !isArchived
        ? [
            CustomTableAction.WatchWithoutText,
            CustomTableAction.ArchiveWithoutText,
          ]
        : [
            CustomTableAction.WatchWithoutText,
            CustomTableAction.UnArchiveWithoutText,
          ],
      actionsAccess: [
        ACCESS.IRRIGATION_SYSTEM_OBJECTS_TABLE_VIEW,
        ACCESS.IRRIGATION_SYSTEM_OBJECTS_ARCHIVE,
      ],
    },
  ];
  return { columns };
};
