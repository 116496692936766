import React, { useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import { Links } from '@enums/links';
import L from 'leaflet';

import styles from './OpenStreetMapV2.module.scss';

import 'leaflet/dist/leaflet.css';

interface IOpenStreetMapV2Props {
  changeLocale: (locale: any) => void;
}
export const OpenStreetMapV2: React.FC<IOpenStreetMapV2Props> = ({
  changeLocale,
}) => {
  const center: any = [41.2044, 74.7661];

  function draggableMarker() {
    const [position, setPosition] = useState<any>(center);
    const markerRef = useRef<any>(null);
    const customIcon = new L.Icon({
      iconUrl: Links.Location,
      iconSize: [32, 32],
    });
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
            changeLocale(marker.getLatLng());
          }
        },
      }),
      [],
    );
    return (
      <Marker
        draggable
        icon={customIcon}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      />
    );
  }

  return (
    <MapContainer className={styles.mapContainer} center={center} zoom={7}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {draggableMarker()}
    </MapContainer>
  );
};
