import { ColumnsType } from 'antd/es/table';

export const useConclusionContractsTableColumns = () => {
  const renderContent = (value: any, row: any) => {
    const obj: any = {
      children: value,
      props: {},
    };
    if (row.region) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns: ColumnsType<any> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      children: [
        {
          title: '1',
          dataIndex: 'id',
          align: 'center',
          width: 60,
          render: (text, row, index) => {
            if (!row.region) {
              return <span>{index + 1}</span>;
            }
          },
        },
      ],
    },
    {
      title: 'Наименование района',
      dataIndex: 'name',
      align: 'center',
      width: 100,
      children: [
        {
          title: '2',
          dataIndex: 'name',
          align: 'center',
          width: 60,
          render: renderContent,
        },
      ],
    },
    {
      title: 'Задолженность на начало года по воде',
      dataIndex: 'differencePaidLastYear',
      align: 'center',
      width: 100,
      children: [
        {
          title: 'тыс.сом',
          align: 'center',
          dataIndex: 'differencePaidLastYear',
          width: 60,
          children: [
            {
              title: '3',
              dataIndex: 'differencePaidLastYear',
              align: 'center',
              width: 60,
              colSpan: 1,
              render: renderContent,
            },
          ],
        },
      ],
    },
    {
      title: 'План заключение договоров на выбранный год',
      dataIndex: 'areaCultivated',
      align: 'center',
      width: 100,
      children: [
        {
          title: 'Площадь на которую составлен план в/п',
          dataIndex: 'areaCultivated',
          align: 'center',
          children: [
            {
              title: 'га',
              dataIndex: 'areaCultivated',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '4',
                  dataIndex: 'areaCultivated',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'Плановый объем хоздоговорной водоподачи',
          dataIndex: 'waterVolAnnualPlan',
          align: 'center',
          children: [
            {
              title: 'тыс.м3',
              dataIndex: 'waterVolAnnualPlan',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '5',
                  dataIndex: 'waterVolAnnualPlan',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'На сумму',
          dataIndex: 'amountAnnualPlan',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'amountAnnualPlan',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '6',
                  dataIndex: 'amountAnnualPlan',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
      ],
    },
    {
      title: 'Подписано к оплате на выбранный год',
      dataIndex: 'waterVolAnnualAppsCurrYear',
      align: 'center',
      width: 100,
      children: [
        {
          title: 'Подписанный объем хоздоговорной водоподачи',
          dataIndex: 'waterVolAnnualAppsCurrYear',
          align: 'center',
          children: [
            {
              title: 'тыс.м3',
              dataIndex: 'waterVolAnnualAppsCurrYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '7',
                  dataIndex: 'waterVolAnnualAppsCurrYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'На сумму',
          dataIndex: 'amountAnnualAppsCurrYear',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'amountAnnualAppsCurrYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '8',
                  dataIndex: 'amountAnnualAppsCurrYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
      ],
    },
    {
      title: 'Подано на выбранный год',
      dataIndex: 'waterPaymentsPendingCurrYear',
      align: 'center',
      width: 100,
      children: [
        {
          title: 'Объем хоздоговорной водоподачи',
          dataIndex: 'waterPaymentsPendingCurrYear',
          align: 'center',
          children: [
            {
              title: 'тыс.м3',
              dataIndex: 'waterPaymentsPendingCurrYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '9',
                  dataIndex: 'waterPaymentsPendingCurrYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'На сумму',
          dataIndex: 'amountPaymentsPendingCurrYear',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'amountPaymentsPendingCurrYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '10',
                  dataIndex: 'amountPaymentsPendingCurrYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'Поступило',
          dataIndex: 'amountPaymentsPaidCurrYear',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'amountPaymentsPaidCurrYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '11',
                  dataIndex: 'amountPaymentsPaidCurrYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'Задолженность',
          dataIndex: 'waterDebt',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'waterDebt',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '12',
                  dataIndex: 'waterDebt',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: '%',
          dataIndex: 'percentCompletionPlanWaterSupplyCurrYear',
          align: 'center',
          width: 90,
          children: [
            {
              title: '13',
              dataIndex: 'percentCompletionPlanWaterSupplyCurrYear',
              align: 'center',
              width: 60,
              colSpan: 1,
              render: renderContent,
            },
          ],
        },
      ],
    },
    {
      title: 'Подписано к оплате на предыдущий год',
      dataIndex: 'waterAnnualAppsLastYear',
      align: 'center',
      width: 100,
      children: [
        {
          title: 'Подписанный объем хоздоговорной водоподачи',
          dataIndex: 'waterAnnualAppsLastYear',
          align: 'center',
          children: [
            {
              title: 'тыс.м3',
              dataIndex: 'waterAnnualAppsLastYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '14',
                  dataIndex: 'waterAnnualAppsLastYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
        {
          title: 'На сумму',
          dataIndex: 'amountAnnualAppsLastYear',
          align: 'center',
          children: [
            {
              title: 'тыс.сом',
              dataIndex: 'amountAnnualAppsLastYear',
              align: 'center',
              width: 90,
              children: [
                {
                  title: '15',
                  dataIndex: 'amountAnnualAppsLastYear',
                  align: 'center',
                  width: 60,
                  colSpan: 1,
                  render: renderContent,
                },
              ],
            },
          ],
          width: 90,
        },
      ],
    },
  ];

  return { columns };
};
