import { useParams } from 'react-router-dom';

import { CustomCard } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';

import { RelatedObjectsTable } from '../../tables';
import { useSystemObjectControllerFindAllLinkedQuery } from '@store/api/systemObjectApi';

export const RelatedObjectsComponent: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const { data: linkedObjects } = useSystemObjectControllerFindAllLinkedQuery(
    id ? { id } : skipToken,
  );

  return (
    <>
      {linkedObjects?.length ? (
        linkedObjects?.map((linkedObject) => (
          <CustomCard
            key={linkedObject.title}
            title={linkedObject.title}
            style={{ marginBottom: 8 }}
          >
            <RelatedObjectsTable data={linkedObject.items} />
          </CustomCard>
        ))
      ) : (
        <RelatedObjectsTable data={[]} />
      )}
    </>
  );
};
