import { EntityTypesEnum } from '@enums/entityTypes';
import { PrimaryButton, TooltipedSelect } from '@features/ui-kit';
import { FilterButtonV2, ResetFilterButtonV2 } from '@features/ui-kit-v2';
import { useDictionaryZoneTableFilters } from '@features/waterQuality/tables/dictionaryZoneTable/useDictionaryZoneTableFilters';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { IWaterQualityFilters } from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetQualityMonitoringQuery, useGetReferencesByParentIdAndTypeQuery,
} from '@store/gisproApi';

import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from '../DictionaryZoneTable.module.scss';
import { useGetCurrentUser } from '@features/authentication';

export const DictionaryZoneTableFilters: React.FC = () => {
  const [filtersState, setFiltersState] = useState<IWaterQualityFilters>({});
  const { filters, setDictionaryTableFilters } = useDictionaryZoneTableFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  useEffect(() => {
    setFiltersState({
      ...filters,
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
    });
    setDictionaryTableFilters({
      ...filters,
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
    });
  }, [user]);
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: Number(filtersState.regionId),
        }
      : skipToken,
  );

  const { data: okmots = [] } = useGetReferencesByParentIdAndTypeQuery(
    filtersState.regionId && filtersState.districtId
      ? {
          typeId: EntityTypesEnum.Okmot,
          parentId: Number(filtersState.districtId),
        }
      : skipToken,
  );

  const { data: qualityMonitoring = [] } = useGetQualityMonitoringQuery();

  const onFilterChange = (filterKey: string, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      okmotId: null,
      regionId: v,
    }));
  };

  const onDistrictChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      okmotId: null,
      districtId: v,
    }));
  };

  const onResetFilterButtonClick = () => {
    const filters: IWaterQualityFilters = {
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      okmotId: null,
      type: null,
    };
    setFiltersState(filters);
    setDictionaryTableFilters(filters);
  };

  const onFilterButtonClick = () => {
    setDictionaryTableFilters(filtersState as IWaterQualityFilters);
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row align="middle" gutter={15} className={styles.tableFiltersRow}>
      <Col span={4}>
        <TooltipedSelect
          value={filtersState.regionId && +filtersState.regionId}
          onChange={(v: string) => onRegionChange(v)}
          allowClear
          placeholder="Область"
          options={regions}
          disabled={isDisabledRegion}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          placeholder="Район"
          value={filtersState.districtId && +filtersState.districtId}
          options={districts}
          disabled={isDisabledDistrict || !filtersState.regionId}
          onChange={(v: string) => onDistrictChange(v)}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          value={filtersState.okmotId}
          placeholder="Айыл окмоту"
          options={okmots}
          disabled={!filtersState.regionId || !filtersState.districtId}
          onChange={(v: string) => onFilterChange('okmotId', v)}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          value={filtersState.type}
          placeholder="Тип мониторинга"
          options={qualityMonitoring}
          onChange={(v: string) => onFilterChange('type', v)}
        />
      </Col>
      <Col span={2}>
        <Row gutter={10}>
          <Col>
            <PrimaryButton
              onClick={onFilterButtonClick}
              icon={<FilterIcon />}
            />
          </Col>

          <Col>
            <PrimaryButton
              onClick={onResetFilterButtonClick}
              icon={<ResetFilterIcon />}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
