import { Col, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import { useDocumentTableColumns } from './useDocumentTableColumns';
import {
  CustomCard,
  TableCustom,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { useMemo, useState } from 'react';
import { documentFormats } from '@constants/documentFormats';

type DocumentTableProps = {
  documents: Array<IAttachment>;
  deleteItem: (attachment: IAttachment) => void;
  onHandleWatch: (attachment: IAttachment) => void;
  showControls?: boolean;
};

export const DocumentTable: React.FC<DocumentTableProps> = ({
  documents,
  onHandleWatch,
  deleteItem,
  showControls = true,
}) => {
  const [name, setName] = useState('');
  const [format, setFormat] = useState();
  const [type, setType] = useState();

  const { data: documentTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.DocumentTypes,
  });
  const { columns } = useDocumentTableColumns();

  const attachmentIdsWithFilters = useMemo(() => {
    if (!type && !format && !name) {
      return documents;
    }
    const documentType = documentTypes?.find(
      (item) => item.id === type,
    );
    const formatType = documentFormats.find(
      (item) => item.value === format,
    );

    return documents.filter((item) => {
      const filter1 = name && item.originName.includes(name);
      const filter2 = item.type === documentType?.title;
      const filter3 = formatType?.fullFormat.includes(item?.extension);
      const answer: boolean[] = [];
      if (name) {
        answer.push(!!filter1);
      }
      if (documentType?.title) {
        answer.push(!!filter2);
      }
      if (formatType) {
        answer.push(!!filter3);
      }

      return answer.every((item) => item === true);
    });
  }, [
    type,
    format,
    documents,
    name,
  ]);

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>Таблица</Typography.Title>
          </Col>
          {showControls && (
            <Col>
              <Space size={8}>
                <TooltipedSearch
                  placeholder="Наименование"
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
                <TooltipedSelect
                  placeholder="Тип документа"
                  options={documentTypes}
                  onChange={(v) => setType(v)}
                  popupMatchSelectWidth={false}
                  allowClear
                />
                <TooltipedSelect
                  placeholder="Формат документа"
                  options={documentFormats}
                  onChange={(v) => setFormat(v)}
                  popupMatchSelectWidth={false}
                  allowClear
                />
              </Space>
            </Col>
          )}
        </Row>
      }
    >
      <TableCustom
        bordered
        columns={columns}
        dataSource={attachmentIdsWithFilters}
        pagination={false}
        scroll={{ y: 400 }}
        actionProps={{
          onHandleWatch,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
