import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useEmployeesColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№ / ID',
      dataIndex: 'id',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => <div>{record.id || '-'}</div>,
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => (
        <div>
          {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: 'Позиция',
      dataIndex: 'position', // TODO: employee position
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
  ];

  return { columns };
};
