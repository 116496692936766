import { IAttachment } from '@models/attachments/interfaces/attachment';
import { ITariffFilters } from '@models/tariff/interfaces/tariff';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TariffState = {
  tariffMonitoringTableFilters: Partial<ITariffFilters>;
  tariffTableFilters: Partial<ITariffFilters>;
  document: null | IAttachment;
};

const initialState: TariffState = {
  tariffMonitoringTableFilters: {
    isActive: true,
    // page: 1,
    // size: 10,
  },
  tariffTableFilters: {
    // page: 1,
    // size: 10,
  },
  document: null,
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    changeTariffMonitoringFilters: (
      state,
      action: PayloadAction<ITariffFilters>,
    ) => {
      return {
        ...state,
        tariffMonitoringTableFilters: action.payload,
      };
    },
    changeTariffFilters: (state, action: PayloadAction<ITariffFilters>) => {
      return {
        ...state,
        tariffTableFilters: action.payload,
      };
    },
    addDocumentToTariff: (state, action: PayloadAction<IAttachment>) => {
      return {
        ...state,
        document: action.payload,
      };
    },
    clearTariffDocument: (state) => {
      return {
        ...state,
        document: null,
      };
    },
  },
});

export const {
  changeTariffMonitoringFilters,
  changeTariffFilters,
  addDocumentToTariff,
  clearTariffDocument,
} = tariffSlice.actions;
export default tariffSlice.reducer;
