import { langOptions } from '@constants/langOptions';
import {
  CustomModal,
  DefaultButton,
  DocsList,
  Mask,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import {
  InstructionsCreateDto,
  useInstructionsControllerCreateMutation,
  useInstructionsControllerUpdateMutation,
} from '@store/api/instructionsApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';
import { Col, Form, Row, Space, Typography, notification } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface IInstrucitionModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const InstrucitionModal: FC<IInstrucitionModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const [createEntity, { isError }] = useInstructionsControllerCreateMutation();
  const [updateEntity, { isError: isErrorUpdate }] =
    useInstructionsControllerUpdateMutation();
  const [show, setShow] = useState(false);

  const [params] = useSearchParams();

  const filtersParams = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const attachmentDtos = Form.useWatch('attachments', form) || [];

  const onAttachmentUpload = (file: any) => {
    const currentAttachments = form.getFieldValue('attachments') || [];
    const updatedAttachments = [...currentAttachments, file];
    form.setFieldValue('attachments', updatedAttachments);
  };

  const onFinish = (values: InstructionsCreateDto) => {
    const attachmentsId = attachmentDtos?.reduce((acc: number[], item: any) => {
      acc.push(item.attachmentId || item.id);
      return acc;
    }, []);
    if (initialValues) {
      updateEntity({
        id: initialValues.id,
        instructionsUpdateDto: { ...values, attachments: attachmentsId || [] },
      })
        .then(() => {
          setShowModal(false);
          notification.success({
            message: 'Инструкция успешно обновлена',
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Произошла ошибка',
          });
        });
      return;
    }
    createEntity({
      instructionsCreateDto: { ...values, attachments: attachmentsId || [] },
    })
      .then(() => {
        setShowModal(false);
        notification.success({
          message: 'Инструкция успешно создана',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Произошла ошибка',
        });
      });
  };

  const setModal = (state: boolean) => {
    if (state) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  return (
    <CustomModal
      open={isModalShow}
      title={initialValues?.id ? 'Изменить' : 'Создать'}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Язык"
              initialValue={filtersParams.lang}
              name="lang"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                placeholder="Выберите язык"
                options={langOptions}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24}>
          <Form.Item label="Название" name="title" rules={[{ required: true }]}>
            <TooltipedInput placeholder="Введите название" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="end" gutter={[0, 16]}>
            <Col span={24}>
              <Typography.Title level={4}>
                Прикрепленные документы:
              </Typography.Title>
              {attachmentDtos?.length ? (
                <DocsList documents={attachmentDtos} />
              ) : (
                <Mask maskText="Нет прикрепленных документов" />
              )}
            </Col>

            <Col>
              <Form.Item
                name="attachments"
                valuePropName="file"
                getValueFromEvent={normalizeFile}
                rules={[{ required: true, message: 'Прикрепите документ' }]}
              >
                <DefaultButton
                  type="default"
                  onClick={() => {
                    setShow(true);
                  }}
                  icon={<UploadFileIcon />}
                >
                  Прикрепить документ*
                </DefaultButton>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>
        <UploadModal
          setShow={setModal}
          show={show}
          onLoad={onAttachmentUpload}
        />
        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
