import { useTranslation } from 'react-i18next';

import { months, monthsTranslate } from '@constants/month';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWaterAndHectareSupplyColumns = () => {
  const { t } = useTranslation();
  const generateColumns = () => {
    const resColumns: TableColumn[] = [];
    for (let i = 0; i < months.length; i += 1) {
      resColumns.push({
        title: `${months[i]}`,
        dataIndex: 'months',
        align: 'left',
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'I',
            dataIndex: `months.${monthsTranslate[months[i]]}.decade1`,
            xtype: CustomTableColumnType.String,
            align: 'left',
          },
          {
            title: 'II',
            dataIndex: `months.${monthsTranslate[months[i]]}.decade2`,
            xtype: CustomTableColumnType.String,
            align: 'left',
          },
          {
            title: 'III',
            dataIndex: `months.${monthsTranslate[months[i]]}.decade3`,
            xtype: CustomTableColumnType.String,
            align: 'left',
          },
        ],
      });
    }

    return resColumns;
  };

  const columns: TableColumn[] = [
    {
      title: 'Параметр',
      dataIndex: 'parameter',
      align: 'left',
      fixed: 'left',
      width: 250,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => {
        return (
          <div>
            {t(`annualApplication.waterSupplyPlan.${record.parameter}`)}
          </div>
        );
      },
    },
    ...generateColumns(),
  ];
  return { columns };
};
