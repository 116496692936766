import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { documentFormats } from '@constants/documentFormats';
import { DocumentTypes } from '@enums/documentTypes';
import {
  CustomModalV2,
  DefaultButtonV2,
  PrimaryButtonV2,
  TooltipedSquaredInputV2,
  TooltipedTextareaV2,
  UploadDraggerV2,
} from '@features/ui-kit-v2';

import { useAttachDocumentModal } from '../useAttachDocumentModal';
import styles from './modals.module.scss';
import {
  ColorBlock,
  CustomModal,
  DefaultButton,
  ManageEventsPath,
  PrimaryButton,
  TooltipedSquaredInput,
  TooltipedTextarea,
} from '@features/ui-kit';
import { useLocation } from 'react-router-dom';

type AttachDocumentModalPropTypes = {
  onLoad: (file: any) => void;
};

export const AttachDocumentModal: FC<AttachDocumentModalPropTypes> = ({
  onLoad,
}) => {
  const { isShow, setShow, setDocType, docType } = useAttachDocumentModal();
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = useForm();
  const elementColor = ManageEventsPath(location.pathname);
  const onClose = () => {
    form.resetFields();
    setDocType('');
    setShow(false);
  };

  const attachmentLoad = useCallback(
    (file: any) => {
      const description = form.getFieldValue('description');
      const document = { ...file, description };
      onLoad(document);
      setDocType('');
      setShow(false);
      form.resetFields();
    },
    [onLoad, setDocType, setShow],
  );

  const originName = Form.useWatch('originName', form);
  const description = Form.useWatch('description', form);

  const photoFormats = documentFormats
    .filter((format) => format.key === 'photo')
    .map((item) => item.value)
    .join(',');

  const docFormats = documentFormats
    .filter((format) => format.key === 'docs')
    .map((item) => item.value)
    .join(',');

  const currentAccept =
    docType === DocumentTypes.PhotoBefore ||
      docType === DocumentTypes.Photo ||
      docType === DocumentTypes.PhotoAfter
      ? photoFormats
      : docFormats;

  return (
    <CustomModal
      centered
      title="Прикрепление документов"
      open={isShow}
      onCancel={onClose}
      width={650}
      footer={
        <Row gutter={30} justify="end">
          <Col span={7}>
            <DefaultButton onClick={onClose}>
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DefaultButton>
          </Col>
        </Row>
      }
    >
      <ColorBlock colorType={elementColor}>
        <Form form={form}>
          <Form.Item name="attachFile">
            <UploadDraggerV2
              onLoad={attachmentLoad}
              docType={docType}
              accept={currentAccept}
              docFormat={currentAccept}
              disabled={!originName}
            />
          </Form.Item>
          <div className={styles.fileNameInp}>
            <Form.Item name="originName" label="Наименование" required>
              <TooltipedSquaredInput placeholder="Наименование" />
            </Form.Item>

            <Form.Item name="description" label="Описание">
              <TooltipedTextarea
                required={false}
                placeholder="Описание задачи"
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
          </div>
        </Form>
      </ColorBlock>
    </CustomModal>
  );
};
