import { useEffect } from 'react';

import { Col, Row, Spin } from 'antd';

import { CustomModalV2, PrimaryButtonV2 } from '@features/ui-kit-v2';
import {
  useGetAccountingBillQuery,
  usePayBillMutation,
} from '@store/gisproApi';

import styles from './PreviewCheckModal.module.scss';
import { usePreviewCheckModal } from './usePreviewCheckModal';

export const PreviewCheckModal = () => {
  const { isModalShown, setShowModal, selectedActsInvoicesForPayment } =
    usePreviewCheckModal();
  const [save] = usePayBillMutation();

  const { data, error, isFetching } = useGetAccountingBillQuery(
    selectedActsInvoicesForPayment,
  );

  const onConfirmCheck = () => {
    if (selectedActsInvoicesForPayment) {
      save({ id: selectedActsInvoicesForPayment.id });
      setShowModal(false);
    }
  };

  const onPrint = () => {
    // const printContents =
    //   document.querySelector('.printable')?.innerHTML || 'Нет данных';
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  };

  return (
    <CustomModalV2
      centered
      open={isModalShown}
      onCancel={() => setShowModal(false)}
      width={880}
      title="Счет"
      destroyOnClose
      footer={false}
    >
      <Spin spinning={isFetching}>
        <Row className={styles.marginTop}>
          <Col
            span={24}
            style={{ height: '50vh', overflow: 'auto', padding: '0 20px' }}
          >
            <div
              className="printable"
              dangerouslySetInnerHTML={{
                __html: data || (error as { data: string })?.data,
              }}
            />
          </Col>
        </Row>
        <Row justify="end" gutter={20} className={styles.marginTop}>
          <Col>
            <PrimaryButtonV2 onClick={onPrint} title="Распечатать" />
          </Col>
          <Col>
            <PrimaryButtonV2
              disabled={
                selectedActsInvoicesForPayment?.status !== 'BILL_CREATED'
              }
              onClick={() => onConfirmCheck()}
              title="Подтвердить Счет"
            />
          </Col>
        </Row>
      </Spin>
    </CustomModalV2>
  );
};
