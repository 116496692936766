import { langOptions, langsEnum } from '@constants/langOptions';
import { CustomModal, DefaultButton, PrimaryButton, Segmented, TooltipedInput, } from '@features/ui-kit';
import { Editor } from '@features/ui-kit/editor/Editor';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useMaterialsControllerCreateMutation,
  useMaterialsControllerGetMaterialQuery,
  useMaterialsControllerUpdateMutation,
} from '@store/api/materialsApi';
import { Col, Form, notification, Row, Typography } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IReferenceMaterialsTableModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  code: string | null;
}

const valuesStateDefault = {
  ru: { title: '', content: '', lang: langsEnum.RU },
  // en: { title: '', content: '', lang: langsEnum.EN },
  kg: { title: '', content: '', lang: langsEnum.KG },
};

export const ReferenceMaterialsTableModal: FC<
  IReferenceMaterialsTableModalProps
> = ({ setShowModal, isModalShow, code }) => {
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const [params, setSearchParams] = useSearchParams();
  const [segmentedValue, setSegmentedValue] = useState<langsEnum>(langsEnum.RU);
  const [values, setValues] = useState<any>(valuesStateDefault);
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const { data: initialValues } = useMaterialsControllerGetMaterialQuery(
    code ? { code } : skipToken,
  );

  const [createMaterials, { isError }] = useMaterialsControllerCreateMutation();
  const [updateMaterials, { isError: isErrorUpdate }] =
    useMaterialsControllerUpdateMutation();

  useEffect(() => {
    if (initialValues) {
      initialValues?.forEach((item) => {
        setValues((prev: any) => ({
          ...prev,
          [item.lang]: {
            title: item.title,
            content: item.content,
            lang: item.lang,
          },
        }));
      });
    }
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values]);

  const onChangeInput = (value: string, field: string) => {
    setValues({
      ...values,
      [segmentedValue]: {
        ...values[segmentedValue],
        [field]: value,
        lang: segmentedValue,
      },
    });
  };

  const changeLangHandle = (value: langsEnum) => {
    setSegmentedValue(value);
  };

  const onFinish = () => {
    if (!values.ru.title || !values.ru.content) {
      changeLangHandle(langsEnum.RU);
      return;
    }
    const result = Object.keys(values).map((key) => ({
      ...values[key],
      category: filtersParams.categoryId,
    }));
    if (code) {
      updateMaterials({ code, body: result })
        .then(() => {
          setShowModal(false);
          notification.success({
            message: 'Справочный материал успешно обновлен',
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Произошла ошибка',
          });
        });
      return;
    }
    createMaterials({
      body: result,
    })
      .then(() => {
        setShowModal(false);
        notification.success({
          message: 'Справочный материал успешно создан',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Произошла ошибка',
        });
      });
  };

  const langOptionsWithRequired = langOptions.map((item) => {
    if (item.value === langsEnum.RU) {
      return { ...item, label: `${item.label}*` };
    }
    return item;
  });

  return (
    <CustomModal
      open={isModalShow}
      title={initialValues ? 'Изменить' : 'Создать'}
      footer={
        <Row gutter={8} justify="end">
          <Col span={4}>
            <DefaultButton onClick={onCancelButtonClick}>Отмена</DefaultButton>
          </Col>
          <Col span={4}>
            <PrimaryButton onClick={form.submit}>Сохранить</PrimaryButton>
          </Col>
        </Row>
      }
      onCancel={() => setShowModal(false)}
      destroyOnClose
      width={800}
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Segmented
          options={langOptionsWithRequired}
          onChange={(value) => changeLangHandle(value as langsEnum)}
          block
          value={segmentedValue}
          defaultValue={
            Object.fromEntries(params.entries()).lang || langsEnum.RU
          }
        />
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Название"
              name={[segmentedValue, 'title']}
              rules={[{ required: true }]}
            >
              <TooltipedInput
                onChange={(e) => onChangeInput(e.target.value, 'title')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Контент"
              name={[segmentedValue, 'content']}
              rules={[{ required: true }]}
            >
              <Editor
                onChange={(e) => onChangeInput(e, 'content')}
                value={values[segmentedValue].content}
              />
            </Form.Item>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
