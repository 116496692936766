export enum WaterUserTypes {
  physical = 'physical',
  legal = 'legal',
  association = 'association',
  all = 'all',
}

export enum WaterUserTypesUp {
  physical = 'INDIVIDUAL',
  legal = 'LEGAL',
  association = 'ASSOCIATION',
  all = 'all',
}

export enum WaterUserEnum {
  INDIVIDUAL = 0,
  LEGAL = 1,
  ASSOCIATION = 2,
  ALL = 3,
}

export enum MainWaterUserTypes {
  Physical = 'physical',
  Legal = 'legal',
  Association = 'association',
}

export const WaterUserQueryKey = 'user';

export enum WaterUserCardStepperQueryTypes {
  totalInformation = 'totalInformation',
  employees = 'employees',
  economies = 'economies',
  document = 'document',
}

export enum CreateLegalWaterRegistryQueryTypes {
  waterUserInfo = 'waterUserInfo',
  responsiblePersons = 'responsiblePersons',
  create = 'create',
  agreement = 'agreement',
}

export enum CreateIndividualWaterRegistryQueryTypes {
  waterUserInfo = 'waterUserInfo',
  create = 'create',
  agreement = 'agreement',
}

export enum CreateAssociationWaterRegistryQueryTypes {
  waterUserInfo = 'waterUserInfo',
  addressInfo = 'addressInfo',
  outletConnection = 'outletConnection',
  responsiblePersons = 'responsiblePersons',
  agreement = 'agreement',
}

export const WaterUserCardStepperQueryKey = 'step';

export enum WaterUserGender {
  M = 'Мужчина',
  F = 'Женщина',
}

export enum AssociationPzTable {
  YES = 'YES',
  NO = 'NO',
  COMMON = 'COMMON',
  NOT_WORKING = 'NOT_WORKING',
}

export enum EmployeePosition {
  Director = 'Руководитель',
  Accountant = 'Бухгалтер',
}

export enum EmployeeTypesEnum {
  Employee = 'employee',
  Accountant = 'accountant',
  Engineer = 'engineer',
  WaterSpecialist = 'waterSpecialist',
}
