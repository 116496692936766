import { useCallback, useEffect } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Row, TablePaginationConfig, Typography } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import {
  IWaterObject,
  IWaterObjectFilters,
} from '@models/waterObject/interfaces/waterObject';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllIrrigationSystemObjectsListWithFiltersQuery } from '@store/gisproApi';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { isEmpty } from '@utils/utils';

import { useWaterObjectFilters } from './useWaterObjectFitlers';
import { useWaterObjectTableColumns } from './useWaterObjectTableColumns';
import { WaterObjectTableFilters } from './waterObjectTableFilters';
import { saveUrl } from '@utils/returnUrl';

interface IWaterObjectTableProps {
  isArchived?: boolean;
}

export const WaterObjectTable: React.FC<IWaterObjectTableProps> = ({
  isArchived = false,
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { filters, setWaterObjectFilters } = useWaterObjectFilters();
  const { columns } = useWaterObjectTableColumns(isArchived);

  const saveAction = saveUrl();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useFindAllIrrigationSystemObjectsListWithFiltersQuery(
    !isEmpty(filters) ? { ...filters, isArchived } : skipToken,
  );

  const onCreateButtonClick = () => {
    navigate(Routes.WaterObjectCreate);
    saveAction();
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setWaterObjectFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
          } as unknown as IWaterObjectFilters)
        : (newFilters as unknown as IWaterObjectFilters),
    );
  }, []);

  const isCanCreateIrrigationSystemObject = useHasUserPermission(
    ACCESS.IRRIGATION_SYSTEM_OBJECTS_CREATE,
  );

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterObjectFilters({
          ...(filters as IWaterObjectFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onHandleWatch = ({ objectId }: IWaterObject) => {
    navigate(
      generatePath(Routes.WaterObjectCard, {
        id: String(objectId),
      }),
    );
    saveAction();
  };

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.objectId);
    setShowModal(true);
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.objectId);
    setShowModal(true);
  };

  const onHandleGis = (record: JsonRecord) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:irrigation-system:${
        record.systemId ? record?.systemId : null
      }`,
    });

    window.open(url, '_blank');
  };

  return (
    <>
      <WaterObjectTableFilters
        isCanCreateIrrigationSystemObject={isCanCreateIrrigationSystemObject}
        onCreateButtonClick={onCreateButtonClick}
        isArchived={isArchived}
      />
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col>
                <Typography.Title level={4}>Таблица</Typography.Title>
              </Col>
            </Row>
          }
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={content}
            columns={columns}
            bordered
            size="small"
            rowKey="objectId"
            onChange={onTableChange}
            totalCount={totalElements}
            actionProps={{
              onHandleWatch,
              onArchiveClick,
              onHandleGis,
              onUnArchiveClick,
            }}
            customPagination={rest}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
