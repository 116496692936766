import { Divider } from 'antd';

import styles from './WaterUsePlanCA.module.scss';
import { WaterUsePlanCAControlBar } from './waterUsePlanCAControlBar/WaterUsePlanCAControlBar';
import { WaterUsePlanCAViewTable } from './waterUsePlanCAViewTable';

export const WaterUsePlanCAView: React.FC = () => {
  return (
    <>
      <WaterUsePlanCAControlBar />
      <Divider className={styles.divider} />
      <WaterUsePlanCAViewTable />
    </>
  );
};
