import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Col,
  Divider,
  Empty,
  Form,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { useAttachDocumentWithoutNotationModal } from '@features/manageEvents/useAttachDocumentWithoutNotationModal';
import {
  BudgetItem,
  CustomCard,
  CustomCarousel,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedTextarea,
  UploadBtn,
  UploadButtonSmall,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateCloseReportOperationalBlockMutation,
  useFindOperationalBlockByIdQuery,
  useSaveClosingReportOperationalBlockMutation,
} from '@store/gisproApi';
import { getOperationalBlockSelector } from '@store/selectors';
import { addDocumentToOperationalBlockAction } from '@store/slices';
import {
  validateFactEndDate,
  validateFactStartDate,
} from '@utils/validation/deadlineValidation';
import dayjs from 'dayjs';
import { isInteger, toInteger } from '@utils/utils';
import clone from 'lodash/clone'; // TODO: change lodash to native implementation

import styles from './OperationalCard.module.scss';

export const CloseOperationalReportCard: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [photoId, setPhotoId] = useState<number>(1);
  const { setShow: setAttachmentDocumentShow, setDocType } =
    useAttachDocumentWithoutNotationModal();
  const operationalBlockState = useSelector(getOperationalBlockSelector);
  const [closeOperationalBlockReport] =
    useCreateCloseReportOperationalBlockMutation();
  const { data: operationalBlock, refetch } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );
  const [saveOperationalBlockCloseReport] =
    useSaveClosingReportOperationalBlockMutation();

  const closeReportHandle = () => {
    if (operationalBlock?.id) {
      closeOperationalBlockReport(operationalBlock.id)
        .unwrap()
        .then(() => {
          refetch();
          notification.success({
            message: 'Вы закрыли отчет',
          });
        })
        .catch((err) => {
          console.log('err is ', err);
          notification.error({
            message: 'Не удалось закрыть отчет',
          });
        });
    }
  };
  const docAttachments = operationalBlockState?.attachments?.filter(
    (item) =>
      item.type === DocumentTypes.CloseReport ||
      item.type === DocumentTypes.Act,
  );
  const onFinish = (values: any) => {
    saveOperationalBlockCloseReport({
      ...values,
      id: operationalBlock?.id,
      closingReportObjectDesc: undefined,
      attachments: docAttachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      factBudget: toInteger(values.factBudget),
      factStartDate: dayjs(values.factStartDate).utc().format('YYYY-MM-DD'),
      factEndDate: dayjs(values.factEndDate).utc().format('YYYY-MM-DD'),
    })
      .unwrap()
      .then(() => {
        refetch();
        notification.success({
          message: 'Вы успешно сохранили закрытие отчета',
        });
      })
      .catch((err) => {
        console.log('err is ', err);
        notification.error({
          message: 'Не удалось сохранить закрытие отчета',
        });
      });
  };

  const validateAttachments = useMemo(() => {
    const hasAct = (
      operationalBlockState?.attachments || operationalBlock?.attachments
    )?.some((att) => att.type === DocumentTypes.Act);
    return hasAct;
  }, [operationalBlockState, operationalBlock]);

  const isSaveDisabled = useMemo(
    () =>
      operationalBlock?.status !== TaskStatusEnum.onRealization &&
      operationalBlock?.status !== TaskStatusEnum.onControl,
    [operationalBlock],
  );

  const isEditingDisabled = useMemo(
    () => operationalBlock?.status !== TaskStatusEnum.onControl,
    [operationalBlock],
  );

  const onAddAttachment = useCallback((docType: string) => {
    setDocType(docType);
    setAttachmentDocumentShow(true);
  }, []);

  useEffect(() => {
    if (operationalBlock) {
      const dataClone = clone(operationalBlock);
      if (dataClone.factStartDate) {
        dataClone.factStartDate = dayjs(dataClone.factStartDate);
      }
      if (dataClone.factEndDate) {
        dataClone.factEndDate = dayjs(dataClone.factEndDate);
      }
      form.setFieldsValue(dataClone);
    }
  }, [operationalBlock]);

  const closingReportDate = dayjs
    .utc(operationalBlock?.closingReportDate)
    .local()
    .format('HH:mm DD.MM.YYYY');

  const attachPhoto = (file: IAttachment) => {
    dispatch(addDocumentToOperationalBlockAction(file));
  };
  const actionsPhoto = operationalBlockState?.attachments
    ?.filter((item) => item.type === 'фото-после')
    .map((item) => item.filePath);

  const isDisabledCloseReport = useMemo(() => {
    if (operationalBlock?.status === TaskStatusEnum.onControl) {
      return !operationalBlock?.closingReportDate;
    }
    return operationalBlock?.status !== TaskStatusEnum.onControl;
  }, [operationalBlock]);

  return (
    <CustomCard>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className={styles.reportBlock}
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4}>Закрытие отчета</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton
                  disabled={isSaveDisabled || !validateAttachments}
                  htmlType="submit"
                >
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  onClick={closeReportHandle}
                  disabled={isDisabledCloseReport}
                >
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item
          name="closingReportDescription"
          label="Краткое описание"
          required
          rules={[{ required: true }]}
        >
          <TooltipedTextarea
            className=""
            placeholder="Краткое описание"
            autoSize={{ minRows: 4, maxRows: 10 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <Form.Item name="factBudget" required rules={[{ required: true }]}>
          <BudgetItem label="Итого затрачено*" disabled={isEditingDisabled} />
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.Act)}
              disabled={isEditingDisabled}
            >
              Прикрепить акт выполненных работ*
            </UploadButtonSmall>
          </Col>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.PhotoAfter)}
              disabled={isEditingDisabled}
            >
              Прикрепить Фото (После)
            </UploadButtonSmall>
          </Col>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.Document)}
              disabled={isEditingDisabled}
            >
              Прикрепить ведомость дефекта*
            </UploadButtonSmall>
          </Col>
        </Row>
        <Col span={12}>
          <Form.Item required />
        </Col>
        <Space direction="vertical" size={20} className={styles.generalStep}>
          <Typography.Text className={styles.title}>
            Заявленные фактические сроки реализации
          </Typography.Text>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validateFactStartDate({
                        _,
                        value,
                        form,
                        type: 'factEndDate',
                      }),
                  },
                ]}
                name="factStartDate"
                required
                label="Начало"
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Начало"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validateFactEndDate({
                        _,
                        value,
                        form,
                        type: 'factStartDate',
                      }),
                  },
                ]}
                name="factEndDate"
                required
                label="Конец"
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Конец"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Row />
          </Row>
        </Space>
        <Form.Item
          name="closingReportObjectDesc"
          label="Блок корректировки данных объектов"
          required
          rules={[{ required: true }]}
        >
          <TooltipedTextarea
            className=""
            placeholder="Блок корректировки данных объектов"
            autoSize={{ minRows: 4, maxRows: 10 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <div className={styles.dueDateBlock}>
          Дата сдачи отчета :
          <Divider className={styles.dueDateBlock__divider} />
          <div className={styles.dueDateBlock__status}>
            {operationalBlock?.closingReportDate
              ? ` ${closingReportDate}`
              : ' Еще не закрыли отчет'}
          </div>
        </div>
      </Form>
    </CustomCard>
  );
};
