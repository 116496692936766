import CountUp from 'react-countup';

import { Statistic as AntdStatistic, StatisticProps } from 'antd';

import styles from './Statistic.module.scss';

type StatisticPropsTypes = StatisticProps & {
  animated?: boolean;
};

const formatter = (value: string) => (
  <CountUp end={parseInt(value, 10)} preserveValue />
);

export const Statistic: React.FC<StatisticPropsTypes> = ({
  animated = false,
  ...props
}) => {
  return (
    <AntdStatistic
      {...props}
      className={styles.statistic}
      formatter={animated ? (formatter as any) : null}
    />
  );
};
