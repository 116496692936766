import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  CustomModalV2,
  PrimaryButtonV2,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { DeleteIcon } from '@icons/DeleteIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdAndTypeQuery,
  useGetReferencesByParentIdQuery,
  useLazyFindBankByIdQuery,
} from '@store/gisproApi';

import { useAddBranch } from '../../useAddBranch';
import styles from './AddBranchModal.module.scss';
import { useGetCurrentUser } from '@features/authentication';

type AddBranchType = {
  bankName: string;
  parentBankName: string;
  region: string;
  district: string;
  city: string;
  bankAddress: string;
  bankBik: string;
};

type PropsType = {
  onClose: () => void;
};

export const AddBranchModal: React.FC<PropsType> = ({ onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [regionId, setRegionId] = useState<string>('');
  const [districtId, setDistrictId] = useState<string>('');
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });

  const { data: districts = [], isLoading: districtIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.District });

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    regionId || skipToken,
  );

  const { data: cityByParentId = [] } = useGetReferencesByParentIdAndTypeQuery(
    districtId
      ? {
          parentId: districtId,
          typeId: EntityTypesEnum.CitiesVillages,
        }
      : skipToken,
  );

  const { addBranch, isModalShown, selectedBankId } = useAddBranch();
  const [trigger, { data }] = useLazyFindBankByIdQuery();

  const findDisctrictByParentId = (value: string) => {
    setRegionId(value);
  };

  const findCityByParentId = (value: string) => {
    setDistrictId(value);
  };

  const onAddBranchClick = useCallback(() => {
    try {
      const values = form.getFieldsValue();
      const { parentBankName, ...restValues } = values;
      addBranch(restValues);
      onClose();
      form.resetFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }, [form, selectedBankId]);

  useEffect(() => {
    if (data) {
      form.setFieldValue('parentBankName', data.bankName);
    }
  }, [data]);

  useEffect(() => {
    if (selectedBankId) {
      trigger({ id: selectedBankId });
    }
  }, [selectedBankId]);

  return (
    <CustomModal
      centered
      title={t('addBranch.title')}
      open={isModalShown}
      onCancel={onClose}
      width={370}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      footer={false}
    >
      <Form<AddBranchType>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onAddBranchClick}
      >
        <Row justify="center">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={t('addBranch.placeholders.bankName')}
                  name="parentBankName"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput
                    size="large"
                    value={data?.bankName}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('addBranch.placeholders.branchName')}
                  name="bankName"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="regionId"
                  label={t('addBranch.placeholders.region')}
                  initialValue={user?.owner?.region?.id}
                >
                  <TooltipedSelect
                    size="large"
                    loading={regionIsLoading}
                    options={regions.map((item) => ({
                      ...item,
                      label: item.title,
                      value: item.id,
                    }))}
                    onChange={findDisctrictByParentId}
                    disabled={isDisabledRegion}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="districtId"
                  label={t('addBranch.placeholders.district')}
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.district?.id}
                >
                  <TooltipedSelect
                    size="large"
                    loading={districtIsLoading}
                    onChange={findCityByParentId}
                    options={(districtByParentId.length
                      ? districtByParentId
                      : districts
                    ).map((item: any) => ({
                      ...item,
                      label: item.title,
                      value: item.id,
                    }))}
                    disabled={isDisabledDistrict || !regionId}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="bankCity"
                  label={t('addBranch.placeholders.city')}
                >
                  <TooltipedSelect
                    size="large"
                    options={cityByParentId.map((item: any) => ({
                      ...item,
                      label: item.title,
                      value: item.title,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('addBranch.placeholders.bankAddress')}
                  rules={[{ required: true }]}
                  name="bankAddress"
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label={t('addBranch.placeholders.bankBik')}
                  name="bankBik"
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              {t('addBranch.buttons.save')}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
