import { Layout } from 'antd';

import { AgroClimaticZoneTable } from '@features/agroClimaticZone';

export const AgroClimaticZoneTableLayout = () => {
  return (
    <Layout>
      <AgroClimaticZoneTable />
    </Layout>
  );
};
