import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Col, Divider, Row, Space, Typography } from 'antd';

import styles from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementContractDetails/AnnualApplicationAgreementContractDetails.module.scss';
import {
  InformationalBlock,
  PrimaryButton,
  TooltipedDatePicker,
} from '@features/ui-kit';
import {
  CustomCard,
  PrimaryButtonV2,
  PurpleAddButton,
  SectionTitle,
} from '@features/ui-kit-v2';
import { getContractFile } from '@features/waterRegistry/masterRegistration/fields/GetContractFile';
import { PrintIcon } from '@icons/PrintIcon';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetContractPdfMutation,
  useGetWaterUserAgreementQuery,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { get, isEmpty } from '@utils/utils';

const getFio = (contractFile: any) => {
  const fio = [
    get(contractFile, ['employees', '0', 'lastName']),
    get(contractFile, ['employees', '0', 'firstName']),
    get(contractFile, ['employees', '0', 'middleName']),
  ];
  return fio.filter(Boolean).join(' ');
};

export const ContractDetails: React.FC = () => {
  const { t } = useTranslation();
  const type = `${useLocation().pathname.split('/').pop() || ''}`;
  const currentWaterRegistry = useSelector(getFormData);

  const contractFile = getContractFile(currentWaterRegistry[type]);
  const waterUserName = getFio(currentWaterRegistry[type]);

  const { data: contract, error: contractError } =
    useGetWaterUserAgreementQuery(
      !isEmpty(contractFile)
        ? {
            waterUserName,
          }
        : skipToken,
    );

  return (
    <Row style={{ height: '100%' }} gutter={[10, 10]}>
      <Col span={24}>
        <InformationalBlock title="Соглашение">
          <CustomCard
            title={
              <Row gutter={8} justify="space-between" align="middle">
                <Col span={22}>
                  <Typography.Title level={4}>{waterUserName}</Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col span={2}>
                  <Row justify="space-between" gutter={8}>
                    <Col>
                      <PrimaryButton
                        icon={<PrintIcon />}
                        onClick={() => window.print()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          >
            <Col span={24}>
              <Row justify="center">
                <Col
                  span={24}
                  style={{
                    height: '63vh',
                    overflow: 'auto',
                    padding: '0 20px',
                  }}
                >
                  <div
                    className="printable"
                    dangerouslySetInnerHTML={{
                      __html:
                        contract || (contractError as { data: string })?.data,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </CustomCard>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
