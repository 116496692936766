import { Col, Row, Switch, Typography } from 'antd';

import { Links } from '@enums/links';
import { useGetCurrentUser } from '@features/authentication';
import { BellIcon } from '@icons/BellIcon';
import { BookIcon } from '@icons/BookIcon';

import styles from './Header.module.scss';
import { useHeaderTitle } from './useHeaderTitle';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Routes } from "@enums/routes";
import { HeaderPath } from '@features/ui-kit';

export const Header: React.FC = () => {
  const { headerTitle } = useHeaderTitle();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { user } = useGetCurrentUser();
  const name =
    user?.waterUser?.legalData?.name || user?.owner?.name || user?.username;

  const headerClass = HeaderPath(params.get('tab'));
  const navigateToProfile = () => {
    navigate(Routes.Profile)
  }
  return (
    <Row className={headerClass} justify="space-between" align="middle">
      <Col>
        <Typography.Title
          level={4}
          className={styles.headerLabel}
          style={{ marginBottom: 0 }}
        >
          {headerTitle}
        </Typography.Title>
      </Col>
      <Col>
        <Row align="middle" gutter={24}>
          <Col>
            <div className={styles.actions}>
              <Switch
                unCheckedChildren="RU"
                checkedChildren="KG"
                defaultChecked
                className={styles.languageBtn}
              />
              <Row style={{ gap: 15 }} align="middle">
                <button type="button" style={{ display: 'flex' }}>
                  <BookIcon />
                </button>
                <button type="button" style={{ display: 'flex' }}>
                  <BellIcon />
                </button>
              </Row>
            </div>
          </Col>
          <Col>
            <Row onClick={navigateToProfile} align="middle" className={styles.userBlock}>
              <div className={styles.avatarWrapper}>
                <img src={Links.userLogo} alt="userLogo" />
              </div>
              <Typography.Text className={styles.name}>{name}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
