import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { get } from '@utils/utils';

export function renderDecadeCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { decadeIndex, dataIndex } = customColumn;
  const { indicatorType } = record;

  if (indicatorType) {
    const indicatorValue = get(
      record,
      [...(decadeIndex || []), indicatorType],
      '-',
    );
    return <div>{indicatorValue}</div>;
  }

  const value = get(record, [...(decadeIndex || []), dataIndex], '-');
  return <div>{formatDecimal(value)}</div>;
}
