import { FC } from 'react';

import { Col, Form, Row, Space } from 'antd';

import { AppstoreFilled } from '@ant-design/icons';
import { PrimaryButton, TooltipedSelect } from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import dayjs from 'dayjs';
import { useGetCurrentUser } from '@features/authentication';

interface ISpprFiltersProps {
  openModal(): void;
  year?: boolean;
  district?: boolean;
  area?: boolean;
  republic?: boolean;
  reservoirs?: boolean;
  irrigationSystem?: boolean;
}

export const SpprFilters: FC<ISpprFiltersProps> = ({
  openModal,
  year,
  district,
  area,
  republic,
  reservoirs,
  irrigationSystem,
}) => {
  const [form] = Form.useForm();
  const regionId = Form.useWatch('area', form);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const onFilterButtonClick = () => {
    form.submit();
  };

  const onResetFilterButtonClick = () => {
    form.resetFields();
  };

  const showFilterButtons =
    year || area || district || republic || reservoirs || irrigationSystem;

  return (
    <Form form={form}>
      <Row justify="space-between" gutter={[0, 8]}>
        <Col>
          <Space>
            <PrimaryButton icon={<AppstoreFilled />} onClick={openModal} />
            {republic && <PrimaryButton>Республика</PrimaryButton>}
            {year && (
              <Form.Item
                name="year"
                valuePropName="year"
                style={{ marginBottom: 0 }}
                normalize={(v) => v.year()}
              >
                <YearPicker
                  placeholder="Год"
                  allowClear
                  defaultValue={dayjs()}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf('year')
                  }
                />
              </Form.Item>
            )}
            {area && (
              <Form.Item
                style={{ marginBottom: 0 }}
                initialValue={user?.owner?.region?.id}
                name="area"
              >
                <TooltipedSelect
                  placeholder="Область"
                  disabled={isDisabledRegion}
                />
              </Form.Item>
            )}
            {district && (
              <Form.Item
                name="district"
                initialValue={user?.owner?.district?.id}
                style={{ marginBottom: 0 }}
              >
                <TooltipedSelect
                  disabled={isDisabledDistrict || !regionId}
                  placeholder="Район"
                />
              </Form.Item>
            )}
            {reservoirs && (
              <Form.Item name="reservoirs" style={{ marginBottom: 0 }}>
                <TooltipedSelect placeholder="Водохранилища" />
              </Form.Item>
            )}
            {irrigationSystem && (
              <Form.Item name="irrigationSystem" style={{ marginBottom: 0 }}>
                <TooltipedSelect placeholder="Ирригационная система" />
              </Form.Item>
            )}
            {showFilterButtons && (
              <>
                <PrimaryButton
                  icon={<FilterIcon />}
                  onClick={onFilterButtonClick}
                />
                <PrimaryButton
                  icon={<ResetFilterIcon />}
                  onClick={onResetFilterButtonClick}
                />
              </>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
