import React from 'react';
import { useSelector } from 'react-redux';

import { DocumentTable } from '@features/manageEvents';
import { getOperationalBlockSelector } from '@store/selectors';

export const DocumentsCard = () => {
  const operationalBlockState = useSelector(getOperationalBlockSelector);

  return (
    <DocumentTable
      type="operational"
      attachments={operationalBlockState?.attachments || []}
    />
  );
};
