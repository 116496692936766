import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useCreateEmployeeOwnerMutation,
  useFindDictionaryByTypeIdQuery,
  useUpdateEmployeeOwnerMutation,
} from '@store/gisproApi';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import styles from './AddEmployeeModal.module.scss';
import { useParams } from 'react-router-dom';

type AddEmployeeType = {
  positionEmployee: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  inn: string;
};

interface IAddEmployeeModalProps {
  setShowModal: (value: boolean) => void;
  employeeInitialValue: any;
  waterUserId: number;
}
export const AddEmployeeModal: React.FC<IAddEmployeeModalProps> = ({
  setShowModal,
  employeeInitialValue,
  waterUserId,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id = '' } = useParams();

  const [createEmployee] = useCreateEmployeeOwnerMutation();
  const [updateEmployee] = useUpdateEmployeeOwnerMutation();

  const { data: positionsList = [], isLoading: positionsListIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.PositionsList,
    });

  const onSaveClick = useCallback(() => {
    if (!employeeInitialValue) {
      form
        .validateFields()
        .then((employee: AddEmployeeType) => {
          createEmployee({
            waterUserId: waterUserId ?? '0',
            body: { ...employee, waterUserId: waterUserId ?? '0', ownerId: id },
          })
            .unwrap()
            .then((data) => {
              setShowModal(false);
              notification.success({
                message: t('addEmployee.success'),
              });
            })
            .catch((e) => {
              notification.error({
                message: t('addEmployee.error'),
              });
            });
        })
        .catch((e) => {
          console.log('Validate errors', e);
        });
    } else {
      form
        .validateFields()
        .then((employee: AddEmployeeType) => {
          updateEmployee({
            waterUserId: waterUserId ?? '0',
            id: employeeInitialValue.id,
            body: { ...employee },
          })
            .unwrap()
            .then((data) => {
              notification.success({
                message: 'Вы успешно обновили объект.',
              });
              setShowModal(false);
            })
            .catch((e) => {
              notification.error({
                message: 'При обновлении объекта произошла ошибка!',
              });
            });
        })
        .catch((e) => {
          console.log('Validate errors', e);
        });
    }
  }, []);

  const onCancelClick = useCallback(() => {
    setShowModal(false);
  }, []);

  const initialValues = {
    ...employeeInitialValue,
    positionEmployee: employeeInitialValue?.positionEmployee?.id,
  };

  return (
    <CustomModal
      centered
      title={t(`addEmployee.titles.employee`)}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      open
      onCancel={onCancelClick}
      width={370}
      footer={
        <Row justify="center">
          <Col>
            <PrimaryButton size="large" htmlType="submit" onClick={onSaveClick}>
              {t('addEmployee.buttons.save')}
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form<AddEmployeeType>
        form={form}
        autoComplete="off"
        layout="vertical"
        className={styles.form}
        initialValues={initialValues}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.role')}
              name="positionEmployee"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                placeholder="Выберите Должность"
                loading={positionsListIsLoading}
                options={positionsList}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.lastName')}
              name="lastName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Фамилию" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.firstName')}
              name="firstName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Имя" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.middleName')}
              name="middleName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Отчество" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('createIndividualWaterRegistry.modal.pin')}
              name="inn"
              rules={[{ required: true }, { len: 14 }, numbersValidator]}
            >
              <TooltipedInput maxLength={14} placeholder="ПИН" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.phone')}
              name="phone"
              rules={[{ required: true }, phoneNumberValidator]}
              normalize={mapPhoneNumberToLong}
            >
              <TooltipedInput
                size="large"
                placeholder="Введите Номер телефона"
                addonBefore="+996"
                onChange={changeCursorPhoneNumberInput}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.email')}
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <TooltipedInput placeholder="Email" size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
