import {
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Statuses } from '@enums/table/customTableStatus';
import { getCurrentTableOptionsSelector } from '@store/selectors';
import { saveTableOptions } from '@store/slices';
import { debounce } from '@utils/utils';

import styles from './OperationalTableSelectsBlock.module.scss';
import { useSystemObjectControllerFindAllQuery } from '@store/api/systemObjectApi';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';

export const OperationalTableSelectsBlock: React.FC = () => {
  const dispatch = useDispatch();

  const statusTypes = Object.entries(Statuses).map(([value, label]) => ({
    value,
    label,
  }));
  const tableOptions = useSelector(getCurrentTableOptionsSelector);

  const { data: objectTypes = [] } = useSystemObjectControllerFindAllQuery();
  const { data: workCategories = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.Directions,
  });
  const setTableOptions = (key: string, value: any) => {
    dispatch(
      saveTableOptions({
        ...tableOptions,
        [key]: value,
      }),
    );
  };

  const changeTableOptions = debounce(setTableOptions, 500);

  return (
    <Row align="middle" gutter={10}>
      <Col span={4}>
        <TooltipedInput
          onChange={(v) => changeTableOptions('q', v)}
          value={tableOptions.q}
          placeholder="Наименование"
        />
      </Col>
      {tableOptions.systemObjectId ? null : (
        <Col span={4}>
          <TooltipedSelect
            allowClear
            onChange={(v) => changeTableOptions('systemObjectId', v)}
            options={objectTypes}
            placeholder="Объект"
            value={
              tableOptions.systemObjectId
                ? tableOptions.systemObjectId
                : undefined
            }
            className={styles.select}
          />
        </Col>
      )}
      <Col span={4}>
        <TooltipedSelect
          allowClear
          value={tableOptions.categoryId ? tableOptions.categoryId : undefined}
          placeholder="Категория"
          onChange={(v) => changeTableOptions('categoryId', v)}
          options={workCategories}
          className={styles.select}
        />
      </Col>
      <Col span={4}>
        <Row gutter={5} wrap={false} align="middle">
          <Col span={4}>
            <h2 className={styles.sumLabel}>Сроки</h2>
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventStartDate', v)}
            />
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventEndDate', v)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row className={styles.sum} gutter={5} wrap={false} align="middle">
          <Col span={4}>
            <h2 className={styles.sumLabel}>Сумма</h2>
          </Col>
          <Col span={10}>
            <TooltipedInput
              onChange={(v) => changeTableOptions('budgetStart', v)}
              type="number"
              placeholder="План"
            />
          </Col>
          <Col span={10}>
            <TooltipedInput
              onChange={(v) => changeTableOptions('budgetEnd', v)}
              type="number"
              placeholder="Факт"
            />
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          popupMatchSelectWidth={false}
          options={statusTypes}
          value={tableOptions.status}
          placeholder="Статус"
          onChange={(v) => changeTableOptions('status', v)}
          className={styles.select}
        />
      </Col>
    </Row>
  );
};
