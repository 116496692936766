import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const BalanceSheetAccountingSystem: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="BalanceSheetAccountingSystem"
        src={getKibanaUrl('Балансовый учет (по системам)')}
        height="2300"
        width="100%"
      />
    </div>
  );
};
