import { Divider } from '@features/ui-kit';
import { WateringSupplyScheduleControls } from '@features/wateringPlan/wateringSupplySchedule/wateringSupplyScheduleControls/WateringSupplyScheduleControls';
import { WateringSupplyScheduleTable } from '@features/wateringPlan/wateringSupplySchedule/wateringSupplyScheduleTable/WateringSupplyScheduleTable';
import { WaterUsersModal } from '@features/waterUser/waterUsersModal';

export const WateringSupplySchedule = () => {
  return (
    <div>
      <WateringSupplyScheduleControls />
      <Divider />
      <WateringSupplyScheduleTable />
      <WaterUsersModal />
    </div>
  );
};
