import { addRowIndexies } from './addRowIndexies';

export const addRowIndexiesMapper = (arr: any, indicatorCount = 3) => {
  const withDistrictRowIndexies = addRowIndexies(
    arr,
    'districtId',
    'districtRowIndex',
    indicatorCount,
  );
  const withSystemRowIndexies = addRowIndexies(
    withDistrictRowIndexies,
    'systemId',
    'systemRowIndex',
    indicatorCount,
  );
  const withSystemTypeRowIndexies = addRowIndexies(
    withSystemRowIndexies,
    'systemTypeId',
    'systemTypeRowIndex',
    indicatorCount,
  );
  return withSystemTypeRowIndexies;
};
