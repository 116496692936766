import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { getTagStatus } from '@utils/tag';
import { get } from '@utils/utils';

import styles from './styles.module.scss';

export function renderTag<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation();
  const value = String(get(record, customColumn.dataIndex));

  return (
    <Tag
      color={getTagStatus(value)}
      className={styles.tag}
      key={value}
      bordered={false}
    >
      {t(`tags.${value}`)}
    </Tag>
  );
}
