import { Routes } from '@enums/routes';
import { useGetCurrentUser } from '@features/authentication';
import { TableCustom } from '@features/ui-kit';
import { useDictionaryZoneTableFilters } from '@features/waterQuality/tables/dictionaryZoneTable/useDictionaryZoneTableFilters';
import { useGetZonesQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';
import { useEffect } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { useDictionaryZoneTableContainerColumns } from './useDictionaryZoneTableContainerColumns';
import { saveUrl } from '@utils/returnUrl';

export const DictionaryZoneTableContainer: React.FC = () => {
  const navigate = useNavigate();
  const { columns } = useDictionaryZoneTableContainerColumns();
  const { filters, setDictionaryTableFilters } =
    useDictionaryZoneTableFilters();
  const { user } = useGetCurrentUser();

  const saveAction = saveUrl();

  const {
    data: content = [],
    isLoading,
    isFetching,
  } = useGetZonesQuery(filters as any);
  const [params] = useSearchParams();

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setDictionaryTableFilters(
      isEmpty(newFilters)
        ? ({
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as any)
        : (newFilters as unknown as any),
    );
  }, []);

  const onHandleEditTable = (val: any) => {
    navigate(
      generatePath(Routes.WaterQualityCard, { id: val?.zoneId || 'new' }),
    );
    saveAction();
  };

  const onArchiveClick = (val: any) => {
    // TODO: доделать
    console.log(val);
  };

  return (
    <TableCustom
      loading={isLoading || isFetching}
      dataSource={content}
      columns={columns}
      size="small"
      scroll={{ y: 520 }}
      actionProps={{
        onHandleEdit: onHandleEditTable,
        onArchiveClick: onArchiveClick,
      }}
    />
  );
};
