import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useEconomiesColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Наименование участка',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Область, район, айыл окмоту',
      dataIndex: 'region',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        const address = [
          record.regionName,
          record.districtName,
          record.okmotName,
        ];
        return address.filter(Boolean).join(', ');
      },
    },
    {
      title: 'РУВХ',
      dataIndex: 'organization',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Оросительная система',
      dataIndex: 'systemName',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Канал',
      dataIndex: 'channelName',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Водовыпуск',
      dataIndex: 'outletName',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Климатическая зона',
      dataIndex: 'agroClimatiqueZoneName',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'КПД',
      dataIndex: 'kpd',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Общая площадь',
      dataIndex: 'area',
      xtype: CustomTableColumnType.NumberColumn,
      align: 'center',
    },
  ];

  return { columns };
};
