import { EntityTypesEnum } from '@enums/entityTypes';
import { TooltipedDatePicker, TooltipedInput, TooltipedSelect, } from '@features/ui-kit';
import { WaterUserEnum, WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { Col, Form, FormInstance, Row } from 'antd';
import dayjs from 'dayjs';

type MainInfoProps = {
  form: FormInstance;
  onCancel(): void;
  data: any;
};

export const MainInfo = ({ form, onCancel, data }: MainInfoProps) => {
  const { data: organizationForm = [], isLoading: organizationFormIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: data.type === WaterUserEnum.ASSOCIATION ? EntityTypesEnum.AssociationTypes : EntityTypesEnum.OrganizationForm,
    });

  const { data: economicActivity = [], isLoading: economicActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.TypeEconomicActivity,
    });

  const {
    data: activityOrganization = [],
    isLoading: activityOrganizationIsLoading,
  } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.KindActivity,
  });

  const { data: ownerType = [], isLoading: ownerTypeIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.OwnerType,
    });

  const initialValues = {
    name: data?.legalData?.name,
    organizationForm: data.type === WaterUserEnum.ASSOCIATION ? {
      label: data?.legalData?.associationType?.title,
      value: data?.legalData?.associationType?.id,
    } : {
      label: data?.legalData?.organizationForm?.title,
      value: data?.legalData?.organizationForm?.id,
    },
    economicActivity: {
      label: data?.legalData?.economicActivity?.title,
      value: data?.legalData?.economicActivity?.id,
    },
    businessActivity: {
      label: data?.legalData?.businessActivity?.title,
      value: data?.legalData?.businessActivity?.id,
    },
    inn: data?.legalData?.inn,
    address: data?.legalData?.address,
    okpo: data?.legalData?.okpo,
    regNumber: data?.legalData?.regNumber,
    legalForm: data?.legalData?.ownershipForm.id,
    registerDate: dayjs(data?.legalData?.registerDate),
  };
console.log('initialValues', initialValues, data);
  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onCancel}
      initialValues={initialValues}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Название организации"
            rules={[{ required: true }]}
          >
            <TooltipedInput size="large"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="organizationForm"
            label="Форма организации"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              placeholder="Введите Форму организации"
              size="large"
              loading={organizationFormIsLoading}
              options={organizationForm}
            />
          </Form.Item>
        </Col>
        {data.type === WaterUserEnum.ASSOCIATION ? null : <Col span={12}>
          <Form.Item
            name="economicActivity"
            label="Вид деятельности организации"
          >
            <TooltipedSelect
              placeholder="Введите Вид хозяйственной деятельности"
              size="large"
              loading={economicActivityIsLoading}
              options={economicActivity}
            />
          </Form.Item>
        </Col> }
        <Col span={12}>
          <Form.Item name="businessActivity" label="Вид хоз. деятельности">
            <TooltipedSelect
              size="large"
              placeholder="Введите вид деятельности организации"
              loading={activityOrganizationIsLoading}
              options={activityOrganization}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="inn"
            label="ИНН"
            rules={[{ required: true }, { len: 18 }, numbersValidator]}
          >
            <TooltipedInput maxLength={18} size="large"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="address" label="Адрес организации">
            <TooltipedInput size="large"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="okpo"
            label="ОКПО"
            rules={[{ required: false }, numbersValidator]}
          >
            <TooltipedInput type="number" size="large"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="regNumber" label="Регистрационный номер">
            <TooltipedInput size="large" disabled/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="legalForm" label="Форма собственности">
            <TooltipedSelect
              placeholder="Введите Форму собственности"
              size="large"
              loading={ownerTypeIsLoading}
              options={ownerType}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="registerDate" label="Дата регистраций">
            <TooltipedDatePicker format="YYYY-MM-DD" size="large"/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
