import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Card, Col, Divider, Empty, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { EmployeePosition } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllByOrganizationIdQuery,
  useGetOwnerInfoByIdQuery,
} from '@store/gisproApi';
import { getSelectedWaterUserIdSelector } from '@store/selectors';

import styles from './WaterUserSupplierDetailsCard.module.scss';

export const WaterUserSupplierDetailsCard = () => {
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);
  const { data: waterUser } = useFindAllByOrganizationIdQuery(
    waterUserId
      ? {
          id: waterUserId,
        }
      : skipToken,
  );

  const { data: ownerInfo } = useGetOwnerInfoByIdQuery(
    waterUser?.owner?.id
      ? {
          id: waterUser?.owner?.id,
        }
      : skipToken,
  );
  const getManagerFio = (employees: any) => {
    if (employees && employees[0]?.positionEmployee) {
      const res = employees?.find(
        (item: any) => item?.positionEmployee?.title === 'Директор',
      );
      return `${res?.firstName} ${res?.lastName} ${res?.middleName}`;
    }
    return '-';
  };

  const getAccountantFio = (employees: any) => {
    if (employees && employees[0]?.positionEmployee) {
      const res = employees?.find(
        (item: any) => item?.positionEmployee?.title === 'бухгалтер',
      );
      return `${res?.firstName} ${res?.lastName} ${res?.middleName}`;
    }
    return '-';
  };

  const data = [
    { label: 'Наименование:', value: ownerInfo?.name || '-' },
    {
      label: 'Ф.И.О Руководителя:',
      value: ownerInfo?.employees && getManagerFio(ownerInfo.employees),
    },
    {
      label: 'Ф.И.О Бухгалтера:',
      value: ownerInfo?.employees && getAccountantFio(ownerInfo.employees),
    },
    { label: 'Юридический адрес:', value: ownerInfo?.address || '-' },
    {
      label: 'Наименование Банка:',
      value: ownerInfo?.bank?.parent?.name || '-',
    },
    {
      label: 'Наименование Филиала Банка:',
      value: ownerInfo?.bank?.name || '-',
    },
    { label: 'БИК:', value: ownerInfo?.bank?.bik || '-' },
    { label: 'Полный адрес Банка:', value: ownerInfo?.bank?.address || '-' },
    {
      label: 'Корреспондентский счет:',
      value: ownerInfo?.organizationAccount || '-',
    },
    { label: 'Расчетный счет:', value: ownerInfo?.accountNumber || '-' },
  ];

  return (
    <Row className={styles.customCard} gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>Детали поставщика</Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Row>
          }
        >
          {!waterUser || !waterUserId ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Row gutter={[0, 4]}>
              <>
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <Col span={12}>
                      <Typography.Text strong>{item.label}</Typography.Text>
                    </Col>
                    <Col span={12}>{item.value}</Col>
                  </React.Fragment>
                ))}
              </>
            </Row>
          )}
        </CustomCard>
      </Col>
    </Row>
  );
};
