import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useTotalInformationColumns = (data: any = {}) => {
  const { t } = useTranslation();

  const outletsColumns: TableColumn[] = [
    {
      title: '№ п / п',
      dataIndex: 'id',
      xtype: CustomTableColumnType.String,
      key: 'id',
      width: 100,
    },
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'name',
      key: 'name',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Наименование оросительной системы',
      dataIndex: 'irrigationSystem.name',
      key: 'name',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Адрес: Область, Район',
      dataIndex: 'name',
      width: 180,
      key: 'square',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        const address = [record.region?.title, record.district?.title];

        return address.filter(Boolean).join(', ');
      },
    },
    {
      width: 100,
      title: 'Действия',
      align: 'left',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];

  const facilitiesColumns: TableColumn[] = [
    {
      title: '№ п / п',
      dataIndex: 'id',
      align: 'center',
      xtype: CustomTableColumnType.String,
      key: 'id',
      width: 100,
    },
    {
      title: 'Наименование участка',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'outletName',
      align: 'center',
      key: 'address',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Наименование оросительной системы',
      dataIndex: 'systemName',
      align: 'center',
      key: 'irrigationSystem',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Общ. площадь Га',
      dataIndex: 'area',
      align: 'center',
      key: 'outlet',
      xtype: CustomTableColumnType.String,
      width: 180,
    },
    {
      width: 100,
      title: 'Действия',
      align: 'left',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];

  const responsiblePersonsColumns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      align: 'center',
      xtype: CustomTableColumnType.String,
      key: 'id',
      width: 100,
    },
    {
      title: 'Должность',
      dataIndex: 'positionEmployee',
      key: 'positionEmployee',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      width: 250,
      renderCustom: ({ record }) => (
        <div>{record?.positionEmployee?.title || '-'}</div>
      ),
    },
    {
      title: 'Фамилия',
      dataIndex: 'middleName',
      align: 'center',
      key: 'middleName',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      align: 'center',
      key: 'firstName',
      xtype: CustomTableColumnType.String,
      width: 250,
    },
    {
      title: 'Отчество',
      dataIndex: 'lastName',
      align: 'center',
      width: 180,
      key: 'square',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'ПИН',
      dataIndex: 'inn',
      align: 'center',
      key: 'inn',
      xtype: CustomTableColumnType.String,
      width: 180,
    },
    {
      width: 100,
      title: 'Действия',
      align: 'left',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions:
        data?.employees?.length !== 1
          ? [
              CustomTableAction.EditWithoutText,
              CustomTableAction.DeleteWithoutText,
            ]
          : [CustomTableAction.EditWithoutText],
    },
  ];

  return { outletsColumns, responsiblePersonsColumns, facilitiesColumns };
};
