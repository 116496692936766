import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const PasteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 5.03125C3.5 4.1869 4.18693 3.5 5.03125 3.5H8.75V1.53125C8.75 1.16881 8.45619 0.875 8.09375 0.875H5.88957C5.58649 0.352461 5.02132 0 4.375 0C3.72868 0 3.16351 0.352461 2.86043 0.875H0.65625C0.293809 0.875 0 1.16881 0 1.53125V10.7188C0 11.0812 0.293809 11.375 0.65625 11.375H3.5V5.03125ZM4.375 1.09375C4.73744 1.09375 5.03125 1.38756 5.03125 1.75C5.03125 2.11244 4.73744 2.40625 4.375 2.40625C4.01256 2.40625 3.71875 2.11244 3.71875 1.75C3.71875 1.38756 4.01256 1.09375 4.375 1.09375ZM9.40625 7.875H12.25V13.3438C12.25 13.7062 11.9562 14 11.5938 14H5.03125C4.66881 14 4.375 13.7062 4.375 13.3438V5.03125C4.375 4.66881 4.66881 4.375 5.03125 4.375H8.75V7.21875C8.75 7.57969 9.04531 7.875 9.40625 7.875ZM12.25 6.83432V7H9.625V4.375H9.79068C9.96472 4.375 10.1316 4.44414 10.2547 4.5672L12.0578 6.37027C12.1808 6.49335 12.25 6.66027 12.25 6.83432Z"
        fill="white"
      />
    </svg>
  );
};
