import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPointIcon: React.FC<IconProps> = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6057_182618)">
        <path
          d="M10.7644 17.1639H7.83224V14.1621C7.83224 13.9654 7.67267 13.8086 7.4789 13.8086H6.35334C6.15672 13.8086 6 13.9682 6 14.1621V18.0819C6 18.5864 6.41033 18.9998 6.91755 18.9998H10.7644C10.961 18.9998 11.1177 18.8402 11.1177 18.6463V17.5231C11.1177 17.3207 10.961 17.1639 10.7644 17.1639Z"
          fill="black" fillOpacity="0.6" />
        <path
          d="M16.2841 12.5632V11.4343C16.2841 11.2376 16.1245 11.0808 15.9307 11.0808H13.9161V9.06248C13.9161 8.86578 13.7566 8.70898 13.5628 8.70898H12.4344C12.2378 8.70898 12.081 8.86863 12.081 9.06248V11.078H10.0607C9.86412 11.078 9.7074 11.2376 9.7074 11.4314V12.5603C9.7074 12.757 9.86697 12.9138 10.0607 12.9138H12.0753V14.935C12.0753 15.1317 12.2349 15.2885 12.4287 15.2885H13.5571C13.7537 15.2885 13.9104 15.1289 13.9104 14.935V12.9195H15.9307C16.1274 12.9195 16.2841 12.7599 16.2841 12.5632Z"
          fill="black" fillOpacity="0.6" />
        <path
          d="M6.35277 9.92323H7.47548C7.6721 9.92323 7.82882 9.76359 7.82882 9.56974V6.83303H10.761C10.9576 6.83303 11.1143 6.67339 11.1143 6.47954V5.35349C11.1143 5.15679 10.9547 5 10.761 5H6.91413C6.40976 5 5.99658 5.41051 5.99658 5.91794V9.56974C5.99943 9.76644 6.159 9.92323 6.35277 9.92323Z"
          fill="black" fillOpacity="0.6" />
        <path
          d="M19.6377 13.8062H18.515C18.3184 13.8062 18.1617 13.9658 18.1617 14.1596V17.1615H15.3577C15.1611 17.1615 15.0044 17.3211 15.0044 17.515V18.6382C15.0044 18.8349 15.164 18.9917 15.3577 18.9917H19.0792C19.5836 18.9917 19.9968 18.5811 19.9968 18.0737V14.1625C19.9911 13.9658 19.8315 13.8062 19.6377 13.8062Z"
          fill="black" fillOpacity="0.6" />
        <path
          d="M19.0737 5H15.355C15.1584 5 15.0017 5.15964 15.0017 5.35349V6.47669C15.0017 6.67339 15.1613 6.83018 15.355 6.83018H18.159V9.56689C18.159 9.76359 18.3186 9.92038 18.5123 9.92038H19.635C19.8316 9.92038 19.9884 9.76074 19.9884 9.56689V5.91794C19.9912 5.41051 19.5809 5 19.0737 5Z"
          fill="black" fillOpacity="0.6" />
      </g>
      <defs>
        <clipPath id="clip0_6057_182618">
          <rect width="14" height="14" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
