import { Alert, Col, Form, Row, Typography } from 'antd';

import { PrimaryButtonV2, TooltipedInputV2 } from '@features/ui-kit-v2';

import styles from './ReservoirMonitoringSetCurrentVolumeModal.module.scss';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  Divider,
  PrimaryButton,
  TooltipedInput,
} from '@features/ui-kit';
import { CloseOutlined } from '@ant-design/icons';

type ReservoirMonitoringSetCurrentVolumeModalPropTypes = {
  action: (values: { beginningVolume: number }) => void;
  onCancel: () => void;
};

export const ReservoirMonitoringSetCurrentVolumeModal: React.FC<
  ReservoirMonitoringSetCurrentVolumeModalPropTypes
> = ({ action, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values: { beginningVolume: number }) => {
    action(values);
  };

  return (
    <CustomModal
      open
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={660}
      footer={false}
      title={'Ввод данных водохранилища'}
      onCancel={onCancel}
      destroyOnClose
    >
      <Alert
        message="Примечание! Для начала работы введите объем воды водохранилища"
        type="warning"
        showIcon
        closable
        className={styles.alert}
      />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Объем" name="beginningVolume">
          <TooltipedInput type="number" />
        </Form.Item>
        <Row justify="center" gutter={16}>
          <Col>
            <DefaultButton onClick={onCancel}>Отмена</DefaultButton>
          </Col>
          <Col>
            <Form.Item style={{ marginBottom: 10 }}>
              <PrimaryButton type="primary" htmlType="submit">
                Сохранить
              </PrimaryButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
