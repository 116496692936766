import { ACCESS, Permission } from '@enums/permisson';
import { AuthenticationUser } from '@models/authentication/interfaces/user';

type PropsType = {
  permissionRequired: ACCESS;
  permissionsGranted: string[];
  user: AuthenticationUser | null;
};

export const validatePermission = ({
  permissionRequired,
  permissionsGranted,
  user,
}: PropsType): boolean => {
  switch (permissionRequired) {
    case ACCESS.NEVER: {
      return false;
    }
    case ACCESS.IS_AUTHENTICATED: {
      return !!user;
    }

    case ACCESS.IS_UNAUTHENTICATED: {
      return !user;
    }
    default: {
      return permissionsGranted.includes(permissionRequired);
    }
  }
};
