import { DefaultOptionType } from 'antd/es/select';

import { Tags } from '@constants/tags';
import { PlanType } from '@enums/annualPlan';
import { DecadeStatus } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import {
  type IActsInvoicesForPayments,
  type IActsInvoicesForPaymentsFilters,
} from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import {
  type IAgroClimaticZone,
  type IAgroClimaticZoneFilters,
} from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import {
  IAnnualApplication,
  type IAnnualApplicationCalculations,
  type IPlantingDataDto,
} from '@models/annualApplication/interfaces/annualApplication';
import { type IAnnualApplicationFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import {
  type IAnnualPlan,
  type IAnnualPlanFilters,
} from '@models/annualPlan/enums/AnnualPlan';
import { IAssociationIndicatorReports } from '@models/associationIndicatorReports/interfaces/associationIndicatorReports';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { type IBasin } from '@models/basin/interfaces/basin';
import {
  type IBusinessPlanFilters,
  type IBusinessPlanItem,
} from '@models/business/interfaces/business';
import { type CommonReference } from '@models/commonReference/interfaces/commonReference';
import { type IFacility } from '@models/facility/interfaces/facility';
import {
  GeoPortalGroupApiArg,
  GeoPortalMapsApiArg,
  IGeoPortalGroup,
  type IIrrigationSystemObjects,
  type IIrrigationSystemObjectsLayers,
  type ILayers,
  IMapItem,
  type IQualityControlMonitoring,
} from '@models/geoPortal/interfaces/GeoPortal';
import { ICreateHydraulicSection } from '@models/hydraulicSection/interfaces/hydraulicSection';
import {
  type IAgroClimaticZoneHydroModule,
  type ICreateGmr,
  ICreateGmrSystemOutlets,
  IGmrSystemsByDistrict,
} from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';
import {
  type IrrigationSystem,
  type IrrigationSystemFilters,
  IrrigationSystemInfo,
  IrrigationSystemObjectsAndStructure,
} from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { JsonRecord } from '@models/jsonRecord';
import { type IBuildingBlock } from '@models/manageEvents/interfaces/buildingBlock';
import { type IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';
import { type IOtherTask } from '@models/manageEvents/interfaces/otherTask';
import { type IRegularEventBlock } from '@models/manageEvents/interfaces/regularEventBlock';
import {
  waterManagementById,
  waterManagementCounters,
  waterUsersCounter,
  waterUsersCounterByDistrictId,
} from '@models/monitoring/interfaces/monitoring';
import { type ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { type IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { type Owner } from '@models/owners/interfaces/owner';
import { type PaginationWrapper } from '@models/pagination/interfaces/pagination';
import {
  ICreateReservoirData,
  ICreateReservoirStartVolume,
  IReservoir,
  IReservoirDataByMonth,
  IReservoirDataByMonthFilters,
} from '@models/reservoir/interfaces/reservoir';
import {
  type INaturalSource,
  type ISource,
  type ISourceType,
} from '@models/source/interfaces/source';
import {
  type ICreateTariff,
  type ITariff,
  type ITariffFilters,
} from '@models/tariff/interfaces/tariff';
import {
  IBalanceSheet,
  IWaterAccountingFilters,
} from '@models/waterAccounting/interfaces/waterAccounting';
import { IWateringPlanFilters } from '@models/wateringPlan/models/wateringPlanFilters';
import {
  ISystemObject,
  type IWaterObject,
  type IWaterObjectFilters,
  type IWaterObjectStatus,
} from '@models/waterObject/interfaces/waterObject';
import { type IWaterSection } from '@models/waterObject/interfaces/waterSection';
import { type IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import {
  ICropSowingPlan,
  ICropSowingPlanByGuvh,
  IHydrometricWorkPlan,
  IHydrometricWorkPlanByGuvh,
  IPlanItemDto,
  ISummaryGeneralPlan,
  IWaterUsePlanByGuvhDto,
  IWaterUsePlanDataDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import {
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import {
  type AssociationInitialData,
  type IOrganization,
  IWaterUserV2,
} from '@models/waterUser/interfaces/waterUser';
import { Dayjs } from 'dayjs';

import { basicApi as api } from './basicApi';

const mainBaseUrl = process.env.REACT_APP_MAIN_BASE_URL + '/api/v1';
const demoDataUrl = process.env.REACT_APP_DEMO_DATA;
export const URLS = {
  // irrigation-system
  saveIrrigationSystem: `${mainBaseUrl}/irrigation-system/create`,
  findAllIrrigationSystemList: `${mainBaseUrl}/irrigation-system/list`,
  updateIrrigationSystem: `${mainBaseUrl}/irrigation-system/update/system`,
  getAllBasin: `${mainBaseUrl}/irrigation-system/get-all-basin`,
  getAllSourceTypes: `${mainBaseUrl}/irrigation-system/get-all-source-type`,
  getSources: `${mainBaseUrl}/irrigation-system/get-all-water-intake-point`,
  getWaterUsersCounter: `${mainBaseUrl}/irrigation-system/counters`,
  findIrrigationSystemById: `${mainBaseUrl}/irrigation-system/system/:id`,
  getIrrigationSystemInfoById: `${mainBaseUrl}/irrigation-system/system/system-info/:id`,
  getIrrigationSystemObjectsAndStructureById: `${mainBaseUrl}/irrigation-system/system/system-objects/:id`,
  getIrrigationWaterUsersById: `${mainBaseUrl}/irrigation-system/system/water-users/:id`,
  balanceAccountingIrrigation: `${mainBaseUrl}/balance-accounting/irrigation/:id`,

  findAllIrrigationSystemsByHydroFacilityId: `${mainBaseUrl}/hydro-facility/get-irrigation-system-by-hydro-facility`,

  getAllIrrigationSystemsByHydroFacilityId: `${mainBaseUrl}/hydro-facility/get-irrigation-systems-by-district`,

  findAllIrrigationSystemsByHydroFacilityId2: `${mainBaseUrl}/hydro-facility/get-irrigation-systems-by-hydro-facility`,

  findAllChannelsByIrrigationSystemId: `${mainBaseUrl}/hydro-facility/get-channels-by-irrigation-system`,
  findAllOutletsByChannelId: `${mainBaseUrl}/hydro-facility/get-outlets-by-channel`,
  getNotBindedOutletsByChannelId: `${mainBaseUrl}/hydro-facility/get-not-binded-outlets-by-channel`,

  // vodovod
  createAqueductWaterObject: `${mainBaseUrl}/irrigation-system/create/aqueduct`,
  findAqueductById: `${mainBaseUrl}/irrigation-system/aqueduct/:id`,
  findGeneralAqueductById: `${mainBaseUrl}/irrigation-system/general-aqueduct/:id`,
  aquedactObjectsSectionConstructor: `${mainBaseUrl}/irrigation-system/create/aqueduct-objects-section-constructor`,
  findAllObjectsByAqueductId: `${mainBaseUrl}/irrigation-system/find-all-objects-by-aqueduct/:id`,
  getAqueductListBySystemId: `${mainBaseUrl}/irrigation-system/aqueduct-list/:id`,
  aqueductToAqueduct: `${mainBaseUrl}/irrigation-system/create/aqueduct-to-aqueduct`,
  findAllObjectsByAqueductSections: `${mainBaseUrl}/irrigation-system/find-all-objects-by-aqueduct-sections/:id`,
  aqueductGtsConstructor: `${mainBaseUrl}/irrigation-system/create/aqueduct-gts-constructor`,
  findAllAllWaterSectionsByAqueductId: `${mainBaseUrl}/irrigation-system/find-by-aqueduct-objects-sections/:id`,

  // irrigation system
  findAllIrrigationSystemObjectsListWithFilters: `${mainBaseUrl}/irrigation-system/object-list`,
  checkObjecsCompare: `${mainBaseUrl}/irrigation-system/objects-compare`,
  createSource: `${mainBaseUrl}/irrigation-system/create/water-intake-point`,
  findAllIrrigationSystemObjectsListOutlets: `${mainBaseUrl}/irrigation-system/object-list/outlets`,
  findAllIrrigationSystemObjectsListHydroposts: `${mainBaseUrl}/irrigation-system/object-list/hydroposts`,
  findAllIrrigationSystemObjectsListSources: `${mainBaseUrl}/irrigation-system/object-list/source`,
  findAllIrrigationSystemObjectsListChannels: `${mainBaseUrl}/irrigation-system/object-list/channels`,
  addGeonode: `${mainBaseUrl}/irrigation-system/add-geonode`,
  createWaterObjectsAqueductWaterObject: `${mainBaseUrl}/irrigation-system/create/aqueduct-objects`,
  getNaturalSources: `${mainBaseUrl}/irrigation-system/get-all-natural-source`,
  findAllIrrigationSystemsOwners: `${mainBaseUrl}/irrigation-system/get-owners`,
  deleteAqueductObjectsWithSections: `${mainBaseUrl}/irrigation-system/delete/aqueduct-objects-with-sections`,
  toArchiveIrrigationSystem: `${mainBaseUrl}/irrigation-system/irrigation/archived/:id`,
  toArchiveIrrigationSystemObject: `${mainBaseUrl}/irrigation-system/system-object/archived/:id`,
  toUnArchiveIrrigationSystem: `${mainBaseUrl}/irrigation-system/irrigation/unarchived/:id`,
  toUnArchiveIrrigationSystemObject: `${mainBaseUrl}/irrigation-system/system-object/unarchived/:id`,
  getRivers: `${mainBaseUrl}/irrigation-system/get-rivers`,
  getOthers: `${mainBaseUrl}/irrigation-system/get-others`,
  getLakes: `${mainBaseUrl}/irrigation-system/get-lakes`,
  getHydroposts: `${mainBaseUrl}/irrigation-system/get-all-hydroposts`,

  // system-object
  findAllIrrigationSystemObjectsList: `${mainBaseUrl}/system-objects/all`,
  getSystemObjectById: `${mainBaseUrl}/system-objects/:id`,
  createSystemObjectList: `${mainBaseUrl}/system-objects/create-list`,
  createSystemObject: `${mainBaseUrl}/system-objects/create`,
  updateSystemObjectStatus: `${mainBaseUrl}/system-objects/status/update/:id`,
  getOutletsByAssociationId: `${mainBaseUrl}/system-objects/outlets/by-association/:id`,
  updateSystemObject: `${mainBaseUrl}/system-objects/update`,
  getOutletHydroposts: `${mainBaseUrl}/irrigation-system/get-outlet-hydroposts`,
  getLinkedSystemObjects: `${mainBaseUrl}/system-objects/linked/:id`,

  // water sections
  getWaterSectionsBySystemObjectId: `${mainBaseUrl}/water-sections/system-object/:queryArg`,
  createWaterSection: `${mainBaseUrl}/water-sections/create`,
  deleteWaterSection: `${mainBaseUrl}/water-sections/delete/:queryArg`,

  // manage events
  submitOperationalBlockImplementationTerm: `${mainBaseUrl}/operational-block/submit/implementation-terms`,
  findOperationalBlockById: `${mainBaseUrl}/operational-block/:id`,
  findRegularEventById: `${mainBaseUrl}/regular-event/:id`,
  findConstructionBlockById: `${mainBaseUrl}/construction-block/:id`,
  getAllOperational: `${mainBaseUrl}/operational-block/get-all`,
  getAllRegular: `${mainBaseUrl}/regular-event/get-all`,
  getAllConstruction: `${mainBaseUrl}/construction-block/get-all`,
  getEventWorkGantt: `${mainBaseUrl}/event-and-work-gantt`,
  getAllExtraTask: `${mainBaseUrl}/extra-task/get-all`,
  createTaskOperationalBlock: `${mainBaseUrl}/operational-block/complete-task/:id`,
  saveTaskOperationalBlock: `${mainBaseUrl}/operational-block/save-task`,
  constructionBlockAttachmentsUpload: `${mainBaseUrl}/construction-block/attachments/upload`,
  constructionBlockAttachmentsDelete: `${mainBaseUrl}/construction-block/attachments/delete`,
  extraTaskBlockAttachmentsUpload: `${mainBaseUrl}/extra-task/attachments/upload`,
  extraTaskBlockAttachmentsDelete: `${mainBaseUrl}/extra-task/attachments/delete`,
  operationalBlockAttachmentsUpload: `${mainBaseUrl}/operational-block/attachments/upload`,
  operationalBlockAttachmentsDelete: `${mainBaseUrl}/operational-block/attachments/delete`,
  saveReportOperationalBlock: `${mainBaseUrl}/operational-block/save-report`,
  operationalBlockSetControl: `${mainBaseUrl}/operational-block/set-on-control`,
  constructionBlockSetControl: `${mainBaseUrl}/construction-block/set-on-control`,
  createReportOperationalBlock: `${mainBaseUrl}/operational-block/complete-report/:query`,
  saveClosingReportOperationalBlock: `${mainBaseUrl}/operational-block/save-closing-report`,
  createCloseReportOperationalBlock: `${mainBaseUrl}/operational-block/complete-closing-report/:id`,
  createTaskRegularEventBlock: `${mainBaseUrl}/regular-event/create-event`,
  createOtherTaskBlock: `${mainBaseUrl}/extra-task/complete-task/:id`,
  saveOtherTaskBlock: `${mainBaseUrl}/extra-task/save-task`,
  createOtherTaskReport: `${mainBaseUrl}/extra-task/complete-report/:id`,
  saveOtherTaskReport: `${mainBaseUrl}/extra-task/save-report`,
  createReportRegularEventBlock: `${mainBaseUrl}/regular-event/create-report`,
  saveTaskBuildingBlock: `${mainBaseUrl}/construction-block/save-task`,
  createTaskBuildingBlock: `${mainBaseUrl}/construction-block/complete-task/:id`,
  saveReportBuildingBlock: `${mainBaseUrl}/construction-block/save-report`,
  createReportBuildingBlock: `${mainBaseUrl}/construction-block/compelete-report/:id`,
  saveCloseReportBuildingBlock: `${mainBaseUrl}/construction-block/save-closing-report`,
  createCloseReportBuildingBlock: `${mainBaseUrl}/construction-block/complete-closing-reposrt/:id`,
  getBuildingTemplates: `${mainBaseUrl}/construction-block/get-templates`,
  getBuildingTemplateById: `${mainBaseUrl}/construction-block/template/:id`,
  getOperationalTemplates: `${mainBaseUrl}/operational-block/get-templates`,
  getOperationalTemplateById: `${mainBaseUrl}/operational-block/template/:id`,
  getRegularEventTemplates: `${mainBaseUrl}/regular-event/get-templates`,
  getExtraTaskTemplates: `${mainBaseUrl}/extra-task/get-templates`,
  getExtraTaskTemplateById: `${mainBaseUrl}/extra-task/template/:id`,
  getRegularEventTemplateById: `${mainBaseUrl}/regular-event/template/:id`,
  getOperationalById: `${mainBaseUrl}/operational-block/:id`,
  getOtherTaskById: `${mainBaseUrl}/extra-task/:query`,
  getRegularEventById: `${mainBaseUrl}/regular-event/:query`,
  regularEventList: `${mainBaseUrl}/statistics/get-regular-events`,
  extraTaskEventList: `${mainBaseUrl}/statistics/get-extra-tasks`,
  operationalBlockEventList: `${mainBaseUrl}/statistics/get-operational-block`,
  constructionBlockEventList: `${mainBaseUrl}/statistics/get-construction-block`,

  toArchiveOperationalBlock: `${mainBaseUrl}/operational-block/archived/:id`,
  toUnArchiveOperationalBlock: `${mainBaseUrl}/operational-block/unarchived/:id`,
  toArchiveBuildingBlock: `${mainBaseUrl}/construction-block/archived/:id`,
  toUnArchiveBuildingBlock: `${mainBaseUrl}/construction-block/unarchived/:id`,
  toArchiveExtraTask: `${mainBaseUrl}/extra-task/archived/:id`,
  toUnArchiveExtraTask: `${mainBaseUrl}/extra-task/unarchived/:id`,

  // water users
  getEmployeeOwnerListById: `${mainBaseUrl}/employee/list?ownerId=:query`,
  getEmployeeOwnerList: `${mainBaseUrl}/employee/owner/list`,
  createEmployeeOwner: `${mainBaseUrl}/employee/create/:waterUserId`,
  updateEmployeeOwner: `${mainBaseUrl}/employee/update/:waterUserId/:id`,
  deleteEmployeeOwner: `${mainBaseUrl}/employee/delete/:id`,
  getAllWaterUsers: `${mainBaseUrl}/water-user/all`,
  waterUserCreate: `${mainBaseUrl}/water-user/create`,
  savePhysicalWaterUser: `${mainBaseUrl}/water-user/save-physical`,
  saveLegalsWaterUser: `${mainBaseUrl}/water-user/save-legal-person`,
  findAllPhysical: `${mainBaseUrl}/water-user/physical`,
  getAllIrrigationSystem: `${mainBaseUrl}/irrigation-system/all`,
  findAllByOrganizationId: `${mainBaseUrl}/water-user/organization/:id`,
  findAllByWaterUserId: `${mainBaseUrl}/water-user/:id`,
  getAllByWaterUserEmployeesId: `${mainBaseUrl}/water-user/employees/:id`,
  findAllLegalPerson: `${mainBaseUrl}/water-user/legal-person`,
  findAllAssociation: `${mainBaseUrl}/water-user/association`,
  findAllUsers: `${mainBaseUrl}/water-user/all`,
  createAssociationStats: `${mainBaseUrl}/water-user/create-association/:id`,
  updateAssociationStats: `${mainBaseUrl}/water-user/update-association`,
  toArchiveWaterUser: `${mainBaseUrl}/water-user/archived/:id`,
  toUnArchiveWaterUser: `${mainBaseUrl}/water-user/unarchived/:id`,
  getWaterUserData: `${mainBaseUrl}/water-user/organization`,
  searchAllWaterUsers: `${mainBaseUrl}/water-user/search-by-organization`,
  getWaterUserAgreement: `${mainBaseUrl}/water-user/agreement/generate`,

  // agro-climatic zone
  getAgroClimaticZones: `${mainBaseUrl}/zone/table`,
  createAgroClimaticZone: `${mainBaseUrl}/zone/create`,
  getGmrZoneList: `${mainBaseUrl}/zone/gmr-list`,
  createGmr: `${mainBaseUrl}/zone/create/gmr`,
  getSystemsByDistrict: `${mainBaseUrl}/hydro-facility/get-systems-by-district`,
  getBindedOutlets: `${mainBaseUrl}/hydro-facility/get-binded-outlets`,
  createHydraulicSection: `${mainBaseUrl}/hydro-facility/create`,

  signUp: `${mainBaseUrl}/auth/signup`,

  // reference
  getReferencesByParentId: `${mainBaseUrl}/reference/parent/:queryArgs`,
  getReferencesByParentIdAndType: `${mainBaseUrl}/reference/parent-and-type`,
  findDictionaryByTypeId: `${mainBaseUrl}/reference/type-id/:id`,
  findDictionaryItemByTypeId: `${mainBaseUrl}/reference/by-id/:id`,
  findReferencesByParentId: `${mainBaseUrl}/reference/parent/:id`,
  getAllCommonReferences: `${mainBaseUrl}/reference/list`,
  getObjectCategories: `${mainBaseUrl}/reference/object-category`,
  findAllObjectGroups: `${mainBaseUrl}/reference/object-group`,
  findObjectGroupByCode: `${mainBaseUrl}/reference/object-group/:id`,
  findAllObjectCategoriesByObjectGroupId: `${mainBaseUrl}/reference/object-category/find-all-objects-by-objectGroupId/:id`,
  findObjectCategoryById: `${mainBaseUrl}/reference/object-category/:id`,

  getAllCommonReferencesList: `${mainBaseUrl}/reference/reference/list/:typeCode`,
  createCommonReference: `${mainBaseUrl}/reference/reference/create/:typeCode`,
  updateCommonReference: `${mainBaseUrl}/reference/reference/update/:id`,
  deleteCommonReference: `${mainBaseUrl}/reference/reference/delete/:id`,

  // geo portal
  geoPortalQualityControlMonitoring: `${mainBaseUrl}/geo-portal/quality-control-monitoring`,
  geoPortalIrrigationSystemObjects: `${mainBaseUrl}/geo-portal/irrigation-system-objects`,
  geoPortalIrrigationSystemObjectsLayers: `${mainBaseUrl}/geo-portal/irrigation-system-objects-layers`,
  geoPortalGetLayers: `${mainBaseUrl}/geo-portal/get-layers/:id`,
  updateGeometry: `${mainBaseUrl}/geo-portal/update-data`,

  // watering-plan
  getWateringPlanDecadalRequest: `${mainBaseUrl}/watering-plan/decadal-request`,
  sendRequestDecadal: `${mainBaseUrl}/watering-plan/decadal-request`,
  saveWateringPlanDecadalRequest: `${mainBaseUrl}/watering-plan/decadal-request/save`,
  wateringPlanWaterUsersByOutlet: `${mainBaseUrl}/watering-plan/water-users-by-outlet`,
  saveOperationalWateringPlanRequest: `${mainBaseUrl}/watering-plan/operational-watering-plan/save`,
  sendOperationalPlanRequest: `${mainBaseUrl}/watering-plan/operational-watering-plan`,
  getWaterAccountingOutletFacts: `${mainBaseUrl}/watering-plan/water-log/outlets-fact`,
  getWaterAccountingHydropostFacts: `${mainBaseUrl}/watering-plan/water-log/hydropost-fact`,
  getWaterAccountingBalanceSheet: `${mainBaseUrl}/watering-plan/water-log/balance-sheet`,
  saveWaterAccountingBalanceSheet: `${mainBaseUrl}/watering-plan/water-log/balance-sheet`,
  saveToSystemWaterAccountingBalanceSheet: `${mainBaseUrl}/watering-plan/water-log/balance-sheet/save`,
  approveWaterAccountingBalanceSheet: `${mainBaseUrl}/watering-plan/water-log/balance-sheet/approve`,
  cancelWaterAccountingBalanceSheet: `${mainBaseUrl}/watering-plan/water-log/balance-sheet/cancel`,
  getWaterAccountingJournal: `${mainBaseUrl}/watering-plan/water-log/water-supply-journal`,
  getWateringPlanOperationalWatering: `${mainBaseUrl}/watering-plan/operational-watering-plan`,
  getWateringPlanOperationalWaterSupply: `${mainBaseUrl}/watering-plan/operational-supply-plan`,

  // bank
  createBank: `${mainBaseUrl}/bank`,
  findAllBanks: `${mainBaseUrl}/bank/list`,
  findBankById: `${mainBaseUrl}/bank/list/:id`,
  findAllFilial: `${mainBaseUrl}/bank/list-filial/:id`,

  findAllRuvhOrganizations: `${mainBaseUrl}/organization/ruvh`,
  getOrganizationsList: `${mainBaseUrl}/organization/get-all`,
  // old backend
  // accounting
  findAllActsInvoicesForPayments: `${mainBaseUrl}/act-of-payment/find-all-act`,
  getAccountingAct: `${mainBaseUrl}/act-of-payment/act/:id`,
  getAccountingBill: `${mainBaseUrl}/act-of-payment/bill/:id`,
  createAccountingBill: `${mainBaseUrl}/act-of-payment/bill/`,

  getQuentityIndicators: `${mainBaseUrl}/act-of-payment/collection-data-outlet`,
  updateQuentityIndicator: `${mainBaseUrl}/act-of-payment/update/collection-data-outlet`,
  getWaterSupplyList: `${mainBaseUrl}/act-of-payment/graph-water-supply-list`,
  useGetWaterUseSupplyList: `${mainBaseUrl}/act-of-payment/water-supply-list`,
  useGetWaterUseWateringList: `${mainBaseUrl}/act-of-payment/irrigation-list`,
  useGetDecadeAppList: `${mainBaseUrl}/act-of-payment/decade-application`,
  updateDecadeAppItem: `${mainBaseUrl}/act-of-payment/update/decade-application`,
  updateWaterUseSupplyList: `${mainBaseUrl}/act-of-payment/update/water-supply-list`,
  updateWaterUseWateringList: `${mainBaseUrl}/act-of-payment/update/irrigation-list`,
  actToBill: `${mainBaseUrl}/act-of-payment/act-to-bill/:id`,
  payBill: `${mainBaseUrl}/act-of-payment/pay-bill/:id`,
  updateDecadeActOfMonth: `${mainBaseUrl}/act-of-payment/update/decade-act`,

  uploadFiles: `${mainBaseUrl}/attachment/upload`,

  createAssociationInitialData: `${mainBaseUrl}/water-user/ida/create`,
  updateAssociationInitialData: `${mainBaseUrl}/water-user/ida/update/:id`,

  getContractPdf: '/report/contract',

  getFacilitiesByFilters: `${mainBaseUrl}/facilities/find-by-filter`,
  getFacilitiesByFilter: `${mainBaseUrl}/facilities/find-by-filter`,
  getFacilitiesByOrgId: `${mainBaseUrl}/facility/by-user/:id`,
  getFacilitiesById: `${mainBaseUrl}/facility/id/:id`,

  //facility
  createFacility: `${mainBaseUrl}/facility`,
  updateFacility: `${mainBaseUrl}/facility/update`,
  deleteFacility: `${mainBaseUrl}/facility/:id`,

  addOutlet: `${mainBaseUrl}/water-user/create/outlet/:id`,
  updateOutlet: `${mainBaseUrl}/water-user/update/outlet/:id`,
  deleteOutlet: `${mainBaseUrl}/water-user/delete/outlet/:waterUserId/:id`,

  contractualWatterSuplyPlan: `${mainBaseUrl}/annual-contract/contractual-water-supply-plan`,
  economicPlan: `${mainBaseUrl}/annual-contract/economic-plan`,
  findAllAnnualContractList: `${mainBaseUrl}/annual-contract/table/list`,
  createAnnualContract: `${mainBaseUrl}/annual-contract/create`,
  updateAnnualContractStatus: `${mainBaseUrl}/annual-contract/update-status/:id`,
  calculateAnnualContract: `${mainBaseUrl}/annual-contract/calculate`,
  updateAttachmentsAnnualContract: `${mainBaseUrl}/annual-contract/update/attachments/:id`,
  submitAnnualContract: `${mainBaseUrl}/annual-contract/submit/:id`,
  cancelAnnualContract: `${mainBaseUrl}/annual-contract/cancel/:id`,
  getAnnualContractById: `${mainBaseUrl}/annual-contract/:id`,
  getLegalDataContract: `${mainBaseUrl}/annual-contract/get-entity-contract/:id`,
  getIndividualDataContract: `${mainBaseUrl}/annual-contract/get-individual-contract/:id`,
  getAssociationDataContract: `${mainBaseUrl}/annual-contract/get-association-contract/:id`,
  addAnnualContractPlantingPlan: `${mainBaseUrl}/annual-contract/add/actual-planting-plan`,
  deleteAnnualContractPlantingPlan: `${mainBaseUrl}/annual-contract/delete/actual-planting-plan/:id`,
  updateAnnualContractPlantingPlan: `${mainBaseUrl}/annual-contract/update/actual-planting-plan/:id`,

  getWaterUsePlan: `${mainBaseUrl}/annual-planning/get-plan`,
  getWaterUsePlanByGuvh: `${mainBaseUrl}/annual-planning/get-plan/guvh`,
  getWaterUsePlanByCa: `${mainBaseUrl}/annual-planning/get-plan/ca`,
  getCropSowingPlan: `${mainBaseUrl}/annual-planning/get-plan-crops-sowing`,
  getCropSowingPlanByGuvh: `${mainBaseUrl}/annual-planning/get-plan-crops-sowing/guvh`,
  getCropSowingPlanByCa: `${mainBaseUrl}/annual-planning/get-plan-crops-sowing/ca`,

  updateCropSowingPlan: `${mainBaseUrl}/annual-planning/update-plan-crops-sowing`,
  cancelApproval: `${mainBaseUrl}/annual-planning/cancel-approval`,
  getContractualPlan: `${mainBaseUrl}/annual-planning/get-plan-contractual`,
  getContractualPlanByGuvh: `${mainBaseUrl}/annual-planning/get-plan-contractual/guvh`,
  getContractualPlanByCa: `${mainBaseUrl}/annual-planning/get-plan-contractual/ca`,
  updateContractualPlan: `${mainBaseUrl}/annual-planning/update-plan-contractual`,

  getStatuses: `${mainBaseUrl}/annual-planning/status-all`,
  getStatusesByGuvh: `${mainBaseUrl}/annual-planning/status-all/guvh`,
  getStatusesByCa: `${mainBaseUrl}/annual-planning/status-all/ca`,

  getSummaryWaterUsePlan: `${mainBaseUrl}/annual-planning/summary/water-use-plan`,
  getSummaryWaterUsePlanByGuvh: `${mainBaseUrl}/annual-planning/summary/water-use-plan/guvh`,

  getSummaryWaterUsePlanByCa: `${mainBaseUrl}/annual-planning/summary/water-use-plan/ca`,

  getGovSummaryWaterUsePlan: `${mainBaseUrl}/annual-planning/summary/water-use-plan-by-systems`,

  findAllTariffList: `${mainBaseUrl}/tariff/find-tariffs/by-filters`,
  getTariffById: `${mainBaseUrl}/tariff/get-tariff-by-id/:id`,
  getTariffByRuvhId: `${mainBaseUrl}/tariff/find-tariffs`,
  createTariff: `${mainBaseUrl}/tariff/create`,

  getReservoirDataMonth: `${mainBaseUrl}/reservoir/get-reservoir-data-month`,
  getReservoirs: `${mainBaseUrl}/reservoir/all`,
  getReservoirById: `${mainBaseUrl}/reservoir/by-id/:id`,
  createReservoirData: `${mainBaseUrl}/reservoir/reservoir-data-entry`,
  // updateReservoirData: `${mainBaseUrl}/reservoir/reservoir-data-entry/update`,
  getReservoirCurrentVolume: `${mainBaseUrl}/reservoir/get-start-volume`,
  createStartVolume: `${mainBaseUrl}/reservoir/create-start-volume`,

  getPoints: `${mainBaseUrl}/quality-monitoring/get-points`,
  getZones: `${mainBaseUrl}/quality-monitoring/get-zones`,
  getZone: `${mainBaseUrl}/quality-monitoring/get-zone/:id`,
  getQualityMonitoring: `${mainBaseUrl}/quality-monitoring/get-zone-type`,
  getPointType: `${mainBaseUrl}/quality-monitoring/get-point-type`,
  getReclamationStates: `${mainBaseUrl}/quality-monitoring/get-reclamation-states`,
  createPoint: `${mainBaseUrl}/quality-monitoring/create-point`,
  createZone: `${mainBaseUrl}/quality-monitoring/create-zone`,
  createReclamationState: `${mainBaseUrl}/quality-monitoring/create-reclamation-state`,
  createDepthOccurrence: `${mainBaseUrl}/quality-monitoring/create-depth-occurrence`,
  createLaboratoryTests: `${mainBaseUrl}/quality-monitoring/create-laboratory-tests`,
  getReclamationData: `${mainBaseUrl}/quality-monitoring/get-reclamation-data`,
  getDepthOccurrence: `${mainBaseUrl}/quality-monitoring/get-depth-occurrence`,
  getLaboratoryTests: `${mainBaseUrl}/quality-monitoring/get-laboratory-tests`,
  getContractualWaterSupply: `${mainBaseUrl}/reports/contractual-water-supply`,
  getWaterConsumption: `${mainBaseUrl}/reports/water-supply`,
  getConcludingAgreement: `${mainBaseUrl}/reports/conclusion-contracts`,
  getWaterManagementCounters: `${mainBaseUrl}/water-management/counters`,

  getGmrSystemsByDistrict: `${mainBaseUrl}/zone/get-systems-by-district`,
  getGmrBindedSystems: `${mainBaseUrl}/zone/get-binded-outlets`,
  createGmrSystemOutlets: `${mainBaseUrl}/zone/create/gmr-system-outlets`,
  deleteGmrSystemOutlets: `${mainBaseUrl}/zone/delete/gmr-system-outlets`,
  getAllAgroClimaticZones: `${mainBaseUrl}/zone/list`,
  getAgroClimaticZoneById: `${mainBaseUrl}/zone/by-id/:id`,
  updateAgroClimaticZone: `${mainBaseUrl}/zone/:id/update`,
  getPlantingList: `${mainBaseUrl}/zone/planting-list`,
  updateGmrPlantingList: `${mainBaseUrl}/zone/update/gmr-planting-list`,
  getGmrPlantingList: `${mainBaseUrl}/zone/gmr-planting-list`,

  deleteHydroFacilitySystem: `${mainBaseUrl}/hydro-facility/delete/hydro-facility-system-outlets`,
  deleteHydroFacility: `${mainBaseUrl}/hydro-facility/delete/:id`,

  createHydroFacilitySystem: `${mainBaseUrl}/hydro-facility/create/hydro-facility-system-outlets`,
  getHydroFacilityAll: `${mainBaseUrl}/hydro-facility/all`,

  geoPortalGetDataLayers: `${mainBaseUrl}/geo-portal/get-data`,
  irrigationSystemMaps: `${mainBaseUrl}/geo-portal/filter-maps/irrigation-systems`,
  waterusersMaps: `${mainBaseUrl}/geo-portal/filter-maps/water-users`,
  getAllSystems: `${demoDataUrl}/systems`,
  getAllSensors: `${demoDataUrl}/sensors`,
  findAllHydroposts: `${demoDataUrl}/hydroposts`,
  getAllMeasurements: `${demoDataUrl}/system/measurements/`,
  findSystemsByWithFilters: `${demoDataUrl}/system/hourly/`,
  findHydropostsByWithTable: `${demoDataUrl}/hydropost/hourly/`,
  getHydropostsByType: `${demoDataUrl}/hydroposts/types`,

  updateWaterAccountingHydropostFacts: `${mainBaseUrl}/watering-plan/water-log/hydropost-fact/save`,
  updateWaterAccountingOutletFacts: `${mainBaseUrl}/watering-plan/water-log/outlet-fact/save`,
  getHydrometricWorkPlan: `${mainBaseUrl}/annual-planning/get-hydrometric-work-plan`,
  getHydrometricWorkPlanByGuvh: `${mainBaseUrl}/annual-planning/get-hydrometric-work-plan/guvh`,
  getHydrometricWorkPlanByCa: `${mainBaseUrl}/annual-planning/get-hydrometric-work-plan/ca`,

  updateWaterUsePlan: `${mainBaseUrl}/annual-planning/update-plan`,
  updateHydrometricWorkPlan: `${mainBaseUrl}/annual-planning/update-hydrometric-work-plan`,
  updateStatusWaterUsePlan: `${mainBaseUrl}/annual-planning/update-status`,

  calculateWaterUsePlan: `${mainBaseUrl}/annual-planning/calculate-plan`,

  approvePlan: `${mainBaseUrl}/annual-planning/approve-plan`,
  getOwnerChilds: `${mainBaseUrl}/annual-planning/owner/children/:ownerId`,

  //reports
  get2tpChangeStatus: `${mainBaseUrl}/reports/2tp/change/status/:year`,
  get2tpWaterUseData: `${mainBaseUrl}/reports/2tp/data/water-use/:year`,
  get2tpTotalWaterUseData: `${mainBaseUrl}/reports/2tp/total/water-use/:year`,
  get2tpDrainage: `${mainBaseUrl}/reports/2tp/data/drainage/:year`,
  get2tpList: `${mainBaseUrl}/reports/2tp/list`,
  update2tpWaterUseData: `${mainBaseUrl}/reports/2tp/data/water-use/:year`,
  update2tpDrainage: `${mainBaseUrl}/reports/2tp/data/drainage/:year`,
  get2tpWaterUseConsolidatedData: `${mainBaseUrl}/reports/2tp/water-use/:year`,
  get2tpConsolidatedDrainage: `${mainBaseUrl}/reports/2tp/drainage/:year`,

  getOwnerInfo: `${mainBaseUrl}/owner/info`,
  updateOwnerInfo: `${mainBaseUrl}/owner/info`,

  getOwnerInfoById: `${mainBaseUrl}/owner/info/:id`,
  updateOwnerInfoById: `${mainBaseUrl}/owner/info/:id`,

  getDependentOwnerList: `${mainBaseUrl}/owner`,

  //permissions
  deletePermissionById: `${mainBaseUrl}/auth/delete/permission/:id`,
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addAnnualContractPlantingPlan: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.addAnnualContractPlantingPlan,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),
    deleteAnnualContractPlantingPlan: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.deleteAnnualContractPlantingPlan.replace(':id', queryArg.id),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),
    updateAnnualContractPlantingPlan: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.updateAnnualContractPlantingPlan.replace(':id', queryArg.id),
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),
    savePhysicalWaterUser: build.mutation<
      savePhysicalWaterUserApiResponse,
      savePhysicalWaterUserApiArg
    >({
      query: (queryArg) => ({
        url: URLS.savePhysicalWaterUser,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.WaterUserPhysical],
    }),
    findAllRuvhOrganizations: build.query<
      FindAllRuvhOrganizationsApiResponse,
      FindAllRuvhOrganizationsApiArg
    >({
      query: () => ({ url: URLS.findAllRuvhOrganizations }),
    }),
    saveLegalsWaterUser: build.mutation<
      saveLegalsWaterUserApiResponse,
      saveLegalsWaterUserApiArg
    >({
      query: (queryArg) => ({
        url: URLS.saveLegalsWaterUser,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    waterUserCreate: build.mutation<
      saveLegalsWaterUserApiResponse,
      saveLegalPersonApiArg
    >({
      query: (queryArg) => {
        return {
          url: URLS.waterUserCreate,
          method: 'POST',
          body: queryArg,
        };
      },
    }),
    saveIrrigationSystem: build.mutation<
      SaveIrrigationSystemApiResponse,
      SaveIrrigationSystemApiArg
    >({
      query: (queryArg) => ({
        url: URLS.saveIrrigationSystem,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
    }),

    createBank: build.mutation<CreateBankApiResponse, CreateBankApiArg>({
      query: (queryArg) => ({
        url: URLS.createBank,
        method: 'POST',
        body: queryArg.bankDto,
      }),
      invalidatesTags: [Tags.Bank],
    }),

    uploadFiles: build.mutation<UploadFilesApiResponse, FormData>({
      query: (queryArg) => {
        return {
          url: URLS.uploadFiles,
          method: 'POST',
          body: queryArg,
        };
      },
    }),
    signUp: build.mutation<SignUpApiResponse, Array<SignUpApiArg>>({
      query: (queryArg) => {
        return {
          url: URLS.signUp,
          method: 'POST',
          body: queryArg,
        };
      },
    }),

    getReferencesByParentId: build.query<any, string>({
      query: (queryArg) => ({
        url: URLS.getReferencesByParentId.replace(':queryArgs', queryArg),
      }),
      transformResponse: (response: Array<CommonReference>) =>
        response.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        })),
    }),
    getReferencesByParentIdAndType: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getReferencesByParentIdAndType,
        params: queryArg,
      }),
      transformResponse: (response: Array<CommonReference>) =>
        response.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        })),
    }),
    getAllCommonReferencesList: build.query<any, { typeCode: string }>({
      query: ({ typeCode }) => {
        return {
          url: URLS.getAllCommonReferencesList.replace(
            ':typeCode',
            typeCode.toString(),
          ),
        };
      },
      providesTags: [Tags.CommonReferencesList],
    }),
    createCommonReference: build.mutation<{ typeCode: string }, any>({
      query: ({ typeCode, body }) => {
        return {
          url: URLS.createCommonReference.replace(
            ':typeCode',
            typeCode.toString(),
          ),
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [Tags.CommonReferencesList],
    }),

    updateCommonReference: build.mutation<{ id: string }, any>({
      query: ({ id, body }) => {
        return {
          url: URLS.updateCommonReference.replace(':id', id),
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [Tags.CommonReferencesList],
    }),
    deleteCommonReference: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: URLS.deleteCommonReference.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.CommonReferencesList],
    }),
    getSystemObjectById: build.query<
      FindSystemObjectByIdApiResponse,
      FindSystemObjectByIdApiArg
    >({
      query: ({ id }) => ({
        url: URLS.getSystemObjectById.replace(':id', id),
        method: 'GET',
      }),
    }),
    getFacilitiesByFilters: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getFacilitiesByFilters,
        method: 'GET',
        params: queryArg,
      }),
    }),
    findAllPhysical: build.query<
      FindAllPhysicalApiResponse,
      FindAllPhysicalApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllPhysical,
        params: {
          ...queryArg,
          sort: ['desc'],
        },
      }),
      providesTags: [Tags.WaterUserPhysical],
    }),
    getAllIrrigationSystem: build.query<any, void>({
      query: () => ({
        url: URLS.getAllIrrigationSystem,
      }),
      providesTags: [Tags.WaterUserPhysical],
      transformResponse: (response: Array<getAllIrrigationSystemApiResponse>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getAllWaterUser: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getAllWaterUsers,
        params: {
          ...queryArg,
          sort: ['desc'],
        },
      }),
      providesTags: [Tags.WaterUserPhysical],
    }),
    findAllByOrganizationId: build.query<
      FindAllByOrganizationIdApiResponse,
      FindAllByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllByWaterUserId.replace(':id', queryArg.id?.toString()),
      }),
      providesTags: [
        Tags.WaterUserAssociation,
        Tags.WaterUserLegal,
        Tags.WaterUserPhysical,
      ],
    }),
    getWaterUserEmployeesId: build.query<
      FindAllByOrganizationIdApiResponse,
      FindAllByOrganizationIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.getAllByWaterUserEmployeesId.replace(
          ':id',
          queryArg.id?.toString(),
        ),
      }),
      providesTags: [
        Tags.WaterUserAssociation,
        Tags.WaterUserLegal,
        Tags.WaterUserPhysical,
      ],
    }),
    findAllLegalPerson: build.query<
      FindAllLegalPersonApiResponse,
      FindAllLegalPersonApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllLegalPerson,
        params: {
          ...queryArg,
          sort: ['desc'],
        },
      }),
      providesTags: [Tags.WaterUserLegal],
    }),
    findAllAssociation: build.query<
      FindAllAssociationApiResponse,
      FindAllAssociationApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllAssociation,
        params: {
          ...queryArg,
        },
      }),
      providesTags: [Tags.WaterUserAssociation],
    }),
    searchAllWaterUsersByOrganization: build.query<any, any>({
      query: (queryArg: { inn: string; fio: string; userType: string }) => ({
        url: URLS.searchAllWaterUsers,
        params: queryArg,
      }),
      providesTags: [Tags.WaterUserAll],
    }),
    findAllUsers: build.query<FindAllUsersApiResponse, FindAllUsersApiArg>({
      query: (queryArg: IWaterRegistryFilters) => ({
        url: URLS.findAllUsers,
        params: {
          ...queryArg,
          sort: ['desc'],
        },
      }),
      transformResponse: (response: PaginationWrapper<WaterUserTableDto>) => {
        const content = response.content.map((item) => ({
          ...item,
          // type: item.type.replace(' ', '.') as WaterUserTypes,
        }));
        return {
          ...response,
          content,
        };
      },
      providesTags: [Tags.WaterUserAll],
    }),
    findDictionaryByTypeId: build.query<
      FindDictionaryByTypeIdApiResponse,
      FindDictionaryByTypeIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findDictionaryByTypeId.replace(':id', queryArg.id.toString()),
      }),
      transformResponse: (response: Array<CommonReference>) =>
        response.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        })),
    }),
    findDictionaryItemByTypeId: build.query<
      FindDictionaryItemByTypeIdApiResponse,
      FindDictionaryItemByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findDictionaryItemByTypeId.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
    }),
    findAllIrrigationSystemList: build.query<
      WaterAccountingDecadesResponse<IrrigationSystem>,
      FindAllIrrigationSystemListApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllIrrigationSystemList,
        params: {
          ...queryArg,
          sort: 'DESC',
        },
      }),
      providesTags: [Tags.IrrigationSystem],
    }),
    findAllIrrigationSystemObjectsList: build.query<
      FindAllIrrigationSystemObjectsListApiResponse,
      FindAllIrrigationSystemObjectsListApiArg
    >({
      query: () => ({ url: URLS.findAllIrrigationSystemObjectsList }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    findAllIrrigationSystemObjectsListHydroposts: build.query<
      FindAllIrrigationSystemObjectsListOutletsApiResponse,
      FindAllIrrigationSystemObjectsListOutletsApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllIrrigationSystemObjectsListHydroposts,
        params: queryArg,
      }),
    }),
    findAllIrrigationSystemsByHydroFacilityId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.findAllIrrigationSystemsByHydroFacilityId,
        params: queryArg,
      }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getAllIrrigationSystemsByHydroFacilityId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getAllIrrigationSystemsByHydroFacilityId,
        params: queryArg,
      }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    findAllChannelsByIrrigationSystemId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.findAllChannelsByIrrigationSystemId,
        params: queryArg,
      }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getNotBindedOutletsByChannelId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getNotBindedOutletsByChannelId,
        params: queryArg,
      }),
      providesTags: [Tags.HydroModule],
    }),
    findAllOutletsByChannelId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.findAllOutletsByChannelId,
        params: queryArg,
      }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      providesTags: [Tags.HydroModule],
    }),
    findAllIrrigationSystemObjectsListOutlets: build.query<
      FindAllIrrigationSystemObjectsListOutletsApiResponse,
      FindAllIrrigationSystemObjectsListOutletsApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllIrrigationSystemObjectsListOutlets,
        params: queryArg,
      }),
    }),
    findAllIrrigationSystemObjectsListSources: build.query<
      FindAllIrrigationSystemObjectsListSourcesApiResponse,
      FindAllIrrigationSystemObjectsListSourcesApiArg
    >({
      query: () => ({ url: URLS.findAllIrrigationSystemObjectsListSources }),
    }),
    findAllIrrigationSystemObjectsListChannels: build.query<
      FindAllIrrigationSystemObjectsListChannelsApiResponse,
      FindAllIrrigationSystemObjectsListChannelsApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllIrrigationSystemObjectsListChannels,
        params: queryArg,
      }),
    }),
    findAllBanks: build.query<FindAllBanksApiResponse, FindAllBanksApiArg>({
      query: () => ({ url: URLS.findAllBanks }),
      providesTags: [Tags.Bank],
    }),
    findBankById: build.query<FindBankByIdApiResponse, FindBankByIdApiArg>({
      query: (queryArg) => ({
        url: URLS.findBankById.replace(':id', queryArg.id.toString()),
      }),
    }),
    findAllFilial: build.query<FindAllFilialApiResponse, FindAllFilialApiArg>({
      query: (queryArg: any) => ({
        url: URLS.findAllFilial.replace(':id', queryArg.bankId.toString()),
      }),
      providesTags: [Tags.Bank],
    }),
    findOperationalBlockById: build.query<
      FindOperationalBlockByIdApiResponse,
      FindOperationalBlockByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findOperationalBlockById.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
      providesTags: [Tags.OperationalBlock],
    }),
    findRegularEventById: build.query<
      FindRegularEventByIdApiResponse,
      FindRegularEventByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findRegularEventById.replace(':id', queryArg.id.toString()),
      }),
    }),

    findConstructionBlockById: build.query<
      FindConstructionBlockByIdApiResponse,
      FindConstructionBlockByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findConstructionBlockById.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
      providesTags: [Tags.ConstructionBlock],
    }),
    findIrrigationSystemById: build.query<
      FindIrrigationSystemByIdResponse,
      FindIrrigationSystemByIdArg
    >({
      query: (queryArg) => ({
        url: URLS.findIrrigationSystemById.replace(
          ':id',
          queryArg.irrigationSystemId.toString(),
        ),
      }),
      providesTags: [Tags.IrrigationSystem],
    }),
    getIrrigationSystemInfoById: build.query<
      GetIrrigationSystemInfoByIdResponse,
      GetIrrigationSystemInfoByIdArg
    >({
      query: (queryArg) => ({
        url: URLS.getIrrigationSystemInfoById.replace(
          ':id',
          queryArg.irrigationSystemId.toString(),
        ),
      }),
      providesTags: [Tags.IrrigationSystem],
    }),
    getIrrigationSystemObjectsAndStructureById: build.query<
      GetIrrigationSystemObjectsAndStructureByIdResponse,
      GetIrrigationSystemObjectsAndStructureByIdArg
    >({
      query: ({ irrigationSystemId, params }) => ({
        url: URLS.getIrrigationSystemObjectsAndStructureById.replace(
          ':id',
          irrigationSystemId.toString(),
        ),
        params,
      }),
      providesTags: [Tags.WaterObject],
    }),
    getIrrigationWaterUsersById: build.query<
      GetIrrigationWaterUsersByIdResponse,
      GetIrrigationWaterUsersByIdArg
    >({
      query: ({ irrigationSystemId, params }) => ({
        url: URLS.getIrrigationWaterUsersById.replace(
          ':id',
          irrigationSystemId.toString(),
        ),
        params,
      }),
      providesTags: [
        Tags.WaterUserPhysical,
        Tags.WaterUserAssociation,
        Tags.WaterUserLegal,
        Tags.WaterUserAll,
      ],
    }),
    balanceAccountingIrrigation: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.balanceAccountingIrrigation.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
    }),
    updateIrrigationSystem: build.mutation<
      SaveIrrigationSystemWaterObjectApiResponse,
      SaveIrrigationSystemWaterObjectApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateIrrigationSystem,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.IrrigationSystem],
    }),

    addGeonode: build.mutation<AddGeonodeApiResponse, AddGeonodeApiArg>({
      query: (queryArg) => ({
        url: URLS.addGeonode,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getAllOperational: build.query<GetAllOperationalApiResp, any>({
      query: (queryArg) => {
        return {
          url: URLS.getAllOperational,
          method: 'POST',
          body: queryArg,
        };
      },
      providesTags: [Tags.OperationalBlockList],
    }),
    getAllRegular: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.getAllRegular,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getAllConstruction: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getAllConstruction,
        method: 'POST',
        body: queryArg,
      }),
      providesTags: [Tags.BuildingBlockList],
    }),
    getEventWorkGantt: build.mutation<GanttResponse, any>({
      query: (queryArg) => ({
        url: URLS.getEventWorkGantt,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getAllExtraTask: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getAllExtraTask,
        method: 'POST',
        body: queryArg,
      }),
      providesTags: [Tags.ExtraTaskList],
    }),

    checkObjecsCompare: build.mutation<any, string>({
      query: (systemId) => ({
        url: URLS.checkObjecsCompare,
        params: {
          systemId,
        },
        method: 'GET',
      }),
    }),

    createTaskOperationalBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createTaskOperationalBlock.replace(':id', id.toString()),
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        { type: Tags.OperationalBlock, id },
      ],
    }),
    saveTaskOperationalBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveTaskOperationalBlock,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalBlock, 'operationalTemplatesList'],
    }),

    saveReportOperationalBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveReportOperationalBlock,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalBlock],
    }),

    operationalBlockSetControl: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.operationalBlockSetControl,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalBlock],
    }),
    constructionBlockSetControl: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.constructionBlockSetControl,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.ConstructionBlock],
    }),
    createReportOperationalBlock: build.mutation<any, number>({
      query: (queryArg) => ({
        url: URLS.createReportOperationalBlock.replace(
          ':query',
          queryArg.toString(),
        ),
        method: 'POST',
      }),
      invalidatesTags: [Tags.OperationalBlock],
    }),

    saveClosingReportOperationalBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveClosingReportOperationalBlock,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalBlock],
    }),
    createCloseReportOperationalBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createCloseReportOperationalBlock.replace(
          ':id',
          id.toString(),
        ),
        method: 'POST',
      }),
      invalidatesTags: [Tags.OperationalBlock],
    }),
    createTaskRegularEventBlock: build.mutation<any, string>({
      query: (queryArg) => ({
        url: URLS.createTaskRegularEventBlock,
        method: 'POST',
        body: queryArg,
      }),
    }),
    createOtherTaskBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createOtherTaskBlock.replace(':id', id.toString()),
        method: 'POST',
      }),
    }),
    saveOtherTaskBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveOtherTaskBlock,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.ExtraTaskTemplate],
    }),

    createOtherTaskReport: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createOtherTaskReport.replace(':id', id.toString()),
        method: 'POST',
      }),
    }),

    saveOtherTaskReport: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveOtherTaskReport,
        method: 'POST',
        body: queryArg,
      }),
    }),

    createReportRegularEventBlock: build.mutation<any, string>({
      query: (queryArg) => ({
        url: URLS.createReportRegularEventBlock,
        method: 'POST',
        body: queryArg,
      }),
    }),
    saveTaskBuildingBlock: build.mutation<any, string>({
      query: (queryArg) => ({
        url: URLS.saveTaskBuildingBlock,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.BuildingTemplate],
    }),
    createTaskBuildingBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createTaskBuildingBlock.replace(':id', id.toString()),
        method: 'POST',
      }),
      invalidatesTags: [Tags.BuildingTemplate],
    }),
    saveReportBuildingBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveReportBuildingBlock,
        method: 'POST',
        body: queryArg,
      }),
    }),
    createReportBuildingBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createReportBuildingBlock.replace(':id', id.toString()),
        method: 'POST',
      }),
    }),
    saveCloseReportBuildingBlock: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.saveCloseReportBuildingBlock,
        method: 'POST',
        body: queryArg,
      }),
    }),
    createCloseReportBuildingBlock: build.mutation<any, number>({
      query: (id) => ({
        url: URLS.createCloseReportBuildingBlock.replace(':id', id.toString()),
        method: 'POST',
      }),
    }),
    updateSystemObject: build.mutation<
      UpdateSystemObjectApiResponse,
      UpdateSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateSystemObject,
        method: 'PUT',
        body: queryArg.waterObject,
      }),
      invalidatesTags: [Tags.WaterObject],
    }),
    getBuildingTemplates: build.query<any, string>({
      query: (templateName = '') => ({
        url: URLS.getBuildingTemplates,
        params: { templateName },
      }),
      providesTags: [Tags.ConstructionEventList, Tags.BuildingTemplate],
    }),
    getOutletHydroposts: build.query<any, { outletId: number }>({
      query: (queryArgs) => ({
        url: URLS.getOutletHydroposts,
        params: queryArgs,
        providesTags: [Tags.ConstructionEventList],
      }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.objectName,
          value: item.objectId,
        })),
    }),
    getBuildingTemplateById: build.query<any, string>({
      query: (id) => ({
        url: URLS.getBuildingTemplateById.replace(':id', id),
      }),
    }),
    getOperationalTemplates: build.query<any, string>({
      query: (templateName = '') => ({
        url: URLS.getOperationalTemplates,
        params: { templateName },
      }),
      providesTags: ['operationalTemplatesList'],
    }),
    getOperationalTemplateById: build.query<any, string>({
      query: (id) => ({
        url: URLS.getOperationalTemplateById.replace(':id', id),
      }),
    }),
    getRegularEventTemplates: build.query<any, string>({
      query: (templateName = '') => ({
        url: URLS.getRegularEventTemplates,
        params: { templateName },
        // providesTags: ['constructionEventList'],
      }),
    }),

    getExtraTaskTemplates: build.query<any, string>({
      query: (templateName = '') => ({
        url: URLS.getExtraTaskTemplates,
        params: { templateName },
      }),
      providesTags: [Tags.ExtraTaskTemplate],
    }),
    getExtraTaskTemplateById: build.query<any, string>({
      query: (id) => ({
        url: URLS.getExtraTaskTemplateById.replace(':id', id),
        // providesTags: ['constructionEventList'],
      }),
    }),
    getOperationalById: build.query<any, number>({
      query: (id) => ({
        url: URLS.getOperationalById.replace(':id', id.toString()),
      }),
      providesTags: (result, error, id) => [
        { type: Tags.OperationalBlock, id },
      ],
    }),
    getOtherTaskById: build.query<IOtherTask, string>({
      query: (queryArg) => ({
        url: URLS.getOtherTaskById.replace(':query', queryArg),
      }),
    }),
    getRegularEventById: build.query<any, number>({
      query: (queryArg) => ({
        url: URLS.getRegularEventById.replace(':query', queryArg.toString()),
      }),
    }),
    getEmployeeOwnerListById: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getEmployeeOwnerListById.replace(':query', queryArg),
      }),
    }),
    createEmployeeOwner: build.mutation<any, any>({
      query: ({ waterUserId, ownerId, body }) => ({
        url: URLS.createEmployeeOwner.replace(':waterUserId', waterUserId),
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        Tags.OwnerInfoById,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAssociation,
      ],
    }),
    updateEmployeeOwner: build.mutation<any, any>({
      query: ({ waterUserId, id, body }) => ({
        url: URLS.updateEmployeeOwner
          .replace(':id', id.toString())
          .replace(':waterUserId', waterUserId.toString()),
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        Tags.OwnerInfoById,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAssociation,
      ],
    }),
    deleteEmployeeOwner: build.mutation<any, any>({
      query: ({ id }) => ({
        url: URLS.deleteEmployeeOwner.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [
        Tags.OwnerInfoById,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAssociation,
      ],
    }),
    createSystemObjectList: build.mutation<
      CreateSystemObjectListApiResponse,
      CreateSystemObjectListApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createSystemObjectList,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.WaterObject],
    }),
    createSystemObject: build.mutation<
      CreateSystemObjectApiResponse,
      CreateSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createSystemObject,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.WaterObject, Tags.Reservoir],
    }),
    regularEventList: build.query<
      FindAllRegularEventListApiResponse,
      FindAllRegularEventListApiArg
    >({
      query: () => ({
        url: URLS.regularEventList,
        providesTags: [Tags.RegularEventList],
      }),
    }),
    extraTaskEventList: build.query<
      FindAllExtraTaskEventListApiResponse,
      FindAllExtraTaskEventListApiArg
    >({
      query: () => ({
        url: URLS.extraTaskEventList,
      }),
      providesTags: [Tags.TaskEventList],
    }),

    operationalBlockEventList: build.query<
      FindAllOperationalBlockEventListApiResponse,
      FindAllOperationalBlockEventListApiArg
    >({
      query: (queryArg = {}) => ({
        url: URLS.operationalBlockEventList,
        params: queryArg,
      }),
      providesTags: [Tags.OperationalEventList],
    }),
    constructionBlockEventList: build.query<
      FindAllConstructionBlockEventListApiResponse,
      FindAllConstructionBlockEventListApiArg
    >({
      query: (queryArg = {}) => ({
        url: URLS.constructionBlockEventList,
        params: queryArg,
      }),
      providesTags: [Tags.ConstructionEventList],
    }),
    toArchiveOperationalBlock: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toArchiveOperationalBlock.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.OperationalBlockList, Tags.OperationalEventList],
    }),
    toUnArchiveOperationalBlock: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toUnArchiveOperationalBlock.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.OperationalBlockList, Tags.OperationalEventList],
    }),
    toArchiveBuildingBlock: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toArchiveBuildingBlock.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.BuildingBlockList, Tags.ConstructionEventList],
    }),
    toUnArchiveBuildingBlock: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toUnArchiveBuildingBlock.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.BuildingBlockList, Tags.ConstructionEventList],
    }),
    toArchiveExtraTask: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toArchiveExtraTask.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.ExtraTaskList, Tags.TaskEventList],
    }),
    toUnArchiveExtraTask: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.toUnArchiveExtraTask.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.ExtraTaskList, Tags.TaskEventList],
    }),
    getEmployeeOwnerList: build.query<any, void>({
      query: () => ({
        url: URLS.getEmployeeOwnerList,
      }),
    }),
    createWaterSection: build.mutation<
      CreateWaterSectionApiResponse,
      CreateWaterSectionApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createWaterSection,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.WaterSection],
    }),

    deleteWaterSection: build.mutation<void, number>({
      query: (queryArg) => ({
        url: URLS.deleteWaterSection.replace(':queryArg', queryArg.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.WaterSection],
    }),
    getWaterSectionsBySystemObjectId: build.query<
      GetWaterSectionsBySystemObjectIdApiResponse,
      GetWaterSectionsBySystemObjectIdApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterSectionsBySystemObjectId.replace(
          ':queryArg',
          queryArgs.toString(),
        ),
      }),
      providesTags: [Tags.WaterSection],
    }),

    updateSystemObjectStatus: build.mutation<
      UpdateSystemObjectStatusApiResponse,
      UpdateSystemObjectStatusApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateSystemObjectStatus.replace(
          ':id',
          queryArg.id.toString(),
        ),
        method: 'PUT',
        body: queryArg.status,
      }),
      invalidatesTags: [Tags.WaterObject],
    }),
    createAqueductWaterObject: build.mutation<
      CreateAqueductWaterObjectApiResponse,
      CreateAqueductWaterObjectApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createAqueductWaterObject,
        method: 'POST',
        body: queryArg.waterObject,
      }),
      invalidatesTags: [Tags.WaterObject, Tags.IrrigationSystem],
    }),
    createWaterObjectsAqueductWaterObject: build.mutation<
      CreateWaterObjectsAqueductWaterObjectApiResponse,
      CreateWaterObjectsAqueductWaterObjectApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createWaterObjectsAqueductWaterObject,
        method: 'POST',
        body: queryArg.waterObjects,
      }),
      invalidatesTags: [Tags.WaterObject],
    }),
    findAllObjectsByAqueductId: build.query<
      FindAllObjectsByAqueductIdApiResponse,
      FindAllObjectsByAqueductIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllObjectsByAqueductId.replace(
          ':id',
          queryArg.objectId.toString(),
        ),
        method: 'GET',
      }),
      providesTags: [Tags.GvsObjectsConstructor, Tags.GtsObjectsConstructor],
    }),
    findAllAllWaterSectionsByAqueductId: build.query<
      FindAllAllWaterSectionsByAqueductIdApiResponse,
      FindAllAllWaterSectionsByAqueductIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllAllWaterSectionsByAqueductId.replace(
          ':id',
          queryArg.objectId.toString(),
        ),
        method: 'GET',
      }),
      providesTags: [Tags.GvsObjectsConstructor, Tags.WaterSection],
    }),
    aquedactObjectsSectionConstructor: build.mutation<
      AquedactObjectsSectionConstructorApiResponse,
      AquedactObjectsSectionConstructorApiArg
    >({
      query: (queryArg) => ({
        url: URLS.aquedactObjectsSectionConstructor,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.GvsObjectsConstructor],
    }),
    getAqueductListBySystemId: build.query<
      GetAqueductListBySystemIdApiResponse,
      GetAqueductListBySystemIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.getAqueductListBySystemId.replace(
          ':id',
          queryArg.systemId.toString(),
        ),
      }),
      providesTags: [
        Tags.MainConduitConstructor,
        Tags.GtsObjectsConstructor,
        Tags.WaterObject,
      ],
    }),

    getQuentityIndicators: build.query<
      WaterAccountingResponse,
      WaterAccountingQueryArg
    >({
      query: (queryArg) => ({
        url: URLS.getQuentityIndicators,
        params: {
          month: queryArg.month,
          decadeNumber: queryArg.decadeNumber,
          sort: queryArg.sort,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
      providesTags: [Tags.WaterQuentity],
    }),
    updateQuentityIndicator: build.mutation<WaterQuentityArg, any>({
      query: (queryArg) => ({
        url: URLS.updateQuentityIndicator,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.WaterQuentity],
    }),
    getWaterSupplyList: build.query(
      // WaterSupplyResponse,
      // WaterAccountingQueryArg
      {
        query: (queryArg) => ({
          url: URLS.getWaterSupplyList,
          params: {
            month: queryArg.month,
            decadeNumber: queryArg.decadeNumber,
            sort: queryArg.sort,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: [Tags.WaterSupply],
      },
    ),
    aqueductToAqueduct: build.mutation<
      AqueductToAqueductApiResponse,
      AqueductToAqueductApiArg
    >({
      query: (queryArg) => ({
        url: URLS.aqueductToAqueduct,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.MainConduitConstructor],
    }),
    aqueductGtsConstructor: build.mutation<
      AqueductGtsConstructorApiResponse,
      AqueductGtsConstructorApiArg
    >({
      query: (queryArg) => ({
        url: URLS.aqueductGtsConstructor,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.GtsObjectsConstructor, Tags.GvsObjectsConstructor],
    }),
    getObjectCategories: build.query<
      GetObjectCategoriesApiResponse,
      GetObjectCategoriesApiArg
    >({
      query: () => ({
        url: URLS.getObjectCategories,
      }),
      transformResponse: (response: Array<ObjectCategory>) =>
        response.map((item) => ({
          ...item,
          label: item.groupTitle,
          value: item.categoryId,
        })),
    }),
    useGetWaterUseSupplyList: build.query<
      WaterAccountingDecadesResponse<WaterUseSuppyDataContent>,
      IWateringPlanFilters
    >({
      query: (queryArg) => ({
        url: URLS.useGetWaterUseSupplyList,
        params: queryArg,
      }),
      providesTags: [Tags.WaterUseSupply],
    }),
    useGetWaterUseWateringList: build.query<
      WaterAccountingDecadesResponse<WaterUseWateringDataContent>,
      IWateringPlanFilters
    >({
      query: (queryArg) => ({
        url: URLS.useGetWaterUseWateringList,
        params: queryArg,
      }),
      providesTags: [Tags.Watering],
    }),
    GetWateringPlanDecadalRequest: build.query<
      Array<GetWateringPlanDecadalRequestApiResponse>,
      GetWateringPlanDecadalRequestApiArg
    >({
      query: (queryArg) => ({
        url: URLS.getWateringPlanDecadalRequest,
        params: queryArg,
      }),
      providesTags: [Tags.Decade],
    }),
    sendRequestToDecadal: build.mutation<
      SendRequestToDecadalApiResponse,
      SendRequestToDecadalApiArg
    >({
      query: (queryArg) => ({
        url: URLS.sendRequestDecadal,
        body: queryArg,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Decade],
    }),
    saveWateringPlanDecadalRequest: build.mutation<
      Array<GetWateringPlanDecadalRequestApiResponse>,
      SaveWateringPlanDecadalRequestApiArg
    >({
      query: (queryArg) => ({
        url: URLS.saveWateringPlanDecadalRequest,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.Decade],
    }),
    useGetDecadeAppList: build.query<
      WaterAccountingDecadesResponse<WaterDecadeAppDataContent>,
      WaterUseQueryArg
    >({
      query: (queryArg) => ({
        url: URLS.useGetDecadeAppList,
        params: queryArg,
      }),
      providesTags: [Tags.Decade],
    }),
    updateDecadeAppItem: build.mutation<any, DecadeAppBodyArg>({
      query: (queryArg) => ({
        url: URLS.updateDecadeAppItem,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.Decade],
    }),
    updateWaterUseSupplyList: build.mutation<any, DecadeAppBodyArg>({
      query: (queryArg) => ({
        url: URLS.updateWaterUseSupplyList,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.WaterUseSupply],
    }),
    updateWaterUseWateringList: build.mutation<any, DecadeAppBodyArg>({
      query: (queryArg) => ({
        url: URLS.updateWaterUseWateringList,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.Watering],
    }),
    findAllObjectsByAqueductSections: build.query<
      FindAllObjectsByAqueductSectionsApiResponse,
      FindAllObjectsByAqueductSectionsApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllObjectsByAqueductSections.replace(
          ':id',
          queryArg.objectId.toString(),
        ),
      }),
    }),
    findAllObjectGroups: build.query<
      FindAllObjectGroupsApiResponse,
      FindAllObjectGroupsApiArg
    >({
      query: () => ({
        url: URLS.findAllObjectGroups,
      }),
      transformResponse: (response: Array<IObjectGroup>) =>
        response.map((item) => ({
          ...item,
          label: item.groupName,
          value: item.id,
        })),
    }),
    findObjectGroupByCode: build.query<
      FindObjectGroupByCodeApiResponse,
      FindObjectGroupByCodeApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findObjectGroupByCode.replace(
          ':id',
          queryArg.code.toString(),
        ),
      }),
      transformResponse: (response: IObjectGroup) => ({
        ...response,
        label: response.groupName,
        value: response.id,
      }),
    }),
    findAllObjectCategoriesByObjectGroupId: build.query<
      FindAllObjectCategoriesByObjectGroupIdApiResponse,
      FindAllObjectCategoriesByObjectGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAllObjectCategoriesByObjectGroupId.replace(
          ':id',
          queryArg.objectGroupId.toString(),
        ),
      }),
      transformResponse: (response: Array<ObjectCategory>) =>
        response.map((item) => ({
          ...item,
          label: item.groupTitle,
          value: item.categoryId,
        })),
    }),
    findObjectCategoryById: build.query<
      FindObjectCategoryByIdApiResponse,
      FindObjectCategoryByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findObjectCategoryById.replace(
          ':id',
          queryArg?.categoryId?.toString(),
        ),
      }),
      transformResponse: (response: ObjectCategory) => ({
        ...response,
        label: response.groupTitle,
        value: response.categoryId,
      }),
    }),
    deleteAqueductObjectsWithSections: build.mutation<
      DeleteAqueductObjectsWithSectionsApiResponse,
      DeleteAqueductObjectsWithSectionsApiArg
    >({
      query: (queryArg) => ({
        url: URLS.deleteAqueductObjectsWithSections,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.GvsObjectsConstructor],
    }),
    findAqueductById: build.query<
      FindAqueductByIdApiResponse,
      FindAqueductByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findAqueductById.replace(':id', queryArg.objectId.toString()),
      }),
      providesTags: [Tags.GvsObjectsConstructor],
    }),
    findReferencesByParentId: build.query<
      FindReferencesByParentIdApiResponse,
      FindReferencesByParentIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findReferencesByParentId.replace(
          ':id',
          queryArg.parentId.toString(),
        ),
      }),
      transformResponse: (response: Array<CommonReference>) =>
        response.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        })),
    }),
    findGeneralAqueductById: build.query<
      FindGeneralAqueductByIdApiResponse,
      FindGeneralAqueductByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.findGeneralAqueductById.replace(
          ':id',
          queryArg.systemId.toString(),
        ),
      }),
      providesTags: [Tags.GvsObjectsConstructor],
    }),
    getAllBasin: build.query<GetAllBasinApiResponse, GetAllBasinApiArg>({
      query: (queryArgs) => ({
        url: URLS.getAllBasin,
        params: { region_id: queryArgs?.regionId },
      }),
      transformResponse: (response: Array<IBasin>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getWaterUsersByOutlet: build.query<any, GetWaterUsersByOutletApiArg>({
      query: (queryArgs) => ({
        url: URLS.wateringPlanWaterUsersByOutlet,
        params: queryArgs,
      }),
    }),
    getAllSourceTypes: build.query<
      GetAllSourceTypesApiResponse,
      GetAllSourceTypesApiArg
    >({
      query: () => ({
        url: URLS.getAllSourceTypes,
      }),
      transformResponse: (response: Array<ISourceType>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),

    getOutletsByAssociationId: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.getOutletsByAssociationId.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
    }),

    getSources: build.query<GetSourcesApiResponse, GetSourcesApiArg>({
      query: (queryArgs) => ({
        url: URLS.getSources,
        params: queryArgs,
      }),
      providesTags: [Tags.Sources],
      transformResponse: (response: Array<ISource>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getNaturalSources: build.query<
      GetNaturalSourcesApiResponse,
      GetNaturalSourcesApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getNaturalSources,
        params: {
          basin_id: queryArgs.basinId,
          region_id: queryArgs.regionId,
        },
      }),
      transformResponse: (response: Array<INaturalSource>) => {
        return response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));
      },
      providesTags: [Tags.Sources],
    }),
    createSource: build.mutation<CreateSourceApiResponse, CreateSourceIdApiArg>(
      {
        query: (queryArg) => ({
          url: URLS.createSource,
          method: 'POST',
          body: queryArg,
        }),
        invalidatesTags: [Tags.Sources],
      },
    ),
    submitOperationalBlockImplementationTerm: build.mutation<
      SubmitOperationalBlockImplementationTermApiResponse,
      SubmitOperationalBlockImplementationTermApiArg
    >({
      query: (queryArg) => ({
        url: URLS.submitOperationalBlockImplementationTerm,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalBlock, Tags.ConstructionBlock],
    }),
    findAllIrrigationSystemObjectsListWithFilters: build.query<
      FindAllIrrigationSystemObjectsListWithFiltersApiResponse,
      FindAllIrrigationSystemObjectsListWithFiltersApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.findAllIrrigationSystemObjectsListWithFilters,
        params: {
          ...queryArgs,
          sort: 'desc',
        },
      }),
      providesTags: [
        Tags.WaterObject,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAssociation,
      ],
    }),
    findAllActsInvoicesForPayments: build.query<
      FindAllActsInvoicesForPaymentsApiResponse,
      FindAllActsInvoicesForPaymentsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.findAllActsInvoicesForPayments,
        params: {
          ...queryArgs,
          sort: 'desc',
        },
      }),
      providesTags: [Tags.ActInvoicesForPayments],
    }),
    findAllIrrigationSystemsOwners: build.query<
      FindAllIrrigationSystemsOwnersApiResponse,
      FindAllIrrigationSystemsOwnersApiArg
    >({
      query: () => ({
        url: URLS.findAllIrrigationSystemsOwners,
      }),
      transformResponse: (response: Array<Owner>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    constructionBlockAttachmentsUpload: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.constructionBlockAttachmentsUpload,
        method: 'POST',
        body: queryArg,
      }),
    }),
    constructionBlockAttachmentsDelete: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.constructionBlockAttachmentsDelete,
        method: 'POST',
        body: queryArg,
      }),
    }),
    extraTaskBlockAttachmentsUpload: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.extraTaskBlockAttachmentsUpload,
        method: 'POST',
        body: queryArg,
      }),
    }),
    extraTaskBlockAttachmentsDelete: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.extraTaskBlockAttachmentsDelete,
        method: 'POST',
        body: queryArg,
      }),
    }),
    operationalBlockAttachmentsUpload: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.operationalBlockAttachmentsUpload,
        method: 'POST',
        body: queryArg,
      }),
    }),
    operationalBlockAttachmentsDelete: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.operationalBlockAttachmentsDelete,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getContractPdf: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.getContractPdf,
        method: 'POST',
        body: queryArg,
        responseHandler: async (response) =>
          window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
    getAllSystems: build.query<GetAllSystemsResponse[], void>({
      query: () => ({
        url: URLS.getAllSystems,
      }),
    }),
    getAllSensors: build.query<GetAllSensorsResponse[], void>({
      query: () => ({
        url: URLS.getAllSensors,
      }),
    }),
    findAllHydroposts: build.query<FindAllHydropostsResponse[], void>({
      query: () => ({
        url: URLS.findAllHydroposts,
      }),
    }),

    getAllMeasurements: build.query<
      getAllMeasurementsResponse[],
      getAllMeasurementsArg
    >({
      query: (queryArg) => ({
        url: URLS.getAllMeasurements,
        params: {
          id: queryArg.id,
          start_date: queryArg.start_date,
          end_date: queryArg.end_date,
        },
      }),
    }),
    findSystemsByWithFilters: build.query<
      findSystemsByWithFiltersResponse[],
      findSystemsByWithFiltersArg
    >({
      query: (queryArg) => ({
        url: URLS.findSystemsByWithFilters,
        params: {
          id: queryArg.id,
          start_date: queryArg.start_date,
          end_date: queryArg.end_date,
        },
      }),
    }),
    findHydropostsByWithTable: build.query<
      findHydropostsByWithTableResponse[],
      findSystemsByWithFiltersArg
    >({
      query: (queryArg) => ({
        url: URLS.findHydropostsByWithTable,
        params: {
          id: queryArg.id,
          start_date: queryArg.start_date,
          end_date: queryArg.end_date,
        },
      }),
    }),
    getHydropostsByType: build.query<getHydropostsByTypeResponse[], void>({
      query: () => ({
        url: URLS.getHydropostsByType,
      }),
    }),

    actToBill: build.mutation<ActToBillApiResponse, ActToBillApiArg>({
      query: (queryArg) => ({
        url: URLS.actToBill.replace(':id', queryArg?.id?.toString()),
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [Tags.ActInvoicesForPayments],
    }),
    getAccountingAct: build.query({
      query: (queryArg) => ({
        url: URLS.getAccountingAct.replace(
          ':id',
          queryArg.actNumber.toString(),
        ),
        method: 'GET',
      }),
    }),
    getAccountingBill: build.query({
      query: (queryArg) => ({
        url: URLS.getAccountingBill.replace(
          ':id',
          queryArg.actNumber.toString(),
        ),
        method: 'GET',
      }),
    }),
    payBill: build.mutation<ActToBillApiResponse, PayBillApiArg>({
      query: (queryArg) => ({
        url: URLS.payBill.replace(':id', queryArg?.id?.toString()),
        method: 'POST',
      }),
      invalidatesTags: [Tags.ActInvoicesForPayments],
    }),
    updateDecadeActOfMonth: build.mutation<
      UpdateDecadeActOfMonthApiResponse,
      UpdateDecadeActOfMonthApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateDecadeActOfMonth,
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: [Tags.ActInvoicesForPayments],
    }),
    createAssociationStats: build.mutation<any, AssociationInitialData>({
      query: (queryArg) => {
        return {
          url: URLS.createAssociationStats.replace(
            ':id',
            queryArg.associationId.toString(),
          ),
          method: 'POST',
          body: queryArg,
        };
      },
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    getAnnualContractById: build.query<
      UpdateAttachmentsAnnualContractApiResponse,
      { id: number }
    >({
      query: (queryArg) => ({
        url: URLS.getAnnualContractById.replace(':id', queryArg.id.toString()),
      }),
      providesTags: [Tags.AnnualApplication],
    }),
    submitAnnualContract: build.mutation<
      UpdateAttachmentsAnnualContractApiResponse,
      UpdateAttachmentsAnnualContractApiArg
    >({
      query: (queryArg) => {
        return {
          url: URLS.submitAnnualContract.replace(':id', queryArg.id.toString()),
          method: 'POST',
          body: {
            attachments: queryArg.attachments,
          },
        };
      },
      invalidatesTags: [Tags.AnnualApplication],
    }),
    cancelAnnualContract: build.mutation<void, { id: number }>({
      query: (queryArg) => {
        return {
          url: URLS.cancelAnnualContract.replace(':id', queryArg.id.toString()),
          method: 'DELETE',
        };
      },
      invalidatesTags: [Tags.AnnualApplication],
    }),
    updateAttachmentsAnnualContract: build.mutation<
      UpdateAttachmentsAnnualContractApiResponse,
      UpdateAttachmentsAnnualContractApiArg
    >({
      query: (queryArg) => {
        return {
          url: URLS.updateAttachmentsAnnualContract.replace(
            ':id',
            queryArg.id.toString(),
          ),
          method: 'PUT',
          body: {
            attachments: queryArg.attachments,
          },
        };
      },
      invalidatesTags: [Tags.AnnualApplication],
    }),
    updateAssociationStats: build.mutation<any, AssociationInitialData>({
      query: (queryArg) => {
        return {
          url: URLS.updateAssociationStats,
          method: 'PUT',
          body: queryArg,
        };
      },
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    getAllAgroClimaticZones: build.query<
      GetAllAgroClimaticZonesApiResponse,
      GetAllAgroClimaticZonesApiArg
    >({
      query: () => ({
        url: URLS.getAllAgroClimaticZones,
      }),
      providesTags: [Tags.AgroClimaticZone],
      transformResponse: (response: Array<IAgroClimaticZone>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getAgroClimaticZones: build.query<
      GetAgroClimaticZonesApiResponse,
      GetAgroClimaticZonesApiArg
    >({
      query: (queryArg: IAgroClimaticZoneFilters) => ({
        url: URLS.getAgroClimaticZones,
        params: {
          ...queryArg,
          sort: 'asc',
        },
      }),
      providesTags: [Tags.AgroClimaticZone],
    }),
    getAgroClimaticZoneById: build.query<
      GetAgroClimaticZoneByIdApiResponse,
      GetAgroClimaticZoneByIdApiArg
    >({
      query: (queryArg) => ({
        url: URLS.getAgroClimaticZoneById.replace(
          ':id',
          queryArg.id.toString(),
        ),
      }),
      providesTags: [Tags.AgroClimaticZone],
    }),
    createAgroClimaticZone: build.mutation<
      CreateAgroClimaticZoneApiResponse,
      CreateAgroClimaticZoneApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createAgroClimaticZone,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AgroClimaticZone],
    }),
    updateAgroClimaticZone: build.mutation<
      UpdateAgroClimaticZoneApiResponse,
      UpdateAgroClimaticZoneApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateAgroClimaticZone.replace(':id', queryArg.id.toString()),
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AgroClimaticZone],
    }),

    getPlantingList: build.query<
      GetPlantingListApiResponse,
      GetPlantingListApiArg
    >({
      query: () => ({
        url: URLS.getPlantingList,
      }),
      transformResponse: (response: GetPlantingListApiResponse) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    toArchiveIrrigationSystem: build.mutation<
      any,
      ArchiveIrrigationSystemApiArg
    >({
      query: (queryArg) => ({
        url: URLS.toArchiveIrrigationSystem.replace(
          ':id',
          queryArg.id.toString(),
        ),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.IrrigationSystem],
    }),
    toUnArchiveIrrigationSystem: build.mutation<any, any>({
      query: ({ id }) => ({
        url: URLS.toUnArchiveIrrigationSystem.replace(':id', id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.IrrigationSystem],
    }),
    toArchiveIrrigationSystemObject: build.mutation<
      any,
      ArchiveIrrigationSystemApiArg
    >({
      query: (queryArg) => ({
        url: URLS.toArchiveIrrigationSystemObject.replace(
          ':id',
          queryArg.id.toString(),
        ),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.WaterObject, Tags.Reservoir],
    }),
    toUnArchiveIrrigationSystemObject: build.mutation<any, any>({
      query: (queryArg) => ({
        url: URLS.toUnArchiveIrrigationSystemObject.replace(
          ':id',
          queryArg.id.toString(),
        ),
        method: 'PUT',
      }),
      invalidatesTags: [Tags.WaterObject, Tags.Reservoir],
    }),
    contractualWatterSuplyPlan: build.query<
      ContractualWatterSuplyPlanApiResponse,
      ContractualWatterSuplyPlanApiArg
    >({
      query: (params) => ({
        url: URLS.contractualWatterSuplyPlan,
        params,
      }),
    }),
    economicPlan: build.query<EconomicPlanApiResponse, EconomicPlanApiArg>({
      query: (params) => ({
        url: URLS.economicPlan,
        params,
      }),
    }),
    getAllCommonReferences: build.query<CommonReference[], void>({
      query: () => ({
        url: URLS.getAllCommonReferences,
      }),
      transformResponse: (response: Array<CommonReference>): any =>
        response.reduce<any>((prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        }, {}),
    }),
    getFacilitiesByFilter: build.query<
      GetFacilitiesByFilterApiResponse,
      GetFacilitiesByFilterApiArg
    >({
      query: (params: any) => ({
        url: URLS.getFacilitiesByFilter,
        params,
      }),
      transformResponse: (response: Array<IFacility>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.facilitiesId,
        })),
    }),
    getRivers: build.query<
      GetNaturalSourcesApiResponse,
      GetNaturalSourcesApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getRivers,
        params: {
          basin_id: queryArgs.basinId,
          region_id: queryArgs.regionId,
        },
      }),
    }),
    getOthers: build.query<
      GetNaturalSourcesApiResponse,
      GetNaturalSourcesApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getOthers,
        params: {
          basin_id: queryArgs.basinId,
          region_id: queryArgs.regionId,
        },
      }),
    }),
    getRegularEventTemplateById: build.query<any, string>({
      query: (id) => ({
        url: URLS.getRegularEventTemplateById.replace(':id', id),
      }),
    }),
    getLakes: build.query<
      GetNaturalSourcesApiResponse,
      GetNaturalSourcesApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getLakes,
        params: {
          basin_id: queryArgs.basinId,
          region_id: queryArgs.regionId,
        },
      }),
    }),
    toArchiveWaterUser: build.mutation<void, toArchiveWaterUserApiArg>({
      query: (queryArg) => ({
        url: URLS.toArchiveWaterUser.replace(':id', queryArg.id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [
        Tags.WaterUserAssociation,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAll,
      ],
    }),
    toUnArchiveWaterUser: build.mutation<void, any>({
      query: (queryArg) => ({
        url: URLS.toUnArchiveWaterUser.replace(':id', queryArg.id.toString()),
        method: 'PUT',
      }),
      invalidatesTags: [
        Tags.WaterUserAssociation,
        Tags.WaterUserPhysical,
        Tags.WaterUserLegal,
        Tags.WaterUserAll,
      ],
    }),
    getFacilitiesByOrgId: build.query<
      GetFacilitiesByOrgIdApiResponse,
      GetFacilitiesByOrgIdApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getFacilitiesByOrgId.replace(
          ':id',
          queryArgs.orgId.toString(),
        ),
      }),
      providesTags: [Tags.FacilitiesByOrgId],
    }),
    getFacilitiesById: build.query<
      GetFacilitiesByIdApiResponse,
      GetFacilitiesByIdApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getFacilitiesById.replace(':id', queryArgs.id.toString()),
      }),
    }),
    updateGmrPlantingList: build.mutation<
      UpdateGmrPlantingListApiResponse,
      UpdateGmrPlantingListApiArg
    >({
      query: (queryArg) => ({
        url: URLS.updateGmrPlantingList,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AgroClimaticZone],
    }),
    getGmrPlantingList: build.query<
      any,
      {
        gmrId: number;
      }
    >({
      query: (queryArg) => ({
        url: URLS.getGmrPlantingList,
        params: queryArg,
      }),
      providesTags: [Tags.AgroClimaticZone],
    }),
    findAllAnnualContractList: build.query<
      FindAllAnnualContractListApiResponse,
      FindAllAnnualContractListApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.findAllAnnualContractList,
        params: {
          ...queryArgs,
          sort: 'desc',
        },
      }),
      providesTags: [Tags.AnnualApplication],
    }),
    createAnnualContract: build.mutation<
      CalculateAnnualContractApiResponse,
      CalculateAnnualContractApiArg
    >({
      query: (queryArg) => ({
        url: URLS.createAnnualContract,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),
    updateAnnualContractStatus: build.mutation<any, any>({
      query: ({ id, status }) => ({
        url: URLS.updateAnnualContractStatus.replace(':id', id.toString()),
        method: 'PUT',
        params: { status },
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),
    calculateAnnualContract: build.mutation<
      CalculateAnnualContractApiResponse,
      CalculateAnnualContractApiArg
    >({
      query: (queryArg) => ({
        url: URLS.calculateAnnualContract,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.AnnualApplication],
    }),

    findAllTariffList: build.query<any, FindAllTariffListApiArg>({
      query: (queryArgs) => ({
        url: URLS.findAllTariffList,
        params: {
          ...queryArgs,
        },
      }),
      providesTags: [Tags.Tariff],
    }),
    getTariffById: build.query<GetTariffByIdApiResponse, GetTariffByIdApiArg>({
      query: (queryArgs) => ({
        url: URLS.getTariffById.replace(':id', queryArgs.tariffId.toString()),
      }),
      providesTags: [Tags.Tariff],
    }),
    getTariffByRuvhId: build.query<
      GetTariffByRuvhIdApiResponse,
      GetTariffByRuvhIdApiArg
    >({
      query: (params) => ({
        url: URLS.getTariffByRuvhId,
        params,
      }),
      providesTags: [Tags.Tariff],
    }),
    createTariff: build.mutation<CreateTariffApiResponse, CreateTariffApiArg>({
      query: (queryArgs) => ({
        url: URLS.createTariff,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.Tariff],
    }),
    getReservoirDataMonth: build.query<
      GetReservoirDataMonthApiResponse,
      GetReservoirDataMonthApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getReservoirDataMonth,
        params: queryArgs,
      }),
      providesTags: [Tags.Reservoir],
    }),
    getReservoirs: build.query<GetReservoirsApiResponse, any>({
      query: (params) => ({
        url: URLS.getReservoirs,
        params,
      }),
      providesTags: [Tags.Reservoir],
    }),
    getReservoirById: build.query<
      GetReservoirByIdApiResponse,
      GetReservoirByIdApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getReservoirById.replace(
          ':id',
          queryArgs.reservoirId.toString(),
        ),
      }),
      providesTags: [Tags.Reservoir, Tags.WaterObject],
    }),
    // updateReservoirData: build.mutation<
    //   UpdateReservoirDataApiResponse,
    //   UpdateReservoirDataApiArg
    // >({
    //   query: (queryArgs) => ({
    //     url: URLS.updateReservoirData,
    //     method: 'PUT',
    //     body: queryArgs,
    //   }),
    //   invalidatesTags: [Tags.Reservoir],
    // }),
    createReservoirData: build.mutation<
      CreateReservoirDataApiResponse,
      CreateReservoirDataApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.createReservoirData,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.Reservoir],
    }),
    getReservoirCurrentVolume: build.query<
      GetReservoirCurrentVolumeApiResponse,
      GetReservoirCurrentVolumeApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getReservoirCurrentVolume,
        params: queryArgs,
      }),
      providesTags: [Tags.Reservoir],
    }),
    createStartVolume: build.mutation<
      CreateStartVolumeApiResponse,
      CreateStartVolumeApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.createStartVolume,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.Reservoir],
    }),
    createAssociationInitialData: build.mutation<
      void,
      CreateAssociationInitialDataApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.createAssociationInitialData,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    updateAssociationInitialData: build.mutation<
      void,
      CreateAssociationInitialDataApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.updateAssociationInitialData.replace(':id', queryArgs.id),
        method: 'PUT',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    getPoints: build.query<Array<GetPointsApiResponse>, GetPointsApiArg>({
      query: (queryArgs) => ({
        url: URLS.getPoints,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterQuality],
    }),
    getZones: build.query<GetZonesApiResponse, GetZonesApiArg>({
      query: (queryArgs) => ({
        url: URLS.getZones,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterQuality],
    }),
    getZone: build.query<GetZoneApiResponse, GetZoneApiArg>({
      query: (queryArgs) => ({
        url: URLS.getZone.replace(':id', queryArgs.id.toString()),
      }),
      providesTags: [Tags.WaterQuality],
    }),
    getQualityMonitoring: build.query<DefaultOptionType[], void>({
      query: () => ({
        url: URLS.getQualityMonitoring,
      }),
      transformResponse: (response: Array<string>) =>
        response.map((item) => ({
          label: item,
          value: item,
        })),
    }),
    getPointType: build.query<DefaultOptionType[], void>({
      query: () => ({
        url: URLS.getPointType,
      }),
      transformResponse: (response: Array<string>) =>
        response.map((item) => ({
          label: item,
          value: item,
        })),
    }),
    getReclamationStates: build.query<GetReclamationStatesApiResponse, void>({
      query: () => ({
        url: URLS.getReclamationStates,
      }),
    }),
    createPoint: build.mutation<CreatePointApiResponse, CreatePointApiArg>({
      query: (queryArgs) => ({
        url: URLS.createPoint,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterQuality],
    }),
    createZone: build.mutation<CreateZoneApiResponse, CreateZoneApiArg>({
      query: (queryArgs) => ({
        url: URLS.createZone,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterQuality],
    }),
    createReclamationState: build.mutation<
      void,
      Array<CreateReclamationApiArg>
    >({
      query: (queryArgs) => ({
        url: URLS.createReclamationState,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterQuality],
    }),
    createDepthOccurrence: build.mutation<void, Array<CreateDepthApiArg>>({
      query: (queryArgs) => ({
        url: URLS.createDepthOccurrence,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterQuality],
    }),
    createLaboratoryTests: build.mutation<
      void,
      Array<CreateLaboratoryTestsApiArg>
    >({
      query: (queryArgs) => ({
        url: URLS.createLaboratoryTests,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterQuality, Tags.LaboratoryTests],
    }),
    getReclamationData: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getReclamationData,
        params: queryArgs,
      }),
      keepUnusedDataFor: 0,
    }),
    getDepthOccurrence: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getDepthOccurrence,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterQuality],
    }),
    getLaboratoryTests: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getLaboratoryTests,
        params: queryArgs,
      }),
      providesTags: [Tags.LaboratoryTests],
    }),
    getContractualWaterSupply: build.query<
      GetContractualWaterSupplyApiResponse,
      GetContractualWaterSupplyApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getContractualWaterSupply,
        params: queryArgs,
      }),
    }),
    getWaterConsumption: build.query<
      GetWaterConsumptionApiResponse,
      GetWaterConsumptionApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterConsumption,
        params: queryArgs,
      }),
    }),
    getConcludingAgreement: build.query<
      GetConcludingAgreementApiResponse,
      GetConcludingAgreementApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getConcludingAgreement,
        params: queryArgs,
      }),
    }),
    geoPortalQualityControlMonitoring: build.query<
      GeoPortalQualityControlMonitoringApiResponse,
      GeoPortalQualityControlMonitoringApiArg
    >({
      query: () => ({
        url: URLS.geoPortalQualityControlMonitoring,
      }),
    }),
    geoPortalIrrigationSystemObjects: build.query<
      GeoPortalIrrigationSystemObjectsApiResponse,
      GeoPortalIrrigationSystemObjectsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.geoPortalIrrigationSystemObjects,
        params: queryArgs,
      }),
    }),
    geoPortalIrrigationSystemObjectsLayers: build.query<
      GeoPortalIrrigationSystemObjectsLayersApiResponse,
      GeoPortalIrrigationSystemObjectsLayersApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.geoPortalGetLayers.replace(':id', queryArgs.filterKey),
      }),
    }),
    geoPortalGetLayers: build.query<
      GeoPortalGetLayersApiResponse,
      GeoPortalGetLayersApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.geoPortalGetLayers.replace(':id', queryArgs.filterKey),
      }),
    }),
    getWaterUsersCounter: build.query<
      GetWaterUsersCounterApiResponse,
      Partial<GetWaterUsersCounterApiArg>
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterUsersCounter,
        params: queryArgs,
      }),
    }),
    getWaterManagementCounters: build.query<
      GetWaterManagementCountersApiResponse,
      GetWaterManagementApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterManagementCounters,
        params: queryArgs,
      }),
    }),
    getGeoNodesByTypeName: build.query<any, GetGeoNodesByTypeNameApiArg>({
      query: (queryArgs) => ({
        url: `http://10.22.22.22:8080/geoserver/geonode/ows?service=WFS&version=1.1.0&request=GetFeature&typename=${queryArgs.typeName}&outputFormat=application/json`,
      }),
    }),
    getWaterUserData: build.query({
      query: (queryArgs) => ({
        url: URLS.getWaterUserData,
        params: queryArgs,
      }),
    }),
    getGmrZoneList: build.query<
      GetGmrZoneListApiResponse,
      GetGmrZoneListApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getGmrZoneList,
        params: queryArgs,
      }),
      providesTags: [Tags.HydroModule],
    }),
    getGmrSystemsByDistrict: build.query<
      GetGmrSystemsByDistrictApiResponse,
      GetGmrSystemsByDistrictApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getGmrSystemsByDistrict,
        params: queryArgs,
      }),
      providesTags: [Tags.HydroModule],
    }),
    getGmrBindedSystems: build.query<
      GetGmrBindedSystemsApiResponse,
      GetGmrBindedSystemsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getGmrBindedSystems,
        params: queryArgs,
      }),
      providesTags: [Tags.HydroModule],
    }),
    createGmr: build.mutation<void, CreateGmrApiArg>({
      query: (queryArgs) => ({
        url: URLS.createGmr,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydroModule],
    }),
    updateGeometry: build.mutation<void, UpdateGeometryApiArg>({
      query: (queryArgs) => ({
        url: URLS.updateGeometry,
        method: 'PUT',
        body: queryArgs,
      }),
      // invalidatesTags: [Tags.Ge],
    }),
    getLegalDataContract: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getLegalDataContract.replace(':id', queryArgs.id),
      }),
    }),
    getIndividualDataContract: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getIndividualDataContract.replace(':id', queryArgs.id),
      }),
    }),
    getAssociationDataContract: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getAssociationDataContract.replace(':id', queryArgs.id),
      }),
    }),
    getWaterUserAgreement: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.getWaterUserAgreement,
        params: queryArgs,
      }),
    }),
    createGmrSystemOutlets: build.mutation<
      CreateGmrSystemOutletsApiResponse,
      CreateGmrSystemOutletsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.createGmrSystemOutlets,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydroModule],
    }),
    deleteGmrSystemOutlets: build.mutation<
      DeleteGmrSystemOutletsApiResponse,
      DeleteGmrSystemOutletsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.deleteGmrSystemOutlets,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydroModule],
    }),
    createHydraulicSection: build.mutation<void, any>({
      query: (queryArgs) => ({
        url: URLS.createHydraulicSection,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydarulicSection],
    }),
    deleteHydroFacilitySystem: build.mutation<
      void,
      DeleteHydroFacilitySystemApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.deleteHydroFacilitySystem,

        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydroModule],
    }),
    deleteHydroFacility: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: URLS.deleteHydroFacility.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.HydarulicSection],
    }),
    createHydroFacilitySystem: build.mutation<
      void,
      CreateHydroFacilitySystemApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.createHydroFacilitySystem,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydroModule],
    }),
    getSystemsByDistrict: build.query<
      Array<GetSystemsByDistrictApiResponse>,
      GetSystemsByDistrictApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getSystemsByDistrict,
        params: queryArgs,
      }),
      providesTags: [Tags.HydroModule],
    }),
    getBindedOutlets: build.query<
      Array<GetBindedOutletsApiResponse>,
      GetBindedOutletsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getBindedOutlets,
        params: queryArgs,
      }),
      providesTags: [Tags.HydroModule],
    }),
    getHydroFacilityAll: build.query<Array<any>, GetHydroFacilityAllApiArg>({
      query: (queryArg) => ({
        url: URLS.getHydroFacilityAll,
        params: {
          region: queryArg?.region,
          district: queryArg?.district,
        },
      }),
      providesTags: [Tags.HydarulicSection],
      transformResponse: (response: Array<any>): any =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    getWaterUsePlan: build.query<
      GetWaterUsePlanApiResponse,
      GetWaterUsePlanApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterUsePlan,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getWaterUseCropSowingPlan: build.query<
      GetWaterUseCropSowingPlanApiResponse,
      GetWaterUseCropSowingPlanApiArg
    >({
      query: () => ({
        url: URLS.getCropSowingPlan,
      }),
      providesTags: [Tags.CropSowing],
    }),
    getWaterUseCropSowingPlanByGuvh: build.query<
      ICropSowingPlanByGuvh,
      GetWaterUseCropSowingPlanByGuvhApiArg
    >({
      query: () => ({
        url: URLS.getCropSowingPlanByGuvh,
      }),
      providesTags: [Tags.CropSowing],
    }),
    getCropSowingPlanByCa: build.query<
      ICropSowingPlanByGuvh,
      GetWaterUseCropSowingPlanByCaApiArg
    >({
      query: () => ({
        url: URLS.getCropSowingPlanByCa,
      }),
      providesTags: [Tags.CropSowing],
    }),
    updateCropSowingPlan: build.mutation<void, UpdateCropSowingPlanApiArg>({
      query: (queryArg) => ({
        url: URLS.updateCropSowingPlan,
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: [Tags.CropSowing],
    }),
    updateContractualPlan: build.mutation<void, UpdateCropSowingPlanApiArg>({
      query: (queryArg) => ({
        url: URLS.updateContractualPlan,
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: [Tags.ContractualPlan],
    }),

    getContractualPlan: build.query<
      IWaterUsePlanDataDto,
      GetWaterUseCropSowingPlanApiArg
    >({
      query: () => ({
        url: URLS.getContractualPlan,
      }),
      providesTags: [Tags.ContractualPlan],
    }),
    getContractualPlanByGuvh: build.query<
      GetContractualPlanByGuvhApiResponse,
      GetContractualPlanByGuvhApiArg
    >({
      query: () => ({
        url: URLS.getContractualPlanByGuvh,
      }),
      providesTags: [Tags.ContractualPlan],
    }),
    getContractualPlanByCa: build.query<
      GetContractualPlanByGuvhApiResponse,
      GetContractualPlanByCaApiArg
    >({
      query: () => ({
        url: URLS.getContractualPlanByCa,
      }),
      providesTags: [Tags.ContractualPlan],
    }),
    getAllStatusWaterUsePlan: build.query<any, void>({
      query: () => ({
        url: URLS.getStatuses,
      }),
      // providesTags: [Tags.ContractualPlan],
    }),
    getHydrometricWorkPlan: build.query<
      GetHydrometricWorkPlanApiResponse,
      GetHydrometricWorkPlanApiArg
    >({
      query: () => ({
        url: URLS.getHydrometricWorkPlan,
      }),
      providesTags: [Tags.HydrometricalWorkPlan],
    }),
    getHydrometricWorkPlanByGuvh: build.query<
      GetHydrometricWorkPlanByRuvhApiResponse,
      GetHydrometricWorkPlanByRuvhApiArg
    >({
      query: () => ({
        url: URLS.getHydrometricWorkPlanByGuvh,
      }),
      providesTags: [Tags.HydrometricalWorkPlan],
    }),
    getHydrometricWorkPlanByCa: build.query<
      GetHydrometricWorkPlanByRuvhApiResponse,
      GetHydrometricWorkPlanByCaApiArg
    >({
      query: () => ({
        url: URLS.getHydrometricWorkPlanByCa,
      }),
      providesTags: [Tags.HydrometricalWorkPlan],
    }),
    updateWaterUsePlan: build.mutation<any, UpdateWaterUsePlanApiArg>({
      query: (queryArgs) => ({
        url: URLS.updateWaterUsePlan,
        method: 'PUT',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUsePlan],
    }),
    approvePlan: build.mutation<any, void>({
      query: (queryArgs) => ({
        url: URLS.approvePlan,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUsePlan],
    }),
    updateHydrometricWorkPlan: build.mutation<
      any,
      UpdateHydrometricWorkPlanApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.updateHydrometricWorkPlan,
        method: 'PUT',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.HydrometricalWorkPlan],
    }),
    updateStatusWaterUsePlan: build.mutation<
      UpdateStatusWaterUsePlanApiResponse,
      UpdateStatusWaterUsePlanApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.updateStatusWaterUsePlan,
        method: 'PUT',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUsePlan],
    }),
    getWaterAccountingOutletFacts: build.query<
      any,
      GetWaterAccountingOutletFactsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterAccountingOutletFacts,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingOutletsFact],
    }),
    updateWaterAccountingOutletFacts: build.mutation<
      any,
      UpdateWaterAccountingOutletFactsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.updateWaterAccountingOutletFacts,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterAccountingOutletsFact],
    }),
    getWaterAccountingHydropostFacts: build.query<
      any,
      GetWaterAccountingHydropostFactsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterAccountingHydropostFacts,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingHydropostFact],
    }),
    updateWaterAccountingHydropostFacts: build.mutation<
      any,
      UpdateWaterAccountingHydropostFactsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.updateWaterAccountingHydropostFacts,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterAccountingHydropostFact],
    }),
    getWaterAccountingJournal: build.query<
      any,
      GetWaterAccountingJournalApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterAccountingJournal,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingBalanceSheet],
    }),
    getWaterAccountingBalanceSheet: build.query<
      GetWaterAccountingBalanceSheetApiResponse,
      GetWaterAccountingBalanceSheetApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterAccountingBalanceSheet,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingBalanceSheet],
    }),

    saveWaterAccountingBalanceSheet: build.mutation<
      any,
      SaveWaterAccountingBalanceSheetApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.saveWaterAccountingBalanceSheet,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterAccountingBalanceSheet],
    }),
    saveToSystemWaterAccountingBalanceSheet: build.query<
      any,
      GetWaterAccountingBalanceSheetApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.saveToSystemWaterAccountingBalanceSheet,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingBalanceSheet],
    }),
    approveWaterAccountingBalanceSheet: build.query<
      any,
      GetWaterAccountingBalanceSheetApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.approveWaterAccountingBalanceSheet,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingBalanceSheet],
    }),
    cancelWaterAccountingBalanceSheet: build.query<
      any,
      GetWaterAccountingBalanceSheetApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.cancelWaterAccountingBalanceSheet,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterAccountingBalanceSheet],
    }),
    getHydroposts: build.query<any, GetHydropostsApiArg>({
      query: (queryArgs) => ({
        url: URLS.getHydroposts,
        params: queryArgs,
      }),
    }),

    getIrrigationSystemsMaps: build.query<any, GetIrrigationSystemsMapsApiArg>({
      query: (queryArgs) => ({
        url: URLS.irrigationSystemMaps,
        params: queryArgs,
      }),
    }),
    getWaterUsersMaps: build.query<any, GetWaterUsersMapsApiArg>({
      query: (queryArgs) => ({
        url: URLS.waterusersMaps,
        params: queryArgs,
      }),
    }),
    getWateringPlanOperationalWatering: build.query<
      any,
      GetWateringPlanOperationalWateringApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWateringPlanOperationalWatering,
        params: queryArgs,
      }),
      providesTags: [Tags.OperationalWateringPlan],
    }),
    getWateringPlanOperationalWaterSupply: build.query<
      any,
      GetWateringPlanOperationalWateringApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWateringPlanOperationalWaterSupply,
        params: queryArgs,
      }),
      providesTags: [Tags.OperationalWateringPlan],
    }),
    saveOperationWateringPlanDecadalRequest: build.mutation<
      Array<GetWateringPlanDecadalRequestApiResponse>,
      SaveWateringPlanDecadalRequestApiArg
    >({
      query: (queryArg) => ({
        url: URLS.saveOperationalWateringPlanRequest,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: [Tags.OperationalWateringPlan],
    }),
    sendOperationalPlanRequest: build.mutation<
      SendRequestToDecadalApiResponse,
      SendRequestToDecadalApiArg
    >({
      query: (queryArg) => ({
        url: URLS.sendOperationalPlanRequest,
        body: queryArg,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.OperationalWateringPlan],
    }),
    calculateWaterUsePlan: build.mutation<any, CalculateWaterUsePlanApiArg>({
      query: (queryArgs) => ({
        url: URLS.calculateWaterUsePlan,
        method: 'POST',
        body: queryArgs,
      }),
      invalidatesTags: [Tags.WaterUsePlan],
    }),
    getLinkedSystemObjects: build.query<
      GetLinkedSystemObjectsApiResponse,
      GetLinkedSystemObjectsApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getLinkedSystemObjects.replace(':id', queryArgs.id),
      }),
      providesTags: [Tags.WaterObject],
    }),
    getSummaryWaterUsePlan: build.query<
      GetSummaryWaterUsePlanApiResponse,
      void
    >({
      query: () => ({
        url: URLS.getSummaryWaterUsePlan,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getSummaryWaterUsePlanByGuvh: build.query<
      GetSummaryWaterUsePlanByGuvhApiResponse,
      GetSummaryWaterUsePlanByGuvhApiArg
    >({
      query: (query) => ({
        url: URLS.getSummaryWaterUsePlanByGuvh,
        params: query,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getSummaryWaterUsePlanByCa: build.query<
      GetSummaryWaterUsePlanByGuvhApiResponse,
      GetSummaryWaterUsePlanByCaApiArg
    >({
      query: (query) => ({
        url: URLS.getSummaryWaterUsePlanByCa,
        params: query,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getGovSummaryWaterUsePlan: build.query<
      GetGovSummaryWaterUsePlanApiResponse,
      void
    >({
      query: () => ({
        url: URLS.getGovSummaryWaterUsePlan,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getWaterUsePlanByGuvh: build.query<
      GetWaterUsePlanByGuvhApiResponse,
      GetWaterUsePlanByGuvhApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterUsePlanByGuvh,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    getWaterUsePlanByCa: build.query<
      GetWaterUsePlanByCaApiResponse,
      GetWaterUsePlanByCaApiArg
    >({
      query: (queryArgs) => ({
        url: URLS.getWaterUsePlanByCa,
        params: queryArgs,
      }),
      providesTags: [Tags.WaterUsePlan],
    }),
    cancelApprovalWaterUsePlan: build.mutation<
      void,
      CancelApprovalWaterUsePlanApiArg
    >({
      query: (body) => ({
        url: URLS.cancelApproval,
        method: 'POST',
        body,
      }),
      invalidatesTags: [Tags.WaterUsePlan],
    }),
    getOwnerChilds: build.query<any, GetOwnerChildsApiArg>({
      query: (queryArg) => ({
        url: URLS.getOwnerChilds.replace(':ownerId', queryArg.ownerId),
      }),
      transformResponse: (response: Array<any>): any =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      providesTags: [Tags.WaterUsePlan],
    }),
    createFacility: build.mutation<void, any>({
      query: (body) => ({
        url: URLS.createFacility,
        method: 'POST',
        body,
      }),
      invalidatesTags: [Tags.FacilitiesByOrgId],
    }),
    updateFacility: build.mutation<void, any>({
      query: (body) => ({
        url: URLS.updateFacility,
        method: 'POST',
        body,
      }),
      invalidatesTags: [Tags.FacilitiesByOrgId],
    }),
    deleteFacility: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.deleteFacility.replace(':id', id),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.FacilitiesByOrgId],
    }),
    addOutlet: build.mutation<void, any>({
      query: ({ body, id }) => ({
        url: URLS.addOutlet.replace(':id', id),
        method: 'POST',
        body,
      }),
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    updateOutlet: build.mutation<void, any>({
      query: ({ body, id }) => ({
        url: URLS.updateOutlet.replace(':id', id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    deleteOutlet: build.mutation<void, any>({
      query: ({ id, waterUserId }) => ({
        url: URLS.deleteOutlet
          .replace(':id', id)
          .replace(':waterUserId', waterUserId),
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.WaterUserAssociation],
    }),
    get2tpChangeStatus: build.query<void, IWaterManagementArg>({
      query: (queryArg: any) => ({
        url: URLS.get2tpChangeStatus.replace(':year', queryArg.year),
      }),
      keepUnusedDataFor: 0,
      providesTags: [Tags.twoTpWaterUseData],
    }),
    get2tpWaterUseData: build.query<
      IWaterManagementResponse,
      IWaterManagementArg
    >({
      query: (queryArg: any) => ({
        url: URLS.get2tpWaterUseData.replace(':year', queryArg.year),
        params: queryArg,
      }),
      keepUnusedDataFor: 0,
      providesTags: [Tags.twoTpWaterUseData],
    }),
    get2tpDrainage: build.query<any, any>({
      query: (queryArg) => ({
        url: URLS.get2tpDrainage.replace(':year', queryArg.year),
      }),
      providesTags: [],
    }),
    get2tpTotalWaterUseData: build.query<
      ITotalWaterResponse,
      IWaterManagementArg
    >({
      query: (queryArg: any) => ({
        url: URLS.get2tpTotalWaterUseData.replace(':year', queryArg.year),
        params: queryArg,
      }),
      keepUnusedDataFor: 0,
      providesTags: [Tags.twoTpWaterUseData],
    }),
    update2tpWaterUseData: build.mutation<any, any>({
      query: ({ body, year }) => ({
        url: URLS.update2tpWaterUseData.replace(':year', year),
        method: 'POST',
        body,
      }),
      invalidatesTags: [Tags.twoTpWaterUseData],
    }),
    get2tpList: build.query<any, any>({
      query: (queryArgs) => ({
        url: URLS.get2tpList,
        params: queryArgs,
      }),
    }),
    update2tpDrainage: build.mutation<any, any>({
      query: ({ body, year }) => ({
        url: URLS.update2tpDrainage.replace(':year', year),
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),

    get2tpWaterUseConsolidatedData: build.query<any, any>({
      query: ({ year }) => ({
        url: URLS.get2tpWaterUseConsolidatedData.replace(':year', year),
      }),
    }),
    get2tpConsolidatedDrainage: build.query<any, any>({
      query: ({ year }) => ({
        url: URLS.get2tpConsolidatedDrainage.replace(':year', year),
      }),
    }),
    getOwnerInfo: build.query<void, any>({
      query: () => ({
        url: URLS.getOwnerInfo,
      }),
      providesTags: [Tags.OwnerInfo],
    }),

    updateOwnerInfo: build.mutation<void, any>({
      query: ({ body }) => ({
        url: URLS.updateOwnerInfo,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [Tags.OwnerInfo],
    }),
    getDependentOwnerList: build.query<any, any>({
      query: (params) => ({
        url: URLS.getDependentOwnerList,
        params,
      }),
    }),
    getOwnerInfoById: build.query<any, any>({
      query: ({ id }) => ({
        url: URLS.getOwnerInfoById.replace(':id', id),
      }),
      providesTags: [Tags.OwnerInfoById],
    }),

    updateOwnerInfoById: build.mutation<void, any>({
      query: ({ id, body }) => ({
        url: URLS.updateOwnerInfoById.replace(':id', id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [Tags.OwnerInfoById],
    }),

    deletePermissionById: build.mutation<void, any>({
      query: ({ id }) => ({
        url: URLS.deletePermissionById.replace(':id', id),
        method: 'DELETE',
      }),
      invalidatesTags: [
        Tags.WaterUserAssociation,
        Tags.WaterUserLegal,
        Tags.WaterUserPhysical,
        Tags.OwnerInfoById,
      ],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as gisproApi };
export type CancelApprovalWaterUsePlanApiArg = {
  ruvhId?: number;
  guvhId?: number;
};
export type GetOwnerChildsApiArg = {
  ownerId: string;
};
export type GetContractualPlanByGuvhApiResponse = any;
export type GetContractualPlanByGuvhApiArg = {
  ruvhId?: number;
};
export type GetContractualPlanByCaApiArg = {
  guvhId?: number;
};
export type UpdateGmrPlantingListApiResponse = void;
export type UpdateGmrPlantingListApiArg = {
  gmrId: number;
  plantingListDtos: any[];
};

export type SubmitOperationalBlockImplementationTermApiResponse = void;
export type SubmitOperationalBlockImplementationTermApiArg = {
  id: number;
  attachments: IAttachment[];
};

export type IGovItemDto = {
  systemId: number;
  systemName: string;
  planItems: IPlanItemDto[];
};

export type GetGovSummaryWaterUsePlanApiResponse = {
  title: string;
  items: IGovItemDto[];
}[];

export type GetSummaryWaterUsePlanApiResponse = Record<
  string,
  ISummaryGeneralPlan[]
>;
export type GetSummaryWaterUsePlanByGuvhApiResponse = any;
export type GetSummaryWaterUsePlanByGuvhApiArg = { ruvhId?: number };
export type GetSummaryWaterUsePlanByCaApiArg = {
  guvhId?: number;
  ruvhId?: number;
};
export type GetLinkedSystemObjectsApiArg = {
  id: string;
};

export type GetLinkedSystemObjectsApiResponse = {
  title: string;
  items: IWaterObject[];
}[];

export type UpdateAttachmentsAnnualContractApiResponse = IAnnualApplication;
export type UpdateAttachmentsAnnualContractApiArg = {
  id: number;
  attachments: IAttachment[];
};

export type SendRequestToDecadalApiArg = number[];
export type SendRequestToDecadalApiResponse = any;

export type Raws = {
  id: number;
  volume: number;
};

export type CalculateWaterUsePlanApiArg = {
  type: PlanType;
};
export type UpdateWaterUsePlanApiArg = {
  type: PlanType;
  raws: Raws[];
};

export type GetWateringPlanOperationalWateringApiArg = {
  month: MonthEnums;
  userType: WaterUserEnum;
  filters?:
    | {
        hydroFacilityId: number;
        irrigationSystemId: number;
        outletName: string;
        name: string;
        year?: number;
      }
    | string;
  page: number;
  size: number;
};

export type UpdateWaterAccountingOutletFactsApiArg = {
  id: string;
  time: string;
  level: number;
  correctionLevel: number;
  waste: number;
  dayTime: string;
}[];

type UpdateWaterAccountingHydropostFactsApiArg =
  UpdateWaterAccountingOutletFactsApiArg;

type SaveWaterAccountingBalanceSheetApiArg = {
  id: string;
  value: number;
}[];

type CreateGmrApiArg = ICreateGmr;

type GetHydropostsApiArg = {
  districtId?: number;
  regionId?: number;
  systemId?: number;
};

type GetWaterAccountingOutletFactsApiArg = {
  month: MonthEnums;
  decade: number;
  decadeDays: number[];
  year: number | string;
  page: number | string;
  filter: IWaterAccountingFilters | string;
};

type GetWaterAccountingHydropostFactsApiArg =
  GetWaterAccountingOutletFactsApiArg;

type GetWaterAccountingJournalApiArg = GetWaterAccountingOutletFactsApiArg;
type GetWaterAccountingBalanceSheetApiArg = {
  outletId: number;
  month: MonthEnums;
  year: number;
  decade: number;
};

type GetWaterAccountingBalanceSheetApiResponse = IBalanceSheet;
type GetIrrigationSystemsMapsApiArg = {
  page: number;
  size: number;
};
type GetWaterUsersMapsApiArg = GetIrrigationSystemsMapsApiArg;

type UpdateCropSowingPlanApiArg = JsonRecord;

type GetWaterUseCropSowingPlanApiArg = void;
type GetWaterUseCropSowingPlanByGuvhApiArg = { ruvhId?: number };
type GetWaterUseCropSowingPlanByCaApiArg = { guvhId?: number };
type GetWaterUsePlanApiArg = {
  type: PlanType;
};
type GetWaterUsePlanByGuvhApiArg = {
  type: PlanType;
  ruvhId?: number;
};
type GetWaterUsePlanByCaApiArg = {
  guvhId?: number;
  type: PlanType;
};
type GetWaterUsePlanByGuvhApiResponse = IWaterUsePlanByGuvhDto;
type GetWaterUsePlanByCaApiResponse = IWaterUsePlanByGuvhDto;
type GetGeoPortalGetDataApiArg = {
  typename: string;
};
type GetGeoPortalGetDataByIdApiArg = GetGeoPortalGetDataApiArg & {
  id: number;
};
type GetHydrometricWorkPlanApiResponse = IHydrometricWorkPlan[];
type GetHydrometricWorkPlanByRuvhApiResponse = IHydrometricWorkPlanByGuvh;
type GetHydrometricWorkPlanByRuvhApiArg = { ruvhid?: number };
type GetHydrometricWorkPlanByCaApiArg = { guvhid?: number };
type UpdateHydrometricWorkPlanApiArg = JsonRecord;
type UpdateStatusWaterUsePlanApiArg = {
  type: PlanType;
};
type UpdateStatusWaterUsePlanApiResponse = {
  // type: PlanType;
  status: PlanStatusEnum;
};
type GetHydrometricWorkPlanApiArg = void;

type GetWaterUsePlanApiResponse = IWaterUsePlanDataDto;
type GetWaterUseCropSowingPlanApiResponse = ICropSowingPlan;

type GeometryItem = {
  id: string;
  layerName: string;
  coordinates: string;
};
type UpdateGeometryApiArg = {
  data: GeometryItem[];
};
type DeleteGmrSystemOutletsApiResponse = void;
type DeleteGmrSystemOutletsApiArg = ICreateGmrSystemOutlets;

type CreateGmrSystemOutletsApiResponse = void;
type CreateGmrSystemOutletsApiArg = ICreateGmrSystemOutlets;

type GetGmrZoneListApiResponse = Array<IAgroClimaticZoneHydroModule>;
type GetGmrZoneListApiArg = {
  agroclimaticzoneId: string;
};

type GetGmrBindedSystemsApiResponse = Array<IGmrSystemsByDistrict>;
type GetGmrBindedSystemsApiArg = {
  gmrId: string;
};

type GetGmrSystemsByDistrictApiResponse = Array<IGmrSystemsByDistrict>;
type GetGmrSystemsByDistrictApiArg = {
  gmrId: string;
  hydroFacilityId?: number | undefined;
  irrigationSystemId?: number | undefined;
};

export type GeoPortalGetLayersApiResponse = {
  layerGroupDtos: ILayers[];
};
type GeoPortalGetLayersApiArg = {
  filterKey: string;
};
type GetGeoNodesByTypeNameApiArg = {
  typeName: string;
};
type GeoPortalIrrigationSystemObjectsLayersApiResponse =
  IIrrigationSystemObjectsLayers;
type GeoPortalIrrigationSystemObjectsLayersApiArg = {
  filterKey: string;
};

type GeoPortalIrrigationSystemObjectsApiResponse = IIrrigationSystemObjects;
type GeoPortalIrrigationSystemObjectsApiArg = {
  systemId: number;
};

type GeoPortalQualityControlMonitoringApiResponse = IQualityControlMonitoring;
type GeoPortalQualityControlMonitoringApiArg = void;

export type FindAllRuvhOrganizationsApiResponse =
  /** status 200 OK */ OrganizationDto[];
export type FindAllRuvhOrganizationsApiArg = void;
export type CreateAssociationInitialDataApiArg = IAssociationIndicatorReports;

export type OutletBindDtoList = {
  id: number;
  // name: string;
  // outletName: string;
  // outletId: number;
  // systemId: number;
  // systemName: string;
};

export type DeleteHydroFacilitySystemApiArg = {
  hydroFacilityId: number;
  outletBindDtoList: Array<OutletBindDtoList>;
};

export type CreateHydroFacilitySystemApiArg = {
  hydroFacilityId: number;
  outletBindDtoList: Array<OutletBindDtoList>;
};

export type GetHydroFacilityAllApiArg = {
  region?: number;
  district?: number;
};

export type GetBindedOutletsApiArg = {
  hydroFacilityId: number;
};

export type GetHydroFacilityAllApiResponse = {
  id: number;
  name: string;
  region: {
    id: number;
    title: string;
    code: number;
    parentId: null | number;
    books: null | string;
  };
  district: {
    id: number;
    title: string;
    code: string;
    parentId: number;
    books: null | string;
  };
};
export type GetBindedOutletsApiResponse = {
  id: number;
  systemName: string;
  systemId: number;
  outletName: string;
  outletId: number;
};

export type GetWaterUsersByOutletApiArg = {
  outletId: number;
};

export type GetSystemsByDistrictApiArg = {
  hydroFacilityId: number;
};

export type GetSystemsByDistrictApiResponse = {
  id: number;
  systemName: string;
  systemId: number;
  outletName: string;
  outletId: number;
};

export type CreateReclamationApiArg = {
  year: number;
  quarter: number;
  reclamationStateSoil: string;
  zoneId: number;
};

export type FacilityDtos = {
  parentId: number;
  organizationId: number;
  organizationName: string;
  facilitiesId: number;
  facilitiesName: string;
  okmotId: number;
  okmotName: string;
  irrigationSystemId: number;
  irrigationSystemName: string;
  factSum: number;
};

export type AssociationDtos = {
  associationName: string;
  associationId: number;
  facilityDtos: Array<FacilityDtos>;
  associationSum: number;
};

export type DistrictDtos = {
  districtId: number;
  regionName: string;
  districtName: string;
  debtByDistrictPreviousYear: number;
  annualPlanAreaOfCrops: number | null;
  annualPlanVolume: number | null;
  annualPlanAmount: number | null;
  signedForPaymentAmount: number;
  signedForPaymentVolume: number;
  submittedVolume: number;
  submittedAmount: number;
  currentPaid: number;
  currentDebt: number;
  percent: number | null;
  previousVolume: number;
  previousAmount: number;
};
export type getAllIrrigationSystemApiResponse = {
  id: number;
  name: string;
};
export type RegionDtos = {
  regionId: number;
  regionName: number | null;
  debtByDistrictPreviousYearRegion: number;
  annualPlanAreaOfCropsRegion: number | null;
  annualPlanVolumeRegion: number | null;
  annualPlanAmountRegion: number | null;
  signedForPaymentAmountRegion: number;
  signedForPaymentVolumeRegion: number;
  submittedVolumeRegion: number;
  submittedAmountRegion: number;
  currentPaidRegion: number;
  currentDebtRegion: number;
  percentRegion: number | null;
  previousVolumeRegion: number;
  previousAmountRegion: number;
  districtDtos: Array<DistrictDtos>;
};

export type GetConcludingAgreementApiResponse = {
  name: string;
  differencePaidLastYear: number | null;
  areaCultivated: number | null;
  waterVolAnnualPlan: number | null;
  amountAnnualPlan: number | null;
  waterVolAnnualAppsCurrYear: number | null;
  amountAnnualAppsCurrYear: number | null;
  waterPaymentsPendingCurrYear: number | null;
  amountPaymentsPendingCurrYear: number | null;
  amountPaymentsPaidCurrYear: number | null;
  waterDebt: number | null;
  percentCompletionPlanWaterSupplyCurrYear: number | null;
  waterAnnualAppsLastYear: number | null;
  amountAnnualAppsLastYear: number | null;
}[];

export type GetWaterConsumptionApiResponse = {
  reports: {
    name: string;
    systems: {
      [key: number]: number;
    };
  }[];
  irrigationSystems: {
    id: number;
    name: string;
  }[];
  sum: {
    legal: {
      [key: number]: number;
    };
    individual: {
      [key: number]: number;
    };
    association: {
      [key: number]: number;
    };
    all: {
      [key: number]: number;
    };
  };
};

export type IrrigationSystemContractualDtos = {
  reservoirId: number;
  reservoirName: string;
  irrigationSystemId: number;
  irrigationSystemName: string;
  areaOfCrops: number;
  wateringVolumeTotal: number;
  billWateringVolumeTotal: number;
  percent: number;
  postedForPaymentTotal: number;
  payWateringVolumeTotal: number;
  hectaresOfIrrigationPlan: number;
  hectaresOfIrrigationFact: number;
  hectaresOfIrrigationPercent: number;
  wateredAreaFirst: number;
  wateredAreaOther: number;
  irrigationNorm: number;
  irrigationRate: number;
  facilitiesContractualDtos: [
    {
      facilitiesId: number;
      facilitiesName: string;
      areaSum: number;
      wateringVolumeSum: number;
      billWateringVolumeSum: number;
      payWateringVolumeSum: number;
      postedForPayment: number;
    },
  ];
};

export type GetContractualWaterSupplyApiResponse = {
  reports: {
    name: string;
    basinName: string;
    actualCultivatedArea: number;
    contractualWaterSupplyPlan: number;
    contractualWaterSupplyFact: number | null;
    completionPercentage: number | null;
    invoicedAmount: number | null;
    amountPaid: number | null;
    tariffAverage: string;
    hectareIrrigationPlan: number;
    hectareIrrigationFact: number;
    hectareIrrigationPercentage: number | null;
    wateredAreas: { [key: string]: number };
    irrigationRate: number;
    irrigationNorm: number;
    filtrationArea: number;
  }[];
  typeNames: string[];
};

export type GetWaterConsumptionApiArg = Partial<{
  region: string;
  district: string;
  startDate: string;
  endDate: string;
}>;

export type GetConcludingAgreementApiArg = Partial<{
  region: string;
  district: string;
  startMonth: string;
  endMonth: string;
  year: string;
}>;

export type GetContractualWaterSupplyApiArg = {
  district: string;
  startDate: string;
  endDate: string;
};

export type CreateLaboratoryTestsApiArg = {
  year: number;
  quarterNumber: number;
  ms: number;
  ph: number;
  gl: number;
  zoneId: number;
  pointId: number;
};

export type CreateDepthApiArg = {
  year: number;
  monthNumber: number;
  decadeNumber: number;
  toTheBottom: number;
  toTheWater: number;
  headHeight: number;
  zoneId: number;
  pointId: number;
};

export type CreateStartVolumeApiResponse = number;
export type CreateStartVolumeApiArg = ICreateReservoirStartVolume;
export type GetReservoirCurrentVolumeApiResponse = number;
export type GetReservoirCurrentVolumeApiArg =
  Partial<IReservoirDataByMonthFilters>;

export type GetReservoirsApiResponse = PaginationWrapper<ISystemObject>;
export type GetReservoirsApiArg = void;

export type GetReservoirByIdApiResponse = ISystemObject;
export type GetReservoirByIdApiArg = {
  reservoirId: number;
};

export type CreateReservoirDataApiResponse = void;
export type CreateReservoirDataApiArg = ICreateReservoirData;

// export type UpdateReservoirDataApiResponse = void;
// export type UpdateReservoirDataApiArg = IReservoir & { year: string | null };

export type GetReservoirDataMonthApiResponse = IReservoirDataByMonth;
export type GetReservoirDataMonthApiArg = IReservoirDataByMonthFilters;

export type FindAllTariffListApiResponse = PaginationWrapper<ITariff>;
export type FindAllTariffListApiArg = ITariffFilters;

export type EconomicPlanApiResponse = IBusinessPlanItem;
export type EconomicPlanApiArg = IBusinessPlanFilters;

export type GetFacilitiesByFilterApiResponse = Array<IFacility>;
export type GetFacilitiesByFilterApiArg = IAnnualPlanFilters;

export type ContractualWatterSuplyPlanApiResponse = Array<IAnnualPlan>;
export type ContractualWatterSuplyPlanApiArg = IAnnualPlanFilters;

export interface PlantingTableDataDto extends IPlantingDataDto {
  foodPlantName: string;
  standardsFoodPlantDtos: any[];
  totalDays: number;
  area: number;
  kpd: number;
  totalIrrigationStandard: number;
}

export type GetTariffByRuvhIdApiResponse = ITariff[];

export type GetTariffByRuvhIdApiArg = any;

export type GetTariffByIdApiResponse = ITariff;

export type GetTariffByIdApiArg = {
  tariffId: number;
};

export type CreateTariffApiArg = Omit<
  ICreateTariff,
  'id' | 'tariffName' | 'createDate'
>;
export type CreateTariffApiResponse = ITariff;

export type GetQualityMonitoringApiResponse = Array<string>;

export type GetReclamationStatesApiResponse = Array<string>;

export type GetPointsApiResponse = {
  id: number;
  pointName: string;
  regionId: number;
  regionName: string;
  districtId: number;
  districtName: string;
  okmotId: number;
  okmotName: string;
  type: string;
  zoneId: number | null;
};

export type GetPointsApiArg = {
  regionId: number;
  districtId: number;
  okmotId: number;
  type: string;
};

export type GetZonesApiResponse = Array<GetZoneApiResponse>;
export type GetZoneApiResponse = {
  id?: string | number;
  zoneName: string;
  zoneId: number;
  regionId: number;
  districtId: number;
  okmotId: number;
  type: string;
  area: number;
  points: GetPointsApiResponse;
};

export type GetZoneApiArg = {
  id: number | string;
};
export type GetZonesApiArg = {
  regionId: number;
  districtId: number;
  okmotId: number;
  zoneId: number;
  type: string;
};

export type GetPointTypeApiResponse = Array<string>;

export type CreatePointApiResponse = number;

export type CreateZoneApiResponse = number;

export type CreateZoneApiArg = {
  zoneId: number;
  zoneName: string;
  regionId: number;
  districtId: number;
  okmotId: number;
  type: string;
  area: number;
  points: Array<GetPointsApiResponse>;
};

export type CreatePointApiArg = {
  pointName: string;
  regionId: number;
  districtId: number;
  okmotId: number;
  type: string;
};

export type GetComputingEstimatedFinancialPlanApiResponse = {
  agroclimaticZoneId: number;
  agroclimaticZoneName: string;
  facilityId: number;
  facilityName: string;
  organizationId: number;
  organizationName: string;
  okmotName: string;
  districtId: number;
  districtName: string;
  regionId: number;
  regionName: string;
  outletId: number;
  outletName: string;
  channelId: number;
  channelName: string;
  systemId: number;
  systemName: string;
  plantingTableDataDtos: PlantingTableDataDto[];
  okmotId: number;
};

export type GetAnnualDataByQuarterApiResponse = {
  facilityId: number;
  facilityName: string;
  totalArea: number;
  waterVolume: number;
  price: number;
  firstFourthQuarterDto: {
    waterVolume: number;
    price: number;
    tarif: number;
  };
  secondThirdQuarterDto: {
    waterVolume: number;
    price: number;
    tarif: number;
  };
};

export type CalculateAnnualContractApiArg = {
  waterUserId: number;
  plantingDataDtoList: IPlantingDataDto[];
};
export type CalculateAnnualContractApiResponse = IAnnualApplicationCalculations;

export type GetFacilitiesByIdApiResponse = IFacility;
export type FindAllAnnualContractListApiArg =
  Partial<IAnnualApplicationFilters>;

export type FindAllAnnualContractListApiResponse =
  PaginationWrapper<IAnnualApplication>;

export type GetFacilitiesByOrgIdApiResponse = Array<IFacility>;
export type GetFacilitiesByOrgIdApiArg = {
  orgId: number;
};
export type GetFacilitiesByIdApiArg = {
  id: number;
};

export type GetPlantingListApiResponse = DefaultOptionType[];
export type GetPlantingListApiArg = void;

export type ArchiveIrrigationSystemApiArg = { id: number };

export type toArchiveWaterUserApiArg = { id: number };
export type GetAgroClimaticZoneByIdApiResponse = IAgroClimaticZone;
export type GetAgroClimaticZoneByIdApiArg = { id: number };

export type UpdateAgroClimaticZoneApiResponse = IAgroClimaticZone;
export type UpdateAgroClimaticZoneApiArg = IAgroClimaticZone;

export type CreateAgroClimaticZoneApiResponse = IAgroClimaticZone;
export type CreateAgroClimaticZoneApiArg = Pick<IAgroClimaticZone, 'name'>;

export type GetAllAgroClimaticZonesApiResponse = Array<IAgroClimaticZone>;
export type GetAllAgroClimaticZonesApiArg = void;

export type GetAgroClimaticZonesApiResponse =
  PaginationWrapper<IAgroClimaticZone>;
export type GetAgroClimaticZonesApiArg = Partial<IAgroClimaticZoneFilters>;

export type UpdateDecadeActOfMonthApiResponse = IActsInvoicesForPayments;
export type UpdateDecadeActOfMonthApiArg = IActsInvoicesForPayments;

export type ActToBillApiResponse = IActsInvoicesForPayments;
export type ActToBillApiArg = IActsInvoicesForPayments;

export type PayBillApiResponse = IActsInvoicesForPayments;
export type PayBillApiArg = {
  id: number;
};

export type GetAllSystemsResponse = {
  id: number;
  display_name: string;
  parent_system_id: null | number;
  facility_tree: null | string;
  timezone: string;
  resp_user_id: number;
  can_have_hps: boolean;
};

export type GetAllSensorsResponse = {
  imei: number;
  display_name: string;
  hpid: number;
  hp_name: string;
  mqtt_topic: null | string;
  create_date: string;
  power: number;
  signal: number;
  temp: string;
  last_transmission: number;
  last_measure: number;
  timezone: null | string;
  model_id: number;
  model_name: string;
};
export type FindAllHydropostsResponse = {
  id: number;
  display_name: string;
  system_name: string;
  system_id: number;
  waterchannel: string;
  watercourse: string;
  hp_type_id: number;
  sensor_imei: number;
  resp_user_id: number;
  user_name: string;
  timezone: string;
  calibrated: boolean;
  max_level: number;
  location_x: null | string;
  location_y: null | string;
  is_active: boolean;
  create_date: number;
  model_link: null | string;
  photo_url: string;
  permissions: string;
};

export type getAllMeasurementsResponse = {
  flow: string;
  level: number;
  utc: number;
  imei: number;
  hpid: number;
  hp_name: string;
};

export type getAllMeasurementsArg = {
  id: number;
  start_date: string;
  end_date: string;
};

export type findSystemsByWithFiltersResponse = {
  utc: number;
  total_intake: string;
  intake: string;
  supply: string;
  discard: string;
};

export type findHydropostsByWithTableResponse = {
  flow: string;
  imei: number;
  level: string;
  utc: number;
};

export type findSystemsByWithFiltersArg = {
  id: number;
  start_date: string;
  end_date: string;
};

export type getHydropostsByTypeResponse = {
  id: number;
  display_name: string;
  order_index: number;
};

export type FindAllIrrigationSystemsOwnersApiResponse = Array<Owner>;
export type FindAllIrrigationSystemsOwnersApiArg = void;

export type FindAllActsInvoicesForPaymentsApiResponse =
  PaginationWrapper<IActsInvoicesForPayments>;
export type FindAllActsInvoicesForPaymentsApiArg =
  Partial<IActsInvoicesForPaymentsFilters>;

export type CreateSourceApiResponse = ISource;
export type CreateSourceIdApiArg = {
  sourceId: number;
  description: string;
  name: string;
};

export type FindAllIrrigationSystemObjectsListWithFiltersApiArg =
  Partial<IWaterObjectFilters>;
export type FindAllIrrigationSystemObjectsListWithFiltersApiResponse =
  /** status 200 OK */ PaginationWrapper<IWaterObject>;

export type FindReferencesByParentIdApiResponse = Array<CommonReference>;
export type FindReferencesByParentIdApiArg = {
  parentId: number;
};

export type FindAqueductByIdApiResponse = IWaterObject;
export type FindAqueductByIdApiArg = {
  objectId: number;
};

export type GetSourcesApiResponse = Array<ISource>;
export type GetSourcesApiArg = {
  regionId?: string;
  basinId?: string;
};

export type GetNaturalSourcesApiResponse = Array<INaturalSource>;
export type GetNaturalSourcesApiArg = {
  basinId: number;
  regionId: number;
};

export type GetAllSourceTypesApiResponse = Array<ISourceType>;
export type GetAllSourceTypesApiArg = void;

export type GetAllBasinApiResponse = Array<IBasin>;
export type GetAllBasinApiArg = {
  regionId: string;
} | void;

export type FindGeneralAqueductByIdApiResponse = IWaterObject;
export type FindGeneralAqueductByIdApiArg = {
  systemId: number;
};

export type DeleteAqueductObjectsWithSectionsApiResponse = void;
export type DeleteAqueductObjectsWithSectionsApiArg = {
  objectId: number;
  aqueductId: number;
};

export type FindObjectCategoryByIdApiResponse = ObjectCategory;
export type FindObjectCategoryByIdApiArg = {
  categoryId: string;
};

export type GetWateringPlanDecadalRequestApiArg = {
  month: MonthEnums;
  year: number;
};
export type SaveWateringPlanDecadalRequestApiArg = {
  id: string;
  value: number;
}[];

export type GetWateringPlanDecadalRequestDecadeDtos = {
  id: number;
  value: {
    request: number;
    plan: number;
    fact: number;
  };
  status: string;
  decade: number;
  month: number;
  year: number;
  facilityName: string;
  facilityId: number;
  outletName: string;
  outletId: number;
};

export type GetWateringPlanDecadalRequestApiResponse = {
  facilityName: string;
  facilityId: number;
  outletName: string;
  outletId: number;
  month: number;
  year: number;
  decadeDtos: Array<GetWateringPlanDecadalRequestDecadeDtos>;
};

export type FindAllObjectCategoriesByObjectGroupIdApiResponse =
  Array<ObjectCategory>;
export type FindAllObjectCategoriesByObjectGroupIdApiArg = {
  objectGroupId: string;
};

export type FindObjectGroupByCodeApiResponse = IObjectGroup;
export type FindObjectGroupByCodeApiArg = {
  code: string;
};

export type FindAllObjectGroupsApiResponse = Array<IObjectGroup>;
export type FindAllObjectGroupsApiArg = void;

export type FindAllObjectsByAqueductSectionsApiResponse = Array<IWaterObject>;
export type FindAllObjectsByAqueductSectionsApiArg = {
  objectId: string;
};

export type GetObjectCategoriesApiResponse = Array<ObjectCategory>;
export type GetObjectCategoriesApiArg = void;

export type AqueductToAqueductApiResponse = void;
export type AqueductToAqueductApiArg = {
  aquductId: number;
  parentAquductId: number;
};

export type AqueductGtsConstructorApiResponse = void;
export type AqueductGtsConstructorApiArg = {
  gtsId: number;
  aqueductId: number;
};

export type savePhysicalWaterUserApiResponse = /** status 200 OK */ EmployeeDto;
export type savePhysicalWaterUserApiArg = {
  body: any;
};
export type saveLegalsWaterUserApiResponse = /** status 200 OK */ WaterUserDto;

export type CreateWaterUserLegalDataDto = {
  name: string;
  registerDate: Date;
  regNumber: string;
  address: string;
  inn: string;
  passportNumber: string;
  okpo: string;
  region: number;
  district: number;
  phone: string;
  fax: string;
  email: string;
  organizationAccount: string;
  accountNumber: string;
  bank: number;
  organizationForm: number;
  businessActivity: number;
  economicActivity: number;
  regForm: number;
  refFlow: number;
  typeOrganization: number;
  gkedClassification: number;
  legalForm: number;
  okmot: number;
  typeProperty: number;
  ownershipForm: number;
  associationType: number;
  attachmentsList: any;
};

export type saveLegalPersonApiArg = {
  code: string;
  employees: EmployeeDto[];
  facilities: FacilitiesDto[];
  legalData: CreateWaterUserLegalDataDto;
  region: number;
  district: number;
  type: number;
  isAssociation: boolean;
  isLegal: boolean;
  isIndividual: boolean;
  outlets: Array<number>;
  attachmentsList: any;
};

export type saveLegalsWaterUserApiArg = {
  body: any;
};

export type SaveIrrigationSystemWaterObjectApiResponse = unknown;
export type SaveIrrigationSystemWaterObjectApiArg = {
  irrigationSystemDto: SystemDto;
};
export type UpdateIrrigationSystemWaterObjectApiResponse = unknown;
export type UpdateIrrigationSystemWaterObjectApiArg = {
  waterObject: WaterObjectInterface;
};

export type AddGeonodeApiResponse = string;
export type AddGeonodeApiArg = {
  systemId: number;
  points: string;
};

export type GanttDataResponse = {
  id: number;
  title: string;
  status: string;
  priority: string;
  planStartDate: Dayjs | string;
  planEndDate: Dayjs | string;
  blockType: string;
};

export type GanttResponse = {
  data: GanttDataResponse[];
  meta: {
    totalCount: number;
    limit: number;
    page: number;
  };
};

export type GetAllOperationalApiResp = {
  data: IOperationalBlock[];
  meta: {
    totalCount: number;
    limit: number;
    page: number;
  };
};

export type CreateSystemObjectListApiResponse = IWaterObject[];
export type CreateSystemObjectListApiArg = IWaterObject[];

export type CreateSystemObjectApiResponse = IWaterObject;
export type CreateSystemObjectApiArg = IWaterObject;

export type CreateWaterSectionApiResponse = IWaterSection;
export type CreateWaterSectionApiArg = IWaterSection;

export type GetWaterSectionsBySystemObjectIdApiResponse = IWaterSection[];
export type GetWaterSectionsBySystemObjectIdApiArg = string;

export type SaveIrrigationSystemApiResponse = unknown;
export type SaveIrrigationSystemApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type SaveApiResponse = /** status 200 OK */ SystemResponseDto;
export type SaveApiArg = {
  fakeSystemDto: FakeSystemDto;
};

export type UpdateApiResponse = unknown;
export type UpdateApiArg = {
  facilitiesDto: FacilitiesDto;
};

export type CreateBankApiResponse = /** status 200 OK */ number;
export type CreateBankApiArg = {
  bankDto: BankDto;
};
export type UploadFilesApiResponse = /** status 200 OK */ {
  filePath: Blob;
  name: string;
  type: string;
  extension: string;
  attachmentId: number;
};

export interface UploadFilesApiArg {
  [key: string]: string | Blob;
}

export type SignUpApiResponse = /** status 201 Created */ AuthResponse;
export type SignUpApiArg = {
  signupRequest: SignupRequest;
};

export type FindAllPhysicalApiResponse =
  /** status 200 OK */ PaginationWrapper<WaterUserTableDto>;
export type FindAllPhysicalApiArg = Partial<IWaterRegistryFilters>;

export type AttachmentDto = {
  attachmentId: number;
  description: string;
  downloadUrl: string;
  extension: string;
  file: string;
  filePath: string;
  name: string;
  objectId: number;
  organizationId: number;
  originName: string;
  systemId: number;
  type: string;
  waterUserId: number;
};

export type FindAllByOrganizationIdApiResponse = any;
export type FindAllByOrganizationIdApiArg = {
  id: number;
};

export type FindAllLegalPersonApiResponse =
  /** status 200 OK */ PaginationWrapper<WaterUserTableDto>;
export type FindAllLegalPersonApiArg = Partial<IWaterRegistryFilters>;

export type FindAllAssociationApiResponse =
  /** status 200 OK */ PaginationWrapper<WaterUserTableDto>;
export type FindAllAssociationApiArg = Partial<IWaterRegistryFilters>;
export type FindAllUsersApiResponse = PaginationWrapper<WaterUserTableDto>;
export type FindAllUsersApiArg = Partial<IWaterRegistryFilters>;
export type FindDictionaryByTypeIdApiResponse =
  /** status 200 OK */ CommonReference[];
export type FindDictionaryByTypeIdApiArg = {
  id: number;
};
export type FindDictionaryItemByTypeIdApiResponse =
  /** status 200 OK */ CommonReference;
export type FindDictionaryItemByIdApiArg = {
  id: number;
};

export type FindAllIrrigationSystemObjectsListApiArg = void;
export type FindAllIrrigationSystemObjectsListApiResponse =
  /** status 200 OK */ Array<{ value: number; label: string }>;

export type FindAllIrrigationSystemListApiArg =
  Partial<IrrigationSystemFilters>;
export type FindAllIrrigationSystemListApiResponse =
  /** status 200 OK */ PaginationWrapper<IrrigationSystem>;

export type FindAllIrrigationSystemObjectsListChannelsApiArg = {
  districtId?: number;
  regionId?: number;
  systemId?: number;
};
export type FindAllIrrigationSystemObjectsListChannelsApiResponse =
  /** status 200 OK */ IWaterObject[];

export type FindAllIrrigationSystemObjectsListOutletsApiArg = {
  districtId?: number;
  regionId?: number;
  systemId?: number;
};
export type FindAllIrrigationSystemObjectsListOutletsApiResponse =
  /** status 200 OK */ IWaterObject[];

export type FindAllIrrigationSystemObjectsListSourcesApiArg = void;
export type FindAllIrrigationSystemObjectsListSourcesApiResponse =
  /** status 200 OK */ IWaterObject[];

export type UpdateSystemObjectApiResponse = /** status 200 OK */ IWaterObject;
export type UpdateSystemObjectApiArg = {
  waterObject: IWaterObject | any;
};

export type UpdateSystemObjectStatusApiResponse =
  /** status 200 OK */ IWaterObjectStatus;
export type UpdateSystemObjectStatusApiArg = {
  status: IWaterObjectStatus;
  id: string;
};

export type CreateAqueductWaterObjectApiResponse =
  /** status 200 OK */ IWaterObject;
export type CreateAqueductWaterObjectApiArg = {
  waterObject: IWaterObject;
};

export type CreateWaterObjectsAqueductWaterObjectApiResponse =
  /** status 200 OK */ IWaterObject[];
export type CreateWaterObjectsAqueductWaterObjectApiArg = {
  waterObjects: IWaterObject[];
};

export type AquedactObjectsSectionConstructorApiResponse =
  /** status 200 OK */ void;
export type AquedactObjectsSectionConstructorApiArg = Array<{
  piketCoordinates: string;
  direction: string;
  systemObjectId: number;
  sectionId: number;
}>;

export type GetAqueductListBySystemIdApiResponse =
  /** status 200 OK */ IWaterObject[];
export type GetAqueductListBySystemIdApiArg = {
  systemId: number;
};

export type FindAllObjectsByAqueductIdApiResponse =
  /** status 200 OK */ IWaterObject[];
export type FindAllObjectsByAqueductIdApiArg = {
  objectId: string;
};

export type FindAllAllWaterSectionsByAqueductIdApiResponse =
  /** status 200 OK */ IWaterSection[];
export type FindAllAllWaterSectionsByAqueductIdApiArg = {
  objectId: string;
};

export type FindIrrigationSystemByIdResponse = IrrigationSystem;
export type FindIrrigationSystemByIdArg = {
  irrigationSystemId: string;
  timestamp?: number;
};

export type GetIrrigationSystemInfoByIdResponse = IrrigationSystemInfo;
export type GetIrrigationSystemInfoByIdArg = {
  irrigationSystemId: string;
};

export type GetIrrigationSystemObjectsAndStructureByIdResponse =
  PaginationWrapper<ISystemObject>;
export type GetIrrigationSystemObjectsAndStructureByIdArg = {
  irrigationSystemId: string;
  params?: any;
};

export type IWaterManagementResponse = {
  content: IWaterManagement[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
};
export type IWaterManagementArg = {
  year: number;
  search?: string;
  page?: number;
  size?: number;
};

export type IWaterManagement = {
  id: number;
  year: string;
  status: string;
  agriculturalWaterSupply: string | null;
  householdDrinking: string | null;
  industrial: string | null;
  irrigationWater: string | null;
  other: string | null;
  waterUseType: string;
  waterUser: WaterUser;
};

export type WaterUser = {
  id: number;
  legalData: LegalData;
};

export type LegalData = {
  name: string;
  address: string;
  region: any;
  district: any;
};

export type ITotalWaterResponse = {
  total_surface: string;
  householdDrinking_surface: string;
  industrial_surface: string;
  irrigationWater_surface: string;
  agriculturalWaterSupply_surface: string;
  other_surface: string;
  total_underground: string;
  householdDrinking_underground: string;
  industrial_underground: string;
  irrigationWater_underground: string;
  agriculturalWaterSupply_underground: string;
  other_underground: string;
};

export type GetIrrigationWaterUsersByIdResponse =
  PaginationWrapper<IWaterUserV2>;
export type GetIrrigationWaterUsersByIdArg = {
  irrigationSystemId: string;
  params: {
    code?: string;
    name?: string;
    userType?: number;
  };
};

export type FindAllBanksApiResponse = /** status 200 OK */ BankDto[];
export type FindAllBanksApiArg = void;
export type FindBankByIdApiResponse = /** status 200 OK */ BankDto;
export type FindBankByIdApiArg = {
  id: number;
};

export type FindAllRegularEventListApiResponse = {
  completed: number;
  expired: number;
  totalCount: number;
  onRealization: number;
};
export type FindAllRegularEventListApiArg = void;
export type FindAllExtraTaskEventListApiResponse = {
  status: {
    completed: number;
    expired: number;
    totalRealization: number;
    onRealization: number;
  };
  totalCount: number;
  type: {
    external: number;
    internal: number;
  };
};
export type FindAllOperationalBlockEventListApiResponse = {
  rehabilitationWork: {
    totalCount: number;
    totalFactBudget: number;
    totalPlanBudget: number;
  };
  reconstructionWork: {
    totalCount: number;
    totalFactBudget: number;
    totalPlanBudget: number;
  };
  capitalWork: {
    totalCount: number;
    totalFactBudget: number;
    totalPlanBudget: number;
  };
  currentWork: {
    totalCount: number;
    totalFactBudget: number;
    totalPlanBudget: number;
  };
  emergencyWork: {
    totalCount: number;
    totalFactBudget: number;
    totalPlanBudget: number;
  };
  status: {
    preparatory: number;
    onRealization: number;
    onControl: number;
    completed: number;
    expired: number;
  };
  finance: {
    totalBudget: number;
    realization: number;
    remains: number;
    onRealization: number;
  };
  totalCount: number;
};
export type FindAllOperationalBlockEventListApiArg = {
  id?: number;
};
export type FindAllConstructionBlockEventListApiResponse = {
  finance: {
    totalBudget: number;
    realization: number;
    remains: number;
    onRealization: number;
  };
  type: {
    interFarmChannels: number;
    interFarmBuildings: number;
    farmChannels: number;
    farmBuildings: number;
  };
  totalCount: number;
};
export type FindAllConstructionBlockEventListApiArg = {
  id?: number;
};
export type FindAllSystemObjectsApiResponse = any;
export type FindAllSystemObjectsApiArg = void;
export type FindSystemObjectByIdApiResponse = any;
export type FindSystemObjectByIdApiArg = {
  id: string;
};
export type FindAllExtraTaskEventListApiArg = void;
export type FindAllFilialApiResponse = /** status 200 OK */ BankDto[];
export type FindAllFilialApiArg = { bankId: number };

export type FindOperationalBlockByIdApiResponse =
  /** status 200 OK */ IOperationalBlock;
export type FindOperationalBlockByIdApiArg = {
  id: number;
};

export type FindConstructionBlockByIdApiResponse =
  /** status 200 OK */
  IBuildingBlock | any;
export type FindConstructionBlockByIdApiArg = {
  id: number;
};

export type FindRegularEventByIdApiResponse =
  /** status 200 OK */ IRegularEventBlock;
export type FindRegularEventByIdApiArg = {
  id: number;
};

export type RehabilitationWorksDto = {
  uuid?: string;
  name?: string;
  is_repeated?: string;
  description?: string;
  pathProtocolTechnicalFile?: string;
  pathNatureObjectFile?: string;
  pathDefectiveAct?: string;
  pathDefectiveSheet?: string;
  budget?: string;
  budgetDescription?: string;
  budgetAttachment?: string;
  dateStart?: string;
  dateEnd?: string;
  months?: number[];
  types?:
    | '\u041A\u0430\u043F\u0438\u0442\u0430\u043B\u044C\u043D\u0430\u044F'
    | '\u0420\u0435\u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F'
    | '\u041F\u043B\u0430\u043D\u043E\u0432\u0430\u044F'
    | '\u041F\u0440\u043E\u0444\u0438\u043B\u0430\u043A\u0442\u0438\u0447\u0435\u0441\u043A\u0430\u044F';
  researchObjectDescription?: string;
  researchObject?: string;
  objectInventory?: string;
  pathResearchSheet?: string;
  waterObjectId?: number;
  waterObjectName?: string;
  status?: string;
};
export type OutletFacilitiesDto = {
  name?: string;
  id?: number;
};
export type OrganizationDto = {
  isRuvh?: boolean;
  organizationId?: number;
  organizationName?: number;
  name?: string;
  regionName?: string;
  districtName?: string;
  address?: string;
  inn?: string;
  phone?: string;
  totalArea?: number;
  type?: string;
  gked?: string;
  legalForm?: string;
  typeProperty?: string;
  code?: string;
  cretedAt?: string;
  updatedAt?: string;
  children?: OrganizationDto[];
  parent_id?: number;
};
export type FacilitiesDto = {
  systemId?: number;
  channelId?: number;
  okmotId?: number;
  regionId?: number;
  disticrtId?: number;
  channelName?: string;
  systemName?: string;
  outletsList?: OutletFacilitiesDto[];
  outletLong?: number[];
  name?: string;
  additionalAddress?: string;
  coordinates_X?: string;
  coordinates_Y?: string;
  organization?: string;
  area?: number;
  facilities?: number;
  organizationDto?: OrganizationDto;
  waterUserId?: number;
};
export type BankDto = {
  id?: number;
  code?: string;
  bankName?: string;
  bankBik?: string;
  bankInn?: string;
  bankOkpo?: string;
  bankCity?: string;
  bankAddress?: string;
  isFilial?: boolean;
  regionId?: number;
  districtId?: number;
  parent_id?: number;
  regionName?: string;
  districtName?: string;
  bankDto?: BankDto;
};
export type EmployeeDto = {
  id?: number;
  waterUserId?: number;
  attachmentResponseDtos?: {
    name: string;
    extension: string;
    filePath: string;
    type: string;
  }[];
  lastName?: string;
  firstName?: string;
  middleName?: string;
  organizationId?: number;
  inn?: string;
  fax?: string;
  passportNumber?: string;
  gender?: string;
  address?: string;
  email?: string;
  bankNumber?: string;
  phone?: string;
  headEnum?: string;
  additionalPhone?: string;
  position?: string;
  dateBirth?: string;
  businessActivityId?: number;
  organizationFormId?: number;
  regionId?: number;
  districtId?: number;
  okmotId?: number;
  passportFile?: Blob;
  bankId?: number;
  facilities?: FacilitiesDto[];
  contractFile?: Blob;
  annualContractFile?: Blob;
  diplomFile?: Blob;
  foundationFile?: Blob;
  otherFile?: Blob;
  passportFilePath?: string;
  contractFilePath?: string;
  annualContractFilePath?: string;
  diplomFilePath?: string;
  foundationFilePath?: string;
  otherFilePath?: string;
  hasWhatsapp?: boolean;
  hasTelegram?: boolean;
  bank?: BankDto;
  area: number;
  organizationFormName: WaterUserTypes;
  fio: string;
};

export type InitialDataAssociationDto = {
  directorsCount?: number;
  legalPersonCount?: number;
  stateLegalPersonCount?: number;
  physicalPerson?: number;
  employeeCount?: number;
  employeeFemaleCount?: number;
  pzScheme?: boolean;
  finishedPz?: boolean;
  zoneAgentsCount?: number;
  zoneAgentsFemaleCount?: number;
  pzTableEnum?: 'YES' | 'NO' | 'COMMON' | 'NOT_WORKING';
};
export type WaterUserDto = {
  organizationInn?: string;
  organizationName?: string;
  organizationId: number;
  regNumber?: string;
  okpo?: string;
  organizationAddress?: string;
  organizationDoc?: string;
  fax?: string;
  email?: string;
  bankNumberOrganization?: string;
  correspondentAccountNumber?: string;
  phone?: string;
  additionalPhone?: string;
  regFormId?: number;
  refFlowId?: number;
  regionId?: number;
  districtId?: number;
  bankId?: number;
  bank?: BankDto;
  bussinessActivityId?: number;
  economicActivityId?: number;
  dateRegistrationOrganization?: string;
  employeeList?: EmployeeDto[];
  organizationFormId?: number;
  organizationType?: string;
  facilities?: FacilitiesDto[];
  rateAssociation?: number;
  initialDataAssociation?: InitialDataAssociationDto;
  contractFile?: Blob;
  area?: number;
  fio: string;
  post: string;
};
export type WaterObjectTypeDto = {
  name?: string;
  routes?: string;
};
export type ChannelDto = {
  id?: number;
  channelCode?: string;
  nameChannelRus?: string;
  nameChannelKyr?: string;
  createdAtChannel?: string;
  updatedChannel?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  areaOfSelfFlowingIrrigation?: number;
  areaOfAutomaticIrrigation?: number;
  totalAreaOfIrrigationLands?: number;
  actuallyUsedIrrigationLands?: number;
  residualValue?: number;
  wearSum?: number;
  characteristicOfFeaturesOfOperationSystem?: string;
  totalAreaOfLandsOfWaterFund?: number;
  totalAreaOfLandsUnderPlantings?: number;
  totalAreaOfLandsUnderAuxiliaryAndInhabitedConstructions?: number;
  totalAreaOfLandsUnderOtherObjects?: number;
};
export type ReservoirDto = {
  reservoirCode?: string;
  created_At?: string;
  nameRus?: string;
  nameKyr?: string;
  update_At?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  annualDrainMean?: number;
  fullVolume?: number;
  usefulVolume?: number;
  npgArea?: number;
  maxDepth?: number;
  npgMark?: number;
  residualValue?: number;
  wearSum?: number;
  damCharacteristic?: string;
  constructionDesignAndAutomationEquipment?: string;
  characteristicField1?: string;
  characteristicField2?: string;
  characteristicField3?: string;
  characteristicField4?: string;
  waterFundLandsTotalArea?: number;
  waterFundLandsUnderPlantingsArea?: number;
  waterFundLandsInhabitedConstructionArea?: number;
  waterFundLandsOtherArea?: number;
};
export type PumpingStationDto = {
  code?: string;
  nameRus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  residualValue?: number;
  wearSum?: number;
  irrigatedArea?: number;
  drainageArea?: number;
  designProductivity?: number;
  currentProductivity?: number;
  characteristicField1?: string;
  characteristicField2?: string;
  characteristicField3?: string;
};
export type OutletsDto = {
  id?: number;
  outletCode?: string;
  nameChannelRus?: string;
  nameChannelKyr?: string;
  createdAtChannel?: string;
  updatedChannel?: string;
};
export type CollectorsDto = {
  code?: string;
  nameRus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  totalDrainageArea?: number;
  closedNetworkDrainageArea?: number;
  residualValue?: number;
  wearSum?: number;
};
export type WaterIntakeDto = {
  code?: string;
  nameRus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  purpose?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  takenWaterQuality?: number;
  dumpedWaterQuality?: number;
  irrigationLandsTotalArea?: number;
  residualValue?: number;
  wearSum?: number;
  systemFeaturesCharacteristic?: string;
  constructionDesignAndAutomationEquipment?: string;
  waterFundLandsTotalArea?: number;
  waterFundLandsUnderPlantingsArea?: number;
  waterFundLandsInhabitedConstructionArea?: number;
  waterFundLandsOtherArea?: number;
};
export type WaterObjectDtoChannel = {
  id?: number;
  uuid?: string;
  nameRus?: string;
  nameKyr?: string;
  waterObjectTypeDto?: WaterObjectTypeDto;
  created?: string;
  updated?: string;
  channelDto?: ChannelDto;
  reservoirDto?: ReservoirDto;
  pumpingStationDto?: PumpingStationDto;
  outletsDto?: OutletsDto;
  collectorsDto?: CollectorsDto;
  waterIntakeDto?: WaterIntakeDto;
};
export type SystemTreeDto = {
  id?: string;
  systemId?: string;
  title?: string;
  isDirectory?: boolean;
  expanded?: boolean;
  children?: SystemTreeDto[];
  code?: string;
  groupObject?: number;
  typeObject?: number;
  categoryObject?: number;
  piketLength?: string;
  piketLengthFirstPart?: string;
  piketLengthSecondPart?: string;
  length?: string;
  coordinates?: string;
  dateStart?: string;
};
export type IrrigationSystemDto = {
  systemId?: string;
  id?: string;
  systemUuid?: string;
  created?: string;
  updated?: string;
  title?: string;
  name?: string;
  piket?: string;
  waterObjectName?: string;
  waterObjectUuid?: string;
  children?: IrrigationSystemDto[];
};
export type SystemResponseDto = {
  systemId?: number;
};
export type FakeSystemDto = {
  name?: string;
  regNum?: string;
  districtId?: number;
  regionId?: number;
  systemId?: string;
  dateDesign?: string;
  residualValue?: string;
  wearSum?: string;
  areaPlanting?: string;
  lengthInnerChannels?: string;
  amountStructures?: string;
  area?: string;
  areaOfFields?: string;
  additionalConstructions?: string;
  otherObjects?: string;
  waterMeasurementDevices?: string;
  waterMeasurementDevicesAuto?: string;
};
export type SystemDto = {
  name?: string;
  regNum?: string;
  district?: string;
  baseId?: string;
  systemId?: string;
  bassinId?: string;
  districtId?: string;
  region?: string;
  regionId?: string;
  dateDesign?: string;
  residualValue?: number;
  wearSum?: number;
  areaPlanting?: number;
  lengthInnerChannels?: number;
  amountStructures?: number;
  waterMeasurementDevices?: number;
  waterMeasurementDevicesAuto?: number;
  area?: number;
  areaOfFields?: number;
  additionalConstructions?: number;
  otherObjects?: number;
  waterObject?: Array<WaterObjectInterface>;
  attachmentResponseDtos?: Array<number>;
  attachmentIds: { id: number }[];
};
export type CommonReferenceType = {
  id?: number;
  title?: string;
  code?: string;
};

export type Bank = {
  id?: number;
  code?: string;
  created?: string;
  updated?: string;
  name?: string;
  bik?: string;
  inn?: string;
  address?: string;
  isFilial?: boolean;
  okpo?: string;
  city?: string;
  parent?: Bank;
};
export type Organization = {
  id?: number;
  isRuvh?: boolean;
  organizationEnum?: string;
  name?: string;
  correspondentAccountNumber?: string;
  bankNumber?: string;
  okpo?: string;
  doc?: string;
  dateRegistration?: string;
  region?: CommonReference;
  district?: CommonReference;
  address?: string;
  inn?: string;
  phone?: string;
  additionalPhone?: string;
  contractFile?: string;
  fax?: string;
  email?: string;
  regNumber?: string;
  totalArea?: number;
  tableNumber?: string;
  rateAssociation?: number;
  ruvh?: Organization;
  organizationForm?: CommonReference;
  businessActivity?: CommonReference;
  regForm?: CommonReference;
  refFlow?: CommonReference;
  typeOrganization?: CommonReference;
  gkedClassification?: CommonReference;
  legalForm?: CommonReference;
  bank?: Bank;
  typeProperty?: CommonReference;
  code?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type User = {
  id?: number;
  role?: string;
  username?: string;
  password?: string;
  organization?: Organization;
  waterUser?: WaterUsers;
};

export type WaterUsers = {
  id?: number;
  code?: string;
  created_At?: string;
  updated_At?: string;
  inn?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  email?: string;
  phone?: string;
  address?: string;
  passportNumber?: string;
  gender?: string;
  regNumber?: string;
  fax?: string;
  bankNumber?: string;
  positionEmployee?: string;
  headEnum?: string;
  phone2?: string;
  hasWhatsapp?: boolean;
  hasTelegram?: boolean;
  dateBirth?: string;
  passportFile?: string;
  contractFile?: string;
  annualContractFile?: string;
  foundationFile?: string;
  diplomFile?: string;
  otherFile?: string;
  isOrganization?: boolean;
  organization?: Organization;
  ruvh?: Organization;
  user?: User;
  bank?: Bank;
  region?: CommonReference;
  activity?: CommonReference;
  district?: CommonReference;
};

export type WaterObjectInterface = {
  name: string;
  systemId: number;
  objectId: number;
  objectName: string;
  objectCode: string;
  typeObjectId: number;
  groupObjectId: number;
  categoryObjectId: number;
  coordinates: string;
  dateStart: string;
  direction: string;
  attachment: {
    documentUrl: [string];
  };
  geoJson: {
    points: string;
  };
  parentId: number;
};
export type WaterObjectType = {
  id?: number;
  name?: string;
  code?: string;
  created_At?: string;
  update_At?: string;
  routes?: string;
};
export type WaterObject = {
  id?: number;
  code?: string;
  nameRus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  dateStart?: string;
  length?: number;
  piketLength?: string;
  coordinates?: string;
  children?: WaterObject[];
  parent?: WaterObject;
  groupObject?: CommonReference;
  typeObject?: CommonReference;
  categoryObject?: CommonReference;
  waterObjectType?: WaterObjectType;
};
export type Channels = {
  id?: number;
  code?: string;
  nameRus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  commissioningYear?: string;
  reconstructionYear?: string;
  designOrganization?: string;
  areaOfSelfFlowingIrrigation?: number;
  areaOfAutomaticIrrigation?: number;
  totalAreaOfIrrigationLands?: number;
  actuallyUsedIrrigationLands?: number;
  residualValue?: number;
  wearSum?: number;
  characteristicOfFeaturesOfOperationSystem?: string;
  totalAreaOfLandsOfWaterFund?: number;
  totalAreaOfLandsUnderPlantings?: number;
  totalAreaOfLandsUnderAuxiliaryAndInhabitedConstructions?: number;
  totalAreaOfLandsUnderOtherObjects?: number;
  waterObject?: WaterObject;
};
export type Outlets = {
  id?: number;
  code?: string;
  namerus?: string;
  nameKyr?: string;
  created_At?: string;
  update_At?: string;
  waterObject?: WaterObject;
  facilities?: Facilities[];
};
export type Facilities = {
  id?: number;
  users?: WaterUsers;
  organization?: Organization;
  region?: CommonReference;
  district?: CommonReference;
  systemId?: IrrigationSystem;
  channels?: Channels;
  outlets?: Outlets[];
  name?: string;
  code?: string;
  totalArea?: number;
  created?: string;
  updated?: string;
  additionalAddress?: string;
  coordinates_X?: string;
  coordinates_Y?: string;
};
export type AuthResponse = {
  accessToken?: string;
};
export type SignupRequest = {
  login?: string;
  email?: string;
  password?: string;
  inn?: string;
  waterUserId?: number;
  organizationId?: number;
  isOrganization?: boolean;
};
export type LoginRequest = {
  login?: string;
  password?: string;
};
export type RehabilitationWork = {
  id?: number;
  waterObject?: WaterObject;
  type?:
    | '\u041A\u0430\u043F\u0438\u0442\u0430\u043B\u044C\u043D\u0430\u044F'
    | '\u0420\u0435\u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F'
    | '\u041F\u043B\u0430\u043D\u043E\u0432\u0430\u044F'
    | '\u041F\u0440\u043E\u0444\u0438\u043B\u0430\u043A\u0442\u0438\u0447\u0435\u0441\u043A\u0430\u044F';
  isRepeated?: boolean;
  uuid?: string;
  name?: string;
  description?: string;
  created?: string;
  updated?: string;
  dateStart?: string;
  dateEnd?: string;
  pathTechProtocol?: string;
  pathObjectDescription?: string;
  pathNatureObjectFile?: string;
  pathDefectiveAct?: string;
  pathDefectiveSheet?: string;
  budget?: string;
  budgetDescription?: string;
  pathBudgetAttachment?: string;
  researchObjectDescription?: string;
  researchObject?: string;
  objectInventory?: string;
  pathResearchSheet?: string;
  statusEnum?:
    | '\u041D\u043E\u0432\u044B\u0439'
    | '\u041F\u0440\u043E\u0435\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0439'
    | '\u0418\u0441\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435'
    | '\u0417\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043D\u044B\u0439';
  listOFMonths?: number[];
};

export type ChannelRehabilitationWorks = {
  id?: number;
  code?: string;
  created_At?: string;
  update_At?: string;
  years?: number;
  rehabilitationWorkType?: string;
  volume?: string;
  price?: number;
  fromDateWork?: string;
  toDateWork?: string;
  channels?: Channels;
};
export type WaterUserTableDto = {
  id: any;
  regNumber: string;
  fio: string;
  type: WaterUserEnum;
  address: string;
  area: number;
  facilitiesCount: number;
  hasCabin: boolean;
  active: boolean;
  organizationId: string;
};

export type WaterAccountingQueryArg = {
  decadeNumber?: number;
  month: string;
  sort?: string;
  page: number;
  size: number;
};
export type DataDto = {
  waterAccountingDecadeId: number;
  outletId: number;
  outletName: string;
  daysId: number;
  date: Dayjs | Date | string;
  reportBlockMorning: number;
  timeReportBlockMorning: Dayjs | Date | string;
  levelCorrectionMorning: number;
  timeLevelCorrectionMorning: Dayjs | Date | string;
  waterRateMorning: number;
  timeWaterRateMorning: Dayjs | Date | string;
  reportBlockEvening: number;
  timeReportBlockEvening: Dayjs | Date | string;
  levelCorrectionEvening: number;
  timeLevelCorrectionEvening: Dayjs | Date | string;
  waterRateEvening: number;
  timeWaterRateEvening: Dayjs | Date | string;
};
export type WaterAccountingContentData = {
  waterAccountingDecadeId: number;
  outletId: number;
  outletName: string;
  date: string | null;
  decadeNumber: number;
  month: string;
  dataDtos: DataDto[];
  averageRate: number;
};
export type WaterAccountingResponse = {
  content: WaterAccountingContentData[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  first: boolean;
  numberOfElements: number;
  empty: boolean;
};

export type WaterQuentityArg = {
  waterAccountingDecadeId: number;
  daysId: number;
  date: string;
  fact: number;
  planed: number;
  residualValue: number;
  reportBlockMorning: number;
  timeReportBlockMorning: string;
  levelCorrectionMorning: number;
  timeLevelCorrectionMorning: string;
  waterRateMorning: number;
  timeWaterRateMorning: string;
  reportBlockEvening: number;
  timeReportBlockEvening: string;
  levelCorrectionEvening: number;
  timeLevelCorrectionEvening: string;
  waterRateEvening: number;
  timeWaterRateEvening: string;
  averageRate: number;
};

export type WaterSupplyDataContent = {
  waterAccountingDecadeId: null | number;
  outletId: number;
  outletName: string;
  facilitiesId: null | number;
  facilityName: null | string;
  systemId: number;
  systemName: string;
  decadeNumber: number;
  month: string;
  factForDecade: number;
  planForDecade: null | number;
  residualValueForDecade: null | number;
  daysDtos: WaterAccountingDecadeDays[];
};
export type WaterAccountingDecadeDays = {
  waterAccountingDecadeId: null | number;
  waterAccountingDecadeDaysId: number;
  date: null | Date;
  fact: number;
  plan: number;
  residualValue: number;
};

export type WaterSupplyResponse = {
  content: WaterSupplyDataContent[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  first: boolean;
  numberOfElements: number;
  empty: boolean;
};
export type WaterUseQueryArg = {
  decadeNumber?: number;
  monthNumber?: number;
  userType?: WaterUserTypes;
  sort?: string;
  page?: number;
  size?: number;
};

export type WaterUseSuppyDataContent = {
  outletId: number;
  outletName: string;
  systemId: number;
  systemName: string;
  month: string;
  residualValueForDecade: number;
  decadeDtos: [
    {
      waterAccountingDecadeId: number;
      decadeNumber: number;
      month: string;
      plan: number;
      fact: number;
    },
  ];
};

export type WaterAccountingDecadesResponse<T> = {
  content: T[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  first: boolean;
  numberOfElements: number;
  empty: boolean;
};

export type WaterDecadeAppDataContent = {
  name: string;
  outletId: number;
  outletName: string;
  facilitiesId: number;
  facilitiesName: string;
  month: string;
  residualValueForDecade: number;
  decadeDtos: [
    {
      waterAccountingDecadeId: number;
      decadeNumber: number;
      month: string;
      plan: number;
      fact: number;
    },
  ];
};

export type WaterUseWateringDataContent = {
  name: string;
  organizationId: number;
  organizationName: string;
  month: string;
  residualValueForDecade: number;
  decadeDtos: [
    {
      waterAccountingDecadeId: number;
      decadeNumber: number;
      month: string;
      plan: number;
      fact: number;
    },
  ];
};

export type DecadeAppBodyArg = {
  waterDecadeId: number;
  plan: number;
  status: DecadeStatus;
};

export type FindAllHydropostResponse = {
  id: number;
  display_name: string;
  system_name: string;
  system_id: number;
  waterchannel: string;
  watercourse: string;
  hp_type_id: number;
  sensor_imei: number;
  resp_user_id: number;
  user_name: string;
  timezone: string;
  calibrated: boolean;
  max_level: number;
  location_x: null | string;
  location_y: null | string;
  is_active: boolean;
  create_date: number;
  model_link: string;
  photo_url: null | string;
};

type GetWaterManagementCountersApiResponse = waterManagementCounters;
type GetWaterUsersCounterApiArg = waterUsersCounterByDistrictId;
type GetWaterUsersCounterApiResponse = waterUsersCounter;
type GetWaterManagementApiArg = waterManagementById;

export const {
  useAddAnnualContractPlantingPlanMutation,
  useDeleteAnnualContractPlantingPlanMutation,
  useUpdateAnnualContractPlantingPlanMutation,
  useGetWaterUserAgreementQuery,
  useGetLegalDataContractQuery,
  useGetIndividualDataContractQuery,
  useGetAssociationDataContractQuery,
  useGetGmrPlantingListQuery,
  useUpdateGmrPlantingListMutation,
  useSubmitOperationalBlockImplementationTermMutation,
  useFindAllOutletsByChannelIdQuery,
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useCancelAnnualContractMutation,
  useGetIrrigationWaterUsersByIdQuery,
  useGetAllCommonReferencesQuery,
  useGetOutletHydropostsQuery,
  useGetAnnualContractByIdQuery,
  useSubmitAnnualContractMutation,
  useUpdateAttachmentsAnnualContractMutation,
  useSearchAllWaterUsersByOrganizationQuery,
  useCreateGmrSystemOutletsMutation,
  useDeleteGmrSystemOutletsMutation,
  useGetGmrBindedSystemsQuery,
  useGetGmrSystemsByDistrictQuery,
  useCreateHydraulicSectionMutation,
  useCreateGmrMutation,
  useGetGmrZoneListQuery,
  useGeoPortalGetLayersQuery,
  useGetOutletsByAssociationIdQuery,
  useGeoPortalIrrigationSystemObjectsLayersQuery,
  useGeoPortalIrrigationSystemObjectsQuery,
  useGeoPortalQualityControlMonitoringQuery,
  useLazyFindRegularEventByIdQuery,
  useLazyGetRegularEventTemplateByIdQuery,
  useFindAllRuvhOrganizationsQuery,
  useCreateStartVolumeMutation,
  useGetReservoirCurrentVolumeQuery,
  useCreateReservoirDataMutation,
  // useUpdateReservoirDataMutation,
  useGetReservoirsQuery,
  useGetReservoirByIdQuery,
  useCreateSystemObjectMutation,
  useGetReservoirDataMonthQuery,
  useGetFacilitiesByOrgIdQuery,
  useGetPlantingListQuery,
  useGetAllAgroClimaticZonesQuery,
  useGetAgroClimaticZonesQuery,
  useGetAgroClimaticZoneByIdQuery,
  useCreateAgroClimaticZoneMutation,
  useUpdateAgroClimaticZoneMutation,
  useUpdateDecadeActOfMonthMutation,
  usePayBillMutation,
  useActToBillMutation,
  useGetAccountingActQuery,
  useGetAccountingBillQuery,
  useGetReferencesByParentIdAndTypeQuery,
  useGetContractPdfMutation,
  useFindAllIrrigationSystemsOwnersQuery,
  useFindAllActsInvoicesForPaymentsQuery,
  useFindAllIrrigationSystemObjectsListWithFiltersQuery,
  useCreateSourceMutation,
  useGetAllSourceTypesQuery,
  useGetSourcesQuery,
  useGetNaturalSourcesQuery,
  useGetAllBasinQuery,
  useFindReferencesByParentIdQuery,
  useGetAllOperationalQuery,
  useGetAllRegularMutation,
  useGetAllConstructionQuery,
  useGetEventWorkGanttMutation,
  useGetAllExtraTaskQuery,
  useSavePhysicalWaterUserMutation,
  useSaveLegalsWaterUserMutation,
  useSaveIrrigationSystemMutation,
  useCreateBankMutation,
  useUploadFilesMutation,
  useSignUpMutation,
  useFindAllLegalPersonQuery,
  useFindAllPhysicalQuery,
  useFindAllByOrganizationIdQuery,
  useGetWaterUserEmployeesIdQuery, //zhanara
  useFindAllAssociationQuery,
  useGetFacilitiesByFilterQuery,
  useFindDictionaryByTypeIdQuery,
  useGetExtraTaskTemplatesQuery,
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListQuery,
  useFindAllBanksQuery,
  useLazyFindBankByIdQuery,
  useFindAllFilialQuery,
  useLazyFindAllUsersQuery,
  useFindAllUsersQuery,
  useFindIrrigationSystemByIdQuery,
  useGetIrrigationSystemObjectsAndStructureByIdQuery,
  useLazyGetIrrigationSystemObjectsAndStructureByIdQuery,
  useGetIrrigationSystemInfoByIdQuery,
  useUpdateIrrigationSystemMutation,
  useGetOtherTaskByIdQuery,
  useFindAllIrrigationSystemObjectsListOutletsQuery,
  useFindAllIrrigationSystemObjectsListChannelsQuery,
  useFindAllIrrigationSystemObjectsListSourcesQuery,
  useFindDictionaryItemByTypeIdQuery,
  useAddGeonodeMutation,
  useCheckObjecsCompareMutation,
  useCreateTaskOperationalBlockMutation,
  useCreateReportOperationalBlockMutation,
  useCreateReportRegularEventBlockMutation,
  useCreateCloseReportOperationalBlockMutation,
  useRegularEventListQuery,
  useExtraTaskEventListQuery,
  useOperationalBlockEventListQuery,
  useConstructionBlockEventListQuery,
  useCreateTaskRegularEventBlockMutation,
  useCreateCloseReportBuildingBlockMutation,
  useGetEmployeeOwnerListQuery,
  useGetEmployeeOwnerListByIdQuery,
  useGetRegularEventTemplatesQuery,
  useGetReferencesByParentIdQuery,
  useGetAllCommonReferencesListQuery,
  useCreateCommonReferenceMutation,
  useUpdateCommonReferenceMutation,
  useDeleteCommonReferenceMutation,
  useGetSystemObjectByIdQuery,
  useLazyGetSystemObjectByIdQuery,
  useCreateOtherTaskBlockMutation,
  useCreateReportBuildingBlockMutation,
  useGetOperationalTemplatesQuery,
  useGetBuildingTemplatesQuery,
  useUpdateSystemObjectMutation,
  useCreateWaterSectionMutation,
  useDeleteWaterSectionMutation,
  useGetWaterSectionsBySystemObjectIdQuery,
  useUpdateSystemObjectStatusMutation,
  useCreateSystemObjectListMutation,
  useCreateAqueductWaterObjectMutation,
  useCreateWaterObjectsAqueductWaterObjectMutation,
  useFindAllObjectsByAqueductIdQuery,
  useFindAllAllWaterSectionsByAqueductIdQuery,
  useAquedactObjectsSectionConstructorMutation,
  useGetAqueductListBySystemIdQuery,
  useUpdateQuentityIndicatorMutation,
  useGetWaterSupplyListQuery,
  useGetQuentityIndicatorsQuery,
  useUseGetWaterUseSupplyListQuery,
  useUseGetWaterUseWateringListQuery,
  useUseGetDecadeAppListQuery,
  useAqueductToAqueductMutation,
  useAqueductGtsConstructorMutation,
  useUpdateDecadeAppItemMutation,
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
  useFindAllObjectsByAqueductSectionsQuery,
  useGetObjectCategoriesQuery,
  useLazyFindAllObjectCategoriesByObjectGroupIdQuery,
  useLazyFindObjectCategoryByIdQuery,
  useFindObjectCategoryByIdQuery,
  useDeleteAqueductObjectsWithSectionsMutation,
  useFindAqueductByIdQuery,
  useFindObjectGroupByCodeQuery,
  useUpdateWaterUseSupplyListMutation,
  useUpdateWaterUseWateringListMutation,
  useFindAllHydropostsQuery,
  useGetSystemsByDistrictQuery,
  useGetBindedOutletsQuery,
  useCreateHydroFacilitySystemMutation,
  useDeleteHydroFacilitySystemMutation,
  useDeleteHydroFacilityMutation,
  useLazyGetBuildingTemplateByIdQuery,
  useGetAllMeasurementsQuery,
  useLazyGetExtraTaskTemplateByIdQuery,
  useLazyGetOperationalTemplateByIdQuery,
  useFindOperationalBlockByIdQuery,
  useFindGeneralAqueductByIdQuery,
  useFindRegularEventByIdQuery,
  useFindConstructionBlockByIdQuery,
  useGetAllSystemsQuery,
  useGetHydropostsByTypeQuery,
  useFindSystemsByWithFiltersQuery,
  useFindHydropostsByWithTableQuery,
  useToArchiveIrrigationSystemMutation,
  useToUnArchiveIrrigationSystemMutation,
  useUpdateAssociationStatsMutation,
  useCreateAssociationStatsMutation,
  useToArchiveIrrigationSystemObjectMutation,
  useToUnArchiveIrrigationSystemObjectMutation,

  useSaveTaskOperationalBlockMutation,
  useSaveReportOperationalBlockMutation,
  useSaveClosingReportOperationalBlockMutation,
  useSaveCloseReportBuildingBlockMutation,
  useSaveReportBuildingBlockMutation,
  useSaveTaskBuildingBlockMutation,
  useCreateTaskBuildingBlockMutation,
  useSaveOtherTaskBlockMutation,
  useCreateOtherTaskReportMutation,
  useSaveOtherTaskReportMutation,
  useContractualWatterSuplyPlanQuery,
  useGetFacilitiesByIdQuery,
  useFindAllAnnualContractListQuery,
  useCalculateAnnualContractMutation,
  useCreateAnnualContractMutation,
  useUpdateAnnualContractStatusMutation,
  useGetRiversQuery,
  useGetLakesQuery,
  useGetOthersQuery,
  useToArchiveWaterUserMutation,
  useToUnArchiveWaterUserMutation,
  useFindAllTariffListQuery,
  useGetTariffByRuvhIdQuery,
  useGetTariffByIdQuery,
  useCreateTariffMutation,
  useEconomicPlanQuery,
  useGetFacilitiesByFiltersQuery,
  useGetRegularEventByIdQuery,
  useCreateAssociationInitialDataMutation,
  useGetZonesQuery,
  useGetZoneQuery,
  useGetPointsQuery,
  useGetQualityMonitoringQuery,
  useGetReclamationStatesQuery,
  useGetPointTypeQuery,
  useCreatePointMutation,
  useCreateZoneMutation,
  useCreateReclamationStateMutation,
  useCreateDepthOccurrenceMutation,
  useCreateLaboratoryTestsMutation,
  useGetContractualWaterSupplyQuery,
  useGetWaterConsumptionQuery,
  useGetConcludingAgreementQuery,
  useGetWaterUsersCounterQuery,
  useGetWaterManagementCountersQuery,
  useGetGeoNodesByTypeNameQuery,
  useGetWateringPlanDecadalRequestQuery,
  useLazyGetGeoNodesByTypeNameQuery,
  useGetWaterUserDataQuery,
  useUpdateGeometryMutation,
  useGetWaterUsePlanQuery,
  useGetHydrometricWorkPlanQuery,
  useUpdateWaterUsePlanMutation,
  useUpdateHydrometricWorkPlanMutation,
  useGetHydroFacilityAllQuery,
  useUpdateStatusWaterUsePlanMutation,
  useGetWaterAccountingOutletFactsQuery,
  useGetHydropostsQuery,
  useGetWaterAccountingBalanceSheetQuery,
  useGetWaterAccountingJournalQuery,
  useGetWaterAccountingHydropostFactsQuery,
  useUpdateWaterAccountingHydropostFactsMutation,
  useUpdateWaterAccountingOutletFactsMutation,
  useSaveWateringPlanDecadalRequestMutation,
  useGetIrrigationSystemsMapsQuery,
  useGetWaterUsersMapsQuery,
  useGetAllWaterUserQuery,
  useWaterUserCreateMutation,
  useGetWaterUseCropSowingPlanQuery,
  useGetAllIrrigationSystemQuery,
  useSendRequestToDecadalMutation,
  useGetWateringPlanOperationalWateringQuery,
  useLazyGetWateringPlanOperationalWateringQuery,
  useCalculateWaterUsePlanMutation,
  useSaveOperationWateringPlanDecadalRequestMutation,
  useSendOperationalPlanRequestMutation,
  useGetWateringPlanOperationalWaterSupplyQuery,
  useLazyGetWateringPlanOperationalWaterSupplyQuery,
  useSaveWaterAccountingBalanceSheetMutation,
  useApprovePlanMutation,
  useLazyApproveWaterAccountingBalanceSheetQuery,
  useLazyCancelWaterAccountingBalanceSheetQuery,
  useLazySaveToSystemWaterAccountingBalanceSheetQuery,
  useGetWaterUsersByOutletQuery,
  useFindAllIrrigationSystemObjectsListHydropostsQuery,
  useGetLinkedSystemObjectsQuery,
  useGetSummaryWaterUsePlanQuery,
  useGetGovSummaryWaterUsePlanQuery,
  useGetAllIrrigationSystemsByHydroFacilityIdQuery,
  useGetNotBindedOutletsByChannelIdQuery,
  useUpdateAssociationInitialDataMutation,
  useGetContractualPlanQuery,
  useOperationalBlockSetControlMutation,
  useConstructionBlockSetControlMutation,
  useUpdateCropSowingPlanMutation,
  useUpdateContractualPlanMutation,
  useGetAllStatusWaterUsePlanQuery,
  useConstructionBlockAttachmentsUploadMutation,
  useConstructionBlockAttachmentsDeleteMutation,
  useOperationalBlockAttachmentsUploadMutation,
  useOperationalBlockAttachmentsDeleteMutation,
  useToArchiveOperationalBlockMutation,
  useToUnArchiveOperationalBlockMutation,
  useToArchiveBuildingBlockMutation,
  useToUnArchiveBuildingBlockMutation,
  useToArchiveExtraTaskMutation,
  useToUnArchiveExtraTaskMutation,
  useExtraTaskBlockAttachmentsUploadMutation,
  useExtraTaskBlockAttachmentsDeleteMutation,
  useBalanceAccountingIrrigationQuery,
  useGetWaterUsePlanByGuvhQuery,
  useGetHydrometricWorkPlanByGuvhQuery,
  useGetSummaryWaterUsePlanByGuvhQuery,
  useGetWaterUseCropSowingPlanByGuvhQuery,
  useGetContractualPlanByGuvhQuery,
  useCancelApprovalWaterUsePlanMutation,
  useGetWaterUsePlanByCaQuery,
  useGetContractualPlanByCaQuery,
  useGetSummaryWaterUsePlanByCaQuery,
  useGetHydrometricWorkPlanByCaQuery,
  useGetCropSowingPlanByCaQuery,
  useGetOwnerChildsQuery,
  useCreateFacilityMutation,
  useUpdateFacilityMutation,
  useAddOutletMutation,
  useUpdateOutletMutation,
  useDeleteFacilityMutation,
  useDeleteOutletMutation,
  useGetReclamationDataQuery,
  useGetDepthOccurrenceQuery,
  useGetLaboratoryTestsQuery,
  useGet2tpWaterUseDataQuery,
  useLazyGet2tpChangeStatusQuery,
  useGet2tpDrainageQuery,
  useUpdate2tpWaterUseDataMutation,
  useGet2tpTotalWaterUseDataQuery,
  useGet2tpListQuery,
  useUpdate2tpDrainageMutation,
  useGet2tpWaterUseConsolidatedDataQuery,
  useGet2tpConsolidatedDrainageQuery,
  useGetOwnerInfoQuery,
  useUpdateOwnerInfoMutation,
  useLazyGetEmployeeOwnerListByIdQuery,
  useGetDependentOwnerListQuery,
  useGetOwnerInfoByIdQuery,
  useUpdateOwnerInfoByIdMutation,
  useCreateEmployeeOwnerMutation,
  useUpdateEmployeeOwnerMutation,
  useDeleteEmployeeOwnerMutation,
  useDeletePermissionByIdMutation,
} = injectedRtkApi;
