import { useTranslation } from 'react-i18next';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { ACCESS } from '@enums/permisson';

type ReturnType = {
  columns: TableColumn[];
};

export const useDictionaryZoneTableContainerColumns = (): ReturnType => {
  const { t } = useTranslation();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: district = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Okmot,
  });

  const columns: TableColumn[] = [
    {
      title: 'ID',
      dataIndex: 'zoneId',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 60,
    },
    {
      title: 'Наименование зоны',
      dataIndex: 'zoneName',
      width: 170,
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('main.table.address'),
      dataIndex: 'address',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'address',
      renderCustom: ({ record }: any) => {
        const text = [
          regions.find((item) => item.id === record.regionId)?.title,
          district.find((item) => item.id === record.districtId)?.title,
          okmots.find((item) => item.id === record.okmotId)?.label,
        ];
        return <div>{text.filter(Boolean).join(', ') || '-'}</div>;
      },
      width: 200,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      align: 'center',
      xtype: CustomTableColumnType.String,
      key: 'address',
      width: 200,
    },
    {
      title: 'Площадь (Га)',
      dataIndex: 'area',
      width: 170,
      align: 'center',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Количество точек',
      dataIndex: 'points',
      width: 80,
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => record.points.length,
    },
    {
      title: 'Действие',
      align: 'center',
      dataIndex: 'actions',
      width: 100,
      actions: [CustomTableAction.EditWithoutText, CustomTableAction.Archive],
      xtype: CustomTableColumnType.ActionColumn,
      actionsAccess: [ACCESS.WATER_QUALITY_ZONE_EDIT],
    },
  ];

  return { columns };
};
