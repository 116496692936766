import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import {
  useMaterialsControllerDeleteMutation,
  useMaterialsControllerGetMaterialsByCategoryQuery,
} from '@store/api/materialsApi';
// import { ReferenceBookCreateModal } from '../referenceBookCreateModal';
import { Col, Row, TablePaginationConfig } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReferenceMaterialsTableModal } from '../referenceMaterialsTableModal/ReferenceMaterialsTableModal';
// import styles from './ReferenceBookTable.module.scss';
import { useReferenceMaterialsTableColumns } from './useReferenceMaterialsTableColumns';

export const ReferenceMaterialsTable: FC = () => {
  const [params, setSearchParams] = useSearchParams();

  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const { data: { content = [], totalElements, ...rest } = {}, isFetching } =
    useMaterialsControllerGetMaterialsByCategoryQuery({
      category: filtersParams.categoryId,
      params: {
        page: filtersParams?.page,
        size: filtersParams?.size,
      },
    });

  const [deleteMaterials] = useMaterialsControllerDeleteMutation();

  const [isModalShow, setShowModal] = useState(false);

  const [initialcode, setInitialCode] = useState(null);

  const { columns } = useReferenceMaterialsTableColumns();

  const handleEdit = (val: any) => {
    setInitialCode(val?.code);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialCode(null);
    setShowModal(true);
  };

  const deleteItem = ({ code }: { code: string }) => {
    deleteMaterials({ code });
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        const currentSearchParams = Object.fromEntries(params.entries());
        const updatedSearchParams = {
          ...currentSearchParams,
          page: newPagination.current.toString(),
          size: newPagination.pageSize.toString(),
        };
        setSearchParams(updatedSearchParams);
      }
    },
    [rest, columns],
  );

  return (
    <div>
      <CustomCard
        title={
          <Row justify="space-between" align="middle">
            <Col>Таблица</Col>
            <Col>
              <PrimaryButton onClick={handleCreate}>Создать</PrimaryButton>
            </Col>
          </Row>
        }
      >
        <TableCustom
          bordered
          size="small"
          columns={columns}
          dataSource={content || []}
          loading={isFetching}
          totalCount={totalElements}
          customPagination={rest}
          onChange={onTableChange}
          actionProps={{
            onHandleEdit: (val) => handleEdit(val),
            deleteItem,
          }}
        />

        {isModalShow && (
          <ReferenceMaterialsTableModal
            isModalShow={isModalShow}
            setShowModal={setShowModal}
            code={initialcode}
          />
        )}
      </CustomCard>
    </div>
  );
};
