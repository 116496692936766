export enum DocumentTypes {
  Agreement = 'соглашение',
  Contract = 'контракт',
  Annex = 'приложение',
  CreateTask = 'создание задачи',
  Estimate = 'смета',
  Chart = ' график',
  Report = 'отчет',
  CloseReport = 'закрытие отчета',
  Defect = 'ведомость дефекта',
  Photo = 'фото',
  PhotoBefore = 'фото:до',
  PhotoAfter = 'фото:после',
  Act = 'акт',
  Regulation = 'устав',
  Passport = 'паспорт',
  Document = 'документ',
}
