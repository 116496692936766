import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { useAnnualWaterSupplyTableFilters } from '@features/annualApplication/annualWaterSupplyTable/useAnnualWaterSupplyTableFilters';
import {
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedDatePickerV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { IAnnualContractualWaterSupplyFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from '@utils/utils';

import styles from './AnnualWaterSupplyTable.module.scss';
import {
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { useGetCurrentUser } from '@features/authentication';

export const AnnualWaterSupplyTableFilters: FC = () => {
  const [params] = useSearchParams();

  const [filtersState, setFiltersState] = useState<
    Partial<IAnnualContractualWaterSupplyFilters>
  >({});
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { filters, setAnnualWaterSupplyFilters } =
    useAnnualWaterSupplyTableFilters();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.region
      ? {
          parentId: parseInt(filtersState.region, 10),
        }
      : skipToken,
  );

  const onFilterChange = (
    filterKey: keyof IAnnualContractualWaterSupplyFilters,
    v: string | null | Dayjs,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    };
    setFiltersState(updatedFilters);
  };

  const onReginChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      region: v,
      district: null,
    }));
  };

  const onYearChange = (year: string) => {
    setFiltersState((state) => ({
      ...state,
      year,
      endDate: year ? `${year}-12-31` : null,
      startDate: year ? `${year}-01-01` : null,
    }));
  };

  const onFilterButtonClick = () => {
    setAnnualWaterSupplyFilters(
      filtersState as IAnnualContractualWaterSupplyFilters,
    );
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      region: user?.owner?.region?.id.toString(),
      district: user?.owner?.district?.id.toString(),
      endDate: null,
      startDate: null,
      year: null,
    });
    setAnnualWaterSupplyFilters({
      region: user?.owner?.region?.id.toString(),
      district: user?.owner?.district?.id.toString(),
      endDate: null,
      startDate: null,
      year: null,
    });
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setFiltersState({
      ...newFilters,
      region: user?.owner?.region?.id.toString() || null,
      district: user?.owner?.district?.id.toString() || null,
    });
    setAnnualWaterSupplyFilters(
      isEmpty(newFilters)
        ? {
            region: user?.owner?.region?.id.toString() || null,
            district: user?.owner?.district?.id.toString() || null,
          }
        : (newFilters as unknown as IAnnualContractualWaterSupplyFilters),
    );
  }, []);

  return (
    <div className={styles.selectWrapper}>
      <Row
        className={styles.tableFilters}
        align="middle"
        gutter={15}
        justify="center"
      >
        <Col span={6}>
          <TooltipedSelect
            value={filtersState?.region && Number(filtersState?.region)}
            onChange={(v: string) => onReginChange(v)}
            allowClear
            placeholder="Область"
            options={regions}
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={6}>
          <TooltipedSelect
            allowClear
            placeholder="Район"
            options={districts}
            disabled={!filtersState.region || isDisabledDistrict}
            value={filtersState?.district && Number(filtersState?.district)}
            onChange={(v: string) => onFilterChange('district', v)}
          />
        </Col>
        <Col span={2}>
          <YearPicker
            value={filtersState.year ? dayjs(filtersState.year) : null}
            placeholder="Год"
            onChange={(date: Dayjs | null, year) => onYearChange(year.toString())}
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col span={4}>
          <TooltipedDatePicker
            value={
              filtersState.startDate ? dayjs(filtersState.startDate) : null
            }
            onChange={(v: Dayjs | null | string) =>
              onFilterChange(
                'startDate',
                (v && dayjs(v).format('YYYY-MM-DD')) || v,
              )
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf('day')
            }
            allowClear
            placeholder="Начало"
          />
        </Col>
        <Col span={4}>
          <TooltipedDatePicker
            value={filtersState.endDate ? dayjs(filtersState.endDate) : null}
            onChange={(v: Dayjs | null | string) =>
              onFilterChange(
                'endDate',
                (v && dayjs(v).format('YYYY-MM-DD')) || v,
              )
            }
            disabledDate={(current) =>
              current && current < dayjs(filtersState.startDate).startOf('day')
            }
            allowClear
            placeholder="Конец"
          />
        </Col>
        <Col flex={1}>
          <Row gutter={15}>
            <Col>
              <PrimaryButton
                onClick={onFilterButtonClick}
                icon={<FilterIcon />}
              />
            </Col>
            <Col>
              <PrimaryButton
                onClick={onResetFilterButtonClick}
                icon={<ResetFilterIcon />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
