import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import {
  getIrrigationSystemCardObjectFiltersSelector,
  getWaterRegistryFiltersSelector,
} from '@store/selectors';
import { isEmpty, isInteger, pickBy } from '@utils/utils';
import { changeIrrigationSystemCardObjectFilters } from '@store/slices';

type ReturnType = {
  filters: Partial<any>;
  setIrrigationSystemCardObjectFilters: (filters: any) => void;
};

export const useIrrigationSystemCardObjectTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const filters = useSelector(getIrrigationSystemCardObjectFiltersSelector);

  const setIrrigationSystemCardObjectFilters = useCallback(
    (newFilters: any) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      setParams(params);
      const { section, ...filterValues } = newFilters;
      const values = pickBy(filterValues, (v) => !isEmpty(v) || isInteger(v));

      dispatch(changeIrrigationSystemCardObjectFilters({ ...values } as any));
    },
    [],
  );

  return { filters, setIrrigationSystemCardObjectFilters };
};
