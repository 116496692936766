import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import { useGetAllOperationalQuery } from '@store/gisproApi';

import { IrrigationalSystemCardOperationalControl } from './irrigationalSystemCardOperationalControl';
import styles from './IrrigationSystemCardOperationalTable.module.scss';
import { useOperationalColumns } from './useOperationalColumns';
import { TablePaginationConfig } from 'antd/lib';

export const IrrigationSystemCardOperationalTable: React.FC = () => {
  const { columns } = useOperationalColumns();
  const { systemId = '' } = useParams<string>();
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(() => {
    const withoutSection = Object.fromEntries(params.entries());
    delete withoutSection.section;
    return withoutSection;
  }, [params]);

  const { data: response, isLoading } = useGetAllOperationalQuery({
    ...filtersParams,
    id: systemId,
  });

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        const currentSearchParams = Object.fromEntries(params.entries());
        const updatedSearchParams = {
          ...currentSearchParams,
          page: newPagination.current.toString(),
          limit: newPagination.pageSize.toString(),
        };
        setSearchParams(updatedSearchParams);
      }
    },
    [columns],
  );
  return (
    <Space direction="vertical" size={20} style={{ width: '100%' }}>
      <IrrigationalSystemCardOperationalControl />
      <Row justify="center" className={styles.tableWrapper}>
        <Col span={24}>
          <InformationalBlock>
            <CustomCard
              style={{ padding: '8px 16px' }}
              title="Эксплуатационный блок"
            >
              <TableCustom
                dataSource={response?.data}
                columns={columns}
                bordered
                totalCount={response?.meta?.totalCount}
                customPagination={{
                  number: response?.meta?.page,
                  size: response?.meta?.limit,
                  pageable: {
                    pageNumber: response?.meta?.page || 1,
                    pageSize: response?.meta?.limit || 10,
                  },
                }}
                scroll={{ x: 1500 }}
                onChange={onTableChange}
                // actionProps={{
                //   onWatch: watchOperationalItem,
                // }}
              />
            </CustomCard>
          </InformationalBlock>
        </Col>
      </Row>
    </Space>
  );
};
