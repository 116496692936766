import { useSelector } from 'react-redux';

import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { getSelectedWorkDtoSelector } from '@store/selectors/waterObject';

import { useWaterObjectModals } from '../useWaterObjectModals';
import { CustomModal, PrimaryButton, TooltipedTextarea } from '@features/ui-kit';
import { Col, Row } from 'antd';

export const WorkVolumeModal: React.FC = () => {
  const { isShowModal, setShowModal } = useWaterObjectModals(
    WaterMainModalTypes.VOLUME,
  );
  const selectedWorkDto = useSelector(getSelectedWorkDtoSelector);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <CustomModal
      open={isShowModal}
      onCancel={closeModal}
      footer={false}
      title="Объем проведенных работ"
    >
      <div style={{ marginBottom: 10 }}>Характеристика, особенности конструкции сооружения</div>
      <TooltipedTextarea disabled value={selectedWorkDto?.description} />
      <Row justify="center" style={{ marginTop: 16 }}>
        <Col>
          <PrimaryButton onClick={() => setShowModal(false)}>OK</PrimaryButton>
        </Col>
      </Row>
    </CustomModal>
  );
};
