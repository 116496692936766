import { Tags } from '@constants/tags';
import { api } from '../basicApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    geoLayersControllerFindByMap: build.query<
      GeoLayersControllerFindByMapApiResponse,
      GeoLayersControllerFindByMapApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo-layers/map/${queryArg.mapFilter}`,
      }),
    }),
    geoLayersControllerFindById: build.query<
      GeoLayersControllerFindByIdApiResponse,
      GeoLayersControllerFindByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/geo-layers/get/${queryArg.id}` }),
      keepUnusedDataFor: 0,
    }),
    geoLayersControllerFindAll: build.query<
      GeoLayersControllerFindAllApiResponse,
      GeoLayersControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo-layers/list`,
        params: {
          ...queryArg,
        },
      }),
      providesTags: [Tags.GeoLayers],
    }),
    geoLayersControllerCreate: build.mutation<
      GeoLayersControllerCreateApiResponse,
      GeoLayersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo-layers/create`,
        method: 'POST',
        body: queryArg.geoLayersCreateDto,
      }),
      invalidatesTags: [Tags.GeoLayers],
    }),
    geoLayersControllerUpdate: build.mutation<
      GeoLayersControllerUpdateApiResponse,
      GeoLayersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo-layers/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.geoLayersCreateDto,
      }),
      invalidatesTags: [Tags.GeoLayers],
    }),
    geoLayersControllerDelete: build.mutation<
      GeoLayersControllerDeleteApiResponse,
      GeoLayersControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo-layers/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.GeoLayers],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GeoLayersControllerFindByMapApiResponse =
  /** status 200  */ GeoLayersDto[];
export type GeoLayersControllerFindByMapApiArg = {
  mapFilter: string;
};
export type GeoLayersControllerFindByIdApiResponse =
  /** status 200  */ GeoLayersDto;
export type GeoLayersControllerFindByIdApiArg = {
  id: string;
};
export type GeoLayersControllerFindAllApiResponse =
  /** status 200  */ PageableResultGeoLayersDto;
export type GeoLayersControllerFindAllApiArg = {
  page: string;
  size: string;
};
export type GeoLayersControllerCreateApiResponse =
  /** status 200  */ GeoLayersDto;
export type GeoLayersControllerCreateApiArg = {
  geoLayersCreateDto: GeoLayersCreateDto;
};
export type GeoLayersControllerUpdateApiResponse =
  /** status 200  */ UpdateResult;
export type GeoLayersControllerUpdateApiArg = {
  id: string;
  geoLayersCreateDto: GeoLayersCreateDto;
};
export type GeoLayersControllerDeleteApiResponse =
  /** status 200  */ DeleteResult;
export type GeoLayersControllerDeleteApiArg = {
  id: string;
};
export type GeoLayersDto = {
  id: number;
  /** Название слоя */
  title: string;
  /** Фильтрация по картам */
  maps: string[];
  /** Ссылка на источник */
  sourceUrl: string;
  /** Дата создания */
  createdAt: string;
  /** Дата обновления */
  updatedAt: string;
};
export type PageableResultGeoLayersDto = {
  content: GeoLayersDto[];
  pageable: any;
  totalElements: number;
};
export type GeoLayersCreateDto = {
  /** Название слоя */
  title: string;
  /** Фильтрация карты */
  maps: string[];
  /** Ссылка на источник */
  sourceUrl: string;
};
export type UpdateResult = {
  affected: number;
  raw: object;
};
export type DeleteResult = {
  affected: number;
  raw: object;
};
export const {
  useGeoLayersControllerFindByMapQuery,
  useGeoLayersControllerFindByIdQuery,
  useGeoLayersControllerFindAllQuery,
  useGeoLayersControllerCreateMutation,
  useGeoLayersControllerUpdateMutation,
  useGeoLayersControllerDeleteMutation,
} = injectedRtkApi;
