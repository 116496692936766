export enum IrrigationSystemQueryTypes {
  totalInformation = 'totalInformation',
  systemBlock = 'systemBlock',
  objects = 'objects',
  waterUsers = 'waterUsers',
  operationalBlock = 'operational',
  document = 'document',
}

export enum CreateIrrigationSystemQueryTypes {
  totalInformation = 'totalInformation',
  works = 'works',
  document = 'document',
}

export enum IrrigationSystemTableQueryTypes {
  irrigationSystem = 'irrigationSystem',
  waterObjects = 'waterObjects',
  reservoirs = 'reservoirs',
}
