import { useCallback, useEffect, useMemo } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Routes } from '@enums/routes';
import { useIrrigationSystemCardWaterUserTableColumns } from '@features/irrigationSystemCard/tables/irrigationSystemCardWaterUserTable/irrigationSystemCardWaterUserTableContainer/useIrrigationSystemCardWaterUserTableColumns';
import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import { IWaterUserV2 } from '@models/waterUser/interfaces/waterUser';
import { useGetIrrigationWaterUsersByIdQuery } from '@store/gisproApi';
import { useIrrigationSystemCardWaterUserTableFilters } from '../irrigationSystemCardWaterUserTableControls/useIrrigationSystemCardWaterUserTableFilters';
import { ISystemObject } from '@models/waterObject/interfaces/waterObject';
import { TablePaginationConfig } from 'antd';

export const IrrigationSystemCardWaterUserTableContainer: React.FC = () => {
  const navigate = useNavigate();
  const { systemId = '' } = useParams<string>();
  const { columns } = useIrrigationSystemCardWaterUserTableColumns();
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const { filters, setIrrigationSystemCardWaterUserFilters } =
    useIrrigationSystemCardWaterUserTableFilters();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetIrrigationWaterUsersByIdQuery({
    irrigationSystemId: systemId,
    params: filters,
  });

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setIrrigationSystemCardWaterUserFilters({
          ...(filters as any),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  useEffect(() => {
    setIrrigationSystemCardWaterUserFilters({
      page: 1,
      size: 10,
      ...(filtersParams as any),
    });
  }, []);

  const onHandleCard = (record: IWaterUserV2) => {
    navigate(generatePath(Routes.WaterUserCard, { id: String(record.id) }));
  };

  const onHandleAnnualApplication = (record: IWaterUserV2) => {
    console.log('record', record);
  };

  return (
    <InformationalBlock>
      <CustomCard title="Таблица водопользователей">
        <TableCustom
          loading={isFetching || isLoading}
          bordered
          size="small"
          dataSource={content}
          columns={columns}
          totalCount={totalElements}
          onChange={onTableChange}
          actionProps={{
            onHandleCard,
            onHandleAnnualApplication,
          }}
          customPagination={rest}
        />
      </CustomCard>
    </InformationalBlock>
  );
};
