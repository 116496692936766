import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const PlotIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 2H2V5.171L9.414 12.585L10 13.171V22H14V20H16V22C16 22.5304 15.7893 23.0391 15.4142 23.4142C15.0391 23.7893 14.5304 24 14 24H10C9.46957 24 8.96086 23.7893 8.58579 23.4142C8.21071 23.0391 8 22.5304 8 22V14L0.586 6.585C0.210901 6.21001 0.000113275 5.70139 0 5.171V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H24V2Z"
        fill="currentColor"
      />
      <path
        d="M0 2H22V5.171L14.586 12.585L14 13.171V22H10V20H8V22C8 22.5304 8.21071 23.0391 8.58579 23.4142C8.96086 23.7893 9.46957 24 10 24H14C14.5304 24 15.0391 23.7893 15.4142 23.4142C15.7893 23.0391 16 22.5304 16 22V14L23.414 6.585C23.7891 6.21001 23.9999 5.70139 24 5.171V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0H0V2Z"
        fill="currentColor"
      />
    </svg>
  );
};
