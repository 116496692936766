import { PlanType } from '@enums/annualPlan';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';
import {
  PlanStatusEnum,
  WaterUsePlanDataViewTypes,
} from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RuvhTablesInfoItem = {
  type: PlanType;
  status: PlanStatusEnum;
};
export type CaFilters = {
  districtId: number;
  regionId: number;
  districtName: string;
};

export type WaterUsePlanState = {
  isShowPrintModal: boolean;
  ruvhTablesInfo: RuvhTablesInfoItem[];
  ruvhStatus: 'Проект РУВХ' | 'На согласовании ГУВХ';
  guvhFilters: Omit<CaFilters, 'regionId'> | null;
  caFilters: CaFilters | null;
  guvhTab: WaterUsePlanDataViewTypes;
};

const initialState: WaterUsePlanState = {
  isShowPrintModal: false,
  ruvhStatus: 'Проект РУВХ',
  ruvhTablesInfo: [
    {
      type: PlanType.TotalWaterIntakeFromIrrigationSources,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.TotalWaterSupplyToAllAllocationPoints,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.IrrigationWaterSupplyPlan,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.MoistureChargingIrrigationPlanWaterSupply,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.MoistureChargingIrrigationPlanHectareIrrigation,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.FirstIrrigationPlan,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.TotalHectareIrrigationPlan,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.ContractualWaterSupplyPlan,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.SowingPlan,
      status: PlanStatusEnum.Editable,
    },
    {
      type: PlanType.HydrometricWorksPlan,
      status: PlanStatusEnum.Editable,
    },
  ],
  guvhTab: WaterUsePlanDataViewTypes.Tables,
  guvhFilters: null,
  caFilters: null,
};

const waterUsePlanSlice = createSlice({
  name: 'waterUse',
  initialState,
  reducers: {
    setShowPrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowPrintModal: action.payload,
      };
    },
    setRuvhStatus: (
      state,
      action: PayloadAction<'Проект РУВХ' | 'На согласовании ГУВХ'>,
    ) => {
      return {
        ...state,
        ruvhStatus: action.payload,
      };
    },
    setUpdateStatus: (state, action: PayloadAction<RuvhTablesInfoItem>) => {
      const { type, status } = action.payload;
      const index = state.ruvhTablesInfo.findIndex(
        (item) => item.type === type,
      );
      if (index !== -1) {
        state.ruvhTablesInfo[index].status = status;
      }
    },
    setGuvhFilters: (
      state,
      action: PayloadAction<Omit<CaFilters, 'regionId'>>,
    ) => {
      return {
        ...state,
        guvhFilters: action.payload,
      };
    },
    setGuvhTab: (state, action: PayloadAction<WaterUsePlanDataViewTypes>) => {
      return {
        ...state,
        guvhTab: action.payload,
      };
    },
    setCaFilters: (state, action: PayloadAction<CaFilters>) => {
      return {
        ...state,
        caFilters: action.payload,
      };
    },
  },
});

export const {
  setShowPrintModal,
  setRuvhStatus,
  setUpdateStatus,
  setCaFilters,
  setGuvhFilters,
  setGuvhTab,
} = waterUsePlanSlice.actions;
export default waterUsePlanSlice.reducer;
