import { Divider } from '@features/ui-kit';
import { WaterUsersModal } from '@features/waterUser/waterUsersModal';

import { EconomicWaterAccountingControls } from './economicWaterAccountingControls/EconomicWaterAccountingControls';
import { EconomicWaterAccountingTable } from './economicWaterAccountingTable/EconomicWaterAccountingTable';
import {
  EconomicWaterPrintModal
} from '@features/waterAccounting/economicWaterAccounting/modals/economicWaterPrintModal';

export const EconomicWaterAccounting = () => {
  return (
    <div>
      <EconomicWaterAccountingControls />
      <Divider />
      <EconomicWaterAccountingTable />
      <EconomicWaterPrintModal />
      <WaterUsersModal />
    </div>
  );
};
