import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WaterFacility = {
  id: number;
  wearSums: {
    year: number;
    cost: number;
  }[];
  workMarks: {
    year: number;
    type: string;
    volume: string;
    sum: number;
  }[];
};

export type WaterFacilityState = {
  isShowWorkVolume: boolean;
  isShowWorkMark: boolean;
  isShowWearSum: boolean;
  waterFacilities: WaterFacility[] | [];
  waterFacility: WaterFacility | null;
};

const initialState: WaterFacilityState = {
  isShowWorkVolume: false,
  isShowWorkMark: false,
  isShowWearSum: false,
  waterFacility: {
    id: 1,
    wearSums: [
      { year: 2021, cost: 5000 },
      { year: 2022, cost: 6000 },
    ],
    workMarks: [
      { year: 2021, type: 'Maintenance', volume: 'Minor', sum: 3000 },
      { year: 2022, type: 'Repair', volume: 'Major', sum: 8000 },
    ],
  },
  waterFacilities: [
    {
      id: 1,
      wearSums: [
        { year: 2021, cost: 5000 },
        { year: 2022, cost: 6000 },
        { year: 2023, cost: 5500 },
      ],
      workMarks: [
        { year: 2021, type: 'Maintenance', volume: 'Minor', sum: 3000 },
        { year: 2022, type: 'Repair', volume: 'Major', sum: 8000 },
      ],
    },
    {
      id: 2,
      wearSums: [
        { year: 2021, cost: 4500 },
        { year: 2022, cost: 4800 },
        { year: 2023, cost: 5200 },
      ],
      workMarks: [
        { year: 2021, type: 'Maintenance', volume: 'Minor', sum: 2800 },
        { year: 2022, type: 'Repair', volume: 'Major', sum: 7500 },
      ],
    },
    {
      id: 3,
      wearSums: [
        { year: 2021, cost: 6000 },
        { year: 2022, cost: 5500 },
        { year: 2023, cost: 6100 },
      ],
      workMarks: [
        { year: 2021, type: 'Maintenance', volume: 'Minor', sum: 3200 },
        { year: 2022, type: 'Repair', volume: 'Major', sum: 7800 },
      ],
    },
  ],
};

const waterFacilitySlice = createSlice({
  name: 'waterFacility',
  initialState,
  reducers: {
    setShowWorkVolume: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWorkVolume: action.payload,
      };
    },
    setShowWorkMark: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWorkMark: action.payload,
      };
    },
    setShowWearSum: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWearSum: action.payload,
      };
    },
    addWearSum: (state, action: PayloadAction<any>) => {
      const selectedWaterFacility = state.waterFacility;

      if (selectedWaterFacility) {
        const newWearSum = action.payload;

        const updatedWaterFacility = {
          ...selectedWaterFacility,
          wearSums: [...selectedWaterFacility.wearSums, newWearSum],
        };

        return {
          ...state,
          waterFacility: updatedWaterFacility,
        };
      }

      return state;
    },
    addWorkMarks: (state, action: PayloadAction<any>) => {
      const selectedWaterFacility = state.waterFacility;

      if (selectedWaterFacility) {
        const newWorkMarks = action.payload;
        const updatedWaterFacility = {
          ...selectedWaterFacility,
          workMarks: [...selectedWaterFacility.workMarks, newWorkMarks],
        };

        return {
          ...state,
          waterFacility: updatedWaterFacility,
        };
      }

      return state;
    },
  },
});

export const { actions: waterFacilityActions } = waterFacilitySlice;

export default waterFacilitySlice.reducer;
