import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionSubmitIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
    >
      <path
        d="M1.57129 3.83674L3.92231 6.28572L8.42843 1.71429"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
