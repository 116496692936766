import { Form, Input, Row, Typography } from 'antd';

import { MoneyArrowIcon } from '@icons/MoneyArrowIcon';

import styles from './styles.module.scss';

interface IBudgetItemProps {
  label: string;
  value?: number;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const BudgetItem: React.FC<IBudgetItemProps> = ({
  label,
  value,
  onChange,
  disabled,
}) => {
  const onValueChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Row justify="start" align="middle" className={styles.budgetRow}>
      <div className={styles.budgetLabel}>
        <MoneyArrowIcon className={styles.budgetLabel__icon} />
        <Typography.Text strong className={styles.label}>
          {label}
        </Typography.Text>
      </div>
      <Input
        className={styles.budgetInput}
        typeof="number"
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
        type="number"
        value={value}
        onChange={onValueChange}
        disabled={disabled}
      />
    </Row>
  );
};
