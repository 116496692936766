import { FC, useState } from 'react';

import { Col, Row } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { Divider, InformationalBlock, Segmented } from '@features/ui-kit';

import {
  WastewaterDisposalTable,
  WaterUseGeneralInforammtionTable,
} from './components';
import { ConsolidatedReportFilters } from './components/consolidatedReportFilters/ConsolidatedReportFilters';

const segmentItems: SegmentedLabeledOption[] = [
  {
    value: '1',
    label: 'Использование воды',
  },
  {
    value: '2',
    label: 'Отведение сточных вод',
  },
];

export const ConsolidatedReportPage: FC = () => {
  const [activeSegmentKey, setActiveSegmentKey] = useState<SegmentedValue>('1');

  let Component;
  switch (activeSegmentKey) {
    case '2':
      Component = WastewaterDisposalTable;
      break;
    default:
      Component = WaterUseGeneralInforammtionTable;
      break;
  }

  return (
    <div>
      <Row>
        <ConsolidatedReportFilters />
        <Divider />
        <Col>
          <Segmented options={segmentItems} onChange={setActiveSegmentKey} />
        </Col>
        <Divider />
      </Row>
      <InformationalBlock>{Component && <Component />}</InformationalBlock>
    </div>
  );
};
