import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import {
  getShowAssociationPermissionsModal,
  getShowIndividualPermissionsModal,
  getShowLegalPermissionsModal,
} from '@store/selectors';
import {
  setShowAssociationPermissionsModal,
  setShowIndividualPermissionsModal,
  setShowLegalPermissionsModal,
} from '@store/slices';

type ReturnType = {
  isModalShownPhysical: boolean;
  isModalShownLegal: boolean;
  isModalShownAssociation: boolean;
  setShowModalAssociation: (val: boolean) => void;
  setShowModalLegal: (val: boolean) => void;
  setShowModalPhysical: (val: boolean) => void;
};

export const useWaterUserPermissionModal = (): ReturnType => {
  const isModalShownPhysical = useSelector(getShowIndividualPermissionsModal);
  const isModalShownLegal = useSelector(getShowLegalPermissionsModal);
  const isModalShownAssociation = useSelector(
    getShowAssociationPermissionsModal,
  );

  const dispatch = useDispatch();

  const setShowModalPhysical = useCallback((val: boolean) => {
    dispatch(setShowIndividualPermissionsModal(val));
  }, []);

  const setShowModalLegal = useCallback((val: boolean) => {
    dispatch(setShowLegalPermissionsModal(val));
  }, []);

  const setShowModalAssociation = useCallback((val: boolean) => {
    dispatch(setShowAssociationPermissionsModal(val));
  }, []);

  return {
    isModalShownPhysical,
    isModalShownLegal,
    isModalShownAssociation,
    setShowModalAssociation,
    setShowModalLegal,
    setShowModalPhysical,
  };
};
