import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  DangerButton,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import {
  CreateAssociationWaterRegistryQueryTypes,
  MainWaterUserTypes,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { clearFormDataAction, setFormData } from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { OutletConnection, OutletConnectionTable } from '../fields';
import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';

export const AssociationMasterRegistrationFourthStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { economies } = useAddEconomy();

  const onFinish = () => {
    console.log('economies', economies);
    dispatch(
      setFormData({
        data: {
          outlets: Array.from(new Set(economies.association)).map((item) => ({
            id: item.outlet,
            kpd: item.kpd,
            area: item.totalArea,
          })),
        },
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.agreement}`,
    );
  };

  const onFinishFailed = (err: ValidateErrorEntity) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(Routes.WaterRegistryUsers);
  };
  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.responsiblePersons}`,
    );
  };

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <InformationalBlock>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <OutletConnection waterUserType={MainWaterUserTypes.Association} />
          </Col>
          <Col span={24}>
            <OutletConnectionTable waterUserType={MainWaterUserTypes.Association} economies={economies.association} />
          </Col>
        </Row>
      </InformationalBlock>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DangerButton
              htmlType="submit"
              size="large"
              onClick={onCancelButtonClick}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <DefaultButton
                  type="default"
                  size="large"
                  htmlType="submit"
                  onClick={onBackButtonClick}
                >
                  {t('createIndividualWaterRegistry.buttons.back')}
                </DefaultButton>
              </Form.Item>
              <Form.Item>
                <PrimaryButton
                  disabled={!economies?.association.length}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {t('createLegalWaterRegistry.buttons.next')}
                </PrimaryButton>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
