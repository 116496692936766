import { Layout } from 'antd';

import { BusinessPlanTable } from '@features/businessPlan/businessPlanTable';

export const BusinessPlanTableLayout = () => {
  return (
    <Layout>
      <div>
        <BusinessPlanTable />
      </div>
    </Layout>
  );
};
