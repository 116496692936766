import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export interface DocumentDataType {
  id: number;
  name: string;
  extension: string;
}

export const useDocumentTableColumns = () => {
  const { t } = useTranslation();
  const columns: TableColumn[] = [
    {
      title: t('№'),
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: t('main.table.name'),
      align: 'center',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'name',
      width: 180,
    },
    {
      title: 'Тип',
      align: 'center',
      dataIndex: 'type',
      xtype: CustomTableColumnType.String,
      key: 'name',
      width: 100,
    },
    {
      title: t('irrigationForm.objectTable.format'),
      align: 'center',
      dataIndex: 'extension',
      xtype: CustomTableColumnType.String,
      key: 'extension',
    },
    {
      title: t('main.table.actions'),
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.WatchWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      width: 100,
    },
  ];
  const data: DocumentDataType[] = [];

  return { columns, data };
};
