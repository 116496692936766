import { Divider } from '@features/ui-kit';
import {
  WaterLogAccountingControls
} from '@features/waterAccounting/waterLogAccounting/waterLogAccountingControls/WaterLogWaterAccountingControls';
import {
  WaterLogAccountingTable
} from '@features/waterAccounting/waterLogAccounting/waterLogAccountingTable/WaterLogAccnountingTable';
import { WaterLogPrintModal } from '@features/waterAccounting/waterLogAccounting/modals/waterLogPrintModal';

export const WaterLogAccounting = () => {
  return (
    <div>
      <WaterLogAccountingControls />
      <Divider />
      <WaterLogAccountingTable />
      <WaterLogPrintModal />
    </div>
  );
};
