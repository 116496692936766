import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useCalculationEstimatedFinancialColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Номер полива',
      dataIndex: 'index',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Вид полива',
      dataIndex: 'irrigationTypeName',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Начало даты',
      dataIndex: 'startDate',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Конец даты',
      dataIndex: 'endDate',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Кол-во дней',
      dataIndex: 'irrigationStandardDaysCount',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Объем, л.сек',
      dataIndex: 'waterSupplyVolume',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Водоподача в тыс. м3.',
      dataIndex: 'waterSupplyVolumeCubicMeters',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Площадь (ГА)',
      dataIndex: 'hectaresIrrigation',
      xtype: CustomTableColumnType.String,
    },
  ];

  return { columns };
};
