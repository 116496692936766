import { Collapse } from 'antd';
import { CollapseProps } from 'antd/es/collapse/Collapse';

import styles from './ConstructorCollapse.module.scss';

type ConstructorCollapseProps = CollapseProps & {
  // TODO
}

export const ConstructorCollapse: React.FC<ConstructorCollapseProps> = ({ ...props }) => {
  return (
    <Collapse
      {...props}
      expandIconPosition="end"
      className={styles.constructorCollapse}
      ghost
    />
  )
}
