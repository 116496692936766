import { createSelector } from '@reduxjs/toolkit';
import { HydraulicSectionState } from '@store/slices';

type WithHydraulicSectionState = {
  hydraulicSection: HydraulicSectionState;
};

export const hydraulicSectionStateSelector = (
  state: WithHydraulicSectionState,
): HydraulicSectionState => state.hydraulicSection;

export const getIsCreateHydraulicSectionModalShownSelector = createSelector(
  hydraulicSectionStateSelector,
  (hydraulicSectionState) => {
    return hydraulicSectionState.isCreateHydraulicSectionModalShown;
  },
);
export const getHydroFacilityIdSelector = createSelector(
  hydraulicSectionStateSelector,
  (decadeAppState) => {
    return decadeAppState.hydroFacilityId;
  },
);
export const getShowAddHydraulicSectionSelector = createSelector(
  hydraulicSectionStateSelector,
  (decadeAppState) => {
    return decadeAppState.isShowAddHydraulicSectionModal;
  },
);
