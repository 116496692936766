import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { Statuses } from '@enums/table/customTableStatus';
import { CalculatedProgress, CustomCard } from '@features/ui-kit';
import {
  TaskPrioritiesTranslateEnum,
  TaskStatusEnum,
} from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindOperationalBlockByIdQuery } from '@store/gisproApi';

import styles from './commonInfo.module.scss';

export const CommonInfo = () => {
  const { id } = useParams();

  const { data: operationalBlock } = useFindOperationalBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );

  return (
    <Row gutter={16}>
      <Col span={6}>
        <CustomCard title="Статус">
          <div className={styles.status}>
            {operationalBlock &&
              Statuses[operationalBlock.status as keyof typeof Statuses]}
          </div>
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Бюджет (сом)">
          <div className={styles.budget}>
            <div className={styles.budgetRow}>
              <div className={styles.budgetRowTitle}>План:</div>
              <div>{operationalBlock && operationalBlock.planBudget}</div>
            </div>
            <div className={styles.budgetRow}>
              <div className={styles.budgetRowTitle}>Факт:</div>
              <div>{operationalBlock && operationalBlock.factBudget}</div>
            </div>
          </div>
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Прогресс">
          <CalculatedProgress
            tasksArray={
              operationalBlock && operationalBlock?.implementationTerms
            }
          />
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Приоритет">
          <div
            className={`${styles.priority} ${
              operationalBlock && styles[operationalBlock.priority]
            }`}
          >
            {operationalBlock &&
              TaskPrioritiesTranslateEnum[operationalBlock.priority]}
          </div>
        </CustomCard>
      </Col>
    </Row>
  );
};
