import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, notification, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { CalculationEstimatedFinancial } from '@features/annualApplication/computingAnnual/tables/calculationEstimatedFinancial/CalculationEstimatedFinancial';
import { ApplicationForm } from '@features/annualApplication/computingAnnual/tables/waterAndHectareSupplyTable/ApplicationForm';
import {
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { useCreateAnnualContractMutation } from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import {
  getAnnualContractSelector,
  getPlantingDataSelector,
  getSelectedWaterUserIdSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

import styles from './ComputingAnnual.module.scss';
import {
  EstimatedFinancialPlanTable,
  WaterAndHectareSupplyTable,
} from './tables';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';

export const ComputingAnnual: React.FC = () => {
  const navigate = useNavigate();
  const plantingData = useSelector(getPlantingDataSelector);
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);
  const [create, { error, isError, isLoading }] =
    useCreateAnnualContractMutation();
  const dispatch = useDispatch<AppDispatch>();
  const annualContract = useSelector(getAnnualContractSelector);

  const createClick = () => {
    if (waterUserId) {
      create({
        plantingDataDtoList: plantingData,
        waterUserId,
      })
        .unwrap()
        .then((resp) => {
          if (resp) {
            navigate(
              `${generatePath(Routes.AnnualApplicationCardView, {
                id: (resp as any)?.id,
              })}?tab=${AnnualApplicationQueryTypes.agreement}`,
            );
            notification.success({
              message: 'Годовая заявка успешно создана',
            });
            dispatch(annualApplicationActions.resetAll());
          }
        })
        .catch((err) => {
          console.log('err', err);
          notification.error({
            message: err?.data?.message ?? 'Не удалось создать годовую заявку',
          });
        });
    }
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <InformationalBlock title="Расчеты">
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <EstimatedFinancialPlanTable
                estimateAndFinancialPlanTotal={
                  annualContract?.estimateAndFinancialPlanTotal
                }
              />
            </Col>
            {annualContract?.calculationsByPlantingData.map((item) => (
              <Row gutter={[0, 8]} key={item.facilityId || item.outletId}>
                <Col span={24}>
                  <ApplicationForm data={item} />
                </Col>
                <Col span={24}>
                  <WaterAndHectareSupplyTable data={item} />
                </Col>
                <Col span={24}>
                  <CalculationEstimatedFinancial data={item} />
                </Col>
              </Row>
            ))}
          </Row>
        </InformationalBlock>
      </Col>
      <Col span={24}>
        <Row justify="end" gutter={16}>
          <Col>
            <DefaultButton
              size="large"
              className={styles.buttons}
              type="default"
              htmlType="submit"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              Назад
            </DefaultButton>
          </Col>

          <Col>
            <PrimaryButton
              size="large"
              type="primary"
              htmlType="submit"
              onClick={createClick}
              loading={isLoading}
            >
              Создать
            </PrimaryButton>
          </Col>
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};
