import React from 'react';

import { Col, Divider, Row, Typography } from 'antd';

import { CustomCard, TableCustom } from '@features/ui-kit';
import { ICalculationsByPlantingData } from '@models/annualApplication/interfaces/annualApplication';

import { useWaterAndHectareDataMapper } from './useWaterAndHectareDataMapper';
import { useWaterAndHectareSupplyColumns } from './useWaterAndHectareSupplyColumns';

type WaterAndHectareSupplyTablePropTypes = {
  data: ICalculationsByPlantingData;
};

export const WaterAndHectareSupplyTable: React.FC<
  WaterAndHectareSupplyTablePropTypes
> = ({ data }) => {
  const { columns } = useWaterAndHectareSupplyColumns();
  const transformData = useWaterAndHectareDataMapper(
    data.waterSupplyAndIrrigationPlan,
  );

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>
                План водоподачи и гектарополива
              </Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Row>
          }
        >
          <Col span={24}>
            <TableCustom
              columns={columns}
              dataSource={transformData}
              scroll={{ x: 3000 }}
              bordered
              size="small"
              pagination={false}
            />
          </Col>
        </CustomCard>
      </Col>
    </Row>
  );
};
