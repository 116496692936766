import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Divider, Form, Row, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { EntityTypesEnum } from '@enums/entityTypes';
import { useAssignExecutorModal } from '@features/manageEvents/useAssignExecutorModal';
import {
  CustomCard,
  DefaultButton,
  PrimaryButton,
  PriorityGroup,
  TooltipedDatePicker,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { AddExecutorButton } from '@features/ui-kit-v2/buttonsV2/AddExecutorButton';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListQuery,
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdQuery,
  useGetRegularEventByIdQuery,
} from '@store/gisproApi';
import { getRegularEventBlockSelector } from '@store/selectors';
import { isInteger } from '@utils/utils';

import styles from './LeftBlock.module.scss';
import { useGetCurrentUser } from '@features/authentication';

export const LeftBlock: React.FC = () => {
  const form = useFormInstance();
  const { id } = useParams();
  const { data: regularEvent } = useGetRegularEventByIdQuery(
    id && isInteger(parseInt(id, 10)) ? parseInt(id, 10) : skipToken,
  );
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const regularEventBlock = useSelector(getRegularEventBlockSelector);
  const { setShow: setShowAssignExecutorModalAction } =
    useAssignExecutorModal();
  const { data: workCategories = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Directions,
  });
  const { data: objectsList, isLoading: objectsListIsLoading } =
    useFindAllIrrigationSystemObjectsListQuery();
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const workCategoriesId = Form.useWatch('categoryId', form);
  const regionId = Form.useWatch('regionId', form);
  const districtId = Form.useWatch('districtId', form);
  const systemId = Form.useWatch('systemId', form);

  const { data: systems, isLoading: systemsIsLoading } =
    useFindAllIrrigationSystemListQuery(
      districtId ? { districtId } : skipToken,
    );

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    isInteger(regionId) ? regionId : skipToken,
  );

  const { data: filteredWorkTypes = [], isLoading: workTypesLoading } =
    useGetReferencesByParentIdQuery(
      isInteger(workCategoriesId) ? workCategoriesId : skipToken,
    );

  const isEditingDisabled = useMemo(
    () => !regularEventBlock.title || !!regularEvent?.operationalBlocks.length,
    [regularEventBlock],
  );

  const handleChangeRegion = () => {
    form.resetFields(['districtId', 'systemId', 'systemObjectId']);
  };

  const handleChangeDisctrict = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const handleChangeSystem = () => {
    form.resetFields(['systemObjectId']);
  };

  const handleChangeCategory = () => {
    form.resetFields(['workTypeId']);
  };

  return (
    <CustomCard overlay={isEditingDisabled}>
      <>
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4} className={styles.title}>
              Создание задачи
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton>Создать шаблон</DefaultButton>
              </Col>
              <Col>
                <DefaultButton htmlType="submit">Сохранить</DefaultButton>
              </Col>
              <Col>
                <PrimaryButton>Создать</PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <PriorityGroup
          rules={[
            {
              required: true,
              message: 'Заполните поле',
            },
          ]}
        />
        <AddExecutorButton
          setShow={() => setShowAssignExecutorModalAction(true)}
          cardBlockType={regularEvent?.executor || regularEventBlock.executor}
        />
        <Form.Item
          rules={[{ required: true }]}
          name="categoryId"
          label="Категория работ"
          required
          style={{ marginTop: 20 }}
        >
          <TooltipedSelect
            onChange={handleChangeCategory}
            options={workCategories.map((item: any) => ({
              ...item,
              label: item.title,
              value: item.id,
            }))}
            placeholder="Категория работ"
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="workTypeId"
          label="Тип работ"
          required
        >
          <TooltipedSelect
            disabled={!workCategoriesId}
            options={filteredWorkTypes.map((item: any) => ({
              ...item,
              label: item.title,
              value: item.id,
            }))}
            placeholder="Тип работ"
          />
        </Form.Item>

        <div className={styles.objectForm}>
          <Typography.Text className={styles.title}>Объект</Typography.Text>
          <div className={styles.selectGroup}>
            <Form.Item
              className={styles.selectGroup__item}
              rules={[{ required: true }]}
              name="regionId"
              label="Область"
              required
              initialValue={user?.owner?.region?.id}
            >
              <TooltipedSelect
                options={regions}
                onChange={handleChangeRegion}
                placeholder="Область"
                disabled={isDisabledRegion}
              />
            </Form.Item>
            <Form.Item
              className={styles.selectGroup__item}
              rules={[{ required: true }]}
              name="districtId"
              label="Район"
              required
              initialValue={user?.owner?.district?.id}
            >
              <TooltipedSelect
                disabled={isDisabledDistrict || !regionId}
                onChange={handleChangeDisctrict}
                options={districtByParentId}
                placeholder="Район"
              />
            </Form.Item>
            <Form.Item
              className={styles.selectGroup__item}
              rules={[{ required: true }]}
              name="systemId"
              label="Система"
              required
            >
              <TooltipedSelect
                disabled={!districtId}
                loading={systemsIsLoading}
                onChange={handleChangeSystem}
                options={systems?.content?.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.systemId,
                }))}
                placeholder="Система"
              />
            </Form.Item>
            <Form.Item
              className={styles.selectGroup__item}
              rules={[{ required: true }]}
              name="systemObjectId"
              label="Объект"
              normalize={(systemObjectId: string) =>
                parseInt(systemObjectId, 10)
              }
              required
            >
              <TooltipedSelect
                disabled={!systemId}
                loading={objectsListIsLoading}
                options={objectsList}
                placeholder="Объект"
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="inspectionDate"
          label="Указать дату инспекции"
          required
          rules={[{ required: true }]}
        >
          <TooltipedDatePicker
            placeholder="Указать дату инспекции"
            format="YYYY-MM-DD"
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="taskDescription"
          label="Описание задачи"
        >
          <TooltipedTextarea
            className=""
            placeholder="Описание задачи"
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </Form.Item>
      </>
    </CustomCard>
  );
};
