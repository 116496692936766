export enum AccountingGroupEnum {
  Gvs = '1',
  MainConduit = '2',
  OtherAquaducts = '3',
  Collector = '4',
  Well = '6',
  Pump = '7',
  Hole = '8',
  Gts = '5',
  SpillwayStructure = '21',
  WaterOutlet = '9',
  Dam = '10',
  Weir = '11',
  Hydropost = '12',
  OtherMeasuringInstruments = '13',
  Bridge = '14',
  Road = '15',
  PowerLine = '16',
  ElectricalSubstation = '17',
  ForestBelt = '18',
  Reservoir = '19',
  PondAndBsr = '20',
  CategoryWaterOutlet = '46',
}
