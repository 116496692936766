import React, { FC, useEffect, useMemo } from 'react';

import { Col, Form, Row, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';

import {
  CustomCard,
  DefaultButton,
  PicketInput,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { useTranslation } from 'react-i18next';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import {
  useIrrigationFindSystemByIdQuery,
  useIrrigationGetAllBasinQuery,
  useIrrigationGetAllOwnerQuery,
  useIrrigationGetAllWaterIntakePointQuery,
} from '@store/api/irrigationSystemApi';
import {
  useReferenceControllerFindBycodeQuery,
  useReferenceControllerFindByObjectCategoryIdQuery,
} from '@store/api/referenceApi';
import { generatePath, useParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  useSystemObjectControllerFindBySystemObjectIdQuery,
  useSystemObjectControllerUpdateSystemObjectDocumentMutation,
} from '@store/api/systemObjectApi';
import { useGetCurrentUser } from '@features/authentication';
import {
  longPicketInputValidator,
  shortPicketInputValidator,
} from '@utils/validation/picketInputValidator';
import { getIsLinearObject } from '@utils/objectType';
import { useFindAllObjectCategoriesByObjectGroupIdQuery } from '@store/gisproApi';
import { AccountingGroupEnum } from '@enums/accountingGroup';

export const PassportInformationComponent: FC = () => {
  const [show, setShow] = React.useState(false);
  const form = Form.useFormInstance();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'systemObject.card.fields',
  });
  const { id } = useParams<{ id: any }>();
  const { data: waterObject } =
    useSystemObjectControllerFindBySystemObjectIdQuery(id ? { id } : skipToken);

  const regionId = Form.useWatch('regionId', form);

  const objectCategoryId = form.getFieldValue('objectCategoryId');
  const systemId = form.getFieldValue('systemId');
  const { data: objectCategory } =
    useReferenceControllerFindByObjectCategoryIdQuery(
      objectCategoryId ? { id: objectCategoryId } : skipToken,
    );
  const { data: objectCategories = [] } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      waterObject?.objectGroupId
        ? { objectGroupId: String(waterObject?.objectGroupId) }
        : skipToken,
    );

  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.District,
  });

  const { data: bassins = [] } = useIrrigationGetAllBasinQuery();

  const { data: sources = [] } = useIrrigationGetAllWaterIntakePointQuery();

  const { data: owners = [] } = useIrrigationGetAllOwnerQuery();

  const { data: technicalStatuses = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.TechnicalStatusEnum,
    });

  const { data: operationalStatuses = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.OperationalStatusEnum,
    });

  const { data: waterObjectDirections = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.WorkCategory,
    });

  const { data: irrigationSystem } = useIrrigationFindSystemByIdQuery(
    systemId
      ? {
          id: systemId,
        }
      : skipToken,
  );

  const [update] =
    useSystemObjectControllerUpdateSystemObjectDocumentMutation();

  const onGisClick = () => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:irrigation-system:${systemId}`,
    });
    window.open(url, '_blank');
  };

  const onAttachmentUpload = (attachment: IAttachment) => {
    const id = form.getFieldValue('id');
    update({
      id,
      documentId: attachment.attachmentId,
    });
  };

  useEffect(() => {
    if (irrigationSystem) {
      form.setFieldsValue({
        systemName: irrigationSystem.name,
      });
    }
  }, [irrigationSystem]);

  const isLinearObject = useMemo(
    () => getIsLinearObject(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const accountingGroupCode = String(objectCategory?.accountingGroup?.code);
  AccountingGroupEnum.MainConduit;
  const isDisabledCoordinates = useMemo(
    () =>
      accountingGroupCode === AccountingGroupEnum.MainConduit &&
      waterObject?.coordinates &&
      waterObject?.lengthKm,
    [accountingGroupCode, waterObject],
  );
  return (
    <>
      <CustomCard
        title={
          <Row justify="space-between">
            <Col>
              <Typography.Title level={4}>Паспортные данные</Typography.Title>
            </Col>
            <Col>
              <PrimaryButton onClick={onGisClick}>ГИС портал</PrimaryButton>
            </Col>
          </Row>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <CustomCard title={null} dashed>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={t('name')} name="name">
                    <TooltipedInput size="large" placeholder="Наименование" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={t('code')} name="code">
                    <TooltipedInput size="large" placeholder="Код" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={t('id')} name="id">
                    <TooltipedInput
                      size="large"
                      disabled={true}
                      placeholder="ID номер"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true }]}
                    name="objectGroupName"
                    label={t('objectGroup')}
                  >
                    <TooltipedInput size="large" disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="typeCategory"
                    rules={[{ required: true }]}
                    label={t('typeCategory')}
                    style={{ marginBottom: 10 }}
                  >
                    <TooltipedInput size="large" disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('objectCategory')}
                    rules={[{ required: true }]}
                    name="objectCategoryId"
                  >
                    <TooltipedSelect
                      disabled
                      size="large"
                      options={objectCategories}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('appointment')}
                    rules={[{ required: true }]}
                    name="appointmentId"
                  >
                    <TooltipedSelect
                      size="large"
                      options={waterObjectDirections}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="coordinates"
                    label={
                      isLinearObject
                        ? 'Протяженность в пикетах'
                        : 'Пикетная координата'
                    }
                    rules={[
                      { required: true },
                      isLinearObject
                        ? longPicketInputValidator
                        : shortPicketInputValidator,
                    ]}
                  >
                    <PicketInput
                      disabled={!!isDisabledCoordinates}
                      size="large"
                      isLinear={isLinearObject}
                    />
                  </Form.Item>
                </Col>
                {isLinearObject && (
                  <Col span={12}>
                    <Form.Item
                      name="lengthKm"
                      label="Протяженность в КМ"
                      rules={[{ required: true }]}
                    >
                      <TooltipedInput
                        disabled={!!isDisabledCoordinates}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </CustomCard>
          </Col>

          <Col span={12}>
            <CustomCard title={null} dashed>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="technicalStatusId"
                    label={t('technicalStatus')}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      size="large"
                      options={technicalStatuses}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="operationalStatusId"
                    label={t('operationalStatus')}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      size="large"
                      options={operationalStatuses}
                      allowClear
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('owner')}
                    rules={[{ required: true }]}
                    name="ownerId"
                  >
                    <TooltipedSelect size="large" options={owners} disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="dateStart"
                    label={t('dateStart')}
                    rules={[{ required: true }]}
                  >
                    <YearPicker
                      size="large"
                      placeholder="Год ввода в экспуалтацию"
                      disabledDate={(current) =>
                        current && current > dayjs().endOf('year')
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="builtProject"
                    label={t('builtProject')}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('carryingAmount')}
                    name="carryingAmount"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </CustomCard>
          </Col>

          <Col span={24}>
            <CustomCard title={null} dashed>
              <Row gutter={16} align="middle">
                {accountingGroupCode !== AccountingGroupEnum.Reservoir && (
                  <>
                    <Col span={6}>
                      <Form.Item
                        label={t('region')}
                        name="regionId"
                        initialValue={user?.owner?.region?.id}
                      >
                        <TooltipedSelect
                          size="large"
                          options={regions}
                          disabled={isDisabledRegion}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t('district')}
                        name="districtId"
                        initialValue={user?.owner?.district?.id}
                      >
                        <TooltipedSelect
                          size="large"
                          disabled={isDisabledDistrict || !regionId}
                          options={districts}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t('basin')} name="basinId">
                        <TooltipedSelect
                          size="large"
                          disabled
                          options={bassins}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t('source')} name="source">
                        <TooltipedSelect
                          size="large"
                          disabled
                          options={sources}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t('system')} name="systemName">
                        <TooltipedInput disabled size="large" />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={6}>
                  <Form.Item label={t('gis')} name="gis">
                    <TooltipedInput disabled size="large" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label={t('suspendedSquare')} name="totalArea">
                    <TooltipedInput size="large" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <DefaultButton
                    onClick={() => setShow(true)}
                    style={{ marginTop: 6 }}
                    icon={<UploadFileIcon />}
                  >
                    Прикрепить ведомость владельца
                  </DefaultButton>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>
      </CustomCard>
      <UploadModal
        onLoad={onAttachmentUpload}
        setShow={setShow}
        preSelectedDocType="ведомость владельца"
        show={show}
      />
    </>
  );
};
