import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const TariffMonitoring: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="TariffMonitoring"
        src={getKibanaUrl('Мониторинг тарифов')}
        height="1220"
        width="100%"
      />
    </div>
  );
};
