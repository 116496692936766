import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { CustomCard, TableCustom } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '@store/gisproApi';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

import styles from './documents.module.scss';
import { useDocumentsColumns } from './useDocumentsColumns';

export const Documents = () => {
  const { columns } = useDocumentsColumns();
  const { id } = useParams();

  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );

  const onHandleWatch = (document: any) => {
    downloadAttachment(document);
  };
  return (
    <div>
      <CustomCard title="Документы">
        <Row>
          <Col span={24}>
            <TableCustom
              bordered
              dataSource={
                constructionBlock ? constructionBlock.attachments : []
              }
              columns={columns}
              rowClassName={styles.tableRow}
              scroll={{ x: 1500 }}
              actionProps={{ onHandleWatch }}
            />
          </Col>
        </Row>
      </CustomCard>
    </div>
  );
};
