import { createSelector } from '@reduxjs/toolkit';
import { MainConduitState } from '@store/slices';

type WithMainConduitState = {
  mainConduit: MainConduitState;
};

export const mainConduitStateSelector = (
  state: WithMainConduitState,
): MainConduitState => state.mainConduit;

export const getWaterObjectsSelector = createSelector(
  mainConduitStateSelector,
  (mainConduitState) => {
    return mainConduitState.waterObjects;
  },
);

export const getSelectedWaterObjectSelector = createSelector(
  mainConduitStateSelector,
  (mainConduitState) => {
    return mainConduitState.selectedWaterObject;
  },
);

export const getDocumentSelector = createSelector(
  mainConduitStateSelector,
  (mainConduitState) => {
    return mainConduitState.documents;
  },
);

export const getSelectedWaterSectionIdSelector = createSelector(
  mainConduitStateSelector,
  (mainConduitState) => {
    return mainConduitState.selectedWaterSectionId;
  },
);

export const getIsMainConduitCreateStepperActionSelectModalShownSelector =
  createSelector(mainConduitStateSelector, (mainConduitState) => {
    return mainConduitState.isMainConduitCreateStepperActionSelectModalShown;
  });
