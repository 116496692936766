import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

export const useWateringRateEditorColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Номер полива',
      dataIndex: 'id',
      key: 'name',
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Дата начала',
      key: 'name',
      dataIndex: 'startDate',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <div>{dayjs(record.startDate).format('MM')}-{dayjs(record.startDate).format('DD')}</div>;
      },
    },
    {
      title: 'Дата Окончания',
      key: 'name',
      dataIndex: 'endDate',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <div>{dayjs(record.endDate).format('MM')}-{dayjs(record.endDate).format('DD')}</div>;
      },
    },
    {
      title: 'Дней',
      key: 'name',
      dataIndex: 'endDay',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <div>{dayjs(record.endDate).diff(dayjs(record.startDate), 'days')}</div>;
      },
    },
    {
      title: 'Норма полива',
      dataIndex: 'irrigationStandard',
      align: 'center',
      key: 'irrigationStandard',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Вид полива',
      dataIndex: 'irrigationTypeName',
      key: 'irrigationTypeName',
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Ордината',
      dataIndex: 'ordinateIrrigationStandard',
      align: 'center',
      key: 'ordinateIrrigationStandard',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Охват, %',
      dataIndex: 'coverField',
      align: 'center',
      key: 'coverField',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
    },
  ];
  return { columns };
};
