import { CustomTableColumnType } from '@enums/table';
import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { DecadeStatus } from '@models/decade/enums/decade';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

type ReturnType = {
  columns: TableColumn[];
};

export const useDecadeApplicationTableColumns = ({
  month,
}: {
  month: string;
}): ReturnType => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      width: 80,
      xtype: CustomTableColumnType.String,
      align: 'left',
    },
    {
      title: 'Наименование участка/водовыпуска',
      dataIndex: 'name',
      align: 'left',
      key: 'name',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => {
        return <div>{record.name}</div>;
      },
      width: 200,
    },
    {
      title: month,
      dataIndex: month,
      xtype: CustomTableColumnType.String,
      align: 'left',
      children: [
        {
          title: '1 Декада',
          dataIndex: `${month}-1`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.String,
              align: 'left',
              width: 100,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  align: 'left',
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
                {
                  title: 'м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  align: 'left',
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              xtype: CustomTableColumnType.String,
              ytype: CustomTableColumnHeaderType.StringWithAction,
              align: 'left',
              status: 'ok',
              width: 100,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  xtype: CustomTableColumnType.DecimalEdit,
                  width: 100,
                  align: 'left',
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  editableDecimalCell: (record) => {
                    const status = get(record, ['decadeDtos', '1', 'status']);
                    return status === 'user' || status === 'new';
                  },
                },
                {
                  title: 'м3',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  align: 'left',
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              width: 100,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
                {
                  title: 'м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
              ],
            },
          ],
        },
        {
          title: '2 Декада',
          dataIndex: `${month}-2`,
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              align: 'left',
              width: 100,
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                },
                {
                  title: 'м3',
                  align: 'left',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              width: 100,
              dataIndex: 'request',
              align: 'left',
              status: 'ok',
              xtype: CustomTableColumnType.String,
              ytype: CustomTableColumnHeaderType.StringWithAction,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],

                  editableDecimalCell: (record) => {
                    const status = get(record, ['decadeDtos', '2', 'status']);
                    return (
                      status === DecadeStatus.USER ||
                      status === DecadeStatus.NEW
                    );
                  },
                },
                {
                  title: 'м3',
                  align: 'left',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              width: 100,
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  align: 'left',
                  dataIndex: 'fact',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                },
                {
                  title: 'м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                },
              ],
            },
          ],
        },
        {
          title: '3 Декада',
          dataIndex: `${month}-3`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              decimalIndex: ['decadeDtos', '3', 'value'],
              align: 'left',
              width: 100,
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                },
                {
                  title: 'м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  align: 'left',
                  decimalIndex: ['decadeDtos', '3', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              width: 100,
              align: 'left',
              status: 'user',
              ytype: CustomTableColumnHeaderType.StringWithAction,
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  width: 100,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  editableDecimalCell: (record) => {
                    const status = get(record, ['decadeDtos', '3', 'status']);
                    return status !== 'edit';
                  },
                },
                {
                  title: 'м3',
                  dataIndex: 'requestM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              width: 100,
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                },
                {
                  title: 'м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 100,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      xtype: CustomTableColumnType.String,
      align: 'left',
      children: [
        {
          title: 'План',
          align: 'left',
          dataIndex: 'plan',
          width: 100,
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              align: 'left',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
            {
              title: 'м3',
              dataIndex: 'plan3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
          ],
        },
        {
          title: 'Заявка',
          dataIndex: 'request',
          align: 'left',
          width: 100,
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'request',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
            {
              title: 'м3',
              dataIndex: 'request3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
          ],
        },
        {
          title: 'Факт',
          dataIndex: 'fact',
          width: 100,
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
            {
              title: 'м3',
              dataIndex: 'fact3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
              decimalIndex: ['sum'],
            },
          ],
        },
      ],
    },
  ];

  return {
    columns,
  };
};
