import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { DangerButton, DefaultButton, PrimaryButton } from '@features/ui-kit';
import { DefaultButtonV2, PrimaryButtonV2 } from '@features/ui-kit-v2';
import { CreateIrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { getCurrentIrrigationSystemSelector } from '@store/selectors';
import {
  changeIrrigationSystemAction,
  clearIrrigationSystemAction,
} from '@store/slices';
import dayjs, { Dayjs } from 'dayjs';

import styles from './CreateStepperSecondStep.module.scss';
import { WorkNotationModal } from './workNotationModal';
import { WorksheetFields } from './workSheetFeilds';

export const CreateStepperSecondStep: FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentIrrigationSystem = useSelector(
    getCurrentIrrigationSystemSelector,
  );

  const onFinish = useCallback((values: any) => {
    dispatch(
      changeIrrigationSystemAction({
        ...values,
        works: (values.works || []).map((item: { workYear: Dayjs }) => ({
          ...item,
          workYear: dayjs(item.workYear).format('YYYY'),
        })),
      }),
    );
    navigate(
      `${Routes.IrrigationSystemCreateStepper}?${SectionQueryKey}=${CreateIrrigationSystemQueryTypes.document}`,
    );
  }, []);

  const onCancelButtonClick = () => {
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  };

  const onPreviousButtonClick = () => {
    navigate(
      `${Routes.IrrigationSystemCreateStepper}?${SectionQueryKey}=${CreateIrrigationSystemQueryTypes.totalInformation}`,
    );
  };

  const works = Form.useWatch('works', form);

  const initailValues = useMemo<{ works: any }>(() => {
    if (currentIrrigationSystem && currentIrrigationSystem.works) {
      return {
        works: currentIrrigationSystem?.works.map((item) => ({
          ...item,
          workYear: dayjs(item.workYear),
        })),
      };
    }
    return { works: [{}] };
  }, [currentIrrigationSystem]);

  return (
    <Form
      initialValues={initailValues}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      form={form}
      className={styles.form}
    >
      <WorksheetFields />

      <Row
        justify="space-between"
        align="middle"
        className={styles.navigationButtons}
      >
        <Col>
          <DangerButton
            type="default"
            htmlType="button"
            onClick={onCancelButtonClick}
            style={{ color: 'white' }}
            size="large"
          >
            {t('createIndividualWaterRegistry.buttons.cancel')}
          </DangerButton>
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <DefaultButton
                size="large"
                type="default"
                htmlType="button"
                onClick={onPreviousButtonClick}
              >
                {t('createIndividualWaterRegistry.buttons.back')}
              </DefaultButton>
            </Col>
            <Col>
              <PrimaryButton
                size="large"
                disabled={!works?.length}
                type="primary"
                htmlType="submit"
              >
                {t('createIndividualWaterRegistry.buttons.next')}
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <WorkNotationModal />
    </Form>
  );
};
