import { IActsInvoicesForPayments } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';

export const decadeDataMapper = (data: IActsInvoicesForPayments) => {
  return [
    {
      outletName: data.outletObjectName,
      months: {
        [data.month]: {
          decadeDtos: {
            1: {
              waterAccountingDecadeId: `${data.waterAccountingMonthId}-firstDecade`,
              decadeNumber: 1,
              month: data.month,
              plan: 0,
              fact: data.firstDecade,
            },
            2: {
              waterAccountingDecadeId: `${data.waterAccountingMonthId}-secondDecade`,
              decadeNumber: 2,
              month: data.month,
              plan: 0,
              fact: data.secondDecade,
            },
            3: {
              waterAccountingDecadeId: `${data.waterAccountingMonthId}-thirdDecade`,
              decadeNumber: 3,
              month: data.month,
              plan: 0,
              fact: data.thirdDecade,
            },
          },
        },
      },
    },
  ];
};
