import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { createSlice } from '@reduxjs/toolkit';

export type PeriodColumns = {
  monthId: number;
  decade: number;
  decadeDays: number;
  month: string;
  page: number;
  size: number;
  sort?: string;
  decadeNumber?: number;
};

export type PeriodWaterUseColumns = {
  monthId: number;
  userType: WaterUserTypes;
  page: number;
  size: number;
  sort?: string;
};

export type TableState = {
  waterSupplyPeriod: PeriodColumns;
  quentityIndicatorsPeriod: PeriodColumns;
  waterUseSupplyPeriod: PeriodWaterUseColumns;
  waterUseWateringPeriod: PeriodWaterUseColumns;
  decadeAppPeriod: PeriodWaterUseColumns;
};

const initialState: TableState = {
  waterSupplyPeriod: {
    monthId: 1,
    decade: 1,
    decadeDays: 1,
    month: 'Январь',
    page: 1,
    size: 10,
    sort: '',
  },
  quentityIndicatorsPeriod: {
    monthId: 1,
    decade: 1,
    decadeDays: 1,
    month: 'Январь',
    page: 1,
    size: 10,
    sort: '',
  },
  waterUseSupplyPeriod: {
    monthId: 1,
    userType: WaterUserTypes.physical,
    page: 1,
    size: 10,
    sort: '',
  },
  waterUseWateringPeriod: {
    monthId: 1,
    userType: WaterUserTypes.physical,
    page: 1,
    size: 10,
    sort: '',
  },
  decadeAppPeriod: {
    monthId: 1,
    userType: WaterUserTypes.physical,
    page: 1,
    size: 10,
    sort: '',
  },
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setCurrentColumnWaterSupply: (state, { payload }) => {
      return {
        ...state,
        waterSupplyPeriod: {
          ...payload,
        },
      };
    },
    setCurrentColumnQuentityIndicators: (state, { payload }) => {
      return {
        ...state,
        quentityIndicatorsPeriod: {
          ...payload,
        },
      };
    },
    setCurrentColumnWaterUseSupply: (state, { payload }) => {
      return {
        ...state,
        waterUseSupplyPeriod: {
          ...payload,
        },
      };
    },
    setCurrentColumnWaterUseWatering: (state, { payload }) => {
      return {
        ...state,
        waterUseWateringPeriod: {
          ...payload,
        },
      };
    },
    setCurrentColumnDecadeApp: (state, { payload }) => {
      return {
        ...state,
        decadeAppPeriod: {
          ...payload,
        },
      };
    },
  },
});

export const {
  setCurrentColumnWaterSupply,
  setCurrentColumnQuentityIndicators,
  setCurrentColumnWaterUseSupply,
  setCurrentColumnDecadeApp,
  setCurrentColumnWaterUseWatering,
} = tableSlice.actions;

export default tableSlice.reducer;
