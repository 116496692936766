import React from 'react';

import { Layout } from 'antd';
import {
  WaterLogAccountingBalanceSheet
} from '@features/waterAccounting/waterLogAccounting/waterLogAccountingBalanceSheet/WaterLogAccountingBalanceSheet';

export const WaterAccountingBalanceSheetLayout = () => {
  return (
    <Layout>
      <WaterLogAccountingBalanceSheet />
    </Layout>
  );
};
