import { CustomCard, Divider, InformationalBlock, PrimaryButton, TableCustom, } from '@features/ui-kit';
import { useJournalFilters } from "@features/vodokhozReport/entryData/magazine/useJournalFilters";

import { PrintIcon } from '@icons/PrintIcon';
import {
  MagazineFilters
} from "@pages/vodkhozReport/components/magazinePage/components/magazineFilters/MagazineFilters";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGet2tpListQuery } from "@store/gisproApi";
import { Col, Row, Typography } from 'antd';
import isEmpty from "lodash/isEmpty";
import { FC } from 'react';
import { columns } from './columns';

export const JournalPage: FC = () => {
  const { filters } = useJournalFilters()
  const { data: { content = [] } = {}, isLoading } = useGet2tpListQuery(
    isEmpty(filters) ? skipToken : filters,
  )
  return (
    <>
      <Row justify="space-between">
        <Col span={24}>
          <MagazineFilters/>
        </Col>
      </Row>
      <Divider/>
      <InformationalBlock>
        <CustomCard
          title={
            <Row gutter={32} justify="space-between">
              <Col style={{ flexGrow: 1, borderBottom: '1px solid #0000000F' }}>
                <Typography.Title style={{ marginBottom: 0 }} level={4}>
                  Список отчетов
                </Typography.Title>
              </Col>
              <Col>
                <PrimaryButton icon={<PrintIcon/>}/>
              </Col>
            </Row>
          }
        >
          <TableCustom
            bordered
            columns={columns}
            dataSource={content}
            loading={isLoading}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
