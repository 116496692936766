import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  ConnectBlock,
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { Col, Divider, Form, Layout, Row, Space, Typography } from 'antd';
import { useEffect } from 'react';
import styles from './ReservoirMonitoringCard.module.scss';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReservoirByIdQuery,
  useUpdateSystemObjectMutation,
} from '@store/gisproApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { Links } from '@enums/links';
import { WorkMarkTable } from '@features/waterObject/waterObjectCard/shared/tables';
import { WorkMarkModal } from '@features/waterObject/waterObjectCard/shared/modals';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { get } from '@utils/utils';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getAllWaterObjectWorkDtosSelector } from '@store/selectors/waterObject';

export const ReservoirMonitoringCard = () => {
  const { setHeaderTitle } = useHeaderTitle();
  const { id: reservoirId } = useParams<{ id: any }>();
  const [form] = Form.useForm();
  const works = useSelector(getAllWaterObjectWorkDtosSelector) || [];

  const { data: reservoir } = useGetReservoirByIdQuery(reservoirId ? { reservoirId } : skipToken);
  const [update, { isError, error }] = useUpdateSystemObjectMutation();
  const { data: technicalStatuses = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.TechnicalStatusEnum,
  });

  const { data: operationalStatuses = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.OperationalStatusEnum,
  });

  const reservoirDetails = [
    {
      label: 'ID номер',
      value: get(reservoir, 'id'),
    },
    {
      label: 'Наименование',
      value: get(reservoir, 'name'),
    },
    {
      label: 'Категория',
      value: get(reservoir, 'objectCategory.groupTitle'),
    },
    {
      label: 'Год ввода в эксплуатацию',
      value: dayjs(get(reservoir, 'dateStart')).year(),
    },
    {
      label: 'Пикетная координата',
      value: get(reservoir, 'coordinates'),
    },
  ];

  const onFinish = (values: any) => {
    update({
      waterObject: {
        id: get(reservoir, 'id'),
        workDtos: works,
        ...values,
      },
    });
  };

  useEffect(() => {
    setHeaderTitle('Карточка водохранилища');
  }, []);

  useEffect(() => {
    if (reservoir) {
      form.setFieldsValue({
        ...reservoir,
        technicalStatusId: reservoir.technicalStatus?.id,
        operationalStatusId: reservoir.operationalStatus?.id,
      });
    }
  }, [reservoir]);

  return (
    <Layout>
      <InformationalBlock>
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          className={styles.form}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Space direction="vertical" style={{ display: 'flex' }}>
                <ConnectBlock
                  connectData={reservoirDetails}
                  title={
                    <Row justify="space-between" align="top">
                      <Col span={24}>
                        <Typography.Title level={4}>
                          Паспортные данные
                        </Typography.Title>
                        <Divider className={styles.divider} />
                      </Col>
                    </Row>
                  }
                />

                <WorkMarkTable />
                <WorkMarkModal />
              </Space>
            </Col>
            <Col span={12}>
              <CustomCard title="Системные показатели">
                <Col>
                  <Form.Item
                    name="technicalStatusId"
                    label="Статус технического состояния"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      placeholder="Выберите состояние"
                      options={technicalStatuses}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="operationalStatusId"
                    label="Статус эксплуатационного состояния"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      placeholder="Выберите состояние"
                      options={operationalStatuses}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label="Максимальный объем млн.м3"
                    name="maxVolume"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите данные"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label="Минимальный объем млн.м3"
                    name="minVolume"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите данные"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label="Полезный объем млн.м3"
                    name="usefulVolume"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите данные"
                      type="number"
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    name="characteristicsOfConstruction"
                    label={
                      <Row align="middle">
                        <img src={Links.Feather} alt="" />
                        Характеристика, особенности конструкции сооружения
                      </Row>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          'Пожалуйста, введите  Характеристика, особенности конструкции сооружения',
                      },
                    ]}
                  >
                    <TooltipedTextarea />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="operatingCharacteristics"
                    label={
                      <Row align="middle">
                        <img src={Links.Feather} alt="" />
                        Характеристика, особенности эксплуатации
                      </Row>
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          'Пожалуйста, введите Характеристика, особенности эксплуатации',
                      },
                    ]}
                  >
                    <TooltipedTextarea />
                  </Form.Item>
                </Col>
              </CustomCard>
            </Col>
          </Row>

          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <PrimaryButton size="large" htmlType="submit">
                Сохранить
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </InformationalBlock>
    </Layout>
  );
};
