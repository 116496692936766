import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useIrrigationReservoirTableColumns = () => {
  const isArchived = location.pathname.includes(Routes.ArchivariusTable);

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      xtype: CustomTableColumnType.GreyCell,
      align: 'left',
      ytype: CustomTableColumnHeaderType.String,
    },
    {
      title: 'Код водного объекта',
      dataIndex: 'code',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
    },
    {
      title: 'Наименование объекта',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'name',
      align: 'left',
    },
    {
      title: 'Категории',
      dataIndex: 'objectCategory',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'objectCategory',
      renderCustom: ({ record }) => {
        return <>{record.objectCategory.groupTitle}</>;
      },
      align: 'left',
    },
    {
      title: 'Тип',
      dataIndex: 'typeCategory',
      xtype: CustomTableColumnType.String,
      key: 'typeCategory',
      align: 'left',
    },
    {
      title: 'Год ввода в эксплуатацию',
      dataIndex: 'dateStart',
      xtype: CustomTableColumnType.DateColumn,
      format: 'YYYY',
      key: 'actions',
      align: 'left',
    },
    {
      title: 'Общ. орошаемая площадь (ГА)',
      dataIndex: 'totalArea',
      xtype: CustomTableColumnType.String,
      key: 'actions',
      align: 'left',
    },
    {
      title: 'Геолокация',
      dataIndex: 'geoJson',
      key: 'actions',
      align: 'left',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Gis],
      actionsAccess: [ACCESS.IRRIGATION_SYSTEM_RESERVOIRS_GIS_VIEW],
    },
    {
      title: 'Статус тех. состояния',
      dataIndex: 'technicalStatus.id',
      xtype: CustomTableColumnType.TechnicalStatus,
      key: 'actions',
      align: 'left',
    },
    {
      title: 'Статус экспл. состояния',
      dataIndex: 'operationalStatus.id',
      xtype: CustomTableColumnType.OperationalStatus,
      key: 'actions',
      align: 'left',
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.WatchWithoutText,
        isArchived
          ? CustomTableAction.UnArchiveWithoutText
          : CustomTableAction.ArchiveWithoutText,
      ],
      actionsAccess: [
        ACCESS.IRRIGATION_SYSTEM_RESERVOIRS_CARD_VIEW,
        ACCESS.IRRIGATION_SYSTEM_RESERVOIRS_CARD_ARCHIVE,
      ],
      key: 'actions',
      align: 'left',
    },
  ];
  return { columns };
};
