import { createSelector } from '@reduxjs/toolkit';
import { HydraulicSectionState } from '@store/slices';

type WithirrigationSystemState = {
  irrigationSystem: any;
};

export const irrigationSystemStateSelector = (
  state: WithirrigationSystemState,
): any => state.irrigationSystem;

export const getIrrigationSystemCardObjectFiltersSelector = createSelector(
  irrigationSystemStateSelector,
  (hydraulicSectionState) => {
    return hydraulicSectionState.irrigationSystemCardObjectFilters;
  },
);

export const getIrrigationSystemCardWaterUserFiltersSelector = createSelector(
  irrigationSystemStateSelector,
  (hydraulicSectionState) => {
    return hydraulicSectionState.irrigationSystemCardWaterUserFilters;
  },
);
