import { Form, Row, Typography } from 'antd';
import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';

import { useWaterObjectModals } from '../../modals/useWaterObjectModals';
import { useWorkMarkTableColumns } from './useWorkMarkTableColumns';
import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { IWorkDto } from '@models/waterObject/interfaces/workDto';
import { useMemo, useState } from 'react';

export const WorkMarkTable: React.FC = () => {
  const form = Form.useFormInstance();
  const systemObject = form.getFieldsValue(true);
  const [dataSource, setDataSource] = useState<any>({});

  const { columns } = useWorkMarkTableColumns();
  const { setShowModal } = useWaterObjectModals(WaterMainModalTypes.MARK);
  const onHandleEdit = (val: any) => {
    setShowModal(true, val);
  };

  const deleteItem = (val: any) => {
    const values = form.getFieldsValue(true);
    const currentWorks = values.workDtos || [];
    const newDataSource = {
      workDtos: currentWorks.reduce((acc: IWorkDto[], work: IWorkDto) => {
        if (val?.customId && work?.customId === val?.customId) {
          console.log('val', val);
          console.log('work', work);
          return acc;
        }

        if (val?.id && work?.id === val?.id) {
          acc.push({ ...work, deleted: true });
        } else {
          acc.push(work);
        }

        return acc;
      }, []),
    };

    form.setFieldsValue(newDataSource);

    setDataSource(newDataSource);
  };

  const filteredDataSource = useMemo(() => {
    const newSystemObject = form.getFieldsValue(true);
    return newSystemObject?.workDtos?.filter((item: any) => !item.deleted);
  }, [dataSource, systemObject]);
  return (
    <CustomCard
      light
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4}>
            Отметки о проведении строительных и ремонтных работ
          </Typography.Title>
          <PrimaryButton onClick={() => setShowModal(true)}>
            Добавить
          </PrimaryButton>
        </Row>
      }
    >
      <TableCustom
        bordered
        columns={columns}
        dataSource={filteredDataSource || []}
        scroll={{ y: 370 }}
        pagination={false}
        actionProps={{
          onHandleEdit,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
