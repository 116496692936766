import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BankDto, useCreateBankMutation } from '@store/gisproApi';
import { getShowAddBankModalSelector } from '@store/selectors';
import { setSelectedBank, setShowAddBankModal } from '@store/slices';

type ReturnType = {
  isLoading: boolean;
  isModalShown: boolean;
  addBank: (values: BankDto) => void;
  setShowModal: (showModal: boolean) => void;
  selectBank: (id: number) => void;
};

export function useAddBank(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowAddBankModalSelector);
  const [create, { isLoading }] = useCreateBankMutation();
  const addBank = useCallback((values: BankDto) => {
    create({ bankDto: { ...values, isFilial: false } }).then(
      ({ data }: any) => {
        dispatch(setSelectedBank(data));
      },
    );
  }, []);

  const selectBank = useCallback((id: number) => {
    dispatch(setSelectedBank(id));
  }, []);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowAddBankModal(showModal));
  }, []);

  return {
    selectBank,
    isLoading,
    isModalShown,
    addBank,
    setShowModal,
  };
}
