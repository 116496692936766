import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function renderMonthHeaderCell<RecordType extends JsonRecord>({
  customColumn,
}: TableRendererProps<RecordType>) {
  return <div className={styles.monthBlock}>{customColumn.title}</div>;
}
