import { List, ListItem } from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import { useNewsLatestQuery } from '@store/api/newsApi';

import { ImportantItem } from './ImportantItem';
import styles from './ImportantList.module.scss';

export const ImportantList: React.FC = () => {
  const {
    data: news = [],
    isLoading,
    isFetching,
  } = useNewsLatestQuery();
  return (
    <div className={styles.list}>
      <List
        dataSource={news}
        renderItem={(item: JsonRecord) => (
          <ListItem>
            <ImportantItem {...(item as any)} />
          </ListItem>
        )}
      />
    </div>
  );
};
