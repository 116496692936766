import { useEffect } from 'react';

import { Col, Form, notification, Row, Space } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { IWorkDto } from '@models/waterObject/interfaces/workDto';

import { useWaterObjectModals } from '../useWaterObjectModals';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
  YearPicker,
} from '@features/ui-kit';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import dayjs from 'dayjs';
import { generateTimestampId } from '@utils/generateTimestampId/generateTimestampId';

export const WorkMarkModal: React.FC = () => {
  const outerFrom = Form.useFormInstance();
  const [form] = Form.useForm();
  const { isShowModal, setShowModal, initialValues } = useWaterObjectModals(
    WaterMainModalTypes.MARK,
  );
  const workTypeId = Form.useWatch('workTypeId', form);

  const { data: workTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WorkTypes,
  });

  useEffect(() => {
    if (workTypeId) {
      const workType = workTypes.find((item) => item.id === workTypeId);
      form.setFieldValue('name', workType?.title);
    }
  }, [workTypeId]);

  useEffect(() => {
    if (initialValues) {
      const workType = workTypes.find(
        (item) => item.title === initialValues.name,
      );
      form.setFieldValue('workTypeId', workType?.id);
    }
  }, [workTypes]);

  const onClose = () => {
    form.resetFields();
    setShowModal(false, null);
  };

  const onFinish = (data: IWorkDto) => {
    try {
      const values = outerFrom.getFieldsValue(true);
      const currentWorks = values.workDtos || [];
      if (initialValues) {
        outerFrom.setFieldsValue({
          workDtos: currentWorks.map((work: IWorkDto) =>
            work?.id === initialValues?.id ||
            (initialValues?.customId &&
              work?.customId === initialValues?.customId)
              ? { ...work, ...data, year: dayjs(data?.year).format('YYYY') }
              : work,
          ),
        });
      } else {
        outerFrom.setFieldsValue({
          workDtos: [
            ...currentWorks,
            {
              ...data,
              year: dayjs(data.year).format('YYYY'),
              customId: generateTimestampId(),
            },
          ],
        });
      }

      onClose();
      notification.success({
        message: `Вы успешно ${
          initialValues ? 'изменили' : 'добавили'
        } пометку`,
      });
    } catch (err) {
      console.log('Err: ', err);
    }
  };

  const formInitialValues = {
    ...initialValues,
    workTypeId:
      workTypes?.find((item) => item.title === initialValues.name)?.id || null,
    year: initialValues?.year ? dayjs(initialValues?.year, 'YYYY') : null,
  };

  return (
    <CustomModal
      centered
      footer={false}
      onCancel={onClose}
      title="Отметки о проведении работ"
      open={isShowModal}
    >
      <Form<IWorkDto>
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Год" rules={[{ required: true }]} name="year">
              <YearPicker placeholder="Введите год" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Вид работ"
              rules={[{ required: true }]}
              name="workTypeId"
            >
              <TooltipedSelect
                placeholder="Выберите тип работ"
                options={workTypes}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Наименование работ" name="name">
              <TooltipedInput disabled placeholder="Автозаполняемое" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              rules={[{ required: true }]}
              label="Описание"
            >
              <TooltipedTextarea placeholder="Введите описание" rows={3} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="sum"
              label="Стоимость (сом)"
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Введите сумму" type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Space size={16}>
              <DefaultButton onClick={() => setShowModal(false)} size="large">
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit" size="large">
                {initialValues ? 'Изменить' : 'Добавить'}
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
