import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useInstructionTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'title',
      title: 'Название',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'attachments[0].name',
      title: 'Наименование файла',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
    },
  ];
  return { columns };
};
