import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IrrigationSystemCardControlBlock } from './irrigationSystemCardControlBlock';
import { IrrigationSystemCardSegment } from './IrrigationSystemCardSegment';
import { Breadcrumb, Col, Row, Typography } from 'antd';
import { DefaultButton, Divider } from '@features/ui-kit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { useFindIrrigationSystemByIdQuery } from '@store/gisproApi';

export const IrrigationSystemCardContent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { systemId = '' } = useParams<string>();

  const { data: systemData } = useFindIrrigationSystemByIdQuery({
    irrigationSystemId: systemId,
  });

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() =>
                navigate(
                  systemData?.isArchived
                    ? Routes.ArchivariusTable
                    : Routes.IrrigationSystemTable,
                )
              }
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: t('headers.irrigation-system'),
                },
                {
                  title: t('headers.irrigation-system-card'),
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <IrrigationSystemCardControlBlock />
      <IrrigationSystemCardSegment />
    </>
  );
};
