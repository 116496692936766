import { api } from '../basicApi';
export const addTagTypes = ['admin.news'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      newsLatest: build.query<NewsLatestApiResponse, NewsLatestApiArg>({
        query: () => ({ url: `/api/v1/admin/news/latest` }),
        providesTags: ['admin.news'],
      }),
      newsList: build.query<NewsListApiResponse, NewsListApiArg>({
        query: () => ({ url: `/api/v1/admin/news/list` }),
        providesTags: ['admin.news'],
      }),
      newsCreate: build.mutation<NewsCreateApiResponse, NewsCreateApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/admin/news/create`,
          method: 'POST',
          body: queryArg.newsDto,
        }),
        invalidatesTags: ['admin.news'],
      }),
      newsUpdate: build.mutation<NewsUpdateApiResponse, NewsUpdateApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/admin/news/update/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.newsDto,
        }),
        invalidatesTags: ['admin.news'],
      }),
      newsDelete: build.mutation<NewsDeleteApiResponse, NewsDeleteApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/admin/news/delete/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['admin.news'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type NewsLatestApiResponse = /** status 200  */ NewsEntityDto[];
export type NewsLatestApiArg = void;
export type NewsListApiResponse = /** status 200  */ NewsEntityDto[];
export type NewsListApiArg = void;
export type NewsCreateApiResponse = /** status 200  */ NewsDto;
export type NewsCreateApiArg = {
  newsDto: NewsDto;
};
export type NewsUpdateApiResponse = /** status 200  */ NewsDto;
export type NewsUpdateApiArg = {
  id: number;
  newsDto: NewsDto;
};
export type NewsDeleteApiResponse = /** status 200  */ UpdateResult;
export type NewsDeleteApiArg = {
  id: number;
};
export type NewsEntityDto = {
  /** Идентификатор */
  id: string;
  /** Заголовок */
  title: string;
  /** Содержание */
  content: string;
  /** Дата создания */
  createdAt: any;
};
export type NewsDto = {
  /** Заголовок */
  title: string;
  /** Содержание */
  content: string;
};
export type UpdateResult = {
  affected: number;
  raw: object;
};
export const {
  useNewsLatestQuery,
  useNewsListQuery,
  useNewsCreateMutation,
  useNewsUpdateMutation,
  useNewsDeleteMutation,
} = injectedRtkApi;
