import { Button, ButtonProps } from 'antd';

import { ArrowRightOutlined, CaretRightOutlined } from '@ant-design/icons';

import styles from './buttons.module.scss';

type NavigateButtonPropTypes = ButtonProps & {
  title: string;
};

export const NavigateButton: React.FC<NavigateButtonPropTypes> = ({
  title,
  ...rest
}) => (
  <Button className={styles.navButton} {...rest}>
    <div> {title}</div>

    <CaretRightOutlined className={styles.navIcon} />
  </Button>
);
