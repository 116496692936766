import { getFormData } from '@store/selectors';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListOutletsQuery,
  useFindDictionaryByTypeIdQuery,
} from '@store/gisproApi';
import { useSelector } from 'react-redux';

import { useAddEconomy } from '../useAddEconomy';
import { EconomyType } from '@store/slices';
import { MainWaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

interface EconomiesTableProps {
  economies: EconomyType[];
  waterUserType: MainWaterUserTypes;
}

export const EconomiesTable: FC<EconomiesTableProps> = ({ economies, waterUserType }) => {
  const { t } = useTranslation();
  const {
    removeEconomy,
    setSelectedEconomyIndex,
  } = useAddEconomy();

  const currentWaterRegistry = useSelector(getFormData);

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: district = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Okmot,
  });

  const { data: { content: irrigationSystemsTypes = [] } = {} } =
    useFindAllIrrigationSystemListQuery({});
  const { data: outlets = [] } =
    useFindAllIrrigationSystemObjectsListOutletsQuery({});

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'economieId',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'economieId',
      align: 'center',
    },
    {
      title: t('ecomoniesTable.name'),
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'name',
      align: 'center',
    },
    {
      title: t('ecomoniesTable.address'),
      dataIndex: 'address',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {regions.find((item) => item.id === record.regionId)?.title || '-'},
            {district.find((item) => item.id === record.districtId)?.title || '-'}
            ,{okmots.find((item) => item.id === record.okmotId)?.title || '-'}
          </div>
        );
      },
    },
    {
      title: t('ecomoniesTable.irrigationSystem'),
      dataIndex: 'irrigationSystem',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {irrigationSystemsTypes.find(
              (item) => item.systemId === record.systemId,
            )?.name || '-'}
          </div>
        );
      },
    },
    {
      title: t('ecomoniesTable.waterOutlet'),
      dataIndex: 'outlet',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'outletId',
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {outlets.find((item) => item.objectId === record.outletId)
              ?.objectName || '-'}
          </div>
        );
      },
    },
    {
      title: t('ecomoniesTable.area'),
      dataIndex: 'area',
      xtype: CustomTableColumnType.String,
      key: 'area',
      align: 'center',
    },
    {
      title: 'ФИО собственника/арендодателя',
      dataIndex: 'renter',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        if (!record.renter) {
          const { firstName, middleName, lastName } = currentWaterRegistry.physical?.employees?.[0] ?? {};
          if (firstName) {
            const fullName = `${lastName} ${firstName} ${middleName}`;
            return <div>{fullName.trim()}</div>;
          }
          return <div>{currentWaterRegistry?.legal?.name}</div>;
        }
        const { firstName, middleName, lastName } = record.renter;
        const fullName = `${lastName} ${firstName} ${middleName}`;
        return <div>{fullName.trim()}</div>;
      },
    },
    {
      title: t('createLegalWaterRegistry.secondStep.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];
  console.log('currentWaterRegistry', currentWaterRegistry)
  return (
    <Space size="large" direction="vertical" style={{ display: 'flex' }}>
      <CustomCard title={t('ecomoniesTable.economiesList')}>
        <TableCustom
          bordered
          dataSource={economies}
          columns={columns}
          actionProps={{
            deleteItem: (record) => removeEconomy(record.economieId),
            onHandleEdit: (record) => setSelectedEconomyIndex(record.economieId, waterUserType),
          }}
        />
      </CustomCard>
    </Space>
  );
};
