import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useIrrigationSystemCardWaterUserTableColumns = () => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      xtype: CustomTableColumnType.NumberColumn,
      width: 60,
      key: 'index',
    },
    {
      title: 'Регистрационный номер',
      dataIndex: 'code',
      xtype: CustomTableColumnType.String,
      key: 'code',
    },
    {
      title: 'Наименование Водопользователя',
      dataIndex: 'legalData.name',
      xtype: CustomTableColumnType.String,
      key: 'fio',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => t(`userTypesEnum.${record.type}`),
      key: 'type',
    },
    {
      title: 'Меню контроля',
      dataIndex: 'actions',
      width: 95,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Card, CustomTableAction.AnnualApplication],
      key: 'actions',
    },
  ];

  return { columns };
};
