import {
  DashedButton,
  Divider,
  InformationalBlock,
  Mask,
  PrimaryButton,
  Segmented,
  StatusWrapper,
} from '@features/ui-kit';
import { useWaterUserFilters } from '@features/vodokhozReport/entryData/waterUser/waterUserFilters';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { EntryDataFilters } from '@pages/vodkhozReport/components/entryDataPage/components/entryDataFilers/EntryDataFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGet2tpDrainageQuery,
  useLazyGet2tpChangeStatusQuery,
} from '@store/gisproApi';

import { Col, notification, Row, Space } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import React, { FC, useState } from 'react';

import { WaterDrainageTable, WaterUserTable } from './components';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export enum Report2tpStatus {
  DRAFT = 'draft',
  CANCELLED = 'cancelled',
  SUBMITTED = 'submitted',
}

export const EntryDataPage: FC = () => {
  const [activeSegmentKey, setActiveSegmentKey] = useState<SegmentedValue>('1');
  const { filters } = useWaterUserFilters();

  const { data: report = [], refetch } = useGet2tpDrainageQuery(
    filters.year ? filters : skipToken,
  );

  const hasPermission = useHasUserPermission(ACCESS.REPORT_2TP_SEND);

  const [changeStatus] = useLazyGet2tpChangeStatusQuery();

  const segmentItems: SegmentedLabeledOption[] = [
    {
      value: '1',
      label: (
        <StatusWrapper
          status={
            report.statusData !== Report2tpStatus.SUBMITTED
              ? PlanStatusEnum.Editable
              : PlanStatusEnum.Approved
          }
        >
          <span>Использование водных ресурсов</span>
        </StatusWrapper>
      ),
    },
    {
      value: '2',
      label: (
        <StatusWrapper
          status={
            report.statusDrainage !== Report2tpStatus.SUBMITTED
              ? PlanStatusEnum.Editable
              : PlanStatusEnum.Approved
          }
        >
          <span>Отведение вод</span>
        </StatusWrapper>
      ),
    },
  ];

  let Component;
  switch (activeSegmentKey) {
    case '2':
      Component = WaterDrainageTable;
      break;
    default:
      Component = WaterUserTable;
      break;
  }

  const onChangeStatus = () => {
    changeStatus({
      year: filters.year,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Отчет отправлен',
        });
        refetch();
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось отправить отчет',
        });
      });
  };

  const isDisabled = !(
    report.statusData === Report2tpStatus.SUBMITTED &&
    report.statusDrainage === Report2tpStatus.SUBMITTED
  );

  return (
    <div>
      <Row justify="space-between">
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <EntryDataFilters />
        </Col>
        {filters.year && (
          <>
            <Col>
              <Segmented
                options={segmentItems}
                onChange={setActiveSegmentKey}
              />
            </Col>
            <Col>
              <Space size={8}>
                <DashedButton size="large">
                  {report.status !== Report2tpStatus.SUBMITTED
                    ? 'Проект'
                    : 'Сдан'}
                </DashedButton>
                {hasPermission ? (
                  <PrimaryButton
                    disabled={isDisabled}
                    size="large"
                    onClick={onChangeStatus}
                  >
                    Отправить
                  </PrimaryButton>
                ) : null}
              </Space>
            </Col>
          </>
        )}
      </Row>
      <Divider />
      {filters.year ? (
        <InformationalBlock>
          <Component report={report} refetch={refetch} />
        </InformationalBlock>
      ) : (
        <Mask maskText="Выберите год" fullHeight={true} />
      )}
    </div>
  );
};
