import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { useGetObjectCategoriesQuery } from '@store/gisproApi';

export function renderObjectCategory<RecordType extends JsonRecord>({
  record,
}: TableRendererProps<RecordType>) {
  const { data: categories = [] } = useGetObjectCategoriesQuery();

  const value =
    categories.find(
      (category) => category.categoryId === record.objectCategoryId,
    )?.groupTitle || '-';

  return <span>{value}</span>;
}
