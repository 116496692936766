export enum Statuses {
  draft = 'Проект',
  onPlanned = 'Запланирована',
  onPreparation = 'Подготовка',
  onRealization = 'Реализации',
  onControl = 'Контроль',
  completed = 'Выполнено',
  expired = 'Не выполнено',
  completedNotOnTime = 'Выполнено не в срок',
  submitted = 'Сдан',
  cancelled = 'Отменен',
}
