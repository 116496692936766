import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCreatingSiteAssociationModalSelector,
  getInitialValuesSiteAssociationModalSelector,
  getShowActionSelectSelector,
} from '@store/selectors';
import {
  setCreatingSiteAssociationModal,
  setInitialValuesSiteAssociationModal,
  setShowActionSelectModalAction,
} from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
  getInitialValues: any;
  setInitialValues: (values: any) => void;
};

export function useCreatingSiteAssociationModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getCreatingSiteAssociationModalSelector);

  const getInitialValues = useSelector(
    getInitialValuesSiteAssociationModalSelector,
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setCreatingSiteAssociationModal(showModal));
  }, []);

  const setInitialValues = useCallback((values: any) => {
    dispatch(setInitialValuesSiteAssociationModal(values));
  }, []);

  return {
    isModalShown,
    setShowModal,
    getInitialValues,
    setInitialValues,
  };
}
