import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Breadcrumb, Col, Row, TabsProps } from 'antd';

import { HomeOutlined } from '@ant-design/icons';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider, Tabs } from '@features/ui-kit';
import { WaterUsePlanDataViewTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { setGuvhTab } from '@store/slices';

import styles from './WaterUsePlanCA.tsx.module.scss';
import { WaterUsePlanCATable } from './waterUsePlanCATable/WaterUsePlanCAEdit';
import { WaterUsePlanCAView } from './waterUsePlanCAView/WaterUsePlanCAView';

const options: TabsProps['items'] = [
  {
    label: 'Таблицы ЦА',
    key: WaterUsePlanDataViewTypes.Tables,
  },
  {
    label: 'Таблицы областей и районов',
    key: WaterUsePlanDataViewTypes.View,
  },
];

export const WaterUsePlanCA: React.FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    setHeaderTitle(
      t('headers.waterUsePlan', { user: 'ЦА Чуйский район', year: 2024 }),
    );
  }, []);

  const onTabChange = (tab: string) => {
    setSelectedTab(tab);
    dispatch(setGuvhTab(tab as WaterUsePlanDataViewTypes));
  };

  let Component;
  switch (selectedTab) {
    case WaterUsePlanDataViewTypes.Tables:
      Component = WaterUsePlanCATable;
      break;
    case WaterUsePlanDataViewTypes.View:
      Component = WaterUsePlanCAView;
      break;
    default:
      Component = WaterUsePlanCATable;
  }

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'ЦА Чуйский район',
          },
          {
            title: t('headers.waterUsePlan', {
              user: 'ЦА Чуйский район',
              year: 2024,
            }),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row
        justify="space-between"
        className={styles.controlBar}
        gutter={[0, 10]}
      >
        <Col span={24}>
          <Tabs onChange={onTabChange} items={options} />
        </Col>

        <Col span={24}>
          <Component />
        </Col>
      </Row>
    </>
  );
};
