import { createSelector } from '@reduxjs/toolkit';
import { GeoPortalState } from '@store/slices/geoPortal/geoPortalSlice';

type WithAGeoPortalState = {
  geoPortal: GeoPortalState;
};

export const geoPortalStateSelector = (
  state: WithAGeoPortalState,
): GeoPortalState => state.geoPortal;

export const getSelectedMapKeySelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.selectedMapKey;
  },
);

export const getSelectedLayerSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.selectedLayer;
  },
);

export const getCurrentLayerSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.currentLayer;
  },
);

export const getAllLayerSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.allLayers;
  },
);

export const getSelectedLayersSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.selectedLayers;
  },
);

export const getCurrentWaterObjectSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.currentWaterObject;
  },
);

export const getCurrentFeatureIdSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.currentFeatureId;
  },
);

export const getGeoportalFiltersSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.geoPortalFilters;
  },
);

export const getGeoCurrentAttributeSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.currentAttribute;
  },
);

export const getMonitoringTabSelector = createSelector(
  geoPortalStateSelector,
  (geoPortalState) => {
    return geoPortalState.monitoringTab;
  },
);
