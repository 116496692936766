import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { DocumentTypes } from '@enums/documentTypes';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomCard,
  DefaultButton,
  DocsList,
  Mask,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import {
  AddButtonSecondary,
  SectionTitle,
  UploadModalV2,
} from '@features/ui-kit-v2';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdQuery,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import { addAttachmentToWaterRegistryUserAction } from '@store/slices';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { passportValidator } from '@utils/validation/passportValidator';
import dayjs, { Dayjs } from 'dayjs';

import styles from './styles.module.scss';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import { useGetCurrentUser } from '@features/authentication';

export const IndividualMainInfo: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentWaterRegistry = useSelector(getFormData);
  const [show, setShow] = useState(false);
  const form = Form.useFormInstance();
  const region = Form.useWatch('region', form);

  const { data: economicActivity = [], isLoading: economicActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.TypeEconomicActivity,
    });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });
  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    region || skipToken,
  );

  const documents: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.physical].attachmentResponseDtos ||
      [],
    [currentWaterRegistry],
  );

  const attachments = documents?.filter(
    (att) => att.type === DocumentTypes.Passport,
  );

  const onRegionChange = () => {
    form.setFieldsValue({
      district: null,
    });
  };

  const genderOptions = useMemo(
    () => [
      { value: 'M', label: t('genders.male') },
      { value: 'F', label: t('genders.female') },
    ],
    [],
  );

  const onAttachmentUpload = useCallback((file: IAttachment) => {
    dispatch(
      addAttachmentToWaterRegistryUserAction({
        attachment: file,
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
  }, []);

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              Общие данные водопользователя
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row className={styles.individualMainInfo}>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.lastName',
                )}
                name={['employees', 'lastName']}
                rules={[
                  {
                    required: true,
                  },
                  lettersValidator,
                ]}
              >
                <TooltipedInput placeholder="Введите Фамилию" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.firstName',
                )}
                name={['employees', 'firstName']}
                rules={[{ required: true }, lettersValidator]}
              >
                <TooltipedInput placeholder="Введите имя" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.middleName',
                )}
                name={['employees', 'middleName']}
                rules={[{ required: true }, lettersValidator]}
              >
                <TooltipedInput placeholder="Введите Отчество" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.inn',
                )}
                name={['employees', 'inn']}
                rules={[{ required: true }, { len: 14 }, numbersValidator]}
              >
                <TooltipedInput
                  placeholder="Введите ИНН/ПИН"
                  size="large"
                  maxLength={14}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.passportNumber',
                )}
                name={['employees', 'passportNumber']}
                rules={[{ required: true }, passportValidator]}
              >
                <TooltipedInput placeholder="Введите № Паспорта" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.dateBirth',
                )}
                name={['employees', 'dateBirth']}
                rules={[{ required: true }]}
              >
                <TooltipedDatePicker
                  disabledDate={(current) =>
                    current && current > dayjs().endOf('day')
                  }
                  placeholder="Выберите дату рождения"
                  size="large"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.gender',
                )}
                name={['employees', 'gender']}
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  size="large"
                  allowClear
                  showSearch={false}
                  options={genderOptions}
                  placeholder="Выберите пол"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.address',
                )}
                name={['employees', 'address']}
                rules={[{ required: true }]}
              >
                <TooltipedInput
                  placeholder="Введите Адрес проживания"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.businessActivity',
                )}
                name="economicActivity"
                rules={[{ required: true }]}
              >
                <TooltipedSelect
                  size="large"
                  loading={economicActivityIsLoading}
                  allowClear
                  options={economicActivity.map((item) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  placeholder="Выберите вид хоз. деятельности"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="region"
                label={t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
                )}
                rules={[{ required: true }]}
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  size="large"
                  onChange={onRegionChange}
                  loading={regionIsLoading}
                  options={regions}
                  allowClear
                  placeholder="Область"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="district"
                label={t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
                )}
                rules={[{ required: true }]}
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  size="large"
                  disabled={!region}
                  options={districtByParentId}
                  placeholder="Район"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="end" gutter={[0, 16]}>
            <Col className={styles.docsList} span={24}>
              <Typography.Title level={4}>
                Прикрепленные документы:
              </Typography.Title>
              {attachments?.length ? (
                <DocsList documents={attachments} />
              ) : (
                <Mask maskText="Нет прикрепленных документов" />
              )}
            </Col>
            <Col>
              <DefaultButton
                icon={<UploadFileIcon />}
                onClick={() => setShow(true)}
              >
                {t(
                  'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                )}
                *
              </DefaultButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <UploadModal
        preSelectedDocType="паспорт"
        onLoad={onAttachmentUpload}
        setShow={setShow}
        show={show}
      />
    </CustomCard>
  );
};
