import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row } from 'antd';

import { documentFormats } from '@constants/documentFormats';
import { DocumentTypes } from '@enums/documentTypes';
import {
  CustomModalV2,
  DefaultButtonV2,
  PrimaryButtonV2,
  TooltipedSquaredInputV2,
  UploadDraggerV2,
} from '@features/ui-kit-v2';

import { useAttachDocumentWithoutNotationModal } from '../useAttachDocumentWithoutNotationModal';
import styles from './modals.module.scss';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedSquaredInput,
} from '@features/ui-kit';

type AttachDocumentModalPropTypes = {
  onLoad: (file: any) => void;
};

export const AttachDocumentWithoutDescriptionModal: FC<
  AttachDocumentModalPropTypes
> = ({ onLoad }) => {
  const { isShow, setShow, setDocType, docType } =
    useAttachDocumentWithoutNotationModal();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setDocType('');
    setShow(false);
  };
  const originName = Form.useWatch('fileName', form);
  const attachmentLoad = useCallback(
    (file: any) => {
      onLoad(file);
      onClose();
    },
    [onLoad, setDocType, setShow],
  );

  const photoFormats = documentFormats
    .filter((format) => format.key === 'photo')
    .map((item) => item.value)
    .join(',');

  const docFormats = documentFormats
    .filter((format) => format.key === 'docs')
    .map((item) => item.value)
    .join(',');

  const currentAccept =
    docType === DocumentTypes.PhotoBefore ||
    docType === DocumentTypes.Photo ||
    docType === DocumentTypes.PhotoAfter
      ? photoFormats
      : docFormats;

  return (
    <CustomModal
      centered
      title="Прикрепление документов"
      open={isShow}
      onCancel={onClose}
      width={650}
      footer={
        <Row gutter={30} justify="end">
          <Col span={7}>
            <DefaultButton onClick={onClose}>
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DefaultButton>
          </Col>
        </Row>
      }
    >
      <Form form={form}>
        <Form.Item name="attachFile">
          <UploadDraggerV2
            accept={currentAccept}
            docFormat={currentAccept}
            onLoad={attachmentLoad}
            docType={docType}
            disabled={!originName}
          />
        </Form.Item>
        <div className={styles.fileNameInp}>
          <Form.Item name="fileName" label="Наименование" required>
            <TooltipedSquaredInput placeholder="Наименование" />
          </Form.Item>
        </div>
      </Form>
    </CustomModal>
  );
};
