import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, Row, Space } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { Col } from 'antd/lib';

import { DashedButton, PrimaryButton, Segmented } from '@features/ui-kit';
import {
  PlanStatusEnum,
  WaterUsePlanTableTypes,
} from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { useGetAllStatusWaterUsePlanQuery } from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setUpdateStatus } from '@store/slices';

import { WaterUsePlanGuvhAdditionsTable } from './waterUsePlanGuvhAdditionsTable/WaterUsePlanGuvhAdditionsTable';
import styles from './WaterUsePlanGuvhEdit.module.scss';
import { WaterUsePlanPivotTable } from './waterUsePlanPivotTable/WaterUsePlanPivotTable';

const options: SegmentedLabeledOption[] = [
  {
    label: 'Сводная таблица',
    value: WaterUsePlanTableTypes.PivotTable,
  },
  {
    label: 'Приложение',
    value: WaterUsePlanTableTypes.AdditionsTable,
  },
];

export const WaterUsePlanGuvhEdit: React.FC = () => {
  const ruvhTableInfo = useSelector(getRuvhTablesInfoSelector);
  const dispatch = useDispatch();
  const { data: statuses } = useGetAllStatusWaterUsePlanQuery();
  const [selectedSegment, setSelectedSegment] =
    useState<SegmentedValue>('pivotTable');

  const isCanApprovedByCa = statuses?.every(
    (status: any) => status.statusGuvh === PlanStatusEnum.Approved,
  );
  const onSegmentedChange = (value: SegmentedValue) => {
    setSelectedSegment(value);
  };

  let Component;
  switch (selectedSegment) {
    case WaterUsePlanTableTypes.PivotTable:
      Component = WaterUsePlanPivotTable;
      break;
    case WaterUsePlanTableTypes.AdditionsTable:
      Component = WaterUsePlanGuvhAdditionsTable;
      break;
    default:
      Component = WaterUsePlanPivotTable;
  }

  useEffect(() => {
    if (statuses) {
      statuses.map((status: any) => dispatch(setUpdateStatus(status)));
    }
  }, [statuses]);

  return (
    <Row justify="space-between">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Segmented options={options} onChange={onSegmentedChange} />
          </Col>

          <Col>
            <Space size={8}>
              <DashedButton size="large">
                {isCanApprovedByCa ? 'На согласовании ЦА' : 'Проект ГУВХ'}
              </DashedButton>
              <PrimaryButton size="large" disabled={!isCanApprovedByCa}>
                Отправить на согласование в ЦА
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider className={styles.divider} />
      </Col>

      <Col span={24}>
        <Component />
      </Col>
    </Row>
  );
};
