import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';
import { SegmentedLabeledOption } from 'antd/es/segmented';

import { SectionQueryKey } from '@constants/queryKeys';
import { EnteringReportingIndicatorsForm } from '@features/associationIndicatorReports';
import { CustomSegmented } from '@features/ui-kit-v2';
import { AssociationIndicatorReportsQueryTypes } from '@models/associationIndicatorReports/enums/associationIndicatorReports';

import styles from './AssociationIndicatorReportsLayout.module.scss';
import { useTranslation } from 'react-i18next';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const AssociationIndicatorReportsLayout: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryKey = useMemo(
    () =>
      params.get(SectionQueryKey) ||
      AssociationIndicatorReportsQueryTypes.enteringReportingIndicators,
    [params],
  );

  let Component;
  switch (queryKey) {
    case AssociationIndicatorReportsQueryTypes.enteringReportingIndicators:
      Component = EnteringReportingIndicatorsForm;
      break;
    // case AssociationIndicatorReportsQueryTypes.reportLog:
    //   Component = EnteringReportingIndicatorsForm;
    // break;
    default:
      Component = EnteringReportingIndicatorsForm;
      break;
  }

  const options: SegmentedLabeledOption[] = [
    {
      value: AssociationIndicatorReportsQueryTypes.enteringReportingIndicators,
      label: 'Ввод отчетных показателей',
    },
    // {
    //   value: AssociationIndicatorReportsQueryTypes.reportLog,
    //   label: 'Журнал отчетов',
    // },
  ];

  return (
    <Layout>
      <Row className={styles.controls}>
        <Row gutter={[0, 16]}>
          <Row gutter={16}>
            <Col>
              <DefaultButton
                onClick={() => navigate(Routes.WaterRegistryAssociations)}
                className="backBtn"
              >
                <LeftOutlined />
                <Typography.Text>Назад</Typography.Text>
              </DefaultButton>
            </Col>
            <Col>
              <Breadcrumb
                className="breadcrumb"
                items={[
                  {
                    title: <HomeOutlined />,
                  },
                  {
                    title: t('headers.water-registry-associations'),
                  },
                  {
                    title: 'Основные показатели ассоциации',
                  },
                ]}
              />
            </Col>
          </Row>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <CustomSegmented
          options={options}
          colorType="primary"
          defaultValue={
            AssociationIndicatorReportsQueryTypes.enteringReportingIndicators
          }
          // onChange={onTableTypeChange}
          queryParams={{
            queryKey: SectionQueryKey,
            queryType: AssociationIndicatorReportsQueryTypes,
          }}
        />
      </Row>
      <div className={styles.controls}>
        <Component />
      </div>
    </Layout>
  );
};
