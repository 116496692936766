import { FC } from 'react';

import { Layout } from 'antd';

import { WateringPlan } from '@features/wateringPlan/WateringPlan';

export const WateringPlanLayout: FC = () => {
  return (
    <Layout>
      <WateringPlan />
    </Layout>
  );
};
