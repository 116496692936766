import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { DecadeDays, MonthEnums } from '@enums/month';
import { IWaterAccountingFilters } from '@models/waterAccounting/interfaces/waterAccounting';
import { IWaterSupplyFilters } from '@models/waterSupply';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WaterAccountingState = {
  isShowQuentityEdit: boolean;
  isShowEnterMorningValuesModal: boolean;
  isShowEnterEveningValuesModal: boolean;
  selectedMorningRecord: any;
  selectedEveningRecord: any;
  waterSupplyFilters: Partial<IWaterSupplyFilters>;
  selectedMonth: MonthEnums;
  selectedDecade: DecadesEnum;
  selectedDecadeDay: DaysInDecadeEnum;
  isShowEconomicWaterPrintModal: boolean;
  isShowWaterLogPrintModal: boolean;
  isShowBalanceSheetPrintModal: boolean;
  waterAccountingBalanceSheetFilters: IWaterAccountingFilters;
  waterAccountingWaterLogFilters: IWaterAccountingFilters;
  waterAccountingEconomicFilters: IWaterAccountingFilters;
};

const initialState: WaterAccountingState = {
  isShowQuentityEdit: false,
  isShowEnterEveningValuesModal: false,
  isShowEnterMorningValuesModal: false,
  selectedMorningRecord: null,
  selectedEveningRecord: null,
  waterSupplyFilters: {},
  selectedMonth: MonthEnums.January,
  selectedDecade: DecadesEnum.First,
  selectedDecadeDay: DaysInDecadeEnum.FirstDaysPart,
  isShowEconomicWaterPrintModal: false,
  isShowWaterLogPrintModal: false,
  isShowBalanceSheetPrintModal: false,
  waterAccountingBalanceSheetFilters: {
    year: 2024,
    month: MonthEnums.January,
    decade: DecadesEnum.First,
    decadeDays: DaysInDecadeEnum.FirstDaysPart,
  },
  waterAccountingWaterLogFilters: {
    year: 2024,
    month: MonthEnums.January,
    decade: DecadesEnum.First,
    decadeDays: DaysInDecadeEnum.FirstDaysPart,
  },
  waterAccountingEconomicFilters: {
    year: 2024,
    month: MonthEnums.January,
    decade: DecadesEnum.First,
    decadeDays: DaysInDecadeEnum.FirstDaysPart,
  },
};

const waterAccountingSlice = createSlice({
  name: 'waterAccounting',
  initialState,
  reducers: {
    setSelectedMorningRecordAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedMorningRecord: action.payload,
      };
    },
    setWaterAccountingBalanceSheetFiltersAction: (
      state,
      action: PayloadAction<IWaterAccountingFilters>,
    ) => {
      return {
        ...state,
        waterAccountingBalanceSheetFilters: action.payload,
      };
    },
    setWaterAccountingWaterLogFiltersAction: (
      state,
      action: PayloadAction<IWaterAccountingFilters>,
    ) => {
      return {
        ...state,
        waterAccountingWaterLogFilters: action.payload,
      };
    },
    setWaterAccountingEconomicFiltersAction: (
      state,
      action: PayloadAction<IWaterAccountingFilters>,
    ) => {
      return {
        ...state,
        waterAccountingEconomicFilters: action.payload,
      };
    },
    setShowEconomicWaterPrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowEconomicWaterPrintModal: action.payload,
      };
    },
    setShowBalanceSheetPrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowBalanceSheetPrintModal: action.payload,
      };
    },
    setShowWaterLogPrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWaterLogPrintModal: action.payload,
      };
    },
    setSelectedEveningRecordAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedEveningRecord: action.payload,
      };
    },
    setShowEnterMorningValuesModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowEnterMorningValuesModal: action.payload,
      };
    },
    setShowEnterEveningValuesModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowEnterEveningValuesModal: action.payload,
      };
    },
    setMonthFilterAction: (state, action: PayloadAction<MonthEnums>) => {
      return {
        ...state,
        selectedMonth: action.payload,
      };
    },
    setDecadeFilterAction: (state, action: PayloadAction<DecadesEnum>) => {
      return {
        ...state,
        selectedDecade: action.payload,
      };
    },
    setDecadeDayFilterAction: (
      state,
      action: PayloadAction<DaysInDecadeEnum>,
    ) => {
      return {
        ...state,
        selectedDecadeDay: action.payload,
      };
    },
    setShowQuentityEdit: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowQuentityEdit: action.payload,
      };
    },
    changeWaterSupplyFilters: (
      state,
      action: PayloadAction<IWaterSupplyFilters>,
    ) => {
      return {
        ...state,
        waterUseFilters: action.payload,
      };
    },
    setWaterAccountingBalanceSheetDecadeAction: (
      state,
      action: PayloadAction<DecadesEnum>,
    ) => {
      return {
        ...state,
        waterAccountingBalanceSheetFilters: {
          ...state.waterAccountingBalanceSheetFilters,
          decade: action.payload,
        },
      };
    },
    setWaterAccountingBalanceSheetDecadeDayAction: (
      state,
      action: PayloadAction<DaysInDecadeEnum>,
    ) => {
      return {
        ...state,
        waterAccountingBalanceSheetFilters: {
          ...state.waterAccountingBalanceSheetFilters,
          decadeDays: action.payload,
        },
      };
    },
    setWaterAccountingWaterLogDecadeAction: (
      state,
      action: PayloadAction<DecadesEnum>,
    ) => {
      return {
        ...state,
        waterAccountingWaterLogFilters: {
          ...state.waterAccountingWaterLogFilters,
          decade: action.payload,
        },
      };
    },
    setWaterAccountingWaterLogDecadeDayAction: (
      state,
      action: PayloadAction<DaysInDecadeEnum>,
    ) => {
      return {
        ...state,
        waterAccountingWaterLogFilters: {
          ...state.waterAccountingWaterLogFilters,
          decadeDays: action.payload,
        },
      };
    },
    setWaterAccountingEconomicDecadeAction: (
      state,
      action: PayloadAction<DecadesEnum>,
    ) => {
      return {
        ...state,
        waterAccountingEconomicFilters: {
          ...state.waterAccountingEconomicFilters,
          decade: action.payload,
        },
      };
    },
    setWaterAccountingEconomicDecadeDayAction: (
      state,
      action: PayloadAction<DaysInDecadeEnum>,
    ) => {
      return {
        ...state,
        waterAccountingEconomicFilters: {
          ...state.waterAccountingEconomicFilters,
          decadeDays: action.payload,
        },
      };
    },
  },
});

export const {
  setWaterAccountingBalanceSheetDecadeAction,
  setWaterAccountingBalanceSheetDecadeDayAction,
  setWaterAccountingWaterLogDecadeAction,
  setWaterAccountingWaterLogDecadeDayAction,
  setWaterAccountingEconomicDecadeAction,
  setWaterAccountingEconomicDecadeDayAction,
  setSelectedEveningRecordAction,
  setSelectedMorningRecordAction,
  setShowEnterMorningValuesModalAction,
  setShowEnterEveningValuesModalAction,
  setShowQuentityEdit,
  changeWaterSupplyFilters,
  setShowEconomicWaterPrintModal,
  setShowWaterLogPrintModal,
  setShowBalanceSheetPrintModal,
  setMonthFilterAction,
  setDecadeFilterAction,
  setDecadeDayFilterAction,
  setWaterAccountingEconomicFiltersAction,
  setWaterAccountingBalanceSheetFiltersAction,
  setWaterAccountingWaterLogFiltersAction,
} = waterAccountingSlice.actions;

export default waterAccountingSlice.reducer;
