import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const EditIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.0762C6 10.8 6.22385 10.5762 6.5 10.5762H10.5C10.7761 10.5762 11 10.8 11 11.0762C11 11.3523 10.7761 11.5762 10.5 11.5762H6.5C6.22385 11.5762 6 11.3523 6 11.0762Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3868 4.62614C11.1917 3.81808 11.1575 2.75693 10.5565 2.10709C10.2634 1.79022 9.8463 1.58782 9.37385 1.57666C8.89965 1.56546 8.40835 1.74681 7.955 2.1261C7.9434 2.1358 7.93225 2.14602 7.9216 2.15673L1.43723 8.66697C1.15721 8.94807 1 9.32872 1 9.72552V10.5723C1 11.123 1.44598 11.5762 2.00191 11.5762H2.84132C3.24018 11.5762 3.62262 11.4173 3.90409 11.1347L10.3868 4.62614ZM8.60355 3.47265C8.4083 3.27739 8.0917 3.27739 7.89645 3.47265C7.7012 3.66791 7.7012 3.98449 7.89645 4.17976L8.39645 4.67975C8.5917 4.87501 8.9083 4.87501 9.10355 4.67975C9.2988 4.48449 9.2988 4.16791 9.10355 3.97265L8.60355 3.47265Z"
        fill="white"
      />
    </svg>
  );
};
