import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { useBuildingTableColumns } from '@features/manageEvents/tables/BuildingTable/useBuildingTableColumns';
import { useGetAllConstructionQuery } from '@store/gisproApi';
import { getCurrentTableOptionsSelector } from '@store/selectors/manageEvents';
import { saveTableOptions } from '@store/slices';
import { toString } from '@utils/utils';

import styles from './BuildingTable.module.scss';
import { BuildingTableSelectsBlock } from './BuildingTableSelectsBlock/BuildingTableSelectsBlock';
import { JsonRecord } from '@models/jsonRecord';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { saveUrl } from '@utils/returnUrl';

interface IBuildingTableProps {
  isArchived?: boolean;
  title?: string;
}

export const BuildingTable: React.FC<IBuildingTableProps> = ({
  title,
  isArchived = false,
}) => {
  const dispatch = useDispatch();
  const { columns } = useBuildingTableColumns(isArchived);
  const tableOptions = useSelector(getCurrentTableOptionsSelector);
  const { data: response, isLoading } = useGetAllConstructionQuery({
    ...tableOptions,
    isArchived,
  });
  const navigate = useNavigate();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const saveAction = saveUrl();

  const changePagination = useCallback(
    (pageData: any) => {
      dispatch(
        saveTableOptions({
          ...tableOptions,
          page: pageData.current,
          limit: pageData.pageSize,
        }),
      );
    },
    [columns, tableOptions],
  );

  const watchBuildingItem = (idOperation: number | any) => {
    navigate(
      generatePath(Routes.BuildingBlock, { id: toString(idOperation.id) }),
    );
    saveAction();
  };

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };
  return (
    <Row>
      <Col span={24}>
        <BuildingTableSelectsBlock />
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <InformationalBlock>
          <CustomCard
            style={{ padding: '8px 16px' }}
            title={<Typography.Title level={4}>{title}</Typography.Title>}
          >
            <TableCustom
              dataSource={response?.data || []}
              columns={columns}
              loading={isLoading}
              rowClassName={styles.tableRow}
              scroll={{ x: 1500 }}
              totalCount={response?.meta?.totalCount}
              customPagination={{
                pageable: {
                  pageNumber: response?.meta?.page || 1,
                  pageSize: response?.meta?.limit || 10,
                },
              }}
              onChange={changePagination}
              actionProps={{
                onWatch: watchBuildingItem,
                onUnArchiveClick,
                onArchiveClick,
              }}
            />
          </CustomCard>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
