import { SelectProps, Tag } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import {
  TableColumnSelectTag,
  TableRendererProps,
} from '@models/table/interfaces/tableColumn';

export function renderSelectTag<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { options = [] } = customColumn as TableColumnSelectTag & SelectProps;
  const value = record[customColumn.dataIndex];
  const selectedValue = options.find((option) => option.value === value);

  if (selectedValue) {
    return <Tag color={selectedValue.color}>{selectedValue.label}</Tag>;
  }

  return null;
}
