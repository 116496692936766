import { CustomModal, PrimaryButton } from '@features/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Space } from 'antd';

import { useActionSelect } from '../../useActionSelect';

type PropsType = {
  onPermissionsOpen: () => void;
  onAssociationStatsOpen?: () => void;
  onComplete: () => void;
  onViewCard?: () => void;
};

export const AssociationSelectModal: React.FC<PropsType> = ({
  onPermissionsOpen,
  onAssociationStatsOpen,
  onComplete,
  onViewCard,
}) => {
  const { t } = useTranslation();
  const { isModalShown } = useActionSelect();

  return (
    <CustomModal
      centered
      title="Выбор действия"
      open={isModalShown}
      width={435}
      closeIcon={null}
      footer={<div />}
    >
      <Row justify="center">
        <Space size="large" direction="vertical">
          <PrimaryButton
            style={{ width: 350 }}
            type="primary"
            onClick={onViewCard}
          >
            Просмотр карточки
          </PrimaryButton>
          <PrimaryButton
            style={{ width: 350 }}
            type="primary"
            onClick={onPermissionsOpen}
          >
            Выдать доступы
          </PrimaryButton>
          <PrimaryButton
            style={{ width: 350, whiteSpace: 'break-spaces', height: 70 }}
            onClick={onAssociationStatsOpen}
            type="primary"
          >
            Вывести основные показатели ассоциации
          </PrimaryButton>
          <PrimaryButton
            style={{ width: 350 }}
            onClick={onComplete}
            type="primary"
          >
            Завершить
          </PrimaryButton>
          <PrimaryButton
            style={{ width: 350 }}
            onClick={onComplete}
            type="primary"
          >
            Перейти на главную страницу
          </PrimaryButton>
        </Space>
      </Row>
    </CustomModal>
  );
};
