import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IActsInvoicesForPayments } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import {
  getIsShowPreviewActModalSelector,
  getSelectedActsInvoicesForPaymentSelector,
} from '@store/selectors';
import { actsInvoicesForPaymentsActions } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (value: boolean) => void;
  setSelectedActsInvoicesForPayment: (value: IActsInvoicesForPayments) => void;
  selectedActsInvoicesForPayment: IActsInvoicesForPayments | null;
};

export const usePreviewActModal = (): ReturnType => {
  const dispatch = useDispatch();

  const selectedActsInvoicesForPayment = useSelector(
    getSelectedActsInvoicesForPaymentSelector,
  );
  const isModalShown = useSelector(getIsShowPreviewActModalSelector);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(actsInvoicesForPaymentsActions.setPreviewActModalAction(value));
  }, []);

  const setSelectedActsInvoicesForPayment = useCallback(
    (value: IActsInvoicesForPayments) => {
      dispatch(
        actsInvoicesForPaymentsActions.setSelectedActsInvoicesForPaymentAction(
          value,
        ),
      );
    },
    [],
  );

  return {
    isModalShown,
    setShowModal,
    selectedActsInvoicesForPayment,
    setSelectedActsInvoicesForPayment,
  };
};
