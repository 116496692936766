import styles from './GeoPortalMapAttributes.module.scss';
import { Divider } from '@features/ui-kit';
import { Button, Typography } from 'antd';
import {
  AttributesList
} from '@features/geoPortal/new/geoPortalMap/geoPortalMapAttributes/attributesList/AttributesList';
import { ILayerDto } from '@models/geoPortal/interfaces/GeoPortal';
import { CloseOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';

const { Paragraph } = Typography;

type GeoPortalMapAttributesProps = {
  layer: ILayerDto | null;
}

export const GeoPortalMapAttributes: React.FC<GeoPortalMapAttributesProps> = ({ layer }) => {

  return (
    <div className={styles.content}>
      <div className={styles.layerName}>
        <Paragraph
          ellipsis={{
            rows: 1,
          }}
          style={{
            marginBottom: '0',
          }}
        >
          {layer?.title}
        </Paragraph>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <AttributesList layer={layer} />
    </div>
  );
}
