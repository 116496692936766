import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { useWaterRegistryFilters } from '@features/waterUser/waterUserTable/useWaterRegistryFilters';
import { IActsInvoicesForPaymentsFilters } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';

export const AssociationTableFilters: FC = () => {
  const { filters, setWaterRegistryFilters } = useWaterRegistryFilters();
  const { t } = useTranslation();

  const [filtersState, setFiltersState] = useState<
    Partial<IWaterRegistryFilters>
  >({});

  const { data: economicActivity = [], isLoading: economicActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.AssociationTypes,
    });

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const onRegionChange = (v: any) => {
    const updatedFilters = {
      ...filtersState,
      district: null,
      regionId: v,
    } as IWaterRegistryFilters;
    setFiltersState(updatedFilters);
  };

  const onDistrictChange = (v: any) => {
    const updatedFilters = {
      ...filtersState,
      district: v,
    } as IWaterRegistryFilters;
    setFiltersState(updatedFilters);
  };

  const onFilterChange = (
    filterKey: keyof IWaterRegistryFilters,
    v: string,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    } as IWaterRegistryFilters;
    setFiltersState(updatedFilters);
  };

  const onFarmTypeChange = (
    filterKey: keyof IWaterRegistryFilters,
    v: string,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    } as IWaterRegistryFilters;
    setFiltersState(updatedFilters);
  };

  useEffect(() => {
    setFiltersState(filters as IWaterRegistryFilters);
  }, [filters]);

  const onFilterButtonClick = () => {
    setWaterRegistryFilters(filtersState as IWaterRegistryFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      regNumber: null,
      waterUserName: null,
      districtId: null,
      regionId: null,
      typeAssociationId: null,
      page: 1,
      size: 10,
    });
    setWaterRegistryFilters({
      regNumber: null,
      waterUserName: null,
      districtId: null,
      regionId: null,
      typeAssociationId: null,
      page: 1,
      size: 10,
    });
  };

  return (
    <Row gutter={16}>
      <Col span={4}>
        <TooltipedInput
          size="middle"
          prefix
          onChange={({ target }) => onFilterChange('regNumber', target.value)}
          placeholder="Регистрационный номер"
          defaultValue={filtersState.regNumber as string}
          value={filtersState?.regNumber || ''}
        />
      </Col>
      <Col span={4}>
        <TooltipedInput
          size="middle"
          prefix
          onChange={({ target }) =>
            onFilterChange('waterUserName', target.value)
          }
          placeholder="Название"
          defaultValue={filtersState.waterUserName as string}
          value={filtersState?.waterUserName || ''}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="middle"
          value={
            regions.find((item) => item.id === toInteger(filtersState.regionId))
              ?.label
          }
          onChange={(v: string) => onRegionChange(v)}
          allowClear
          placeholder="Область"
          options={regions}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="middle"
          allowClear
          placeholder="Район"
          options={districts}
          disabled={!filtersState.regionId}
          value={
            districts.find(
              (item) => item.id === toInteger(filtersState.districtId),
            )?.label
          }
          onChange={(v: string) => onDistrictChange(v)}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="middle"
          allowClear
          value={
            economicActivity.find(
              (item) => item.id === toInteger(filtersState.typeAssociationId),
            )?.label
          }
          onChange={(v: string) => onFarmTypeChange('typeAssociationId', v)}
          placeholder="Тип ассоциации"
          loading={economicActivityIsLoading}
          options={economicActivity}
          dropdownMatchSelectWidth={false}
        />
      </Col>
      <Col span={2}>
        <Row gutter={8} wrap={false}>
          <Col>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
          </Col>
          <Col>
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
