import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput, TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCreateCommonReferenceMutation, useGetAllCommonReferencesListQuery,
  useUpdateCommonReferenceMutation,
} from '@store/gisproApi';
import { getReferenceBookIdSelector } from '@store/selectors';
import { Col, Form, notification, Row, Space, Typography } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';

interface IReferenceBookCreateModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const ReferenceBookCreateModal: FC<IReferenceBookCreateModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const item = useSelector(getReferenceBookIdSelector);
  const [createCommonReference, { isError }] =
    useCreateCommonReferenceMutation();
  const [updateCommonReference, { isError: isErrorUpdate }] =
    useUpdateCommonReferenceMutation();

  const { data: parentOptions, isFetching } = useGetAllCommonReferencesListQuery(
    item?.parent
      ? {
        typeCode: item?.parent,
      }
      : skipToken,
  );

  const onFinish = (values: any) => {
    if (initialValues) {
      updateCommonReference({ id: initialValues.id, body: values })
        .unwrap()
        .then(() => {
          setShowModal(false);
          notification.success({ message: 'Успешно создано' });
        })
        .catch(({ data }) => {
          notification.error({ message: data?.message ?? 'Произошла ошибка' });
        });
      return;
    }
    createCommonReference({
      typeCode: item?.code,
      body: values,
    })
      .unwrap()
      .then(() => {
        setShowModal(false);
        notification.success({ message: 'Успешно создано' });
      })
      .catch(({ data }) => {
        notification.error({ message: data?.message ?? 'Произошла ошибка' });
      });
  };

  return (
    <CustomModal
      open={isModalShow}
      title="Создать"
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
              <TooltipedInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Код" name="code" rules={[{ required: true }]}>
              <TooltipedInput type="number" />
            </Form.Item>
          </Col>
          {item?.parent ? <Col span={24}>
            <Form.Item label="Родитель" name="parentId" rules={[{ required: true }]}>
              <TooltipedSelect
                options={parentOptions?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </Form.Item>
          </Col> : null}
          {/* <Col span={24}>
            <Form.Item label="ParentId" name="parentId">
              <TooltipedInput type="number" />
            </Form.Item>
          </Col>
         <Col span={24}>
            <Form.Item label="Books" name="books">
              <TooltipedInput />
            </Form.Item>
          </Col> */}
        </Row>

        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
