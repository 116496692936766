import { FC, useMemo } from 'react';

import { TableCustom } from '@features/ui-kit';

import { useRelatedObjectsColumns } from './useRelatedObjectsColumns';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { SystemObject } from '@models/systemObject/systemObject';

interface IRelatedObjectsTableProps {
  data?: any[];
}

export const RelatedObjectsTable: React.FC<IRelatedObjectsTableProps> = ({
  data = [],
}) => {
  const navigate = useNavigate();
  const { columns } = useRelatedObjectsColumns();

  const onHandleEdit = (record: SystemObject) => {
    navigate(generatePath(Routes.WaterObjectCard, { id: String(record.id) }));
  }

  const dataSource = useMemo(() => data.map((item, index) => ({...item, index: index + 1 })), [data]);

  return (
    <TableCustom
      bordered
      dataSource={dataSource}
      columns={columns}
      actionProps={{
        onHandleEdit,
      }}
    />
  );
};
