import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const FolderIcon: React.FC<IconProps> = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3.46482C14 2.84705 14 2.53816 13.9515 2.28087C13.7378 1.14822 12.8518 0.262248 11.7191 0.048545C11.4618 7.45058e-09 11.1529 0 10.5352 0C10.2645 0 10.1292 0 9.9991 0.012166C9.43834 0.064603 8.90644 0.284928 8.47284 0.644357C8.37228 0.727727 8.27659 0.823431 8.08521 1.01482L7.7 1.4C7.12894 1.97105 6.84341 2.25657 6.50153 2.4468C6.31372 2.5513 6.11443 2.63382 5.90772 2.69275C5.53147 2.8 5.12771 2.8 4.32012 2.8H4.05853C2.21585 2.8 1.29458 2.8 0.69566 3.33862C0.64057 3.38817 0.58814 3.4406 0.538651 3.49568C9.53674e-07 4.09454 0 5.01588 0 6.85853V8.4C0 11.0398 9.53674e-07 12.3598 0.820121 13.1799C1.64017 14 2.96016 14 5.6 14H8.4C11.0399 14 12.3598 14 13.1799 13.1799C14 12.3598 14 11.0398 14 8.4V3.46482Z"
        fill="black" />
      <path d="M2.97518 6.46338H6.32935" stroke="white" strokeLinecap="round" />
      <path
        d="M9 1H4.45C4.12492 1 3.96238 1 3.82588 1.01467C2.88354 1.11594 2.14203 1.72127 2.01799 2.49053C2 2.60193 2 2.73462 2 3"
        stroke="black" />
    </svg>
  );
};
