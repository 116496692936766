import { linearObjectCategoriesCodes } from '@constants/linearObjectCategoriesCodes';
import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';

export const getIsLinearObject = (
  objectCategories: Array<ObjectCategory>,
  objectCategoryId: number,
) => {
  if (objectCategoryId) {
    const selectedObjectCategory = objectCategories.find(
      (item) => item.categoryId === objectCategoryId,
    );
    if (selectedObjectCategory) {
      return linearObjectCategoriesCodes.includes(
        String(selectedObjectCategory?.code),
      );
    }
  }
  return true;
};
