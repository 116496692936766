import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const SchemeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2345_50929)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59393 0H16.4065C17.2694 0 17.969 0.686491 17.969 1.53332V6.13329C17.969 6.98012 17.2694 7.66661 16.4065 7.66661H8.59393C7.73098 7.66661 7.03143 6.98012 7.03143 6.13329V1.53332C7.03143 0.686491 7.73098 0 8.59393 0ZM18.9065 12.1133H6.09392V14.4746H4.84392V10.8866H11.8752V8.4333H13.1252V10.8866H20.1565V14.4746H18.9065V12.1133ZM1.56251 15.3334H9.37503C10.238 15.3334 10.9375 16.0199 10.9375 16.8667V21.4667C10.9375 22.3135 10.238 23 9.37503 23H1.56251C0.699557 23 0 22.3135 0 21.4667V16.8667C0 16.0199 0.699557 15.3334 1.56251 15.3334ZM23.4375 15.3334H15.625C14.762 15.3334 14.0625 16.0199 14.0625 16.8667V21.4667C14.0625 22.3135 14.762 23 15.625 23H23.4375C24.3004 23 25 22.3135 25 21.4667V16.8667C25 16.0199 24.3004 15.3334 23.4375 15.3334Z"
          fill="#4B60D8"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2345_50929">
          <rect width="25" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
