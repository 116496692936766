import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { DocumentTypes } from '@enums/documentTypes';
import { Routes } from '@enums/routes';
import { DocumentTable } from '@features/manageEvents/tables';
import { useAttachDocumentModal } from '@features/manageEvents/useAttachDocumentModal';
import { useCreateTemplateModal } from '@features/manageEvents/useCreateTemplateModal';
import {
  CustomCard,
  DatesFormList,
  DefaultButton,
  PrimaryButton,
  UploadButtonSmall,
} from '@features/ui-kit';
import { TablePreviewV2 } from '@features/ui-kit-v2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetRegularEventByIdQuery } from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { getRegularEventBlockSelector } from '@store/selectors';
import { setRegularEventBlockAction } from '@store/slices';
import { isEmpty, isInteger } from '@utils/utils';

import { useOperationalBlocksColumns } from '../../useOperationalBlocksColumns';
import styles from './RightBlock.module.scss';

export const RightBlock: React.FC = () => {
  const navigate = useNavigate();
  const form = useFormInstance();
  const { columns } = useOperationalBlocksColumns();
  const { id } = useParams();
  const { data: regularEvent } = useGetRegularEventByIdQuery(
    id && isInteger(parseInt(id, 10)) ? parseInt(id, 10) : skipToken,
  );
  const regularEventBlock = useSelector(getRegularEventBlockSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { setShow } = useCreateTemplateModal();
  const { setShow: setAttachmentDocumentShow, setDocType } =
    useAttachDocumentModal();

  const createTemplate = () => {
    const values = form.getFieldsValue();
    dispatch(setRegularEventBlockAction(values));
    setShow(true);
  };

  const isSaveDisabled = useMemo(
    () => !!regularEvent?.id || isEmpty(regularEventBlock.executor),
    [regularEventBlock, regularEvent],
  );

  const isEditingDisabled = useMemo(
    () => !regularEventBlock.title || !!regularEvent?.operationalBlocks.length,
    [regularEventBlock],
  );

  const onAddAttachment = useCallback((docType: string) => {
    setDocType(docType);
    setAttachmentDocumentShow(true);
  }, []);

  const watchOperationalItem = (idOperation: number | any) => {
    navigate(generatePath(Routes.OperationalBlock, { id: idOperation.id }));
  };

  return (
    <CustomCard overlay={isEditingDisabled}>
      <div className={styles.rightBlock}>
        <Row justify="start" gutter={16}>
          <Col flex={11}>
            <Typography.Title level={4} className={styles.title}>
              Отчет по подготовительным работам
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={1}>
            <Row justify="end" className={styles.btnWrapper} gutter={8}>
              <Col>
                <DefaultButton onClick={createTemplate}>
                  Создать шаблон
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton disabled={isSaveDisabled} htmlType="submit">
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Space direction="vertical" size={20} className={styles.deadlinesForm}>
          <Typography.Text className={styles.deadlinesFormTitle}>
            Установить сроки
          </Typography.Text>
          <DatesFormList />
        </Space>

        <div className={styles.tableWrapper}>
          <DocumentTable
            type="operational"
            attachments={regularEventBlock.attachments ?? null}
          />
        </div>

        <Form.Item name="attachments">
          <UploadButtonSmall
            onClick={() => onAddAttachment(DocumentTypes.CreateTask)}
            hasIcon
          >
            Прикрепить документ
          </UploadButtonSmall>
        </Form.Item>
      </div>
    </CustomCard>
  );
};
