import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Form } from 'antd';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

import 'dayjs/locale/ru';

export const EditDate: React.FC<TableEditorProps> = React.memo(
  ({ customColumn }) => {
    const { t } = useTranslation();

    return (
      <Form.Item
        name={customColumn.dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message:
              t('customComponents.table.cellEditor.enterValue', {
                value: customColumn.title,
              }) || '',
          },
        ]}
        getValueFromEvent={(value) => {
          return dayjs(value).toISOString();
        }}
        getValueProps={(value) => {
          const valueDayjs = dayjs(value);
          return {
            value: valueDayjs,
          };
        }}
      >
        <DatePicker showTime />
      </Form.Item>
    );
  },
);
