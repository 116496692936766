import { Button, ButtonProps } from 'antd';

import { Links } from '@enums/links';

import styles from './buttons.module.scss';

type CreateButtonPropTypes = ButtonProps & {
  iconType?: 'create' | 'print';
  background?: 'blue' | 'yellow' | 'brown' | 'green' | 'cyan' | 'turquoise';
};

export const CreateButton: React.FC<CreateButtonPropTypes> = ({
  children,
  iconType = 'create',
  background = 'cyan',
  ...rest
}) => {
  return (
    <Button
      {...rest}
      className={`${styles.createBtn} ${styles[background]}`}
      icon={
        <img
          src={iconType === 'create' ? Links.WhitePlus : Links.PrintBig}
          alt=""
        />
      }
    >
      {children}
    </Button>
  );
};
