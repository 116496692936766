import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Divider, Row } from 'antd';

import {
  InformationalBlock,
  PrimaryButton,
  TooltipedDatePicker,
} from '@features/ui-kit';
import { CustomCard } from '@features/ui-kit-v2';
import { PrintIcon } from '@icons/PrintIcon';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetAssociationDataContractQuery,
  useGetIndividualDataContractQuery,
  useGetLegalDataContractQuery,
} from '@store/gisproApi';

import styles from './AnnualApplicationAgreementContractDetails.module.scss';

export const AnnualApplicationAgreementContractDetails: React.FC<any> = ({
  annulContract,
}: any) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();

  const { data: legal, error: errorLegal } = useGetLegalDataContractQuery(
    WaterUserEnum.LEGAL === annulContract?.waterUser.type ? { id } : skipToken,
  );

  const { data: individual, error: errorIndividual } =
    useGetIndividualDataContractQuery(
      WaterUserEnum.INDIVIDUAL === annulContract?.waterUser.type
        ? { id }
        : skipToken,
    );

  const { data: association, error: errorAssociation } =
    useGetAssociationDataContractQuery(
      WaterUserEnum.ASSOCIATION === annulContract?.waterUser.type
        ? { id }
        : skipToken,
    );

  let html = '';
  switch (annulContract?.waterUser.type) {
    case WaterUserEnum.ASSOCIATION:
      html = association;
      break;
    case WaterUserEnum.INDIVIDUAL:
      html = individual;
      break;
    case WaterUserEnum.LEGAL:
      html = legal;
      break;
    default:
      html = individual;
      break;
  }

  const print = () => {
    const mywindow = window.open('', 'PRINT');
    if (mywindow) {
      mywindow.document.write(
        html ||
          (errorAssociation as { data: string })?.data ||
          (errorIndividual as { data: string })?.data ||
          (errorLegal as { data: string })?.data,
      );
      mywindow.document.write(
        '<style>page { font-size: 12pt; } page p { font-size: 12pt !important; }</style>',
      );
      mywindow.document.close(); // necessary for IE >= 10
      setTimeout(() => {
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
      }, 1000);
    }
    return true;
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <InformationalBlock
          title={t(
            'createIndividualWaterRegistry.thirdStep.contractDetails.title',
          )}
        >
          <CustomCard
            title={
              <Row justify="space-between" align="middle">
                <Col span={23}>
                  <Divider className={styles.divider} />
                </Col>
                <Col span={1}>
                  <PrimaryButton icon={<PrintIcon />} onClick={() => print()} />
                  {/* <Row justify="space-between" gutter={8}> */}
                  {/*  <Col span={7}> */}
                  {/*    <TooltipedDatePicker placeholder="Дата заключения договора" /> */}
                  {/*  </Col> */}
                  {/*  <Col span={7}> */}
                  {/*    <TooltipedDatePicker placeholder="Дата заключения договора" /> */}
                  {/*  </Col> */}
                  {/*  <Col span={7}> */}
                  {/*    <TooltipedDatePicker placeholder="Дата заключения договора" /> */}
                  {/*  </Col> */}
                  {/*  <Col> */}
                  {/*    <PrimaryButton */}
                  {/*      icon={<PrintIcon />} */}
                  {/*      onClick={() => print()} */}
                  {/*    /> */}
                  {/*  </Col> */}
                  {/* </Row> */}
                </Col>
              </Row>
            }
          >
            <Col span={24} style={{ height: 600 }}>
              <Row justify="center" className={styles.agreementWrapper}>
                <Col
                  span={17}
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    padding: '0 20px',
                  }}
                >
                  <div
                    className="printable"
                    dangerouslySetInnerHTML={{
                      __html:
                        html ||
                        (errorAssociation as { data: string })?.data ||
                        (errorIndividual as { data: string })?.data ||
                        (errorLegal as { data: string })?.data,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </CustomCard>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
