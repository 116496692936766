export const SectionQueryKey = 'section';
export const QuarterQueryKey = 'quarter';
export const TypeQueryKey = 'type';
export const TabQueryKey = 'tab';
export const YearQueryKey = 'key';
export const DecadeQueryKey = 'decade';
export const DecadeDayQueryKey = 'decadeDays';
export const MonthQueryKey = 'month';
export const PageQueryKey = 'page';
export const HydroFacilityQueryKey = 'hydroFacilityId';
export const IrrigationSystemQueryKey = 'irrigationSystemId';
export const ChannelQueryKey = 'channelId';
export const OutletQueryKey = 'outletId';
export const OutletNameQueryKey = 'outletName';
export const NameQueryKey = 'name';
