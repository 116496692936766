import { generatePath, Link } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { StatusInfo } from '@features/manageEvents';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';

import styles from './OperationalData.module.scss';

interface IOperationalDataProps {
  operational: IOperationalBlock;
}
export const OperationalDataItem: React.FC<IOperationalDataProps> = ({
  operational,
}) => {
  const { id, title, factBudget, planBudget, status } = operational;
  return (
    <Link
      className={styles.link}
      to={generatePath(Routes.OperationalBlock, { id: String(id) })}
    >
      <Row justify="space-between" gutter={8} className={styles.item}>
        <Col span={15}>
          <Typography.Paragraph ellipsis className={styles.title}>
            {title}
          </Typography.Paragraph>
          <Typography.Text className={styles.price}>
            {factBudget || planBudget || '-'} c
          </Typography.Text>
        </Col>
        <Col span={9} className={styles.status}>
          <StatusInfo status={status} />
        </Col>
      </Row>
    </Link>
  );
};
