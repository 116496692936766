import { ElementColor } from "./ColorBlock";
import headerStyles from '../../../features/header/Header.module.scss';

export const WaterAccountingPath = (search: string | null) => {
  switch (search) {
    case 'economicWaterAccounting':
      return ElementColor.default;
    case 'balanceSheetAccounting':
      return ElementColor.violet;
    case 'waterLogAccounting':
      return ElementColor.aqua;
    default:
      return ElementColor.default;
  }
};

export const ManageEventsPath = (pathname: string | null): ElementColor => {
  if (!pathname) return ElementColor.default;

  switch (true) {
    case pathname.includes('/manage-events/building/'):
      return ElementColor.aqua;
    case pathname.includes('/manage-events/operational/'):
      return ElementColor.default;
    case pathname.includes('/manage-events/other-tasks/'):
      return ElementColor.default;
    default:
      return ElementColor.default;
  }
};

export const HeaderPath = (tabParam: string | null) => {
  if (location.pathname.includes('/manage-events/building/')) {
    return headerStyles.waterPath;
  }

  switch (tabParam) {
    case 'economicWaterAccounting':
      return headerStyles.header;
    case 'balanceSheetAccounting':
      return headerStyles.balancePath;
    case 'waterLogAccounting':
      return headerStyles.waterPath;
    default:
      return headerStyles.header;
  }
};