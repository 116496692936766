import { notification, Tag } from 'antd';

import { Links } from '@enums/links';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

export function renderGeo<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value: string = record[customColumn.dataIndex];
  return (
    <div style={{ fontSize: 20, fontWeight: 600 }}>
      {/* <Link to="#"> */}
      <Tag
        icon={<img src={Links.Pin} alt="" />}
        color="#3b5999"
        className="tag"
        onClick={() => {
          notification.warning({
            message: 'Информация о геолокации не указана',
          });
        }}
      >
        ГИС
      </Tag>
      {/* </Link> */}
    </div>
  );
}
