import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowAddHydraulicSectionSelector } from '@store/selectors/hydraulicSection';
import { setShowAddHydraulicSectionModalAction } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export function useAddHydraulicSectionModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowAddHydraulicSectionSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowAddHydraulicSectionModalAction(showModal));
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
}
