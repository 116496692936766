import { useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { months, monthsTranslate } from '@constants/month';
import { MonthIndexies } from '@enums/month';
import { IReservoirDataByMonthFilters } from '@models/reservoir/interfaces/reservoir';
import { useGetReservoirByIdQuery } from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';

import { useReservoirMonitoringFilters } from '../useReservoirMonitoringFilters';
import { useParams } from 'react-router-dom';
import {
  PrimaryButton,
  Segmented,
  TooltipedInput,
  YearPicker,
} from '@features/ui-kit';

import styles from './ReservoirMonitoringFilters.module.scss';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export const ReservoirMonitoringFilters = () => {
  const [filtersState, setFiltersState] = useState<
    Partial<IReservoirDataByMonthFilters>
  >({});

  const { filters, setReservoirMonitoringFilters } =
    useReservoirMonitoringFilters();
  const { id: reservoirId } = useParams<{ id: any }>();
  const { data: reservoir } = useGetReservoirByIdQuery(
    reservoirId ? { reservoirId } : skipToken,
  );
  const [form] = Form.useForm();
  const onFilterChange = (
    filterKey: keyof IReservoirDataByMonthFilters,
    v: string,
  ) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onFilterButtonClick = () => {
    setReservoirMonitoringFilters(filtersState as IReservoirDataByMonthFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      year: null,
    });
    setReservoirMonitoringFilters({
      year: null,
    });
  };

  const onMonthChange = (v: SegmentedValue) => {
    const engMonth = monthsTranslate[v].toLowerCase();
    onFilterChange('month', String(MonthIndexies[engMonth]));
    setReservoirMonitoringFilters({
      ...filters,
      month: String(MonthIndexies[engMonth]) as any,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  useEffect(() => {
    form.setFieldsValue({
      name: reservoir?.name || '',
      maxVolume: reservoir?.maxVolume || 0 + ' млн.м3',
      minVolume: reservoir?.minVolume || 0 + ' млн.м3',
      usefulVolume: reservoir?.usefulVolume || 0 + ' млн.м3',
    });
  }, [form, reservoir]);
  return (
    <Col>
      <Row>
        <Col span={16}>
          <Form layout="vertical" form={form} autoComplete="off">
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="Наименование:" name="name">
                  <TooltipedInput readOnly />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Макс. объем:" name="maxVolume">
                  <TooltipedInput readOnly />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Мин. объем:" name="minVolume">
                  <TooltipedInput readOnly />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Полезный объем:" name="usefulVolume">
                  <TooltipedInput readOnly />
                </Form.Item>
              </Col>
              <Col span={4} className={styles.col}>
                <YearPicker
                  value={filtersState.year ? dayjs(filtersState.year) : null}
                  onChange={(v: Dayjs | null) =>
                    v && onFilterChange('year', v.format('YYYY'))
                  }
                  placeholder="Год"
                  disabledDate={(current) =>
                    current && current > dayjs().endOf('year')
                  }
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={3} className={styles.col}>
          <Row gutter={10}>
            <Col>
              <PrimaryButton
                icon={<FilterIcon />}
                onClick={onFilterButtonClick}
              />
            </Col>
            <Col>
              <PrimaryButton
                icon={<ResetFilterIcon />}
                onClick={onResetFilterButtonClick}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col>
        <Segmented options={months} onChange={onMonthChange} />
      </Col>
    </Col>
  );
};
