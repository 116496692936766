import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useEmployeesTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      xtype: CustomTableColumnType.String,
      align: 'center',
      width: 100,
    },
    {
      dataIndex: 'name',
      title: 'Наименование',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'region.title',
      title: 'Область',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'district.title',
      title: 'Район',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText],
      key: 'actions',
      align: 'center',
      width: 100,
    },
  ];
  return { columns };
};
