import React, { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { WaterObjectStepperQueryTypes } from '@models/waterObject/enums/waterObjectTypesEnum';

import { ChooseGtsConstructor } from './chooseGtsConstructor';
import { WaterObjectConstructor } from './waterObjectConstructor';
import styles from './WaterObjectStepper.module.scss';
import { CustomSteps } from '@features/ui-kit/customSteps';
import { Breadcrumb, Divider } from '@features/ui-kit';

const steps = [
  {
    value: WaterObjectStepperQueryTypes.constructor,
    title: 'Конструктор',
  },
  {
    value: WaterObjectStepperQueryTypes.gtsConstructor,
    title: 'Определить ГТС',
  },
];

export const WaterObjectStepper: FC = () => {
  const [searchParams] = useSearchParams();
  const step = useMemo(() => searchParams.get(SectionQueryKey), [searchParams]);

  let Component;

  switch (step) {
    case WaterObjectStepperQueryTypes.constructor:
      Component = WaterObjectConstructor;
      break;
    case WaterObjectStepperQueryTypes.gtsConstructor:
      Component = ChooseGtsConstructor;
      break;
    default:
      Component = WaterObjectConstructor;
      break;
  }

  return (
    <>
      <Breadcrumb
        returnButton={true}
        items={[
          {
            title: 'Конструктор',
          },
        ]}
      />
      <div className={styles.progress}>
        <CustomSteps
          queryParams={{
            queryType: WaterObjectStepperQueryTypes,
            queryKey: SectionQueryKey,
          }}
          steps={steps}
        />
      </div>
      <Divider />
      <Component />
    </>
  );
};
