import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionCloseIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57854 1.0072C5.96906 0.616679 6.60223 0.616679 6.99275 1.0072C7.38328 1.39773 7.38328 2.03089 6.99275 2.42142L5.41421 3.99995L6.99289 5.57863C7.38342 5.96916 7.38342 6.60232 6.99289 6.99285C6.60237 7.38337 5.9692 7.38337 5.57868 6.99285L4 5.41417L2.42132 6.99285C2.0308 7.38337 1.39763 7.38337 1.00711 6.99285C0.616585 6.60232 0.616585 5.96916 1.00711 5.57863L2.58579 3.99995L1.00725 2.42142C0.616724 2.03089 0.616724 1.39773 1.00725 1.0072C1.39777 0.616679 2.03094 0.616679 2.42146 1.0072L4 2.58574L5.57854 1.0072Z"
        fill="#fff"
      />
    </svg>
  );
};
