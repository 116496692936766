import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Col, Divider, Row } from 'antd';

import { Routes } from '@enums/routes';
import { ActualStructureTable } from '@features/annualApplication/actualStructure/actualStructureTable/ActualStructureTable';
import {
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';

import styles from './ActualStructure.module.scss';
import {
  ActualStructureCreateModal
} from '@features/annualApplication/actualStructure/actualStructureCreateModal/ActualStructureCreateModal';
import {
  useActualStructureCreateModal
} from '@features/annualApplication/actualStructure/actualStructureCreateModal/useActualStructureCreateModal';

export const ActualStructure: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setShowModal, setSelectedActualStructure } = useActualStructureCreateModal();

  const onBackButtonClick = () => {
    navigate(Routes.AnnualApplicationTable);
  };

  const onAddActualStructure = () => {
    setSelectedActualStructure(null);
    setShowModal(true);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="end" gutter={20}>
            <Col>
              <PrimaryButton
                size="large"
                onClick={onAddActualStructure}
                className={styles.createButton}
              >
                Создать сев
              </PrimaryButton>
            </Col>
            <Col span={24}>
              <Divider className={styles.divider} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <InformationalBlock
            title={t('annualApplication.table.actualStructure')}
          >
            <ActualStructureTable />
          </InformationalBlock>
        </Col>
        <Col className={styles.buttonsBlock} span={24}>
          <Row justify="end" gutter={16}>
            <Col>
              <DefaultButton
                size="large"
                className={styles.buttons}
                onClick={onBackButtonClick}
              >
                Назад
              </DefaultButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <ActualStructureCreateModal />
    </>
  );
};
