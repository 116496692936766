import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Form, Row, Space } from 'antd';

import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
} from '@store/gisproApi';
import {
  getSelectedWaterObjectSelector,
  getWaterObjectsSelector,
} from '@store/selectors/mainConduit';
import { extractObjectTypes, getIsLinearObject } from '@utils/objectType';
import {
  longPicketInputValidator,
  shortPicketInputValidator,
} from '@utils/validation/picketInputValidator';
import dayjs from 'dayjs';
import { ObjectGroupCodesEnum } from '@enums/objectGroupCodes';
import {
  PicketInput,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
  CustomCard,
} from '@features/ui-kit';

type TotalInformationPropTypes = {
  isMainConduit: boolean;
};

export const TotalInformation: React.FC<TotalInformationPropTypes> = ({
  isMainConduit = false,
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const waterObjects = useSelector(getWaterObjectsSelector);
  const selectedWaterObject = useSelector(getSelectedWaterObjectSelector);
  const objectGroupId = Form.useWatch('objectGroupId', form);
  const objectCategoryId = Form.useWatch('objectCategoryId', form);

  const { data: groups, isLoading: groupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      objectGroupId
        ? {
            objectGroupId,
          }
        : skipToken,
    );

  const gvsGroupId = groups?.find(
    (item) => item.code === parseInt(ObjectGroupCodesEnum.Gvs, 10),
  );
  const isGvsExists = waterObjects.some(
    (item) => item.objectGroupId === gvsGroupId?.id,
  );

  const objectGroups = useMemo(() => {
    return groups?.filter((item: IObjectGroup) => {
      if (isGvsExists) {
        return (
          item.code !== parseInt(ObjectGroupCodesEnum.MainConduit, 10) &&
          item.code !== parseInt(ObjectGroupCodesEnum.Gvs, 10) &&
          item.code !== parseInt(ObjectGroupCodesEnum.Reservoir, 10)
        );
      }
      if (isMainConduit) {
        return (
          item.code !== parseInt(ObjectGroupCodesEnum.MainConduit, 10) &&
          item.code !== parseInt(ObjectGroupCodesEnum.Reservoir, 10)
        );
      }
      return (
        item.code !== parseInt(ObjectGroupCodesEnum.MainConduit, 10) &&
        item.code !== parseInt(ObjectGroupCodesEnum.Gvs, 10) &&
        item.code !== parseInt(ObjectGroupCodesEnum.Reservoir, 10)
      );
    });
  }, [groups, isMainConduit, isGvsExists]);

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const isLinearObject = useMemo(
    () => getIsLinearObject(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const onGroupChange = () => {
    form.setFieldValue('objectCategoryId', null);
    form.setFieldValue('coordinates', '');
  };

  const onCategoryChange = () => {
    form.setFieldValue('typeCategory', null);
  };

  useEffect(() => {
    if (selectedWaterObject) {
      form.setFieldsValue({
        ...selectedWaterObject,
        dateStart: dayjs(selectedWaterObject.dateStart),
      });
    }
  }, [selectedWaterObject]);

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <CustomCard title="Ввод данных">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="objectName"
            label={t('irrigationForm.waterObjectForm.name')}
            rules={[{ required: true }]}
          >
            <TooltipedInput
              size="large"
              placeholder={t('irrigationForm.waterObjectForm.name')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dateStart"
            label={t('irrigationForm.waterObjectForm.date')}
            rules={[{ required: true }]}
          >
            <YearPicker
              size="large"
              style={{ width: '100%' }}
              allowClear
              placeholder={t('irrigationForm.waterObjectForm.date')}
              disabledDate={(current) =>
                current && current > dayjs().endOf('year')
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="objectGroupId"
            label={t('irrigationForm.waterObjectForm.group')}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              allowClear
              loading={groupsIsLoading}
              options={objectGroups}
              onChange={onGroupChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="objectCategoryId"
            label="Категория"
            required
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              allowClear
              showSearch
              disabled={!objectGroupId}
              loading={objectCategoriesIsLoading}
              options={objectCategories}
              onChange={onCategoryChange}
              filterOption={filterOption}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="objectCode" label="Код" rules={[{ required: true }]}>
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="typeCategory"
            label={t('irrigationForm.waterObjectForm.typeOfObject')}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              allowClear
              options={objectTypes}
              disabled={!objectCategoryId}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="coordinates"
            label={
              isLinearObject ? 'Протяженность в пикетах' : 'Пикетная координата'
            }
            rules={[
              { required: true },
              isLinearObject
                ? longPicketInputValidator
                : shortPicketInputValidator,
            ]}
            style={{ marginBottom: 10 }}
          >
            <PicketInput size="large" isLinear={isLinearObject} />
          </Form.Item>
        </Col>
        {isLinearObject && (
          <Col span={12}>
            <Form.Item
              name="lengthKm"
              label="Протяженность в КМ"
              rules={[{ required: true }]}
            >
              <TooltipedInput size="large" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </CustomCard>
  );
};
