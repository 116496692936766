import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import { useGetDependentOwnerListQuery } from '@store/gisproApi';

import { Col, Row, TablePaginationConfig, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useEmployeesTableColumns } from './useEmployeesTableColumns';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { saveUrl } from '@utils/returnUrl';

export const EmployeesTable: React.FC = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();

  const saveAction = saveUrl();

  const [filtersState, setFilterState] = useState<any>(params);

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetDependentOwnerListQuery(filtersState);

  const { columns } = useEmployeesTableColumns();

  const onHandleWatch = (val: any) => {
    navigate(
      generatePath(Routes.ProfileById, {
        id: String(val.id),
      }),
    );
    saveAction();
  };

  useEffect(() => {
    setHeaderTitle('Список владельцев');
  }, []);

  useEffect(() => {
    Object.entries(filtersState).forEach(([key, value]: any) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });

    setParams(params);
  }, [filtersState]);

  const onTableChange = useCallback((newPagination: TablePaginationConfig) => {
    if (newPagination.pageSize && newPagination.current) {
      setFilterState({
        ...filtersState,
        page: newPagination.current,
        size: newPagination.pageSize,
      });
    }
  }, []);

  return (
    <>
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>Список владельцев</Typography.Title>
              </Col>
            </Row>
          }
        >
          <TableCustom
            loading={isLoading || isFetching}
            dataSource={content}
            bordered
            columns={columns}
            onChange={onTableChange}
            totalCount={totalElements}
            customPagination={rest}
            size="small"
            actionProps={{
              onHandleWatch,
            }}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
