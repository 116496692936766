import React from 'react';

import { Col, Form, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { IBuildingBlock } from '@models/manageEvents/interfaces/buildingBlock';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';
import { generateFio } from '@utils/generateFio/generateFio';

import styles from './ExecutorInfo.module.scss';

type ExecutorInfoPropTypes = {
  data?: IOperationalBlock | IBuildingBlock;
};

export const ExecutorInfo: React.FC<ExecutorInfoPropTypes> = ({ data }) => {
  return (
    <Form layout="vertical" className={styles.container}>
      <CustomCard title="Исполнитель">
        <Row>
          <Col
            span={12}
            style={{
              borderRight: '1px solid rgba(0, 0, 0, 0.06)',
              padding: 16,
            }}
          >
            <Form.Item label="Ф.И.О.">
              <Typography.Text strong>
                {generateFio(data?.executor)}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col
            span={12}
            style={{
              padding: 16,
            }}
          >
            <Form.Item label="Организация:">
              <Typography.Text strong>
                {(data &&
                  data?.executor?.owner &&
                  data?.executor?.owner?.name) ||
                  '-'}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Row>
      </CustomCard>
    </Form>
  );
};
