import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Typography } from 'antd';

import { CustomCard, Divider, TooltipedInput } from '@features/ui-kit';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

import styles from './styles.module.scss';

export const LegalContactDetails: FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <CustomCard
          title={t('createLegalWaterRegistry.firstStep.contact.title')}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.contact.placeholders.phone',
                )}
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                  phoneNumberValidator,
                ]}
                normalize={mapPhoneNumberToLong}
              >
                <TooltipedInput
                  size="large"
                  placeholder="Введите Номер телефона"
                  addonBefore="+996"
                  onChange={changeCursorPhoneNumberInput}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.contact.placeholders.fax',
                )}
                name="fax"
              >
                <TooltipedInput placeholder="Введите Факс" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.contact.placeholders.email',
                )}
                name="email"
                rules={[{ required: true, type: 'email' }]}
              >
                <TooltipedInput placeholder="Введите Email" size="large" />
              </Form.Item>
            </Col>
          </Row>
        </CustomCard>
      </Col>
    </Row>
  );
};
