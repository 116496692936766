import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BlueEditIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67137 15.1164L13.4593 5.0451C13.8826 4.50199 14.0331 3.87408 13.892 3.23474C13.7697 2.65352 13.4123 2.10088 12.8762 1.68164L11.5688 0.643065C10.4307 -0.262115 9.01982 -0.166833 8.21092 0.871742L7.33619 2.00655C7.22332 2.14852 7.25154 2.35814 7.39262 2.47248C7.39262 2.47248 9.60298 4.24473 9.65 4.28284C9.8005 4.42576 9.91337 4.61633 9.94158 4.84501C9.98861 5.29283 9.67822 5.71207 9.21734 5.76924C9.00101 5.79783 8.79408 5.73113 8.64359 5.60726L6.32036 3.75879C6.2075 3.67399 6.03819 3.69209 5.94413 3.80643L0.422951 10.9526C0.0655324 11.4004 -0.0567425 11.9816 0.0655324 12.5438L0.770965 15.6024C0.808588 15.7643 0.949674 15.8787 1.11898 15.8787L4.22288 15.8406C4.78723 15.831 5.31395 15.5738 5.67137 15.1164ZM10.021 14.162H15.0822C15.576 14.162 15.9776 14.5688 15.9776 15.0691C15.9776 15.5702 15.576 15.9761 15.0822 15.9761H10.021C9.52716 15.9761 9.12553 15.5702 9.12553 15.0691C9.12553 14.5688 9.52716 14.162 10.021 14.162Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
