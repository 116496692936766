import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const TractorIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8146 11.5652H17.3224V10.9565V8.52174V4.86957C17.3224 4.50435 17.0732 4.26087 16.6994 4.26087C16.3255 4.26087 16.0763 4.50435 16.0763 4.86957V7.91304H14.581C14.3941 5.41739 13.2726 3.04348 11.4657 1.21739H12.9611C13.3349 1.21739 13.5841 0.973913 13.5841 0.608696C13.5841 0.243478 13.3349 0 12.9611 0H9.8458H8.28816C6.73053 0 5.48442 1.21739 5.48442 2.73913V6.69565H3.24143C2.05763 6.69565 1.12305 7.6087 1.12305 8.76522V9.55652C0.749221 10.0435 0.5 10.5913 0.5 11.2609C0.5 12.7826 1.74611 14 3.30374 14C4.54984 14 5.54673 13.2087 5.92056 12.1739H9.22274C9.34735 13.2087 10.2819 14 11.3411 14C12.4003 14 13.3349 13.2087 13.4595 12.1739H13.5841C13.8333 12.1739 14.0826 11.9913 14.2072 11.7478C14.2072 11.687 14.2072 11.6261 14.2695 11.5652H16.1386V12.1739C16.1386 12.5391 16.3879 12.7826 16.7617 12.7826H19.8769C20.2508 12.7826 20.5 12.5391 20.5 12.1739C20.5 11.8087 20.1885 11.5652 19.8146 11.5652ZM8.28816 1.21739H9.59657L10.095 1.64348C11.6526 2.98261 12.7118 4.74783 13.0857 6.69565H12.0265C10.8427 6.69565 9.72118 6.08696 9.03583 5.11304C8.97352 4.9913 8.7866 4.86957 8.59969 4.86957H6.73053V2.73913C6.73053 1.88696 7.41589 1.21739 8.28816 1.21739ZM3.30374 12.7826C2.43146 12.7826 1.74611 12.113 1.74611 11.2609C1.74611 10.4087 2.43146 9.73913 3.30374 9.73913C4.17601 9.73913 4.86137 10.4087 4.86137 11.2609C4.86137 12.113 4.17601 12.7826 3.30374 12.7826ZM11.4034 12.7826C10.905 12.7826 10.4688 12.3565 10.4688 11.8696C10.4688 11.3826 10.905 10.9565 11.4034 10.9565C11.9019 10.9565 12.338 11.3826 12.338 11.8696C12.338 12.3565 11.9019 12.7826 11.4034 12.7826ZM14.5187 10.3478C14.581 9.92174 14.6433 9.55652 14.6433 9.13043H16.0763V10.3478H14.5187Z"
        fill="white"
      />
    </svg>
  );
};
