import { useEffect, useMemo, useState } from 'react';

import { Col, Row, Space } from 'antd';

import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import {
  useFindAllObjectGroupsQuery,
  useGetObjectCategoriesQuery,
} from '@store/gisproApi';
import { extractObjectTypes } from '@utils/objectType';
import { useIrrigationSystemCardObjectTableFilters } from './useIrrigationSystemCardObjectTableFilters';
import { isEmpty } from '@utils/utils';
import { useSearchParams } from 'react-router-dom';

export const IrrigationSystemCardObjectTableControls: React.FC = () => {
  const [filterState, setFilterState] = useState<any>({});

  const { data: groups } = useFindAllObjectGroupsQuery();

  const { data: objectCategories = [] } = useGetObjectCategoriesQuery();
  const [params] = useSearchParams();
  const { filters, setIrrigationSystemCardObjectFilters } =
    useIrrigationSystemCardObjectTableFilters();

  const onFilterChange = (filterKey: keyof any, v: string | null) => {
    const updatedFilters = {
      ...filterState,
      [filterKey]: v,
      page: 1,
    };
    setFilterState(updatedFilters);
  };

  const objectTypes = useMemo(
    () =>
      filterState.objectCategoryId
        ? extractObjectTypes(objectCategories, filterState.objectCategoryId)
        : [],
    [objectCategories, filterState],
  );

  const objectCategoriesOptions = objectCategories.filter(
    (item) => item.objectGroup.id === filterState.objectGroupId,
  );

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  const onFilterButtonClick = () => {
    setIrrigationSystemCardObjectFilters(filterState as any);
  };

  const onResetFilterButtonClick = () => {
    setFilterState({
      ...filterState,
      code: null,
      objectName: null,
      objectGroupId: null,
      objectCategoryId: null,
      typeCategory: null,
      page: 1,
      size: 10,
    });
    setIrrigationSystemCardObjectFilters({
      code: null,
      objectName: null,
      objectGroupId: null,
      objectCategoryId: null,
      typeCategory: null,
      page: 1,
      size: 10,
    });
  };

  return (
    <Row gutter={16}>
      <Col>
        <TooltipedSearch
          type="number"
          placeholder="Код водного объекта"
          value={filterState?.code}
          onChange={({ target }) => onFilterChange('code', target.value)}
        />
      </Col>
      <Col>
        <TooltipedSearch
          placeholder="Наименование объекта"
          value={filterState?.objectName}
          onChange={({ target }) => onFilterChange('objectName', target.value)}
        />
      </Col>
      <Col>
        <TooltipedSelect
          style={{ width: 220 }}
          placeholder="Группа"
          options={groups}
          value={filterState?.objectGroupId}
          allowClear
          onChange={(v: string) => onFilterChange('objectGroupId', v)}
        />
      </Col>
      <Col>
        <TooltipedSelect
          style={{ width: 220 }}
          placeholder="Категория"
          disabled={!filterState.objectGroupId}
          options={objectCategoriesOptions}
          value={filterState?.objectCategoryId}
          allowClear
          onChange={(v: string) => onFilterChange('objectCategoryId', v)}
        />
      </Col>
      <Col>
        <TooltipedSelect
          style={{ width: 220 }}
          placeholder="Тип"
          disabled={!filterState.objectCategoryId}
          options={objectTypes}
          value={filterState?.typeCategory}
          allowClear
          onChange={(v: string) => onFilterChange('typeCategory', v)}
        />
      </Col>
      <Col>
        <Space size={8}>
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Space>
      </Col>
    </Row>
  );
};
