import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Divider, Row, Typography } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CustomGanttChart } from '@features/ui-kit/customGanttChart';
import styles from '@features/waterUser/waterUserTable/WaterUserTable.module.scss';
import { WorkEventManagementQueryTypes } from '@models/manageEvents/enums/manageEventsEnum';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { getCurrentGanttOptionsSelector } from '@store/selectors';
import { saveGanttOptions } from '@store/slices';
import { CustomSegmented } from '@features/ui-kit-v2';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';
import { getReturnUrl } from '@store/selectors/header';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

interface optionsItem {
  value: string;
  label: string;
}

export const ManageEventsGanttDiagramLayout: React.FC = () => {
  const dispatch = useDispatch();
  const ganttOptions = useSelector(getCurrentGanttOptionsSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const returnUrl = useSelector(getReturnUrl);

  const savedReturnUrl = localStorage.getItem('returnUrl');

  const options = [
    { value: 'all', label: 'Все' },
    { value: 'operationalBlock', label: 'Эксплуатационный блок ' },
    { value: 'extraTask', label: 'Прочие задачи ' },
    { value: 'constructionBlock', label: 'Строительные работы' },
  ];
  const typeQueryKey = searchParams.get(SectionQueryKey);

  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WORK_MANAGEMENT_OPERATIONAL_LIST_VIEW,
      ACCESS.WORK_MANAGEMENT_OTHERTASK_LIST_VIEW,
      ACCESS.WORK_MANAGEMENT_CONSTRUCTION_LIST_VIEW,
    ]);

    const allPermissions = tabPermissions.every((item) => item);
    const permissions = [allPermissions, ...tabPermissions];

    const validTabPermissions = arr.filter((_, idx) => permissions[idx]);

    return validTabPermissions;
  };

  const onWaterUserTypeChange = useCallback(
    (value: SegmentedValue) => {
      setSearchParams({
        [SectionQueryKey]: value,
      } as unknown as WorkEventManagementQueryTypes);
      dispatch(saveGanttOptions({ ...ganttOptions, type: value, page: 1 }));
    },
    [searchParams, ganttOptions],
  );

  useEffect(() => {
    dispatch(
      saveGanttOptions({
        ...ganttOptions,
        type: typeQueryKey || 'all',
      }),
    );
  }, []);

  useEffect(() => {
    setHeaderTitle('Диаграмма Ганта');
    if (returnUrl) {
      localStorage.setItem('returnUrl', returnUrl);
    }
  }, [returnUrl]);

  const gotoBack = () => {
    navigate(Routes.ManageEvents);
    localStorage.removeItem('returnUrl');
  };

  return (
    <div>
      <Row className={styles.controls}>
        {savedReturnUrl ? (
          <>
            <Row gutter={16}>
              <Col>
                <DefaultButton onClick={gotoBack} className="backBtn">
                  <LeftOutlined />
                  <Typography.Text>Назад</Typography.Text>
                </DefaultButton>
              </Col>
              <Col>
                <Breadcrumb
                  className="breadcrumb"
                  items={[
                    {
                      title: <HomeOutlined />,
                    },
                    {
                      title: t('headers.manage-events'),
                    },
                    {
                      title: 'Диаграмма Ганта',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider style={{ margin: '6px 0 8px' }} />
          </>
        ) : null}
        <CustomSegmented
          options={dynamicOptions(options)}
          colorType="primary"
          defaultValue={WaterUserTypes.all}
          onChange={onWaterUserTypeChange}
          queryParams={{
            queryKey: SectionQueryKey,
            queryType: WorkEventManagementQueryTypes,
          }}
        />
      </Row>
      <Row style={{ marginTop: '30px' }} justify="center">
        <Col span={24}>
          <CustomGanttChart />
        </Col>
      </Row>
    </div>
  );
};
