import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { SummaryCardColor } from '@enums/summaryCardColor';
import { CustomCard, DefaultButton, SummaryCard } from '@features/ui-kit';
import { useGetWaterManagementCountersQuery } from '@store/gisproApi';

import styles from './WaterSupplyAgreementCard.module.scss';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { formatNumber } from '@utils/formatNumber/formatNumber';
import { saveUrl } from '@utils/returnUrl';

export const WaterSupplyAgreementCard: React.FC = () => {
  const { data } = useGetWaterManagementCountersQuery({});
  const saveAction = saveUrl();
  const navigate = useNavigate();

  const hasPermission = useHasUserPermission(
    ACCESS.WATER_ACCOUNTING_WATER_LOG_VIEW,
  );

  const clickGoto = useCallback(() => {
    navigate(`${Routes.WaterAccounting}?tab=waterLogAccounting`);
    saveAction();
  }, []);

  const format = (arg?: number): string => {
    if (arg === undefined) return '';

    switch (true) {
      case arg >= 1000000000:
        return 'млрд.';
      case arg >= 1000000:
        return 'млн.';
      case arg >= 1000:
        return 'тыс.';
      default:
        return '';
    }
  };

  return (
    <CustomCard
      title={
        <Row align="middle" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Хоз. договорная водоподача
            </Typography.Title>
          </Col>
          <Col>
            <Row justify="end">
              {hasPermission ? (
                <Col>
                  <DefaultButton onClick={clickGoto}>Перейти</DefaultButton>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      }
    >
      <Row gutter={48} className={styles.content}>
        <Col span={8}>
          <p className={styles.label}>Подано</p>
          <Row gutter={24}>
            <Col span={12}>
              <SummaryCard
                size="medium"
                sum={formatNumber(data?.plan) || 0}
                sumSubtitle={`${format(data?.plan)}сом`}
                title="План"
                subtitle="Год"
                color={SummaryCardColor.Purple}
              />
            </Col>
            <Col span={12}>
              <SummaryCard
                size="medium"
                sumSubtitle={`${format(data?.fact)}сом`}
                title="Факт"
                subtitle="Год"
                sum={formatNumber(data?.fact) || 0}
                color={SummaryCardColor.Orange}
              />
            </Col>
          </Row>
        </Col>
        <Col span={3.5} className={styles.divider}>
          <p className={styles.label}>Факт посева</p>
          <Row gutter={24} className={styles.single}>
            <Col span={24}>
              <SummaryCard
                size="small"
                sumSubtitle={`${format(data?.plantingFact)}сом`}
                title=""
                subtitle="год"
                sum={formatNumber(data?.plantingFact) || 0}
                color={SummaryCardColor.Pink}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <p className={styles.label}>Договор</p>
          <Row className={styles.submitted}>
            <Col>
              <SummaryCard
                size="small"
                sum={formatNumber(data?.agreementPlan) || 0}
                sumSubtitle={`${format(data?.agreementPlan)}сом`}
                title="План"
                subtitle="Год"
                color={SummaryCardColor.Purple}
              />
            </Col>
            <Col>
              <SummaryCard
                size="small"
                sumSubtitle={`${format(data?.agreementSubmitted)}сом`}
                title="Подано"
                subtitle="Год"
                sum={formatNumber(data?.agreementSubmitted) || 0}
                color={SummaryCardColor.Orange}
              />
            </Col>
            <Col>
              <SummaryCard
                size="small"
                sumSubtitle={`${format(data?.agreementPaid)}сом`}
                title="Оплачено"
                subtitle="Год"
                sum={formatNumber(data?.agreementPaid) || 0}
                color={SummaryCardColor.Blue}
              />
            </Col>
            <Col>
              <SummaryCard
                size="small"
                sumSubtitle={`${format(data?.agreementDebt)}сом`}
                title="Задолженность"
                subtitle="Год"
                sum={formatNumber(data?.agreementDebt) || 0}
                color={SummaryCardColor.Red}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomCard>
  );
};
