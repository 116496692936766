import { toInteger } from '@utils/utils';
import { FC, useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { IBusinessPlanFilters } from '@models/business/interfaces/business';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetFacilitiesByFiltersQuery,
} from '@store/gisproApi';

import { useBusinessPlanTableFilters } from '../useBusinessPlanTableFilters';
import styles from './BusinessPlanTableFilters.module.scss';
import { useGetCurrentUser } from '@features/authentication';

export const BusinessPlanTableFilters: FC = () => {
  const [filtersState, setFiltersState] = useState<
    Partial<IBusinessPlanFilters>
  >({});

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { filters, setBusinessPlanFilters } = useBusinessPlanTableFilters();

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      facilityId: null,
      regionId: v,
    }));
  };

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const { data: facilities = [] } = useGetFacilitiesByFiltersQuery(
    filtersState.regionId && filtersState.districtId
      ? {
          region: parseInt(filtersState.regionId, 10),
          district: parseInt(filtersState.districtId, 10),
        }
      : skipToken,
  );

  const onFilterChange = (filterKey: keyof IBusinessPlanFilters, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onDistrictChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: v,
      facilityId: null,
    }));
  };

  const onFilterButtonClick = () => {
    setBusinessPlanFilters(filtersState as IBusinessPlanFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      facilityId: null,
    });
    setBusinessPlanFilters({
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      facilityId: null,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <div className={styles.selectWrapper}>
      <Row gutter={16}>
        <Col span={6}>
          <TooltipedSelectV2
            placeholder="Область"
            value={
              regions.find(
                (item) => item.id === toInteger(filtersState.regionId),
              )?.label
            }
            onChange={(v: string) => onRegionChange(v)}
            options={regions}
            bordered={false}
            allowClear
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={6}>
          <TooltipedSelectV2
            bordered={false}
            placeholder="Район"
            options={districts}
            disabled={isDisabledDistrict || !filtersState.regionId}
            value={
              districts.find(
                (item) => item.id === toInteger(filtersState.districtId),
              )?.label
            }
            onChange={(v: string) => onDistrictChange(v)}
            allowClear
          />
        </Col>
        <Col span={6}>
          <TooltipedSelectV2
            placeholder="Хозяйство"
            bordered={false}
            allowClear
            value={
              facilities.find(
                (item: any) =>
                  item.facilitiesId === toInteger(filtersState.facilityId),
              )?.name
            }
            options={facilities.map((item: any) => ({
              label: item.name,
              value: item.facilitiesId,
            }))}
            onChange={(v: string) => onFilterChange('facilityId', v)}
          />
        </Col>
        <Col span={2}>
          <Row gutter={10}>
            <Col>
              <FilterButtonV2 onClick={onFilterButtonClick} />
            </Col>
            <Col>
              <ResetFilterButtonV2 onClick={onResetFilterButtonClick} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
