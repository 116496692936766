import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BusinessPlanState = {
  businessPlanFilters: Partial<any>;
};

const initialState: BusinessPlanState = {
  businessPlanFilters: {},
};

const businessPlanSlice = createSlice({
  name: 'businessPlan',
  initialState,
  reducers: {
    changeBusinessPlanFilters: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        businessPlanFilters: action.payload,
      };
    },
  },
});
export const { changeBusinessPlanFilters } = businessPlanSlice.actions;
export default businessPlanSlice.reducer;
