import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@store/index';
import { getShowCreatePlantingStructureModalSelector } from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

export const usePlannedStructureModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isModalShow = useSelector(getShowCreatePlantingStructureModalSelector);

  const setShowModal = (isShow: boolean) => {
    dispatch(
      annualApplicationActions.setShowCreatePlantingStructureModal(isShow),
    );
  };

  return { isModalShow, setShowModal };
};
