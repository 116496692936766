import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/es/table';

import { EntityTypesEnum } from '@enums/entityTypes';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

export const useWaterUserSelectionTableColumns = () => {
  const { t } = useTranslation();

  const physical: ColumnsType<any> = [
    {
      title: 'Регистрационный номер водопользователя',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: `${t('annualApplication.columns.fio')} водопользователя`,
      dataIndex: 'fio',
      key: 'fio',
      width: 100,
    },
    {
      title: 'Адрес водопользователя',
      dataIndex: 'address',
      key: 'address',
      width: 100,
    },
  ];

  const legal: ColumnsType<any> = [
    {
      title: 'Регистрационный номер водопользователя',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Наименование водопользователя',
      dataIndex: 'fio',
      key: 'fio',
      width: 100,
    },
    {
      title: 'Адрес организации',
      dataIndex: 'address',
      key: 'address',
      width: 100,
    },
  ];

  return {
    [WaterUserEnum.LEGAL]: legal,
    [WaterUserEnum.INDIVIDUAL]: physical,
    [WaterUserEnum.ASSOCIATION]: physical,
  };
};
