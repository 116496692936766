import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BlueArchiveIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M1.66667 15.5C1.20833 15.5 0.815835 15.3367 0.489168 15.01C0.162501 14.6833 -0.000554141 14.2911 1.41483e-06 13.8333V3.41667C1.41483e-06 3.20834 0.0347236 3.02084 0.104168 2.85417C0.173613 2.6875 0.26389 2.52778 0.375001 2.37501L1.54167 0.958339C1.65278 0.805561 1.79167 0.690839 1.95833 0.614173C2.125 0.537506 2.30556 0.49945 2.5 0.500006H12.5C12.6944 0.500006 12.875 0.538062 13.0417 0.614173C13.2083 0.690284 13.3472 0.805006 13.4583 0.958339L14.625 2.37501C14.7361 2.52778 14.8264 2.6875 14.8958 2.85417C14.9653 3.02084 15 3.20834 15 3.41667V13.8333C15 14.2917 14.8367 14.6842 14.51 15.0108C14.1833 15.3375 13.7911 15.5006 13.3333 15.5H1.66667ZM2 3H13L12.2917 2.16667H2.70833L2 3ZM1.66667 4.66667V13.8333H13.3333V4.66667H1.66667ZM7.5 13L10.8333 9.66667L9.66667 8.5L8.33333 9.83333V6.33334H6.66667V9.83333L5.33333 8.5L4.16667 9.66667L7.5 13Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
