import { Row, Space } from 'antd';

import styles from './InfoBlock.module.scss';
import { CardDataItem } from '@features/geoPortal/geoPortalCard/dataOperationalBlock/cardData/CardDataItem';
import { PrimaryButton } from '@features/ui-kit';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { CardIcon } from '@icons/CardIcon';

type InfoBlockProps = {
  waterObject: any;
};

export const InfoBlock: React.FC<InfoBlockProps> = ({ waterObject }) => {
  const navigate = useNavigate();
  const toShowCard = () => {
    navigate(
      generatePath(Routes.WaterObjectCard, {
        id: String(waterObject?.id),
      }),
    );
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <Space size={8} direction="vertical">
        <CardDataItem
          title="Наименование объекта:"
          value={waterObject?.name ?? '-'}
        />
        <CardDataItem
          title="Группа:"
          value={waterObject?.objectGroupName ?? '-'}
        />
        <CardDataItem
          title="Категория:"
          value={waterObject?.objectCategoryName ?? '-'}
        />
        <CardDataItem title="Тип:" value={waterObject?.typeCategory ?? '-'} />
        <CardDataItem
          title="Пикетные координаты:"
          value={waterObject?.coordinates ?? '-'}
        />
        <CardDataItem title="Владелец:" value={waterObject?.ownerName ?? '-'} />
        <CardDataItem
          title="Год ввода в эксплуатацию:"
          value={waterObject?.dateStart ?? '-'}
        />
        <CardDataItem
          title="Балансовая стоимость:"
          value={waterObject?.carryingAmount || '-'}
        />
        <CardDataItem title="Область:" value={waterObject?.regionName ?? '-'} />
        <CardDataItem title="Район:" value={waterObject?.districtName || '-'} />
        <CardDataItem title="Бассейн:" value={waterObject?.basinName || '-'} />
        <CardDataItem title="Источник:" value={waterObject?.baseName || '-'} />
        <CardDataItem
          title="Ирригационная система:"
          value={waterObject?.systemName}
        />
      </Space>
      <Row justify="center" className={styles.buttonWrapper}>
        <PrimaryButton onClick={toShowCard} icon={<CardIcon />}>
          Карточка
        </PrimaryButton>
      </Row>
    </div>
  );
};
