import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

export const useGeoPortalArchiveTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'name',
      title: 'Наименование',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'attachments',
      title: 'Документ',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <a onClick={() => downloadAttachment(record.attachments[0])}>
          {record.attachments[0]?.originName || record.attachments[0]?.name}
        </a>
      ),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
    },
  ];
  return { columns };
};
