import React, { useEffect } from 'react';

import { Button, ButtonProps } from 'antd';

import { Links } from '@enums/links';

import styles from './buttons.module.scss';

type AddExecutorButtonPropsType = ButtonProps & {
  cardBlockType?: {
    fio: string;
    organization: {
      name: string;
    };
    id: number;
  };
  setShow: () => void;
};

export const AddExecutorButton: React.FC<AddExecutorButtonPropsType> = ({
  cardBlockType,
  setShow,
}) => {
  const buttonText = cardBlockType?.id
    ? `${cardBlockType?.fio}, ${
        cardBlockType?.organization?.name || cardBlockType?.organization
      }`
    : 'Назначить исполнителя*';

  return (
    <Button onClick={setShow} className={`${styles.addExecutor}`}>
      {buttonText}
    </Button>
  );
};
