import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
  Segmented,
  Tabs,
} from '@features/ui-kit';
import { AllTableFilters } from '@features/waterUser/waterUserTable/allTable/allTableFilters';
import { LegalTableFilters } from '@features/waterUser/waterUserTable/legalTable/legalTableFilters';
import { PhysicalTableFilters } from '@features/waterUser/waterUserTable/physicalTable/physicalTableFilters';
import { ArchivariusTableQueryTypes } from '@models/archivarius/enums/archivariusEnum';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { toString } from '@utils/utils';

import { AssociationPermissionsModal } from '../associationPermissionsModal';
import { AssociationStatsModal } from '../associationStatsModal';
import { IndividualPermissionsModal } from '../individualPermissionsModal';
import { LegalPermissionsModal } from '../legalPermissionsModal';
import { AllTable } from './allTable';
import { LegalTable } from './legalTable';
import { PhysicalTable } from './physicalTable';
import { useWaterRegistryFilters } from './useWaterRegistryFilters';
import styles from './WaterUserTable.module.scss';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { saveUrl } from '@utils/returnUrl';
import { HomeOutlined } from '@ant-design/icons';

const options = [
  {
    key: `${WaterUserEnum.ALL}`,
    label: 'Все',
  },
  {
    key: `${WaterUserEnum.INDIVIDUAL}`,
    label: 'Физ. лица',
  },
  {
    key: `${WaterUserEnum.LEGAL}`,
    label: 'Юр. лица',
  },
];

const segmentItems: SegmentedLabeledOption[] = [
  {
    value: `${WaterUserEnum.ALL}`,
    label: 'Все',
  },
  {
    value: `${WaterUserEnum.INDIVIDUAL}`,
    label: 'Физ. лица',
  },
  {
    value: `${WaterUserEnum.LEGAL}`,
    label: 'Юр. лица',
  },
];

interface IWaterUserTableProps {
  isArchived?: boolean;
}

export const WaterUserTable: React.FC<IWaterUserTableProps> = ({
  isArchived = false,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const saveAction = saveUrl();

  const navigate = useNavigate();
  const { setWaterRegistryFilters } = useWaterRegistryFilters();
  const [activeTab, setActiveTab] = useState(3);

  const dynamicOptions = (arr: any[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WATER_USE_IRRIGATION_PHYSICAL_VIEW,
      ACCESS.WATER_USE_IRRIGATION_LEGAL_VIEW,
    ]);

    const allWaterUseTable = tabPermissions.every((item) => item);
    const permissions = [allWaterUseTable, ...tabPermissions];

    const validTabPermissions = arr.filter((_, idx) => permissions[idx]);

    return validTabPermissions;
  };

  let Component;
  let ComponentFilters;
  switch (activeTab) {
    case WaterUserEnum.INDIVIDUAL:
      Component = PhysicalTable;
      ComponentFilters = PhysicalTableFilters;
      break;
    case WaterUserEnum.LEGAL:
      Component = LegalTable;
      ComponentFilters = LegalTableFilters;
      break;
    default:
      Component = AllTable;
      ComponentFilters = AllTableFilters;
      break;
  }

  const changeContentHandle = (v: string) => {
    setActiveTab(parseInt(v, 10));
    setWaterRegistryFilters({
      page: 1,
      size: 10,
      userType: parseInt(v, 10),
      isArchived,
    });
  };

  const signUpPhys = () => {
    navigate(Routes.WaterRegistryMasterRegistrationIndividual);
    saveAction();
  };

  const signUpLegal = () => {
    navigate(Routes.WaterRegistryMasterRegistrationLegal);
    saveAction();
  };

  useEffect(() => {
    setWaterRegistryFilters({
      page: 1,
      size: 10,
      isArchived,
    });

    setSearchParams({
      page: '1',
      size: '10',
      isArchived: isArchived.toString(),
      ...(isArchived ? { section: ArchivariusTableQueryTypes.waterUser } : {}),
    });
    setHeaderTitle(t('headers.water-registry-users'));
  }, []);

  const isCanCreateUserPermission = useHasUserPermission(
    ACCESS.WATER_USER_CREATE,
  );

  return (
    <>
      {!isArchived && (
        <Col span={24}>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: t('headers.water-registry-users'),
              },
            ]}
          />
          <Divider style={{ margin: '6px 0 8px' }} />
        </Col>
      )}
      {!isArchived ? (
        <Tabs items={dynamicOptions(options)} onChange={changeContentHandle} />
      ) : (
        <Segmented
          options={dynamicOptions(segmentItems)}
          onChange={(e: SegmentedValue) => changeContentHandle(toString(e))}
          className={styles.segmented}
        />
      )}
      <Row gutter={10} align="middle" justify="space-between">
        <Col span={17}>
          <ComponentFilters />
        </Col>
        {isCanCreateUserPermission && !isArchived && (
          <Col>
            <Row className={styles.createBtn} gutter={8}>
              <Col>
                <Typography.Text>Регистрация:</Typography.Text>
              </Col>
              <Col>
                <PrimaryButton onClick={signUpPhys}>Физ. лицо</PrimaryButton>
              </Col>
              <Col>
                <PrimaryButton onClick={signUpLegal}>Юр. лицо</PrimaryButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Divider />
      <div className={styles.coverToDeleteHeight}>
        <InformationalBlock title="Реестр водопользователей">
          <CustomCard withoutBottomPadding>
            <Component isArchived={isArchived} />
          </CustomCard>
        </InformationalBlock>
        <AssociationStatsModal />
      </div>
      <AssociationPermissionsModal />
      <IndividualPermissionsModal />
      <LegalPermissionsModal />
    </>
  );
};
