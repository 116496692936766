import { useCallback, useEffect } from 'react';

import { Col, Form, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
} from '@features/ui-kit';
import { useCreateAgroClimaticZoneMutation } from '@store/gisproApi';

import { useCreateAgroClimaticZoneModal } from './useCreateAgroClimaticZoneModal';

export const CreateAgroClimaticZoneModal: React.FC = () => {
  const { isShow, setShow } = useCreateAgroClimaticZoneModal();
  const [form] = useForm();
  const [save, { data, error, isError }] = useCreateAgroClimaticZoneMutation();

  const onCancel = () => {
    setShow(false);
  };

  const onFinish = useCallback((values: { name: string }) => {
    save({ name: values.name });
  }, []);

  useEffect(() => {
    if (data) {
      onCancel();
    }
  }, [data]);

  return (
    <CustomModal
      footer={null}
      title="Создание агроклиматической зоны"
      open={isShow}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Наименование агроклиматической зоны"
          rules={[{ required: true }]}
        >
          <TooltipedInput />
        </Form.Item>
        <Row justify="end" gutter={8}>
          <Col>
            <Space size={8}>
              <DefaultButton onClick={onCancel}>Отмена</DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Form>
    </CustomModal>
  );
};
