export enum DecadeStatus {
  Confirmed = 'confirmed',
  NotConfirmed = 'notConfirmed',
}

export enum DecadesEnum {
  First = '1',
  Second = '2',
  Third = '3',
}

export enum DaysInDecadeEnum {
  FirstDaysPart = '1',
  SecondDaysPart = '2',
  ThirdDaysPart = '3',
  FourthDaysPart = '4',
  FifthDaysPart = '5',
}
