import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const TechCharIcon: React.FC<IconProps> = () => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.16667 1.66309H6.61333C6.75107 1.27274 7.00649 0.934714 7.3444 0.695618C7.68231 0.456522 8.08606 0.328125 8.5 0.328125C8.91394 0.328125 9.31769 0.456522 9.6556 0.695618C9.99351 0.934714 10.2489 1.27274 10.3867 1.66309H11.8333C12.0101 1.66309 12.1797 1.73333 12.3047 1.85835C12.4298 1.98338 12.5 2.15295 12.5 2.32976C12.5 2.50657 12.4298 2.67614 12.3047 2.80116C12.1797 2.92619 12.0101 2.99643 11.8333 2.99643H10.3867C10.2489 3.38678 9.99351 3.7248 9.6556 3.9639C9.31769 4.203 8.91394 4.33139 8.5 4.33139C8.08606 4.33139 7.68231 4.203 7.3444 3.9639C7.00649 3.7248 6.75107 3.38678 6.61333 2.99643H1.16667C0.989856 2.99643 0.820286 2.92619 0.695262 2.80116C0.570238 2.67614 0.5 2.50657 0.5 2.32976C0.5 2.15295 0.570238 1.98338 0.695262 1.85835C0.820286 1.73333 0.989856 1.66309 1.16667 1.66309ZM1.16667 6.99643H2.61333C2.75107 6.60607 3.00649 6.26805 3.3444 6.02895C3.68231 5.78986 4.08606 5.66146 4.5 5.66146C4.91394 5.66146 5.31769 5.78986 5.6556 6.02895C5.99351 6.26805 6.24893 6.60607 6.38667 6.99643H11.8333C12.0101 6.99643 12.1797 7.06666 12.3047 7.19169C12.4298 7.31671 12.5 7.48628 12.5 7.66309C12.5 7.8399 12.4298 8.00947 12.3047 8.1345C12.1797 8.25952 12.0101 8.32976 11.8333 8.32976H6.38667C6.24893 8.72011 5.99351 9.05814 5.6556 9.29723C5.31769 9.53633 4.91394 9.66473 4.5 9.66473C4.08606 9.66473 3.68231 9.53633 3.3444 9.29723C3.00649 9.05814 2.75107 8.72011 2.61333 8.32976H1.16667C0.989856 8.32976 0.820286 8.25952 0.695262 8.1345C0.570238 8.00947 0.5 7.8399 0.5 7.66309C0.5 7.48628 0.570238 7.31671 0.695262 7.19169C0.820286 7.06666 0.989856 6.99643 1.16667 6.99643Z"
            fill="black" fillOpacity="0.5" />
    </svg>
  );
};
