import React from 'react';

import { Links } from '@enums/links';

import styles from './SectionTitle.module.scss';

type SectionTitlePropsType = {
  title: string;
  background: 'blue' | 'yellow' | 'brown' | 'green' | 'cyan' | 'turquoise';
};

export const SectionTitle: React.FC<SectionTitlePropsType> = ({
  title,
  background,
}) => {
  return (
    <div className={`${styles.title} ${styles[background]}`}>
      <img src={Links.BookIcon} alt="bookIcon" />
      {title}
    </div>
  );
};
