import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Card, Col, Row } from 'antd';

import { Links } from '@enums/links';
import {
  useConstructionBlockEventListQuery,
  useOperationalBlockEventListQuery,
} from '@store/gisproApi';
import { saveTableOptions } from '@store/slices';
import { get } from '@utils/utils';

import styles from './InfoTableCard.module.scss';
import { CustomCard } from '@features/ui-kit';

export const InfoTableCard: React.FC<{systemId: string}> = ({ systemId }) => {
  const { t } = useTranslation();

  const { data } = useOperationalBlockEventListQuery({
    id: Number(systemId)
  });
  const { data: construction } = useConstructionBlockEventListQuery({
    id: Number(systemId)
  });
  const defaultValue = 0;
  const dispatch = useDispatch();

  const handleLinkClick = (item: number | string | null) => {
    let optionsToSend: { [key: string]: number | string } = {};

    if (typeof item === 'number') {
      optionsToSend = { categoryId: item };
    } else if (typeof item === 'string') {
      optionsToSend = { status: item };
    } else {
      optionsToSend = {};
    }

    dispatch(saveTableOptions(optionsToSend));
  };

  const constructionElements = {
    byCategory: [
      {
        count: get(construction, 'currentWork.totalCount', defaultValue),
        sum: get(construction, 'currentWork.totalFactBudget', defaultValue),
        label: t('manageEvents.constructionBlock.interfarmChannels'),
        icon: Links.ValveOutline,
        directionId: 2823,
      },
      {
        count: get(construction, 'capitalWork.totalCount', defaultValue),
        sum: get(construction, 'capitalWork.totalFactBudget', defaultValue),
        label: t('manageEvents.constructionBlock.interfarmStructures'),
        icon: Links.BungalowVilla,
        directionId: 2825,
      },
      {
        count: get(construction, 'emergencyWork.totalCount', defaultValue),
        sum: get(construction, 'emergencyWork.totalFactBudget', defaultValue),
        label: t('manageEvents.constructionBlock.householdChannels'),
        icon: Links.PipelineOutline,
        directionId: 2824,
      },
      {
        count: get(construction, 'rehabilitationWork.totalCount', defaultValue),
        sum: get(
          construction,
          'rehabilitationWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.constructionBlock.householdStructures'),
        icon: Links.CommercialComplex,
        directionId: 2826,
      },
    ],
    byStatus: [
      {
        count: get(construction, 'status.onPlanned', defaultValue),
        label: t('manageEvents.preparatoryStage'),
        icon: Links.Wheel,
        status: 'onPreparation',
      },
      {
        count: get(construction, 'status.onRealization', defaultValue),
        label: t('manageEvents.onSale'),
        icon: Links.Tools,
        status: 'onRealization',
      },
      {
        count: get(construction, 'status.onControl', defaultValue),
        label: t('manageEvents.onControl'),
        icon: Links.Warn,
        status: 'onControl',
      },
      {
        count: get(construction, 'status.completed', defaultValue),
        label: t('manageEvents.completedOnTime'),
        icon: Links.Settings,
        status: 'completed',
      },
      {
        count: get(construction, 'status.expired', defaultValue),
        label: t('manageEvents.overdue'),
        icon: Links.Gaika,
        status: 'completedNotOnTime',
      },
    ],
    moneySums: [
      {
        count: get(construction, 'finance.totalBudget', defaultValue),
        label: t('manageEvents.financialBlock.totalPlanned'),
        icon: Links.List,
      },
      {
        count: get(construction, 'finance.realization', defaultValue),
        label: t('manageEvents.financialBlock.soldForAmount'),
        icon: Links.Dollar,
      },
      {
        count: get(construction, 'finance.remains', defaultValue),
        label: t('manageEvents.financialBlock.remainder'),
        icon: Links.Remainder,
      },
      {
        count: get(construction, 'finance.onRealization', defaultValue),
        label: t('manageEvents.financialBlock.inImplementation'),
        icon: Links.Implementation,
      },
    ],
    total: [
      {
        count: get(construction, 'currentWork.totalPlanBudget', defaultValue),
        secondCount: get(
          construction,
          'currentWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.constructionBlock.interfarmChannels'),
        icon: Links.ValveOutline,
        directionId: 2823,
      },
      {
        count: get(construction, 'capitalWork.totalPlanBudget', defaultValue),
        secondCount: get(
          construction,
          'capitalWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.constructionBlock.interfarmStructures'),
        icon: Links.BungalowVilla,
        directionId: 2825,
      },
      {
        count: get(construction, 'emergencyWork.totalPlanBudget', defaultValue),
        secondCount: get(
          construction,
          'emergencyWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.constructionBlock.householdChannels'),
        icon: Links.PipelineOutline,
        directionId: 2824,
      },
      {
        count: get(
          construction,
          'rehabilitationWork.totalPlanBudget',
          defaultValue,
        ),
        secondCount: get(
          construction,
          'rehabilitationWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.constructionBlock.householdStructures'),
        icon: Links.CommercialComplex,
        directionId: 2826,
      },
    ],
  };

  const operationalElements = {
    byCategory: [
      {
        count: get(data, 'currentWork.totalCount', defaultValue),
        sum: get(data, 'currentWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.currentRepairWork'),
        icon: Links.Wheel,
        categoryId: 2792,
      },
      {
        count: get(data, 'capitalWork.totalCount', defaultValue),
        sum: get(data, 'capitalWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.majorRepairWork'),
        icon: Links.Tools,
        categoryId: 2793,
      },
      {
        count: get(data, 'emergencyWork.totalCount', defaultValue),
        sum: get(data, 'emergencyWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.emergencyWork'),
        icon: Links.Warn,
        categoryId: 2794,
      },
      {
        count: get(data, 'rehabilitationWork.totalCount', defaultValue),
        sum: get(data, 'rehabilitationWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.rehabilitation'),
        icon: Links.Settings,
        categoryId: 2795,
      },
      {
        count: get(data, 'reconstructionWork.totalCount', defaultValue),
        sum: get(data, 'reconstructionWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.reconstruction'),
        icon: Links.Gaika,
        categoryId: 2796,
      },
    ],
    byStatus: [
      {
        count: get(data, 'status.onPlanned', defaultValue),
        label: t('manageEvents.preparatoryStage'),
        icon: Links.Wheel,
        status: 'onPreparation',
      },
      {
        count: get(data, 'status.onRealization', defaultValue),
        label: t('manageEvents.onSale'),
        icon: Links.Tools,
        status: 'onRealization',
      },
      {
        count: get(data, 'status.onControl', defaultValue),
        label: t('manageEvents.onControl'),
        icon: Links.Warn,
        status: 'onControl',
      },
      {
        count: get(data, 'status.completed', defaultValue),
        label: t('manageEvents.completedOnTime'),
        icon: Links.Settings,
        status: 'completed',
      },
      {
        count: get(data, 'status.expired', defaultValue),
        label: t('manageEvents.overdue'),
        icon: Links.Gaika,
        status: 'completedNotOnTime',
      },
    ],
    moneySums: [
      {
        count: get(data, 'finance.totalBudget', defaultValue),
        label: t('manageEvents.financialBlock.totalPlanned'),
        icon: Links.List,
      },
      {
        count: get(data, 'finance.realization', defaultValue),
        label: t('manageEvents.financialBlock.soldForAmount'),
        icon: Links.Dollar,
      },
      {
        count: get(data, 'finance.remains', defaultValue),
        label: t('manageEvents.financialBlock.remainder'),
        icon: Links.Remainder,
      },
      {
        count: get(data, 'finance.onRealization', defaultValue),
        label: t('manageEvents.financialBlock.inImplementation'),
        icon: Links.Implementation,
      },
    ],
    total: [
      {
        count: get(data, 'currentWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'currentWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.currentRepairWork'),
        icon: Links.Wheel,
      },
      {
        count: get(data, 'capitalWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'capitalWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.majorRepairWork'),
        icon: Links.Tools,
      },
      {
        count: get(data, 'emergencyWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'emergencyWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.emergencyWork'),
        icon: Links.Warn,
      },
      {
        count: get(data, 'rehabilitationWork.totalPlanBudget', defaultValue),
        secondCount: get(
          data,
          'rehabilitationWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.operationalBlock.rehabilitation'),
        icon: Links.Settings,
      },
      {
        count: get(data, 'reconstructionWork.totalPlanBudget', defaultValue),
        secondCount: get(
          data,
          'reconstructionWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.operationalBlock.reconstruction'),
        icon: Links.Gaika,
      },
    ],
  };
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <CustomCard title="Эксплуатация" className={styles.infoTableCard}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>По категориям:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Штук:
                  </Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Сумма:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {operationalElements.byCategory.map((item) => (
                    <div key={item.categoryId}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.sum}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>По категориям:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Штук:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {operationalElements.byStatus.map((item) => (
                    <div key={item.label}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>Финансы:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Сумма:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {operationalElements.moneySums.map((item) => (
                    <div key={item.label}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
        <Col span={24}>
          <CustomCard title="Строительство" className={styles.infoTableCard}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>По категориям:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Штук:
                  </Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Сумма:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {constructionElements.byCategory.map((item) => (
                    <div key={item.directionId}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.sum}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>По категориям:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Штук:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {constructionElements.byStatus.map((item) => (
                    <div key={item.label}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ flex: '1', margin: '0 20px' }}>
                <Row className={styles.infoTableCardBorderBottom}>
                  <Col span={16}>Финансы:</Col>
                  <Col span={4} className={styles.infoTableCardBorderLeft}>
                    Сумма:
                  </Col>
                </Row>
                <div className={styles.infoTableCardHeader}>
                  {constructionElements.moneySums.map((item) => (
                    <div key={item.label}>
                      <Row className={styles.infoTableCardBorderBottom}>
                        <Col span={16}>{item.label}</Col>
                        <Col
                          span={4}
                          className={styles.infoTableCardBorderLeft}
                        >
                          {item.count}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
