import Icon, {
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { LayerItem } from '@features/geoPortal/new/geoPortalMap/geoPortalMapNavigation/layerBlock/LayerItem';
import { CollapseMenu } from '@features/ui-kit/collapseMenu/CollapseMenu';
import { FolderIcon } from '@icons/FolderIcon';
import {
  ILayerDto,
  ILayers,
  ILayersByName,
} from '@models/geoPortal/interfaces/GeoPortal';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useLazyMapControllerGetDataQuery,
  useMapControllerGetLayersArchiveQuery,
  useMapControllerGetLayersQuery,
} from '@store/api/geoPortalApi';

import { Button, Checkbox, Spin } from 'antd';
import { ItemType } from 'rc-collapse/es/interface';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { MapContext } from '../../mapComponent/MapComponentContext';
import styles from './LayerBlock.module.scss';
import { TooltipedDatePicker } from '@features/ui-kit';
import dayjs, { Dayjs } from 'dayjs';

export const LayerBlock: React.FC = () => {
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersParams = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const {
    setSelectedLayer,
    showLayer,
    hideLayer,
    showWMS,
    hideWMS,
    toggleSetGroupArchiveLayer,
  } = useContext(MapContext);
  const [loadLayerData] = useLazyMapControllerGetDataQuery();

  const { data: layers, isLoading } = useMapControllerGetLayersQuery(
    nameParamArchive !== 'archive' && filterKey
      ? {
          mapName: filterKey,
        }
      : skipToken,
  );

  const { data: layersArchive, isLoading: isLoadingArchive } =
    useMapControllerGetLayersArchiveQuery(
      nameParamArchive === 'archive' && filterKey
        ? {
            mapName: filterKey,
          }
        : skipToken,
    );

  const [layersMutationList, setLayersMutationList] = useState<ILayersByName[]>(
    [],
  );
  const [loadingLayerData, setLoadingLayerData] = useState(false);

  const onChangeDateLayer = (
    date: Dayjs | null,
    key: 'startDate' | 'endDate' = 'startDate',
  ) => {
    if (date) {
      setSearchParams({
        ...filtersParams,
        [key]: date.format('YYYY-MM-DD'),
      });
    }
  };

  const toggleAddDay = (
    type: string,
    key: 'startDate' | 'endDate' = 'startDate',
  ) => {
    if (type === 'add') {
      setSearchParams({
        ...searchParams,
        [key]: dayjs(startDate).add(1, 'day').format('YYYY-MM-DD'),
      });
    } else if (type === 'subtract') {
      setSearchParams({
        ...searchParams,
        [key]: dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD'),
      });
    }
  };

  const onShow = (e: any, layer: ILayerDto, index: number) => {
    e.stopPropagation();

    setLayersMutationList((items) => {
      return items.map((item, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...item,
            checked: false,
            layers: item.layers.map((item2) => {
              if (item2.layerName === layer.layerName) {
                return {
                  ...item2,
                  checked: e.target.checked,
                };
              }

              return item2;
            }),
          };
        }

        return item;
      }) as ILayersByName[];
    });

    if (e.target.checked) {
      if (layer.datasetType === 'wms') {
        setSelectedLayer(layer);
        showWMS(layer);
        return;
      }

      if (nameParamArchive === 'archive') {
        hideLayer(layer);
        showLayer(layer, layer.attributeList);
      } else {
        loadLayerData(
          {
            layerName: layer.layerName,
            mapName: filterKey,
            startDate,
            endDate,
          },
          true,
        ).then(({ data }) => {
          hideLayer(layer);
          showLayer(layer, data);
        });
      }
    } else {
      if (layer.datasetType === 'wms') {
        hideWMS(layer);
        return;
      }

      hideLayer(layer);
    }
  };

  const selectAllLayers = async (e: any, group: ILayers, index: number) => {
    e.stopPropagation();

    const checked = e.target.checked;

    setLayersMutationList((items) => {
      return items.map((item, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...item,
            checked: checked,
            layers: item.layers.map((item2) => {
              return {
                ...item2,
                checked,
              };
            }),
          };
        }

        return item;
      }) as ILayersByName[];
    });

    const listLayers = group.layers.map((item) => ({
      ...item,
      selected: false,
      layerShown: false,
    }));

    if (checked) {
      try {
        setLoadingLayerData(true);

        if (nameParamArchive !== 'archive') {
          const layerList = await Promise.all(
            listLayers.map((layer) =>
              loadLayerData(
                {
                  layerName: layer.layerName,
                  mapName: filterKey,
                  startDate,
                  endDate,
                },
                true,
              ),
            ),
          );

          const layerListMutation: any = layerList.map((item) => item?.data);
          hideLayer(listLayers);
          showLayer(listLayers, layerListMutation);
        } else {
          const attributeList = listLayers.map((item) => item.attributeList);
          hideLayer(listLayers);
          showLayer(listLayers, attributeList);
        }
      } catch (e) {
        console.log(e);
        setLoadingLayerData(false);
      } finally {
        setLoadingLayerData(false);
      }
    } else {
      hideLayer(listLayers);
    }
  };

  const items = useMemo(() => {
    if (!layersMutationList) return [];

    return layersMutationList.map((layer, index) => {
      const children = layer.layers.map((el) => {
        return (
          <LayerItem
            index={index}
            onClick={() => setSelectedLayer(el as any)}
            onShow={onShow}
            item={el as any}
            loadingLayerData
          />
        );
      });
      const item: ItemType = {
        label: (
          <div style={{ fontSize: 14, position: 'relative' }}>
            <Icon style={{ marginRight: 4 }} component={FolderIcon} />
            {layer.name}
            <Checkbox
              onClick={(e) => selectAllLayers(e, layer as any, index)}
              checked={layersMutationList[index].checked}
              disabled={loadingLayerData}
              style={{ position: 'absolute', right: 0, top: 0 }}
            />
          </div>
        ),
        key: layer.name,
        children,
      };
      return item;
    });
  }, [setSelectedLayer, layersMutationList]);

  useEffect(() => {
    if (nameParamArchive === 'archive' && layersArchive) {
      const groupLayer: ILayersByName[] = [];

      layersArchive.features.forEach((feature) => {
        let group = groupLayer.find((g) => g.name === feature.groupName);

        if (!group) {
          group = {
            checked: false,
            name: feature.groupName,
            layers: [],
          };
          groupLayer.push(group);
        }

        let layer = group.layers.find(
          (l) => l.layerName === feature.properties.layerName,
        );

        if (!layer) {
          layer = {
            checked: false,
            count: 0,
            layerName: feature.properties.layerName,
            title: feature.layerTitle,
            datasetType: feature.geometry.type,
            attributeList: [],
          };
          group.layers.push(layer);
        }

        if (layer) {
          layer.attributeList?.push({
            baseName: feature.properties.baseNameAtt,
            coordinates: feature.geometry.coordinates,
            dateStart: feature.properties.dateStart,
            extra: feature.properties.extra,
            geoId: feature.properties.geoId,
            id: feature.properties.id,
            irrigationSystemName: feature.properties.irrigationSystemNameAtt,
            name: feature.properties.name,
            objectType: feature.properties.objectType,
            operationalStatus: feature.properties.operationalStatusAtt,
            piket: feature.properties.piket,
            popupText: feature.properties.popupText,
            technicalStatus: feature.properties.technicalStatusAtt,
            type: feature.properties.type,
            systemObject: feature.properties,
          });

          layer.count = layer.attributeList?.length || 0;
        }
      });

      toggleSetGroupArchiveLayer(groupLayer);
      setLayersMutationList(groupLayer);
    }

    if (layers) {
      const layerMutationList = layers.map((item) => ({
        ...item,
        checked: false,
        layers: item.layers.map((item2) => ({ ...item2, checked: false })),
      })) as ILayersByName[];

      setLayersMutationList(layerMutationList);
    }
  }, [layers, nameParamArchive, layersArchive]);

  useEffect(() => {
    if (startDate || endDate) {
      // loadLayerData for checked layers
      layersMutationList.forEach((group) => {
        group.layers.forEach((layer) => {
          if (layer.checked) {
            loadLayerData(
              {
                layerName: layer.layerName,
                mapName: filterKey,
                startDate,
                endDate,
              },
              true,
            ).then(({ data }) => {
              hideLayer(layer, true);
              showLayer(layer, data);
            });
          }
        });
      });
    }
  }, [startDate, endDate]);

  return (
    <div className={styles.layersBlock}>
      {isLoading ||
        (isLoadingArchive && (
          <div>
            <Spin />
          </div>
        ))}
      {!isLoading ||
        (!isLoadingArchive && filterKey.split(':')[1] === 'quality' && (
          <div
            style={{
              margin: '5px 0 20px 0',
              padding: '0 5px',
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Button
              type="primary"
              onClick={() => toggleAddDay('subtract')}
              icon={<LeftOutlined />}
            />
            <TooltipedDatePicker
              value={startDate ? dayjs(startDate) : null}
              onChange={(data) => onChangeDateLayer(data)}
              allowClear={true}
            />
            <Button
              type="primary"
              onClick={() => toggleAddDay('add')}
              icon={<RightOutlined />}
            />
          </div>
        ))}
      {!isLoading && filterKey.split(':')[1] === 'monitoring' && (
        <div
          style={{
            margin: '5px 0 20px 0',
            padding: '0 5px',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <TooltipedDatePicker
            value={startDate ? dayjs(startDate) : null}
            onChange={(data) => onChangeDateLayer(data, 'startDate')}
            allowClear={true}
          />
          <TooltipedDatePicker
            value={endDate ? dayjs(endDate) : null}
            onChange={(data) => onChangeDateLayer(data, 'endDate')}
            allowClear={true}
            disabledDate={(current) => {
              return (
                current &&
                !!startDate &&
                current < dayjs(startDate).endOf('day')
              );
            }}
          />
        </div>
      )}
      <div style={{ flex: 1, overflow: 'auto' }}>
        <CollapseMenu
          ghost
          items={items}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        />
      </div>
    </div>
    // <Collapse
    //   defaultActiveKey={items?.length === 1 ? 1 : 0}
    //   items={items}
    //   style={{ height: '100%' }}
    //   className={styles.collapse}
    // expandIcon={({ isActive }) => (
    //   <CaretRightOutlined
    //     rotate={isActive ? 90 : 0}
    //     style={{ opacity: 0.7 }}
    //   />
    // )}
    // onChange={onChange}
    // />
  );
};
