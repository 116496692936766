import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { ViewTypeMonitoring } from '@models/monitoring/enums/DemoMonitoringEnum';

import { DailyLayout } from './Daily/DailyLayout';
import { HydroTableLayout } from './Hydro/HydroTable/HydroTableLayout';
import { MonitoringContentLayout } from './MonitoringContentLayout';
import styles from './MonitoringLayout.module.scss';

export default function MonitoringSchedule() {
  const [params] = useSearchParams();

  const segment = useMemo(
    () => params.get(SectionQueryKey) || ViewTypeMonitoring.Monitoring,
    [params],
  );

  let Component;
  switch (segment) {
    case ViewTypeMonitoring.Monitoring:
      Component = MonitoringContentLayout;
      break;
    case ViewTypeMonitoring.Daily:
      Component = DailyLayout;
      break;
    case ViewTypeMonitoring.Hydro:
      Component = HydroTableLayout;
      break;
    default:
      Component = MonitoringContentLayout;
      break;
  }

  return (
    <div className={styles.scheduleBlock}>
      <Component />
    </div>
  );
}
