import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { TablePaginationConfig } from 'antd';

import { ACCESS, Permission } from '@enums/permisson';
import { Routes } from '@enums/routes';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { JsonRecord } from '@models/jsonRecord';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllIrrigationSystemListQuery } from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { clearIrrigationSystemAction } from '@store/slices';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { isEmpty } from '@utils/utils';
import { IrrigationSystemTableFilters } from './irrigationSystemTableFilters';
import { useIrrigationSystemColumns } from './useIrrigationSystemColumns';
import { useIrrigationSystemFilters } from './useIrrigationSystemFilters';
import { useGetCurrentUser } from '@features/authentication';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { saveUrl } from '@utils/returnUrl';

interface IIrrigationSystemTableProps {
  isArchived?: boolean;
}

export const IrrigationSystemTable: React.FC<IIrrigationSystemTableProps> = ({
  isArchived = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { filters, setIrrigationSystemFilters } =
    useIrrigationSystemFilters(isArchived);
  const { columns } = useIrrigationSystemColumns(isArchived);
  const [params] = useSearchParams();
  const { user } = useGetCurrentUser();
  const saveAction = saveUrl();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useFindAllIrrigationSystemListQuery(
    !isEmpty(filters) ? { ...filters, isArchived } : skipToken,
  );

  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const onCreateButtonClick = () => {
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemCreateStepper);
    saveAction();
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setIrrigationSystemFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
            isArchived,
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as IrrigationSystemFilters)
        : (newFilters as unknown as IrrigationSystemFilters),
    );
  }, []);

  const isCanCreateIrrigationSystem = useHasUserPermission(
    ACCESS.IRRIGATION_SYSTEM_CREATE,
  );

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setIrrigationSystemFilters({
          ...(filters as IrrigationSystemFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.systemId);
    setShowModal(true);
  };

  const onHandleCard = (record: JsonRecord) => {
    navigate(
      generatePath(Routes.IrrigationSystemCard, {
        systemId: record.systemId ? record.systemId : null,
      }),
    );
    saveAction();
  };

  const onHandleGis = (record: JsonRecord) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:irrigation-system:${
        record.systemId ? record.systemId : '000'
      }`,
    });

    window.open(url, '_blank');
  };

  const onHandleMainConduit = (record: JsonRecord) => {
    navigate(
      generatePath(Routes.IrrigationSystemMainConduitRegistration, {
        systemId: record.systemId ? record.systemId : null,
        mainConduitId: 'none',
      }),
    );
    saveAction();
  };

  const onHandleConstructor = (record: JsonRecord) => {
    navigate(
      generatePath(Routes.IrrigationSystemWaterObjectsRegistration, {
        systemId: record.systemId ? record.systemId : null,
      }),
    );
    saveAction();
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.systemId);
    setShowModal(true);
  };

  return (
    <>
      <IrrigationSystemTableFilters
        onCreateButtonClick={onCreateButtonClick}
        isCanCreateIrrigationSystem={isCanCreateIrrigationSystem}
        isArchived={isArchived}
      />
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={t('headers.irrigation-system')}
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={content}
            columns={columns}
            bordered
            size="small"
            onChange={onTableChange}
            totalCount={totalElements}
            actionProps={{
              onArchiveClick,
              onHandleCard,
              onHandleGis,
              onHandleMainConduit,
              onHandleConstructor,
              onUnArchiveClick,
            }}
            customPagination={rest}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
