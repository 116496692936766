import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreateHydroModuleDistrictModalShownSelector } from '@store/selectors';
import { agroClimaticZoneActions } from '@store/slices';

export const useCreateHydroModuleDistrictModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsCreateHydroModuleDistrictModalShownSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(
      agroClimaticZoneActions.setCreateHydroModuleDistrictModalShownAction(
        state,
      ),
    );
  }, []);

  return { isShow, setShow };
};
