import { Col, Empty, Row, Space, Typography } from 'antd';

import { CardDataItem } from '@features/geoPortal/geoPortalCard/dataOperationalBlock/cardData/CardDataItem';
import { CustomModal, PrimaryButton } from '@features/ui-kit';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { CardIcon } from '@icons/CardIcon';
import { Line } from '@ant-design/charts';
import { SystemObjectGeoPortalType } from '@models/systemObject/systemObjectEnum';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { MonitoringBlockModal } from './MonitoringBlockModal';
import styles from './MonitoringBlock.module.scss';

type InfoBlockProps = {
  waterObject: any;
};

const typeTranslation: Record<string, string> = {
  release: 'Сбросной',
  releaseToSources: 'Выпуск к источникам',
  waterIntake: 'Водозабор',
  waterLossByGvs: 'Потери воды по ГВС',
  waterSupply: 'Водоподача',
  waterSupplyByGvs: 'Водоснабжение по ГВС',
  feedingLine: 'Подпитывающий (магистраль)',
  feedingRegulation: 'Подпитывающий (регулирование)',
  releaseFinal: 'Сбросной (итоговый)',
  releaseRegulation: 'Сбросной (регулирование)',
  waterIntakeHydropost: 'Водозабор гидропост',
  commercial: 'Коммерческий',
};

const renderTooltip = (e: any, { title, items }: any) => (
  <div key={title}>
    <h4>{dayjs(title).format('YYYY-MM-DD')}</h4>
    {items.map((item: any) => {
      const { name, value, color } = item;
      return (
        <div key={name}>
          <div className={styles.tooltipContent}>
            <div>
              <span
                className={styles.tooltipMarker}
                style={{
                  backgroundColor: color,
                }}
              ></span>
              <span>Средний Индикатор</span>
            </div>
            <b>{value && Number(value?.toFixed(3))}</b>
          </div>
        </div>
      );
    })}
  </div>
);

export const MonitoringBlock: React.FC<InfoBlockProps> = ({ waterObject }) => {
  const [isShowModal, setShowModal] = useState(false);

  const getData = (multiplier: number) =>
    waterObject?.indicators.map(
      ({
        averageIndicator,
        date,
      }: {
        averageIndicator: string;
        date: string;
      }) => ({
        averageIndicator: Number(averageIndicator) * multiplier,
        date,
      }),
    );

  const config = useMemo(() => {
    const commonConfig = {
      height: 200,
      xField: 'date',
      yField: 'value',
      axis: {
        x: {
          labelFormatter: (date: string) => dayjs(date).format('YYYY-MM-DD'),
        },
        y: {},
      },
    };

    if (waterObject?.indicators) {
      return {
        indicators: {
          ...commonConfig,
          data: waterObject?.indicators || [],
          yField: 'averageIndicator',
          interaction: {
            tooltip: {
              render: renderTooltip,
            },
          },
        },
      };
    } else if (waterObject?.infoBySystem && waterObject?.infoByChannel) {
      const flattenData = (items: any[], types: string[]) =>
        items.flatMap((item: any) =>
          types.map((type) => ({
            date: dayjs(item.date).format('YYYY-MM-DD'),
            value: Number(item[type]),
            type: typeTranslation[type],
          })),
        );

      return {
        infoBySystem: {
          ...commonConfig,
          colorField: 'type',
          seriesField: 'type',
          data: flattenData(waterObject?.infoBySystem || [], [
            'release',
            'releaseToSources',
            'waterIntake',
            'waterLossByGvs',
            'waterSupply',
            'waterSupplyByGvs',
          ]),
          legend: isShowModal,
        },
        infoByChannel: {
          ...commonConfig,
          colorField: 'type',
          seriesField: 'type',
          data: flattenData(waterObject?.infoByChannel || [], [
            'feedingLine',
            'feedingRegulation',
            'release',
            'releaseFinal',
            'releaseRegulation',
            'waterIntake',
            'waterIntakeHydropost',
            'waterSupply',
            'commercial',
          ]),
          legend: isShowModal,
        },
      };
    }
  }, [waterObject, isShowModal]);

  const ChartsView = useMemo(() => {
    return (
      <>
        {config?.infoBySystem && (
          <Line {...config?.infoBySystem} title="Общие данные по системе" />
        )}
        {config?.infoByChannel && (
          <Line {...config?.infoByChannel} title="Общие данные по каналу" />
        )}
        {config?.indicators && (
          <>
            <Line
              {...config?.indicators}
              data={getData(1)}
              title="Балансовый учёт, л/с"
            />
            <Line
              {...config?.indicators}
              data={getData(0.864)}
              title="Балансовый учёт, тыс м3"
            />
            <Line
              {...config?.indicators}
              data={getData(3.6)}
              title="Балансовый учёт, м3/ч"
            />
          </>
        )}
      </>
    );
  }, [config]);

  const isNoData = useMemo(() => {
    return (
      !!waterObject?.infoByChannel?.length ||
      !!waterObject?.infoBySystem?.length ||
      !!waterObject?.indicators?.length
    );
  }, [waterObject]);

  if (!isNoData) {
    return (
      <Col span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    );
  }
  return (
    <div className={styles.monitoringBlock}>
      <Row justify="center">
        <PrimaryButton onClick={() => setShowModal(true)} icon={<CardIcon />}>
          Просмотр в крупном виде
        </PrimaryButton>
      </Row>
      {ChartsView}
      {isShowModal && (
        <CustomModal
          className={styles.monitoringBlock_modal}
          open={isShowModal}
          onCancel={() => setShowModal(false)}
          destroyOnClose
          footer={false}
          width="90%"
          title="Просмотр"
        >
          {ChartsView}
        </CustomModal>
      )}
    </div>
  );
};
