import { IAnnualPlanFilters } from '@models/annualPlan/enums/AnnualPlan';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AnnualPlanState = {
  annualPlanFilters: Partial<IAnnualPlanFilters>;
};

const initialState: AnnualPlanState = {
  annualPlanFilters: {},
};

const annualPlanSlice = createSlice({
  name: 'annualPlan',
  initialState,
  reducers: {
    changeAnnualPlanFilters: (
      state,
      action: PayloadAction<IAnnualPlanFilters>,
    ) => {
      return {
        ...state,
        annualPlanFilters: action.payload,
      };
    },
  },
});
export const { changeAnnualPlanFilters } = annualPlanSlice.actions;
export default annualPlanSlice.reducer;
