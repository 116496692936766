import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row } from 'antd';

import { Permission } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useAnnualApplicationTableFilters } from '@features/annualApplication/annualApplicationTable/useAnnualApplicationTableFilters';
import { ContractualWaterSupplyTableContainer } from '@features/annualApplication/contractualWaterSupplyTable/contractualWaterSupplyTableContainer';
import { ContractualWaterSupplyTableFilters } from '@features/annualApplication/contractualWaterSupplyTable/contractualWaterSupplyTableFilters';
import { SectionTitle } from '@features/ui-kit-v2';
import { IAnnualApplicationFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { isEmpty } from '@utils/utils';

import styles from './ContractualWaterSupplyTable.module.scss';
import { Divider } from '@features/ui-kit';
import { HomeOutlined } from '@ant-design/icons';

/**
 * deprecated
 */
export const ContractualWaterSupplyTable = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { setAnnualContractFilters } = useAnnualApplicationTableFilters();
  const onCreateButtonClick = () => {
    navigate(generatePath(Routes.AnnualApplicationCard));
  };
  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setAnnualContractFilters(
      isEmpty(newFilters)
        ? ({} as unknown as IAnnualApplicationFilters)
        : (newFilters as unknown as IAnnualApplicationFilters),
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      {/* <Row gutter={12} justify="space-between">
        <Col span={24}>
          <SectionTitle
            background="cyan"
            title={t('annualApplication.table.contractualWaterSupply')}
          />
        </Col>
      </Row> */}
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('annualApplication.table.contractualWaterSupply'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <ContractualWaterSupplyTableFilters />
        </Col>
        <Col span={24}>
          <ContractualWaterSupplyTableContainer />
        </Col>
      </Row>
    </div>
  );
};
