import { MenuTab } from '@features/ui-kit/menuTab/MenuTab';
import { useEffect, useMemo, useState } from 'react';
import { useGeoPortalMapFilters } from '@features/geoPortal/geoPortalMaps/useGeoPoralFilters';
import { useGeoControllerGetPortalGroupsQuery } from '@store/api/geoPortalApi';
import { useSearchParams } from 'react-router-dom';

export const GeoPortalNavigationMenu: React.FC = () => {
  const { setGeoPortalFilters, filters } = useGeoPortalMapFilters();
  const { data = [] } = useGeoControllerGetPortalGroupsQuery({
    region: filters.region || undefined,
    district: filters.district || undefined,
  });
  const [params, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState('1');
  const items = useMemo(
    () => data?.map((item) => ({ ...item, label: item.name })) || [],
    [data],
  );

  const handleMenuChange = (key: string) => {
    setSearchParams(params);
    setActiveKey(key);
    setGeoPortalFilters({
      ...filters,
      groupKey: key,
      page: 1,
    });
  };

  useEffect(() => {
    if (items.length && !filters.groupKey) {
      setActiveKey(items[0].key);
      setGeoPortalFilters({
        ...filters,
        groupKey: items[0].key,
      });
    }
  }, [items]);

  return (
    <div>
      <MenuTab
        items={items}
        activeKey={activeKey}
        onChange={handleMenuChange}
      />
    </div>
  );
};
