import { Button, ButtonProps } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons';

import styles from './buttons.module.scss';

type NavigateButtonV2PropTypes = ButtonProps & {
  title: string;
};

export const NavigateButtonV2: React.FC<NavigateButtonV2PropTypes> = ({
  title,
  ...rest
}) => (
  <Button className={styles.navButton} {...rest}>
    {title}

    <ArrowRightOutlined className={styles.navIcon} />
  </Button>
);
