import { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import {
  ITariffFilters,
  ITariffFindFilters,
} from '@models/tariff/interfaces/tariff';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllRuvhOrganizationsQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';

import styles from './TariffTableFilters.module.scss';
import { useTariffTableFilters } from './useTariffTableFilters';
import { useGetCurrentUser } from '@features/authentication';

interface ITariffTableFilters {
  isCanCreateTariff: boolean;
  onCreateButtonClick: () => void;
}

export const TariffTableFilters: React.FC<ITariffTableFilters> = ({
  isCanCreateTariff,
  onCreateButtonClick,
}) => {
  const [filtersState, setFiltersState] = useState<any>({});

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const { filters, setTariffFilters } = useTariffTableFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: organizations = [] } = useFindAllRuvhOrganizationsQuery();

  const filteredTariffs = () => {
    setTariffFilters({
      ...(filters as ITariffFilters),
      ...filtersState,
    });
  };

  const onFilterChange = (filterKey: keyof ITariffFilters, v: string) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      ...filtersState,
      organizationId: '',
      districtId: user?.owner?.district?.id || null,
      regionId: user?.owner?.region?.id || null,
      year: '',
      page: 1,
      size: 10,
    });

    setTariffFilters({
      organizationId: null,
      districtId: user?.owner?.district?.id.toString() || null,
      regionId: user?.owner?.region?.id.toString() || null,
      year: null,
      page: 1,
      size: 10,
    });
  };

  const onRegionChange = (v: string) => {
    setFiltersState((state: any) => ({
      ...state,
      districtId: null,
      regionId: v,
      page: 1,
    }));
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Row align="middle" gutter={16} className={styles.selectWrapper}>
        <Col>
          <TooltipedSelect
            placeholder="Область"
            value={
              regions.find(
                (item) => item.id === toInteger(filtersState.regionId),
              )?.label
            }
            onChange={(v: string) => onRegionChange(v)}
            options={regions}
            allowClear
            disabled={isDisabledRegion}
          />
        </Col>
        <Col>
          <TooltipedSelect
            placeholder="Район"
            options={districts}
            disabled={!filtersState.regionId || isDisabledDistrict}
            value={
              districts.find(
                (item) => item.id === toInteger(filtersState.districtId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('districtId', v)}
            allowClear
          />
        </Col>
        <Col>
          <YearPicker
            value={filtersState.year ? dayjs(filtersState.year) : null}
            placeholder="Год"
            onChange={(date: Dayjs | null, year) =>
              onFilterChange('year', year.toString())
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col className={styles.filterCol}>
          <Row className={styles.filterBtns} wrap={false}>
            <PrimaryButton icon={<FilterIcon />} onClick={filteredTariffs} />
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        {isCanCreateTariff && (
          <Col flex="auto">
            <PrimaryButton onClick={onCreateButtonClick}>
              Создать тариф
            </PrimaryButton>
          </Col>
        )}
      </Row>
    </Row>
  );
};
