import { DecadeStartDate } from '@constants/decades';
import { MonthIndex } from '@constants/month';
import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';
import { get } from '@utils/utils';

type ReturnType = {
  columns: any;
};

export const useWaterLogAccoutingBalanceSheetTableColumns = ({
  month,
  decade,
  year,
}: {
  month: MonthEnums;
  decade: DecadesEnum;
  year: string;
}): ReturnType => {
  const day = DecadeStartDate[decade];

  const otherDays: string[] = [];

  const dateStart = dayjs()
    .year(parseInt(year, 10))
    .month(MonthIndex[month])
    .date(day)
    .format('YYYY-MM-DD');

  const dateEnd = dayjs()
    .year(parseInt(year, 10))
    .month(MonthIndex[month])
    .date(day + 9)
    .format('YYYY-MM-DD');

  let currentDate = dayjs(dateStart).add(1, 'day'); // Начинаем со следующего дня после dateStart
  while (currentDate.isBefore(dayjs(dateEnd))) {
    otherDays.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }
  const additionalColumns = Array.from({ length: 8 }, (_, i) => {
    return [
      {
        title: otherDays[i],
        dataIndex: `${otherDays[i]}`,
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Расход л/с',
            dataIndex: 'plan',
            decimalIndex: ['decadeDtos', otherDays[i]],
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: false,
          },
          {
            title: 'Cток тыс. м3',
            dataIndex: 'fact',
            decimalIndex: ['decadeDtos', otherDays[i]],
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: false,
          },
        ],
      },
    ];
  }).flat();
  const dateColumns: any = [
    { title: dateStart },
    ...additionalColumns,
    { title: dateEnd },
  ]
    .map((date) => {
      const dayTitle = parseInt(dayjs(date.title).format('D'), 10);
      return [
        {
          title: date.title,
          dataIndex: 'day',
          key: dayTitle,
          xtype: CustomTableColumnType.String,
          children: [
            {
              xtype: CustomTableColumnType.DecimalEdit,
              width: 100,
              title: 'Расход л/с',
              dataIndex: 'value',
              decimalIndex: ['days', dayTitle],
              decimalClassName: (record: any) => {
                const day = get(record, ['days', dayTitle]);
                if (day?.isBlocked) {
                  return 'decimalBlocked';
                } else if (day?.isSaved) {
                  return 'decimalSaved';
                }
                return '';
              },
              editableDecimalCell: (record: any) => {
                const isBlocked = get(record, ['days', dayTitle, 'isBlocked']);
                return isBlocked !== true;
              },
            },
            {
              title: 'Cток тыс. м3',
              dataIndex: 'valueM3',
              decimalIndex: ['days', dayTitle],
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 100,
            },
          ],
        },
      ];
    })
    .flat();

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      width: 50,
      xtype: CustomTableColumnType.String,
      // fixed: 'left',
    },
    {
      title: 'Наименование водопользователя',
      dataIndex: 'name',
      width: 210,
      xtype: CustomTableColumnType.String,
      // fixed: 'left',
    },
    {
      title: 'Общие показатели за декаду',
      dataIndex: 'general',
      xtype: CustomTableColumnType.String,
      // fixed: 'left',
      children: [
        {
          title: 'Всего Расход л/с',
          dataIndex: 'value',
          decimalIndex: ['sum'],
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
          width: 100,
          // fixed: 'left',
        },
        {
          title: 'Всего сток тыс. м3',
          dataIndex: 'valueM3',
          decimalIndex: ['sum'],
          width: 100,
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
          // fixed: 'left',
        },
      ],
    },
    ...dateColumns,
  ];

  return { columns };
};
