import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useGetObjectCategoriesQuery } from '@store/gisproApi';

export const useGtsObjectsTableColumns = () => {
  const { data: objectCategories = [] } = useGetObjectCategoriesQuery();
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: t('irrigationForm.objectTable.objectName'),
      dataIndex: 'objectName',
      key: 'objectName',
      xtype: CustomTableColumnType.String,
      width: 180,
    },
    {
      title: t('irrigationForm.objectTable.code'),
      dataIndex: 'objectCode',
      key: 'objectCode',
      xtype: CustomTableColumnType.String,
      width: 100,
      sorter: {
        compare: (a, b) => a.objectCode - b.objectCode,
      },
    },
    {
      title: t('irrigationForm.objectTable.group'),
      dataIndex: 'groupObjectId',
      key: 'groupObjectId',
      width: 160,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <span>
          {objectCategories.find(
            (category) => category.categoryId === record.objectCategoryId,
          )?.objectGroup.groupName || '-'}
        </span>
      ),
    },
    {
      title: t('irrigationForm.objectTable.category'),
      dataIndex: 'categoryObjectId',
      key: 'categoryObjectId',
      xtype: CustomTableColumnType.RenderCustom,
      width: 160,
      renderCustom: ({ record }) => (
        <span>
          {objectCategories.find(
            (category) => category.categoryId === record.objectCategoryId,
          )?.groupTitle || '-'}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.currentGroup - b.currentGroup,
      },
    },
    {
      title: '...',
      dataIndex: 'actions',
      key: 'actions',
      width: 60,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];
  return { columns };
};
