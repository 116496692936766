import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

type ReturnType = {
  columns: TableColumn[];
};

export const useMonitoringPointsTableColumns = (): ReturnType => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 60,
    },
    {
      title: 'Наименование точки',
      dataIndex: 'pointName',
      width: 170,
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Вид точки',
      dataIndex: 'type',
      align: 'center',
      xtype: CustomTableColumnType.String,
      key: 'address',
      width: 200,
    },
    {
      title: t('main.table.address'),
      dataIndex: 'region',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        const address = [
          record.regionName,
          record.districtName,
          record.okmotName,
        ];
        return address.filter(Boolean).join(', ');
      },
      width: 200,
    },
    {
      title: 'Действия',
      align: 'center',
      dataIndex: 'actions',
      width: 100,
      actions: [CustomTableAction.EditWithoutText, CustomTableAction.Archive],
      xtype: CustomTableColumnType.ActionColumn,
    },
  ];

  return { columns };
};
