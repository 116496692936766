import { CloseOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function renderDecadeCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { dataIndex, parentDataIndex, decadeType } = customColumn;
  const decadeValue =
    record.months[dataIndex]?.decadeDtos[parentDataIndex || '1'];

  let value;

  switch (decadeType) {
    case 'fact':
      value = decadeValue?.fact;
      break;
    case 'plan':
      value = decadeValue?.plan;
      break;
    case 'remains':
      value = record.months[dataIndex]?.residualValueForDecade;
      break;
    default:
      value = 0;
      break;
  }
  return record.months[dataIndex] ? (
    <div
      className={`${styles.decadeCell} 
      ${decadeValue?.isAdminEdited && styles.adminEditedCell}`}
    >
      {value || 0}
    </div>
  ) : (
    <div className={styles.xMarkWrapper}>
      <CloseOutlined className={styles.xmark} />
    </div>
  );
}
