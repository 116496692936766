import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IReservoirDataByMonthFilters } from '@models/reservoir/interfaces/reservoir';
import { getReservoirMonitoringFiltersSelector } from '@store/selectors/reservoirMonitoring/reservoirMonitoring';
import { reservoirMonitoringActions } from '@store/slices/reservoirMonitoring/reservoirMonitoringSlice';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IReservoirDataByMonthFilters>;
  setReservoirMonitoringFilters: (
    filters: IReservoirDataByMonthFilters,
  ) => void;
};

export const useReservoirMonitoringFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getReservoirMonitoringFiltersSelector);

  const setReservoirMonitoringFilters = useCallback(
    (newFilters: IReservoirDataByMonthFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        reservoirMonitoringActions.changeReservoirMonitoringFilters(
          values as IReservoirDataByMonthFilters,
        ),
      );
    },
    [],
  );

  return { filters, setReservoirMonitoringFilters };
};
