import { Layout } from 'antd';

import { CreateHydraulicSectionModal } from '@features/hydraulicSection/createHydraulicSectionModal/CreateHydraulicSectionModal';
import { HydraulicSectionEditor } from '@features/hydraulicSection/hydraulicSectionEditor/HydraulicSectionEditor';

export const HydraulicSectionEditorLayout: React.FC = () => {
  return (
    <Layout>
      <HydraulicSectionEditor />
      <CreateHydraulicSectionModal />
    </Layout>
  );
};
