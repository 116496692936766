import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IAnnualContractualWaterSupplyFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { getAnnualWaterSupplyFiltersSelector } from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

type ReturnType = {
  filters: Partial<IAnnualContractualWaterSupplyFilters>;
  setAnnualWaterSupplyFilters: (
    filters: Partial<IAnnualContractualWaterSupplyFilters>,
  ) => void;
};

export const useAnnualWaterSupplyTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getAnnualWaterSupplyFiltersSelector);

  const setAnnualWaterSupplyFilters = useCallback(
    (newFilters: Partial<IAnnualContractualWaterSupplyFilters>) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      setParams(params);
      dispatch(
        annualApplicationActions.changeAnnualWaterSupplyFilters(
          newFilters as Required<IAnnualContractualWaterSupplyFilters>,
        ),
      );
    },
    [],
  );

  return { filters, setAnnualWaterSupplyFilters };
};
