import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const TwoPeople: React.FC<IconProps> = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2345_50939)">
        <path
          d="M9.83784 0C11.2428 0 12.5902 0.559481 13.5836 1.55536C14.577 2.55125 15.1351 3.90195 15.1351 5.31034C15.1351 6.71874 14.577 8.06944 13.5836 9.06532C12.5902 10.0612 11.2428 10.6207 9.83784 10.6207C8.43291 10.6207 7.08552 10.0612 6.09208 9.06532C5.09865 8.06944 4.54054 6.71874 4.54054 5.31034C4.54054 3.90195 5.09865 2.55125 6.09208 1.55536C7.08552 0.559481 8.43291 0 9.83784 0ZM20.4324 3.7931C21.6367 3.7931 22.7916 4.27266 23.6431 5.12627C24.4946 5.97989 24.973 7.13764 24.973 8.34483C24.973 9.55202 24.4946 10.7098 23.6431 11.5634C22.7916 12.417 21.6367 12.8966 20.4324 12.8966C18.6768 12.8966 17.1632 11.8952 16.4065 10.4386C17.7261 8.73084 18.3386 6.57906 18.1168 4.43035C18.7978 4.02069 19.5849 3.7931 20.4324 3.7931ZM0 19.3448C0 16.2041 4.40432 13.6552 9.83784 13.6552C15.2714 13.6552 19.6757 16.2041 19.6757 19.3448V22H0V19.3448ZM28 22H22.7027V19.3448C22.7027 17.7821 22.1578 16.3559 21.2649 15.3241C25.1395 15.84 28 17.6152 28 19.7241V22Z"
          fill="#4B60D8"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2345_50939">
          <rect width="28" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
