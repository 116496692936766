import React from 'react';

import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { type JsonRecord } from '@models/jsonRecord';
import { type TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderHeaderStringCell } from './renderHeaderStringCell';
import { renderHeaderStringWithActionCell } from './renderHeaderStringWithActionCell';

export class RendererHeaderCellFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { ytype } = props.customColumn;
    let renderer = renderHeaderStringCell;

    switch (ytype) {
      case CustomTableColumnHeaderType.String:
        renderer = renderHeaderStringCell;
        break;
      case CustomTableColumnHeaderType.StringWithAction:
        renderer = renderHeaderStringWithActionCell;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
