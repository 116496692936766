import { createSelector } from '@reduxjs/toolkit';
import { WateringPlanState } from '@store/slices';

type WithWateringPlanState = {
  wateringPlan: WateringPlanState;
};

export const wateringPlanStateSelector = (
  state: WithWateringPlanState,
): WateringPlanState => state.wateringPlan;

export const getShowSchedulePrintSelector = createSelector(
  wateringPlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowSchedulePrintModal;
  },
);

export const getShowSupplyPrintSelector = createSelector(
  wateringPlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowSupplyPrintModal;
  },
);

export const getShowWateringFiltersSelector = createSelector(
  wateringPlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.wateringPlanFilters;
  },
);

export const getShowWaterSupplyFiltersSelector = createSelector(
  wateringPlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.waterSupplyFilters;
  },
);
