import { EntityTypesEnum } from '@enums/entityTypes';
import { ACCESS } from '@enums/permisson';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useFindAllIrrigationSystemsOwnersQuery,
  useFindDictionaryByTypeIdQuery,
  useGetAllBasinQuery,
  useGetSourcesQuery,
} from '@store/gisproApi';

export const useIrrigationSystemColumns = (isArchived = false) => {
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: district = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: systemTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.IrrigationSystemsType,
  });

  const { data: bassins = [] } = useGetAllBasinQuery();

  const { data: owners = [] } = useFindAllIrrigationSystemsOwnersQuery();

  const { data: sources = [] } = useGetSourcesQuery({});

  const columns: TableColumn[] = [
    {
      title: 'Регистрационный номер',
      dataIndex: 'regNum',
      key: 'regNum',
      align: 'left',
      xtype: CustomTableColumnType.NumberColumn,
      width: 150,
    },
    {
      title: 'Наименование системы',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'name',
      width: 240,
      align: 'left',
    },
    {
      title: 'Тип',
      dataIndex: 'systemTypeId',
      key: 'systemTypeId',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      renderCustom: ({ record }) => {
        return (
          <div>
            {systemTypes.find((item) => item.id === record.systemTypeId)
              ?.title || '-'}
          </div>
        );
      },
    },
    {
      title: 'Адрес: Обл., р-н., источник, бассейн',
      dataIndex: 'districtId',
      key: 'districtId',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      width: 200,
      renderCustom: ({ record }) => {
        const text = [
          regions.find((item) => item.id === record.regionId)?.title,
          district.find((item) => item.id === record.districtId)?.title,
          sources.find((item) => item.sourceId === record.baseId)?.name,
          bassins.find((item) => item.id === record.bassinId)?.name,
        ];
        return <div>{text.filter(Boolean).join(', ') || '-'}</div>;
      },
    },
    {
      title: 'Владелец',
      dataIndex: 'ownerId',
      key: 'ownerId',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      renderCustom: ({ record }) => {
        return (
          <div>
            {owners.find((item) => item.id === record.ownerId)?.name || '-'}
          </div>
        );
      },
    },
    {
      title: 'Общ. орошаемая площадь (ГА)',
      dataIndex: 'areaPlanting',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'area',
      width: 140,
      align: 'left',
    },
    {
      title: 'Общ. кол-во сооружений (шт)',
      dataIndex: 'amountStructures',
      key: 'amountStructures',
      xtype: CustomTableColumnType.NumberColumn,
      align: 'left',
      width: 140,
    },
    {
      title: 'Меню контроля',
      align: 'left',
      dataIndex: 'menuControl',
      key: 'menuControl',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.Card,
        CustomTableAction.Gis,
        CustomTableAction.MainConduit,
        CustomTableAction.Constructor,
      ],
      actionsAccess: [
        ACCESS.IRRIGATION_SYSTEM_CARD_VIEW,
        ACCESS.IRRIGATION_SYSTEM_GIS_VIEW,
        ACCESS.IRRIGATION_SYSTEM_WATER_PIPE_CREATE,
        ACCESS.IRRIGATION_SYSTEM_CONSTRUCTOR_VIEW,
      ],
    },
    {
      title: '...',
      align: 'left',
      dataIndex: 'actions',
      width: 150,
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actionsAccess: [ACCESS.IRRIGATION_SYSTEM_ARCHIVE],
      actions: [
        isArchived
          ? CustomTableAction.UnArchiveWithoutText
          : CustomTableAction.ArchiveWithoutText,
      ],
    },
  ];

  return { columns };
};
