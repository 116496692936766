export type CustomTableMethod = {
  name: string;
  title: string;
  icon?: string;
  confirm?: string;
  condition?: (record: any) => boolean;
}
export enum CustomTableAction {
  Edit = 'edit',
  EditWithoutText = 'edit-without-text',
  DeleteWithoutText = 'delete-without-text',
  ArchiveWithoutText = 'archive-without-text',
  UnArchiveWithoutText = 'unArchive-without-text',
  WatchWithoutText = 'watch-without-text',
  SubmitWithoutText = 'submit-without-text',

  Text = 'text',
  Disable = 'disable',
  Change = 'change',
  Archive = 'archive',
  UnArchive = 'unArchive',
  Watch = 'watch',
  Print = 'print',
  Confirm = 'confirm',
  BlueEdit = 'blue-edit',
  Delete = 'delete',
  WaterUsers = 'water-users',
  BalanceSheet = 'balance-sheet',
  Card = 'card',
  Gis = 'gis',

  MainConduit = 'main-conduit',
  Constructor = 'constructor',
  AnnualApplication = 'annual-application',

  LinkOutlet = 'link-outlet',
  IrrigationMode = 'irrigation-mode',

  TextCheck = 'text-check',
  ConfirmCheck = 'confirm-check',
  EditCheck = 'edit-check',
  PrintCheck = 'print-check',
  WatchCheck = 'watch-check',

  EnterMorningValues = 'enter-morning-values',
  EnterEveningValues = 'enter-evening-values',

  GrantingAccess = 'granting-access',
}
