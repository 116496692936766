import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import { StatusWrapper, Tabs } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAllStatusWaterUsePlanQuery } from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setRuvhStatus, setUpdateStatus } from '@store/slices';

import {
  ContractualWaterSupplyPlan,
  CropSowingOnIrrigatedLands,
  FirstWateringPlan,
  HydroWorkPlan,
  IrrigationWaterSupplyPlan,
  TotalHectareIrrigationPlan,
  TotalWaterIntakePlan,
  TotalWaterSupplyPlan,
  WaterChargingIrrigationPlanHectareSupply,
  WaterChargingIrrigationPlanWaterSupply,
} from '../ruvhTables';
import styles from './ruvhTablesTabs.module.scss';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';

export const RuvhTablesTabs = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const { data: statuses } = useGetAllStatusWaterUsePlanQuery(
    userRole === UserRoles.ROLE_RUVH ? undefined : skipToken,
  );
  const dispatch = useDispatch();
  const ruvhTableInfo = useSelector(getRuvhTablesInfoSelector);
  const getTableComponent = (index: number) => {
    switch (index) {
      case 0:
        return <TotalWaterIntakePlan />;
      case 1:
        return <TotalWaterSupplyPlan />;
      case 2:
        return <IrrigationWaterSupplyPlan />;
      case 3:
        return <WaterChargingIrrigationPlanWaterSupply />;
      case 4:
        return <WaterChargingIrrigationPlanHectareSupply />;
      case 5:
        return <FirstWateringPlan />;
      case 6:
        return <TotalHectareIrrigationPlan />;
      case 7:
        return <ContractualWaterSupplyPlan />;
      case 8:
        return <CropSowingOnIrrigatedLands />;
      case 9:
        return <HydroWorkPlan />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (statuses) {
      statuses.map((status: any) =>
        dispatch(
          setUpdateStatus({ status: status.statusRuvh, type: status.type }),
        ),
      );
    }
  }, [statuses]);

  const dynamicOptions = (arr: any[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.ANNUAL_WATERSHED_VIEW,
      ACCESS.ANNUAL_SUPPLY_VIEW,
      ACCESS.ANNUAL_IRRIGATION_VIEW,
      ACCESS.ANNUAL_IRRIGATION_SUPPLY_VIEW,
      ACCESS.ANNUAL_IRRIGATION_HECTARES_VIEW,
      ACCESS.ANNUAL_FIRST_WATERING_VIEW,
      ACCESS.ANNUAL_IRRIGATION_TOTAL_HECTARES_VIEW,
      ACCESS.ANNUAL_CONTRACTUAL_WATER_SUPPLY_VIEW,
      ACCESS.ANNUAL_IRRIGATED_SOWING_VIEW,
      ACCESS.ANNUAL_HYDROMETRIC_WORK_VIEW,
    ]);

    const validPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validPermissions;
  };

  const tabsItems = dynamicOptions(ruvhTableInfo).map((info, index) => {
    const label =
      userRole === UserRoles.ROLE_RUVH ? (
        <StatusWrapper status={info.status}>
          <span>{info.type}</span>
        </StatusWrapper>
      ) : (
        <span>{info.type}</span>
      );

    return {
      key: `${index + 1}`,
      label,
      children: getTableComponent(index),
    };
  });

  return (
    <Row gutter={[0, 0]}>
      <Col span={24} className={styles.segmented}>
        <Tabs items={tabsItems} />
      </Col>
    </Row>
  );
};
