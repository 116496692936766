import { useCallback, useEffect, useMemo, useState } from 'react';

import { Table } from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';

import { JsonRecord } from '@models/jsonRecord';
import { ICreateReservoirData } from '@models/reservoir/interfaces/reservoir';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateReservoirDataMutation,
  useCreateStartVolumeMutation,
  useGetReservoirCurrentVolumeQuery,
  useGetReservoirDataMonthQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { ReservoirMonitoringMask } from '../reservoirMonitoringMask/ReservoirMonitoringMask';
import { ReservoirMonitoringSetCurrentVolumeModal } from '../reservoirMonitoringSetCurrentVolumeModal/ReservoirMonitoringSetCurrentVolumeModal';
import { useReservoirMonitoringFilters } from '../useReservoirMonitoringFilters';
import { mapReservoirMonitoringTableData } from './mapReservoirMonitoringTableData';
import { RenderDateWithAction } from './RenderDateWithAction';
import styles from './ReservoirMonitoringTable.module.scss';
import { useParams } from 'react-router-dom';

export const ReservoirMonitoringTable: React.FC = () => {
  const { filters } = useReservoirMonitoringFilters();
  const [save] = useCreateReservoirDataMutation();
  const { id: reservoir } = useParams();
  const { data, isLoading, isFetching } = useGetReservoirDataMonthQuery(
    reservoir && filters.month && filters.year
      ? { ...filters, reservoirId: Number(reservoir) }
      : skipToken,
  );
  const { data: currentReservoirVolume, error: currentReservoirVolumeError } =
    useGetReservoirCurrentVolumeQuery(
      reservoir && filters.year
        ? {
            ...filters,
            reservoirId: Number(reservoir),
          }
        : skipToken,
    );
  const [createVolume] = useCreateStartVolumeMutation();

  const [reservoirVolumeError, setReservoirVolumeError] = useState(false);

  const dataSource = useMemo(
    () => data && mapReservoirMonitoringTableData(data),
    [data],
  );

  const saveReservoirData = useCallback((values: ICreateReservoirData) => {
    save(values);
  }, []);

  const onSetStartVolume = useCallback(
    (values: { beginningVolume: number }) => {
      if (reservoir && filters.year) {
        createVolume({
          beginningVolume: toInteger(values.beginningVolume),
          reservoirId: toInteger(reservoir),
          year: toInteger(filters.year),
        });
      }
    },
    [filters],
  );

  const getLastExistingCurrentVolumeDate = useCallback(() => {
    if (!dataSource) return null;
    const lastExistingCurrentVolumeDateIndex = dataSource.findIndex(
      (item) => !item.currentVolume,
    );
    return dataSource[lastExistingCurrentVolumeDateIndex + 1];
  }, [dataSource]);

  useEffect(() => {
    setReservoirVolumeError(!!currentReservoirVolumeError);
  }, [currentReservoirVolumeError]);

  return (
    <div className={styles.wrapper}>
      {reservoirVolumeError && (
        <ReservoirMonitoringSetCurrentVolumeModal
          action={onSetStartVolume}
          onCancel={() => setReservoirVolumeError(false)}
        />
      )}
      {!dataSource && !reservoirVolumeError ? (
        <ReservoirMonitoringMask maskText="Для начала работы выберите год" />
      ) : (
        <Table
          loading={isLoading || isFetching}
          dataSource={dataSource || []}
          bordered
          size="small"
          pagination={false}
        >
          <Column
            dataIndex="name"
            title="Дата"
            align="center"
            fixed="left"
            render={(value, record) => (
              <RenderDateWithAction
                value={value}
                record={record}
                action={saveReservoirData}
                getLastExistingCurrentVolumeDate={
                  getLastExistingCurrentVolumeDate
                }
              />
            )}
          />
          <Column title="Объем м3" align="center" dataIndex="currentVolume" />
          <ColumnGroup title="Сработка">
            <Column
              title="Всего м3/с"
              align="center"
              dataIndex="outflowVolume"
            />
            <Column title="Всего млн.м3" align="center" dataIndex="outflow" />
          </ColumnGroup>

          <ColumnGroup title="Сработка Кыргызстан">
            <Column title=" м3/с" align="center" dataIndex="outfluxKr" />
            <Column
              title=" млн.м3"
              align="center"
              dataIndex="outfluxVolumeKr"
            />
          </ColumnGroup>

          <ColumnGroup title="Сработка другие страны">
            <Column
              title=" м3/с"
              align="center"
              dataIndex="outfluxOtherCountries"
            />
            <Column
              title=" млн.м3"
              align="center"
              dataIndex="outfluxVolumeOtherCountries"
            />
          </ColumnGroup>

          <ColumnGroup title="Приток">
            <Column title=" м3/с" align="center" dataIndex="influxVolume" />
            <Column title=" млн.м3" align="center" dataIndex="influx" />
          </ColumnGroup>
        </Table>
      )}
    </div>
  );
};
