import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton, DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { Row, Col, Typography, Divider, Form, notification } from 'antd';
import { IModalState } from '@utils/modalHook/modalHook';
import { TotalInfoModalTypes } from '@features/waterUser/waterUserCard/modals/totalInfoModal';
import {
  useFindAllBanksQuery,
  useFindAllFilialQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useUpdateOwnerInfoByIdMutation,
  useUpdateOwnerInfoMutation,
} from '@store/gisproApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { useGetCurrentUser } from '@features/authentication';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';

type TotalInfoModalProps = {
  modalState: IModalState<TotalInfoModalTypes>;
  dataSource: any;
};

const TITLES: Record<TotalInfoModalTypes, string> = {
  main: 'Основные данные',
  bank: 'Банковские реквизиты',
  contacts: 'Контактные данные',
  passport: 'Паспортные данные',
};

export const TotalInfoModal = ({
  modalState,
  dataSource,
}: TotalInfoModalProps) => {
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const [selectedRegion, setSelectedRegion] = useState('');
  const parentBankId = Form.useWatch('parentBankId', form);
  const [updateOwner] = useUpdateOwnerInfoMutation();
  const [updateOwnerById] = useUpdateOwnerInfoByIdMutation();
  const { data: banks = [] } = useFindAllBanksQuery();
  const { data: filials = [], isLoading: filialIsLoading } =
    useFindAllFilialQuery(parentBankId ? { bankId: parentBankId } : skipToken);
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: districts = [] } = useFindReferencesByParentIdQuery({
    parentId: parseInt(dataSource?.region?.id, 10),
  });
  const { data: okmots = [] } = useFindReferencesByParentIdQuery({
    parentId: parseInt(dataSource.district?.id, 10),
  });
  const { data: economicActivity = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.TypeEconomicActivity,
  });
  const { data: gked = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Gked,
  });

  const { isOpen, data, close } = modalState;
  const isMain = data === 'main';
  const isPassport = data === 'passport';
  const regionId = Form.useWatch('regionId', form);

  const onCancel = () => {
    close();
  };

  const initialValues = {
    ...dataSource,
    district: user?.owner?.district?.id,
    region: user?.owner?.region?.id,
    gkedClassification: dataSource?.gked?.id,
    economicActivity: dataSource?.economicActivity?.id,
    okmot: dataSource?.okmot?.id,
    bankId: dataSource?.bank?.id,
    parentBankId: dataSource?.bank?.parent?.id,
  };
  const onSubmit = (values: any) => {
    const handleUpdate = (promise: any) => {
      promise
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Данные успешно обновлены',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Не удалось обновить данные',
          });
        })
        .finally(close);
    };

    if (id) {
      handleUpdate(updateOwnerById({ id, body: values }));
    } else {
      handleUpdate(updateOwner({ body: values }));
    }
  };
  const Footer = (
    <Row gutter={10} justify="center">
      <Col>
        <DefaultButton size="large" onClick={onCancel}>
          Отмена
        </DefaultButton>
      </Col>
      <Col>
        <PrimaryButton
          size="large"
          onClick={() => form.submit()}
        >
          Изменить
        </PrimaryButton>
      </Col>
    </Row>
  );
  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const handleChangeBank = () => {
    form.setFieldValue('bankId', null);
  };

  return (
    <CustomModal
      centered
      open={isOpen}
      onCancel={onCancel}
      width={830}
      footer={Footer}
      closeIcon={CloseIcon}
      title={TITLES[data]}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        {isMain && (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Наименование"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="ИНН" name="inn" rules={[{ required: true }]}>
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="ОКПО"
                  name="okpo"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Юридический адрес"
                  name="address"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Область"
                  name="region"
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.region?.id}
                >
                  <TooltipedSelect
                    size="large"
                    options={regions}
                    onChange={(v) => setSelectedRegion(v)}
                    disabled={isDisabledRegion}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Район"
                  name="district"
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.district?.id}
                >
                  <TooltipedSelect
                    disabled={isDisabledDistrict || !regionId}
                    size="large"
                    options={districts}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Город/село" name="okmot">
                  <TooltipedSelect size="large" options={okmots} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Номер телефона"
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                    phoneNumberValidator,
                  ]}
                  normalize={mapPhoneNumberToLong}
                >
                  <TooltipedInput
                    onChange={changeCursorPhoneNumberInput}
                    addonBefore="+996"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, type: 'email' }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Фактический вид экономической деятельности"
                  name="economicActivity"
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect size="large" options={economicActivity} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="ГКЭД" name="gkedClassification">
                  <TooltipedSelect size="large" options={gked} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Общая площадь земель ГА" name="totalArea">
                  <TooltipedInput type="number" size="large" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {isPassport && (
          <>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Наименование банка"
                  name="parentBankId"
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    onChange={handleChangeBank}
                    options={banks.map((bank) => ({
                      value: bank.id,
                      label: bank.bankName,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Расчетный счет"
                  name="accountNumber"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Название филиала"
                  name="bankId"
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    options={filials?.map((item) => ({
                      label: item.bankName,
                      value: item.id,
                    }))}
                    disabled={!parentBankId}
                    loading={filialIsLoading}
                    allowClear
                    placeholder="Введите название филиала"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Корреспондентский счет"
                  name="organizationAccount"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </CustomModal>
  );
};
