import React, { useState } from 'react';

import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { InformationalBlock, Segmented } from '@features/ui-kit';
import { informationalMonitoringType } from '@models/geoPortal/enums/geoPortalEnum';

import { ImportantList } from './importantList/ImportantList';
import { Instructions } from './instructions/Instructions';

const options: SegmentedLabeledOption[] = [
  {
    value: informationalMonitoringType.Important,
    label: 'Важные',
  },
  {
    value: informationalMonitoringType.Instruction,
    label: 'Инструкция',
  },
];

export const InformationMonitoring: React.FC = () => {
  const [activeTab, setActiveTab] = useState<informationalMonitoringType>(
    informationalMonitoringType.Important,
  );

  const changeContentHandle = (value: informationalMonitoringType) => {
    setActiveTab(value);
  };

  let Component;
  switch (activeTab) {
    case informationalMonitoringType.Important:
      Component = ImportantList;
      break;
    case informationalMonitoringType.Instruction:
      Component = Instructions;

      break;
    default:
      Component = ImportantList;
      break;
  }

  return (
    <div style={{ height: '100%' }}>
      <InformationalBlock title="Информация">
        <div>
          <Segmented
            options={options}
            onChange={(value) =>
              changeContentHandle(value as informationalMonitoringType)
            }
          />
        </div>
        <Component />
      </InformationalBlock>
    </div>
  );
};
