import { Routes } from '@enums/routes';
import {
  CustomCard,
  DangerButton,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
  TableCustom
} from '@features/ui-kit';
import { WaterQualityTable } from '@features/waterQuality/tables';
import {
  useWaterQualityCardSecondStepTableColumns
} from '@features/waterQuality/waterQualityCard/waterQualityCardSecondStep/waterQualityCardSecondStepTable/useWaterQualityCardSecondStepTableColumns';
import { WaterQualityTableFilters } from '@models/waterQuality/interfaces/waterQuality';
import { useCreateZoneMutation } from '@store/gisproApi';
import {
  getMetricsCollectionPointType,
  getWaterQualityCardSelector,
  getWaterQualityMonitoringTypeSelector,
} from '@store/selectors';
import { setMetricsCollectionPointType, setWaterQualityCard, } from '@store/slices';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCreatePointCollectionModal } from '../modals/useCreatePointCollectionModal';
import styles from './WaterQualityCardSecondStep.module.scss';
import { WaterQualityCardSecondStepTable } from './waterQualityCardSecondStepTable';

export const WaterQualityCardSecondStep: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createZone] = useCreateZoneMutation();

  const monitoringType = useSelector(getWaterQualityMonitoringTypeSelector);
  const waterQuality = useSelector(getWaterQualityCardSelector);
  const { columns } = useWaterQualityCardSecondStepTableColumns(monitoringType, true);

  const onCancelClick = () => {
    dispatch(setWaterQualityCard({}));
    dispatch(setMetricsCollectionPointType({}));
    navigate(Routes.WaterQualityTable);
  };

  const onNextClick = () => {
    console.log('waterQuality', waterQuality);
    createZone(waterQuality);
    dispatch(setWaterQualityCard({}));
    dispatch(setMetricsCollectionPointType({}));
    navigate(
      `${Routes.WaterQualityTable}?${WaterQualityTable}?section=${WaterQualityTableFilters.DictionaryZone}`,
    );
  };

  const deleteItem = (val: any) => {
    console.log('val', val, waterQuality.points);
    dispatch(
      setWaterQualityCard({
        ...waterQuality,
        points: waterQuality.points.filter((point: any) => point.id !== val.id),
      }),
    );
  };

  return (
    <div className={styles.firstStepWrapper}>
      <InformationalBlock title="Создание точки сбора показателей">
        <CustomCard title={
          <Row justify="space-between">
            <Col><DangerButton onClick={onCancelClick}>Отмена</DangerButton></Col>
            <Col>
              <PrimaryButton
                disabled={!waterQuality?.points?.length}
                onClick={onNextClick}
              >{waterQuality.zoneId ? 'Обновить' : 'Создать'}</PrimaryButton>
            </Col>
          </Row>
        }>
          <Row gutter={20} justify="end">
            <Col span={12}>
              <WaterQualityCardSecondStepTable/>
            </Col>
            <Col span={12}>
              <Typography.Title level={4}>Добавленные точки</Typography.Title>
              <TableCustom
                columns={columns}
                scroll={{ y: 250 }}
                actionProps={{
                  deleteItem
                }}
                dataSource={waterQuality.points || []}
              />
            </Col>
          </Row>
        </CustomCard>
      </InformationalBlock>
    </div>
  );
};
