import { IAttachment } from '@models/attachments/interfaces/attachment';
import { IWaterObjectFilters } from '@models/waterObject/interfaces/waterObject';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';
import { IWearDto } from '@models/waterObject/interfaces/wearDto';
import { IWorkDto } from '@models/waterObject/interfaces/workDto';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import concat from 'lodash/concat'; // TODO: change lodash to native implementation
import { SystemObject } from '@models/systemObject/systemObject';

export type WaterObjectState = {
  waterObjectFilters: Partial<IWaterObjectFilters>;
  isShowWorkVolume: boolean;
  isShowWorkMark: boolean;
  isShowWearSum: boolean;
  isShowSize: boolean;
  isShowTechStats: boolean;
  isShowConstructorStats: boolean;
  isCreateWaterSectionModalShown: boolean;
  isWaterObjectStepperActionSelectModalShown: boolean;
  selectedWorkDto: IWorkDto | null;
  selectedWaterSection: IWaterSection | null;
  waterObject: Partial<SystemObject>;
  initialValuesWearSum: any;
  initialValuesWorkMark: any;
};

const initialState: WaterObjectState = {
  waterObjectFilters: {
    page: 1,
    size: 10,
  },
  isShowWorkVolume: false,
  isShowWorkMark: false,
  isShowWearSum: false,
  isShowTechStats: false,
  isShowConstructorStats: false,
  isShowSize: false,
  isCreateWaterSectionModalShown: false,
  isWaterObjectStepperActionSelectModalShown: false,
  selectedWorkDto: null,
  selectedWaterSection: null,
  waterObject: {},
  initialValuesWearSum: null,
  initialValuesWorkMark: null,
};

const waterObjectSlice = createSlice({
  name: 'waterObject',
  initialState,
  reducers: {
    changeWaterObjectFilters: (
      state,
      action: PayloadAction<IWaterObjectFilters>,
    ) => {
      return {
        ...state,
        waterObjectFilters: action.payload,
      };
    },
    setShowWaterObjectStepperActionSelectModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isWaterObjectStepperActionSelectModalShown: action.payload,
      };
    },
    setWaterObject: (state, action: PayloadAction<Partial<SystemObject>>) => {
      return {
        ...state,
        waterObject: action.payload,
      };
    },
    setShowWorkVolume: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWorkVolume: action.payload,
      };
    },
    setSelectedWorkVolumeAction: (state, action: PayloadAction<IWorkDto>) => {
      return {
        ...state,
        selectedWorkDto: action.payload,
      };
    },
    setSelectedWaterSectionAction: (
      state,
      action: PayloadAction<IWaterSection | null>,
    ) => {
      return {
        ...state,
        selectedWaterSection: action.payload,
      };
    },
    setShowWorkMark: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWorkMark: action.payload,
      };
    },
    setInitialValuesWorkMark: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        initialValuesWorkMark: action.payload,
      };
    },
    setShowWearSum: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWearSum: action.payload,
      };
    },
    setInitialValuesWearSum: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        initialValuesWearSum: action.payload,
      };
    },
    setShowSize: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowSize: action.payload,
      };
    },
    setShowTechStats: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowTechStats: action.payload,
      };
    },
    setShowConstructorStats: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowConstructorStats: action.payload,
      };
    },
    addWorkMark: (state, action: PayloadAction<IWorkDto>) => {
      const workDtos: IWorkDto[] = concat(
        state.waterObject?.workDtos || [],
        action.payload,
      );
      return {
        ...state,
        waterObject: {
          ...state.waterObject,
          workDtos,
        },
      };
    },
    addWearSum: (state, action: PayloadAction<IWearDto>) => {
      const wearDtos: IWearDto[] = concat(
        state.waterObject?.wearDtos || [],
        action.payload,
      );
      return {
        ...state,
        waterObject: {
          ...state.waterObject,
          wearDtos,
        },
      };
    },
    setShowCreateWaterSectionModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isCreateWaterSectionModalShown: action.payload,
      };
    },
    addDocumentToWaterObjectCardAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentIds = concat(
        state.waterObject.attachmentIds || [],
        action.payload,
      );
      return {
        ...state,
        waterObject: {
          ...state.waterObject,
          attachmentIds,
        },
      };
    },
  },
});

export const { actions: waterObjectActions } = waterObjectSlice;

export default waterObjectSlice.reducer;
