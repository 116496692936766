import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useGetReclamationStatesQuery } from '@store/gisproApi';

export const useReclamationSoilDataTableColumns = (reclamationData: any) => {
  const { data: reclamationStates = [] } = useGetReclamationStatesQuery();
  const columns: TableColumn[] = [
    {
      title: 'Наименование шкалы',
      dataIndex: 'zoneName',
      key: 'zoneName',
      align: 'center',
      width: 135,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Показатели',
      dataIndex: 'reclamationStateSoil',
      align: 'center',
      width: 100,
      xtype: CustomTableColumnType.SelectColumn,
      editableCell: true,
      options: reclamationStates?.map((item) => ({
        label: item,
        value: item,
      })),
      additionalData: reclamationData,
    },
  ];

  return { columns };
};
