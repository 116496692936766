import { Col, Row } from 'antd';
import { GeoPortalMapNavigation } from '@features/geoPortal/new/geoPortalMap/geoPortalMapNavigation';
import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { GeoPortalMapAttributes } from '@features/geoPortal/new/geoPortalMap/geoPortalMapAttributes/GeoPortalMapAttributes';
import { MapComponent } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponent';
import { useContext, useEffect } from 'react';
import { GeoPortalMapObjectDetails } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/GeoPortalMapObjectDetails';
import { GeoPortalCloseIcon } from '@icons/GeoPortalCloseIcon';
import { SaveMap } from '@features/geoPortal/new/geoPortalMap/saveMap/SaveMap';
import { useParams } from 'react-router-dom';
import { GeoPortalRegionAndArea } from '@constants/geoPortal';
import regionData from '../../../../data/geoBoundaries-KGZ-ADM1_simplified.json';
import areaData from '../../../../data/geoBoundaries-KGZ-ADM2_simplified.json';
import { GeoJsonType } from '@models/geoPortal/interfaces/GeoPortal';
import { useGetCurrentUser } from '@features/authentication';
export const GeoPortalMap: React.FC = () => {
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;
  const {
    currentAttribute,
    selectedLayer,
    setSelectedLayer,
    searchActive,
    getCoordinateRegion,
  } = useContext(MapContext);
  const onCloseClick = () => {
    setSelectedLayer(null);
  };


  const { user } = useGetCurrentUser();
  const userCode = user?.owner?.region?.code;

  const findCoordinate = (geoJson: GeoJsonType, searchByName: string) => {
    return geoJson.features.find(
      (item) => item.properties.shapeName === searchByName,
    );
  };

  useEffect(() => {
    const regionCode = `_${filterKey.split(':')[2]
      }` as keyof typeof GeoPortalRegionAndArea;
    const сode = filterKey.split(':')[1] === 'irrigation-system' ? `_${userCode}` : regionCode;
    const searchByName = GeoPortalRegionAndArea[сode];

    if (!searchByName) {
      return;
    }

    const geoJsonRegion = regionData as GeoJsonType;
    const geoJsonArea = areaData as GeoJsonType;
    let data = null;

    const coordinateRegion = findCoordinate(geoJsonRegion, searchByName);
    const coordinateArea = findCoordinate(geoJsonArea, searchByName);
    const coordinate = coordinateRegion || coordinateArea;

    if (!coordinate) {
      return;
    }

    data = {
      coordinate: coordinate.geometry.coordinates,
      code: regionCode,
      type: coordinate.geometry.type,
    };

    if (data) {
      getCoordinateRegion(data.coordinate, data.code, data.type);
    }
  }, [filterKey, regionData, areaData, userCode]);

  return (
    <div>
      <Row>
        <Col style={{ height: '100vh' }}>
          <GeoPortalMapNavigation />
          {(selectedLayer || searchActive) && (
            <>
              <GeoPortalMapAttributes layer={selectedLayer} />
              <div
                style={{
                  position: 'absolute',
                  top: 6,
                  left: 559,
                  padding: '8px',
                  width: 26,
                  height: 38,
                  zIndex: 11,
                  background: '#ffffff',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    position: 'relative',
                    top: 3,
                    right: 4,
                  }}
                  onClick={onCloseClick}
                >
                  <GeoPortalCloseIcon />
                </div>
              </div>
            </>
          )}
        </Col>
        <Col flex="1 0" style={{ height: '100vh' }}>
          <MapComponent />
          <GeoPortalMapObjectDetails />
        </Col>
      </Row>

      {nameParamArchive !== 'archive' && <SaveMap />}
    </div>
  );
};
