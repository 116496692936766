import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  notification,
  Row,
  TablePaginationConfig,
  Typography,
} from 'antd';

import { CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  CustomModal,
  DangerButton,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { ActsInvoicesForPaymentsStatusEnum } from '@models/actsInvoicesForPayments/enums/actsInvoicesForPaymentsEnum';
import {
  IActsInvoicesForPayments,
  IActsInvoicesForPaymentsFilters,
} from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useActToBillMutation,
  useFindAllActsInvoicesForPaymentsQuery,
} from '@store/gisproApi';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { t } from 'i18next';
import { isEmpty } from '@utils/utils';

import { ActsInvoicesForPaymentsFilters } from './actsInvoicesForPaymentsFilters';
import styles from './ActsInvoicesForPaymentsTable.module.scss';
import { ActsInvoicesForPaymentsTableContainer } from './actsInvoicesForPaymentsTableContainer';
import { useInvoicesPaymentColumns } from './actsInvoicesForPaymentsTableContainer/useInvoicesPaymentColumns';
import { ConfirmActModal, useConfirmActModal } from './confirmActModal';
import { ConfirmCheckModal, useConfirmCheckModal } from './confirmCheckModal';
import { EditActModal, useEditActModal } from './editActModal';
import { PreviewActModal, usePreviewActModal } from './previewActModal';
import { PreviewCheckModal, usePreviewCheckModal } from './previewCheckModal';
import { useActsInvoicesForPaymentsFilters } from './useActInvoicesForPaymentsFilters';

export const ActsInvoicesForPaymentsTable = () => {
  const [params, setSearchParams] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();

  const {
    setShowModal: setShowActModal,
    setSelectedActsInvoicesForPayment: setSelectedActsInvoicesForPaymentId,
  } = useConfirmActModal();

  const { setShowModal: setShowCheckModal } = useConfirmCheckModal();
  const {
    setShowModal: setShowPreviewActModal,
    isModalShown: isModalShownPreviewActModal,
    selectedActsInvoicesForPayment: selectedActsInvoicesForPaymentAct,
  } = usePreviewActModal();
  const {
    setShowModal: setShowPreviewCheckModal,
    isModalShown: isModalShownPreviewCheckModal,
    selectedActsInvoicesForPayment: selectedActsInvoicesForPaymentCheck,
  } = usePreviewCheckModal();

  const [isConfirmModalShown, setIsConfirmModalShown] = useState<{
    type: string;
    isShow: boolean;
  }>({ type: '', isShow: false });

  const { columns } = useInvoicesPaymentColumns();

  const { filters, setActsInvoicesForPaymentsFilters } =
    useActsInvoicesForPaymentsFilters();

  const { data: { content = [], totalElements = 0, ...rest } = {}, isLoading } =
    useFindAllActsInvoicesForPaymentsQuery(
      !isEmpty(filters) ? filters : skipToken,
    );

  const [save] = useActToBillMutation();

  const modifiedContent = content.map((item) => ({
    ...item,
    yearMonth: `${item.year}-${item.month}`,
  }));

  useEffect(() => {
    setHeaderTitle(t('headers.acts-invoices-for-payment'));
  }, []);

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setActsInvoicesForPaymentsFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
          } as unknown as IActsInvoicesForPaymentsFilters)
        : (newFilters as unknown as IActsInvoicesForPaymentsFilters),
    );
  }, []);

  const onCancel = () => {
    setIsConfirmModalShown({ type: '', isShow: false });
  };

  const onConfirmAct = () => {
    if (selectedActsInvoicesForPaymentAct) {
      save(selectedActsInvoicesForPaymentAct).then((data: any) => {
        if (!data?.error) {
          setIsConfirmModalShown({ type: '', isShow: false });
        } else {
          notification.error({
            message: data?.error?.data?.message || 'Ошибка подтверждения',
          });
        }
      });
    }
  };

  const onConfirmCheckAct = () => {
    if (selectedActsInvoicesForPaymentCheck) {
      const actInvoiceToUpdate: IActsInvoicesForPayments = {
        ...selectedActsInvoicesForPaymentCheck,
        id: selectedActsInvoicesForPaymentCheck.id,
      };

      save(actInvoiceToUpdate);
      setIsConfirmModalShown({ type: '', isShow: false });
    }
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setActsInvoicesForPaymentsFilters({
          ...(filters as IActsInvoicesForPaymentsFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onPrint = useCallback((actInvoice: IActsInvoicesForPayments) => {
    const url = `${process.env.REACT_APP_MAIN_BASE_URL}/rest/report/act/${actInvoice?.id}`;
    downloadAttachment({
      fileUrl: url,
      originName: 'Акт.pdf',
    } as IAttachment);
  }, []);

  const onConfirm = useCallback((actInvoice: IActsInvoicesForPayments) => {
    setSelectedActsInvoicesForPaymentId(actInvoice);
    setShowActModal(true);
  }, []);

  const onWatch = useCallback((actInvoice: IActsInvoicesForPayments) => {
    setSelectedActsInvoicesForPaymentId(actInvoice);
    setShowPreviewActModal(true);
  }, []);

  const onText = useCallback((actInvoice: IActsInvoicesForPayments) => {
    setSelectedActsInvoicesForPaymentId(actInvoice);
    setIsConfirmModalShown({ type: 'onText', isShow: true });
  }, []);

  const onWatchCheck = useCallback((actInvoice: IActsInvoicesForPayments) => {
    if (actInvoice.status !== ActsInvoicesForPaymentsStatusEnum.issued) {
      setSelectedActsInvoicesForPaymentId(actInvoice);
      setShowPreviewCheckModal(true);
    } else {
      notification.warning({
        message: 'Счет не сформирован',
      });
    }
  }, []);

  const onPrintCheck = useCallback((actInvoice: IActsInvoicesForPayments) => {
    const url = `${process.env.REACT_APP_MAIN_BASE_URL}/rest/report/payment/${actInvoice?.id}`;
    downloadAttachment({
      fileUrl: url,
      originName: 'Счет.pdf',
    } as IAttachment);
  }, []);

  const onConfirmCheck = useCallback((actInvoice: IActsInvoicesForPayments) => {
    if (actInvoice.status !== ActsInvoicesForPaymentsStatusEnum.issued) {
      setSelectedActsInvoicesForPaymentId(actInvoice);
      setShowCheckModal(true);
    } else {
      notification.warning({
        message: 'Счет не сформирован',
      });
    }
  }, []);

  const onTextCheck = useCallback((actInvoice: IActsInvoicesForPayments) => {
    setSelectedActsInvoicesForPaymentId(actInvoice);
    setIsConfirmModalShown({ type: 'onTextCheck', isShow: true });
  }, []);

  const getIsDisabled = useCallback(
    (actInvoice: IActsInvoicesForPayments) =>
      actInvoice.status === ActsInvoicesForPaymentsStatusEnum.issued ||
      actInvoice.status === ActsInvoicesForPaymentsStatusEnum.filled,
    [],
  );

  const getIsDisabledOnText = useCallback(
    (actInvoice: IActsInvoicesForPayments) =>
      actInvoice.status !== ActsInvoicesForPaymentsStatusEnum.issued &&
      actInvoice.status !== ActsInvoicesForPaymentsStatusEnum.filled,
    [],
  );

  const Footer = (
    <Row gutter={10} justify="center">
      <Col>
        <PrimaryButton type="default" size="large" onClick={onCancel}>
          Нет
        </PrimaryButton>
      </Col>
      <Col>
        <PrimaryButton
          type="primary"
          size="large"
          disabled={
            selectedActsInvoicesForPaymentAct?.status !== 'BILL_CREATED' &&
            isConfirmModalShown.type === 'onTextCheck'
          }
          onClick={
            isConfirmModalShown.type === 'onText'
              ? onConfirmAct
              : onConfirmCheckAct
          }
        >
          Да, хочу!
        </PrimaryButton>
      </Col>
    </Row>
  );

  useEffect(() => {
    setActsInvoicesForPaymentsFilters({
      page: 1,
      size: 10,
      waterUserName: '',
      actNumber: '',
      status: null,
      dateOfSubmission: '',
      outletObjectName: '',
      facilitiesName: '',
      month: null,
      year: null,
    });

    setSearchParams({
      page: '1',
      size: '10',
    });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <HomeOutlined />,
            },
            {
              title: t('Акты и счета на оплату'),
            },
          ]}
        />
        <Divider style={{ margin: '6px 0 8px' }} />
        <div className={styles.marginTop}>
          <ActsInvoicesForPaymentsFilters />
          <Divider />
          <InformationalBlock>
            <CustomCard
              style={{ padding: '8px 16px' }}
              title={
                <Row>
                  <Col>
                    <Typography.Title level={4}>Таблица</Typography.Title>
                  </Col>
                </Row>
              }
            >
              <TableCustom
                scroll={{ x: 1750 }}
                loading={isLoading}
                dataSource={modifiedContent}
                columns={columns}
                size="small"
                onChange={onTableChange}
                totalCount={totalElements}
                bordered
                actionProps={{
                  onPrint,
                  onConfirm,
                  onWatch,
                  onText,
                  onWatchCheck,
                  onPrintCheck,
                  onTextCheck,
                  onConfirmCheck,
                  getIsDisabled,
                  getIsDisabledOnText,
                }}
                customPagination={rest}
              />
              <CustomModal
                open={isConfirmModalShown.isShow}
                closeIcon={
                  <div>
                    <DangerButton icon={<CloseOutlined />} />
                  </div>
                }
                width={370}
                onCancel={onCancel}
                footer={Footer}
                title="Потверждение"
                centered
              >
                <Row className={styles.modalContent}>
                  <Col span={24} className={styles.modalContentTitle}>
                    <p>Действительно хотите подтвердить?</p>
                  </Col>
                  <Col span={24} className={styles.modalContentText}>
                    {isConfirmModalShown.type === 'onText' ? (
                      <p className={styles.modalText}>Дейтвие акта</p>
                    ) : (
                      <p className={styles.modalText}>Дейтвие счета</p>
                    )}
                  </Col>
                </Row>
              </CustomModal>
            </CustomCard>
          </InformationalBlock>
        </div>
      </div>
      <ConfirmActModal />
      <ConfirmCheckModal />
      {isModalShownPreviewActModal && <PreviewActModal />}
      <EditActModal />
      {isModalShownPreviewCheckModal && <PreviewCheckModal />}
    </>
  );
};
