import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { typeOptions } from '@features/annualApplication/annualApplicationTable/annualApplicationTableFilters';
import {
  DefaultButton,
  Divider,
  InformationalBlock,
  Mask,
  PrimaryButton,
  TooltipedSelect,
} from '@features/ui-kit';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';
import {
  getSelectedFacilityIdSelector,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';
import { isEmpty, toInteger } from '@utils/utils';

import styles from './CreateAnnualApplication.module.scss';
import { WaterUserSelection } from './waterUserSelection';

export const CreateAnnualApplication = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const facilityId = useSelector(getSelectedFacilityIdSelector);
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);
  const onCancelButtonClick = () => {
    navigate(Routes.AnnualApplicationTable);
  };

  const onNextHandle = () => {
    setSearchParams({
      [SectionQueryKey]: AnnualApplicationQueryTypes.plannedStructure,
    });
    dispatch(
      annualApplicationActions.setSelectedAgroClimatIdAction(toInteger(1)),
    );
    dispatch(annualApplicationActions.clearPlantingData());
  };

  const setWaterUserType = (type: string) => {
    dispatch(annualApplicationActions.setSelectedWaterUserTypeAction(type));
    dispatch(annualApplicationActions.setSelectedWaterUserIdAction(0));
  };
  return (
    <Row className={styles.defaultTable} gutter={[0, 8]}>
      <Col className={styles.waterUserTypeSelect}>
        <TooltipedSelect
          allowClear
          placeholder="Тип водопользователя"
          onChange={(type) => {
            setWaterUserType(type);
          }}
          value={waterUserType || 'Тип водопользователя'}
          options={typeOptions}
        />
      </Col>
      <Divider className={styles.divider} />
      {isEmpty(waterUserType) ? (
        <Col className={styles.mask} span={24}>
          <Mask maskText="Выберите тип водопользователя" />
        </Col>
      ) : (
        <>
          <Col span={24}>
            <InformationalBlock title="Общие данные">
              <WaterUserSelection />
            </InformationalBlock>
          </Col>
          <Col span={24}>
            <Row justify="end" gutter={16}>
              <Col>
                <DefaultButton
                  className={styles.buttons}
                  size="large"
                  type="default"
                  htmlType="submit"
                  onClick={onCancelButtonClick}
                >
                  Назад
                </DefaultButton>
              </Col>

              <Col>
                <PrimaryButton
                  className={styles.buttons}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  onClick={onNextHandle}
                  disabled={!facilityId || !waterUserType}
                >
                  Далее
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};
