import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row, Typography, Upload, UploadProps } from 'antd';

import { Links } from '@enums/links';
import { CloudUpload } from '@icons/CloudUpload';
import { useUploadFilesMutation } from '@store/gisproApi';

import styles from './DocsUploader.module.scss';

type CustomUploaderPropTypes = UploadProps & {
  title: string | null;
  onLoad: (v: any) => void;
  docType?: string;
  getIsDisabled?: () => boolean;
  irrigationSystemId?: string;
};

const props: UploadProps = {
  name: 'file',
};

export const DocsUploader: React.FC<CustomUploaderPropTypes> = ({
  title,
  onLoad,
  docType,
  getIsDisabled,
  irrigationSystemId,
  ...additionalProps
}) => {
  const { t } = useTranslation();
  const [uploadDocument, { error, isError }] = useUploadFilesMutation();

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);
    if (irrigationSystemId) {
      formData.append('irrigationSystemId', irrigationSystemId);
    }
    try {
      const response: any = await uploadDocument(formData);
      onLoad(response.data);
      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  const disabled = useMemo(() => {
    if (getIsDisabled) {
      return getIsDisabled();
    }
    return false;
  }, []);

  return (
    <Row gutter={[0, 8]} className={styles.block}>
      {title && (
        <Col span={24}>
          <div className={`${styles.title} ${disabled ? styles.disabled : ''}`}>
            <span
              className={`${styles.text} ${disabled ? styles.disabled : ''}`}
            >
              {title}
            </span>
          </div>
        </Col>
      )}
      <Col span={24}>
        {disabled ? (
          <div className={styles.successUpload}>
            <img src={Links.SuccessUpload} alt="successs" />
            <p
              className="ant-upload-hint"
              style={{ textAlign: 'center', width: 88 }}
            >
              Загружено
            </p>
          </div>
        ) : (
          <Upload.Dragger
            {...props}
            {...additionalProps}
            className={styles.dragger}
            showUploadList={false}
            customRequest={customRequest}
            accept={additionalProps.accept}
          >
            <p className={styles.uploadHint}>
              <CloudUpload />
              <span>Перетащите файлы сюда или нажмите чтобы загрузить</span>
            </p>
            <Button
              type="primary"
              style={{
                fontSize: 15,
                marginTop: 20,
              }}
              size="large"
              disabled={additionalProps.disabled}
              className={`${disabled ? styles.disabledButton : ''} buttonHint`}
            >
              {t('customUploader.button')}
            </Button>
          </Upload.Dragger>
        )}
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};
