import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { TablePaginationConfig } from 'antd';

import { Routes } from '@enums/routes';
import { TableCustom } from '@features/ui-kit';
import { useWaterUserPermissionModal } from '@features/waterRegistry';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { useWaterRegistryFilters } from '@features/waterUser/waterUserTable/useWaterRegistryFilters';
import { JsonRecord } from '@models/jsonRecord';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllWaterUserQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAssociationTableContainerColumns } from './useAssociationTableContainerColumns';
import { IWaterUserV2 } from '@models/waterUser/interfaces/waterUser';
import { useGetCurrentUser } from '@features/authentication/useGetCurrentUser';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { saveUrl } from '@utils/returnUrl';

export const AssociationTableContainer = () => {
  const navigate = useNavigate();
  const { columns } = useAssociationTableContainerColumns();
  const { setUserId } = useWaterUserId();
  const { user } = useGetCurrentUser();

  const saveAction = saveUrl();

  const { filters, setWaterRegistryFilters } = useWaterRegistryFilters();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();
  const { setShowModalAssociation } = useWaterUserPermissionModal();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetAllWaterUserQuery(
    isEmpty(filters)
      ? skipToken
      : { ...filters, userType: WaterUserEnum.ASSOCIATION },
  );

  console.log(content);

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterRegistryFilters({
          ...(filters as IWaterRegistryFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onHandleEdit = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record?.legalData?.owner?.id),
      }),
    );
  }, []);

  const onArchiveClick = useCallback((record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  }, []);

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const showGis = useCallback((record: JsonRecord) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:water-user:${String(record.id)}`,
    });
    window.open(url, '_blank');
  }, []);

  const showAssociationStats = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.AssociationIndicatorReports, {
        associationId: String(record?.id),
      }),
    );
    saveAction();
  }, []);

  const showWaterUserCard = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record.id),
      }),
    );
    saveAction();
  }, []);

  const showPermissions = useCallback((record: IWaterUserV2) => {
    setUserId(String(record.id));
    setShowModalAssociation(true);
  }, []);

  const showAnnualApplication = useCallback((record: IWaterUserV2) => {
    const urlParams = new URLSearchParams({
      page: 1,
      size: 10,
      regionId: record?.region || user?.owner?.region?.id || null,
      districtId: record?.district || user?.owner?.district?.id || null,
      organizationName: record?.legalData?.name,
    } as any);

    navigate({
      pathname: Routes.AnnualApplicationTable,
      search: urlParams.toString(),
    });
    saveAction();
  }, []);

  return (
    <TableCustom
      loading={isLoading || isFetching}
      dataSource={content}
      columns={columns}
      bordered
      size="small"
      onChange={onTableChange}
      totalCount={totalElements}
      customPagination={rest}
      actionProps={{
        onHandleEdit,
        onArchiveClick,
        showAssociationStats,
        showGis,
        showWaterUserCard,
        showPermissions,
        showAnnualApplication,
        onUnArchiveClick,
      }}
    />
  );
};
