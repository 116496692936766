import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useLinkOutletTableColumns = () => {
  const columns: TableColumn[] = [
    // {
    //   dataIndex: 'systemName',
    //   title: 'Наименование канала',
    //   xtype: CustomTableColumnType.String,
    //   align: 'center',
    // },
    {
      dataIndex: 'name',
      title: 'Наименование водовыпуска',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
  ];

  return { columns };
};
