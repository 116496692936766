import { useEffect, useMemo } from 'react';
import {
  generatePath,
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Col, Menu, Row } from 'antd';
import { SegmentedLabeledOption } from 'antd/es/segmented';
import { MenuProps } from 'antd/lib';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CustomSegmented } from '@features/ui-kit-v2';
import { ViewTypeMonitoring } from '@models/monitoring/enums/DemoMonitoringEnum';
import { GetAllSystemsResponse, useGetAllSystemsQuery } from '@store/gisproApi';
import { toString } from '@utils/utils';

import styles from './MonitoringLayout.module.scss';

const segments: SegmentedLabeledOption[] = [
  {
    value: ViewTypeMonitoring.Monitoring,
    label: 'Мониторинг',
  },
  {
    value: ViewTypeMonitoring.Daily,
    label: 'Суточный',
  },
  {
    value: ViewTypeMonitoring.Hydro,
    label: 'Гидропосты',
  },
];

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    children,
    label,
  } as MenuItem;
}

export const MonitoringLayout: React.FC = () => {
  const { data: systems = [] } = useGetAllSystemsQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeaderTitle();

  const recurseMenu = (
    menu: GetAllSystemsResponse[],
    parentId: number | null,
  ) => {
    const childItems = menu.filter(
      (item) => item.parent_system_id === parentId,
    );
    const children: any = childItems.map((childItem) => {
      const childLinks = recurseMenu(menu, childItem.id);
      const links =
        childLinks.length > 0
          ? getItem(childItem.display_name, `${childItem.id}`, childLinks)
          : getItem(
              <Link
                to={generatePath(Routes.DemoMonitoringSection, {
                  section: toString(childItem.id),
                })}
              >
                {childItem.display_name}
              </Link>,
              `${childItem.id}`,
            );

      return links;
    });
    return children;
  };

  const menuItems = useMemo(() => recurseMenu(systems, null), [systems]);

  const selectedKey = location.pathname.split('/').pop();

  useEffect(() => {
    if (location.pathname === '/monitoring') {
      navigate(
        generatePath(Routes.DemoMonitoringSection, { section: toString(4) }),
      );
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    setHeaderTitle('Мониторинг датчики');
  }, []);

  return (
    <Row gutter={30} className={styles.mainLayout}>
      <Col className={styles.layoutFilters} span={5}>
        <CustomSegmented
          options={segments}
          colorType="primary"
          queryParams={{
            queryKey: SectionQueryKey,
            queryType: ViewTypeMonitoring,
          }}
          block
          defaultValue={ViewTypeMonitoring.Monitoring}
        />
        <Menu
          theme="light"
          mode="inline"
          items={menuItems}
          className={styles.menu}
          defaultSelectedKeys={[toString(selectedKey)]}
          defaultOpenKeys={['14', '3', '4']}
        />
      </Col>
      <Col span={19}>
        <Outlet />
      </Col>
    </Row>
  );
};
