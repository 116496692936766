import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { Table, Typography } from 'antd';

interface IEstimatedFinancialPlanSummary {
  data: any;
  columns: any;
}

export const ContractualWaterSupplyTableSummary: React.FC<
  IEstimatedFinancialPlanSummary
> = ({ data, columns }) => {
  const summaryFn = (dataArg: any) => {
    const summaryData = dataArg?.reduce(
      (acc: any, el: any) => {
        acc.actualCultivatedArea += el.actualCultivatedArea;
        acc.irrigationNorm += el.irrigationNorm;
        acc.irrigationRate += el.irrigationRate;
        acc.tariffAverage += +el.tariffAverage;
        acc.amountPaid += el.amountPaid;
        acc.invoicedAmount += el.invoicedAmount;

        acc.contractualWaterSupplyFact += el.contractualWaterSupplyFact;
        acc.hectareIrrigationPlan += el.hectareIrrigationPlan;
        acc.hectareIrrigationPercentage += el.hectareIrrigationPercentage;
        acc.hectareIrrigationFact += el.hectareIrrigationFact;
        acc.completionPercentage += el.completionPercentage;
        acc.contractualWaterSupplyPlan += el.contractualWaterSupplyPlan;
        for (const [waterAreaName, value] of Object.entries(el.waterAreas)) {
          acc.waterAreas[waterAreaName] = formatDecimal(
            (acc.waterAreas[waterAreaName] || 0) + (value ?? 0),
          );
        }
        for (const key of Object.keys(acc)) {
          if (key !== 'waterAreas') {
            acc[key] = formatDecimal(acc[key]);
          }
        }
        return acc;
      },
      {
        actualCultivatedArea: 0,
        irrigationNorm: 0,
        irrigationRate: 0,
        contractualWaterSupplyFact: 0,
        completionPercentage: 0,
        contractualWaterSupplyPlan: 0,
        wateredAreaOther: 0,
        tariffAverage: 0,
        amountPaid: 0,
        waterAreas: {},
        invoicedAmount: 0,
        wateringFrequency: 0,
        hectareIrrigationPercentage: 0,
        hectareIrrigationPlan: 0,
        hectareIrrigationFact: 0,
      },
    );
    return summaryData;
  };

  const allRow = columns
    .map((item: any) => {
      const res = [];
      if (item.children) {
        item.children.forEach((child: any) => {
          res.push(child);
        });
      } else {
        res.push(item);
      }
      return res;
    })
    .flat();
  const generalData: any = summaryFn(data);
  return (
    <Table.Summary.Row>
      {allRow.map((item: any, index: number, arr: any) => {
        if (item.dataIndex === 'index') {
          return (
            <Table.Summary.Cell
              key={index}
              colSpan={2}
              index={0}
              align="center"
            >
              <Typography.Text strong>Итого:</Typography.Text>
            </Table.Summary.Cell>
          );
        }
        if (item.dataIndex === 'name') {
          return (
            <Table.Summary.Cell
              key={index}
              colSpan={0}
              rowSpan={0}
              index={0}
              align="center"
            />
          );
        }
        if (
          item?.dataIndex?.includes('completionPercentage') ||
          item?.dataIndex?.includes('hectareIrrigationPercentage')
        ) {
          return (
            <Table.Summary.Cell
              key={item.dataIndex}
              index={index + 1}
              align="center"
            >
              <Typography.Text>
                {formatDecimal(
                  generalData[
                    Array.isArray(item.dataIndex)
                      ? item.dataIndex[1]
                      : item.dataIndex
                  ] / data?.length,
                )}
                %
              </Typography.Text>
            </Table.Summary.Cell>
          );
        }
        return (
          <Table.Summary.Cell
            key={item.dataIndex}
            index={index + 1}
            align="center"
          >
            <Typography.Text>
              {
                generalData[
                  Array.isArray(item.dataIndex)
                    ? item.dataIndex[1]
                    : item.dataIndex
                ]
              }
            </Typography.Text>
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  );
};
