import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { Report2tpStatus } from '@pages/vodkhozReport/components';
import { formatDecimal } from '@utils/formatNumber/formatNumber';

const useOfWaterResourcesTranslate: Record<string, string> = {
  'Использовано поверхностных вод тыс. м3': 'surface',
  'Использовано подземных вод тыс. м3': 'groundwater',
};

const generateWaterColumns = (
  waterType: 'surface' | 'underground',
  canEdit: boolean,
): TableColumn[] => [
  {
    title:
      waterType === 'surface'
        ? 'Использовано поверхностных вод тыс. м3'
        : 'Использовано подземных вод тыс. м3',
    dataIndex:
      useOfWaterResourcesTranslate[
        waterType === 'surface'
          ? 'Использовано поверхностных вод тыс. м3'
          : 'Использовано подземных вод тыс. м3'
      ],
    key: waterType === 'surface' ? 'surfaceWater' : 'undergroundWater',
    align: 'left',
    xtype: CustomTableColumnType.String,
    children: [
      {
        title: 'Хоз. питьевые',
        dataIndex: `householdDrinking_${waterType}`,
        align: 'left',
        width: 120,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) => {
          if (record[`status_${waterType}`] === Report2tpStatus.SUBMITTED) {
            return 'decimalBlocked';
          }
          if (record[`id_${waterType}`]) {
            return 'decimalSaved';
          }
          return '';
        },
      },
      {
        title: 'Производственные',
        dataIndex: `industrial_${waterType}`,
        align: 'left',
        width: 120,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) => {
          if (record[`status_${waterType}`] === Report2tpStatus.SUBMITTED) {
            return 'decimalBlocked';
          }
          if (record[`id_${waterType}`]) {
            return 'decimalSaved';
          }
          return '';
        },
      },
      {
        title: 'Орошение',
        dataIndex: `irrigationWater_${waterType}`,
        align: 'left',
        width: 120,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) => {
          if (record[`status_${waterType}`] === Report2tpStatus.SUBMITTED) {
            return 'decimalBlocked';
          }
          if (record[`id_${waterType}`]) {
            return 'decimalSaved';
          }
          return '';
        },
      },
      {
        title: 'с/х Водоснабжение',
        dataIndex: `agriculturalWaterSupply_${waterType}`,
        align: 'left',
        width: 120,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) => {
          if (record[`status_${waterType}`] === Report2tpStatus.SUBMITTED) {
            return 'decimalBlocked';
          }
          if (record[`id_${waterType}`]) {
            return 'decimalSaved';
          }
          return '';
        },
      },
      {
        title: 'Другие',
        dataIndex: `other_${waterType}`,
        align: 'left',
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) => {
          if (record[`status_${waterType}`] === Report2tpStatus.SUBMITTED) {
            return 'decimalBlocked';
          }
          if (record[`id_${waterType}`]) {
            return 'decimalSaved';
          }
          return '';
        },
      },
      {
        title: 'Всего',
        dataIndex: `total_${waterType}`,
        align: 'left',
        width: 123,
        xtype: CustomTableColumnType.String,
      },
    ],
  },
];

export const useWaterTableColumns = (
  canEdit: boolean = false,
): TableColumn[] => [
  {
    title: 'Наименование Водопользователей',
    dataIndex: 'waterUser.legalData.name',
    xtype: CustomTableColumnType.String,
    key: 'waterUsersName',
    align: 'left',
    width: 178,
  },
  {
    title: 'Использовано воды за год тыс. м3',
    dataIndex: 'total',
    xtype: CustomTableColumnType.String,
    key: 'total',
    align: 'left',
    width: 120,
  },
  ...generateWaterColumns('surface', canEdit), // Генерация колонок для поверхностных вод
  ...generateWaterColumns('underground', canEdit), // Генерация колонок для подземных вод
];
