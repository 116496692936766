export enum MonthTypesFilter {
  First = 'jan-feb',
  Second = 'mar-apr',
  Third = 'may-june',
  Fourth = 'july-aug',
  Fifth = 'sep-oct',
  Six = 'nov-dec',
}

export enum CapitalizedMonthEnums {
  JUNE = 'JUNE',
  JANUARY = 'JANUARY',
  MAY = 'MAY',
  OCTOBER = 'OCTOBER',
  DECEMBER = 'DECEMBER',
  MARCH = 'MARCH',
  AUGUST = 'AUGUST',
  FEBRUARY = 'FEBRUARY',
  SEPTEMBER = 'SEPTEMBER',
  JULY = 'JULY',
  NOVEMBER = 'NOVEMBER',
  APRIL = 'APRIL',
}

export enum MonthEnums {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum RussianMonthEnums {
  January = 'Январь',
  February = 'Февраль',
  March = 'Март',
  April = 'Апрель',
  May = 'Май',
  June = 'Июнь',
  July = 'Июль',
  August = 'Август',
  September = 'Сентябрь',
  October = 'Октябрь',
  November = 'Ноябрь',
  December = 'Декабрь',
}

export enum ViewTypeWaterAccounting {
  WaterSupplySchedule = 'waterSupplySchedule',
  QuentityIndicators = 'quentityIndicators',
}

export enum Decades {
  First = 'first',
  Second = 'second',
  Third = 'third',
}

export enum DecadeDays {
  FirstDays = 'firstDays',
  SecondDays = 'secondDays',
  ThirdDays = 'thirdDays',
  FourthDays = 'fourthDays',
  FifthDays = 'fifthDays',
}

export const MonthIndexies: { [key: string]: number } = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

export const RussianMonthIndexies: { [key: string]: number } = {
  январь: 1,
  февраль: 2,
  март: 3,
  апрель: 4,
  май: 5,
  июнь: 6,
  июль: 7,
  август: 8,
  сентябрь: 9,
  октябрь: 10,
  ноябрь: 11,
  декабрь: 12,
};

export const RussianMonth: { [key: string]: string } = {
  январь: 'JANUARY',
  февраль: 'FEBRUARY',
  март: 'MARCH',
  апрель: 'APRIL',
  май: 'MAY',
  июнь: 'JUNE',
  июль: 'JULY',
  август: 'AUGUST',
  сентябрь: 'SEPTEMBER',
  октябрь: 'OCTOBER',
  ноябрь: 'NOVEMBER',
  декабрь: 'DECEMBER',
};

export const MonthIndexiesByDuo: { [key: string]: number } = {
  'jan-feb': 1,
  'mar-apr': 2,
  'may-june': 3,
  'july-aug': 4,
  'sep-oct': 5,
  'nov-dec': 6,
};
