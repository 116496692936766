import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';

import styles from './StatusWrapper.module.scss';

type StatusWrapperPropTypes = {
  status?: PlanStatusEnum;
  children: React.ReactNode;
};

export const StatusWrapper: React.FC<StatusWrapperPropTypes> = ({
  status = PlanStatusEnum.Editable,
  children,
}) => {
  return (
    <div
      className={
        status === PlanStatusEnum.Approved ? styles.success : styles.error
      }
    >
      {children}
    </div>
  );
};
