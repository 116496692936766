import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsShowCreateTemplateModalSelector } from '@store/selectors';
import { setShowCreateTemplateModalAction } from '@store/slices';

export const useCreateTemplateModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsShowCreateTemplateModalSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(setShowCreateTemplateModalAction(state));
  }, []);

  return { isShow, setShow };
};
