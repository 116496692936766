import { CustomModal, PrimaryButton } from '@features/ui-kit';
import { useEffect } from 'react';

import { Col, notification, Row, Spin } from 'antd';

import { CustomModalV2, PrimaryButtonV2 } from '@features/ui-kit-v2';
import {
  useActToBillMutation,
  useGetAccountingActQuery,
} from '@store/gisproApi';

import styles from './PreviewActModal.module.scss';
import { usePreviewActModal } from './usePreviewActModal';

export const PreviewActModal = () => {
  const { isModalShown, setShowModal, selectedActsInvoicesForPayment } =
    usePreviewActModal();
  const [save] = useActToBillMutation();
  const { data, error, isFetching } = useGetAccountingActQuery(
    selectedActsInvoicesForPayment,
  );

  const onConfirmAct = (type: 'act' | 'bill') => {
    if (selectedActsInvoicesForPayment) {
      save(selectedActsInvoicesForPayment)
        .unwrap()
        .then(() => {
          setShowModal(false);
          notification.success({
            message: type === 'act' ? 'Акт подтвержден' : 'Счет выставлен',
          });
        })
        .catch(({ data }) => {
          notification.error({
            message: data?.message || (type === 'act' ? 'Ошибка подтверждения акта' : 'Ошибка выставления счета'),
          });
        });
    }
  };

  return (
    <CustomModal
      destroyOnClose
      centered
      open={isModalShown}
      onCancel={() => setShowModal(false)}
      width={930}
      title="Акт"
      footer={
        <Row justify="end" gutter={20} className={styles.marginTop}>
          <Col>
            <PrimaryButton onClick={() => window.print()}>Распечатать</PrimaryButton>
          </Col>
          {selectedActsInvoicesForPayment?.status === 'ISSUED' ? (
            <Col>
              <PrimaryButton onClick={() => onConfirmAct('act')}>Подтвердить Акт</PrimaryButton>
            </Col>
          ) : null}
          {selectedActsInvoicesForPayment?.status === 'FILLED' ? (
            <Col>
              <PrimaryButton onClick={() => onConfirmAct('bill')}>Выставить счет</PrimaryButton>
            </Col>
          ) : null}
        </Row>
      }
    >
      <Spin spinning={isFetching}>
        <Row className={styles.marginTop}>
          <Col
            span={24}
            style={{ height: '50vh', overflow: 'auto', padding: '0 20px' }}
          >
            <div
              className="printable"
              dangerouslySetInnerHTML={{
                __html: data || (error as { data: string })?.data,
              }}
            />
          </Col>
        </Row>
      </Spin>
    </CustomModal>
  );
};
