import { Col, Row, Typography } from 'antd';
import { Divider } from '@features/ui-kit';
import { GeoPortalNavigationMenu } from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationMenu/GeoPortalNavigationMenu';
import { GeoPortalNavigationContent } from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationContent/GeoPortalNavigationContent';
import GeoPortalNavigationMap from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationMap/GeoPortalNavigationMap';
import { GeoPortalNavigationMapContext } from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationMap/GeoPortalNavigationMapContext';

export const GeoPortalNavigation: React.FC = () => {
  return (
    <div>
      <Typography.Title level={1}>Карты</Typography.Title>
      <Divider />
      <Row>
        <Col flex="30%">
          <GeoPortalNavigationMenu />
        </Col>
        <Col flex="70%">
          {/* TODO: uncomment when needed */}
          {/*<GeoPortalNavigationMapContext>*/}
          {/*  <GeoPortalNavigationMap />*/}
          {/*</GeoPortalNavigationMapContext>*/}
          <GeoPortalNavigationContent />
        </Col>
      </Row>
    </div>
  );
};
