import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGeoportalFiltersSelector } from '@store/selectors';
import { changeGeoportalFilters } from '@store/slices/geoPortal/geoPortalSlice';
import { GeoControllerGetPortalMapsApiArg } from '@store/api/geoPortalApi';

type ReturnType = {
  filters: Partial<GeoControllerGetPortalMapsApiArg>;
  setGeoPortalFilters: (filters: Partial<GeoControllerGetPortalMapsApiArg>) => void;
};

export const useGeoPortalMapFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const filters = useSelector(getGeoportalFiltersSelector);

  const setGeoPortalFilters = useCallback(
    (newFilters: Partial<GeoControllerGetPortalMapsApiArg>) => {
      dispatch(changeGeoportalFilters(newFilters as GeoControllerGetPortalMapsApiArg));
    },
    [],
  );

  return { filters, setGeoPortalFilters };
};
