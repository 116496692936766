import { Button, ButtonProps } from 'antd';

import styles from './buttons.module.scss';

type PrimaryButtonV2PropTypes = ButtonProps & {
  title: string;
};

export const PrimaryButtonV2: React.FC<PrimaryButtonV2PropTypes> = ({
  title,
  ...rest
}) => (
  <Button className={styles.primaryButton} {...rest}>
    {title}
  </Button>
);
