import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BankDto, useCreateBankMutation } from '@store/gisproApi';
import {
  getSelectedBankSelector,
  getShowAddBranchModalSelector,
} from '@store/selectors';
import { setShowAddBranchModal } from '@store/slices';

type ReturnType = {
  isLoading: boolean;
  selectedBankId: number | null;
  isModalShown: boolean;
  addBranch: (values: BankDto) => void;
  setShowModal: (showModal: boolean) => void;
};

export function useAddBranch(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowAddBranchModalSelector);
  const selectedBankId = useSelector(getSelectedBankSelector);
  const [create, { isLoading }] = useCreateBankMutation();

  const addBranch = useCallback(
    (values: BankDto) => {
      create({
        bankDto: {
          ...values,
          isFilial: true,
          parent_id: selectedBankId || 0,
        },
      });
    },
    [selectedBankId],
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowAddBranchModal(showModal));
  }, []);

  return {
    isLoading,
    selectedBankId,
    isModalShown,
    addBranch,
    setShowModal,
  };
}
