import { Statistic } from '@features/ui-kit/statistic';

import styles from './StatisticCard.module.scss';

interface IStatsBlockProps {
  title: string;
  count: string | number;
  icon?: React.ReactNode;
}

export const StatisticCard: React.FC<IStatsBlockProps> = ({
  count,
  title,
  icon,
}) => {
  return (
    <div className={styles.statsBlock}>
      <div className={styles.header}>
        {title}
      </div>
      <div className={styles.content}>
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        <Statistic animated value={count} />
      </div>
    </div>
  );
};
