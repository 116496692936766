export enum langsEnum {
  RU = 'ru',
  // EN = 'en',
  KG = 'kg',
}

export const langOptions = [
  { value: langsEnum.RU, label: 'Русский' },
  // { value: langsEnum.EN, label: 'Английский' },
  { value: langsEnum.KG, label: 'Кыргызский' },
];
