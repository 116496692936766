import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { useNewsDeleteMutation, useNewsListQuery } from '@store/api/newsApi';

import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useGeoPortalArchiveTableColumns } from './useGeoPortalArchiveTableColumns';
import { GeoPortalArchiveModal } from '../geoPortalArchiveModal';
import {
  useGeoPortalArchiveDeleteMutation,
  useGeoPortalArchiveListQuery,
} from '@store/api/geoPortalArchiveApi';
import { useSearchParams } from 'react-router-dom';
import { GeoPortalArchiveTableFilters } from '../geoPortalArchiveTableFilters/GeoPortalArchiveTableFilters';
import { useGetCurrentUser } from '@features/authentication';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

export const GeoPortalArchiveTable: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { setHeaderTitle } = useHeaderTitle();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGeoPortalArchiveListQuery(filtersParams);

  useEffect(() => {
    setSearchParams(
      pickBy(
        {
          regionId: user?.owner?.region?.id.toString() || null,
          districtId: user?.owner?.district?.id.toString() || null,
          ...filtersParams,
        },
        (v) => !isEmpty(v) || isInteger(v),
      ),
    );
  }, []);
  const { columns } = useGeoPortalArchiveTableColumns();

  const [deleteEntity] = useGeoPortalArchiveDeleteMutation();

  const [isModalShow, setShowModal] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(null);

  const handleEdit = (val: any) => {
    const defaultValue = {
      attachmentsList: val?.attachments,
      districtId: val?.district?.id,
      region: val?.region?.id,
      name: val?.name,
      id: val?.id,
    };

    setInitialValues(defaultValue);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = ({ id }: { id: number }) => {
    deleteEntity({ id });
  };

  useEffect(() => {
    setHeaderTitle(t('Реестр архивных данных ГЕО портала'));
  }, []);

  return (
    <>
      <GeoPortalArchiveTableFilters />
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>
                  {' '}
                  Архивные данные ГЕО портала
                </Typography.Title>
              </Col>
              <Col>
                <PrimaryButton onClick={handleCreate}>
                  Добавить архивные данные
                </PrimaryButton>
              </Col>
            </Row>
          }
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={data as any[]}
            bordered
            columns={columns}
            size="small"
            actionProps={{
              onHandleEdit: (val) => handleEdit(val),
              deleteItem,
            }}
          />
          {isModalShow && (
            <GeoPortalArchiveModal
              isModalShow={isModalShow}
              setShowModal={setShowModal}
              initialValues={initialValues}
            />
          )}
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
