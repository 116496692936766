import { Col, Row } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderDeadline<RecordType extends JsonRecord>({
  customColumn,
  record,
}: TableRendererProps<RecordType>) {
  return (
    <div className={styles.tagWrapper}>
      <Row gutter={10} className={styles.tag}>
        <Col>План:</Col>
        <Col>{record.planStartDate || '-'}</Col>
      </Row>
      <Row gutter={10} className={styles.tag}>
        <Col>Факт:</Col>
        <Col>{record.factStartDate || '-'}</Col>
      </Row>
    </div>
  );
}
