import React, { useEffect } from 'react';

import { Col, Form, Row } from 'antd';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  DangerButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
} from '@features/ui-kit';
import {
  validateEndDate,
  validateStartDate,
} from '@utils/validation/deadlineValidation';
import { normalizePercents } from '@utils/validation/normalizePercents';
import dayjs from 'dayjs';
import { validateTotalWeight } from '@utils/validation/validateTotalWeight';

interface IImplementationDeadlinesProps {
  disabled?: boolean;
}

export const ImplementationDeadlines: React.FC<
  IImplementationDeadlinesProps
> = ({ disabled }) => {
  const form = Form.useFormInstance();
  const handleWeightChange = () => {
    form
      .getFieldsValue()
      .implementationTerms.forEach((item: any, index: number) => {
        form.validateFields([['implementationTerms', index, 'weight']]);
      });
  };

  return (
    <Form.List name="implementationTerms">
      {(fields, { add, remove }) => (
        <>
          <div>
            <PrimaryButton
              style={{ borderRadius: 7, marginBottom: 7 }}
              size="small"
              onClick={() => add()}
              icon={<PlusOutlined />}
              disabled={disabled}
            >
              Добавить работу/мероприятие
            </PrimaryButton>
          </div>
          <Row align="bottom" gutter={[5, 12]}>
            {fields.map(({ key, name }) => (
              <>
                <Col key={key} span={23}>
                  <Row align="top" gutter={5}>
                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: true, message: '' }]}
                        name={[name, 'event']}
                        required
                        label="Работа/мероприятие"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Работа/мероприятие"
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        rules={[
                          { required: true, message: '' },
                          {
                            validator: (_, value) =>
                              validateTotalWeight({ _, value, form }),
                          },
                        ]}
                        name={[name, 'weight']}
                        required
                        label="Вес %"
                        normalize={normalizePercents}
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Вес"
                          disabled={disabled}
                          onChange={handleWeightChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          {
                            validator: (_, value) =>
                              validateStartDate({
                                _,
                                form,
                                type: `${name}`,
                                value,
                              }),
                          },
                        ]}
                        name={[name, 'start']}
                        required
                        label="Начало"
                      >
                        <TooltipedDatePicker
                          placeholder="Начало"
                          format="YYYY-MM-DD"
                          size="large"
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                          {
                            validator: (_, value) =>
                              validateEndDate({
                                _,
                                form,
                                type: `${name}`,
                                value,
                              }),
                          },
                        ]}
                        name={[name, 'end']}
                        required
                        label="Конец"
                      >
                        <TooltipedDatePicker
                          placeholder="Конец"
                          format="YYYY-MM-DD"
                          size="large"
                          disabled={disabled}
                          disabledDate={(current) => {
                            const startDate = form.getFieldValue([
                              'implementationTerms',
                              name,
                              'start',
                            ]);
                            return (
                              current &&
                              startDate &&
                              current < dayjs(startDate).endOf('day')
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={1}>
                  <DangerButton
                    style={{ borderRadius: 7, marginBottom: 7 }}
                    size="small"
                    onClick={() => name !== 0 && remove(name)}
                    icon={<CloseOutlined />}
                    disabled={disabled}
                  />
                </Col>
              </>
            ))}
          </Row>
        </>
      )}
    </Form.List>
  );
};
