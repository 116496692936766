import React, { memo, useCallback, useEffect, useRef } from 'react';

import { Form, InputNumber, InputRef } from 'antd';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';
import { formatDecimal } from '@utils/formatNumber/formatNumber';

import styles from './styles.module.scss';

export const EditDecadeCell: React.FC<TableEditorProps> = memo(
  ({ customColumn, record, actionProps }) => {
    const inputRef = useRef<InputRef>(null);
    const { dataIndex } = customColumn;

    const onPressEnter = useCallback(() => {
      actionProps?.onEditCellSaveClick?.(record, customColumn);
    }, [record, customColumn]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current?.focus();
      }
    }, []);

    return (
      <td>
        <Form.Item
          className={styles.editingCell}
          name={dataIndex}
          style={{ margin: 0, minHeight: 22, height: 22 }}
          normalize={(v: string) => v && formatDecimal(v)}
        >
          <InputNumber
            parser={(value) => value?.replace(',', '.') as unknown as number}
            autoFocus={true}
            className={styles.inputNumber}
            variant="borderless"
            onPressEnter={onPressEnter}
          />
        </Form.Item>
      </td>
    );
  },
);
