import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

// @ApiProperty()
// id: number;
// @ApiProperty()
// roleName: string;
// @ApiProperty()
// title: string;
// @ApiProperty()
// readonly: boolean;
export const useAccessTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'roleName',
      title: 'Название роли',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'title',
      title: 'Описание',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'readonly',
      title: 'Только чтение',
      xtype: CustomTableColumnType.BooleanColumn,
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.MethodsColumn,
      methods: [
        {
          name: 'access',
          title: 'Доступ',
        },
        {
          name: 'edit',
          title: 'Редактировать',
        },
        {
          name: 'delete',
          title: 'Удалить',
          confirm: 'Вы уверены, что хотите удалить эту запись?',
          condition: (record) => !record.readonly,
        }
      ],
      key: 'actions',
      align: 'center',
    },
  ];
  return { columns };
};
