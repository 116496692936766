import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { IWaterSection } from '@models/waterObject/interfaces/waterSection';
import { useWaterSectionTableColumns } from './useWaterSectionTableColumns';
import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import {
  useWaterSectionsControllerDeleteWaterSectionMutation,
  useWaterSectionsControllerFindBySystemObjectIdQuery,
} from '@store/api/waterSections';
import { AppDispatch } from '@store/index';
import { useDispatch } from 'react-redux';
import { waterObjectActions } from '@store/slices';
import { useCreateWaterSectionModal } from '@features/waterSection/createWaterSectionModal';

export const WaterSectionTable: FC = () => {
  const { id, mainConduitId } = useParams<{
    id: any;
    mainConduitId: any;
  }>();
  const { setShowModal } = useCreateWaterSectionModal();
  const { columns } = useWaterSectionTableColumns();
  const dispatch = useDispatch<AppDispatch>();

  const [remove] = useWaterSectionsControllerDeleteWaterSectionMutation();
  const { data: waterSections = [], isLoading } =
    useWaterSectionsControllerFindBySystemObjectIdQuery({
      id: mainConduitId || id,
    });

  const deleteItem = (record: IWaterSection) => {
    remove({ id: record.id });
  };

  const onHandleEdit = (record: IWaterSection) => {
    setShowModal(true);
    dispatch(waterObjectActions.setSelectedWaterSectionAction(record));
  };

  return (
    <InformationalBlock title="Водоводные участки">
      <CustomCard title="Данные участков">
        <TableCustom
          actionProps={{
            deleteItem,
            onHandleEdit,
          }}
          scroll={{ x: 1750 }}
          columns={columns}
          dataSource={waterSections}
          loading={isLoading}
        />
      </CustomCard>
    </InformationalBlock>
  );
};
