import { CustomCard, TableCustom } from '@features/ui-kit';

import { useFacilitiesColumns } from './useFacilitiesColumns';

export const FacilitiesCard: React.FC = () => {
  const { columns } = useFacilitiesColumns();

  return (
    <CustomCard title="Список хозяйств">
      <TableCustom
        size="small"
        pagination={false}
        scroll={{ y: 185 }}
        bordered
        columns={columns}
        dataSource={[
          {
            id: 1,
            name: 'Хозяйство 1',
            outlet: 'г. Бишкек, ул. Ленина, 1',
            area: '123',
          },
          {
            id: 2,
            name: 'Хозяйство 2',
            outlet: 'г. Бишкек',
            area: '1222',
          },
          {
            id: 3,
            name: 'Хозяйство 3',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 4,
            name: 'Хозяйство 4',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 5,
            name: 'Хозяйство 5',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 6,
            name: 'Хозяйство 6',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 7,
            name: 'Хозяйство 7',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 8,
            name: 'Хозяйство 8',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 9,
            name: 'Хозяйство 9',
            outlet: 'г. Бишкек',
            area: '123',
          },
          {
            id: 10,
            name: 'Хозяйство 10',
            outlet: 'г. Бишкек',
            area: '123',
          },
        ]}
      />
    </CustomCard>
  );
};
