import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { TabQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  ColorBlock,
  Divider,
  Tabs,
  WaterAccountingPath,
} from '@features/ui-kit';
import { WaterAccountingTableTypes } from '@models/waterAccounting/enums/waterAccountingEnums';

import { BalanceSheetAccounting } from './balanceSheetAccounting/BalanceSheetAccounting';
import { EconomicWaterAccounting } from './economicWaterAccounting/EconomicWaterAccounting';
import { EnterMonitoringValuesEveningModal } from './enterMonitoringValuesModals/enterMonitoringValuesEveningModal/EnterMonitoringValuesEveningModal';
import { EnterMonitoringValuesMorningModal } from './enterMonitoringValuesModals/enterMonitoringValuesMorningModal/EnterMonitoringValuesMorningModal';
import { WaterLogAccounting } from './waterLogAccounting/WaterLogAccounting';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

interface optionsItem {
  key: string;
  label: string;
}

const options = [
  {
    label: 'Хоз. договорной водоучет',
    key: WaterAccountingTableTypes.EconomicWaterAccounting,
  },
  {
    label: 'Балансовый водоучет',
    key: WaterAccountingTableTypes.BalanceSheetAccounting,
  },
  {
    label: 'Журнал водоподачи',
    key: WaterAccountingTableTypes.WaterLogAccounting,
  },
];

export const WaterAccounting = () => {
  const { setHeaderTitle } = useHeaderTitle();
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const activeTab =
    (params.get(TabQueryKey) as any) ||
    WaterAccountingTableTypes.EconomicWaterAccounting;

  const elementColor = WaterAccountingPath(params.get('tab'));
  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WATER_ACCOUNTING_HOUSEHOLD_VIEW,
      ACCESS.WATER_ACCOUNTING_BALANCE_VIEW,
      ACCESS.WATER_ACCOUNTING_WATER_LOG_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  const changeContentHandle = (key: string) => {
    setParams({
      [TabQueryKey]: key,
    });
  };

  useEffect(() => {
    setHeaderTitle(t('headers.waterAccounting'));
  }, []);

  let Component;
  switch (activeTab) {
    case WaterAccountingTableTypes.EconomicWaterAccounting:
      Component = EconomicWaterAccounting;
      break;
    case WaterAccountingTableTypes.BalanceSheetAccounting:
      Component = BalanceSheetAccounting;
      break;
    case WaterAccountingTableTypes.WaterLogAccounting:
      Component = WaterLogAccounting;
      break;
    default:
      Component = EconomicWaterAccounting;
  }

  return (
    <ColorBlock colorType={elementColor}>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.waterAccounting'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        items={dynamicOptions(options)}
        activeKey={activeTab}
        onChange={changeContentHandle}
      />
      <Component />
      <EnterMonitoringValuesMorningModal type={activeTab} />
      <EnterMonitoringValuesEveningModal type={activeTab} />
    </ColorBlock>
  );
};
