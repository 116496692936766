import { useFindIrrigationSystemByIdQuery } from '@store/gisproApi';

import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import { DocumentTable, UploadDocumentBlock } from '@components/index';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

export const IrrigationSystemCardDocumentTable: React.FC = () => {
  const { systemId = '' } = useParams<string>();

  const { data: systemData, refetch: refetchSystemData } =
    useFindIrrigationSystemByIdQuery({
      irrigationSystemId: systemId,
    });

  const onWatch = (attachment: IAttachment) => {
    downloadAttachment(attachment);
  };

  return (
    <>
      <Row gutter={8}>
        <Col span={14}>
          <DocumentTable
            deleteItem={() => {}}
            onHandleWatch={onWatch}
            documents={systemData?.attachmentIds || []}
          />
        </Col>
        <Col span={10}>
          <UploadDocumentBlock
            onLoad={refetchSystemData}
            irrigationSystemId={systemId}
          />
        </Col>
      </Row>
    </>
  );
};
