import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  CustomCard,
  SectionTitle,
  TooltipedDatePickerV2,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdQuery,
} from '@store/gisproApi';
import { innValidatorMaxLength } from '@utils/validation/innValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import dayjs, { Dayjs } from 'dayjs';

export const RegistrationDetails: FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const region = Form.useWatch('region', form);

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    region || skipToken,
  );

  const onRegionChange = () => {
    form.setFieldsValue({
      district: null,
    });
  };

  const { data: ownerType = [], isLoading: ownerTypeIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.OwnerType,
    });

  const { data: kindActivity = [], isLoading: kindActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.KindActivity,
    });

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {t(
                'createAssociationWaterRegistry.firstStep.registrationDetails.title',
              )}
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.dateRegistrationOrganization',
            )}
            name="registerDate"
            rules={[{ required: true }]}
          >
            <TooltipedDatePicker
              disabledDate={(current) =>
                current && current > dayjs().endOf('day')
              }
              size="large"
              allowClear
              placeholder="Выберите Дату регистрации организации"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.regNumber',
            )}
            name="regNumber"
            rules={[{ required: true }]}
          >
            <TooltipedInput
              placeholder="Введите Регистрационный номер"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.ownershipForm',
            )}
            name="ownershipForm"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={ownerTypeIsLoading}
              allowClear
              options={ownerType.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
              placeholder="Введите Форму собственности"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.activityType',
            )}
            name="businessActivity"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={kindActivityIsLoading}
              allowClear
              options={kindActivity.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
              placeholder="Введите вид деятельности организации"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.organizationAddress',
            )}
            name="address"
            rules={[{ required: true }]}
          >
            <TooltipedInput
              placeholder="Введите Адрес организации"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.okpo',
            )}
            name="okpo"
            rules={[{ required: false }, numbersValidator]}
          >
            <TooltipedInput
              type="number"
              placeholder="Введите ОКПО"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.registrationDetails.placeholders.inn',
            )}
            name="inn"
            rules={[{ required: true }, { len: 18 }, numbersValidator]}
          >
            <TooltipedInput
              placeholder="Введите ИНН организации"
              size="large"
              maxLength={18}
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomCard>
  );
};
