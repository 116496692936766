export enum WaterUsePlanDataViewTypes {
  Tables = 'Tables',
  View = 'View',
}

export enum WaterUsePlanSummaryViewTypes {
  General = 'General',
  State = 'State',
}

export enum WaterUsePlanTableTypes {
  PivotTable = 'PivotTable',
  AdditionsTable = 'AdditionsTable',
}

export enum PlanStatusEnum {
  Editable = 'Editable',
  Approved = 'Approved',
}

export enum AnnualStatusEnum {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  GENERATED = 'GENERATED',
  REJECTED = 'REJECTED',
}
