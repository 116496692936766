import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Card,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Typography,
  notification,
} from 'antd';

import { months, monthsObject, monthsTranslate } from '@constants/month';
import {
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  UploadBtn,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { getTariffDocumentFiltersSelector } from '@store/selectors';
import {
  addDocumentToTariff,
  clearTariffDocument,
} from '@store/slices/tariff/tariffSlice';
import { toInteger } from '@utils/utils';

import styles from './MonthTariffTable.module.scss';
import { PdfFileIcon } from '@icons/PdfFileIcon';
import { EyeIcon } from '@icons/EyeIcon';
import { useForm } from 'antd/es/form/Form';
import {
  useCreateTariffMutation,
  useGetTariffByIdQuery,
} from '@store/gisproApi';
import { IMonthlyTariffs, ITariff } from '@models/tariff/interfaces/tariff';
import { skipToken } from '@reduxjs/toolkit/query';
import { Routes } from '@enums/routes';

interface IMonthTariffTableProps {
  year: string | number;
  onCancel: () => void;
}

export const MonthTariffTable: React.FC<IMonthTariffTableProps> = ({
  year,
  onCancel,
}) => {
  const [form] = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tariffDocument = useSelector(getTariffDocumentFiltersSelector);
  const { data: tariff = {} as ITariff } = useGetTariffByIdQuery(
    toInteger(id) ? { tariffId: toInteger(id) } : skipToken,
  );
  const attachDocument = (file: IAttachment) => {
    dispatch(addDocumentToTariff(file));
  };
  const [createTariff] = useCreateTariffMutation();
  const [formFilled, setFormFilled] = useState(false);

  const validateDecimals = (_: any, value: any) => {
    if (!value || /^\d*(\.\d{0,2})?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error('Введите число с максимум двумя цифрами после запятой'),
    );
  };

  const onFinish = (
    values: Omit<ITariff, 'tariffName' | 'id' | 'createDate'>,
  ) => {
    createTariff({
      active: true,
      doc: tariffDocument?.attachmentId || 1,
      ruvhId: 1,
      monthlyTariffs: Object.entries(values).map(([monthlyName, price]) => ({
        monthlyName: monthsTranslate[monthlyName],
        price: Number(price),
      })),
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Тариф создан',
        });
        navigate(Routes.TariffTable);
      })
      .catch(() => {
        notification.error({
          message: 'Не удалось создать тариф',
        });
      });
    dispatch(clearTariffDocument());
  };

  useEffect(() => {
    if (
      id &&
      parseInt(id, 10) &&
      (tariff as { monthlyTariffs: IMonthlyTariffs[] }).monthlyTariffs
    ) {
      form.setFieldsValue(
        (tariff as { monthlyTariffs: IMonthlyTariffs[] }).monthlyTariffs.reduce(
          (acc, el) => {
            const { monthlyName, price } = el;
            return { ...acc, [monthsObject[monthlyName.toLowerCase()]]: price };
          },
          {},
        ),
      );
    }
  }, [id, tariff]);

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const isFormFilled = Object.values(values).every(
      (value) => value !== undefined && value !== null && value !== '',
    );
    setFormFilled(isFormFilled);
  };

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <Card className={styles.listWrapper}>
      <Typography.Title level={3} style={{ color: '#3f4778' }}>
        Создание тарифа {year} год
      </Typography.Title>
      <Divider className={styles.divider} />
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
        layout="vertical"
      >
        <Row gutter={30} className={styles.wrapper}>
          {months.map((month) => (
            <Col key={month} span={8}>
              <Form.Item
                label={`${month} (сом/м3)`}
                name={`${month}`}
                rules={[
                  { required: true, validator: validateDecimals },
                  // {
                  //   validator: validateDecimals,
                  // },
                ]}
              >
                <TooltipedInput
                  width={304}
                  type="number"
                  disabled={!!toInteger(id)}
                  placeholder="Введите значение"
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row gutter={30} align="middle" className={styles.row}>
          <Col span={24}>
            <Typography.Title
              style={{ fontSize: '14px', fontWeight: 'normal' }}
            >
              Прикрепленные документы:
            </Typography.Title>
            <Row gutter={[0, 8]} className={styles.docCard}>
              <Col span={24}>
                {tariffDocument || !isEmptyObject(tariff) ? (
                  <Space
                    size={8}
                    style={{ display: 'flex' }}
                    direction="vertical"
                  >
                    <Row
                      align="middle"
                      justify="space-between"
                      className={styles.docItem}
                      key={tariffDocument?.attachmentId}
                    >
                      <Col>
                        <Space size={8}>
                          <PdfFileIcon />
                          <Typography.Text>
                            {tariffDocument?.originName ||
                              (tariff && tariff?.doc?.name)}
                          </Typography.Text>
                        </Space>
                      </Col>
                      <Col>
                        <EyeIcon />
                      </Col>
                    </Row>
                  </Space>
                ) : (
                  <div style={{ fontSize: '13px' }}>
                    Нет закрепленных документов
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {!toInteger(id) && (
          <Row className={styles.uploadWrapper}>
            <UploadBtn
              docType="документ тарифа"
              title="Прикрепить документ"
              onLoad={attachDocument}
              disabled={!!toInteger(id)}
              className={styles.uploadBtn}
            />
          </Row>
        )}
        <Row className={styles.modalBtns}>
          <Col>
            <DefaultButton
              onClick={onCancel}
              htmlType="button"
              className={styles.btn}
            >
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            {!toInteger(id) && (
              <PrimaryButton
                htmlType="submit"
                disabled={!tariffDocument || !formFilled}
                className={styles.btn}
              >
                Создать
              </PrimaryButton>
            )}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
