import { useSelector } from 'react-redux';

import { ACCESS, Permission } from '@enums/permisson';
import { getCurrentUserPermissionsSelector } from '@store/selectors';

export const useHasUserPermission = (permission: ACCESS) => {
  const userPermissions = useSelector(getCurrentUserPermissionsSelector);

  return userPermissions.includes(permission);
};
