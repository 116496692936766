import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { Header } from '@features/header/Header';
import { Sidebar } from '@features/sidebar/Sidebar';
import { colors } from '@theme/colors';

export const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout style={{ minHeight: '100vh', background: colors.background }}>
      <Layout.Sider
        width={190}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        theme="light"
        style={{
          position: 'fixed',
          top: 0,
          width: 60,
          maxWidth: 60,
          bottom: 0,
          zIndex: 401,
          overflow: 'auto',
        }}
      >
        <Sidebar setCollapsed={setCollapsed} collapsed={collapsed} />
      </Layout.Sider>
      <Layout>
        <Layout.Header
          style={{
            padding: 0,
            position: 'sticky',
            top: 0,
            zIndex: 400,
            width: '100%',
            lineHeight: '1',
            height: 68,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Header />
        </Layout.Header>
        <Layout.Content style={{ padding: '16px 16px 30px 76px' }}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
