import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

export function renderDate<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value: string = record[customColumn.dataIndex];
  const formatted = value ? dayjs(value).format(customColumn.format ?? 'YYYY-MM-DD') : null;

  if (formatted) {
    return <div>{formatted}</div>;
  }

  return null;
}
