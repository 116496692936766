import { FC } from 'react';

import { Empty, Spin } from 'antd';
import { ConstructorCollapse } from '@components/constructorCollapse/ConstructorCollapse';
import { CustomCard } from '@features/ui-kit';

interface WaterObjectCollapsePropTypes {
  collapsedItems: any;
  waterObjectsIsLoading: boolean;
}

export const WaterObjectCollapse: FC<WaterObjectCollapsePropTypes> = ({
  collapsedItems,
  waterObjectsIsLoading,
}) => {
  return (
    <CustomCard title="Блок объектов" loading={waterObjectsIsLoading}>
      {!collapsedItems.length
        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        : (
          <ConstructorCollapse
            defaultActiveKey={[collapsedItems[0].key]}
            items={collapsedItems}
          />
        )
      }
    </CustomCard>
  );
};
