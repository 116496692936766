import { LocalStorageKey } from '@enums/localStorageKey';
import { LocalStorage } from '@utils/localStorage/localStorage';
import axios from 'axios';

const $api = axios.create({
  baseURL: process.env.REACT_APP_MAIN_BASE_URL,
});

$api.interceptors.request.use(
  (config: any) => {
    if (typeof window !== 'undefined') {
      const token = LocalStorage.getItem(LocalStorageKey.AccessToken);
      if (token && token !== 'undefined') {
        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default $api;
