import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoEditIcon: React.FC<IconProps> = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13 17.2498C13 16.9277 13.2612 16.6665 13.5833 16.6665H18.25C18.5722 16.6665 18.8333 16.9277 18.8333 17.2498C18.8333 17.572 18.5722 17.8332 18.25 17.8332H13.5833C13.2612 17.8332 13 17.572 13 17.2498Z"
            fill="black" fillOpacity="0.6" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.1179 9.7248C19.0569 8.78206 19.017 7.54406 18.3159 6.7859C17.9739 6.41623 17.4873 6.18009 16.9361 6.16707C16.3829 6.15401 15.8097 6.36558 15.2808 6.80808C15.2673 6.8194 15.2543 6.83132 15.2418 6.84382L7.67673 14.4391C7.35004 14.7671 7.16663 15.2111 7.16663 15.6741V16.662C7.16663 17.3045 7.68694 17.8332 8.33553 17.8332H9.31484C9.78017 17.8332 10.2263 17.6478 10.5547 17.3181L18.1179 9.7248ZM16.0374 8.37906C15.8096 8.15125 15.4403 8.15125 15.2125 8.37906C14.9847 8.60687 14.9847 8.97621 15.2125 9.20402L15.7958 9.78735C16.0236 10.0152 16.393 10.0152 16.6208 9.78735C16.8486 9.55954 16.8486 9.1902 16.6208 8.96239L16.0374 8.37906Z"
            fill="black" fillOpacity="0.6" />
    </svg>
  )
}
