import { MonthEnums } from '@enums/month';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WateringPlanState = {
  isShowSchedulePrintModal: boolean;
  isShowSupplyPrintModal: boolean;
  wateringPlanFilters: IWaterUseFilters;
  waterSupplyFilters: IWaterUseFilters;
};

const initialState: WateringPlanState = {
  isShowSupplyPrintModal: false,
  isShowSchedulePrintModal: false,
  wateringPlanFilters: {
    month: MonthEnums.January,
    userType: WaterUserEnum.INDIVIDUAL,
    size: 10,
    page: 1,
    // filters: {},
  },
  waterSupplyFilters: {
    month: MonthEnums.January,
    userType: WaterUserEnum.INDIVIDUAL,
    size: 10,
    page: 1
  },
};

const wateringPlanSlice = createSlice({
  name: 'waterUse',
  initialState,
  reducers: {
    setShowSchedulePrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowSchedulePrintModal: action.payload,
      };
    },
    setShowSupplyPrintModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowSupplyPrintModal: action.payload,
      };
    },
    setWateringFilters: (state, action: PayloadAction<IWaterUseFilters>) => {
      return {
        ...state,
        wateringPlanFilters: action.payload,
      };
    },
    setWaterSupplyFilters: (state, action: PayloadAction<IWaterUseFilters>) => {
      return {
        ...state,
        waterSupplyFilters: action.payload,
      };
    },
  },
});

export const {
  setShowSchedulePrintModal,
  setWateringFilters,
  setWaterSupplyFilters,
  setShowSupplyPrintModal,
} = wateringPlanSlice.actions;
export default wateringPlanSlice.reducer;
