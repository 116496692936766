import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { TablePaginationConfig } from 'antd';

import { Routes } from '@enums/routes';
import { TableCustom } from '@features/ui-kit';
import { useWaterUserPermissionModal } from '@features/waterRegistry';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { useWaterRegistryFilters } from '@features/waterUser/waterUserTable/useWaterRegistryFilters';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllWaterUserQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { usePhysicalTableColumns } from './usePhysicalTableContainerColumns';
import { IWaterUserV2 } from '@models/waterUser/interfaces/waterUser';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { JsonRecord } from '@models/jsonRecord';
import { saveUrl } from '@utils/returnUrl';

interface IPhysicalTableContainerProps {
  isArchived?: boolean;
}

export const PhysicalTableContainer: React.FC<IPhysicalTableContainerProps> = ({
  isArchived = false,
}) => {
  const { columns } = usePhysicalTableColumns();

  const saveAction = saveUrl();

  const { filters, setWaterRegistryFilters } = useWaterRegistryFilters();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();
  const { setShowModalPhysical } = useWaterUserPermissionModal();
  const { setUserId } = useWaterUserId();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetAllWaterUserQuery(
    isEmpty(filters) ? skipToken : { ...filters, isArchived },
  );

  const navigate = useNavigate();

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterRegistryFilters({
          ...(filters as IWaterRegistryFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onHandleEdit = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record.id),
      }),
    );
  }, []);

  const onArchiveClick = (record: IWaterUserV2) => {
    setConfirmArchiveId(+record.id);
    setShowModal(true);
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const showGis = useCallback((record: IWaterUserV2) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:water-user:${String(record.id)}`,
    });
    window.open(url, '_blank');
  }, []);

  const showAssociationStats = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.AssociationIndicatorReports, {
        associationId: String(record.organizationId),
      }),
    );
  }, []);

  const showWaterUserCard = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record.id),
      }),
    );
    saveAction();
  }, []);

  const showPermissions = useCallback((record: IWaterUserV2) => {
    setUserId(String(record.id));
    setShowModalPhysical(true);
  }, []);

  const showAnnualApplication = useCallback((record: IWaterUserV2) => {
    navigate(
      `${Routes.AnnualApplicationTable}?organizationName=${record?.legalData?.name}`,
    );
    saveAction();
  }, []);

  return (
    <TableCustom
      loading={isLoading || isFetching}
      dataSource={content}
      columns={columns}
      size="small"
      bordered
      onChange={onTableChange}
      totalCount={totalElements}
      customPagination={rest}
      actionProps={{
        onHandleEdit,
        onArchiveClick,
        showGis,
        showAnnualApplication,
        showPermissions,
        showAssociationStats,
        showWaterUserCard,
        onUnArchiveClick,
      }}
    />
  );
};
