import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { StatusInfo } from '@features/manageEvents/components';
import {
  CalculatedProgress,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { IBuildingBlock } from '@models/manageEvents/interfaces/buildingBlock';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindConstructionBlockByIdQuery,
  useGetBuildingTemplatesQuery,
  useLazyGetBuildingTemplateByIdQuery,
} from '@store/gisproApi';
import { setBuildingBlockAction } from '@store/slices';
import { debounce, isInteger } from '@utils/utils';

import styles from './BuildingCard.module.scss';

export const HeaderBuildingCard: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: buildingBlock } = useFindConstructionBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );
  const onValuesChange = debounce(
    (values: Partial<IBuildingBlock>) => {
      dispatch(setBuildingBlockAction(values));
    },
    250,
    {
      maxWait: 1000,
    },
  );
  const { data: templates = [], isLoading: templateIsLoading } =
    useGetBuildingTemplatesQuery('');

  const [triggerTemplate, { data: template }] =
    useLazyGetBuildingTemplateByIdQuery();

  const setBuildingTemplate = (value: string) => {
    triggerTemplate(value).then(({ data }: any) => {
      dispatch(setBuildingBlockAction({ ...data, id: data.taskId || null }));
      form.setFieldsValue({ ...data, id: data.taskId || null });
    });
  };

  const templateDisabled = !!buildingBlock?.id;

  useEffect(() => {
    form.setFieldsValue(buildingBlock);
  }, [buildingBlock, id]);

  return (
    <Form<IBuildingBlock>
      layout="vertical"
      onValuesChange={onValuesChange}
      className={styles.form}
      form={form}
    >
      <Row align="top" gutter={16} justify="space-between">
        <Col span={12}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item className={styles.formItem} name="id">
                <TooltipedInput
                  size="large"
                  className={styles.input}
                  placeholder="ID задачи"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={[{ required: true }]} name="title">
                <TooltipedInput
                  size="large"
                  placeholder="Наименование задачи"
                  disabled={!!buildingBlock?.id}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="templateId">
                <TooltipedSelect
                  size="large"
                  placeholder="Загрузить шаблон"
                  disabled={templateDisabled}
                  loading={templateIsLoading}
                  options={templates.map((item: any) => ({
                    label: item.templateName,
                    value: item.id,
                  }))}
                  onChange={setBuildingTemplate}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row gutter={16}>
            <Col span={16}>
              <CalculatedProgress
                separatePercentage
                tasksArray={buildingBlock && buildingBlock?.implementationTerms}
              />
            </Col>
            <Col span={8}>
              <StatusInfo
                className={styles.status}
                status={buildingBlock?.status}
                planEndDate={buildingBlock?.planEndDate}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
