import { WaterObjectConstructor } from '@features/waterObject/waterObjectStepper/waterObjectConstructor';
import { FC } from 'react';

import { WaterObjectActionSelectModal } from '@features/waterObject';

export const IrrigationSystemWaterObjectsLayout: FC = () => {
  return (
    <div>
      <WaterObjectConstructor />
      <WaterObjectActionSelectModal />
    </div>
  );
};
