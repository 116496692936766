import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { SectionTitle } from '@features/ui-kit-v2';
import { IBusinessPlanFilters } from '@models/business/interfaces/business';
import { isEmpty } from '@utils/utils';

import styles from './BusinessPlanTable.module.scss';
import { BusinessPlanTableContainer } from './businessPlanTableContainer';
import { BusinessPlanTableFilters } from './businessPlanTableFilters';
import { useBusinessPlanTableFilters } from './useBusinessPlanTableFilters';
import { useGetCurrentUser } from '@features/authentication';

export const BusinessPlanTable = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { setBusinessPlanFilters } = useBusinessPlanTableFilters();

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setBusinessPlanFilters(
      isEmpty(newFilters)
        ? ({
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as IBusinessPlanFilters)
        : (newFilters as unknown as IBusinessPlanFilters),
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <Row gutter={12} justify="space-between">
        <Col span={24}>
          <SectionTitle
            background="cyan"
            title={t('annualApplication.table.sectionTitle')}
          />
        </Col>
      </Row>
      <Row className={styles.marginTop}>
        <Col span={24}>
          <BusinessPlanTableFilters />
        </Col>
        <Col span={24}>
          <BusinessPlanTableContainer />
        </Col>
      </Row>
    </div>
  );
};
