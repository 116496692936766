import React from 'react';

import { Select, SelectProps, Tooltip, TooltipProps } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';

import styles from './Select.module.scss';

type TooltipedSelectPropsType = SelectProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const TooltipedSelect: React.FC<TooltipedSelectPropsType> = ({
  tooltipProps,
  ...props
}) => {
  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Tooltip {...tooltipProps}>
      <Select
        className={styles.select}
        showSearch
        filterOption={filterOption}
        {...props}
        suffixIcon={
          <CaretDownOutlined style={{ opacity: 0.5, color: '#000' }} />
        }
      />
    </Tooltip>
  );
};
