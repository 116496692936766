import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Button, Carousel, Col, Empty, Modal, Row } from 'antd';
import { CarouselProps } from 'antd/es/carousel';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import styles from './CustomCarousel.module.scss';
import { Dayjs } from 'dayjs';

type CustomCarouselPropsType = CarouselProps & {
  items:
    | Array<{ path: string; id: number; name?: string; date?: Dayjs | null }>
    | string[];
  disabled?: boolean;
  detailedModal?: boolean;
};

export const CustomCarousel: React.FC<CustomCarouselPropsType> = ({
  disabled,
  detailedModal = false,
  items,
  ...props
}) => {
  const carouselRef = useRef<any>(null);
  const modalCarouselRef = useRef<any>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(0);

  const handlePreview = useCallback(
    (index: any) => {
      setDefaultIndex(index);
      modalCarouselRef.current?.goTo(index, true);
      setPreviewVisible(true);
    },
    [modalCarouselRef.current],
  );
  const onCancel = () => {
    setPreviewVisible(false);
  };
  return (
    <>
      {items?.length > 0 ? (
        <Row className={styles.carousel} align="middle">
          {!disabled && (
            <Button
              size="large"
              onClick={() => carouselRef.current?.prev()}
              icon={<LeftOutlined />}
              className={styles.slideBtnPrev}
            />
          )}
          <Col span={24}>
            <Carousel {...props} ref={carouselRef} dots={false}>
              {items?.map((item: any, index: number) => (
                <Row
                  onClick={() => {
                    if (detailedModal) handlePreview(index);
                  }}
                  key={item.id || item}
                  className={styles.imgWrapper}
                  tabIndex={0}
                >
                  <img src={item.path || item} alt="" />
                  {item?.name && <p>{item?.name}</p>}
                </Row>
              ))}
            </Carousel>
          </Col>
          {!disabled && (
            <Button
              onClick={() => carouselRef.current?.next()}
              size="large"
              icon={<RightOutlined />}
              className={styles.slideBtnNext}
            />
          )}
        </Row>
      ) : (
        <Empty description="Нет фото" />
      )}
      <Modal
        visible={previewVisible}
        onCancel={onCancel}
        footer={null}
        width={1024}
        closeIcon={false}
      >
        <Row className={styles.carousel}>
          <Button
            size="large"
            onClick={() => modalCarouselRef.current?.prev()}
            icon={<LeftOutlined />}
            className={styles.slideModalBtnPrev}
          />
          <Col span={24}>
            <Carousel
              initialSlide={defaultIndex}
              draggable
              ref={modalCarouselRef}
              dots={false}
            >
              {items?.map((item: any) => (
                <Row key={item.id || item} className={styles.modalImgWrapper}>
                  <img src={item.path || item} alt="" />
                </Row>
              ))}
            </Carousel>
          </Col>
          <Button
            onClick={() => modalCarouselRef.current?.next()}
            size="large"
            icon={<RightOutlined />}
            className={styles.slideModalBtnNext}
          />
        </Row>
      </Modal>
    </>
  );
};
