import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowAssociationStatsModal } from '@store/selectors';
import { setShowAssociationStatsModal } from '@store/slices';

type ReturnType = {
  isShowModal: boolean;
  setShowModal: (val: boolean) => void;
};

export const useAssociationStatsModal = (): ReturnType => {
  const isModalShown = useSelector(getShowAssociationStatsModal);
  const dispatch = useDispatch();

  const setShowModal = useCallback((val: boolean) => {
    dispatch(setShowAssociationStatsModal(val));
  }, []);
  return {
    isShowModal: isModalShown,
    setShowModal,
  };
};
