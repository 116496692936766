import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContractualWatterSuplyPlanQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAnnualPlanQuarterlyTableFilters } from '../useAnnualPlanQuarterlyTableFilters';
import styles from './AnnualPlanQuarterlyTableContainer.module.scss';
import { mapAnnualPlanQuarterlyColumns } from './mapAnnualPlanQuarterlyColumns';
import { mapAnnualPlanQuarterlyData } from './mapAnnualPlanQuarterlyData';

export const AnnualPlanQuarterlyTableContainer = () => {
  const { t } = useTranslation();
  const { Column, ColumnGroup } = Table;
  const { filters } = useAnnualPlanQuarterlyTableFilters();

  const { data: planData = [] } = useContractualWatterSuplyPlanQuery(
    isEmpty(filters) ? skipToken : filters,
  );

  const dataSource = useMemo(
    () => mapAnnualPlanQuarterlyData(planData),
    [planData],
  );

  const columns = useMemo(
    () => mapAnnualPlanQuarterlyColumns(planData),
    [planData],
  );

  return (
    <Table
      pagination={false}
      scroll={{ x: 3500 }}
      bordered
      className={styles.table}
      dataSource={dataSource}
      size="small"
    >
      <ColumnGroup>
        <Column
          dataIndex="name"
          title="Наименование"
          align="center"
          fixed="left"
          width={300}
        />
        <Column dataIndex="area" title="Площ. хоз. дог. в/п га." width={130} />

        <>
          {columns?.map((column) => {
            return (
              <ColumnGroup
                title={t(`quarters.${column.quarterTypeNumber}`)}
                key={column.quarterTypeNumber as string}
                align="center"
              >
                <>
                  {(column?.dataIndexes || []).map(({ title, dataIndex }) => {
                    return (
                      <Column
                        width={110}
                        title={t(`months.${title}`)}
                        key={dataIndex as string}
                        dataIndex={dataIndex as string}
                        align="center"
                      />
                    );
                  })}
                </>
              </ColumnGroup>
            );
          })}
        </>

        <Column
          title="Нарост. итогом за год"
          width={130}
          dataIndex="totalArea"
          align="center"
        />
      </ColumnGroup>
    </Table>
  );
};
