import React from 'react';
import { useSelector } from 'react-redux';

import { notification } from 'antd';

import { WaterAssociationsTable } from '@features/waterUser';
import { useToArchiveWaterUserMutation } from '@store/gisproApi';
import {
  getOrganizationIdSelector,
  getConfirmArchiveId,
} from '@store/selectors';
import { toInteger } from '@utils/utils';
import { ArchiveConfirmModal } from '@features/archiveConfirmModal';

export const WaterRegistryAssociationsLayout: React.FC = () => {
  const [toArchive] = useToArchiveWaterUserMutation();
  const waterUserId = useSelector(getOrganizationIdSelector);
  const confirmArchiveId = useSelector(getConfirmArchiveId);

  const onSubmitArchive = () => {
    toArchive({ id: toInteger(confirmArchiveId) })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно добавлен в архив',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Не удалось добавить в архив',
        });
      });
  };

  return (
    <>
      <WaterAssociationsTable />
      <ArchiveConfirmModal submit={onSubmitArchive} />
    </>
  );
};
