import {
  IPlanItemDto,
  ISummaryGeneralPlan,
  IWaterUsePlanDataDto,
  PlanItemDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { IGovItemDto } from '@store/gisproApi';
import {
  addRowIndexies,
  reducedArrToObj,
} from '@utils/addRowIndexies/addRowIndexies';

export const waterUsePlanRuvhGovSummaryDataMapper = (
  dataSrc?: IGovItemDto[],
) => {
  if (!dataSrc) return undefined;
  const mappedItems = dataSrc.map((el) => {
    const { planItems } = el;
    const planItemDtos = planItems.reduce((acc: any, item) => {
      const { month, decade } = item;
      acc[`${month}-${decade}`] = item;
      return acc;
    }, {});
    return {
      systemId: el.systemId,
      systemName: el.systemName,
      planItemDtos,
    };
  });
  const filledArr = [...mappedItems, ...mappedItems, ...mappedItems].sort(
    (a, b) => a.systemId - b.systemId,
  );
  const withSystemRowIndexies = addRowIndexies(
    filledArr,
    'systemId',
    'systemRowIndex',
  );
  console.log('withSystemRowIndexies', withSystemRowIndexies);

  return withSystemRowIndexies;
};
