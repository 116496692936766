import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { getTagStatus } from '@utils/tag';
import { get } from '@utils/utils';

import styles from './styles.module.scss';

export function renderTag<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation();
  const values: string[] | string = get(record, customColumn.dataIndex);

  if (typeof values === 'object') {
    return values?.map((tagValue) => (
      <Tag
        color={getTagStatus(tagValue)}
        className={`${styles.tagName} ${styles[tagValue]}`}
        key={tagValue}
      >
        {t(`tags.${tagValue}`)}
      </Tag>
    ));
  }

  return (
    <Tag
      color={getTagStatus(values.toString())}
      className={`${styles.tag} ${styles.tagName}`}
      key={values}
      bordered
    >
      {t(`tags.${values}`)}
    </Tag>
  );
}
