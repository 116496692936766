import styles from './ColorBlock.module.scss';

type ColorBlockPropTypes = {
  colorType?: ElementColor | string;
  children?: React.ReactNode;
};

export enum ElementColor {
  default = 'default',
  violet = 'violet',
  aqua = 'aqua',
}

export const ColorBlock: React.FC<ColorBlockPropTypes> = ({
  colorType = ElementColor.default,
  children,
}) => {
  return (
    <div className={styles[colorType]}>
      {children}
    </div>
  );
};
