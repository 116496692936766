import { useEffect, useState } from 'react';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import {
  IWaterUsePlanByGuvhDto,
  IWaterUsePlanDataDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetContractualPlanByCaQuery,
  useGetContractualPlanByGuvhQuery,
  useGetContractualPlanQuery,
  useGetWaterUsePlanByCaQuery,
  useGetWaterUsePlanByGuvhQuery,
  useGetWaterUsePlanQuery,
} from '@store/gisproApi';

export type ReturnType = {
  data: IWaterUsePlanByGuvhDto | IWaterUsePlanDataDto;
  isLoading: boolean;
};

export const useContractualDataQuery = (roleName: UserRoles): ReturnType => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: contractualPlanByCa, isLoading: waterUsePlanByCALoading } =
    useGetContractualPlanByCaQuery(
      roleName === UserRoles.ROLE_CA ? {} : skipToken,
    );

  const { data: contractualPlanByGuvh, isLoading: waterUsePlanByGuvhLoading } =
    useGetContractualPlanByGuvhQuery(
      roleName === UserRoles.ROLE_GUVH ? {} : skipToken,
    );

  const { data: contractualPlanByRuvh, isLoading: waterUsePlanByRuvhLoading } =
    useGetContractualPlanQuery(
      roleName === UserRoles.ROLE_RUVH ? undefined : skipToken,
    );

  useEffect(() => {
    switch (roleName) {
      case UserRoles.ROLE_RUVH:
        setData(contractualPlanByRuvh);
        setIsLoading(waterUsePlanByRuvhLoading);
        break;
      case UserRoles.ROLE_GUVH:
        setData(contractualPlanByGuvh);
        setIsLoading(waterUsePlanByGuvhLoading);
        break;
      case UserRoles.ROLE_CA:
        setData(contractualPlanByCa);
        setIsLoading(waterUsePlanByCALoading);
        break;
      default:
        break;
    }
  }, [
    roleName,
    contractualPlanByCa,
    waterUsePlanByCALoading,
    contractualPlanByGuvh,
    waterUsePlanByGuvhLoading,
    contractualPlanByRuvh,
    waterUsePlanByRuvhLoading,
  ]);

  return { data, isLoading };
};
