import React, { useState } from 'react';

import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { documentFormats } from '@constants/documentFormats';
import { useSubmitImplementationTermModal } from '@features/manageEvents/modals/submitImplementationTerm/useSubmitImplementationTermModal';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  useSubmitOperationalBlockImplementationTermMutation,
  useUploadFilesMutation,
} from '@store/gisproApi';
import { UploadFileIcon } from '@icons/UploadFileIcon';

export const SubmitImplementationTermModal = () => {
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [documentList, setDocumentList] = useState<UploadFile[]>([]);
  const { isShow, setShow, selectedImplementationTerm } =
    useSubmitImplementationTermModal();

  const [uploadDocument] = useUploadFilesMutation();
  const [submit, { error, isError }] =
    useSubmitOperationalBlockImplementationTermMutation();

  const customRequestUploader = async (
    { file, onSuccess, onError }: any,
    type: any,
  ) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type,
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);

    try {
      const newFile = await uploadDocument(formData).unwrap();
      setAttachments((state) => [...state, newFile as unknown as IAttachment]);
      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onDocChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setDocumentList(newFileList);
  };

  const onCancel = () => {
    setShow(false);
  };
  const onSubmit = () => {
    if (selectedImplementationTerm && fileList.length) {
      submit({
        id: selectedImplementationTerm.id,
        attachments,
      })
        .unwrap()
        .then(() => {
          setFileList([]);
          setDocumentList([]);
          setShow(false);
        });
    }
  };

  const photoFormats = documentFormats
    .filter((format) => format.key === 'photo')
    .map((item) => item.value)
    .join(',');
  const docFormats = documentFormats
    .filter((format) => format.key === 'docs')
    .map((item) => item.value)
    .join(',');

  return (
    <CustomModal
      centered
      title="Закрытие этапа"
      open={isShow}
      onCancel={() => setShow(false)}
      width={350}
      footer={false}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
    >
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <div>
            <Typography.Text strong>Работа/мероприятие: </Typography.Text>
            {selectedImplementationTerm?.event}
          </div>
          <div>
            <Typography.Text strong>Вес: </Typography.Text>
            {selectedImplementationTerm?.weight}
          </div>
        </Col>
        <Col>
          <Upload
            fileList={fileList}
            onChange={onChange}
            accept={photoFormats}
            maxCount={10}
            customRequest={(options) =>
              customRequestUploader(options, 'фотография')
            }
          >
            <Button icon={<UploadFileIcon />}>Загрузите фотографии*</Button>
          </Upload>
        </Col>
        <Col>
          <Upload
            accept={docFormats}
            fileList={documentList}
            onChange={onDocChange}
            maxCount={1}
            customRequest={(options) =>
              customRequestUploader(options, 'документ')
            }
          >
            <Button icon={<UploadFileIcon />}>Загрузите документ</Button>
          </Upload>
        </Col>
        <Row justify="center">
          <Col span={14}>
            <Space size={8}>
              <DefaultButton onClick={onCancel}>Отмена</DefaultButton>
              <PrimaryButton onClick={onSubmit}>Закрыть этап</PrimaryButton>
            </Space>
          </Col>
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Row>
    </CustomModal>
  );
};
