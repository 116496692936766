import {
  IActsInvoicesForPayments,
  IActsInvoicesForPaymentsFilters,
} from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ActsInvoicesForPaymentsState = {
  isShowActModal: boolean;
  isShowCheckModal: boolean;
  isPreviewActModal: boolean;
  isPreviewCheckModal: boolean;
  isEditActModal: boolean;
  selectedActsInvoicesForPayment: IActsInvoicesForPayments | null;
  actsInvoicesForPaymentsFilters: Partial<IActsInvoicesForPaymentsFilters>;
};

const initialState: ActsInvoicesForPaymentsState = {
  isShowActModal: false,
  isShowCheckModal: false,
  isPreviewActModal: false,
  isPreviewCheckModal: false,
  isEditActModal: false,
  selectedActsInvoicesForPayment: null,
  actsInvoicesForPaymentsFilters: {
    page: 1,
    size: 10,
  },
};

const actsInvoicesForPaymentsSlice = createSlice({
  name: 'actsInvoicesForPayments',
  initialState,
  reducers: {
    changeActsInvoicesForPaymentsFiltersAction: (
      state,
      action: PayloadAction<IActsInvoicesForPaymentsFilters>,
    ) => {
      return {
        ...state,
        actsInvoicesForPaymentsFilters: action.payload,
      };
    },
    setShowActModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowActModal: action.payload,
      };
    },
    setShowEditActModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isEditActModal: action.payload,
      };
    },
    setPreviewActModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPreviewActModal: action.payload,
      };
    },
    setPreviewCheckModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPreviewCheckModal: action.payload,
      };
    },
    setShowCheckModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowCheckModal: action.payload,
      };
    },
    setSelectedActsInvoicesForPaymentAction: (
      state,
      action: PayloadAction<IActsInvoicesForPayments>,
    ) => {
      return {
        ...state,
        selectedActsInvoicesForPayment: action.payload,
      };
    },
  },
});

export const { actions: actsInvoicesForPaymentsActions } =
  actsInvoicesForPaymentsSlice;
export default actsInvoicesForPaymentsSlice.reducer;
