import { useParams } from 'react-router-dom';

import { CustomTableColumnType } from '@enums/table';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery, useFindIrrigationSystemByIdQuery, useGetObjectCategoriesQuery } from '@store/gisproApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { CommonReference } from '@models/commonReference/interfaces/commonReference';
import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';

export const DeclaredDataForm: React.FC = () => {
  const { systemId = '' } = useParams<string>();

  const { data: systemData, isFetching } = useFindIrrigationSystemByIdQuery({
    irrigationSystemId: systemId,
  });

  const { data: workKinds = [] } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.WorkTypes });

  const { data: objectCategories = [] } =
    useGetObjectCategoriesQuery();

  const getWorkTitle = (workType: string) => {
    const work = workKinds.find((work: CommonReference) => work.id === Number(workType));
    return work ? work.title : workType;
  };

  const getObjectCategoryTitle = (objectType: string) => {
    const objectCategory = objectCategories.find((category: ObjectCategory) => category.code === Number(objectType));
    return objectCategory ? objectCategory.groupTitle : objectType;
  };

  const columns: TableColumn[] = [
    {
      dataIndex: 'workYear',
      title: 'Год проведения работ',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'workType',
      title: 'Вид работы',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <>{getWorkTitle(record.workType)}</>;
      },
    },
    {
      dataIndex: 'objectType',
      title: 'Категория объекта',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <>{getObjectCategoryTitle(record.objectType)}</>;
      }
    },
    {
      dataIndex: 'length',
      title: 'Протяженность, км / кол-во шт.',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'sum',
      title: 'Общая сумма, сом',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'description',
      title: 'Объем проведенных работ',
      xtype: CustomTableColumnType.String,
    },
  ];

  return (
    <CustomCard title="Объявленные данные">
      <TableCustom
        loading={isFetching}
        style={{ marginTop: 8 }}
        columns={columns}
        dataSource={systemData?.works}
      />
    </CustomCard>
  );
};
