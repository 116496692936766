import { generatePath } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { TagsWithProgress } from '@features/ui-kit/tagsWithProgress';
import { TagsType } from '@features/ui-kit/tagsWithProgress/TagsWithProgress';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

const tags = (record: JsonRecord): TagsType[] => [
  {
    title: 'Карточка',
    status: 'success',
    path: generatePath(Routes.IrrigationSystemCard, {
      systemId: record.systemId ? record.systemId : null,
    }),
  },
  {
    title: 'Создание водовода',
    status: 'success',
    path: generatePath(Routes.IrrigationSystemMainConduitRegistration, {
      systemId: record.systemId ? record.systemId : null,
      mainConduitId: 'none',
    }),
  },
  {
    title: 'ГИС',
    status: 'success',
    path: `${generatePath(Routes.geoPortalMap, {
      filterKey: record.systemId ? `is:${record.systemId}` : null,
    })}`,
  },
  {
    title: 'Конструктор',
    status: 'success',
    path: `${generatePath(Routes.IrrigationSystemWaterObjectsRegistration, {
      systemId: record.systemId ? record.systemId : null,
    })}?step=constructor`,
  },
];

export function renderIrrigationControl<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  // const value: string = record[customColumn.dataIndex];
  return (
    <div>
      <TagsWithProgress tags={tags(record)} />
    </div>
  );
}
