export const cropYieldName: Record<string, string> = {
  'Озимая пшеница': 'cropYieldWinterWheat',
  Табак: 'cropYieldTobacco',
  Хлопок: 'cropYieldCotton',
  'Кукуруза на зерно': 'cropYieldMaizeForGrain',
  Рис: 'cropYieldRice',
  'многол. Травы': 'cropYieldMultiHerbs',
  Овощи: 'cropYieldVegetables',
  Масличные: 'cropYieldOilseeds',
  Бахчи: 'cropYieldMelons',
  прочие: 'cropYieldOthers',
};

export const actualSowingName: Record<string, string> = {
  'Озимая пшеница': 'actualSowingWinterWheat',
  Табак: 'actualSowingTobacco',
  Хлопок: 'actualSowingCotton',
  'Кукуруза на зерно': 'actualSowingMaizeForGrain',
  Рис: 'actualSowingRice',
  'многол. Травы': 'actualSowingMultiHerbs',
  Овощи: 'actualSowingVegetables',
  Масличные: 'actualSowingOilseeds',
  Бахчи: 'actualSowingMelons',
  прочие: 'actualSowingOthers',
  'Приус.Участки': 'actualSowingFallowLand',
  'Не заселено': 'actualSowingUnplanted',
};
