export enum SystemObjectCardTabsEnum {
  totalInformation = 'totalInformation',
  relatedObjects = 'relatedObjects',
  operationalBlock = 'operationalBlock',
  documents = 'documents',
}

export enum SystemObjectGeoPortalType {
  WaterOutlets = 46,
  Channels = 2,
  Hydroposts = 55,
}
