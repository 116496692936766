import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type vodokhozReportState = {
  waterUserFilter: {
    year: number;
  };
  magazineFilters: {
    year?: number | null;
    district?: number | string | null;
    region?: number | string | null;
    name?: string | null;
    status?: string | null;
  };
  consolidatedReportFilter: {
    year: number | null;
  };
};

const initialState: vodokhozReportState = {
  waterUserFilter: {
    year: 2024,
  },
  magazineFilters: {},
  consolidatedReportFilter: {
    year: null,
  },
};

const vodokhozReportSlice = createSlice({
  name: 'vodokhozReport',
  initialState,
  reducers: {
    changeWaterUserFilters: (state, action) => {
      state.waterUserFilter = action.payload;
    },
    changeMagazineFilters: (state, action) => {
      state.magazineFilters = action.payload;
    },
    changeConsolidatedReportFilter: (state, action) => {
      state.consolidatedReportFilter = action.payload;
    },
  },
});

export const {
  changeWaterUserFilters,
  changeMagazineFilters,
  changeConsolidatedReportFilter,
} = vodokhozReportSlice.actions;

export default vodokhozReportSlice.reducer;
