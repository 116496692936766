import { FC, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { CustomSteps } from '@features/ui-kit/customSteps';
import { CreateMainConduitQueryTypes } from '@models/waterObject/enums/mainConduitEnum';

import { CreateGtsObjects } from './createGtsObjects';
import styles from './CreateStepper.module.scss';
import { InformationBlock } from './informationBlock';
import { MainConduitContsruction } from './mainConduitConstruction';
import { WaterSectionWrapper } from './waterSectionsWrapper';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Col, Row, Typography } from 'antd';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

const steps = [
  {
    title: 'Паспортные данные',
    value: CreateMainConduitQueryTypes.totalInformation,
  },
  {
    title: 'Создание водоводных участков',
    value: CreateMainConduitQueryTypes.waterSections,
  },
  {
    title: 'Создание объектов (ГТС)',
    value: CreateMainConduitQueryTypes.gts,
  },
  {
    title: 'Конструктор',
    value: CreateMainConduitQueryTypes.constructor,
  },
];

export const CreateStepper: FC = () => {
  const [searchParams] = useSearchParams();
  const step = useMemo(() => searchParams.get(SectionQueryKey), [searchParams]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  let Component;

  switch (step) {
    case CreateMainConduitQueryTypes.totalInformation:
      Component = InformationBlock;
      break;
    case CreateMainConduitQueryTypes.waterSections:
      Component = WaterSectionWrapper;
      break;
    case CreateMainConduitQueryTypes.gts:
      Component = CreateGtsObjects;
      break;
    case CreateMainConduitQueryTypes.constructor:
      Component = MainConduitContsruction;
      break;
    default:
      Component = InformationBlock;
      break;
  }

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.IrrigationSystemTable)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: t('headers.irrigation-system'),
                },
                {
                  title: 'Создание водовода',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <div className={styles.progress}>
        <CustomSteps
          steps={steps}
          queryParams={{
            queryKey: SectionQueryKey,
            queryType: CreateMainConduitQueryTypes,
          }}
        />
      </div>
      <Divider />
      <Component />
    </>
  );
};
