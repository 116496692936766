import { CloseOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

import styles from './renderer.module.scss';

export function renderDecadeEditableCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { dataIndex, parentDataIndex, decadeType } = customColumn;
  const decadeValue = get(record, [
    'months',
    dataIndex,
    'decadeDtos',
    parentDataIndex!,
  ]);

  let value;

  switch (decadeType) {
    case 'fact':
      value = decadeValue?.fact;
      break;
    case 'plan':
      value = decadeValue?.plan;
      break;
    case 'remains':
      value = record.months[dataIndex]?.residualValueForDecade;
      break;
    default:
      break;
  }
  return record.months[dataIndex] ? (
    <div
      className={`${styles.decadeCell} 
      ${decadeValue?.isAdminEdited && styles.adminEditedCell}`}
    >
      {value}
    </div>
  ) : (
    <div className={styles.xMarkWrapper}>
      <CloseOutlined className={styles.xmark} />
    </div>
  );
}
