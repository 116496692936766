import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Table, TableProps } from 'antd';

import { PAGINATION_MIN_RECORDS_AMOUNT } from '@constants/pagination';
import { dataTreeFindCell } from '@features/ui-kit/customTable/dataTreeFindCell';
import { getCellObject } from '@features/ui-kit/customTable/getCellObject';
import { type JsonRecord } from '@models/jsonRecord';
import { type PaginationWrapper } from '@models/pagination/interfaces/pagination';
import { type TableColumn } from '@models/table/interfaces/tableColumn';
import {
  type TableCustomActionProps,
  TableCustomMethodProps,
} from '@models/table/interfaces/tableCustomActionProps';
import { customAssign } from '@utils/customAssignFn';
import { set } from '@utils/utils';
import { FieldData } from 'rc-field-form/es/interface';

import { EditableCell } from './cell';
import { dataTreeReplaceByCell } from './dataTreeReplaceByCell';
import { filterCutomTableColumns } from './filterCutomTableColumns';
import { getCellKeyValue } from './getCellKeyValue';
import { getKeyValue } from './getKeyValue';
import { HeaderCell } from './headerCell';
import { mapCutomTableColumns } from './mapCutomTableColumns';
import styles from './TableCustom.module.scss';

export type GetCellKey<RecordType> = (
  record: RecordType,
  column: TableColumn,
  index?: number,
) => React.Key;

interface TableCustomProps<RecordType> extends TableProps<RecordType> {
  columns: TableColumn[];
  columnsOrder?: string[];
  totalCount?: number;
  highlightedRowKeys?: (React.Key | undefined)[];
  customPagination?: Partial<PaginationWrapper<RecordType>>;
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
  actionProps?: TableCustomActionProps<RecordType>;
  methodsProps?: TableCustomMethodProps<RecordType>[];
}

export function TableCustom<RecordType extends JsonRecord>({
  columns,
  columnsOrder,
  dataSource = [],
  onChange,
  highlightedRowKeys = [],
  rowClassName,
  rowKey,
  cellKey,
  customPagination,
  totalCount = 10,
  methodsProps = [],
  actionProps: {
    editingCellKey: editingCellKeyParent,
    onEditSaveClick: onEditSaveClickParent,
    onEditCellSaveClick: onEditCellSaveClickParent,
    getData,
    ...otherActionProps
  } = {},
  ...other
}: TableCustomProps<RecordType>) {
  const [form] = Form.useForm();
  const [data, setData] = useState<RecordType[]>([]);
  const [editingCellKey, setEditingCellKey] = useState<React.Key | null>(
    editingCellKeyParent || null,
  );
  const [isFormValid, setFormValid] = useState(false);

  const isPaginationDisplayed = useMemo(
    () => totalCount && totalCount > PAGINATION_MIN_RECORDS_AMOUNT,
    [totalCount],
  );

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setData([...dataSource]);
    } else {
      setData([]);
    }
  }, [dataSource]);

  const getEditingCell = useCallback(
    (record: RecordType, column: TableColumn): boolean => {
      const keyValue = getCellKeyValue(record, column, cellKey);
      return keyValue === editingCellKey;
    },
    [editingCellKey, cellKey],
  );

  const onEditCellClick = useCallback(
    (record: RecordType, column: TableColumn) => {
      const cellData = getCellObject(record, column);
      form.setFieldsValue({ ...cellData });
      const keyValue = getCellKeyValue(record, column, cellKey);

      setEditingCellKey(keyValue);
    },
    [cellKey, form],
  );

  const handleGetData = (payload: any) => {
    if (getData) {
      getData(payload);
    }
  };

  const onEditCellSaveClick = async (
    record: RecordType,
    column: TableColumn,
  ) => {
    try {
      const keyValue = getCellKeyValue(record, column, cellKey);
      const row = (await form.getFieldsValue(true)) as RecordType;
      const targetItem = dataTreeFindCell({
        children: data,
        column,
        keyValue,
        cellKey,
      });
      if (targetItem) {
        let newCellObject: RecordType;

        if (column.decadeIndex?.length) {
          newCellObject = set(targetItem, column.decadeIndex, row);
        } else if (column.decimalIndex?.length) {
          newCellObject = set(targetItem, column.decimalIndex, row);
        } else {
          newCellObject = customAssign<RecordType>(targetItem, row);
        }

        const newItem: RecordType = newCellObject;

        const newData = dataTreeReplaceByCell({
          children: data,
          keyValue,
          newItem,
          rowKey,
        });

        try {
          setData(newData);
          handleGetData(newData);
          onEditCellSaveClickParent?.(newItem, column);
          onEditSaveClickParent?.(data as any);
        } catch (e) {
          console.log('e', e);
        }
        setEditingCellKey(null);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onFieldsChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      const isValid = allFields.reduce((prev, field) => {
        return prev && !(field.value === undefined || !!field.errors?.length);
      }, true);
      // form.setFieldValue();
      setFormValid(isValid);
    },
    [setFormValid],
  );

  const canEditCell = useCallback((record: RecordType) => record.disalbed, []);

  const mappedColumns = mapCutomTableColumns({
    customColumns: filterCutomTableColumns({
      customColumns: columns,
      columnsOrder,
    }),
    methodsProps,
    actionProps: {
      onEditCellSaveClick,
      isFormValid,
      canEditCell,
      editingCellKey,
      getEditingCell,
      onEditCellClick,
      ...otherActionProps,
    },
  });

  const getRowClassName = useCallback(
    (record: RecordType, index: number, indent: number) => {
      const classNames: string[] = [];

      if (typeof rowClassName === 'string') {
        classNames.push(rowClassName);
      }
      if (typeof rowClassName === 'function') {
        classNames.push(rowClassName(record, index, indent));
      }

      const keyValue = getKeyValue(record, rowKey);
      if (highlightedRowKeys.indexOf(keyValue) > -1) {
        classNames.push(styles.highlightedRow);
      }
      return classNames.join(' ');
    },
    [highlightedRowKeys, rowClassName, rowKey],
  );

  const handleTableChange: TableProps<any>['onChange'] = (
    newPagination,
    newFilters,
    newSorter,
    extra,
  ) => {
    if (onChange) {
      onChange(newPagination, newFilters, newSorter, extra);
    }
  };

  return (
    <Form
      form={form}
      onFieldsChange={onFieldsChange}
      style={{ width: '100%', height: '100%' }}
    >
      <Table<RecordType>
        className={styles.table}
        components={{
          header: {
            cell: HeaderCell,
          },
          body: {
            cell: EditableCell,
          },
        }}
        columns={mappedColumns}
        dataSource={data}
        onChange={handleTableChange}
        rowKey={rowKey || 'id'}
        rowClassName={getRowClassName}
        pagination={
          isPaginationDisplayed && customPagination
            ? {
                total: totalCount,
                current: customPagination.pageable?.pageNumber || 1,
                showSizeChanger: false,
                showTotal: () => {
                  return `Страница ${
                    customPagination.pageable?.pageNumber || 1
                  } из ${Math.ceil(totalCount / 10)}`;
                },
              }
            : false
        }
        {...other}
      />
    </Form>
  );
}
