import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

export function renderQuantity<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const format = 'HH:mm';

  if (record) {
    return (
      <div>
        {/* {record.day.map((day: any) => (
          <div key={day.title} className={styles.railItem}>
            <div className={styles.time}>
              <TimePicker
                placeholder="--:--"
                format={format}
                bordered={false}
                className="timePicker"
                allowClear={false}
                defaultValue={dayjs(day.rail.time, format)}
                disabled
              />
            </div>
            <div key={day.title} className={styles.value}>
              {day.expenditure.sec}
            </div>
          </div>
        ))} */}
      </div>
    );
  }

  return null;
}
