import { FC } from 'react';

import { Typography } from 'antd';

import styles from './SectionTitle.module.scss';

interface ISectionTitleProps {
  title: string | React.ReactNode;
}

export const SectionTitle: React.FC<ISectionTitleProps> = ({ title }) => {
  return (
    <div className={styles.header}>
      <Typography.Title level={3} className={styles.headerText}>
        {title}
      </Typography.Title>
    </div>
  );
};
