import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Col, Row } from 'antd';

import { HomeOutlined } from '@ant-design/icons';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider } from '@features/ui-kit';
import { DecadeApplicationControls } from '@features/decadaApp/decadeApplicationControls/DecadeApplicationControls';
import { DecadeApplicationTable } from '@features/decadaApp/decadeApplicationTable/DecadeApplicationTable';

export const DecadeApplication = () => {
  const { setHeaderTitle } = useHeaderTitle();
  const { t } = useTranslation();

  useEffect(() => {
    setHeaderTitle(t('headers.decadeApplication'));
  }, []);

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.decadeApplication', {
              user: 'ЦА Чуйский район',
              year: 2024,
            }),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row justify="space-between">
        <Col span={24}>
          <DecadeApplicationControls />
        </Col>
        <Divider />
        <Col span={24}>
          <DecadeApplicationTable />
        </Col>
      </Row>
    </>
  );
};
