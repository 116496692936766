import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmployeeTypesEnum } from '@models/waterUser/enums/waterUserEnums';
import {
  getCurrentEmployeeTypeSelector,
  getEmployeesSelector,
  getShowAddEmployeeModalSelector,
} from '@store/selectors';
import {
  addEmployeeAction,
  setCurrentEmployeeTypeAction,
  setShowAddEmployeeModal,
} from '@store/slices/waterRegistry';

type Employee = {
  employeeId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  positionEmployee: string;
};

type ReturnType = {
  employees: Array<Employee>;
  isModalShown: boolean;
  currentEmployeeType: EmployeeTypesEnum | null;
  addEmployee: (values: Employee) => void;
  setShowModal: (showModal: boolean) => void;
  setCurrentEmployeeType: (type: EmployeeTypesEnum) => void;
};

export function useAddEmployee(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowAddEmployeeModalSelector);
  const employees = useSelector(getEmployeesSelector);
  const currentEmployeeType = useSelector(getCurrentEmployeeTypeSelector);

  const addEmployee = useCallback((employee: Employee) => {
    dispatch(addEmployeeAction(employee));
  }, []);

  const setCurrentEmployeeType = useCallback(
    (employeeType: EmployeeTypesEnum) => {
      dispatch(setCurrentEmployeeTypeAction(employeeType));
    },
    [],
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowAddEmployeeModal(showModal));
  }, []);

  return {
    employees,
    isModalShown,
    addEmployee,
    setShowModal,
    setCurrentEmployeeType,
    currentEmployeeType,
  };
}
