import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import {
  DefaultButton,
  DocsList,
  Mask,
  TooltipedInput,
  WhatsAppInputButton,
} from '@features/ui-kit';
import { TelegramInputButton } from '@features/ui-kit/buttons/TelegramInputButton';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { CustomCard, UploadModalV2 } from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { normalizeFile } from '@utils/attachments/normalizeFile';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import { UploadFileIcon } from '@icons/UploadFileIcon';

export const AddManager: FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [show, setShow] = useState(false);
  const [defaultDocType, setDefaultDocType] = useState('');

  const attachmentDtos =
    Form.useWatch(['manager', 'attachmentsList'], form) || [];

  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue(['manager', 'attachmentsList']) || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue(
      ['manager', 'attachmentsList'],
      updatedAttachments,
    );
    setDefaultDocType('');
  };

  const setModal = (state: boolean) => {
    if (state) {
      setShow(true);
    } else {
      setShow(false);
      setDefaultDocType('');
    }
  };

  return (
    <>
      <CustomCard
        title={
          <Row>
            <Col span={24}>
              <Typography.Title level={4}>Руководитель</Typography.Title>
              <Divider className={styles.divider} />
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={t(
                    'createLegalWaterRegistry.secondStep.placeholders.lastName',
                  )}
                  name={['manager', 'lastName']}
                  rules={[{ required: true }, lettersValidator]}
                >
                  <TooltipedInput placeholder="Фамилия" size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t(
                    'createLegalWaterRegistry.secondStep.placeholders.firstName',
                  )}
                  name={['manager', 'firstName']}
                  rules={[{ required: true }, lettersValidator]}
                >
                  <TooltipedInput placeholder="Имя" size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t(
                    'createLegalWaterRegistry.secondStep.placeholders.middleName',
                  )}
                  name={['manager', 'middleName']}
                  rules={[{ required: true }, lettersValidator]}
                >
                  <TooltipedInput placeholder="Отчество" size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('createIndividualWaterRegistry.modal.pin')}
                  name={['manager', 'inn']}
                  rules={[{ required: true }, { len: 14 }, numbersValidator]}
                >
                  <TooltipedInput
                    maxLength={14}
                    placeholder="ПИН"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t(
                    'createLegalWaterRegistry.secondStep.placeholders.phone',
                  )}
                  name={['manager', 'phone']}
                  rules={[
                    {
                      required: true,
                    },
                    phoneNumberValidator,
                  ]}
                  normalize={mapPhoneNumberToLong}
                >
                  <TooltipedInput
                    placeholder="Введите номер телефона"
                    size="large"
                    addonBefore="+996"
                    onChange={changeCursorPhoneNumberInput}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t(
                    'createLegalWaterRegistry.secondStep.placeholders.email',
                  )}
                  name={['manager', 'email']}
                  rules={[{ required: true, type: 'email' }]}
                >
                  <TooltipedInput placeholder="E-mail" size="large" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="WhatsApp" name={['manager', 'hasWhatsapp']}>
                  <WhatsAppInputButton
                    size="large"
                    btnName={['manager', 'hasWhatsapp']}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Telegram" name={['manager', 'hasTelegram']}>
                  <TelegramInputButton
                    size="large"
                    btnName={['manager', 'hasTelegram']}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="end" gutter={[0, 16]}>
              <Col className={styles.docsList} span={24}>
                <Typography.Title level={4}>
                  Прикрепленные документы:
                </Typography.Title>
                {attachmentDtos?.length ? (
                  <DocsList documents={attachmentDtos} />
                ) : (
                  <Mask maskText="Нет прикрепленных документов" />
                )}
              </Col>
              <Col>
                <Row gutter={16}>
                  <Col>
                    <DefaultButton
                      icon={<UploadFileIcon />}
                      onClick={() => {
                        setDefaultDocType('паспорт');
                        setShow(true);
                      }}
                    >
                      {t(
                        'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                      )}
                      *
                    </DefaultButton>
                  </Col>
                  <Col>
                    <Form.Item
                      name={['manager', 'attachmentsList']}
                      valuePropName="file"
                      getValueFromEvent={normalizeFile}
                    >
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        type="default"
                        onClick={() => {
                          setDefaultDocType('основание');
                          setShow(true);
                        }}
                      >
                        {t(
                          'createLegalWaterRegistry.secondStep.placeholders.attachReason',
                        )}
                        *
                      </DefaultButton>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={['manager', 'attachmentsList']}
                      valuePropName="file"
                      getValueFromEvent={normalizeFile}
                    >
                      <DefaultButton
                        type="default"
                        onClick={() => setShow(true)}
                        icon={<UploadFileIcon />}
                      >
                        {t(
                          'createLegalWaterRegistry.secondStep.placeholders.another',
                        )}
                      </DefaultButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomCard>
      <UploadModal
        setShow={setModal}
        show={show}
        onLoad={onAttachmentUpload}
        preSelectedDocType={defaultDocType}
      />
    </>
  );
};
