import React from 'react';
import { Link } from 'react-router-dom';

import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './monitoringColumn.module.scss';

export const useMonitoringHydroColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      xtype: CustomTableColumnType.NumberColumn,
      width: 10,
    },
    {
      title: 'Название',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 20,
    },
    {
      title: 'Канал',
      dataIndex: 'waterchannel',
      key: 'waterchannel',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 20,
    },
    {
      title: 'Водоток',
      dataIndex: 'watercourse',
      key: 'watercourse',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 20,
    },
    {
      title: 'Привязанк рейке',
      dataIndex: 'calibrated',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'calibrated',
      width: 20,
      align: 'center',
      renderCustom: ({ record }) => (
        <div>
          {record.calibrated ? (
            <CheckCircleOutlined className={styles.iconModel} />
          ) : (
            <MinusCircleOutlined className={styles.iconModel} />
          )}
        </div>
      ),
    },
    {
      title: 'Активен',
      dataIndex: 'is_active',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'is_active',
      width: 20,
      align: 'center',
      renderCustom: ({ record }) => (
        <div>
          {record.is_active ? (
            <CheckCircleOutlined className={styles.iconModel} />
          ) : (
            <MinusCircleOutlined className={styles.iconModel} />
          )}
        </div>
      ),
    },
    {
      title: '3D-модель',
      dataIndex: 'model_link',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'model_link',
      width: 20,
      align: 'center',
      renderCustom: ({ record }) =>
        record.model_link ? (
          <Link to={record.model_link}>
            <p>Показать</p>
          </Link>
        ) : (
          <MinusCircleOutlined className={styles.iconModel} />
        ),
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      width: 20,
      align: 'center',
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
