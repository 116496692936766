import { useEffect, useState } from 'react';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import { WaterUsePlanDataViewTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import {
  IWaterUsePlanByGuvhDto,
  IWaterUsePlanDataDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetWaterUsePlanByCaQuery,
  useGetWaterUsePlanByGuvhQuery,
  useGetWaterUsePlanQuery,
} from '@store/gisproApi';
import { CaFilters } from '@store/slices';

export type ReturnType = {
  data: IWaterUsePlanByGuvhDto | IWaterUsePlanDataDto;
  isLoading: boolean;
};

export const useWaterUsePlanDataQuery = (
  roleName: UserRoles,
  planType: PlanType,
  filter?: CaFilters | Omit<CaFilters, 'regionId'> | null,
  tab?: WaterUsePlanDataViewTypes,
): ReturnType => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: waterUsePlanByCa, isLoading: waterUsePlanByCALoading } =
    useGetWaterUsePlanByCaQuery(
      roleName === UserRoles.ROLE_CA ? { type: planType } : skipToken,
    );

  const { data: waterUsePlanByGuvh, isLoading: waterUsePlanByGuvhLoading } =
    useGetWaterUsePlanByGuvhQuery(
      roleName === UserRoles.ROLE_GUVH
        ? {
            type: planType,
            ruvhId:
              tab === WaterUsePlanDataViewTypes.View
                ? filter?.districtId
                : undefined,
          }
        : skipToken,
    );

  const { data: waterUsePlanByRuvh, isLoading: waterUsePlanByRuvhLoading } =
    useGetWaterUsePlanQuery(
      roleName === UserRoles.ROLE_RUVH ? { type: planType } : skipToken,
    );

  useEffect(() => {
    switch (roleName) {
      case UserRoles.ROLE_RUVH:
        setData(waterUsePlanByRuvh);
        setIsLoading(waterUsePlanByRuvhLoading);
        break;
      case UserRoles.ROLE_GUVH:
        setData(waterUsePlanByGuvh);
        setIsLoading(waterUsePlanByGuvhLoading);
        break;
      case UserRoles.ROLE_CA:
        setData(waterUsePlanByCa);
        setIsLoading(waterUsePlanByCALoading);
        break;
      default:
        break;
    }
  }, [
    roleName,
    waterUsePlanByCa,
    waterUsePlanByCALoading,
    waterUsePlanByGuvh,
    waterUsePlanByGuvhLoading,
    waterUsePlanByRuvh,
    waterUsePlanByRuvhLoading,
  ]);

  return { data, isLoading };
};
