import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomCard,
  DefaultButton,
  DocsList,
  Mask,
  PrimaryButton,
  TelegramButton,
  TooltipedInput,
  TooltipedSelect,
  WhatsAppButton,
  WhatsAppInputButton,
} from '@features/ui-kit';
import { TelegramInputButton } from '@features/ui-kit/buttons/TelegramInputButton';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import {
  AddButtonSecondary,
  TooltipedInputV2,
  TooltipedSelectV2,
  UploadModalV2,
} from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { PrintIcon } from '@icons/PrintIcon';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import { UploadFileIcon } from '@icons/UploadFileIcon';

export const AddAssociationManager: React.FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [show, setShow] = useState(false);
  const [defaultDocType, setDefaultDocType] = useState('');

  const genderOptions = useMemo(
    () => [
      { value: 'M', label: t('genders.male') },
      { value: 'F', label: t('genders.female') },
    ],
    [],
  );
  const attachmentDtos =
    Form.useWatch(['manager', 'attachmentsList'], form) || [];

  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue(['manager', 'attachmentsList']) || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue(
      ['manager', 'attachmentsList'],
      updatedAttachments,
    );
    setDefaultDocType('');
  };

  const { data: education = [], isLoading: educationIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.Education,
    });

  const { data: stateEmploye = [], isLoading: stateEmployeIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.StateEmploye,
    });

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {t(
                'createAssociationWaterRegistry.thirdStep.addAssociationManager.title',
              )}
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.lastName',
            )}
            name={['manager', 'lastName']}
            rules={[{ required: true }, lettersValidator]}
          >
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.inn',
            )}
            name={['manager', 'inn']}
            rules={[{ required: true }, { len: 14 }, numbersValidator]}
          >
            <TooltipedInput size="large" maxLength={14} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.phone',
            )}
            name={['manager', 'phone']}
            rules={[
              {
                required: true,
              },
              phoneNumberValidator,
            ]}
            normalize={mapPhoneNumberToLong}
          >
            <TooltipedInput
              size="large"
              addonBefore="+996"
              onChange={changeCursorPhoneNumberInput}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.firstName',
            )}
            name={['manager', 'firstName']}
            rules={[{ required: true }, lettersValidator]}
          >
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.education',
            )}
            name={['manager', 'educationId']}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={educationIsLoading}
              options={education.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.additionalPhone',
            )}
            name={['manager', 'additionalPhone']}
            rules={[phoneNumberValidator]}
            normalize={mapPhoneNumberToLong}
          >
            <TooltipedInput
              size="large"
              addonBefore="+996"
              onChange={changeCursorPhoneNumberInput}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.middleName',
            )}
            name={['manager', 'middleName']}
            rules={[{ required: true }, lettersValidator]}
          >
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.specialty',
            )}
            name={['manager', 'specialty']}
            rules={[{ required: true }]}
          >
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.gender',
            )}
            name={['manager', 'gender']}
            rules={[{ required: true }]}
          >
            <TooltipedSelect allowClear size="large" options={genderOptions} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.email',
            )}
            name={['manager', 'email']}
            rules={[{ required: true, type: 'email' }]}
          >
            <TooltipedInput size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.thirdStep.addAssociationManager.placeholders.govWorker',
            )}
            name={['manager', 'govWorkerId']}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              allowClear
              loading={stateEmployeIsLoading}
              options={stateEmploye.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label="WhatsApp" name={['manager', 'hasWhatsapp']}>
            <WhatsAppInputButton
              size="large"
              btnName={['manager', 'hasWhatsapp']}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Telegram" name={['manager', 'hasTelegram']}>
            <TelegramInputButton
              size="large"
              btnName={['manager', 'hasWhatsapp']}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="end" gutter={[0, 16]}>
            <Col className={styles.docsList} span={24}>
              <Typography.Title level={4}>
                Прикрепленные документы:
              </Typography.Title>
              {attachmentDtos?.length ? (
                <DocsList documents={attachmentDtos} />
              ) : (
                <Mask maskText="Нет прикрепленных документов" />
              )}
            </Col>
            <Col>
              <Row gutter={16}>
                <Col>
                  <DefaultButton
                    icon={<UploadFileIcon />}
                    onClick={() => {
                      setDefaultDocType('паспорт');
                      setShow(true);
                    }}
                  >
                    {t(
                      'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                    )}
                    *
                  </DefaultButton>
                </Col>
                <Col>
                  <Form.Item
                    name={['manager', 'attachmentsList']}
                    valuePropName="file"
                    getValueFromEvent={normalizeFile}
                  >
                    <DefaultButton
                      type="default"
                      icon={<UploadFileIcon />}
                      onClick={() => {
                        setDefaultDocType('основание');
                        setShow(true);
                      }}
                    >
                      {t(
                        'createLegalWaterRegistry.secondStep.placeholders.attachReason',
                      )}
                      *
                    </DefaultButton>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={['manager', 'attachmentsList']}
                    valuePropName="file"
                    getValueFromEvent={normalizeFile}
                  >
                    <DefaultButton
                      icon={<UploadFileIcon />}
                      type="default"
                      onClick={() => setShow(true)}
                    >
                      {t(
                        'createLegalWaterRegistry.secondStep.placeholders.another',
                      )}
                    </DefaultButton>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <UploadModal
        setShow={setShow}
        show={show}
        onLoad={onAttachmentUpload}
        preSelectedDocType={defaultDocType}
      />
    </CustomCard>
  );
};
