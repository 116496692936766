import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const CopySolidIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 12.25V13.3438C9.75 13.7062 9.45619 14 9.09375 14H1.65625C1.29381 14 1 13.7062 1 13.3438V3.28125C1 2.91881 1.29381 2.625 1.65625 2.625H3.625V10.7188C3.625 11.5631 4.3119 12.25 5.15625 12.25H9.75ZM9.75 2.84375V0H5.15625C4.79381 0 4.5 0.293809 4.5 0.65625V10.7188C4.5 11.0812 4.79381 11.375 5.15625 11.375H12.5938C12.9562 11.375 13.25 11.0812 13.25 10.7188V3.5H10.4062C10.0453 3.5 9.75 3.20469 9.75 2.84375ZM13.0578 1.9953L11.2547 0.192199C11.1316 0.0691366 10.9647 9.09989e-07 10.7907 0L10.625 0V2.625H13.25V2.45932C13.25 2.28528 13.1809 2.11837 13.0578 1.9953Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  );
};
