import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Links } from '@enums/links';
import { Routes } from '@enums/routes';
import { CustomModalV2, NavigateButtonV2 } from '@features/ui-kit-v2';
import { CreateMainConduitQueryTypes } from '@models/waterObject/enums/mainConduitEnum';
import { clearIrrigationSystemAction } from '@store/slices';

import styles from './MainConduitCreateStepperActionSelectModal.module.scss';
import { useMainConduitCreateStepperActionSelectModal } from './useMainConduitCreateStepperActionSelectModal';

export const MainConduitCreateStepperActionSelectModal: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { systemId } = useParams<{ systemId: any }>();

  const { isModalShown, setShowModal } =
    useMainConduitCreateStepperActionSelectModal();

  const onClickHome = () => {
    setShowModal(false);
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  };

  const onNavigateToConstructor = useCallback(() => {
    navigate(
      generatePath(Routes.IrrigationSystemWaterObjectsRegistration, {
        systemId,
      }),
    );
    setShowModal(false);
  }, [systemId]);

  const onCreateMainConduitClick = useCallback(() => {
    navigate(
      `${generatePath(Routes.IrrigationSystemMainConduitRegistration, {
        systemId,
        mainConduitId: 'none',
      })}?${SectionQueryKey}=${CreateMainConduitQueryTypes.totalInformation}`,
    );
    setShowModal(false);
  }, [systemId]);

  return (
    <CustomModalV2
      centered
      title="Выбор действия"
      open={isModalShown}
      onCancel={() => setShowModal(false)}
      width={400}
      footer={<span />}
    >
      <Row justify="center">
        <Space
          size="large"
          direction="vertical"
          className={styles.actionButtons}
        >
          <Row>
            <NavigateButtonV2
              onClick={onClickHome}
              icon={<img src={Links.Home} alt="" />}
              title="Перейти на главную страницу"
            />
          </Row>

          <Row>
            <NavigateButtonV2
              icon={<img src={Links.CheckCard} alt="" />}
              onClick={onCreateMainConduitClick}
              title="Создать новый водовод"
            />
          </Row>

          <Row>
            <NavigateButtonV2
              icon={<img src={Links.LocationIcon} alt="" />}
              onClick={onNavigateToConstructor}
              title="Перейти в конструктор"
            />
          </Row>
        </Space>
      </Row>
    </CustomModalV2>
  );
};
