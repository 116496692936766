import { createSelector } from '@reduxjs/toolkit';
import { WaterObjectState } from '@store/slices';

type WithWaterObjectState = {
  waterObject: WaterObjectState;
};

export const waterObjectStateSelector = (
  state: WithWaterObjectState,
): WaterObjectState => state.waterObject;

export const getWaterObjectFiltersSelector = createSelector(
  waterObjectStateSelector,
  (waterObjectState) => {
    return waterObjectState.waterObjectFilters;
  },
);

export const getShowMainWaterWearSumSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowWearSum;
  },
);

export const getInitialValuesWearSumSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.initialValuesWearSum;
  },
);

export const getWaterObjectSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.waterObject;
  },
);

export const getShowMainWaterWorkMarkSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowWorkMark;
  },
);

export const getInitialValuesWorkMarkSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.initialValuesWorkMark;
  },
);

export const getShowMainWaterWorkVolumeSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowWorkVolume;
  },
);

export const getShowMainWaterSizeSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowSize;
  },
);

export const getSelectedWorkDtoSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.selectedWorkDto;
  },
);

export const getSelectedWaterSectionSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.selectedWaterSection;
  },
);

export const getShowMainWaterTechStatsSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowTechStats;
  },
);

export const getShowMainWaterConstuctorStatsSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isShowConstructorStats;
  },
);

export const getIsCreateWaterSectionModalShownSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.isCreateWaterSectionModalShown;
  },
);

export const getAllWaterObjectWearDtosSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.waterObject?.wearDtos;
  },
);

export const getAllWaterObjectWorkDtosSelector = createSelector(
  waterObjectStateSelector,
  (waterMainState) => {
    return waterMainState.waterObject?.workDtos;
  },
);

export const getIsWaterObjectStepperActionSelectModalShownSelector =
  createSelector(waterObjectStateSelector, (waterMainState) => {
    return waterMainState.isWaterObjectStepperActionSelectModalShown;
  });
