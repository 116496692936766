import { SegmentedLabeledOption } from 'antd/es/segmented';

import { SectionQueryKey } from '@constants/queryKeys';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

import { CustomSegmented } from '../segmentedV2';

interface IWaterUserSegmentedV2Props {
  colorType?: 'primary' | 'default' | 'turquoise' | 'green';
}

const users: SegmentedLabeledOption[] = [
  {
    value: WaterUserTypes.physical,
    label: 'Физ. лица',
  },
  {
    value: WaterUserTypes.legal,
    label: 'Юр. лица',
  },
  {
    value: WaterUserTypes.association,
    label: 'Ассоциации',
  },
  {
    value: WaterUserTypes.all,
    label: 'Все',
  },
];

export const WaterUserSegmentedV2: React.FC<IWaterUserSegmentedV2Props> = ({
  colorType = 'default',
}) => {
  return (
    <CustomSegmented
      colorType={colorType}
      options={users}
      queryParams={{
        queryKey: SectionQueryKey,
        queryType: WaterUserTypes,
      }}
      defaultValue={WaterUserTypes.physical}
    />
  );
};
