import { FC, useMemo } from 'react';

import { Col, Form, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Links } from '@enums/links';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { debounce, get } from '@utils/utils';

import { useWorkNotationModal } from './useWorkNotationModal';
import styles from './workNotationModal.module.scss';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedTextarea,
} from '@features/ui-kit';

export const WorkNotationModal: FC = () => {
  const form = Form.useFormInstance();
  const formValues = form.getFieldsValue();
  const {
    isModalShown,
    setShowModal,
    selectedWorkNotationIndex = 0,
  } = useWorkNotationModal();

  const { data: workKinds = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.KindOfWork,
  });

  const workTypeId = useMemo(
    () =>
      get(formValues, ['works', String(selectedWorkNotationIndex), 'workType']),
    [formValues, selectedWorkNotationIndex],
  );

  const onChange = (event: any) => {
    form.setFieldValue(
      ['works', String(selectedWorkNotationIndex), 'description'],
      event.target.value,
    );
  };

  const onSave = () => {
    console.log(form.getFieldValue('works'));
  };

  const debounceOnChange = debounce(onChange, 20);

  return (
    <CustomModal
      open={isModalShown}
      title="Описание проведенных работ"
      footer={
        <Row gutter={8} justify="center">
          <Col span={7}>
            <DefaultButton onClick={() => setShowModal(false)}>
              Закрыть
            </DefaultButton>
          </Col>
        </Row>
      }
      onCancel={() => setShowModal(false)}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name={['works', String(selectedWorkNotationIndex), 'description']}
            label="Описание"
            required
            className={styles.formItem}
          >
            <TooltipedTextarea
              onChange={debounceOnChange}
              autoSize={{ minRows: 15, maxRows: 20 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomModal>
  );
};
