import { useGetCurrentUser } from '@features/authentication';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import { normalizeValue } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/normalizeValue';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { useCreatingSiteAssociationModal } from '@features/waterUser/waterUserCard/useCreatingSiteAssociationModal';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useAddOutletMutation,
  useFindAllByOrganizationIdQuery,
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useFindDictionaryByTypeIdQuery,
  useGetHydroFacilityAllQuery,
  useUpdateOutletMutation,
} from '@store/gisproApi';
import { useParams } from 'react-router-dom';

type IndividualPermissionsModalForm = {
  login: string;
  password: string;
  pin: string;
  phone: string;
  email: string;
};

export const CreatingSiteAssociationModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams();

  const { isModalShown, setShowModal, getInitialValues } =
    useCreatingSiteAssociationModal();
  const irrigationSystemId = Form.useWatch('irrigationSystemId', form);
  const hydroFacilityId = Form.useWatch('hydroFacility', form);
  const channel = Form.useWatch('channel', form);
  const region = Form.useWatch('region', form);
  const district = Form.useWatch('district', form);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region,
    district,
  });

  const { data: irrigationSystems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId ? { hydroFacilityId } : skipToken,
    );

  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    irrigationSystemId ? { id: irrigationSystemId } : skipToken,
  );

  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    channel ? { id: channel } : skipToken,
  );

  const [addOutlet] = useAddOutletMutation();
  const [updateOutlet] = useUpdateOutletMutation();

  const onFinish = (values: any) => {
    if (!getInitialValues) {
      addOutlet({
        id,
        body: {
          outletId: values.outletId,
          kpd: values.kpd,
          area: values.area,
        },
      });
    } else {
      updateOutlet({
        id,
        body: {
          outletId: values.outletId,
          kpd: values.kpd,
          area: values.area,
        },
      });
    }
    setShowModal(false);
  };

  const onHydroFacilityChange = () => {
    form.setFieldsValue({
      irrigationSystemId: undefined,
      channel: undefined,
      outletId: undefined,
    });
  };

  const onIrrigationSystemChange = () => {
    form.setFieldsValue({
      channel: undefined,
      outletId: undefined,
    });
  };

  const onChannelChange = () => {
    form.setFieldsValue({
      outletId: undefined,
    });
  };

  const onCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  console.log('getInitialValues', getInitialValues);
  const initialValues = {
    hydroFacility: getInitialValues?.hydroFacility?.id,
    irrigationSystemId: getInitialValues?.irrigationSystem?.id,
    channel: getInitialValues?.sections?.systemObject?.id,
    outletId: getInitialValues?.id,
    region: getInitialValues?.region?.id || user?.owner?.region?.id,
    district: getInitialValues?.district?.id || user?.owner?.district?.id,
    area: getInitialValues?.area,
    kpd: getInitialValues?.kpd,
  };

  return (
    <CustomModal
      centered
      title={
        getInitialValues ? 'Редактировать водовыпуск' : 'Создание водовыпуска'
      }
      open={isModalShown}
      onCancel={onCancel}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={830}
      footer={false}
    >
      <Form<IndividualPermissionsModalForm>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="region"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
              )}
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                placeholder="Область"
                size="large"
                options={regions}
                disabled={isDisabledRegion || getInitialValues}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
              )}
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                options={districts}
                placeholder="Район"
                size="large"
                disabled={!region || getInitialValues || isDisabledDistrict}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="hydroFacility"
              label="Гидроучасток"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                options={hydroFacilities}
                onChange={onHydroFacilityChange}
                placeholder="Гидроучасток"
                disabled={!region || !district || getInitialValues}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="irrigationSystemId"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.irrigationSystem',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility']}
            >
              <TooltipedSelect
                size="large"
                placeholder="Оросительная система"
                options={irrigationSystems}
                onChange={onIrrigationSystemChange}
                disabled={!hydroFacilityId || getInitialValues}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="channel"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.channel',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility', 'irrigationSystemId']}
            >
              <TooltipedSelect
                size="large"
                placeholder="Канал"
                options={channels}
                disabled={
                  !irrigationSystemId || !hydroFacilityId || getInitialValues
                }
                onChange={onChannelChange}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="outletId"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.waterOutlet',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility', 'irrigationSystemId', 'channel']}
            >
              <TooltipedSelect
                placeholder="Водовыпуск"
                size="large"
                options={outlets}
                disabled={
                  !channel ||
                  !irrigationSystemId ||
                  !hydroFacilityId ||
                  getInitialValues
                }
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="area"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.totalArea',
              )}
              rules={[{ required: true }]}
            >
              <TooltipedInput
                placeholder="Общая площадь"
                size="large"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="kpd"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.efficiency',
              )}
              rules={[{ required: true }]}
              normalize={normalizeValue}
            >
              <TooltipedInput placeholder="КПД" size="large" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton type="primary" size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton type="primary" size="large" htmlType="submit">
              {getInitialValues ? 'Изменить' : 'Добавить'}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
