import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function renderWaterUserCell<RecordType extends JsonRecord>({
  record,
  customColumn,
  actionProps,
}: TableRendererProps<RecordType>) {
  const value = record[customColumn.dataIndex];
  const { userColor } = customColumn;
  const onButtonClick = () => {
    actionProps?.showWaterUserInfo?.();
  };

  return (
    <div className={styles.waterUserWrapper}>
      {/* <Button className={styles.waterUserButton} onClick={onButtonClick}>
        Инфо
      </Button> */}
      <button
        type="button"
        className={`${styles.waterUserName} ${userColor && styles[userColor]}`}
        onClick={onButtonClick}
      >
        {value || '-'}
      </button>
    </div>
  );
}
