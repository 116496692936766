import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  CustomModal,
  PrimaryButton,
  TableCustom,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';

import {
  useDeleteEmployeeOwnerMutation,
  useDeletePermissionByIdMutation,
  useGetOwnerInfoQuery,
  useSignUpMutation,
} from '@store/gisproApi';
import { Col, Divider, Form, notification, Row, Typography } from 'antd';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useProfileEmployeeListTableColumns } from './useProfileEmployeeListTableColumns';
import { useAccessControllerGetRolesQuery } from '@store/api/accessApi';
import { OwnerTypes, OwnerTypesNames } from '@enums/ownerTypes';
import { RevokeAccessModal } from '@features/waterUser/revokeAccessModal/RevokeAccessModal';
import { useParams } from 'react-router-dom';
interface IProfileEmployeeListTableProps {
  dataSource: any;
  setShowModal: (value: boolean) => void;
  setEmployeeInitialValue: (value: any) => void;
}

export const ProfileEmployeeListTable: FC<IProfileEmployeeListTableProps> = ({
  dataSource,
  setShowModal,
  setEmployeeInitialValue,
}) => {
  const [update] = useSignUpMutation();
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [employee, setEmployee] = useState<any>(null);
  const [revokeAccess] = useDeletePermissionByIdMutation();
  const [isShowRevokeAccessModal, setShowRevokeAccessModal] = useState(false);
  const [form] = Form.useForm();
  const { columns } = useProfileEmployeeListTableColumns(dataSource?.employees);
  const [deleteEmployee] = useDeleteEmployeeOwnerMutation();

  const { data: roles = [] } = useAccessControllerGetRolesQuery();

  const mappedRoles = useMemo(() => {
    return roles.reduce<
      { label: string; value: number; parent: { id: number | null } }[]
    >((acc, item) => {
      if (
        item?.roleName === OwnerTypesNames[dataSource?.type as OwnerTypes] ||
        (item?.parent?.roleName &&
          item?.parent?.roleName ===
            OwnerTypesNames[dataSource?.type as OwnerTypes])
      ) {
        acc.push({
          label: item?.title,
          value: item?.id,
          parent: item.parent?.id ?? null,
        });
      }
      return acc;
    }, []);
  }, [roles]);

  const onHandleAccess = (val: any) => {
    console.log('val', val);
    if (val.users?.length <= 1) {
      setEmployee(val);
      setOpen(true);
      form.setFieldValue('login', val.users?.[0]?.login);
      form.setFieldValue('role', val.users?.[0]?.role?.id || null);
      setDisable(!!val.users?.[0]?.login);
    } else {
      notification.error({
        message: `В системе зарегистрировано ${val.users.length}  вользователей. Обратитесь к администраций`,
      });
    }
  };

  const onHandleEdit = (val: any) => {
    setShowModal(true);
    setEmployeeInitialValue(val);
  };

  const deleteItem = (val: any) => {
    deleteEmployee({ id: val.id });
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFinish = (values: any) => {
    console.log(values, 'values');
    update([{ ...values, employeeId: employee.id }])
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Доступ успешно выдан',
        });
      })
      .catch((e) => {
        notification.error({
          message: e?.data?.message ?? 'Не удалось выдать доступ',
        });
        console.log('e', e);
      });
    onCancel();
  };

  const deleteRevokeAccess = useCallback(() => {
    employee?.id &&
      revokeAccess({ id: employee?.id })
        .unwrap()
        .then(() => {
          setShowRevokeAccessModal(false);
        })
        .catch((err) => {
          notification.error({
            message: err?.data?.message ?? 'Произошла  ошибка',
          });
          setShowRevokeAccessModal(false);
        });
  }, [employee?.id]);

  return (
    <>
      <TableCustom
        columns={columns}
        dataSource={dataSource?.employees || []}
        actionProps={{
          onHandleAccess,
          onHandleEdit,
          deleteItem,
        }}
        bordered
      />
      <CustomModal
        centered
        title={'Выдача доступа'}
        open={open}
        onCancel={onCancel}
        width={840}
        footer={null}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Логин"
                name="login"
                rules={[{ required: true }]}
              >
                <TooltipedInput disabled={disable} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[{ required: true }]}
              >
                <TooltipedInput allowClear placeholder="Введите пароль" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Роль" name="role" rules={[{ required: true }]}>
                <TooltipedSelect
                  options={mappedRoles}
                  allowClear
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="bottom">
            <Col>
              <Form.Item>
                <PrimaryButton size="large" onClick={() => form.submit()}>
                  Выдать доступы
                </PrimaryButton>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <PrimaryButton
                  size="large"
                  type="primary"
                  onClick={() => setShowRevokeAccessModal(true)}
                >
                  Отозвать доступ
                </PrimaryButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {isShowRevokeAccessModal && (
          <RevokeAccessModal
            revokeAccess={deleteRevokeAccess}
            onCancel={() => setShowRevokeAccessModal(false)}
          />
        )}
      </CustomModal>
    </>
  );
};
