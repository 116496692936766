import { SegmentedLabeledOption } from 'antd/es/segmented';
import { DefaultOptionType } from 'antd/es/select';

export const quartalOptions: SegmentedLabeledOption[] & DefaultOptionType[] = [
  {
    value: '1',
    label: 'I квартал',
  },
  {
    value: '2',
    label: 'II квартал',
  },
  {
    value: '3',
    label: 'III квартал',
  },
  {
    value: '4',
    label: 'IV квартал',
  },
];
