import { FormInstance } from 'antd';

import dayjs from 'dayjs';

interface IDeadlineValidationProps {
  _: any;
  value: any;
  form: FormInstance<any>;
  type:
    | 'planEndDate'
    | 'factEndDate'
    | 'planStartDate'
    | 'factStartDate'
    | 'start'
    | 'end'
    | any;
}

export const validateStartDate = ({
  _,
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const endDate = form.getFieldValue(['implementationTerms', type, 'end']); // Получаем дату конца для текущего элемента списка

  if (value && endDate && value.isAfter(endDate)) {
    return Promise.reject(
      new Error('Дата начала должна быть раньше даты окончания'),
    );
  }
  return Promise.resolve();
};

export const validateEndDate = ({
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const startDate = form.getFieldValue(['implementationTerms', type, 'start']); // Получаем дату начала для текущего элемента списка

  if (value && startDate && value.isBefore(startDate)) {
    return Promise.reject(
      new Error('Дата окончания должна быть позже даты начала'),
    );
  }
  return Promise.resolve();
};

export const validatePlanStartDate = ({
  _,
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const { planEndDate } = form.getFieldsValue([type]);
  if (value && planEndDate && value >= planEndDate) {
    const error = new Error('Начальная дата должна быть меньше конечной даты');
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const validatePlanEndDate = ({
  _,
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const { planStartDate } = form.getFieldsValue([type]);
  if (value && planStartDate && value <= planStartDate) {
    const error = new Error('Конечная дата должна быть больше начальной даты');
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const validateFactStartDate = ({
  _,
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const { factEndDate } = form.getFieldsValue([type]);
  if (value && factEndDate && value >= factEndDate) {
    const error = new Error('Начальная дата должна быть меньше конечной даты');
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const validateFactEndDate = ({
  _,
  value,
  form,
  type,
}: IDeadlineValidationProps) => {
  const { factStartDate } = form.getFieldsValue([type]);
  if (value && factStartDate && value <= factStartDate) {
    const error = new Error('Конечная дата должна быть больше начальной даты');
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const inspectionDate = ({ _, value }: any) => {
  const selectedDate = dayjs(value);
  const currentDate = dayjs();
  if (selectedDate.isBefore(currentDate)) {
    const error = new Error('Дата инспекции должна быть позже текущей даты');
    return Promise.reject(error);
  }
  return Promise.resolve();
};
