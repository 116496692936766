import { createSelector } from '@reduxjs/toolkit';
import { IrrigationRegistryState } from '@store/slices';

type WithIrrigationRegistryState = {
  irrigationRegistry: IrrigationRegistryState;
};

export const irrigationRegistryStateSelector = (
  state: WithIrrigationRegistryState,
): IrrigationRegistryState => state.irrigationRegistry;

export const getCurrentIrrigationSystemSelector = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.irrigationSystem;
  },
);

export const getIrrigationSystemFiltersSelector = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.irrigationSystemFilters;
  },
);

export const getObjectDocumentsSelector = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.documents;
  },
);

export const getSelectedWorkNotationIndexSelector = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.selectedWorkNotationIndex;
  },
);

export const getCreateWaterObjectList = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.createWaterObjectList;
  },
);

export const getShowWorkNotation = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.isShowWorkNotationModal;
  },
);

export const getShowCreateWaterObjectModal = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.isShowCreateWaterObjectModal;
  },
);

export const getShowCreateIrrigationSystemAction = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.isShowCreateIrrigationSystemActionModal;
  },
);

export const getShowObjectsIrrigationSystemAction = createSelector(
  irrigationRegistryStateSelector,
  (irrigationState) => {
    return irrigationState.isShowObjectsIrrigationSystemActionModal;
  },
);
