import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const LayerIcon: React.FC<IconProps> = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75739 1.94687L14.1824 3.69354C15.4574 4.19354 15.4574 5.02021 14.1824 5.52021L9.75739 7.26689C9.25489 7.46689 8.42989 7.46689 7.92739 7.26689L3.50239 5.52021C2.22739 5.02021 2.22739 4.19354 3.50239 3.69354L7.92739 1.94687C8.42989 1.74687 9.25489 1.74687 9.75739 1.94687Z"
        fill="black" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.25 7.3335C2.25 7.8935 2.7225 8.54016 3.3 8.76683L8.3925 10.7802C8.7825 10.9335 9.225 10.9335 9.6075 10.7802L14.7 8.76683C15.2775 8.54016 15.75 7.8935 15.75 7.3335"
        stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.25 10.6665C2.25 11.2865 2.6625 11.8465 3.3 12.0998L8.3925 14.1132C8.7825 14.2665 9.225 14.2665 9.6075 14.1132L14.7 12.0998C15.3375 11.8465 15.75 11.2865 15.75 10.6665"
        stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
