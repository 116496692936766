import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { MonthEnums } from '@enums/month';
import {
  PrimaryButton,
  Segmented,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import {
  MainWaterUserTypes,
  WaterUserEnum,
  WaterUserTypesUp,
} from '@models/waterUser/enums/waterUserEnums';

import { useWateringFilters } from './useWateringFilters';
import { useSearchParams } from 'react-router-dom';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery, useFindAllOutletsByChannelIdQuery,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import dayjs, { Dayjs } from 'dayjs';
import { useGetCurrentUser } from '@features/authentication';
import { SearchOutlined } from '@ant-design/icons';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';

const userTypes: SegmentedLabeledOption[] = [
  { value: WaterUserEnum.INDIVIDUAL, label: 'Физ лицо' },
  { value: WaterUserEnum.LEGAL, label: 'Юр лицо' },
  { value: WaterUserEnum.ASSOCIATION, label: 'Ассоциация' },
];

export const WateringScheduleControls = () => {
  const [params, setParams] = useSearchParams();
  const { filters, setWaterUseWateringFilters } = useWateringFilters();
  const [filterState, setFilterState] = useState<Partial<IWaterUseFilters>>({});
  const { t } = useTranslation();
  const { user } = useGetCurrentUser();

  const namePlaceholder =
    filters.userType === WaterUserEnum.INDIVIDUAL
      ? 'ФИО'
      : 'Наименование организации';
  const monthOptions = useMemo(
    () =>
      Object.values(MonthEnums).map((month) => ({
        label: t(`months.${month}`),
        value: month,
      })),
    [MonthEnums],
  );

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      filterState.hydroFacilityId
        ? {
            hydroFacilityId: filterState.hydroFacilityId,
          }
        : skipToken,
    );

  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    filterState.irrigationSystemId
      ? { id: filterState.irrigationSystemId }
      : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    filterState.channelId ? { id: filterState.channelId } : skipToken,
  );
  const changeMonthHandle = (v: SegmentedValue) => {
    setFilterState((state: any) => ({
      ...state,
      month: v as MonthEnums,
    }));
    setWaterUseWateringFilters({
      ...filters,
      month: v as MonthEnums,
    });
    params.set('month', v as MonthEnums);
    setParams(params);
  };

  const changeWaterUserTypeHandle = (v: SegmentedValue) => {
    setFilterState((state: any) => ({
      ...state,
      waterUser: v as WaterUserTypesUp,
    }));
    setWaterUseWateringFilters({
      ...filters,
      userType: v as WaterUserEnum,
    });
  };

  const onFilterChange = (
    filterKey: keyof IWaterUseFilters,
    v: string | null | Dayjs | number,
  ) => {
    const updatedFilters = {
      ...filterState,
      [filterKey]: v,
      page: 1,
    };
    setFilterState(updatedFilters);
  };

  const onResetFilterButtonClick = () => {
    setWaterUseWateringFilters({
      ...filterState,
      month: MonthEnums.January,
      userType: WaterUserEnum.INDIVIDUAL,
      outletName: '',
      irrigationSystemId: null,
      hydroFacilityId: null,
      channelId: null,
      outletId: null,
      name: '',
      year: null,
      page: 1,
      size: 10,
    });
  };

  const onFilterButtonClick = () => {
    setWaterUseWateringFilters({
      ...(filters as IWaterUseFilters),
      ...filterState,
    });
  };

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  return (
    <>
      <Row gutter={[5, 5]}>
        <Col>
          <TooltipedInput
            value={filterState.name}
            onChange={({ target }) => onFilterChange('name', target.value)}
            placeholder="Введите наименование организации/ФИО"
            width={348}
            prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
          />
        </Col>
        <Col>
          <TooltipedSelect
            options={hydroFacilities}
            value={filterState.hydroFacilityId}
            onChange={(v: number) => onFilterChange('hydroFacilityId', v)}
            placeholder="Наименование гидроучастка"
            allowClear
          />
        </Col>
        <Col>
          <TooltipedSelect
            options={systems}
            value={filterState.irrigationSystemId}
            onChange={(v: number) => onFilterChange('irrigationSystemId', v)}
            placeholder="Наименование системы"
            disabled={!filterState.hydroFacilityId}
            allowClear
          />
        </Col>
        <Col>
          <TooltipedSelect
            popupMatchSelectWidth={300}
            options={channels}
            onChange={(v: number) => onFilterChange('channelId', v)}
            placeholder="Канал"
            allowClear
            value={filterState.channelId}
            disabled={!filterState.hydroFacilityId || !filterState.irrigationSystemId}
          />
        </Col>
        <Col>
          <TooltipedSelect
            popupMatchSelectWidth={300}
            onChange={(v: number) => onFilterChange('outletId', v)}
            options={outlets}
            placeholder="Водовыпуск"
            allowClear
            value={filterState.outletId}
            disabled={!filterState.hydroFacilityId || !filterState.irrigationSystemId || !filterState.channelId}
          />
        </Col>
        <Col>
          <YearPicker
            value={filterState.year ? dayjs(filterState.year) : null}
            placeholder="Год"
            onChange={(date: Dayjs | null, year) =>
              onFilterChange('year', year.toString())
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col>
          <PrimaryButton onClick={onFilterButtonClick} icon={<FilterIcon />} />
        </Col>
        <Col>
          <PrimaryButton
            onClick={onResetFilterButtonClick}
            icon={<ResetFilterIcon />}
          />
        </Col>
        <Col span={24}>
          <Segmented
            options={monthOptions}
            onChange={changeMonthHandle}
            value={filters.month}
            defaultValue={filters.month}
          />
        </Col>
        <Col span={24}>
          <Segmented
            options={userTypes}
            onChange={changeWaterUserTypeHandle}
            defaultValue={filters.userType}
            value={filters.userType}
          />
        </Col>
      </Row>
    </>
  );
};
