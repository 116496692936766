
import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoReloadIcon: React.FC<IconProps> = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="24" fill="white" fillOpacity="0.12" />
      <g clipPath="url(#clip0_6057_182627)">
        <path
          d="M7.21175 12.729C7.18197 12.4901 7.16663 12.2468 7.16663 11.9998C7.16663 8.77817 9.7783 6.1665 13 6.1665C14.8233 6.1665 16.4513 7.00305 17.5209 8.31329M17.5209 8.31329V6.1665M17.5209 8.31329V8.35395L15.3334 8.354M18.7882 11.2707C18.818 11.5095 18.8333 11.7529 18.8333 11.9998C18.8333 15.2215 16.2216 17.8332 13 17.8332C11.2577 17.8332 9.69384 17.0694 8.62496 15.8583M8.62496 15.8583V15.6457H10.8125M8.62496 15.8583V17.8332"
          stroke="black" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6057_182627">
          <rect width="14" height="14" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
