import { get } from '@utils/utils';

export const generateFio = (user: any) => {
  const fio = [
    get(user, 'lastName'),
    get(user, 'firstName'),
    get(user, 'middleName'),
  ];
  return fio.join(' ');
};
