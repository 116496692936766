import { IAnnualPlan, IThreeMoth } from '@models/annualPlan/enums/AnnualPlan';
import { set, get } from '@utils/utils';

interface generatedItem {
  [key: string]: string | number | Array<generatedItem> | undefined;
  children?: Array<generatedItem>;
}

const processMonthData = (
  item: generatedItem,
  monthName: string,
  month: IThreeMoth,
) => {
  const firstQuarterValue =
    (get(item, `${monthName}FirstQuarterValue`, 0) as number) +
    month.decadeMap['1'];
  const secondQuarterValue =
    (get(item, `${monthName}FirstQuarterValue`, 0) as number) +
    month.decadeMap['2'];
  const thirdQuarterValue =
    (get(item, `${monthName}FirstQuarterValue`, 0) as number) +
    month.decadeMap['3'];
  const quarterTotalValue =
    get(item, `${monthName}QuarterTotalValue`, 0) +
    month.planByMonths[monthName];
  const totalGrowth =
    (get(item, `${monthName}QuarterTotalValue`, 0) as number) + month.totalArea;

  set(item, `${monthName}FirstQuarterValue`, firstQuarterValue);
  set(item, `${monthName}SecondQuarterValue`, secondQuarterValue);
  set(item, `${monthName}ThirdQuarterValue`, thirdQuarterValue);
  set(item, `${monthName}QuarterTotalValue`, quarterTotalValue);
  set(item, `${monthName}TotalGrowth`, totalGrowth);
  return item;
};

export const mapAnnualPlanYearlyData = (data: Array<IAnnualPlan>) => {
  const regionsItems = data?.reduce<generatedItem[]>((acc, curr) => {
    // 1. process Region Item
    const existingRegionItemIndex = acc.findIndex(
      (item) => item.key === curr.regionId,
    );
    let regionItem = {
      name: curr.regionName,
      key: curr.regionId,
      children: [],
    } as generatedItem;

    if (existingRegionItemIndex >= 0) {
      regionItem = acc[existingRegionItemIndex];
    }

    (curr.planQuarterDtoList || []).forEach((planQuarterDto) => {
      (planQuarterDto.threeMonths || []).forEach((month) => {
        const monthName = Object.keys(month.planByMonths)[0];
        if (monthName) {
          processMonthData(regionItem, monthName, month);
        }
      });
    });

    // 1. process District Item
    let districtItem = {
      name: curr.districtName,
      key: curr.districtId,
      children: [],
    } as generatedItem;

    const existingDistrictItemIndex = (regionItem.children || []).findIndex(
      (item) => item.key === curr.districtId,
    );

    if (existingDistrictItemIndex >= 0) {
      districtItem = (regionItem.children || [])[existingDistrictItemIndex];
    }

    (curr.planQuarterDtoList || []).forEach((planQuarterDto) => {
      (planQuarterDto.threeMonths || []).forEach((month) => {
        const monthName = Object.keys(month.planByMonths)[0];
        if (monthName) {
          processMonthData(districtItem, monthName, month);
        }
      });
    });

    // 3. process Facility Item
    (curr.planQuarterDtoList || []).forEach((planQuarterDto) => {
      (planQuarterDto.threeMonths || []).forEach((month) => {
        if (month.orgName) {
          let existedFailictityItemIndex = (
            (regionItem.children || [])[existingDistrictItemIndex].children ||
            []
          ).findIndex((item) => item.key === month.orgId);
          const monthName = Object.keys(month.planByMonths)[0];

          if (existedFailictityItemIndex < 0) {
            (
              (regionItem.children || [])[existingDistrictItemIndex].children ||
              []
            ).push({
              name: month.orgName,
              key: month.orgId,
            });
            existedFailictityItemIndex = 0;
          }
          const existingFacilityItem = get(
            regionItem,
            [
              'children',
              existingDistrictItemIndex,
              'children',
              existedFailictityItemIndex,
            ],
            {
              name: month.orgName,
              key: month.orgId,
            },
          );
          processMonthData(existingFacilityItem, monthName, month);
        }
      });
    });

    // 4. push to Array of items if not exist else replace the one
    if (existingDistrictItemIndex >= 0) {
      (regionItem.children || [])[existingDistrictItemIndex] = districtItem;
    } else {
      (regionItem.children || []).push(districtItem);
    }

    if (existingRegionItemIndex >= 0) {
      acc[existingRegionItemIndex] = regionItem;
      return acc;
    }

    return [...acc, regionItem];
  }, []);

  return regionsItems;
};
