import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsWaterObjectStepperActionSelectModalShownSelector } from '@store/selectors/waterObject';
import { waterObjectActions } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export const useWaterObjectStepperActionSelectModal = (): ReturnType => {
  const dispatch = useDispatch();
  const isModalShown = useSelector(
    getIsWaterObjectStepperActionSelectModalShownSelector,
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(
      waterObjectActions.setShowWaterObjectStepperActionSelectModalAction(
        showModal,
      ),
    );
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
};
