import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useIrrigationSystemTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'channelName',
      title: 'Наименование канала',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'outletName',
      title: 'Наименование водовыпуска',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
  ];

  return { columns };
};
