import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Col, Divider, notification, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import {
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
  TooltipedSelect,
} from '@features/ui-kit';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';
import {
  useCalculateAnnualContractMutation,
} from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import {
  getPlantingDataSelector,
  getSelectedWaterUserIdSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

import styles from './PlannedStructure.module.scss';
import { usePlannedStructureModal } from './plannedStructureCreateModal/usePlannedStructureModal';
import { PlannedStructureTable } from './plannedStructureTable/PlannedStructureTable';
import { Routes } from '@enums/routes';

export const PlannedStructure: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const plantingData = useSelector(getPlantingDataSelector);
  const { setShowModal } = usePlannedStructureModal();
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);

  const [calculate, { error, isError }] = useCalculateAnnualContractMutation();
  const editing = !!id;

  const calculateClick = () => {
    if (waterUserId) {
      calculate({
        plantingDataDtoList: plantingData,
        waterUserId,
      })
        .then((resp) => {
          if ('data' in resp) {
            dispatch(annualApplicationActions.setAnnualContract(resp.data));
          }
          setSearchParams({
            [SectionQueryKey]: AnnualApplicationQueryTypes.computing,
          });
        })
        .catch(() => {
          notification.error({
            message: 'Не удалось провести вычисления',
          });
        });
    }
  };

  const onBackButtonClick = () => {
    navigate(`${Routes.AnnualApplicationCard}?section=${AnnualApplicationQueryTypes.totalInformation}`);
  };

  return (
    <Row>
      <Col span={24}>
        {!editing && (<Row justify="space-between" gutter={20}>
          <Col>
            <PrimaryButton
              size="large"
              onClick={() => setShowModal(true)}
              className={styles.createButton}
            >
              Создать сев
            </PrimaryButton>
          </Col>
          <Col span={24}>
            <Divider className={styles.divider} />
          </Col>
        </Row>)
        }
      </Col>
      <Col span={24}>
        <InformationalBlock
          title={t('annualApplication.table.plannedStructure')}
        >
          <PlannedStructureTable />
        </InformationalBlock>
      </Col>
      <Col className={styles.buttonsBlock} span={24}>
        <Row justify="end" gutter={16}>
          <Col>
            <DefaultButton
              size="large"
              className={styles.buttons}
              type="default"
              onClick={onBackButtonClick}
            >
              Назад
            </DefaultButton>
          </Col>

          {!editing && (<Col>
            <PrimaryButton
              size="large"
              type="primary"
              htmlType="submit"
              onClick={calculateClick}
              disabled={!plantingData?.length}
            >
              Сделать расчет
            </PrimaryButton>
          </Col>)}
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};
