import { List, ListItem, Pagination } from '@features/ui-kit';
import {
  GeoPortalMapItem
} from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationContent/geoPortalNavigationContentMaps/GeoPortalMapItem';
import { useGeoPortalMapFilters } from '@features/geoPortal/geoPortalMaps/useGeoPoralFilters';
import { IMapItem } from '@models/geoPortal/interfaces/GeoPortal';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Row } from 'antd';
import { GeoControllerGetPortalMapsApiArg, useGeoControllerGetPortalMapsQuery } from '@store/api/geoPortalApi';

export const GeoPortalNavigationContentMaps: React.FC = () => {
  const { filters, setGeoPortalFilters } = useGeoPortalMapFilters();
  const {
    data,
  } = useGeoControllerGetPortalMapsQuery(filters.groupKey ? filters as GeoControllerGetPortalMapsApiArg : skipToken);

  const onPageChange = (page: number) => {
    setGeoPortalFilters({ ...filters, page });
  }

  return (
    <div>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={data?.items || []}
        renderItem={(item) => {
          return (
            <ListItem>
              <GeoPortalMapItem item={item as IMapItem} />
            </ListItem>
          );
        }}
      />
      <Row justify="end">
        <Pagination
          total={data?.totalCount || 0}
          pageSize={filters.size || 9}
          onChange={onPageChange}
          showSizeChanger={false}
        />
      </Row>
    </div>
  );
}

