import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const WaterOutletSovingFact: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="WaterOutletSovingFact"
        src={getKibanaUrl('Водопользование: Посевная факт/план')}
        height="2600"
        width="100%"
      />
    </div>
  );
};
