import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { Row, Col, Typography, Divider, Form } from 'antd';
import { useUpdateMainInfoMutation } from '@store/api/waterUsersApi';
import { useMainInfoModal } from '../useMainInfoModal';
import { useParams } from 'react-router-dom';
import { mainInfoDto } from '@store/models/waterUsersModel';
import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { EntityTypesEnum } from '@enums/entityTypes';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';

interface IMainInfoBlockProps {
  data: any;
}

export const MainInfoModal: FC<IMainInfoBlockProps> = ({ data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isModalShown, setShowModal } = useMainInfoModal();
  const [updateMainInfo] = useUpdateMainInfoMutation();
  const { id = '' } = useParams();

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: mainInfoDto) => {
    updateMainInfo({ body: values, id });
    setShowModal(false);
  };

  const { data: economicActivity = [], isLoading: economicActivityIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.TypeEconomicActivity,
    });

  const { data: positionsList = [], isLoading: positionsListIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.PositionsList,
    });

  const initialValues = {
    lastName: data?.employees?.[0]?.lastName,
    firstName: data?.employees?.[0]?.firstName,
    middleName: data?.employees?.[0]?.middleName,
    positionEmployee: data?.employees?.[0]?.positionEmployee?.id,
    inn: data?.legalData?.inn,
    passportNumber: data?.legalData?.passportNumber,
    dateBirth: data?.legalData?.dateBirth
      ? dayjs(data?.legalData?.dateBirth)
      : dayjs(data?.employees?.[0]?.dateBirth),
    gender: data?.legalData?.gender || data?.employees?.[0]?.gender,
    address: data?.legalData?.address,
    businessActivity: data?.legalData?.economicActivity?.id,
  };
  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const genderOptions = useMemo(
    () => [
      { value: 'M', label: t('genders.male') },
      { value: 'F', label: t('genders.female') },
    ],
    [],
  );

  return (
    <CustomModal
      centered
      open={isModalShown}
      onCancel={onCancel}
      width={830}
      title="Основные данные"
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.lastName',
              )}
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Фамилия" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.firstName',
              )}
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Имя" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="middleName"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.middleName',
              )}
              rules={[{ required: false }, lettersValidator]}
            >
              <TooltipedInput placeholder="Отчество" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="passportNumber"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.passportNumber',
              )}
            >
              <TooltipedInput placeholder="№ Паспорта" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="dateBirth"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.dateBirth',
              )}
            >
              <TooltipedDatePicker format="YYYY-MM-DD" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gender"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.gender',
              )}
            >
              <TooltipedSelect size="large" options={genderOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.address',
              )}
            >
              <TooltipedInput placeholder="Адрес проживания" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="businessActivity"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.businessActivity',
              )}
            >
              <TooltipedSelect
                placeholder="Введите Вид хозяйственной деятельности"
                size="large"
                loading={economicActivityIsLoading}
                options={economicActivity}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="inn"
              label={t(
                'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.inn',
              )}
              rules={[{ required: true }, { len: 14 }, numbersValidator]}
            >
              <TooltipedInput placeholder="Инн" size="large" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
