import { Divider } from '@features/ui-kit';
import { WateringScheduleControls } from '@features/wateringPlan/wateringSchedule/wateringScheduleControls/WateringScheduleControls';
import { WateringScheduleTable } from '@features/wateringPlan/wateringSchedule/wateringScheduleTable/WateringScheduleTable';
import { WaterUsersModal } from '@features/waterUser/waterUsersModal';

export const WateringSchedule = () => {
  return (
    <div>
      <WateringScheduleControls />
      <Divider />
      <WateringScheduleTable />
      <WaterUsersModal />
    </div>
  );
};
