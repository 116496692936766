import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb, Col, Row, TabsProps } from 'antd';

import { HomeOutlined } from '@ant-design/icons';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider, Tabs } from '@features/ui-kit';
import { WaterUsePlanDataViewTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { getGuvhTabSelector } from '@store/selectors';
import { setGuvhTab } from '@store/slices';

import styles from './WaterUsePlanGuvh.module.scss';
import { WaterUsePlanGuvhEdit } from './waterUsePlanGuvhEdit/WaterUsePlanGuvhEdit';
import { WaterUsePlanGuvhView } from './waterUsePlanGuvhView/WaterUsePlanGuvhView';

const options: TabsProps['items'] = [
  {
    label: 'Таблицы ГУВХ',
    key: WaterUsePlanDataViewTypes.Tables,
  },
  {
    label: 'Таблицы районов',
    key: WaterUsePlanDataViewTypes.View,
  },
];

export const WaterUsePlanGuvh: React.FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const dispatch = useDispatch();
  const selectedGuvhTab = useSelector(getGuvhTabSelector);
  useEffect(() => {
    setHeaderTitle(
      t('headers.waterUsePlan', { user: 'ГУВХ Чуйский район', year: 2024 }),
    );
  }, []);

  const onTabChange = (tab: string) => {
    console.log('tab', tab);
    dispatch(setGuvhTab(tab as WaterUsePlanDataViewTypes));
  };

  let Component;
  switch (selectedGuvhTab) {
    case WaterUsePlanDataViewTypes.Tables:
      Component = WaterUsePlanGuvhEdit;
      break;
    case WaterUsePlanDataViewTypes.View:
      Component = WaterUsePlanGuvhView;
      break;
    default:
      Component = WaterUsePlanGuvhEdit;
  }

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.waterUsePlan'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row
        justify="space-between"
        className={styles.controlBar}
        gutter={[0, 10]}
      >
        <Col span={24}>
          <Tabs onChange={onTabChange} items={options} />
        </Col>

        <Col span={24}>
          <Component />
        </Col>
      </Row>
    </>
  );
};
