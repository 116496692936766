import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Col, Form, notification, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import {
  CustomCard,
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useDeletePermissionByIdMutation,
  useFindAllByOrganizationIdQuery,
  useSignUpMutation,
} from '@store/gisproApi';
import { mapPhoneNumberToLong } from '@utils/phone';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { passportValidator } from '@utils/validation/passportValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

import { useWaterUserPermissionModal } from '../useWaterUserPermissionModal';
import styles from './AssociationPermissionsModal.module.scss';
import { useAccessControllerGetRolesQuery } from '@store/api/accessApi';
import { UserRoles } from '@enums/roles';
import { RevokeAccessModal } from '../revokeAccessModal/RevokeAccessModal';

type IndividualPermissionsModalForm = {
  employee: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  email: string;
  login: string;
  password: string;
  pin: string;
};

export const AssociationPermissionsModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const employeeId = Form.useWatch('employee', form);
  const [loginDisabled, setLoginDisabled] = useState<boolean>(false);

  const { waterUserId } = useWaterUserId();
  const { organizationId } = useOrganizationId();
  const [isShowRevokeAccessModal, setShowRevokeAccessModal] = useState(false);

  const [revokeAccess] = useDeletePermissionByIdMutation();
  const { isModalShownAssociation, setShowModalAssociation } =
    useWaterUserPermissionModal();
  const { data, refetch } = useFindAllByOrganizationIdQuery(
    waterUserId
      ? {
          id: +waterUserId,
        }
      : skipToken,
  );

  const { data: roles = [] } = useAccessControllerGetRolesQuery();

  const mappedRoles = useMemo(() => {
    const isAssociation = location.pathname
      .split('/')
      .filter((v) => v)
      .includes('users-table');

    const currentRole = isAssociation
      ? UserRoles.ROLE_WATER_USER
      : UserRoles.ROLE_ASSOCIATION;
    return roles.reduce<
      { label: string; value: number; parent: { id: number | null } }[]
    >((acc, item) => {
      if (
        item?.roleName === currentRole ||
        item?.parent?.roleName === currentRole
      ) {
        acc.push({
          label: item.title,
          value: item.id,
          parent: item.parent?.id ?? null,
        });
      }
      return acc;
    }, []);
  }, [roles]);

  const [signUp] = useSignUpMutation();

  const employeesPositionOptions: DefaultOptionType[] = data?.employees?.map(
    (employee: any) => ({
      label: employee.firstName,
      value: employee.id,
    }),
  );

  const onFinish = () => {
    signUp([
      {
        ...form.getFieldsValue(),
        waterUserId,
        employeeId: form.getFieldValue('employee'),
        organizationId,
        isOrganization: true,
      },
    ])
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Доступ успешно выдан',
        });
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: err?.data?.message ?? 'Произошла  ошибка',
        });
      });
    form.resetFields();
    setLoginDisabled(false);
  };

  const deleteRevokeAccess = useCallback(() => {
    employeeId &&
      revokeAccess({ id: employeeId })
        .unwrap()
        .then(() => {
          setShowRevokeAccessModal(false);
        })
        .catch((err) => {
          notification.error({
            message: err?.data?.message ?? 'Произошла  ошибка',
          });
          setShowRevokeAccessModal(false);
        });
  }, [employeeId]);

  const onCancel = () => {
    setShowModalAssociation(false);
    form.resetFields();
    setLoginDisabled(false);
  };

  const changeEmployeesList = (v: number) => {
    if (v !== undefined) {
      form.setFieldsValue(
        data?.employees?.find((employee: any) => employee.id === v),
      );
      setLoginDisabled(
        !!data?.employees?.find((employee: any) => employee.id === v)?.user
          ?.login,
      );
    } else {
      form.resetFields();
      setLoginDisabled(false);
    }
  };

  return (
    <CustomModal
      centered
      title={'Выдача доступа'}
      open={isModalShownAssociation}
      onCancel={onCancel}
      width={840}
      footer={
        <Row className={styles.footer} gutter={16} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Печать
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              onClick={onCancel}
            >
              Завершить
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form<IndividualPermissionsModalForm>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row justify="space-between" align="bottom">
          <Col span={12}>
            <Form.Item
              label={t('createLegalWaterRegistry.secondStep.employeesList')}
              name="employee"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                allowClear
                size="large"
                placeholder="Список сотрудников"
                onChange={changeEmployeesList}
                options={employeesPositionOptions}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <PrimaryButton
                size="large"
                type="primary"
                htmlType="submit"
                onClick={() => setShowRevokeAccessModal(true)}
                disabled={!employeeId}
              >
                Отозвать доступ
              </PrimaryButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <PrimaryButton size="large" type="primary" htmlType="submit">
                Выдать доступы
              </PrimaryButton>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.customCard}>
          <Col span={12}>
            <CustomCard title={t('Данные сотрудника')} bordered={false}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.lastName',
                    )}
                    name="lastName"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      disabled
                      placeholder="Введите Фамилию"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.firstName',
                    )}
                    name="firstName"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      disabled
                      placeholder="Введите Имя"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.mainInfoBlock.placeholders.middleName',
                    )}
                    name="middleName"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      disabled
                      placeholder="Введите Отчество"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.phone',
                    )}
                    name="phone"
                    rules={[
                      {
                        required: true,
                      },
                      phoneNumberValidator,
                    ]}
                    normalize={mapPhoneNumberToLong}
                  >
                    <TooltipedInput
                      disabled
                      placeholder="Введите Номер телефона"
                      size="large"
                      addonBefore="+996"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.email',
                    )}
                    name="email"
                    rules={[{ required: true, type: 'email' }]}
                  >
                    <TooltipedInput disabled placeholder="Email" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </CustomCard>
          </Col>
          <Col span={12}>
            <CustomCard title={t('permissions.title')} bordered={false}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t('createIndividualWaterRegistry.modal.pin')}
                    name="inn"
                    rules={[{ required: true }, { len: 14 }, numbersValidator]}
                  >
                    <TooltipedInput maxLength={14} disabled size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('authentication.login.placeholders.login')}
                    name="login"
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput disabled={loginDisabled} size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('authentication.login.placeholders.password')}
                    name="password"
                    rules={[{ required: true }, passportValidator]}
                  >
                    <TooltipedInput size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Роль"
                    name="role"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      options={mappedRoles}
                      allowClear
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>
      </Form>

      {isShowRevokeAccessModal && (
        <RevokeAccessModal
          revokeAccess={deleteRevokeAccess}
          onCancel={() => setShowRevokeAccessModal(false)}
        />
      )}
    </CustomModal>
  );
};
