import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
} from '@features/ui-kit';
import { useUpdateIrrigationSystemMutation } from '@store/gisproApi';
import { Col, Divider, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

interface IGeneralCharacteristicsModalProps {
  setShowModal: (value: boolean) => void;
  systemData: any;
}

export const GeneralCharacteristicsModal: FC<
  IGeneralCharacteristicsModalProps
> = ({ setShowModal, systemData }) => {
  const [form] = Form.useForm();

  const [updateIrrigationSystem] = useUpdateIrrigationSystemMutation();

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: any) => {
    systemData?.systemId &&
      updateIrrigationSystem({ ...values, systemId: systemData?.systemId });

    setShowModal(false);
  };

  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const initialValues = {
    areaPlanting: systemData?.areaPlanting,
    amountStructures: systemData?.amountStructures,
    waterMeasurementDevices: systemData?.waterMeasurementDevices,
    waterMeasurementDevicesAuto: systemData?.waterMeasurementDevicesAuto,
    area: systemData?.area,
    areaOfFields: systemData?.areaOfFields,
    additionalConstructions: systemData?.additionalConstructions,
    otherObjects: systemData?.otherObjects,
  };

  return (
    <CustomModal
      centered
      open
      onCancel={onCancel}
      width={830}
      title={'Общие характеристики'}
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="areaPlanting"
              label="Общ. орошаемая площадь (Га)"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amountStructures"
              label="Кол-тво сооружений всего, шт."
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="waterMeasurementDevices"
              label="Водомерные устройства всего шт"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="waterMeasurementDevicesAuto"
              label="Из них автоматические, шт"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="area"
              label="Всего (Га)"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="areaOfFields"
              label="Под насаждения (Га)"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="additionalConstructions"
              label="Под вспом. постройки (Га)"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="otherObjects"
              label="Прочие объекты (Га)"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                type="number"
                placeholder="Введите данные"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
