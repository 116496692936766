import {
  IByDistrictDto,
  IWaterUsePlanDataDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { addRowIndexies } from '@utils/addRowIndexies/addRowIndexies';
import { set, toInteger } from '@utils/utils';

export const contractualWaterSupplyPlanDataMapper = (
  obj?: IWaterUsePlanDataDto | IByDistrictDto,
) => {
  if (!obj) return undefined;
  const districtId = 'districtId' in obj ? obj.districtId : '';
  const districtName = 'districtName' in obj ? obj.districtName : '';

  const calculateQuarterVolume = (planItemDtos: any[], quarter: number) =>
    planItemDtos
      .filter((el: any) => el.quarter === quarter)
      .reduce((acc: number, el) => acc + toInteger(el.volume), 0);

  const { bySystemDtos, totals, totalsBySystemType } = obj;
  const systemsData = bySystemDtos.reduce((acc: any[], el, index) => {
    const { systems, systemTypeName, systemTypeId } = el;
    const totalBySystem = totalsBySystemType.find(
      (totalSystem) => totalSystem.systemTypeId === systemTypeId,
    );
    const systemsWithTotal = systems.map((system) => {
      const sum = {
        I: calculateQuarterVolume(system.planItemDtos, 1),
        II: calculateQuarterVolume(system.planItemDtos, 2),
        III: calculateQuarterVolume(system.planItemDtos, 3),
        IV: calculateQuarterVolume(system.planItemDtos, 4),
      }
      return {
        ...system,
        districtId,
        districtName,
        ...sum,
        'cumulativeTotal-I': sum.I,
        'cumulativeTotal-II': sum.I + sum.II,
        'cumulativeTotal-III': sum.I + sum.II + sum.III,
        'cumulativeTotal-IV': sum.I + sum.II + sum.III + sum.IV,
      };
    });
    const sum = {
      I: systemsWithTotal.reduce((acc, el) => acc + el.I, 0),
      II: systemsWithTotal.reduce((acc, el) => acc + el.II, 0),
      III: systemsWithTotal.reduce((acc, el) => acc + el.III, 0),
      IV: systemsWithTotal.reduce((acc, el) => acc + el.IV, 0),
    }
    const allTotals = {
      systemTypeName: 'Всего Итого',
      planItemDtos: totals,
      systemTypeId: `total${index + 1}`,
      systemId: `total${index + 2}`,
      ...sum,
      'cumulativeTotal-I': sum.I,
      'cumulativeTotal-II': sum.I + sum.II,
      'cumulativeTotal-III': sum.I + sum.II + sum.III,
      'cumulativeTotal-IV': sum.I + sum.II + sum.III + sum.IV,
    };
    const total = {
      systemTypeName: `Итого "${systemTypeName}"`,
      planItemDtos: totalBySystem?.planItemDtos,
      systemTypeId: `total${index}`,
      systemId: `total${index}`,
      ...sum,
      'cumulativeTotal-I': sum.I,
      'cumulativeTotal-II': sum.I + sum.II,
      'cumulativeTotal-III': sum.I + sum.II + sum.III,
      'cumulativeTotal-IV': sum.I + sum.II + sum.III + sum.IV,
    };
    if ('districtId' in obj) {
      set(total, 'districtId', obj.districtId);
      set(total, 'districtName', obj.districtName);
      // set(allTotals, 'districtId', obj.districtId);
      // set(allTotals, 'districtName', obj.districtName);
    }
    const allTotalArr = Array(2).fill(allTotals);
    const totalArr = Array(2).fill(total);

    const dataArr = [...systemsWithTotal, ...systemsWithTotal]
      .slice()
      .sort((a, b) => a.systemId - b.systemId);
    // return [...acc, ...dataArr, ...totalArr, ...allTotalArr];
    return [...acc, ...dataArr, ...totalArr];
  }, []);

  const withSystemRowIndexies = addRowIndexies(
    systemsData,
    'systemId',
    'systemRowIndex',
    2,
  );
  const withSystemTypeRowIndexies = addRowIndexies(
    withSystemRowIndexies,
    'systemTypeId',
    'systemTypeRowIndex',
    2,
  );
  console.log('withSystemTypeRowIndexies', withSystemTypeRowIndexies);
  return withSystemTypeRowIndexies;
};
