import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import Icon from '@ant-design/icons';
import { Routes } from '@enums/routes';
import { CustomCard, PrimaryButton } from '@features/ui-kit';
import { KyrgyzstanMapIcon } from '@icons/KyrgyzstanMapIcon';
import { IMapItem } from '@models/geoPortal/interfaces/GeoPortal';
import { setMapName } from '@store/slices/geoPortal/geoPortalSlice';

import styles from './GeoPortalMapItem.module.scss';

interface IGeoPortalMapItemProps {
  item: IMapItem;
}

export const GeoPortalMapItem: React.FC<IGeoPortalMapItemProps> = ({
  item,
}) => {
  const { mapName, name } = item;
  const dispatch = useDispatch();
  const navigateMap = () => {
    dispatch(setMapName(name));
    const url = generatePath(Routes.geoPortalMap, { filterKey: mapName });
    window.open(url, '_blank');
  };

  return (
    <CustomCard
      hoverable
      style={{
        background: '#f4f6fd',
        border: '1px solid rgba(75, 96, 216, 0.15)',
        borderRadius: 15,
        padding: 8,
      }}
    >
      <div className={styles.cardImage}>
        <Icon allowFullScreen component={KyrgyzstanMapIcon} />
      </div>
      <Row justify="space-between" align="bottom">
        <Col span={11}>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
            }}
            strong
          >
            {name}
          </Typography.Paragraph>
        </Col>
        <Col>
          <PrimaryButton target="_blank" onClick={navigateMap}>
            Просмотр
          </PrimaryButton>
        </Col>
      </Row>
    </CustomCard>
  );
};
