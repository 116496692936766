import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useGetObjectCategoriesQuery } from '@store/gisproApi';

export const useCreateWaterObjectTablePreviewColumns = () => {
  const { data: objectCategories = [] } = useGetObjectCategoriesQuery();

  const columns: TableColumn[] = [
    {
      title: 'Наименование объекта',
      align: 'center',
      dataIndex: 'objectName',
      xtype: CustomTableColumnType.String,
      key: 'objectName',
      width: 230,
    },
    {
      title: 'Код водного объекта',
      align: 'center',
      dataIndex: 'objectCode',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'objectCode',
      width: 150,
    },
    {
      title: 'Группа',
      align: 'center',
      dataIndex: 'objectType',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'objectType',
      width: 140,
      renderCustom: ({ record }) => (
        <span>
          {objectCategories.find(
            (category) => category.categoryId === record.objectCategoryId,
          )?.objectGroup.groupName || '-'}
        </span>
      ),
    },
    {
      title: 'Категория',
      align: 'center',
      dataIndex: 'objectType',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'objectType',
      width: 140,
      renderCustom: ({ record }) => (
        <span>
          {objectCategories.find(
            (category) => category.categoryId === record.objectCategoryId,
          )?.groupTitle || '-'}
        </span>
      ),
    },
    {
      title: '...',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: 50,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];

  return { columns };
};
