import { IReservoirDataByMonthFilters } from '@models/reservoir/interfaces/reservoir';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ReservoirMonitoringState = {
  reservoirMonitoringFilters: Partial<IReservoirDataByMonthFilters>;
};

const initialState: ReservoirMonitoringState = {
  reservoirMonitoringFilters: {},
};

const reservoirMonitoringSlice = createSlice({
  name: 'reservoirMonitoring',
  initialState,
  reducers: {
    changeReservoirMonitoringFilters: (
      state,
      action: PayloadAction<IReservoirDataByMonthFilters>,
    ) => {
      return {
        ...state,
        reservoirMonitoringFilters: action.payload,
      };
    },
  },
});

export const { actions: reservoirMonitoringActions } = reservoirMonitoringSlice;
export default reservoirMonitoringSlice.reducer;
