import { type JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

export function getCellObject<RecordType extends JsonRecord>(
  record: RecordType,
  column: TableColumn,
): JsonRecord {
  let cellObject = {};
  // if (column.decadeIndex) {
  cellObject = get(record, column?.decimalIndex || column?.decadeIndex || [], record);
  // }
  return cellObject;
}
