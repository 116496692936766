import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BookIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 4H3.5C3.23478 4 2.98043 3.89464 2.79289 3.70711C2.60536 3.51957 2.5 3.26522 2.5 3C2.5 2.73478 2.60536 2.48043 2.79289 2.29289C2.98043 2.10536 3.23478 2 3.5 2H14.5V1C14.5 0.734784 14.3946 0.48043 14.2071 0.292893C14.0196 0.105357 13.7652 0 13.5 0L2.5 0C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2V18C0.5 19.1 1.4 20 2.5 20H14.5C15.0304 20 15.5391 19.7893 15.9142 19.4142C16.2893 19.0391 16.5 18.5304 16.5 18V5C16.5 4.73478 16.3946 4.48043 16.2071 4.29289C16.0196 4.10536 15.7652 4 15.5 4H8.5V12L6.5 10L4.5 12V4Z"
        fill="white"
      />
    </svg>
  );
};
