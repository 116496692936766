import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useGetFacilitiesByIdQuery } from '@store/gisproApi';
import { Routes } from '@enums/routes';
import { Row, Space } from 'antd';
import { CardDataItem } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/cardData/CardDataItem';
import styles from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/cardData/CardData.module.scss';
import { waterUserConvert } from '@constants/waterUser';
import { PrimaryButton } from '@features/ui-kit';
import { CardIcon } from '@icons/CardIcon';
import { useFacilitiesControllerFindByFacilitiesIdQuery } from '@store/api/facilityApi';

export const FacilityInfo = () => {
  const navigate = useNavigate();
  const { currentAttribute } = useContext(MapContext);

  const { data: facility } =
    useFacilitiesControllerFindByFacilitiesIdQuery(currentAttribute ? { id: currentAttribute.id } : skipToken);

  const toShowCard = () => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(currentAttribute?.id),
      }),
    );
  };

  return (
    <>
      <Space size={8} direction="vertical" className={styles.content}>
        <CardDataItem
          title="ФИО/Наименование"
          value={facility?.waterUser?.legalData?.name ?? '-'}
        />
        <CardDataItem
          title="Вид хозяйственной деятельности"
          value={facility?.waterUser?.legalData?.businessActivity?.title ?? '-'}
        />
        <CardDataItem
          title="Тип водопользователя (Физ.Юр)"
          value={waterUserConvert[facility?.waterUser?.type || 0] ?? '-'}
        />
        <CardDataItem
          title="Наименование участка"
          value={facility?.name || '-'}
        />
        <CardDataItem
          title="Оросительная система:"
          value={facility?.systemName ?? '-'}
        />
        <CardDataItem title="Канал:" value={facility?.channelName ?? '-'} />
        <CardDataItem
          title="Водовыпуск:"
          value={facility?.outletName ?? '-'}
        />
        <CardDataItem title="КПД:" value={facility?.kpd ?? '-'} />
      </Space>
      <Row justify="center" className={styles.buttonWrapper}>
        <PrimaryButton onClick={toShowCard} icon={<CardIcon />}>
          Карточка
        </PrimaryButton>
      </Row>
    </>
  );
}
