import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { IAttachment } from '@models/attachments/interfaces/attachment';
import { AppDispatch } from '@store/index';
import { getCurrentIrrigationSystemSelector } from '@store/selectors';
import {
  addAttachmentToIrrigationSystemAction,
  removeAttachmentFromIrrigationSystemAction,
} from '@store/slices';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

import { DocumentTable, UploadDocumentBlock } from '@components/index';
import { InformationalBlock } from '@features/ui-kit';

export const DocumentFields: FC = () => {
  const irrigationSystem = useSelector(getCurrentIrrigationSystemSelector);
  const dispatch = useDispatch<AppDispatch>();

  const onLoad = useCallback((file: IAttachment) => {
    dispatch(addAttachmentToIrrigationSystemAction(file));
  }, []);

  const deleteItem = (file: IAttachment) => {
    dispatch(removeAttachmentFromIrrigationSystemAction(file));
  };

  const onWatch = (file: IAttachment) => {
    downloadAttachment(file);
  };

  return (
    <InformationalBlock title="Документы">
      <Row gutter={8}>
        <Col span={14}>
          <DocumentTable
            deleteItem={deleteItem}
            onHandleWatch={onWatch}
            documents={irrigationSystem?.attachmentIds || []}
          />
        </Col>
        <Col span={10}>
          <UploadDocumentBlock onLoad={onLoad} />
        </Col>
      </Row>
    </InformationalBlock>
  );
};
