import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';
import { useAnnualApplicationAgreementModal } from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementModal/useAnnualApplicationAgreementModal';
import { CustomModal, DangerButton, DefaultButton } from '@features/ui-kit';
import { useCancelAnnualContractMutation } from '@store/gisproApi';

import styles from './AnnualApplicationAgreementModal.module.scss';

export const AnnualApplicationAgreementModal: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { isModalShow, setShowModal } = useAnnualApplicationAgreementModal();
  const [cancel, { error, isError }] = useCancelAnnualContractMutation();

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = () => {
    cancel({
      id,
    })
      .unwrap()
      .then(() => {
        setShowModal(false);
        navigate(Routes.AnnualApplicationTable);
      });
  };

  return (
    <CustomModal
      open={isModalShow}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={370}
      onCancel={onCancel}
      footer={<div />}
      title={'Отмена'}
      centered
    >
      <Row gutter={[0, 48]} justify="center" align="middle">
        <Col span={24}>
          <Row justify="center" align="middle">
            <Col>
              <Typography.Title level={4}>
                Действительно хотите отменить?
              </Typography.Title>
            </Col>
            <Col className={styles.modalDescription}>
              <Typography.Text>
                Все действия будут удалены
                <br /> без возможности восстановить
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={10} className={styles.modalBtn}>
            <Col>
              <DefaultButton onClick={onCancel}>Нет</DefaultButton>
            </Col>
            <Col>
              <DangerButton onClick={onSubmit}>Да, хочу!</DangerButton>
            </Col>
          </Row>
        </Col>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Row>
    </CustomModal>
  );
};
