import { PrimaryButton, TooltipedSelect, YearPicker } from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedSelectV2,
  TooltipedYearPickerV2,
} from '@features/ui-kit-v2';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import {
  IWaterQualityFilters,
  WaterQualityMonitoringType,
  WaterQualityPointType,
} from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GetZonesApiArg,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetPointsQuery,
  useGetZonesQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { useGetCurrentUser } from '@features/authentication';

export const GroundWaterDataFilters = () => {
  const [filtersState, setFiltersState] = useState<IWaterQualityFilters>({});
  const { filters, setDictionaryTableFilters } = useEnteringDataTablesFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: zoneContent = [] } = useGetZonesQuery(
    !(filtersState.regionId && filtersState.districtId)
      ? skipToken
      : ({
          type: WaterQualityMonitoringType.DepthUGV,
          regionId: filtersState.regionId,
          districtId: filtersState.districtId,
        } as unknown as GetZonesApiArg),
  );

  const { data: pointsContent = [] } = useGetPointsQuery(
    !(filtersState.regionId && filtersState.districtId)
      ? skipToken
      : ({
          type: WaterQualityPointType.WellMonitoring,
          regionId: filtersState.regionId,
          districtId: filtersState.districtId,
        } as unknown as GetZonesApiArg),
  );

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
    }));
  };

  const onFilterChange = (filterKey: string, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onResetFilterButtonClick = () => {
    setDictionaryTableFilters({
      regionId: user?.owner?.region?.id || null,
      districtId: user?.owner?.district?.id || null,
      year: null,
      quarter: null,
      pointId: null,
      monthNumber: null,
      zoneId: null,
    });
  };

  const onFilterButtonClick = () => {
    setDictionaryTableFilters(filtersState as IWaterQualityFilters);
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row gutter={16}>
      <Col span={3}>
        <TooltipedSelect
          placeholder="Область"
          value={filtersState.regionId && +filtersState.regionId}
          onChange={(v: string) => onRegionChange(v)}
          options={regions}
          allowClear
          disabled={isDisabledRegion}
        />
      </Col>
      <Col span={3}>
        <TooltipedSelect
          placeholder="Район"
          options={districts}
          disabled={isDisabledDistrict || !filtersState.regionId}
          value={filtersState.districtId && +filtersState.districtId}
          onChange={(v: string) => onFilterChange('districtId', v)}
          allowClear
        />
      </Col>
      <Col span={3}>
        <TooltipedSelect
          placeholder="Наименование зоны"
          options={zoneContent?.map((item) => ({
            label: item.zoneName,
            value: item.zoneId,
            zoneInfo: item,
          }))}
          disabled={!filtersState.districtId}
          value={filtersState.zoneId}
          onChange={(v: string) => onFilterChange('zoneId', v)}
          allowClear
        />
      </Col>
      <Col span={3}>
        <TooltipedSelect
          placeholder="Скважина"
          options={pointsContent?.map((item) => ({
            label: item.pointName,
            value: item.id,
          }))}
          disabled={!filtersState.districtId}
          value={filtersState.pointId}
          onChange={(v: string) => onFilterChange('pointId', v)}
          allowClear
        />
      </Col>
      <Col>
        <YearPicker
          placeholder="Год"
          disabledDate={(current) => current && current > dayjs().endOf('year')}
          value={filtersState.year ? dayjs(filtersState.year) : undefined}
          onChange={(value: Dayjs | null) =>
            onFilterChange('year', String(value?.year()))
          }
        />
      </Col>
      <Col>
        <PrimaryButton
          onClick={onFilterButtonClick}
          icon={<FilterIcon />}
        />
      </Col>
      <Col>
        <PrimaryButton
          onClick={onResetFilterButtonClick}
          icon={<ResetFilterIcon />}
        />
      </Col>
    </Row>
  );
};
