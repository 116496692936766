import React from 'react';

import { Card, Layout } from 'antd';

import styles from './ErrorLayout.module.css';

type PropsType = {
  children?: React.ReactNode;
};

export const ErrorLayout: React.FC<PropsType> = ({ children }) => {
  return (
    <Layout className={styles.layout}>
      <Layout className={styles.childLayout}>
        <Card className={styles.card}>{children}</Card>
      </Layout>
    </Layout>
  );
};
