export const GeoPortalRegionAndArea: Record<string, string> = {
  _105: 'Batken Region',
  _102: 'Osh Region',
  _103: 'Talas Region',
  _107: 'Jalal-Abad Region',
  _106: 'Issyk-Kul Region',
  _101: 'Chuy Region',
  _104: 'Naryn Region',
  //Чуй
  _201: 'Alamudun',
  _202: 'Chuy Region',
  _203: 'Jayyl',
  _204: 'Kemin',
  _205: 'Moskva',
  _206: 'Panfilov',
  _207: 'Chuy',
  _208: 'Ysyk-Ata',
  _241: 'Chuy Region',
  _243: 'City of Tomok',
  _250: 'Sokuluk',
  //Ош
  _218: 'Alay',
  _219: 'Kara-Kulja',
  _220: 'Kara-Suu',
  _221: 'Nookat',
  _222: 'Uzgen',
  _223: 'Chong-Alay',
  _224: 'Aravan',
  _242: 'Osh Region',
  //Талас
  _214: 'Bakay-Ata',
  _215: 'Kara-Buura',
  _216: 'Manas',
  _217: 'Talas',
  _246: 'Talas',
  //Нарын
  _209: 'Ak-Talaa',
  _210: 'At-Bashy',
  _211: 'Jumgal',
  _212: 'Kochkor',
  _213: 'Naryn',
  _245: 'Naryn',
  //Баткен
  _234: 'Kadamjay',
  _235: 'Leilek',
  _233: 'Batken',
  _247: 'Batken',
  //Джалал-абад
  _200: 'Bazar-Korgon',
  _225: 'Aksy',
  _226: 'Ala-Buka',
  _228: 'Nooken',
  _229: 'Suzak',
  _230: 'Toguz-Toro',
  _231: 'Toktogul',
  _232: 'Chatkal',
  _249: 'Jalal-Abad Region',
  //Иссык-куль
  _237: 'Ak-Suu',
  _236: 'Issyk Kul',
  _238: 'Jeti-Oguz',
  _240: 'Tup',
  _244: 'Issyk-Kul Region',
  _248: 'Issyk-Kul Region',
  _564: 'Tong',
};
