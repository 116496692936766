import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedTextarea,
} from '@features/ui-kit';
import {
  NewsDto,
  useNewsCreateMutation,
  useNewsUpdateMutation,
} from '@store/api/newsApi';
import { Col, Form, Row, Space, Typography } from 'antd';
import { FC } from 'react';

interface INewsModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const NewsModal: FC<INewsModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const [createEntity, { isError }] = useNewsCreateMutation();
  const [updateEntity, { isError: isErrorUpdate }] = useNewsUpdateMutation();

  const onFinish = (values: NewsDto) => {
    if (initialValues) {
      updateEntity({ id: initialValues.id, newsDto: values }).then(() =>
        setShowModal(false),
      );
      return;
    }
    createEntity({ newsDto: values }).then(() => {
      setShowModal(false);
    });
  };

  return (
    <CustomModal
      open={isModalShow}
      title="Создать"
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Заголовок"
              name="title"
              rules={[{ required: true }]}
            >
              <TooltipedInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Контент"
              name="content"
              rules={[{ required: true }]}
            >
              <TooltipedTextarea autoSize={{ minRows: 4, maxRows: 10 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
