import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { mapAnnualPlanQuarterlyColumns } from '@features/annualPlan/annualPlanQuarterlyTable/annualPlanQuarterlyTableContainer/mapAnnualPlanQuarterlyColumns';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindOperationalBlockByIdQuery } from '@store/gisproApi';

import styles from './implementation.module.scss';
import { useImplementationColumns } from './useImplementationColumns';

export const Implementation = () => {
  const { id } = useParams();
  const { columns } = useImplementationColumns();

  const { data: operationalBlock } = useFindOperationalBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );
  const dataSource = useMemo(() => {
    if (operationalBlock?.implementationTerms) {
      return operationalBlock?.implementationTerms?.map(
        (item: any, index: number) => ({
          ...item,
          index,
        }),
      );
    }
    return [];
  }, [operationalBlock]);
  return (
    <CustomCard title="Реализация">
      <Row>
        <Col span={24}>
          <TableCustom
            bordered
            dataSource={dataSource}
            columns={columns}
            rowClassName={styles.tableRow}
            scroll={{ x: 1500 }}
          />
        </Col>
      </Row>
    </CustomCard>
  );
};
