/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';

import { Button, Form, Space } from 'antd';

import Icon from '@ant-design/icons';
import { Links } from '@enums/links';

import styles from './buttons.module.scss';

type TelegramButtonPropTypes = {
  name: string;
};

export const TelegramButton: React.FC<TelegramButtonPropTypes> = ({ name }) => {
  const [selected, setSelected] = useState(false);
  const form = Form.useFormInstance();

  useEffect(() => {
    form.setFieldValue(name, false);
  }, []);

  return (
    <Button
      className={`${styles.telegramButton} ${
        selected ? styles.telegramButtonSelected : ''
      }`}
      onClick={() => {
        setSelected(!selected);
        form.setFieldValue(name, !selected);
      }}
    >
      <Space>
        <Icon
          component={() => <img src={Links.TelegramIcon} alt="telegram" />}
        />
        Telegram
      </Space>
    </Button>
  );
};
