import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DocumentTable } from '@features/manageEvents/tables/DocumentTable';
import { getBuildingBlockSelector } from '@store/selectors';

export const DocumentsCard = () => {
  const { id } = useParams();
  const buildingBlockState = useSelector(getBuildingBlockSelector);

  return (
    <DocumentTable
      type="building"
      attachments={buildingBlockState?.attachments || []}
    />
  );
};
