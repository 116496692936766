import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

export const useHydroModuleTableColumns = () => {
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const columns: TableColumn[] = [
    {
      dataIndex: 'gmrName',
      title: 'Наименование гидромодульного района',
      xtype: CustomTableColumnType.NumberColumn,
      width: 600,
    },
    {
      dataIndex: 'agroclimaticZoneName',
      title: 'Агроклиматическая зона',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'regionId',
      title: 'Область',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        const region = regions.find((item) => item.id === record.regionId);
        return <span>{region?.title}</span>;
      },
      width: 300,
    },
    {
      dataIndex: 'districtId',
      title: 'Район',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        const district = districts.find(
          (item) => item.id === record.districtId,
        );
        return <span>{district?.title}</span>;
      },
      width: 300,
    },
    {
      dataIndex: 'actions',
      title: 'Редактор',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.LinkOutlet, CustomTableAction.IrrigationMode],
    },
  ];

  return { columns };
};
