import { Breadcrumb } from '@features/ui-kit';
import {
  CreatePointCollectionModal,
  WaterQualityCard,
} from '@features/waterQuality';

export const WaterQualityCardLayout: React.FC = () => {
  return (
    <>
      <WaterQualityCard />
      <CreatePointCollectionModal />
    </>
  );
};
