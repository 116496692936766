import { createSelector } from '@reduxjs/toolkit';
import { ActsInvoicesForPaymentsState } from '@store/slices';

type WithActInvoicesForPaymentsState = {
  actsInvoicesForPayments: ActsInvoicesForPaymentsState;
};

export const actInvoicesForPaymentsStateSelector = (
  state: WithActInvoicesForPaymentsState,
): ActsInvoicesForPaymentsState => state.actsInvoicesForPayments;

export const getActsInvoicesForPaymentsFiltersSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.actsInvoicesForPaymentsFilters;
  },
);

export const getIsShowActModalSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.isShowActModal;
  },
);

export const getIsShowPreviewActModalSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.isPreviewActModal;
  },
);

export const getIsShowPreviewCheckModalSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.isPreviewCheckModal;
  },
);

export const getIsShowEditActModalSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.isEditActModal;
  },
);

export const getIsShowCheckModalSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.isShowCheckModal;
  },
);

export const getSelectedActsInvoicesForPaymentSelector = createSelector(
  actInvoicesForPaymentsStateSelector,
  (actInvoicesForPaymentsState) => {
    return actInvoicesForPaymentsState.selectedActsInvoicesForPayment;
  },
);
