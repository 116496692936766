import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const FilterIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3398_105117)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.860107 2.9242C0.860107 2.37638 1.15824 1.93634 1.51845 1.61706C1.88003 1.2971 2.36304 1.0399 2.90132 0.837966C3.98332 0.432044 5.43236 0.195312 7.00011 0.195312C8.56717 0.195312 10.0169 0.432044 11.0989 0.837966C11.6379 1.0399 12.1209 1.2971 12.4818 1.61706C12.8413 1.93634 13.1401 2.37638 13.1401 2.9242C13.1401 6.09244 11.1821 8.82679 8.4396 9.55813C8.41379 9.56457 8.3905 9.57859 8.37274 9.59838C8.3666 9.60521 8.36455 9.60998 8.36455 9.60998V12.3423C8.36445 12.5324 8.30081 12.717 8.18375 12.8668C8.0667 13.0167 7.90294 13.1231 7.71849 13.1691L6.69516 13.425C6.56948 13.4564 6.4383 13.4587 6.31157 13.4319C6.18484 13.405 6.0659 13.3496 5.96378 13.2699C5.86166 13.1902 5.77903 13.0883 5.72218 12.9719C5.66533 12.8555 5.63574 12.7277 5.63566 12.5981V9.60998C5.63566 9.60998 5.63362 9.60452 5.62748 9.5977C5.6096 9.57819 5.58633 9.56442 5.56062 9.55813C2.81809 8.82679 0.860107 6.09244 0.860107 2.9242ZM2.22455 2.9242C2.22455 2.5251 3.11144 2.21674 3.38024 2.11577C4.27395 1.78148 5.5538 1.55976 7.00011 1.55976C8.44642 1.55976 9.72695 1.7808 10.62 2.11577C10.8881 2.21674 11.7757 2.5251 11.7757 2.9242C11.7757 3.3233 10.8888 3.63167 10.62 3.73264C9.72627 4.06693 8.44642 4.28865 7.00011 4.28865C5.5538 4.28865 4.27327 4.06761 3.38024 3.73264C3.11212 3.63167 2.22455 3.3233 2.22455 2.9242Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3398_105117">
          <rect
            width="12.9231"
            height="14"
            fill="white"
            transform="translate(0.538574)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
