import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { DangerButton, InformationalBlock, PrimaryButton, } from '@features/ui-kit';
import { CreateAssociationWaterRegistryQueryTypes, WaterUserTypes, } from '@models/waterUser/enums/waterUserEnums';
import { getFormData } from '@store/selectors';
import { clearFormDataAction, setFormData } from '@store/slices';

import { Col, Form, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ExtendedBankDetails, PassportDetails, RegistrationDetails, } from '../fields';
import styles from './styles.module.scss';

type AssociationFirstStepFormTypes = {
  organizationName: string;
  registryNumber: string;
  associationTypeId: number;
  registerDate: Date | string;
  regNumber: string;
  ownershipFormId: number;
  activityTypeId: number;
  organizationInn: string;
  okpo: string;
  organizationAddress: string;
};

export const AssociationMasterRegistrationFirstStep: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);

  const onFinish = (values: AssociationFirstStepFormTypes) => {
    dispatch(
      setFormData({
        data: {
          ...values,
          registerDate: dayjs(values.registerDate).format('YYYY-MM-DD'),
        },
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.addressInfo}`,
    );
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(Routes.WaterRegistryUsers);
  };

  const onFinishFailed = (
    err: ValidateErrorEntity<AssociationFirstStepFormTypes>,
  ) => {
    console.log('onFinishFailed: ', err);
  };
  useEffect(() => {
    if (
      Object.values(currentWaterRegistry[WaterUserTypes.association]).length
    ) {
      form.setFieldsValue(currentWaterRegistry[WaterUserTypes.association]);
      form.setFieldValue(
        'registerDate',
        dayjs(currentWaterRegistry[WaterUserTypes.association].registerDate),
      );
    }
  }, [currentWaterRegistry]);

  return (
    <Form<AssociationFirstStepFormTypes>
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <InformationalBlock title="Данные водопользователя">
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <PassportDetails/>
          </Col>
          <Col span={24}>
            <RegistrationDetails/>
          </Col>
          <Col span={24}>
            <ExtendedBankDetails/>
          </Col>
        </Row>
      </InformationalBlock>
      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DangerButton
              size="large"
              htmlType="submit"
              onClick={onCancelButtonClick}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <PrimaryButton size="large" type="primary" htmlType="submit">
                  {t('createLegalWaterRegistry.buttons.next')}
                </PrimaryButton>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
