import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { ImplementationDeadlines } from '@features/manageEvents/cards/implementationDeadlines/ImplementationDeadlines';
import { useAttachDocumentModal } from '@features/manageEvents/useAttachDocumentModal';
import {
  BudgetItem,
  CustomCard,
  DefaultButton,
  PrimaryButton,
  TooltipedTextarea,
  UploadButtonSmall,
} from '@features/ui-kit';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateReportOperationalBlockMutation,
  useFindOperationalBlockByIdQuery,
  useSaveReportOperationalBlockMutation,
} from '@store/gisproApi';
import { getOperationalBlockSelector } from '@store/selectors';
import dayjs from 'dayjs';
import { isInteger, toInteger } from '@utils/utils';

import styles from './OperationalCard.module.scss';

const { Title } = Typography;

export const CreateReportOperationalCard: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const operationalBlockState = useSelector(getOperationalBlockSelector);
  const { id } = useParams();
  const { setShow: setAttachmentDocumnetShow, setDocType } =
    useAttachDocumentModal();
  const { data: operationalBlock, refetch } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const [createOperationalBlockReport] =
    useCreateReportOperationalBlockMutation();
  const [saveOperationalBlockReport] = useSaveReportOperationalBlockMutation();
  const createReportHandle = () => {
    if (operationalBlock?.id) {
      createOperationalBlockReport(operationalBlock.id)
        .unwrap()
        .then(() => {
          refetch();
          notification.success({
            message: 'Вы успешно создали отчет',
          });
        })
        .catch((err) => {
          notification.error({
            message: 'Не удалось создать отчет',
          });
        });
    }
  };

  const attachments = (
    operationalBlockState?.attachments || operationalBlock?.attachments
  )?.filter(
    (att) =>
      att.type === DocumentTypes.Estimate ||
      att.type === DocumentTypes.Chart ||
      att.type === DocumentTypes.Defect ||
      att.type === DocumentTypes.Photo ||
      att.type === DocumentTypes.Report,
  );

  const onFinish = (values: any) => {
    saveOperationalBlockReport({
      ...values,
      id: operationalBlock?.id,
      attachments: attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      planBudget: toInteger(values.planBudget),
    })
      .unwrap()
      .then((data: any) => {
        refetch();
        notification.success({
          message: 'Вы успешно сохранили отчет',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Не удалось сохранить отчет',
        });
      });
  };

  const validateAttachments = useMemo(() => {
    const hasEstimate = attachments?.some(
      (att) => att.type === DocumentTypes.Estimate,
    );
    const hasDefect = attachments?.some(
      (att) => att.type === DocumentTypes.Defect,
    );

    return hasEstimate && hasDefect;
  }, [attachments]);

  const isSaveDisabled = useMemo(
    () =>
      operationalBlock?.status !== TaskStatusEnum.onPlanned &&
      operationalBlock?.status !== TaskStatusEnum.onPreparation,
    [operationalBlock],
  );

  const isEditingDisabled = useMemo(
    () =>
      !operationalBlock?.id ||
      (operationalBlock.status !== TaskStatusEnum.onPlanned &&
        operationalBlock.status !== TaskStatusEnum.onPreparation),
    [operationalBlock],
  );

  const onAddAttachment = useCallback((docType: string) => {
    setDocType(docType);
    setAttachmentDocumnetShow(true);
  }, []);

  useEffect(() => {
    if (operationalBlock) {
      form.setFieldsValue({
        ...operationalBlock,
        implementationTerms: operationalBlock.implementationTerms.map(
          (item) => ({
            ...item,
            start: dayjs(item.start),
            end: dayjs(item.end),
          }),
        ),
      });
    }
  }, [operationalBlock, id]);

  return (
    <CustomCard
      title={
        <Row gutter={20} justify="start">
          <Col flex={6}>
            <Title level={4}>Отчет по подготовительным работам</Title>
          </Col>
          <Col flex={6}>
            <Row justify="end" gutter={20}>
              <Col>
                <DefaultButton
                  disabled={isSaveDisabled || !validateAttachments}
                  onClick={() => form.submit()}
                >
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  disabled={
                    operationalBlock?.status !== TaskStatusEnum.onPreparation
                  }
                  onClick={createReportHandle}
                >
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Form
        initialValues={{
          implementationTerms: [{}],
        }}
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="prepReportDescription"
          required
          label="Описание задачи"
          rules={[{ required: true }]}
        >
          <TooltipedTextarea
            className=""
            placeholder="Описание задачи"
            autoSize={{ minRows: 4, maxRows: 10 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <Form.Item name="planBudget" required rules={[{ required: true }]}>
          <BudgetItem
            label="Планируемый бюджет* (сом)"
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col flex={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.Estimate)}
              disabled={isEditingDisabled}
            >
              Прикрепить смету*
            </UploadButtonSmall>
          </Col>
          <Col flex={8}>
            <Form.Item required>
              <UploadButtonSmall
                onClick={() => onAddAttachment(DocumentTypes.Defect)}
                disabled={isEditingDisabled}
              >
                Прикрепить ведомость дефекта*
              </UploadButtonSmall>
            </Form.Item>
          </Col>
          <Col flex={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.PhotoBefore)}
              disabled={isEditingDisabled}
            >
              Прикрепить Фото (До)
            </UploadButtonSmall>
          </Col>
        </Row>
        <div className={styles.generalStep}>
          <Typography.Text className={styles.title}>
            Заявленные сроки реализации
          </Typography.Text>
          <ImplementationDeadlines disabled={isEditingDisabled} />
        </div>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <UploadButtonSmall
                onClick={() => onAddAttachment(DocumentTypes.Report)}
                hasIcon
                disabled={isEditingDisabled}
              >
                Прикрепить документ
              </UploadButtonSmall>
            </Col>
            <Col span={12}>
              <UploadButtonSmall
                onClick={() => onAddAttachment(DocumentTypes.Chart)}
                disabled={isEditingDisabled}
              >
                Прикрепить График
              </UploadButtonSmall>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </CustomCard>
  );
};
