import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomCard, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import {
  SectionTitle,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

export const PassportDetails: FC = () => {
  const { t } = useTranslation();

  const { data: associationTypes = [], isLoading: associationTypesIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.AssociationTypes });

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>Паспортные данные</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row justify="space-between" gutter={[30, 30]}>
        <Col span={12}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.passportDetails.placeholders.associationName',
            )}
            name="name"
            rules={[{ required: true }]}
          >
            <TooltipedInput placeholder="Введите Название" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t(
              'createAssociationWaterRegistry.firstStep.passportDetails.placeholders.associationType',
            )}
            name="associationType"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              allowClear
              size="large"
              loading={associationTypesIsLoading}
              options={associationTypes}
              placeholder="Введите Тип ассоциации"
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomCard>
  );
};
