import { EntityTypesEnum } from '@enums/entityTypes';
import { CommonReference } from '@models/commonReference/interfaces/commonReference';
import {
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListOutletsQuery,
  useFindDictionaryByTypeIdQuery,
} from '@store/gisproApi';

export const getContractFile = ({
  facilities,
  employeeList,
  dateRegistrationOrganization,
  dateBirth,
  organizationName,
  firstName,
  activityId,
  organizationAddress,
  regNumber,
  inn,
  passportNumber,
  ...rest
}: any) => {
  const firstFacilities = facilities?.[0];
  const employeeListAccount = employeeList?.accountant;
  const employeeListManager = employeeList?.manager;

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: agroClimateZone = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.AgroClimateZone,
  });

  const { data: outlets = [] } =
    useFindAllIrrigationSystemObjectsListOutletsQuery({});

  const { data: { content: irrigationSystemsTypes = [] } = {} } =
    useFindAllIrrigationSystemListQuery({});

  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Okmot,
  });

  const localeData = {
    regionName: regions.find((item) => item.id === firstFacilities?.regionId)
      ?.title,
    irrigationSystemName: irrigationSystemsTypes.find(
      (item) => item.systemId === firstFacilities?.systemId,
    )?.name,
    waterReleaseName: outlets.find(
      (item) => item.objectId === firstFacilities?.outletId,
    )?.objectName,
    agroClimateZoneName: agroClimateZone.find(
      (item) => item.id === firstFacilities?.agroClimatiqueZoneId,
    )?.title,
    districtName: districts.find(
      (district: CommonReference) =>
        district.id === firstFacilities?.districtId,
    )?.title,
    okmotsName: okmots.find((item) => item.id === firstFacilities?.okmotId)
      ?.title,
  };

  const contractData: any = {
    date: dateRegistrationOrganization || dateBirth,
    ruvhName: organizationName || firstName,
    ruvhCityOrVillage: activityId || firstFacilities?.additionalAddress,
    ruvhAddress: organizationAddress,
    employeeFullName: employeeListAccount?.firstName,
    employeePosition: employeeListAccount?.position,
    contractNumber: regNumber || '1',
    farmName: organizationName,
    waterUserFio: employeeListManager?.firstName || firstName,
    waterUserInn: employeeListManager?.inn || inn,
    userPassportNumber: passportNumber,
    userDateOfBirth: dateBirth,
    sectionName: employeeListManager?.gender,
    region: localeData.regionName,
    requiredVolume: 100.5,
    irrigationSystem: localeData.irrigationSystemName,
    channel: firstFacilities?.channelId,
    waterRelease: localeData.waterReleaseName,
    totalArea: firstFacilities?.area || 0,
    kpd: firstFacilities?.kpd || 0,
    agroClimateZone: localeData.agroClimateZoneName,
    district: localeData.districtName,
    ayilOkmotu: localeData.okmotsName,
    latitude: 34.0522,
    longitude: -118.2437,
  };

  Object.entries(contractData).forEach(([key, value]) => {
    contractData[key] = value !== undefined ? value : '-';
  });

  return contractData;
};
