import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';

import { WaterObjectAccordionItem } from './waterObjectAccordionItem/WaterObjectItem';
import { SystemObject } from '@models/systemObject/systemObject';

export const mapAccordionItems = (
  data: SystemObject[],
  waterSections: IWaterSection[],
  objectCategories: ObjectCategory[],
  generalAqueduct: SystemObject,
  gvsGroup: IObjectGroup,
) => {
  const usedWaterObjectsIds =
    waterSections.map((item) =>
      item.systemObjectDtoList.map((systemObject) => systemObject.objectId),
    ).flat(2);
  if (generalAqueduct && generalAqueduct.gvsObject) {
    usedWaterObjectsIds.push(generalAqueduct.gvsObject.objectId);
  }

  return data?.reduce<any>((prev, curr, index, array) => {
    const foundItem = prev.find(
      (item: any) => item.objectGroupId === curr.objectGroupId,
    );
    if (foundItem) {
      return prev;
    }

    const currentObjectCategory = objectCategories.find(
      (item) => item.categoryId === curr.objectCategoryId,
    );
    const children = array
      .filter(
        (item) =>
          item.objectGroupId === currentObjectCategory?.objectGroup.id &&
          !usedWaterObjectsIds.includes(item.objectId),
      )
      .map((item) => (
        <WaterObjectAccordionItem
          type={gvsGroup.id === item.objectGroupId ? 'gvs' : 'waterObject'}
          key={item.objectId}
          isDropped={false}
          {...item}
        />
      ));

    if (!children.length) {
      return prev;
    }

    const item = {
      objectGroupId: curr.objectGroupId,
      key: curr.objectGroupId,
      label: currentObjectCategory?.groupTitle,
      children,
    };
    return [...prev, item];
  }, []);
};
