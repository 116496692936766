import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { toInteger } from '@utils/utils';

import { WaterObjectPreviewCard } from '../waterObjectPreviewCard';
import styles from './WaterObjectBinContainer.module.scss';
import { WaterObjectBinItem } from './waterObjectBinItem';
import { WaterObjectGvsBin } from './waterObjectGvsBin';
import {
  useIrrigationDeleteAqueductObjectsRelationWithSectionsMutation,
  useIrrigationFindByAqueductIdQuery,
  useIrrigationFindByAqueductSectionsQuery,
  useIrrigationFindGeneralAqueductBySystemIdQuery,
  useIrrigationSaveAqueductObjectWithSectionsMutation,
  useIrrigationSaveAqueductWithAqueductsMutation,
} from '@store/api/irrigationSystemApi';

type WaterObjectBinContainerPropTypes = {
  activeKey?: string;
};

export const WaterObjectBinContainer: React.FC<
  WaterObjectBinContainerPropTypes
> = ({ activeKey }) => {
  const { mainConduitId, systemId } = useParams<{
    mainConduitId: any;
    systemId: any;
  }>();

  const { data: mainConduit } =
    useIrrigationFindByAqueductIdQuery({
      aqueductId: mainConduitId,
    });

  const {
    data: waterSections = [],
    isLoading: waterSectionsIsLoading,
    isFetching: waterSectionsIsFetching,
  } = useIrrigationFindByAqueductSectionsQuery({
    aqueductId: mainConduitId,
  });

  const { data: generalAqueduct } =
    useIrrigationFindGeneralAqueductBySystemIdQuery(
      mainConduit?.isGeneral ? { systemId } : skipToken,
    );

  const selectedWaterSectionId = activeKey || waterSections[0]?.id;

  const [save] =
    useIrrigationSaveAqueductObjectWithSectionsMutation();
  const [deleteItem] =
    useIrrigationDeleteAqueductObjectsRelationWithSectionsMutation();
  const [saveGts] =
    useIrrigationSaveAqueductWithAqueductsMutation();

  const selectedWaterSection = useMemo(() => {
    const found = waterSections.find(
      (item) => item.id === toInteger(selectedWaterSectionId),
    );
    if (found) {
      return found;
    }
    return null;
  }, [selectedWaterSectionId, waterSections]);

  const onGvsBinDrop = useCallback((item: { systemObjectId: number }) => {
    saveGts({
      systemObjectAqueducts: {
        gtsId: item.systemObjectId,
        aqueductId: mainConduitId,
      },
    });
  }, []);

  const onWaterSectionBinDrop = useCallback(
    (item: {
      coordinates: string;
      direction: string;
      systemObjectId: number;
      sectionId: number;
    }) => {
      save({
        body: [
          {
            direction: item.direction,
            piketCoordinates: item.coordinates,
            sectionId: item.sectionId,
            systemObjectId: item.systemObjectId,
          },
        ],
      });
    },
    [],
  );

  const onWaterSectionBinDelete = useCallback((item: { objectId: number }) => {
    deleteItem({
      aqueductObjectsWithSections: {
        aqueductId: mainConduitId,
        objectId: item.objectId,
      },
    });
  }, []);

  const showBin = useMemo(
    () =>
      selectedWaterSectionId === 'gvs' &&
      mainConduit?.objectId === generalAqueduct?.objectId &&
      !generalAqueduct?.gvsObject,
    [selectedWaterSectionId, mainConduit, generalAqueduct],
  );

  return (
    <div className={styles.container}>
      <Row gutter={[20, 20]}>
        {selectedWaterSectionId === 'gvs' &&
          generalAqueduct &&
          generalAqueduct?.gvsObject && (
            <Col className={styles.gvsCard}>
              <WaterObjectPreviewCard
                item={generalAqueduct?.gvsObject}
                onRemoveConnection={null}
              />
            </Col>
          )}
        {showBin && (
          <Col span={24}>
            <WaterObjectGvsBin accept={['gvs']} onDrop={onGvsBinDrop} />
          </Col>
        )}
        {selectedWaterSectionId !== 'gvs' && (
          <Col span={24}>
            <WaterObjectBinItem
              isLoading={waterSectionsIsLoading || waterSectionsIsFetching}
              sectionId={selectedWaterSection?.id}
              onDrop={onWaterSectionBinDrop}
              onDelete={onWaterSectionBinDelete}
              coordinates={selectedWaterSection?.coordinates}
              systemObjectDtoList={selectedWaterSection?.systemObjectDtoList}
              typeSectionId={selectedWaterSection?.typeSectionId}
              name={selectedWaterSection?.name}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
