import { Col, Row, Typography } from 'antd';

import { CustomCard, TooltipedSelect } from '@features/ui-kit';

import styles from './WaterSupplyCardVolume.module.scss';

export const WaterSupplyCardVolume: React.FC = () => {
  return (
    <CustomCard
      title={
        <Row align="middle" justify="space-between" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Водоподача, м3
            </Typography.Title>
          </Col>
          <Col>
            <Row justify="end" gutter={8}>
              <Col>
                <TooltipedSelect placeholder="Год" />
              </Col>
              <Col>
                <TooltipedSelect placeholder="Месяц" />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      {/* TODO: replace with kibana */}
      <div
        style={{
          height: 250,
          width: '100%',
        }}
      />
    </CustomCard>
  );
};
