import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxProps, Col, Divider, Row, Table, Typography } from 'antd';

import { useWaterUserHouseHoldsColumns } from '@features/annualApplication/createAnnualApplication/waterUserSelection/WaterUserHouseholdsTable/useWaterUserHouseHoldsColumns';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { IFacility } from '@models/facility/interfaces/facility';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetFacilitiesByOrgIdQuery,
  useGetOutletsByAssociationIdQuery,
} from '@store/gisproApi';
import {
  getSelectedFacilityIdSelector,
  getSelectedWaterUserIdSelector,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';
import { toInteger } from '@utils/utils';

interface IColumns {
  legal: TableColumn[];
  association: TableColumn[];
}

export const WaterUserHouseholdsTable = () => {
  const dispatch = useDispatch();
  const columns = useWaterUserHouseHoldsColumns();
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);
  const facilityId = useSelector(getSelectedFacilityIdSelector);
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);
  const isAssociation = toInteger(waterUserType) === WaterUserEnum.ASSOCIATION;

  const { data: facilities = [] } = useGetFacilitiesByOrgIdQuery(
    !isAssociation && waterUserId
      ? {
          orgId: waterUserId,
        }
      : skipToken,
  );

  const { data: outlets = [] } = useGetOutletsByAssociationIdQuery(
    isAssociation && waterUserId
      ? {
          id: waterUserId,
        }
      : skipToken,
  );

  const onChange = (selectedRowKey: React.Key[]) => {
    let selectedIds = [];
    if (isAssociation) {
      selectedIds = outlets.filter((item: any) =>
        selectedRowKey.includes(item.id),
      );
    } else {
      selectedIds = facilities.filter((item) =>
        selectedRowKey.includes(item.facilitiesId),
      );
    }
    dispatch(annualApplicationActions.setSelectedFacilityIdAction(selectedIds));
  };

  const getCheckboxProps = (record: IFacility): CheckboxProps => ({
    value: record.id,
    name: record.name,
  });

  const dataSource = isAssociation
    ? outlets
    : facilities.map((item) => ({
        ...item,
        id: item.facilitiesId,
      }));

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>
                Список участков водопользователя
              </Typography.Title>
            </Row>
          }
        >
          <Col>
            <TableCustom
              bordered
              rowSelection={{
                type: 'checkbox',
                onChange,
                getCheckboxProps,
                defaultSelectedRowKeys: facilityId || [],
              }}
              size="small"
              rowKey={(record) => (record as any).id}
              columns={(columns as any)[waterUserType]}
              dataSource={dataSource}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Col>
        </CustomCard>
      </Col>
    </Row>
  );
};
