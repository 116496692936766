import React from 'react';
import { useTranslation } from 'react-i18next';

import { FileOutlined } from '@ant-design/icons';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { PrimaryButton } from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

type ReturnType = {
  columns: TableColumn[];
};

export const useRealizationTableColumns = (): ReturnType => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: 'Работа/мероприятие',
      dataIndex: 'event',
      key: 'event',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      xtype: CustomTableColumnType.String,
      key: 'weight',
    },
    {
      title: 'Начало',
      dataIndex: 'start',
      xtype: CustomTableColumnType.DateColumn,
      key: 'start',
    },
    {
      title: 'Конец',
      dataIndex: 'end',
      xtype: CustomTableColumnType.DateColumn,
      key: 'end',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <div>{t(`tags.${record.status}`)}</div>;
      },
      key: 'status',
    },
    {
      title: 'Документ',
      dataIndex: 'status',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        console.log(record);
        const document = record?.attachments?.find(
          (attachment: IAttachment) => attachment.type === 'документ',
        );
        return (
          <PrimaryButton
            icon={<FileOutlined />}
            onClick={() => downloadAttachment(document)}
            disabled={!document}
          />
        );
      },
      key: 'status',
    },
    {
      title: '...',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.SubmitWithoutText],
    },
  ];
  return {
    columns,
  };
};
