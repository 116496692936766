import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Divider, Row, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import {
  ConnectBlock,
  CustomCard,
  DocsList,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
  Tabs,
} from '@features/ui-kit';
// import { useCreatingSiteAssociationModal } from '@features/waterUser/waterUserCard/useCreatingSiteAssociationModal';
import { useCreatingSiteModal } from '@features/waterUser/waterUserCard/useCreatingSiteModal';
import { useTotalInformationColumns } from '@features/waterUser/waterUserCard/useTotalInformationColumns';
import { EditIcon } from '@icons/EditIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useDeleteEmployeeOwnerMutation,
  useFindAllByOrganizationIdQuery,
  useGetFacilitiesByOrgIdQuery,
  useGetWaterUserEmployeesIdQuery
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import styles from '../LegalWaterUserCard.module.scss';
import {
  TotalInfoModalTypes,
  TotalInfoModal,
  Attachment,
} from '../../modals/totalInfoModal';
import { useModalState } from '@utils/modalHook/modalHook';
import { IFacility } from '@models/facility/interfaces/facility';
import { AddEmployeeModal } from '../../modals/addEmployeeModal/AddEmployeeModal';

export const TotalInformation = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const [activeAttachments, setActiveAttachments] = useState('agreement');
  const { setShowModal, setInitialValues } = useCreatingSiteModal();
  const modalState = useModalState<TotalInfoModalTypes>();

  const [isShowEmployeeModal, setShowEmployeeModal] = useState(false);
  const [employeeInitialValue, setEmployeeInitialValue] = useState(null);
  const [deleteEmployee] = useDeleteEmployeeOwnerMutation();

  const { open } = modalState;


  const { data: EmployeesData } = useGetWaterUserEmployeesIdQuery(
    id
      ? {
        id: toInteger(id),
      }
      : skipToken,
  );


  const { data } = useFindAllByOrganizationIdQuery(
    id
      ? {
        id: toInteger(id),
      }
      : skipToken,
  );

  const { data: facilities = [] } = useGetFacilitiesByOrgIdQuery(
    id
      ? {
        orgId: Number(id),
      }
      : skipToken,
  );

  const { facilitiesColumns, responsiblePersonsColumns } =
    useTotalInformationColumns(data);

  const contactInformation = [
    {
      label: 'Номер телефона',
      value: data?.legalData?.phone || '-',
    },
    { label: 'E-mail', value: data?.legalData?.email || '-' },
  ];
  const basicData = [
    {
      label: 'Название организации',
      value: data?.legalData?.name || '-',
    },
    {
      label: 'Форма организации',
      value: data?.legalData?.organizationForm?.title || '-',
    },
    {
      label: 'Вид деятельности организации',
      value: data?.legalData?.economicActivity?.title || '-',
    },
    {
      label: 'Вид хоз. деятельности',
      value: data?.legalData?.businessActivity?.title || '-',
    },
    { label: 'ИНН', value: data?.legalData?.inn || '-' },
    { label: 'ОКПО', value: data?.legalData?.okpo || '-' },
    {
      label: 'Регистрационный номер',
      value: data?.legalData?.regNumber || '-',
    },
    { label: 'Дата регистрации', value: data?.legalData?.registerDate || '-' },
    { label: 'Адрес организации', value: data?.legalData?.address || '-' },
  ];
  const bankDetails = [
    {
      label: 'Название банка',
      value: data?.legalData?.bank?.parent?.name || '-',
    },
    {
      label: 'Название филиала',
      value: data?.legalData?.bank?.name || '-',
    },
    {
      label: 'Полный адрес банка',
      value: data?.legalData?.bank?.address || '-',
    },
    { label: 'БИК банка', value: data?.legalData?.bank?.bik || '-' },
    {
      label: 'Расчетный счет водопользователя',
      value: data?.legalData?.accountNumber || '-',
    },
    {
      label: 'Корреспондентский счет',
      value: data?.legalData?.organizationAccount || '-',
    },
  ];

  const attachments: any = {
    agreement: data?.attachmentsList?.filter(
      (att: any) => att.type === DocumentTypes.Agreement,
    ),
    regulation: data?.attachmentsList?.filter(
      (att: any) => att.type === DocumentTypes.Regulation,
    ),
  };

  const changeActiveAttachments = (key: string) => {
    setActiveAttachments(key);
  };

  const options = [
    {
      label: 'Соглашение',
      key: 'agreement',
    },
    {
      label: 'Устав',
      key: 'regulation',
    },
    {
      label: 'Руководитель',
      key: '3',
    },
    {
      label: 'Бухгалтер',
      key: '4',
    },
  ];

  const regulationData = data.attachmentsList
    .filter((t: Attachment) => t.type === 'устав')

  const agreementData = data.attachmentsList
    .filter((t: Attachment) => t.type === 'соглашение')


  const onHandleEdit = (record: IFacility) => {
    setInitialValues(record);
    setShowModal(true);
  };

  const onHandleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const handleCreateEmployee = () => {
    setShowEmployeeModal(true);
    setEmployeeInitialValue(null);
  };

  const onHandleEditEmployee = (val: any) => {
    setShowEmployeeModal(true);
    setEmployeeInitialValue(val);
  };

  const onHanleDeleteEmployee = (val: any) => {
    deleteEmployee({ id: val.id });
  };

  return (
    <InformationalBlock>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <ConnectBlock
            connectData={basicData}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>Основные данные</Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={() => open(TotalInfoModalTypes.main)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <ConnectBlock
            connectData={contactInformation}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Контактные данные
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={() => open(TotalInfoModalTypes.contacts)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <ConnectBlock
            connectData={bankDetails}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Банковские реквизиты
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={() => open(TotalInfoModalTypes.bank)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <CustomCard
            title={
              <Row justify="space-between" align="middle">
                <Col span={24}>
                  <Typography.Title level={4}>
                    Прикрепленные документы
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
              </Row>
            }
          >
            <Row>
              <Col span={24}>
                <Tabs onChange={changeActiveAttachments} items={options} />
              </Col>
              <Col className={styles.docsList} span={24}>
                {activeAttachments === 'agreement' && (
                  <DocsList documents={agreementData} />
                )}
                {activeAttachments === 'regulation' && (
                  <DocsList documents={regulationData} />
                )}
                {activeAttachments === '4' && (
                  <DocsList documents={EmployeesData[1].attachmentsList} />
                )}
                {activeAttachments === '3' && (
                  <DocsList documents={EmployeesData[0].attachmentsList} />
                )}
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={24}>
          <CustomCard
            title={
              <Row justify="space-between">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Ответственные лица
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={handleCreateEmployee}
                    type="primary"
                    htmlType="button"
                    disabled={data?.isArchived}
                  >
                    Добавить пользователя
                  </PrimaryButton>
                </Col>
              </Row>
            }
          >
            <TableCustom
              dataSource={data?.employees || []}
              columns={responsiblePersonsColumns}
              pagination={false}
              bordered
              actionProps={{
                onHandleEdit: (val) => onHandleEditEmployee(val),
                deleteItem: (val) => onHanleDeleteEmployee(val),
              }}
            />
          </CustomCard>
        </Col>
        <Col span={24}>
          <CustomCard
            title={
              <Row justify="space-between">
                <Col span={21}>
                  <Typography.Title level={4}>
                    {t(
                      'createIndividualWaterRegistry.secondStep.economyDetails.title',
                    )}
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={onHandleCreate}
                    type="primary"
                    htmlType="button"
                    disabled={data?.isArchived}
                  >
                    Добавить участок
                  </PrimaryButton>
                </Col>
              </Row>
            }
          >
            <TableCustom
              dataSource={facilities || []}
              columns={facilitiesColumns}
              pagination={false}
              bordered
              actionProps={{
                onHandleEdit,
              }}
            />
          </CustomCard>
        </Col>
      </Row>
      {isShowEmployeeModal && (
        <AddEmployeeModal
          employeeInitialValue={employeeInitialValue}
          setShowModal={setShowEmployeeModal}
          onSaved={() => { }}
        />
      )}
      <TotalInfoModal dataSource={data} modalState={modalState} />
    </InformationalBlock>
  );
};
