import { changeReturnUrl } from '@store/slices/header';
import { useDispatch } from 'react-redux';

export const clearUrl = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(changeReturnUrl(undefined));
  };
};
