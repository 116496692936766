import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FireOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';
import dayjs from 'dayjs';
import { Task } from 'gantt-task-react';

dayjs.locale('ru');

interface ICustomGanttHeaderRowsProps {
  tasks: Task[];
}

interface NavigationLink {
  regular_events: string;
  extra_tasks: string;
  construction_block: string;
  operational_block: string;
}
export const CustomGanttHeaderRows: React.FC<ICustomGanttHeaderRowsProps> = ({
  tasks,
}) => {
  const navigate = useNavigate();

  const navigationLink: NavigationLink = {
    regular_events: 'regular-events',
    extra_tasks: 'other-tasks',
    construction_block: 'building',
    operational_block: 'operational',
  };

  const colors = {
    high: 'rgba(251, 99, 64, 1)',
    low: '#2cce89',
    medium: '#5f72e4',
  };

  const onItemClick = (item: any) => {
    navigate(
      `${Routes.ManageEvents}/${(navigationLink as any)[item.blockType]}/${
        item.rowId
      }`,
    );
  };

  return (
    <div
      style={{
        width: 610,
        height: '100%',
        borderTop: '1px solid #edecec',
        borderLeft: '1px solid #edecec',
      }}
    >
      {tasks.map((item: any, index: number) => {
        return (
          <div
            key={item.id}
            onClick={() => onItemClick(item)}
            style={{
              height: 50,
              background: index % 2 !== 0 ? '#f5f5f5' : '',
              borderBottom: '1px solid #edecec',
              borderRight: '1px solid #edecec',
              display: 'flex',
            }}
          >
            <div
              style={{
                width: 290,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #edecec',
                borderRight: '1px solid #edecec',
                padding: 6,
                color: 'var(--text-color-dark-blue, #525f7f)',
                fontSize: 14,
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {item.name}
              <FireOutlined
                style={{ fontSize: 24, color: (colors as any)[item.priority] }}
              />
            </div>
            <div
              style={{
                width: 320,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #edecec',
                borderRight: '1px solid #edecec',
                padding: 6,
              }}
            >
              <div>{dayjs(item.start).format('dd, D MMMM YYYY г.')}</div>
              <div>{dayjs(item.end).format('dd, D MMMM YYYY г.')}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
