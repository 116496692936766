import React, { FC, useEffect } from 'react';

import { Breadcrumb, Col, Layout, Row } from 'antd';
import { Divider, InformationalBlock, Mask } from '@features/ui-kit';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { useSearchParams } from 'react-router-dom';

import styles from './ReferenceBookLayout.module.scss';
import { ReferenceMaterialsList } from './referenceMaterialsList';
import { ReferenceMaterialsTable } from './referenceMaterialsTable';

export const ReferenceMaterials: FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const [params] = useSearchParams();

  const categoryId = params.get('categoryId');

  useEffect(() => {
    setHeaderTitle(t('headers.referenceMaterials'));
  }, []);

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'Справочные материалы',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <InformationalBlock>
        <Row gutter={8}>
          <Col span={8}>
            <ReferenceMaterialsList />
          </Col>
          <Col span={16}>
            {categoryId ? (
              <ReferenceMaterialsTable />
            ) : (
              <Mask
                fullHeight
                maskText={t('referenceMaterials.selectCategory')}
              />
            )}
          </Col>
        </Row>
      </InformationalBlock>
    </Layout>
  );
};
