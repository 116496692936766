import { Checkbox } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

export function renderCheck<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value = record[customColumn.dataIndex];

  return <Checkbox value={value} onClick={(e) => e.stopPropagation()} />;
}
