import { useTranslation } from 'react-i18next';

import { MonthIndexies } from '@enums/month';
import { CustomTableColumnType } from '@enums/table';
import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { PeriodWaterUseColumns } from '@store/slices';

type ReturnType = {
  columns: TableColumn[];
};

export const useEditActModalColumns = (
  period: Pick<PeriodWaterUseColumns, 'monthId'>,
): ReturnType => {
  const { t } = useTranslation();
  const monthValue: number = period.monthId;

  let columnsMonth: TableColumn[] = [];
  const generateMonthColumns = (firstMonth: string): TableColumn[] => {
    const monthColumnss: TableColumn[] = [
      {
        title: t(`months.${firstMonth}`),
        dataIndex: `${MonthIndexies[firstMonth]}`,
        align: 'center',
        ytype: CustomTableColumnHeaderType.Month,
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Декада №1',
            align: 'center',
            dataIndex: 'decade_1',
            width: 180,
            xtype: CustomTableColumnType.String,
            ytype: CustomTableColumnHeaderType.Decade,
            children: [
              {
                xtype: CustomTableColumnType.DecadeCell,
                title: 'Заявка',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '1',
                ytype: CustomTableColumnHeaderType.Decade,
                align: 'center',
                decadeType: 'plan',
                width: 90,
              },
              {
                title: 'Факт',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '1',
                decadeType: 'fact',
                align: 'center',
                ytype: CustomTableColumnHeaderType.Decade,
                width: 90,
                xtype: CustomTableColumnType.DecadeEditableCell,
                editableCell: true,
              },
            ],
          },
          {
            title: 'Декада №2',
            key: 'decade1',
            align: 'center',
            dataIndex: 'decade_2',
            width: 100,
            xtype: CustomTableColumnType.String,
            ytype: CustomTableColumnHeaderType.Decade,
            children: [
              {
                xtype: CustomTableColumnType.DecadeCell,
                title: 'Заявка',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '2',
                align: 'center',
                decadeType: 'plan',
                key: 'plan1',
                width: 100,
                ytype: CustomTableColumnHeaderType.Decade,
              },
              {
                xtype: CustomTableColumnType.DecadeEditableCell,
                editableCell: true,
                title: 'Факт',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '2',
                align: 'center',
                decadeType: 'fact',
                width: 100,
                ytype: CustomTableColumnHeaderType.Decade,
              },
            ],
          },
          {
            title: 'Декада №3',
            align: 'center',
            dataIndex: 'decade_3',
            width: 100,
            xtype: CustomTableColumnType.String,
            ytype: CustomTableColumnHeaderType.Decade,
            children: [
              {
                xtype: CustomTableColumnType.DecadeCell,
                title: 'Заявка',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '3',
                align: 'center',
                key: t(`months.${firstMonth}`),
                width: 100,
                decadeType: 'plan',
                ytype: CustomTableColumnHeaderType.Decade,
              },
              {
                xtype: CustomTableColumnType.DecadeEditableCell,
                editableCell: true,
                title: 'Факт',
                dataIndex: t(`months.${firstMonth}`),
                parentDataIndex: '3',
                align: 'center',
                width: 100,
                decadeType: 'fact',
                ytype: CustomTableColumnHeaderType.Decade,
              },
            ],
          },
        ],
      },
    ];

    return monthColumnss;
  };
  switch (monthValue) {
    case 1:
      columnsMonth = generateMonthColumns('january');
      break;
    case 2:
      columnsMonth = generateMonthColumns('february');
      break;
    case 3:
      columnsMonth = generateMonthColumns('march');
      break;
    case 4:
      columnsMonth = generateMonthColumns('april');
      break;
    case 5:
      columnsMonth = generateMonthColumns('may');
      break;
    case 6:
      columnsMonth = generateMonthColumns('june');
      break;
    case 7:
      columnsMonth = generateMonthColumns('july');
      break;
    case 8:
      columnsMonth = generateMonthColumns('august');
      break;
    case 9:
      columnsMonth = generateMonthColumns('september');
      break;
    case 10:
      columnsMonth = generateMonthColumns('october');
      break;
    case 11:
      columnsMonth = generateMonthColumns('november');
      break;
    case 12:
      columnsMonth = generateMonthColumns('december');
      break;
    default:
      columnsMonth = generateMonthColumns('january');
      break;
  }
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'outletId',
      xtype: CustomTableColumnType.GreyCell,
      width: 80,
      ytype: CustomTableColumnHeaderType.String,
    },
    {
      title: 'Наименование участка / водовыпуска',
      align: 'center',
      dataIndex: 'name',
      ytype: CustomTableColumnHeaderType.String,
      width: 220,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => <div>{record.name}</div>,
    },
    ...columnsMonth,
  ];

  return { columns };
};
