import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { CreateSourceModal } from '@features/source';
import {
  DangerButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { CreateIrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { getCurrentIrrigationSystemSelector } from '@store/selectors';
import {
  changeIrrigationSystemAction,
  clearIrrigationSystemAction,
} from '@store/slices';
import dayjs from 'dayjs';

import { CommonCharacteristicsFields } from './commonCharacteristicsFields';
import { CommonDataFields } from './commonDataFields';
import styles from './CreateStepperFirstStep.module.scss';

export const CreateStepperFirstStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentIrrigationSystem = useSelector(
    getCurrentIrrigationSystemSelector,
  );

  const onFinish = useCallback((values: any) => {
    navigate(
      `${Routes.IrrigationSystemCreateStepper}?${SectionQueryKey}=${CreateIrrigationSystemQueryTypes.works}`,
    );
    dispatch(
      changeIrrigationSystemAction({
        ...values,
        dateStart: new Date(values?.dateStart).getFullYear(),
      }),
    );
  }, []);

  const onCancelButtonClick = () => {
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={
          currentIrrigationSystem
            ? {
                ...currentIrrigationSystem,
                dateStart: dayjs(currentIrrigationSystem?.dateStart.toString()),
              }
            : {}
        }
        className={styles.form}
      >
        <CommonDataFields />
        <CommonCharacteristicsFields />
        <Row
          justify="space-between"
          align="middle"
          className={styles.navigationButtons}
        >
          <Col>
            <DangerButton
              type="default"
              htmlType="button"
              size="large"
              onClick={onCancelButtonClick}
              style={{ color: 'white' }}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Col>
          <Col>
            <PrimaryButton size="large" type="primary" htmlType="submit">
              {t('createIndividualWaterRegistry.buttons.next')}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
      <CreateSourceModal />
    </>
  );
};
