import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { CustomCard, DefaultButton, StatisticCard } from '@features/ui-kit';
import { useGetWaterUsersCounterQuery } from '@store/gisproApi';

import styles from './IrrigationFondCard.module.scss';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { saveUrl } from '@utils/returnUrl';

export const IrrigationFondCard: React.FC = () => {
  const { data } = useGetWaterUsersCounterQuery({});
  const navigate = useNavigate();
  const urlAction = saveUrl();

  const hasPermission = useHasUserPermission(ACCESS.IRRIGATION_SYSTEM_VIEW);

  const clickGoto = useCallback(() => {
    urlAction();
    navigate(Routes.IrrigationSystemTable);
  }, []);
  return (
    <CustomCard
      title={
        <Row align="middle" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Ирригационный фонд
            </Typography.Title>
          </Col>
          {hasPermission ? (
            <Col>
              <DefaultButton onClick={clickGoto}>Перейти</DefaultButton>
            </Col>
          ) : null}
        </Row>
      }
    >
      <Row className={styles.cardStats}>
        <Col>
          <StatisticCard title="Систем (шт)" count={data?.systems || 0} />
        </Col>
        <Col>
          <StatisticCard title="Каналов (шт)" count={data?.channels || 0} />
        </Col>
        <Col>
          <StatisticCard
            title="Гидростопов (шт)"
            count={data?.hydroposts || 0}
          />
        </Col>
        <Col>
          <StatisticCard title="Др. ГТС (шт)" count={data?.otherGts || 0} />
        </Col>
        <Col>
          <StatisticCard
            title="Др. ед. учета (шт)"
            count={data?.otherCounts || 0}
          />
        </Col>
      </Row>
    </CustomCard>
  );
};
