import {
  HomeOutlined,
  LeftOutlined,
  MinusSquareFilled,
  PlusSquareFilled,
} from '@ant-design/icons';

import {
  CustomCard,
  DefaultButton,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TooltipedSelect,
} from '@features/ui-kit';

import { LoadingPage } from '@pages/loading';
import {
  useIrrigationGetAqueductsQuery,
  useIrrigationSaveAqueductToAqueductMutation,
} from '@store/api/irrigationSystemApi';

import { getTreeFromFlatData } from '@utils/getTreeFromFlatData';
import {
  Breadcrumb,
  Col,
  Empty,
  notification,
  Row,
  TreeDataNode,
  TreeProps,
  Typography,
} from 'antd';
import Tree, { DataNode } from 'antd/es/tree';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from '../WaterObjectStepper.module.scss';
import { Routes } from '@enums/routes';

export const WaterObjectConstructor = () => {
  const { systemId } = useParams<{
    systemId: any;
  }>();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [unsortedChannels, setUnsortedChannels] = useState<any[]>([]);
  const { data = [], isLoading } = useIrrigationGetAqueductsQuery({ systemId });

  const [updateTree] = useIrrigationSaveAqueductToAqueductMutation();

  useEffect(() => {
    setTreeData(
      getTreeFromFlatData({
        flatData: data
          .filter((item) => item.isGeneral || item?.parent?.id)
          .map((item) => ({
            ...item,
            id: item.id,
            key: item.id,
            children: item.children || [],
            disabled: item.isGeneral,
            parentId: item.isGeneral ? '0' : item?.parent?.id,
          })),
      }),
    );
    setUnsortedChannels(
      data.filter((item) => !item?.parent?.id && !item.isGeneral),
    );
  }, [data]);

  const onDragEnter: TreeProps['onDragEnter'] = (info) => {
    // expandedKeys, set it when controlled is needed
    // setExpandedKeys(info.expandedKeys)
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    // console.log(item);
    e.dataTransfer.setData('newNode', item.id);
  };

  const onDropNode = (
    e: React.DragEvent<HTMLDivElement>,
    selectedItem: any,
  ) => {
    // e.preventDefault();
    const id = e.dataTransfer.getData('newNode');
    const dragItem = data.find((item) => item.id.toString() === id.toString());
    if (dragItem && selectedItem) {
      selectedItem.children = [...selectedItem.children];
      selectedItem?.children?.push(
        JSON.parse(
          JSON.stringify({
            ...dragItem,
            key: dragItem.id,
            id: dragItem.id,
            parentId: selectedItem.id,
            children: [],
          }),
        ),
      );
      setTreeData([...treeData]);
      setUnsortedChannels(
        unsortedChannels.filter((item) => item.id !== dragItem.id),
      );
    }
  };

  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
    ) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data);
          }
          if (data[i].children) {
            loop(data[i].children!, key, callback);
          }
        }
      }
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        console.log('dragObj', dragObj);
        item.children.unshift(JSON.parse(JSON.stringify(dragObj)));
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData(data);
  };
  const onFinish = () => {
    const loop = (entity: any): any[] => {
      if (!entity) return [];
      const el = {
        id: entity.id,
        parentId: entity.parentId === '0' ? null : entity.parentId,
        waterIntakeId: entity.waterIntake?.id,
      };
      return [el, ...entity.children.map(loop).flat()];
    };
    const info = treeData.map(loop).flat();
    updateTree({
      aqueducts: info,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно обновлено',
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Ошибка обновления',
        });
      });
  };

  const getInputOptions = (props: any) => {
    const options = [];
    if (props.objects.length > 0) {
      options.push({
        label: props.name,
        options: props.objects.map((item: any) => ({
          value: item.id,
          label: `${item.name} (${item.coordinates})`,
        })),
      });
    }
    if (props.parentId) {
      const parent = data.find(
        (item) => item.id.toString() === props.parentId.toString(),
      );
      if (parent && parent.objects.length > 0) {
        options.push({
          label: parent.name,
          options: parent.objects.map((item: any) => ({
            value: item.id,
            label: `${item.name} (${item.coordinates})`,
          })),
        });
      }
    }
    return options;
  };

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.IrrigationSystemTable)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Конструктор',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <InformationalBlock title="Конструктор водоводов">
        {isLoading ? (
          <LoadingPage />
        ) : (
          <CustomCard
            title={
              <PrimaryButton size="large" onClick={onFinish}>
                Сохранить
              </PrimaryButton>
            }
          >
            <Row>
              <Col span={4}>
                <Typography.Title level={5}>
                  Нераспределенные водоводы
                </Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={5}>
                  Основная схема ирригационной системы
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={4} className={styles.container}>
                {unsortedChannels.length > 0 &&
                  unsortedChannels.map((props) => {
                    return (
                      <>
                        <div
                          className={styles.leftItem}
                          draggable={true}
                          onDragStart={(e) => onDragStart(e, props)}
                        >
                          <div className={styles.title}>
                            [{props.id}] {props.name} ({props.typeCategory})
                          </div>
                          <div>{props.objectCategory?.groupTitle}</div>
                        </div>
                      </>
                    );
                  })}
                {unsortedChannels.length === 0 && <Empty />}
              </Col>
              <Col span={20} className={styles.container}>
                <Tree
                  showLine
                  switcherIcon={(props: any) =>
                    props.expanded ? (
                      <MinusSquareFilled
                        style={{ color: '#4c61d7', fontSize: '20px' }}
                      />
                    ) : (
                      <PlusSquareFilled
                        style={{ color: '#4c61d7', fontSize: '20px' }}
                      />
                    )
                  }
                  draggable
                  defaultExpandAll={true}
                  // blockNode
                  treeData={treeData}
                  onDragEnter={onDragEnter}
                  onDrop={onDrop}
                  titleRender={(props: any) => {
                    return (
                      <div
                        className={styles.treeItem}
                        style={{
                          border: props.hover
                            ? '1px solid #4c61d7'
                            : '1px solid transparent',
                        }}
                      >
                        <div
                          className={styles.title}
                          onDrop={(e) => {
                            props.hover = false;
                            onDropNode(e, props);
                          }}
                          onDragEnter={() => {
                            props.hover = true;
                          }}
                          onDragLeave={() => {
                            props.hover = false;
                          }}
                        >
                          [{props.id}] {props.name} ({props.typeCategory})
                        </div>
                        <div>{props.objectCategory?.groupTitle}</div>
                        {props.parentId !== '0' && (
                          <TooltipedSelect
                            size="small"
                            showSearch
                            optionFilterProp="label"
                            value={props.waterIntake?.id}
                            popupMatchSelectWidth={500}
                            onChange={(value) => {
                              props.waterIntake = props.objects.find(
                                (item: any) => item.id === value,
                              );
                            }}
                            options={getInputOptions(props)}
                          />
                        )}
                      </div>
                    );
                  }}
                />
              </Col>
            </Row>
          </CustomCard>
        )}
      </InformationalBlock>
    </>
  );
};
