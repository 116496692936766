import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Col, Divider, Empty, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllByOrganizationIdQuery } from '@store/gisproApi';
import {
  getSelectedWaterUserIdSelector,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';
import { get } from '@utils/utils';

import styles from './WaterUserDetailCard.module.scss';

const getSafeVal = (
  object: JsonRecord | undefined,
  path: string | string[],
  defaultVal?: any,
) => {
  return get(object, path, defaultVal);
};

const generateFio = (waterUser: any) => {
  if (waterUser?.employees?.length) {
    const fio = [
      getSafeVal(waterUser, 'legalData.employees.0.firstName'),
      getSafeVal(waterUser, 'legalData.employees.0.middleName'),
      getSafeVal(waterUser, 'legalData.employees.0.middleName'),
    ];
    return fio.join(' ');
  }
  return waterUser?.fio;
};

export const WaterUserDetailCard = () => {
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);
  const { data: waterUser } = useFindAllByOrganizationIdQuery(
    waterUserId
      ? {
          id: waterUserId,
        }
      : skipToken,
  );

  const getManagerFio = (employees: any) => {
    if (employees && employees[0]?.positionEmployee) {
      const res = employees?.find(
        (item: any) => item?.positionEmployee?.title === 'Директор',
      );
      return `${res?.firstName} ${res?.lastName} ${res?.middleName}`;
    }
    return '-';
  };

  const defaultData = {
    [WaterUserEnum.LEGAL]: [
      {
        title: 'Форма собственности',
        value: getSafeVal(waterUser, 'legalData.ownershipForm.title', '-'),
      },
      {
        title: 'Вид деятельности',
        value: getSafeVal(waterUser, 'legalData.businessActivity.title', '-'),
      },
      {
        title: 'Дата регистрации',
        value: getSafeVal(waterUser, 'legalData.registerDate', '-'),
      },
      {
        title: 'ИНН организации',
        value: getSafeVal(waterUser, 'legalData.inn', '-'),
      },
      {
        title: 'Регистрационный номер организации',
        value: getSafeVal(waterUser, 'legalData.regNumber', '-'),
      },
      { title: 'ОКПО', value: getSafeVal(waterUser, 'legalData.okpo', '-') },
      {
        title: 'ФИО Руководителя',
        value: waterUser?.employees && getManagerFio(waterUser.employees),
      },
      {
        title: 'Наименование Банка',
        value: getSafeVal(waterUser, 'legalData.bank.parent.name', '-'),
      },
      {
        title: 'Наименование Филиала Банка',
        value: getSafeVal(waterUser, 'legalData.bank.name', '-'),
      },
      { title: 'БИК', value: getSafeVal(waterUser, 'legalData.bank.bik', '-') },
      {
        title: 'Полный адрес банка',
        value: [
          getSafeVal(waterUser, 'legalData.bank.city', '-'),
          getSafeVal(waterUser, 'legalData.bank.address', '-'),
        ]
          .filter(Boolean)
          .join(', '),
      },
      {
        title: 'Корреспондентский счет',
        value: getSafeVal(waterUser, 'legalData.accountNumber', '-'),
      },
      {
        title: 'Расчетный счет',
        value: getSafeVal(waterUser, 'legalData.organizationAccount'),
      },
    ],
    [WaterUserEnum.INDIVIDUAL]: [
      {
        title: 'Наименование Банка',
        value: getSafeVal(waterUser, 'legalData.bank.parent.name', '-'),
      },
      {
        title: 'Наименование Филиала Банка',
        value: getSafeVal(waterUser, 'legalData.bank.name', '-'),
      },
      { title: 'БИК', value: getSafeVal(waterUser, 'legalData.bank.bik', '-') },
      {
        title: 'Полный адрес банка',
        value: [
          getSafeVal(waterUser, 'legalData.bank.city', '-'),
          getSafeVal(waterUser, 'legalData.bank.address', '-'),
        ]
          .filter(Boolean)
          .join(', '),
      },
      {
        title: 'Расчетный счет',
        value: getSafeVal(waterUser, 'legalData.accountNumber'),
      },
    ],
    [WaterUserEnum.ASSOCIATION]: [
      {
        title: 'Форма собственности',
        value: getSafeVal(waterUser, 'legalData.ownershipForm.title', '-'),
      },
      {
        title: 'Вид деятельности',
        value: getSafeVal(waterUser, 'legalData.businessActivity.title', '-'),
      },
      {
        title: 'Дата регистрации',
        value: getSafeVal(waterUser, 'legalData.registerDate', '-'),
      },
      {
        title: 'ИНН организации',
        value: getSafeVal(waterUser, 'legalData.inn', '-'),
      },
      {
        title: 'Регистрационный номер организации',
        value: getSafeVal(waterUser, 'legalData.regNumber', '-'),
      },
      { title: 'ОКПО', value: getSafeVal(waterUser, 'legalData.okpo', '-') },
      {
        title: 'ФИО Руководителя',
        value: waterUser?.employees && getManagerFio(waterUser.employees),
      },
      {
        title: 'Наименование Банка',
        value: getSafeVal(waterUser, 'legalData.bank.parent.name', '-'),
      },
      {
        title: 'Наименование Филиала Банка',
        value: getSafeVal(waterUser, 'legalData.bank.name', '-'),
      },
      { title: 'БИК', value: getSafeVal(waterUser, 'legalData.bank.bik', '-') },
      {
        title: 'Полный адрес банка',
        value: [
          getSafeVal(waterUser, 'legalData.bank.city', '-'),
          getSafeVal(waterUser, 'legalData.bank.address', '-'),
        ]
          .filter(Boolean)
          .join(', '),
      },
      {
        title: 'Корреспондентский счет',
        value: getSafeVal(waterUser, 'legalData.accountNumber', '-'),
      },
      {
        title: 'Расчетный счет',
        value: getSafeVal(waterUser, 'legalData.organizationAccount'),
      },
    ],
  };

  const detailData: any = {
    [WaterUserEnum.INDIVIDUAL]: [
      { title: 'ИНН', value: getSafeVal(waterUser, 'employees.0.inn', '-') },
      {
        title: 'Номер паспорта',
        value: getSafeVal(waterUser, 'employees.0.passportNumber', '-'),
      },
      {
        title: 'Дата рождения',
        value: getSafeVal(waterUser, 'employees.0.dateBirth', '-'),
      },
      {
        title: 'Вид хозяйственной деятельности',
        value: getSafeVal(waterUser, 'legalData.economicActivity.title', '-'),
      },
      ...defaultData[WaterUserEnum.INDIVIDUAL],
    ],
    [WaterUserEnum.LEGAL]: [
      // {
      //   title: 'ФИО Руководителя',
      //   value: waterUser?.employees && getManagerFio(waterUser.employees),
      // },
      {
        title: 'Форма организации',
        value: getSafeVal(waterUser, 'legalData.organizationForm.title', '-'),
      },
      ...defaultData[WaterUserEnum.LEGAL],
    ],
    [WaterUserEnum.ASSOCIATION]: [
      {
        title: 'Тип ассоциации',
        value: getSafeVal(waterUser, 'legalData.associationType.title', '-'),
      },
      ...defaultData[WaterUserEnum.ASSOCIATION],
    ],
  };

  return (
    <Row className={styles.customCard} gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>
                Детали водопользователя
              </Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Row>
          }
        >
          {!waterUser || !waterUserId ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Row gutter={[0, 4]}>
              {detailData[waterUserType]?.map((item: any) => (
                <>
                  <Col span={12} key={item?.title}>
                    <Typography.Text strong>{item?.title}: </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Typography.Text>{item?.value}</Typography.Text>
                  </Col>
                </>
              ))}
            </Row>
          )}
        </CustomCard>
      </Col>
    </Row>
  );
};
