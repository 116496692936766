import {
  IrrigationSystemCardWaterUserTableContainer
} from '@features/irrigationSystemCard/tables/irrigationSystemCardWaterUserTable/irrigationSystemCardWaterUserTableContainer/IrrigationSystemCardWaterUserTableContainer';
import {
  IrrigationSystemCardWaterUserTableControls
} from '@features/irrigationSystemCard/tables/irrigationSystemCardWaterUserTable/irrigationSystemCardWaterUserTableControls/IrrigationSystemCardWaterUserTableControls';
import { Divider } from '@features/ui-kit';

export const IrrigationSystemCardWaterUserTable = () => {
  return (
    <>
      <IrrigationSystemCardWaterUserTableControls/>
      <Divider/>
      <IrrigationSystemCardWaterUserTableContainer/>
    </>
  );
};
