import { FC, useEffect, useLayoutEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { useIrrigationSystemFilters } from '../useIrrigationSystemFilters';
import styles from './IrrigationSystemTableFilters.module.scss';
import { useGetCurrentUser } from '@features/authentication';

interface IIrrigationSystemTableContainerProps {
  onCreateButtonClick: () => void;
  isCanCreateIrrigationSystem: boolean;
  isArchived?: boolean;
}

export const IrrigationSystemTableFilters: FC<
  IIrrigationSystemTableContainerProps
> = ({
  isCanCreateIrrigationSystem,
  onCreateButtonClick,
  isArchived = false,
}) => {
  const [filtersState, setFiltersState] = useState<
    Partial<IrrigationSystemFilters>
  >({});
  const { filters, setIrrigationSystemFilters } =
    useIrrigationSystemFilters(isArchived);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: systemTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.IrrigationSystemsType,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const onFilterButtonClick = () => {
    setIrrigationSystemFilters(filtersState as IrrigationSystemFilters);
  };

  const onFilterChange = (
    filterKey: keyof IrrigationSystemFilters,
    v: string,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
      page: 1,
    }));
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      ...filtersState,
      name: null,
      regNum: null,
      systemTypeId: null,
      page: 1,
      size: 10,
    });
    setIrrigationSystemFilters({
      regionId: user?.owner?.region?.id.toString() || '',
      districtId: user?.owner?.district?.id.toString() || '',
      name: null,
      regNum: null,
      systemTypeId: null,
      page: 1,
      size: 10,
    });
  };
  useLayoutEffect(() => {
    setIrrigationSystemFilters({
      ...filters,
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
    });
  }, []);

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row justify="space-between">
      <Row gutter={16} align="middle" style={{ width: '70%' }}>
        <Col span={5}>
          <TooltipedSearch
            size="large"
            value={filtersState.regNum || ''}
            onChange={({ target }) => onFilterChange('regNum', target.value)}
            placeholder="Регистрационный номер"
            className={styles.tooltiped}
          />
        </Col>
        <Col span={5}>
          <TooltipedSearch
            size="large"
            value={filtersState.name || ''}
            onChange={({ target }) => onFilterChange('name', target.value)}
            placeholder="Наименование системы"
            className={styles.tooltiped}
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            size="large"
            value={
              systemTypes.find(
                (item) => item.id === toInteger(filtersState.systemTypeId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('systemTypeId', v)}
            placeholder="Тип системы"
            options={systemTypes}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            size="large"
            placeholder="Область"
            value={
              regions.find(
                (item) => item.id === toInteger(filtersState.regionId),
              )?.label
            }
            onChange={(v: string) => onRegionChange(v)}
            options={regions}
            allowClear
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            size="large"
            placeholder="Район"
            options={districts}
            disabled={isDisabledDistrict || !filtersState.regionId}
            value={
              districts.find(
                (item) => item.id === toInteger(filtersState.districtId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('districtId', v)}
            allowClear
          />
        </Col>
        <Col span={2} flex="0 0 100px" className={styles.filterCol}>
          <Row className={styles.filterBtns} wrap={false}>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        {isCanCreateIrrigationSystem && !isArchived && (
          <Col flex="auto">
            <PrimaryButton onClick={onCreateButtonClick} size="large">
              Создать систему
            </PrimaryButton>
          </Col>
        )}
      </Row>
    </Row>
  );
};
