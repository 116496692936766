import React from 'react';

import styles from './FadeCarousel.module.scss';

type FadeCarouselPropsType = {
  item: {
    header: string;
    text: string;
    imgPath: string;
  };
};

export const CarouselItem: React.FC<FadeCarouselPropsType> = ({ item }) => {
  return (
    <div
      key={item.text}
      className={styles.carouselItem}
      style={{
        backgroundImage: `url(${item.imgPath})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.textOverlay}>
        <h1 className={styles.header}>{item.header}</h1>
        <div className={styles.text}>{item.text}</div>
      </div>
    </div>
  );
};
