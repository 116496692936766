import {
  IDecadeDto,
  IMonthDto,
} from '@models/annualApplication/interfaces/annualApplication';
import { set } from '@utils/utils';

interface DecadeData {
  decade1: number;
  decade2: number;
  decade3: number;
}

interface MonthData {
  [month: string]: DecadeData;
}

interface ParameterResult {
  parameter: string;
  months: MonthData;
}

const parameters: string[] = [
  'hectaresIrrigation',
  'hectaresIrrigationIncrement',
  'waterSupplyVolume',
  'waterSupplyVolumeCubicMeters',
  'waterSupplyVolumeCubicMetersIncrement',
  'waterConsumptionVolume',
  'waterConsumptionVolumeIncrement',
];
export const useWaterAndHectareDataMapper = (data?: IMonthDto[]) => {
  if (data) {
    const res: ParameterResult[] = parameters.map((parameter) => {
      const parameterObj: ParameterResult = {
        parameter,
        months: {},
      };

      data.forEach((monthData) => {
        const { month, decadeDtos } = monthData;
        set(parameterObj, ['months', month], {});

        decadeDtos.forEach((decade) => {
          const decadeNumber = `decade${decade.decade}`;
          set(
            parameterObj,
            ['months', month, decadeNumber],
            decade[parameter as keyof IDecadeDto],
          );
        });
      });

      return parameterObj;
    });

    return res;
  }

  return [];
};
