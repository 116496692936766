import { getKibanaUrl } from '@store/kibana';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, Space, Table } from 'antd';

import { BalanceSheetTable } from '@features/monitoring/demoMonitoring/table';
import { SectionTitle } from '@features/ui-kit-v2';
import {
  useFindAllHydropostsQuery,
  useFindSystemsByWithFiltersQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

export const HydroCardLayout = () => {
  const { id } = useParams();
  const { data: hydroposts = [] } = useFindAllHydropostsQuery();
  const currentHydro = hydroposts.find((hydro) => hydro.id === toInteger(id));

  return (
    <Space
      size="large"
      direction="vertical"
      style={{ display: 'flex', marginTop: 110 }}
    >
      <Row gutter={12}>
        <Col span={24}>
          <SectionTitle
            background="blue"
            title={`Гидропосты дeтальная карточка - ${currentHydro?.display_name}`}
          />
        </Col>
      </Row>

      <Row justify="center">
        <iframe
          title="Гидропосты"
          src={getKibanaUrl('Гидропосты детальная карточка')}
          height="600"
          width="100%"
        />
      </Row>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <SectionTitle background="blue" title="Балансовая ведомость" />
        <BalanceSheetTable />
      </div>
    </Space>
  );
};
