import { CustomModal, DefaultButton, PrimaryButton, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import {
  CustomModalV2,
  DefaultButtonV2,
  PrimaryButtonV2,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { useCreatePointMutation, useGetPointTypeQuery } from '@store/gisproApi';
import {
  getMetricsCollectionPointType,
  getWaterQualityCardSelector,
} from '@store/selectors';

import { useCreatePointCollectionModal } from './useCreatePointCollectionModal';

export const CreatePointCollectionModal: React.FC = () => {
  const { isModalShow, setShowModal } = useCreatePointCollectionModal();
  const [form] = useForm();
  const [createPoint] = useCreatePointMutation();

  const defaultPointType = useSelector(getMetricsCollectionPointType);
  const { okmotId, districtId, regionId } = useSelector(
    getWaterQualityCardSelector,
  );

  const { data: pointType = [] } = useGetPointTypeQuery();

  const onCancelClick = () => {
    setShowModal(false);
  };

  const onFinish = () => {
    createPoint({ okmotId, districtId, regionId, ...form.getFieldsValue() });
    form.resetFields();
    setShowModal(false);
  };

  return (
    <CustomModal
      title="Создание точки сбора показателей"
      footer={null}
      open={isModalShow}
      onCancel={onCancelClick}
      centered
    >
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              label="Вид точки"
              name="type"
              initialValue={defaultPointType.value}
            >
              <TooltipedSelect
                options={pointType}
                disabled={defaultPointType.disabledValue}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              label="Наименование точки"
              name="pointName"
            >
              <TooltipedInput />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={10}>
          <Col>
            <DefaultButton onClick={onCancelClick}>Отмена</DefaultButton>
          </Col>
          <Col>
            <PrimaryButton htmlType="submit">Создать</PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
