import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Space } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { MonthEnums } from '@enums/month';
import {
  Divider,
  PrimaryButton,
  Segmented,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';

import { useWaterSupplyFilters } from './useWaterSupplyFilters';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery, useFindAllOutletsByChannelIdQuery,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useGetCurrentUser } from '@features/authentication';
import { SearchOutlined } from '@ant-design/icons';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';

const userTypes: SegmentedLabeledOption[] = [
  { value: WaterUserEnum.INDIVIDUAL, label: 'Физ лицо' },
  { value: WaterUserEnum.LEGAL, label: 'Юр лицо' },
  { value: WaterUserEnum.ASSOCIATION, label: 'Ассоциация' },
];
export const WateringSupplyScheduleControls: React.FC = () => {
  const [filterState, setFilterState] = useState<Partial<IWaterUseFilters>>({});
  const { user } = useGetCurrentUser();
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const { filters, setWaterUseWaterSupplyFilters } = useWaterSupplyFilters();
  const monthOptions = useMemo(
    () =>
      Object.values(MonthEnums).map((month) => ({
        label: t(`months.${month}`),
        value: month,
      })),
    [MonthEnums],
  );
  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });
  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      filterState.hydroFacilityId
        ? {
            hydroFacilityId: filterState.hydroFacilityId,
          }
        : skipToken,
    );

  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    filterState.irrigationSystemId
      ? { id: filterState.irrigationSystemId }
      : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    filterState.channelId ? { id: filterState.channelId } : skipToken,
  );
  const changeMonthHandle = (v: SegmentedValue) => {
    setFilterState((state: any) => ({
      ...state,
      month: v as MonthEnums,
    }));
    setWaterUseWaterSupplyFilters({
      ...filters,
      month: v as MonthEnums,
    });
    params.set('month', v as MonthEnums);
    setParams(params);
  };

  const onFilterChange = (
    filterKey: keyof IWaterUseFilters,
    v: string | null | Dayjs | number,
  ) => {
    const updatedFilters = {
      ...filterState,
      [filterKey]: v,
      page: 1,
    };
    setFilterState(updatedFilters);
  };

  const onResetFilterButtonClick = () => {
    setWaterUseWaterSupplyFilters({
      ...filterState,
      month: MonthEnums.January,
      userType: WaterUserEnum.ALL,
      outletName: '',
      irrigationSystemId: null,
      hydroFacilityId: null,
      channelId: null,
      outletId: null,
      year: null,
      page: 1,
      size: 10,
    });
  };

  const onFilterButtonClick = () => {
    setWaterUseWaterSupplyFilters({
      ...(filters as IWaterUseFilters),
      ...filterState,
    });
  };

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Space>
            <TooltipedSelect
              options={hydroFacilities}
              placeholder="Наименование гидроучастка"
              onChange={(v: number) => onFilterChange('hydroFacilityId', v)}
              allowClear
              value={filterState.hydroFacilityId}
              style={{ width: '100%' }}
            />
            <TooltipedSelect
              options={systems}
              placeholder="Наименование системы"
              onChange={(v: number) => onFilterChange('irrigationSystemId', v)}
              disabled={!filterState.hydroFacilityId}
              value={filterState.irrigationSystemId}
              allowClear
            />
            <TooltipedSelect
              popupMatchSelectWidth={300}
              options={channels}
              onChange={(v: number) => onFilterChange('channelId', v)}
              placeholder="Канал"
              allowClear
              value={filterState.channelId}
              disabled={!filterState.hydroFacilityId || !filterState.irrigationSystemId}
            />
            <TooltipedSelect
              popupMatchSelectWidth={300}
              onChange={(v: number) => onFilterChange('outletId', v)}
              options={outlets}
              placeholder="Водовыпуск"
              allowClear
              value={filterState.outletId}
              disabled={!filterState.hydroFacilityId || !filterState.irrigationSystemId || !filterState.channelId}
            />
            <YearPicker
              value={filterState.year ? dayjs(filterState.year) : null}
              onChange={(date: Dayjs | null, year) =>
                onFilterChange('year', year.toString())
              }
              placeholder="Год"
              disabledDate={(current) =>
                current && current > dayjs().endOf('year')
              }
            />
            <PrimaryButton
              onClick={onFilterButtonClick}
              icon={<FilterIcon />}
            />
            <PrimaryButton
              onClick={onResetFilterButtonClick}
              icon={<ResetFilterIcon />}
            />
          </Space>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Segmented
            options={monthOptions}
            onChange={changeMonthHandle}
            value={filters.month}
            defaultValue={filters.month}
          />
        </Col>
      </Row>
    </>
  );
};
