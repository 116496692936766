import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { IAttachment } from '@models/attachments/interfaces/attachment';
import { getDocumentSelector } from '@store/selectors/mainConduit';
import { addDocumentAction, removeDocumentAction } from '@store/slices';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

import { DocumentTable } from '@components/documentTable/DocumentTable';
import { UploadDocumentBlock } from '@components/uploadDocumentBlock/UploadDocumentBlock';

export const DocumentUploader = () => {
  const dispatch = useDispatch();
  const documents = useSelector(getDocumentSelector);

  const deleteItem = useCallback((document: IAttachment) => {
    dispatch(removeDocumentAction(document));
  }, []);

  const onLoad = useCallback((document: IAttachment) => {
    dispatch(addDocumentAction(document));
  }, []);

  const onWatch = useCallback(
    (document: IAttachment) => downloadAttachment(document),
    [],
  );

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <DocumentTable
            deleteItem={deleteItem}
            onHandleWatch={onWatch}
            documents={documents}
            showControls={false}
          />
        </Col>
        <Col span={12}>
          <UploadDocumentBlock onLoad={onLoad} />
        </Col>
      </Row>
    </>
  );
};
