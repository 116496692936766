import { MapContextType } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { GeoEditIcon } from '@icons/GeoEditIcon';
import { GeoPointCloseIcon } from '@icons/GeoPointCloseIcon';
import { GeoPointConfirmIcon } from '@icons/GeoPointConfirmIcon';
import { GeoPointIcon } from '@icons/GeoPointIcon';
import { GeoReloadIcon } from '@icons/GeoReloadIcon';
import { GeoAttributeTypeEnum } from '@models/geoPortal/enums/geoPortalEnum';
import {
  GeoAttribute,
  ILayerDto,
} from '@models/geoPortal/interfaces/GeoPortal';
import { Button, Col, Row, Tag, Typography } from 'antd';
import { get } from '@utils/utils';
import React from 'react';
import styles from '../../GeoPortalMapAttributes.module.scss';

type AttributeItemProps = {
  attribute: GeoAttribute;
  layer: ILayerDto | null;
  index: number;
  filterKey: string;
};

const facilityInfo = {
  name: 'ФИО/Наименование',
  hydroFacilityName: 'Наименование участка',
  irrigationSystemName: 'Оросительная система',
  channelName: 'Канал',
};

const systemObjectInfo = {
  name: 'Наименование',
  baseName: 'Источник',
  irrigationSystemName: 'Ирригационная система',
  piket: 'Пикетные координаты',
};

const associationInfo = {
  name: 'Наименование ассоциации',
  associationTypeName: 'Тип асоциации',
  businessActivityName: 'Вид деятельности',
};

export const AttributeItem: React.FC<
  Partial<MapContextType> & AttributeItemProps
> = ({
  attribute,
  editableAttribute,
  focusedAttribute,
  layer,
  index,
  onEditClick,
  onReloadClick,
  onPointClick,
  onSaveClick,
  onCancelClick,
  openDetails,
  isPreviewMap,
  filterKey,
}) => {
  let info = {};
  switch (attribute.type) {
    case GeoAttributeTypeEnum.WATER_USER:
      info = associationInfo;
      break;
    case GeoAttributeTypeEnum.FACILITY:
      info = facilityInfo;
      break;
    case GeoAttributeTypeEnum.SYSTEM_OBJECT:
      info = systemObjectInfo;
      break;
    default:
      info = systemObjectInfo;
      break;
  }

  const checkArchive = filterKey.split(':')[1] !== 'archive';

  return (
    <div
      className={
        focusedAttribute && focusedAttribute.id === attribute.id
          ? `${styles.detailsAttribute} ${styles.active}`
          : styles.detailsAttribute
      }
    >
      <Row style={{ padding: 8, width: '100%' }}>
        <Col span={2}>
          <Typography.Paragraph style={{ fontWeight: 600 }}>
            {index}.
          </Typography.Paragraph>
        </Col>

        {filterKey.split(':')[1] !== 'quality' ? (
          <Col span={19}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => openDetails?.(attribute)}
            >
              <div style={{ fontWeight: 'bold' }}>
                {get(attribute, 'name', '-')} (
                {get(attribute, 'objectType', '-')})
              </div>
              {attribute.coordinates.length === 0 && (
                <Tag color="red">Нет геоданных</Tag>
              )}
            </div>
            {Object.entries(info).map(([key, title]) => {
              return (
                <div>
                  <div
                    style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}
                  >
                    {title}:
                  </div>
                  <div style={{ marginBottom: 4 }}>
                    {get(attribute, key, '-')}
                  </div>
                </div>
              );
            })}
          </Col>
        ) : (
          <Col span={19}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => openDetails?.(attribute)}
            >
              <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                Наименование:
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {get(attribute, 'name', '-')} (
                {get(attribute, 'objectType', '-')})
              </div>
              {attribute.coordinates.length === 0 && (
                <Tag color="red">Нет геоданных</Tag>
              )}
            </div>

            {attribute?.area && (
              <div>
                <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                  Площадь ГА:
                </div>
                <div style={{ marginBottom: 4 }}>
                  {get(attribute, 'area', '-')}
                </div>
              </div>
            )}

            <div>
              <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                Описание:
              </div>
              <div
                style={{ marginBottom: 4 }}
                dangerouslySetInnerHTML={{
                  __html: get(attribute, 'popupText', '-'),
                }}
              ></div>
            </div>
          </Col>
        )}
        <Col
          span={3}
          style={{
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
            paddingLeft: 2,
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div>
              <Button
                type="text"
                icon={<GeoPointIcon />}
                onClick={() => onPointClick?.(layer, attribute)}
              />
              {checkArchive && !isPreviewMap && (
                <>
                  <Button
                    type="text"
                    icon={<GeoReloadIcon />}
                    onClick={() => onReloadClick?.(layer, attribute)}
                  />
                  <Button
                    type="text"
                    icon={<GeoEditIcon />}
                    onClick={() => onEditClick?.(layer, attribute)}
                  />
                </>
              )}
            </div>
            {checkArchive &&
              editableAttribute &&
              editableAttribute.id === attribute.id && (
                <div>
                  <Button
                    type="text"
                    icon={<GeoPointConfirmIcon />}
                    onClick={() => onSaveClick?.(layer, attribute)}
                  />
                  <Button
                    type="text"
                    icon={<GeoPointCloseIcon />}
                    onClick={() => onCancelClick?.(layer, attribute)}
                  />
                </div>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
