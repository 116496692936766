import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowActionSelectSelector } from '@store/selectors';
import { setShowActionSelectModalAction } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export function useActionSelect(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowActionSelectSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowActionSelectModalAction(showModal));
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
}
