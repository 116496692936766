import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const NewsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0723 4.3501H18.2998C18.5385 4.3501 18.7674 4.44492 18.9362 4.6137C19.105 4.78248 19.1998 5.0114 19.1998 5.2501V20.1001C19.1998 20.3388 19.105 20.5677 18.9362 20.7365C18.7674 20.9053 18.5385 21.0001 18.2998 21.0001H5.6998C5.46111 21.0001 5.23219 20.9053 5.06341 20.7365C4.89463 20.5677 4.7998 20.3388 4.7998 20.1001V5.2501C4.7998 5.0114 4.89463 4.78248 5.06341 4.6137C5.23219 4.44492 5.46111 4.3501 5.6998 4.3501H8.8498V5.7001H15.1498V4.3501H16.0723Z"
        stroke-width="2"
        stroke-linejoin="round"
        stroke="#89898b"
        fill="#89898b"
      />
      <path
        d="M8.8501 3H15.1501V5.7H8.8501V3Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="#89898b"
        fill="white"
      />
      <path
        d="M13.35 9.75L9.75 13.3504H14.2518L10.65 16.9505"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <g mask="url(#mask0_5502_135525)">
        <path d="M1.19971 1.19995H22.7997V22.8H1.19971V1.19995Z" />
      </g>
    </svg>
  );
};
