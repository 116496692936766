import { Col, Row } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { TooltipedTextareaV2 } from '@features/ui-kit-v2';
import { IBuildingBlock } from '@models/manageEvents/interfaces/buildingBlock';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';

type DescriptionInfoPropTypes = {
  data?: IOperationalBlock | IBuildingBlock;
};

export const DescriptionInfo: React.FC<DescriptionInfoPropTypes> = ({
  data,
}) => {
  return (
    <CustomCard title="Описание">
      <Row gutter={8}>
        <Col span={12}>
          <TooltipedTextareaV2 value={data?.taskDescription} />
        </Col>
        <Col span={12}>
          <TooltipedTextareaV2 value={data?.prepReportDescription} />
        </Col>
      </Row>
    </CustomCard>
  );
};
