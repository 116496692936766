export enum WaterQualityTableFilters {
  DictionaryZone = 'dictionaryZone',
  EntryData = 'entryData',
  MonitoringPoints = 'monitoringPoints',
}

export enum WaterQualityCardStep {
  RegisterMonitoringZone = 'registerMonitoringZone',
  CreateCollectionPoint = 'createCollectionPoint',
  Gis = 'gis',
}

export enum WaterQualityMonitoringType {
  ReclamationStatus = 'Мелиоративное состояние',
  MineralizationGroundwater = 'Лабораторные исследования',
  DepthUGV = 'Глубина залегания УГВ',
}

export enum WaterQualityPointType {
  Hydropost = 'Гидропост',
  WellMonitoring = 'Мониторинг скважина',
  OtherSamplingLocation = 'Другое место отбора проб',
}

export enum ViewTypeMonitoringZone {
  Reclamation = 'reclamationState',
  GroundWater = 'groundWaterDepth',
  LaboratoryResearch = 'laboratoryResearch',
  MonitoringZone = 'monitoringZone',
}

export interface IWaterQualityFilters {
  pointId?: number;
  zoneId?: number;
  regionId?: string | null;
  districtId?: string | null;
  okmotId?: string | null;
  monthNumber?: number;
  type?: string | null;
  page?: number;
  year?: number;
  quarter?: number;
}
