import { AccountingGroupEnum } from '@enums/accountingGroup';
import { type ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { SystemObjectCardTabsEnum } from '@models/systemObject/systemObjectEnum';
import { useTranslation } from 'react-i18next';
import {
  OtherIndicatorsComponent,
  PassportInformationComponent,
  SystemIndicatorsOtherAquaductsComponent,
  SystemIndicatorsPowerlineComponent,
  SystemIndicatorsForestBeltsComponent,
  SystemIndicatorsGvsComponent,
  SystemIndicatorsHydropostComponent,
  SystemIndicatorsBridgeComponent,
  SystemIndicatorsOutletsComponent,
  SystemIndicatorsRoadComponent,
  SystemIndicatorsWellComponent,
  SystemIndicatorsHoleComponent,
  SystemIndicatorsCollectorComponent,
  SystemIndicatorsPumpComponent,
  SystemIndicatorsElectricalSubstationComponent,
  SystemIndicatorsDamComponent,
  RelatedObjectsComponent,
  OperationalBlockComponent,
  DocumentsComponent,
} from '@features/waterObject/waterObjectCard/shared/components';
import { InformationalBlock } from '@features/ui-kit';
import { OperationalBlock } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/systemObjectInfo/operationalBlock/OperationalBlock';
import { SystemIndicatorsPondAndBsrComponent } from './shared/components/systemIndicatorsPondAndBsrComponent';
import { SystemIndicatorsMainConduitComponent } from './shared/components/systemIndicatorsMainConduitComponent';

export const getWaterObjectCardTypeAndComponent = (
  objectCategory: ObjectCategory | undefined,
) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'systemObject.card.tabs',
  });
  const accountingGroupCode = String(objectCategory?.accountingGroup?.code);

  let tabs: any[] = [];

  switch (accountingGroupCode) {
    case AccountingGroupEnum.MainConduit:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsMainConduitComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.ForestBelt:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsForestBeltsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.PowerLine:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsPowerlineComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Gvs:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsGvsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Hydropost:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsHydropostComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.WaterOutlet:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsOutletsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Bridge:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsBridgeComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Road:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsRoadComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Well:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsWellComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Hole:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsHoleComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Collector:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsCollectorComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Pump:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsPumpComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.ElectricalSubstation:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsElectricalSubstationComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.OtherMeasuringInstruments:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsHydropostComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Dam:
    case AccountingGroupEnum.Weir:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsDamComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.Gts:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsOutletsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.SpillwayStructure:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsOutletsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.OtherAquaducts:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsOtherAquaductsComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    case AccountingGroupEnum.PondAndBsr:
    case AccountingGroupEnum.Reservoir:
      tabs = [
        {
          key: SystemObjectCardTabsEnum.totalInformation,
          label: t(SystemObjectCardTabsEnum.totalInformation),
          children: (
            <InformationalBlock>
              <PassportInformationComponent />
              <SystemIndicatorsPondAndBsrComponent />
              <OtherIndicatorsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.relatedObjects,
          label: t(SystemObjectCardTabsEnum.relatedObjects),
          children: (
            <InformationalBlock>
              <RelatedObjectsComponent />
            </InformationalBlock>
          ),
        },
        {
          key: SystemObjectCardTabsEnum.operationalBlock,
          label: t(SystemObjectCardTabsEnum.operationalBlock),
          children: <OperationalBlockComponent />,
        },
        {
          key: SystemObjectCardTabsEnum.documents,
          label: t(SystemObjectCardTabsEnum.documents),
          children: (
            <InformationalBlock>
              <DocumentsComponent />
            </InformationalBlock>
          ),
        },
      ];
      break;
    default:
      tabs = [];
      break;
  }

  return { tabs };
};
