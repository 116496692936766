import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const columns: TableColumn[] = [
  {
    title: 'Административно-территориальная единица',
    dataIndex: 'name',
    xtype: CustomTableColumnType.String,
    key: 'name',
    align: 'left',
    width: 178,
  },
  {
    title: 'Количество отчитываю-щихся водопользо-вателей',
    dataIndex: 'waterUsersCount',
    xtype: CustomTableColumnType.String,
    key: 'waterUsersCount',
    align: 'left',
    width: 120,
  },
  {
    title: 'Всего забрано из водных объектов',
    dataIndex: 'totalWater',
    xtype: CustomTableColumnType.String,
    key: 'totalWater',
    align: 'left',
    width: 120,
  },
  {
    title: 'Использовано',
    dataIndex: 'used',
    align: 'left',
    key: 'used',
    xtype: CustomTableColumnType.String,
    children: [
      {
        title: 'Всего',
        dataIndex: 'totalUsed',
        align: 'left',
        xtype: CustomTableColumnType.String,
      },
      {
        title: 'В том числе на нужды',
        dataIndex: 'forNeeds',
        align: 'left',
        key: 'forNeeds',
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Хоз. питьевые',
            dataIndex: 'householdDrinking',
            align: 'left',
            width: 131,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'Производственные',
            dataIndex: 'industrial',
            align: 'left',
            width: 131,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'Орошение',
            dataIndex: 'irrigationWater',
            align: 'left',
            width: 131,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'с/х Водоснабжение',
            dataIndex: 'agriculturalWaterSupply',
            align: 'left',
            width: 131,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'Другие',
            dataIndex: 'other',
            align: 'left',
            width: 131,
            xtype: CustomTableColumnType.String,
          },
        ],
      },
    ],
  },
  {
    title: 'Потери при транспортировке',
    dataIndex: 'transportLosses',
    xtype: CustomTableColumnType.String,
    key: 'transportLosses',
    align: 'left',
    width: 160,
  },
  {
    title: 'Отведено без использования',
    dataIndex: 'dischargedWithoutUse',
    xtype: CustomTableColumnType.String,
    key: 'dischargedWithoutUse',
    align: 'left',
    width: 160,
  },
  {
    title: 'Сброшено в водные источники после использования',
    dataIndex: 'dischargedAfterUse',
    xtype: CustomTableColumnType.String,
    key: 'dischargedAfterUse',
    align: 'left',
    width: 160,
  },
  {
    title: 'Передано за пределы района, области, гос.',
    dataIndex: 'transferredOutside',
    xtype: CustomTableColumnType.String,
    key: 'transferredOutside',
    align: 'left',
    width: 160,
  },
];
