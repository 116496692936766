import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, notification, Row, Space } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { DocsList, DocsUploader, InformationalBlock } from '@features/ui-kit';
import { CustomCard, DocsUploaderV2, SectionTitle } from '@features/ui-kit-v2';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { getFormData } from '@store/selectors';
import { addAttachmentToWaterRegistryUserAction } from '@store/slices';

export type DocumentsBlockProps = {
  userType: WaterUserTypes;
};

export const DocumentsBlock: React.FC<DocumentsBlockProps> = ({ userType }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentWaterRegistry = useSelector(getFormData);

  const documents: Array<IAttachment> = useMemo(
    () => currentWaterRegistry[userType].attachmentResponseDtos || [],
    [currentWaterRegistry],
  );

  const attachments = documents?.filter(
    (att) =>
      att?.type === DocumentTypes.Agreement ||
      att?.type === DocumentTypes.Contract ||
      att?.type === DocumentTypes.Annex,
  );

  const onAttachmentUpload = useCallback((data: any) => {
    try {
      dispatch(
        addAttachmentToWaterRegistryUserAction({
          attachment: data,
          waterRegistryUserType: userType,
        }),
      );
      notification.success({
        message: 'Сохранено',
      });
    } catch (error) {
      notification.success({
        message: 'Не удалось сохранить документ',
      });
    }
  }, []);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <InformationalBlock
            title={t(
              'createIndividualWaterRegistry.thirdStep.documentsBlock.title',
            )}
          >
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <DocsUploader
                  docType={DocumentTypes.Agreement}
                  onLoad={onAttachmentUpload}
                  // documents={documents}
                  title="Прикрепить соглашение"
                />
              </Col>
              <Col span={24}>
                <DocsList
                  title={t('irrigationForm.attachedDocs')}
                  documents={attachments}
                />
              </Col>
            </Row>
          </InformationalBlock>
        </Col>
      </Row>
    </Form>
  );
};
