import { InstructionsManagement } from '@features/administration';
import { Layout } from 'antd';

export const InstructionsManagementLayout: React.FC = () => {
  return (
    <Layout>
      <InstructionsManagement />
    </Layout>
  );
};
