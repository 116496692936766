import { Col, Row, Typography } from 'antd';

import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { WaterUsePlanRuvhGeneralSummary } from '@features/waterUsePlan/shared';
import { PrintIcon } from '@icons/PrintIcon';

import { useWaterUsePlanGuvhAdditionsTableColumns } from './useWaterUsePlanPivotTableColumns';
import styles from './WaterUsePlanPivotTable.module.scss';

export const WaterUsePlanPivotTable = () => {
  const { columns } = useWaterUsePlanGuvhAdditionsTableColumns();

  return (
    <InformationalBlock>
      <CustomCard
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Title
                style={{ marginBottom: 0, color: '#3F4778' }}
                level={4}
              >
                Сводная таблица
              </Typography.Title>
            </Col>
            <Col>
              <PrimaryButton icon={<PrintIcon />} />
            </Col>
          </Row>
        }
      >
        <WaterUsePlanRuvhGeneralSummary />
      </CustomCard>
    </InformationalBlock>
  );
};
