import { documentFormats } from '@constants/documentFormats';
import { attachFileUrl } from '@features/ui-kit/upload/utils/attachFileUrl';
import { IAttachment } from '@models/attachments/interfaces/attachment';

const baseUrl = process.env.REACT_APP_MAIN_BASE_URL;

export const downloadAttachment = (attachment: IAttachment) => {
  const contentType: string =
    documentFormats.find((item) => item.formats?.includes(attachment.extension))
      ?.value || 'application/pdf';
  const fileUrl = `${baseUrl}/api/v1/attachment/download?path=${
    attachment.filePath || attachment.path
  }`;
  fetch(fileUrl, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.originName || attachment.name);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    });
};
