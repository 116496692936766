import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Form, Row, Space } from 'antd';

import {
  CustomCard,
  DefaultButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllBanksQuery, useFindAllFilialQuery } from '@store/gisproApi';
import { getSelectedBankSelector } from '@store/selectors';
import { numbersValidator } from '@utils/validation/numbersValidator';

import { useAddBank } from '../useAddBank';
import { useAddBranch } from '../useAddBranch';

export const ExtendedBankDetails: React.FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const parentBankId = Form.useWatch('parentBankId', form);
  const bank = Form.useWatch('bank', form);
  const selectedBankId = useSelector(getSelectedBankSelector);

  const { setShowModal: setShowAddBankModal, selectBank } = useAddBank();
  const { setShowModal: setShowAddBranchModal } = useAddBranch();

  const { data: banks = [], isLoading: bankIsLoading } = useFindAllBanksQuery();
  const { data: filials = [], isLoading: filialIsLoading } =
    useFindAllFilialQuery(parentBankId ? { bankId: parentBankId } : skipToken);

  useEffect(() => {
    const selectedFilial = filials.find((item) => item.id === bank);
    if (selectedFilial) {
      const { bankBik, regionName, districtName, bankCity, bankAddress } =
        selectedFilial;

      const fullBankAddress = [regionName, districtName, bankCity, bankAddress]
        .filter(Boolean)
        .join(', ');

      form.setFieldsValue({
        bankBik,
        bankAddress: fullBankAddress,
      });
    }
  }, [bank]);

  useEffect(() => {
    if (selectedBankId) {
      form.setFieldValue('parentBankId', selectedBankId);
    }
  }, [selectedBankId]);

  useEffect(() => {
    selectBank(parentBankId);
  }, [parentBankId]);

  useEffect(() => {
    form.resetFields(['parentBankId']);
  }, []);

  const handleChangeBank = () => {
    form.resetFields(['bankId', 'bankBik', 'bankAddress']);
  };

  return (
    <Row>
      <Col span={24}>
        <CustomCard title="Банковские реквизиты">
          <Row gutter={16}>
            <Col span={8}>
              <Row align="bottom" gutter={8}>
                <Col span={16}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.bank',
                    )}
                    name="parentBankId"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      size="large"
                      allowClear
                      onChange={handleChangeBank}
                      options={banks.map((item) => ({
                        label: item.bankName,
                        value: item.id,
                      }))}
                      loading={bankIsLoading}
                      placeholder="Введите название банка"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <DefaultButton
                      block
                      size="large"
                      onClick={() => setShowAddBankModal(true)}
                    >
                      {t(
                        'createIndividualWaterRegistry.firstStep.bankDetails.buttons.addBank',
                      )}
                    </DefaultButton>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.bankAddress',
                )}
                name="bankAddress"
                rules={[{ required: true }]}
              >
                <TooltipedInput
                  placeholder="Введите адрес"
                  size="large"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.bank.placeholders.correspondentAccount',
                )}
                rules={[{ required: true }, numbersValidator]}
                name="accountNumber"
              >
                <TooltipedInput
                  type="number"
                  placeholder="Введите Номер корреспондентского счета"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Row gutter={8} align="bottom">
                <Col span={16}>
                  <Form.Item
                    label={t(
                      'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.branchName',
                    )}
                    name="bank"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      size="large"
                      options={filials?.map((item) => ({
                        label: item.bankName,
                        value: item.id,
                      }))}
                      disabled={!parentBankId}
                      loading={filialIsLoading}
                      allowClear
                      placeholder="Введите название филиала"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <DefaultButton
                      size="large"
                      onClick={() => setShowAddBranchModal(true)}
                      disabled={!parentBankId}
                      block
                    >
                      {t(
                        'createIndividualWaterRegistry.firstStep.bankDetails.buttons.addBranch',
                      )}
                    </DefaultButton>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.bankBik',
                )}
                name="bankBik"
              >
                <TooltipedInput
                  placeholder="Введите БИК"
                  size="large"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  'createLegalWaterRegistry.firstStep.bank.placeholders.organizationAccount',
                )}
                name="organizationAccount"
                rules={[{ required: true }, numbersValidator]}
              >
                <TooltipedInput
                  type="number"
                  placeholder="Введите Расчетный счет организации"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomCard>
      </Col>
    </Row>
  );
};
