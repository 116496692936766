import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsPondAndBsrComponent: FC = () => {
  const { data: managementMethods = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.ControlMethodTypes,
    });

  const { data: facings = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: formTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  const { data: fillModeTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.FillModeType,
  });

  const { data: resetModeTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.ResetModeType,
  });

  const { data: waterBalanceTypes = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.WaterBalanceType,
    });

  // TODO: add types of trees
  // const { data: typesOfTrees = [] } = useReferenceControllerFindBycodeQuery({
  //   id: EntityTypesEnum.Types,
  // });

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <CustomCard title="Системные показатели">
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={9}>
                <CustomCard title={null} dashed>
                  <Row gutter={16} align="bottom">
                    <Col span={8}>
                      <Form.Item
                        name={['status', 'surfaceAreaHectares']}
                        label="Площадь поверхности Га"
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name={['status', 'volume']} label="Объем (м3)">
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={['status', 'depthM']}
                        label="Глубина, (м)"
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
              <Col span={15}>
                <CustomCard title={null} dashed>
                  <Row gutter={16} align="bottom">
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'facingId']}
                        label="Облицовка"
                      >
                        <TooltipedSelect
                          options={facings}
                          size="large"
                          placeholder="Выберите облицовку"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name={['status', 'formId']} label="Форма">
                        <TooltipedSelect
                          size="large"
                          options={formTypes}
                          placeholder="Выберите форму"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name={['status', 'managementMethodsId']}
                        label="Методы управления"
                      >
                        <TooltipedSelect
                          size="large"
                          options={managementMethods}
                          placeholder="Выберите метод"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name="direction">
                        <PropertyBlock
                          icon={ConstructionIcon}
                          label="Направление"
                          type="radio"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>

              <Col span={24}>
                <CustomCard title={null} dashed>
                  <Row gutter={16} align="bottom">
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'maxWaterLevel']}
                        label="Макс. уровень воды"
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name={['status', 'minWaterLevel']}
                        label="Мин. уровень воды"
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'maxCapacity']}
                        label="Макс. емкость "
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'minCapacity']}
                        label="Мин. емкость "
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name={['status', 'fillModeId']}
                        label="Режимы заполнения"
                      >
                        <TooltipedSelect
                          options={fillModeTypes}
                          size="large"
                          placeholder="Выберите режим заполнения"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'resetModeId']}
                        label="Режимы сброса"
                      >
                        <TooltipedSelect
                          options={resetModeTypes}
                          size="large"
                          placeholder="Выберите режим сброса"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'waterBalanceId']}
                        label="Водный баланс"
                      >
                        <TooltipedSelect
                          options={waterBalanceTypes}
                          size="large"
                          placeholder="Выберите водный баланс"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['status', 'usefulVolume']}
                        label="Полезный обьем"
                      >
                        <TooltipedInput
                          type="number"
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
