import { createSelector } from '@reduxjs/toolkit';
import { SourceState } from '@store/slices/source';

type WithSourceState = {
  source: SourceState;
};

export const sourceStateSelector = (state: WithSourceState): SourceState =>
  state.source;

export const getIsCreateSourceModalOpenSelector = createSelector(
  sourceStateSelector,
  (sourceState) => {
    return sourceState.isCreateSourceModalOpen;
  },
);

export const getSelectedRegionIdSelector = createSelector(
  sourceStateSelector,
  (sourceState) => {
    return sourceState.selectedRegionId;
  },
);

export const getSelectedBassinIdSelector = createSelector(
  sourceStateSelector,
  (sourceState) => {
    return sourceState.selectedBassinId;
  },
);
