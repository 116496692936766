import { CloseOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderDecadePlanCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { value, disabled }: { value: string; disabled: boolean } =
    record[customColumn.dataIndex];
  return value ? (
    <div
      className={`${styles.decadeCell} ${
        disabled && styles.decadeCellDisabled
      }`}
    >
      {value}
      <CloseOutlined className={`${styles.xmark} ${styles.xmarkPosition}`} />
    </div>
  ) : (
    <CloseOutlined className={styles.xmark} />
  );
}
