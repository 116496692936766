import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { Col, Row } from 'antd';
import React, { SyntheticEvent } from 'react';

import { PrimaryButton } from '../../../buttons';

export function renderMethods<RecordType extends JsonRecord>({
                                                               customColumn,
                                                               record,
                                                               methodsProps,
                                                             }: TableRendererProps<RecordType>) {
  const { methods = [], dataIndex } = customColumn;

  const onClickStopPropagation = (callback?: () => void) => {
    return (e: SyntheticEvent) => {
      e.stopPropagation();
      callback?.();
    };
  };
  return (
    <Row
      className="actions-wrapper"
      justify="center"
      align="middle"
      wrap={false}
      gutter={8}
    >
      {methods.map((method, index) => {
        // TODO: confirm
        // TODO: icon
        return !method.condition || method.condition(record) ? (
          <Col key={method.title}>
            <PrimaryButton
              onClick={() => methodsProps?.[index]?.(record)}
            >
              {method.title}
            </PrimaryButton>
          </Col>
        ) : null;
      })}
    </Row>
  );
}
