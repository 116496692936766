import { getKibanaUrl } from '@store/kibana';
import { Col, Row, Typography } from 'antd';

import { CustomCard, TooltipedSelect } from '@features/ui-kit';

import styles from './WaterSupplyCard.module.scss';

export const WaterSupplyCard: React.FC = () => {
  return (
    <CustomCard
      title={
        <Row align="middle" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Водоподача, л/с
            </Typography.Title>
          </Col>
          <Col>
            <Row justify="end" gutter={8}>
              <Col>
                <TooltipedSelect placeholder="Год" />
              </Col>
              <Col>
                <TooltipedSelect placeholder="Месяц" />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      {/* TODO: replace with kibana */}
      <div
        style={{
          height: 590,
          width: '100%',
        }}
      >
        <iframe
          title="water supply"
          src={getKibanaUrl('Мониторинг')}
          height="100%"
          width="100%"
        />
      </div>
    </CustomCard>
  );
};
