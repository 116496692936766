import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useOperationalBlocksColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },

    {
      title: 'Система',
      align: 'center',
      dataIndex: 'system',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'system',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.system.name}</span>
        </div>
      ),
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: '...',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch],
      width: 100,
    },
  ];

  return { columns };
};
