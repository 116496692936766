import { ColumnType } from 'antd/es/table';

import { toInteger } from '@utils/utils';

interface GroupedData {
  day: string;
  [hour: string]: string | undefined;
}

export const useBalanceSheetColumns = () => {
  const arrLenght: any = Array.from({ length: 24 }, (_, index) => ({
    title: index.toString(),
    dataIndex: index.toString(),
    key: index.toString(),
    render: (renderText: any, record: any) =>
      record ? record[index.toString()] || '0' : 0,
  }));

  const calculateSum = (data: Record<string, string>): number => {
    return Object.values(data).reduce<number>(
      (acc, value) => acc + toInteger(value || '0'),
      0,
    );
  };

  const columns: ColumnType<any>[] = [
    {
      title: 'День',
      width: 100,
      dataIndex: 'day',
      key: 'day',
      fixed: 'left',
    },
    ...arrLenght,
    {
      title: 'Всего за день',
      width: 100,
      dataIndex: 'somfse',
      key: 'somfse',
      fixed: 'right',
      render: (text, record) => {
        const obj = { ...record };
        delete obj.day;
        const sum = calculateSum(obj);
        return <div>{sum}</div>;
      },
    },
  ];

  return { columns };
};
