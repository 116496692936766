import { Button, ButtonProps } from 'antd';

import styles from './DashedButton.module.scss';

type DashedButtonPropTypes = ButtonProps & {
  colorType?: string;
};

export const DashedButton: React.FC<DashedButtonPropTypes> = ({
  children,
  colorType = 'cyan',
  ...props
}) => {
  return (
    <Button
      className={`${styles.dashedButton}, ${styles[colorType]}`}
      {...props}
    >
      {children}
    </Button>
  );
};
