import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckboxProps, Col, Divider, Row, Table, Typography } from 'antd';

import { CustomCard, TableCustom, TooltipedInput } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useSearchAllWaterUsersByOrganizationQuery,
  WaterUserTableDto,
} from '@store/gisproApi';
import {
  getSelectedWaterUserIdSelector,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

import { useWaterUserSelectionTableColumns } from './useWaterUserSelectionTableColumns';

export const WaterUserSelectionTable = () => {
  const [filter, setFilter] = React.useState({});
  const userType = useSelector(getSelectedWaterUserTypeSelector);

  const dispatch = useDispatch();
  const columns: any = useWaterUserSelectionTableColumns();
  const waterUserId = useSelector(getSelectedWaterUserIdSelector);

  const {
    data: content,
    isLoading,
    isFetching,
  } = useSearchAllWaterUsersByOrganizationQuery(
    userType
      ? {
          userType,
          ...(filter || {}),
        }
      : skipToken,
  );

  const onChange = (selectedRowKey: React.Key[]) => {
    dispatch(
      annualApplicationActions.setSelectedWaterUserIdAction(
        selectedRowKey[0] as number,
      ),
    );
    dispatch(annualApplicationActions.setSelectedFacilityIdAction(null));
  };

  const getCheckboxProps = (record: WaterUserTableDto): CheckboxProps => ({
    value: record.id,
    name: record.fio,
  });

  const onFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    setFilter((state) => ({
      ...state,
      [field]: event.target.value,
    }));
  };

  return (
    <Row gutter={[0, 10]}>
      <CustomCard
        title={
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography.Title level={4}>
                Список водопользователей
              </Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Col>
            <Col>
              <Row gutter={8}>
                <Col span={12}>
                  <TooltipedInput
                    placeholder="ИНН"
                    onChange={(event) => onFilterChange(event, 'inn')}
                  />
                </Col>

                <Col span={12}>
                  <TooltipedInput
                    placeholder="Наименование"
                    onChange={(event) => onFilterChange(event, 'fio')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Col>
          <TableCustom
            rowSelection={{
              type: 'radio',
              onChange,
              getCheckboxProps,
              defaultSelectedRowKeys: [waterUserId as number],
            }}
            bordered
            size="small"
            loading={isLoading || isFetching}
            rowKey={(record) => record.id}
            columns={columns[userType] || columns.legal}
            dataSource={content}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </Col>
      </CustomCard>
    </Row>
  );
};
