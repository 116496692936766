import { Layout } from 'antd';

import { AgroClimaticZoneCard } from '@features/agroClimaticZone';

export const AgroClimaticZoneCardLayout = () => {
  return (
    <Layout>
      <AgroClimaticZoneCard />
    </Layout>
  );
};
