import { useTranslation } from 'react-i18next';

import { Col, Row, Tag, Typography } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

import styles from './styles.module.scss';

export function renderWaterRegistryControl<RecordType extends JsonRecord>({
  customColumn,
  actionProps,
  record,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation();

  let tags = [
    {
      title: 'Карточка',
      action: actionProps?.showWaterUserCard,
      status: 'success',
    },
    {
      title: 'Годовая заявка',
      action: actionProps?.showAnnualApplication,
      status: 'processing',
    },
    {
      title: 'ГИС',
      action: actionProps?.showGis,
      status: 'success',
    },
    {
      title: 'Выдача доступа',
      action: actionProps?.showPermissions,
      status: 'warning',
    },
  ];

  if (
    record.type === t(`userTypes.${WaterUserTypes.association}`) ||
    record.organizationType === t(`userTypes.${WaterUserTypes.association}`)
  ) {
    tags = [
      ...tags,
      {
        title: 'Основные показатели ассоциации',
        action: actionProps?.showAssociationStats,
        status: 'error',
      },
    ];
  }

  return (
    <Row gutter={[4, 4]}>
      <>
        {tags.map((item) => (
          <Col key={item.title} flex="1 1">
            <Tag
              key={item.title}
              color={item.status}
              bordered={false}
              className={styles.tag}
              onClick={() => item.action?.(record)}
            >
              <Typography.Text>{item.title}</Typography.Text>
            </Tag>
          </Col>
        ))}
      </>
    </Row>
  );
}
