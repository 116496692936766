import {
  GeoPortalNavigationContentFilters
} from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationContent/geoPortalNavigationContentFilters/GeoPortalNavigationContentFilters';
import { Divider } from '@features/ui-kit';
import {
  GeoPortalNavigationContentMaps
} from '@features/geoPortal/new/geoPortalNavigation/geoPortalNavigationContent/geoPortalNavigationContentMaps/GeoPortalNavigationContentMaps';

export const GeoPortalNavigationContent = () => {
  return (
    <div>
      <GeoPortalNavigationContentFilters />
      <Divider />
      <GeoPortalNavigationContentMaps />
    </div>
  );
}
