import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/index';
import { Routes } from '@enums/routes';
import { saveTableOptions } from '@store/slices';
import { toInteger } from '@utils/utils';
import { List, Row, Space } from 'antd';
import styles
  from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/operationalData/OperationalData.module.scss';
import {
  OperationalDataItem
} from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/operationalData/OperationalDataItem';
import { PrimaryButton } from '@features/ui-kit';
import { TractorIcon } from '@icons/TractorIcon';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';

type OperationalBlockProps = {
  operational: any;
  waterObject: any;
}

export const OperationalBlock: React.FC<OperationalBlockProps> = ({ operational, waterObject }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const onHandleClick = () => {
    navigate(`${Routes.ManageEventsSchedule}/?type=operational`);
    dispatch(
      saveTableOptions({
        systemObjectId: toInteger(waterObject?.id),
      }),
    );
  };

  return (
    <>
      <Space direction="vertical" size={0} className={styles.operationalData}>
        <List
          dataSource={operational?.data}
          renderItem={(item: IOperationalBlock) => (
            <List.Item>
              <OperationalDataItem operational={item} />
            </List.Item>
          )}
        />
      </Space>
      <Row justify="center" className={styles.buttonWrapper}>
        <PrimaryButton onClick={onHandleClick} icon={<TractorIcon />}>
          Экспл. блок
        </PrimaryButton>
      </Row>
    </>
  );
}
