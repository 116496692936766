import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Form, notification, Row, Spin } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { AccountingGroupEnum } from '@enums/accountingGroup';
import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  SectionTitle,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { CreateMainConduitQueryTypes } from '@models/waterObject/enums/mainConduitEnum';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import {
  useCreateAqueductWaterObjectMutation,
  useFindAllIrrigationSystemsOwnersQuery,
  useFindDictionaryByTypeIdQuery,
  useFindIrrigationSystemByIdQuery,
  useFindObjectGroupByCodeQuery,
  useGetAllBasinQuery,
  useGetAqueductListBySystemIdQuery,
  useGetSourcesQuery,
  useLazyFindAllObjectCategoriesByObjectGroupIdQuery,
} from '@store/gisproApi';
import { extractObjectTypes } from '@utils/objectType';
import dayjs from 'dayjs';
import { toInteger } from '@utils/utils';

import styles from './InformationBlock.module.scss';
import { AlertV2 } from '@features/ui-kit-v2';
import { useIrrigationFindAllAqueductsBySystemIdQuery } from '@store/api/irrigationSystemApi';
import { useGetCurrentUser } from '@features/authentication';

export const InformationBlock = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { systemId } = useParams<{ systemId: any }>();

  const [save, result] = useCreateAqueductWaterObjectMutation();

  const { data: group } = useFindObjectGroupByCodeQuery({
    code: AccountingGroupEnum.MainConduit,
  });
  const { user } = useGetCurrentUser();

  const [trigger, { data: objectCategories = [] }] =
    useLazyFindAllObjectCategoriesByObjectGroupIdQuery();

  const { data: aqueductList = [], isSuccess } =
    useIrrigationFindAllAqueductsBySystemIdQuery({
      systemId,
    });

  const { data: irrigationSystem, isLoading } =
    useFindIrrigationSystemByIdQuery({
      irrigationSystemId: systemId,
    });

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: basins = [] } = useGetAllBasinQuery();

  const { data: sources = [] } = useGetSourcesQuery({});

  const { data: owners = [], isLoading: ownersIsLoading } =
    useFindAllIrrigationSystemsOwnersQuery();

  const { data: waterObjectDirections = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.WorkCategory,
  });

  const objectCategoryId = Form.useWatch('objectCategoryId', form);

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const onFinish = useCallback(
    (values: IWaterObject) => {
      try {
        if (group) {
          save({
            waterObject: {
              ...values,
              systemId: toInteger(irrigationSystem?.systemId),
              objectGroupId: group?.id,
              dateStart: dayjs(values.dateStart).utc().format('YYYY-MM-DD'),
            },
          });
        }
      } catch (err) {
        console.error('Err: ', err);
      }
    },
    [group, irrigationSystem],
  );

  useEffect(() => {
    if (result.data) {
      const path = generatePath(
        Routes.IrrigationSystemMainConduitRegistration,
        {
          systemId,
          mainConduitId: String(result.data.objectId),
        },
      );
      navigate(
        `${path}?${SectionQueryKey}=${CreateMainConduitQueryTypes.waterSections}`,
      );
    }
  }, [result]);

  useEffect(() => {
    if (group) {
      trigger({ objectGroupId: String(group.id) });
      form.setFieldValue('objectGroupId', group.groupName);
    }
  }, [group]);

  useEffect(() => {
    form.setFieldsValue({
      regionId: user?.owner?.region?.id,
      districtId: user?.owner?.district?.id,
      source: irrigationSystem?.baseId,
      basinId: irrigationSystem?.bassinId,
      ownerId: irrigationSystem?.ownerId,
      systemId: irrigationSystem?.name,
    });
  }, [irrigationSystem]);

  useEffect(() => {
    if (!aqueductList.length && isSuccess) {
      notification.warning({
        duration: 10,
        message: 'Примечание! Вы создаете главного водовода!',
      });
    }
  }, [aqueductList]);

  if (isLoading) {
    return (
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <SectionTitle title="Паспортные данные" />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Spin size="large" />
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <SectionTitle title="Паспортные данные" />
      <Form<IWaterObject>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        className={styles.form}
      >
        <InformationalBlock>
          <CustomCard title="Данные системы">
            <Row gutter={15}>
              {/* <Col span={8}>
                <Form.Item rules={[{ required: true }]} label="ID номер">
                  <TooltipedInput size="large" placeholder="" type="number" />
                </Form.Item>
              </Col> */}

              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Код"
                  name="objectCode"
                >
                  <TooltipedInput size="large" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Наименование"
                  name="objectName"
                >
                  <TooltipedInput placeholder="" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Общ.орошаемая площадь (Га)"
                  name="totalArea"
                  normalize={(v) => String(v)}
                >
                  <TooltipedInput size="large" type="number" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Кол.свя-ых гидротех.сооружений (шт)"
                  name="numberOfStructures"
                  normalize={(v) => String(v)}
                >
                  <TooltipedInput size="large" type="number" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="objectGroupId"
                  label="Группа"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="objectCategoryId"
                  label="Категория"
                >
                  <TooltipedSelect size="large" options={objectCategories} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="typeCategory"
                  label="Тип"
                >
                  <TooltipedSelect size="large" options={objectTypes} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="appointmentId"
                  label="Назначение"
                >
                  <TooltipedSelect
                    size="large"
                    options={waterObjectDirections}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Область"
                  name="regionId"
                >
                  <TooltipedSelect
                    size="large"
                    disabled
                    options={regions}
                    bordered
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Район"
                  name="districtId"
                >
                  <TooltipedSelect
                    size="large"
                    options={districts}
                    bordered
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Бассейн"
                  name="basinId"
                >
                  <TooltipedSelect
                    size="large"
                    options={basins}
                    bordered
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  label="Источник"
                  rules={[{ required: true }]}
                  name="source"
                >
                  <TooltipedSelect
                    size="large"
                    options={sources}
                    bordered
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="systemId"
                  label="Ирригационная система"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" disabled bordered />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Владелец"
                  rules={[{ required: true }]}
                  name="ownerId"
                >
                  <TooltipedSelect
                    size="large"
                    loading={ownersIsLoading}
                    options={owners}
                    bordered
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  name="builtProject"
                  label="Построена по проекту"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" bordered />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="dateStart"
                  label="Год ввода в эксплуатацию"
                  rules={[{ required: true }]}
                >
                  <YearPicker
                    size="large"
                    bordered
                    placeholder=""
                    className={styles.input}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf('year')
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="carryingAmount"
                  label="Балансовая стоимость (сом)"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput size="large" type="number" bordered />
                </Form.Item>
              </Col>
            </Row>
          </CustomCard>
        </InformationalBlock>
        <Row justify="end" style={{ marginTop: 16 }}>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Сохранить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};
