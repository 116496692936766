import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const ResetIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 5C19.4275 4.99845 18.1285 5.34886 16.9862 6.01526C15.8439 6.68165 14.8995 7.64003 14.25 8.792V4H12.25V12H20.25V10H15.867C16.3123 9.13118 16.9811 8.39673 17.8046 7.87222C18.628 7.34771 19.5763 7.05209 20.5519 7.0158C21.5276 6.97951 22.4952 7.20384 23.3554 7.66572C24.2155 8.1276 24.937 8.81034 25.4456 9.64367C25.9543 10.477 26.2317 11.4308 26.2493 12.407C26.2669 13.3831 26.024 14.3463 25.5458 15.1974C25.0675 16.0485 24.3711 16.7568 23.5282 17.2494C22.6852 17.742 21.7263 18.0011 20.75 18H20.25V20H20.75C22.7391 20 24.6468 19.2098 26.0533 17.8033C27.4598 16.3968 28.25 14.4891 28.25 12.5C28.25 10.5109 27.4598 8.60322 26.0533 7.1967C24.6468 5.79018 22.7391 5 20.75 5Z"
        fill="currentColor"
      />
      <path
        d="M24.25 2H2.25V5.171L9.664 12.585L10.25 13.171V22H14.25V20H16.25V22C16.25 22.5304 16.0393 23.0391 15.6642 23.4142C15.2891 23.7893 14.7804 24 14.25 24H10.25C9.71957 24 9.21086 23.7893 8.83579 23.4142C8.46071 23.0391 8.25 22.5304 8.25 22V14L0.836 6.585C0.460901 6.21001 0.250113 5.70139 0.25 5.171V2C0.25 1.46957 0.460714 0.960859 0.835786 0.585786C1.21086 0.210714 1.71957 0 2.25 0H24.25V2Z"
        fill="currentColor"
      />
    </svg>
  );
};
