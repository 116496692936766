import React, { useEffect, useMemo, useState } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
  generatePath,
  Route,
} from 'react-router-dom';
import { Breadcrumb, Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import {
  CustomCard,
  DefaultButton,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import {
  useFindAllIrrigationSystemListQuery,
  useGetGmrZoneListQuery,
} from '@store/gisproApi';
import { useHydroModuleTableColumns } from './useHydroModuleTableColumns';
import { saveUrl } from '@utils/returnUrl';
import { useHydroModuleFilters } from '@features/agroClimaticZone/agroClimaticZoneCard/hydroModuleTable/useHydroModuleFilters';
import { HydroModuleTableFilters } from '@features/agroClimaticZone/agroClimaticZoneCard/hydroModuleTable/HydroModuleTableFilters/HydroModuleTableFilters';
import { useCreateHydroModuleDistrictModal } from '@features/agroClimaticZone';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const HydroModuleTable = () => {
  const { id } = useParams<{ id: any }>();
  const { columns } = useHydroModuleTableColumns();
  const navigate = useNavigate();
  const { setShow } = useCreateHydroModuleDistrictModal();
  const { filters, setHydroModuleFilters } = useHydroModuleFilters();
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersParams: any = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );

  useEffect(() => {
    setHydroModuleFilters({ ...filtersParams });
  }, []);
  const { data: gmrZones = [] } = useGetGmrZoneListQuery({
    agroclimaticzoneId: id,
    ...filters,
  });
  const saveAction = saveUrl();

  const irrigationMode = (record: JsonRecord) => {
    navigate(
      generatePath(Routes.IrrigationModeEditor, {
        id,
        hydroModuleDistrictId: String(record.gmrId),
      }),
    );
    saveAction();
  };

  const linkOutlet = (record: JsonRecord) => {
    navigate(
      generatePath(Routes.LinkedOutlet, {
        id,
        hydroModuleDistrictId: String(record.gmrId),
      }),
    );
    saveAction();
  };

  const createHydroModuleDistrict = () => {
    setShow(true);
  };

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.AgroClimaticZoneTable)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Агроклиматические зоны',
                },
                {
                  title: 'Редактор ГМР',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row
        justify="space-between"
        align="middle"
        style={{
          marginTop: 5,
          marginBottom: 10,
          paddingBottom: 10,
          borderBottom: '1px solid #00000018',
        }}
      >
        <Col span={15}>
          <HydroModuleTableFilters />
        </Col>
        <PrimaryButton onClick={createHydroModuleDistrict}>
          Создать ГМР
        </PrimaryButton>
      </Row>
      <InformationalBlock title="Реестр гидромодульных районов">
        <CustomCard
          title={
            <Row align="middle">
              <Col>
                <Typography.Title level={4}>
                  Список гидромодульных районов
                </Typography.Title>
              </Col>
            </Row>
          }
        >
          <TableCustom
            bordered
            size="small"
            columns={columns}
            dataSource={gmrZones}
            actionProps={{ irrigationMode, linkOutlet }}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
