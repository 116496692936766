import React from 'react';
import { PhysicalTableContainer } from './physicalTableContainer';
import { PhysicalTableFilters } from './physicalTableFilters';

interface IPhysicalTableProps {
  isArchived?: boolean;
}

export const PhysicalTable: React.FC<IPhysicalTableProps> = ({ isArchived = false }) => {
  return <PhysicalTableContainer isArchived={isArchived} />;
};
