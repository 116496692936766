import { TableColumn } from '@models/table/interfaces/tableColumn';
import { formatDecimal } from '@utils/formatNumber/formatNumber';

export const useGroundWaterDataTableColumns = () => {
  const columns = [
    {
      title: 'Декада',
      dataIndex: 'decade',
      key: 'decade',
      align: 'center',
      width: 135,
      editable: false,
    },
    {
      title: 'Глубина до таможной точки. см',
      dataIndex: 'depth',
      key: 'depth',
      align: 'center',
      width: 100,
      editable: true,
      children: [
        {
          title: 'До дна',
          dataIndex: 'toTheBottom',
          key: 'toTheBottom',
          align: 'center',
          editable: true,
        },
        {
          title: 'До воды',
          dataIndex: 'toTheWater',
          key: 'toTheWater',
          align: 'center',
          editable: true,
        },
        {
          title: 'Высота оголовка',
          dataIndex: 'headHeight',
          key: 'headHeight',
          align: 'center',
          editable: true,
        },
        {
          title: 'Чистый УГВ',
          dataIndex: 'pureGwv',
          key: 'pureGwv',
          align: 'center',
          editable: false,
          render: (_: any, record: any) => {
            if (record.toTheWater && record.headHeight) {
              return <div>{formatDecimal(record.toTheWater - record.headHeight)}</div>;
            }
            return <div />;
          },
        },
      ],
    },
  ];

  return { columns };
};
