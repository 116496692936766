import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BellIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7143 13.75C16.1254 13.75 15.4286 13.0725 15.4286 12.5V6.25C15.4286 2.80406 12.5444 0 9 0C5.45561 0 2.57143 2.80406 2.57143 6.25V12.5C2.57143 13.0603 1.86204 13.75 1.28571 13.75C0.575036 13.75 0 14.3091 0 15C0 15.6909 0.575036 16.25 1.28571 16.25H16.7143C17.425 16.25 18 15.6909 18 15C18 14.3091 17.425 13.75 16.7143 13.75Z"
        fill="white"
      />
      <path
        d="M9.00014 20C10.4202 20 11.5716 18.8806 11.5716 17.5H6.42871C6.42871 18.8806 7.58007 20 9.00014 20Z"
        fill="white"
      />
    </svg>
  );
};
