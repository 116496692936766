import React from 'react';

import { Col, Row } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './IrrigationSystemCardOperationalTable.module.scss';

export const useOperationalColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Категория ',
      dataIndex: 'category',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'direction',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.category.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Тип работ ',
      dataIndex: 'workType',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'workType',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.workType.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Исполнитель',
      dataIndex: 'executor',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'executor',
      renderCustom: ({ record }) => (
        <Row gutter={10}>
          <Col>{record.executor?.firstName}</Col>
          <Col>{record.executor?.middleName}</Col>
          <Col>{record.executor?.lastName}</Col>
        </Row>
      ),
    },
    {
      title: 'Объект',
      dataIndex: 'systemObject.name',
      xtype: CustomTableColumnType.String,
      key: 'systemObject.name',
    },
    {
      title: 'Система',
      dataIndex: 'system.name',
      xtype: CustomTableColumnType.String,
      key: 'system.name',
      // renderCustom: ({ record }) => (
      //   <div>
      //     <span>{record.system.name}</span>
      //   </div>
      // ),
    },
    {
      title: 'Сроки',
      align: 'left',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 250,
      xtype: CustomTableColumnType.String,
      deadlineTime: 'start',
      className: styles.deadlineColumn,
      children: [
        {
          title: 'Начало',
          dataIndex: 'start',
          key: 'start',
          deadlineTime: 'start',
          xtype: CustomTableColumnType.Deadline,
        },
        {
          title: 'Конец',
          dataIndex: 'end',
          key: 'end',
          deadlineTime: 'end',
          xtype: CustomTableColumnType.Deadline,
        },
      ],
    },
    {
      title: 'Бюджет',
      dataIndex: 'budget',
      key: 'budget',
      width: 270,
      xtype: CustomTableColumnType.Budget,
      className: styles.budgetColumn,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: '...',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
