import { type GetCellKey } from '@features/ui-kit';
import { getCellKeyValue } from '@features/ui-kit/customTable/getCellKeyValue';
import { type JsonRecord } from '@models/jsonRecord';
import { type TableColumn } from '@models/table/interfaces/tableColumn';

type PropsType<RecordType> = {
  children: RecordType[];
  keyValue: React.Key;
  column: TableColumn;
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
};

export function dataTreeFindCell<RecordType extends JsonRecord>({
  children,
  keyValue,
  column,
  cellKey,
}: PropsType<RecordType>): RecordType | null {
  const item: RecordType | null = children.reduce(
    (prev: RecordType | null, current) => {
      if (prev) {
        return prev;
      }
      if (keyValue === getCellKeyValue(current, column, cellKey)) {
        return current;
      }
      if (current.children) {
        return dataTreeFindCell({
          children: current.children,
          keyValue,
          column,
          cellKey,
        });
      }

      return null;
    },
    null,
  );

  return item;
}
