import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { Report2tpStatus } from '@pages/vodkhozReport/components';
import { formatDecimal } from '@utils/formatNumber/formatNumber';

const getClassNameBasedOnStatus = (status: string) => {
  if (status === Report2tpStatus.SUBMITTED) {
    return 'decimalBlocked';
  }
  return '';
};

export const waterWrainageColumns = (
  canEdit: boolean = false,
): TableColumn[] => [
  {
    title: 'Всего отведено вод тыс. м3',
    dataIndex: 'waterDevirted',
    xtype: CustomTableColumnType.String,
    key: 'waterDevirted',
    align: 'left',
    width: 120,
  },
  {
    title: 'Без очистки тыс. м3',
    dataIndex: 'surface',
    align: 'left',
    key: 'surface',
    xtype: CustomTableColumnType.String,
    children: [
      {
        title: 'Нормативно чистых',
        dataIndex: 'withoutCleaningStandard',
        align: 'left',
        width: 204,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) =>
          getClassNameBasedOnStatus(record.statusData),
      },
      {
        title: 'Загрязненных',
        dataIndex: 'withoutCleaningPolluted',
        align: 'left',
        width: 204,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) =>
          getClassNameBasedOnStatus(record.statusData),
      },
      {
        title: 'Всего',
        dataIndex: 'totalCleaning',
        align: 'left',
        width: 204,
        xtype: CustomTableColumnType.RenderCustom,
        renderCustom: ({ record }) => {
          return (
            (+formatDecimal(record.withoutCleaningStandard) || 0) +
            (+formatDecimal(+record.withoutCleaningPolluted) || 0)
          );
        },
      },
    ],
  },
  {
    title: 'После очистки тыс. м3',
    dataIndex: 'surface',
    align: 'left',
    key: 'surface',
    xtype: CustomTableColumnType.String,
    children: [
      {
        title: 'Биологической очисткой',
        dataIndex: 'afterCleaningBiological',
        align: 'left',
        width: 153,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) =>
          getClassNameBasedOnStatus(record.statusData),
      },
      {
        title: 'Физико-химический',
        dataIndex: 'afterCleaningPhysicalChemical',
        align: 'left',
        width: 153,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) =>
          getClassNameBasedOnStatus(record.statusData),
      },
      {
        title: 'Загрязненных',
        dataIndex: 'afterCleaningMechanical',
        align: 'left',
        width: 153,
        xtype: CustomTableColumnType.DecimalEdit,
        editableDecimalCell: canEdit,
        decimalClassName: (record) =>
          getClassNameBasedOnStatus(record.statusData),
      },
      {
        title: 'Всего',
        dataIndex: 'total',
        align: 'left',
        width: 153,
        xtype: CustomTableColumnType.RenderCustom,
        renderCustom: ({ record }) => {
          return (
            (+formatDecimal(record.afterCleaningBiological) || 0) +
            (+formatDecimal(record.afterCleaningPhysicalChemical) || 0) +
            (+formatDecimal(record.afterCleaningMechanical) || 0)
          );
        },
      },
    ],
  },
  {
    title: 'Недостаточно очищенных тыс. м3',
    dataIndex: 'notEnoughPurified',
    xtype: CustomTableColumnType.DecimalEdit,
    editableDecimalCell: canEdit,
    decimalClassName: (record) => getClassNameBasedOnStatus(record.statusData),
    key: 'insufficientlyPurified',
    align: 'left',
    width: 223,
  },
  {
    title: 'Разрешенное водоотведение тыс. м3',
    dataIndex: 'allowedDrainage',
    xtype: CustomTableColumnType.DecimalEdit,
    editableDecimalCell: canEdit,
    decimalClassName: (record) => getClassNameBasedOnStatus(record.statusData),

    key: 'permittedDrainage',
    align: 'left',
    width: 223,
  },
];

const commonColumnsNameTranslate: Record<string, string> = {
  'Поверхностные воды тыс. м3': 'surface',
  'Подземные воды тыс. м3': 'groundwater',
};

export const commonColumns = (canEdit: boolean = false): TableColumn[] =>
  ['Поверхностные воды тыс. м3', 'Подземные воды тыс. м3'].map(
    (el, index): TableColumn => {
      const useType = index === 0 ? 'surface' : 'underground';
      return {
        title: el,
        dataIndex: commonColumnsNameTranslate[el],
        align: 'left',
        key: commonColumnsNameTranslate[el],
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Потери при транспортировке тыс. м3',
            dataIndex: `${useType}TransportLosses`,
            align: 'left',
            width: 222,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: canEdit,
            decimalClassName: (record) =>
              getClassNameBasedOnStatus(record.statusData),
          },
          {
            title: 'Отведено без использования тыс. м3',
            dataIndex: `${useType}DischargedWithoutUse`,
            align: 'left',
            width: 222,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: canEdit,
            decimalClassName: (record) =>
              getClassNameBasedOnStatus(record.statusData),
          },
          {
            title: 'Сброшено в водные источники после использ. тыс. м3',
            dataIndex: `${useType}DischargedAfterUse`,
            align: 'left',
            width: 222,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: canEdit,
            decimalClassName: (record) =>
              getClassNameBasedOnStatus(record.statusData),
          },
          {
            title: 'Передано за пределы района тыс. м3',
            dataIndex: `${useType}TransferredOutside`,
            align: 'left',
            width: 222,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: canEdit,
            decimalClassName: (record) =>
              getClassNameBasedOnStatus(record.statusData),
          },
        ],
      };
    },
  );
