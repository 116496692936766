import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { CustomCard, CustomCarousel } from '@features/ui-kit';
import { attachFileUrl } from '@features/ui-kit/upload/utils/attachFileUrl';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '@store/gisproApi';

import styles from './images.module.scss';

export const Images = () => {
  const { id } = useParams();
  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );

  const attachmentsImageBefore = constructionBlock?.attachments
    .map((item: any) => {
      const res: any = [];
      if (item.type === DocumentTypes.PhotoBefore) {
        const { fileUrl } = attachFileUrl(item);
        res.push({ path: fileUrl, id: item.id });
      }
      return res;
    })
    .flat();

  const attachmentsImageAfter = constructionBlock?.attachments
    .map((item: any) => {
      const res: any = [];
      if (item.type === DocumentTypes.PhotoAfter) {
        const { fileUrl } = attachFileUrl(item);
        res.push({ path: fileUrl, id: item.id });
      }
      return res;
    })
    .flat();

  return (
    <div className={styles.container}>
      <CustomCard title="Фотографии">
        <Row gutter={64}>
          <Col span={12} className={styles.imageItem}>
            <CustomCarousel
              draggable
              detailedModal
              slidesToShow={3}
              items={attachmentsImageBefore}
            />
          </Col>
          <Col span={12}>
            <CustomCarousel
              slidesToShow={3}
              draggable
              detailedModal
              items={attachmentsImageAfter}
            />
          </Col>
        </Row>
      </CustomCard>
    </div>
  );
};
