import React, { useMemo } from 'react';

import { CardProps } from 'antd';

import {
  ImplementationTermStatusEnum,
  TaskStatusEnum,
} from '@models/manageEvents/enums/manageEventsEnum';

import styles from './CalculatedProgress.module.scss';

interface ITasksArray {
  status: ImplementationTermStatusEnum;
}

type CustomCardPropsType = CardProps & {
  tasksArray: Array<any> | undefined;
  separatePercentage?: boolean;
};

export const CalculatedProgress: React.FC<CustomCardPropsType> = ({
  tasksArray,
  separatePercentage = false,
}) => {
  const calculateProgress = useMemo(() => {
    let completedCount = 0;
    tasksArray?.forEach((item: any) => {
      if (item.status === ImplementationTermStatusEnum.Done) {
        completedCount += 1;
      }
    });

    const totalCount = tasksArray?.length || 0;
    const progress = (completedCount / totalCount) * 100;

    return Number.isNaN(progress) ? 0 : Math.floor(progress);
  }, [tasksArray]);
  return separatePercentage ? (
    <div className={styles.container}>
      <div className={styles.progressNumber}>{calculateProgress}%</div>
      <div className={styles.progress}>
        <div
          style={{
            width: `${calculateProgress}%`,
          }}
        />
      </div>
    </div>
  ) : (
    <div className={styles.progress} data-value={`${calculateProgress}%`}>
      <div
        style={{
          width: `${calculateProgress}%`,
        }}
      />
    </div>
  );
};
