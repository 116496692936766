import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Flex, Form, Row, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { normalizeValue } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/normalizeValue';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  DefaultButton,
  DocsList,
  Mask,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { OpenStreetMapV2 } from '@features/ui-kit-v2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useFindDictionaryByTypeIdQuery,
  useGetHydroFacilityAllQuery,
  useGetReferencesByParentIdAndTypeQuery,
} from '@store/gisproApi';

import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';
import { RadioGroup } from '@features/ui-kit/radioGroup';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import { MainWaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

type EconomyDetailsFormTypes = {
  name: string;
  regionId: number;
  districtId: number;
  okmotId: number;
  systemId: number;
  channelId: number;
  outletId: number;
  area: string;
  kpd: number;
  agroZoneId: number;
  additionalAddress: string;
  gisAddress: string;
  renter?: any;
};

interface EconomyDetailsProps {
  waterUserType: MainWaterUserTypes;
}

export const EconomyDetails: React.FC<EconomyDetailsProps> = ({ waterUserType }) => {
  const [isRenter, setIsRenter] = React.useState(false);
  const [defaultDocType, setDefaultDocType] = useState('');
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();
  const regionId = Form.useWatch('regionId', form);

  const irrigationSystem = Form.useWatch('systemId', form);
  const hydroFacilityId = Form.useWatch('hydroFacility', form);
  const channel = Form.useWatch('channelId', form);
  const [show, setShow] = useState(false);

  const { saveOrEditEconomy, economies, selectedEconomyIndex } =
    useAddEconomy();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });
  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });

  const { data: irrigationSystems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId ? { hydroFacilityId } : skipToken,
    );
  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    irrigationSystem ? { id: irrigationSystem } : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    channel ? { id: channel } : skipToken,
  );

  const { data: ocmotByParentId = [], isLoading: okmotIsLoading } =
    useGetReferencesByParentIdAndTypeQuery(
      user?.owner?.district?.id
        ? {
          parentId: user.owner.district.id,
          typeId: EntityTypesEnum.Okmot,
        }
        : skipToken,
    );
  const attachmentDtos =
    Form.useWatch(['renter', 'attachmentList'], form) || [];

  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue(['renter', 'attachmentList']) || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue(['renter', 'attachmentList'], updatedAttachments);
    setDefaultDocType('');
  };

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then((values: EconomyDetailsFormTypes) => {
        const economyData = {
          ...values,
          economieId: Math.random() * 10000,
        };

        if ('renter' in values) {
          economyData.renter = {
            ...values.renter,
            dateOfBirth: values.renter.dateOfBirth?.format('YYYY-MM-DD'),
          };
        }

        saveOrEditEconomy(economyData, selectedEconomyIndex, waterUserType);
        form.resetFields();
        form.setFieldsValue({
          regionId: user?.owner?.region?.id,
          districtId: user?.owner?.district?.id,
        });
      })
      .catch((e) => {
        console.log('validateErrors: ', e);
      });
  }, [selectedEconomyIndex, economies, user]);

  const changeLocale = (locale: any) => {
    form.setFieldValue('gisAddress', `${locale.lat} ${locale.lng}`);
  };

  const renterHasDocument = useMemo(() => {
    if (isRenter) {
      return !(attachmentDtos || []).some(
        (item: IAttachment) => item.type === 'документ',
      );
    }
    return false;
  }, [isRenter, attachmentDtos]);

  useEffect(() => {
    if (selectedEconomyIndex !== null && economies[waterUserType][selectedEconomyIndex]) {
      const { renter, ...restValues } = economies[waterUserType][selectedEconomyIndex];
      form.setFieldsValue({
        ...restValues,
        renter: {
          ...renter,
          dateOfBirth: dayjs(renter?.dateOfBirth),
        },
      });
    }
  }, [economies, selectedEconomyIndex]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        regionId: user.owner?.region?.id,
        districtId: user.owner?.district?.id,
      });
    }
  }, [user]);

  const onHydroFacilityChange = () => {
    form.setFieldsValue({
      systemId: undefined,
      channelId: undefined,
      outletId: undefined,
    });
  };

  const onIrrigationSystemChange = () => {
    form.setFieldsValue({
      channelId: undefined,
      outletId: undefined,
    });
  };

  const onChannelChange = () => {
    form.setFieldsValue({
      outletId: undefined,
    });
  };

  return (
    <Form<EconomyDetailsFormTypes> layout="vertical" form={form}>
      <CustomCard
        title={
          <Row justify="space-between">
            <Col span={22}>
              <Typography.Title level={4}>
                {t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.title',
                )}
              </Typography.Title>
              <Divider className={styles.divider} />
            </Col>
            <Col>
              <PrimaryButton
                disabled={renterHasDocument}
                type="primary"
                htmlType="button"
                onClick={onSave}
              >
                {t(
                  'createIndividualWaterRegistry.secondStep.economyDetails.buttons.addNewEconomy',
                )}
              </PrimaryButton>
            </Col>
          </Row>
        }
      >
        <Row justify="space-between" style={{ marginBottom: 8 }}>
          <Col>
            <RadioGroup
              defaultValue="owner"
              groupButtons={[
                {
                  label: 'Собственник',
                  value: 'owner',
                },
                {
                  label: 'Арендатор',
                  value: 'renter',
                },
              ]}
              onChange={(e) => setIsRenter(e.target.value === 'renter')}
            />
          </Col>
        </Row>
        {isRenter && (
          <>
            <CustomCard title="Данные арендодателя участка" dashed>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Фамилия"
                    name={['renter', 'lastName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput
                      placeholder="Введите фамилию"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Имя"
                    name={['renter', 'firstName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput placeholder="Введите имя" size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Отчество"
                    name={['renter', 'middleName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput
                      placeholder="Введите отчество"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="ИНН/ПИН"
                    name={['renter', 'inn']}
                    rules={[{ required: true }, { len: 14 }, numbersValidator]}
                  >
                    <TooltipedInput
                      placeholder="Введите ИНН/ПИН"
                      size="large"
                      maxLength={14}
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="№ Паспорта"
                    name={['renter', 'passportNumber']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите № Паспорта"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Дата рождения"
                    name={['renter', 'dateOfBirth']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedDatePicker
                      disabledDate={(current) =>
                        current && current > dayjs().endOf('day')
                      }
                      placeholder="Выберите дату рождения"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Пол"
                    name={['renter', 'gender']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      options={[
                        { value: 'm', label: 'Муж' },
                        { value: 'f', label: 'Жен' },
                      ]}
                      placeholder="Выберите пол"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Адрес проживания"
                    name={['renter', 'address']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите № паспорта"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" gutter={[0, 16]}>
                <Col className={styles.docsList} span={24}>
                  <Typography.Title level={4}>
                    Прикрепленные документы:
                  </Typography.Title>
                  {attachmentDtos?.length ? (
                    <DocsList documents={attachmentDtos} />
                  ) : (
                    <Mask maskText="Нет прикрепленных документов" />
                  )}
                </Col>
                <Col span={6}>
                  <Flex gap="middle">
                    <Form.Item name={['renter', 'attachmentList']}>
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        onClick={() => {
                          setDefaultDocType('паспорт');
                          setShow(true);
                        }}
                      >
                        {t(
                          'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                        )}
                      </DefaultButton>
                    </Form.Item>
                    <Form.Item name={['renter', 'attachmentList']}>
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        onClick={() => {
                          setDefaultDocType('документ');
                          setShow(true);
                        }}
                      >
                        Прикрепить документ*
                      </DefaultButton>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
            </CustomCard>
            <Divider />
          </>
        )}
        <Row gutter={16}>
          <Col span={8}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="gisAddress"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.gisAddress',
                  )}
                >
                  <TooltipedInput size="large" disabled />
                </Form.Item>
              </Col>
              <Col className={styles.openStreetMap} span={24}>
                <OpenStreetMapV2 changeLocale={changeLocale} />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.areaName',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedInput
                    placeholder="Наименование участка"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="regionId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
                  )}
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.region?.id}
                >
                  <TooltipedSelect
                    placeholder="Область"
                    size="large"
                    options={regions}
                    disabled={isDisabledRegion}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="districtId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
                  )}
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.district?.id}
                >
                  <TooltipedSelect
                    options={districts}
                    placeholder="Район"
                    disabled={isDisabledDistrict || !regionId}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="okmotId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.okmot',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    placeholder="Айыл окмоту"
                    size="large"
                    loading={okmotIsLoading}
                    options={ocmotByParentId}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="kpd"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.efficiency',
                  )}
                  rules={[{ required: true }]}
                  normalize={normalizeValue}
                >
                  <TooltipedInput
                    placeholder="КПД"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={8}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="hydroFacility"
                  label="Гидроучасток"
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    options={hydroFacilities}
                    placeholder="Гидроучасток"
                    allowClear
                    onChange={onHydroFacilityChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="systemId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.irrigationSystem',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    placeholder="Оросительная система"
                    options={irrigationSystems}
                    disabled={!hydroFacilityId}
                    allowClear
                    onChange={onIrrigationSystemChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="channelId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.channel',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    placeholder="Канал"
                    options={channels}
                    disabled={!irrigationSystem}
                    allowClear
                    onChange={onChannelChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="outletId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.waterOutlet',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    placeholder="Водовыпуск"
                    size="large"
                    disabled={!channel}
                    options={outlets}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="area"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.totalArea',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedInput
                    placeholder="Общая площадь"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomCard>
      <UploadModal
        setShow={setShow}
        show={show}
        onLoad={onAttachmentUpload}
        preSelectedDocType={defaultDocType}
      />
    </Form>
  );
};
