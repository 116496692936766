import {
  useCreatePointCollectionModal
} from '@features/waterQuality/waterQualityCard/modals/useCreatePointCollectionModal';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Table, Typography } from 'antd';

import { PrimaryButton, TableCustom, TooltipedSelect } from '@features/ui-kit';
import { PrimaryButtonV2 } from '@features/ui-kit-v2';
import { GetPointsApiResponse, useGetPointsQuery, useGetPointTypeQuery } from '@store/gisproApi';
import {
  getMetricsCollectionPointType,
  getWaterQualityCardSelector,
  getWaterQualityMonitoringTypeSelector,
} from '@store/selectors';
import { setMetricsCollectionPointType, setWaterQualityCard } from '@store/slices';

import { useWaterQualityCardSecondStepTableColumns } from './useWaterQualityCardSecondStepTableColumns';

export interface IPointData {
  id: number;
  pointName: string;
  regionId: number;
  regionName: string;
  districtId: number;
  districtName: string;
  okmotId: number;
  okmotName: string;
  type: string;
  zoneId: number | null;
}

export const WaterQualityCardSecondStepTable: React.FC = () => {
  const dispatch = useDispatch();

  const [points, setPoints] = useState<Array<GetPointsApiResponse>>([]);
  const pointType = useSelector(getMetricsCollectionPointType);
  const waterQualityCard = useSelector(getWaterQualityCardSelector);
  const { setShowModal } = useCreatePointCollectionModal();

  const monitoringType = useSelector(getWaterQualityMonitoringTypeSelector);
  const { columns } = useWaterQualityCardSecondStepTableColumns(monitoringType);
  const { data: pointTypeOptions = [] } = useGetPointTypeQuery();

  const params = {
    regionId: waterQualityCard.regionId,
    districtId: waterQualityCard.districtId,
    okmotId: waterQualityCard.okmotId,
    type: pointType.value || '',
  };

  const {
    data: content = [],
    isLoading,
    isFetching,
  } = useGetPointsQuery(params);

  const pointsData = useMemo(() => {
    return content.map((item) => ({ ...item, key: item.id }));
  }, [content]);

  const onSelectAll = (
    selected: boolean,
    selectedRows: Array<GetPointsApiResponse>,
    changeRows: Array<GetPointsApiResponse>,
  ) => {
    if (selected) {
      setPoints([...points, ...changeRows]);
    } else {
      setPoints(
        points.filter(({ id }) => !changeRows.find((el) => el.id === id)),
      );
    }
  };

  const onSelectPoints = (record: IPointData, selected: boolean) => {
    if (selected) {
      setPoints([...points, record]);
    } else {
      setPoints(points.filter((item) => item.id !== record.id));
    }
  };
  const setPointType = (value: any) => {
    dispatch(
      setMetricsCollectionPointType({ value }),
    );
  };

  const onCreateCollectionPoint = () => {
    setShowModal(true);
  };
  const addPoints = () => {
    dispatch(
      setWaterQualityCard({
        ...waterQualityCard,
        points,
      }),
    );
  };
  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Typography.Title level={4}>Точки сбора показателей</Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={20} justify="space-between">
          <Col>
            <PrimaryButton onClick={onCreateCollectionPoint}>
              Создать точку
            </PrimaryButton>
          </Col>
          <Col span={10}>
            <TooltipedSelect
              onChange={setPointType}
              options={pointTypeOptions}
              disabled={pointType.disabledValue}
              placeholder="Вид точки"
              allowClear
            />
          </Col>
          <Col>
            <PrimaryButton onClick={addPoints}>Добавить</PrimaryButton>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <TableCustom
          rowSelection={{
            type: 'checkbox',
            onSelect: onSelectPoints,
            selectedRowKeys: points.map((item: any) => item.id),
            onSelectAll,
          }}
          size="small"
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={pointsData}
          pagination={false}
          scroll={{ y: 250 }}
        />
      </Col>
    </Row>
  );
};
