import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const PipeIcon: React.FC<IconProps> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 9.75V15.75H15V14.25H12.435C11.8575 15.57 10.5375 16.5 9 16.5C7.4625 16.5 6.1425 15.57 5.565 14.25H3V15.75H1.5V9.75H3V11.25H5.5725C5.9475 10.3875 6.6375 9.6975 7.5 9.315V8.25H6V6.75H12V8.25H10.5V9.315C11.3625 9.6975 12.0525 10.3875 12.4275 11.25H15V9.75H16.5ZM12.75 1.5H5.25C4.8375 1.5 4.5 1.8375 4.5 2.25C4.5 2.6625 4.8375 3 5.25 3H7.5V3.75H8.25V6H9.75V3.75H10.5V3H12.75C13.1625 3 13.5 2.6625 13.5 2.25C13.5 1.8375 13.1625 1.5 12.75 1.5Z"
        fill="black" fillOpacity="0.6" />
    </svg>
  );
};
