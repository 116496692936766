import { useEffect } from 'react';

import { Col, Form, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomCard, TooltipedSelect } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetAllBasinQuery,
  useGetHydroFacilityAllQuery,
  useGetSourcesQuery,
} from '@store/gisproApi';
import { useGetCurrentUser } from '@features/authentication';

export const IrrigationSystemInfo = () => {
  const form = Form.useFormInstance();

  const basinId = Form.useWatch('basinId', form);
  const regionId = Form.useWatch('regionId', form);
  const districtId = Form.useWatch('districtId', form);
  const hydroFacilityId = Form.useWatch('hydroFacilityId', form);

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery(
    regionId && districtId
      ? {
          region: regionId,
          district: districtId,
        }
      : skipToken,
  );

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId
        ? {
            hydroFacilityId,
          }
        : skipToken,
    );
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionId
      ? {
          parentId: parseInt(regionId, 10),
        }
      : skipToken,
  );
  const { data: sources = [], isLoading: sourcesIsLoading } =
    useGetSourcesQuery(regionId && basinId ? { regionId, basinId } : skipToken);

  const { data: bassins = [] } = useGetAllBasinQuery(
    regionId ? { regionId } : skipToken,
  );

  useEffect(() => {
    form.setFieldValue('source', null);
    form.setFieldValue('pool', null);
    form.setFieldValue('basinId', null);
  }, [regionId]);

  const handleChangeHydroFacility = () => {
    form.resetFields(['systemId']);
  };

  return (
    <CustomCard title="Информация">
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            style={{ marginBottom: 10 }}
            label="Область"
            name="regionId"
            rules={[{ required: true }]}
            initialValue={user?.owner?.region?.id}
          >
            <TooltipedSelect
              placeholder="Область"
              size="large"
              options={regions}
              bordered
              disabled={isDisabledRegion}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            style={{ marginBottom: 10 }}
            label="Район"
            name="districtId"
            rules={[{ required: true }]}
            initialValue={user?.owner?.district?.id}
          >
            <TooltipedSelect
              placeholder="Район"
              size="large"
              options={districts}
              bordered
              disabled={isDisabledDistrict}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Бассейн"
            rules={[{ required: true }]}
            name="basinId"
            style={{ marginBottom: 10 }}
          >
            <TooltipedSelect
              placeholder="Бассейн"
              size="large"
              options={bassins}
              bordered
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Источник"
            rules={[{ required: true }]}
            name="source"
            style={{ marginBottom: 10 }}
          >
            <TooltipedSelect
              size="large"
              placeholder="Источник"
              options={sources}
              disabled={!basinId}
              bordered
              loading={sourcesIsLoading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            name="hydroFacilityId"
            label="Гидроучасток"
            required
          >
            <TooltipedSelect
              options={hydroFacilities}
              onChange={handleChangeHydroFacility}
              placeholder="Гидроучасток"
              size="large"
              disabled={!districtId && !regionId}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Наименование системы"
            rules={[{ required: true }]}
            style={{ marginBottom: 10 }}
            name="systemId"
          >
            <TooltipedSelect
              size="large"
              placeholder="Наименование системы"
              options={systems}
              disabled={!hydroFacilityId}
            />
          </Form.Item>
        </Col>
      </Row>
    </CustomCard>
  );
};
