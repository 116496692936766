import { Space } from 'antd';

import { DeclaredDataForm } from '@features/irrigationSystemCard/forms/declaredDataForm';
import { PassportForm } from '@features/irrigationSystemCard/forms/passportForm';
import { InformationalBlock } from '@features/ui-kit';

export const TotalInformationSegment: React.FC = () => {
  return (
    <InformationalBlock>
      <Space direction="vertical" style={{ width: '100%' }}>
        <PassportForm />
        <DeclaredDataForm />
      </Space>
    </InformationalBlock>
  );
};
