import React, { FC, useMemo } from 'react';
import { Col, Collapse, Row, Typography, CollapseProps } from 'antd';
import { CustomCard, TooltipedInput } from '@features/ui-kit';
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { colors } from '@theme/colors';
import { FolderIcon } from '@icons/FolderIcon';

import styles from './ReferenceBookCollapse.module.scss';
import { collapseMockData } from './referenceBook.mock';
import { LinkedIcon } from '@icons/LinkedIcon';
import { useDispatch } from 'react-redux';
import { setReferenceBookIdAction } from '@store/slices';

export const ReferenceBookCollapse: FC = () => {
  const dispatch = useDispatch();

  const setReferenceBookId = (id: string) => {
    dispatch(setReferenceBookIdAction(id));
  };

  const collapseItems = (payloads: any[]): CollapseProps['items'] => {
    return payloads?.map((item) => {
      return {
        ...item,
        key: item.id,
        collapsible: item.key === '1' ? 'disabled' : 'header',
        label: (
          <div className={styles.collapseHeader}>
            <FolderIcon />
            {item.label}
          </div>
        ),
        children: item.children.map((item: any) => {
          return (
            <button
              type="button"
              onClick={() => setReferenceBookId(item)}
              className={styles.collapseName}
            >
              <LinkedIcon />
              {item?.label}
            </button>
          );
        }),
      };
    });
  };
  const collapsedItems = useMemo(
    () => collapseItems(collapseMockData),
    [collapseMockData],
  );

  return (
    <CustomCard>
      <Row gutter={16} justify="space-between">
        <Col>
          <Typography>Список справочников</Typography>
        </Col>
        <Col>
          <TooltipedInput
            placeholder="Поиск по наименованию карты"
            prefix={
              <SearchOutlined style={{ color: colors.main_color_grey_2 }} />
            }
          />
        </Col>
      </Row>
      <div className={styles.collapse}>
        <Collapse
          items={collapsedItems}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              style={{ opacity: 0.7 }}
            />
          )}
        />
      </div>
    </CustomCard>
  );
};
