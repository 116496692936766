import { months, monthsTranslate } from '@constants/month';
import { waterUsePlanTranslate } from '@constants/waterUsePlan';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWaterUseRuvhGovSummaryColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Наименование системы',
      dataIndex: 'systemName',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },

    {
      title: 'Показатели',
      dataIndex: 'aaa',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'code',
      align: 'left',
      width: 120,
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.indicatorType === 'cumulativeTotal' ? 'нар' : 'дек'}
          </div>
        );
      },
    },
    {
      title: 'Ед. изм.',
      dataIndex: 'picket',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'type',
      align: 'left',
      width: 90,
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.indicatorType === 'volumeOfExpenses' ? 'м3/с' : 'млн.м3'}
          </div>
        );
      },
    },
    ...months.map(
      (month): TableColumn => ({
        title: month,
        dataIndex: monthsTranslate[month],
        key: monthsTranslate[month],
        xtype: CustomTableColumnType.String,
        children: [
          {
            dataIndex: 'volume',
            dynamicIndex: (record) => {
              if (record.indicatorType === 'cumulativeTotal') {
                return 'cumulativeTotal';
              } else if (record.indicatorType === 'volumeOfExpenses') {
                return 'volumeOfExpenses';
              }
              return 'volume';
            },
            title: 'I',
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-1`,
            ],
            width: 60,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: false,
          },
          {
            dataIndex: 'volume',
            dynamicIndex: (record) => {
              if (record.indicatorType === 'cumulativeTotal') {
                return 'cumulativeTotal';
              } else if (record.indicatorType === 'volumeOfExpenses') {
                return 'volumeOfExpenses';
              }
              return 'volume';
            },
            title: 'II',
            width: 60,
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-2`,
            ],
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: false,
          },
          {
            dataIndex: 'volume',
            title: 'III',
            width: 60,
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: false,
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-3`,
            ],
          },
        ],
      }),
    ),
  ];

  return { columns };
};
