import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select, SelectProps } from 'antd';

import {
  TableColumnSelect,
  TableEditorProps,
} from '@models/table/interfaces/tableColumn';

export const EditSelect: React.FC<TableEditorProps> = React.memo(
  ({ customColumn, actionProps, record }) => {
    const { t } = useTranslation();
    const { options = [] } = customColumn as TableColumnSelect & SelectProps;
    return (
      <Form.Item
        name={record.id || record.zoneId}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          onChange={(value) =>
            actionProps?.onSelectChange?.({ ...record, value })
          }
          key="value"
          options={options}
        />
      </Form.Item>
    );
  },
);
