import { JsonRecord } from '@models/jsonRecord';

export const months: string[] = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const monthsTranslate: JsonRecord = {
  'Январь': 'JANUARY',
  'Февраль': 'FEBRUARY',
  'Март': 'MARCH',
  'Апрель': 'APRIL',
  'Май': 'MAY',
  'Июнь': 'JUNE',
  'Июль': 'JULY',
  'Август': 'AUGUST',
  'Сентябрь': 'SEPTEMBER',
  'Октябрь': 'OCTOBER',
  'Ноябрь': 'NOVEMBER',
  'Декабрь': 'DECEMBER',
};

export const monthsTranslateU: JsonRecord = {
  'Январь': 'January',
  'Февраль': 'FEBRUARY',
  'Март': 'MARCH',
  'Апрель': 'APRIL',
  'Май': 'MAY',
  'Июнь': 'JUNE',
  'Июль': 'JULY',
  'Август': 'AUGUST',
  'Сентябрь': 'SEPTEMBER',
  'Октябрь': 'OCTOBER',
  'Ноябрь': 'NOVEMBER',
  'Декабрь': 'DECEMBER',
};

type MonthsObject = Record<string, string>;

export const monthsObject: MonthsObject = {
  january: 'Январь',
  february: 'Февраль',
  march: 'Март',
  april: 'Апрель',
  may: 'Май',
  june: 'Июнь',
  july: 'Июль',
  august: 'Август',
  september: 'Сентябрь',
  october: 'Октябрь',
  november: 'Ноябрь',
  december: 'Декабрь',
};

export const MonthIndex: { [key: string]: number } = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

type DecadeObjectType = Record<string, number>;

export const decadeObject: DecadeObjectType = {
  first: 1,
  second: 2,
  third: 3,
};

export const MONTH_DAYS = 30;
export const ONE_DAY = 1;
export const WEEK_DAY = 7;
