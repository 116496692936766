import { Table, Typography } from 'antd';

import { JsonRecord } from '@models/jsonRecord';

import styles from './CalculationEstimatedFinancialTable.module.scss';

interface IEstimatedFinancialPlanSummary {
  data: any;
}

export const CalculationEstimatedFinancialSummary: React.FC<
  IEstimatedFinancialPlanSummary
> = ({ data }) => {
  const sumFn = (dataArg: any): JsonRecord => {
    const sumData: JsonRecord = {
      irrigationStandardDaysCount: dataArg.reduce(
        (acc: number, el: any) => acc + el.irrigationStandardDaysCount,
        0,
      ),
      hydromoduleOrdinate: dataArg.reduce(
        (acc: number, el: any) => acc + el.hydromoduleOrdinate,
        0,
      ),
      waterSupplyVolume: dataArg.reduce(
        (acc: number, el: any) => acc + el.waterSupplyVolume,
        0,
      ),
      waterSupplyVolumeCubicMeters: dataArg.reduce(
        (acc: number, el: any) => acc + el.waterSupplyVolumeCubicMeters,
        0,
      ),
      hectaresIrrigation: dataArg?.[0]?.hectaresIrrigation || 0,
    };
    return sumData;
  };
  const sumData = sumFn(data);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell colSpan={4} index={0} align="center">
        <Typography.Text className={styles.totalLabel} strong>
          Итого по сельхозкультуре:
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <Typography.Text>
          {sumData.irrigationStandardDaysCount?.toFixed(2)}
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2}>
        <Typography.Text>
          {sumData.waterSupplyVolume?.toFixed(2)}
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3}>
        <Typography.Text>
          {sumData.waterSupplyVolumeCubicMeters?.toFixed(2)}
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4}>
        <Typography.Text>
          {sumData.hectaresIrrigation?.toFixed(2)}
        </Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
