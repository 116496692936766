import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Card, Col, Row, Space, Typography } from 'antd';

import { Links } from '@enums/links';
import { Routes } from '@enums/routes';
import {
  CustomCard,
  CustomPieChart,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { StatsCard } from '@features/ui-kit';
import { useExtraTaskEventListQuery } from '@store/gisproApi';
import { saveTableOptions } from '@store/slices';

import styles from './dashboard.module.scss';
import { CubeIcon } from '@icons/CubeIcon';
import { StatusType } from '@enums/statusType';
import { getDashboardtCardItem } from '../mappers';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { saveUrl } from '@utils/returnUrl';

export const OtherTasksCard: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useExtraTaskEventListQuery();
  const defaultValue = 0;
  const dispatch = useDispatch();

  const saveAction = saveUrl();

  const hasListPermission = useHasUserPermission(
    ACCESS.WORK_MANAGEMENT_OTHERTASK_LIST_VIEW,
  );

  const handleLinkClick = (item: string) => {
    const optionsToSend = item.trim() !== '' ? { status: item } : {};
    dispatch(saveTableOptions(optionsToSend));
  };

  const otherTaskElements = {
    byStatus: [
      {
        count: data?.status.totalRealization || defaultValue,
        label: t('manageEvents.onPerformance'),

        status: StatusType.OnControl,
        countSize: 3,
      },
      {
        count: data?.status.onRealization || defaultValue,
        label: t('manageEvents.implemented'),

        status: StatusType.OnRealization,
        countSize: 3,
      },
      {
        count: data?.status.completed || defaultValue,
        label: t('manageEvents.completedOnTime'),

        status: StatusType.Completed,
        countSize: 3,
      },
      {
        count: data?.status.expired || defaultValue,
        label: t('manageEvents.overdue'),
        status: StatusType.CompletedNotOnTime,
        countSize: 3,
      },
    ],
  };
  return (
    <div className={styles.container}>
      <InformationalBlock
        title={
          <Row justify="space-between" align="middle">
            <Row align="middle" gutter={8}>
              <Col style={{ display: 'flex' }}>
                <CubeIcon />
              </Col>
              <Col>
                <Link
                  to={`${Routes.ManageEventsSchedule}/?section=operational`}
                  className={styles.cardTitle}
                >
                  {t('otherTasks-block')}
                </Link>
              </Col>
            </Row>
            <Row gutter={8} align="middle">
              <Col>
                {hasListPermission ? (
                  <Row onClick={saveAction} gutter={8} align="middle">
                    <Col>
                      <Link
                        to={`${Routes.ManageEventsSchedule}/?section=other-tasks`}
                        onClick={() => handleLinkClick('')}
                      >
                        <PrimaryButton className={styles.btn}>
                          {t('list')}
                        </PrimaryButton>
                      </Link>
                    </Col>

                    <Col>
                      <Link
                        to={`${Routes.ManageEventsGanttDiagram}/?section=extraTask`}
                      >
                        <PrimaryButton className={styles.btn}>
                          {t('ganttChart')}
                        </PrimaryButton>
                      </Link>
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col>
                <div className={styles.taskCount}>
                  {t('totalTasks')}: {data?.totalCount}
                </div>
              </Col>
            </Row>
          </Row>
        }
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <CustomCard title="Общие данные">
              <Row justify="space-between" gutter={8}>
                <Col span={12} className={styles.col}>
                  <Space direction="vertical" className={styles.section}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          {t('byCategory')}:
                        </Typography.Text>
                      </Col>
                      <Col span={3} className={styles.countTitle}>
                        Кол-во:
                      </Col>
                    </Row>
                    <Divider withoutMargin />
                    <StatsCard
                      count={data?.type.external || defaultValue}
                      icon={Links.Mail}
                      label={t('manageEvents.incoming')}
                      countSize={3}
                    />
                    <Link
                      to={`${Routes.ManageEventsSchedule}/?section=other-tasks`}
                      onClick={() => handleLinkClick('')}
                    >
                      <StatsCard
                        count={data?.type.internal || defaultValue}
                        icon={Links.Message}
                        label={t('manageEvents.internally')}
                        countSize={3}
                      />
                    </Link>
                  </Space>
                </Col>
                <Col span={12} className={styles.col}>
                  <Space direction="vertical" className={styles.section}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          {t('byStatus')}:
                        </Typography.Text>
                      </Col>
                      <Col span={3} className={styles.countTitle}>
                        Кол-во:
                      </Col>
                    </Row>
                    <Divider withoutMargin />

                    {otherTaskElements.byStatus.map((item) => (
                      <Link
                        key={item.status}
                        to={`${Routes.ManageEventsSchedule}/?section=other-tasks`}
                        onClick={() => handleLinkClick(item.status)}
                      >
                        <Space direction="vertical" className={styles.section}>
                          {getDashboardtCardItem([item])}
                        </Space>
                      </Link>
                    ))}
                  </Space>
                </Col>
              </Row>
            </CustomCard>
          </Col>
          <Col span={24}>
            <Row gutter={8} className={styles.diagrams}>
              <Col span={12} className={styles.giagramItem}>
                <CustomCard title={t('manageEvents.inbox')}>
                  <CustomPieChart
                    data={[
                      [t('manageEvents.incoming'), data?.type.external || 0],
                      [t('manageEvents.internally'), data?.type.internal || 0],
                    ]}
                  />
                </CustomCard>
              </Col>
              <Col span={12} className={styles.giagramItem}>
                <CustomCard title={t('manageEvents.internalTasks')}>
                  <CustomPieChart
                    data={[
                      [
                        t('manageEvents.implemented'),
                        data?.status.totalRealization || 0,
                      ],
                      [
                        t('manageEvents.onPerformance'),
                        data?.status.onRealization || 0,
                      ],
                      [
                        t('manageEvents.completedOnTime'),
                        data?.status.completed || 0,
                      ],
                      [t('manageEvents.overdue'), data?.status.expired || 0],
                    ]}
                  />
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </InformationalBlock>
    </div>
  );
};
