import { Col, Row } from 'antd';

import { CustomCard, PrimaryButton } from '@features/ui-kit';

import styles from './ActionCard.module.scss';

export const ActionCard: React.FC = () => {
  return (
    <CustomCard title="Действия">
      <Row gutter={32} className={styles.actions}>
        <Col span={12}>
          <PrimaryButton>Скачать договора</PrimaryButton>
        </Col>
        <Col span={12}>
          <PrimaryButton>Годовые заявки</PrimaryButton>
        </Col>
        <Col span={12}>
          <PrimaryButton>Декадные заявки</PrimaryButton>
        </Col>
        <Col span={12}>
          <PrimaryButton>Акты и счета на оплату</PrimaryButton>
        </Col>
      </Row>
    </CustomCard>
  );
};
