import {
  DatePicker as AntdDatePicker,
  DatePickerProps,
  Tooltip,
  TooltipProps,
} from 'antd';

import styles from './YearPicker.module.scss';

type YearPickerPropTypes = DatePickerProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const YearPicker: React.FC<YearPickerPropTypes> = ({
  tooltipProps,
  ...rest
}) => {
  return (
    <Tooltip {...tooltipProps}>
      <AntdDatePicker.YearPicker className={styles.yearPicker} {...rest} />
    </Tooltip>
  );
};
