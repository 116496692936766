import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSwitch,
  TooltipedTextarea,
} from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import {
  AccessDto,
  AccessPermissionsDto,
  AccessRoleDto,
  AccessSystemModulesDto,
  useAccessControllerCreateRoleMutation,
  useAccessControllerGetRoleByIdQuery,
  useAccessControllerGetSystemModulesQuery,
  useAccessControllerUpdatePermissionsRoleMutation,
  useAccessControllerUpdateRoleMutation,
  useAccessControllerUpdateUserMutation,
} from '@store/api/accessApi';
import { Col, Form, Row, Space, Spin, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IAccessModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const AccessModal: FC<IAccessModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  console.log('initialValues', initialValues);
  const {
    data: modules = [],
    isLoading: isModulesLoading,
    isFetching: isModulesFetching,
  } = useAccessControllerGetSystemModulesQuery(
    initialValues?.parent?.roleName
      ? { role: initialValues?.parent?.roleName }
      : {},
  );
  const {
    data: permissions = [],
    isLoading: isLoadingPermissions,
    isFetching: isFetchingPermissions,
  } = useAccessControllerGetRoleByIdQuery({ id: initialValues.id });
  const [updateRole, { isError: isErrorRole, isLoading: isLoadingRole }] =
    useAccessControllerUpdatePermissionsRoleMutation();
  const [updateUser, { isError: isErrorUser }] =
    useAccessControllerUpdateUserMutation();

  const onFinish = (values: JsonRecord) => {
    const permissions: AccessDto[] = [];
    for (const key of Object.keys(values)) {
      const [module, action] = key.split(':');
      const access = values[key];
      permissions.push({ module: +module, action, access });
    }
    updateRole({ id: initialValues.id, body: permissions })
      .unwrap()
      .then(() => setShowModal(false));
    return;
  };

  const onSubmit = () => {
    const values = form.getFieldsValue();
    onFinish(values);
  };

  useEffect(() => {
    if (permissions) {
      const initialValues: any = {};
      permissions.forEach((permission: AccessPermissionsDto) => {
        permission.actions.forEach(({ module, action, access }) => {
          initialValues[`${module}:${action}`] = access;
        });
      });
      form.setFieldsValue(initialValues);
    }
  }, [permissions]);

  const permissionsList = modules.map((module: AccessSystemModulesDto) => {
    return (
      <>
        <Row gutter={8} key={module.id}>
          <Col span={8}>{module.title}</Col>
        </Row>
        {module.actions.map((action) => {
          return (
            <Row gutter={8} key={action.name}>
              <Col span={8} />
              <Col span={16}>
                <Form.Item
                  label={t(action.title)}
                  name={`${module.id}:${action.name}`}
                >
                  <TooltipedSwitch />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      </>
    );
  });

  return (
    <CustomModal
      open={isModalShow}
      title="Настройка доступа"
      footer={[
        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton onClick={onSubmit}>Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>,
      ]}
      width={1000}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Spin spinning={isLoadingRole}>
        <Form
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          {(isModulesLoading || isLoadingPermissions) && (
            <Row justify="center" style={{ minHeight: '65vh' }}>
              <Typography.Text type="secondary">Загрузка...</Typography.Text>
            </Row>
          )}
          {!isLoadingPermissions && permissionsList}
          {(isErrorRole || isErrorUser) && (
            <Row justify="center">
              <Typography.Text type="danger">
                Что-то пошло не так
              </Typography.Text>
            </Row>
          )}
        </Form>
      </Spin>
    </CustomModal>
  );
};
