import { FC, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Row, TablePaginationConfig } from 'antd';

import { Routes } from '@enums/routes';
import { TableCustom } from '@features/ui-kit';
import {
  IAgroClimaticZone,
  IAgroClimaticZoneFilters,
} from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAgroClimaticZonesQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAgroClimaticZoneTableFilters } from '../useAgroClimaticZoneTableFilters';
import { useAgroClimaticZoneTableColumns } from './useAgroClimaticZoneTableColumns';
import { saveUrl } from '@utils/returnUrl';

export const AgroClimaticZoneTableContainer: FC = () => {
  const navigate = useNavigate();
  const { columns } = useAgroClimaticZoneTableColumns();
  const { filters, setAgroClimaticZoneTableFilters } =
    useAgroClimaticZoneTableFilters();

  const saveAction = saveUrl();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetAgroClimaticZonesQuery(isEmpty(filters) ? skipToken : filters);

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setAgroClimaticZoneTableFilters({
          ...(filters as IAgroClimaticZoneFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onHandleWatch = useCallback((record: IAgroClimaticZone) => {
    navigate(
      generatePath(Routes.AgroClimaticZoneCard, { id: String(record.id) }),
    );
    saveAction();
  }, []);

  return (
    <Row>
      <TableCustom
        bordered
        size="small"
        loading={isLoading || isFetching}
        dataSource={content}
        onChange={onTableChange}
        columns={columns}
        totalCount={totalElements}
        customPagination={rest}
        actionProps={{
          onHandleWatch,
        }}
      />
    </Row>
  );
};
