import Table, { ColumnGroupType, type ColumnType } from 'antd/es/table';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps, TableCustomMethodProps } from '@models/table/interfaces/tableCustomActionProps';

type PropsType<RecordType> = {
  customColumns: TableColumn[];
  actionProps: TableCustomActionProps<RecordType>;
  methodsProps?: TableCustomMethodProps<RecordType>[];
};

export function mapCutomTableColumns<RecordType extends JsonRecord>({
  customColumns,
  actionProps,
  methodsProps,
}: PropsType<RecordType>): ColumnType<RecordType>[] {
  const columns: ColumnType<RecordType>[] = customColumns.map(
    (customColumn) => {
      if (customColumn.xtype === CustomTableColumnType.Select) {
        return Table.SELECTION_COLUMN;
      }
      if (customColumn.xtype === CustomTableColumnType.Expand) {
        return Table.EXPAND_COLUMN;
      }

      const onCell = (record: RecordType, rowIndex: number | undefined) => {
        // const isCanEditRow = actionProps.canEdit && actionProps.canEdit(record);
        const customOnCell = customColumn?.onCell?.(record, rowIndex);
        // const isDisplayNoneActionColumn =
        //   customColumn.xtype === CustomTableColumnType.ActionColumn &&
        //   !isCanEditRow &&
        //   actionProps.cannotEditDisplayNone;

        return {
          ...(customOnCell || {}),
          customColumn,
          record,
          title: customColumn.title as string,
          actionProps,
          methodsProps,
          // colSpan: !isCanEditRow
          //   ? customColumn.cannotEditRowColSpan
          //   : undefined,
        };
      };

      const onHeaderCell = (column: any) => {
        const customOnHeaderCell = customColumn?.onHeaderCell?.(column);
        return {
          ...(customOnHeaderCell || {}),
          customColumn,
          title: customColumn.title as string,
          actionProps,
          methodsProps,
        };
      };

      const column: ColumnType<RecordType> & ColumnGroupType<RecordType> = {
        align: customColumn.align,
        dataIndex: customColumn.dataIndex,
        title: customColumn.title,
        sorter: customColumn.sorter,
        filters: customColumn.filters,
        className: customColumn.className,
        onFilter: customColumn.onFilter,
        filterDropdown: customColumn.filterDropdown,
        filterIcon: customColumn.filterIcon,
        filterSearch: customColumn.filterSearch,
        defaultFilteredValue: customColumn.defaultFilteredValue,
        filteredValue: customColumn.filteredValue,
        defaultSortOrder: customColumn.defaultSortOrder,
        render: customColumn.render,
        children: mapCutomTableColumns({
          customColumns: customColumn.children ? customColumn.children : [],
          actionProps,
          methodsProps,
        }),
        onCell,
        onHeaderCell,
        fixed: customColumn.fixed,
        width: customColumn.width,
      };

      return column;
    },
  );

  return columns;
}
