import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { useGetReservoirsQuery } from '@store/gisproApi';
import {
  Col,
  Divider,
  Flex,
  Row,
  TablePaginationConfig,
  Typography,
} from 'antd';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { FC, useCallback, useMemo } from 'react';

import { useIrrigationReservoirTableColumns } from '@components/irrigationReservoirTable/useIrrigationReservoirTableColumns';
import { IrrigationReservoirTableFilters } from '@components/irrigationReservoirTable/irrigationReservoirTableFilters';

import { skipToken } from '@reduxjs/toolkit/query';

import { JsonRecord } from '@models/jsonRecord';

import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { ObjectGroupCodesEnum } from '@enums/objectGroupCodes';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { saveUrl } from '@utils/returnUrl';
interface IrrigationWaterReservoirProps {
  isArchived?: boolean;
}
export const IrrigationWaterReservoir: FC<IrrigationWaterReservoirProps> = ({
  isArchived = false,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const saveAction = saveUrl();

  const {
    data: { content = [], totalElements, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetReservoirsQuery(
    filtersParams
      ? {
          ...filtersParams,
          groupId: parseInt(ObjectGroupCodesEnum.Reservoir, 10),
          isArchive: false,
        }
      : skipToken,
  );

  const navigate = useNavigate();

  const { columns } = useIrrigationReservoirTableColumns();

  const onCreateButtonClick = () => {
    navigate(Routes.IrrigationReservoir);
    saveAction();
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        const updatedSearchParams = {
          ...filtersParams,
          page: newPagination.current.toString(),
          size: newPagination.pageSize.toString(),
        };
        setSearchParams(updatedSearchParams);
      }
    },
    [rest, columns],
  );

  const isCanCreateIrrigationSystemResorvoirs = useHasUserPermission(
    ACCESS.RESERVOIRS_CREATE,
  );

  const onHandleWatch = ({ id }: { id: number }) => {
    navigate(
      generatePath(Routes.WaterObjectCard, {
        id: String(id),
      }),
    );
    saveAction();
  };

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const onHandleGis = (record: JsonRecord) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:irrigation-system:${
        record.systemId ? record.systemId : '000'
      }`,
    });

    window.open(url, '_blank');
  };

  return (
    <>
      <Row gutter={20}>
        <Col span={17}>
          <IrrigationReservoirTableFilters />
        </Col>
        {!isArchived && isCanCreateIrrigationSystemResorvoirs && (
          <Col span={7}>
            <Flex justify="end">
              <PrimaryButton onClick={onCreateButtonClick} size="large">
                Создать водохранилище
              </PrimaryButton>
            </Flex>
          </Col>
        )}
      </Row>
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row justify="space-between">
              <Col>
                <Typography.Title level={4}>Таблица</Typography.Title>
              </Col>
            </Row>
          }
        >
          <Flex gap={11} vertical>
            <TableCustom
              dataSource={content}
              columns={columns}
              loading={isLoading || isFetching}
              bordered
              size="small"
              totalCount={totalElements}
              customPagination={rest}
              onChange={onTableChange}
              actionProps={{
                onHandleWatch,
                onArchiveClick,
                onUnArchiveClick,
                onHandleGis,
              }}
            />
          </Flex>
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
