import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';
import { getIrrigationSystemFiltersSelector } from '@store/selectors';
import { getShowWateringFiltersSelector } from '@store/selectors/wateringPlan';
import { setWateringFilters } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: IWaterUseFilters;
  setWaterUseWateringFilters: (filters: IWaterUseFilters) => void;
};

export const useWateringFilters = (): ReturnType => {
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();
  const filters = useSelector(getShowWateringFiltersSelector);

  const setWaterUseWateringFilters = useCallback(
    (newFilters: IWaterUseFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);
      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(setWateringFilters(values as IWaterUseFilters));
    },
    [],
  );

  return { filters, setWaterUseWateringFilters };
};
