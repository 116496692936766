import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { langOptions } from '@constants/langOptions';
import {
  CustomModal,
  DefaultButton,
  DocsList,
  Mask,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GeoLayersCreateDto,
  useGeoLayersControllerCreateMutation,
  useGeoLayersControllerFindByIdQuery,
  useGeoLayersControllerUpdateMutation,
} from '@store/api/geoLayersApi';
import {
  InstructionsCreateDto,
  useInstructionsControllerCreateMutation,
  useInstructionsControllerUpdateMutation,
} from '@store/api/instructionsApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';
import { Button, Col, Form, Row, Space, Typography, notification } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styles from './GeoPortalManagement.module.scss';
interface IGeoPortalManagementTableModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const GeoPortalManagementTableModal: FC<
  IGeoPortalManagementTableModalProps
> = ({ setShowModal, isModalShow, initialValues }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const { data, refetch } = useGeoLayersControllerFindByIdQuery(
    initialValues?.id
      ? {
          id: initialValues?.id,
        }
      : skipToken,
  );
  const [createGeoLayers, { isError }] = useGeoLayersControllerCreateMutation();
  const [updateGeoLayers, { isError: isErrorUpdate }] =
    useGeoLayersControllerUpdateMutation();

  const [params] = useSearchParams();

  const filtersParams = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const onFinish = (values: GeoLayersCreateDto) => {
    console.log('values', values);
    if (initialValues) {
      updateGeoLayers({
        id: initialValues.id,
        geoLayersCreateDto: { ...values },
      })
        .then(() => {
          setShowModal(false);
          notification.success({
            message: 'WMS сервис успешно обновлен',
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Произошла ошибка',
          });
        });
      return;
    }
    createGeoLayers({
      geoLayersCreateDto: { ...values },
    })
      .then(() => {
        setShowModal(false);
        notification.success({
          message: 'WMS сервис успешно создан',
        });
      })
      .catch((error) => {
        notification.error({
          message: 'Произошла ошибка',
        });
      });
  };

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return (
    <CustomModal
      open={isModalShow}
      title={initialValues?.id ? 'Изменить' : 'Создать'}
      footer={
        <Row gutter={8} justify="end">
          <Col span={7}>
            <DefaultButton onClick={onCancelButtonClick}>Отмена</DefaultButton>
          </Col>
          <Col span={7}>
            <PrimaryButton onClick={form.submit}>Сохранить</PrimaryButton>
          </Col>
        </Row>
      }
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Заголовок"
              initialValue={filtersParams.lang}
              name="title"
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Введите заголовок" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="URL"
              initialValue={filtersParams.lang}
              name="sourceUrl"
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Введите заголовок" />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24}>
          <Form.List name="maps">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row gutter={8} align="middle" className={styles.row}>
                    <Col span={22}>
                      <Form.Item
                        label={index === 0 ? 'Карты' : ''}
                        {...restField}
                        name={index}
                        rules={[
                          {
                            required: true,
                            message: 'Пожалуйста, введите Карты',
                          },
                        ]}
                      >
                        <TooltipedInput placeholder="Карта" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        className={
                          index === 0
                            ? styles.firstDeleteButton
                            : styles.deleteButton
                        }
                        onClick={() => remove(name)}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <DefaultButton
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить карту
                  </DefaultButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
