export const referenceMaterialsCategoryModal = () => {
  return;
};
import { CustomModal, DefaultButton, PrimaryButton, TooltipedInput, } from '@features/ui-kit';
import {
  useMaterialsCategoryControllerCreateMutation,
  useMaterialsCategoryControllerUpdateMutation,
} from '@store/api/materialsApi';
import { Col, Form, notification, Row, Space, Typography } from 'antd';
import { FC } from 'react';

interface IReferenceMaterialCategoryModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const ReferenceMaterialCategoryModal: FC<
  IReferenceMaterialCategoryModalProps
> = ({ setShowModal, isModalShow, initialValues }) => {
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const [createMaterialsCategory, { isError }] =
    useMaterialsCategoryControllerCreateMutation();
  const [updateMaterialsCategory, { isError: isErrorUpdate }] =
    useMaterialsCategoryControllerUpdateMutation();

  const onFinish = (values: any) => {
    if (initialValues) {
      updateMaterialsCategory({
        id: initialValues.id,
        materialsCategoryCreateDto: values,
      })
        .unwrap()
        .then(() => {
          setShowModal(false);
          notification.success({
            message: 'Категория успешно обновлена',
          });
        })
        .catch(({ data }) => {
          notification.error({
            message: data?.message ?? 'Произошла ошибка',
          });
        });
      return;
    }
    createMaterialsCategory({ materialsCategoryCreateDto: values })
      .unwrap()
      .then(() => {
        setShowModal(false);
        notification.success({
          message: 'Категория успешно создана',
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Произошла ошибка',
        });
      });
  };

  return (
    <CustomModal
      open={isModalShow}
      title={initialValues?.id ? 'Изменить' : 'Создать'}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Название"
              name="title"
              rules={[{ required: true }]}
            >
              <TooltipedInput/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
