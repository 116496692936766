import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const SmallArrowIcon: React.FC<IconProps> = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 4.5L3.25 7.9641L3.25 1.0359L8.5 4.5Z" fill="black" />
    </svg>
  );
};
