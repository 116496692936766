import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsPumpComponent: FC = () => {
  const { data: managementMethods = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.ControlMethodTypes,
    });

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <CustomCard title="Системные показатели">
            <Row gutter={[16, 16]}>
              <Col span={18}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item name={['status', 'powerLSec']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Мощность (л/с)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name={['status', 'pressureBar']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Напор (бар)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name={['status', 'diameterD']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Диаметр, м"
                          type="input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
              <Col span={6}>
                <CustomCard title={null} dashed>
                  <Form.Item name="direction">
                    <PropertyBlock
                      icon={ConstructionIcon}
                      label="Направление"
                      type="radio"
                    />
                  </Form.Item>
                </CustomCard>
              </Col>
              <Col span={18}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['status', 'managementMethodsId']}
                        label="Методы управления"
                      >
                        <TooltipedSelect
                          options={managementMethods}
                          size="large"
                          placeholder="Выберите метод"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['status', 'numberOfPumps']}
                        label="Количество насосов (шт)"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>

              <Col span={6}>
                <CustomCard title={null} dashed>
                  <Form.Item
                    name={['status', 'maximumWorkingPressure']}
                    label="Максимально рабочее давление"
                  >
                    <TooltipedInput size="large" placeholder="Введите данные" />
                  </Form.Item>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
