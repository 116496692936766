import { useEffect, useState } from 'react';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import {
  ICropSowingPlan,
  ICropSowingPlanByGuvh,
  IWaterUsePlanDataDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetCropSowingPlanByCaQuery,
  useGetWaterUseCropSowingPlanByGuvhQuery,
  useGetWaterUseCropSowingPlanQuery,
} from '@store/gisproApi';

export type ReturnType = {
  data: ICropSowingPlan | ICropSowingPlanByGuvh;
  isLoading: boolean;
};

export const useCropSowingDataQuery = (roleName: UserRoles): ReturnType => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: cropSowingPlanByCa, isLoading: waterUsePlanByCALoading } =
    useGetCropSowingPlanByCaQuery(
      roleName === UserRoles.ROLE_CA ? {} : skipToken,
    );

  const { data: cropSowingPlanByGuvh, isLoading: waterUsePlanByGuvhLoading } =
    useGetWaterUseCropSowingPlanByGuvhQuery(
      roleName === UserRoles.ROLE_GUVH ? {} : skipToken,
    );

  const { data: cropSowingPlanByRuvh, isLoading: waterUsePlanByRuvhLoading } =
    useGetWaterUseCropSowingPlanQuery(
      roleName === UserRoles.ROLE_RUVH ? undefined : skipToken,
    );

  useEffect(() => {
    switch (roleName) {
      case UserRoles.ROLE_RUVH:
        setData(cropSowingPlanByRuvh);
        setIsLoading(waterUsePlanByRuvhLoading);
        break;
      case UserRoles.ROLE_GUVH:
        setData(cropSowingPlanByGuvh);
        setIsLoading(waterUsePlanByGuvhLoading);
        break;
      case UserRoles.ROLE_CA:
        setData(cropSowingPlanByCa);
        setIsLoading(waterUsePlanByCALoading);
        break;
      default:
        break;
    }
  }, [
    roleName,
    cropSowingPlanByCa,
    waterUsePlanByCALoading,
    cropSowingPlanByRuvh,
    waterUsePlanByGuvhLoading,
    cropSowingPlanByGuvh,
    waterUsePlanByRuvhLoading,
  ]);

  return { data, isLoading };
};
