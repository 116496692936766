import { Card, Col, Empty, Row, Space, Table } from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';

import { months, monthsTranslate } from '@constants/month';
import { IPlantingDataCalculatedDto } from '@models/annualApplication/interfaces/annualApplication';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEconomicPlanQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useBusinessPlanTableFilters } from '../useBusinessPlanTableFilters';
import { BusinessPlanSummeryData } from './BusinessPlanSummeryData';
import styles from './BusinessPlanTableContainer.module.scss';
import { mapBusinessPlanData } from './mapBusinessPlanData';

export const BusinessPlanTableContainer: React.FC = () => {
  const { filters } = useBusinessPlanTableFilters();

  const { data: planData } = useEconomicPlanQuery(
    filters.facilityId
      ? {
          facilityId: filters.facilityId,
        }
      : skipToken,
  );

  const transformData = !isEmpty(planData) && mapBusinessPlanData(planData);

  return (
    <Space size="large" direction="vertical" style={{ display: 'flex' }}>
      {planData && (
        <Row>
          <Card>
            <Col span={24}>Хозяйство: {planData.facilityName}</Col>
            <Col span={24}>
              <Row gutter={15}>
                <Col>Водовыпуск: {planData.outletName}</Col>
                <Col>Канал: {planData.channelName}</Col>
                <Col>Орос. система: {planData.systemName}</Col>
              </Row>
            </Col>
            <Col span={24}>
              {(planData.plantingDataCalculatedDtos || []).map(
                (plantingData: IPlantingDataCalculatedDto) => (
                  <Row key={plantingData.foodPlantId} gutter={15}>
                    <Col>{plantingData.name},</Col>
                    <Col>Площадь: {plantingData.area},</Col>
                    <Col>КТП: {plantingData.kpd},</Col>
                    <Col>КВХ: {plantingData.kvh},</Col>
                  </Row>
                ),
              )}
            </Col>
          </Card>
        </Row>
      )}
      <Table
        pagination={false}
        scroll={{ x: 3500 }}
        bordered
        dataSource={transformData}
        size="small"
        className={styles.table}
        // eslint-disable-next-line react/no-unstable-nested-components
        summary={(sumData) => <BusinessPlanSummeryData data={sumData} />}
      >
        <Column
          title="Номер полива"
          width={250}
          dataIndex={['wateringTypeName']}
          align="center"
        />
        <ColumnGroup title="Сроки полива">
          <ColumnGroup align="center" title="От">
            <Column width={250} dataIndex={['startDate']} align="center" />
          </ColumnGroup>
          <ColumnGroup align="center" title="До">
            <Column width={250} dataIndex={['endDate']} align="center" />
          </ColumnGroup>
        </ColumnGroup>
        <Column
          title="Норма полива"
          width={250}
          dataIndex={['irrigationStandard']}
          align="center"
        />
        <Column
          title="Пол. пер. сут."
          width={250}
          dataIndex={['days']}
          align="center"
        />
        <ColumnGroup title="Сум. водопотреб. за вегетац. период">
          <ColumnGroup align="center" title="Тыс м3">
            <Column
              width={250}
              dataIndex="wateringVolumeTotal"
              align="center"
            />
          </ColumnGroup>
          <ColumnGroup align="center" title="л/сек">
            <Column
              width={250}
              dataIndex="wateringVolumeTotal"
              align="center"
            />
          </ColumnGroup>
        </ColumnGroup>
        <Column
          title="Сут. зад. полива."
          width={250}
          dataIndex={['days']}
          align="center"
        />
        <Column
          title="Ср.дин"
          width={250}
          dataIndex={['days']}
          align="center"
        />
        <>
          {months.map((month) => (
            <ColumnGroup title={month} key={month}>
              <Column
                title="I"
                dataIndex={['months', `${monthsTranslate[month]}`, 'decade1']}
                align="center"
                width={250}
              />
              <Column
                title="II"
                dataIndex={['months', `${monthsTranslate[month]}`, 'decade2']}
                align="center"
                width={250}
              />
              <Column
                title="III"
                dataIndex={['months', `${monthsTranslate[month]}`, 'decade3']}
                align="center"
                width={250}
              />
            </ColumnGroup>
          ))}
        </>
      </Table>
    </Space>
  );
};
