import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useDocumentTableColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'attachmentId',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'id',
      align: 'center',
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'name',
      align: 'center',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      xtype: CustomTableColumnType.String,
      key: 'id',
      align: 'center',
    },
    {
      title: 'Формат',
      dataIndex: 'extension',
      xtype: CustomTableColumnType.String,
      key: 'format',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText, CustomTableAction.DeleteWithoutText],
      key: 'actions',
      align: 'center',
    },
  ];

  return { columns };
};
