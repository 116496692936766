import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import {
  CustomCard,
  PicketInput,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import {
  useFindAllObjectGroupsQuery,
  useLazyFindAllObjectCategoriesByObjectGroupIdQuery,
} from '@store/gisproApi';
import { getCreateWaterObjectList } from '@store/selectors';
import { addWaterObjectToCreateWaterObjectListAction } from '@store/slices';
import { extractObjectTypes, getIsLinearObject } from '@utils/objectType';
import {
  longPicketInputValidator,
  shortPicketInputValidator,
} from '@utils/validation/picketInputValidator';
import dayjs from 'dayjs';
import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { ObjectGroupCodesEnum } from '@enums/objectGroupCodes';

export const WaterObjectInformationReservoir = () => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const waterObjects: Array<Partial<IWaterObject>> = useSelector(
    getCreateWaterObjectList,
  );

  const objectGroupId = Form.useWatch('objectGroupId', form);
  const objectCategoryId = Form.useWatch('objectCategoryId', form);

  const { data: groups, isLoading: groupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const [
    trigger,
    { data: objectCategories = [], isLoading: objectCategoriesIsLoading },
  ] = useLazyFindAllObjectCategoriesByObjectGroupIdQuery();

  const isLinearObject = useMemo(
    () => getIsLinearObject(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const objectGroups = useMemo(() => {
    return groups?.filter(
      (item: IObjectGroup) =>
        item.code === parseInt(ObjectGroupCodesEnum.Reservoir, 10),
    );
  }, [groups]);

  useEffect(() => {
    if (objectGroupId) {
      trigger({ objectGroupId: String(objectGroupId) });
    }
  }, [objectGroupId]);

  const onGroupChange = () => {
    form.resetFields(['objectCategoryId']);
  };

  const onCategoryChange = () => {
    form.resetFields(['typeCategory']);
  };

  return (
    <CustomCard title="Ввод данных">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Наименование водного объекта"
            name="objectName"
            rules={[{ required: true }]}
          >
            <TooltipedInput size="large" placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Год ввода в эксплуатцию"
            rules={[{ required: true }]}
            name="dateStart"
          >
            <YearPicker
              style={{ width: '100%' }}
              size="large"
              placeholder=""
              disabledDate={(current) =>
                current && current > dayjs().endOf('year')
              }
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Группа"
            name="objectGroupId"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              options={objectGroups}
              loading={groupsIsLoading}
              placeholder=""
              onChange={onGroupChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Категория"
            name="objectCategoryId"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              options={objectCategories}
              disabled={!objectGroupId}
              loading={objectCategoriesIsLoading}
              placeholder=""
              onChange={onCategoryChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Тип"
            name="typeCategory"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              disabled={!objectCategoryId}
              options={objectTypes}
              placeholder=""
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Код объекта"
            rules={[{ required: true }]}
            name="objectCode"
          >
            <TooltipedInput size="large" placeholder="" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="coordinates"
            label={
              isLinearObject ? 'Протяженность в пикетах' : 'Пикетная координата'
            }
            rules={[
              { required: true },
              isLinearObject
                ? longPicketInputValidator
                : shortPicketInputValidator,
            ]}
          >
            <PicketInput size="large" isLinear={isLinearObject} />
          </Form.Item>
        </Col>

        {isLinearObject && (
          <Col span={12}>
            <Form.Item required name="lengthKm" label="Протяженность в КМ">
              <TooltipedInput size="large" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </CustomCard>
  );
};
