import { Button, ButtonProps } from 'antd';

import styles from './buttons.module.scss';

type DefaultButtonV2PropTypes = ButtonProps & {
  title: string;
};

export const DefaultButtonV2: React.FC<DefaultButtonV2PropTypes> = ({
  title,
  ...rest
}) => {
  return (
    <Button type="default" className={styles.defaultButton} {...rest}>
      {title}
    </Button>
  );
};
