import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHeaderTitleSelector } from '@store/selectors/header';
import { setHeaderTitleAction } from '@store/slices/header';

type ReturnType = {
  headerTitle: string;
  setHeaderTitle: (title: string) => void;
};

export const useHeaderTitle = (): ReturnType => {
  const dispatch = useDispatch();
  const headerTitle = useSelector(getHeaderTitleSelector);

  const setHeaderTitle = useCallback((title: string) => {
    dispatch(setHeaderTitleAction(title));
  }, []);

  return {
    headerTitle,
    setHeaderTitle,
  };
};
