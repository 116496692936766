import { isEmpty, isInteger, pickBy } from '@utils/utils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IAnnualPlanFilters } from '@models/annualPlan/enums/AnnualPlan';
import { getAnnualPlanFiltersSelector } from '@store/selectors/annualPlan/annualPlan';
import { changeAnnualPlanFilters } from '@store/slices';

type ReturnType = {
  filters: Partial<IAnnualPlanFilters>;
  setAnnualPlanFilters: (filters: IAnnualPlanFilters) => void;
};

export const useAnnualPlanQuarterlyTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getAnnualPlanFiltersSelector);

  const setAnnualPlanFilters = useCallback((newFilters: IAnnualPlanFilters) => {
    Object.entries(newFilters).forEach(([key, value]: any) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    setParams(params);

    const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));
    dispatch(changeAnnualPlanFilters(values as IAnnualPlanFilters));
  }, []);

  return { filters, setAnnualPlanFilters };
};
