import styles from './ReservoirMonitoringMask.module.scss';

type ReservoirMonitoringMaskTypes = {
  maskText: string;
};

export const ReservoirMonitoringMask: React.FC<
  ReservoirMonitoringMaskTypes
> = ({ maskText }) => {
  return (
    <div className={styles.mask}>
      <p>{maskText}</p>
    </div>
  );
};
