import { FC } from 'react';

import { WaterUserCardLayout } from '@features/layout/waterUser';

export const WaterUserCardPage: FC = () => {
  return (
    <div>
      <WaterUserCardLayout />
    </div>
  );
};
