import {
  ICropSowingPlan,
  ICropSowingPlanByDistrict,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { addRowIndexies } from '@utils/addRowIndexies/addRowIndexies';
import { set, toInteger } from '@utils/utils';

import { actualSowings } from './useCropSowingIrragatedLandsColumns';

export const cropSowingOnIrrigatedLandsDataMapper = (
  obj?: ICropSowingPlan | ICropSowingPlanByDistrict,
) => {
  if (!obj) return undefined;

  const { bySystemDtos, totalsBySystemType, totals } = obj;
  const districtId = 'districtId' in obj ? obj.districtId : '';
  const districtName = 'districtName' in obj ? obj.districtName : '';

  const systemsData = bySystemDtos?.reduce((acc: any[], el, index) => {
    const { systemTypeName, systemTypeId, systems } = el;
    const totalBySystem = totalsBySystemType?.find(
      (totalSystem) => totalSystem.systemTypeId === systemTypeId,
    );
    const allTotals = {
      systemTypeName: 'Итого по району',
      planItemDtos: totals[0],
      systemTypeId: `total${index + 1}`,
      systemId: `total${index + 2}`,
      allTotalLand: 0,
      totalIrrigatedLand: 0,
      totalIrrigatedArableLand: 0,
    };
    const total = {
      systemTypeName: `Итого "${systemTypeName}"`,
      planItemDtos: totalBySystem?.planItemDtos,
      systemTypeId: `total${index}`,
      systemId: `total${index}`,
      allTotalLand: 0,
      totalIrrigatedLand: 0,
      totalIrrigatedArableLand: 0,
    };
    const mutatedSystems = systems.map((s) => {
      const planItem = s.planItemDtos[0];

      const totalIrrigatedLand =
        toInteger(planItem.gardensAndVineyards) +
        toInteger(planItem.personalPlots) +
        toInteger(planItem.otherIrrigationLands);

      const totalIrrigatedArableLand = actualSowings.reduce(
        (acc1: number, sowing) =>
          acc1 + toInteger(planItem[sowing.value as keyof typeof planItem]),
        0,
      );
      allTotals.allTotalLand += totalIrrigatedLand + totalIrrigatedArableLand;
      allTotals.totalIrrigatedLand += totalIrrigatedLand;
      allTotals.totalIrrigatedArableLand += totalIrrigatedArableLand;
      total.allTotalLand += totalIrrigatedLand + totalIrrigatedArableLand;
      total.totalIrrigatedLand += totalIrrigatedLand;
      total.totalIrrigatedArableLand += totalIrrigatedArableLand;
      return {
        ...s,
        districtId,
        districtName,
        planItemDtos: planItem,
        totalIrrigatedLand,
        totalIrrigatedArableLand,
        allTotalLand: totalIrrigatedLand + totalIrrigatedArableLand,
      };
    });
    if ('districtId' in obj) {
      set(total, 'districtId', obj.districtId);
      set(total, 'districtName', obj.districtName);
      set(allTotals, 'districtId', obj.districtId);
      set(allTotals, 'districtName', obj.districtName);
    }
    const allSystems = [...acc, ...mutatedSystems, total, allTotals];

    return allSystems;
  }, []);
  return systemsData;
};
