import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select, SelectProps, Tag } from 'antd';

import {
  TableColumnSelectTag,
  TableEditorProps,
} from '@models/table/interfaces/tableColumn';

export const EditSelectTag: React.FC<TableEditorProps> = React.memo(
  ({ customColumn }) => {
    const { t } = useTranslation();
    const { options = [] } = customColumn as TableColumnSelectTag & SelectProps;

    return (
      <Form.Item
        name={customColumn.dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message:
              t('customComponents.table.cellEditor.selectValue', {
                value: customColumn.title,
              }) || '',
          },
        ]}
      >
        <Select>
          {options.map((option) => (
            <Select.Option key={String(option.value)} value={option.value}>
              <Tag color={option.color}>{option.label}</Tag>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
