import { getFormData } from '@store/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  DangerButton,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import {
  CreateIndividualWaterRegistryQueryTypes,
  MainWaterUserTypes,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import {
  clearEconomiesAction,
  clearFormDataAction,
  setFormData,
} from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { EconomiesTable, EconomyDetails } from '../fields';
import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';

type IndividualMasterRegistrationSecondStepFormTypes = any;

export const IndividualMasterRegistrationSecondStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { economies } = useAddEconomy();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);

  const onFinish = () => {
    dispatch(
      setFormData({
        data: {
          facilities: economies.physical,
        },
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationIndividual}?${SectionQueryKey}=${CreateIndividualWaterRegistryQueryTypes.agreement}`,
    );
  };

  const onFinishFailed = (
    err: ValidateErrorEntity<IndividualMasterRegistrationSecondStepFormTypes>,
  ) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
    dispatch(clearEconomiesAction());
    navigate(Routes.WaterRegistryUsers);
  };

  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationIndividual}?${SectionQueryKey}=${CreateIndividualWaterRegistryQueryTypes.waterUserInfo}`,
    );
  };

  if (!currentWaterRegistry?.physical?.district) {
    onBackButtonClick();
  }

  return (
    <Form<IndividualMasterRegistrationSecondStepFormTypes>
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <InformationalBlock title="Создание хозяйства">
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <EconomyDetails waterUserType={MainWaterUserTypes.Physical} />
              </Col>
              <Col span={24}>
                <EconomiesTable
                  waterUserType={MainWaterUserTypes.Physical}
                  economies={economies.physical}
                />
              </Col>
            </Row>
          </InformationalBlock>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <DangerButton
                htmlType="submit"
                size="large"
                onClick={onCancelButtonClick}
              >
                {t('createIndividualWaterRegistry.buttons.cancel')}
              </DangerButton>
            </Col>

            <Col span={4}>
              <Row gutter={16} justify="end">
                <Col>
                  <DefaultButton
                    type="default"
                    htmlType="submit"
                    onClick={onBackButtonClick}
                    size="large"
                  >
                    {t('createIndividualWaterRegistry.buttons.back')}
                  </DefaultButton>
                </Col>
                <Col>
                  <PrimaryButton
                    type="primary"
                    htmlType="submit"
                    disabled={!economies.physical.length}
                    size="large"
                  >
                    {t('createLegalWaterRegistry.buttons.next')}
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
