import { FC, useCallback, useEffect, useMemo } from 'react';

import { Col, Form, Row } from 'antd';

import {
  CustomModalV2,
  PrimaryButtonV2,
  TooltipedInputV2,
  TooltipedSelectV2,
  TooltipedTextareaV2,
} from '@features/ui-kit-v2';
import { ISourceType } from '@models/source/interfaces/source';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateSourceMutation,
  useGetAllSourceTypesQuery,
  useGetLakesQuery,
  useGetNaturalSourcesQuery,
  useGetOthersQuery,
  useGetRiversQuery,
} from '@store/gisproApi';

import { useCreateSourceModal } from './useCreateSourceModal';

const sourcelabels = {
  other: 'Другие',
  river: 'Река',
  lake: 'Озеро',
};

export const CreateSourceModal: FC = () => {
  const [form] = Form.useForm();
  const { isModalShown, selectedBassinId, selectedRegionId, setShowModal } =
    useCreateSourceModal();

  const [save] = useCreateSourceMutation();

  const typeId: number = Form.useWatch('typeId', form);

  const { data: sourceTypes = [], isLoading: sourceTypesIsLoading } =
    useGetAllSourceTypesQuery();

  const onFinish = useCallback(
    (values: { name: string; description: string; sourceId: number }) => {
      save({
        name: values.name,
        description: values.description,
        sourceId: values.sourceId,
      }).then(() => {
        setShowModal(false);
      });
    },
    [selectedBassinId, selectedRegionId],
  );

  const onSourceTypeChange = () => {
    form.setFieldValue('sourceId', null);
  };

  const selectedSourceType = useMemo<string>(
    () =>
      sourceTypes.find((item: ISourceType) => item.id === typeId)
        ?.description || 'river',
    [typeId, sourceTypes],
  );

  const { data: other } = useGetOthersQuery(
    selectedSourceType === 'other' && selectedRegionId && selectedBassinId
      ? { regionId: selectedRegionId, basinId: selectedBassinId }
      : skipToken,
  );

  const { data: river } = useGetRiversQuery(
    selectedSourceType === 'river' && selectedRegionId && selectedBassinId
      ? { regionId: selectedRegionId, basinId: selectedBassinId }
      : skipToken,
  );

  const { data: lake } = useGetLakesQuery(
    selectedSourceType === 'lake' && selectedRegionId && selectedBassinId
      ? { regionId: selectedRegionId, basinId: selectedBassinId }
      : skipToken,
  );

  const naturalSourcesOptions = useMemo(() => {
    const sourceData: any = {
      other,
      river,
      lake,
    };
    return (
      sourceData[selectedSourceType]?.map((item: ISourceType) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  }, [selectedSourceType, other, river, lake]);

  useEffect(() => {
    form.resetFields();
  }, [isModalShown]);

  return (
    <CustomModalV2
      open={isModalShown}
      title="Создание источника"
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              name="name"
              label="Наименование источника"
            >
              <TooltipedInputV2 />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              name="typeId"
              label="Тип источника"
            >
              <TooltipedSelectV2
                onChange={onSourceTypeChange}
                loading={sourceTypesIsLoading}
                options={sourceTypes}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              name="sourceId"
              label={(sourcelabels as any)[selectedSourceType]}
            >
              <TooltipedSelectV2
                options={naturalSourcesOptions}
                disabled={!typeId}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              name="description"
              label="Описание источника"
            >
              <TooltipedTextareaV2 rows={2} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <PrimaryButtonV2 htmlType="submit" title="Сохранить" />
          </Col>
        </Row>
      </Form>
    </CustomModalV2>
  );
};
