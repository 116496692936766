import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import { useGetReservoirsQuery } from '@store/gisproApi';
import { Col, Row, TablePaginationConfig, Typography } from 'antd';
import { useReservoirListTableColumns } from './useReservoirListTableColumns';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import { useCallback, useMemo, useState } from 'react';
import { ReservoirCreateModal } from '../reservoirCreateModal/ReservoirCreateModal';
import { ISystemObject } from '@models/waterObject/interfaces/waterObject';
import { saveUrl } from '@utils/returnUrl';

interface IReservoirListTableProps {
  isArchived?: boolean;
  title?: string;
}

export const ReservoirListTable: React.FC<IReservoirListTableProps> = ({
  isArchived = false,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const saveAction = saveUrl();

  const {
    data: { content: reservoirs = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetReservoirsQuery(filtersParams);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { columns } = useReservoirListTableColumns(isArchived);

  const clickGoto = useCallback((val: ISystemObject) => {
    navigate(
      generatePath(Routes.ReservoirMonitoring, {
        id: String(val.id),
      }),
    );
    saveAction();
  }, []);

  const onHandleWatch = ({ id }: { id: number }) => {
    navigate(
      generatePath(Routes.WaterObjectCard, {
        id: String(id),
      }),
    );
    saveAction();
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        const updatedSearchParams = {
          ...filtersParams,
          page: newPagination.current.toString(),
          size: newPagination.pageSize.toString(),
        };
        setSearchParams(updatedSearchParams);
      }
    },
    [rest, columns],
  );

  return (
    <InformationalBlock>
      <CustomCard
        style={{ padding: '8px 16px' }}
        title={
          <Row justify="space-between">
            <Col>
              <Typography.Title level={4}>Таблица</Typography.Title>
            </Col>
          </Row>
        }
      >
        <TableCustom
          dataSource={reservoirs}
          columns={columns}
          loading={isLoading || isFetching}
          bordered
          size="small"
          totalCount={totalElements}
          customPagination={rest}
          onChange={onTableChange}
          actionProps={{
            onHandleEdit: (val) => clickGoto(val),
            onHandleWatch: (val) => onHandleWatch(val),
          }}
        />
      </CustomCard>
      <ReservoirCreateModal showModal={showModal} setShowModal={setShowModal} />
    </InformationalBlock>
  );
};
