import { createSelector } from '@reduxjs/toolkit';
import { WaterUsePlanState } from '@store/slices/waterUsePlan';

export const waterUsePlanStateSelector = (state: {
  waterUsePlan: WaterUsePlanState;
}): WaterUsePlanState => state.waterUsePlan;

export const getPrintSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowPrintModal;
  },
);

export const getRuvhTablesInfoSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.ruvhTablesInfo;
  },
);

export const getRuvhStatusSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.ruvhStatus;
  },
);

export const getCaFiltersSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.caFilters;
  },
);

export const getGuvhFiltersSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.guvhFilters;
  },
);

export const getGuvhTabSelector = createSelector(
  waterUsePlanStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.guvhTab;
  },
);
