import Table, { ColumnGroupType, type ColumnType } from 'antd/es/table';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps } from '@models/table/interfaces/tableCustomActionProps';

type PropsType<RecordType> = {
  customColumns: TableColumn[];
  actionProps: TableCustomActionProps<RecordType>;
  parent?: TableColumn;
};

export function mapCutomTableColumns<RecordType extends JsonRecord>({
  parent,
  customColumns,
  actionProps,
}: PropsType<RecordType>): ColumnType<RecordType>[] {
  const columns: ColumnType<RecordType>[] = customColumns.map(
    (customColumn) => {
      if (customColumn.xtype === CustomTableColumnType.Select) {
        return Table.SELECTION_COLUMN;
      }
      if (customColumn.xtype === CustomTableColumnType.Expand) {
        return Table.EXPAND_COLUMN;
      }

      const onCell = (record: RecordType) => {
        return {
          customColumn,
          record,
          title: customColumn.title as string,
          actionProps,
          parent,
        };
      };

      const onHeaderCell = () => {
        return {
          parent,
          customColumn,
          title: customColumn.title as string,
          actionProps,
        };
      };

      const column: ColumnType<RecordType> &
        ColumnGroupType<RecordType> & { parentDataIndex: string | null } = {
        align: customColumn.align,
        dataIndex: customColumn.dataIndex,
        parentDataIndex: customColumn.parentDataIndex
          ? customColumn.parentDataIndex
          : null,
        title: customColumn.title,
        className: customColumn.className,
        render: customColumn.render,
        children: mapCutomTableColumns({
          customColumns: customColumn.children ? customColumn.children : [],
          actionProps,
        }),
        onCell,
        onHeaderCell,
        fixed: customColumn.fixed,
        width: customColumn.width,
      };

      return column;
    },
  );

  return columns;
}
