import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';

import { Routes } from '@enums/routes';
import { TableCustomV2 } from '@features/ui-kit-v2';
import styles from '@pages/sppr/components/SpprBuildingTable/SpprBuildingTable.module.scss';
import { useSpprBuildingTableColumns } from '@pages/sppr/components/SpprBuildingTable/useSpprBuildingTableColumns';
import { useGetAllConstructionQuery } from '@store/gisproApi';
import { getCurrentTableOptionsSelector } from '@store/selectors';
import { saveTableOptions } from '@store/slices';
import { toString } from '@utils/utils';

export const IrrigationFundConstruction: FC = () => {
  const dispatch = useDispatch();
  const { columns } = useSpprBuildingTableColumns();
  const tableOptions = useSelector(getCurrentTableOptionsSelector);
  const { data: response, isLoading } = useGetAllConstructionQuery({
    ...tableOptions,
  });
  const navigate = useNavigate();

  const changePagination = useCallback(
    (pageData: any) => {
      dispatch(
        saveTableOptions({ page: pageData.current, limit: pageData.pageSize }),
      );
    },
    [columns, tableOptions],
  );

  const watchBuildingItem = (idOperation: number | any) => {
    navigate(
      generatePath(Routes.WorksAndEventsBuilding, {
        id: toString(idOperation.id),
      }),
    );
  };

  return (
    <Row>
      <Col span={24}>
        <TableCustomV2
          dataSource={response?.data}
          columns={columns}
          rowClassName={styles.tableRow}
          scroll={{ x: 1500 }}
          totalCount={response?.meta?.totalCount}
          customPagination={{
            number: response?.meta?.page,
            size: response?.meta?.limit,
          }}
          onChange={changePagination}
          actionProps={{
            onWatch: watchBuildingItem,
          }}
        />
      </Col>
    </Row>
  );
};
