import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreateAgroClimaticZoneModalShownSelector } from '@store/selectors';
import { agroClimaticZoneActions } from '@store/slices';

export const useCreateAgroClimaticZoneModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsCreateAgroClimaticZoneModalShownSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(
      agroClimaticZoneActions.setCreateAgroClimaticZoneModalShownAction(state),
    );
  }, []);

  return { isShow, setShow };
};
