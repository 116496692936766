export function formatNumber(num: number | undefined) {
  if (typeof num !== 'number') {
    return num;
  }

  let formattedNumber: string;

  switch (true) {
    case num >= 1000000000:
      formattedNumber = `${(num / 1000000000).toFixed(1)}`;
      break;
    case num >= 1000000:
      formattedNumber = `${(num / 1000000).toFixed(1)}`;
      break;
    case num >= 1000:
      formattedNumber = `${(num / 1000).toFixed(1)}`;
      break;
    default:
      formattedNumber = `${num}`;
      break;
  }

  return formattedNumber;
}

export function formatDecimal(
  num: number | string | undefined | null,
  decimal = 2,
): number {
  if (!num) {
    return 0;
  }
  return Number.parseFloat(Number.parseFloat(String(num)).toFixed(decimal));
}
