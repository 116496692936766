import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { BigPropertyBlock, CustomCard } from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsElectricalSubstationComponent: FC = () => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <CustomCard title="Системные показатели">
            <Row gutter={16}>
              <Col span={16}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name={['status', 'power']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Мощность"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name={['status', 'voltage']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Напряжение"
                          type="input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
              <Col span={8}>
                <CustomCard title={null} dashed>
                  <Form.Item name="direction">
                    <PropertyBlock
                      icon={ConstructionIcon}
                      label="Направление"
                      type="radio"
                    />
                  </Form.Item>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
