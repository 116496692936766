import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { CustomCard, DangerButton, PrimaryButton, TableCustom } from '@features/ui-kit';
import { ICreateGmrSystemOutlets } from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';
import {
  OutletBindDtoList,
  useDeleteGmrSystemOutletsMutation,
  useGetGmrBindedSystemsQuery,
} from '@store/gisproApi';

import { useIrrigationSystemTableColumns } from '../irrigationSystemTable/useIrrigationSystemTableColumns';

export const AddedOutletsTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { hydroModuleDistrictId } = useParams<{
    id: any;
    hydroModuleDistrictId: any;
  }>();

  const { columns } = useIrrigationSystemTableColumns();

  const [remove, { isError, error }] = useDeleteGmrSystemOutletsMutation();

  const { data = [] } = useGetGmrBindedSystemsQuery({
    gmrId: hydroModuleDistrictId,
  });

  const onChange = (selectedKey: React.Key[]) => {
    setSelectedRowKeys(selectedKey);
  };

  const onDeleteButtonClick = () => {
    const res = selectedRowKeys.reduce(
      (acc: ICreateGmrSystemOutlets, curr) => {
        const system = data.find((item) => item.id === curr);
        if (system) {
          acc.outletBindDtoList.push(system);
        }
        return acc;
      },
      {
        gmrId: hydroModuleDistrictId,
        outletBindDtoList: [],
      },
    );
    remove(res as ICreateGmrSystemOutlets);
    setSelectedRowKeys([]);
  };

  return (
    <CustomCard title={
      <Row justify="space-between">
        <Col>
          <Typography.Title level={5}>Добавленные системы</Typography.Title>
        </Col>
        <Col>
          <DangerButton onClick={onDeleteButtonClick}>Удалить</DangerButton>
        </Col>
      </Row>
    } light={true}>
      <TableCustom
        bordered
        size="small"
        rowSelection={{
          type: 'checkbox',
          columnTitle: 'Удалить',
          onChange,
          selectedRowKeys,
        }}
        rowKey={(item) => item.id}
        columns={columns}
        dataSource={data}
      />
      {isError && (
        <Typography.Text type="danger">
          {(error as any).status} {JSON.stringify((error as any).data)}
        </Typography.Text>
      )}
    </CustomCard>
  );
};
