import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { EntityTypesEnum } from '@enums/entityTypes';
import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { getSelectedWaterSectionSelector } from '@store/selectors/waterObject';

import { useWaterObjectModals } from '../useWaterObjectModals';
import { CustomModal, PropertyBlock } from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';

export const ConstructorStatsModal: React.FC = () => {
  const [form] = useForm();
  const waterSection = useSelector(getSelectedWaterSectionSelector);

  const { isShowModal, setShowModal } = useWaterObjectModals(
    WaterMainModalTypes.CONSTRUCTOR_STATS,
  );

  const { data: formTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  const { data: facingTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: soilTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.WaterSectionSoilTypes,
  });

  useEffect(() => {
    if (isShowModal) {
      form.setFieldsValue(waterSection);
    }
  }, [isShowModal]);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  return (
    <CustomModal
      centered
      footer={false}
      onCancel={onClose}
      title="Конструктивные характеристики"
      open={isShowModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="formId">
          <PropertyBlock
            icon={SizesIcon}
            label="Форма"
            type="select"
            options={formTypes}
          />
        </Form.Item>

        <Form.Item name="facingId">
          <PropertyBlock
            icon={SizesIcon}
            label="Облицовка"
            type="select"
            options={facingTypes}
          />
        </Form.Item>

        <Form.Item name="direction">
          <PropertyBlock
            icon={SizesIcon}
            label="Направление"
            type="radio"
          />
        </Form.Item>

        <Form.Item name="bottomSlopeAngle">
          <PropertyBlock
            icon={SizesIcon}
            label="Угол наклона дна"
            type="input"
          />
        </Form.Item>

        <Form.Item name="soilId">
          <PropertyBlock
            icon={SizesIcon}
            label="Грунт"
            type="select"
            options={soilTypes}
          />
        </Form.Item>

        <Form.Item name="sedimentLevelMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Уровень наносов"
            type="input"
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
