import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { quartalOptions } from '@constants/quartalOptions';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedSelectV2,
  TooltipedYearPickerV2,
} from '@features/ui-kit-v2';
import { IAnnualPlanFilters } from '@models/annualPlan/enums/AnnualPlan';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import dayjs from 'dayjs';
import { toInteger } from '@utils/utils';

import { useAnnualPlanQuarterlyTableFilters } from '../useAnnualPlanQuarterlyTableFilters';
import styles from './AnnualPlanQuarterlyTableFilters.module.scss';
import { useGetCurrentUser } from '@features/authentication';

export const AnnualPlanQuarterlyTableFilters = () => {
  const [filtersState, setFiltersState] = useState<Partial<IAnnualPlanFilters>>(
    {},
  );

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const { data: facility = [] } = useFindReferencesByParentIdQuery(
    filtersState.districtId
      ? {
          parentId: parseInt(filtersState.districtId, 10),
        }
      : skipToken,
  );

  const { filters, setAnnualPlanFilters } =
    useAnnualPlanQuarterlyTableFilters();

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
    }));
  };

  const onFilterChange = (filterKey: keyof IAnnualPlanFilters, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onFilterButtonClick = () => {
    setAnnualPlanFilters(filtersState as IAnnualPlanFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      districtId: user?.owner?.district?.id.toString(),
      regionId: user?.owner?.region?.id.toString(),
      facilityId: null,
      year: null,
      quarter: null,
    });
    setAnnualPlanFilters({
      districtId: user?.owner?.district?.id.toString(),
      regionId: user?.owner?.region?.id.toString(),
      facilityId: null,
      year: null,
      quarter: null,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <div className={styles.selectWrapper}>
      <Row gutter={16}>
        <Col span={5}>
          <TooltipedSelectV2
            placeholder="Область"
            value={
              regions.find(
                (item) => item.id === toInteger(filtersState.regionId),
              )?.label
            }
            onChange={(v: string) => onRegionChange(v)}
            options={regions}
            bordered={false}
            allowClear
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={5}>
          <TooltipedSelectV2
            bordered={false}
            placeholder="Район"
            options={districts}
            disabled={isDisabledDistrict || !filtersState.regionId}
            value={
              districts.find(
                (item) => item.id === toInteger(filtersState.districtId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('districtId', v)}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedSelectV2
            placeholder="Хозяйство"
            options={facility}
            disabled={!filtersState.districtId}
            value={
              facility.find(
                (item) => item.id === toInteger(filtersState.facilityId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('facilityId', v)}
            bordered={false}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedYearPickerV2
            borderless
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col span={4}>
          <TooltipedSelectV2
            bordered={false}
            placeholder="Выберите квартал"
            allowClear
            options={quartalOptions}
            onChange={(v: string) => onFilterChange('quarter', v)}
          />
        </Col>
        <Col span={2}>
          <Row gutter={10}>
            <Col>
              <FilterButtonV2 onClick={onFilterButtonClick} />
            </Col>
            <Col>
              <ResetFilterButtonV2 onClick={onResetFilterButtonClick} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
