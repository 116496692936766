import { Col, List, Row } from 'antd';

import styles from './MenuTab.module.scss';
import type { JsonRecord } from '@models/jsonRecord';
import { NoteIcon } from '@icons/NoteIcon';
import Icon from '@ant-design/icons';

type MenuTabProps<T> = {
  items: T[];
  activeKey?: keyof T;
  onChange?: (key: keyof T) => void;
}

type MenuItemProps<T> = {
  item: T;
  isActive: boolean;
};

export const MenuTab: React.FC<MenuTabProps<JsonRecord>> = ({ items, activeKey, onChange }) => {
  return (
    <div className={styles.menuContainer}>
      <List
        className={styles.menu}
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => {
          const isActive = item.key === activeKey;
          return (
            <div onClick={() => onChange?.(item.key)}>
              <MenuItem item={item} isActive={isActive}  />
            </div>
          )
        }}
      />
    </div>
  );
}

const MenuItem: React.FC<MenuItemProps<JsonRecord>> = ({ item, isActive = false }) => {
  return (
    <div className={isActive ? styles.activeItem : styles.item}>
      <Row align="middle" style={{ height: '100%' }} gutter={10}>
        <Col>
          <Icon style={{ fontSize: 20, marginLeft: 16, marginTop: 4 }} component={NoteIcon} />
        </Col>
        <Col>
          <div className={styles.itemText}>{item.label}</div>
        </Col>
        {'count' in item && (
          <Col>
            <div className={styles.itemText}>{item.count}</div>
          </Col>
        )}
      </Row>
    </div>
  );
}
