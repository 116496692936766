import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, Space, Typography } from 'antd';

import { baseUrl } from '@constants/baseUrl';
import styles from '@features/manageEvents/tables/DocumentTable/DocumentTable.module.scss';
import { RealizationTable } from '@features/manageEvents/tables/realizationTable/RealizationTable';
import {
  CustomCard,
  CustomCarousel,
  Divider,
  PrimaryButton,
} from '@features/ui-kit';
import { attachFileUrl } from '@features/ui-kit/upload/utils/attachFileUrl';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  ImplementationTermStatusEnum,
  TaskStatusEnum,
} from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindOperationalBlockByIdQuery,
  useOperationalBlockSetControlMutation,
  useSaveTaskOperationalBlockMutation,
} from '@store/gisproApi';
import { isInteger } from '@utils/utils';

export const RealizationCard = () => {
  const { id } = useParams();
  const [setControl] = useOperationalBlockSetControlMutation();

  const { data: operationalBlock, refetch } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const submitOperationalEvent = () => {
    setControl({ id: operationalBlock?.id });
  };

  const doneImplementationTerms =
    operationalBlock?.implementationTerms?.filter(
      (item) => item.status === ImplementationTermStatusEnum.Done,
    )?.length || 0;

  const photos = operationalBlock?.implementationTerms
    ?.map((item) =>
      item.attachments?.filter((subItem) => subItem.type === 'фотография'),
    )
    .flat();

  const photosWithUrls =
    photos?.map((item: IAttachment) => ({
      id: item.id as number,
      path: `${baseUrl}/api/v1/attachment/download?path=${item.path}`,
    })) || [];
  const isEditingDisabled = useMemo(
    () => operationalBlock?.status !== TaskStatusEnum.onRealization,
    [operationalBlock],
  );
  const doneDisabled =
    !isEditingDisabled &&
    doneImplementationTerms === operationalBlock?.implementationTerms?.length;
  return (
    <CustomCard
      title={
        <Row align="middle" justify="space-between" gutter={8}>
          <Col>
            <Space size={16}>
              <Typography.Title className={styles.title} level={4}>
                Реализация
              </Typography.Title>
              <Typography.Title className={styles.title} level={4}>
                {doneImplementationTerms} /{' '}
                {operationalBlock?.implementationTerms?.length || 0}
              </Typography.Title>
            </Space>
          </Col>
          <Col>
            <PrimaryButton
              disabled={!doneDisabled}
              onClick={submitOperationalEvent}
            >
              Завершить
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <RealizationTable
        implementationTerms={operationalBlock?.implementationTerms || []}
      />
      <Divider />
      <CustomCarousel items={photosWithUrls} />
    </CustomCard>
  );
};
