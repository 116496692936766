import { ArgLayer } from '@utils/map/Line/types';

export const getStyleLine = (argLine: ArgLayer) => {
  const { layer, name, L, feature } = argLine;

  const originalLatlngs = feature.geometry.coordinates.map(
    (coord: any) => [coord[1], coord[0]], // Преобразуем [lng, lat] в [lat, lng]
  );

  //Внутрихозяйственные каналы +
  if (name === 'geonode:onfarm_channel') {
    return layer.setStyle({
      color: '#267300',
    });
  }

  //Дренажные трубы
  if (name === 'geonode:drainage_pipes_drains') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 60,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/geonode_drainage_pipes_drains.svg',
                iconSize: [40, 40],
                iconAnchor: [20, 20],
              }),
            },
          }),
        },
      ],
    });
  }

  //Водосбросный канал (дренаж)
  if (name === 'geonode:spillway_channel_drainage') {
    layer.setStyle({
      color: '#0066FF',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 60,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/geonode_spillway_channel_drainage.svg',
                iconSize: [20, 20],
                iconAnchor: [10, 10],
              }),
            },
          }),
        },
      ],
    });
  }

  //Трубчатый переезд
  if (name === 'geonode:tubular_crossing') {
    layer.setStyle({
      color: '#000',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 60,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/geonode_tubular_crossing_right.svg',
                iconSize: [20, 20],
                iconAnchor: [10, 10],
              }),
            },
          }),
        },
        {
          offset: 20,
          repeat: 60,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/geonode_tubular_crossing_left.svg',
                iconSize: [20, 20],
                iconAnchor: [10, 10],
              }),
            },
          }),
        },
      ],
    });
  }

  //Граница РУВХ --
  // if (name === 'color2') {
  //   return layer.setStyle({
  //     color: '#A80000',
  //   });
  // }

  //Межозяйственные каналы ++
  if (name === 'geonode:interfarm_channels') {
    return layer.setStyle({
      color: '#A900E6',
    });
  }

  //Районная граница --
  // if (name === 'color4') {
  //   return layer.setStyle({
  //     color: '#000',
  //   });
  // }

  //Реки и ручья --
  // if (name === 'color5') {
  //   return layer.setStyle({
  //     color: '#00C5FF',
  //   });
  // }

  //Системы --
  // if (name === 'color6') {
  //   return layer.setStyle({
  //     color: '#004DA8',
  //   });
  // }

  //Хозяйственный канал
  if (name === 'geonode:economic_channel') {
    return layer.setStyle({
      color: '#54fc00',
    });
  }

  //Водопроводы наземные ---
  // if (name === 'geonode:aqueduct') {
  //   layer.setStyle({
  //     color: 'blue',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 40,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 0,
  //           pathOptions: {
  //             color: 'blue',
  //             weight: 3,
  //             lineCap: 'round',
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  //Водопроводы подземные ---
  // if (name === 'line2') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 48,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 27,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: 40,
  //         repeat: 48,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 0,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 5,
  //             lineCap: 'round',
  //           },
  //         }),
  //       },
  //       {
  //         offset: 50,
  //         repeat: 48,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 0,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 5,
  //             lineCap: 'round',
  //           },
  //         }),
  //       },
  //       {
  //         offset: 60,
  //         repeat: 48,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 0,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 5,
  //             lineCap: 'round',
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Быстроток ++
  if (name === 'geonode:quickness_stream') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 57,
          symbol: L.Symbol.dash({
            pixelSize: 55,
            pathOptions: {
              color: '#005CE6',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Хозяйственный водосбросный канал ++
  if (name === 'geonode:economic_spillway_channel') {
    layer.setStyle({
      color: '#4CE600',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 20,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/iconLine.svg',
                iconSize: [10, 10],
                iconAnchor: [5, 5],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Водопропускная труба ++
  if (name === 'geonode:culvert') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 31,
          symbol: L.Symbol.dash({
            pixelSize: 16,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
        {
          offset: 16,
          repeat: 31,
          symbol: L.Symbol.dash({
            pixelSize: 5,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Главный водосбросный канал ++
  if (name === 'geonode:main_spillway') {
    layer.setStyle({
      color: '#005CE6',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 20,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/iconLine3.svg',
                iconSize: [10, 10],
                iconAnchor: [4, 4],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Государственная граница ---
  // if (name === 'line7') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 17,
  //         symbol: L.Symbol.marker({
  //           rotate: true,
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconLine4.svg',
  //               iconSize: [17, 17],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Дамбы ---
  // if (name === 'line8') {
  //   layer.setStyle({
  //     color: '#000',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: false,
  //         symbol: L.Symbol.arrowHead({
  //           pixelSize: 4,
  //           polygon: false,
  //           pathOptions: {
  //             stroke: true,
  //             color: '#000',
  //             weight: 2,
  //           },
  //           headAngle: 180,
  //         }),
  //       },
  //       {
  //         offset: '100%',
  //         repeat: false,
  //         symbol: L.Symbol.arrowHead({
  //           pixelSize: 4,
  //           polygon: false,
  //           pathOptions: {
  //             stroke: true,
  //             color: '#000',
  //             weight: 2,
  //           },
  //           headAngle: 180,
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Дюкеры на линиях водопроводов ++
  if (name === 'geonode:duker') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 35,
          symbol: L.Symbol.dash({
            pixelSize: 20,
            pathOptions: {
              color: '#000',
              weight: 2,
            },
          }),
        },
        {
          offset: '100%',
          repeat: false,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: false,
            pathOptions: {
              stroke: true,
              color: '#000',
              weight: 2,
            },
            headAngle: 90,
          }),
        },
        {
          offset: 0,
          repeat: false,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: false,
            pathOptions: {
              stroke: true,
              color: '#000',
              weight: 2,
            },
            headAngle: 90,
          }),
        },
      ],
    });
  }

  // //Железная дорога ---
  // if (name === 'line9') {
  //   layer.setStyle({
  //     color: '#000',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 21,
  //         symbol: L.Symbol.arrowHead({
  //           pixelSize: 6,
  //           polygon: false,
  //           pathOptions: {
  //             stroke: true,
  //             color: '#000',
  //             weight: 2,
  //           },
  //           headAngle: 180,
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Канавы сухие в две линии ++
  if (name === 'geonode:ditches') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 12,
          symbol: L.Symbol.dash({
            pixelSize: 6,
            pathOptions: {
              color: '#CC8061',
              weight: 2,
            },
          }),
        },
        {
          offset: 0,
          repeat: 12,
          symbol: L.Symbol.dash({
            pixelSize: 6,
            pathOptions: {
              color: '#CC8061',
              weight: 2,
              offset: -4,
            },
          }),
        },
      ],
    });
  }

  // //Канавы
  // if (name === 'geonode:ditches') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 12,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 6,
  //           pathOptions: {
  //             color: '#005CE6',
  //             weight: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Коллектор(связанный с водным объектом) ---
  // if (name === 'line12') {
  //   layer.setStyle({
  //     color: '#fff',
  //     weight: 2,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 36,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 16,
  //           pathOptions: {
  //             color: '#005CE6',
  //             weight: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Лесополосы ---
  // if (name === 'geonode:forest_belts') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 20,
  //         symbol: L.Symbol.marker({
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconLine5.svg',
  //               iconSize: [10, 10],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Меж-участковый водосбросный канал
  if (name === 'geonode:inter_stage_spillway_channel') {
    layer.setStyle({
      color: '#8400A8',
      weight: 2,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 81,
          symbol: L.Symbol.marker({
            markerOptions: {
              rotate: true,
              icon: L.icon({
                iconUrl: '/icons/iconLine7.svg',
                iconSize: [15, 15],
              }),
            },
          }),
        },
      ],
    });
  }

  //Межхозяйственный водосбросный канал ++
  if (name === 'geonode:inter_farm_spillway_channel') {
    layer.setStyle({
      color: '#8400A8',
      weight: 2,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 81,
          symbol: L.Symbol.marker({
            markerOptions: {
              rotate: true,
              icon: L.icon({
                iconUrl: '/icons/iconLine18.svg',
                iconSize: [15, 15],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Мелкие водосбросные каналы ++
  if (name === 'geonode:shallow_spillway_channels') {
    layer.setStyle({
      color: '#00A9E6',
      weight: 2,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 81,
          symbol: L.Symbol.marker({
            markerOptions: {
              rotate: true,
              icon: L.icon({
                iconUrl: '/icons/iconLine7.svg',
                iconSize: [15, 15],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Областная ---
  // if (name === 'line17') {
  //   layer.setStyle({
  //     color: '#fff',
  //     weight: 3,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 20,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 10,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 2,
  //             lineCap: 'square',
  //           },
  //         }),
  //       },
  //       {
  //         offset: 12,
  //         repeat: 20,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 1,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 2,
  //             lineCap: 'square',
  //           },
  //         }),
  //       },
  //       {
  //         offset: 9,
  //         repeat: 20,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 1,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 2,
  //             lineCap: 'square',
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Оградительный канал (ловчий) ++
  if (name === 'geonode:protective_channel_lovchyi') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 9,
            pathOptions: {
              color: '#A900E6',
              weight: 1,
            },
          }),
        },
        {
          offset: 15,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#A900E6',
              weight: 1,
            },
          }),
        },
        {
          offset: 9,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#A900E6',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Оградительный канал(нагорно-ловчий) ---
  if (name === 'geonode:protective_channel_nagornyi_lovchyi') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 9,
            pathOptions: {
              color: '#38A800',
              weight: 1,
            },
          }),
        },
        {
          offset: 15,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#38A800',
              weight: 1,
            },
          }),
        },
        {
          offset: 9,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#38A800',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Оградительный канал(нагорный) ---
  if (name === 'geonode:protective_channel_nagornyi') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 9,
            pathOptions: {
              color: '#004DA8',
              weight: 1,
            },
          }),
        },
        {
          offset: 15,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#004DA8',
              weight: 1,
            },
          }),
        },
        {
          offset: 9,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#004DA8',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Оградительный канал
  if (name === 'geonode:protective_channel') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 9,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
        {
          offset: 15,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
        {
          offset: 9,
          repeat: 24,
          symbol: L.Symbol.dash({
            pixelSize: 3,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
      ],
    });
  }

  // //Открытый коллектор ---
  // if (name === 'line22') {
  //   layer.setStyle({
  //     color: '#fff',
  //     weight: 2,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 51,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 47,
  //           pathOptions: {
  //             color: '#0070FF',
  //             weight: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Плотины проезжие ---
  // if (name === 'line23') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 6,
  //         symbol: L.Symbol.marker({
  //           rotate: true,
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconLine10.svg',
  //               iconSize: [15, 15],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Полевая и грунтовая дорога ---
  // if (name === 'line24') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 23,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 17,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Селеотвод
  // if (name === 'geonode:seleotvod') {
  //   layer.setStyle({
  //     color: '#005CE6',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 18,
  //         symbol: L.Symbol.marker({
  //           rotate: true,
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconLine11.svg',
  //               iconSize: [15, 15],
  //               iconAnchor: [13, 0],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Селехранилише ---
  // if (name === 'line26') {
  //   layer.setStyle({
  //     opacity: 0,
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 6,
  //         symbol: L.Symbol.marker({
  //           rotate: true,
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconLine12.svg',
  //               iconSize: [15, 15],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //Трубопровод (канализация)
  if (name === 'geonode:pipeline_sewerage') {
    layer.setStyle({
      color: '#000',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 81,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/iconLine13.svg',
                iconSize: [15, 15],
                iconAnchor: [7, 0],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Трубопровод++
  if (name === 'geonode:pipelines') {
    layer.setStyle({
      color: '#000',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 64,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/iconLine14.svg',
                iconSize: [15, 15],
                iconAnchor: [7, 0],
              }),
            },
          }),
        },
      ],
    });
  }

  // //Туннели ++
  if (name === 'geonode:tunnel') {
    layer.setStyle({
      opacity: 0,
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 14,
          symbol: L.Symbol.dash({
            pixelSize: 6,
            pathOptions: {
              color: '#000',
              weight: 1,
            },
          }),
        },
        {
          offset: 0,
          repeat: false,
          symbol: L.Symbol.arrowHead({
            pixelSize: 5,
            polygon: false,
            pathOptions: {
              stroke: true,
              color: '#000',
              weight: 2,
            },
            headAngle: 180,
          }),
        },
        {
          offset: '100%',
          repeat: false,
          symbol: L.Symbol.arrowHead({
            pixelSize: 5,
            polygon: false,
            pathOptions: {
              stroke: true,
              color: '#000',
              weight: 2,
            },
            headAngle: 180,
          }),
        },
      ],
    });
  }

  // //Участковый водосбросный канал
  if (name === 'geonode:precinct_spillway_channel') {
    layer.setStyle({
      color: '#BEE8FF',
    });

    return L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 64,
          symbol: L.Symbol.marker({
            rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: '/icons/iconLine15.svg',
                iconSize: [15, 15],
                iconAnchor: [7, 0],
              }),
            },
          }),
        },
      ],
    });
  }

  // //ЛЭП на деревянных опорах и железобетонных столбах ---
  // if (name === 'line31') {
  //   layer.setStyle({
  //     color: '#000',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: -2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: 24,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: -2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: 12,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // //ЛЭП на металл ---
  // if (name === 'line32') {
  //   layer.setStyle({
  //     color: '#000',
  //   });
  //
  //   return L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: -2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: 24,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: -2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: 12,
  //         repeat: 84,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: 2,
  //           },
  //         }),
  //       },
  //       {
  //         offset: '100%',
  //         repeat: false,
  //         symbol: L.Symbol.dash({
  //           pixelSize: 2,
  //           pathOptions: {
  //             color: '#000',
  //             weight: 3,
  //             lineCap: 'square',
  //             offset: 2,
  //           },
  //         }),
  //       },
  //     ],
  //   });
  // }

  // // Автомобильная дорога
  if (name === 'geonode:roads') {
    layer.setStyle({
      weight: 3,
      color: 'orange',
    });

    return L.layerGroup([
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(2),
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(-2),
    ]);
  }

  // //Акведук
  if (name === 'geonode:aqueduct') {
    layer.setStyle({
      weight: 2,
      color: '#00C5FF',
    });

    return L.layerGroup([
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(5),
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(-5),
    ]);
  }

  // // Магистральные оросительные каналы
  if (name === 'geonode:magistral_irrigation_channels') {
    layer.setStyle({
      weight: 3,
      color: '#00C5FF',
    });

    return L.layerGroup([
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(2),
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(-2),
    ]);
  }

  // //Селедук
  if (name === 'geonode:seleduk') {
    layer.setStyle({
      weight: 2,
      color: '#000',
    });

    return L.layerGroup([
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(5),
      L.polyline(originalLatlngs, {
        color: '#000',
        weight: 2,
      }).setOffset(-5),
    ]);
  }

  return layer;
};
