import { PrimaryButton, TooltipedInput, YearPicker, } from '@features/ui-kit';
import { useWaterUserFilters } from "@features/vodokhozReport/entryData/waterUser/waterUserFilters";
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { Col, Flex, Row, Space } from 'antd';
import dayjs, { Dayjs } from "dayjs";
import { createRef, FC, useEffect, useRef, useState } from 'react';


export const EntryDataFilters: FC = () => {
  const [filtersState, setFiltersState] = useState<Partial<any>>({});
  const { filters, setWaterUserFilters } = useWaterUserFilters();

  const onFilterButtonClick = () => {
    setWaterUserFilters({
      ...filtersState,
      page: undefined,
      size: undefined,
    } as any)
  };

  const onFilterChange = (
    filterKey: string,
    v: string,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v
    };
    setFiltersState(updatedFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      year: 2024,
      search: '',
    });
    setWaterUserFilters({
      year: 2024,
      search: '',
      page: undefined,
      size: undefined,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);
  useEffect(() => {
    if (!filtersState.year) {
      onResetFilterButtonClick();
    }
  }, []);

  return (
    <Row justify="space-between" gutter={10}>
      <Col>
        <Space size={5}>
        <YearPicker
          value={filtersState.year ? dayjs().set('year', filtersState.year) : null}
          placeholder="Год"
          allowClear
          disabledDate={(current) =>
            current && current > dayjs().endOf('year')
          }
          onChange={(value: Dayjs | null) =>
            onFilterChange('year', String(value?.year()))
          }
        />
        <TooltipedInput
          placeholder="Поиск"
          value={filtersState.search}
          onChange={(e) => onFilterChange('search', e.target.value || '')}
        />
        </Space>
      </Col>
      <Col>
        <Flex gap='small'>
          <PrimaryButton
            icon={<FilterIcon/>}
            onClick={onFilterButtonClick}
          />
          <PrimaryButton
            icon={<ResetFilterIcon/>}
            onClick={onResetFilterButtonClick}
          />
        </Flex>
      </Col>
    </Row>
  );
};
