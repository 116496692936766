import { FC, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Row, TablePaginationConfig } from 'antd';

import { Routes } from '@enums/routes';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { IAnnualApplication } from '@models/annualApplication/interfaces/annualApplication';
import { IAnnualApplicationFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllAnnualContractListQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAnnualApplicationTableFilters } from '../useAnnualApplicationTableFilters';
import styles from './AnnualApplicationTableContainer.module.scss';
import { useAnnualApplicationTableColumns } from './useAnnualApplicationTableColumns';
import { saveUrl } from '@utils/returnUrl';

export const AnnualApplicationTableContainer: FC = () => {
  const navigate = useNavigate();
  const { columns } = useAnnualApplicationTableColumns();
  const { filters, setAnnualContractFilters } =
    useAnnualApplicationTableFilters();

  const saveAction = saveUrl();

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useFindAllAnnualContractListQuery(isEmpty(filters) ? skipToken : filters);

  const onHandleWatch = (record: IAnnualApplication) => {
    navigate(
      generatePath(Routes.AnnualApplicationCardView, {
        id: String(record.id),
      }),
    );
    saveAction();
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setAnnualContractFilters({
          ...(filters as IAnnualApplicationFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  return (
    <Row className={styles.table}>
      <Col span={24}>
        <CustomCard>
          <TableCustom<IAnnualApplication>
            loading={isLoading || isFetching}
            dataSource={content}
            onChange={onTableChange}
            totalCount={totalElements}
            columns={columns}
            customPagination={rest || {}}
            size="small"
            actionProps={{
              onHandleWatch,
            }}
          />
        </CustomCard>
      </Col>
    </Row>
  );
};
