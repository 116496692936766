import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { roundBigInteger } from '@utils/roundBigInteger';

type ReturnType = {
  data: any[];
};

export const mapWateringSupplyScheduleTableData = ({
  data,
  month,
}: {
  month: string;
  data: any;
}): ReturnType => {
  const sumRes = (resultSum: any, curr: any) => {
    Object.entries(curr).forEach(([key, value]) => {
      resultSum[key] = (resultSum[key] || 0) + value;
      resultSum[`${key}M3`] =
        resultSum[key] === 0
          ? 0
          : roundBigInteger(formatDecimal(resultSum[key]) * 0.864, 1000);
    });
  };
  const filteredData = data?.map((item: any, index: number) => {
    const resultSum: any = {};
    const decades: any = {};
    for (let i = 0; i < item.decadal?.length; i += 1) {
      const { decade, value } = item.decadal[i];
      decades[decade] = {
        ...item.decadal[i],
        value: {
          ...value,
          factM3: value.fact
            ? roundBigInteger(formatDecimal(value.fact) * 0.864, 1000)
            : null,
          planM3: value.plan
            ? roundBigInteger(formatDecimal(value.plan) * 0.864, 1000)
            : null,
          requestM3: value.request
            ? roundBigInteger(value.request * 0.864, 1000)
            : null,
        },
      };
      sumRes(resultSum, value);
    }

    return {
      ...item,
      decadal: decades,
      sum: { ...resultSum },
      index: index + 1,
    };
  });

  return {
    data: filteredData,
  };
};
