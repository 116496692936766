import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Row, Space } from 'antd';

import { Routes } from '@enums/routes';
import { MonitoringTableFilters } from '@features/monitoring/demoMonitoring/controls';
import { useMonitoringHydroColumns } from '@features/monitoring/demoMonitoring/table/useMonitoringHydroColumns';
import { SectionTitle, TableCustomV2 } from '@features/ui-kit-v2';
import { useFindAllHydropostsQuery } from '@store/gisproApi';

import styles from '../../MonitoringLayout.module.scss';

export const HydroTableLayout = () => {
  const navigate = useNavigate();
  const { columns } = useMonitoringHydroColumns();
  const { data: hydroposts = [], isLoading } = useFindAllHydropostsQuery();

  const onWatch = (objectId: any) => {
    navigate(
      generatePath(Routes.DemoMonitoringHydroCard, {
        id: String(objectId.id),
      }),
    );
  };

  return (
    <Space
      size="large"
      className={styles.layoutBlock}
      direction="vertical"
      style={{ display: 'flex', marginTop: 20, width: '100%' }}
    >
      <Row gutter={12}>
        <Col span={24}>
          <SectionTitle background="blue" title="Гидропосты" />
        </Col>
      </Row>
      <Row>
        <TableCustomV2
          loading={isLoading}
          dataSource={hydroposts}
          columns={columns}
          size="small"
          actionProps={{ onWatch }}
          // totalCount={table?.length}
          scroll={{ x: 1600 }}
        />
      </Row>
    </Space>
  );
};
