import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { get } from '@utils/utils';
import styles from './WateringScheduleTable.module.scss';
import { ACCESS } from '@enums/permisson';

type ReturnType = {
  columns: TableColumn[];
};

export const useWateringScheduleTableColumns = ({
  month,
  userType,
}: {
  month: string;
  userType: WaterUserEnum;
}): ReturnType => {
  let columnNameByWaterUserType;
  switch (userType) {
    case WaterUserEnum.INDIVIDUAL:
      columnNameByWaterUserType = 'ФИО / Участок / Водовыпуск';
      break;
    case WaterUserEnum.LEGAL:
      columnNameByWaterUserType = 'Организация / Участок / Водовыпуск';
      break;
    case WaterUserEnum.ASSOCIATION:
      columnNameByWaterUserType = 'Организация / водовыпуск';
      break;
    default:
      columnNameByWaterUserType = 'ФИО / Участок / Водовыпуск';
      break;
  }
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      width: 56,
      xtype: CustomTableColumnType.String,
      align: 'left',
      className: styles.columns,
    },
    {
      title: columnNameByWaterUserType,
      dataIndex: 'name',
      align: 'left',
      xtype: CustomTableColumnType.String,
      // actions: [CustomTableAction.WaterUsers],
      width: 177,
      className: styles.columns,
    },
    {
      title: month,
      dataIndex: month,
      xtype: CustomTableColumnType.String,
      align: 'left',
      className: styles.columns,
      children: [
        {
          title: '1 Декада',
          dataIndex: `${month}-1`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.String,
              align: 'left',
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              xtype: CustomTableColumnType.String,
              ytype: CustomTableColumnHeaderType.StringWithAction,
              actionsAccess: [ACCESS.WATER_USE_SPILLWAY_REQUEST],
              align: 'left',
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: (record: any) => {
                    const status = get(record, ['decadeDtos', '1', 'status']);
                    return status === 'edit' || status === 'new';
                  },
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '1', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
          ],
        },
        {
          title: '2 Декада',
          dataIndex: `${month}-2`,
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              align: 'left',
              xtype: CustomTableColumnType.String,
              ytype: CustomTableColumnHeaderType.StringWithAction,
              actionsAccess: [ACCESS.WATER_USE_SPILLWAY_REQUEST],
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  xtype: CustomTableColumnType.DecimalEdit,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                  editableDecimalCell: (record: any) => {
                    const status = get(record, ['decadeDtos', '2', 'status']);
                    return status === 'edit' || status === 'new';
                  },
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '2', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
          ],
        },
        {
          title: '3 Декада',
          dataIndex: `${month}-3`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              align: 'left',
              ytype: CustomTableColumnHeaderType.StringWithAction,
              xtype: CustomTableColumnType.String,
              actionsAccess: [ACCESS.WATER_USE_SPILLWAY_REQUEST],
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  xtype: CustomTableColumnType.DecimalEdit,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                  editableDecimalCell: (record: any) => {
                    const status = get(record, ['decadeDtos', '3', 'status']);
                    return status === 'edit' || status === 'new';
                  },
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  decimalClassName: styles.columns,
                  decimalIndex: ['decadeDtos', '3', 'value'],
                  width: 64,
                  align: 'left',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      xtype: CustomTableColumnType.String,
      align: 'left',
      children: [
        {
          title: 'План',
          align: 'left',
          dataIndex: 'sum-plan',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
            {
              title: 'тыс. м3',
              dataIndex: 'planM3',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
          ],
        },
        {
          title: 'Заявка',
          dataIndex: 'sum-request',
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'request',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
            {
              title: 'тыс. м3',
              dataIndex: 'requestM3',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
          ],
        },
        {
          title: 'Факт',
          dataIndex: 'sum-fact',
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'fact',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
            {
              title: 'тыс. м3',
              dataIndex: 'factM3',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              decimalClassName: styles.columns,
              decimalIndex: ['sum'],
              width: 64,
              align: 'left',
            },
          ],
        },
      ],
    },
  ];

  return {
    columns,
  };
};
