import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Upload, UploadProps } from 'antd';

import { UploadIcon } from '@icons/UploadIcon';
import { useUploadFilesMutation } from '@store/gisproApi';

import styles from './styles.module.scss';
import { UploadFileIcon } from '@icons/UploadFileIcon';

type UploadBtnPropTypes = UploadProps & {
  customRequest?: (file: any) => void;
  onLoad?: (file: any) => void;
  docType?: string;
  title: string;
};

export const UploadBtn: FC<UploadBtnPropTypes> = ({
  onLoad,
  docType,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  const [uploadDocument] = useUploadFilesMutation();

  const getFilePath = (file: any) => {
    if (file.extension === 'png') {
      return {
        ...file,
        filePath: `${process.env.REACT_APP_MAIN_BASE_URL}/api/v1/attachment/download?path=${file.filePath}`,
      };
    }
    return file;
  };

  const customRequestUploader = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);

    try {
      const response: any = await uploadDocument(formData);
      onLoad?.(getFilePath(response.data));

      const url = URL.createObjectURL(file);

      URL.revokeObjectURL(url);

      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Upload
      showUploadList={false}
      customRequest={customRequestUploader}
      className={styles.upload__wrapper}
      {...props}
    >
      <Button type="default" className={styles.uploadBtn}>
        <UploadFileIcon />
        {title}
      </Button>
    </Upload>
  );
};
