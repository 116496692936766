import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsMainConduitCreateStepperActionSelectModalShownSelector } from '@store/selectors/mainConduit';
import { setShowMainConduitCreateStepperActionSelectModalAction } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export const useMainConduitCreateStepperActionSelectModal = (): ReturnType => {
  const dispatch = useDispatch();
  const isModalShown = useSelector(
    getIsMainConduitCreateStepperActionSelectModalShownSelector,
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowMainConduitCreateStepperActionSelectModalAction(showModal));
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
};
