import React from 'react';

import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderDateInfoHeaderCell } from './renderDateInfoHeaderCell';
import { rendererDecadeHeaderCell } from './rendererDecadeHeaderCell';
import { rendererDecadeWithActionHeaderCell } from './rendererDecadeWithActionHeaderCell';
import { renderHeaderCellString } from './renderHeaderCellString';
import { renderMonthHeaderCell } from './renderMonthHeaderCell';
import { renderStringHeaderCell } from './renderStringHeaderCell';

export class RendererHeaderCellFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { ytype } = props.customColumn;
    let renderer = renderHeaderCellString;

    switch (ytype) {
      case CustomTableColumnHeaderType.DecadeWithAction:
        renderer = rendererDecadeWithActionHeaderCell;
        break;
      case CustomTableColumnHeaderType.Decade:
        renderer = rendererDecadeHeaderCell;
        break;
      case CustomTableColumnHeaderType.Month:
        renderer = renderMonthHeaderCell;
        break;
      case CustomTableColumnHeaderType.DateInfo:
        renderer = renderDateInfoHeaderCell;
        break;
      case CustomTableColumnHeaderType.String:
        renderer = renderStringHeaderCell;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
