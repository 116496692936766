import { FC, useEffect } from 'react';

import { Layout } from 'antd';

import { ContractualWaterSupplyTable } from '@features/annualApplication/contractualWaterSupplyTable';
import { useHeaderTitle } from '@features/header/useHeaderTitle';

export const ContractualWaterSupplyTableLayout: FC = () => {
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle('Выполнение хоздоговорной водоподачи');
  }, []);
  return (
    <Layout>
      <ContractualWaterSupplyTable />
    </Layout>
  );
};
