import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, notification, Row, Typography } from 'antd';

import { PlanType } from '@enums/annualPlan';
import { EntityTypesEnum } from '@enums/entityTypes';
import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  StatusWrapper,
  TableCustom,
  TooltipedSelect,
} from '@features/ui-kit';
import { PrintModal } from '@features/waterUsePlan/waterUsePlanRuvh/waterUsePlanRuvhModal/printModal';
import { usePrintModal } from '@features/waterUsePlan/waterUsePlanRuvh/waterUsePlanRuvhModal/usePrintModal';
import { CopyIcon } from '@icons/CopyIcon';
import { PasteIcon } from '@icons/PasteIcon';
import { PrintIcon } from '@icons/PrintIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetWaterUseCropSowingPlanByGuvhQuery,
  useGetWaterUseCropSowingPlanQuery,
  useUpdateCropSowingPlanMutation,
  useUpdateStatusWaterUsePlanMutation,
} from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setUpdateStatus } from '@store/slices';
import { addRowIndexiesMapper } from '@utils/addRowIndexies/finalArrRowIndexies';
import { get } from '@utils/utils';

import { cropSowingOnIrrigatedLandsDataMapper } from './cropSowingOnIrrigatedLandsDataMapper';
import { useCropSowingDataQuery } from './useCropSowingDataQuery';
import { useCropSowingIrragatedLandsColumns } from './useCropSowingIrragatedLandsColumns';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const CropSowingOnIrrigatedLands: React.FC = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const hasPermission = useHasUserPermission(ACCESS.ANNUAL_CARD_EDIT);
  const { columns } = useCropSowingIrragatedLandsColumns(
    userRole,
    hasPermission,
  );
  const [disabelBtns, setDisableBtns] = useState<any>({
    calculate: false,
    save: false,
  });
  const dispatch = useDispatch();
  const { data: cropSowing } = useCropSowingDataQuery(userRole as UserRoles);
  const { setShowModal } = usePrintModal();
  const [updateStatus] = useUpdateStatusWaterUsePlanMutation();
  const [updateCropSowing] = useUpdateCropSowingPlanMutation();
  // const dataSource = cropSowingOnIrrigatedLandsDataMapper(cropSowing);
  const ruvhTables = useSelector(getRuvhTablesInfoSelector);
  const tableStatus = ruvhTables.find(
    (table) => table.type === PlanType.SowingPlan,
  )?.status;
  const getCellKey = (
    record: JsonRecord,
    column: TableColumn,
    index: number | undefined,
  ) => {
    if (column.editableCell && column.decimalIndex) {
      const uniqueId = get(record, [...(column.decimalIndex || []), 'id']);
      return uniqueId;
    }
    return -1;
  };

  const mappedData = useMemo(() => {
    let data;
    if (cropSowing) {
      if ('byDistrictDtos' in cropSowing) {
        data = cropSowing.byDistrictDtos.map((item: any) => {
          return cropSowingOnIrrigatedLandsDataMapper(item);
        });
      } else {
        data = cropSowingOnIrrigatedLandsDataMapper(cropSowing);
      }
    }
    return addRowIndexiesMapper((data || [])?.flat(), 0);
  }, [cropSowing]);
  const onFinish = () => {
    updateStatus({
      type: PlanType.SowingPlan,
    }).then((resp) => {
      setDisableBtns({ save: false, calculate: true });
      if ('data' in resp) {
        const status = resp.data.status
          ? PlanStatusEnum.Approved
          : PlanStatusEnum.Editable;
        dispatch(
          setUpdateStatus({
            status,
            type: PlanType.SowingPlan,
          }),
        );
      }
    });
  };

  const onEditDecimalSaveClick = (
    record: JsonRecord,
    column: TableColumn,
    value: any,
  ) => {
    const item = get(record, [...(column.decimalIndex || [])]);
    // console.log('item', item, column, record, value, column.dataIndex);
    // const currentValue = get(item, column.dataIndex);
    updateCropSowing({
      rawId: item.id,
      [column.dataIndex]: value,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        setDisableBtns({ save: true, calculate: true });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  return (
    <Col span={24}>
      <InformationalBlock>
        <CustomCard
          title={
            <Row justify="space-between">
              <Col span={12}>
                {userRole === UserRoles.ROLE_RUVH ? (
                  <StatusWrapper status={tableStatus}>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      План посева сельхозкультур на орошаемых землях
                    </Typography.Title>
                  </StatusWrapper>
                ) : (
                  <Typography.Title style={{ marginBottom: 0 }} level={4}>
                    План посева сельхозкультур на орошаемых землях
                  </Typography.Title>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={10} justify="end">
                  {userRole === UserRoles.ROLE_RUVH && (
                    <Row gutter={10}>
                      <Col>
                        <TooltipedSelect placeholder="Год" />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<CopyIcon />} />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<PasteIcon />} />
                      </Col>
                      {hasPermission ? (
                        <Col>
                          <PrimaryButton
                            disabled={!disabelBtns.save}
                            onClick={onFinish}
                            htmlType="submit"
                          >
                            Сохранить
                          </PrimaryButton>
                        </Col>
                      ) : null}
                    </Row>
                  )}
                  <Col>
                    <PrimaryButton
                      onClick={() => setShowModal(true)}
                      icon={<PrintIcon />}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <TableCustom
            bordered
            // loading={}
            // rowKey={(record) => record.systemId}
            // cellKey={getCellKey}
            dataSource={mappedData || []}
            scroll={{ y: 'calc(100vh - 600px)', x: 2700 }}
            columns={columns}
            actionProps={{
              onEditDecimalSaveClick,
            }}
          />
        </CustomCard>
      </InformationalBlock>
      <PrintModal />
    </Col>
  );
};
