export const statusOptions = [
  {
    value: 'draft',
    label: 'Проект',
  },
  {
    value: 'cancelled',
    label: 'Отменен',
  },
  {
    value: 'submitted',
    label: 'Сдан',
  },
];
