import { NewsModal } from '@features/administration/newsManagement/newsModal';
import { useNewsTableColumns } from '@features/administration/newsManagement/newsTable/useNewsTableColumns';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CustomCard, Divider, InformationalBlock, PrimaryButton, TableCustom, } from '@features/ui-kit';
import { useNewsDeleteMutation, useNewsListQuery } from '@store/api/newsApi';

import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

export const NewsTable: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitle();

  const {
    data = [],
    isLoading,
    isFetching,
  } = useNewsListQuery();

  const { columns } = useNewsTableColumns();

  const [deleteEntity] = useNewsDeleteMutation();

  const [isModalShow, setShowModal] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const handleEdit = (val: any) => {
    setInitialValues(val);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = ({ id }: { id: number }) => {
    deleteEntity({ id });
  };

  useEffect(() => {
    setHeaderTitle(t('headers.news'));
  }, []);

  return (
    <>
      <Divider/>
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>
                  Список новостей
                </Typography.Title>
              </Col>
              <Col>
                <PrimaryButton onClick={handleCreate}>Добавить новость</PrimaryButton>
              </Col>
            </Row>
          }
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={data as any[]}
            bordered
            columns={columns}
            size="small"
            actionProps={{
              onHandleEdit: (val) => handleEdit(val),
              deleteItem,
            }}
          />
          {isModalShow && (
            <NewsModal
              isModalShow={isModalShow}
              setShowModal={setShowModal}
              initialValues={initialValues}
            />
          )}
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
