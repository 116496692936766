import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  AttachDocumentModal,
  CreateTemplateModal,
} from '@features/manageEvents';
import { AssignExecutorModal } from '@features/manageEvents/modals/AssignExecutorModal';
import {
  ColorBlock,
  DangerButton,
  DefaultButton,
  Divider,
  ElementColor,
  InformationalBlock,
} from '@features/ui-kit';
import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import {
  useExtraTaskBlockAttachmentsUploadMutation,
  useGetOtherTaskByIdQuery,
  useSaveOtherTaskBlockMutation,
} from '@store/gisproApi';
import { getOtherTaskBlockSelector } from '@store/selectors';
import {
  addDocumentToOtherTaskAction,
  clearOtherTaskBlock,
  setOtherTaskBlockAction,
} from '@store/slices';
import { toInteger } from '@utils/utils';

import styles from './CreateOperationalCardLayout.module.scss';
import { skipToken } from '@reduxjs/toolkit/query';
import { DocumentsCard } from '@features/manageEvents/cards/otherTasksCard/DocumentsCard';
import {
  CreateOtherTasks,
  OtherReport,
  OtherTaskHeader,
} from '@features/manageEvents/cards/otherTasksCard';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { isInteger } from '@utils/utils';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const CreateOtherTasksCardLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: any }>();
  const otherTask = useSelector(getOtherTaskBlockSelector);

  const { setHeaderTitle } = useHeaderTitle();

  const [saveOtherTask] = useSaveOtherTaskBlockMutation();
  const [attachmentsUpload] = useExtraTaskBlockAttachmentsUploadMutation();

  const { data: otherTaskData } = useGetOtherTaskByIdQuery(id ? id : skipToken);

  const attachDocument = useCallback(
    (file: IAttachment) => {
      if (id && isInteger(parseInt(id, 10))) {
        attachmentsUpload({
          attachments: [{ ...file, id: file.attachmentId }],
          id,
        })
          .unwrap()
          .then(() => {
            dispatch(addDocumentToOtherTaskAction(file));
          });
      }
    },
    [id],
  );

  const saveTemplate = (values: any) => {
    const { executor, id, ...rest } = otherTask;
    saveOtherTask({
      ...rest,
      ...values,
      executorId: toInteger(otherTask?.executor?.id),
      systemObjectId: otherTask?.systemObjectId,
      isInternal: false,
      isTemplate: true,
      systemId: toInteger(otherTask.systemId),
      title: otherTask?.title,
    })
      .unwrap()
      .then((response: any) => {
        navigate(generatePath(Routes.OtherTask, { id: response.id }));
      })
      .catch((err) => {
        console.log('err is ', err);
      });
  };

  useEffect(() => {
    setHeaderTitle('Карточка прочих задач');

    return () => {
      dispatch(clearOtherTaskBlock());
    };
  }, []);

  useEffect(() => {
    if (otherTaskData) {
      dispatch(setOtherTaskBlockAction(otherTaskData));
    }
  }, [otherTaskData]);

  return (
    <ColorBlock colorType={ElementColor.default}>
      <Layout className={styles.operationalCardLayout}>
        <Row gutter={[0, 16]} justify="start">
          <Col span={24}>
            <Row gutter={16}>
              <Col>
                <DefaultButton onClick={() => navigate(-1)} className="backBtn">
                  <LeftOutlined />
                  <Typography.Text>Назад</Typography.Text>
                </DefaultButton>
              </Col>
              <Col>
                <Breadcrumb
                  className="breadcrumb"
                  items={[
                    {
                      title: <HomeOutlined />,
                    },
                    {
                      title: 'Карточка прочих задач',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider style={{ margin: '6px 0 8px' }} />
          </Col>

          <Col className={styles.divider} span={24}>
            <OtherTaskHeader />
            <Divider />
          </Col>
          <Col span={24}>
            <InformationalBlock>
              <Row gutter={8}>
                <Col span={12}>
                  <CreateOtherTasks />
                </Col>
                <Col span={12} className={styles.cardWrapper}>
                  <OtherReport />
                  <DocumentsCard />
                </Col>
              </Row>
            </InformationalBlock>
          </Col>
          <Col span={24}>
            <DangerButton
              onClick={() => navigate(Routes.ManageEvents)}
              size="large"
            >
              Отмена
            </DangerButton>
          </Col>
        </Row>
      </Layout>
      <AttachDocumentModal onLoad={attachDocument} />
      <CreateTemplateModal save={saveTemplate} />
      <AssignExecutorModal type={ManageEventsWorkTypes.OTHER_TASKS} />
    </ColorBlock>
  );
};
