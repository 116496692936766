import React from 'react';

import { Col, Row } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './ManageEventsOperationalTable.module.scss';
import { ACCESS } from '@enums/permisson';
import { useLocation } from 'react-router-dom';

export const useOperationalColumns = (isArchived = false) => {
  const location = useLocation();
  const isLocation = location.pathname.includes('/water-object/card/');

  const actions = [
    CustomTableAction.Watch,
    !isLocation &&
      (!isArchived
        ? CustomTableAction.ArchiveWithoutText
        : CustomTableAction.UnArchiveWithoutText),
  ].filter(Boolean) as CustomTableAction[];

  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Категория ',
      align: 'center',
      dataIndex: 'category',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'direction',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.category.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Тип работ ',
      align: 'center',
      dataIndex: 'workType',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'workType',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.workType.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Исполнитель',
      align: 'center',
      dataIndex: 'executor',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'executor',
      renderCustom: ({ record }) => (
        <Row gutter={10}>
          <Col>{record.executor?.firstName}</Col>
          <Col>{record.executor?.middleName}</Col>
          <Col>{record.executor?.lastName}</Col>
        </Row>
      ),
    },
    {
      title: 'Объект',
      align: 'center',
      dataIndex: 'systemObject.name',
      xtype: CustomTableColumnType.String,
      key: 'systemObject.name',
    },
    {
      title: 'Система',
      align: 'center',
      dataIndex: 'system.name',
      xtype: CustomTableColumnType.String,
      key: 'system.name',
      // renderCustom: ({ record }) => (
      //   <div>
      //     <span>{record.system.name}</span>
      //   </div>
      // ),
    },
    {
      title: 'Сроки',
      align: 'center',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 250,
      xtype: CustomTableColumnType.String,
      deadlineTime: 'start',
      className: styles.deadlineColumn,
      children: [
        {
          title: 'Начало',
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          deadlineTime: 'start',
          xtype: CustomTableColumnType.Deadline,
        },
        {
          title: 'Конец',
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          deadlineTime: 'end',
          xtype: CustomTableColumnType.Deadline,
        },
      ],
    },
    {
      title: 'Бюджет',
      align: 'center',
      dataIndex: 'budget',
      key: 'budget',
      width: 270,
      xtype: CustomTableColumnType.Budget,
      className: styles.budgetColumn,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: '...',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: actions,
      actionsAccess: [
        ACCESS.WORK_MANAGEMENT_OPERATIONAL_CARD_EDIT,
        ACCESS.WORK_MANAGEMENT_OPERATIONAL_ARCHIVE,
      ],
    },
  ];
  return { columns };
};
