import lodash from 'lodash';

export function toInteger(value: any) {
  if (value === null || value === undefined) {
    return 0;
  }

  const number = Number(value);

  if (isNaN(number)) {
    return 0;
  }

  if (number === 0 || !isFinite(number)) {
    return number;
  }

  return (number > 0 ? Math.floor(number) : Math.ceil(number));
}
export function pickBy(object: any, predicate: (value: any, key: string) => boolean): any {
  return lodash.pickBy(object, predicate); // TODO: change lodash to native implementation
  // return Object.fromEntries(Object.entries(object).filter(([key, value]) => predicate(value, key)));
}
export function isEmpty(value: any) {
  return lodash.isEmpty(value); // TODO: change lodash to native implementation
  // if (Array.isArray(value) || typeof value === 'string') {
  //   return value.length === 0;
  // } else if (value !== null && typeof value === 'object') {
  //   return Object.keys(value).length === 0;
  // }
  // return false;
}
export function isInteger(value: any) {
  return lodash.isInteger(value); // TODO: change lodash to native implementation
  // return Number.isInteger(value);
}
export function toString(value: any) {
  return value == null ? '' : String(value);
}
export function set<T extends object, K extends keyof any>(obj: T, path: K | K[], value: any): T {
  return lodash.set(obj, path, value); // TODO: change lodash to native implementation
  // if (!obj || typeof obj !== 'object') return obj;
  //
  // const keys = Array.isArray(path) ? path : (path as string).match(/[^.[\]]+/g) as string[];
  //
  // keys.slice(0, -1).reduce((acc, key, index) => {
  //   if (typeof acc[key] !== 'object' || acc[key] === null) {
  //     acc[key] = isNaN(Number(keys[index + 1])) ? {} : [];
  //   }
  //   return acc[key];
  // }, obj as any)[keys[keys.length - 1]] = value;
  //
  // return obj;
}
export function get<T>(obj: T, path: (string | number) | (string | number)[], defaultValue: any = null): any {
  return lodash.get(obj, path, defaultValue); // TODO: change lodash to native implementation
  // if (!obj || typeof obj !== 'object') return defaultValue;
  //
  // if (typeof path === 'string') {
  //   path = path.split('.'); // Разбиваем строку пути на массив ключей
  // } else if (!Array.isArray(path)) {
  //   path = [path]; // Преобразуем path в массив, если он не является массивом или строкой
  // }
  // return path.reduce((acc, key) => (acc !== undefined ? acc[key] : defaultValue), obj as any);
}
export function debounce<F extends (...args: any[]) => any>(func: F, wait: number, options?: any): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return function debounced(...args: Parameters<F>): void {
    const later = () => {
      timeout = undefined;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function sum(numbers: number[]): number {
  return numbers.reduce((acc, curr) => acc + curr, 0);
}
