import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { DefaultButton, Divider } from '@features/ui-kit';
import { CustomSteps } from '@features/ui-kit/customSteps';
import {
  AssociationMasterRegistrationFirstStep,
  AssociationMasterRegistrationFiveStep,
  AssociationMasterRegistrationFourthStep,
  AssociationMasterRegistrationSecondStep,
  AssociationMasterRegistrationThirdStep,
} from '@features/waterRegistry';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';
import { CreateAssociationWaterRegistryQueryTypes } from '@models/waterUser/enums/waterUserEnums';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const AssociationWaterUserMasterRegistrationLayout: FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { t } = useTranslation();
  const queryKey = useMemo(() => params.get(SectionQueryKey), [params]);
  const { setHeaderTitle } = useHeaderTitle();

  const steps = [
    {
      value: CreateAssociationWaterRegistryQueryTypes.waterUserInfo,
      title: 'Данные водопользователя',
    },
    {
      value: CreateAssociationWaterRegistryQueryTypes.addressInfo,
      title: 'Адресные данные',
      disabled:
        !queryKey ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.waterUserInfo,
    },
    {
      value: CreateAssociationWaterRegistryQueryTypes.responsiblePersons,
      title: 'Ответственные лица',
      disabled:
        !queryKey ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.addressInfo ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.waterUserInfo,
    },
    {
      value: CreateAssociationWaterRegistryQueryTypes.outletConnection,
      title: 'Привязка водовыпуска',
      disabled:
        !queryKey ||
        queryKey ===
          CreateAssociationWaterRegistryQueryTypes.outletConnection ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.waterUserInfo,
    },
    {
      value: CreateAssociationWaterRegistryQueryTypes.agreement,
      title: 'Соглашение',
      disabled:
        !queryKey ||
        queryKey ===
          CreateAssociationWaterRegistryQueryTypes.responsiblePersons ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.addressInfo ||
        queryKey === CreateAssociationWaterRegistryQueryTypes.waterUserInfo,
    },
  ];

  let Component;
  switch (queryKey) {
    case CreateAssociationWaterRegistryQueryTypes.waterUserInfo:
      Component = AssociationMasterRegistrationFirstStep;
      break;
    case CreateAssociationWaterRegistryQueryTypes.addressInfo:
      Component = AssociationMasterRegistrationSecondStep;
      break;
    case CreateAssociationWaterRegistryQueryTypes.responsiblePersons:
      Component = AssociationMasterRegistrationThirdStep;
      break;
    case CreateAssociationWaterRegistryQueryTypes.outletConnection:
      Component = AssociationMasterRegistrationFourthStep;
      break;
    case CreateAssociationWaterRegistryQueryTypes.agreement:
      Component = AssociationMasterRegistrationFiveStep;
      break;
    default:
      Component = AssociationMasterRegistrationFirstStep;
      break;
  }

  useEffect(() => {
    setHeaderTitle('Регистрация водопользователя Ассоциаций');
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[0, 16]}>
          <Row gutter={16}>
            <Col>
              <DefaultButton
                onClick={() => navigate(Routes.WaterRegistryAssociations)}
                className="backBtn"
              >
                <LeftOutlined />
                <Typography.Text>Назад</Typography.Text>
              </DefaultButton>
            </Col>
            <Col>
              <Breadcrumb
                className="breadcrumb"
                items={[
                  {
                    title: <HomeOutlined />,
                  },
                  {
                    title: t('Регистрация водопользователя Ассоциаций'),
                  },
                ]}
              />
            </Col>
          </Row>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <CustomSteps
          queryParams={{
            queryType: AnnualApplicationQueryTypes,
            queryKey: SectionQueryKey,
          }}
          steps={steps}
        />
        <Divider />
      </Col>
      <Col span={24}>
        <Component />
      </Col>
    </Row>
  );
};
