import { basicApi as api } from '../basicApi';
import {
  GeoAttribute,
  GeoJsonType,
  type IGeoPortalGroup,
  ILayerGroup,
  type IMapItem,
} from '@models/geoPortal/interfaces/GeoPortal';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    geoControllerGetPortalGroups: build.query<
      GeoControllerGetPortalGroupsApiResponse,
      GeoControllerGetPortalGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/portal/groups`,
        params: {
          region: queryArg.region,
          district: queryArg.district,
          startDate: queryArg?.startDate,
        },
      }),
    }),
    geoControllerGetPortalMaps: build.query<
      GeoControllerGetPortalMapsApiResponse,
      GeoControllerGetPortalMapsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/portal/maps/${queryArg.groupKey}`,
        params: {
          region: queryArg.region,
          district: queryArg.district,
          page: queryArg.page,
          size: queryArg.size,
          startDate: queryArg?.startDate,
        },
      }),
    }),
    mapControllerGetLayers: build.query<
      MapControllerGetLayersApiResponse,
      MapControllerGetLayersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/layers/${queryArg.mapName}`,
        params: queryArg?.startDate ? { startDate: queryArg?.startDate } : {},
      }),
    }),
    mapControllerGetLayersArchive: build.query<
      MapControllerGetLayersArchiveApiResponse,
      MapControllerGetLayersArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/archive/${queryArg.mapName}`,
      }),
    }),
    mapControllerGetData: build.query<
      MapControllerGetDataApiResponse,
      MapControllerGetDataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/data/${queryArg.mapName}/${queryArg.layerName}`,
        params:
          queryArg?.startDate || queryArg?.endDate
            ? { startDate: queryArg?.startDate, endDate: queryArg?.endDate }
            : {},
      }),
      providesTags: ['GeoPortal'],
      keepUnusedDataFor: 60,
    }),
    mapControllerUpdateData: build.mutation<
      MapControllerUpdateDataApiResponse,
      MapControllerUpdateDataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/data/update`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['GeoPortal'],
    }),
    mapControllerGetInfo: build.query<
      MapControllerGetInfoApiResponse,
      MapControllerGetInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/info/${queryArg.objectType}/${queryArg.id}`,
        params: queryArg.params,
      }),
    }),
    mapControllerSearch: build.query<
      MapControllerSearchApiResponse,
      MapControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/geo/map/search/${queryArg.mapName}`,
        params: { query: queryArg.query },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GeoControllerGetPortalGroupsApiResponse = Array<IGeoPortalGroup>;
export type GeoControllerGetPortalGroupsApiArg = {
  region?: number;
  district?: number;
  startDate?: string;
};
export type GeoControllerGetPortalMapsApiResponse = {
  items: Array<IMapItem>;
  totalCount: number;
};
export type GeoControllerGetPortalMapsApiArg = {
  groupKey: string;
  region?: number;
  district?: number;
  page?: number;
  size?: number;
  startDate?: string;
};
export type MapControllerGetLayersArchiveApiResponse = GeoJsonType;
export type MapControllerGetLayersApiResponse = Array<ILayerGroup>;
export type MapControllerGetLayersApiArg = {
  mapName: string;
  startDate?: string | null;
};
export type MapControllerGetLayersArchiveApiArg = {
  mapName: string | null;
};
export type MapControllerGetDataApiResponse = Array<GeoAttribute>;
export type MapControllerGetDataApiArg = {
  mapName: string;
  layerName: string;
  startDate?: string | null;
  endDate?: string | null;
};
export type MapControllerUpdateDataApiResponse = unknown;
export type MapControllerUpdateDataApiArg = {
  body: {
    data: GeoportalPointDto[];
  };
};
export type MapControllerGetInfoApiResponse = any;
export type MapControllerGetInfoApiArg = {
  objectType: string;
  id: string;
  params?: {
    startDate?: string;
    endDate?: string;
  };
};
export type MapControllerSearchApiResponse = Array<GeoAttribute>;
export type MapControllerSearchApiArg = {
  mapName: string;
  query: string;
};
export type GeoportalPointDto = {
  id: string;
  layerName: string;
  coordinates: string;
};
export const {
  useGeoControllerGetPortalGroupsQuery,
  useGeoControllerGetPortalMapsQuery,
  useMapControllerGetLayersQuery,
  useMapControllerGetLayersArchiveQuery,
  useMapControllerGetDataQuery,
  useLazyMapControllerGetDataQuery,
  useMapControllerUpdateDataMutation,
  useMapControllerGetInfoQuery,
  useMapControllerSearchQuery,
} = injectedRtkApi;
