import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import {
  DefaultButtonV2,
  PrimaryButtonV2,
  TooltipedInputV2,
} from '@features/ui-kit-v2';
import { ICreateReservoirData } from '@models/reservoir/interfaces/reservoir';
import {
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
} from '@features/ui-kit';

import styles from './ReservoirMonitoringPopoperContent.module.scss';
import { CloseOutlined } from '@ant-design/icons';

type ReservoirMonitoringPopoverContentPropTypes = {
  hide: () => void;
  action: (values: ICreateReservoirData) => void;
};

export const ReservoirMonitoringPopoverContent: React.FC<
  ReservoirMonitoringPopoverContentPropTypes
> = ({ hide, action }) => {
  const [form] = Form.useForm();

  const onFinish = (values: ICreateReservoirData) => {
    action(values);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className={styles.form}
      onFinish={onFinish}
    >
      <Row>
        <Col span={22}>
          <Typography.Title level={5}>
            Ввод данных водохранилища
          </Typography.Title>
          <Divider className={styles.divider} />
        </Col>
        <Col span={2}>
          <div>
            <DangerButton onClick={hide} icon={<CloseOutlined />} />
          </div>
        </Col>
      </Row>
      <Space size="small" direction="vertical" className={styles.generalStep}>
        <Typography.Title level={5}>
          Сработка общая (м<sup>3</sup>/c)
        </Typography.Title>

        <Col>
          <Form.Item name="outflow">
            <TooltipedInput placeholder="Введите данные" type="number" />
          </Form.Item>
        </Col>
      </Space>
      <Space size="small" direction="vertical" className={styles.generalStep}>
        <Typography.Title level={5}>
          Сработка др. страны (м<sup>3</sup>/c)
        </Typography.Title>
        <Col>
          <Form.Item label="Узбекистан" name="outflowUz">
            <TooltipedInput placeholder="Введите данные" type="number" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Казахстан" name="outflowKz">
            <TooltipedInput placeholder="Введите данные" type="number" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Таджикистан" name="outflowTj">
            <TooltipedInput placeholder="Введите данные" type="number" />
          </Form.Item>
        </Col>
      </Space>

      <Space size="small" direction="vertical" className={styles.generalStep}>
        <Typography.Title level={5}>
          Приток (м<sup>3</sup>/c)
        </Typography.Title>
        <Col>
          <Form.Item name="influx" label="Казахстан">
            <TooltipedInput type="number" />
          </Form.Item>
        </Col>
      </Space>

      <Row gutter={15} justify="center">
        <Col>
          <DefaultButton onClick={hide}>Отмена</DefaultButton>
        </Col>
        <Col>
          <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
        </Col>
      </Row>
    </Form>
  );
};
