import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SegmentedValue } from 'antd/es/segmented';

import { quartalOptions } from '@constants/quartalOptions';
import { QuarterQueryKey } from '@constants/queryKeys';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomSegmented,
  FilterButtonV2,
  ResetFilterButtonV2,
  TooltipedSelectV2,
  TooltipedYearPickerV2,
} from '@features/ui-kit-v2';
import styles from '@features/waterQuality/tables/dictionaryZoneTable/DictionaryZoneTable.module.scss';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import { IWaterQualityFilters } from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetFacilitiesByFilterQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';
import { PrimaryButton, TooltipedSelect, YearPicker } from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { useGetCurrentUser } from '@features/authentication';

export const ReclamationSoilDataFilters = () => {
  const [filtersState, setFiltersState] = useState<IWaterQualityFilters>({});
  const { filters, setDictionaryTableFilters } = useEnteringDataTablesFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const onFilterChange = (filterKey: string, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
    }));
  };

  const onDisctrictChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: v,
    }));
  };

  const onSelectQuarter = (value: SegmentedValue) => {
    setFiltersState((state) => ({ ...state, quarter: +value }));
    setDictionaryTableFilters({
      ...filtersState,
      quarter: +value,
    } as IWaterQualityFilters);
  };

  const onResetFilterButtonClick = () => {
    setDictionaryTableFilters({
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      year: null,
      quarter: null,
    });
  };

  const onFilterButtonClick = () => {
    setDictionaryTableFilters(filtersState as IWaterQualityFilters);
  };
  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Область"
          value={
            regions.find((item) => item.id === toInteger(filtersState.regionId))
              ?.label
          }
          onChange={(v: string) => onRegionChange(v)}
          options={regions}
          disabled={isDisabledRegion}
          allowClear
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Район"
          options={districts}
          disabled={isDisabledDistrict || !filtersState.regionId}
          value={
            districts.find(
              (item) => item.id === toInteger(filtersState.districtId),
            )?.label
          }
          onChange={(v: string) => onDisctrictChange(v)}
          allowClear
        />
      </Col>
      <Col>
        <YearPicker
          placeholder="Год"
          disabledDate={(current) => current && current > dayjs().endOf('year')}
          value={filtersState.year ? dayjs(filtersState.year) : undefined}
          onChange={(value: Dayjs | null) =>
            onFilterChange('year', String(value?.year()))
          }
        />
      </Col>
      <Col>
        <Row gutter={10}>
          <Col>
            <PrimaryButton
              onClick={onFilterButtonClick}
              icon={<FilterIcon />}
            />
          </Col>

          <Col>
            <PrimaryButton
              onClick={onResetFilterButtonClick}
              icon={<ResetFilterIcon />}
            />
          </Col>
        </Row>
      </Col>
      {filters.year && (
        <Col span={24}>
          <CustomSegmented
            colorType="primary"
            defaultValue={1}
            options={[
              {
                value: '0',
                label: filters.year,
                disabled: true,
              },
              ...quartalOptions,
            ]}
            queryParams={{
              queryKey: QuarterQueryKey,
              queryType: QuarterQueryKey,
            }}
            onChange={onSelectQuarter}
            block
          />
        </Col>
      )}
    </Row>
  );
};
