export enum Links {
  // table
  ViewButton = '/icons/table/viewButton.svg',
  EditButton = '/icons/table/editButton.svg',
  RemoveButton = '/icons/table/removeButton.svg',
  // documents
  SuccessUpload = '/icons/documents/successUpload.svg',
  Pdf = '/icons/documents/pdf.svg',
  Docx = '/icons/documents/docx.svg',
  Jpg = '/icons/documents/jpg.svg',
  Png = '/icons/documents/png.svg',
  Xlsx = '/icons/documents/xlsx.svg',
  Heic = '/icons/documents/heic.svg',
  OtherDocType = '/icons/documents/ohter.svg',
  PdfDoc = '/icons/pdf-doc.svg',
  Doc = '/icons/pencil-file.svg',
  Logo = '/images/logo.png',
  Logo2 = '/icons/logo.svg',
  SmallLogo = '/images/smallLogo.png',
  FullLogo = '/images/fullLogo.png',
  CountryMap = '/images/countryMap.png',
  Background1 = '/images/background1.jpg',
  Background2 = '/images/background2.jpg',
  Background3 = '/images/background3.jpg',
  Background4 = '/images/background4.jpg',
  Gis = '/images/gis.png',
  ArrowLeft = '/icons/Left.svg',
  Pin = '/icons/pin.svg',
  View = '/icons/view.svg',
  Edit = '/icons/edit.svg',
  Delete = '/icons/delete.svg',
  ArrowRight = '/icons/Right.svg',
  BurgerButton = '/icons/burgerIcon.svg',
  Info = '/icons/infoBorder.svg',
  BlackButton = '/icons/Black1.svg',
  BlackButtonTwo = '/icons/Black2.svg',
  Print = '/icons/printIcon.svg',
  Check = '/icons/check.svg',
  Location = '/icons/location.svg',
  BlackButtonThree = '/icons/Black3.svg',
  BlackButtonFour = '/icons/Black4.svg',
  BookButton = '/icons/Book.svg',
  DangerButton = '/icons/Danger.svg',
  DefaultButton = '/icons/Default.svg',
  PrintButton = '/icons/print.svg',
  InfoButton = '/icons/Info.svg',
  InfoButtonTwo = '/icons/Info2.svg',
  PinkButton = '/icons/Pink.svg',
  DashboardButton = '/icons/Primary.svg',
  SuccessButton = '/icons/Success.svg',
  WarningButton = '/icons/Warning.svg',
  TelegramIcon = '/icons/TelegramIcon.svg',
  WhatsAppIcon = '/icons/WhatsAppIcon.svg',
  QuestionIcon = '/icons/questionIcon.svg',
  CheckIcon = '/icons/checkIcon.svg',
  File = '/icons/file.svg',
  Blank = '/icons/blank.svg',
  Constructor = '/icons/constructor.svg',
  Metka = '/icons/metka.svg',
  WhiteKub = '/icons/whiteKub.svg',
  WhiteTesseract = '/icons/whiteTesseract.svg',
  Plus = '/icons/plus.svg',
  Archive = '/icons/archive.svg',
  Kub = '/icons/kub.svg',
  Document = '/icons/Group.svg',
  DisabledDocument = '/icons/DisabledGroup.svg',
  BigDocument = '/icons/BigGroup.svg',
  DoubleBlank = '/icons/doubleBlank.svg',
  Eye = '/icons/eye.svg',
  Map = '/images/map.png',
  LocationMap = '/images/locationMap.png',
  GisMap = '/images/gisMap.png',
  Wheel = '/icons/wheel.svg',
  Tools = '/icons/tools.svg',
  Settings = '/icons/settings.svg',
  Gaika = '/icons/gaika.svg',
  Warn = '/icons/warn.svg',
  Time = '/icons/time.svg',
  Kran = '/icons/kran.svg',
  Grid = '/icons/grid.svg',
  Pass = '/icons/pass.svg',
  CheckMark = '/icons/checkMark.svg',
  Note = '/icons/note.svg',
  Close = '/icons/close.svg',
  List = '/icons/list.svg',
  Gears = '/icons/gears.svg',
  TubeGears = '/icons/tubeGears.svg',
  Dollar = '/icons/dollar.svg',
  Money = '/icons/money.svg',
  Remainder = '/icons/remainder.svg',
  ReverseMoney = '/icons/reverseMoney.svg',
  Implementation = '/icons/implementation.svg',
  WaterSupply = '/icons/waterSupply.svg',
  ValveOutline = '/icons/valveOutline.svg',
  Building = '/icons/building.svg',
  BungalowVilla = '/icons/bungalowVilla.svg',
  WaterCran = '/icons/waterCran.svg',
  PipelineOutline = '/icons/pipelineOutline.svg',
  Builds = '/icons/builds.svg',
  CommercialComplex = '/icons/commercialComplex.svg',
  Mail = '/icons/mail.svg',
  Message = '/icons/message.svg',
  Letter = '/icons/letter.svg',
  Architect = '/icons/architect.svg',
  BoldPlus = '/icons/boldPlus.svg',
  DocList = '/icons/docList.svg',
  DownArrow = '/icons/downArrow.svg',
  Edits = '/icons/edits.svg',
  CloseIcon = '/icons/closeIcon.svg',
  Watch = '/icons/watch.svg',
  CheckBlue = '/icons/checkBlue.svg',
  YellowWarning = '/icons/yellowWarning.svg',
  BigEye = '/icons/bigEye.svg',
  BookIcon = '/icons/bookIcon.svg',
  Logout = '/icons/logout.svg',
  Dash = '/icons/dash.svg',
  Stats = '/icons/stats.svg',
  Puzzle = '/icons/puzzle.svg',
  NoteList = '/icons/noteList.svg',
  Lock = '/icons/lock.svg',
  Chart = '/icons/chart.svg',
  Notification = '/icons/notification.svg',
  AettingsIcon = '/icons/settingsIcon.svg',
  ArrowDown = '/icons/arrowDown.svg',
  Feather = '/icons/feather.svg',
  BlueBoldPlus = '/icons/blueBoldPlus.svg',
  BlueEdit = '/icons/blueEdit.svg',
  BlueDelete = '/icons/blueDelete.svg',
  WhitePlus = '/icons/whitePlus.svg',
  Burger = '/icons/burger.svg',
  Question = '/icons/question.svg',
  BlackEdit = '/icons/blackEdit.svg',
  AppRegister = '/icons/appRegister.svg',
  CheckCard = '/icons/checkCard.svg',
  LocationIcon = '/icons/locationIcon.svg',
  Home = '/icons/home.svg',
  AddExecutor = '/icons/addExecutor.svg',
  PrintBig = '/icons/printBig.svg',
  GreenCheck = '/icons/greenCheck.svg',
  // Header Backgrounds
  BlueTop = '/images/headerBgBlue.jpg',
  BlueBottom = '/images/headerBgBlueBottom.jpg',
  GreenTop = '/images/headerBgGreen.jpg',
  GreenBottom = '/images/headerBgGreenBottom.jpg',
  YellowTop = '/images/headerBgYellow.jpg',
  YellowBottom = '/images/headerBgYellowBottom.jpg',
  OrangeTop = '/images/headerBgOrangeTop.jpg',
  OrangeBottom = '/images/headerBgOrangeBottom.jpg',
  WaterUserAll = '/images/headerWaterUserAll.jpg',
  WaterUserAllBottom = '/images/headerWaterUserAllBottom.jpg',
  WaterUserIndividual = '/images/headerIndividual.jpg',
  WaterUserIndividualBottom = '/images/headerIndividualBottom.jpg',
  WaterMainFacilityTop = '/images/waterMainFacilityTop.jpg',
  WaterMainFacilityBottom = '/images/waterMainFacilityBottom.jpg',
  WaterSupplyTop = '/images/waterPlanSupplyHeader.jpg',
  WaterSupplyBottom = '/images/waterPlanSupplyHeaderBottom.jpg',
  WaterPlanOperHeader = '/images/waterPlanOperHeader.jpg',
  WaterPlanOperHeaderBottom = '/images/waterPlanOperHeaderBottom.jpg',
  // SideBar
  WaterUsers = '/icons/waterUsers.svg',
  Request = '/icons/request.svg',
  WaterAccounting = '/icons/waterAccounting.svg',
  Diagram = '/icons/diagram.svg',
  Calendar = '/icons/calendar.svg',
  Cash = '/icons/cash.svg',
  PrivateManage = '/icons/privateManage.svg',
  Dogovor = '/icons/dogovor.svg',
  Directory = '/icons/directory.svg',
  Card = '/icons/card.svg',
  Stack = '/icons/stack.svg',
  Tractor = '/icons/tractor.svg',
  Layer = '/icons/layer.svg',
  UnvisibleEye = '/icons/unvisibleEye.svg',
  // Header
  BookMark = '/icons/bookMark.svg',
  Bell = '/icons/bell.svg',

  // Sidebar
  SideTrigger = '/icons/sideTrigger.svg',

  // userLogo = '/images/userLogo.png',
  userLogo = '/images/kgLogo.png',
}
