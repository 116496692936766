import { generatePath } from 'react-router-dom';
import { basicApi as api } from '../basicApi';
import { Tags } from '@constants/tags';
import {
  UpdateBankDetailsApiArg,
  UpdateBankDetailsApiResponse,
  UpdateContactsApiArg,
  UpdateContactsApiResponse,
  UpdateLegalEntityMainInfoApiArg,
  UpdateLegalEntityMainInfoApiResponse,
  UpdateMainInfoApiArg,
  UpdateMainInfoApiResponse,
} from '../models/waterUsersModel';

const mainBaseUrl = process.env.REACT_APP_MAIN_BASE_URL;
const URLS = {
  mainInfo: `${mainBaseUrl}/api/v1/water-user/:id/main-info`,
  contacts: `${mainBaseUrl}/api/v1/water-user/:id/contacts`,
  bankDetails: `${mainBaseUrl}/api/v1/water-user/:id/bank-details`,
};
// url: URLS.findBankById.replace(':id', queryArg.id.toString()),
const waterUsersRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateMainInfo: build.mutation<
      UpdateMainInfoApiResponse,
      UpdateMainInfoApiArg
    >({
      query: (queryArg) => ({
        method: 'PUT',
        url: URLS.mainInfo.replace(':id', queryArg.id.toString()),
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.WaterUserPhysical],
    }),
    updateLegalEntityMainInfo: build.mutation<
      UpdateLegalEntityMainInfoApiResponse,
      UpdateLegalEntityMainInfoApiArg
    >({
      query: (queryArg) => ({
        method: 'PUT',
        url: URLS.mainInfo.replace(':id', queryArg.id.toString()),
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.WaterUserPhysical],
    }),
    updateContacts: build.mutation<
      UpdateContactsApiResponse,
      UpdateContactsApiArg
    >({
      query: (queryArg) => ({
        method: 'PUT',
        url: URLS.contacts.replace(':id', queryArg.id.toString()),
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.WaterUserPhysical],
    }),
    updateBankDetails: build.mutation<
      UpdateBankDetailsApiResponse,
      UpdateBankDetailsApiArg
    >({
      query: (queryArg) => ({
        method: 'PUT',
        url: URLS.bankDetails.replace(':id', queryArg.id.toString()),
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.WaterUserPhysical],
    }),
  }),
  overrideExisting: false,
});

export const {
  useUpdateMainInfoMutation,
  useUpdateContactsMutation,
  useUpdateBankDetailsMutation,
  useUpdateLegalEntityMainInfoMutation,
} = waterUsersRtkApi;
