import { notification, Tag } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderGeo<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value: string = record[customColumn.dataIndex];
  return (
    <div>
      <Tag
        bordered={false}
        className={styles.geoTag}
        onClick={() => {
          notification.warning({
            message: 'Информация о геолокации не указана',
          });
        }}
      >
        ГИС
      </Tag>
      {value}
    </div>
  );
}
