import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import { CreateButton, SectionTitle } from '@features/ui-kit-v2';
import { ITariffFilters } from '@models/tariff/interfaces/tariff';
import { useFindAllTariffListQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { TariffMonitoringTableContainer } from './tariffMonitoringTableContainer';
import { tariffMonitoringDataMapper } from './tariffMonitoringTableContainer/tariffMonitoringDataMapper';
import { useTariffMonitoringTableColumns } from './tariffMonitoringTableContainer/useTariffMonitoringTableColumns';
import { TariffMonitoringTableFilters } from './tariffMonitoringTableFilters';
import { useTariffMonitoringTableFilters } from './tariffMonitoringTableFilters/useTariffMonitoringTableFilters';
import { useGetCurrentUser } from '@features/authentication';

export const TariffMonitoringTable = () => {
  const [params] = useSearchParams();

  const { filters, setTariffMonitoringFilters } =
    useTariffMonitoringTableFilters();

  const {
    data: tariffs = [],
    isLoading,
    isFetching,
  } = useFindAllTariffListQuery(filters);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { columns } = useTariffMonitoringTableColumns();

  // const mappedData = tariffMonitoringDataMapper(tariffs ?? []);

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setTariffMonitoringFilters(
      isEmpty(newFilters)
        ? ({
            isActive: true,
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as ITariffFilters)
        : ({ isActive: true, ...newFilters } as unknown as ITariffFilters),
    );
  }, []);

  return (
    <>
      <TariffMonitoringTableFilters />
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col>
                <Typography.Title level={4}>
                  Таблица эксплуатационного блока
                </Typography.Title>
              </Col>
            </Row>
          }
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={tariffs}
            bordered
            columns={columns}
            size="small"
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
