import React from 'react';
import { Link } from 'react-router-dom';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useMonitoringColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      xtype: CustomTableColumnType.NumberColumn,
      width: 10,
    },
    {
      title: 'Название',
      dataIndex: 'display_name',
      key: 'display_name',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 20,
    },
    {
      title: 'Тип гидропоста',
      dataIndex: 'hp_type_name',
      key: 'hp_type_name',
      align: 'center',
      xtype: CustomTableColumnType.RenderCustom,
      width: 20,
      renderCustom: ({ record }) => {
        let htType;
        switch (record.hp_type_name) {
          case 'hpType.intake':
            htType = 'Водозаборный';
            break;
          case 'hpType.commerce':
            htType = 'Коммерческий';
            break;
          case 'hpType.magistralIntake':
            htType = 'Подпитка из маг.канала';
            break;
          case 'hpType.balance':
            htType = 'Балансовый';
            break;
          case 'hpType.reserveIntake':
            htType = 'Подпитка из БСР';
            break;
          case 'hpType.magistralDiscard':
            htType = 'Сброс в маг.канал';
            break;
          case 'hpType.reserveSupply':
            htType = 'Сброс в БСР';
            break;
          case 'hpType.intakePlusComm':
            htType = 'Водозаборный/Коммерчески';
            break;
          default:
            htType = '';
            break;
        }
        return <div> {htType}</div>;
      },
    },
    {
      title: 'Водозабор',
      dataIndex: 'watercourseqweqw',
      key: 'watercourseqwe',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 20,
    },
    {
      title: 'Среднесуточный расход',
      dataIndex: 'flow',
      key: 'flow',
      align: 'center',
      xtype: CustomTableColumnType.NumberColumn,
      width: 20,
    },
    {
      title: '3D-модель',
      dataIndex: 'model_link',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'model_link',
      width: 20,
      align: 'center',
      renderCustom: ({ record }) =>
        record.model_link && (
          <Link to={record.model_link}>
            <p>Показать</p>
          </Link>
        ),
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      width: 40,
      align: 'center',
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
