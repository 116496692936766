import { FC } from 'react';

import { Col, Row, Typography, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import { BigPropertyBlock, CustomCard } from '@features/ui-kit';
import Icon from '@ant-design/icons';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { PipeIcon } from '@icons/PipeIcon';
import { WearSumTable } from '@features/waterObject/waterObjectCard/shared/tables';

export const SystemIndicatorsOtherAquaductsComponent: FC = () => {
  const { data: formTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  const { data: facingTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: soilTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionSoilTypes,
  });

  return (
    <>
      <Row gutter={8} style={{ marginTop: 8 }}>
        <Col span={12}>
          <CustomCard title="Системные показатели">
            <Row justify="space-between" gutter={16}>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name={['status', 'maxFlowRateInBeginning']}>
                      <BigPropertyBlock
                        icon={PipeIcon}
                        label="Макс. расход (в начале участка м3)"
                        type="input"
                        suffix="л/с"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name={['status', 'flowRateSpeed']}>
                      <BigPropertyBlock
                        icon={PipeIcon}
                        label="Скорость потока (м3)"
                        type="input"
                        suffix="л/с"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name={['status', 'permeability']}>
                      <BigPropertyBlock
                        icon={PipeIcon}
                        label="Проницаемость"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name={['status', 'averageKpd']}>
                      <BigPropertyBlock
                        icon={PipeIcon}
                        label="КПД"
                        type="input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <CustomCard
                  light
                  title={
                    <Row align="middle">
                      <Col>
                        <Icon component={SizesIcon} />
                      </Col>
                      <Col>
                        <Typography.Title style={{ marginBottom: 0 }} level={4}>
                          Размеры и габариты
                        </Typography.Title>
                      </Col>
                    </Row>
                  }
                >
                  <Col span={24}>
                    <Form.Item name={['status', 'widthW']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Ширина, м (B)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'bottomWidthb']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Ширина по дну, м (b)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'heightH']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Высота, м (h)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'constructionHeight']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Высота строения, м (H стр.)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'lengthL']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Протяженность, км (L)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'areaHectares']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Площадь, Га"
                        type="input"
                      />
                    </Form.Item>
                  </Col>
                </CustomCard>
              </Col>

              <Col span={12}>
                <CustomCard
                  light
                  title={
                    <Row align="middle">
                      <Col>
                        <Icon component={ConstructionIcon} />
                      </Col>
                      <Col>
                        <Typography.Title style={{ marginBottom: 0 }} level={4}>
                          Конструктивные характеристики
                        </Typography.Title>
                      </Col>
                    </Row>
                  }
                >
                  <Col span={24}>
                    <Form.Item name={['status', 'formId']}>
                      <PropertyBlock
                        icon={ConstructionIcon}
                        options={formTypes}
                        label="Форма"
                        type="select"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'facingId']}>
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Облицовка"
                        options={facingTypes}
                        type="select"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="direction">
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Направление"
                        type="radio"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'bottomAngle']}>
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Угол наклона дна"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'soilId']}>
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Грунт"
                        options={soilTypes}
                        type="select"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'sedimentLevel']}>
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Уровень наносов"
                        type="input"
                      />
                    </Form.Item>
                  </Col>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
