import { useParams } from 'react-router-dom';

import { Table } from 'antd';

import {
  useFindHydropostsByWithTableQuery,
  useFindSystemsByWithFiltersQuery,
} from '@store/gisproApi';
import { roundBigInteger } from '@utils/roundBigInteger';
import dayjs from 'dayjs';

import style from './monitoringColumn.module.scss';
import { useBalanceSheetColumns } from './useBalanceSheetColumns';

interface GroupedData {
  day: string;
  [hour: string]: string | undefined;
}

const generateEmptyDay = (dayNumber: number): GroupedData => ({
  day: dayNumber.toString(),
});

export const BalanceSheetTable: React.FC = () => {
  const { id = '' } = useParams();
  const { data: table = [], isLoading } = useFindHydropostsByWithTableQuery({
    id: +id,
    start_date: dayjs().startOf('month').unix().toString(),
    end_date: dayjs().endOf('month').unix().toString(),
  });

  const groupedData: { [key: string]: GroupedData } = {};

  const { columns } = useBalanceSheetColumns();

  table.forEach((item: any) => {
    const dateKey = dayjs.unix(item.utc).format('YYYY-MM-DD');
    const hourKey = dayjs.unix(item.utc).format('H');

    if (!groupedData[dateKey]) {
      groupedData[dateKey] = { day: dateKey.split('-')[2] };
    }

    groupedData[dateKey][hourKey] = Number(item.flow * 3600).toFixed();
  });

  const transformedData = Array.from({ length: 30 }, (_, index) => {
    const dayNumber = index + 1;
    const dateKey = dayjs()
      .startOf('month')
      .add(dayNumber - 1, 'day')
      .format('YYYY-MM-DD');
    const transformedRow = groupedData[dateKey] || generateEmptyDay(dayNumber);
    return transformedRow;
  });

  const rowsWithSumAndAvg: GroupedData[] = [];
  transformedData.forEach((row, index) => {
    rowsWithSumAndAvg.push(row);
    if ((index + 1) % 10 === 0) {
      // Вычисляем сумму для каждого часа
      const sumRow: GroupedData = {
        day: 'Сумма, м³',
      };
      const avgRow: GroupedData = {
        day: 'Ср.расход, м³/с',
      };
      for (let i = 0; i < 24; i += 1) {
        const valuesToSum: number[] = transformedData
          .slice(index - 9, index + 1)
          .map((r: GroupedData) => parseInt(r[i.toString()] || '0', 10));

        const sum = valuesToSum
          .reduce((acc, value) => acc + value, 0)
          .toString();
        const avg = roundBigInteger(parseFloat(sum) / 3600, 1000);

        sumRow[i.toString()] = sum.toString();
        avgRow[i.toString()] = avg.toString();
      }

      rowsWithSumAndAvg.push(sumRow);
      rowsWithSumAndAvg.push(avgRow);
    }
  });

  return (
    <Table
      columns={columns}
      dataSource={rowsWithSumAndAvg}
      loading={isLoading}
      pagination={false}
      size="small"
      className={style.tableName}
    />
  );
};
