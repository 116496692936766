import { MonthEnums } from '@enums/month';
import dayjs, { Dayjs } from 'dayjs';

const monthObj = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
export const getDaysInMonth = (month: MonthEnums, year: Dayjs) => {
  const monthNumber = monthObj[month];
  // const monthInNumber = dayjs(month, 'MMMM').month();
  const daysInMonth = dayjs(`${year.year()}-${monthNumber}-01`).daysInMonth();
  return daysInMonth;
};
