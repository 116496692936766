import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsShowEnterEveningValuesModal,
  getSelectedEveningRecrod,
} from '@store/selectors';
import {
  setSelectedEveningRecordAction,
  setShowEnterEveningValuesModalAction,
} from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (isShow: boolean) => void;
  selectedEveningRecord: any;
  setSelectedEveningRecord: (record: any) => void;
};

export const useEnterMonitoringValuesEveningModal = (): ReturnType => {
  const dispatch = useDispatch();

  const isModalShown = useSelector(getIsShowEnterEveningValuesModal);
  const selectedEveningRecord = useSelector(getSelectedEveningRecrod);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(setShowEnterEveningValuesModalAction(value));
  }, []);

  const setSelectedEveningRecord = useCallback((record: any) => {
    dispatch(setSelectedEveningRecordAction(record));
  }, []);

  return {
    setSelectedEveningRecord,
    selectedEveningRecord,
    isModalShown,
    setShowModal,
  };
};
