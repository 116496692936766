import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import {
  DangerButton,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { useAddEmployee } from '@features/waterRegistry/masterRegistration/useAddEmployee';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  CreateAssociationWaterRegistryQueryTypes,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { EmployeeDto, useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import { clearFormDataAction, setFormData } from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import {
  AddAccountant,
  AddAssociationManager,
  AddEmployee,
  EmployeesTable,
  LegalAttachments,
} from '../fields';
import styles from './styles.module.scss';

type AssociationMasterRegistrationThirdStepFormTypes = {
  manager: EmployeeDto;
  accountant: EmployeeDto;
};

export const AssociationMasterRegistrationThirdStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);

  const attachmentResponseDtos = {
    manager: Form.useWatch(['manager', 'attachmentsList'], form) || [],
    accountant:
      Form.useWatch(['accountant', 'attachmentsList'], form) || [],
  };
  const { employees } = useAddEmployee();
  const { data: positionsList = [], isLoading: positionsListIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.PositionsList,
    });

  const validateAttachments = useMemo(() => {
    const hasManagerEstimate = attachmentResponseDtos.manager.some(
      (att: IAttachment) => att.type === 'основание',
    );
    const hasManagerDefect = attachmentResponseDtos.manager.some(
      (att: IAttachment) => att.type === 'паспорт',
    );
    const hasAccountantEstimate = attachmentResponseDtos.accountant.some(
      (att: IAttachment) => att.type === 'паспорт',
    );
    return hasManagerEstimate && hasManagerDefect && hasAccountantEstimate;
  }, [attachmentResponseDtos]);

  const onFinish = (
    values: AssociationMasterRegistrationThirdStepFormTypes,
  ) => {
    dispatch(
      setFormData({
        data: {
          initialEmployees: values,
          employees: [
            {
              ...values.manager,
              positionEmployee: positionsList.find(
                (item) => item.title === 'Директор',
              )?.id,
            },
            {
              ...values.accountant,
              positionEmployee: positionsList.find(
                (item) => item.title === 'бухгалтер',
              )?.id,
            },
            ...employees,
          ],
        },
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.outletConnection}`,
    );
  };

  const onFinishFailed = (
    err: ValidateErrorEntity<AssociationMasterRegistrationThirdStepFormTypes>,
  ) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(Routes.WaterRegistryUsers);
  };

  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.addressInfo}`,
    );
  };

  useEffect(() => {
    if (currentWaterRegistry[WaterUserTypes.association]) {
      form.setFieldsValue(
        currentWaterRegistry[WaterUserTypes.association].initialEmployees,
      );
    }
  }, [currentWaterRegistry]);

  return (
    <Form<AssociationMasterRegistrationThirdStepFormTypes>
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <InformationalBlock title="Ответственные лица">
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <AddAssociationManager />
          </Col>
          <Col span={24}>
            <AddAccountant />
          </Col>
          <Col span={24}>
            <EmployeesTable />
          </Col>
        </Row>
      </InformationalBlock>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DangerButton
              size="large"
              htmlType="submit"
              onClick={onCancelButtonClick}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <DefaultButton
                  size="large"
                  type="default"
                  htmlType="submit"
                  onClick={onBackButtonClick}
                >
                  {t('createIndividualWaterRegistry.buttons.back')}
                </DefaultButton>
              </Form.Item>
              <Form.Item>
                <PrimaryButton
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!validateAttachments}
                >
                  {t('createLegalWaterRegistry.buttons.next')}
                </PrimaryButton>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
