import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const UploadFileIcon: React.FC<IconProps> = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5" clipPath="url(#clip0_5921_171114)">
        <path
          d="M14.0625 8.57513V12.2501C14.0625 12.5982 13.9242 12.9321 13.6781 13.1782C13.4319 13.4244 13.0981 13.5626 12.75 13.5626H2.25C1.9019 13.5626 1.56806 13.4244 1.32192 13.1782C1.07578 12.9321 0.9375 12.5982 0.9375 12.2501V8.57513C0.9375 8.36628 1.02047 8.16597 1.16815 8.01829C1.31584 7.8706 1.51614 7.78763 1.725 7.78763C1.93386 7.78763 2.13416 7.8706 2.28185 8.01829C2.42953 8.16597 2.5125 8.36628 2.5125 8.57513V11.9876H12.4875V8.57513C12.4875 8.36628 12.5705 8.16597 12.7182 8.01829C12.8658 7.8706 13.0661 7.78763 13.275 7.78763C13.4839 7.78763 13.6842 7.8706 13.8318 8.01829C13.9795 8.16597 14.0625 8.36628 14.0625 8.57513ZM5.43216 4.40729L6.7125 3.12826V8.57513C6.7125 8.78399 6.79547 8.98429 6.94315 9.13198C7.09084 9.27966 7.29114 9.36263 7.5 9.36263C7.70886 9.36263 7.90916 9.27966 8.05685 9.13198C8.20453 8.98429 8.2875 8.78399 8.2875 8.57513V3.12826L9.56784 4.40926C9.71579 4.5572 9.91644 4.64031 10.1257 4.64031C10.3349 4.64031 10.5355 4.5572 10.6835 4.40926C10.8314 4.26132 10.9145 4.06067 10.9145 3.85145C10.9145 3.64222 10.8314 3.44157 10.6835 3.29363L8.05847 0.668633C7.98531 0.595217 7.89837 0.536965 7.80265 0.497218C7.70693 0.457472 7.6043 0.437012 7.50066 0.437012C7.39701 0.437012 7.29438 0.457472 7.19866 0.497218C7.10294 0.536965 7.01601 0.595217 6.94284 0.668633L4.31784 3.29363C4.24459 3.36689 4.18648 3.45385 4.14684 3.54956C4.1072 3.64527 4.08679 3.74785 4.08679 3.85145C4.08679 4.06067 4.1699 4.26132 4.31784 4.40926C4.46579 4.5572 4.66644 4.64031 4.87566 4.64031C5.08488 4.64031 5.28553 4.5572 5.43347 4.40926L5.43216 4.40729Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5921_171114">
          <rect width="14" height="14" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
