import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { useGetAllOperationalQuery } from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { Tabs } from '@features/ui-kit';
import { useContext, useEffect, useMemo } from 'react';
import { useSystemObjectControllerFindBySystemObjectIdQuery } from '@store/api/systemObjectApi';
import { useParams, useSearchParams } from 'react-router-dom';
import { SystemObjectGeoPortalType } from '@models/systemObject/systemObjectEnum';
import { MonitoringBlock } from './monitoringBlock/MonitoringBlock';
import { useMapControllerGetInfoQuery } from '@store/api/geoPortalApi';
import { InfoBlock } from './infoBlock/InfoBlock';
import { OperationalBlock } from './operationalBlock/OperationalBlock';
import { useDispatch } from 'react-redux';
import { changeMonitoringTab } from '@store/slices/geoPortal/geoPortalSlice';

export const MonitoringObjectInfo = () => {
  const { currentAttribute } = useContext(MapContext);
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const filtersParams = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const { data: waterObject } = useMapControllerGetInfoQuery(
    nameParamArchive !== 'archive' && currentAttribute
      ? {
          objectType: currentAttribute.type,
          id: currentAttribute.id.toString(),
          params: {
            startDate: filtersParams?.startDate,
            endDate: filtersParams?.endDate,
          },
        }
      : skipToken,
  );

  const { data: operationalBlocks, isLoading } = useGetAllOperationalQuery({
    systemObjectId: currentAttribute?.id,
  });

  const options = [
    {
      key: 'monitoring',
      label: 'Мониторинг',
      children: <MonitoringBlock waterObject={waterObject} />,
    },
    {
      key: 'info',
      label: 'Данные карточки',
      children: (
        <InfoBlock
          waterObject={
            nameParamArchive === 'archive'
              ? currentAttribute?.systemObject
              : waterObject
          }
        />
      ),
    },
    {
      key: 'operational',
      label: 'Экспл. данные',
      children: (
        <OperationalBlock
          waterObject={
            nameParamArchive === 'archive'
              ? currentAttribute?.systemObject
              : waterObject
          }
          operational={operationalBlocks}
        />
      ),
    },
  ];

  const onChange = (v: any) => {
    dispatch(changeMonitoringTab('monitoring' === v));
  };

  useEffect(() => {
    return () => {
      dispatch(changeMonitoringTab(false));
    };
  }, []);

  return (
    <div style={{ padding: 5 }}>
      <Tabs onChange={onChange} items={options} defaultActiveKey="monitoring" />
    </div>
  );
};
