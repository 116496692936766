import { Col, Row, Space } from 'antd';

import { type JsonRecord } from '@models/jsonRecord';
import { type TableRendererProps } from '@models/table/interfaces/tableColumn';

import { PrimaryButton } from '../../../buttons/primaryButton/PrimaryButton';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';

export function renderHeaderStringWithActionCell<
  RecordType extends JsonRecord,
>({ customColumn, actionProps, record }: TableRendererProps<RecordType>) {
  const { title, children, actionsAccess } = customColumn;

  if (!actionsAccess?.length) {
    return (
      <Row align="middle" justify="center">
        <Col span={14}>{title}</Col>
        <Col span={5}>
          <PrimaryButton
            size="small"
            // disabled={data[status].disabled}
            onClick={() => actionProps?.onHeaderActionClick?.(customColumn)}
          >
            Ок
          </PrimaryButton>
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle" justify="center">
      {useHasUserPermission(actionsAccess[0]) ? (
        <Col span={5}>
          <PrimaryButton
            size="small"
            // disabled={data[status].disabled}
            onClick={() => actionProps?.onHeaderActionClick?.(customColumn)}
          >
            Ок
          </PrimaryButton>
        </Col>
      ) : (
        <Col>{title}</Col>
      )}
    </Row>
  );
}
