import { ChangeEvent } from 'react';

import { mapPhoneNumberToLong } from './mapPhoneNumberToLong';

export const changeCursorPhoneNumberInput = (
  event: ChangeEvent<HTMLInputElement>,
) => {
  let caret = event.target.selectionStart || 0;
  const element = event.target;
  if (
    mapPhoneNumberToLong(event.target.value)[caret - 1] === '+' &&
    event.nativeEvent instanceof InputEvent &&
    event.nativeEvent.data
  ) {
    caret += 5;
  }
  if (
    mapPhoneNumberToLong(event.target.value)[caret - 1] === '-' &&
    event.nativeEvent instanceof InputEvent &&
    event.nativeEvent.data
  ) {
    caret += 1;
  }
  requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};
