import { FC, useEffect, useState } from 'react';

import { Col, Flex, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';

import {
  IVodokhozMagazineFilters,
  useJournalFilters,
} from '@features/vodokhozReport/entryData/magazine/useJournalFilters';
import dayjs, { Dayjs } from 'dayjs';
import { statusOptions } from '@pages/vodkhozReport/components/magazinePage/components/magazineFilters/mocks';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from '@utils/utils';
import { useGetCurrentUser } from '@features/authentication';

export const MagazineFilters: FC = () => {
  const [filtersState, setFiltersState] = useState<IVodokhozMagazineFilters>(
    {},
  );
  const { filters, setWaterUserFilters } = useJournalFilters();
  const [params] = useSearchParams();
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.region
      ? {
          parentId: parseInt(String(filtersState.region), 10),
        }
      : skipToken,
  );

  const onFilterButtonClick = () => {
    setWaterUserFilters(filtersState as IVodokhozMagazineFilters);
  };

  const onFilterChange = (
    filterKey: keyof IVodokhozMagazineFilters,
    v: string | number,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    };
    setFiltersState(updatedFilters);
  };

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      district: null,
      region: v,
      page: 1,
    }));
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      region: user?.owner?.region?.id || null,
      district: user?.owner?.district?.id || null,

      name: null,
      year: null,
      status: null,
    });
    setWaterUserFilters({
      region: user?.owner?.region?.id || null,
      district: user?.owner?.district?.id || null,

      name: null,
      year: null,
      status: null,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setWaterUserFilters(
      isEmpty(newFilters)
        ? {
            ...filters,
            region: user?.owner?.region?.id || null,
            district: user?.owner?.district?.id || null,
          }
        : {
            ...newFilters,
            ...filters,
            region: user?.owner?.region?.id || null,
            district: user?.owner?.district?.id || null,
          },
    );
  }, []);

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);
  return (
    <Row gutter={16} align="middle" style={{ width: '70%' }}>
      <Col span={4}>
        <TooltipedSearch
          value={filtersState.name as any}
          onChange={({ target }) => onFilterChange('name', target.value)}
          placeholder="Наименование РУВХ"
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Область"
          value={filtersState.region && +filtersState.region}
          onChange={(v: string) => onRegionChange(v)}
          options={regions}
          allowClear
          disabled={isDisabledRegion}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Район"
          options={districts}
          disabled={!filtersState.region || isDisabledDistrict}
          value={filtersState.district && +filtersState.district}
          onChange={(v: string) => onFilterChange('district', v)}
          allowClear
        />
      </Col>
      <Col span={4}>
        <YearPicker
          defaultValue={dayjs()}
          placeholder="Год"
          onChange={(date: Dayjs | null) =>
            onFilterChange('year', date ? date.year() : dayjs().year())
          }
          disabledDate={(current) => current && current > dayjs().endOf('year')}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Статус"
          options={statusOptions}
          value={filtersState.status}
          onChange={(v: string) => onFilterChange('status', v)}
          allowClear
        />
      </Col>
      <Col span={4}>
        <Flex gap="small">
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Flex>
      </Col>
    </Row>
  );
};
