import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPointCloseIcon: React.FC<IconProps> = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM5.00725 5.00718C5.39777 4.61665 6.03094 4.61665 6.42146 5.00718L8 6.58572L9.57854 5.00718C9.96906 4.61665 10.6022 4.61665 10.9928 5.00718C11.3833 5.3977 11.3833 6.03087 10.9928 6.42139L9.41421 7.99993L10.9929 9.57861C11.3834 9.96913 11.3834 10.6023 10.9929 10.9928C10.6024 11.3833 9.9692 11.3833 9.57868 10.9928L8 9.41414L6.42132 10.9928C6.0308 11.3833 5.39763 11.3833 5.00711 10.9928C4.61658 10.6023 4.61658 9.96913 5.00711 9.57861L6.58579 7.99993L5.00725 6.42139C4.61672 6.03087 4.61672 5.3977 5.00725 5.00718Z"
            fill="#AD1313" fillOpacity="0.6" />
    </svg>
  )
}
