import { createSelector } from '@reduxjs/toolkit';

import { AnnualPlanState } from '../../slices/annualPlan/AnnualPlanSlice';

export const annualPlanStateSelector = (state: {
  annualPlan: AnnualPlanState;
}): AnnualPlanState => state.annualPlan;

export const getAnnualPlanStateSelector = createSelector(
  annualPlanStateSelector,
  (annualPlanState) => {
    return annualPlanState.annualPlanFilters;
  },
);

export const getAnnualPlanFiltersSelector = createSelector(
  annualPlanStateSelector,
  (annualPlanState) => {
    return annualPlanState.annualPlanFilters;
  },
);
