import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Form, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { CustomModal } from '@features/ui-kit';
import {
  DefaultButtonV2,
  PrimaryButtonV2,
  TooltipedSquaredInputV2,
} from '@features/ui-kit-v2';
import { AppDispatch } from '@store/index';

import { useCreateTemplateModal } from '../useCreateTemplateModal';
import styles from './modals.module.scss';

export interface ICreateTemplateModalProps {
  type?: 'operational' | 'regularEvent' | 'building' | 'otherTask';
  save: (values: any) => void;
}

export const CreateTemplateModal: React.FC<ICreateTemplateModalProps> = ({
  type,
  save,
}) => {
  const { t } = useTranslation();
  const { isShow, setShow } = useCreateTemplateModal();
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () => {
    setShow(false);
  };

  const onFinish = (values: string) => {
    save(values);

    setShow(false);
  };

  return (
    <CustomModal
      open={isShow}
      footer={false}
      title={t('template.createTemplate')}
      onCancel={closeModal}
      closeIcon={null}
      centered
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t('template.nameTemplate')}
          required
          name="templateName"
        >
          <TooltipedSquaredInputV2 placeholder={t('main.select.objectName')} />
        </Form.Item>
        <Row justify="end" style={{ gap: 15 }}>
          <DefaultButtonV2
            onClick={closeModal}
            title={t('common.buttons.cancel')}
          />
          <Form.Item className={styles.saveBtn}>
            <PrimaryButtonV2
              htmlType="submit"
              title={t('addBranch.buttons.save')}
            />
          </Form.Item>
        </Row>
      </Form>
    </CustomModal>
  );
};
