import { toInteger } from '@utils/utils';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Form, notification, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { AccountingGroupEnum } from '@enums/accountingGroup';
import { Routes } from '@enums/routes';
import { DefaultButtonV2, PrimaryButtonV2 } from '@features/ui-kit-v2';
import { CreateMainConduitQueryTypes } from '@models/waterObject/enums/mainConduitEnum';
import {
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import {
  getDocumentSelector,
  getWaterObjectsSelector,
} from '@store/selectors/mainConduit';
import {
  addWaterObjectAction,
  clearDocumentAction,
  clearWaterObjectAction,
  setSelectedWaterObjectAction,
} from '@store/slices';
import dayjs from 'dayjs';

import { DocumentUploader } from './documentUploader';
import { GtsObjectsPreviewTable } from './gtsObjectsPreviewTable';
import { MainConduitInfo } from './mainConduitInfo';
import { TotalInformation } from './totalInformation';
import {
  useIrrigationFindAllAqueductsBySystemIdQuery,
  useIrrigationFindSystemByIdQuery,
} from '@store/api/irrigationSystemApi';
import {
  useSystemObjectControllerFindBySystemObjectIdQuery,
  useSystemObjectControllerSaveSystemObjectListMutation,
} from '@store/api/systemObjectApi';
import { SystemObject } from '@models/systemObject/systemObject';

export const CreateGtsObjects: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { systemId, mainConduitId } = useParams<{
    systemId: any;
    mainConduitId: any;
  }>();
  const documents = useSelector(getDocumentSelector);
  const waterObjects = useSelector(getWaterObjectsSelector);

  const { data: irrigationSystem } = useIrrigationFindSystemByIdQuery({
    id: systemId,
  });
  const { data: aqueductList = [], isSuccess } =
    useIrrigationFindAllAqueductsBySystemIdQuery({
      systemId,
    });

  const { data: waterObject } =
    useSystemObjectControllerFindBySystemObjectIdQuery({
      id: mainConduitId,
    });

  const [save] = useSystemObjectControllerSaveSystemObjectListMutation();

  const onFinish = (values: IWaterObject) => {
    if (irrigationSystem) {
      dispatch(
        addWaterObjectAction({
          ...values,
          systemId: toInteger(irrigationSystem.systemId),
          districtId: irrigationSystem.districtId,
          pool: irrigationSystem.bassinId,
          source: irrigationSystem.baseId,
          regionId: irrigationSystem.regionId,
          attachmentIds: documents,
          parentId: mainConduitId,
          irrigationSystems: toInteger(irrigationSystem.systemId),
          aqueductId: toInteger(mainConduitId),
          dateStart: dayjs(values.dateStart).format('YYYY-MM-DD'),
        }),
      );
      form.resetFields();
      dispatch(setSelectedWaterObjectAction(null));
      dispatch(clearDocumentAction());
    }
  };

  const onCancelButtonClick = () => {
    const path = generatePath(Routes.IrrigationSystemMainConduitRegistration, {
      systemId,
      mainConduitId,
    });
    navigate(
      `${path}?${SectionQueryKey}=${CreateMainConduitQueryTypes.waterSections}`,
    );
  };

  const onContinue = () => {
    save({ body: waterObjects as unknown as SystemObject[] }).then(() => {
      dispatch(clearWaterObjectAction());
      const path = generatePath(
        Routes.IrrigationSystemMainConduitRegistration,
        {
          systemId,
          mainConduitId,
        },
      );
      navigate(
        `${path}?${SectionQueryKey}=${CreateMainConduitQueryTypes.constructor}`,
      );
    });
  };

  const validateNextButton =
    aqueductList.length <= 1
      ? waterObjects.length >= 2 &&
        waterObjects.some(
          (waterObj) =>
            waterObj.objectGroupId === toInteger(AccountingGroupEnum.Gvs),
        ) &&
        waterObjects.some(
          (waterObj) =>
            waterObj.objectCategoryId ===
            toInteger(AccountingGroupEnum.CategoryWaterOutlet),
        )
      : waterObjects.length >= 1;

  //Старое условие
  // ? waterObjects.length >= 1
  // : waterObjects.length >= 2 &&
  //   waterObjects.some(
  //     (waterObj) =>
  //       waterObj.objectCategoryId === toInteger(AccountingGroupEnum.Gvs),
  //   );

  const isMainConduit =
    aqueductList.some((item) => item.isGeneral) && aqueductList.length === 1;

  useEffect(() => {
    if (isSuccess) {
      if (!isMainConduit) {
        notification.warning({
          duration: 10,
          message: 'Примечание! Вы должны создать минимум 1 объект ГТС',
        });
      } else {
        notification.warning({
          duration: 10,
          message:
            'Примечание! Вы должны создать минимум 2 объектов ГТС, один из которых должен быть из группы "Головные водозаборные сооружения", а другой содержать категории "Водовыпуск".',
        });
      }
    }
  }, [isSuccess]);

  return (
    <Form autoComplete="off" layout="vertical" form={form} onFinish={onFinish}>
      <InformationalBlock title="Создание объектов (ГТС)">
        <Row gutter={8}>
          <Col span={12}>
            <Space size={8} direction="vertical">
              <MainConduitInfo
                mainConduitInfo={{
                  systemName: irrigationSystem?.name,
                  objectName: waterObject?.name,
                  districtName: irrigationSystem?.districtName,
                  baseName: irrigationSystem?.baseName,
                }}
              />

              <GtsObjectsPreviewTable />
            </Space>
          </Col>

          <Col span={12}>
            <Space size={8} direction="vertical">
              <TotalInformation isMainConduit={isMainConduit} />
              <DocumentUploader />
              <PrimaryButton
                style={{ width: '100%' }}
                size="large"
                htmlType="submit"
              >
                Сохранить
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      </InformationalBlock>
      <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
        <Col>
          <DefaultButton
            size="large"
            htmlType="button"
            onClick={onCancelButtonClick}
          >
            Отмена
          </DefaultButton>
        </Col>
        <Col>
          <PrimaryButton
            size="large"
            htmlType="button"
            onClick={onContinue}
            disabled={!validateNextButton}
          >
            {t('createIndividualWaterRegistry.buttons.next')}
          </PrimaryButton>
        </Col>
      </Row>
    </Form>
  );
};
