import React, { useContext, useEffect, useRef, useState } from 'react';

import { Col, Form, Input, InputRef, Row } from 'antd';

import { EditableContext } from './EditableRow';
import styles from './EditableTable.module.scss';

interface EditableCellProps {
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: {
    [name: string]: string;
  };
  index: string;
  handleSave: (row: { [key: string]: string | number }) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  index,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(editable);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    // if (editable) {
    //   setEditing(!editing);
    //   form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    // }
  };
  const save = async () => {
    if (form.getFieldValue(`${dataIndex}_${record.id}`)) {
      const values = await form.getFieldsValue();
      const res: any = {};
      Object.keys(values).forEach((oldKey) => {
        const newKey = oldKey.split('_')[0];
        res[newKey] = values[oldKey];
      });
      toggleEdit();
      handleSave({ ...record, ...res });
    } else {
      form.validateFields();
    }
  };

  useEffect(() => {
    form.setFieldValue(`${dataIndex}_${record?.id}`, record?.[dataIndex]);
  }, [record]);

  return (
    <td {...restProps}>
      <Row align="middle">
        {editing ? (
          <Col span={24}>
            <Form.Item
              name={`${dataIndex}_${record.id}`}
              initialValue={record?.[dataIndex]}
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              className={styles.form}
            >
              <Input
                disabled={record?.decade === 'Средний за месяц'}
                type="number"
                className={styles.tableCellInput}
                onBlur={save}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col onClick={toggleEdit} span={24}>
            <div>{children}</div>
          </Col>
        )}
      </Row>
    </td>
  );
};
