import { FC } from 'react';

import { Col, Row, Typography } from 'antd';

import styles from './StatsCard.module.scss';
import Icon from '@ant-design/icons/lib/components/Icon';
interface ICardItemProps {
  icon?: string;
  label: string | React.ReactNode;
  count: number | string;
  countSize?: number;
  secondCount?: number | string;
  inlinePadding?: number;
  status?: string;
}

export const StatsCard: FC<ICardItemProps> = ({
  count,
  icon,
  countSize,
  label,
  secondCount,
  inlinePadding = 30,
  status,
}) => {
  const countSpan = countSize ? countSize : 5;
  const isSecondCountNumber = typeof secondCount === 'number';
  return (
    <Row
      justify="space-between"
      align="middle"
      className={`${styles.infoItem} ${status && styles[status]}`}
      wrap={false}
    >
      <Col flex={1} className={styles.wrapper}>
        <Row className={styles.itemAbout} align="middle" wrap={false}>
          {icon && (
            <div className={styles.workIcon}>
              <img src={icon} alt="whell" />
            </div>
          )}
          <Typography.Text className={styles.label}>{label}</Typography.Text>
        </Row>
      </Col>
      {/* {isSecondCountNumber && secondCount >= 0 ? (
          <Row style={{ gap: 10 }} wrap={false}>
            <div className={countClassName}>{count}</div>
            <div className={countClassName}>{secondCount}</div>
          </Row>
        ) : (
          <div className={countClassName}>{count}</div>
        )} */}
      {isSecondCountNumber && secondCount >= 0 ? (
        // <Row style={{ gap: 10 }} wrap={false}>
        <>
          <Col span={countSpan} className={styles.count}>
            <div>{count}</div>
          </Col>
          <Col span={countSpan} className={styles.count}>
            <div>{secondCount}</div>
          </Col>
        </>
      ) : (
        // </Row>
        <Col span={countSpan} className={styles.count}>
          <div>{count}</div>
        </Col>
      )}
    </Row>
  );
};
