import { useEffect } from 'react';

import { Form, TimePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

import styles from './renderer.module.scss';

export function renderQuantity<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const [form] = useForm();
  const { day } = customColumn;
  const currDay = record.days?.find((item: any) => {
    const itemDate = dayjs(item.date).date();
    return itemDate === day;
  });
  const format = 'HH:mm';

  useEffect(() => {
    form.setFieldsValue({
      timeReportBlockMorning: currDay?.timeReportBlockMorning
        ? dayjs(currDay.timeReportBlockMorning)
        : dayjs('00:00', 'HH:mm'),

      timeReportBlockEvening: currDay?.timeReportBlockEvening
        ? dayjs(currDay.timeReportBlockEvening)
        : dayjs('00:00', 'HH:mm'),
    });
  }, [record]);

  if (record) {
    return (
      <Form form={form}>
        <div className={styles.railItem}>
          <Form.Item
            style={{ marginBottom: 10 }}
            name="timeReportBlockMorning"
            className={styles.time}
          >
            <TimePicker
              placeholder="--:--"
              format={format}
              bordered={false}
              className="timePicker"
              allowClear={false}
              disabled
            />
          </Form.Item>
          <div className={styles.value}>
            {currDay?.reportBlockMorning || 'X'}
          </div>
        </div>
        <div className={styles.railItem}>
          <Form.Item
            style={{ marginBottom: 10 }}
            name="timeReportBlockEvening"
            className={styles.time}
          >
            <TimePicker
              placeholder="--:--"
              format={format}
              bordered={false}
              className="timePicker"
              allowClear={false}
              disabled
            />
          </Form.Item>
          <div className={styles.value}>
            {currDay?.reportBlockEvening || 'X'}
          </div>
        </div>
      </Form>
    );
  }

  return null;
}
