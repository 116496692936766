import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useTariffMonitoringTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'averageAnnualTariff',
      title: 'Средний тариф годовой сом/м3',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'createDate',
      title: 'Дата создания',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'doc',
      title: 'Подтверждающий документ',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => <p>{record.doc?.name}</p>,
    },
    {
      dataIndex: 'active',
      title: 'Действия',
      xtype: CustomTableColumnType.ActionColumn,
      align: 'center',
      actions: [CustomTableAction.Watch, CustomTableAction.Print],
    },
    {
      dataIndex: 'isActive',
      title: 'Статус',
      xtype: CustomTableColumnType.TagColumn,
      align: 'center',
    },
  ];
  return { columns };
};
