import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const WatchIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.5C7.6722 0.5 9.1578 1.28563 10.2156 2.25813C10.7466 2.74625 11.184 3.29437 11.4924 3.84C11.7954 4.37562 12 4.95625 12 5.5C12 6.04375 11.796 6.62437 11.4924 7.16C11.184 7.70563 10.7466 8.25375 10.2156 8.74187C9.1578 9.71438 7.6716 10.5 6 10.5C4.3278 10.5 2.8422 9.71438 1.7844 8.74187C1.2534 8.25375 0.816 7.70563 0.5076 7.16C0.204 6.62437 0 6.04375 0 5.5C0 4.95625 0.204 4.37562 0.5076 3.84C0.816 3.29437 1.2534 2.74625 1.7844 2.25813C2.8422 1.28563 4.3284 0.5 6 0.5ZM6 1.75C4.6896 1.75 3.4752 2.37063 2.5788 3.195C2.1324 3.605 1.7796 4.05313 1.5414 4.47438C1.2978 4.90563 1.2 5.2625 1.2 5.5C1.2 5.7375 1.2978 6.09437 1.5414 6.52562C1.7796 6.94688 2.1324 7.39437 2.5788 7.805C3.4752 8.62937 4.6896 9.25 6 9.25C7.3104 9.25 8.5248 8.62937 9.4212 7.805C9.8676 7.39437 10.2204 6.94688 10.4586 6.52562C10.7022 6.09437 10.8 5.7375 10.8 5.5C10.8 5.2625 10.7022 4.90563 10.4586 4.47438C10.2204 4.05313 9.8676 3.60563 9.4212 3.195C8.5248 2.37063 7.3104 1.75 6 1.75ZM6 3.625C6.0528 3.625 6.105 3.6275 6.1566 3.63187C6.02633 3.86991 5.97475 4.14593 6.0098 4.41749C6.04485 4.68904 6.16458 4.94108 6.35057 5.13482C6.53657 5.32856 6.77852 5.45328 7.03921 5.48979C7.2999 5.5263 7.56489 5.47257 7.7934 5.33687C7.82495 5.71323 7.74663 6.09074 7.56869 6.41993C7.39075 6.74912 7.12149 7.01465 6.79621 7.18171C6.47092 7.34877 6.10477 7.40958 5.74573 7.35617C5.3867 7.30275 5.05153 7.13761 4.78414 6.88238C4.51675 6.62714 4.32962 6.29372 4.24725 5.9258C4.16489 5.55789 4.19115 5.17263 4.32258 4.82052C4.454 4.46842 4.68448 4.16588 4.9838 3.95255C5.28312 3.73922 5.63732 3.62505 6 3.625Z"
        fill="white"
      />
    </svg>
  );
};
