import { SectionQueryKey } from '@constants/queryKeys';
import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect
} from '@features/ui-kit';
import { WaterQualityTable } from '@features/waterQuality/tables';
import {
  WaterQualityCardStep,
  WaterQualityMonitoringType,
  WaterQualityPointType,
  WaterQualityTableFilters,
} from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateZoneMutation,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetQualityMonitoringQuery,
  useGetReferencesByParentIdAndTypeQuery, useGetZoneQuery,
} from '@store/gisproApi';
import {
  getMetricsCollectionPointType,
  getWaterQualityCardSelector,
  getWaterQualityMonitoringTypeSelector,
} from '@store/selectors';
import { setMetricsCollectionPointType, setMonitoringType, setWaterQualityCard, } from '@store/slices';

import { Col, Form, Row } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import styles from './WaterQualityCardFirstStep.module.scss';

export const WaterQualityCardFirstStep: React.FC = () => {
  const [form] = useForm();
  const monitoringType = useSelector(getWaterQualityMonitoringTypeSelector);
  const metricsCollectionPointType = useSelector(getMetricsCollectionPointType);
  const waterQualityCard = useSelector(getWaterQualityCardSelector);
  const [createZone] = useCreateZoneMutation();
  const navigate = useNavigate();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const regionIdWatch = useWatch('regionId', form);
  const districtIdWatch = useWatch('districtId', form);
  const dispatch = useDispatch();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionIdWatch
      ? {
        parentId: parseInt(regionIdWatch, 10),
      }
      : skipToken,
  );

  const { data: okmots = [] } = useGetReferencesByParentIdAndTypeQuery(
    districtIdWatch
      ? {
        typeId: EntityTypesEnum.Okmot,
        parentId: parseInt(districtIdWatch, 10),
      } : skipToken,
  );

  const { data: qualityMonitoring = [] } = useGetQualityMonitoringQuery();

  const onRegionChange = () => {
    form.resetFields(['districtId']);
  };

  const onDisctrictChange = () => {
    form.resetFields(['okmotId']);
  };

  const onCancelClick = () => {
    form.resetFields();
    dispatch(setWaterQualityCard({}));
    dispatch(setMetricsCollectionPointType(''));
    navigate(Routes.WaterQualityTable);
  };

  const monitoringTypeChange = (value: WaterQualityMonitoringType) => {
    dispatch(setMonitoringType(value));
  };

  const onCreate = () => {
    createZone({ ...form.getFieldsValue(), zoneId: waterQualityCard?.zoneId ?? null, points: waterQualityCard.points || [] });
    form.resetFields();
    navigate(
      `${Routes.WaterQualityTable}?${WaterQualityTable}?section=${WaterQualityTableFilters.DictionaryZone}`,
    );
  };

  const onFinish = () => {
    if (monitoringType === WaterQualityMonitoringType.DepthUGV) {
      dispatch(
        setMetricsCollectionPointType({
          disabledValue: true,
          value: WaterQualityPointType.WellMonitoring,
        }),
      );
    } else {
      dispatch(setMetricsCollectionPointType({}));
    }
    dispatch(setWaterQualityCard({ ...form.getFieldsValue(), zoneId: waterQualityCard?.zoneId ?? null, points: waterQualityCard.points || [] }));
    navigate(
      generatePath(`${Routes.WaterQualityCard}/?${SectionQueryKey}=${WaterQualityCardStep.CreateCollectionPoint}`, {
        id: waterQualityCard?.zoneId || 'new',
      }),
    );
  };

  useEffect(() => {
    form.setFieldsValue(waterQualityCard);
    monitoringTypeChange(waterQualityCard.type);
  }, [waterQualityCard, metricsCollectionPointType]);

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <div className={styles.firstStepWrapper}>
        <InformationalBlock title="Общие данные">
          <CustomCard>
            <Row justify="space-between" gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="zoneName"
                  label="Наименование зоны"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="regionId"
                  label="Область"
                  initialValue={user?.owner?.region?.id}
                >
                  <TooltipedSelect
                    onChange={onRegionChange}
                    allowClear
                    placeholder="Область"
                    options={regions}
                    disabled={isDisabledRegion}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="districtId"
                  label="Район"
                  initialValue={user?.owner?.district?.id}
                >
                  <TooltipedSelect
                    allowClear
                    placeholder="Район"
                    options={districts}
                    disabled={isDisabledDistrict || !regionIdWatch}
                    onChange={onDisctrictChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="okmotId"
                  label="Айыл окмоту"
                >
                  <TooltipedSelect
                    allowClear
                    placeholder="Айыл окмоту"
                    options={okmots}
                    disabled={!districtIdWatch}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="area"
                  label="Площадь (Га)"
                  rules={[{ required: true }]}
                >
                  <TooltipedInput type="number"/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="type"
                  label="Тип мониторинга"
                >
                  <TooltipedSelect
                    allowClear
                    placeholder="Тип мониторинга"
                    onChange={monitoringTypeChange}
                    options={qualityMonitoring}
                  />
                </Form.Item>
              </Col>
            </Row>
          </CustomCard>
        </InformationalBlock>

        <Row gutter={20} justify="end">
          <Col>
            <DefaultButton onClick={onCancelClick}>Отмена</DefaultButton>
          </Col>
          <Col>
            {monitoringType === WaterQualityMonitoringType.ReclamationStatus ? (
              <Form.Item>
                <PrimaryButton onClick={onCreate}>{ waterQualityCard.zoneId ? 'Обновить' : 'Создать' }</PrimaryButton>
              </Form.Item>
            ) : (
              <PrimaryButton htmlType="submit">Далее</PrimaryButton>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};
