import type { FC } from 'react';
import { memo } from 'react';
import { useDrop } from 'react-dnd';

import { Col, Row, Spin, Typography } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

import { WaterObjectPreviewCard } from '../../waterObjectPreviewCard';
import styles from './WaterObjectBinItem.module.scss';
import { CustomCard, TooltipedInput } from '@features/ui-kit';
import { SystemObject } from '@models/systemObject/systemObject';

export interface DustbinProps {
  lastDroppedItem?: any;
  onDrop: (item: any) => void;
  onDelete: (item: any) => void;
  coordinates?: string;
  name?: string;
  sectionId?: number;
  systemObjectDtoList?: SystemObject[];
  typeSectionId?: number;
  isLoading: boolean;
}

export const WaterObjectBinItem: FC<DustbinProps> = memo(
  function WaterObjectBinItem({
                                coordinates,
                                name,
                                onDrop,
                                onDelete,
                                sectionId,
                                systemObjectDtoList = [],
                                typeSectionId,
                                isLoading
  }) {
    const { data: sectionTypes = [] } = useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.SectionType,
    });

    const typeSectionName =
      sectionTypes.find((section) => section.id === typeSectionId)?.title ||
      '-';

    const customOnDrop = (item: any) => {
      onDrop({
        ...item,
        sectionId,
      });
    };

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: 'waterObject',
      drop: customOnDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = isOver && canDrop;

    let backgroundColor = 'transparent';
    if (isActive) {
      backgroundColor = '#c7d0f9';
    } else if (canDrop) {
      backgroundColor = '#c7d0f9';
    }

    return (
      <CustomCard title="Участок" loading={isLoading}>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Typography.Text>Наименование участка</Typography.Text>
            <TooltipedInput size="large" disabled value={name} />
          </Col>
          <Col span={8}>
            <Typography.Text>Пикетная координата</Typography.Text>
            <TooltipedInput size="large" disabled value={coordinates} />
          </Col>
          <Col span={8}>
            <Typography.Text>Пикетная координата</Typography.Text>
            <TooltipedInput size="large" disabled value={typeSectionName} />
          </Col>
          <Col span={24}>
            <div
              ref={drop}
              style={{ backgroundColor }}
              className={styles.drop}
              data-testid="dustbin"
            >
              <Typography.Title level={4}>Перетащите чтобы прикрепить</Typography.Title>
              <div>
                <PlusOutlined />
              </div>
            </div>
            <Row gutter={[1, 8]}>
              {systemObjectDtoList.map((item) => (
                <WaterObjectPreviewCard
                  key={item.objectId}
                  item={item}
                  onRemoveConnection={onDelete}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </CustomCard>
    );
  },
);
