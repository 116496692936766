import {
  HydroFacilityQueryKey,
  IrrigationSystemQueryKey,
} from '@constants/queryKeys';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Col, Row, Space, Typography } from 'antd';

import {
  CustomCard,
  Divider,
  PrimaryButton,
  TableCustom,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  IBindDto,
  ICreateGmrSystemOutlets,
} from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  CreateHydroFacilitySystemApiArg,
  OutletBindDtoList,
  useCreateGmrSystemOutletsMutation,
  useCreateHydroFacilitySystemMutation,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useGetGmrSystemsByDistrictQuery,
  useGetGmrZoneListQuery,
  useGetHydroFacilityAllQuery,
  useGetSystemsByDistrictQuery,
} from '@store/gisproApi';

import { useIrrigationSystemTableColumns } from './useIrrigationSystemTableColumns';

export const IrrigationSystemTable: React.FC<{ gmr: any }> = ({ gmr }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { hydroModuleDistrictId, id } = useParams<{
    hydroModuleDistrictId: any;
    id: any;
  }>();
  const [params, setParams] = useSearchParams();

  const { columns } = useIrrigationSystemTableColumns();
  const filterState: {
    hydroFacilityId?: number;
    irrigationSystemId?: number;
  } = {
    hydroFacilityId: undefined,
    irrigationSystemId: undefined,
  };

  const selectedHydroFacilityId = params.get(HydroFacilityQueryKey);
  if (selectedHydroFacilityId) {
    filterState.hydroFacilityId = parseInt(selectedHydroFacilityId);
  }
  const selectedIrrigationSystemId = params.get(IrrigationSystemQueryKey);
  if (selectedIrrigationSystemId) {
    filterState.irrigationSystemId = parseInt(selectedIrrigationSystemId);
  }

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery(
    gmr
      ? {
          district: gmr?.districtId,
          region: gmr?.regionId,
        }
      : skipToken,
  );

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      filterState.hydroFacilityId
        ? { hydroFacilityId: filterState.hydroFacilityId }
        : skipToken,
    );

  const { data = [] } = useGetGmrSystemsByDistrictQuery({
    gmrId: hydroModuleDistrictId,
    hydroFacilityId: filterState.hydroFacilityId,
    irrigationSystemId: filterState.irrigationSystemId,
  });

  const [save, { isError, error }] = useCreateGmrSystemOutletsMutation();

  const onChange = (selectedKey: React.Key[]) => {
    setSelectedRowKeys(selectedKey);
  };

  const onFilterChange = (key: string, value: number) => {
    console.log('value', value);
    if (value) {
      params.set(key, value.toString());
    } else {
      params.delete(key);
    }
    setParams(params);
  };

  const onAddButtonClick = () => {
    const res = selectedRowKeys.reduce(
      (acc: ICreateGmrSystemOutlets, curr) => {
        const outlet = data.find((item) => item.id === curr);
        if (outlet) {
          acc.outletBindDtoList.push({ outletId: outlet.id });
        }
        return acc;
      },
      {
        gmrId: hydroModuleDistrictId,
        outletBindDtoList: [],
      },
    );
    save(res as ICreateGmrSystemOutlets);
    setSelectedRowKeys([]);
  };

  return (
    <CustomCard
      title={
        <>
          <Row gutter={5} justify="space-between">
            <Col>
              <Typography.Title level={5}>
                Ирригационные системы
              </Typography.Title>
            </Col>
            <Col>
              <Space size={8}>
                <TooltipedSelect
                  popupMatchSelectWidth={300}
                  options={hydroFacilities}
                  onChange={(v: number) => onFilterChange('hydroFacilityId', v)}
                  placeholder="Гидроучасток"
                  value={filterState.hydroFacilityId}
                  allowClear
                />
                <TooltipedSelect
                  popupMatchSelectWidth={300}
                  options={systems}
                  onChange={(v: number) =>
                    onFilterChange('irrigationSystemId', v)
                  }
                  placeholder="Ирригационная система"
                  allowClear
                  value={filterState.irrigationSystemId}
                  disabled={!filterState.hydroFacilityId}
                />
              </Space>
            </Col>
            <Col>
              <PrimaryButton onClick={onAddButtonClick}>Добавить</PrimaryButton>
            </Col>
          </Row>
        </>
      }
      light={true}
    >
      <TableCustom
        rowSelection={{
          type: 'checkbox',
          columnTitle: 'Выбрать',
          onChange,
          selectedRowKeys,
        }}
        bordered
        size="small"
        rowKey={(item) => item.systemId || item.id}
        columns={columns}
        dataSource={data}
      />
      {isError && (
        <Typography.Text type="danger">
          {(error as any).status} {JSON.stringify((error as any).data)}
        </Typography.Text>
      )}
    </CustomCard>
  );
};
