import { ACCESS } from '@enums/permisson';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

type ReturnType = {
  columns: TableColumn[];
};

export const useWateringSupplyScheduleTableColumns = ({
  month,
}: {
  month: string;
}): ReturnType => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      width: 56,
      xtype: CustomTableColumnType.String,
      align: 'left',
    },
    {
      title: 'Наименование системы / водовыпуска',
      dataIndex: 'name',
      align: 'left',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WaterUsers],
      width: 177,
    },
    {
      title: month,
      dataIndex: month,
      xtype: CustomTableColumnType.String,
      align: 'left',
      children: [
        {
          title: '1 Декада',
          dataIndex: `${month}-1`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.String,
              align: 'left',
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  align: 'left',
                  decimalIndex: ['decadal', '1', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  align: 'left',
                  decimalIndex: ['decadal', '1', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              xtype: CustomTableColumnType.String,
              align: 'left',
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  align: 'left',
                  decimalIndex: ['decadal', '1', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  align: 'left',
                  decimalIndex: ['decadal', '1', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '1', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '1', 'value'],
                },
              ],
            },
          ],
        },
        {
          title: '2 Декада',
          dataIndex: `${month}-2`,
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
                {
                  title: 'тыс. м3',
                  align: 'left',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
                {
                  title: 'тыс. м3',
                  align: 'left',
                  dataIndex: 'requestM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  align: 'left',
                  dataIndex: 'fact',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '2', 'value'],
                },
              ],
            },
          ],
        },
        {
          title: '3 Декада',
          dataIndex: `${month}-3`,
          xtype: CustomTableColumnType.String,
          align: 'left',
          children: [
            {
              title: 'План',
              dataIndex: 'plan',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'plan',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '3', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'planM3',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  align: 'left',
                  decimalIndex: ['decadal', '3', 'value'],
                },
              ],
            },
            {
              title: 'Заявка',
              dataIndex: 'request',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'request',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '3', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'requestM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '3', 'value'],
                },
              ],
            },
            {
              title: 'Факт',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.String,
              children: [
                {
                  title: 'л/сек',
                  dataIndex: 'fact',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '3', 'value'],
                },
                {
                  title: 'тыс. м3',
                  dataIndex: 'factM3',
                  align: 'left',
                  xtype: CustomTableColumnType.DecimalEdit,
                  editableDecimalCell: false,
                  width: 64,
                  decimalIndex: ['decadal', '3', 'value'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      xtype: CustomTableColumnType.String,
      align: 'left',
      children: [
        {
          title: 'План',
          align: 'left',
          dataIndex: 'sum-plan',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              align: 'left',
              dataIndex: 'plan',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
            {
              title: 'тыс. м3',
              dataIndex: 'planM3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
          ],
        },
        {
          title: 'Заявка',
          dataIndex: 'sum-request',
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'request',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
            {
              title: 'тыс. м3',
              dataIndex: 'requestM3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
          ],
        },
        {
          title: 'Факт',
          dataIndex: 'sum-fact',
          align: 'left',
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'л/сек',
              dataIndex: 'fact',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
            {
              title: 'тыс. м3',
              dataIndex: 'factM3',
              align: 'left',
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: false,
              width: 64,
              decimalIndex: ['sum'],
            },
          ],
        },
      ],
    },
  ];

  return {
    columns,
  };
};
