import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const columns: TableColumn[] = [
  {
    title: 'Наименование РУВХ',
    dataIndex: 'name',
    xtype: CustomTableColumnType.String,
    key: 'name',
    align: 'left',
    width: 355,
  },
  {
    title: 'Наименование области',
    dataIndex: 'region.title',
    xtype: CustomTableColumnType.String,
    key: 'region',
    align: 'left',
    width: 355,
  },
  {
    title: 'Наименование района',
    dataIndex: 'district.title',
    xtype: CustomTableColumnType.String,
    key: 'district',
    align: 'left',
    width: 355,
  },
  {
    title: 'Год',
    dataIndex: 'year',
    xtype: CustomTableColumnType.String,
    key: 'year',
    align: 'left',
    width: 355,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    xtype: CustomTableColumnType.Status,
    key: 'status',
    align: 'left',
    width: 355,
  },
];
