import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from '@models/table/interfaces/tableColumn';

export const nameFilterDropdown = (dataIndex: string, text: string) => ({
  filterDropdown: ({
    setSelectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => {
    const { t } = useTranslation();
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSelectedKeys(value ? [value] : []);
    };

    const handleReset = () => {
      setSelectedKeys([]);
      clearFilters();
      confirm();
    };

    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={
            t('customComponents.table.cellEditor.enterValue', {
              value: text,
            }) || ''
          }
          onChange={handleSearchChange}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => {
            handleReset();
          }}
          size="small"
          style={{ width: 90 }}
        >
          {t('customComponents.buttons.reset')}
        </Button>
        <Button
          type="primary"
          onClick={confirm}
          size="small"
          style={{ width: 90, marginLeft: 8 }}
        >
          {t('customComponents.buttons.search')}
        </Button>
      </div>
    );
  },
});

export const statusFilterDropdown = (dataIndex: string) => ({
  filterDropdown: ({
    setSelectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => {
    const { t } = useTranslation();
    const handleSearchChange = (value: any) => {
      setSelectedKeys(value ? [...value] : []);
    };

    const handleReset = () => {
      setSelectedKeys([]);
      clearFilters();
      confirm();
    };

    const filters = [
      { text: 'Свободен', value: 'Available' },
      { text: 'На брони к заказу', value: 'Reserved' },
      { text: 'Заблокирован', value: 'Locked' },
      { text: 'Занят', value: 'Busy' },
    ];

    return (
      <div>
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            onChange={handleSearchChange}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {filters.map((filter) => (
              <Checkbox
                key={filter.value}
                value={filter.value}
                style={{ marginInlineStart: '0px' }}
                className="custom-checkbox"
              >
                {filter.text}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 8,
          }}
        >
          <Button
            size="small"
            onClick={handleReset}
            className="ant-btn-reset"
            style={{ color: 'red' }}
          >
            {t('macAddress.buttons.reset')}
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={confirm}
            style={{ marginLeft: 8 }}
          >
            {t('macAddress.buttons.search')}
          </Button>
        </div>
      </div>
    );
  },
});

export const nameFilterIcon = (filtered: string) => (
  <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
);
