import { ActsInvoicesForPaymentsStatusEnum } from '@models/actsInvoicesForPayments/enums/actsInvoicesForPaymentsEnum';
import { TariffStatusEnum } from '@models/tariff/enums/tariffStatusEnum';

export const getTagStatus = (value: string) => {
  let status = 'processing';

  switch (value) {
    case ActsInvoicesForPaymentsStatusEnum.issued:
      status = 'processing';
      break;
    case ActsInvoicesForPaymentsStatusEnum.filled:
      status = 'warning';
      break;
    case ActsInvoicesForPaymentsStatusEnum.billCreated:
      status = 'warning';
      break;
    case ActsInvoicesForPaymentsStatusEnum.paid:
    case TariffStatusEnum.active:
      status = 'success';
      break;
    case TariffStatusEnum.notActive:
      status = 'error';
      break;
    default:
      status = 'processing';
      break;
  }

  return status;
};
