import React from 'react';

import { Layout } from 'antd';

import {
  WaterRegistryAssociationsLayout,
  WaterRegistryUsersLayout,
} from '@features/layout';

export const WaterRegistryAssociationsPage: React.FC = () => {
  return <WaterRegistryAssociationsLayout />;
};
