import { Tags } from '@constants/tags';
import { api } from '../basicApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    instructionsControllerFindAll: build.query<
      InstructionsControllerFindAllApiResponse,
      InstructionsControllerFindAllApiArg
    >({
      query: ({ lang, ...params }) => ({
        url: `/api/v1/instructions/list/${lang}`,
        params,
      }),
      providesTags: [Tags.Instruction],
    }),
    instructionsControllerCreate: build.mutation<
      InstructionsControllerCreateApiResponse,
      InstructionsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/instructions/create`,
        method: 'POST',
        body: queryArg.instructionsCreateDto,
      }),
      invalidatesTags: [Tags.Instruction],
    }),
    instructionsControllerUpdate: build.mutation<
      InstructionsControllerUpdateApiResponse,
      InstructionsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/instructions/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.instructionsUpdateDto,
      }),
      invalidatesTags: [Tags.Instruction],
    }),
    instructionsControllerDelete: build.mutation<
      InstructionsControllerDeleteApiResponse,
      InstructionsControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/instructions/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.Instruction],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type InstructionsControllerFindAllApiResponse =
  /** status 200  */ PageableResultInstructionsDto;
export type InstructionsControllerFindAllApiArg = {
  lang: string;
  page: number;
  size: number;
};
export type InstructionsControllerCreateApiResponse =
  /** status 200  */ InstructionsDto;
export type InstructionsControllerCreateApiArg = {
  instructionsCreateDto: InstructionsCreateDto;
};
export type InstructionsControllerUpdateApiResponse =
  /** status 200  */ UpdateResult;
export type InstructionsControllerUpdateApiArg = {
  id: string;
  instructionsUpdateDto: InstructionsUpdateDto;
};
export type InstructionsControllerDeleteApiResponse =
  /** status 200  */ DeleteResult;
export type InstructionsControllerDeleteApiArg = {
  id: string;
};
export type InstructionsDto = {
  id: number;
  title: string;
  attachments: string[];
  lang: 'ru' | 'en' | 'kg';
  createdAt: string;
  updatedAt: string;
};
export type PageableResultInstructionsDto = {
  content: InstructionsDto[];
  pageable: any;
  totalElements: number;
};
export type InstructionsCreateDto = {
  /** Название инструкции */
  title: string;
  /** файлы */
  attachments: any[];
  /** Язык */
  lang: string;
};
export type UpdateResult = {
  affected: number;
  raw: object;
};
export type InstructionsUpdateDto = {
  /** Название инструкции */
  title: string;
  /** файлы */
  attachments: any[];
  /** Язык */
  lang: string;
};
export type DeleteResult = {
  affected: number;
  raw: object;
};
export const {
  useInstructionsControllerFindAllQuery,
  useInstructionsControllerCreateMutation,
  useInstructionsControllerUpdateMutation,
  useInstructionsControllerDeleteMutation,
} = injectedRtkApi;
