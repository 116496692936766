export enum EntityTypesEnum {
  Region = 1,
  District = 2,
  EconomicActivity = 3,
  Gked = 5,
  Temporary = 7,
  Source = 8,
  OwnerType = 9,
  TypeEconomicActivity = 10,
  Pool = 11,
  OrganizationForm = 12,
  AgroClimateZone = 13,
  PositionsList = 14,
  TypeActivityOrganization = 15,
  KindActivity = 16,
  WaterCouncil = 17,
  Education = 18,
  ObjectGroups = 19,
  ObjectCategories = 20,
  ObjectTypes = 21,
  Okmot = 22,
  DocumentTypes = 23,
  IrrigationSystemsType = 24,
  AssociationTypes = 25,
  FarmTypes = 26,
  StateEmploye = 27,
  CitiesVillages = 28,
  KindOfWork = 40,
  WaterSectionSoilTypes = 29,
  WaterSectionFormTypes = 30,
  WaterSectionFacingTypes = 31,
  SectionType = 34,
  WaterObjectDirection = 39,
  OperationalStatusEnum = 32,
  TechnicalStatusEnum = 33,
  WorkTypes = 36,
  WorkCategory = 35,
  Directions = 37,
  EventTypes = 38,
  СonstructionType = 41,
  ControlMethodTypes = 42,
  WaterLevelRegulationTypes = 43,
  WireType = 45,
  MeasurementType = 46,
  CoverageType = 48,
  WaterQualityType = 50,
  IrrigationTypes = 52,
  DevelopmentStageAVP = 73,
  ActualSowing = 74,
  CropYield = 75,
  RegistersProtocolMeetingsCompiled = 76,
  FillModeType = 83,
  ResetModeType = 84,
  WaterBalanceType = 86,
  TreesType = 85,
}
