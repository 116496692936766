import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, message, notification, Row, Space, Upload } from 'antd';

import { documentFormats } from '@constants/documentFormats';
import { EntityTypesEnum } from '@enums/entityTypes';
import { PrimaryButtonV2 } from '@features/ui-kit-v2/buttonsV2';
import { CustomModalV2 } from '@features/ui-kit-v2/customModalV2';
import { TooltipedSelectV2 } from '@features/ui-kit-v2/selectV2';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  useFindDictionaryByTypeIdQuery,
  useUploadFilesMutation,
} from '@store/gisproApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';

type DocumentUploadModalPropTypes = {
  onLoad: (doc: IAttachment) => void;
  show: boolean;
  setShow: (val: boolean) => void;
  preSelectedDocType?: string;
  defaultDocType?: string;
};

export const UploadModalV2: React.FC<DocumentUploadModalPropTypes> = ({
  onLoad,
  setShow,
  show,
  preSelectedDocType,
  defaultDocType = 'устав',
}) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.success({
      message: 'Документ успешно загружен!',
      placement: 'topRight',
    });
  };

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const docFormat = Form.useWatch('docFormat', form);
  const docType = Form.useWatch('docType', form) || defaultDocType;

  const { data: documentTypes = [], isLoading: documentTypesLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.DocumentTypes });

  const [uploadDocument] = useUploadFilesMutation();

  const getFilePath = (file: any) => {
    if (file.extension === 'png') {
      return {
        ...file,
        filePath: `${process.env.REACT_APP_MAIN_BASE_URL}/api/v1/attachment/download?path=${file.filePath}`,
      };
    }
    return file;
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    } else {
      form.setFieldValue('docType', preSelectedDocType);
    }
  }, [show]);

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);

    try {
      const response: any = await uploadDocument(formData);
      openNotification();
      form.resetFields();
      onLoad(getFilePath(response.data) as IAttachment);
      onSuccess();
      setShow(false);
    } catch (error) {
      onError(error);
    }
  };

  const beforeUpload = (file: any) => {
    const limit = file.size / 1024 / 1024 <= 5;
    if (!limit) {
      message.error('File must be smaller than 2MB!');
    }
    return limit;
  };

  return (
    <CustomModalV2
      centered
      title="Прикрепление документов"
      open={show}
      onCancel={() => setShow(false)}
      width={650}
      footer={false}
    >
      {contextHolder}
      <Form form={form} autoComplete="off" layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              name="docType"
              label={t('irrigationForm.chooseDocumentType')}
              rules={[{ required: true }]}
            >
              <TooltipedSelectV2
                showSearch={false}
                disabled={!!preSelectedDocType}
                loading={documentTypesLoading}
                defaultValue={{
                  value: defaultDocType,
                  label: defaultDocType,
                }}
                options={documentTypes}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="docFormat"
              label={t('common.placeholders.docFormat')}
              rules={[{ required: true }]}
            >
              <TooltipedSelectV2 showSearch={false} options={documentFormats} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Form.Item
            valuePropName="file"
            getValueFromEvent={normalizeFile}
            name="file"
          >
            <Upload
              name="file"
              accept={docFormat}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              showUploadList={false}
            >
              <PrimaryButtonV2
                type="primary"
                disabled={!docType || !docFormat}
                title={t('common.buttons.load')}
              />
            </Upload>
          </Form.Item>
        </Row>
      </Form>
    </CustomModalV2>
  );
};
