import { useParams } from 'react-router-dom';

import { Col, Row, Space, Typography } from 'antd';

import { CustomCard, Divider, PrimaryButton } from '@features/ui-kit';
import { useFindIrrigationSystemByIdQuery } from '@store/gisproApi';

import dayjs from 'dayjs';
import styles from './PassportForm.module.scss';
import { useState } from 'react';
import { PassportDetailsModal } from '@features/irrigationSystemCard/modals/PassportDetailsModal';
import { EditIcon } from '@icons/EditIcon';
import { SourceModal } from '@features/irrigationSystemCard/modals/SourceModal';
import { GeneralCharacteristicsModal } from '@features/irrigationSystemCard/modals/GeneralCharacteristicsModal';

export const PassportForm: React.FC = () => {
  const { systemId = '' } = useParams<string>();

  const { data: systemData } = useFindIrrigationSystemByIdQuery({
    irrigationSystemId: systemId,
  });

  const [isShowPassportDetailsModal, setShowPassportDetailsModal] =
    useState(false);
  const [isShowSourceModal, setShowSourceModal] = useState(false);
  const [
    isShowGeneralCharacteristicsModal,
    setShowGeneralCharacteristicsModal,
  ] = useState(false);

  return (
    <Space direction="vertical" size={20} className={styles.fullHeight}>
      <Row gutter={8}>
        <Col span={8}>
          <CustomCard
            withoutHeadBorder
            title={
              <Row gutter={16} justify="space-between" align="top">
                <Col flex={1}>
                  <Typography.Title level={4}>
                    Паспортные данные
                  </Typography.Title>
                  <Divider withoutMargin />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={systemData?.isArchived}
                    onClick={() => setShowPassportDetailsModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          >
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Наименование системы:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.name}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Регистрационный номер:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.regNum}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">Тип системы:</Typography.Text>
              </Col>
              <Col span={12}> {systemData?.systemTypeName}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">Владелец:</Typography.Text>
              </Col>
              <Col span={12}>{systemData?.ownerName}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Год ввода в эксплуатацию:
                </Typography.Text>
              </Col>
              <Col span={12}>{dayjs(systemData?.dateDesign).format('YYYY')}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Построена по проекту:
                </Typography.Text>
              </Col>
              <Col span={12}> {systemData?.builtProject}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Область-район:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.regionName} / {systemData?.districtName}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Остаточная балансовая стоим. (сом):
                </Typography.Text>
              </Col>
              <Col span={12}> {systemData?.residualValue}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Сумма износа (сом):
                </Typography.Text>
              </Col>
              <Col span={12}> {systemData?.wearSum}</Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={8}>
          <CustomCard
            withoutHeadBorder
            title={
              <Row gutter={16} justify="space-between" align="top">
                <Col flex={1}>
                  <Typography.Title level={4}>Источник</Typography.Title>
                  <Divider withoutMargin />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={systemData?.isArchived}
                    onClick={() => setShowSourceModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
            className={styles.fullHeight}
          >
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <Typography.Text type="secondary">Бассейн:</Typography.Text>
              </Col>
              <Col span={12}> {systemData?.bassinName}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Наименование источника:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.baseName}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Тип источника:
                </Typography.Text>
              </Col>
              <Col span={12}> {systemData?.baseType}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Описание источника:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.baseDescription}</Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={8}>
          <CustomCard
            withoutHeadBorder
            title={
              <Row gutter={16} justify="space-between" align="top">
                <Col flex={1}>
                  <Typography.Title level={4}>
                    Общие характеристики
                  </Typography.Title>
                  <Divider withoutMargin />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={systemData?.isArchived}
                    onClick={() => setShowGeneralCharacteristicsModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
            className={styles.fullHeight}
          >
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Общ. орошаемая площадь (Га):
                </Typography.Text>
              </Col>
              <Col span={12}> {systemData?.areaPlanting}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Кол-тво сооружений всего, шт.:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.amountStructures}</Col>
              <Col span={12}>
                <Typography.Text strong type="secondary">
                  Водомерные устройства всего шт:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.waterMeasurementDevices}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  <ul className={styles.list}>
                    <li>Из них автоматические, шт</li>
                  </ul>
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.waterMeasurementDevicesAuto}</Col>
              <Col span={24}>
                <Typography.Text strong type="secondary">
                  Земельный фонд
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  <ul className={styles.list}>
                    <li>Всего (Га):</li>
                  </ul>
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.area}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  <ul className={styles.list}>
                    <li>Под насаждения (Га):</li>
                  </ul>
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.areaPlanting}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  <ul className={styles.list}>
                    <li>Под вспом. постройки (Га):</li>
                  </ul>
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.additionalConstructions}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  <ul className={styles.list}>
                    <li>Прочие объекты (Га):</li>
                  </ul>
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.otherObjects}</Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>

      {isShowGeneralCharacteristicsModal && (
        <GeneralCharacteristicsModal
          setShowModal={setShowGeneralCharacteristicsModal}
          systemData={systemData}
        />
      )}
      {isShowSourceModal && (
        <SourceModal
          setShowModal={setShowSourceModal}
          systemData={systemData}
        />
      )}
      {isShowPassportDetailsModal && (
        <PassportDetailsModal
          setShowModal={setShowPassportDetailsModal}
          systemData={systemData}
        />
      )}
    </Space>
  );
};
