import { PrimaryButton } from '@features/ui-kit';
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { months } from '@constants/month';
import { CustomSegmented, PrimaryButtonV2 } from '@features/ui-kit-v2';
import { EditableTable } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/EditableTable';
import { GroundWaterDataFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/GroundWaterDataTable/GroundWaterDataFilters';
import { useGroundWaterDataTableColumns } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/GroundWaterDataTable/useGroundWaterDataTableColumns';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import {
  CreateDepthApiArg,
  useCreateDepthOccurrenceMutation,
  useGetDepthOccurrenceQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { isEmpty } from '@utils/utils';

const defaultData = [
  {
    decade: '1 Декада',
    id: 1,
    toTheBottom: 0,
    toTheWater: 0,
    headHeight: 0,
  },
  {
    decade: '2 Декада',
    id: 2,
    toTheBottom: 0,
    toTheWater: 0,
    headHeight: 0,
  },
  {
    decade: '3 Декада',
    id: 3,
    toTheBottom: 0,
    toTheWater: 0,
    headHeight: 0,
  },
  {
    decade: 'Средний за месяц',
    id: 4,
    toTheBottom: 0,
    toTheWater: 0,
    headHeight: 0,
  },
];
const monthsData: SegmentedLabeledOption[] = months.map((item, index) => ({
  value: `${index + 1}`,
  label: item,
}));
export const GroundWaterDataTable = () => {
  const { columns } = useGroundWaterDataTableColumns();
  const [data, setData] = useState(defaultData);
  const { filters, setDictionaryTableFilters } = useEnteringDataTablesFilters();
  const [createDepthOccurrence] = useCreateDepthOccurrenceMutation();
  const {
    data: depthOccurrence,
    isFetching,
    ...rest
  } = useGetDepthOccurrenceQuery(
    filters?.monthNumber
      ? {
          districtId: filters?.districtId,
          pointId: filters?.pointId,
          regionId: filters?.regionId,
          year: filters?.year,
          zoneId: filters?.zoneId,
          month: filters?.monthNumber,
        }
      : skipToken,
  );
  useEffect(() => {
    if (!isEmpty(depthOccurrence)) {
      const newData = depthOccurrence?.map((item: any) => {
        const selectedCol = defaultData.find((col) => col.id === item.decade);
        return {
          ...selectedCol,
          toTheBottom: item?.toTheBottom,
          toTheWater: item?.toTheWater,
          headHeight: item?.headHeight,
        };
      });

      setData(newData);
    } else {
      setData(defaultData);
    }
  }, [depthOccurrence]);

  const [groundWaterState, setGroundWaterState] = useState<
    Partial<CreateDepthApiArg>
  >({});

  const onSelectMonth = (value: SegmentedValue) => {
    setGroundWaterState({ ...groundWaterState, monthNumber: +value });

    setDictionaryTableFilters({ ...filters, monthNumber: +value });
  };
  const onSave = () => {
    const res = data.map((item: any) => {
      return {
        year: groundWaterState.year,
        monthNumber: groundWaterState.monthNumber,
        decadeNumber: item.id,
        toTheBottom: item.toTheBottom,
        toTheWater: item.toTheWater,
        headHeight: item.headHeight,
        zoneId: groundWaterState.zoneId,
        pointId: groundWaterState.pointId,
      };
    });
    createDepthOccurrence(res as Array<Required<CreateDepthApiArg>>);
  };

  const addGroundWaterData = (value: any) => {
    const newData = value.reduce(
      (acc: any, item: any) => {
        if (item.id === 4) {
          acc.data.push({
            ...item,
            toTheBottom: acc?.sum?.toTheBottom / 3,
            toTheWater: acc?.sum?.toTheWater / 3,
            headHeight: acc?.sum?.headHeight / 3,
          });
          return acc;
        }
        acc.sum.toTheBottom =
          Number(item.toTheBottom) + Number(acc.sum.toTheBottom);
        acc.sum.toTheWater =
          Number(item.toTheWater) + Number(acc.sum.toTheWater);
        acc.sum.headHeight =
          Number(item.headHeight) + Number(acc.sum.headHeight);
        acc.data.push(item);
        return acc;
      },
      { data: [], sum: { toTheBottom: 0, toTheWater: 0, headHeight: 0 } },
    );
    setData(newData.data);
  };

  useEffect(() => {
    setGroundWaterState({
      monthNumber: filters.monthNumber,
      zoneId: filters.zoneId,
      pointId: filters.pointId,
      year: filters.year,
    });
  }, [filters]);

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <GroundWaterDataFilters />
          </Col>
          {filters.year && filters.zoneId && filters.pointId && (
            <Col span={24}>
              <CustomSegmented
                colorType="primary"
                defaultValue={1}
                options={[
                  {
                    value: '0',
                    label: filters.year,
                    disabled: true,
                  },
                  ...monthsData,
                ]}
                queryParams={{
                  queryKey: 'monthNumber',
                  queryType: 'monthNumber',
                }}
                block
                onChange={onSelectMonth}
              />
            </Col>
          )}
        </Row>
      </Col>
      {groundWaterState.monthNumber &&
        groundWaterState.zoneId &&
        groundWaterState.pointId && (
          <Col span={24}>
            <Row gutter={[20, 20]} justify="end">
              <Col span={24}>
                <EditableTable
                  isLoading={isFetching}
                  setData={addGroundWaterData}
                  dataSource={data}
                  defaultColumns={columns}
                />
              </Col>
              <Col offset={20} span={2}>
                <PrimaryButton htmlType="button">Отмена</PrimaryButton>
              </Col>
              <Col span={2}>
                <PrimaryButton htmlType="submit" onClick={onSave}>
                  Сохранить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        )}
    </Row>
  );
};
