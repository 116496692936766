import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { useGetWaterAccountingJournalQuery } from '@store/gisproApi';
import { getDecadeFilterSelector } from '@store/selectors';
import { toInteger } from '@utils/utils';

import { useWaterAccountingFilters } from '../../useWaterAccountingFilters';
import { mapWaterLogAccnountingTable } from '@features/waterAccounting/waterLogAccounting/waterLogAccountingTable/mapWaterLogAccnountingTable';
import { useWaterLogAccountingTableColumns } from '@features/waterAccounting/waterLogAccounting/waterLogAccountingTable/useWaterLogAccoutingTableColumns';
import React, { useRef, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { PrintIcon } from '@icons/PrintIcon';
import { PageQueryKey } from '@constants/queryKeys';
import { useReactToPrint } from 'react-to-print';
import { saveUrl } from '@utils/returnUrl';

export const WaterLogAccountingTable = () => {
  const printRef = useRef<any>();
  const decade = useSelector(getDecadeFilterSelector);

  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(params.get(PageQueryKey) || 1);
  const { filters } = useWaterAccountingFilters('waterLog');
  const navigate = useNavigate();
  const saveAction = saveUrl();

  const decadeDaysValue = {
    '1': [1, 10],
    '2': [11, 20],
    '3': [21, 30],
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'waterLogAccountingPrint',
    onBeforeGetContent: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Балансовая ведомость') {
          item.style.display = 'none';
        }
      });
    },
    onAfterPrint: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Балансовая ведомость') {
          item.style.display = 'block';
        }
      });
    },
  });

  const numbersForFirstDecade = Array.from(
    {
      length:
        decadeDaysValue[filters.decade][1] -
        decadeDaysValue[filters.decade][0] +
        1,
    },
    (_, index) => index + decadeDaysValue[decade][0],
  );

  const {
    data: { content: waterSupplies = [], totalElements, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetWaterAccountingJournalQuery({
    page,
    month: filters.month,
    decadeDays: numbersForFirstDecade,
    decade: toInteger(filters.decade),
    filter: JSON.stringify(filters),
    year: filters.year,
  });

  const { columns } = useWaterLogAccountingTableColumns({
    month: filters.month,
    decade: filters.decade,
    decadeDay: numbersForFirstDecade,
    year: String(filters.year),
  });
  const { data } = mapWaterLogAccnountingTable(waterSupplies);

  const onBalanceSheetClick = (record: any) => {
    navigate(
      generatePath(Routes.WaterAccountingBalanceSheet, {
        outletId: record.outletId,
        year: `${filters.year}`,
        decade: filters.decade,
        month: filters.month,
      }),
    );
    saveAction();
  };

  const onTablePageChange = (pagination: any) => {
    params.set(PageQueryKey, pagination.current);
    setParams(params);
    setPage(pagination.current);
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>Журнал водоподачи</Typography.Title>
          </Col>
          <Col>
            <PrimaryButton onClick={handlePrint} icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      <Col span={24} ref={printRef}>
        <TableCustom
          scroll={{ x: 3000 }}
          columns={columns}
          dataSource={data}
          loading={isFetching || isLoading}
          bordered
          totalCount={totalElements}
          customPagination={rest}
          size="small"
          onChange={onTablePageChange}
          actionProps={{
            onBalanceSheetClick,
          }}
        />
      </Col>
    </CustomCard>
  );
};
