import { ArgLayerPolygon } from '@utils/map/Line/types';
import { ReclamationStatesEnum } from '@utils/map/Map';

export const getStylePolygon = (argPolygon: ArgLayerPolygon) => {
  const { map, layer, name, L, indicator } = argPolygon;

  //Бассейн суточного регулирования (БСР)
  if (name === 'geonode:daily_regulation_pool') {
    return layer.setStyle({
      color: '#0070FF',
      fillColor: '#6FE9F6',
      fillOpacity: 0.5,
      weight: 2,
    });
  }

  //Бассейн декадного регулирования (БДР)
  if (name === 'geonode:decade_regulation_pool') {
    return layer.setStyle({
      color: '#0070FF',
      fillColor: '#6FE9F6',
      fillOpacity: 0.5,
      weight: 2,
    });
  }

  //Селе-хранилища
  if (name === 'geonode:storage facilities') {
    L.polylineDecorator(layer, {
      patterns: [
        {
          offset: 0,
          repeat: 30,
          symbol: L.Symbol.dash({
            pixelSize: 10,
            pathOptions: {
              color: '#002d80',
              weight: 2,
            },
          }),
        },
      ],
    }).addTo(map);

    return layer.setStyle({
      color: '#000',
      fillOpacity: 0,
      weight: 0,
    });
  }

  //Пруд
  if (name === 'geonode:pond') {
    return layer.setStyle({
      color: '#0070FF',
      fillColor: '#6FE9F6',
      fillOpacity: 0.5,
      weight: 2,
    });
  }

  //Лесополоса
  if (name === 'geonode:forest_belts') {
    const fillPolygon = L.polygon(layer.getLatLngs(), {
      fillColor: '#85ff72',
      fillOpacity: '0.5',
    });

    fillPolygon.addTo(map);

    fillPolygon.customId = layer?.feature?.id;

    const shape = new L.PatternCircle({
      x: 12,
      y: 12,
      radius: 2,
      fill: true,
      color: '#000',
      fillOpacity: 1,
    });

    const pattern = new L.Pattern({
      width: 25,
      height: 25,
      fillOpacity: 1,
    });
    pattern.addShape(shape);
    pattern.addTo(map);

    return layer.setStyle({
      color: '#85ff72',
      fillPattern: pattern,
      weight: 2,
    });
  }

  //Водохранилища
  if (name === 'geonode:reservoirs') {
    return layer.setStyle({
      color: '#0070FF',
      fillColor: '#6FE9F6',
      fillOpacity: 0.5,
      weight: 2,
    });
  }

  //Мелиаративные зоны
  if (name === 'geonode:meliration_zone') {
    if (indicator?.type?.toUpperCase() === 'DOGL') {
      const zone = parseFloat(indicator.toTheWater);

      if (zone < 1.0) {
        return layer.setStyle({
          color: '#98c6ff',
          fillColor: '#98c6ff',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 1.0 && zone < 1.5) {
        return layer.setStyle({
          color: '#86ff96',
          fillColor: '#86ff96',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 1.5 && zone < 2.0) {
        return layer.setStyle({
          color: '#ffc996',
          fillColor: '#ffc996',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 2.0 && zone < 3.0) {
        return layer.setStyle({
          color: '#fffa31',
          fillColor: '#fffa31',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 3.0 && zone < 5.0) {
        return layer.setStyle({
          color: '#925caf',
          fillColor: '#925caf',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 5.0) {
        return layer.setStyle({
          fillColor: '#000000',
          fillOpacity: 0.1,
          weight: 0,
        });
      }
    }

    if (indicator?.type?.toUpperCase() === 'LT') {
      const zone = parseFloat(indicator.gl);

      if (zone >= 0 && zone <= 1.0) {
        return layer.setStyle({
          color: '#ffc996',
          fillColor: '#ffc996',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 1.0 && zone <= 3.0) {
        return layer.setStyle({
          color: '#86ff96',
          fillColor: '#86ff96',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 3.0 && zone <= 5.0) {
        return layer.setStyle({
          color: '#ff67c0',
          fillColor: '#ff67c0',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (zone >= 5.0) {
        return layer.setStyle({
          color: '#925caf',
          fillColor: '#925caf',
          fillOpacity: 0.5,
          weight: 3,
        });
      }
    }

    if (indicator?.type?.toUpperCase() === 'RS') {
      const text = indicator.reclamationCondition;

      if (text === ReclamationStatesEnum.GOOD) {
        return layer.setStyle({
          color: '#86ff96',
          fillColor: '#86ff96',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (text === ReclamationStatesEnum.SATISFACTORY) {
        return layer.setStyle({
          color: '#fffa31',
          fillColor: '#fffa31',
          fillOpacity: 0.5,
          weight: 3,
        });
      }

      if (text === ReclamationStatesEnum.UNACCEPTABLE_DEPTH) {
        const stripes = new L.StripePattern({
          angle: 90,
          weight: 1,
          color: '#000',
          spaceWidth: 5,
        });

        stripes.addTo(map);

        return layer.setStyle({
          color: '#000',
          fillPattern: stripes,
          weight: 2,
        });
      }

      if (text === ReclamationStatesEnum.UNACCEPTABLE_DEPTH) {
        const stripes = new L.StripePattern({
          angle: 180,
          weight: 1,
          color: '#000',
          spaceWidth: 5,
        });

        stripes.addTo(map);

        return layer.setStyle({
          color: '#000',
          fillPattern: stripes,
          weight: 2,
        });
      }

      if (text === ReclamationStatesEnum.UD_SS) {
        const shape = new L.PatternRect({
          width: 100,
          height: 100,
          fillOpacity: 0,
        });

        const pattern = new L.Pattern({ width: 100, height: 100 });
        pattern.addShape(shape);
        pattern.addTo(map);

        return layer.setStyle({
          color: '#000',
          fillPattern: pattern,
          weight: 2,
        });
      }
    }
  }

  //Напорный бассейн ++
  if (name === 'geonode:pressure_pool') {
    return layer.setStyle({
      color: '#004DA8',
      fillColor: '#49EDFF',
      fillOpacity: 0.5,
      weight: 3,
    });
  }

  //Пруд ++
  if (name === 'geonode:pond') {
    return layer.setStyle({
      color: '#0070FF',
      fillColor: '#6FE9F6',
      fillOpacity: 0.5,
      weight: 2,
    });
  }

  //Район --
  // if (name === 'polygon2') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0,
  //     weight: 2,
  //   });
  // }

  //Хорошее мелиоративное состояние
  // if (name === 'polygon3') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: 'green',
  //   });
  // }

  //Засоление почв
  // if (name === 'polygon4') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#ddd',
  //   });
  // }

  //Недопустимая глубина грунтовых вод
  // if (name === 'polygon5') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#850000',
  //   });
  // }

  //Удовлетворительное мелиоративное состояние
  // if (name === 'polygon6') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#fffa31',
  //   });
  // }

  //Мин.грунт.вод (более 5,0 г
  // if (name === 'polygon7') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#ff25a1',
  //   });
  // }

  //Мин.грунт
  // if (name === 'polygon8') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#2542ff',
  //   });
  // }

  //Мин.грунт.вод (1,0-3,0г
  // if (name === 'polygon9') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#3ebdff',
  //   });
  // }

  //Мин.грунт.вод (3,0-5,0 г
  // if (name === 'polygon10') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#13ff38',
  //   });
  // }

  //Мин.грунт.вод (3,0-5,0 г
  // if (name === 'polygon11') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#13ff38',
  //   });
  // }

  //УГВ более 5м
  // if (name === 'polygon12') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#ff5900',
  //   });
  // }

  //УГВ 0-1м
  // if (name === 'polygon13') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#3ebdff',
  //   });
  // }

  //УГВ 1,5-2м
  // if (name === 'polygon14') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#9f3000',
  //   });
  // }

  //УГВ 1-1,5м
  // if (name === 'polygon15') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#0d9a00',
  //   });
  // }

  //УГВ 2-3м
  // if (name === 'polygon16') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#fffa31',
  //   });
  // }

  //УГВ 3-5м
  // if (name === 'polygon17') {
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0.5,
  //     weight: 0,
  //     fillColor: '#be32ff',
  //   });
  // }

  //Населенный пункт
  // if (name === 'polygon18') {
  //   const stripes = new L.StripePattern({
  //     angle: 180,
  //     weight: 1,
  //     color: '#000',
  //     spaceWidth: 5,
  //   });
  //
  //   stripes.addTo(map);
  //
  //   return layer.setStyle({
  //     color: '#000',
  //     fillPattern: stripes,
  //     weight: 2,
  //   });
  // }

  //Очистное сооружение
  // if (name === 'geonode:sewage_treatment_plant') {
  //   const shape = new L.PatternRect({
  //     width: 100,
  //     height: 100,
  //     fillOpacity: 0,
  //   });
  //
  //   const pattern = new L.Pattern({ width: 100, height: 100 });
  //   pattern.addShape(shape);
  //   pattern.addTo(map);
  //
  //   return layer.setStyle({
  //     color: '#000',
  //     fillPattern: pattern,
  //     weight: 2,
  //   });
  // }

  //Осадочный бассейн
  if (name === 'geonode:sedimentary pool') {
    const fillPolygon = L.polygon(layer.getLatLngs(), {
      fillColor: '#50C1FF',
      fillOpacity: '0.1',
    });

    fillPolygon.addTo(map);

    fillPolygon.customId = layer?.feature?.id;

    const shape = new L.PatternPath({
      d: 'M0 0h10v2H0V0z',
      fill: true,
      color: 'black',
    });

    const pattern = new L.Pattern({
      width: 50,
      height: 50,
    });
    pattern.addShape(shape);
    pattern.addTo(map);

    return layer.setStyle({
      color: '#004DA8',
      fillPattern: pattern,
      weight: 2,
    });
  }

  //Отстойник
  // if (name === 'geonode:water_sump') {
  //   L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 20,
  //         symbol: L.Symbol.arrowHead({
  //           pixelSize: 8,
  //           polygon: false,
  //           pathOptions: {
  //             stroke: true,
  //             color: '#000',
  //             weight: 2,
  //           },
  //           headAngle: 180,
  //         }),
  //       },
  //     ],
  //   }).addTo(map);
  //
  //   return layer.setStyle({
  //     color: '#000',
  //     fillColor: '#04D2FF',
  //     fillOpacity: 0.1,
  //     weight: 2,
  //   });
  // }

  //Пескогравиловка
  if (name === 'geonode:sandgravielovka') {
    const shape = new L.PatternPath({
      d: 'M0 0h10v10H0V0z',
      fill: true,
      color: 'black',
    });

    const pattern = new L.Pattern({
      width: 50,
      height: 50,
    });
    pattern.addShape(shape);
    pattern.addTo(map);

    return layer.setStyle({
      color: '#000',
      fillPattern: pattern,
      weight: 2,
    });
  }

  //Резервуар
  if (name === 'geonode:reservoir_others') {
    const shape = new L.PatternPath({
      d: 'M 0 24.24 L 24.24 0',
      fill: true,
      color: '#004DA8',
    });

    const pattern = new L.Pattern({
      width: 50,
      height: 50,
    });
    pattern.addShape(shape);
    pattern.addTo(map);

    return layer.setStyle({
      color: '#000',
      fillPattern: pattern,
      weight: 2,
    });
  }

  //Скотопрогон
  // if (name === 'polygon10') {
  //   L.polylineDecorator(layer, {
  //     patterns: [
  //       {
  //         offset: 0,
  //         repeat: 50,
  //         symbol: L.Symbol.marker({
  //           rotate: true,
  //           markerOptions: {
  //             icon: L.icon({
  //               iconUrl: '/icons/iconPolygon.svg',
  //               iconSize: [15, 15],
  //               offset: 10,
  //               iconAnchor: [1, 0],
  //             }),
  //           },
  //         }),
  //       },
  //     ],
  //   }).addTo(map);
  //
  //   return layer.setStyle({
  //     color: '#000',
  //     fillOpacity: 0,
  //     weight: 2,
  //   });
  // }

  return layer;
};
