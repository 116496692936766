import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useAccessControllerCreateRoleMutation,
  useAccessControllerGetRolesQuery,
  useAccessControllerUpdateRoleMutation,
} from '@store/api/accessApi';
import { Col, Form, Row, Space, Typography } from 'antd';
import { FC, useMemo } from 'react';

interface IRoleModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const RoleModal: FC<IRoleModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const [createEntity, { isError }] = useAccessControllerCreateRoleMutation();
  const [updateEntity, { isError: isErrorUpdate }] =
    useAccessControllerUpdateRoleMutation();

  const { data: roles = [] } = useAccessControllerGetRolesQuery();
  const mappedRoles = useMemo(() => {
    return roles.reduce<{ label: string; value: number }[]>((acc, item) => {
      if (item?.readonly) {
        acc.push({ label: item.title, value: item.id });
      }
      return acc;
    }, []);
  }, [roles]);

  const onFinish = (values: any) => {
    if (initialValues) {
      updateEntity({
        id: initialValues.id,
        accessCreateRoleDto: { ...values, parent: values?.parent?.id },
      }).then(() => setShowModal(false));
      return;
    }
    createEntity({
      accessCreateRoleDto: { ...values, parent: values?.parent?.id },
    }).then(() => {
      setShowModal(false);
    });
  };

  return (
    <CustomModal
      open={isModalShow}
      title="Создать"
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Ключ роли"
              name="roleName"
              rules={[{ required: true }]}
            >
              <TooltipedInput disabled={initialValues?.readonly} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Заголовок"
              name="title"
              rules={[{ required: true }]}
            >
              <TooltipedInput />
            </Form.Item>
          </Col>
          {initialValues?.readonly ? null : (
            <Col span={24}>
              <Form.Item label="Источник" name={['parent', 'id']}>
                <TooltipedSelect allowClear options={mappedRoles} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
