import React from 'react';

import { Col, Row } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './SpprBuildingTable.module.scss';

export const useSpprBuildingTableColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Направление',
      align: 'center',
      dataIndex: 'direction',
      xtype: CustomTableColumnType.RenderCustom,
      width: 150,
      key: 'direction',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.direction?.title || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Объект',
      align: 'center',
      dataIndex: 'constructionObject',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'constructionObject',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.constructionObject?.name || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Категория объекта',
      align: 'center',
      dataIndex: 'constructionObject',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'constructionObject',
      width: 150,
      renderCustom: ({ record }) => {
        return (
          <div>
            <span>
              {record?.constructionObject?.objectCategory?.title || ''}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Система',
      align: 'center',
      dataIndex: 'system',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'system',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.system?.name || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Сроки',
      align: 'center',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 250,
      xtype: CustomTableColumnType.String,
      deadlineTime: 'start',
      className: styles.deadlineColumn,
      children: [
        {
          title: 'Начало',
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          deadlineTime: 'start',
          xtype: CustomTableColumnType.Deadline,
        },
        {
          title: 'Конец',
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          deadlineTime: 'end',
          xtype: CustomTableColumnType.Deadline,
        },
      ],
    },
    {
      title: 'Бюджет',
      align: 'center',
      dataIndex: 'budget',
      key: 'budget',
      className: styles.budgetColumn,
      width: 295,
      xtype: CustomTableColumnType.Budget,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      width: 250,
      xtype: CustomTableColumnType.Status,
    },
    {
      title: 'Действия',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
