import React from 'react';

import styles from './StatusCard.module.scss';

export type StatusCardType = 'good' | 'satisfying' | 'normal' | 'bad';

interface StatusCardProps {
  status: StatusCardType;
  total?: string;
  type?: string;
}

enum StatusLabel {
  good = 'Хорошее',
  satisfying = 'Удов.',
  normal = 'Среднее',
  bad = 'Плохое',
}
export const StatusCard: React.FC<StatusCardProps> = ({
  status,
  total,
  type,
}) => {
  return (
    <div className={[styles.statusCard, styles[status]].join(' ')}>
      {StatusLabel[status]}
      {total && <hr className={styles.divider} />}
      {type === 'channelsInfo' && total ? parseFloat(total) * 1000 : total}
    </div>
  );
};
