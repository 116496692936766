import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/es/select';

import { MONTH_DAYS, ONE_DAY } from '@constants/month';
import {
  monthOptions,
  weekOptionFn,
  yearOptions,
} from '@features/decadaApp/decadeAppFilters/options';
import {
  PrimaryButtonV2,
  SectionTitle,
  TooltipedDatePickerV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { convertToTimeStamp } from '@utils/timestamp';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';

import { MainMonitoringIFrame } from './iframes/MainMonitoringIFrame';
import { MainMonitoringTable } from './table/MainMonitoringTable';

interface IFilterOptions {
  sys_id: number;
  start_date: number;
  end_date: number;
  period: number;
}

export const periodOptions: DefaultOptionType[] = [
  {
    label: 'За месяц',
    value: '30',
  },
  {
    label: 'За неделю',
    value: '7',
  },
  {
    label: 'За день',
    value: '1',
  },
];

export const MonitoringContent: React.FC = () => {
  const { section } = useParams();
  const [form] = useForm();
  const startTimestamp = dayjs().date(ONE_DAY).year(2023).month(10).unix();

  const endTimestamp = dayjs
    .unix(startTimestamp)
    .add(MONTH_DAYS, 'days')
    .unix();

  const [filters, setFilters] = useState<IFilterOptions>({
    sys_id: toInteger(section),
    start_date: startTimestamp,
    end_date: endTimestamp,
    period: MONTH_DAYS,
  });

  const period = Form.useWatch('period', form);
  const yearValue = Form.useWatch('year', form);
  let interval: 'week' | 'month' | 'day';
  switch (period) {
    case '7':
      interval = 'week';
      break;
    case '30':
      interval = 'month';
      break;
    case '1':
      interval = 'day';
      break;
    default:
      interval = 'month';
      break;
  }
  const onFinish = (values: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: convertToTimeStamp({
        type: 'start',
        values: {
          interval: values[interval],
          year: values.year,
          period: values.period,
        },
      }),
      end_date: convertToTimeStamp({
        type: 'end',
        values: {
          interval: values[interval],
          year: values.year,
          period: values.period,
        },
      }),
      period: values.period,
    }));
  };

  const intervalDefaultValue = () => {
    let defaultValue;
    switch (interval) {
      case 'week':
        defaultValue = '2023-01-01';
        break;
      case 'month':
        defaultValue = '0';
        break;
      case 'day':
        defaultValue = '2023-01-01';
        break;
      default:
        defaultValue = '0';
        break;
    }

    return defaultValue;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Form
        form={form}
        style={{ width: '100%' }}
        onFinish={onFinish}
        initialValues={{
          period: '30',
          year: '2023',
          month: String(dayjs().month()),
          day: dayjs(),
        }}
      >
        <Row gutter={30} justify="end">
          <Col span={3}>
            <Form.Item name="period">
              <TooltipedSelectV2 options={periodOptions} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="year">
              <TooltipedSelectV2 options={yearOptions} />
            </Form.Item>
          </Col>
          <Col span={3}>
            {period !== '1' ? (
              <Form.Item name={period === '30' ? 'month' : 'week'}>
                <TooltipedSelectV2
                  options={
                    period === '7' ? weekOptionFn(yearValue) : monthOptions
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="day"
                valuePropName="year"
                normalize={(date: Dayjs) => date?.format('YYYY-MM-DD')}
              >
                <TooltipedDatePickerV2 placeholder="Календарь" />
              </Form.Item>
            )}
          </Col>
          <Col span={4}>
            <Form.Item>
              <PrimaryButtonV2 htmlType="submit" title="Показать" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <div>
          <SectionTitle background="blue" title="Мониторинг" />
          <MainMonitoringIFrame filters={filters} systemId={section || '4'} />
        </div>
        <div>
          <SectionTitle title="Статистика по гидропостам" background="blue" />
          <MainMonitoringTable filters={filters} />
        </div>
      </Space>
    </div>
  );
};
