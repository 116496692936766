export const documentFormats = [
  {
    label: 'PDF',
    fullFormat: '*.pdf',
    value: 'application/pdf',
    formats: ['pdf'],
    key: 'pdf',
  },
  {
    label: 'Word',
    value: 'application/msword,.docx',
    fullFormat: '*.dot;*.doc;*.docx',
    formats: ['docx'],
    key: 'docs',
  },
  {
    label: 'Excel',
    fullFormat: '*.xls;*.csv',
    value: 'application/vnd.ms-excel,.csv',
    formats: ['csv'],
    key: 'csv',
  },
  {
    label: 'PowerPoint',
    value: 'application/vnd.ms-powerpoint,.bmp',
    format: ['bmp'],
    fullFormat: '*.ppt;*.bmp',
    key: 'diagram',
  },
  {
    label: 'Фотография',
    value: 'image/jpeg,image/png,image/gif,image/bmp,image/webp,image/ico,image/xbm,.heic,.heif',
    formats: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'ico', 'xbm', 'heic', 'heif'],
    fullFormat:
      '*.tiff;*.jfif;*.bmp;*.gif;*.png;*.jpeg;*.jpg;**.webp;*.ico;*xbm;*.dib;*.pjp;**.apng;*.tif;*.pjpeg;*.avif;*.heic;*.heif',
    key: 'photo',
  }
];
