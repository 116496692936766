import { Divider, PrimaryButton, TableCustom } from '@features/ui-kit';
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { quartalOptions } from '@constants/quartalOptions';
import { QuarterQueryKey } from '@constants/queryKeys';
import { CustomSegmented } from '@features/ui-kit-v2';
import { EditableTable } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/EditableTable';
import { MineralizationDataFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/MineralizationDataTable/MineralizationDataFilters';
import { useMineralizationDataTableColumns } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/MineralizationDataTable/useMineralizationDataTableColumns';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import { useMonitoringPointsTableColumns } from '@features/waterQuality/tables/monitoringPointsTable/monitoringPointsTableContainer/useMonitoringPointsTableColumns';
import {
  CreateLaboratoryTestsApiArg,
  CreateReclamationApiArg,
  useCreateLaboratoryTestsMutation,
  useGetLaboratoryTestsQuery,
  useGetPointsQuery,
} from '@store/gisproApi';
import { isEmpty } from '@utils/utils';
import { skipToken } from '@reduxjs/toolkit/query';

export const MineralizationDataTable = () => {
  const { columns } = useMineralizationDataTableColumns();
  const { columns: pointsColumns } = useMonitoringPointsTableColumns();
  const [createLaboratoryTests] = useCreateLaboratoryTestsMutation();

  const { filters, setDictionaryTableFilters } = useEnteringDataTablesFilters();
  const [pointData, setPointData] = useState<any>([]);
  const {
    data: content = [],
    isLoading,
    isFetching,
  } = useGetPointsQuery(filters as any);

  const { data: laboratoryTests = [] } = useGetLaboratoryTestsQuery(
    filters?.quarter
      ? {
          year: filters?.year,
          quarter: filters?.quarter,
          regionId: filters?.regionId,
          districtId: filters?.districtId,
          zoneId: filters?.zoneId,
        }
      : skipToken,
  );

  const [mineralizationState, setMineralizationState] = useState<
    Partial<CreateReclamationApiArg>
  >({});

  const onSelectQuarter = (value: SegmentedValue) => {
    setMineralizationState({ ...mineralizationState, quarter: +value });
    setDictionaryTableFilters({ ...filters, quarter: +value });
  };

  const updatePointData = (content: any, laboratoryTests: any) => {
    return content.map((item: any) => {
      let initialData = {
        ...item,
        gl: 0,
        ph: 0,
        ms: 0,
      };

      const selectedCol = laboratoryTests.find(
        (col: any) => col.pointId === item.id,
      );

      if (selectedCol) {
        initialData = {
          ...item,
          gl: selectedCol.gl,
          ph: selectedCol.ph,
          ms: selectedCol.ms,
        };
      }

      return initialData;
    });
  };

  useEffect(() => {
    const newData = updatePointData(content, laboratoryTests);
    setPointData(newData);
  }, [laboratoryTests, content]);

  const onSave = () => {
    const res = pointData.map((item: any) => {
      return {
        year: mineralizationState.year,
        quarterNumber: mineralizationState.quarter,
        ms: item.ms,
        ph: item.ph,
        gl: item.gl,
        zoneId: mineralizationState.zoneId,
        pointId: item.id,
      };
    });
    createLaboratoryTests(res as Array<Required<CreateLaboratoryTestsApiArg>>);
  };

  const onHandleEdit = (v: any) => {
    setPointData(v);
  };

  const onResetData = () => {
    const newData = updatePointData(content, laboratoryTests);
    setPointData(newData);
  };

  useEffect(() => {
    setMineralizationState({
      year: filters.year,
      quarter: filters.quarter,
      zoneId: filters.zoneId,
    });
  }, [filters]);

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <MineralizationDataFilters />
          </Col>
          {filters.year && filters.zoneId && (
            <Col span={24}>
              <CustomSegmented
                colorType="primary"
                defaultValue={1}
                options={[
                  {
                    value: '0',
                    label: filters.year,
                    disabled: true,
                  },
                  ...quartalOptions,
                ]}
                queryParams={{
                  queryKey: QuarterQueryKey,
                  queryType: QuarterQueryKey,
                }}
                block
                onChange={onSelectQuarter}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      {mineralizationState.quarter &&
        mineralizationState.year &&
        mineralizationState.zoneId && (
          <Col span={24}>
            <Row gutter={[20, 20]} justify="end">
              <Col span={24}>
                <EditableTable
                  isLoading={isFetching}
                  setData={onHandleEdit}
                  dataSource={pointData as any}
                  defaultColumns={columns}
                />
              </Col>
              <Col offset={18} span={2}>
                <PrimaryButton
                  type="default"
                  htmlType="button"
                  onClick={onResetData}
                >
                  Отмена
                </PrimaryButton>
              </Col>
              <Col span={3}>
                <PrimaryButton htmlType="submit" onClick={onSave}>
                  Сохранить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        )}
    </Row>
  );
};
