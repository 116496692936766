import { FC } from 'react';

import { Col, Row, Typography } from 'antd';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { PrintIcon } from '@icons/PrintIcon';

import { columns } from './columns';
import { useGet2tpWaterUseConsolidatedDataQuery } from '@store/gisproApi';
import { useConsolidatedReportFilter } from '../../useConsolidatedReportFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReservoirMonitoringMask } from '@features/monitoring/reservoirMonitoring/reservoirMonitoringMask/ReservoirMonitoringMask';

export const WaterUseGeneralInforammtionTable: FC = () => {
  const { filters } = useConsolidatedReportFilter();
  const { data } = useGet2tpWaterUseConsolidatedDataQuery(
    filters.year ? { ...filters } : skipToken,
  );
  return (
    <CustomCard
      title={
        <Row gutter={32} justify="space-between">
          <Col style={{ flexGrow: 1, borderBottom: '1px solid #0000000F' }}>
            <Typography.Title style={{ marginBottom: 0 }} level={4}>
              Общие сведения по использованию воды
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      {filters?.year ? (
        <TableCustom bordered columns={columns} dataSource={data || []} />
      ) : (
        <ReservoirMonitoringMask maskText="Для начала работы выберите год" />
      )}
    </CustomCard>
  );
};
