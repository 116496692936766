import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { PrimaryButton, TableCustom } from '@features/ui-kit';
import { CustomCard, SectionTitle, TablePreviewV2 } from '@features/ui-kit-v2';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { EmployeeTypesEnum } from '@models/waterUser/enums/waterUserEnums';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

import { useAddEmployee } from '../useAddEmployee';
import { useEditEmployee } from '../useEditEmployee';

export const EmployeesTable: FC = () => {
  const { t } = useTranslation();
  const { removeEmployee, editEmployeeId } = useEditEmployee();
  const { employees = [] } = useAddEmployee();
  const { setShowModal, setCurrentEmployeeType } = useAddEmployee();
  const { data: positionsList = [], isLoading: positionsListIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.PositionsList,
    });
  const columns = useMemo((): TableColumn[] => {
    return [
      {
        title: '№',
        dataIndex: 'employeeId',
        xtype: CustomTableColumnType.RenderCustom,
        key: 'employeeId',
        align: 'center',
        renderCustom: ({ record }) =>
          employees.findIndex(
            (employee) => employee.employeeId === record.employeeId,
          ) + 1,
      },
      {
        title: t('createLegalWaterRegistry.secondStep.placeholders.role'),
        dataIndex: 'positionEmployee',
        xtype: CustomTableColumnType.RenderCustom,
        key: 'position',
        align: 'center',
        renderCustom: ({ record }) => (
          <div>
            {positionsList.find((item) => item.id === record.positionEmployee)
              ?.title || '-'}
          </div>
        ),
      },
      {
        title: t('createLegalWaterRegistry.secondStep.placeholders.lastName'),
        dataIndex: 'lastName',
        xtype: CustomTableColumnType.String,
        key: 'lastName',
        align: 'center',
      },
      {
        title: t('createLegalWaterRegistry.secondStep.placeholders.firstName'),
        dataIndex: 'firstName',
        xtype: CustomTableColumnType.String,
        key: 'firstName',
        align: 'center',
      },
      {
        title: t('createLegalWaterRegistry.secondStep.placeholders.middleName'),
        dataIndex: 'middleName',
        xtype: CustomTableColumnType.String,
        key: 'middleName',
        align: 'center',
      },
      {
        title: t('createIndividualWaterRegistry.modal.pin'),
        dataIndex: 'inn',
        xtype: CustomTableColumnType.NumberColumn,
        key: 'phone',
        align: 'center',
      },
      {
        title: t('createLegalWaterRegistry.secondStep.actions'),
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        xtype: CustomTableColumnType.ActionColumn,
        actions: [
          CustomTableAction.EditWithoutText,
          CustomTableAction.DeleteWithoutText,
        ],
      },
    ];
  }, [employees]);
  return (
    <CustomCard
      title={
        <Row align="middle" justify="space-between">
          <Col span={21}>
            <Typography.Title level={4}>Список сотрудников</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col>
            <PrimaryButton
              onClick={() => {
                setCurrentEmployeeType(EmployeeTypesEnum.Employee);
                setShowModal(true);
              }}
            >
              {t('addEmployee.titles.employee')}
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <TableCustom
        bordered
        dataSource={employees}
        columns={columns}
        actionProps={{
          deleteItem: (record) => removeEmployee(record.employeeId),
          onHandleEdit: (record) => editEmployeeId(record.employeeId),
        }}
      />
    </CustomCard>
  );
};
