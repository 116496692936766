import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { DefaultButton, Divider, SectionTitle } from '@features/ui-kit';
import { ITariff } from '@models/tariff/interfaces/tariff';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetTariffByIdQuery } from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { MonthTariffTable } from './monthTariffTable/MonthTariffTable';
import styles from './TariffCard.module.scss';
import { clearTariffDocument } from '@store/slices/tariff/tariffSlice';
import { useDispatch } from 'react-redux';
import { clearUrl } from '@utils/returnUrl';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const TariffCard: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearAction = clearUrl();

  const { data: tariff = {} } = useGetTariffByIdQuery(
    toInteger(id) ? { tariffId: toInteger(id) } : skipToken,
  );

  const onCancel = () => {
    dispatch(clearTariffDocument());
    clearAction();
    navigate(Routes.TariffTable);
  };

  return (
    <section className={styles.createTariff}>
      <Row gutter={12} justify="space-between" className={styles.title}>
        <Row gutter={16}>
          <Col>
            <DefaultButton onClick={() => navigate(-1)} className="backBtn">
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Тариф',
                },
                {
                  title: `${
                    id && parseInt(id, 10) ? 'Карточка' : 'Создание'
                  } тарифа`,
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <SectionTitle
          title={`${id && parseInt(id, 10) ? 'Карточка' : 'Создание'} тарифа`}
        />
      </Row>
      <MonthTariffTable
        year={(tariff as ITariff).year || new Date().getFullYear()}
        onCancel={onCancel}
      />
    </section>
  );
};
