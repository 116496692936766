import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, notification, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { DangerButton, DefaultButton, PrimaryButton } from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  CreateAssociationWaterRegistryQueryTypes,
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import {
  saveLegalPersonApiArg,
  useWaterUserCreateMutation,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import {
  addAttachmentToWaterRegistryUserAction,
  clearEmployeesAction,
  clearFormDataAction,
} from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { ContractDetails, DocumentsBlock } from '../fields';
import { useActionSelect } from '../useActionSelect';
import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';
import { DocumentTypes } from '@enums/documentTypes';

export const AssociationMasterRegistrationFiveStep: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState<Array<IAttachment>>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentWaterRegistry = useSelector(getFormData);
  const [save] = useWaterUserCreateMutation();
  const { setShowModal: setShowActionSelectModal } = useActionSelect();
  const { setUserId } = useWaterUserId();
  const { setOrgId } = useOrganizationId();
  const { economies } = useAddEconomy();
  const onFinish = () => {
    const data = currentWaterRegistry[WaterUserTypes.association];
    const {
      employees,
      attachmentResponseDtos,
      outlets,
      initialEmployees,
      ...otherProps
    } = data;
    save({
      region: economies.association[0].regionId, // TODO взять из другого источника
      district: economies.association[0].districtId,
      legalData: otherProps,
      employees,
      outlets,
      type: WaterUserEnum.ASSOCIATION,
      attachmentsList: attachmentResponseDtos,
    } as saveLegalPersonApiArg)
      .unwrap()
      .then((res: any) => {
        if (res?.error) {
          notification.error({
            message: 'Не удалось создать водопользователя',
          });
          return;
        } else {
          setShowActionSelectModal(true);
          dispatch(
            clearFormDataAction({
              waterRegistryUserType: WaterUserTypes.association,
            }),
          );
          dispatch(clearEmployeesAction());
          setUserId(res?.id || res?.data?.id);
          setOrgId(res?.owner?.id || res?.data?.owner?.id);
          notification.success({
            message: 'Сохранено',
            description: t('Успешно сохранено'),
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Ошибка',
          description: t('Не удалось создать водопользователя'),
        });
        console.log('Error: ', err);
      });
  };

  const onFinishFailed = (err: ValidateErrorEntity) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    dispatch(clearEmployeesAction());
    navigate(Routes.WaterRegistryUsers);
  };
  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.outletConnection}`,
    );
  };

  const onAttachmentUpload = useCallback(
    (file: IAttachment) => {
      setDocuments([...documents, file]);
      dispatch(
        addAttachmentToWaterRegistryUserAction({
          attachment: file,
          waterRegistryUserType: WaterUserTypes.association,
        }),
      );
    },
    [documents],
  );

  const newDocuments: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.association].attachmentResponseDtos ||
      [],
    [currentWaterRegistry],
  );

  const attachments = newDocuments?.filter(
    (att) => att.type === DocumentTypes.Agreement,
  );

  const validateAttachments = useMemo(() => {
    return attachments?.some((att) => att.type === DocumentTypes.Agreement);
  }, [attachments]);

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <Row gutter={[30, 30]}>
        <Col span={16}>
          <ContractDetails />
        </Col>
        <Col span={8}>
          <DocumentsBlock userType={WaterUserTypes.association} />
        </Col>
        <Form.Item name="test" />
        <UploadModal
          onLoad={onAttachmentUpload}
          setShow={setShow}
          show={show}
        />
      </Row>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DangerButton
              htmlType="submit"
              size="large"
              onClick={onCancelButtonClick}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <DefaultButton
                  type="default"
                  size="large"
                  htmlType="submit"
                  onClick={onBackButtonClick}
                >
                  {t('createIndividualWaterRegistry.buttons.back')}
                </DefaultButton>
              </Form.Item>
              <Form.Item>
                <PrimaryButton
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!validateAttachments}
                >
                  {t('createLegalWaterRegistry.buttons.finish')}
                </PrimaryButton>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
