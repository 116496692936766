import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Form, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import {
  CustomCard,
  PrimaryButton,
  TableCustom,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  CreateHydroFacilitySystemApiArg,
  useCreateHydroFacilitySystemMutation,
  useFindAllChannelsByIrrigationSystemIdQuery,
  useGetAllIrrigationSystemsByHydroFacilityIdQuery,
  useGetNotBindedOutletsByChannelIdQuery,
} from '@store/gisproApi';
import { getHydroFacilityIdSelector } from '@store/selectors/hydraulicSection';

import styles from './LinkOutletTable.module.scss';
import { useLinkOutletTableColumns } from './useLinkOutletTableColumns';

export const LinkOutletTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [form] = useForm();
  const { columns } = useLinkOutletTableColumns();
  const hydroFacilityId = useSelector(getHydroFacilityIdSelector);
  const { data: irrigationSystems = [] } =
    useGetAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId ? { hydroFacilityId } : skipToken,
    );

  const irrigationSystemId = Form.useWatch('irrigationSystemId', form);
  const channelId = Form.useWatch('channelId', form);

  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    irrigationSystemId ? { id: irrigationSystemId } : skipToken,
  );
  const [save, { isError, error }] = useCreateHydroFacilitySystemMutation();

  const { data: outlets = [] } = useGetNotBindedOutletsByChannelIdQuery(
    channelId ? { id: channelId } : skipToken,
  );
  const onChange = (selectedKey: React.Key[]) => {
    setSelectedRowKeys(selectedKey);
  };

  const filteredFacilities = irrigationSystemId
    ? outlets.filter((item: any) => item.systemId === irrigationSystemId)
    : outlets;

  const onAddButtonClick = () => {
    if (hydroFacilityId) {
      const res = selectedRowKeys.reduce(
        (acc: CreateHydroFacilitySystemApiArg, curr) => {
          const outlet = outlets.find((item: any) => item.id === curr);
          if (outlet) {
            acc.outletBindDtoList.push({
              id: outlet.id,
            });
          }
          return acc;
        },
        {
          hydroFacilityId,
          outletBindDtoList: [],
        },
      );
      save(res as CreateHydroFacilitySystemApiArg);
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [hydroFacilityId]);

  return (
    <CustomCard
      title={
        <Row align="middle" justify="space-between">
          <Col className={styles.title}>Добавить водовыпуск</Col>
          <Col>
            <Form form={form}>
              <Space size={8}>
                <Form.Item
                  name="irrigationSystemId"
                  style={{ marginBottom: 0 }}
                >
                  <TooltipedSelect
                    placeholder="Ирригационная система"
                    options={irrigationSystems}
                  />
                </Form.Item>
                <Form.Item name="channelId" style={{ marginBottom: 0 }}>
                  <TooltipedSelect
                    disabled={!irrigationSystemId}
                    placeholder="Канал"
                    options={channels}
                  />
                </Form.Item>
                <PrimaryButton onClick={onAddButtonClick}>
                  Добавить
                </PrimaryButton>
              </Space>
            </Form>
          </Col>
        </Row>
      }
    >
      {!outlets.length ? (
        <div className={styles.mask}>
          <p>Выберите канал и систему</p>
        </div>
      ) : (
        <TableCustom
          rowSelection={{
            type: 'checkbox',
            columnTitle: 'Выбрать',
            onChange,
          }}
          className={styles.table}
          bordered
          size="small"
          rowKey={(item) => item.id}
          columns={columns}
          dataSource={outlets}
        />
      )}
    </CustomCard>
  );
};
