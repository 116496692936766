import { useMemo } from 'react';

import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import { WaterUsePlanCA } from '@features/waterUsePlan/waterUsePlanCA/WaterUsePlanCA';
import { WaterUsePlanGuvh } from '@features/waterUsePlan/waterUsePlanGuvh';
import { WaterUsePlanRuvh } from '@features/waterUsePlan/waterUsePlanRuvh';

export const WaterUsePlan: React.FC = () => {
  const { user } = useGetCurrentUser();

  const userRole = useMemo(() => user?.role?.roleName, [user]);

  let Component;
  switch (userRole) {
    case UserRoles.ROLE_RUVH:
      Component = WaterUsePlanRuvh;
      break;
    case UserRoles.ROLE_GUVH:
      Component = WaterUsePlanGuvh;
      break;
    case UserRoles.ROLE_CA:
      Component = WaterUsePlanCA;
      break;
    default:
      Component = null;
  }

  return Component ? <Component /> : <div />;
};
