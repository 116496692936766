import React from 'react';

import { Col, Row } from 'antd';

import styles from './CustomGanttHeader.module.scss';

export const CustomGanttHeader: React.FC = () => {
  return (
    <div
      style={{
        height: 50,
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #edecec',
        color: 'var(--text-color-dark-blue, #525f7f)',
        fontSize: 14,
        fontWeight: 600,
        borderLeft: '1px solid #edecec',
      }}
    >
      <div style={{ width: 290, borderRight: '1px solid #edecec', padding: 6 }}>
        Название
      </div>
      <div style={{ width: 310 }}>
        <div
          style={{
            padding: 6,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>Дата начала</div>
          <div>Дата окончания</div>
        </div>
      </div>
    </div>
  );
};
