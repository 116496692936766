import { useContext, useEffect, useRef } from 'react';

import { MapContext } from './MapComponentContext';

import './index.scss';

const Map: React.FC = () => {
  const mapRef = useRef(null);
  const { map } = useContext(MapContext);
  useEffect(() => {
    if (mapRef && mapRef.current && map) {
      map.init(mapRef.current, null);
    }
  }, [map]);

  return <div style={{ width: '100%', height: '100%' }} ref={mapRef} />;
};

export const MapComponent = Map;
