import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderAction } from './renderAction';
import { renderControl } from './renderControls';
import { renderDate } from './renderDate';
import { renderNumber } from './renderNumber';
import { renderStatus } from './renderStatus';
import { renderString } from './renderString';

export class RendererFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { xtype } = props.customColumn;
    let renderer = renderString;

    switch (xtype) {
      case CustomTableColumnType.ActionColumn:
        renderer = renderAction;
        break;
      case CustomTableColumnType.String:
        renderer = renderString;
        break;
      case CustomTableColumnType.NumberColumn:
        renderer = renderNumber;
        break;
      case CustomTableColumnType.DateColumn:
        renderer = renderDate;
        break;
      case CustomTableColumnType.Control:
        renderer = renderControl;
        break;
      case CustomTableColumnType.Status:
        renderer = renderStatus;
        break;

      default:
        break;
    }

    return renderer(props);
  }
}
