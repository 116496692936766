import React, { useCallback, useEffect } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { TablePaginationConfig } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  TableCustom,
} from '@features/ui-kit';
import { ITariff, ITariffFilters } from '@models/tariff/interfaces/tariff';
import { useFindAllTariffListQuery } from '@store/gisproApi';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { isEmpty } from '@utils/utils';

import { TariffTableFilters } from './tariffTableFilters/TariffTableFilters';
import { useTariffTableFilters } from './tariffTableFilters/useTariffTableFilters';
import { useGetCurrentUser } from '@features/authentication';
import { useTariffTableColumns } from './tariffTableContainer/useTariffTableColumns';
import { saveUrl } from '@utils/returnUrl';

export const TariffTable: React.FC = () => {
  const { user } = useGetCurrentUser();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const saveAction = saveUrl();

  const { setHeaderTitle } = useHeaderTitle();
  const { filters, setTariffFilters } = useTariffTableFilters();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useFindAllTariffListQuery(filters);

  const { columns } = useTariffTableColumns();

  const onCreateButtonClick = () => {
    navigate(generatePath(Routes.TariffCard, { id: 'new' }));
    saveAction();
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setTariffFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            // size: 10,
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as ITariffFilters)
        : (newFilters as unknown as ITariffFilters),
    );
  }, []);

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setTariffFilters({
          ...(filters as ITariffFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const isCanCreateTariff = useHasUserPermission(ACCESS.TARIFF_VIEW);

  useEffect(() => {
    setHeaderTitle('Тариф');
  }, []);

  const onHandleWatch = (record: ITariff) => {
    navigate(
      generatePath(Routes.TariffCard, {
        id: String(record.id),
      }),
    );
    saveAction();
  };

  return (
    <>
      <TariffTableFilters
        isCanCreateTariff={isCanCreateTariff}
        onCreateButtonClick={onCreateButtonClick}
      />
      <Divider />
      <InformationalBlock>
        <CustomCard style={{ padding: '8px 16px' }} title="Таблица тарифа">
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={content}
            bordered
            columns={columns}
            size="small"
            totalCount={totalElements}
            actionProps={{
              onHandleWatch,
            }}
            customPagination={rest}
            onChange={onTableChange}
          />
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
