import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowCreateIrrigationSystemAction } from '@store/selectors';
import { setShowCreateIrrigationSystemActionModalAction } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export const useCreateIrrigationSystemActionSelect = (): ReturnType => {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowCreateIrrigationSystemAction);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowCreateIrrigationSystemActionModalAction(showModal));
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
};
