import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import { useAssignExecutorModal } from '@features/manageEvents/useAssignExecutorModal';
import { useAttachDocumentModal } from '@features/manageEvents/useAttachDocumentModal';
import { useCreateTemplateModal } from '@features/manageEvents/useCreateTemplateModal';
import {
  CustomCard,
  DefaultButton,
  PrimaryButton,
  PriorityGroup,
  TooltipedDatePicker,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { AddExecutorButton } from '@features/ui-kit/buttons/AddExecutorButton';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateTaskOperationalBlockMutation,
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListWithFiltersQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
  useFindDictionaryByTypeIdQuery,
  useFindOperationalBlockByIdQuery,
  useGetHydroFacilityAllQuery,
  useGetReferencesByParentIdAndTypeQuery,
  useGetReferencesByParentIdQuery,
  useSaveTaskOperationalBlockMutation,
} from '@store/gisproApi';
import { getOperationalBlockSelector } from '@store/selectors';
import { setOperationalBlockAction } from '@store/slices';
import dayjs from 'dayjs';
import clone from 'lodash/clone'; // TODO: change lodash to native implementation
import { isEmpty, toInteger, isInteger } from '@utils/utils';

import styles from './OperationalCard.module.scss';
import { useGetCurrentUser } from '@features/authentication';

const { Title } = Typography;

export const CreateOperationalCard: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [object, setObject] = useState<boolean>(false);
  const operationalBlockState = useSelector(getOperationalBlockSelector);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { setShow: setCreateTemplateModalShow } = useCreateTemplateModal();
  const { setShow: setAttachmentDocumnetShow, setDocType } =
    useAttachDocumentModal();
  const { setShow: setShowAssignExecutorModalAction } =
    useAssignExecutorModal();
  const { id } = useParams();

  const { data: operationalBlock, refetch } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const workCategoriesId = Form.useWatch('categoryId', form);
  const regionId = Form.useWatch('regionId', form);
  const districtId = Form.useWatch('districtId', form);
  const hydroFacilityId = Form.useWatch('hydroFacilityId', form);
  const systemId = Form.useWatch('systemId', form);
  const systemObjectId = Form.useWatch('systemObjectId', form);
  const groupId = Form.useWatch('groupId', form);
  const categoryId = Form.useWatch('objectCategoryId', form);

  const [createOperationalBlock] = useCreateTaskOperationalBlockMutation();
  const [saveOperationalBlock] = useSaveTaskOperationalBlockMutation();
  const { data: filteredWorkTypes = [], isLoading: workTypesLoading } =
    useGetReferencesByParentIdQuery(
      isInteger(workCategoriesId) ? workCategoriesId : skipToken,
    );

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    isInteger(regionId) ? regionId : skipToken,
  );

  const { data: workCategories = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Directions,
  });

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery(
    regionId || districtId
      ? {
          region: regionId,
          district: districtId,
        }
      : skipToken,
  );
  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId
        ? {
            hydroFacilityId,
          }
        : skipToken,
    );
  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    systemId ? { id: systemId } : skipToken,
  );
  const { data: group = [], isLoading: groupIsLoading } =
    useFindAllObjectGroupsQuery();
  const { data: categories = [], isLoading: categoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      groupId
        ? {
            objectGroupId: groupId,
          }
        : skipToken,
    );
  const { data: objectsList, isLoading: objectsListIsLoading } =
    useFindAllIrrigationSystemObjectsListWithFiltersQuery({
      groupId,
      categoryId,
      irrigationSystemId: systemId,
    });

  const isEditingDisabled = useMemo(
    () =>
      (!operationalBlockState?.title && !operationalBlock?.id) ||
      (operationalBlock && operationalBlock?.status !== TaskStatusEnum.draft),
    [operationalBlockState, operationalBlock],
  );
  const isSaveDisabled = useMemo(() => {
    return (
      (isEmpty(operationalBlockState.executor) &&
        isEmpty(operationalBlock?.executor)) ||
      (operationalBlock && operationalBlock?.status !== TaskStatusEnum.draft)
    );
  }, [operationalBlock, operationalBlockState]);

  const createTask = () => {
    if (operationalBlock?.id) {
      createOperationalBlock(operationalBlock.id)
        .unwrap()
        .then((data: any) => {
          refetch();
          notification.success({
            message: 'Вы успешно создали задачу',
          });
        })
        .catch(({ data }) => {
          notification.error({
            message: data?.message ?? 'Не удалось создать задачу',
          });
        });
    }
  };

  const submitObject = () => {
    setObject(true);
  };

  const onFinish = ({
    objectCategoryId,
    groupId: objectGroupId,
    hydroFacilityId, // нужен только для фильтраций
    ...values
  }: any) => {
    saveOperationalBlock({
      ...values,
      id: operationalBlock?.id || undefined,
      workTypeId: toInteger(values.workTypeId),
      districtId: toInteger(values.districtId),
      title: operationalBlockState?.title || operationalBlock?.title,
      executorId: toInteger(
        operationalBlockState?.executor?.id || operationalBlock?.executor?.id,
      ),
      attachments: operationalBlockState?.attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      isTemplate: false,
      systemId: parseInt(values.systemId, 10),
      inspectionDate: dayjs(values.inspectionDate).utc().format('YYYY-MM-DD'),
    })
      .unwrap()
      .then((data) => {
        navigate(generatePath(Routes.OperationalBlock, { id: data.id }));
        notification.success({
          message: 'Вы успешно сохранили задачу',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Не удалось сохранить задачу',
        });
      });
  };

  const templateModal = () => {
    const values = form.getFieldsValue();
    dispatch(
      setOperationalBlockAction({
        ...values,
        inspectionDate: dayjs(values.inspectionDate).utc().format('YYYY-MM-DD'),
      }),
    );
    setCreateTemplateModalShow(true);
  };

  useEffect(() => {
    const setFormFields = (data: any) => {
      const dataClone = clone(data);
      dataClone.executorId = toInteger(dataClone?.executor?.id);
      dataClone.regionId = toInteger(user?.owner?.region?.id);
      dataClone.districtId = toInteger(user?.owner?.district?.id);
      dataClone.categoryId = toInteger(dataClone?.category?.id);
      dataClone.hydroFacilityId = toInteger(
        dataClone?.system.hydroFacility?.id,
      );
      dataClone.workTypeId = dataClone?.workType?.id;
      dataClone.inspectionDate = dayjs(dataClone?.inspectionDate);
      dataClone.systemId = dataClone.system?.id;
      dataClone.systemObjectId = toInteger(dataClone.systemObject?.id);
      dataClone.objectCategoryId = dataClone.systemObject?.objectCategory?.id;
      dataClone.groupId = dataClone.systemObject?.objectGroup?.id;
      form.setFieldsValue(dataClone);
    };
    if (operationalBlock) {
      setFormFields(operationalBlock);
    } else if (operationalBlockState.isTemplate) {
      setFormFields(operationalBlockState);
    }
  }, [operationalBlock, operationalBlockState]);

  const handleChangeCategory = () => {
    form.resetFields(['workTypeId']);
  };

  const handleChangeRegion = () => {
    form.resetFields([
      'districtId',
      'systemId',
      'systemObjectId',
      'hydroFacilityId',
    ]);
  };

  const handleChangeDistrict = () => {
    form.resetFields(['systemId', 'systemObjectId', 'hydroFacilityId']);
  };
  const handleChangeHydroFacility = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const handleChangeChannel = () => {
    form.resetFields(['systemObjectId']);
  };

  const handleChangeSystem = () => {
    form.resetFields(['systemObjectId']);
  };

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <CustomCard>
      <Form<IOperationalBlock>
        layout="vertical"
        form={form}
        onFinish={onFinish}
        className={styles.form}
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Title level={4}>Создание задачи</Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton
                  onClick={templateModal}
                  disabled={isSaveDisabled}
                >
                  Создать шаблон
                </DefaultButton>
              </Col>
              <Col>
                <DefaultButton disabled={isSaveDisabled} htmlType="submit">
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  disabled={!operationalBlock?.id || isSaveDisabled}
                  onClick={createTask}
                >
                  Создать
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.cardBody}>
          <PriorityGroup
            rules={[{ required: true }]}
            disabled={isEditingDisabled}
          />
          <Form.Item required>
            <AddExecutorButton
              setShow={() => setShowAssignExecutorModalAction(true)}
              cardBlockType={
                operationalBlock?.executor || operationalBlockState.executor
              }
              disabled={isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="categoryId"
            label="Категория работ"
            required
          >
            <TooltipedSelect
              onChange={handleChangeCategory}
              options={workCategories}
              placeholder="Категория работ"
              size="large"
              disabled={isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="workTypeId"
            label="Тип работ"
            required
          >
            <TooltipedSelect
              disabled={!workCategoriesId || isEditingDisabled}
              loading={workTypesLoading}
              options={filteredWorkTypes}
              placeholder="Тип работ"
              size="large"
            />
          </Form.Item>
          <div
            className={`${styles.objectForm} ${object ? styles.masked : ''}`}
          >
            <Row justify="space-between">
              <Typography.Text className={styles.title}>Объект</Typography.Text>
              {systemObjectId && !object && (
                <PrimaryButton
                  disabled={isEditingDisabled}
                  onClick={submitObject}
                >
                  Подтвердить
                </PrimaryButton>
              )}
            </Row>
            <div className={styles.selectGroup}>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="regionId"
                label="Область"
                required
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  options={regions}
                  onChange={handleChangeRegion}
                  placeholder="Область"
                  size="large"
                  disabled={isDisabledRegion || isEditingDisabled}
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="districtId"
                label="Район"
                required
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  disabled={isDisabledDistrict || !regionId}
                  onChange={handleChangeDistrict}
                  options={districtByParentId}
                  placeholder="Район"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="hydroFacilityId"
                label="Гидроучасток"
                required
              >
                <TooltipedSelect
                  options={hydroFacilities}
                  onChange={handleChangeHydroFacility}
                  placeholder="Гидроучасток"
                  size="large"
                  disabled={!districtId || !regionId || isEditingDisabled}
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="systemId"
                label="Система"
                required
              >
                <TooltipedSelect
                  disabled={
                    !districtId ||
                    !regionId ||
                    !hydroFacilityId ||
                    isEditingDisabled
                  }
                  onChange={handleChangeSystem}
                  options={systems}
                  size="large"
                  placeholder="Система"
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                name="groupId"
                label="Группа"
              >
                <TooltipedSelect
                  loading={groupIsLoading}
                  placeholder="Группа"
                  size="large"
                  options={group}
                  disabled={isEditingDisabled || !systemId}
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                name="objectCategoryId"
                label="Категория объекта"
              >
                <TooltipedSelect
                  loading={categoriesIsLoading}
                  placeholder="Категория объекта"
                  size="large"
                  options={categories}
                  disabled={isEditingDisabled || !groupId}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="systemObjectId"
                label="Объект"
                normalize={(systemObjectIdd: string) =>
                  parseInt(systemObjectIdd, 10)
                }
                required
              >
                <TooltipedSelect
                  disabled={!(groupId && categoryId) || isEditingDisabled}
                  loading={objectsListIsLoading}
                  options={objectsList?.content?.map((item) => ({
                    ...item,
                    label: item.objectName,
                    value: item.objectId,
                  }))}
                  placeholder="Объект"
                  size="large"
                />
              </Form.Item>
              <Row />
            </div>
          </div>
          <Form.Item
            name="inspectionDate"
            label="Указать дату инспекции"
            required
            rules={[{ required: true }]}
          >
            <TooltipedDatePicker
              placeholder="Указать дату инспекции"
              format="YYYY-MM-DD"
              size="large"
              disabled={isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="taskDescription"
            label="Описание задачи"
          >
            <TooltipedTextarea
              className=""
              placeholder="Описание задачи"
              autoSize={{ minRows: 3, maxRows: 10 }}
              size="large"
              disabled={isEditingDisabled}
            />
          </Form.Item>
        </div>
      </Form>
    </CustomCard>
  );
};
