import Table, { ColumnGroupType, type ColumnType } from 'antd/es/table';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps } from '@models/table/interfaces/tableCustomActionProps';

type PropsType<RecordType> = {
  customColumns: TableColumn[];
  actionProps: TableCustomActionProps<RecordType>;
};

export function mapCutomTableColumns<RecordType extends JsonRecord>({
  customColumns,
  actionProps,
}: PropsType<RecordType>): ColumnType<RecordType>[] {
  const columns: ColumnType<RecordType>[] = customColumns.map(
    (customColumn) => {
      if (customColumn.xtype === CustomTableColumnType.Select) {
        return Table.SELECTION_COLUMN;
      }
      if (customColumn.xtype === CustomTableColumnType.Expand) {
        return Table.EXPAND_COLUMN;
      }

      const onCell = (record: RecordType) => {
        const isCanEditRow = actionProps.canEdit && actionProps.canEdit(record);
        const isDisplayNoneActionColumn =
          customColumn.xtype === CustomTableColumnType.ActionColumn &&
          !isCanEditRow &&
          actionProps.cannotEditDisplayNone;

        return {
          customColumn,
          record,
          title: customColumn.title as string,

          actionProps,
          colSpan: !isCanEditRow
            ? customColumn.cannotEditRowColSpan
            : undefined,
          style: isDisplayNoneActionColumn ? { display: 'none' } : undefined,
        };
      };

      const column: ColumnType<RecordType> & ColumnGroupType<RecordType> = {
        align: customColumn.align,
        dataIndex: customColumn.dataIndex,
        title: customColumn.title,
        sorter: customColumn.sorter,
        filters: customColumn.filters,
        className: customColumn.className,
        onFilter: customColumn.onFilter,

        filterDropdown: customColumn.filterDropdown,
        filterIcon: customColumn.filterIcon,
        filterSearch: customColumn.filterSearch,
        defaultFilteredValue: customColumn.defaultFilteredValue,
        filteredValue: customColumn.filteredValue,
        defaultSortOrder: customColumn.defaultSortOrder,
        render: customColumn.render,
        children: mapCutomTableColumns({
          customColumns: customColumn.children ? customColumn.children : [],
          actionProps,
        }),
        onCell,
        fixed: customColumn.fixed,
        width: customColumn.width,
        // rowSpan: customColumn.rowSpan,
        // colSpan: customColumn.colSpan,
      };

      return column;
    },
  );

  return columns;
}
