import { IAgroClimaticZoneFilters } from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutletDto } from '@store/api/annualContractApi';
import { IAgroClimaticZoneHydroModule } from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';

export type AgroClimaticZoneState = {
  selectedPlantingId: number | null;
  isCreateWaerRateModalShown: boolean;
  wateringRateData: any;
  agroClimaticZoneFilters: Partial<IAgroClimaticZoneFilters>;
  isCreateAgroClimaticZoneModalShown: boolean;
  isCreateHydroModuleDistrictModalShown: boolean;
  hydroModuleFilters: Partial<IAgroClimaticZoneHydroModule>;
};

const initialState: AgroClimaticZoneState = {
  selectedPlantingId: null,
  isCreateWaerRateModalShown: false,
  wateringRateData: null,
  isCreateAgroClimaticZoneModalShown: false,
  isCreateHydroModuleDistrictModalShown: false,
  agroClimaticZoneFilters: {
    page: 1,
    size: 10,
  },
  hydroModuleFilters: {},
};

const agroClimaticZoneSlice = createSlice({
  name: 'agroClimaticZone',
  initialState,
  reducers: {
    setSelectedPlantingIdAction: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      return {
        ...state,
        selectedPlantingId: action.payload,
      };
    },
    setCreateWaerRateModalShownAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isCreateWaerRateModalShown: action.payload,
      };
    },
    setWateringRateDataAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        wateringRateData: action.payload,
      };
    },
    setCreateAgroClimaticZoneModalShownAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isCreateAgroClimaticZoneModalShown: action.payload,
      };
    },
    setCreateHydroModuleDistrictModalShownAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isCreateHydroModuleDistrictModalShown: action.payload,
      };
    },
    changeAgroClimaticZoneFilters: (
      state,
      action: PayloadAction<IAgroClimaticZoneFilters>,
    ) => {
      return {
        ...state,
        agroClimaticZoneFilters: action.payload,
      };
    },
    changeHydroModuleFilters: (
      state,
      action: PayloadAction<IAgroClimaticZoneHydroModule>,
    ) => {
      return {
        ...state,
        hydroModuleFilters: action.payload,
      };
    },
  },
});

export const { actions: agroClimaticZoneActions } = agroClimaticZoneSlice;
export default agroClimaticZoneSlice.reducer;
