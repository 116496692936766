import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGtsPointSelector, getIsCreateGtsModalSelector } from '@store/selectors';
import { setCreateGtsModalAction, setEditGtsPointAction } from '@store/slices';

export const useCreateMonitoringPointsModal = () => {
  const dispatch = useDispatch();

  const isModalShown = useSelector(getIsCreateGtsModalSelector);
  const gtsPoint = useSelector(getGtsPointSelector);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(setCreateGtsModalAction(value));
  }, []);

  const setGtsPoint = useCallback((value: any) => {
    dispatch(setEditGtsPointAction(value));
  }, []);

  return { isModalShown, setShowModal, setGtsPoint, gtsPoint };
};
