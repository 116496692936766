import { Col, Row, Space, Typography } from 'antd';

import styles from './irrigationSystemModal.module.scss';
import { useArchiveConfirmModal } from './useArchiveConfirmModal';
import { CustomModal, DefaultButton, PrimaryButton } from '@features/ui-kit';

interface IIrrigationSystemConfirmModalProps {
  submit: () => void;
  isArchived?: boolean;
}

export const ArchiveConfirmModal: React.FC<
  IIrrigationSystemConfirmModalProps
> = ({ submit, isArchived = false }) => {
  const { isModalShown, setShowModal } = useArchiveConfirmModal();
  const onSubmit = () => {
    submit();
    setShowModal(false);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const confirmText = isArchived ? 'извлечь из архива' : 'добавить в архив';
  const confirmStatus = isArchived ? 'извлечен из архива' : 'добавлен в архив';

  return (
    <CustomModal
      width={400}
      open={isModalShown}
      centered
      title="Архивация"
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <Typography.Title level={4} className={styles.title}>
        Вы уверены, что хотите {confirmText}?
      </Typography.Title>
      <Typography.Text className={styles.text}>
        После подтверждения, он будет {confirmStatus}.
      </Typography.Text>
      <Row justify="center" className={styles.textWrapper}>
        <Col>
          <Space size={8}>
            <DefaultButton onClick={onClose}>Отменить</DefaultButton>
            <PrimaryButton onClick={onSubmit}>Подтвердить</PrimaryButton>
          </Space>
        </Col>
      </Row>
    </CustomModal>
  );
};
