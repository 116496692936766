import { months, monthsTranslate } from '@constants/month';
import { UserRoles } from '@enums/roles';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useTotalWaterSupplyPlanTableColumns = (
  userRole: UserRoles = UserRoles.ROLE_RUVH,
  canEdit: boolean = false,
) => {
  const isShowRole =
    userRole === UserRoles.ROLE_CA || userRole === UserRoles.ROLE_GUVH;

  const columns: TableColumn[] = [
    {
      title: 'Система',
      dataIndex: 'systemTypeName',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemTypeRowIndex) {
          if (record.systemTypeRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemTypeRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Наименование системы',
      dataIndex: 'systemName',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Показатели',
      dataIndex: 'aaa',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'code',
      align: 'left',
      width: 120,
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.indicatorType === 'cumulativeTotal' ? 'нар' : 'дек'}
          </div>
        );
      },
    },
    {
      title: 'Ед. изм.',
      dataIndex: 'picket',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'type',
      align: 'left',
      width: 90,
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.indicatorType === 'volumeOfExpenses' ? 'м3/с' : 'млн.м3'}
          </div>
        );
      },
    },
    ...months.map(
      (month): TableColumn => ({
        title: month,
        dataIndex: monthsTranslate[month],
        key: monthsTranslate[month],
        xtype: CustomTableColumnType.String,
        children: [
          {
            dataIndex: 'volume',
            title: 'I',
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-1`,
            ],
            align: 'center',
            width: 60,
            dynamicIndex: (record) => {
              if (record.indicatorType === 'cumulativeTotal') {
                return 'cumulativeTotal';
              } else if (record.indicatorType === 'volumeOfExpenses') {
                return 'volumeOfExpenses';
              }
              return 'volume';
            },
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: (record) => {
              if (!canEdit) {
                return false;
              }
              if (isShowRole) {
                return false;
              }
              if (record.systemTypeName.includes('Итого')) {
                return false;
              }
              return record.indicatorType === 'volume';
            },
          },
          {
            dataIndex: 'volume',
            title: 'II',
            align: 'center',
            width: 60,
            dynamicIndex: (record) => {
              if (record.indicatorType === 'cumulativeTotal') {
                return 'cumulativeTotal';
              } else if (record.indicatorType === 'volumeOfExpenses') {
                return 'volumeOfExpenses';
              }
              return 'volume';
            },
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-2`,
            ],
            xtype: CustomTableColumnType.DecimalEdit,
            editableDecimalCell: (record) => {
              if (!canEdit) {
                return false;
              }
              if (isShowRole) {
                return false;
              }
              if (record.systemTypeName.includes('Итого')) {
                return false;
              }
              return record.indicatorType === 'volume';
            },
          },
          {
            dataIndex: 'volume',
            title: 'III',
            align: 'center',
            width: 60,
            dynamicIndex: (record) => {
              if (record.indicatorType === 'cumulativeTotal') {
                return 'cumulativeTotal';
              } else if (record.indicatorType === 'volumeOfExpenses') {
                return 'volumeOfExpenses';
              }
              return 'volume';
            },
            xtype: CustomTableColumnType.DecimalEdit,
            decimalIndex: [
              'planItemDtos',
              `${monthsTranslate[month].toUpperCase()}-3`,
            ],
            editableDecimalCell: (record) => {
              if (!canEdit) {
                return false;
              }
              if (isShowRole) {
                return false;
              }
              if (record.systemTypeName.includes('Итого')) {
                return false;
              }
              return record.indicatorType === 'volume';
            },
          },
        ],
      }),
    ),
  ];

  if (isShowRole) {
    columns.unshift({
      title: 'Наименование района',
      dataIndex: 'districtName',
      xtype: CustomTableColumnType.String,
      key: 'districtName',
      align: 'left',
      width: 150,
      onCell: (record, rowIndex) => {
        if (record?.districtRowIndex) {
          if (record.districtRowIndex?.start === rowIndex) {
            return { rowSpan: record.districtRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    });
  }
  return { columns };
};
