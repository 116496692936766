import { FC } from 'react';

import { Layout } from 'antd';

import { CreateWaterObject } from '@features/waterObject';

export const CreateWaterObjectLayout: FC = () => {
  return (
    <Layout>
      <CreateWaterObject />
    </Layout>
  );
};
