import { TooltipedDatePicker, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import { Statuses } from '@enums/table/customTableStatus';
import { useFindAllIrrigationSystemListQuery } from '@store/gisproApi';
import { getCurrentTableOptionsSelector } from '@store/selectors';
import { saveTableOptions } from '@store/slices';
import { debounce } from '@utils/utils';

import styles from './OtherTasksTableSelectBlock.module.scss';

export const OtherTasksTableSelectBlock: React.FC = () => {
  const dispatch = useDispatch();

  const statusTypes = Object.entries(Statuses).map(([value, label]) => ({
    value,
    label,
  }));
  const { data: { content: systems = [] } = {} } =
    useFindAllIrrigationSystemListQuery({});
  const setTableOptions = (key: string, value: any) => {
    dispatch(saveTableOptions({
      ...tableOptions,
      [key]: value,
    }));
  };
  const changeTableOptions = debounce(setTableOptions, 500);
  const tableOptions = useSelector(getCurrentTableOptionsSelector);

  return (
    <Row
      align="middle"
      className={styles.selectsBlock}
      justify="space-between"
      gutter={10}
    >
      <Col span={6}>
        <TooltipedInput
          onChange={(v) => changeTableOptions('q', v)}
          placeholder="Наименование"
        />
      </Col>
      <Col span={6}>
        <TooltipedSelect
          allowClear
          onChange={(v) => changeTableOptions('systemId', v)}
          options={systems?.map((item) => ({
            ...item,
            label: item.name,
            value: item.systemId,
          }))}
          placeholder="Источник"
          className={styles.select}
        />
      </Col>
      <Col span={6}>
        <Row gutter={5} wrap={false} align="middle">
          <Col span={4}>
            <h2 className={styles.sumLabel}>Сроки</h2>
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventStartDate', v)}
            />
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventEndDate', v)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <TooltipedSelect
          defaultValue={tableOptions.status}
          onChange={(v) => changeTableOptions('status', v)}
          popupMatchSelectWidth={false}
          options={statusTypes.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
          placeholder="Статус"
          allowClear
          className={styles.select}
        />
      </Col>
    </Row>
  );
};
