import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const UploadIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" clipPath="url(#clip0_3892_110408)">
        <path
          d="M14.0625 8.57562V12.2506C14.0625 12.5987 13.9242 12.9326 13.6781 13.1787C13.4319 13.4248 13.0981 13.5631 12.75 13.5631H2.25C1.9019 13.5631 1.56806 13.4248 1.32192 13.1787C1.07578 12.9326 0.9375 12.5987 0.9375 12.2506V8.57562C0.9375 8.36676 1.02047 8.16646 1.16815 8.01877C1.31584 7.87109 1.51614 7.78812 1.725 7.78812C1.93386 7.78812 2.13416 7.87109 2.28185 8.01877C2.42953 8.16646 2.5125 8.36676 2.5125 8.57562V11.9881H12.4875V8.57562C12.4875 8.36676 12.5705 8.16646 12.7182 8.01877C12.8658 7.87109 13.0661 7.78812 13.275 7.78812C13.4839 7.78812 13.6842 7.87109 13.8318 8.01877C13.9795 8.16646 14.0625 8.36676 14.0625 8.57562ZM5.43216 4.40778L6.7125 3.12875V8.57562C6.7125 8.78448 6.79547 8.98478 6.94315 9.13247C7.09084 9.28015 7.29114 9.36312 7.5 9.36312C7.70886 9.36312 7.90916 9.28015 8.05685 9.13247C8.20453 8.98478 8.2875 8.78448 8.2875 8.57562V3.12875L9.56784 4.40975C9.71578 4.55769 9.91644 4.6408 10.1257 4.6408C10.3349 4.6408 10.5355 4.55769 10.6835 4.40975C10.8314 4.2618 10.9145 4.06115 10.9145 3.85193C10.9145 3.64271 10.8314 3.44206 10.6835 3.29412L8.05847 0.669121C7.98531 0.595705 7.89837 0.537453 7.80265 0.497707C7.70693 0.45796 7.6043 0.4375 7.50066 0.4375C7.39701 0.4375 7.29438 0.45796 7.19866 0.497707C7.10294 0.537453 7.01601 0.595705 6.94284 0.669121L4.31784 3.29412C4.24459 3.36737 4.18648 3.45434 4.14684 3.55005C4.10719 3.64576 4.08679 3.74834 4.08679 3.85193C4.08679 4.06115 4.1699 4.2618 4.31784 4.40975C4.46578 4.55769 4.66644 4.6408 4.87566 4.6408C5.08488 4.6408 5.28553 4.55769 5.43347 4.40975L5.43216 4.40778Z"
          fill="current"
        />
      </g>
    </svg>
  );
};
