import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import { Task } from 'gantt-task-react/dist/types/public-types';

import styles from './TooltipContent.module.scss';

type TooltipContentPropTypes = {
  task: Task;
  fontSize: string;
  fontFamily: string;
};

export const TooltipContent: React.FC<TooltipContentPropTypes> = ({
  fontFamily,
  task,
  fontSize,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.task}>
      <div>
        <Typography.Text strong>Наименование: </Typography.Text>
        {task.name}
      </div>
      <div>
        <Typography.Text strong>Статус: </Typography.Text>
        {t(`statusList.${(task as any).status as string}`)}
      </div>
    </div>
  );
};
