import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEconomiesSelector,
  getSelectedEconomyIndexSelector,
} from '@store/selectors';
import {
  EconomyType,
  removeEconomyAction,
  saveOrEditEconomyAction,
  setSelectedEconomyIndexAction,
} from '@store/slices/waterRegistry';
import { MainWaterUserTypes, WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

type ReturnType = {
  economies: {
    [WaterUserTypes.association]: EconomyType[],
    [WaterUserTypes.physical]: EconomyType[],
    [WaterUserTypes.legal]: EconomyType[],
  };
  saveOrEditEconomy: (values: EconomyType, index: number | null, waterUserType: MainWaterUserTypes) => void;
  removeEconomy: (index: number) => void;
  setSelectedEconomyIndex: (index: number, WaterUserType: MainWaterUserTypes) => void;
  selectedEconomyIndex: number | null;
};

export function useAddEconomy(): ReturnType {
  const dispatch = useDispatch();
  const economies = useSelector(getEconomiesSelector);
  const selectedEconomyIndex = useSelector(getSelectedEconomyIndexSelector);

  const saveOrEditEconomy = useCallback(
    (values: EconomyType, index: number | null, waterUserType: MainWaterUserTypes) => {
      dispatch(
        saveOrEditEconomyAction({
          values,
          index,
          waterUserType,
        }),
      );
      dispatch(setSelectedEconomyIndexAction(null));
    },
    [],
  );

  const setSelectedEconomyIndex = useCallback(
    (index: number, waterUserType: MainWaterUserTypes) => {
      dispatch(
        setSelectedEconomyIndexAction(
          economies[waterUserType].findIndex((item) => item.economieId === index),
        ),
      );
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    [economies],
  );

  const removeEconomy = useCallback((index: number) => {
    dispatch(removeEconomyAction(index));
  }, []);

  return {
    economies,
    saveOrEditEconomy,
    removeEconomy,
    setSelectedEconomyIndex,
    selectedEconomyIndex
  };
}
