export const Tags = {
  MainConduitConstructor: 'MainConduitConstructor',
  GtsObjectsConstructor: 'GtsObjectsConstructor',
  GvsObjectsConstructor: 'GvsObjectsConstructor',
  WaterQuentity: 'WaterQuentity',
  WaterObject: 'WaterObject',
  WaterQuality: 'WaterQuality',
  WaterSection: 'WaterSection',
  WaterSupply: 'WaterSupply',
  WaterUseSupply: 'WaterUseSupply',
  Watering: 'Watering',
  Decade: 'Decade',
  OperationalEventList: 'OperationalEventList',
  ConstructionEventList: 'ConstructionEventList',
  TaskEventList: 'TaskEventList',
  RegularEventList: 'RegularEventList',
  IrrigationSystem: 'IrrigationSystem',
  Bank: 'Bank',
  WaterUserPhysical: 'WaterUserPhysical',
  WaterUserLegal: 'WaterUserLegal',
  WaterUserAll: 'WaterUserAll',
  WaterUserAssociation: 'WaterUserAssociation',
  Sources: 'Sources',
  ActInvoicesForPayments: 'ActInvoicesForPayments',
  AgroClimaticZone: 'AgroClimaticZone',
  AnnualApplication: 'AnnualApplication',
  OperationalBlock: 'OperationalBlock',
  BuildingBlock: 'BuildingBlock',
  ConstructionBlock: 'ConstructionBlock',
  Tariff: 'Tariff',
  Reservoir: 'Reservoir',
  HydroModule: 'HydroModule',
  HydarulicSection: 'HydarulicSection',

  WaterUsePlan: 'WaterUsePlan',
  HydrometricalWorkPlan: 'HydrometricalWorkPlan',

  WaterAccountingBalanceSheet: 'WaterAccountingBalanceSheet',
  WaterAccountingOutletsFact: 'WaterAccountingOutletsFact',
  WaterAccountingHydropostFact: 'WaterAccountingHydropostFact',

  OperationalWateringPlan: 'OperationalWateringPlan',

  CropSowing: 'CropSowing',
  ContractualPlan: 'ContractualPlan',

  FacilitiesByOrgId: 'FacilitiesByOrgId',

  LaboratoryTests: 'LaboratoryTests',
  CommonReferencesList: 'CommonReferencesList',
  twoTpWaterUseData: 'twoTpWaterUseData',
  update2tpDrainage: 'update2tpDrainage',

  NewsList: 'NewsList',
  AccessList: 'AccessList',
  PermissionsList: 'PermissionsList',

  OwnerInfo: 'OwnerInfo',
  OwnerInfoById: 'OwnerInfoById',

  Materials: 'Materials',
  MaterialsCategory: 'MaterialsCategory',
  Instruction: 'Instruction',
  GeoLayers: 'GeoLayers',
  BuildingTemplate: 'BuildingTemplate',
  ExtraTaskTemplate: 'ExtraTaskTemplate',

  OperationalBlockList: 'OperationalBlockList',
  BuildingBlockList: 'BuildingBlockList',
  ExtraTaskList: 'ExtraTaskList',
};
