import React from 'react';

import { Card, CardProps } from 'antd';

import styles from './CustomCard.module.scss';

type CustomCardPropsType = CardProps & {
  children: React.ReactNode;
  readonly?: boolean;
  props?: CardProps;
};

/**
 * deprecated
 * */
export const CustomCard: React.FC<CustomCardPropsType> = ({
  children,
  readonly,
  ...props
}) => {
  return (
    <Card {...props} className={styles.card}>
      {readonly && <div className={styles.overlay} />}
      {children}
    </Card>
  );
};
