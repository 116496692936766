import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ITariffFilters } from '@models/tariff/interfaces/tariff';
import { getTariffMonitoringTableFiltersSelector } from '@store/selectors';
import { changeTariffMonitoringFilters } from '@store/slices/tariff/tariffSlice';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

export const useTariffMonitoringTableFilters = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getTariffMonitoringTableFiltersSelector);

  const setTariffMonitoringFilters = useCallback(
    (newFilters: ITariffFilters & { section?: string }) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);
      const { section, page, size, ...filterValues } = newFilters;
      const values = pickBy(filterValues, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        changeTariffMonitoringFilters({ ...values, isActive: true } as any),
      );
    },
    [filters],
  );

  return { filters, setTariffMonitoringFilters };
};
