import { SelectProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import {
  TableColumnSelect,
  TableRendererProps,
} from '@models/table/interfaces/tableColumn';

export function renderSelect<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { options = [] } = customColumn as TableColumnSelect & SelectProps;
  const value = record[customColumn.dataIndex];
  const selectedValue = options.find((option) => option.value === value);

  if (selectedValue) {
    return selectedValue.label;
  }

  return null;
}
