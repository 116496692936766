import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizationIdSelector } from '@store/selectors';
import { setOrganizationId } from '@store/slices';

type ReturnType = {
  organizationId: string;
  setOrgId: (userId: string) => void;
};
export function useOrganizationId(): ReturnType {
  const dispatch = useDispatch();
  const organizationId = useSelector(getOrganizationIdSelector);

  const setOrgId = useCallback((userId: string) => {
    dispatch(setOrganizationId(userId));
  }, []);

  return {
    organizationId,
    setOrgId,
  };
}
