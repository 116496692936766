import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, notification } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { HomeOutlined } from '@ant-design/icons';
import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { IrrigationSystemTable } from '@features/irrigationSystem';
import { Divider, Tabs } from '@features/ui-kit';
import { WaterObjectTable } from '@features/waterObject/waterObjectTable';
import { WaterAssociationsTable } from '@features/waterUser/waterUserTable/WaterAssociationsTable';
import { WaterUserTable } from '@features/waterUser/waterUserTable/WaterUserTable';
import { ArchivariusTableQueryTypes } from '@models/archivarius/enums/archivariusEnum';
import { t } from 'i18next';

import styles from './ArchivariusTableLayout.module.scss';
import {
  BuildingTable,
  ManageEventsOperationalTable,
  OtherTasksTable,
} from '@features/manageEvents';
import { ReservoirListTable } from '@features/monitoring/reservoirList/reservoirListTable';
import { WaterUserConfirmModal } from '@features/waterUser';
import {
  useToUnArchiveBuildingBlockMutation,
  useToUnArchiveExtraTaskMutation,
  useToUnArchiveIrrigationSystemMutation,
  useToUnArchiveIrrigationSystemObjectMutation,
  useToUnArchiveOperationalBlockMutation,
  useToUnArchiveWaterUserMutation,
} from '@store/gisproApi';
import { IrrigationWaterReservoir } from '@features/layout/irrigationSystem/waterReservoir';
import { getConfirmArchiveId } from '@store/selectors';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { ArchiveConfirmModal } from '@features/archiveConfirmModal';

const options = [
  {
    key: ArchivariusTableQueryTypes.irrigationSystem,
    value: ArchivariusTableQueryTypes.irrigationSystem,
    label: 'Реестр ирригационной системы',
  },
  {
    key: ArchivariusTableQueryTypes.waterObjects,
    value: ArchivariusTableQueryTypes.waterObjects,
    label: 'Гидротехнические сооружения и прочее',
  },
  {
    key: ArchivariusTableQueryTypes.reservoir,
    value: ArchivariusTableQueryTypes.reservoir,
    label: 'Водохранилища',
  },
  {
    key: ArchivariusTableQueryTypes.waterUser,
    value: ArchivariusTableQueryTypes.waterUser,
    label: 'Реестр водопользователей',
  },
  {
    key: ArchivariusTableQueryTypes.associations,
    value: ArchivariusTableQueryTypes.associations,
    label: 'Ассоциаций',
  },
  {
    key: ArchivariusTableQueryTypes.manageEventsOperational,
    value: ArchivariusTableQueryTypes.manageEventsOperational,
    label: 'Эксплуатационный блок',
  },
  {
    key: ArchivariusTableQueryTypes.building,
    value: ArchivariusTableQueryTypes.building,
    label: 'Строительные работы',
  },
  {
    key: ArchivariusTableQueryTypes.otherTasks,
    value: ArchivariusTableQueryTypes.otherTasks,
    label: 'Прочие задачи',
  },
];

export const ArchivariusTableLayout: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();
  const confirmArchiveId = useSelector(getConfirmArchiveId);
  const queryKey: keyof typeof ArchivariusTableQueryTypes = useMemo(() => {
    const paramValue = params.get(
      SectionQueryKey,
    ) as keyof typeof ArchivariusTableQueryTypes;
    return paramValue || ArchivariusTableQueryTypes.irrigationSystem;
  }, [params]);
  const [toUnArchive] = useToUnArchiveIrrigationSystemMutation();
  const [toUnArchiveSystemObject] =
    useToUnArchiveIrrigationSystemObjectMutation();
  const [toUnArchiveWaterUser] = useToUnArchiveWaterUserMutation();

  const [toUnArchiveOperationalBlock] =
    useToUnArchiveOperationalBlockMutation();
  const [toUnArchiveBuildingBlock] = useToUnArchiveBuildingBlockMutation();
  const [toUnArchiveExtraTask] = useToUnArchiveExtraTaskMutation();

  const unArchiveAndNotify = (unArchiveFunc: any) => {
    unArchiveFunc(confirmArchiveId ? { id: confirmArchiveId } : skipToken)
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно извлечен из архива',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Не удалось извлечь из архива',
        });
      });
  };

  const submitFunctions: Record<
    keyof typeof ArchivariusTableQueryTypes,
    () => void
  > = {
    [ArchivariusTableQueryTypes.irrigationSystem]: () =>
      unArchiveAndNotify(toUnArchive),
    [ArchivariusTableQueryTypes.waterObjects]: () =>
      unArchiveAndNotify(toUnArchiveSystemObject),
    [ArchivariusTableQueryTypes.reservoir]: () =>
      unArchiveAndNotify(toUnArchiveSystemObject),
    [ArchivariusTableQueryTypes.waterUser]: () =>
      unArchiveAndNotify(toUnArchiveWaterUser),
    [ArchivariusTableQueryTypes.associations]: () =>
      unArchiveAndNotify(toUnArchiveWaterUser),
    [ArchivariusTableQueryTypes.manageEventsOperational]: () =>
      unArchiveAndNotify(toUnArchiveOperationalBlock),
    [ArchivariusTableQueryTypes.building]: () =>
      unArchiveAndNotify(toUnArchiveBuildingBlock),
    [ArchivariusTableQueryTypes.otherTasks]: () =>
      unArchiveAndNotify(toUnArchiveExtraTask),
  };
  let Component;
  switch (queryKey) {
    case ArchivariusTableQueryTypes.irrigationSystem:
      Component = IrrigationSystemTable;
      break;
    case ArchivariusTableQueryTypes.waterUser:
      Component = WaterUserTable;
      break;
    case ArchivariusTableQueryTypes.waterObjects:
      Component = WaterObjectTable;
      break;
    case ArchivariusTableQueryTypes.associations:
      Component = WaterAssociationsTable;
      break;
    case ArchivariusTableQueryTypes.manageEventsOperational:
      Component = ManageEventsOperationalTable;
      break;
    case ArchivariusTableQueryTypes.building:
      Component = BuildingTable;
      break;
    case ArchivariusTableQueryTypes.otherTasks:
      Component = OtherTasksTable;
      break;
    case ArchivariusTableQueryTypes.reservoir:
      Component = IrrigationWaterReservoir;
      break;
    default:
      Component = IrrigationSystemTable;
      break;
  }

  const onTableTypeChange = (value: SegmentedValue) => {
    const searchParams = new URLSearchParams({
      section: value as string,
      page: '1',
      size: '10',
      isArchived: 'true',
    });
    setSearchParams(searchParams);
  };

  useEffect(() => {
    onTableTypeChange(queryKey);
    setHeaderTitle(t('headers.archivarius'));
  }, []);

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.archivarius'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        defaultActiveKey={queryKey}
        activeKey={queryKey}
        items={options}
        onChange={onTableTypeChange}
      />
      <Component isArchived />
      <ArchiveConfirmModal isArchived submit={submitFunctions[queryKey]} />
    </Layout>
  );
};
