import { Divider, Tabs } from '@features/ui-kit';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { SectionQueryKey } from '@constants/queryKeys';
import { EnteringDataTable } from '@features/waterQuality/tables/enteringDataTable';
import { WaterQualityTableFilters } from '@models/waterQuality/interfaces/waterQuality';

import { DictionaryZoneTable } from '../dictionaryZoneTable';
import { MonitoringPointsTable } from '../monitoringPointsTable';
import { useGetCurrentUser } from '@features/authentication';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';
import { HomeOutlined } from '@ant-design/icons';

const options = [
  {
    key: WaterQualityTableFilters.DictionaryZone,
    label: 'Справочник зон',
  },
  {
    key: WaterQualityTableFilters.EntryData,
    label: 'Ввод данных',
  },
  {
    key: WaterQualityTableFilters.MonitoringPoints,
    label: 'Точки мониторинга',
  },
];

export const WaterQualityTable: React.FC = () => {
  const [params, setUrlParams] = useSearchParams();
  const { user } = useGetCurrentUser();

  const waterQualityTableType =
    params.get(SectionQueryKey) || WaterQualityTableFilters.DictionaryZone;

  let Component;
  switch (waterQualityTableType) {
    case WaterQualityTableFilters.DictionaryZone:
      Component = DictionaryZoneTable;
      break;
    case WaterQualityTableFilters.EntryData:
      Component = EnteringDataTable;
      break;
    case WaterQualityTableFilters.MonitoringPoints:
      Component = MonitoringPointsTable;
      break;
    default:
      Component = DictionaryZoneTable;
      break;
  }

  const onWaterQualityTypeChange = useCallback((value: SegmentedValue) => {
    setUrlParams({
      [SectionQueryKey]: value as string,
      regionId: user?.owner?.region?.id.toString() || '',
      districtId: user?.owner?.district?.id.toString() || '',
    });

    // setWaterRegistryFilters({
    //   type: value,
    //   page: 1,
    //   size: 10,
    // } as unknown as IWaterRegistryFilters);
  }, []);

  const dynamicOptions = (arr: any[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WATER_QUALITY_HANDBOOK_VIEW,
      ACCESS.WATER_QUALITY_ENTRYDATA_VIEW,
      ACCESS.WATER_QUALITY_MONITORING_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: 'Качественные показатели воды',
              },
            ]}
          />
          <Divider style={{ margin: '6px 0 8px' }} />
        </Col>

        <Col span={24}>
          <Tabs
            items={dynamicOptions(options)}
            activeKey={waterQualityTableType}
            onChange={onWaterQualityTypeChange}
            // queryParams={{
            //   queryKey: SectionQueryKey,
            //   queryType: WaterQualityTableFilters,
            // }}
          />
        </Col>
        <Col span={24}>
          <Component />
        </Col>
      </Row>
    </>
  );
};
