import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import {
  CustomCard,
  DangerButton,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  DeleteHydroFacilitySystemApiArg,
  OutletBindDtoList,
  useDeleteHydroFacilitySystemMutation,
  useGetBindedOutletsQuery,
  useGetSystemsByDistrictQuery,
} from '@store/gisproApi';
import { getHydroFacilityIdSelector } from '@store/selectors/hydraulicSection';

import styles from './AddedOutletTable.module.scss';
import { useAddedOutletTableColumns } from './useAddedOutletTableColumns';

export const AddedOutletTable: React.FC = () => {
  const { columns } = useAddedOutletTableColumns();
  const hydroFacilityId = useSelector(getHydroFacilityIdSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [remove] = useDeleteHydroFacilitySystemMutation();

  const { data = [] } = useGetBindedOutletsQuery(
    hydroFacilityId
      ? {
          hydroFacilityId,
        }
      : skipToken,
  );
  const onChange = (selectedKey: React.Key[]) => {
    setSelectedRowKeys(selectedKey);
  };

  const onDeleteButtonClick = () => {
    if (hydroFacilityId) {
      const res = selectedRowKeys.reduce(
        (acc: DeleteHydroFacilitySystemApiArg, curr) => {
          const system = data.find((item) => item.id === curr);
          if (system) {
            acc.outletBindDtoList.push(system as OutletBindDtoList);
          }
          return acc;
        },
        {
          hydroFacilityId,
          outletBindDtoList: [],
        },
      );
      remove(res as DeleteHydroFacilitySystemApiArg);
      setSelectedRowKeys([]);
    }
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between" align="middle">
          <Col className={styles.title}>Таблица водовыпусков гидроучастка</Col>
          <Col>
            <DangerButton onClick={onDeleteButtonClick}>Удалить</DangerButton>
          </Col>
        </Row>
      }
    >
      <TableCustom
        className={styles.table}
        bordered
        size="small"
        rowSelection={{
          type: 'checkbox',
          columnTitle: 'Удалить',
          onChange,
        }}
        rowKey={(item) => item.id}
        columns={columns}
        dataSource={data}
      />
    </CustomCard>
  );
};
