import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type irrigationSystemState = {
  irrigationSystemCardObjectFilters: any;
  irrigationSystemCardWaterUserFilters: {
    code?: null | string;
    name?: null | string;
    userType?: null | number;
  };
};

const initialState: irrigationSystemState = {
  irrigationSystemCardObjectFilters: {},
  irrigationSystemCardWaterUserFilters: {
    // code: null,
    // name: null,
    // userType: null,
  },
};

const irrigationSystemSlice = createSlice({
  name: 'irrigationSystem',
  initialState,
  reducers: {
    changeIrrigationSystemCardObjectFilters: (
      state,
      action: PayloadAction<any>,
    ) => {
      return {
        ...state,
        irrigationSystemCardObjectFilters: action.payload,
      };
    },
    changeIrrigationSystemCardWaterUserFilters: (
      state,
      action: PayloadAction<any>,
    ) => {
      return {
        ...state,
        irrigationSystemCardWaterUserFilters: action.payload,
      };
    },
  },
});

export const {
  changeIrrigationSystemCardObjectFilters,
  changeIrrigationSystemCardWaterUserFilters,
} = irrigationSystemSlice.actions;

export default irrigationSystemSlice.reducer;
