export enum SystemModules {
  MAIN = 'MAIN',
  IRRIGATION_SYSTEM = 'IRRIGATION_SYSTEM',
  IRRIGATION_SYSTEM_CARD = 'IRRIGATION_SYSTEM_CARD',
  WATER_USER = 'WATER_USER',
  WATER_USER_ASSOCIATION = 'WATER_USER_ASSOCIATION',
  WATER_USER_CARD = 'WATER_USER_CARD',
  WATER_USER_ASSOCIATION_CARD = 'WATER_USER_ASSOCIATION_CARD',
  DECADE_APPLICATION = 'DECADE_APPLICATION',
  WATER_USE = 'WATER_USE',
  WATER_USE_IRRIGATION = 'WATER_USE_IRRIGATION',
  WATER_USE_WATER_SUPPLY = 'WATER_USE_WATER_SUPPLY',
  WATER_ACCOUNTING = 'WATER_ACCOUNTING',
  WORK_MANAGEMENT = 'WORK_MANAGEMENT',
  ACT_PAYMENT = 'ACT_PAYMENT',
  TARIFF = 'TARIFF',
  GANTT_DIAGRAM = 'GANTT_DIAGRAM',
  ANNUAL_REQUEST = 'ANNUAL_REQUEST',
  ANNUAL_PLANNING = 'ANNUAL_PLANNING',
  REPORT_2TP = 'REPORT_2TP',
  MONITORING_RUVH = 'MONITORING_RUVH',
  QUALITY_INDICATORS = 'QUALITY_INDICATORS',
  RESERVOIRS = 'RESERVOIRS',
  SPPR = 'SPPR',
  AGROCLIMATIC_ZONE = 'AGROCLIMATIC_ZONE',
  ARCHIVARIUS = 'ARCHIVARIUS',
  HYDRO_FACILITY = 'HYDRO_FACILITY',
  REFERENCES = 'REFERENCES',
  OWNERS = 'OWNERS',
  ROLES = 'ROLES',
  GEO_PORTAL = 'GEO_PORTAL',
  NEWS = 'NEWS',
  INSTRUCTIONS = 'INSTRUCTIONS',
  REFERENCE_MATERIALS = 'REFERENCE_MATERIALS',
  REPORTS = 'REPORTS',
  REPORTS_WATER_SUPPLY_CONTRACT = 'REPORTS_WATER_SUPPLY_CONTRACT',
  REPORTS_CONTRACTS = 'REPORTS_CONTRACTS',
  REPORTS_WATER_SUPPLY = 'REPORTS_WATER_SUPPLY',
}
// MAIN view,news,instructions,statistics,graphs
// IRRIGATION_SYSTEM view,create,constructor,createWaterPipe,archive
// IRRIGATION_SYSTEM_CARD view,generalData,systemBlock,objects,waterUsers,operationalBlock,documents,generalDataEdit
// WATER_USER view,create
// WATER_USER_CARD view,edit,createLand,editLand,deleteLand
// WATER_USER_ASSOCIATION view,create
// WATER_USER_ASSOCIATION_CARD view,edit
// DECADE_REQUEST view,edit
// WATER_USE view
// WATER_USE_IRRIGATION view,physicalView,legalView,associationView,physicalEdit,legalEdit,associationEdit
// WATER_USE_WATER_SUPPLY view
// WATER_ACCOUNTING view,edit
// WORK_MANAGEMENT view
// ACT_PAYMENT view
// TARIFF view
// GANTT_DIAGRAM view
// ANNUAL_REQUEST view
// ANNUAL_PLANNING view
// MONITORING_RUVH view
// QUALITY_INDICATORS view
// RESERVOIRS view
// SPPR view
// AGROCLIMATIC_ZONE view
// ARCHIVARIUS view
// HYDRO_FACILITY view
// REFERENCES view
// OWNERS view
// ROLES view
// GEO_PORTAL view
// NEWS view
// INSTRUCTIONS view
// REFERENCE_MATERIALS view
// REPORTS view
// REPORT_2TP view
// REPORTS_WATER_SUPPLY_CONTRACT view
// REPORTS_CONTRACTS view
// REPORTS_WATER_SUPPLY view

export enum ACCESS {
  NEVER = 'NEVER',
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  IS_UNAUTHENTICATED = 'IS_UNAUTHENTICATED',
  // главная страница - [просмотр]
  MAIN_VIEW = 'MAIN_VIEW',
  // главная страница - [новости]
  MAIN_NEWS = 'MAIN_NEWS',
  // главная страница - [инструкции]
  MAIN_INSTRUCTIONS = 'MAIN_INSTRUCTIONS',
  // главная страница - [статистика]
  MAIN_STATISTICS = 'MAIN_STATISTICS',
  // главная страница - [графики]
  MAIN_GRAPHS = 'MAIN_GRAPHS',

  // ирригационная система - [просмотр]
  IRRIGATION_SYSTEM_VIEW = 'IRRIGATION_SYSTEM_VIEW',
  // ирригационная система - [создать системы]
  IRRIGATION_SYSTEM_CREATE = 'IRRIGATION_SYSTEM_CREATE',
  // ирригационная система - [конструктор]
  IRRIGATION_SYSTEM_CONSTRUCTOR_VIEW = 'IRRIGATION_SYSTEM_CONSTRUCTOR_VIEW',
  // ирригационная система - [создать водовод]
  IRRIGATION_SYSTEM_WATER_PIPE_CREATE = 'IRRIGATION_SYSTEM_WATER_PIPE_CREATE',
  // ирригационная система - [архивация]
  IRRIGATION_SYSTEM_ARCHIVE = 'IRRIGATION_SYSTEM_ARCHIVE',
  // ирригационная система - карточка - [просмотр]
  IRRIGATION_SYSTEM_CARD_VIEW = 'IRRIGATION_SYSTEM_CARD_VIEW',
  // ирригационная система - карточка - [общие данные]
  IRRIGATION_SYSTEM_CARD_GENERAL_DATA = 'IRRIGATION_SYSTEM_CARD_GENERAL_DATA',
  // ирригационная система - карточка - [системный блок]
  IRRIGATION_SYSTEM_CARD_SYSTEM_BLOCK = 'IRRIGATION_SYSTEM_CARD_SYSTEM_BLOCK',
  // ирригационная система - карточка - [объекты и структура]
  IRRIGATION_SYSTEM_CARD_OBJECTS = 'IRRIGATION_SYSTEM_CARD_OBJECTS',
  // ирригационная система - карточка - [водопользователи]
  IRRIGATION_SYSTEM_CARD_WATER_USERS = 'IRRIGATION_SYSTEM_CARD_WATER_USERS',
  // ирригационная система - карточка - [эксплуатационный блок]
  IRRIGATION_SYSTEM_CARD_OPERATIONAL_BLOCK = 'IRRIGATION_SYSTEM_CARD_OPERATIONAL_BLOCK',
  // ирригационная система - карточка - [документы]
  IRRIGATION_SYSTEM_CARD_DOCUMENTS = 'IRRIGATION_SYSTEM_CARD_DOCUMENTS',
  // ирригационная система - карточка - общие данные - [редактирование]
  IRRIGATION_SYSTEM_CARD_GENERAL_DATA_EDIT = 'IRRIGATION_SYSTEM_CARD_GENERAL_DATA_EDIT',
  // ирригационная система - ГИС
  IRRIGATION_SYSTEM_GIS_VIEW = 'IRRIGATION_SYSTEM_GIS_VIEW',

  // водопользователи - физ и юр лица - [просмотр]
  WATER_USER_VIEW = 'WATER_USER_VIEW',
  // водопользователи - таблица - карточка - [просмотр]
  WATER_USER_VIEW_TABLE_CARD = 'WATER_USER_VIEW_TABLE_CARD',
  // водопользователи - физ и юр лица - [создать]
  WATER_USER_CREATE = 'WATER_USER_CREATE',
  // водопользователи - физ и юр лица - карточка - [просмотр]
  WATER_USER_CARD_VIEW = 'WATER_USER_CARD_VIEW',
  // водопользователи - физ и юр лица - карточка - [редактировать]
  WATER_USER_CARD_EDIT = 'WATER_USER_CARD_EDIT',
  // водопользователи - физ и юр лица - карточка - участки [создать]
  WATER_USER_CARD_CREATE_LAND = 'WATER_USER_CARD_CREATE_LAND',
  // водопользователи - физ и юр лица - карточка - участки [редактировать]
  WATER_USER_CARD_EDIT_LAND = 'WATER_USER_CARD_EDIT_LAND',
  // водопользователи - физ и юр лица - карточка - участки [удалить]
  WATER_USER_CARD_DELETE_LAND = 'WATER_USER_CARD_DELETE_LAND',
  // водопользователи - ассоциации - [просмотр]
  WATER_USER_ASSOCIATION_VIEW = 'WATER_USER_ASSOCIATION_VIEW',
  // водопользователи - ассоциации - [создание]
  WATER_USER_ASSOCIATION_CREATE = 'WATER_USER_ASSOCIATION_CREATE',
  // водопользователи - ассоциации - карточка - [просмотр]
  WATER_USER_ASSOCIATION_CARD_VIEW = 'WATER_USER_ASSOCIATION_CARD_VIEW',
  // водопользователи - ассоциации - карточка - [редактировать]
  WATER_USER_ASSOCIATION_CARD_EDIT = 'WATER_USER_ASSOCIATION_CARD_EDIT',
  // водопользователи - ассоциации - карточка - [добавить водовыпуск]

  // водопользователи - таблица - годовая заявка
  WATER_USER_ANNUAL_VIEW = 'WATER_USER_ANNUAL_VIEW',
  // водопользователи - таблица - ГИС
  WATER_USER_GIS_VIEW = 'WATER_USER_GIS_VIEW',
  // водопользователи - таблица - выдача доступа
  WATER_USER_ACCESS_PROVIDE = 'WATER_USER_ACCESS_PROVIDE',
  // водопользователи - таблица - архив
  WATER_USER_CARD_ARCHIVE = 'WATER_USER_CARD_ARCHIVE',

  // Декаданая заявка - [просмотр]
  DECADE_REQUEST_VIEW = 'DECADE_REQUEST_VIEW',
  // Декаданая заявка - [редактировать]
  DECADE_REQUEST_EDIT = 'DECADE_REQUEST_EDIT',

  // Водопользование - [просмотр]
  WATER_USE_VIEW = 'WATER_USE_VIEW',
  // Водопользование - водополив [просмотр]
  WATER_USE_SPILLWAY_VIEW = 'WATER_USE_SPILLWAY_VIEW',
  // Водопользование - водополив - физ лица [просмотр]
  WATER_USE_IRRIGATION_PHYSICAL_VIEW = 'WATER_USE_IRRIGATION_PHYSICAL_VIEW',
  // Водопользование - водополив - юр лица [просмотр]
  WATER_USE_IRRIGATION_LEGAL_VIEW = 'WATER_USE_IRRIGATION_LEGAL_VIEW',
  // Водопользование - водополив - ассоциация [просмотр]
  WATER_USE_IRRIGATION_ASSOCIATION_VIEW = 'WATER_USE_IRRIGATION_ASSOCIATION_VIEW',
  // Водопользование - водополив - физ лица [редактировать]
  WATER_USE_IRRIGATION_PHYSICAL_EDIT = 'WATER_USE_IRRIGATION_PHYSICAL_EDIT',
  // Водопользование - водополив - юр лица [редактировать]
  WATER_USE_IRRIGATION_LEGAL_EDIT = 'WATER_USE_IRRIGATION_LEGAL_EDIT',
  // Водопользование - водополив - ассоциация [редактировать]
  WATER_USE_IRRIGATION_ASSOCIATION_EDIT = 'WATER_USE_IRRIGATION_ASSOCIATION_EDIT',
  // Водопользование - водоподача - [просмотр]
  WATER_USE_SUPPLY_VIEW = 'WATER_USE_SUPPLY_VIEW',
  // Водопользование - водополив - [заявка]
  WATER_USE_SPILLWAY_REQUEST = 'WATER_USE_SPILLWAY_REQUEST',

  // Водоучет - [просмотр]
  WATER_ACCOUNTING_HOUSEHOLD_VIEW = 'WATER_ACCOUNTING_HOUSEHOLD_VIEW',
  // Водоучет - хоз договорной - таблица (утро,вечер)
  WATER_ACCOUNTING_HOUSEHOLD_DATETIME_SAVE = 'WATER_ACCOUNTING_HOUSEHOLD_DATETIME_SAVE',
  // балансовый водоучет - [просмотр]
  WATER_ACCOUNTING_BALANCE_VIEW = 'WATER_ACCOUNTING_BALANCE_VIEW',
  // Водоучет - балансовый - таблица (утро,вечер)
  WATER_ACCOUNTING_BALANCE_DATETIME_SAVE = 'WATER_ACCOUNTING_BALANCE_DATETIME_SAVE',
  // Журнал водоподачи - [просмотр]
  WATER_ACCOUNTING_WATER_LOG_VIEW = 'WATER_ACCOUNTING_WATER_LOG_VIEW',
  // Управление работами - [просмотр]
  WORK_MANAGEMENT_VIEW = 'WORK_MANAGEMENT_VIEW',
  // Управление работами - эксплуатационный блок - [создание]
  WORK_MANAGEMENT_OPERATIONAL_CREATE = 'WORK_MANAGEMENT_OPERATIONAL_CREATE',
  // Управление работами - эксплуатационный блок - список
  WORK_MANAGEMENT_OPERATIONAL_LIST_VIEW = 'WORK_MANAGEMENT_OPERATIONAL_LIST_VIEW',
  // Управление работами - Строительство - список
  WORK_MANAGEMENT_CONSTRUCTION_LIST_VIEW = 'WORK_MANAGEMENT_CONSTRUCTION_LIST_VIEW',
  // Управление работами - Прочие таски - список
  WORK_MANAGEMENT_OTHERTASK_LIST_VIEW = 'WORK_MANAGEMENT_OTHERTASK_LIST_VIEW',
  // Управление работами - список -  эксплуатационный блок - карточка
  WORK_MANAGEMENT_OPERATIONAL_CARD_EDIT = 'WORK_MANAGEMENT_OPERATIONAL_CARD_EDIT',
  // Управление работами - список -  эксплуатационный блок - архив
  WORK_MANAGEMENT_OPERATIONAL_ARCHIVE = 'WORK_MANAGEMENT_OPERATIONAL_ARCHIVE',
  // Управление работами - строительство - [создание]
  WORK_MANAGEMENT_CONSTRUCTION_CREATE = 'WORK_MANAGEMENT_CONSTRUCTION_CREATE',
  // Управление работами - список - строительство - карточка
  WORK_MANAGEMENT_CONSTRUCTION_CARD_EDIT = 'WORK_MANAGEMENT_CONSTRUCTION_CARD_EDIT',
  // Управление работами - список - строительство - архив
  WORK_MANAGEMENT_CONSTRUCTION_ARCHIVE = 'WORK_MANAGEMENT_CONSTRUCTION_ARCHIVE',
  // Управление работами - Прочие задачи - [создание]
  WORK_MANAGEMENT_OTHERTASK_CREATE = 'WORK_MANAGEMENT_OTHERTASK_CREATE',
  // Управление работами - список - Прочие задачи - карточка
  WORK_MANAGEMENT_OTHERTASK_CARD_EDIT = 'WORK_MANAGEMENT_OTHERTASK_CARD_EDIT',
  // Управление работами - список - Прочие задачи - архив
  WORK_MANAGEMENT_OTHERTASK_ARCHIVE = 'WORK_MANAGEMENT_OTHERTASK_ARCHIVE',
  // Акты и счета на оплату - [просмотр]
  ACT_PAYMENT_CARD_VIEW = 'ACT_PAYMENT_CARD_VIEW',
  // Акты и счета на оплату - [просмотр]
  ACT_PAYMENT_CARD_CONFIRM = 'ACT_PAYMENT_CARD_CONFIRM',
  // Тарифы - [просмотр]
  TARIFF_VIEW = 'TARIFF_VIEW',
  // Тарифы - просмотр карточки тарифа - [просмотр]
  TARIFF_CARD_VIEW = 'TARIFF_CARD_VIEW',
  // Тарифы - принт
  TARIFF_CARD_PRINT = 'TARIFF_CARD_PRINT',
  // Диаграмма Ганта - [просмотр]
  GANTT_DIAGRAM_VIEW = 'GANTT_DIAGRAM_VIEW',
  // Годовая заявка - [просмотр]
  ANNUAL_REQUEST_VIEW = 'ANNUAL_REQUEST_VIEW',
  // Годовая заявка - карточка - [просмотр]
  ANNUAL_CARD_VIEW = 'ANNUAL_CARD_VIEW',
  // Годовая заявка - [создать]
  ANNUAL_CARD_CREATE = 'ANNUAL_CARD_CREATE',
  // Годовое планирование - [просмотр]
  ANNUAL_PLANNING_VIEW = 'ANNUAL_PLANNING_VIEW',
  // Мониторинг РУВХ - [просмотр]
  MONITORING_RUVH_VIEW = 'MONITORING_RUVH_VIEW',
  // Качественные показатели - [просмотр]
  QUALITY_INDICATORS_VIEW = 'QUALITY_INDICATORS_VIEW',
  // Водохранилища - [просмотр]
  RESERVOIRS_VIEW = 'RESERVOIRS_VIEW',
  // Водохранилища - [создание]
  RESERVOIRS_CREATE = 'RESERVOIRS_CREATE',
  // Водохранилища - ирригационная - таблица - ГИС
  IRRIGATION_SYSTEM_RESERVOIRS_GIS_VIEW = 'IRRIGATION_SYSTEM_RESERVOIRS_GIS_VIEW',
  // Водохранилища - ирригационная - таблица - Карточка: "Головное водозаборное сооружение (узел)"
  IRRIGATION_SYSTEM_RESERVOIRS_CARD_VIEW = 'IRRIGATION_SYSTEM_RESERVOIRS_CARD_VIEW',
  // Водохранилища - ирригационная -таблица - архив
  IRRIGATION_SYSTEM_RESERVOIRS_CARD_ARCHIVE = 'IRRIGATION_SYSTEM_RESERVOIRS_CARD_ARCHIVE',
  // Водохранилища - таблица - едит хранилища
  RESERVOIRS_CARD_EDIT = 'RESERVOIRS_CARD_EDIT',
  // Водохранилища - таблица - просмотр хранилища
  RESERVOIRS_CARD_VIEW = 'RESERVOIRS_CARD_VIEW',
  // Водохранилища - таблица - архив
  RESERVOIRS_CARD_ARCHIVE = 'RESERVOIRS_CARD_ARCHIVE',
  // Геопортал - [просмотр]
  GEOPORTAL_VIEW = 'GEOPORTAL_VIEW',
  // СППР - [просмотр]
  SPPR_VIEW = 'SPPR_VIEW',

  // Управление системой - Агроклиматическая зона - [просмотр]
  SYSTEM_AGROCLIMATIC_ZONE_VIEW = 'SYSTEM_AGROCLIMATIC_ZONE_VIEW',
  // Управление системой - Архивариус - [просмотр]
  ARCHIVARIUS_VIEW = 'ARCHIVARIUS_VIEW',
  // Управление системой - Справочник гидроучастков - [просмотр]
  HYDRO_FACILITY_VIEW = 'HYDRO_FACILITY_VIEW',
  // Управление системой - Справочники - [просмотр]
  REFERENCES_VIEW = 'REFERENCES_VIEW',
  // Управление системой - Управляющие системой - [просмотр]
  OWNERS_VIEW = 'OWNERS_VIEW',
  // Управление системой - Управление ролями и доступом - [просмотр]
  ROLES_VIEW = 'ROLES_VIEW',
  // Управление системой - Управление Гео порталом - [просмотр]
  GEO_PORTAL_VIEW = 'GEO_PORTAL_VIEW',
  // Администрирование - Новости - [просмотр]
  NEWS_VIEW = 'NEWS_VIEW',
  // Администрирование - Инструкции - [просмотр]
  INSTRUCTIONS_VIEW = 'INSTRUCTIONS_VIEW',
  // Администрирование - Справочные материалы - [просмотр]
  REFERENCE_MATERIALS_VIEW = 'REFERENCE_MATERIALS_VIEW',

  // Отчеты - [просмотр]
  REPORTS_VIEW = 'REPORTS_VIEW',
  // Отчет 2ТП водхоз - [просмотр]
  REPORT_2TP_ENTRYDATA_VIEW = 'REPORT_2TP_ENTRYDATA_VIEW',
  // Отчет 2ТП водхоз - [редактировование]
  REPORT_2TP_CARD_EDIT = 'REPORT_2TP_CARD_EDIT',
  // Отчет 2ТП водхоз - кнопка отправить
  REPORT_2TP_SEND = 'REPORT_2TP_SEND',
  // Отчет 2ТП водхоз  - журнал - [просмотр]
  REPORT_2TP_LOG_VIEW = 'REPORT_2TP_LOG_VIEW',
  // Отчет сводный отчет 2тп - [просмотр]
  REPORT_2TP_CONSOLIDATE_VIEW = 'REPORT_2TP_CONSOLIDATE_VIEW',
  // Отчеты - [Выполнение хоз договорной водоподачи]
  REPORTS_WATER_SUPPLY_CONTRACT_VIEW = 'REPORTS_WATER_SUPPLY_CONTRACT_VIEW',
  // Отчеты - [Заключение договоров]
  REPORTS_CONTRACTS_VIEW = 'REPORTS_CONTRACTS_VIEW',
  // Отчеты - [Водоподача]
  REPORTS_WATER_SUPPLY_VIEW = 'REPORTS_WATER_SUPPLY_VIEW',

  //Гидротехнические сооружения и прочее - [просмотр]
  IRRIGATION_SYSTEM_OBJECTS_VIEW = 'IRRIGATION_SYSTEM_OBJECTS_VIEW',
  //Гидротехнические сооружения и прочее - [создание]
  IRRIGATION_SYSTEM_OBJECTS_CREATE = 'IRRIGATION_SYSTEM_OBJECTS_CREATE',
  //Гидротехнические сооружения и прочее - таблица - действие - ГИС
  IRRIGATION_SYSTEM_OBJECTS_GIS_VIEW = 'IRRIGATION_SYSTEM_OBJECTS_GIS_VIEW',
  //Гидротехнические сооружения и прочее - таблица - действие - просмотри гидропост
  IRRIGATION_SYSTEM_OBJECTS_TABLE_VIEW = 'IRRIGATION_SYSTEM_OBJECTS_TABLE_VIEW',
  //Гидротехнические сооружения и прочее - таблица - действие - архив
  IRRIGATION_SYSTEM_OBJECTS_ARCHIVE = 'IRRIGATION_SYSTEM_OBJECTS_ARCHIVE',

  //План суммарного водозбора из источников орошения
  ANNUAL_WATERSHED_VIEW = 'ANNUAL_WATERSHED_VIEW',
  //План суммарной водоподачи в точка выдела всего
  ANNUAL_SUPPLY_VIEW = 'ANNUAL_SUPPLY_VIEW',
  //План водоподачи на орошение
  ANNUAL_IRRIGATION_VIEW = 'ANNUAL_IRRIGATION_VIEW',
  //План влагозарядковых поливов
  ANNUAL_IRRIGATION_SUPPLY_VIEW = 'ANNUAL_IRRIGATION_SUPPLY_VIEW',
  //План влагозарядковых поливов(гектарополивы)
  ANNUAL_IRRIGATION_HECTARES_VIEW = 'ANNUAL_IRRIGATION_HECTARES_VIEW',
  //План первого полива
  ANNUAL_FIRST_WATERING_VIEW = 'ANNUAL_FIRST_WATERING_VIEW',
  //План гектарополивов всего
  ANNUAL_IRRIGATION_TOTAL_HECTARES_VIEW = 'ANNUAL_IRRIGATION_TOTAL_HECTARES_VIEW',
  //План хоздоговорной водоподачи
  ANNUAL_CONTRACTUAL_WATER_SUPPLY_VIEW = 'ANNUAL_CONTRACTUAL_WATER_SUPPLY_VIEW',
  //План посева сельхозкультур на орошаемых землях
  ANNUAL_IRRIGATED_SOWING_VIEW = 'ANNUAL_IRRIGATED_SOWING_VIEW',
  //План гидрометрических работ
  ANNUAL_HYDROMETRIC_WORK_VIEW = 'ANNUAL_HYDROMETRIC_WORK_VIEW',
  //годовое планирование - согласование
  ANNUAL_RECONCILIATION = 'ANNUAL_RECONCILIATION',
  //годовое планирование - редактор
  ANNUAL_CARD_EDIT = 'ANNUAL_CARD_EDIT',

  //Качественные показатели воды - таб - справочник зон
  WATER_QUALITY_HANDBOOK_VIEW = 'WATER_QUALITY_HANDBOOK_VIEW',
  //Качественные показатели воды - таб - ввод данных
  WATER_QUALITY_ENTRYDATA_VIEW = 'WATER_QUALITY_ENTRYDATA_VIEW',
  //Качественные показатели воды - таб - точки мониторинга
  WATER_QUALITY_MONITORING_VIEW = 'WATER_QUALITY_MONITORING_VIEW',
  //Качественные показатели воды - создать зону
  WATER_QUALITY_ZONE_CREATE = 'WATER_QUALITY_ZONE_CREATE',
  //Качественные показатели воды - таблица - едит зоны
  WATER_QUALITY_ZONE_EDIT = 'WATER_QUALITY_ZONE_EDIT',

  //Система планирование - Карты
  PLANNING_SYSTEM_MAP_VIEW = 'PLANNING_SYSTEM_MAP_VIEW',
  // Система планирование - Водоиспользование
  PLANNING_SYSTEM_WATER_USE_VIEW = 'PLANNING_SYSTEM_WATER_USE_VIEW',
  // Система планирование - Ирригационный фонд
  PLANNING_SYSTEM_IRRIGATION_VIEW = 'PLANNING_SYSTEM_IRRIGATION_VIEW',
  // Система планирование - Качественные показатели
  PLANNING_SYSTEM_QUALITY_VIEW = 'PLANNING_SYSTEM_QUALITY_VIEW',
  // Система планирование - Водохранилище
  PLANNING_SYSTEM_RESERVOIRS_VIEW = 'PLANNING_SYSTEM_RESERVOIRS_VIEW',
  // Система планирование - Мониторинг тарифов
  PLANNING_SYSTEM_MONITORING_VIEW = 'PLANNING_SYSTEM_MONITORING_VIEW',
  // Система планирование - Балансовый водоучет
  PLANNING_SYSTEM_BALANCE_WATER_VIEW = 'PLANNING_SYSTEM_BALANCE_WATER_VIEW',
  // Система планирование - Балансовый учет
  PLANNING_SYSTEM_BALANCE_SHEET_VIEW = 'PLANNING_SYSTEM_BALANCE_SHEET_VIEW',

  //Агроклиматические зоны - Создание
  AGROCLIMATIC_ZONE_CREATE = 'AGROCLIMATIC_ZONE_CREATE',
  //Агроклиматические зоны - просмотр страницы
  AGROCLIMATIC_ZONE_VIEW = 'AGROCLIMATIC_ZONE_VIEW',
  //Агроклиматические зоны - просмотр таблицы
  AGROCLIMATIC_ZONE_CARD_VIEW = 'AGROCLIMATIC_ZONE_CARD_VIEW',
}
export enum Permission {
  NEVER = 'NEVER',
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  IS_UNAUTHENTICATED = 'IS_UNAUTHENTICATED',

  MAIN_VIEW = 'MAIN_READ',
  WATER_USER_VIEW = 'WATER_USER_READ',
  WATER_USER_EDIT = 'WATER_USER_UPDATE',
  WATER_USER_CREATE = 'WATER_USER_CREATE',
  IRRIGATION_SYSTEM_VIEW = 'IRRIGATION_SYSTEM_READ',
  IRRIGATION_SYSTEM_EDIT = 'IRRIGATION_SYSTEM_UPDATE',
  IRRIGATION_SYSTEM_CREATE = 'IRRIGATION_SYSTEM_CREATE',
  IRRIGATION_SYSTEM_OBJECT_VIEW = 'IRRIGATION_SYSTEM_OBJECT_READ',
  IRRIGATION_SYSTEM_OBJECT_EDIT = 'IRRIGATION_SYSTEM_OBJECT_UPDATE',
  IRRIGATION_SYSTEM_OBJECTS_CREATE = 'IRRIGATION_SYSTEM_OBJECTS_CREATE',
  RESERVOIR_MONITORING_DATA_INSERTION_FORM_VIEW = 'RESERVOIR_MONITORING_DATA_INSERTION_FORM_READ',
  RESERVOIR_MONITORING_DATA_INSERTION_FORM_EDIT = 'RESERVOIR_MONITORING_DATA_INSERTION_FORM_UPDATE',
  RESERVOIR_MONITORING_DATA_INSERTION_FORM_CREATE = 'RESERVOIR_MONITORING_DATA_INSERTION_FORM_CREATE',
  GEONODE_VIEW = 'GEONODE_READ',
  GEONODE_EDIT = 'GEONODE_UPDATE',
  GEONODE_CREATE = 'GEONODE_CREATE',
  ANNUAL_PLANNING_VIEW = 'ANNUAL_PLANNING_READ',
  ANNUAL_PLANNING_EDIT = 'ANNUAL_PLANNING_UPDATE',
  ANNUAL_PLANNING_CREATE = 'ANNUAL_PLANNING_CREATE',
  DECADE_APPLICATION_VIEW = 'DECADE_REQUEST_READ',
  DECADE_APPLICATION_EDIT = 'DECADE_REQUEST_UPDATE',
  DECADE_APPLICATION_CREATE = 'DECADE_REQUEST_CREATE',
  WATER_SUPPLY_LIST_VIEW = 'WATER_SUPPLY_LIST_READ',
  WATER_SUPPLY_LIST_EDIT = 'WATER_SUPPLY_LIST_UPDATE',
  WATER_SUPPLY_LIST_CREATE = 'WATER_SUPPLY_LIST_CREATE',
  GRAPH_WATER_SUPPLY_LIST_VIEW = 'GRAPH_WATER_SUPPLY_LIST_READ',
  GRAPH_WATER_SUPPLY_LIST_EDIT = 'GRAPH_WATER_SUPPLY_LIST_UPDATE',
  GRAPH_WATER_SUPPLY_LIST_CREATE = 'GRAPH_WATER_SUPPLY_LIST_CREATE',
  ACT_PAYMENT_VIEW = 'ACT_PAYMENT_READ',
  ACT_PAYMENT_EDIT = 'ACT_PAYMENT_UPDATE',
  ACT_PAYMENT_CREATE = 'ACT_PAYMENT_CREATE',
  TARIFF_VIEW = 'TARIFF_READ',
  TARIFF_EDIT = 'TARIFF_UPDATE',
  TARIFF_CREATE = 'TARIFF_CREATE',
  MAIN_TASK_VIEW = 'MAIN_TASK_READ',
  AGROCLIMATICZONE_VIEW = 'AGROCLIMATICZONE_READ',
  AGROCLIMATICZONE_VIEWS = 'AGROCLIMATICZONE_VIEWS',
  TASK_DIAGRAMM_GUNTT_VIEW = 'TASK_DIAGRAMM_GUNTT_READ',
  IRRIGATION_LIST_VIEW = 'IRRIGATION_LIST_READ',
  IRRIGATION_LIST_EDIT = 'IRRIGATION_LIST_UPDATE',
  IRRIGATION_LIST_CREATE = 'IRRIGATION_LIST_CREATE',
  COLLECTION_DATA_OUTLET_VIEW = 'COLLECTION_DATA_OUTLET_READ',
  COLLECTION_DATA_OUTLET_EDIT = 'COLLECTION_DATA_OUTLET_UPDATE',
  COLLECTION_DATA_OUTLET_CREATE = 'COLLECTION_DATA_OUTLET_CREATE',
  MONITORING_CHUI_RUVH_VIEW = 'MONITORING_CHUI_RUVH_READ',

  // ManageEvents
  TASK_OTHER_CREATE_VIEW = 'TASK_OTHER_CREATE_READ',
  TASK_OTHER_CREATE_EDIT = 'TASK_OTHER_CREATE_UPDATE',
  TASK_OTHER_CREATE_CREATE = 'TASK_OTHER_CREATE_CREATE',
  TASK_CONSTRUCTION_CREATE_VIEW = 'TASK_CONSTRUCTION_CREATE_READ',
  TASK_CONSTRUCTION_CREATE_CREATE = 'TASK_CONSTRUCTION_CREATE_CREATE',
  TASK_CONSTRUCTION_CREATE_EDIT = 'TASK_CONSTRUCTION_CREATE_UPDATE',
  TASK_REGULAR_CREATE_VIEW = 'TASK_REGULAR_CREATE_READ',
  TASK_REGULAR_CREATE_EDIT = 'TASK_REGULAR_CREATE_UPDATE',
  TASK_REGULAR_CREATE_CREATE = 'TASK_REGULAR_CREATE_CREATE',
  TASK_CREATE_VIEW = 'TASK_CREATE_READ',
  TASK_CREATE_CREATE = 'TASK_CREATE_CREATE',
  TASK_CREATE_EDIT = 'TASK_CREATE_UPDATE',
  SOURCE_DATA_INSERTION_FORM_VIEW = 'SOURCE_DATA_INSERTION_FORM_READ',
  SOURCE_DATA_INSERTION_FORM_EDIT = 'SOURCE_DATA_INSERTION_FORM_UPDATE',
  SOURCE_DATA_INSERTION_FORM_CREATE = 'SOURCE_DATA_INSERTION_FORM_CREATE',
}
