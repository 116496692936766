import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const DangerDeleteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9001 1.55556H7.4501L6.7501 0.875H3.2501L2.5501 1.55556H0.100098V2.91667H9.9001M0.800098 11.7639C0.800098 12.1249 0.947597 12.4711 1.21015 12.7263C1.4727 12.9816 1.82879 13.125 2.2001 13.125H7.8001C8.1714 13.125 8.5275 12.9816 8.79005 12.7263C9.0526 12.4711 9.2001 12.1249 9.2001 11.7639V3.59722H0.800098V11.7639Z"
        fill="#D84B53"
      />
    </svg>
  );
};
