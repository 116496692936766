import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { CustomCard, Divider, InformationalBlock } from '@features/ui-kit';
import { useGetIrrigationSystemInfoByIdQuery } from '@store/gisproApi';

import { InfoTableCard, StateCards } from './components';
import styles from './SystemBlockSegment.module.scss';

export const SystemBlockSegment: React.FC = () => {
  const { systemId = '' } = useParams<string>();

  const { data: systemData } = useGetIrrigationSystemInfoByIdQuery({
    irrigationSystemId: systemId,
  });

  return (
    <InformationalBlock>
      <Row gutter={[8, 8]}>
        <Col span="9">
          <CustomCard title="Основные характеристики">
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Общ. орошаемая площадь, Га:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.totalArea ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Балансовая стоимость, сом:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.balanceSum ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Средний КПД, %:
                </Typography.Text>
              </Col>
              <Col span={12}>{formatDecimal(systemData?.averageKpd ?? 0)}%</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Количество каналов, шт:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.channelsCount ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Протяженность каналов, км:
                </Typography.Text>
              </Col>
              <Col span={12}>
                {formatDecimal(systemData?.channelsLength ?? 0)}
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Облицованных участков, км:
                </Typography.Text>
              </Col>
              <Col span={12}>
                {formatDecimal(systemData?.linedWithLength ?? 0)}
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Необлицованных участков, км:
                </Typography.Text>
              </Col>
              <Col span={12}>
                {formatDecimal(systemData?.linedWithoutLength ?? 0)}
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Количество ГТС всего, шт:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.gtsCount ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Количество эксплуатационных сооружений, шт:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.operationalFacilitiesCount ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Водосборно-сбросная сеть, км:
                </Typography.Text>
              </Col>
              <Col span={12}>{systemData?.collectorDrainageNetwork ?? 0}</Col>
              <Col span={12}>
                <Typography.Text type="secondary">
                  Коллекторно-дренажная сеть, км:
                </Typography.Text>
              </Col>
              <Col span={12}>
                {systemData?.drainageAndDischargeNetwork ?? 0}
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={5}>
          <CustomCard title="Состояние каналов">
            <Typography.Text strong>Основных, (м):</Typography.Text>
            <StateCards
              states={systemData?.channelsInfo[0]}
              type="channelsInfo"
            />
            <Divider />
            <Typography.Text strong>Каналы КДС, (м.):</Typography.Text>
            <StateCards
              states={systemData?.channelsInfo[1]}
              type="channelsInfo"
            />
            <Divider />
            <Typography.Text strong>
              Каналы Сбросной сети, (м.):
            </Typography.Text>
            <StateCards
              states={systemData?.channelsInfo[2]}
              type="channelsInfo"
            />
          </CustomCard>
        </Col>
        <Col span={5}>
          <CustomCard title="Состояние ГТС">
            <Typography.Text strong> На основных каналах, шт:</Typography.Text>
            <StateCards states={systemData?.gtsInfo[0]} type="gtsInfo" />
            <Divider />
            <Typography.Text strong>На сбросной сети, шт:</Typography.Text>
            <StateCards states={systemData?.gtsInfo[1]} type="gtsInfo" />
            <Divider />
            <Typography.Text strong> На КДС, шт.:</Typography.Text>
            <StateCards states={systemData?.gtsInfo[2]} type="gtsInfo" />
          </CustomCard>
        </Col>
        <Col span={5}>
          <CustomCard title="Резервуары">
            <div className={styles.reservoirsContainer}>
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text type="secondary">
                    Общая площадь, кв.м:
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  {systemData?.reservoirsInfo.reservoirsCount ?? 0}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text type="secondary">БСР, шт.:</Typography.Text>
                </Col>
                <Col span={12}>{systemData?.reservoirsInfo.bsrCount ?? 0}</Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text type="secondary">БДР, шт:</Typography.Text>
                </Col>
                <Col span={12}>{systemData?.reservoirsInfo.bdrCount ?? 0}</Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text type="secondary">
                    Другие, шт.:
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  {systemData?.reservoirsInfo.othersCount ?? 0}
                </Col>
              </Row>
            </div>
            <Typography.Text strong>Состояние шт.:</Typography.Text>
            <StateCards
              states={systemData?.reservoirsInfo}
              type="reservoirsInfo"
            />
          </CustomCard>
        </Col>
        <Col span={24}>
          <InfoTableCard systemId={systemId} />
        </Col>
      </Row>
    </InformationalBlock>
  );
};
