import React from 'react';

import { Modal, ModalProps } from 'antd';

import styles from './CustomModalV2.module.scss';

type PropsType = ModalProps;

export const CustomModalV2: React.FC<PropsType> = ({ children, ...props }) => {
  return (
    <Modal className={styles.modal} {...props}>
      {children}
    </Modal>
  );
};
