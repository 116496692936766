import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { useGetAllCommonReferencesQuery } from '@store/gisproApi';
import { get } from '@utils/utils';
import { useReferenceControllerFindAllQuery } from '@store/api/referenceApi';

export function renderCommonReference<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { data: commonReferences = {} } = useReferenceControllerFindAllQuery();
  const referenceId = get(record, customColumn.dataIndex) as string;
  const commonReference = (commonReferences as any)[referenceId] as any;

  return <span>{commonReference?.title || '-'}</span>;
}
