import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const columns: TableColumn[] = [
  {
    title: 'Административно-территориальная единица',
    dataIndex: 'name',
    xtype: CustomTableColumnType.String,
    key: 'name',
    align: 'left',
    width: 200,
  },
  {
    title: 'Количество отчитываю-щихся водопользо-вателей',
    dataIndex: 'waterUsersCount',
    xtype: CustomTableColumnType.String,
    key: 'waterUsersCount',
    align: 'left',
    width: 200,
  },
  {
    title: 'Всего забрано из водных объектов',
    dataIndex: 'totalWater',
    xtype: CustomTableColumnType.String,
    key: 'totalWater',
    align: 'left',
    width: 200,
  },
  {
    title: 'Отведено вод тыс. м3',
    dataIndex: 'diverted',
    align: 'left',
    key: 'diverted',
    xtype: CustomTableColumnType.String,
    children: [
      {
        title: 'Без очистки',
        dataIndex: 'withoutCleaning',
        align: 'left',
        key: 'withoutCleaning',
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Хоз. питьевые',
            dataIndex: 'householdDrinking',
            align: 'left',
            width: 114,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'Производ-ственные',
            dataIndex: 'industrial',
            align: 'left',
            width: 114,
            xtype: CustomTableColumnType.String,
          },
        ],
      },
      {
        title: 'После очистки',
        dataIndex: 'afterClean',
        align: 'left',
        key: 'afterClean',
        xtype: CustomTableColumnType.String,
        children: [
          {
            title: 'Недостаточно очищенных',
            dataIndex: 'notEnoughPurified',
            align: 'left',
            width: 119,
            xtype: CustomTableColumnType.String,
          },
          {
            title: 'Нормативные очищенных на сооружениях очистки',
            dataIndex: 'treatmentFacilities',
            align: 'left',
            key: 'treatmentFacilities',
            xtype: CustomTableColumnType.String,
            children: [
              {
                title: 'Хоз. питьевые',
                dataIndex: 'householdDrinking',
                align: 'left',
                width: 275,
                xtype: CustomTableColumnType.String,
              },
              {
                title: 'Производственные',
                dataIndex: 'industrialCleaned',
                align: 'left',
                width: 275,
                xtype: CustomTableColumnType.String,
              },
              {
                title: 'Орошение',
                dataIndex: 'irrigationWater',
                align: 'left',
                width: 275,
                xtype: CustomTableColumnType.String,
              },
            ],
          },
        ],
      },
    ],
  },
];
