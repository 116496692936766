import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const HomeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7103 8.8853L9.3493 2.15332C9.3035 2.10472 9.2491 2.06616 9.18921 2.03985C9.12932 2.01354 9.06512 2 9.00029 2C8.93545 2 8.87126 2.01354 8.81137 2.03985C8.75148 2.06616 8.69708 2.10472 8.65128 2.15332L2.29033 8.8853C2.10501 9.08157 2 9.34816 2 9.62621C2 10.2036 2.44321 10.673 2.98835 10.673H3.65857V15.4766C3.65857 15.7661 3.87941 16 4.15275 16H8.01194V12.3363H9.74155V16H13.8478C14.1212 16 14.342 15.7661 14.342 15.4766V10.673H15.0122C15.2748 10.673 15.5265 10.5634 15.7118 10.3655C16.0963 9.95659 16.0963 9.29419 15.7103 8.8853Z"
        fill="#FF6984"
      />
    </svg>
  );
};
