import React from 'react';

import { Tooltip, TooltipProps } from 'antd';
import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';

import styles from './TooltipedTextarea.module.scss';

type TooltipedSelectPropsType = TextAreaProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const TooltipedTextarea: React.FC<TooltipedSelectPropsType> = ({
  tooltipProps,
  ...props
}) => {
  return (
    <Tooltip {...tooltipProps}>
      <TextArea className={styles.textarea} {...props} />
    </Tooltip>
  );
};
