import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction, Middleware } from 'redux';

export const localStorageMiddleware: Middleware<
  object,
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = ({ getState }) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    const headerState = (getState() as any).headerSlice ?? {};

    localStorage.setItem('headerState', JSON.stringify(headerState));
    return result;
  };
};

export const loadStore = () => {
  try {
    const serializedState = localStorage.getItem('headerState');
    if (serializedState === null) {
      return {};
    }
    return { headerSlice: JSON.parse(serializedState) };
  } catch (err) {
    return {};
  }
};
