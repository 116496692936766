import { Routes } from '@enums/routes';
import { OperationalTableSelectsBlock } from '@features/manageEvents/tables/OperationalTable/OperationalTableSelectsBlock';
import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import { useGetAllOperationalQuery } from '@store/gisproApi';
import { getCurrentTableOptionsSelector } from '@store/selectors/manageEvents';
import { saveTableOptions } from '@store/slices';

import { Col, Divider, Row, Typography } from 'antd';
import { toString } from '@utils/utils';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import styles from './ManageEventsOperationalTable.module.scss';
import { useOperationalColumns } from './useOperationalColumns';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { JsonRecord } from '@models/jsonRecord';
import { saveUrl } from '@utils/returnUrl';

interface IManageEventsOperationalTableProps {
  isArchived?: boolean;
  title?: string;
}

export const ManageEventsOperationalTable: React.FC<
  IManageEventsOperationalTableProps
> = ({ title, isArchived = false }) => {
  const dispatch = useDispatch();
  const { columns } = useOperationalColumns(isArchived);
  const tableOptions = useSelector(getCurrentTableOptionsSelector);
  const { data: response, isLoading } = useGetAllOperationalQuery({
    ...tableOptions,
    isArchived,
  });

  const saveAction = saveUrl();

  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const navigate = useNavigate();
  const { id } = useParams();
  const watchOperationalItem = (idOperation: number | any) => {
    navigate(
      generatePath(Routes.OperationalBlock, {
        id: toString(idOperation.id),
      }),
    );
    saveAction();
  };

  const changePagination = useCallback(
    (pageData: any) => {
      dispatch(
        saveTableOptions({
          ...tableOptions,
          page: pageData.current,
          limit: pageData.pageSize,
        }),
      );
    },
    [columns, tableOptions],
  );

  useEffect(() => {
    if (id) {
      dispatch(saveTableOptions({ systemObjectId: Number(id) }));
    }
  }, [id]);

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  return (
    <Row>
      <Col span={24}>
        <OperationalTableSelectsBlock />
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <InformationalBlock>
          <CustomCard
            style={{ padding: '8px 16px' }}
            title={<Typography.Title level={4}>{title}</Typography.Title>}
          >
            <TableCustom
              dataSource={response?.data || []}
              columns={columns}
              loading={isLoading}
              bordered
              customPagination={{
                pageable: {
                  pageNumber: response?.meta?.page || 1,
                  pageSize: response?.meta?.limit || 10,
                },
              }}
              scroll={{ x: 1500 }}
              totalCount={response?.meta?.totalCount}
              onChange={changePagination}
              actionProps={{
                onWatch: watchOperationalItem,
                onUnArchiveClick,
                onArchiveClick,
              }}
            />
          </CustomCard>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
