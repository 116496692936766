import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMainInfoModalSelector } from '@store/selectors';
import { setMainInfoModal } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export function useMainInfoModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getMainInfoModalSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setMainInfoModal(showModal));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
}
