import React from 'react';
import {TabQueryKey} from "@constants/queryKeys";
import {Col, Row} from "antd";
import {Tabs} from "@features/ui-kit";
import {useSearchParams} from "react-router-dom";
import {
  ComputingExistedAnnualApplication
} from "@features/annualApplication/computingExistedAnnualApplication/ComputingExistedAnnualApplication";
import {AnnualApplicationAgreement} from "@features/annualApplication/annualApplicationAgreement";
import {ActualStructure} from "@features/annualApplication/actualStructure/ActualStructure";
import {PlannedStructure} from "@features/annualApplication";
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';

const options = [
  {
    label: 'Плановая структура сева',
    key: AnnualApplicationQueryTypes.plannedStructure,
  },
  {
    label: 'Расчеты',
    key: AnnualApplicationQueryTypes.computing,
  },
  {
    label: 'Договор',
    key: AnnualApplicationQueryTypes.agreement,
  },
  {
    label: 'Фактическая структура сева',
    key: AnnualApplicationQueryTypes.sowingPlan,
  },
];

export const AnnualApplicationView = () => {
  const [params, setParams] = useSearchParams();
  const activeTab =
    (params.get(TabQueryKey) as any) || AnnualApplicationQueryTypes.plannedStructure;

  const changeContentHandle = (key: string) => {
    const newParams = new URLSearchParams();
    newParams.set(TabQueryKey, key);
    setParams(newParams);
  };

  let Component;
  switch (activeTab) {
    case AnnualApplicationQueryTypes.plannedStructure:
      Component = PlannedStructure;
      break;
    case AnnualApplicationQueryTypes.computing:
      Component = ComputingExistedAnnualApplication;
      break;
    case AnnualApplicationQueryTypes.agreement:
      Component = AnnualApplicationAgreement;
      break;
    case AnnualApplicationQueryTypes.sowingPlan:
      Component = ActualStructure;
      break;
    default:
      Component = PlannedStructure;
  }

  return (
    <Row justify="space-between">
      <Col span={24}>
        <Tabs
          items={options}
          activeKey={activeTab}
          onChange={changeContentHandle}
        />
      </Col>

      <Col span={24}>
        <Component />
      </Col>
    </Row>
  );
};
