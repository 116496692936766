import React from 'react';

import { Col, Row } from 'antd';

import { StatusCard, StatusCardType } from '@features/ui-kit';

const STATE_CARDS_DATA: StatusCardType[] = [
  'bad',
  'normal',
  'satisfying',
  'good',
];

interface StateCardsProps {
  states?: { [key in StatusCardType]: number };
  type?: string;
}
export const StateCards: React.FC<StateCardsProps> = ({ states, type }) => {
  return (
    <Row gutter={[10, 10]}>
      {STATE_CARDS_DATA.map((state) => (
        <Col span={6} key={state}>
          <StatusCard
            status={state}
            total={String(states?.[state] ?? 0) || '0'}
            type={type}
          />
        </Col>
      ))}
    </Row>
  );
};
