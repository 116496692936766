import { useTranslation } from 'react-i18next';

import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const usePlantingTableColumns = () => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: t('agroClimaticZone.columns.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 60,
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('agroClimaticZone.columns.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 120,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'name',
      align: 'center',
      width: 120,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <span>{record?.type?.name}</span>;
      },
    },
  ];

  return { columns };
};
