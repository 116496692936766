import { GetWateringPlanDecadalRequestApiResponse } from '@store/gisproApi';
import { roundBigInteger } from '@utils/roundBigInteger';

type ReturnType = {
  data: Array<GetWateringPlanDecadalRequestApiResponse>;
  month: number;
};

export const mapDecadeApplicationTableData = ({ data, month }: ReturnType) => {
  const sumRes = (resultSum: any, curr: any) => {
    Object.entries(curr).forEach(([key, value]) => {
      resultSum[key] = (resultSum[key] || 0) + value;
      resultSum[`${key}3`] =
        resultSum[key] === 0
          ? 0
          : roundBigInteger(resultSum[key] * 0.864, 1000);
    });
  };

  const filteredData = data.map((item: any, index: number) => {
    const resultSum: any = {};
    const decades: any = {};
    for (let i = 0; i < item.decadeDtos.length; i += 1) {
      const { decade, value } = item.decadeDtos[i];
      decades[decade] = {
        ...item.decadeDtos[i],
        value: {
          ...value,
          factM3: value.fact ? roundBigInteger(value.fact * 0.864, 1000) : null,
          planM3: value.plan ? roundBigInteger(value.plan * 0.864, 1000) : null,
          requestM3: value.request
            ? roundBigInteger(value.request * 0.864, 1000)
            : null,
        },
      };
      sumRes(resultSum, value);
    }

    return {
      ...item,
      decadeDtos: decades,
      sum: { ...resultSum },
      index: index + 1,
    };
  });

  return { data: filteredData };
};
