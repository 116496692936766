import { TableProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';

import { getKeyValue } from './getKeyValue';

type PropsType<RecordType> = {
  children: RecordType[];
  keyValue: React.Key;
  rowKey: TableProps<RecordType>['rowKey'];
  newItem: RecordType;
};

export function dataTreeReplace<RecordType extends JsonRecord>({
  children,
  keyValue,
  rowKey,
  newItem,
}: PropsType<RecordType>): RecordType[] {
  const newArray = children.map((item) => {
    if (keyValue === getKeyValue(item, rowKey)) {
      return newItem;
    }

    if (item.children) {
      return {
        ...item,
        children: dataTreeReplace({
          children: item.children,
          keyValue,
          newItem,
          rowKey,
        }),
      };
    }

    return item;
  });

  return newArray;
}
