import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { HomeOutlined } from '@ant-design/icons';
import { TabQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider, Tabs } from '@features/ui-kit';
import { WateringSchedule } from '@features/wateringPlan/wateringSchedule/WateringSchedule';
import { WateringSupplySchedule } from '@features/wateringPlan/wateringSupplySchedule/WateringSupplySchedule';
import { WateringPlanTableTypes } from '@models/wateringPlan/enums/wateringPlan';

import styles from './WateringPlan.module.scss';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';

interface optionsItem {
  key: string;
  label: string;
}

const options = [
  {
    label: 'Оперативный план водополива',
    key: WateringPlanTableTypes.WateringSchedule,
  },
  {
    label: 'Оперативный план водоподачи',
    key: WateringPlanTableTypes.WateringSupplySchedule,
  },
];

export const WateringPlan = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const [params, setParams] = useSearchParams();
  const activeTab =
    (params.get(TabQueryKey) as any) || WateringPlanTableTypes.WateringSchedule;

  const changeContentHandle = (key: string) => {
    const newParams = new URLSearchParams();
    newParams.set(TabQueryKey, key);
    setParams(newParams);
  };

  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WATER_USE_SPILLWAY_VIEW,
      ACCESS.WATER_USE_SUPPLY_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  useEffect(() => {
    setHeaderTitle(t('headers.watering-plan'));
  }, []);

  let Component;
  switch (activeTab) {
    case WateringPlanTableTypes.WateringSchedule:
      Component = WateringSchedule;
      break;
    case WateringPlanTableTypes.WateringSupplySchedule:
      Component = WateringSupplySchedule;
      break;
    default:
      Component = WateringSchedule;
  }

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.watering-plan'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        items={dynamicOptions(options)}
        activeKey={activeTab}
        onChange={changeContentHandle}
      />
      <Component />
    </>
  );
};
