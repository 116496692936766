import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const TelegramAppIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3111_94656)">
        <path
          d="M10 0C7.34844 0 4.80312 1.05422 2.92969 2.92891C1.05432 4.80434 0.000519484 7.34778 0 10C0 12.6511 1.05469 15.1964 2.92969 17.0711C4.80312 18.9458 7.34844 20 10 20C12.6516 20 15.1969 18.9458 17.0703 17.0711C18.9453 15.1964 20 12.6511 20 10C20 7.34891 18.9453 4.80359 17.0703 2.92891C15.1969 1.05422 12.6516 0 10 0Z"
          fill="url(#paint0_linear_3111_94656)"
        />
        <path
          d="M4.5267 9.89404C7.44232 8.62404 9.38608 7.7867 10.358 7.38217C13.1361 6.22701 13.7126 6.02639 14.0892 6.01959C14.172 6.01826 14.3564 6.03873 14.4767 6.136C14.5767 6.21803 14.6048 6.32897 14.6189 6.40686C14.6314 6.48467 14.6486 6.66201 14.6345 6.80045C14.4845 8.3817 13.833 12.2189 13.5017 13.99C13.3626 14.7394 13.0861 14.9906 12.8189 15.0151C12.2376 15.0686 11.797 14.6314 11.2345 14.2628C10.3548 13.6858 9.85795 13.3267 9.00326 12.7637C8.01576 12.1131 8.65639 11.7554 9.21889 11.1711C9.36576 11.0181 11.9251 8.69076 11.9736 8.47967C11.9798 8.45326 11.9861 8.35482 11.9267 8.30295C11.8689 8.25092 11.783 8.26873 11.7205 8.28279C11.6314 8.30279 10.2267 9.23217 7.5017 11.0708C7.10326 11.3448 6.74232 11.4784 6.41732 11.4714C6.06107 11.4637 5.37357 11.2695 4.86264 11.1036C4.23764 10.9 3.7392 10.7923 3.78295 10.4465C3.80482 10.2665 4.05326 10.0823 4.5267 9.89404Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3111_94656"
          x1="1000"
          y1="0"
          x2="1000"
          y2="2000"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_3111_94656">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
