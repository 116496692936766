import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';
import { getIrrigationSystemFiltersSelector } from '@store/selectors';
import {
  getShowWateringFiltersSelector,
  getShowWaterSupplyFiltersSelector,
} from '@store/selectors/wateringPlan';
import {
  changeIrrigationSystemFilters,
  setWateringFilters,
  setWaterSupplyFilters,
} from '@store/slices';
import { isEmpty, pickBy, isInteger } from '@utils/utils';
import wateringPlanSlice from '@store/slices/wateringPlan/wateringPlanSlice';

type ReturnType = {
  filters: IWaterUseFilters;
  setWaterUseWaterSupplyFilters: (filters: IWaterUseFilters) => void;
};

export const useWaterSupplyFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getShowWaterSupplyFiltersSelector);

  const setWaterUseWaterSupplyFilters = useCallback(
    (newFilters: IWaterUseFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);
      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(setWaterSupplyFilters(values as IWaterUseFilters));
    },
    [],
  );

  return { filters, setWaterUseWaterSupplyFilters };
};
