import { monthsObject } from '@constants/month';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import styles from './ActsInvoicesForPaymentsTableContainer.module.scss';
import { ACCESS } from '@enums/permisson';

export const useInvoicesPaymentColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Водопользователь',
      dataIndex: 'waterUserName',
      key: 'waterUserName',
      width: 150,
      xtype: CustomTableColumnType.String,
      className: styles.columns,
    },
    {
      title: 'Объем водоподачи тыс.м3',
      dataIndex: 'waterSupply',
      key: 'waterSupply',
      width: 150,
      xtype: CustomTableColumnType.NumberColumn,
      className: styles.columns,
    },
    {
      title: 'Месяц',
      dataIndex: 'month',
      key: 'month',
      width: 150,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <div>{monthsObject[record.month.toLowerCase()]}</div>
      ),
      className: styles.columns,
    },

    {
      title: 'Стоимость',
      dataIndex: 'cost',
      key: 'cost',
      width: 150,
      xtype: CustomTableColumnType.NumberColumn,
      className: styles.columns,
    },
    {
      title: 'Номер акта',
      dataIndex: 'actNumber',
      key: 'actNumber',
      width: 150,
      xtype: CustomTableColumnType.String,
      className: styles.columns,
    },
    {
      title: 'Дата акта',
      dataIndex: 'dateOfAct',
      key: 'dateOfAct',
      width: 150,
      xtype: CustomTableColumnType.DateColumn,
      className: styles.columns,
    },
    {
      title: 'Действия акта',
      dataIndex: 'actAction',
      key: 'actAction',
      width: 109,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch, CustomTableAction.Text],
      actionsAccess: [
        ACCESS.ACT_PAYMENT_CARD_VIEW,
        ACCESS.ACT_PAYMENT_CARD_CONFIRM,
      ],
    },
    {
      title: 'Дата счета',
      dataIndex: 'dateOfBill',
      key: 'dateOfBill',
      width: 160.5,
      xtype: CustomTableColumnType.DateColumn,
      className: styles.columns,
    },
    {
      title: 'Номер счета',
      dataIndex: 'billNumber',
      key: 'billNumber',
      width: 160.5,
      xtype: CustomTableColumnType.String,
      className: styles.columns,
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'dateOfPay',
      key: 'dateOfPay',
      width: 160.5,
      xtype: CustomTableColumnType.DateColumn,
      className: styles.columns,
    },
    {
      title: 'Действия счета',
      dataIndex: 'billActions',
      key: 'costAction',
      width: 109,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchCheck, CustomTableAction.TextCheck],
      actionsAccess: [
        ACCESS.ACT_PAYMENT_CARD_VIEW,
        ACCESS.ACT_PAYMENT_CARD_CONFIRM,
      ],
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 160.5,
      xtype: CustomTableColumnType.TagColumn,
    },
  ];

  return { columns };
};
