import { ReferenceMaterials } from '@features/administration';
import { Layout } from 'antd';

export const ReferenceMaterialsLayout: React.FC = () => {
  return (
    <Layout>
      <ReferenceMaterials />
    </Layout>
  );
};
