import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Space, Typography } from 'antd';

import { Links } from '@enums/links';
import { useUpdateSystemObjectMutation } from '@store/gisproApi';
import { getWaterObjectSelector } from '@store/selectors/waterObject';
import { waterObjectActions } from '@store/slices';

import styles from './PhotoRecordingComponent.module.scss';
import {
  CustomCard,
  CustomCarousel,
  PhotoUploader,
  TooltipedDatePicker,
  TooltipedInput,
} from '@features/ui-kit';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';

interface ImageArray {
  name?: string;
  date?: Dayjs | null;
  id: number;
  path: string;
}

export const PhotoRecordingComponent: React.FC = () => {
  const [update] = useUpdateSystemObjectMutation();
  const dispatch = useDispatch();

  const waterObject = useSelector(getWaterObjectSelector);

  const [date, setDate] = useState<Dayjs | null>(null);
  const [name, setName] = useState<string>('');
  const [filterDate, setFilterDate] = useState<Dayjs | null>(null);
  const [imageArray, setImageArray] = useState<ImageArray[]>([]);

  const disabled = !date || !name;

  const onLoad = async (file: any) => {
    try {
      dispatch(waterObjectActions.addDocumentToWaterObjectCardAction(file));
      setImageArray((prevArr) => [
        ...prevArr,
        { name: name, date: date, path: file.fileUrl, id: file.attachmentId },
      ]);
    } catch (error) {
      console.log(error);
    }
    setDate(null);
    setName('');
  };

  const filteredArray = useMemo(() => {
    return imageArray.filter((item) => {
      return !filterDate || dayjs(item.date).isSame(dayjs(filterDate), 'day');
    });
  }, [imageArray, filterDate]);

  return (
    <>
      <Row gutter={8}>
        <Col span={14}>
          <CustomCard
            title={
              <Row justify="space-between">
                <Col>
                  <Typography.Title level={4}>Фотографии</Typography.Title>
                </Col>
                <Col>
                  <Space size={8}>
                    <TooltipedDatePicker
                      placeholder="Дата"
                      value={filterDate}
                      onChange={(filterDate) => setFilterDate(filterDate)}
                      allowClear
                    />
                  </Space>
                </Col>
              </Row>
            }
          >
            <Row>
              <Col span={24}>
                <CustomCarousel
                  slidesToShow={filteredArray?.length >= 3 ? 2.2 : 1}
                  items={filteredArray}
                  className={styles.carusel}
                />
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={10}>
          <CustomCard
            title={
              <Row justify="space-between">
                <Col>
                  <Typography.Title level={4}>Документ</Typography.Title>
                </Col>
                <Col>
                  <Space size={8}>
                    <TooltipedDatePicker
                      placeholder="Дата проведения"
                      value={date}
                      onChange={(date) => setDate(date)}
                      allowClear
                    />
                    <TooltipedInput
                      placeholder="Наименование"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      maxLength={255}
                    />
                  </Space>
                </Col>
              </Row>
            }
          >
            <PhotoUploader
              disabled={disabled}
              onLoad={onLoad}
              accept=".png,.jpeg,.jpg,.gif,.svg"
              docType="фото"
            />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
