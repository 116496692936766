import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import { TableCustom } from '@features/ui-kit';
import { WaterUsePlanDataViewTypes } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetSummaryWaterUsePlanByCaQuery,
  useGetSummaryWaterUsePlanByGuvhQuery,
  useGetSummaryWaterUsePlanQuery,
} from '@store/gisproApi';
import {
  getCaFiltersSelector,
  getGuvhFiltersSelector,
  getGuvhTabSelector,
} from '@store/selectors';

import { TotalKpdSummary } from './TotalKpdSummary';
import { useWaterUseRuvhGeneralSummaryColumns } from './useWaterUseRuvhGeneralSummaryColumns';
import { waterUseRuvhGeneralSummaryDataMapper } from './waterUseRuvhGeneralSummaryDataMapper';

export const WaterUsePlanRuvhGeneralSummary: React.FC = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const guvhTab = useSelector(getGuvhTabSelector);
  const guvhFilters = useSelector(getGuvhFiltersSelector);
  const caFilters = useSelector(getCaFiltersSelector);
  const { columns } = useWaterUseRuvhGeneralSummaryColumns();
  const {
    data: generalSummary,
    isLoading: generalSummaryLoading,
    isFetching: generalSummaryFetching,
  } = useGetSummaryWaterUsePlanQuery(
    userRole === UserRoles.ROLE_RUVH ? undefined : skipToken,
  );

  const {
    data: generalSummaryByGuvh,
    isLoading: generalSummaryByGuvhLoading,
    isFetching: generalSummaryByGuvhFetching,
  } = useGetSummaryWaterUsePlanByGuvhQuery(
    userRole === UserRoles.ROLE_GUVH
      ? {
          ruvhId:
            guvhTab === WaterUsePlanDataViewTypes.View
              ? guvhFilters?.districtId
              : undefined,
        }
      : skipToken,
  );
  const {
    data: generalSummaryByCa,
    isLoading: generalSummaryByCaLoading,
    isFetching: generalSummaryByCaFetching,
  } = useGetSummaryWaterUsePlanByCaQuery(
    userRole === UserRoles.ROLE_CA
      ? {
          guvhId:
            guvhTab === WaterUsePlanDataViewTypes.View
              ? caFilters?.regionId
              : undefined,
          ruvhId:
            guvhTab === WaterUsePlanDataViewTypes.View
              ? caFilters?.districtId
              : undefined,
        }
      : skipToken,
  );
  const summaryData =
    generalSummary || generalSummaryByGuvh || generalSummaryByCa;
  const mappedData = waterUseRuvhGeneralSummaryDataMapper(summaryData);
  return (
    <TableCustom
      columns={columns}
      loading={
        generalSummaryLoading ||
        generalSummaryByGuvhLoading ||
        generalSummaryByCaLoading ||
        generalSummaryByCaFetching ||
        generalSummaryByGuvhFetching ||
        generalSummaryFetching
      }
      dataSource={mappedData || generalSummaryByGuvh || generalSummaryByCa}
      scroll={{ x: 'max-content' }}
      // eslint-disable-next-line react/no-unstable-nested-components
      summary={() => <TotalKpdSummary data={summaryData} />}
    />
  );
};
