import { UserRoles } from './roles';

export enum OwnerTypes {
  CA = 0,
  BUVH = 1,
  GUVH = 2,
  RUVH = 3,
  ASSOCIATION = 4,
}

export const OwnerTypesNames = {
  [OwnerTypes.CA]: UserRoles.ROLE_CA,
  [OwnerTypes.BUVH]: UserRoles.ROLE_BUVH,
  [OwnerTypes.GUVH]: UserRoles.ROLE_GUVH,
  [OwnerTypes.RUVH]: UserRoles.ROLE_RUVH,
  [OwnerTypes.ASSOCIATION]: UserRoles.ROLE_ASSOCIATION,
};
