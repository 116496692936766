import { getKibanaUrl } from '@store/kibana';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Row, Space } from 'antd';

import { Routes } from '@enums/routes';
import { MonitoringTableFilters } from '@features/monitoring/demoMonitoring/controls';
import { useMonitoringDailyColumns } from '@features/monitoring/demoMonitoring/table';
import { SectionTitle, TableCustomV2 } from '@features/ui-kit-v2';
import {
  useFindAllHydropostsQuery,
  useGetAllMeasurementsQuery,
} from '@store/gisproApi';
import dayjs from 'dayjs';

import styles from '../MonitoringLayout.module.scss';

export const DailyLayout = () => {
  const navigate = useNavigate();

  const { columns } = useMonitoringDailyColumns();
  const { data: table = [], isLoading, isError } = useFindAllHydropostsQuery();
  const { section = '' } = useParams<string>();
  const dailyTable = table.filter((item) => section === String(item.system_id));
  const today = dayjs().unix();
  const yesterday = dayjs().subtract(1, 'day').unix();

  const { data: measure = [] } = useGetAllMeasurementsQuery({
    id: Number(section),
    start_date: String(yesterday),
    end_date: String(today),
  });
  const uniqueIds = new Set<number>();

  const dailyTableMeasure: any[] = measure.filter((obj) => {
    const isUnique = !uniqueIds.has(obj.hpid);
    if (isUnique) {
      uniqueIds.add(obj.hpid);
    }
    return isUnique;
  });

  const onWatch = (objectId: any) => {
    navigate(
      generatePath(Routes.DemoMonitoringHydroCard, {
        id: String(objectId.id),
      }),
    );
  };

  return (
    <Space size="large" direction="vertical" className={styles.layoutBlock}>
      <Row gutter={12}>
        <Col span={24}>
          <SectionTitle background="blue" title="Суточный" />
        </Col>
      </Row>
      <Row justify="center" style={{ gap: '1%' }}>
        <div style={{ width: '32%' }}>
          <SectionTitle background="blue" title="Балансовые" />
          <iframe
            title="Балансовые"
            src={getKibanaUrl('Балансовые гидропосты дневные')}
            height="600"
            width="100%"
          />
        </div>
        <div style={{ width: '32%' }}>
          <SectionTitle background="blue" title="Водосброс" />
          <iframe
            title="водосброс"
            src={getKibanaUrl('Водосброс гидропосты')}
            height="600"
            width="100%"
          />
        </div>
        <div style={{ width: '32%' }}>
          <SectionTitle background="blue" title="Водоподача" />
          <iframe
            title="водоподача"
            src={getKibanaUrl('Водоподача гидропосты')}
            height="600"
            width="100%"
          />
        </div>
      </Row>
      <Row>
        <TableCustomV2
          loading={isLoading}
          dataSource={dailyTableMeasure.length ? dailyTableMeasure : dailyTable}
          columns={columns}
          size="small"
          actionProps={{ onWatch }}
          // totalCount={table?.length}
          scroll={{ x: 1600 }}
        />
      </Row>
    </Space>
  );
};
