import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { WaterQualityMonitoringType } from '@models/waterQuality/interfaces/waterQuality';

export const useWaterQualityCardSecondStepTableColumns = (
  monitoringType: WaterQualityMonitoringType,
  actions: boolean = false,
) => {
  const columns: TableColumn[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      xtype: CustomTableColumnType.String,
      width: 60,
    },
    {
      title: `Наименование ${
        monitoringType === WaterQualityMonitoringType.ReclamationStatus
          ? 'скважины'
          : 'точки'
      }`,
      dataIndex:
        monitoringType === WaterQualityMonitoringType.ReclamationStatus
          ? 'fio'
          : 'pointName',
      width: 170,
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Вид точки',
      dataIndex: 'type',
      width: 170,
      align: 'center',
      xtype: CustomTableColumnType.String,
    },
  ];
  if (actions) {
    columns.push({
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      align: 'center',
      width: 100,
      title: 'Действия',
      actions: [
        CustomTableAction.DeleteWithoutText,
      ]
    });
  }

  return { columns };
};
