import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BigSuccessIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="44"
        cy="44"
        r="42"
        stroke="#A5DC86"
        strokeOpacity="0.3"
        strokeWidth="4"
      />
      <rect
        x="23.0742"
        y="41.748"
        width="25"
        height="4"
        rx="2"
        transform="rotate(45 23.0742 41.748)"
        fill="#A5DC86"
      />
      <rect
        x="35.4688"
        y="59.2051"
        width="47"
        height="4"
        rx="2"
        transform="rotate(-45 35.4688 59.2051)"
        fill="#A5DC86"
      />
    </svg>
  );
};
