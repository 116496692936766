import { Layout } from 'antd';

import { WaterUsePlan } from '@features/waterUsePlan';

export const WaterUsePlanLayout: React.FC = () => {
  return (
    <Layout>
      <WaterUsePlan />
    </Layout>
  );
};
