import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Table, TableProps } from 'antd';

import { PAGINATION_MIN_RECORDS_AMOUNT } from '@constants/pagination';
import { JsonRecord } from '@models/jsonRecord';
import { PaginationWrapper } from '@models/pagination/interfaces/pagination';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps } from '@models/table/interfaces/tableCustomActionProps';
import { FieldData } from 'rc-field-form/es/interface';

import { EditableCell } from './cell';
import { dataTreeFind } from './dataTreeFind';
import { dataTreeReplace } from './dataTreeReplace';
import { filterCutomTableColumns } from './filterCutomTableColumns';
import { getKeyValue } from './getKeyValue';
import { mapCutomTableColumns } from './mapCutomTableColumns';
import styles from './TableCustom.module.scss';

type GetCellKey<RecordType> = (record: RecordType, index?: number) => React.Key;

interface TableCustomV2Props<RecordType> extends TableProps<RecordType> {
  columns: TableColumn[];
  columnsOrder?: string[];
  totalCount?: number;
  customPagination?: Partial<PaginationWrapper<RecordType>>;
  highlightedRowKeys?: (React.Key | undefined)[];
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
  actionProps?: TableCustomActionProps<RecordType>;
}

/**
 * @deprecated
 */
export function TableCustomV2<RecordType extends JsonRecord>({
  columns,
  columnsOrder,
  dataSource = [],
  onChange,
  highlightedRowKeys = [],
  rowClassName,
  rowKey,
  cellKey,
  totalCount,
  customPagination,
  pagination,
  actionProps: {
    editingRowKey: editingRowKeyParent,
    editingCellKey: editingCellKeyParent,
    getEditingRow: getEditingRowParent,
    getEditingCell: getEditingCellParent,
    onEditRowClick: onEditClickParent,
    onEditCellClick: onEditCellClickParent,
    onEditSaveClick: onEditSaveClickParent,
    onEditCancelClick: onEditCancelClickParent,
    ...otherActionProps
  } = {},
  ...other
}: TableCustomV2Props<RecordType>) {
  const [form] = Form.useForm();
  const [data, setData] = useState<RecordType[]>([]);
  const { t } = useTranslation();
  const [editingRowKey, setEditingRowKey] = useState<React.Key | null>(
    editingRowKeyParent || null,
  );
  const [editingCellKey, setEditingCellKey] = useState<React.Key | null>(
    editingCellKeyParent || null,
  );
  const [isFormValid, setFormValid] = useState(false);
  const isPaginationDisplayed = useMemo(
    () => totalCount && totalCount > PAGINATION_MIN_RECORDS_AMOUNT,
    [totalCount],
  );

  useEffect(() => {
    setData([...dataSource]);
  }, [dataSource]);

  const getEditingRow = useCallback(
    (record: RecordType): boolean => {
      const keyValue = getKeyValue(record, rowKey);
      if (getEditingRowParent) {
        return getEditingRowParent(record);
      }

      return keyValue === editingRowKey;
    },
    [editingRowKey, getEditingRowParent],
  );

  const getEditingCell = useCallback(
    (record: RecordType, column: TableColumn): boolean => {
      const currentCell = record[column.dataIndex];
      const keyValue = getKeyValue(currentCell, cellKey);

      if (getEditingCellParent) {
        return getEditingCellParent(record, column);
      }

      const cellKeyValue = `${keyValue}_${record.id}`;
      return cellKeyValue === editingCellKey;
    },
    [editingCellKey, getEditingCellParent],
  );

  const onEditRowClick = useCallback(
    (record: RecordType) => {
      form.setFieldsValue({ ...record });

      const keyValue = getKeyValue(record, rowKey);

      setEditingRowKey(keyValue);
      onEditClickParent?.(record);
    },
    [rowKey],
  );

  const onEditCellClick = useCallback(
    (record: RecordType, column: TableColumn) => {
      form.setFieldsValue({ ...record });

      const currentCell = record[column.dataIndex];
      const keyValue = getKeyValue(currentCell, cellKey);
      const cellKeyValue = `${keyValue}_${record.id}`;

      setEditingCellKey(cellKeyValue);
      onEditCellClickParent?.(record, column);
    },
    [cellKey],
  );

  const onEditCancelClick = useCallback(() => {
    setEditingRowKey(null);
    onEditCancelClickParent?.();
  }, []);

  const onEditSaveClick = async (record: RecordType) => {
    try {
      const keyValue = getKeyValue(record, rowKey);
      const row = (await form.validateFields()) as RecordType;

      const targetItem = dataTreeFind({
        children: data,
        keyValue,
        rowKey,
      });

      if (targetItem) {
        const newItem = {
          ...targetItem,
          ...row,
        };

        const newData = dataTreeReplace({
          children: data,
          keyValue,
          newItem,
          rowKey,
        });
        setData(newData);
        setEditingRowKey(null);
        onEditSaveClickParent?.(newItem);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onFieldsChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      const isValid = allFields.reduce((prev, field) => {
        return prev && !(field.value === undefined || !!field.errors?.length);
      }, true);

      setFormValid(isValid);
    },
    [setFormValid],
  );

  const canEditCell = useCallback((record: RecordType) => record.disalbed, []);

  const mapedColumns = mapCutomTableColumns({
    customColumns: filterCutomTableColumns({
      customColumns: columns,
      columnsOrder,
    }),
    actionProps: {
      editingRowKey,
      getEditingRow,
      onEditCancelClick,
      onEditRowClick,
      onEditSaveClick,
      isFormValid,
      canEditCell,

      editingCellKey,
      getEditingCell,
      onEditCellClick,
      ...otherActionProps,
    },
  });

  const handleTableChange: TableProps<any>['onChange'] = (
    newPagination,
    newFilters,
    newSorter,
    extra,
  ) => {
    if (onChange) {
      onChange(newPagination, newFilters, newSorter, extra);
    }
  };
  return (
    <Form
      form={form}
      onFieldsChange={onFieldsChange}
      className={styles.tableForm}
      style={{ width: '100%' }}
    >
      <Table<RecordType>
        className={styles.table}
        {...other}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mapedColumns}
        dataSource={data}
        onChange={handleTableChange}
        rowKey={rowKey}
        rowClassName={styles.rowClassName}
        pagination={
          isPaginationDisplayed && customPagination
            ? {
                total: totalCount,
                current: customPagination?.number || 1,
                pageSize: customPagination?.size || 10,
                showSizeChanger: false,
                showTotal: () => {
                  if (customPagination?.totalPages && customPagination?.size) {
                    return `Страница ${
                      customPagination?.number || 1
                    } из ${Math.ceil(customPagination.totalPages)}`;
                  }
                  return '';
                },
              }
            : false
        }
      />
    </Form>
  );
}
