import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Col, Pagination, Row, Space, Spin } from 'antd';

import { Routes } from '@enums/routes';
import { CustomGanttHeader } from '@features/ui-kit/customGanttChart/CustomGanttHeader/CustomGanttHeader';
import { CustomGanttHeaderRows } from '@features/ui-kit/customGanttChart/CustomGanttHeaderRows';
import { CustomGanttSidebar } from '@features/ui-kit/customGanttChart/CustomGanttSidebar';
import { useGetColumnWidth } from '@features/ui-kit/customGanttChart/hooks/useGetColumnWidth';
import { useGetTableData } from '@features/ui-kit/customGanttChart/hooks/useGetTableData';
import { TooltipContent } from '@features/ui-kit/customGanttChart/tooltipContent/TooltipContent';
import { CustomCard, DefaultButtonV2 } from '@features/ui-kit-v2';
import { useGetEventWorkGanttMutation } from '@store/gisproApi';
import { getCurrentGanttOptionsSelector } from '@store/selectors';
import { saveGanttOptions } from '@store/slices';
import { Gantt, Task, ViewMode } from 'gantt-task-react';

import styles from './CustomGanttChart.module.scss';

import 'gantt-task-react/dist/index.css';

interface IHeaderOptions {
  range?: ViewMode;
  taskList?: boolean;
}

export const CustomGanttChart: React.FC = () => {
  const dispatch = useDispatch();
  const [getGanttData, { data: response, isLoading }] =
    useGetEventWorkGanttMutation();
  const ganttOptions = useSelector(getCurrentGanttOptionsSelector);
  const [headerOptions, setHeaderOptions] = useState<IHeaderOptions>({});

  const { meta } = response || {};
  const [data, setData] = useState<Array<Task>>([]);

  useEffect(() => {
    if (!isLoading) {
      getGanttData(ganttOptions);
    }
  }, [ganttOptions]);

  useEffect(() => {
    if (response?.data) {
      setData(useGetTableData(response?.data));
    }
  }, [response?.data, headerOptions]);

  return (
    <Space className={styles.container} direction="vertical" size="small">
      <CustomCard>
        <Col className={styles.customGanttTable} span={24}>
          <div className={styles.ganttTableTabs}>
            <CustomGanttSidebar setHeaderOptions={setHeaderOptions} />

            {!isLoading ? (
              data.length !== 0 && (
                <Gantt
                  tasks={data}
                  locale="ru"
                  columnWidth={155}
                  viewMode={headerOptions.range}
                  TaskListHeader={CustomGanttHeader}
                  TaskListTable={CustomGanttHeaderRows}
                  barCornerRadius={10}
                  TooltipContent={TooltipContent}
                />
              )
            ) : (
              <Spin />
            )}
          </div>
        </Col>
        <Pagination
          current={meta?.page}
          total={meta?.totalCount}
          onChange={(activePage) =>
            dispatch(saveGanttOptions({ ...ganttOptions, page: activePage }))
          }
          showTotal={() =>
            meta
              ? `Страница ${meta.page} из ${Math.ceil(
                  meta.totalCount / meta.limit,
                )} `
              : ''
          }
          pageSize={meta ? meta?.limit : 3}
          showSizeChanger={false}
        />
      </CustomCard>
      <Link to={Routes.ManageEvents}>
        <DefaultButtonV2 title="Назад" />
      </Link>
    </Space>
  );
};
