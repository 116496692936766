import { useMemo } from 'react';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';
import { get } from '@utils/utils';

import { Divider } from '../../../divider';

export const renderWaterAccountingValues = <RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) => {
  const morningValue = useMemo(
    () => get(record, `${customColumn.dataIndex}Morning`),
    [record],
  );
  const eveningValue = useMemo(
    () => get(record, `${customColumn.dataIndex}Evening`),
    [record],
  );
  if (customColumn.dataIndex.includes('time')) {
    return (
      <div>
        <div>{morningValue ? dayjs(morningValue).format('HH:mm') : '-'}</div>
        <Divider style={{ margin: '7px 0' }} />
        <div>{eveningValue ? dayjs(eveningValue).format('HH:mm') : '-'}</div>
      </div>
    );
  }

  return (
    <div>
      <div>{morningValue ?? '-'}</div>
      <Divider style={{ margin: '7px 0' }} />
      <div>{eveningValue ?? '-'}</div>
    </div>
  );
};
