import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const NavCardIcon: React.FC<IconProps> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.75082 1.575C4.5237 2.0954 4.40684 2.6572 4.40762 3.225C4.40762 3.66261 4.58146 4.08229 4.89089 4.39173C5.20033 4.70116 5.62001 4.875 6.05762 4.875H11.0076C11.4452 4.875 11.8649 4.70116 12.1743 4.39173C12.4838 4.08229 12.6576 3.66261 12.6576 3.225C12.6576 2.63843 12.5355 2.0799 12.3144 1.575H13.4826C13.9202 1.575 14.3399 1.74884 14.6493 2.05828C14.9588 2.36771 15.1326 2.7874 15.1326 3.225V15.6C15.1326 16.0376 14.9588 16.4573 14.6493 16.7667C14.3399 17.0762 13.9202 17.25 13.4826 17.25H3.58262C3.14501 17.25 2.72533 17.0762 2.41589 16.7667C2.10646 16.4573 1.93262 16.0376 1.93262 15.6V3.225C1.93262 2.7874 2.10646 2.36771 2.41589 2.05828C2.72533 1.74884 3.14501 1.575 3.58262 1.575H4.75082ZM8.53262 10.65H6.05762C5.83881 10.65 5.62897 10.7369 5.47425 10.8916C5.31954 11.0464 5.23262 11.2562 5.23262 11.475C5.23262 11.6938 5.31954 11.9036 5.47425 12.0584C5.62897 12.2131 5.83881 12.3 6.05762 12.3H8.53262C8.75142 12.3 8.96126 12.2131 9.11598 12.0584C9.2707 11.9036 9.35762 11.6938 9.35762 11.475C9.35762 11.2562 9.2707 11.0464 9.11598 10.8916C8.96126 10.7369 8.75142 10.65 8.53262 10.65ZM11.0076 7.35H6.05762C5.84734 7.35024 5.64509 7.43075 5.49219 7.5751C5.33929 7.71945 5.24727 7.91674 5.23495 8.12665C5.22263 8.33657 5.29092 8.54327 5.42589 8.70451C5.56085 8.86576 5.75229 8.96939 5.96109 8.99423L6.05762 9H11.0076C11.2264 9 11.4363 8.91308 11.591 8.75837C11.7457 8.60365 11.8326 8.39381 11.8326 8.175C11.8326 7.9562 11.7457 7.74636 11.591 7.59164C11.4363 7.43692 11.2264 7.35 11.0076 7.35ZM8.53262 0.750003C8.88092 0.749489 9.22537 0.822741 9.54332 0.964937C9.86127 1.10713 10.1455 1.31505 10.3773 1.575C10.7304 1.96935 10.9581 2.47673 11.0002 3.0369L11.0076 3.225H6.05762C6.05762 2.62688 6.26964 2.07825 6.62274 1.6509L6.68792 1.575C7.14167 1.06845 7.80002 0.750003 8.53262 0.750003Z"
            fill="#16ABFF" />
    </svg>
  );
};
