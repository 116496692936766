import { monthsTranslate } from '@constants/month';
import { UserRoles } from '@enums/roles';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

const seasonWatering = [
  {
    seasonName: 'Ранне-весенние влагозарядковые поливы',
    months: ['Февраль', 'Март', 'Апрель', 'Май'],
  },
  {
    seasonName: 'Осенние-зимние влагозарядковые поливы',
    months: ['Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  },
];

export const useWaterChargingIrrigationPlanHectareSupplyTableColumns = (
  userRole: UserRoles = UserRoles.ROLE_RUVH,
  canEdit: boolean = false,
) => {
  const isShowRole =
    userRole === UserRoles.ROLE_CA || userRole === UserRoles.ROLE_GUVH;

  const columns: TableColumn[] = [
    {
      dataIndex: 'systemName',
      title: 'Наименование системы',
      xtype: CustomTableColumnType.String,
      align: 'center',
      width: 150,
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      dataIndex: 'indicator',
      title: 'Показатели',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.indicatorType === 'cumulativeTotal' ? 'нар' : 'дек'}
          </div>
        );
      },
    },
    {
      dataIndex: 'indicator',
      title: 'Ед. изм.',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: () => {
        return <div>тыс.га</div>;
      },
    },
    ...seasonWatering.map((season): TableColumn => {
      return {
        dataIndex: season.seasonName,
        title: season.seasonName,
        xtype: CustomTableColumnType.String,
        align: 'center',
        children: season.months.map((month, index, arr): TableColumn => {
          return {
            dataIndex: month,
            title: month,
            xtype: CustomTableColumnType.String,
            align: 'center',
            children: [
              {
                dataIndex: 'I',
                title: 'I',
                align: 'center',
                width: 60,
                xtype: CustomTableColumnType.String,

                children: [
                  {
                    dataIndex: 'volume',
                    title: 'Гектарополивы',
                    dynamicIndex: (record) => {
                      if (record.indicatorType === 'cumulativeTotal') {
                        return 'cumulativeTotal';
                      } else if (record.indicatorType === 'volumeOfExpenses') {
                        return 'volumeOfExpenses';
                      }
                      return 'volume';
                    },
                    decimalIndex: [
                      'planItemDtos',
                      `${monthsTranslate[month]}-1`,
                    ],
                    align: 'center',
                    xtype: CustomTableColumnType.DecimalEdit,
                    onHeaderCell: () => {
                      const colSpan =
                        index === 0 &&
                        season.seasonName ===
                          'Ранне-весенние влагозарядковые поливы'
                          ? 24
                          : 0;
                      return {
                        colSpan,
                      };
                    },
                    editableDecimalCell: (record) => {
                      if (!canEdit) {
                        return false;
                      }
                      if (isShowRole) {
                        return false;
                      }
                      if (record.systemName?.includes('Итого')) {
                        return false;
                      }
                      return record.indicatorType === 'volume';
                    },
                  },
                ],
              },
              {
                dataIndex: 'II',
                title: 'II',
                align: 'center',
                width: 60,
                xtype: CustomTableColumnType.String,
                children: [
                  {
                    dataIndex: 'volume',
                    title: 'Водоподача',
                    dynamicIndex: (record) => {
                      if (record.indicatorType === 'cumulativeTotal') {
                        return 'cumulativeTotal';
                      } else if (record.indicatorType === 'volumeOfExpenses') {
                        return 'volumeOfExpenses';
                      }
                      return 'volume';
                    },
                    onHeaderCell: () => {
                      return {
                        colSpan: 0,
                      };
                    },
                    decimalIndex: [
                      'planItemDtos',
                      `${monthsTranslate[month]}-2`,
                    ],
                    editableDecimalCell: (record) => {
                      if (!canEdit) {
                        return false;
                      }
                      if (isShowRole) {
                        return false;
                      }
                      if (record.systemName?.includes('Итого')) {
                        return false;
                      }

                      return record.indicatorType === 'volume';
                    },

                    align: 'center',
                    width: 60,
                    xtype: CustomTableColumnType.DecimalEdit,
                  },
                ],
              },
              {
                dataIndex: 'III',
                title: 'III',
                align: 'center',
                width: 60,
                xtype: CustomTableColumnType.String,
                children: [
                  {
                    dataIndex: 'volume',
                    dynamicIndex: (record) => {
                      if (record.indicatorType === 'cumulativeTotal') {
                        return 'cumulativeTotal';
                      } else if (record.indicatorType === 'volumeOfExpenses') {
                        return 'volumeOfExpenses';
                      }
                      return 'volume';
                    },
                    decimalIndex: [
                      'planItemDtos',
                      `${monthsTranslate[month]}-3`,
                    ],
                    title: 'Водоподача',
                    onHeaderCell: () => {
                      return {
                        colSpan: 0,
                      };
                    },
                    align: 'center',
                    width: 60,
                    editableDecimalCell: (record) => {
                      if (!canEdit) {
                        return false;
                      }
                      if (isShowRole) {
                        return false;
                      }
                      if (record.systemName?.includes('Итого')) {
                        return false;
                      }

                      return record.indicatorType === 'volume';
                    },
                    xtype: CustomTableColumnType.DecimalEdit,
                  },
                ],
              },
            ],
          };
        }),
      };
    }),
  ];
  if (isShowRole) {
    columns.unshift({
      title: 'Наименование района',
      dataIndex: 'districtName',
      xtype: CustomTableColumnType.String,
      key: 'districtName',
      align: 'left',
      width: 150,
      onCell: (record, rowIndex) => {
        if (record?.districtRowIndex) {
          if (record.districtRowIndex?.start === rowIndex) {
            return { rowSpan: record.districtRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    });
  }
  return { columns };
};
