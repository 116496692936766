import { Button, ButtonProps, Col, Row, Space } from 'antd';

import styles from './NavigateButton.module.scss';
import { SmallArrowIcon } from '@icons/SmallArrowIcon';
import Icon from '@ant-design/icons';

type NavigateButtonV2PropTypes = ButtonProps & {
  title: string;
};

export const NavigateButton: React.FC<NavigateButtonV2PropTypes> = ({
  title,
  icon,
  ...rest
}) => (
  <Button className={styles.navButton} {...rest} icon={null} >
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Col>
        <Space size={8}>
          <Icon component={icon as any}/>
          {title}
        </Space>
      </Col>
      <Col>
        <Icon style={{ float: 'left' }} component={SmallArrowIcon}/>
      </Col>
    </Row>
  </Button>
);
