import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Layout, Tabs } from 'antd';

import { HomeOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider, InformationalBlock } from '@features/ui-kit';
import { BalanceSheetAccountingSystem } from '@pages/sppr/components/balanceSheetAccountingSystem';
import { IrrigationFundConstructionMonitoring } from '@pages/sppr/components/irrigationFundConstruction/IrrigationFundConstructionMonitoring';
import { IrrigationFundOperationalBlockMonitoring } from '@pages/sppr/components/irrigationFundOperationalBlock/IrrigationFundOperationalBlockMonitoring';

import {
  BalanceSheetAccounting,
  CommonSystemUnit,
  IrrigationFundConstruction,
  IrrigationFundMainData,
  IrrigationFundOperationalBlock,
  QualitativeIndicators,
  Reservoirs,
  SpprMenu,
  TariffMonitoring,
  WaterOutletAgreementRepublic,
  WaterOutletAgreementSystem,
  WaterOutletMainData,
  WaterOutletSovingFact,
} from './components';
import { SpprFilters } from './components/spprFilters/SpprFilters';
import { SEARCH_PARAMS_KEY, TAB_PARAMS_KEY } from './constants';
import { SpprMenuKey } from './types/spprTypes';

interface ITabItems {
  label: string;
  key: string;
}

const defaultTabItems = [
  { label: 'Диаграмма', key: 'diagram' },
  { label: 'Таблица', key: 'table' },
];

const tariffMonitoringTabItems = [
  { label: 'РУВХ', key: 'ruvh' },
  { label: 'Ассоциации', key: 'associations' },
];

export const SpprPage: FC = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const [modalIsOpen, setModalIsOpen] = useState(true);

  useEffect(() => {
    setHeaderTitle(t('headers.sppr'));
  }, []);

  const currentType = params.get(SEARCH_PARAMS_KEY);

  const onTabChange = (key: string) => {
    params.delete(TAB_PARAMS_KEY);
    params.append(TAB_PARAMS_KEY, key);
    setParams(params);
  };

  let Component;
  let tabItems: ITabItems[] = [];
  let filterConfig = {};
  let informationBlockTitle;
  switch (currentType) {
    case SpprMenuKey.map:
      navigate(Routes.geoPortalTable);
      break;
    case SpprMenuKey.commonSystemUnit:
      informationBlockTitle = 'Общий системный блок';
      Component = CommonSystemUnit;
      break;
    case SpprMenuKey.waterOutletMainData:
      informationBlockTitle = 'Водопользование: Общие данные';
      Component = WaterOutletMainData;
      break;
    case SpprMenuKey.waterOutletAgreementRepublic:
      informationBlockTitle =
        'Водопользование: Хоз. Договорная водоподача Республика';
      filterConfig = { year: true, republic: true, district: true, area: true };
      tabItems = defaultTabItems;
      Component = WaterOutletAgreementRepublic;
      break;
    case SpprMenuKey.waterOutletAgreementSystem:
      informationBlockTitle =
        'Водопользование: Хоз. Договорная водоподача системы';
      filterConfig = { year: true, district: true, area: true };
      Component = WaterOutletAgreementSystem;
      tabItems = defaultTabItems;
      break;
    case SpprMenuKey.waterOutletSovingFact:
      informationBlockTitle = 'Водопользование: Посевная факт/план';
      Component = WaterOutletSovingFact;
      filterConfig = { republic: true, district: true, area: true };
      // tabItems = defaultTabItems;
      break;
    // case SpprMenuKey.waterOutletSovingPlan:
    //   informationBlockTitle = 'Водопользование: Посевная план';
    //   Component = WaterOutletSovingPlan;
    //   filterConfig = { republic: true, district: true, area: true };
    //   tabItems = defaultTabItems;
    //   break;

    case SpprMenuKey.irrigationFundMainData:
      informationBlockTitle = 'Ирригационный фонд: Общие данные';
      Component = IrrigationFundMainData;
      filterConfig = { republic: true, district: true, area: true };
      break;
    case SpprMenuKey.irrigationFundOperationalBlockMonitoring:
      informationBlockTitle =
        'Ирригационный фонд: Мониторинг Эксплуатационный блок';
      Component = IrrigationFundOperationalBlockMonitoring;
      filterConfig = { republic: true, district: true, area: true };
      break;
    case SpprMenuKey.irrigationFundOperationalBlock:
      informationBlockTitle = 'Ирригационный фонд: Эксплуатационный блок';
      Component = IrrigationFundOperationalBlock;
      filterConfig = { republic: true, district: true, area: true };
      break;
    case SpprMenuKey.irrigationFundConstructionMonitoring:
      informationBlockTitle = 'Ирригационный фонд: Мониторинг Строительство';
      Component = IrrigationFundConstructionMonitoring;
      filterConfig = { republic: true, district: true, area: true };
      break;
    case SpprMenuKey.irrigationFundConstruction:
      informationBlockTitle = 'Ирригационный фонд: Строительство';
      Component = IrrigationFundConstruction;
      filterConfig = { republic: true, district: true, area: true };
      break;

    case SpprMenuKey.qualitativeIndicators:
      informationBlockTitle = 'Качественные показатели';
      Component = QualitativeIndicators;
      break;
    case SpprMenuKey.qualitativeIndicatorsGroundwaterMineralization:
      navigate(
        generatePath(Routes.geoPortalMap, {
          filterKey: 'mineralization',
        }),
      );
      break;
    case SpprMenuKey.qualitativeIndicatorsGroundwaterDepth:
      navigate(
        generatePath(Routes.geoPortalMap, {
          filterKey: 'depth',
        }),
      );
      break;
    case SpprMenuKey.qualitativeIndicatorsReclamationState:
      navigate(
        generatePath(Routes.geoPortalMap, {
          filterKey: 'melioration',
        }),
      );
      break;

    case SpprMenuKey.reservoirs:
      informationBlockTitle = 'Водохранилища';
      Component = Reservoirs;
      filterConfig = {
        year: true,
        district: true,
        area: true,
        reservoirs: true,
      };
      break;
    case SpprMenuKey.tariffMonitoring:
      informationBlockTitle = 'Мониторинг тарифов';
      Component = TariffMonitoring;
      filterConfig = { district: true, area: true };
      tabItems = tariffMonitoringTabItems;
      break;
    case SpprMenuKey.balanceSheetAccounting:
      informationBlockTitle = 'Балансовый водоучет';
      Component = BalanceSheetAccounting;
      filterConfig = {
        republic: true,
        year: true,
        district: true,
        area: true,
        irrigationSystem: true,
      };
      break;
    case SpprMenuKey.balanceSheetAccountingSystem:
      informationBlockTitle = 'Балансовый учет (по системам)';
      Component = BalanceSheetAccountingSystem;
      filterConfig = {
        republic: true,
        year: true,
        district: true,
        area: true,
        irrigationSystem: true,
      };
      break;
    default:
      informationBlockTitle = '';
      Component = null;
      break;
  }

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'План водопользования',
          },
          {
            title: 'Просмотр',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      {Boolean(tabItems.length) && (
        <Tabs items={tabItems} onChange={onTabChange} />
      )}
      <SpprFilters {...filterConfig} openModal={() => setModalIsOpen(true)} />

      <Divider />
      <InformationalBlock title={informationBlockTitle}>
        {Component && <Component />}
      </InformationalBlock>

      <SpprMenu open={modalIsOpen} onCancel={() => setModalIsOpen(false)} />
    </Layout>
  );
};
