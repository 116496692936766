import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Col,
  Divider,
  Empty,
  Form,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { DocumentTypes } from '@enums/documentTypes';
import { useAttachDocumentWithoutNotationModal } from '@features/manageEvents/useAttachDocumentWithoutNotationModal';
import {
  BudgetItem,
  CustomCard,
  CustomCarousel,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedTextarea,
  UploadBtn,
  UploadButtonSmall,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateCloseReportBuildingBlockMutation,
  useFindConstructionBlockByIdQuery,
  useSaveCloseReportBuildingBlockMutation,
} from '@store/gisproApi';
import { getBuildingBlockSelector } from '@store/selectors';
import { addDocumentToBuildingBlockAction } from '@store/slices';
import {
  validateFactEndDate,
  validateFactStartDate,
} from '@utils/validation/deadlineValidation';
import dayjs from 'dayjs';
import { clone } from 'lodash'; // TODO: change lodash to native implementation
import { isInteger, toInteger } from '@utils/utils';

import styles from './BuildingCard.module.scss';

export const CloseBuildingReportCard: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: buildingBlock, refetch } = useFindConstructionBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );
  const buildingBlockState = useSelector(getBuildingBlockSelector);
  const { setShow: setAttachmentDocumnetShow, setDocType } =
    useAttachDocumentWithoutNotationModal();
  const [closeReport] = useCreateCloseReportBuildingBlockMutation();
  const [saveBuildingBlockClosingReport] =
    useSaveCloseReportBuildingBlockMutation();

  const closeReportHandle = () => {
    if (buildingBlock?.id) {
      closeReport(buildingBlock.id)
        .unwrap()
        .then(() => {
          refetch();
          notification.success({
            message: 'Вы закрыли отчет',
          });
        })
        .catch((err) => {
          console.log('err is ', err);
          notification.error({
            message: 'Не удалось закрыть отчет',
          });
        });
    }
  };

  const attachments = buildingBlockState.attachments?.filter(
    (att) => att.type === DocumentTypes.Act,
  );

  const onFinish = (values: any) => {
    saveBuildingBlockClosingReport({
      id: buildingBlock?.id,
      ...values,
      factBudget: toInteger(values.factBudget),
      factStartDate: dayjs(values.factStartDate).utc().format('YYYY-MM-DD'),
      factEndDate: dayjs(values.factEndDate).utc().format('YYYY-MM-DD'),
      attachments: attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
    })
      .then(({ data }: any) => {
        refetch();
        notification.success({
          message: 'Вы успешно сохранили отчет',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Не удалось сохранить отчет',
        });
      });
  };

  const isEditingDisabled = useMemo(
    () =>
      buildingBlock?.status !== TaskStatusEnum.onRealization &&
      buildingBlock?.status !== TaskStatusEnum.onControl,
    [buildingBlock],
  );

  const onAddAttachment = useCallback((docType: string) => {
    setDocType(docType);
    setAttachmentDocumnetShow(true);
  }, []);

  const attachPhoto = (file: IAttachment) => {
    dispatch(addDocumentToBuildingBlockAction(file));
  };
  const actionsPhoto = buildingBlock?.attachments
    ?.filter((item: any) => item.type === 'фото-после')
    .map((item: any) => item.filePath);

  const buildAttachments = !buildingBlock?.attachments.some(
    (att: any) => att.type === DocumentTypes.Act,
  )
    ? buildingBlockState?.attachments
    : buildingBlock?.attachments;

  const validateAttachments = useMemo(() => {
    const hasAct = buildAttachments?.some(
      (att: any) => att.type === DocumentTypes.Act,
    );
    return hasAct;
  }, [buildAttachments]);

  const isSaveDisabled = useMemo(
    () => buildingBlock?.status !== TaskStatusEnum.onControl,
    [buildingBlock],
  );

  const closingReportDate = dayjs
    .utc(buildingBlock?.closingReportDate)
    .local()
    .format('HH:mm DD.MM.YYYY');

  useEffect(() => {
    if (buildingBlock) {
      const dataClone = clone(buildingBlock);
      if (dataClone.factStartDate) {
        dataClone.factStartDate = dayjs(dataClone.factStartDate);
      }
      if (dataClone.factEndDate) {
        dataClone.factEndDate = dayjs(dataClone.factEndDate);
      }
      form.setFieldsValue(dataClone);
    }
  }, [buildingBlock]);

  const isDisabledCloseReport = useMemo(() => {
    if (buildingBlock?.status === TaskStatusEnum.onControl) {
      return !buildingBlock?.closingReportDate;
    }
    return buildingBlock?.status !== TaskStatusEnum.onControl;
  }, [buildingBlock]);

  return (
    <CustomCard>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className={styles.reportBlock}
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4}>Закрытие отчета</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton
                  disabled={isSaveDisabled || !validateAttachments}
                  htmlType="submit"
                >
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  onClick={closeReportHandle}
                  disabled={isDisabledCloseReport}
                >
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item
          name="closingReportDescription"
          label="Краткое описание"
          required
          rules={[{ required: true }]}
        >
          <TooltipedTextarea
            className=""
            placeholder="Краткое описание"
            autoSize={{ minRows: 4, maxRows: 10 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>

        <Form.Item
          name="factBudget"
          required
          rules={[
            { required: true, message: 'Пожалуйста введите Итого затрачено' },
          ]}
        >
          <BudgetItem label="Итого затрачено*" disabled={isEditingDisabled} />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.Act)}
              disabled={isEditingDisabled}
            >
              Прикрепить акт выполненных работ*
            </UploadButtonSmall>
          </Col>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.PhotoAfter)}
              disabled={isEditingDisabled}
            >
              Прикрепить Фото (После)
            </UploadButtonSmall>
          </Col>
          <Col span={8}>
            <UploadButtonSmall
              onClick={() => onAddAttachment(DocumentTypes.Document)}
              disabled={isEditingDisabled}
            >
              Прикрепить ведомость дефекта*
            </UploadButtonSmall>
          </Col>
        </Row>

        <Space direction="vertical" size={20} className={styles.generalStep}>
          <Typography.Text className={styles.title}>
            Заявленные фактические сроки реализации
          </Typography.Text>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validateFactStartDate({
                        _,
                        value,
                        form,
                        type: 'factEndDate',
                      }),
                  },
                ]}
                name="factStartDate"
                required
                label="Начало"
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Начало"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validateFactEndDate({
                        _,
                        value,
                        form,
                        type: 'factStartDate',
                      }),
                  },
                ]}
                name="factEndDate"
                required
                label="Конец"
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Конец"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Row />
          </Row>
        </Space>
        <Form.Item
          name="closingReportObjectDesc"
          label="Блок корректировки данных объектов"
          required
          rules={[{ required: true }]}
        >
          <TooltipedTextarea
            className=""
            placeholder="Блок корректировки данных объектов"
            autoSize={{ minRows: 4, maxRows: 10 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <div className={styles.dueDateBlock}>
          Дата сдачи отчета :
          <Divider className={styles.dueDateBlock__divider} />
          <div className={styles.dueDateBlock__status}>
            {buildingBlock?.closingReportDate
              ? ` ${closingReportDate}`
              : ' Еще не закрыли отчет'}
          </div>
        </div>
      </Form>
    </CustomCard>
  );
};
