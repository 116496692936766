import React from 'react';

import { Layout } from 'antd';

import { WaterAccounting } from '@features/waterAccounting/WaterAccounting';

export const WaterAccountingLayout: React.FC = () => {
  return (
    <Layout>
      <WaterAccounting />
    </Layout>
  );
};
