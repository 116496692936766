import { useDispatch, useSelector } from 'react-redux';

import { IImplementationTerm } from '@models/manageEvents/interfaces/operationalBlock';
import {
  getIsShowSubmitImplementationTermModalShownSelector,
  getSelectedImplementationTermSelector,
} from '@store/selectors';
import {
  setSelectedImplementationTermAction,
  setSubmitImplementationTermModalShownAction,
} from '@store/slices';

type ReturnType = {
  isShow: boolean;
  setShow: (value: boolean) => void;
  selectedImplementationTerm: IImplementationTerm | null;
  setSelectedImplementationTerm: (value: IImplementationTerm | null) => void;
};

export const useSubmitImplementationTermModal = (): ReturnType => {
  const dispatch = useDispatch();

  const isShow = useSelector(
    getIsShowSubmitImplementationTermModalShownSelector,
  );
  const selectedImplementationTerm = useSelector(
    getSelectedImplementationTermSelector,
  );

  const setShow = (value: boolean) => {
    dispatch(setSubmitImplementationTermModalShownAction(value));
  };

  const setSelectedImplementationTerm = (value: IImplementationTerm | null) => {
    dispatch(setSelectedImplementationTermAction(value));
  };

  return {
    setShow,
    isShow,
    selectedImplementationTerm,
    setSelectedImplementationTerm,
  };
};
