import {
  GeoAttribute,
  ILayerDto,
} from '@models/geoPortal/interfaces/GeoPortal';
import { JsonRecord } from '@models/jsonRecord';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoControllerGetPortalMapsApiArg } from '@store/api/geoPortalApi';

interface Layer {
  id: string;
  data: ILayerDto | JsonRecord;
  geoJson: any;
  show?: boolean;
}

export interface GeoPortalState {
  selectedLayer: ILayerDto | null;
  selectedMapKey: string | null;
  selectedLayers: any[];
  allLayers: any[];
  currentLayer: Layer;
  currentFeatureId: string | string[];
  currentWaterObject: {
    id: string;
    type: 'wo' | 'wu' | 'fa';
  } | null;
  geoPortalFilters: GeoControllerGetPortalMapsApiArg;
  mapName: string;
  currentAttribute: GeoAttribute | null;
  monitoringTab: boolean;
}

const initialState: GeoPortalState = {
  selectedLayer: null,
  selectedMapKey: null,
  selectedLayers: [],
  allLayers: [],
  currentLayer: { id: '', data: {}, geoJson: {}, show: false },
  currentFeatureId: '',
  currentWaterObject: null,
  geoPortalFilters: {
    groupKey: '',
    page: 1,
    size: 9,
  },
  mapName: '',
  currentAttribute: null,
  monitoringTab: location.pathname.includes('map:monitoring'),
};
const geoPortalSlice = createSlice({
  name: 'geoPortal',
  initialState,
  reducers: {
    setSelectedLayerAction(state, action: PayloadAction<ILayerDto | null>) {
      return {
        ...state,
        selectedLayer: action.payload,
      };
    },
    setSelectedMapKey(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedMapKey: action.payload,
      };
    },
    setAllLayers(state, action: PayloadAction<Layer[]>) {
      return {
        ...state,
        allLayers: action.payload,
      };
    },
    setMapName(state, action: PayloadAction<string>) {
      return {
        ...state,
        mapName: action.payload,
      };
    },
    changeGeoportalFilters(
      state,
      action: PayloadAction<GeoControllerGetPortalMapsApiArg>,
    ) {
      return {
        ...state,
        geoPortalFilters: action.payload,
      };
    },
    addToLayers(state, action: PayloadAction<Layer>) {
      const newItem = action.payload;

      const existingIndex = state.selectedLayers.findIndex(
        (layer) => layer.id === newItem.id,
      );
      if (existingIndex !== -1) {
        state.selectedLayers[existingIndex] = newItem;
      } else {
        state.selectedLayers.push(newItem);
      }
    },
    removeFromLayers(state, action: PayloadAction<string>) {
      state.selectedLayers = state.selectedLayers.filter(
        (layer) => layer.id !== action.payload,
      );
    },
    setCurrentFeatureId(state, action: PayloadAction<string>) {
      state.currentFeatureId = action.payload;
    },
    setCurrentWaterObject(state, action: PayloadAction<any>) {
      state.currentWaterObject = action.payload;
    },
    setCurrentLayerData(state, action: PayloadAction<Omit<Layer, 'show'>>) {
      state.currentLayer = { ...action.payload };
    },
    setCurrentLayerGeoJson(state, action: PayloadAction<any>) {
      state.currentLayer.geoJson = action.payload;
    },
    updateCoordinatesForCurrentLayer(state, action: PayloadAction<any>) {
      const { id, coordinates } = action.payload;
      console.log('action.payload', action.payload);
      state.currentLayer.geoJson.find(
        (layer: any) => layer.id === id,
      ).geometry.coordinates = coordinates;
    },
    clearCurrentLayer(state) {
      state.currentLayer = { id: '', data: {}, geoJson: {}, show: false };
    },
    clearAllLayers(state) {
      state.selectedLayers = [];
    },

    changeMonitoringTab(state, action: PayloadAction<any>) {
      state.monitoringTab = action.payload;
    },
  },
});

export const {
  setSelectedLayerAction,
  addToLayers,
  removeFromLayers,
  clearAllLayers,
  setCurrentLayerData,
  setCurrentLayerGeoJson,
  setCurrentFeatureId,
  clearCurrentLayer,
  setCurrentWaterObject,
  changeGeoportalFilters,
  updateCoordinatesForCurrentLayer,
  setAllLayers,
  setMapName,
  changeMonitoringTab,
} = geoPortalSlice.actions;

export default geoPortalSlice.reducer;
