import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { StatusInfo } from '@features/manageEvents/components';
import {
  CalculatedProgress,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { IOperationalBlock } from '@models/manageEvents/interfaces/operationalBlock';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindOperationalBlockByIdQuery,
  useGetOperationalTemplatesQuery,
  useLazyGetOperationalTemplateByIdQuery,
} from '@store/gisproApi';
import { setOperationalBlockAction } from '@store/slices';
import { debounce, isInteger } from '@utils/utils';

import styles from './OperationalCard.module.scss';

export const HeaderOperationalCard: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: operationalBlock } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const onValuesChange = debounce(
    (values: Partial<IOperationalBlock>) => {
      dispatch(setOperationalBlockAction(values));
    },
    250,
    {
      maxWait: 1000,
    },
  );

  const { data: templates = [], isLoading: templateIsLoading } =
    useGetOperationalTemplatesQuery('');

  const [triggerTemplate, { data: template }] =
    useLazyGetOperationalTemplateByIdQuery();

  const setOperationalTemplate = async (value: string) => {
    triggerTemplate(value).then(({ data }: any) => {
      dispatch(setOperationalBlockAction({ ...data, value }));
      form.setFieldsValue({ ...data, id: data.taskId || null });
    });
  };

  const templateDisabled = !!operationalBlock?.id;

  useEffect(() => {
    if (operationalBlock) {
      form.setFieldsValue(operationalBlock);
    }
  }, [operationalBlock]);

  return (
    <Form<IOperationalBlock>
      layout="horizontal"
      onValuesChange={onValuesChange}
      className={styles.form}
      form={form}
    >
      <Row align="top" gutter={16} justify="space-between">
        <Col span={12}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item className={styles.formItem} name="taskId">
                <TooltipedInput
                  size="large"
                  className={styles.input}
                  placeholder="ID задачи"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={[{ required: true }]} name="title">
                <TooltipedInput
                  size="large"
                  placeholder="Наименование задачи"
                  disabled={!!operationalBlock?.id}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="templateId">
                <TooltipedSelect
                  size="large"
                  placeholder="Загрузить шаблон"
                  disabled={templateDisabled}
                  loading={templateIsLoading}
                  options={templates.map((item: any) => ({
                    label: item.templateName,
                    value: item.id,
                  }))}
                  onChange={setOperationalTemplate}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row gutter={16}>
            <Col span={16}>
              <CalculatedProgress
                separatePercentage
                tasksArray={
                  operationalBlock && operationalBlock?.implementationTerms
                }
              />
            </Col>
            <Col span={8}>
              <StatusInfo
                className={styles.status}
                status={operationalBlock?.status}
                planEndDate={operationalBlock?.planEndDate}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
