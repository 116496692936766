import React, { useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { quartalOptions } from '@constants/quartalOptions';
import { QuarterQueryKey } from '@constants/queryKeys';
import {
  CustomSegmented,
  PrimaryButtonV2,
  TableCustomV2,
} from '@features/ui-kit-v2';
import { useReclamationSoilDataTableColumns } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/ReclamationSoilDataTable/useReclamationSoilDataTableColumns';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import { JsonRecord } from '@models/jsonRecord';
import { WaterQualityMonitoringType } from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  CreateReclamationApiArg,
  GetZonesApiArg,
  useCreateReclamationStateMutation,
  useGetReclamationDataQuery,
  useGetZonesQuery,
} from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { ReclamationSoilDataFilters } from './ReclamationSoilDataFilters';
import styles from './ReclamationSoilDataTable.module.scss';
import { PrimaryButton, TableCustom } from '@features/ui-kit';

export const ReclamationSoilDataTable = () => {
  const { filters } = useEnteringDataTablesFilters();
  const [createReclamation] = useCreateReclamationStateMutation();
  const {
    data: content = [],
    isLoading,
    isFetching,
  } = useGetZonesQuery(
    isEmpty(filters)
      ? skipToken
      : ({
          ...filters,
          type: WaterQualityMonitoringType.ReclamationStatus,
        } as unknown as GetZonesApiArg),
  );
  const { data: reclamationData } = useGetReclamationDataQuery({
    districtId: filters.districtId,
    regionId: filters.regionId,
    year: filters.year,
    quarter: filters.quarter,
  });

  const { columns } = useReclamationSoilDataTableColumns(reclamationData);

  const [reclamationState, setReclamationState] = useState<
    Partial<CreateReclamationApiArg>
  >({});
  const [reclamationResult, setReclamationResult] = useState<
    Array<Partial<CreateReclamationApiArg>>
  >([]);

  const onSelectChange = (actInvoice: JsonRecord) => {
    if (reclamationResult.find((item) => item.zoneId === actInvoice.zoneId)) {
      const newIndicators = reclamationResult.map((item) => {
        if (item.zoneId === actInvoice.zoneId) {
          return {
            ...reclamationState,
            zoneId: actInvoice.zoneId,
            reclamationStateSoil: actInvoice.value,
          };
        }
        return item;
      });
      setReclamationResult(newIndicators);
    } else {
      setReclamationResult([
        ...reclamationResult,
        {
          ...reclamationState,
          zoneId: actInvoice.zoneId,
          reclamationStateSoil: actInvoice.value,
        },
      ]);
    }
  };

  useEffect(() => {
    if (!isEmpty(reclamationData)) {
      const newIndicators = content?.map((item: any) => {
        const selectedIndicator = reclamationData?.find(
          (data: any) => item?.zoneId === data?.zoneId,
        );
        return {
          quarter: selectedIndicator?.quarter,
          reclamationStateSoil: selectedIndicator?.reclamationCondition,
          year: selectedIndicator?.year,
          zoneId: selectedIndicator?.zoneId,
        };
      });

      setReclamationResult(newIndicators);
    }
  }, [reclamationData]);
  const onSave = () => {
    createReclamation(
      reclamationResult as Array<Required<CreateReclamationApiArg>>,
    );
    setReclamationResult([]);
  };

  useEffect(() => {
    setReclamationResult([]);
    setReclamationState({
      year: filters.year,
      quarter: filters.quarter && +filters.quarter,
    });
  }, [filters]);

  return (
    <Row justify="end" gutter={[0, 20]}>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <ReclamationSoilDataFilters />
          </Col>
        </Row>
      </Col>
      {reclamationState.year && reclamationState.quarter && (
        <Col span={24}>
          <Row gutter={[0, 20]} justify="end">
            <TableCustom
              className={styles.table}
              loading={isLoading || isFetching}
              dataSource={content}
              columns={columns}
              key={reclamationState.quarter}
              actionProps={{
                onSelectChange,
              }}
              size="small"
            />
            <Col span={5}>
              <Row justify="end" gutter={20}>
                <Col span={12}>
                  <PrimaryButton htmlType="button">Отмена</PrimaryButton>
                </Col>
                <Col span={12}>
                  <PrimaryButton
                    disabled={reclamationResult.length !== content.length}
                    title="Сохранить"
                    htmlType="submit"
                    onClick={onSave}
                  >
                    Сохранить
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
