import React, { useCallback, useEffect, useRef } from 'react';

import { Form, Input, InputRef, notification } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

import styles from './editor.module.scss';

export const EditPriceCell: React.FC<TableEditorProps> = ({
  customColumn,
  editValidator,
  actionProps,
  record,
}) => {
  const form = useFormInstance();
  const inputRef = useRef<InputRef>(null);
  const { dataIndex } = customColumn;
  const validationRules = editValidator?.rules ?? [];

  const value = get(record, [dataIndex]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    form.setFieldValue(dataIndex, value);
  }, [record, value]);

  const onSave = useCallback(() => {
    try {
      const inputValue = inputRef.current?.input
        ? inputRef.current?.input.value
        : '';

      actionProps?.onCellSaveClick?.(record, customColumn, inputValue ?? '');
      if (inputRef.current && inputRef.current.input) {
        inputRef.current.input.blur();
      }
    } catch (error) {
      notification.success({
        message: 'Не удалось сохранить',
      });
    }
  }, [actionProps, record]);

  return (
    <Form.Item
      id={`${dataIndex}`}
      name={[dataIndex]}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: '',
        },
        ...validationRules,
      ]}
      className={styles.editPriceWrapper}
    >
      <Input
        id={`${dataIndex}`}
        onPressEnter={onSave}
        onBlur={onSave}
        type="number"
        ref={inputRef}
        bordered={false}
        className={styles.editDecadePlaneCellInput}
      />
    </Form.Item>
  );
};
