import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Layout, Tabs } from 'antd';

import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { Divider } from '@features/ui-kit';

import {
  ConsolidatedReportPage,
  EntryDataPage,
  JournalPage,
} from './components';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';
import { HomeOutlined } from '@ant-design/icons';

interface optionsItem {
  key: string;
  label: string;
}

const tabItems = [
  { label: 'Ввод данных', key: 'entryData' },
  { label: 'Журнал', key: 'magazine' },
  { label: 'Сводный отчет 2-ТП', key: 'consolidatedReport' },
];

enum TabValue {
  entryData = 'entryData',
  magazine = 'magazine',
  consolidatedReport = 'consolidatedReport',
}

export const VodkhozReportPage = () => {
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const activeTab = params.get('tabValue') || TabValue.entryData;

  useEffect(() => {
    setHeaderTitle(t('headers.vodokhoz-report'));
  }, []);

  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.REPORT_2TP_ENTRYDATA_VIEW,
      ACCESS.REPORT_2TP_LOG_VIEW,
      ACCESS.REPORT_2TP_CONSOLIDATE_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  const handelTabChanger = (val: string) => {
    params.set('tabValue', val);
    setParams(params);
  };

  let Component;

  switch (activeTab) {
    case TabValue.consolidatedReport:
      Component = ConsolidatedReportPage;
      break;
    case TabValue.magazine:
      Component = JournalPage;
      break;
    default:
      Component = EntryDataPage;
      break;
  }

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'Отчет 2-ТП Водхоз',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        items={dynamicOptions(tabItems)}
        onChange={handelTabChanger}
        activeKey={activeTab}
      />
      <Component />
    </Layout>
  );
};
