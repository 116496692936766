import {
  IAnnualApplicationCalculations,
  IPlantingDataDto,
} from '@models/annualApplication/interfaces/annualApplication';
import {
  IAnnualApplicationFilters,
  IAnnualContractualWaterSupplyFilters,
} from '@models/annualApplication/interfaces/annualApplicationFilters';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import concat from 'lodash/concat'; // TODO: change lodash to native implementation
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';

export type AnnualApplicationState = {
  attachments: IAttachment[];
  selectedWaterUserId: number | null;
  selectedFacilityId: any[] | null;
  isShowCreatePlantingStructureModal: boolean;
  isShowCreateActualStructureModal: boolean;
  selectedActualStructure: IPlantingDataDto | null;
  selectedAgroClimatId: number | null;
  annualTableFilters: IAnnualApplicationFilters;
  annualContract: IAnnualApplicationCalculations | null;
  selectedPlantingData: IPlantingDataDto | null;
  plantingDataDtos: IPlantingDataDto[];
  annualContractualWaterSupplyFilters: Partial<IAnnualContractualWaterSupplyFilters>;
  isShowAnnualApplicationAgreementModal: boolean;
  annualWaterSupplyFilters: Partial<IAnnualContractualWaterSupplyFilters>;
  conclusionContractsFilters: Partial<IAnnualContractualWaterSupplyFilters>;
  waterUserType: string;
};

const initialState: AnnualApplicationState = {
  attachments: [],
  selectedWaterUserId: null,
  selectedActualStructure: null,
  selectedFacilityId: null,
  selectedAgroClimatId: null,
  selectedPlantingData: null,
  isShowCreatePlantingStructureModal: false,
  isShowCreateActualStructureModal: false,
  isShowAnnualApplicationAgreementModal: false,
  annualTableFilters: {
    page: 1,
    size: 10,
  },
  annualContractualWaterSupplyFilters: {},
  annualWaterSupplyFilters: {},
  conclusionContractsFilters: {},
  annualContract: null,
  plantingDataDtos: [],
  waterUserType: '',
};

const annualApplicationSlice = createSlice({
  name: 'annualApplication',
  initialState,
  reducers: {
    setAnnualApplicationAttachmentsAction: (
      state,
      action: PayloadAction<IAttachment[]>,
    ) => {
      return {
        ...state,
        attachments: action.payload,
      };
    },
    setSelectedWaterUserIdAction: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      return {
        ...state,
        selectedWaterUserId: action.payload,
      };
    },
    setSelectedFacilityIdAction: (
      state,
      action: PayloadAction<any[] | null>,
    ) => {
      return {
        ...state,
        selectedFacilityId: action.payload,
      };
    },
    setSelectedWaterUserTypeAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        waterUserType: action.payload,
      };
    },
    setSelectedAgroClimatIdAction: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      return {
        ...state,
        selectedAgroClimatId: action.payload,
      };
    },
    setShowCreatePlantingStructureModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowCreatePlantingStructureModal: action.payload,
      };
    },
    setShowCreateActualStructureModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowCreateActualStructureModal: action.payload,
      };
    },
    setShowAnnualApplicationAgreementModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAnnualApplicationAgreementModal: action.payload,
      };
    },
    changeAnnualApplicationFilters: (
      state,
      action: PayloadAction<IAnnualApplicationFilters>,
    ) => {
      return {
        ...state,
        annualTableFilters: action.payload,
      };
    },
    changeAnnualContractualWaterSupplyFilters: (
      state,
      action: PayloadAction<IAnnualContractualWaterSupplyFilters>,
    ) => {
      return {
        ...state,
        annualContractualWaterSupplyFilters: action.payload,
      };
    },
    changeAnnualWaterSupplyFilters: (
      state,
      action: PayloadAction<IAnnualContractualWaterSupplyFilters>,
    ) => {
      return {
        ...state,
        annualWaterSupplyFilters: action.payload,
      };
    },
    changeConclusionContractsFilters: (
      state,
      action: PayloadAction<IAnnualContractualWaterSupplyFilters>,
    ) => {
      return {
        ...state,
        conclusionContractsFilters: action.payload,
      };
    },
    setAnnualContract: (
      state,
      action: PayloadAction<IAnnualApplicationCalculations | null>,
    ) => {
      return {
        ...state,
        annualContract: action.payload,
      };
    },
    setSelectedActualStructureAction: (
      state,
      action: PayloadAction<IPlantingDataDto | null>,
    ) => {
      return {
        ...state,
        selectedActualStructure: action.payload,
      };
    },
    setSelectedPlantingData: (
      state,
      action: PayloadAction<IPlantingDataDto | null>,
    ) => {
      return {
        ...state,
        selectedPlantingData: action.payload,
      };
    },
    removePlantingData: (state, action: PayloadAction<IPlantingDataDto>) => {
      const plantingDataDtos = state.plantingDataDtos.filter(
        (item) => !isEqual(item, action.payload),
      );
      return {
        ...state,
        plantingDataDtos,
      };
    },
    addPlantingData: (state, action: PayloadAction<IPlantingDataDto>) => {
      let plantingDataDtos;
      if (state.selectedPlantingData !== null) {
        plantingDataDtos = state.plantingDataDtos.map((planting) =>
          isEqual(planting, state.selectedPlantingData)
            ? action.payload
            : planting,
        );
      } else {
        plantingDataDtos = concat(state.plantingDataDtos, action.payload);
      }

      const updatedState = {
        ...state,
        plantingDataDtos,
        selectedPlantingData: null,
      };
      return updatedState;
    },
    clearPlantingData: (state) => {
      return {
        ...state,
        plantingDataDtos: [],
      };
    },
    resetAll: (state) => {
      return {
        ...state,
        plantingDataDtos: [],
        annualContract: null,
        selectedFacilityId: null,
        selectedAgroClimatId: null,
        selectedWaterUserId: null,
      };
    },
  },
});

export const { actions: annualApplicationActions } = annualApplicationSlice;
export default annualApplicationSlice.reducer;
