import { FC } from 'react';

import { Layout } from 'antd';

import { WaterUserCard } from '@features/waterUser/waterUserCard/WaterUserCard';

export const WaterUserCardLayout: FC = () => (
  <Layout>
    <WaterUserCard />
  </Layout>
);
