import React from 'react';
import { GeoPortalArchiveTable } from './geoPortalArchiveTable';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Divider } from '@features/ui-kit';

export const GeoPortalArchiveManagement = () => {
  return (
    <div>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'Реестр архивных данных ГЕО портала',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <GeoPortalArchiveTable />
    </div>
  );
};
