import { Col, Row } from 'antd';

import { InformationalBlock } from '@features/ui-kit';

import { AgreementCard } from './agreementCard/AgreementCard';
import styles from './HomeMonitoring.module.scss';
import { WaterSupplyCard } from './waterSupplyCard/WaterSupplyCard';
import { WaterSupplyCardVolume } from './waterSupplyCardVolume/WaterSupplyCardVolume';

export const HomeMonitoring = () => {
  return (
    <div className={styles.homeMonitoring}>
      <InformationalBlock title="Мониторинг">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <WaterSupplyCard />
          </Col>

          <Col span={8}>
            <WaterSupplyCardVolume />
          </Col>
          <Col span={16}>
            <AgreementCard />
          </Col>
        </Row>
      </InformationalBlock>
    </div>
  );
};
