import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select, SelectProps } from 'antd';

import {
  TableColumnSelect,
  TableEditorProps,
} from '@models/table/interfaces/tableColumn';

export const EditSelect: React.FC<TableEditorProps> = React.memo(
  ({ customColumn, actionProps, record }) => {
    const { t } = useTranslation();
    const { options = [] } = customColumn as TableColumnSelect & SelectProps;
    const selectedValue = useMemo(() => {
      if (customColumn?.additionalData) {
        return customColumn?.additionalData?.find(
          (item: any) => item.zoneId === record.zoneId,
        );
      }
    }, [customColumn]);

    return (
      <Form.Item
        name={`${selectedValue?.year}-${selectedValue?.quarter}-${record?.zoneName}-${record?.id}`}
        style={{ margin: 0 }}
        initialValue={selectedValue?.reclamationCondition}
        rules={[
          {
            required: true,
            message:
              t('customComponents.table.cellEditor.selectValue', {
                value: customColumn.title,
              }) || '',
          },
        ]}
      >
        <Select
          onChange={(value) =>
            actionProps?.onSelectChange?.({ ...record, value })
          }
          key="value"
          options={options}
        />
      </Form.Item>
    );
  },
);
