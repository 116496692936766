import { Space, Typography } from 'antd';

import styles from './CardData.module.scss';

interface ICardDataItemProps {
  title: string;
  value: string | number | (string | number)[];
}

export const CardDataItem: React.FC<ICardDataItemProps> = ({
  title,
  value,
}) => (
  <Space size={0} direction="vertical" className={styles.item}>
    <Typography.Text className={styles.title}>{title}</Typography.Text>
    {Array.isArray(value) ? (
      <Space size={5} direction="vertical">
        {value.map((val, index) => (
          <Typography.Text key={val}>
            {index + 1}.{val}
          </Typography.Text>
        ))}
      </Space>
    ) : (
      <Typography.Text>{value}</Typography.Text>
    )}
  </Space>
);
