import React, { ReactNode, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import type { MenuProps } from 'antd';
import { Button, Menu, Space } from 'antd';

import {
  ApartmentOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BoxPlotOutlined,
  CalendarOutlined,
  CloudSyncOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Links } from '@enums/links';
import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useGetCurrentUser } from '@features/authentication';
import { logOut } from '@store/slices';

import styles from './Sidebar.module.scss';
import { clearReturnUrl } from '@store/slices/header';

type MenuItem =
  | (Required<MenuProps>['items'][number] & { icon: ReactNode })
  | null;

interface IMenuKeyContent {
  key: string;
  label: string;
  path?: string;
  icon?: React.ReactNode;
  permissions?: ACCESS | ACCESS[] | ACCESS[][];
  children?: Record<string, IMenuKeyContent>;
}

export type IMenuKey = Record<string, IMenuKeyContent>;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const menuKeys: IMenuKey = {
  mainPage: {
    key: '1',
    label: 'Главная страница',
    path: Routes.Home,
    permissions: [ACCESS.MAIN_VIEW],
  },
  waterRegistry: {
    key: '2',
    label: 'Реестр',
    path: Routes.WaterRegistry,
    permissions: [ACCESS.WATER_USER_VIEW, ACCESS.WATER_USER_ASSOCIATION_VIEW],
    children: {
      actsInvoicesForPayments: {
        path: Routes.WaterRegistryUsers,
        key: '2-1',
        label: 'Реестр водопользователей',
        permissions: ACCESS.WATER_USER_VIEW,
      },
      tariff: {
        path: Routes.WaterRegistryAssociations,
        key: '2-2',
        label: 'Реестр Ассоциаций',
        permissions: ACCESS.WATER_USER_ASSOCIATION_VIEW,
      },
    },
  },
  irrigationSystem: {
    key: '3',
    label: 'Ирригационных системы',
    path: Routes.IrrigationSystem,
    permissions: [ACCESS.IRRIGATION_SYSTEM_VIEW],
  },
  decadaApp: {
    key: '4',
    label: 'Заявки',
    path: Routes.DecadeApplication,
    permissions: [ACCESS.DECADE_REQUEST_VIEW],
  },
  wateringPlan: {
    key: '5',
    label: 'Водопользование',
    path: Routes.WateringPlan,
    permissions: [ACCESS.WATER_USE_SPILLWAY_VIEW, ACCESS.WATER_USE_SUPPLY_VIEW],
  },
  waterAccounting: {
    key: '6',
    label: 'Водоучет',
    path: Routes.WaterAccounting,
    permissions: [
      ACCESS.WATER_ACCOUNTING_HOUSEHOLD_VIEW,
      ACCESS.WATER_ACCOUNTING_BALANCE_VIEW,
      ACCESS.WATER_ACCOUNTING_WATER_LOG_VIEW,
    ],
  },
  manageEvents: {
    key: '7',
    label: 'Управление работами',
    path: Routes.ManageEvents,
    permissions: [
      ACCESS.WORK_MANAGEMENT_OPERATIONAL_LIST_VIEW,
      ACCESS.WORK_MANAGEMENT_CONSTRUCTION_LIST_VIEW,
      ACCESS.WORK_MANAGEMENT_OTHERTASK_LIST_VIEW,
    ],
  },
  finances: {
    key: '8',
    label: 'Финансы',
    permissions: [ACCESS.ACT_PAYMENT_CARD_VIEW, ACCESS.TARIFF_VIEW],
    children: {
      actsInvoicesForPayments: {
        path: Routes.ActsInvoicesForPaymentsTable,
        key: '8-1',
        label: 'Акты и счета на оплату',
        permissions: ACCESS.ACT_PAYMENT_CARD_VIEW,
      },
      tariff: {
        path: Routes.TariffTable,
        key: '8-2',
        label: 'Тарифы',
        permissions: ACCESS.TARIFF_VIEW,
      },
    },
  },
  reports: {
    key: '9',
    label: 'Отчеты',
    permissions: [ACCESS.REPORTS_VIEW],
    children: {
      ganttDiagram: {
        path: Routes.ManageEventsGanttDiagram,
        key: '9-1',
        label: 'Диаграмма Ганта',
        permissions: [
          ACCESS.GANTT_DIAGRAM_VIEW,
          ACCESS.WORK_MANAGEMENT_OPERATIONAL_CREATE,
          ACCESS.WORK_MANAGEMENT_OTHERTASK_CREATE,
          ACCESS.WORK_MANAGEMENT_CONSTRUCTION_CREATE,
        ],
      },
      annualApplication: {
        path: Routes.AnnualApplicationTable,
        key: '9-2',
        label: 'Годовая заявка',
        permissions: ACCESS.ANNUAL_REQUEST_VIEW,
      },
      businessPlan: {
        path: Routes.BusinessPlanTable,
        key: '9-3',
        label: 'Хозяйственный план',
      },
      // annualPlan: {
      //   path: Routes.AnnualPlanTable,
      //   key: '9-4',
      //   label: 'Годовой план',
      //   permissions: Permission.ANNUAL_PLANNING_VIEW,
      // },
      contractualWaterSupply: {
        path: Routes.AnnualApplicationContractualWaterSupplyTable,
        key: '9-5',
        label: 'Выполнение хоздоговорной водоподачи',
        permissions: ACCESS.REPORTS_WATER_SUPPLY_CONTRACT_VIEW,
      },
      conclusionContracts: {
        path: Routes.AnnualApplicationConclusionContractsTable,
        key: '9-6',
        label: 'Заключение договоров',
        permissions: ACCESS.REPORTS_CONTRACTS_VIEW,
      },
      waterSupply: {
        path: Routes.AnnualApplicationWaterSupplyTable,
        key: '9-7',
        label: 'Водоподача',
        permissions: ACCESS.REPORTS_WATER_SUPPLY_VIEW,
      },
      vodkhozКeport: {
        path: Routes.vodkgozReport,
        key: '9-8',
        label: 'Отчет 2-ТП Водхоз',
        permissions: ACCESS.REPORT_2TP_ENTRYDATA_VIEW,
      },
      waterUsePlan: {
        path: Routes.WaterUsePlan,
        key: '9-9',
        label: 'Годовое планирование',
        permissions: ACCESS.ANNUAL_PLANNING_VIEW,
      },
    },
  },
  monitoring: {
    key: '10',
    label: 'Мониторинг',
    path: 'monitoring',
    permissions: [
      ACCESS.MONITORING_RUVH_VIEW,
      ACCESS.QUALITY_INDICATORS_VIEW,
      ACCESS.RESERVOIRS_VIEW,
      ACCESS.GEOPORTAL_VIEW,
      ACCESS.SPPR_VIEW,
    ],
    children: {
      demoMonitoring: {
        path: Routes.DemoMonitoring,
        key: '10-1',
        label: 'Мониторинг датчики',
        permissions: ACCESS.MONITORING_RUVH_VIEW,
      },
      waterQuality: {
        path: Routes.WaterQualityTable,
        key: '10-2',
        label: 'Качественные показатели воды',
        permissions: ACCESS.QUALITY_INDICATORS_VIEW,
      },
      reservoirMonitoring: {
        path: Routes.ReservoirTable,
        key: '10-3',
        label: 'Водохранилища',
        permissions: ACCESS.RESERVOIRS_VIEW,
      },
      geoPortal: {
        path: Routes.geoPortalTable,
        key: '10-4',
        label: 'Гео-Портал',
        permissions: ACCESS.GEOPORTAL_VIEW,
      },
      sppr: {
        path: Routes.sppr,
        key: '10-5',
        label: 'Система планирования и принятия решений',
        permissions: ACCESS.SPPR_VIEW,
      },
    },
  },
  settings: {
    key: '11',
    label: 'Администрирование',
    permissions: [
      ACCESS.SYSTEM_AGROCLIMATIC_ZONE_VIEW,
      ACCESS.ARCHIVARIUS_VIEW,
      ACCESS.HYDRO_FACILITY_VIEW,
      ACCESS.REFERENCES_VIEW,
    ],
    children: {
      agroClimaticZone: {
        path: Routes.AgroClimaticZoneTable,
        key: '11-1',
        label: 'Агроклиматические зоны',
        permissions: ACCESS.SYSTEM_AGROCLIMATIC_ZONE_VIEW,
      },
      archivarius: {
        path: Routes.ArchivariusTable,
        key: '11-2',
        label: 'Архивариус',
        permissions: ACCESS.ARCHIVARIUS_VIEW,
      },
      hydraulicSection: {
        path: Routes.HydraulicSectionEditor,
        key: '11-3',
        label: 'Справочник гидроучастков',
        permissions: ACCESS.HYDRO_FACILITY_VIEW,
      },
      referenceBook: {
        path: Routes.ReferenceBook,
        key: '11-4',
        label: 'Справочник',
        permissions: ACCESS.REFERENCES_VIEW,
      },
    },
  },
  administration: {
    key: '12',
    label: 'Администрирование',
    permissions: [
      ACCESS.NEWS_VIEW,
      ACCESS.INSTRUCTIONS_VIEW,
      ACCESS.ROLES_VIEW,
      ACCESS.OWNERS_VIEW,
      ACCESS.REFERENCE_MATERIALS_VIEW,
      ACCESS.GEO_PORTAL_VIEW,
    ],
    children: {
      newsManagement: {
        path: Routes.NewsManagement,
        key: '12-1',
        label: 'Управление новостями',
        permissions: ACCESS.NEWS_VIEW,
      },
      instructionsManagement: {
        path: Routes.InstructionsManagement,
        key: '12-2',
        label: 'Управление инструкциями',
        permissions: ACCESS.INSTRUCTIONS_VIEW,
      },
      rolesAndRightsManagement: {
        path: Routes.RolesAndRightsManagement,
        key: '12-3',
        label: 'Управление ролями и правами',
        permissions: ACCESS.ROLES_VIEW,
      },
      employees: {
        path: Routes.Employees,
        key: '12-4',
        label: 'Организации и сотрудники',
        permissions: ACCESS.OWNERS_VIEW,
      },
      referenceMaterials: {
        path: Routes.ReferenceMaterials,
        key: '12-5',
        label: 'Справочные материалы',
        permissions: ACCESS.REFERENCE_MATERIALS_VIEW,
      },
      geoPortalManagement: {
        path: Routes.GeoPortalManagement,
        key: '12-6',
        label: 'Управление ГЕО порталом',
        permissions: ACCESS.GEO_PORTAL_VIEW,
      },
      geoPortalArchiveManagement: {
        path: Routes.GeoPortalArchiveManagement,
        key: '12-7',
        label: 'Управление архивным ГЕО порталом',
        permissions: ACCESS.GEO_PORTAL_VIEW,
      },
    },
  },
};

const items: MenuItem[] = [
  getItem(
    menuKeys.mainPage.label,
    menuKeys.mainPage.key,
    <Link to={Routes.Home}>
      <HomeOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.irrigationSystem.label,
    menuKeys.irrigationSystem.key,
    <Link to={Routes.IrrigationSystemTable}>
      <ApartmentOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.waterRegistry.label,
    menuKeys.waterRegistry.key,
    <Link to={Routes.WaterRegistryUsers}>
      <TeamOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.decadaApp.label,
    menuKeys.decadaApp.key,
    <Link to={Routes.DecadeApplication}>
      <AuditOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.wateringPlan.label,
    menuKeys.wateringPlan.key,
    <Link to={Routes.WateringPlan}>
      <ReconciliationOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.waterAccounting.label,
    menuKeys.waterAccounting.key,
    <Link to={Routes.WaterAccounting}>
      <ReadOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.manageEvents.label,
    menuKeys.manageEvents.key,
    <Link to={Routes.ManageEvents}>
      <CalendarOutlined />
    </Link>,
  ),
  getItem(
    menuKeys.finances.label,
    menuKeys.finances.key,
    <div>
      <DollarOutlined />
    </div>,
    [
      getItem(
        'Акты и счета на оплату',
        'a1',
        <Link to={Routes.ActsInvoicesForPaymentsTable}>
          <CreditCardOutlined />
        </Link>,
      ),
      getItem(
        'Тарифы',
        'a2',
        <Link to={Routes.TariffTable}>
          <CreditCardOutlined />
        </Link>,
      ),
    ],
  ),
  getItem(
    menuKeys.reports.label,
    menuKeys.reports.key,
    <div>
      <FolderOpenOutlined />
    </div>,
    [
      getItem(
        menuKeys.reports.children?.ganttDiagram.label ?? '',
        menuKeys.reports.children?.ganttDiagram.key ?? '',
        <Link to={Routes.ManageEventsGanttDiagram}>
          <BoxPlotOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.reports.children?.annualApplication.label ?? '',
        menuKeys.reports.children?.annualApplication.key ?? '',
        <Link to={Routes.AnnualApplicationTable}>
          <ScheduleOutlined />
        </Link>,
      ),
      // getItem(
      //   menuKeys.reports.children?.annualPlan.label ?? '',
      //   menuKeys.reports.children?.annualPlan.key ?? '',
      //   <Link to={Routes.AnnualPlanTable}>
      //     <ProfileOutlined />
      //   </Link>,
      // ),
      getItem(
        menuKeys.reports.children?.businessPlan.label ?? '',
        menuKeys.reports.children?.businessPlan.key ?? '',
        <Link to={Routes.BusinessPlanTable}>
          <ScheduleOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.reports.children?.waterUsePlan.label ?? '',
        menuKeys.reports.children?.waterUsePlan.key ?? '',
        <Link to={Routes.WateringPlan}>
          <ScheduleOutlined />
        </Link>,
      ),
    ],
  ),
  getItem(
    menuKeys.monitoring.label,
    menuKeys.monitoring.key,
    <div>
      <AreaChartOutlined />
    </div>,
    [
      getItem(
        menuKeys.monitoring.children?.demoMonitoring.label,
        menuKeys.monitoring.children?.demoMonitoring.key || '',
        <Link to={Routes.DemoMonitoring}>
          <CloudSyncOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.monitoring.children?.waterQuality.label,
        menuKeys.monitoring.children?.waterQuality.key || '',
        <Link to={Routes.WaterQualityTable}>
          <CloudSyncOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.monitoring.children?.reservoirMonitoring.label,
        menuKeys.monitoring.children?.reservoirMonitoring.key || '',
        <Link to={Routes.ReservoirTable}>
          <CloudSyncOutlined />
        </Link>,
      ),
    ],
  ),
  getItem(
    menuKeys.settings.label,
    menuKeys.settings.key,
    <div>
      <SettingOutlined />
    </div>,
    [
      getItem(
        menuKeys.settings.children?.hydraulicSection.label ?? '',
        menuKeys.settings.children?.hydraulicSection.key ?? '',
        <Link to={Routes.HydraulicSectionEditor}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.settings.children?.agroClimaticZone.label ?? '',
        menuKeys.settings.children?.agroClimaticZone.key ?? '',
        <Link to={Routes.AgroClimaticZoneTable}>
          <CloudSyncOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.settings.children?.archivarius.label ?? '',
        menuKeys.settings.children?.archivarius.key ?? '',
        <Link to={Routes.ArchivariusTable}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.settings.children?.referenceBook.label ?? '',
        menuKeys.settings.children?.referenceBook.key ?? '',
        <Link to={Routes.ReferenceBook}>
          <DatabaseOutlined />
        </Link>,
      ),
    ],
  ),

  getItem(
    menuKeys.administration.label,
    menuKeys.administration.key,
    <div>
      <ProfileOutlined />
    </div>,
    [
      getItem(
        menuKeys.administration.children?.newsManagement.label ?? '',
        menuKeys.administration.children?.newsManagement.key ?? '',
        <Link to={Routes.NewsManagement}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.instructionsManagement.label ?? '',
        menuKeys.administration.children?.instructionsManagement.key ?? '',
        <Link to={Routes.InstructionsManagement}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.rolesAndRightsManagement.label ?? '',
        menuKeys.administration.children?.rolesAndRightsManagement.key ?? '',
        <Link to={Routes.RolesAndRightsManagement}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.employees.label ?? '',
        menuKeys.administration.children?.employees.key ?? '',
        <Link to={Routes.Employees}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.referenceMaterials.label ?? '',
        menuKeys.administration.children?.referenceMaterials.key ?? '',
        <Link to={Routes.ReferenceMaterials}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.geoPortalManagement.label ?? '',
        menuKeys.administration.children?.geoPortalManagement.key ?? '',
        <Link to={Routes.GeoPortalManagement}>
          <DatabaseOutlined />
        </Link>,
      ),
      getItem(
        menuKeys.administration.children?.geoPortalArchiveManagement.label ??
          '',
        menuKeys.administration.children?.geoPortalArchiveManagement.key ?? '',
        <Link to={Routes.GeoPortalArchiveManagement}>
          <DatabaseOutlined />
        </Link>,
      ),
    ],
  ),
];

type SidebarProps = {
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
};

export const SidebarComponent: React.FC<SidebarProps> = ({
  setCollapsed,
  collapsed,
}) => {
  const { userPermissions } = useGetCurrentUser();

  // ACCESS - and
  // ACCESS[] - or
  // ACCESS[][] - and
  const checkPermissionMenu = (
    permissions: ACCESS[],
    check: ACCESS | ACCESS[] | ACCESS[][],
  ) => {
    if (Array.isArray(check)) {
      return check.some((el) => {
        if (Array.isArray(el)) {
          return el.every((elem) => permissions.includes(elem));
        }
        return permissions.includes(el);
      });
    }
    return permissions.includes(check);
  };
  const showItems = (
    elements: MenuItem[],
    permissions: ACCESS[] = [],
    menuKeyss: IMenuKey,
  ) => {
    const reducedItems: Record<string, any> = Object.values(menuKeyss).reduce(
      (acc, el) => {
        if (el.children) {
          const obj = { ...el, children: Object.values(el.children) };
          return { ...acc, [el.key]: obj };
        }
        return { ...acc, [el.key]: el };
      },
      {},
    );
    const mappedItems: IMenuKeyContent[] = elements.map((item) => {
      if (!item?.key) {
        return null;
      }

      const menuItem = reducedItems[String(item.key)];
      if (
        menuItem?.permissions &&
        !checkPermissionMenu(permissions, menuItem.permissions)
      ) {
        return null;
      }
      menuItem.children = menuItem.children
        ?.filter((child: any) =>
          checkPermissionMenu(permissions, child.permissions),
        )
        .map((el: any) =>
          getItem(<Link to={`${el.path}`}>{el.label}</Link>, `${el.key}`),
        );
      return {
        ...menuItem,
        icon: item?.icon,
      };
    });

    return mappedItems;
  };

  const showFilteredRoutes = useMemo(
    () => showItems(items, userPermissions, menuKeys),
    [items, userPermissions, menuKeys],
  );

  const onMenuItemClick = () => {
    dispatch(clearReturnUrl());
  };

  const dispatch = useDispatch();
  const location = useLocation();

  const selectedKey = useMemo(() => {
    let key: string[] = [];
    Object.values(menuKeys).forEach((item) => {
      if (item.path && location.pathname.includes(item.path)) {
        key = [item.key];
      }
      if (item.children) {
        Object.values(item.children).forEach((child) => {
          if (child.path && location.pathname.includes(child.path)) {
            key = [item.key, child.key];
          }
        });
      }
    });
    return key;
  }, [location]);

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
  }, []);

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.nav}>
        <Space
          direction={collapsed ? 'vertical' : 'horizontal'}
          className={styles.logo}
          size={collapsed ? 4 : 56}
        >
          <Link to={Routes.WaterRegistryUsers}>
            <img
              src={collapsed ? Links.SmallLogo : Links.FullLogo}
              alt="logo"
            />
          </Link>
          <div className={styles.burger}>
            <Button
              className={styles.burgerButton}
              icon={
                collapsed ? (
                  <MenuUnfoldOutlined style={{ opacity: 0.3 }} />
                ) : (
                  <MenuFoldOutlined style={{ opacity: 0.3 }} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        </Space>

        <Menu
          defaultSelectedKeys={selectedKey}
          theme="light"
          onClick={onMenuItemClick}
          mode="inline"
          items={showFilteredRoutes}
          className={collapsed ? styles.collapsedMenu : styles.menu}
        />
      </div>
      <Button
        className={`${styles.logout} ${
          !collapsed ? styles.collapsedLogout : ''
        }`}
        icon={<img src={Links.Logout} alt="error" />}
        onClick={handleLogOut}
      >
        {!collapsed && 'Выход'}
      </Button>
    </div>
  );
};

export const Sidebar = React.memo(SidebarComponent);
