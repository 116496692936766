import React from 'react';
import { useParams } from 'react-router-dom';

import { Form, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindOperationalBlockByIdQuery } from '@store/gisproApi';

import styles from './descriptionTask.module.scss';

export const DescriptionTask = () => {
  const { id } = useParams();

  const { data: operationalBlock } = useFindOperationalBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );
  return (
    <Form layout="vertical" className={styles.container}>
      <CustomCard title="Описание задачи">
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <Form.Item label="Код задачи">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.taskId}
              </Typography.Text>
            </Form.Item>
          </div>
          <div className={styles.rowItem}>
            <Form.Item label="Дата:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.inspectionDate}
              </Typography.Text>
            </Form.Item>
          </div>
          <div className={styles.rowItem}>
            <Form.Item label="Сроки (план):">
              <Typography.Text strong>
                {operationalBlock &&
                  `${operationalBlock.planStartDate || '-'} ${
                    operationalBlock.planEndDate || '-'
                  }`}
              </Typography.Text>
            </Form.Item>
          </div>
          <div className={styles.rowItem}>
            <Form.Item label="Сроки (факт):">
              <Typography.Text strong>
                {operationalBlock &&
                  `${operationalBlock.factStartDate || '-'} ${
                    operationalBlock.factEndDate || '-'
                  }`}
              </Typography.Text>
            </Form.Item>
          </div>
        </div>
      </CustomCard>
    </Form>
  );
};
