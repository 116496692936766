import { Button, ButtonProps } from 'antd';

import Icon from '@ant-design/icons';
import { PlotIcon } from '@icons/PlotIcon';

import styles from './buttons.module.scss';

type FilterButtonV2PropTypes = ButtonProps;

const PlotIconComponent = () => <PlotIcon />;

export const FilterButtonV2: React.FC<FilterButtonV2PropTypes> = ({
  ...rest
}) => {
  return (
    <Button className={styles.filterButton} {...rest}>
      <Icon component={PlotIconComponent} />
    </Button>
  );
};
