import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, notification, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useGetCurrentUser } from '@features/authentication';
import { DashedButton, PrimaryButton, TooltipedSelect } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCancelApprovalWaterUsePlanMutation,
  useGetOwnerChildsQuery,
} from '@store/gisproApi';
import { getGuvhFiltersSelector } from '@store/selectors';
import { setGuvhFilters, setRuvhStatus } from '@store/slices';

export const WaterUsePlanGuvhViewControlBar: React.FC = () => {
  const { user } = useGetCurrentUser();
  const [form] = useForm();
  const dispatch = useDispatch();
  const ownerId = user?.owner?.id;
  const guvhFilters = useSelector(getGuvhFiltersSelector);
  const districtId = Form.useWatch('districtId', form);
  const [cancelApprove] = useCancelApprovalWaterUsePlanMutation();
  const onDistrictChange = (v: number, option: any) => {
    dispatch(setGuvhFilters({ districtId: v, districtName: option?.label }));
  };
  const { data: districtsByOwnerId, isLoading } = useGetOwnerChildsQuery(
    { ownerId: String(ownerId) } || skipToken,
  );

  const onCancelApprove = () => {
    cancelApprove({ ruvhId: districtId }).then(() => {
      dispatch(setRuvhStatus('Проект РУВХ'));
      notification.info({
        message: 'Статус изменен этого РУВХ изменен',
      });
    });
  };
  return (
    <Row justify="space-between">
      <Col>
        <Form
          form={form}
          initialValues={{ districtId: guvhFilters?.districtId }}
        >
          <Form.Item name="districtId">
            <TooltipedSelect
              placeholder="Выберите Район"
              loading={isLoading}
              options={districtsByOwnerId}
              onChange={onDistrictChange}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col>
        {districtId && (
          <Row gutter={20}>
            <Col>
              <DashedButton colorType="green">
                На согласовании ГУВХ
              </DashedButton>
            </Col>
            <Col>
              <PrimaryButton onClick={onCancelApprove}>
                Изменить статус
              </PrimaryButton>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
