import React from 'react';

import { Table, Typography } from 'antd';

import { IBalanceSheet } from '@models/waterAccounting/interfaces/waterAccounting';
import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { roundBigInteger } from '@utils/roundBigInteger';

interface IWaterLogAccountingBalanceSheetSummaryProps {
  data?: IBalanceSheet;
  waterUserData: any[];
}

export const WaterLogAccountingBalanceSheetSummary: React.FC<
  IWaterLogAccountingBalanceSheetSummaryProps
> = ({ data, waterUserData }) => {
  if (!data || !waterUserData.length) {
    return null;
  }

  const totalWaterUserSum = waterUserData.reduce(
    (acc, el) => acc + formatDecimal(el.sum.value ?? 0),
    0,
  );

  const calculateTotalValues = (dataSrc: any[]) => {
    const result = Object.keys(dataSrc[0].days).map((day) => {
      const totalSum = dataSrc.reduce(
        (acc: any, facility: any) =>
          acc + formatDecimal(facility.days[day].value),
        0,
      );
      return {
        day,
        totalSum,
        totalSumM3:
          totalSum === 0 ? 0 : roundBigInteger(totalSum * 0.864, 100),
      };
    });
    return result;
  };

  const totalDays = calculateTotalValues(waterUserData);

  const outletDays = data.daysSum.map((el) => ({
    ...el,
    value: Number(formatDecimal(el.value)),
    valueM3:
      Number(el.value) === 0
        ? 0
        : roundBigInteger(formatDecimal(el.value) * 0.864, 100),
  }));

  const m3Total = roundBigInteger(formatDecimal(data.totalSum) * 0.864, 100);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2} align="left">
          <Typography.Text strong>
            Всего по водовыпускам расход л/с
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="left">
          <Typography.Text
            type={
              totalWaterUserSum === formatDecimal(data.totalSum)
                ? 'success'
                : 'danger'
            }
          >
            {formatDecimal(data.totalSum)}
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="left">
          <Typography.Text
            type={
              totalWaterUserSum === formatDecimal(data.totalSum)
                ? 'success'
                : 'danger'
            }
          >
            {formatDecimal(m3Total)}
          </Typography.Text>
        </Table.Summary.Cell>
        {outletDays.map((el) => {
          return (
            <React.Fragment key={el.day}>
              <Table.Summary.Cell index={3} align="left">
                <Typography.Text
                  type={
                    Number(formatDecimal(el.value)) ===
                    Number(
                      formatDecimal(
                        totalDays.find(
                          (dayItem) => Number(dayItem.day) === el.day,
                        )?.totalSum,
                      ),
                    )
                      ? 'success'
                      : 'danger'
                  }
                >
                  {el.value}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="left">
                <Typography.Text>{el.valueM3}</Typography.Text>
              </Table.Summary.Cell>
            </React.Fragment>
          );
        })}
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2} align="left">
          <Typography.Text strong>
            Всего по водопользователям расход л/с
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="left">
          <Typography.Text
            type={
              formatDecimal(totalWaterUserSum) === formatDecimal(data.totalSum)
                ? 'success'
                : 'danger'
            }
          >
            {totalWaterUserSum}
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="left">
          <Typography.Text
            type={
              formatDecimal(totalWaterUserSum) === formatDecimal(data.totalSum)
                ? 'success'
                : 'danger'
            }
          >
            {formatDecimal(totalWaterUserSum)}
          </Typography.Text>
        </Table.Summary.Cell>
        {totalDays.map((dayItem) => (
          <React.Fragment key={dayItem.day}>
            <Table.Summary.Cell index={1} align="left">
              <Typography.Text
                type={
                  formatDecimal(dayItem.totalSum) ===
                  formatDecimal(outletDays.find(
                    (day) => Number(day.day) === Number(dayItem.day),
                  )?.value)
                    ? 'success'
                    : 'danger'
                }
              >
                {formatDecimal(dayItem.totalSum)}
              </Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="left">
              <Typography.Text>{dayItem.totalSumM3}</Typography.Text>
            </Table.Summary.Cell>
          </React.Fragment>
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
};
