import { Col, Row } from 'antd';

import {
  ConstructorStatsModal,
  SizeModal,
  TechStatsModal,
} from '@features/waterObject/waterObjectCard/shared/modals';

import {
  CreateWaterSectionModal,
  useCreateWaterSectionModal,
} from './createWaterSectionModal';
import { WaterSectionTable } from './waterSectionTable';
import { PrimaryButton } from '@features/ui-kit';

export const WaterSection: React.FC = () => {
  const { setShowModal } = useCreateWaterSectionModal();

  return (
    <>
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <PrimaryButton size="large" onClick={() => setShowModal(true)}>
            + Создать
          </PrimaryButton>
        </Col>
      </Row>
      <WaterSectionTable />
      <CreateWaterSectionModal />
      <TechStatsModal />
      <SizeModal />
      <ConstructorStatsModal />
    </>
  );
};
