import {
  ConnectBlock,
  CustomCard,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { LoadingPage } from '@pages/loading';
import { Col, Divider, Row, Space, Typography } from 'antd';
import styles from '@features/waterUser/waterUserCard/legalWaterUserCard/LegalWaterUserCard.module.scss';
import { TotalInfoModalTypes } from '@features/waterUser/waterUserCard/modals/totalInfoModal';
import { EditIcon } from '@icons/EditIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useModalState } from '@utils/modalHook/modalHook';
import { TotalInfoModal } from '@features/profile/profileCard/modals/TotalInfoModal';
import {
  useGetOwnerInfoByIdQuery,
  useGetOwnerInfoQuery,
} from '@store/gisproApi';
import { ProfileEmployeeListTable } from '@features/profile/profileEmployeeListTable';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { AddEmployeeModal } from './modals/addEmployeeModal/AddEmployeeModal';

export const ProfileInformation = () => {
  const modalState = useModalState<TotalInfoModalTypes>();
  const { id = '' } = useParams();
  const { open } = modalState;
  const { data = {} as any, isLoading: isLoadingProfile } =
    useGetOwnerInfoQuery(id && skipToken);
  const { data: infoById, isLoading: isLoadingOwner } =
    useGetOwnerInfoByIdQuery(id ? { id } : skipToken);
  const [isShowModal, setShowModal] = useState(false);
  const [employeeInitialValue, setEmployeeInitialValue] = useState(null);
  const { t } = useTranslation();
  const dataSource = useMemo(() => (id ? infoById : data), [data, infoById]);
  const basicDataForAssociation = (dataSource: any) => {
    return dataSource?.isAssociation
      ? [
          { label: 'Фактический вид экономической деятельности', value: '-' },
          { label: 'ГКЭД', value: '-' },
        ]
      : [];
  };

  const basicData = [
    {
      label: 'Наименование',
      value: dataSource?.name || '-',
    },
    {
      label: 'ИНН',
      value: dataSource?.inn || '-',
    },
    {
      label: 'ОКПО',
      value: dataSource?.okpo || '-',
    },
    {
      label: 'Юридический адрес',
      value: dataSource?.address || '-',
    },
    { label: 'Область', value: dataSource?.region?.title || '-' },
    { label: 'Регион', value: dataSource?.district?.title || '-' },
    {
      label: 'Город/село',
      value: dataSource?.okmot?.title || '-',
    },
    { label: 'Номер телефона', value: dataSource?.phone || '-' },
    { label: 'Email', value: dataSource?.email || '-' },
    {
      label: 'Фактический вид экономической деятельности',
      value: dataSource?.economicActivity?.title || '-',
    },
    { label: 'ГКЭД', value: dataSource?.gked?.title || '-' },
    {
      label: 'Общая площадь земель ГА',
      value: dataSource?.totalArea || '-',
    },
    {
      label: 'Общая орошаемая площадь охвата ГА',
      value: dataSource?.area || '-',
    },
    // ...basicDataForAssociation(dataSource),
  ];

  console.log('dataSource', dataSource);

  const passportDataForAssociation = (dataSource: any) => {
    return dataSource?.isAssociation
      ? [
          {
            label: 'Общая площадь земель ГА',
            value: dataSource?.totalArea || '-',
          },
          {
            label: 'Общая орошаемая площадь охвата ГА',
            value: dataSource?.area || '-',
          },
        ]
      : [];
  };

  const passportData = [
    { label: 'Название банка', value: dataSource?.bank?.parent?.name || '-' },
    { label: 'Название филиала', value: dataSource?.bank?.name || '-' },
    {
      label: 'Полный адрес банка',
      value:
        [dataSource?.bank?.city, dataSource?.bank?.address]
          .filter(Boolean)
          .join(', ') || '-',
    },
    { label: 'Расчетный счет', value: dataSource?.accountNumber || '-' },
    {
      label: 'Корреспондентский счет',
      value: dataSource?.organizationAccount || '-',
    },
  ];
  const handleCreate = () => {
    setShowModal(true);
    setEmployeeInitialValue(null);
  };
  if (isLoadingProfile || isLoadingOwner) {
    return <LoadingPage />;
  }

  return (
    <InformationalBlock title={`Профиль ${dataSource?.name ?? ''}`}>
      <Row gutter={5}>
        <Col span={10}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <ConnectBlock
                connectData={basicData}
                title={
                  <Row justify="space-between" align="top">
                    <Col span={21}>
                      <Typography.Title level={4}>
                        Основные данные
                      </Typography.Title>
                      <Divider className={styles.divider} />
                    </Col>
                    <Col>
                      <PrimaryButton
                        onClick={() => open(TotalInfoModalTypes.main)}
                        icon={<EditIcon />}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
            <Col span={24}>
              <ConnectBlock
                connectData={passportData}
                title={
                  <Row justify="space-between" align="top">
                    <Col span={21}>
                      <Typography.Title level={4}>
                        Банковские данные
                      </Typography.Title>
                      <Divider className={styles.divider} />
                    </Col>
                    <Col>
                      <PrimaryButton
                        onClick={() => open(TotalInfoModalTypes.passport)}
                        icon={<EditIcon />}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <CustomCard
            title={
              <Row align="middle" justify="space-between">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Список сотрудников {dataSource?.employees?.length || 0}
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton onClick={handleCreate}>
                    {t('addEmployee.titles.employee')}
                  </PrimaryButton>
                </Col>
              </Row>
            }
          >
            <ProfileEmployeeListTable
              dataSource={dataSource}
              setShowModal={setShowModal}
              setEmployeeInitialValue={setEmployeeInitialValue}
            />
          </CustomCard>
        </Col>
      </Row>
      {modalState.isOpen && (
        <TotalInfoModal dataSource={dataSource} modalState={modalState} />
      )}
      {isShowModal && (
        <AddEmployeeModal
          waterUserId={dataSource?.waterUserId}
          employeeInitialValue={employeeInitialValue}
          setShowModal={setShowModal}
        />
      )}
    </InformationalBlock>
  );
};
