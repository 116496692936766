import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { DescriptionInfo } from '@pages/worksAndEvents/shared/descriptionInfo/DescriptionInfo';
import { ExecutorInfo } from '@pages/worksAndEvents/shared/executorInfo/ExecutorInfo';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  DefaultButton,
  Divider,
  InformationalBlock,
} from '../../../features/ui-kit';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '../../../store/gisproApi';
import {
  CommonInfo,
  DescriptionTask,
  Documents,
  Images,
  Implementation,
  PassportData,
} from './components';
import styles from './worksAndEvents.module.scss';

export const BuildingWorksAndEvents: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );

  return (
    <Row className={styles.worksAndEvents}>
      <Col span={24}>
        <Row gutter={16}>
          <Col>
            <DefaultButton onClick={() => navigate(-1)} className="backBtn">
              <LeftOutlined />
              <Typography.Text className={styles.textBack}>
                Назад
              </Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Агроклиматические зоны',
                },
                {
                  title: 'Редактор ГМР',
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
      </Col>
      <Col span={24}>
        <CommonInfo />
        <Divider />
      </Col>
      <Col span={24}>
        <InformationalBlock>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <PassportData />
            </Col>
            <Col span={24}>
              <DescriptionTask />
            </Col>
            <Col span={24}>
              <DescriptionTask />
            </Col>
            <Col span={24}>
              <ExecutorInfo data={constructionBlock} />
            </Col>
            <Col span={24}>
              <DescriptionInfo data={constructionBlock} />
            </Col>
            <Col span={24}>
              <Images />
            </Col>
            <Col span={24}>
              <Implementation />
            </Col>
            <Col span={24}>
              <Documents />
            </Col>
          </Row>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
