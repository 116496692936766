import { useTranslation } from 'react-i18next';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

export const useWaterUserHouseHoldsColumns = () => {
  const { t } = useTranslation();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: district = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Okmot,
  });

  const legal: TableColumn[] = [
    {
      title: 'Наименование хозяйства',
      dataIndex: 'name',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('annualApplication.columns.address'),
      dataIndex: 'address',
      key: 'address',
      xtype: CustomTableColumnType.RenderCustom,
      width: 100,
      renderCustom: ({ record }) => (
        <div>
          {regions.find((item) => item.id === record.regionId)?.title || '-'}{' '}
          {district.find((item) => item.id === record.districtId)?.title || '-'}{' '}
          {okmots.find((item) => item.id === record.okmotId)?.title || '-'}
        </div>
      ),
    },
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'outletName',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование канала',
      dataIndex: 'channelName',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование оросительной системы',
      dataIndex: 'systemName',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование гидроучастка',
      dataIndex: 'agroClimatiqueZoneName',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
  ];

  const association: TableColumn[] = [
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'name',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование канала',
      dataIndex: 'channel.name',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование оросительной системы',
      dataIndex: 'irrigationSystem.name',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование гидроучастка',
      dataIndex: 'hydroFacility.name',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
  ];

  return {
    [WaterUserEnum.LEGAL]: legal,
    [WaterUserEnum.INDIVIDUAL]: legal,
    [WaterUserEnum.ASSOCIATION]: association,
  };
};
