import { createSelector } from '@reduxjs/toolkit';
import { DecadeAppState } from '@store/slices';

export const decadeAppSateSelector = (state: {
  decadeApp: DecadeAppState;
}): DecadeAppState => state.decadeApp;

export const getDecadeAppFiltersSelector = createSelector(
  decadeAppSateSelector,
  (decadeAppState) => {
    return decadeAppState.decadeApplicationFilters;
  },
);

export const getShowDecadeApplicationPrintSelector = createSelector(
  decadeAppSateSelector,
  (decadeAppState) => {
    return decadeAppState.isShowDecadeApplicationPrintModal;
  },
);

export const getSelectedDecade = createSelector(
  decadeAppSateSelector,
  (decadeAppState) => {
    return decadeAppState.selectedDecade;
  },
);

export const getSelectedDecadeDay = createSelector(
  decadeAppSateSelector,
  (decadeAppState) => {
    return decadeAppState.selectedDecadeDay;
  },
);
