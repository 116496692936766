export enum TotalInfoModalTypes {
  main = 'main',
  bank = 'bank',
  contacts = 'contacts',
  passport = 'passport'
}
export interface PositionEmployee {
  id: number;
  title: string;
  code: string;
  parentId: number | null;
  books: any | null;
}

export interface WaterUser {
  id: number;
  code: string;
  createdAt: string;
  updatedAt: string;
  isArchived: boolean;
  hasCabin: boolean;
  type: number;
  isAssociation: boolean;
  isLegal: boolean;
  isIndividual: boolean;
  region: string | null;
  district: string | null;
}

export interface EmployeeReference {
  id: number;
}

export interface Attachment {
  id: number;
  path: string;
  type: string;
  extension: string;
  name: string;
  description: string | null;
  employees?: EmployeeReference;
}

export interface Employee {
  id: number;
  createdAt: string;
  updatedAt: string;
  inn: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  additionalPhone: string;
  address: string;
  passportNumber: string;
  gender: string;
  regNumber: string;
  headEnum: string;
  hasWhatsapp: boolean;
  hasTelegram: boolean;
  dateBirth: string;
  attachmentsList: Attachment[];
  waterUser: WaterUser;
  positionEmployee: PositionEmployee;
  login?: string;
  user?: string;
}



