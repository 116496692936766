import { useCallback, useEffect, useMemo } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Routes } from '@enums/routes';
import { useIrrigationSystemCardObjectColumns } from '@features/irrigationSystemCard/tables/irrigationSystemCardObjectTable/irrigationSystemCardObjectTableContainer/useIrrigationSystemCardObjectColumns';
import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import { ISystemObject } from '@models/waterObject/interfaces/waterObject';
import { useGetIrrigationSystemObjectsAndStructureByIdQuery } from '@store/gisproApi';
import { useIrrigationSystemCardObjectTableFilters } from '../irrigationSystemCardObjectTableControls/useIrrigationSystemCardObjectTableFilters';
import { TablePaginationConfig } from 'antd';
import { useGetCurrentUser } from '@features/authentication';
import { isEmpty } from '@utils/utils';

export const IrrigationSystemCardObjectTableContainer: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { systemId = '' } = useParams<string>();
  const { filters, setIrrigationSystemCardObjectFilters } =
    useIrrigationSystemCardObjectTableFilters();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetIrrigationSystemObjectsAndStructureByIdQuery({
    irrigationSystemId: systemId,
    params: filters,
  });

  const { columns } = useIrrigationSystemCardObjectColumns();

  const onHandleWatch = (waterObject: ISystemObject) => {
    navigate(
      generatePath(Routes.WaterObjectCard, {
        id: String(waterObject.objectId),
      }),
    );
  };

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setIrrigationSystemCardObjectFilters({
          ...(filters as any),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  useEffect(() => {
    setIrrigationSystemCardObjectFilters({
      section: 'objects',
      page: 1,
      size: 10,
    });

    setSearchParams({
      section: 'objects',
      page: '1',
      size: '10',
    });
  }, []);

  return (
    <InformationalBlock>
      <CustomCard title="Таблица объектов">
        <TableCustom
          loading={isFetching || isLoading}
          bordered
          size="small"
          dataSource={content}
          columns={columns}
          totalCount={totalElements}
          onChange={onTableChange}
          actionProps={{
            onHandleWatch,
          }}
          customPagination={rest}
        />
      </CustomCard>
    </InformationalBlock>
  );
};
