export const normalizeValue = (val: number, prev: number) => {
  if (
    val > 1 ||
    val < 0 ||
    String(val).length > 4 ||
    String(val).includes('-')
  ) {
    return prev;
  }
  return val;
};
