import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Form, Spin, notification } from 'antd';

import { skipToken } from '@reduxjs/toolkit/dist/query';

import { getWaterObjectCardTypeAndComponent } from './getWaterObjectCardType';
import { Breadcrumb, PrimaryButton, Tabs } from '@features/ui-kit';
import { useReferenceControllerFindByObjectCategoryIdQuery } from '@store/api/referenceApi';
import {
  useSystemObjectControllerFindBySystemObjectIdQuery,
  useSystemObjectControllerUpdateSystemObjectMutation,
} from '@store/api/systemObjectApi';
import dayjs from 'dayjs';
import {
  WearSumModal,
  WorkMarkModal,
  WorkVolumeModal,
} from '@features/waterObject/waterObjectCard/shared/modals';
import { useTranslation } from 'react-i18next';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { SystemObject } from '@models/systemObject/systemObject';
import { WaterObjectDataSetTypes } from '@models/waterObject/enums/waterObjectTypesEnum';
import { useWaterObjectModals } from './shared/modals/useWaterObjectModals';
import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';

export const WaterObjectCard = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: any }>();
  const { setHeaderTitle } = useHeaderTitle();
  const { t } = useTranslation(undefined, { keyPrefix: 'systemObject.card' });

  const { isShowModal: isShowWearSumModal } = useWaterObjectModals(
    WaterMainModalTypes.WEAR_SUM,
  );

  const { isShowModal: isShowWorkMarkModal } = useWaterObjectModals(
    WaterMainModalTypes.MARK,
  );

  const [update, { data }] =
    useSystemObjectControllerUpdateSystemObjectMutation();
  const { data: waterObject, isLoading } =
    useSystemObjectControllerFindBySystemObjectIdQuery(id ? { id } : skipToken);
  const { data: objectCategory } =
    useReferenceControllerFindByObjectCategoryIdQuery(
      waterObject?.objectCategoryId
        ? {
            id: String(waterObject.objectCategoryId),
          }
        : skipToken,
    );

  const onFinish = async (values: SystemObject) => {
    const data: SystemObject = await form.getFieldsValue(true);
    try {
      values.dateStart = dayjs(values.dateStart).utc().format('YYYY-MM-DD');
      update({ systemObjectDto: { ...data, ...values } })
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Данные успешно обновлены',
          });
        });
    } catch (err) {
      console.log('Err: ', err);
    }
  };

  const { tabs } = getWaterObjectCardTypeAndComponent(objectCategory);

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (waterObject) {
      form.setFieldsValue({
        ...waterObject,
        dateStart: waterObject.dateStart ? dayjs(waterObject.dateStart) : null,
        gis: waterObject?.gis
          ? WaterObjectDataSetTypes[
              waterObject?.gis as keyof typeof WaterObjectDataSetTypes
            ]
          : '',
        collectedWater:
          waterObject?.collectedWater && Number(waterObject?.collectedWater),
        dischargedWater:
          waterObject?.dischargedWater && Number(waterObject?.dischargedWater),
        status: {
          ...waterObject?.status,
          facingId: waterObject?.status?.facing?.id,
          measurementTypeId: waterObject?.status?.measurementType?.id,
          formId: waterObject?.status?.form?.id,
          managementMethodsId: waterObject?.status?.managementMethods?.id,
          soilId: waterObject?.status?.soil?.id,
          typeСoatingId: waterObject?.status?.typeСoating?.id,
          materialId: waterObject?.status?.material?.id,
          waterQualityId: waterObject?.status?.waterQuality?.id,
          fillModeId: waterObject?.status?.fillMode?.id,
          resetModeId: waterObject?.status?.resetMode?.id,
          waterBalanceId: waterObject?.status?.waterBalance?.id,
          waterLvlRegulationId: waterObject?.status?.waterLvlRegulation?.id,
        },
      });
    }
  }, [waterObject]);

  useEffect(() => {
    if (objectCategory) {
      setHeaderTitle(
        t('header', {
          type: objectCategory.accountingGroup.accountingGroupName,
        }),
      );
    }
  }, [objectCategory]);

  return (
    <>
      <Breadcrumb
        returnButton
        items={[
          {
            title: 'Карточка',
          },
        ]}
      />
      <Spin spinning={isLoading}>
        <Form<SystemObject>
          form={form}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
        >
          <Tabs items={tabs} onChange={handleTabChange} />
          {!(
            activeTab === 'relatedObjects' ||
            activeTab === 'operationalBlock' ||
            activeTab === 'documents'
          ) && (
            <PrimaryButton
              style={{ marginTop: 16, float: 'right' }}
              size="large"
              htmlType="submit"
            >
              Сохранить
            </PrimaryButton>
          )}
          {isShowWorkMarkModal && <WorkMarkModal />}
          {isShowWearSumModal && <WearSumModal />}
          {<WorkVolumeModal />}
        </Form>
      </Spin>
    </>
  );
};
