import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContractualWatterSuplyPlanQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAnnualPlanYearlyTableFilters } from '../useAnnualPlanYearlyTableFilters';
import styles from './AnnualPlanYearlyTableContainer.module.scss';
import { mapAnnualPlanYearlyColumns } from './mapAnnualPlanYearlyColumns';
import { mapAnnualPlanYearlyData } from './mapAnnualPlanYearlyData';

export const AnnualPlanYearlyTableContainer = () => {
  const { t } = useTranslation();
  const { Column, ColumnGroup } = Table;
  const { filters } = useAnnualPlanYearlyTableFilters();

  const { data: planData = [] } = useContractualWatterSuplyPlanQuery(
    isEmpty(filters) ? skipToken : filters,
  );

  const dataSource = useMemo(
    () => mapAnnualPlanYearlyData(planData),
    [planData],
  );

  const columns = useMemo(
    () => mapAnnualPlanYearlyColumns(planData),
    [planData],
  );

  return (
    <Table
      pagination={false}
      scroll={{ x: 3500 }}
      bordered
      className={styles.table}
      size="small"
      dataSource={dataSource}
    >
      <ColumnGroup>
        <Column
          dataIndex="name"
          title="Наименование"
          align="center"
          fixed="left"
          width={300}
        />
        <Column dataIndex="area" title="Площ. хоз. дог. в/п га." width={130} />

        <>
          {columns?.map((column) => {
            return (
              <ColumnGroup
                title={t(`months.${column.monthName}`)}
                key={column.monthName}
                align="center"
              >
                <Column
                  width={110}
                  title="I"
                  dataIndex={column.firstDataIndex}
                  align="center"
                />
                <Column
                  width={110}
                  title="II"
                  dataIndex={column.secondDataIndex}
                  align="center"
                />
                <Column
                  width={110}
                  title="III"
                  dataIndex={column.thirdDataIndex}
                  align="center"
                />
                <Column
                  width={110}
                  title="Итого"
                  dataIndex={column.quarterTotalDataIndex}
                  align="center"
                />
                <Column
                  width={170}
                  title="Нарост. итогом"
                  dataIndex={column.totalAreaDataIndex}
                  align="center"
                />
              </ColumnGroup>
            );
          })}
        </>

        <Column
          title="Нарост. итогом за год"
          width={130}
          dataIndex="totalArea"
          align="center"
        />
      </ColumnGroup>
    </Table>
  );
};
