import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const DeleteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M5.28516 5.28589L14.7132 14.714M5.28516 14.714L14.7132 5.28589"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
