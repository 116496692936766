import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const CardIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H12C15.7712 0 17.6569 0 18.8284 1.02512C19.672 1.76322 19.9082 2.82522 19.9743 4.59375H0.0257227C0.0918527 2.82522 0.328033 1.76322 1.17157 1.02512C2.34315 0 4.22876 0 8 0ZM12 14H8C4.22876 14 2.34315 14 1.17157 12.9749C0 11.9498 0 10.2998 0 7C0 6.61334 0 6.24925 0.00188994 5.90625H19.9981C20 6.24925 20 6.61334 20 7C20 10.2998 20 11.9498 18.8284 12.9749C17.6569 14 15.7712 14 12 14ZM4 9.84375C3.58579 9.84375 3.25 10.1376 3.25 10.5C3.25 10.8624 3.58579 11.1563 4 11.1563H8C8.4142 11.1563 8.75 10.8624 8.75 10.5C8.75 10.1376 8.4142 9.84375 8 9.84375H4ZM9.75 10.5C9.75 10.1376 10.0858 9.84375 10.5 9.84375H12C12.4142 9.84375 12.75 10.1376 12.75 10.5C12.75 10.8624 12.4142 11.1563 12 11.1563H10.5C10.0858 11.1563 9.75 10.8624 9.75 10.5Z"
        fill="white"
      />
    </svg>
  );
};
