import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { CustomTableColumnType } from '@enums/table';
import { CustomTableColumnHeaderType } from '@enums/table/customTableColumnHeaderType';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useContractualWaterSupplyTableColumns = (types: string[]) => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 60,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Бассейн',
      dataIndex: 'basinName',
      align: 'center',
      width: 50,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Название оросительной системы',
      dataIndex: 'name',
      align: 'center',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Факт площадь посева с/х культур, тыч га',
      dataIndex: 'actualCultivatedArea',
      align: 'center',
      width: 100,
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: false,
    },
    {
      title: 'Выполнение хоздоговорной водоподачи тыс.м3',
      dataIndex: 'plantingCount',
      align: 'center',
      xtype: CustomTableColumnType.String,
      children: [
        {
          title: 'План',
          dataIndex: 'contractualWaterSupplyPlan',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
        },
        {
          title: 'Факт',
          dataIndex: 'contractualWaterSupplyFact',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
        },
        {
          title: '%',
          dataIndex: 'completionPercentage',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.RenderCustom,
          renderCustom: ({ record }) => {
            return (
              <Row justify="center">
                <Col>{(record?.completionPercentage ?? 0) + '%'}</Col>
              </Row>
            );
          },
        },
      ],
      width: 50,
    },
    // {
    //   title: 'Тариф, тыс.сом /тыс м3',
    //   dataIndex: 'tariffAverage',
    //   align: 'center',
    //   width: 100,
    // },
    {
      title: 'Оплачено на сумму, тыс сом',
      dataIndex: 'amountPaid',
      align: 'center',
      width: 100,
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: false,
    },
    {
      title: 'Выставлено на оплату, тыс. сом',
      dataIndex: 'invoicedAmount',
      align: 'center',
      width: 100,
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: false,
    },
    {
      title: 'Гектарополивы, тыс га',
      dataIndex: 'hectaropolis',
      align: 'center',
      xtype: CustomTableColumnType.String,
      children: [
        {
          title: 'план',
          dataIndex: 'hectareIrrigationPlan',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
        },
        {
          title: 'факт',
          dataIndex: 'hectareIrrigationFact',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.DecimalEdit,
          editableDecimalCell: false,
        },
        {
          title: '%',
          dataIndex: 'hectareIrrigationPercentage',
          align: 'center',
          width: 90,
          xtype: CustomTableColumnType.RenderCustom,
          renderCustom: ({ record }) => {
            return (
              <Row justify="center">
                <Col>{(record?.hectareIrrigationPercentage ?? 0) + '%'}</Col>
              </Row>
            );
          },
        },
      ],
      width: 100,
    },
    {
      title: 'Политые площади',
      dataIndex: 'waterArea', // waterAreas оригинальное название, данные приходят в виде обьекта
      align: 'center',
      xtype: CustomTableColumnType.String,
      children: types.map((type: string) => {
        return {
          title: type,
          dataIndex: `waterAreas.${type}`,
          align: 'center',
          width: 100,
          xtype: CustomTableColumnType.String,
        };
      }) as TableColumn[],
      width: 100,
    },
    {
      title: 'Поливная норма. м3/га',
      dataIndex: 'irrigationRate',
      align: 'center',
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: false,
      width: 100,
    },
    {
      title: 'Оросительная норма. м3/га',
      dataIndex: 'irrigationNorm',
      align: 'center',
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: false,
      width: 100,
    },
  ];
  return { columns };
};
