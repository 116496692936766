import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BigQuestionMarkIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="44" cy="44" r="42" stroke="#C9DAE1" strokeWidth="4" />
      <path
        d="M39.4883 55.1055V53.582C39.4883 52.0195 39.6348 50.6816 39.9277 49.5684C40.2207 48.4355 40.7285 47.3809 41.4512 46.4043C42.1934 45.4277 43.2188 44.3926 44.5273 43.2988C46.0508 42.0293 47.252 40.9551 48.1309 40.0762C49.0293 39.1777 49.6738 38.2793 50.0645 37.3809C50.4551 36.4824 50.6504 35.3984 50.6504 34.1289C50.6504 32.0977 49.9961 30.5449 48.6875 29.4707C47.3789 28.377 45.5332 27.8301 43.1504 27.8301C41.2168 27.8301 39.498 28.0742 37.9941 28.5625C36.4902 29.0508 35.0449 29.6367 33.6582 30.3203L31.959 26.3945C33.5605 25.5547 35.2988 24.8711 37.1738 24.3438C39.0488 23.8164 41.1387 23.5527 43.4434 23.5527C47.2129 23.5527 50.1328 24.4902 52.2031 26.3652C54.2734 28.2207 55.3086 30.7793 55.3086 34.041C55.3086 35.8574 55.0156 37.4102 54.4297 38.6992C53.8438 39.9688 53.0137 41.1406 51.9395 42.2148C50.8652 43.2695 49.5957 44.3926 48.1309 45.584C46.8418 46.6777 45.8555 47.6445 45.1719 48.4844C44.4883 49.3047 44.0195 50.1543 43.7656 51.0332C43.5117 51.8926 43.3848 52.9277 43.3848 54.1387V55.1055H39.4883ZM38.1992 63.9531C38.1992 62.5859 38.5215 61.6191 39.166 61.0527C39.8105 60.4668 40.6406 60.1738 41.6562 60.1738C42.6133 60.1738 43.4238 60.4668 44.0879 61.0527C44.7715 61.6191 45.1133 62.5859 45.1133 63.9531C45.1133 65.3008 44.7715 66.2871 44.0879 66.9121C43.4238 67.5176 42.6133 67.8203 41.6562 67.8203C40.6406 67.8203 39.8105 67.5176 39.166 66.9121C38.5215 66.2871 38.1992 65.3008 38.1992 63.9531Z"
        fill="#87ADBD"
      />
    </svg>
  );
};
