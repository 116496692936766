import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useRelatedObjectsColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'index',
      title: '№',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 60,
    },
    {
      dataIndex: 'code',
      title: 'Код',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 160,
    },
    {
      dataIndex: 'name',
      title: 'Наименование',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 355,
    },
    {
      dataIndex: 'coordinates',
      title: 'Пикетные координаты',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 355,
    },
    {
      dataIndex: 'appointmentId',
      title: 'Назначение',
      xtype: CustomTableColumnType.CommonReference,
      align: 'left',
      width: 355,
    },
    {
      dataIndex: 'technicalStatusId',
      title: 'Статус тех. сост.',
      xtype: CustomTableColumnType.TechnicalStatus,
      align: 'left',
      width: 197,
    },
    {
      dataIndex: 'operationalStatusId',
      title: 'Статус экспл. сост.',
      xtype: CustomTableColumnType.OperationalStatus,
      align: 'left',
      width: 197,
    },
    {
      dataIndex: 'actions',
      width: 95,
      title: 'Действия',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.EditWithoutText],
      align: 'left',
    },
  ];

  return { columns };
};
