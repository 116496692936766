import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Col, Row, Table } from 'antd';

import { useAnnualWaterSupplyTableFilters } from '@features/annualApplication/annualWaterSupplyTable/useAnnualWaterSupplyTableFilters';
import { ReservoirMonitoringMask } from '@features/monitoring/reservoirMonitoring/reservoirMonitoringMask/ReservoirMonitoringMask';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetWaterConsumptionQuery } from '@store/gisproApi';

import styles from './AnnualWaterSupplyTable.module.scss';
import { useAnnualWaterSupplyTableColumns } from './useAnnualWaterSupplyTableColumns';

export const AnnualWaterSupplyTableContainer: FC = () => {
  const { filters } = useAnnualWaterSupplyTableFilters();
  const {
    data: contentApi,
    isLoading,
    isFetching,
  } = useGetWaterConsumptionQuery(
    filters.district && filters.startDate && filters.endDate
      ? {
          district: filters.district || '',
          startDate: filters.startDate || '',
          endDate: filters.endDate || '',
        }
      : skipToken,
  );
  const { columns } = useAnnualWaterSupplyTableColumns(
    contentApi?.irrigationSystems,
  );

  const isTableShow = useMemo(
    () => Boolean(filters.district && filters.startDate && filters.endDate),
    [filters],
  );

  const dataSource = useMemo(() => {
    if (!contentApi) return [];
    const reports = contentApi?.reports.reduce((acc: any, item) => {
      const newReport = {
        name: item.name,
        ...item.systems,
      };
      acc.push(newReport);
      return acc;
    }, []);
    const sum = [
      {
        name: <strong>Сумма Ассоц:</strong>,
        ...contentApi?.sum?.association,
      },
      { name: <strong>Сумма Физ:</strong>, ...contentApi?.sum?.individual },
      { name: <strong>Сумма Юр:</strong>, ...contentApi?.sum?.legal },
      { name: <strong>Итого:</strong>, ...contentApi?.sum?.all },
    ];
    return [...reports, ...sum];
  }, [contentApi]);
  return (
    <Row>
      {!isTableShow ? (
        <ReservoirMonitoringMask maskText="Для начала работы выберите область, район и даты" />
      ) : (
        <Row justify="space-between" gutter={[0, 10]} className={styles.table}>
          <Col span={24}>
            <Table
              pagination={false}
              dataSource={dataSource}
              loading={isLoading || isFetching}
              columns={columns as any}
              scroll={{ x: 500 }}
              size="small"
              bordered
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};
