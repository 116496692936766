import { Form, Radio, RadioGroupProps } from 'antd';
import { Rule } from 'antd/es/form';

import styles from './PriorityGroup.module.scss';

type IPriorityGroupProps = RadioGroupProps & {
  required?: boolean;
  rules?: Rule[];
};
export const PriorityGroup: React.FC<IPriorityGroupProps> = ({
  required = true,
  rules,
  ...props
}) => {
  return (
    <Form.Item
      rules={rules}
      name="priority"
      label="Приоритет"
      required={required}
    >
      <Radio.Group className={styles.priorityGroup} {...props}>
        <Radio.Button className={styles.priorityBtn} value="high">
          Высокий
        </Radio.Button>
        <Radio.Button className={styles.priorityBtn} value="medium">
          Средний
        </Radio.Button>
        <Radio.Button className={styles.priorityBtn} value="low">
          Обычный
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
