import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Form, notification, Row } from 'antd';

import { Routes } from '@enums/routes';
import { InformationalBlock } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateTaskRegularEventBlockMutation,
  useGetRegularEventByIdQuery,
} from '@store/gisproApi';
import { getRegularEventBlockSelector } from '@store/selectors';
import dayjs from 'dayjs';
import { isInteger, toInteger } from '@utils/utils';
import clone from 'lodash/clone'; // TODO: change lodash to native implementation

import { LeftBlock, RightBlock } from './blocks';
import styles from './RegularEventsCard.module.scss';

export const CreateRegularEventCard: FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const regularEventBlock = useSelector(getRegularEventBlockSelector);

  const { data: regularEvent } = useGetRegularEventByIdQuery(
    id && isInteger(parseInt(id, 10)) ? parseInt(id, 10) : skipToken,
  );

  const navigate = useNavigate();
  const [createRegularTask] = useCreateTaskRegularEventBlockMutation();

  const onFinish = (values: any) => {
    createRegularTask({
      ...values,
      title: regularEventBlock.title,
      executorId: toInteger(regularEventBlock?.executor?.id),
      systemObjectId: values.systemObjectId || null,
      systemId: toInteger(values.systemId) || regularEventBlock.system?.id,
      isTemplate: false,
      inspectionDate: dayjs(values.inspectionDate).utc().format('YYYY-MM-DD'),
      workTypeId: toInteger(values.workTypeId),
      districtId: toInteger(values.districtId),
      attachments: regularEventBlock.attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
    })
      .then(({ data }: any) => {
        navigate(generatePath(Routes.RegularEvent, { id: data.id }));
        notification.success({
          message: 'Вы успешно создали задачу',
        });
      })
      .catch((err) => {
        console.log('err is ', err);
        notification.error({
          message: 'Не удалось создать задачу',
        });
      });
  };

  useEffect(() => {
    const setFormFields = (data: any) => {
      const dataClone = clone(data);
      dataClone.executorId = toInteger(dataClone?.executor?.id);
      dataClone.regionId = toInteger(dataClone?.region?.id);
      dataClone.categoryId = toInteger(dataClone?.category?.id);
      dataClone.workTypeId = toInteger(dataClone?.workType?.id);
      dataClone.districtId = toInteger(dataClone?.district?.id);
      dataClone.inspectionDate = dayjs(dataClone?.inspectionDate);
      dataClone.systemId = dataClone.system?.id;
      dataClone.systemObjectId = toInteger(dataClone.systemObject?.id);
      form.setFieldsValue(dataClone);
    };
    if (regularEvent) {
      setFormFields(regularEvent);
    } else if (regularEventBlock.isTemplate) {
      setFormFields(regularEventBlock);
    }
  }, [regularEventBlock, regularEvent]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      disabled={!regularEventBlock.title}
      layout="vertical"
      className={styles.form}
      initialValues={{
        planDates: [{}],
      }}
    >
      <InformationalBlock>
        <Row gutter={8}>
          <Col span={12}>
            <LeftBlock />
          </Col>
          <Col span={12}>
            <RightBlock />
          </Col>
        </Row>
      </InformationalBlock>
    </Form>
  );
};
