import { Divider } from '@features/ui-kit';
import {
  BalanceSheetAccountingControls
} from '@features/waterAccounting/balanceSheetAccounting/balanceSheetAccountingControls/BalanceSheetAccountingControls';
import {
  BalanceSheetAccountingTable
} from '@features/waterAccounting/balanceSheetAccounting/balanceSheetAccountingTable/BalanceSheetAccountingTable';
import { BalanceSheetPrintModal } from '@features/waterAccounting/balanceSheetAccounting/modals/balanceSheetPrintModal';
import React from 'react';

export const BalanceSheetAccounting = () => {
  return (
    <div>
      <BalanceSheetAccountingControls />
      <Divider />
      <BalanceSheetAccountingTable />
      <BalanceSheetPrintModal />
    </div>
  );
};
