import { JsonRecord } from '@models/jsonRecord';
import { IReservoirDataByMonth } from '@models/reservoir/interfaces/reservoir';
import dayjs from 'dayjs';
import { sum } from '@utils/utils';

export const mapReservoirMonitoringTableData = (
  data: IReservoirDataByMonth,
) => {
  const mappedDecadeData = data.reservoirDataDecadeDtos.reduce<JsonRecord[]>(
    (prev, curr) => {
      const decadeData = curr.reservoirDataDayDtos.reduce<JsonRecord[]>(
        (prevChild, currChild) => {
          const day = dayjs(currChild.createdAt)
            .format('YYYY-MM-DD')
            .toString();
          const outfluxOtherCountries = sum(
            [
              currChild.outflowCn ?? 0,
              currChild.outflowKz ?? 0,
              currChild.outflowTj ?? 0,
              currChild.outflowUz ?? 0,
            ].map(Number),
          );
          const outfluxVolumeOtherCountries = sum(
            [
              currChild.outfluxVolumeCn ?? 0,
              currChild.outfluxVolumeKz ?? 0,
              currChild.outfluxVolumeTj ?? 0,
              currChild.outfluxVolumeUz ?? 0,
            ].map(Number),
          );

          const dayData = {
            name: day,
            reservoirId: currChild.reservoirId,
            currentVolume: currChild.currentVolume,
            influx: currChild.influx,
            influxVolume: currChild.influxVolume,
            outflowVolume: currChild.outfluxVolume,
            outflow: currChild.outflow,
            outfluxOtherCountries,
            outfluxVolumeOtherCountries,
            outfluxKr: currChild.outfluxKr,
            outfluxVolumeKr: currChild.outfluxVolumeKr,
          };
          return [...prevChild, dayData];
        },
        [],
      );
      const outfluxOtherCountries = sum([
        curr.averageDecadeOutflowCn,
        curr.averageDecadeOutflowKz,
        curr.averageDecadeOutflowTj,
        curr.averageDecadeOutflowUz,
      ]);
      const outfluxVolumeOtherCountries = sum([
        curr.averageDecadeOutflowVolumeCn,
        curr.averageDecadeOutflowVolumeKz,
        curr.averageDecadeOutflowVolumeTj,
        curr.averageDecadeOutflowVolumeUz,
      ]);
      const sumDecadeOutflowVolumeOtherCountries = sum([
        curr.sumDecadeOutflowVolumeCn,
        curr.sumDecadeOutflowVolumeKz,
        curr.sumDecadeOutflowVolumeTj,
        curr.sumDecadeOutflowVolumeUz,
      ]);
      const sortedDecadeData = decadeData.sort(
        (a, b) =>
          (new Date(a.name) as unknown as number) -
          (new Date(b.name) as unknown as number),
      );
      return [
        ...prev,
        ...sortedDecadeData,
        {
          name: 'Среднее за декаду',
          outflow: curr.averageDecadeOutflow, // сработка м3\с
          outflowVolume: curr.averageDecadeOutflowVolume, // сработка м3
          influx: curr.averageDecadeInflux, // приток м3\с
          influxVolume: curr.averageDecadeInfluxVolume, // приток м3
          outfluxKr: curr.averageDecadeOutfluxKr,
          outfluxVolumeKr: curr.averageDecadeOutfluxVolumeKr,
          outfluxOtherCountries,
          outfluxVolumeOtherCountries,
        },
        {
          name: 'Всего декада',
          outflow: curr.sumDecadeOutflowVolume,
          influx: curr.sumDecadeInfluxVolume,
          outfluxVolumeKr: curr.sumDecadeOutfluxVolumeKr,
          outfluxVolumeOtherCountries: sumDecadeOutflowVolumeOtherCountries,
        },
      ];
    },
    [],
  );

  const outfluxOtherCountries = sum([
    data.averageMonthOutflowCn,
    data.averageMonthOutflowKz,
    data.averageMonthOutflowTj,
    data.averageMonthOutflowUz,
  ]);
  const outfluxOtherCountriesStok = sum([
    data.averageMonthOutflowStokCn,
    data.averageMonthOutflowStokKz,
    data.averageMonthOutflowStokTj,
    data.averageMonthOutflowStokUz,
  ]);
  const outfluxVolumeOtherCountries = sum([
    data.sumMonthOutflowVolumeCn,
    data.sumMonthOutflowVolumeKz,
    data.sumMonthOutflowVolumeTj,
    data.sumMonthOutflowVolumeUz,
  ]);

  return [
    ...mappedDecadeData,
    {
      name: 'Cреднее месяц',
      outflow: data.averageMonthOutflow,
      influx: data.averageMonthInflux,
      outfluxKr: data.averageMonthOutfluxKr,
      outfluxOtherCountries,
    },
    {
      name: 'Среднемесячный сток',
      outflow: data.averageMonthOutflowStok,
      influx: data.averageMonthInfluxStok,
      outfluxKr: data.averageMonthOutfluxStokKr,
      outfluxOtherCountries: outfluxOtherCountriesStok,
    },
    {
      name: 'Всего месяц',
      outflowVolume: data.sumMonthOutflowVolume,
      influxVolume: data.sumMonthInfluxVolume,
      outfluxVolumeKr: data.sumMonthOutfluxVolumeKr,
      outfluxVolumeOtherCountries,
    },
  ];
};
