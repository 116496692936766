import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Radio, Row, Switch } from 'antd';

import { PriorityGroup } from '@features/ui-kit-v2/priorityGroupV2';
import { getCurrentGanttOptionsSelector } from '@store/selectors';
import { saveGanttOptions } from '@store/slices';
import { ViewMode } from 'gantt-task-react';

import styles from './CustomGanttSidebar.module.scss';

interface ICustomGanttSidebar {
  setHeaderOptions: (options: any) => void;
}
export const CustomGanttSidebar: React.FC<ICustomGanttSidebar> = ({
  setHeaderOptions,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const ganttOptions = useSelector(getCurrentGanttOptionsSelector);

  const changePriority = () => {
    dispatch(
      saveGanttOptions({
        ...ganttOptions,
        priority: form.getFieldValue('priority'),
        page: 1,
      }),
    );
  };
  const changeForm = () => {
    setHeaderOptions(form.getFieldsValue());
  };

  useEffect(() => {
    changeForm();
  }, []);

  return (
    <Form form={form} layout="vertical" className={styles.sidebar}>
      <Row gutter={30}>
        <Col>
          <PriorityGroup required={false} onChange={changePriority} />
        </Col>
        <Col>
          <Form.Item initialValue={ViewMode.Day} label="Тип формы" name="range">
            <Radio.Group
              className={styles.radioGroup}
              onChange={changeForm}
              defaultValue={ViewMode.Day}
            >
              <Radio.Button
                className={styles.radioGroupItem}
                value={ViewMode.Day}
              >
                Дни
              </Radio.Button>
              <Radio.Button
                className={styles.radioGroupItem}
                value={ViewMode.Month}
              >
                Месяц
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
