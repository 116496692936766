import { combineReducers } from 'redux';

import { configureStore } from '@reduxjs/toolkit';
import wateringPlanSlice from '@store/slices/wateringPlan/wateringPlanSlice';
import waterUsePlanSlice from '@store/slices/waterUsePlan/waterUsePlanSlice';

import { basicApi } from './basicApi';
import actsInvoicesForPaymentsSlice from './slices/actInvoicesForPayments/actInvoicesForPaymentsSlice';
import agroClimaticZoneSlice from './slices/agroClimaticZone/agroClimaticZoneSlice';
import annualApplicationSlice from './slices/annualApplication/annualApplicationSlice';
import annualPlanSlice from './slices/annualPlan/AnnualPlanSlice';
import archivariusSlice from './slices/archivarius/archivariusSlice';
import authenticationSlice from './slices/authentication/authenticationSlice';
import businessPlanSlice from './slices/businessPlan/businessPlanSlice';
import decadeAppSlice from './slices/decadeApp/decadeAppSlice';
import geoPortalSlice from './slices/geoPortal/geoPortalSlice';
import headerSlice from './slices/header/headerSlice';
import hydraulicSectionSlice from './slices/hydraulicSection/hydraulicSectionSlice';
import irrigationRegistrySlice from './slices/irrigationRegistry/irrigationRegistrySlice';
import mainConduitSlice from './slices/mainConduit/mainConduitSlice';
import manageEventsSlice from './slices/manageEvents/manageEventsSlice';
import reservoirMonitoringSlice from './slices/reservoirMonitoring/reservoirMonitoringSlice';
import sourceSlice from './slices/source/sourceSlice';
import tableSlice from './slices/table/tableSlice';
import tariffSlice from './slices/tariff/tariffSlice';
import waterAccountingSlice from './slices/waterAccounting/waterAccountingSlice';
import waterFacilitySlice from './slices/waterFacility/waterFacilitySlice';
import waterObjectSlice from './slices/waterObject/waterObjectSlice';
import waterQualitySlice from './slices/waterQuality/waterQualitySlice';
import waterRegistry from './slices/waterRegistry/waterRegistrySlice';
import waterUseSlice from './slices/waterUse/waterUseSlice';
import referenceBookSlice from './slices/referenceBook/referenceBookSlices';
import vodokhozReportSlice from '@store/slices/vodokhozReport/VodokhozReportSlice';
import irrigationSystemSlice from '@store/slices/irrigationSystem/irrigationSystemSlice';
import { localStorageMiddleware, loadStore } from './slices/header';
const rootReducer = combineReducers({
  authentication: authenticationSlice,
  irrigationRegistry: irrigationRegistrySlice,
  waterRegistry,
  table: tableSlice,
  manageEvents: manageEventsSlice,
  waterFacility: waterFacilitySlice,
  waterAccounting: waterAccountingSlice,
  mainConduit: mainConduitSlice,
  source: sourceSlice,
  decadeApp: decadeAppSlice,
  waterObject: waterObjectSlice,
  actsInvoicesForPayments: actsInvoicesForPaymentsSlice,
  agroClimaticZone: agroClimaticZoneSlice,
  annualPlan: annualPlanSlice,
  businessPlan: businessPlanSlice,
  annualApplication: annualApplicationSlice,
  archivarius: archivariusSlice,
  tariff: tariffSlice,
  waterUse: waterUseSlice,
  waterUsePlan: waterUsePlanSlice,
  reservoirMonitoring: reservoirMonitoringSlice,
  waterQuality: waterQualitySlice,
  geoPortal: geoPortalSlice,
  wateringPlan: wateringPlanSlice,
  headerSlice,
  hydraulicSection: hydraulicSectionSlice,
  referenceBook: referenceBookSlice,
  vodokhozReport: vodokhozReportSlice,
  irrigationSystem: irrigationSystemSlice,
  [basicApi.reducerPath]: basicApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: loadStore(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(basicApi.middleware, localStorageMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
