import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

import styles from './renderer.module.scss';

export function renderCountCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { day } = customColumn;
  const currDay = record.daysDtos?.find((item: any) => {
    const itemDate = dayjs(item.date).date();
    return itemDate === day;
  });
  let value;
  switch (customColumn.decadeType) {
    case 'fact':
      value = currDay?.fact;
      break;
    case 'plan':
      value = currDay?.plan;
      break;
    case 'remains':
      value = currDay?.residualValue;
      break;
    default:
      value = 0;
      break;
  }
  return <div className={styles.countWrapper}>{value || 0}</div>;
}
