import { createSelector } from '@reduxjs/toolkit';
import { AnnualApplicationState } from '@store/slices';

type WithAnnualApplicationState = {
  annualApplication: AnnualApplicationState;
};

export const annualApplicationStateSelector = (
  state: WithAnnualApplicationState,
): AnnualApplicationState => state.annualApplication;

export const getSelectedWaterUserIdSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.selectedWaterUserId;
  },
);

export const getAnnualApplicationAttachmentsSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.attachments;
  },
);

export const getSelectedFacilityIdSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.selectedFacilityId;
  },
);

export const getSelectedWaterUserTypeSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.waterUserType;
  },
);

export const getSelectedAgroClimatIdSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.selectedAgroClimatId;
  },
);
export const getShowCreatePlantingStructureModalSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.isShowCreatePlantingStructureModal;
  },
);

export const getShowCreateActualStructureModalSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.isShowCreateActualStructureModal;
  },
);

export const getSelectedActualStructureSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.selectedActualStructure;
  },
);
export const getShowApplicationAgreementModalSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.isShowAnnualApplicationAgreementModal;
  },
);

export const getAnnualTableFiltersSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.annualTableFilters;
  },
);

export const getAnnualContractualWaterSupplyFiltersSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.annualContractualWaterSupplyFilters;
  },
);

export const getAnnualWaterSupplyFiltersSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.annualWaterSupplyFilters;
  },
);

export const getConclusionContractsSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.conclusionContractsFilters;
  },
);

export const getAnnualContractSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.annualContract;
  },
);

export const getPlantingDataSelector = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.plantingDataDtos;
  },
);

export const getSelectedPlantingData = createSelector(
  annualApplicationStateSelector,
  (annualApplicationState) => {
    return annualApplicationState.selectedPlantingData;
  },
);
