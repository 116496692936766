import { workAndEventsColors } from '@constants/workAndEventsColors';
import dayjs from 'dayjs';

export const useGetTableData = (schedules: any) => {
  if (schedules.length !== 0) {
    return schedules.map((item: any) => {
      return {
        ...item,
        start: dayjs(item.planStartDate).toDate(),
        end: dayjs(item.planEndDate).toDate(),
        name: item.title,
        id: item.blockType + item.id.toString(),
        type: 'task',
        rowId: item.id,
        blockType: item.blockType,
        priority: item.priority,
        progress: 100,
        styles: {
          progressColor:
            workAndEventsColors[
              item.blockType as keyof typeof workAndEventsColors
            ],
          progressSelectedColor:
            workAndEventsColors[
              item.blockType as keyof typeof workAndEventsColors
            ],
        },
      };
    });
  }
  return [];
};
