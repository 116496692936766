import {
  GeoAttribute,
  ILayerDto,
  ILayers,
  ILayersByName,
} from '@models/geoPortal/interfaces/GeoPortal';
import { useMapControllerUpdateDataMutation } from '@store/api/geoPortalApi';
import { AppDispatch } from '@store/index';
import { getSelectedLayerSelector } from '@store/selectors';
import { setSelectedLayerAction } from '@store/slices/geoPortal/geoPortalSlice';

import { LayerType, MapEvents, MapService } from '@utils/map/Map';
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export type MapContextType = {
  map: MapService;
  currentAttribute: GeoAttribute | null; //
  editableAttribute: GeoAttribute | null;
  focusedAttribute: GeoAttribute | null;
  setSelectedLayer: (layer: ILayerDto | null) => void;
  selectedLayer: ILayerDto | null;
  setCurrentLayer: (layer: ILayerDto, data: any) => void;
  clearCurrentLayer: (layer: ILayerDto) => void;
  showLayer: (layer: ILayerDto | ILayerDto[], data: any) => void;
  hideLayer: (layer: ILayerDto | ILayerDto[], closeForcibly?: boolean) => void;
  showWMS: (layer: ILayerDto) => void;
  hideWMS: (layer: ILayerDto) => void;
  onReloadClick: (layer: ILayerDto | null, attribute: GeoAttribute) => void;
  onEditClick: (layer: ILayerDto | null, attribute: GeoAttribute) => void;
  onPointClick: (layer: ILayerDto | null, attribute: GeoAttribute) => void;
  onSaveClick: (layer: ILayerDto | null, attribute: GeoAttribute) => void;
  onCancelClick: (layer: ILayerDto | null, attribute: GeoAttribute) => void;
  openDetails: (attribute: GeoAttribute) => void;
  searchActive: boolean;
  setSearchActive: (active: boolean) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  isPreviewMap: boolean;
  toggleSetLegendLayer: (value: ILayers) => void;
  legendLayer: ILayers | null;
  showedLayers: { [id: string]: string[] | null };
  currentGroup: { attributes: string[]; name: string };
  toggleSetGroupArchiveLayer: (groupLayer: ILayersByName[]) => void;
  groupArchiveLayer: ILayersByName[];
  getCoordinateRegion: (coordinate: any[], code: string, type: string) => void;
};

type Props = {
  children?: ReactNode;
};

export const MapContext = createContext({} as MapContextType);

export const MapStore: FC<Props> = ({ children }: Props) => {
  const map = new MapService();
  const { filterKey } = useParams<{ filterKey: any }>();
  const dispatch = useDispatch<AppDispatch>();
  const [saveAttribute] = useMapControllerUpdateDataMutation();
  const selectedLayer = useSelector(getSelectedLayerSelector);
  const [showedLayers, setShowedLayers] = useState<{
    [key: string]: string[] | null;
  }>({}); // надо
  const [currentGroup, setCurrentGroup] = useState<{
    name: string;
    attributes: string[];
  }>({
    name: '',
    attributes: [],
  }); // надо
  const [currentAttribute, setCurrentAttribute] = useState<GeoAttribute | null>(
    null,
  );
  const [editableAttribute, setEditableAttribute] =
    useState<GeoAttribute | null>(null);
  const [focusedAttribute, setFocusedAttribute] = useState<GeoAttribute | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState('');
  const [searchActive, setSearchActive] = useState(false);

  const mapContextValue = useMemo(
    () => ({
      map,
    }),
    [],
  );

  const [legendLayer, setLegendLayer] = useState<ILayers | null>(null);

  const [groupArchiveLayer, setGroupArchiveLayer] = useState<ILayersByName[]>(
    [],
  );

  const toggleSetGroupArchiveLayer = (groupLayer: ILayersByName[]) => {
    setGroupArchiveLayer(groupLayer);
  };

  const toggleSetLegendLayer = (value: ILayers) => {
    setLegendLayer(value);
  };

  const setSelectedLayer = (layer: ILayerDto | null) => {
    if (layer?.datasetType === 'wms') {
      // showWMS(layer);
    } else {
      if (layer === null && selectedLayer) {
        clearCurrentLayer(selectedLayer);
      }
      dispatch(setSelectedLayerAction(layer));
    }
  };
  const addToMap = (layer: ILayerDto, data: any[]) => {
    const ids: string[] = [];

    if (!data || !data.length) return [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].coordinates || data[i].coordinates.length === 0) continue;
      const info = {
        id: data[i].geoId,
        type: LayerType.feature,
        properties: {
          ...data[i],
          indicator: data[i]?.indicators?.[0] ?? data[i]?.indicator,
        },
        geometry: {
          layerName: layer.layerName,
          type: layer.datasetType,
          coordinates: data[i].coordinates,
        },
      };
      ids.push(mapContextValue.map.addLayer(info) as string);
    }

    return ids;
  };

  const setCurrentLayer = (layer: ILayerDto, data: any[]) => {
    if (!data) return;
    if (selectedLayer) {
      clearCurrentLayer(selectedLayer);
    }
    if (currentGroup.name && !showedLayers[currentGroup.name]) {
      mapContextValue.map.removeLayer(currentGroup.attributes);
    }
    const ids = addToMap(layer, data);
    setCurrentGroup({ name: layer.layerName, attributes: ids });
    dispatch(setSelectedLayerAction(layer));
  };

  const clearCurrentLayer = (layer: ILayerDto) => {
    // если есть уже выбранный слой и он не checked, то очищаем
    if (currentGroup.name && !showedLayers[currentGroup.name]) {
      mapContextValue.map.removeLayer(currentGroup.attributes);
    }
    if (editableAttribute) {
      mapContextValue.map.cancelEditFeature(editableAttribute.geoId);
    }
    setCurrentGroup({ name: '', attributes: [] });
    dispatch(setSelectedLayerAction(null));
    setCurrentAttribute(null);
    setEditableAttribute(null);
  };

  const showLayer = (layer: ILayerDto | ILayerDto[], data: any) => {
    if (!Array.isArray(layer)) {
      const ids = addToMap(layer, data);
      setShowedLayers({ ...showedLayers, [layer.layerName]: ids });
    } else {
      const layersData: { [id: string]: string[] } = {};
      layer.forEach((item, index) => {
        layersData[item.layerName] = addToMap(item, data[index]);
      });
      setShowedLayers({ ...showedLayers, ...layersData });
    }
  };

  const hideLayer = async (
    layer: ILayerDto | ILayerDto[],
    closeForcibly: boolean = false,
  ) => {
    if (!Array.isArray(layer)) {
      if (currentGroup.name !== layer.layerName || closeForcibly) {
        const ids: string[] = showedLayers[layer.layerName] ?? [];

        mapContextValue.map.removeLayer(ids);
      }
      setShowedLayers({ ...showedLayers, [layer.layerName]: null });
    } else {
      const ids: string[] = [];

      Object.keys(showedLayers).forEach((key) => {
        const check = layer.find((item) => item.layerName === key);

        if (check) {
          if (showedLayers[key] !== null) {
            ids.push(...(showedLayers[key] as any));
          }
          setShowedLayers((prevState) => ({
            ...prevState,
            [key]: null,
          }));
        }
      });

      mapContextValue.map.removeLayer(ids);
    }
  };

  const showWMS = (layer: ILayerDto) => {
    const geoNodeLayer = mapContextValue.map.addWMSLayer(layer.layerName);
    setShowedLayers({
      ...showedLayers,
      [layer.layerName]: geoNodeLayer as any,
    });
  };

  const hideWMS = (layer: ILayerDto) => {
    const geoNodeLayer = showedLayers[
      layer.layerName
    ] as unknown as L.TileLayer.WMS;
    mapContextValue.map.removeWMSLayer(geoNodeLayer);
    setShowedLayers({ ...showedLayers, [layer.layerName]: null });
  };

  const openDetails = (attribute: GeoAttribute) => {
    setCurrentAttribute(attribute);
  };

  const onReloadClick = (layer: ILayerDto | null, attribute: GeoAttribute) => {
    mapContextValue.map.resetFeature(
      attribute.geoId,
      layer?.datasetType ?? 'Polygon',
    );
    setCurrentAttribute(attribute);
    setEditableAttribute(attribute);
  };

  const onPointClick = (layer: ILayerDto | null, attribute: GeoAttribute) => {
    mapContextValue.map.getViewFeature(attribute.geoId);
  };

  const getCoordinateRegion = (
    coordinate: any[],
    code: string,
    type: string,
  ) => {
    mapContextValue.map.getViewCustomFeature(coordinate, code, type);
  };

  const onEditClick = (layer: ILayerDto | null, attribute: GeoAttribute) => {
    if (editableAttribute) {
      mapContextValue.map.cancelEditFeature(editableAttribute.geoId);
    }
    mapContextValue.map.editFeature(
      attribute.geoId,
      layer?.datasetType ?? 'Polygon',
    );
    onPointClick(layer, attribute);
    setCurrentAttribute(attribute);
    setEditableAttribute(attribute);
    openDetails(attribute);
  };

  const onSaveClick = (layer: ILayerDto | null, attribute: GeoAttribute) => {
    const feature = mapContextValue.map.submitFeature(attribute.geoId);
    // TODO: save feature.geometry.coordinates
    if (feature?.geometry?.coordinates) {
      saveAttribute({
        body: {
          data: [
            {
              id: attribute.geoId,
              layerName: layer?.layerName ?? '',
              coordinates: feature?.geometry?.coordinates,
            },
          ],
        },
      });
    } else {
      if (editableAttribute) {
        mapContextValue.map.cancelEditFeature(editableAttribute.geoId);
      }
    }
    setCurrentAttribute(null);
    setEditableAttribute(null);
  };

  const onCancelClick = (layer: ILayerDto | null, attribute: GeoAttribute) => {
    mapContextValue.map.cancelEditFeature(attribute.geoId);
    setCurrentAttribute(null);
    setEditableAttribute(null);
  };

  const isPreviewMap = useMemo(() => {
    const [_, type, code] = filterKey.split(':');
    const rexegp = new RegExp(/^1\d{2}$/);
    if (['irrigation-fund', 'monitoring'].includes(type)) {
      return true;
    } else if (['irrigation-system', 'water-user'].includes(type)) {
      return false;
    } else if (rexegp.test(code) || code === '000') {
      return true;
    }
    return false;
  }, [filterKey]);

  useEffect(() => {
    mapContextValue.map.subscribe(MapEvents.objectClick, (feature: any) => {
      openDetails(feature.properties);
    });
    mapContextValue.map.subscribe(MapEvents.objectHover, (feature: any) => {
      setFocusedAttribute(feature.properties);
    });
    mapContextValue.map.subscribe(MapEvents.objectUnhover, (feature: any) => {
      setFocusedAttribute(null);
    });
  }, []);

  return (
    <MapContext.Provider
      value={{
        map: mapContextValue.map,
        currentAttribute,
        editableAttribute,
        focusedAttribute,
        onReloadClick,
        setSelectedLayer,
        selectedLayer,
        setCurrentLayer,
        clearCurrentLayer,
        showLayer,
        hideLayer,
        showWMS,
        hideWMS,
        onEditClick,
        onPointClick,
        onSaveClick,
        onCancelClick,
        openDetails,
        searchActive,
        setSearchActive,
        searchValue,
        setSearchValue,
        isPreviewMap,
        toggleSetLegendLayer,
        legendLayer,
        showedLayers,
        currentGroup,
        toggleSetGroupArchiveLayer,
        groupArchiveLayer,
        getCoordinateRegion,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
