import { monthsTranslate } from '@constants/month';
import { UserRoles } from '@enums/roles';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

const quarters = [
  {
    title: 'I Квартал',
    months: ['Январь', 'Февраль', 'Март'],
  },
  {
    title: 'II Квартал',
    months: ['Апрель', 'Май', 'Июнь'],
  },
  {
    title: 'III Квартал',
    months: ['Июль', 'Август', 'Сентябрь'],
  },
  {
    title: 'IV Квартал',
    months: ['Октябрь', 'Ноябрь', 'Декабрь'],
  },
];

export const useContractualWaterSupplyPlanColumns = (
  userRole: UserRoles = UserRoles.ROLE_RUVH,
  canEdit: boolean = false,
) => {
  const isShowRole =
    userRole === UserRoles.ROLE_CA || userRole === UserRoles.ROLE_GUVH;

  const quarterColumns: TableColumn[] = quarters.flatMap(
    (quarter): TableColumn[] => {
      const quarterNumber = quarter.title.split(' ')[0];
      const el: TableColumn[] = [
        {
          dataIndex: quarter.title,
          title: quarter.title,
          xtype: CustomTableColumnType.String,
          align: 'center',
          children: quarter.months.map((month) => ({
            dataIndex: month,
            title: month,
            xtype: CustomTableColumnType.String,
            align: 'center',
            children: [
              {
                dataIndex: 'volume',
                title: 'I',
                decimalIndex: ['planItemDtos', `${monthsTranslate[month]}-1`],
                editableDecimalCell: (record) => {
                  if (!canEdit) {
                    return false;
                  }
                  if (isShowRole) {
                    return false;
                  }
                  if (record.systemTypeName?.includes('Итого')) {
                    return false;
                  }
                  return record.indicatorType === 'volume';
                },
                width: 60,
                xtype: CustomTableColumnType.DecimalEdit,
              },
              {
                dataIndex: 'volume',
                title: 'II',
                width: 60,
                decimalIndex: ['planItemDtos', `${monthsTranslate[month]}-2`],
                xtype: CustomTableColumnType.DecimalEdit,
                editableDecimalCell: (record) => {
                  if (!canEdit) {
                    return false;
                  }
                  if (isShowRole) {
                    return false;
                  }
                  if (record.systemTypeName?.includes('Итого')) {
                    return false;
                  }
                  return record.indicatorType === 'volume';
                },
              },
              {
                dataIndex: 'volume',
                title: 'III',
                width: 60,
                xtype: CustomTableColumnType.DecimalEdit,
                decimalIndex: ['planItemDtos', `${monthsTranslate[month]}-3`],
                editableDecimalCell: (record) => {
                  if (!canEdit) {
                    return false;
                  }
                  if (isShowRole) {
                    return false;
                  }
                  if (record.systemTypeName?.includes('Итого')) {
                    return false;
                  }
                  return record.indicatorType === 'volume';
                },
              },
            ],
          })),
        },
        {
          dataIndex: `${quarterNumber}`,
          title: `Итого за ${quarter.title}`,
          xtype: CustomTableColumnType.String,
          width: 150,
          align: 'center',
        },
        {
          dataIndex: `cumulativeTotal-${quarterNumber}`,
          title: 'Нараст. итогом',
          width: 150,
          align: 'center',
          xtype: CustomTableColumnType.DecimalEdit,
          key: 'cumulativeTotal',
          editableDecimalCell: false,
        },
      ];
      return el;
    },
  );

  const columns: TableColumn[] = [
    {
      title: 'Система',
      dataIndex: 'systemTypeName',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemTypeRowIndex) {
          if (record.systemTypeRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemTypeRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Наименование системы',
      dataIndex: 'systemName',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      dataIndex: 'area',
      title: 'Площадь охвата договорами тыс.га',
      xtype: CustomTableColumnType.String,
      width: 150,
      align: 'center',
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Ед. изм.',
      dataIndex: 'picket',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'type',
      align: 'left',
      width: 90,
      renderCustom: ({ record }) => {
        return (
          <div>{record.indicatorType === 'volume' ? 'тыс.м3' : 'т.сом'}</div>
        );
      },
    },

    ...quarterColumns,
  ];
  if (isShowRole) {
    columns.unshift({
      title: 'Наименование района',
      dataIndex: 'districtName',
      xtype: CustomTableColumnType.String,
      key: 'districtName',
      align: 'left',
      width: 150,
      onCell: (record, rowIndex) => {
        if (record?.districtRowIndex) {
          if (record.districtRowIndex?.start === rowIndex) {
            return { rowSpan: record.districtRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    });
  }

  return { columns };
};
