import { Card, Divider, Typography } from 'antd';

import { SummaryCardColor } from '@enums/summaryCardColor';

import styles from './SummaryCard.module.scss';

type SummaryCardPropsType = {
  sum: string | number;
  title?: string;
  subtitle: string;
  sumSubtitle: string;
  size?: 'small' | 'medium' | 'large';
  color?: SummaryCardColor;
};

export const SummaryCard: React.FC<SummaryCardPropsType> = ({
  sum = '0',
  title = '',
  subtitle = '',
  sumSubtitle = '',
  size = 'medium',
  color = SummaryCardColor.Purple,
}) => {
  const smallClassName = size === 'small' ? styles.small : '';
  const meduimClassName = size === 'medium' ? styles.medium : '';
  const largeClassName = size === 'large' ? styles.large : '';

  return (
    <Card
      className={`${styles.summaryCard} ${styles[color]} ${smallClassName} ${meduimClassName} ${largeClassName}`}
    >
      <div className={styles.innerBlock}>
        <span className={styles.statistics}>{sum}</span>
        <div className={styles.centralizeText}>
          <Typography.Text className={styles.statisticsText}>
            {sumSubtitle || 'сом'}
          </Typography.Text>
        </div>

        <Divider className={styles.divider} />
        <div className={styles.centralizeText}>
          <Typography.Title
            level={title.length > 8 ? 3 : 2}
            style={{ color: 'white', marginBottom: 0 }}
          >
            {title}
          </Typography.Title>
          <Typography.Text className={styles.statisticsText}>
            {subtitle}
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
};
