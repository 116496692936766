import { Table, Typography } from 'antd';

import { DistrictDtos } from '@store/gisproApi';

interface IEstimatedFinancialPlanSummary {
  data: any;
  columns: any;
}

type DataArgType = {
  [key: string]: number;
};

type ColumnType = {
  dataIndex: string;
  children?: ColumnType[];
};

export const ConclusionContractsTableSummary: React.FC<
  IEstimatedFinancialPlanSummary
> = ({ data, columns }) => {
  const processChildColumn = (
    child: ColumnType,
    dataArg: Array<DistrictDtos>,
    result: DataArgType,
  ) => {
    if (child.children) {
      child.children.forEach((grandChild) => {
        processChildColumn(grandChild, dataArg, result);
      });
    } else {
      dataArg.forEach((arg: any) => {
        const { dataIndex } = child;
        if (typeof arg[dataIndex] !== 'string' && !(dataIndex in result)) {
          result[dataIndex] = 0;
        }
        if (typeof arg[dataIndex] !== 'string') {
          result[dataIndex] += arg[dataIndex] || 0;
        }
      });
    }
  };

  const summaryFn = (dataArg: Array<DistrictDtos>): DataArgType => {
    return columns.reduce((result: DataArgType, item: ColumnType) => {
      if (item.children) {
        item.children.forEach((child) => {
          processChildColumn(child, dataArg, result);
        });
      } else {
        processChildColumn(item, dataArg, result);
      }
      return result;
    }, {});
  };

  const allRow = columns
    .map((item: any) => {
      const res = [];
      if (item.children) {
        item.children.forEach((child: any) => {
          res.push(child);
        });
      } else {
        res.push(item);
      }
      return res;
    })
    .flat();
  const generalData: any = summaryFn(data);
  return (
    <Table.Summary.Row>
      {allRow.map((item: any, index: number) => {
        if (item.dataIndex === 'id') {
          return (
            <Table.Summary.Cell colSpan={2} key={0} index={0} align="center">
              <Typography.Text strong>Итого:</Typography.Text>
            </Table.Summary.Cell>
          );
        }
        if (item.dataIndex === 'name') {
          return (
            <Table.Summary.Cell
              key={0}
              rowSpan={0}
              colSpan={0}
              index={0}
              align="center"
            />
          );
        }
        return (
          <Table.Summary.Cell
            key={item.dataIndex}
            index={index + 1}
            align="center"
          >
            <Typography.Text>
              <strong>{generalData[item.dataIndex]}</strong>
            </Typography.Text>
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  );
};
