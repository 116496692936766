import { createSelector } from '@reduxjs/toolkit';
import { WaterFacilityState } from '@store/slices/waterFacility';

type WithWaterFacilityState = {
  waterFacility: WaterFacilityState;
};

export const waterFacilityStateSelector = (
  state: WithWaterFacilityState,
): WaterFacilityState => state.waterFacility;

export const getShowWearSumSelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.isShowWearSum;
  },
);

export const getShowWorkMarkSelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.isShowWorkMark;
  },
);

export const getShowWorkVolumeSelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.isShowWorkVolume;
  },
);

export const getWaterFacilitySelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.waterFacility;
  },
);

export const getWaterFacilityWearSumsSelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.waterFacility?.wearSums;
  },
);

export const getWaterFacilityWorkMarksSelector = createSelector(
  waterFacilityStateSelector,
  (waterFacilityState) => {
    return waterFacilityState.waterFacility?.workMarks;
  },
);
