import { TableProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';

import { getKeyValue } from './getKeyValue';

type PropsType<RecordType> = {
  children: RecordType[];
  keyValue: string | React.Key;
  rowKey: TableProps<RecordType>['rowKey'];
  newItem: RecordType;
};

export function dataTreeReplaceByCell<RecordType extends JsonRecord>({
  children,
  keyValue,
  rowKey,
  newItem,
}: PropsType<RecordType>): RecordType[] {
  const newArray = children.map((item) => {
    if (getKeyValue(item, rowKey) === keyValue) {
      return newItem;
    }
    if (item.children) {
      return {
        ...item,
        children: dataTreeReplaceByCell({
          children: item.children,
          keyValue,
          newItem,
          rowKey,
        }),
      };
    }

    return item;
  });
  return newArray;
}
