import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSelectedWorkNotationIndexSelector,
  getShowWorkNotation,
} from '@store/selectors';
import {
  setSelectedWorkNotationIndexAction,
  setShowWorkNotationModalAction,
} from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
  setWorkNotationIndex: (index: number) => void;
  selectedWorkNotationIndex: number | null;
};

export const useWorkNotationModal = (): ReturnType => {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getShowWorkNotation);
  const selectedWorkNotationIndex = useSelector(
    getSelectedWorkNotationIndexSelector,
  );

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowWorkNotationModalAction(showModal));
  }, []);

  const setWorkNotationIndex = useCallback((index: number) => {
    dispatch(setSelectedWorkNotationIndexAction(index));
  }, []);

  return {
    isModalShown,
    setShowModal,
    setWorkNotationIndex,
    selectedWorkNotationIndex,
  };
};
