import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, TimePicker } from 'antd';

import { TooltipedInput } from '@features/ui-kit/input';
import { TableEditorProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

import 'dayjs/locale/ru';

export const EditQuentity: React.FC<TableEditorProps> = React.memo(
  ({ customColumn, record }) => {
    const { t } = useTranslation();
    const format = 'HH:mm';

    return (
      <div className={styles.editQuentity}>
        <div className={styles.railItem}>
          <Form.Item name={record.day[0].rail.time} className={styles.time}>
            <TimePicker
              className="timePicker"
              bordered={false}
              format={format}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            name={record.day[0].rail.width}
            className={styles.value}
            rules={[
              {
                required: true,
                message:
                  t('customComponents.table.cellEditor.enterValue', {
                    value: customColumn.title,
                  }) || '',
              },
            ]}
          >
            <TooltipedInput type="number" />
          </Form.Item>
        </div>
        <div className={styles.railItem}>
          <Form.Item name={record.day[1].rail.time} className={styles.railItem}>
            <TimePicker
              className="timePicker"
              bordered={false}
              format={format}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            name={record.day[1].rail.width}
            rules={[
              {
                required: true,
                message:
                  t('customComponents.table.cellEditor.enterValue', {
                    value: customColumn.title,
                  }) || '',
              },
            ]}
            className={styles.value}
          >
            <TooltipedInput type="number" />
          </Form.Item>
        </div>
      </div>
    );
  },
);
