import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Row, Space } from 'antd';

import { Routes } from '@enums/routes';
import { clearIrrigationSystemAction } from '@store/slices';

import { useWaterObjectStepperActionSelectModal } from './useWaterObjectStepperActionSelectModal';
import styles from './WaterObjectStepperActionSelectModal.module.scss';
import { CustomModal } from '@features/ui-kit';
import { NavigateButton } from '@features/ui-kit/buttons/navigateButton/NavigateButton';
import { NavCardIcon } from '@icons/NavCardIcon';
import { NavHomeIcon } from '@icons/NavHomeIcon';
import { NavGISIcon } from '@icons/NavGISIcon';

export const WaterObjectActionSelectModal: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { systemId } = useParams();

  const { isModalShown, setShowModal } =
    useWaterObjectStepperActionSelectModal();

  const onClickHome = () => {
    setShowModal(false);
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  };

  const onViewCardClick = () => {
    navigate(
      generatePath(Routes.IrrigationSystemCard, {
        systemId: systemId || '',
      }),
    );
    dispatch(clearIrrigationSystemAction());
    setShowModal(false);
  };

  const onGeoClick = () => {
    navigate(
      generatePath(Routes.geoPortalMap, {
        filterKey: `map:irrigation-system:${systemId}`,
      }),
    );
    dispatch(clearIrrigationSystemAction());
    setShowModal(false);
  };

  return (
    <CustomModal
      centered
      title="Выбор действия"
      open={isModalShown}
      onCancel={() => setShowModal(false)}
      width={400}
      footer={<span />}
    >
      <Row justify="center">
        <Space
          size="large"
          direction="vertical"
          className={styles.actionButtons}
        >
          <Row>
            <NavigateButton
              icon={NavCardIcon}
              onClick={onViewCardClick}
              title="Просмотр карточки"
            />
          </Row>

          <Row>
            <NavigateButton
              icon={NavGISIcon}
              onClick={onGeoClick}
              title="Перейти на ГИС"
            />
          </Row>

          <Row>
            <NavigateButton
              onClick={onClickHome}
              icon={NavHomeIcon}
              title="Завершить"
            />
          </Row>
        </Space>
      </Row>
    </CustomModal>
  );
};
