import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Col, Row } from 'antd';

import { AnnualWaterSupplyTableContainer } from '@features/annualApplication/annualWaterSupplyTable/AnnualWaterSupplyTableContainer';
import { AnnualWaterSupplyTableFilters } from '@features/annualApplication/annualWaterSupplyTable/AnnualWaterSupplyTableFilters';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { SectionTitle } from '@features/ui-kit-v2';

import styles from './AnnualWaterSupplyTable.module.scss';
import { HomeOutlined } from '@ant-design/icons';
import { Divider } from '@features/ui-kit';

export const AnnualWaterSupplyTable = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle('Водоподача');
  }, []);

  return (
    <div className={styles.wrapper}>
      {/* <Row gutter={12} justify="space-between">
        <Col span={24}>
          <SectionTitle
            background="cyan"
            title={t('annualApplication.table.waterSupply')}
          />
        </Col>
      </Row> */}
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('annualApplication.table.contractualWaterSupply'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <AnnualWaterSupplyTableFilters />
      <Divider />
      <AnnualWaterSupplyTableContainer />
    </div>
  );
};
