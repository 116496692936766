import React from 'react';

import { Col, Row } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './SpprOperationalTable.module.scss';

export const useSpprOperationalTableColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Категория ',
      align: 'center',
      dataIndex: 'category',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'direction',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.category.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Тип работ ',
      align: 'center',
      dataIndex: 'workType',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'workType',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.workType.title || ''}</span>
        </div>
      ),
    },
    {
      title: 'Исполнитель',
      align: 'center',
      dataIndex: 'executor',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'executor',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.executor?.fio}</span>
        </div>
      ),
    },
    {
      title: 'Объект',
      align: 'center',
      dataIndex: 'systemObject',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'systemObject',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.systemObject.name}</span>
        </div>
      ),
    },
    {
      title: 'Система',
      align: 'center',
      dataIndex: 'name',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'system',
      width: 250,
      renderCustom: ({ record }) => (
        <Row>
          <Col span={24}>{record.system.name}</Col>
        </Row>
      ),
    },
    {
      title: 'Сроки',
      align: 'center',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 250,
      xtype: CustomTableColumnType.String,
      deadlineTime: 'start',
      className: styles.deadlineColumn,
      children: [
        {
          title: 'Начало',
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          deadlineTime: 'start',
          width: 250,
          xtype: CustomTableColumnType.Deadline,
        },
        {
          title: 'Конец',
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          deadlineTime: 'end',
          width: 250,
          xtype: CustomTableColumnType.Deadline,
        },
      ],
    },
    {
      title: 'Бюджет',
      align: 'center',
      dataIndex: 'budget',
      key: 'budget',
      width: 200,
      xtype: CustomTableColumnType.Budget,
      className: styles.budgetColumn,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: '...',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch],
    },
  ];
  return { columns };
};
