import { createSelector } from '@reduxjs/toolkit';
import { IrrigationRegistryState } from '@store/slices';
import { ArchivariusState } from '@store/slices/archivarius';

type WithArchivariusState = {
  archivarius: ArchivariusState;
};

export const archivariusStateSelector = (
  state: WithArchivariusState,
): ArchivariusState => state.archivarius;

export const getArchivariusIrrigationSystemFiltersSelector = createSelector(
  archivariusStateSelector,
  (archivariusState) => {
    return archivariusState.archivariusIrrigationSystemFilters;
  },
);

export const getArchivariusWaterUserFiltersSelector = createSelector(
  archivariusStateSelector,
  (archivariusState) => {
    return archivariusState.archivariusWaterUserFilters;
  },
);

export const getConfirmArchiveId = createSelector(
  archivariusStateSelector,
  (archivariusState) => {
    return archivariusState.confirmArchiveId;
  },
);

export const getShowConfirmArchive = createSelector(
  archivariusStateSelector,
  (archivariusState) => {
    return archivariusState.isShowConfirmArchiveModal;
  },
);
