import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IAnnualApplicationFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { getAnnualTableFiltersSelector } from '@store/selectors';
import { annualApplicationActions } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IAnnualApplicationFilters>;
  setAnnualContractFilters: (
    filters: Partial<IAnnualApplicationFilters>,
  ) => void;
};

export const useAnnualApplicationTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getAnnualTableFiltersSelector);

  const setAnnualContractFilters = useCallback(
    (newFilters: Partial<IAnnualApplicationFilters>) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      setParams(params);
      const values: any = pickBy(
        newFilters,
        (v) => !isEmpty(v) || isInteger(v),
      );
      dispatch(annualApplicationActions.changeAnnualApplicationFilters(values));
    },
    [],
  );

  return { filters, setAnnualContractFilters };
};
