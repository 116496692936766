import React, { RefAttributes } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StepProps, Steps } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import styles from './CustomSteps.module.scss';

type QueryParams = {
  queryKey: string;
  queryType: any;
  onChange?: (value: SegmentedValue) => void;
};

type CustomSegmentedPropsType = RefAttributes<HTMLDivElement> & {
  steps: StepProps[];
  current?: number;
  queryParams: any;
};

export const CustomSteps: React.FC<CustomSegmentedPropsType> = ({
  steps,
  queryParams,
  current,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = Object.fromEntries(searchParams.entries());
  const currentIndex = () => {
    const index = steps.findIndex(
      (item: any) => item.value === currentSearchParams.section,
    );
    if (index === -1) {
      return 0;
    }
    return index;
  };
  return (
    <Steps
      className={styles.steps}
      current={current ?? currentIndex()}
      items={steps}
    />
  );
};
