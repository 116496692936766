import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ReferenceBookState = {
  referenceBookId: any | null;
};

const initialState: ReferenceBookState = {
  referenceBookId: null,
};

const referenceBookSlices = createSlice({
  name: 'hydraulicSection',
  initialState,
  reducers: {
    setReferenceBookIdAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        referenceBookId: action.payload,
      };
    },
  },
});

export const { setReferenceBookIdAction } = referenceBookSlices.actions;

export default referenceBookSlices.reducer;
