import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  DangerButton,
  DefaultButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import {
  CreateAssociationWaterRegistryQueryTypes,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { getFormData } from '@store/selectors';
import { clearFormDataAction, setFormData } from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { AddressDetails, EconomiesTable, EconomyDetails } from '../fields';
import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';

type AssociationMasterRegistrationSecondStepFormTypes = {
  regionId: number;
  districtId: number;
  okmotId: number;
  waterCouncilId: number;
  gisAddress: string;
};

export const AssociationMasterRegistrationSecondStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);
  const { economies = [] } = useAddEconomy();
  const onFinish = (
    values: AssociationMasterRegistrationSecondStepFormTypes,
  ) => {
    dispatch(
      setFormData({
        data: values,
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.responsiblePersons}`,
    );
  };

  const onFinishFailed = (
    err: ValidateErrorEntity<AssociationMasterRegistrationSecondStepFormTypes>,
  ) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.association,
      }),
    );
    navigate(Routes.WaterRegistryUsers);
  };

  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationAssociation}?${SectionQueryKey}=${CreateAssociationWaterRegistryQueryTypes.waterUserInfo}`,
    );
  };

  useEffect(() => {
    if (currentWaterRegistry[WaterUserTypes.association]) {
      form.setFieldsValue(currentWaterRegistry[WaterUserTypes.association]);
    }
  }, [currentWaterRegistry]);

  return (
    <Form<AssociationMasterRegistrationSecondStepFormTypes>
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <InformationalBlock title="Физический адрес">
        <Row>
          <Col span={24}>
            <AddressDetails />
          </Col>
        </Row>
      </InformationalBlock>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DangerButton
              htmlType="submit"
              size="large"
              onClick={onCancelButtonClick}
            >
              {t('createIndividualWaterRegistry.buttons.cancel')}
            </DangerButton>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <DefaultButton
                  type="default"
                  htmlType="submit"
                  onClick={onBackButtonClick}
                  size="large"
                >
                  {t('createIndividualWaterRegistry.buttons.back')}
                </DefaultButton>
              </Form.Item>
              <Form.Item>
                <PrimaryButton size="large" type="primary" htmlType="submit">
                  {t('createLegalWaterRegistry.buttons.next')}
                </PrimaryButton>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
