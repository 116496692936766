import { AllTableContainer } from './allTableContainer';
import { AllTableFilters } from './allTableFilters';

interface IPhysicalTableProps {
  isArchived?: boolean;
}

export const AllTable: React.FC<IPhysicalTableProps> = ({ isArchived = false }) => {
  return <AllTableContainer isArchived={isArchived} />;
};
