import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';

import {
  CustomModalV2,
  DefaultButtonV2,
  PrimaryButtonV2,
} from '@features/ui-kit-v2';
import { BigQuestionMarkIcon } from '@icons/BigQuestionMarkIcon';
import { BigSuccessIcon } from '@icons/BigSuccessIcon';
import { ActsInvoicesForPaymentsStatusEnum } from '@models/actsInvoicesForPayments/enums/actsInvoicesForPaymentsEnum';
import { usePayBillMutation } from '@store/gisproApi';

import styles from './ConfirmCheckModal.module.scss';
import { useConfirmCheckModal } from './useConfirmCheckModal';

export const ConfirmCheckModal: FC = () => {
  const [success, setSuccess] = useState(false);
  const { isModalShown, setShowModal, selectedActsInvoicesForPayment } =
    useConfirmCheckModal();

  const { t } = useTranslation();
  const [save, { data }] = usePayBillMutation();

  const onClose = () => {
    setShowModal(false);
  };

  const onConfirmCheck = () => {
    if (selectedActsInvoicesForPayment) {
      setSuccess(true);
      save({ id: selectedActsInvoicesForPayment.id });
    }
  };

  useEffect(() => {
    if (data && data.status === ActsInvoicesForPaymentsStatusEnum.paid) {
      setSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedActsInvoicesForPayment &&
      selectedActsInvoicesForPayment.status ===
        ActsInvoicesForPaymentsStatusEnum.paid
    ) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [selectedActsInvoicesForPayment]);

  const confirmAction = (
    <>
      <Row justify="center">
        <Col span={5}>
          <BigQuestionMarkIcon />
        </Col>
      </Row>
      <Row justify="center" className={styles.marginTop}>
        <Col span={20}>
          <Typography.Title level={3} className={styles.alignCenter}>
            {t('invoicesPayment.modal.payConfirm.title')}
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={30} justify="space-between">
        <Col>
          <DefaultButtonV2
            htmlType="submit"
            onClick={onClose}
            title={t('invoicesPayment.modal.payConfirm.cancel')}
          />
        </Col>
        <Col>
          <PrimaryButtonV2
            htmlType="submit"
            onClick={onConfirmCheck}
            title={t('invoicesPayment.modal.payConfirm.confirm')}
          />
        </Col>
      </Row>
    </>
  );

  const successAction = (
    <>
      <Row justify="center">
        <Col span={5}>
          <BigSuccessIcon />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Typography.Title level={3} className={styles.alignCenter}>
            {t('invoicesPayment.modal.paySuccess.title')}
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={30} justify="end" className={styles.marginTop}>
        <Col>
          <PrimaryButtonV2
            htmlType="submit"
            onClick={onClose}
            title={t('invoicesPayment.modal.paySuccess.cancel')}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <CustomModalV2
      centered
      open={isModalShown}
      onCancel={onClose}
      width={512}
      closeIcon={null}
      footer={false}
    >
      {!success ? confirmAction : successAction}
    </CustomModalV2>
  );
};
