import React, { FC, useEffect, useState } from 'react';

import { Col, Flex, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';

import { toInteger } from '@utils/utils';

import { useAnnualApplicationTableFilters } from '../useAnnualApplicationTableFilters';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import dayjs, { Dayjs } from 'dayjs';
import { useGetCurrentUser } from '@features/authentication';

export const typeOptions: DefaultOptionType[] = [
  {
    value: String(WaterUserEnum.INDIVIDUAL),
    label: 'Физ. лица',
  },
  {
    value: String(WaterUserEnum.LEGAL),
    label: 'Юр. лица',
  },
  {
    value: String(WaterUserEnum.ASSOCIATION),
    label: 'Ассоциации',
  },
];
export const AnnualApplicationTableFilters: FC = () => {
  const { filters, setAnnualContractFilters } =
    useAnnualApplicationTableFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();
  const [filtersState, setFiltersState] = useState<any>({});

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const onReginChange = (v: string) => {
    setFiltersState((state: any) => ({
      ...state,
      regionId: v,
      districtId: undefined,
    }));
  };

  const onFilterChange = (filterKey: keyof any, v: string | null | number) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  const onFilterButtonClick = () => {
    setAnnualContractFilters({ ...filtersState });
  };

  const onResetFilterButtonClick = () => {
    setAnnualContractFilters({
      facilityName: null,
      organizationName: null,
      agroclimaticZoneName: null,
      regionId: user?.owner?.region?.id || null,
      districtId: user?.owner?.district?.id || null,
      applicationId: null,
      waterUserType: null,
      waterUserRegNumber: null,
      year: null,
      page: 1,
      size: 10,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row gutter={15}>
      <Col>
        <TooltipedSelect
          value={
            regions.find((item) => item.id === toInteger(filtersState.regionId))
              ?.label
          }
          onChange={(v: string) => onReginChange(v)}
          allowClear
          placeholder="Область"
          options={regions}
          disabled={isDisabledRegion}
        />
      </Col>
      <Col>
        <TooltipedSelect
          allowClear
          placeholder="Район"
          options={districts}
          disabled={!filtersState.regionId || isDisabledDistrict}
          value={
            districts.find(
              (item) => item.id === toInteger(filtersState.districtId),
            )?.label
          }
          onChange={(v: string) => onFilterChange('districtId', v)}
        />
      </Col>
      <Col>
        <TooltipedSelect
          allowClear
          value={filtersState.waterUserType}
          onChange={(v: string) => onFilterChange('waterUserType', v)}
          placeholder="Тип водопользователя"
          options={typeOptions}
        />
      </Col>
      <Col>
        <TooltipedInput
          value={filtersState.applicationId}
          onChange={(v: any) => onFilterChange('applicationId', v.target.value)}
          placeholder="ID заявки"
        />
      </Col>
      <Col>
        <TooltipedInput
          value={filtersState.organizationName}
          onChange={(v: any) =>
            onFilterChange('organizationName', v.target.value)
          }
          placeholder="Название/ФИО"
        />
      </Col>
      <Col>
        <TooltipedInput
          value={filtersState.waterUserRegNumber}
          onChange={(v: any) =>
            onFilterChange('waterUserRegNumber', v.target.value)
          }
          placeholder="Регистрационный номер водопользователя"
        />
      </Col>
      <Col>
        <TooltipedInput
          value={filtersState.facilityName}
          onChange={(v: any) => onFilterChange('facilityName', v.target.value)}
          placeholder="Наименование хозяйства"
        />
      </Col>
      <Col>
        <YearPicker
          defaultValue={undefined}
          onChange={(date: Dayjs | null) =>
            onFilterChange('year', date ? date.year() : dayjs().year())
          }
          disabledDate={(current) => current && current > dayjs().endOf('year')}
          placeholder="Год"
        />
      </Col>
      <Col>
        <Flex align="center" gap="small">
          <PrimaryButton onClick={onFilterButtonClick} icon={<FilterIcon />} />
          <PrimaryButton
            onClick={onResetFilterButtonClick}
            icon={<ResetFilterIcon />}
          />
        </Flex>
      </Col>
    </Row>
  );
};
