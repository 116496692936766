import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import { IAgroClimaticZoneFilters } from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllIrrigationSystemListQuery } from '@store/gisproApi';

import { useAgroClimaticZoneTableFilters } from '../useAgroClimaticZoneTableFilters';
import styles from './AgroClimaticZoneTableFilters.module.scss';
import { PrimaryButton, TooltipedInput } from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { SearchOutlined } from '@ant-design/icons';

export const AgroClimaticZoneTableFilters: FC = () => {
  const { t } = useTranslation();
  const [filtersState, setFiltersState] = useState<
    Partial<IAgroClimaticZoneFilters>
  >({});
  const { filters, setAgroClimaticZoneTableFilters } =
    useAgroClimaticZoneTableFilters();

  const { data: { content = [] } = {} } = useFindAllIrrigationSystemListQuery(
    filtersState.district && filtersState.region
      ? {
          size: 1000,
          regionId: filtersState.region,
          districtId: filtersState.district,
        }
      : skipToken,
  );

  const onFilterChange = (
    filterKey: keyof IAgroClimaticZoneFilters,
    v: string | null,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    } as IAgroClimaticZoneFilters;
    setFiltersState(updatedFilters);
  };

  const onFilterButtonClick = () => {
    setAgroClimaticZoneTableFilters(filtersState as IAgroClimaticZoneFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      id: null,
      name: null,
      page: 1,
      size: 10,
    });
    setAgroClimaticZoneTableFilters({
      id: null,
      name: null,
      page: 1,
      size: 10,
    });
  };

  console.log(filters, 'filters');

  useEffect(() => {
    setFiltersState(filters as IAgroClimaticZoneFilters);
  }, [filters]);

  return (
    <Row gutter={16}>
      <Col span={6}>
        <TooltipedInput
          size="middle"
          value={filtersState?.id || ''}
          onChange={({ target }) => onFilterChange('id', target.value)}
          placeholder="Поиск по регистрационному номеру"
          prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
        />
      </Col>
      <Col span={6}>
        <TooltipedInput
          size="middle"
          value={filtersState?.name || ''}
          placeholder="Поиск по наименованию зоны"
          onChange={({ target }) => onFilterChange('name', target.value)}
          prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
        />
      </Col>
      <Col span={2}>
        <Row gutter={8} wrap={false}>
          <Col>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
          </Col>
          <Col>
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
