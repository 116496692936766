import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

export const useReferenceMaterialsTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'id',
      title: 'ID',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'title',
      title: 'Наименование',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'createdAt',
      title: 'Дата создания',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        const createdAt = dayjs(record?.createdAt).format('YYYY-MM-DD');
        return <span>{createdAt}</span>;
      },
      width: 300,
    },
    {
      dataIndex: 'updatedAt',
      title: 'Дата обновления',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        console.log('record', record);
        const updatedAt = dayjs(record?.updatedAt).format('YYYY-MM-DD');
        return <span>{updatedAt}</span>;
      },
      width: 300,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
    },
  ];

  return { columns };
};
