import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { ACCESS } from '@enums/permisson';

export const useAllTableContainerColumns = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isArchived = location.pathname.includes(Routes.ArchivariusTable);
  const columns: TableColumn[] = [
    {
      title: t('main.table.regNumber'),
      dataIndex: 'legalData.regNumber',
      key: 'regNumber',
      xtype: CustomTableColumnType.String,
      width: 120,
    },
    {
      title: t('main.table.name-fio'),
      dataIndex: 'legalData.name',
      key: 'fio',
      width: 170,
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('main.table.type'),
      dataIndex: 'type',
      key: 'type',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => t(`userTypesEnum.${record.type}`),
    },
    {
      title: t('main.table.irrigationSystems'),
      dataIndex: 'irrigationNameList',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'irrigationNameList',
      width: 170,
      ellipsis: true,
      renderCustom: ({ record }) => {
        const irrigationSystemNames = record.facilities.map(
          (item: any) => item.irrigationSystem?.name,
        );
        return (
          <div key={record.id}>
            {Array.from(new Set(irrigationSystemNames)).join(', ')}
          </div>
        );
      },
    },
    {
      title: t('main.table.address'),
      dataIndex: 'address',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'address',
      renderCustom: ({ record }: any) => {
        const address = [
          record.legalData?.region?.title,
          record.legalData?.district?.title,
          record.legalData?.address,
        ];
        return address.filter(Boolean).join(', ');
      },
      width: 190,
    },
    {
      title: t('main.table.totalArea-count'),
      dataIndex: 'area',
      key: 'area',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) =>
        `${record.legalData?.totalArea || 0} / ${record.facilities.length}`,
      width: 200,
    },
    {
      title: t('main.table.cabinet/status'),
      dataIndex: 'hasCabin',
      key: 'hasCabin',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: (props) => (
        <div>
          {props.record.hasCabin ? 'Да' : 'Нет'} /{' '}
          {props.record.active ? 'Актив' : 'Пассив'}
        </div>
      ),
      width: 150,
    },
    {
      title: t('main.table.controls'),
      key: 'controls',
      dataIndex: 'controls',
      width: 430,
      xtype: CustomTableColumnType.WaterRegistryControl,
      actionsAccess: [
        ACCESS.WATER_USER_CARD_VIEW,
        ACCESS.WATER_USER_ANNUAL_VIEW,
        ACCESS.WATER_USER_GIS_VIEW,
        ACCESS.WATER_USER_ACCESS_PROVIDE,
      ],
    },
    {
      title: t('main.table.actions'),
      key: 'controls',
      dataIndex: 'controls',
      width: 100,
      actions: [
        isArchived
          ? CustomTableAction.UnArchiveWithoutText
          : CustomTableAction.ArchiveWithoutText,
      ],
      xtype: CustomTableColumnType.ActionColumn,
      actionsAccess: [ACCESS.WATER_USER_CARD_ARCHIVE],
    },
  ];

  return { columns };
};
