export enum Routes {
  Landing = '/',
  Login = '/login',
  NotFound = '/notFound',

  // Home Page
  Home = '/home',

  // water registry
  WaterRegistry = '/water-registry',
  WaterRegistryUsers = '/water-registry/users-table',
  WaterRegistryTable = '/water-registry/table',
  WaterRegistryAssociations = '/water-registry/associations-table',

  // water user
  WaterUser = '/water-user',
  WaterUserCard = '/water-user/card/:id',
  WaterUserCardEmployeesDocuments = '/employee/attachmentsList/:id',

  // master registration
  WaterRegistryMasterRegistrationIndividual = '/water-registry/master-registration/physical',
  WaterRegistryMasterRegistrationLegal = '/water-registry/master-registration/legal',
  WaterRegistryMasterRegistrationAssociation = '/water-registry/master-registration/association',

  // irrigation system
  IrrigationSystem = '/irrigation-system',
  IrrigationSystemCreateStepper = '/irrigation-system/create-stepper',
  IrrigationSystemWaterObjectsRegistration = '/irrigation-system/:systemId/water-objects-registration',
  IrrigationSystemMainConduitRegistration = '/irrigation-system/:systemId/main-conduit-registration/:mainConduitId',
  IrrigationSystemCard = '/irrigation-system/:systemId/card',
  IrrigationSystemTable = '/irrigation-system/table',
  IrrigationReservoir = '/irrigation-system/create-reservoir',

  // Water Objects
  WaterObject = '/water-object',
  WaterObjectCard = '/water-object/card/:id',
  WaterObjectCreate = '/water-object/create',

  // Hydraulic Module
  HydraulicSection = '/hydraulic-section',
  HydraulicSectionEditor = '/hydraulic-section/editor',

  // water
  WateringPlan = '/watering-plan',
  WaterAccounting = '/water-accounting',
  WaterAccountingBalanceSheet = '/water-accounting/balance-sheet/:outletId/:year/:month/:decade',

  // invoice payments
  ActsInvoicesForPayments = '/acts-invoices-for-payment',
  ActsInvoicesForPaymentsTable = '/acts-invoices-for-payment/table',

  // Decada App
  DecadaApp = '/decada-app',
  DecadeApplication = '/decade-application',

  // Manage Events
  ManageEvents = '/manage-events',
  ManageEventsSchedule = '/manage-events/schedule',
  OperationalBlock = '/manage-events/operational/:id',
  BuildingBlock = '/manage-events/building/:id',
  RegularEvent = '/manage-events/regular-events/:id',
  OtherTask = '/manage-events/other-tasks/:id',
  RegularEventBlock = '/manage-events/regular-event/:id',
  ManageEventsOperationalCard = '/manage-events/schedule/operational/:id/card',
  ManageEventsBuildingCard = '/manage-events/schedule/building/:id/card',
  ManageEventsRegularEventsCard = '/manage-events/schedule/regular-events/:id/card',
  ManageEventsOtherTasksCard = '/manage-events/schedule/other-tasks/:id/card',
  ManageEventsGanttDiagram = '/manage-events/gantt-diagram',
  WorksAndEventsBuilding = '/manage-events/works-and-events/building/:id',
  WorksAndEventsOperational = '/manage-events/works-and-events/operational/:id',

  // Demo Monitoring
  DemoMonitoring = '/demo-monitoring',
  DemoMonitoringHydroCard = '/demo-monitoring/hydro/card/:id',
  DemoMonitoringSection = '/demo-monitoring/:section',

  // Reservoir Monitoring
  ReservoirTable = '/reservoir-monitoring',
  ReservoirMonitoring = '/reservoir-monitoring/:id',
  ReservoirMonitoringCard = '/reservoir-monitoring/card/:id',

  // Annual Application
  AnnualApplication = '/annual-application',
  AnnualApplicationTable = '/annual-application/table',
  AnnualApplicationCard = '/annual-application/card',
  AnnualApplicationCardView = '/annual-application/card/view/:id',
  AnnualApplicationCardAgreement = '/annual-application/card/agreement/:id',
  AnnualApplicationCardCalculations = '/annual-application/card/calculations/:id',
  AnnualApplicationCardActualStructure = '/annual-application/card/actual-structure/:id',
  AnnualApplicationContractualWaterSupplyTable = '/annual-application/contractual-water-supply',
  AnnualApplicationWaterSupplyTable = '/annual-application/water-supply',
  AnnualApplicationConclusionContractsTable = '/annual-application/conclusion-contracts',

  // Business plan
  BusinessPlan = '/business-plan',
  BusinessPlanTable = '/business-plan/table',

  // Agro-climatic zones
  AgroClimaticZone = '/agro-climatic-zone',
  AgroClimaticZoneTable = '/agro-climatic-zone/table',
  AgroClimaticZoneCard = '/agro-climatic-zone/card/:id',
  LinkedOutlet = '/agro-climatic-zone/:id/linked-outlet/:hydroModuleDistrictId',
  IrrigationModeEditor = '/agro-climatic-zone/:id/irrigation-mode-editor/:hydroModuleDistrictId',

  // Anual Plan
  AnnualPlan = '/annual-plan',
  AnnualPlanTable = '/annual-plan/table',

  // Archivarius
  Archivarius = '/archivarius',
  ArchivariusTable = '/archivarius/table',

  // Tariff
  Tariff = '/tariff',
  TariffTable = '/tariff/table',
  TariffCard = '/tariff/card/:id',

  // WaterQuality
  WaterQuality = '/water-quality',
  WaterQualityTable = '/water-quality/table',
  WaterQualityCard = '/water-quality/card/:id',

  // GeoPortal
  geoPortalIrrigationMap = '/geo-portal/irrigation-map/:irrigationSystemId',
  geoPortal = '/geo-portal',
  geoPortalTable = '/geo-portal/table',
  geoPortalMap = '/geo-portal/map/:filterKey',

  // Association Stats
  AssociationIndicatorReports = '/association-indicator-reports/:associationId',

  // WaterUse Plan
  WaterUsePlan = '/water-use-plan',

  // sppr
  sppr = '/sppr',

  // 2-TP Vodkhoz Reprot
  vodkgozReport = '/vodkhoz-report',

  //reference-book
  ReferenceBook = '/reference-book',

  //profileLayout
  Profile = '/profile',
  ProfileById = '/employee/:id',

  //Administration
  NewsManagement = '/news-management',
  InstructionsManagement = '/instructions-management',
  RolesAndRightsManagement = '/roles-and-rights-management',
  Employees = '/employees',
  ReferenceMaterials = '/reference-materials',
  GeoPortalManagement = '/geo-portal-management',
  GeoPortalArchiveManagement = '/geo-portal-archive-management',
}
