import { List as AntdList, ListProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';

import styles from './List.module.scss';

type ListPropTypes = ListProps<JsonRecord>;
type ListItemPropTypes = React.ComponentProps<typeof AntdList.Item>;

export const List: React.FC<ListPropTypes> = (props) => {
  return <AntdList {...props} className={styles.list} />;
};

export const ListItem: React.FC<ListItemPropTypes> = ({ children }) => {
  return <AntdList.Item className={styles.listItem}>{children}</AntdList.Item>;
};
