import { EntityTypesEnum } from '@enums/entityTypes';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGeoPortalArchiveCreateMutation,
  useGeoPortalArchiveUpdateMutation,
  GeoPortalArchiveDto,
} from '@store/api/geoPortalArchiveApi';
import { NewsDto } from '@store/api/newsApi';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useUploadFilesMutation,
} from '@store/gisproApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';
import {
  Col,
  Form,
  Row,
  Space,
  Typography,
  Upload,
  message,
  notification,
} from 'antd';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './GeoPortalArchiveModal.module.scss';

interface IGeoPortalArchiveModalProps {
  setShowModal: (value: boolean) => void;
  isModalShow: boolean;
  initialValues: any;
}

export const GeoPortalArchiveModal: FC<IGeoPortalArchiveModalProps> = ({
  setShowModal,
  isModalShow,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const regionId = Form.useWatch('regionId', form);
  const attachmentsList = Form.useWatch('attachmentsList', form);

  const onCancelButtonClick = () => {
    setShowModal(false);
  };
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const [createEntity, { isError, isLoading }] =
    useGeoPortalArchiveCreateMutation();
  const [updateEntity, { isError: isErrorUpdate, isLoading: isLoadingUpdate }] =
    useGeoPortalArchiveUpdateMutation();
  const { t } = useTranslation();
  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionId ? { parentId: parseInt(regionId, 10) } : skipToken,
  );
  const onFinish = (values: GeoPortalArchiveDto) => {
    if (initialValues) {
      updateEntity({
        id: initialValues.id,
        geoPortalArchiveDto: {
          ...values,
          attachmentsList: [
            {
              ...values.attachmentsList[0],
              attachmentId:
                values.attachmentsList[0]?.attachmentId ||
                values.attachmentsList[0]?.id,
            },
          ],
        },
      })
        .unwrap()
        .then(() => {
          setShowModal(false);
          notification.success({
            message: 'Архивные данные ГЕО портала успешно обновлены!',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Произошла ошибка',
          });
        });
      return;
    }
    createEntity({ geoPortalArchiveDto: values })
      .unwrap()
      .then(() => {
        setShowModal(false);
        notification.success({
          message: 'Архивные данные ГЕО портала успешно созданы!',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Произошла ошибка',
        });
      });
  };

  const [uploadDocument] = useUploadFilesMutation();
  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: 'Карта',
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);

    try {
      const response: any = await uploadDocument(formData);
      onSuccess();
      form.setFieldValue('attachmentsList', [response.data]);
      notification.success({
        message: 'Документ успешно загружен!',
      });
    } catch (error) {
      onError();
      notification.error({
        message: 'Не удалось загрузить документ',
      });
    }
  };

  const beforeUpload = (file: any) => {
    const limit = file.size / 1024 / 1024 <= 5;
    if (!limit) {
      message.error('File must be smaller than 2MB!');
    }
    return limit;
  };

  return (
    <CustomModal
      open={isModalShow}
      title={initialValues ? 'Изменить' : 'Создать'}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          initialValue={user?.owner?.region?.id}
          name="regionId"
          label="Область"
          rules={[{ required: true }]}
        >
          <TooltipedSelect disabled={isDisabledRegion} options={regions} />
        </Form.Item>
        <Form.Item
          initialValue={user?.owner?.district?.id}
          name="districtId"
          label="Район"
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            disabled={isDisabledDistrict || !regionId}
            options={districts}
          />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="Наименование"
            name="name"
            rules={[{ required: true }]}
          >
            <TooltipedInput />
          </Form.Item>
        </Col>
        <Form.Item
          getValueFromEvent={normalizeFile}
          name="attachmentsList"
          rules={[{ required: true, message: 'Пожалуйста загрузите файл' }]}
        >
          <Upload
            name="file"
            accept="application/json"
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            maxCount={1}
            showUploadList={false}
          >
            <PrimaryButton size="large" type="primary">
              {t('common.buttons.load')}
            </PrimaryButton>
          </Upload>
        </Form.Item>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            {attachmentsList?.length ? (
              <Space size={8} style={{ display: 'flex' }} direction="vertical">
                <Row
                  align="middle"
                  justify="space-between"
                  className={styles.docItem}
                >
                  <Col>
                    <Space size={8}>
                      <Typography.Text>
                        {attachmentsList[0]?.name ||
                          attachmentsList[0]?.originName}
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Space>
            ) : (
              <div className={styles.noDoc}>Нет закрепленных документов</div>
            )}
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton
                loading={isLoading || isLoadingUpdate}
                htmlType="submit"
              >
                Сохранить
              </PrimaryButton>
            </Space>
          </Col>
        </Row>

        {(isError || isErrorUpdate) && (
          <Row justify="center">
            <Typography.Text type="danger">Что-то пошло не так</Typography.Text>
          </Row>
        )}
      </Form>
    </CustomModal>
  );
};
