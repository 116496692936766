import { TooltipedDatePicker, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Statuses } from '@enums/table/customTableStatus';
import {
  useFindAllIrrigationSystemObjectsListChannelsQuery,
  useFindDictionaryByTypeIdQuery,
} from '@store/gisproApi';
import { getCurrentTableOptionsSelector } from '@store/selectors';
import { saveTableOptions } from '@store/slices';
import { debounce } from '@utils/utils';

import styles from './BuildingTableSelectsBlock.module.scss';

export const BuildingTableSelectsBlock: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const statusTypes = Object.entries(Statuses).map(([value, label]) => ({
    value,
    label,
  }));
  const { data: objectTypes = [] } =
    useFindAllIrrigationSystemObjectsListChannelsQuery({});

  const tableOptions = useSelector(getCurrentTableOptionsSelector);

  const setTableOptions = (key: string, value: any) => {
    dispatch(
      saveTableOptions({
        ...tableOptions,
        [key]: value,
      }),
    );
  };
  const { data: directions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.WaterObjectDirection,
  });
  const changeTableOptions = debounce(setTableOptions, 500);

  return (
    <Row align="middle" gutter={10}>
      <Col span={4}>
        <TooltipedInput
          onChange={(v) => changeTableOptions('q', v)}
          value={tableOptions.q}
          placeholder="Наименование"
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          onChange={(v) => changeTableOptions('systemObjectId', v)}
          options={objectTypes.map((item) => ({
            ...item,
            label: item.objectName,
            value: item.objectId,
          }))}
          placeholder="Объект"
          value={
            tableOptions.systemObjectId
              ? tableOptions.systemObjectId
              : undefined
          }
          className={styles.select}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          value={tableOptions.categoryId ? tableOptions.categoryId : undefined}
          placeholder="Категория"
          onChange={(v) => changeTableOptions('categoryId', v)}
          options={directions}
          className={styles.select}
        />
      </Col>
      <Col span={4}>
        <Row gutter={5} wrap={false} align="middle">
          <Col span={4}>
            <h2 className={styles.sumLabel}>Сроки</h2>
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventStartDate', v)}
            />
          </Col>
          <Col span={10}>
            <TooltipedDatePicker
              allowClear
              onChange={(v) => changeTableOptions('eventEndDate', v)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row gutter={5} wrap={false} align="middle">
          <Col span={4}>
            <h2 className={styles.sumLabel}>Сумма</h2>
          </Col>
          <Col span={10}>
            <TooltipedInput
              onChange={(v) => changeTableOptions('budgetStart', v)}
              type="number"
              placeholder="План"
            />
          </Col>
          <Col span={10}>
            <TooltipedInput
              onChange={(v) => changeTableOptions('budgetEnd', v)}
              type="number"
              placeholder="Факт"
            />
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <TooltipedSelect
          allowClear
          popupMatchSelectWidth={false}
          options={statusTypes}
          value={tableOptions.status}
          placeholder="Статус"
          onChange={(v) => changeTableOptions('status', v)}
          className={styles.select}
        />
      </Col>
    </Row>
  );
};
