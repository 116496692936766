import { ColumnsType } from 'antd/es/table';

export const useEstimatedFinancialPlanColumns = () => {
  const columns: ColumnsType<any> = [
    {
      title: 'Сроки водоподачи',
      dataIndex: 'quarterName',
      align: 'left',
    },
    {
      title: 'Площадь (ГА)',
      dataIndex: 'mock',
      align: 'left',
    },
    {
      title: 'Объем водоподачи, тыс.м3',
      dataIndex: 'waterSupplyVolumeCubicMeters',
      align: 'left',
    },
    {
      title: 'Тариф за 1 м3 воды, сом',
      dataIndex: 'waterTariffForOneCubicMeter',
      align: 'left',
    },
    {
      title: 'Стоимость подачи воды, сом',
      dataIndex: 'waterSupplyCost',
      align: 'left',
    },
  ];

  return { columns };
};
