import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { MonthEnums, RussianMonthEnums } from '@enums/month';
import { Routes } from '@enums/routes';
import { DefaultButton, InformationalBlock } from '@features/ui-kit';
import { WaterLogAccountingBalanceSheetFilters } from '@features/waterAccounting/waterLogAccounting/waterLogAccountingBalanceSheet/waterLogAccountingbalanceSheetFilters/waterLogAccountingBalanceSheetFilters2';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetSystemObjectByIdQuery,
  useGetWaterAccountingBalanceSheetQuery,
} from '@store/gisproApi';
import {
  Breadcrumb,
  Col,
  Divider,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from 'antd';
import { toInteger } from '@utils/utils';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { WaterLogAccountingBalanceSheetTable } from './waterLogAccountingBalanceSheetTable/WaterLogAccountingBalanceSheetTable';

export type typeWaterLogAccountingBalanceSheetProps = {
  balanceSheet: any;
  refetch: () => void;
};

export const WaterLogAccountingBalanceSheet = () => {
  const { outletId, year, decade, month } = useParams();
  const { data: outletInfo } = useGetSystemObjectByIdQuery(
    outletId ? { id: outletId } : skipToken,
  );

  const navigate = useNavigate();

  const BreadcrumbItems = [
    {
      title: <HomeOutlined />,
    },
    {
      title: 'План водопользования',
    },
    {
      title: 'Просмотр',
    },
  ];

  const { data: balanceSheet, refetch } =
    useGetWaterAccountingBalanceSheetQuery({
      outletId: toInteger(outletId),
      decade: toInteger(decade),
      month: month as MonthEnums,
      year: toInteger(year),
    });

  const chandeDecade = (decade: number) => () => {
    navigate(
      generatePath(Routes.WaterAccountingBalanceSheet, {
        outletId: outletId?.toString() || '',
        year: year || '',
        month: month || '',
        decade: decade.toString(),
      }),
    );
  };

  return (
    <Layout>
      <Row gutter={16}>
        <Col>
          <DefaultButton onClick={() => navigate(-1)} className="backBtn">
            <LeftOutlined />
            <Typography.Text>Назад</Typography.Text>
          </DefaultButton>
        </Col>
        <Col>
          <Breadcrumb className="breadcrumb" items={BreadcrumbItems} />
        </Col>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[0, 16]} justify="space-between">
        <Col>
          <Space size={4}>
            <Input
              placeholder="Канал"
              addonBefore="Канал:"
              value={outletInfo?.channelName}
              readOnly
            />
            <Input
              placeholder="Водовыпуск"
              addonBefore="Водовыпуск:"
              value={outletInfo?.name}
              readOnly
            />
            <Input
              placeholder="Год"
              addonBefore="Год:"
              styles={{ input: { width: 80 } }}
              value={year}
              readOnly
            />
            <Input
              placeholder="Месяц"
              addonBefore="Месяц:"
              styles={{ input: { width: 80 } }}
              value={
                month
                  ? RussianMonthEnums[month as keyof typeof RussianMonthEnums]
                  : ''
              }
              readOnly
            />
            <DefaultButton
              icon={<ArrowLeftOutlined />}
              disabled={Number(decade) === 1}
              onClick={chandeDecade(Number(decade) - 1)}
            />
            <Input
              placeholder="Декада"
              addonBefore="Декада:"
              styles={{ input: { width: 50 } }}
              value={decade}
              readOnly
            />
            <DefaultButton
              icon={<ArrowRightOutlined />}
              disabled={Number(decade) === 3}
              onClick={chandeDecade(Number(decade) + 1)}
            />
          </Space>
        </Col>
        <Col>
          <WaterLogAccountingBalanceSheetFilters
            balanceSheet={balanceSheet}
            refetch={refetch}
          />
        </Col>
        <Col span={24}>
          <InformationalBlock title="Балансовая ведомость">
            <WaterLogAccountingBalanceSheetTable
              balanceSheet={balanceSheet}
              refetch={refetch}
            />
          </InformationalBlock>
        </Col>
      </Row>
    </Layout>
  );
};
