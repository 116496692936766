import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

type ReturnType = {
  columns: TableColumn[];
};

export const useFacilitiesColumns = (): ReturnType => {
  const columns: TableColumn[] = [
    {
      title: 'Наименвание участка',
      dataIndex: 'name',
      key: 'name',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Водовыпуск',
      dataIndex: 'outlet',
      key: 'outlet',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Общая площадь Га',
      dataIndex: 'area',
      key: 'area',
      xtype: CustomTableColumnType.String,
    },
  ];

  return {
    columns,
  };
};
