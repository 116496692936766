import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalCloseIcon: React.FC<IconProps> = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.29289 8.85811C2.90237 9.24864 2.90237 9.8818 3.29289 10.2723C3.68342 10.6628 4.31658 10.6628 4.70711 10.2723L6.78252 8.19691L8.85811 10.2725C9.24864 10.663 9.8818 10.663 10.2723 10.2725C10.6628 9.88197 10.6628 9.24881 10.2723 8.85828L8.19674 6.78269L10.2723 4.70711C10.6628 4.31658 10.6628 3.68342 10.2723 3.29289C9.8818 2.90237 9.24864 2.90237 8.85811 3.29289L6.78252 5.36848L4.70711 3.29306C4.31658 2.90254 3.68342 2.90254 3.29289 3.29306C2.90237 3.68359 2.90237 4.31675 3.29289 4.70728L5.36831 6.78269L3.29289 8.85811Z"
              fill="black" />
      </g>
    </svg>
  )
}
