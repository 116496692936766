import React, { RefAttributes, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { SegmentedProps } from 'antd';
import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import styles from './Segmented.module.scss';

type QueryParams = {
  queryKey: string;
  queryType: any;
  onChange?: (value: SegmentedValue) => void;
};

type CustomSegmentedPropsType = SegmentedProps &
  RefAttributes<HTMLDivElement> & {
    colorType?: 'primary' | 'default' | 'turquoise' | 'green';
    queryParams: QueryParams;
  };

export const CustomSegmented: React.FC<CustomSegmentedPropsType> = ({
  colorType = 'default',
  queryParams,
  defaultValue,
  onChange,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = Object.fromEntries(searchParams.entries());

  const defaultValueCustom =
    searchParams.get(queryParams.queryKey) || defaultValue;

  const onChangeValue = useCallback(
    (value: SegmentedValue) => {
      const updatedSearchParams = {
        ...currentSearchParams,
        [queryParams.queryKey]: value as string,
      };

      setSearchParams(updatedSearchParams);
      if (onChange) {
        onChange(value);
      }
    },

    [searchParams, onChange],
  );

  const className = useMemo(() => {
    let color;
    switch (colorType) {
      case 'primary':
        color = styles.navTabsPrimary;
        break;
      case 'turquoise':
        color = styles.navTabsTurquoise;
        break;
      case 'green':
        color = styles.navTabsGreen;
        break;
      default:
        color = styles.navTabs;
        break;
    }
    return color;
  }, [colorType]);

  return (
    <Segmented
      defaultValue={defaultValueCustom}
      {...props}
      className={className}
      onChange={onChangeValue}
      value={currentSearchParams[queryParams.queryKey]}
    />
  );
};
