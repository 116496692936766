import { UserRoles } from '@enums/roles';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const actualSowings: { title: string; value: string }[] = [
  { title: 'Зерновые', value: 'cereals' },
  { title: 'Многолетние', value: 'perennial' },
  { title: 'Кукуруза на зерно', value: 'cornForGrain' },
  { title: 'Овощи', value: 'vegetables' },
  { title: 'Картофель', value: 'potato' },
  { title: 'сах. свекла', value: 'sugarBeet' },
  { title: 'хлопок', value: 'cotton' },
  { title: 'фасоль', value: 'beans' },
  { title: 'рис', value: 'rice' },
  { title: 'табак', value: 'tobacco' },
  { title: 'маслиничные', value: 'oilSeeds' },
  { title: 'Кукуруза на силос', value: 'cornForSilage' },
  { title: 'прочие', value: 'otherArable' },
];

export const useCropSowingIrragatedLandsColumns = (
  userRole: UserRoles = UserRoles.ROLE_RUVH,
  canEdit: boolean = false,
) => {
  const isShowRole =
    userRole === UserRoles.ROLE_CA || userRole === UserRoles.ROLE_GUVH;

  const columns: TableColumn[] = [
    {
      title: 'Система',
      dataIndex: 'systemTypeName',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemTypeRowIndex) {
          if (record.systemTypeRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemTypeRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Наименование системы',
      dataIndex: 'systemName',
      xtype: CustomTableColumnType.String,
      align: 'left',
      width: 200,
      onCell: (record, rowIndex) => {
        if (record?.systemRowIndex) {
          if (record.systemRowIndex?.start === rowIndex) {
            return { rowSpan: record.systemRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    },
    {
      title: 'Всего орошаемых земель, Га',
      dataIndex: 'allTotalLand',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 150,
    },
    {
      title: 'В том числе',
      dataIndex: 'picket',
      xtype: CustomTableColumnType.String,
      key: 'type',
      align: 'center',
      children: [
        {
          dataIndex: '',
          title: 'Орошаемая пашня, га',
          xtype: CustomTableColumnType.String,
          align: 'center',
          children: actualSowings.map((sow: any): TableColumn => {
            return {
              dataIndex: sow.value,
              title: sow.title,
              width: 100,
              decimalIndex: ['planItemDtos'],
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: (record) => {
                if (!canEdit) {
                  return false;
                }
                return !(isShowRole || record.systemTypeName.includes('Итого'));
              },
            };
          }),
        },
        {
          dataIndex: 'totalIrrigatedArableLand',
          title: 'Итого орошаемой пашни',
          xtype: CustomTableColumnType.String,
          align: 'center',
        },
        {
          dataIndex: 'asd',
          title: 'Поливные угодия, Га',
          xtype: CustomTableColumnType.String,
          align: 'center',
          children: [
            {
              dataIndex: 'gardensAndVineyards',
              title: 'сады, виноградники',
              decimalIndex: ['planItemDtos'],
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: (record) => {
                if (!canEdit) {
                  return false;
                }
                return !(isShowRole || record.systemTypeName.includes('Итого'));
              },
            },
            {
              dataIndex: 'householdPlots',
              title: 'приусадебные участки',
              decimalIndex: ['planItemDtos'],
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: (record) => {
                if (!canEdit) {
                  return false;
                }
                return !(isShowRole || record.systemTypeName.includes('Итого'));
              },
            },
            {
              dataIndex: 'otherIrrigationLands',
              title: 'Прочие',
              decimalIndex: ['planItemDtos'],
              xtype: CustomTableColumnType.DecimalEdit,
              editableDecimalCell: (record) => {
                if (!canEdit) {
                  return false;
                }
                return !(isShowRole || record.systemTypeName.includes('Итого'));
              },
            },
          ],
        },
        {
          dataIndex: 'totalIrrigatedLand',
          title: 'Итого поливных угодий',
          xtype: CustomTableColumnType.String,
          align: 'center',
        },
      ],
    },
    {
      dataIndex: 'unused',
      title: 'Неиспользовано',
      key: 'type',
      align: 'left',
      decimalIndex: ['planItemDtos'],
      xtype: CustomTableColumnType.DecimalEdit,
      editableDecimalCell: (record) => {
        if (!canEdit) {
          return false;
        }
        return !(isShowRole || record.systemTypeName.includes('Итого'));
      },
    },
  ];
  if (isShowRole) {
    columns.unshift({
      title: 'Наименование района',
      dataIndex: 'districtName',
      xtype: CustomTableColumnType.String,
      key: 'districtName',
      align: 'left',
      width: 150,
      onCell: (record, rowIndex) => {
        if (record?.districtRowIndex) {
          if (record.districtRowIndex?.start === rowIndex) {
            return { rowSpan: record.districtRowIndex?.end };
          }
          return { rowSpan: 0 };
        }
        return { rowSpan: 1 };
      },
    });
  }
  return { columns };
};
