import { FC, useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'antd';
import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, isInteger, pickBy } from '@utils/utils';
import { EntityTypesEnum } from '@enums/entityTypes';
import { useGetCurrentUser } from '@features/authentication';

interface IGeoPortalArchiveTableFilters {
  name: string | null;
  districtId: number | null;
  regionId: number | null;
}

export const GeoPortalArchiveTableFilters: FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const [filtersState, setFiltersState] = useState<
    Partial<IGeoPortalArchiveTableFilters>
  >({});

  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: filtersState.regionId,
        }
      : skipToken,
  );
  const onFilterChange = (
    filterKey: keyof IGeoPortalArchiveTableFilters,
    v: string,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    };
    setFiltersState(updatedFilters);
  };

  useEffect(() => {
    setFiltersState(filtersParams as any);
  }, [filtersParams]);

  const onFilterButtonClick = () => {
    setSearchParams(
      pickBy(
        {
          ...filtersParams,
          ...filtersState,
        },
        (v) => !isEmpty(v) || isInteger(v),
      ),
    );
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      ...filtersState,
      name: null,
    });

    setSearchParams(
      pickBy(
        {
          regionId: user?.owner?.region?.id.toString() || null,
          districtId: user?.owner?.district?.id.toString() || null,
        },
        (v) => !isEmpty(v) || isInteger(v),
      ),
    );
  };

  const onRegionChange = (v: number) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
    }));
  };

  return (
    <Row gutter={[16, 16]} style={{ margin: '16px 0 16px' }}>
      <Col span={4}>
        <TooltipedSelect
          size="large"
          placeholder="Область"
          value={
            regions.find((item) => item.id === toInteger(filtersState.regionId))
              ?.label
          }
          onChange={(v: number) => onRegionChange(v)}
          options={regions}
          allowClear
          disabled={isDisabledRegion}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="large"
          placeholder="Район"
          options={districts}
          disabled={isDisabledDistrict || !filtersState.regionId}
          value={
            districts.find(
              (item) => item.id === toInteger(filtersState.districtId),
            )?.label
          }
          onChange={(v: string) => onFilterChange('districtId', v)}
          allowClear
        />
      </Col>
      <Col span={4}>
        <TooltipedSearch
          size="large"
          value={filtersState.name || ''}
          onChange={({ target }) => onFilterChange('name', target.value)}
          placeholder="Наименование"
        />
      </Col>

      <Col span={2}>
        <Row gutter={8} wrap={false}>
          <Col>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
          </Col>
          <Col>
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
