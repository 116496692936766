import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Space } from 'antd';

import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useIrrigationSystemCardWaterUserTableFilters } from './useIrrigationSystemCardWaterUserTableFilters';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from '@utils/utils';

export const IrrigationSystemCardWaterUserTableControls: React.FC = () => {
  const { t } = useTranslation();

  const [filterState, setFilterState] = useState<any>({});
  const [params] = useSearchParams();
  const { setIrrigationSystemCardWaterUserFilters } =
    useIrrigationSystemCardWaterUserTableFilters();
  const onFilterChange = (key: string, value: string) => {
    setFilterState((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearchChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    onFilterChange(key, value);
  };

  const userTypes = [
    WaterUserEnum.LEGAL,
    WaterUserEnum.ASSOCIATION,
    WaterUserEnum.INDIVIDUAL,
  ].map((key) => ({
    label: t(`userTypesEnum.${key}`),
    value: key,
  }));

  const onFilterButtonClick = () => {
    setIrrigationSystemCardWaterUserFilters(filterState);
  };

  const onResetFilterButtonClick = () => {
    setFilterState({
      name: null,
      code: null,
      userType: null,
    });
    setIrrigationSystemCardWaterUserFilters({
      name: null,
      code: null,
      userType: null,
    });
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setFilterState(newFilters);
    setIrrigationSystemCardWaterUserFilters(
      isEmpty(newFilters) ? {} : (newFilters as unknown as any),
    );
  }, []);

  return (
    <Row gutter={16}>
      <Col>
        <TooltipedSearch
          value={filterState?.code}
          placeholder="Код водного объекта"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchChange('code', event)
          }
        />
      </Col>
      <Col>
        <TooltipedSearch
          value={filterState?.name}
          placeholder="Наименование объекта"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchChange('name', event)
          }
        />
      </Col>
      <Col>
        <TooltipedSelect
          style={{ width: 220 }}
          placeholder="Тип"
          options={userTypes}
          allowClear
          value={filterState?.userType}
          onChange={(v: string) => onFilterChange('userType', v)}
        />
      </Col>
      <Col>
        <Space size={8}>
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Space>
      </Col>
    </Row>
  );
};
