import { IAttachment } from '@models/attachments/interfaces/attachment';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import concat from 'lodash/concat'; // TODO: change lodash to native implementation
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';

export type MainConduitState = {
  isMainConduitCreateStepperActionSelectModalShown: boolean;
  waterObjects: IWaterObject[];
  selectedWaterObject: IWaterObject | null;
  documents: IAttachment[];
  selectedWaterSectionId: string | null;
};

const initialState: MainConduitState = {
  isMainConduitCreateStepperActionSelectModalShown: false,
  waterObjects: [],
  documents: [],
  selectedWaterSectionId: null,
  selectedWaterObject: null,
};

const mainConduitSlice = createSlice({
  name: 'mainConduit',
  initialState,
  reducers: {
    setShowMainConduitCreateStepperActionSelectModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isMainConduitCreateStepperActionSelectModalShown: action.payload,
      };
    },
    setSelectedWaterSectionIdAction: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      return {
        ...state,
        selectedWaterSectionId: action.payload,
      };
    },

    setSelectedWaterObjectAction: (
      state,
      action: PayloadAction<IWaterObject | null>,
    ) => {
      return {
        ...state,
        selectedWaterObject: action.payload,
        documents: action.payload?.attachmentIds || [],
      };
    },
    addWaterObjectAction: (state, action: PayloadAction<IWaterObject>) => {
      let waterObjects;
      if (state.selectedWaterObject !== null) {
        waterObjects = state.waterObjects.map((object) =>
          isEqual(object, state.selectedWaterObject) ? action.payload : object,
        );
      } else {
        waterObjects = concat(state.waterObjects, action.payload);
      }
      return {
        ...state,
        waterObjects,
      };
    },
    removeWaterObjectAction: (state, action: PayloadAction<IWaterObject>) => {
      state.waterObjects = state.waterObjects.filter(
        (item) => !isEqual(item, action.payload),
      );
    },
    clearWaterObjectAction: (state) => {
      return {
        ...state,
        waterObjects: [],
      };
    },
    changeSelectedWaterObjectAction: (
      state,
      action: PayloadAction<IWaterObject>,
    ) => {
      const waterObjects = concat(state.waterObjects, action.payload);
      return {
        ...state,
        waterObjects,
      };
    },

    addDocumentAction: (state, action: PayloadAction<IAttachment>) => {
      const documents = concat(state.documents, action.payload);
      return {
        ...state,
        documents,
      };
    },
    removeDocumentAction: (state, action: PayloadAction<IAttachment>) => {
      const documents = remove(
        state.documents,
        (item) => !isEqual(item, action.payload),
      );
      return {
        ...state,
        documents,
      };
    },
    clearDocumentAction: (state) => {
      return {
        ...state,
        documents: [],
      };
    },
  },
});

export const {
  changeSelectedWaterObjectAction,
  addWaterObjectAction,
  clearWaterObjectAction,
  removeWaterObjectAction,
  addDocumentAction,
  clearDocumentAction,
  removeDocumentAction,
  setSelectedWaterSectionIdAction,
  setShowMainConduitCreateStepperActionSelectModalAction,
  setSelectedWaterObjectAction,
} = mainConduitSlice.actions;

export default mainConduitSlice.reducer;
