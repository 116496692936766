import React, { useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import {
  EstimatedFinancialPlanTable,
  WaterAndHectareSupplyTable,
} from '@features/annualApplication';
import { CalculationEstimatedFinancial } from '@features/annualApplication/computingAnnual/tables/calculationEstimatedFinancial/CalculationEstimatedFinancial';
import { ApplicationForm } from '@features/annualApplication/computingAnnual/tables/waterAndHectareSupplyTable/ApplicationForm';
import {
  InformationalBlock,
} from '@features/ui-kit';
import {
  useCalculateAnnualContractMutation,
  useGetAnnualContractByIdQuery,
} from '@store/gisproApi';

export const ComputingExistedAnnualApplication = () => {
  const { id } = useParams<{ id: any }>();
  const [calculate, { error, isError, data: annualContract }] =
    useCalculateAnnualContractMutation();

  const { data: annulContractItem } = useGetAnnualContractByIdQuery({ id });

  useEffect(() => {
    if (annulContractItem) {
      calculate({
        plantingDataDtoList: annulContractItem.rawAnnualPlantingPlans.map(
          (item: any) => ({
            ...item,
            outletId: item?.outlet?.id,
            facilityId: item?.facility?.id,
          }),
        ),
        waterUserId: annulContractItem?.waterUser?.id,
      });
    }
  }, [annulContractItem]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <InformationalBlock title="Расчеты">
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <EstimatedFinancialPlanTable
                estimateAndFinancialPlanTotal={
                  annualContract?.estimateAndFinancialPlanTotal
                }
              />
            </Col>
            {annualContract?.calculationsByPlantingData.map((item) => (
              <Row gutter={[0, 8]} key={item.facilityId || item.outletId}>
                <Col span={24}>
                  <ApplicationForm data={item} />
                </Col>
                <Col span={24}>
                  <WaterAndHectareSupplyTable data={item} />
                </Col>
                <Col span={24}>
                  <CalculationEstimatedFinancial data={item} />
                </Col>
              </Row>
            ))}
          </Row>
        </InformationalBlock>
      </Col>
      <Col span={24}>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};
