import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Row } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { IWorkDto } from '@models/waterObject/interfaces/workDto';
import { AppDispatch } from '@store/index';
import { waterObjectActions } from '@store/slices';

import styles from '../table.module.scss';

export const useWorkMarkTableColumns = () => {
  const dispatch = useDispatch<AppDispatch>();

  const openModal = useCallback((workDto: IWorkDto) => {
    dispatch(waterObjectActions.setShowWorkVolume(true));
    dispatch(waterObjectActions.setSelectedWorkVolumeAction(workDto));
  }, []);

  const columns: TableColumn[] = [
    {
      title: (
        <Row style={{ gap: 5 }} align="middle">
          <div>Год</div>
        </Row>
      ),
      dataIndex: 'year',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      key: 'year',
      width: 100,
      sorter: {
        compare: (a, b) => a.year - b.year,
      },
      renderCustom: ({ record }) => <Row align="middle">{record.year} год</Row>,
    },
    {
      title: (
        <Row style={{ gap: 5 }} align="middle">
          <div>Вид</div>
        </Row>
      ),
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      align: 'left',
      key: 'type',
      width: 100,
      sorter: {
        compare: (a, b) => a.year - b.year,
      },
    },
    {
      title: 'Объем проведенных работ',
      dataIndex: 'volume',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      key: 'volume',
      width: 300,
      renderCustom: ({ record }) => (
        <Row align="middle">
          <div className={styles.dotsTitle}>{record.name}</div>
          <Button
            className={styles.dotsButton}
            onClick={() => openModal(record)}
          >
            ...
          </Button>
        </Row>
      ),
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      key: 'sum',
      width: 130,
      renderCustom: ({ record }) => <span>{record.sum} сом</span>,
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
      width: 100,
    },
  ];

  return { columns };
};
