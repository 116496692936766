import { StatsCard } from '@features/ui-kit';
import React from 'react';

interface IGetCardItem {
  count: number;
  secondCount?: number;
  label: string;
  icon?: string;
  status?: string;
  countSize?: number;
}

export const getDashboardtCardItem = (itemData: Array<IGetCardItem>) => {
  return itemData.map((item) => {
    return (
      <StatsCard
        countSize={item?.countSize}
        key={item.label}
        status={item?.status}
        count={item.count}
        secondCount={item.secondCount}
        label={item.label}
        icon={item?.icon}
      />
    );
  });
};
