import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import {
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { ActsInvoicesForPaymentsStatusEnum } from '@models/actsInvoicesForPayments/enums/actsInvoicesForPaymentsEnum';
import { IActsInvoicesForPaymentsFilters } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import dayjs, { Dayjs } from 'dayjs';

import { useActsInvoicesForPaymentsFilters } from '../useActInvoicesForPaymentsFilters';
import styles from './ActsInvoicesForPaymentsFilters.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { RussianMonth, RussianMonthIndexies } from '@enums/month';

export const ActsInvoicesForPaymentsFilters: FC = () => {
  const { t } = useTranslation();
  const [filtersState, setFiltersState] = useState<
    Partial<IActsInvoicesForPaymentsFilters>
  >({});
  const { filters, setActsInvoicesForPaymentsFilters } =
    useActsInvoicesForPaymentsFilters();

  const filteredActs = () => {
    setActsInvoicesForPaymentsFilters({
      ...(filters as IActsInvoicesForPaymentsFilters),
      ...filtersState,
    });
  };

  const onFilterChange = (
    filterKey: keyof IActsInvoicesForPaymentsFilters,
    v: string | null | Dayjs,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  const monthArray = Object.entries(RussianMonth).map(([label, value]) => ({
    label,
    value,
    disabled: false,
  }));

  const onResetFilterButtonClick = () => {
    setActsInvoicesForPaymentsFilters({
      ...filtersState,
      waterUserName: '',
      actNumber: '',
      status: null,
      dateOfSubmission: '',
      outletObjectName: '',
      facilitiesName: '',
      month: null,
      year: null,
      page: 1,
      size: 10,
      sort: [],
    });
  };

  const statusesEnum = useMemo(
    () =>
      Object.values(ActsInvoicesForPaymentsStatusEnum).map((item) => ({
        label: t(`tags.${item}`),
        value: item,
      })),
    [],
  );

  return (
    <div className={styles.selectWrapper}>
      <Row align="middle" gutter={16}>
        <Col span={3}>
          <TooltipedInput
            value={filtersState.waterUserName}
            onChange={({ target }) =>
              onFilterChange('waterUserName', target.value)
            }
            placeholder="Водопользователь"
            prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
          />
        </Col>
        <Col span={3}>
          <TooltipedInput
            value={filtersState.actNumber}
            onChange={({ target }) => onFilterChange('actNumber', target.value)}
            placeholder="Номер акта/счета"
            prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
          />
        </Col>
        <Col span={3}>
          <TooltipedInput
            value={filtersState.facilitiesName}
            onChange={({ target }) =>
              onFilterChange('facilitiesName', target.value)
            }
            placeholder="Участок"
            prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
          />
        </Col>
        <Col span={3}>
          <TooltipedInput
            value={filtersState.outletObjectName}
            onChange={({ target }) =>
              onFilterChange('outletObjectName', target.value)
            }
            placeholder="Водовыпуск"
            prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
          />
        </Col>
        <Col span={2}>
          <TooltipedSelect
            bordered={false}
            placeholder="Статус"
            options={statusesEnum}
            value={filtersState.status}
            onChange={(v: string) => onFilterChange('status', v)}
          />
        </Col>
        <Col span={2}>
          <YearPicker
            value={filtersState.year ? dayjs(filtersState.year) : null}
            placeholder="Год"
            onChange={(date: Dayjs | null, year) =>
              onFilterChange('year', year.toString())
            }
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col span={2}>
          <TooltipedSelect
            placeholder="Месяц"
            options={monthArray}
            bordered={false}
            value={filtersState.month}
            onChange={(v: string) => onFilterChange('month', v)}
          />
        </Col>
        <Col span={2} flex="0 0 100px" className={styles.filterCol}>
          <Row className={styles.filterBtns} wrap={false}>
            <PrimaryButton icon={<FilterIcon />} onClick={filteredActs} />
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};
