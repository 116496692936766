import React, { useEffect, useState } from 'react';

import { Table, TableProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps } from '@models/table/interfaces/tableCustomActionProps';

import { RenderCell } from './cell/RenderCell';
import { filterCutomTableColumns } from './filterCutomTableColumns';
import { mapCutomTableColumns } from './mapCutomTableColumns';
import styles from './TablePreviewV2.module.scss';

type GetCellKey<RecordType> = (record: RecordType, index?: number) => React.Key;

interface TablePreviewV2Props<RecordType> extends TableProps<RecordType> {
  dataSource: RecordType[];
  columns: TableColumn[];
  columnsOrder?: string[];
  totalCount?: number;
  highlightedRowKeys?: (React.Key | undefined)[];
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
  actionProps?: TableCustomActionProps<RecordType>;
}

export function TablePreviewV2<RecordType extends JsonRecord>({
  columns,
  columnsOrder,
  dataSource = [],
  onChange,
  highlightedRowKeys = [],
  rowClassName,
  rowKey,
  cellKey,
  totalCount,
  actionProps: { ...otherActionProps } = {},
  ...other
}: TablePreviewV2Props<RecordType>) {
  const [data, setData] = useState(dataSource);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const mappedColumns = mapCutomTableColumns({
    customColumns: filterCutomTableColumns({
      customColumns: columns,
      columnsOrder,
    }),
    actionProps: {
      ...otherActionProps,
    },
  });

  const handleTableChange: TableProps<any>['onChange'] = (
    newPagination,
    newFilters,
    newSorter,
    extra,
  ) => {
    if (onChange) {
      onChange(newPagination, newFilters, newSorter, extra);
    }
  };

  return (
    <div>
      <Table
        className={styles.table}
        columns={mappedColumns}
        {...other}
        onChange={handleTableChange}
        components={{
          body: {
            cell: RenderCell,
          },
        }}
        bordered={false}
        rowKey={rowKey}
        rowClassName={styles.rowClassName}
        dataSource={data}
      />
    </div>
  );
}
