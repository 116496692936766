import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import dayjs, { Dayjs } from 'dayjs';

import styles from './StatusInfo.module.scss';

type StatusInfoPropTypes = {
  className?: string;
  status?: TaskStatusEnum | string;
  planEndDate?: Dayjs | Date;
};

export const StatusInfo: FC<StatusInfoPropTypes> = ({
  status,
  className = '',
  planEndDate,
}) => {
  const { t } = useTranslation();
  const endDate = dayjs(planEndDate);
  const expiredStatus = dayjs(endDate).isBefore(dayjs());

  if (status === TaskStatusEnum.onControl) {
    return (
      <Button
        type="dashed"
        className={`${styles.control} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.control')}
      </Button>
    );
  }

  if (status === TaskStatusEnum.completedNotOnTime) {
    return (
      <Button
        type="dashed"
        className={`${styles.completedNonTime} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.notCompletedTime')}
      </Button>
    );
  }

  if (status === TaskStatusEnum.onRealization) {
    return (
      <Button
        type="dashed"
        className={`${styles.realization} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.implementation')}
      </Button>
    );
  }

  if (status === TaskStatusEnum.onPreparation) {
    return (
      <Button
        type="dashed"
        className={`${styles.preparation} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.preparation')}
      </Button>
    );
  }
  if (status === TaskStatusEnum.completed) {
    return (
      <Button
        type="dashed"
        className={`${styles.completed} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.done')}
      </Button>
    );
  }

  if (status === TaskStatusEnum.onPlanned) {
    return (
      <Button
        type="dashed"
        className={`${styles.planned} ${styles.statusBtn} ${className}`}
      >
        {t('statusList.planned')}
      </Button>
    );
  }

  return (
    <Button
      type="dashed"
      className={`${styles.draft} ${styles.statusBtn} ${className}`}
    >
      {t('statusList.draft')}
    </Button>
  );
};
