import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { GeoAttributeTypeEnum } from '@models/geoPortal/enums/geoPortalEnum';
import { useContext } from 'react';

import styles from './GeoPortalMapObjectDetails.module.scss';
import { SystemObjectInfo } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/systemObjectInfo/SystemObjectInfo';
import { FacilityInfo } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/facilityInfo/FacilityInfo';
import { WaterUserInfo } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/waterUserInfo/WaterUserInfo';
import { MonitoringObjectInfo } from './monitoringObjectInfo/MonitoringObjectInfo';
import { getMonitoringTabSelector } from '@store/selectors';
import { useSelector } from 'react-redux';

export const GeoPortalMapObjectDetails: React.FC = () => {
  const { currentAttribute } = useContext(MapContext);
  const monitoringTab = useSelector(getMonitoringTabSelector);
  let Component;
  if (!currentAttribute) {
    return null;
  }
  switch (currentAttribute.type) {
    case GeoAttributeTypeEnum.SYSTEM_OBJECT:
      Component = SystemObjectInfo;
      break;
    case GeoAttributeTypeEnum.FACILITY:
      Component = FacilityInfo;
      break;
    case GeoAttributeTypeEnum.WATER_USER:
      Component = WaterUserInfo;
      break;
    case GeoAttributeTypeEnum.M_HYDROPOST:
    case GeoAttributeTypeEnum.M_OUTLET:
    case GeoAttributeTypeEnum.M_CHANNELS:
      Component = MonitoringObjectInfo;
      break;
    default:
      Component = null;
      break;
  }

  return (
    <div
      className={styles.dataOperationalBlock}
      style={{
        overflow: monitoringTab ? 'unset' : 'auto',
      }}
    >
      {Component && <Component />}
    </div>
  );
};
