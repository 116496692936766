import React, { SyntheticEvent, useCallback } from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps, TableCustomMethodProps } from '@models/table/interfaces/tableCustomActionProps';

import { EditorFactory } from './editor/editorFactory';
import { RendererCellFactory } from './rendererCell/rendererCellFactory';

export interface EditableCellProps<RecordType>
  extends React.HTMLAttributes<HTMLElement> {
  customColumn?: TableColumn;
  record: RecordType;
  actionProps: TableCustomActionProps<RecordType>;
  methodsProps: TableCustomMethodProps<RecordType>[];
  parent?: TableColumn;
}

export function EditableCell<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps,
  methodsProps,
  parent,
  ...restProps
}: EditableCellProps<RecordType>): React.ReactElement {
  const isEditPossible = actionProps?.editingRowKey === null;
  const isEditingCell = actionProps?.getEditingCell?.(record, customColumn!);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      if (customColumn?.editableCell) {
        actionProps?.onEditCellClick?.(record, customColumn);
      }
    },
    [record, customColumn],
  );

  if (!customColumn) return <td {...restProps} />;

  if (customColumn.editableCell && isEditingCell) {
    const editValidator = actionProps.editValidators?.[customColumn.dataIndex];
    const editorElement =
      customColumn.xtype === CustomTableColumnType.RenderCustom
        ? customColumn.renderCustomEditor!({ customColumn, record })
        : EditorFactory.createEditor({
            customColumn,
            record,
            editValidator,
            actionProps,
          });

    return (
      <td onClick={onClick} aria-hidden="true" {...restProps}>
        {editorElement}
      </td>
    );
  }

  const renderProps = {
    customColumn,
    record,

    isEditPossible,
    isEditingCell,
    actionProps,
    methodsProps,
    parent,
  };

  const renderedElement =
    customColumn.xtype === CustomTableColumnType.RenderCustom
      ? customColumn.renderCustom!(renderProps)
      : RendererCellFactory.createRenderer(renderProps);

  return (
    <td onClick={onClick} {...restProps}>
      {renderedElement}
    </td>
  );
}
