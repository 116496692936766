import {
  CustomCard,
  DefaultButton,
  Divider,
  InformationalBlock,
  TooltipedInput,
} from '@features/ui-kit';
import { useGetGmrZoneListQuery } from '@store/gisproApi';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { AddedOutletsTable } from './addedOutletsTable';
import { IrrigationSystemTable } from './irrigationSystemTable';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const LinkedOutlet: React.FC = () => {
  const navigate = useNavigate();
  const { id, hydroModuleDistrictId } = useParams<{
    id: any;
    hydroModuleDistrictId: any;
  }>();

  const { data: gmrs = [] } = useGetGmrZoneListQuery({
    agroclimaticzoneId: id,
  });
  const gmr = gmrs.find((item) => item.gmrId === Number(hydroModuleDistrictId));

  return (
    <Layout>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton onClick={() => navigate(-1)} className="backBtn">
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Агроклиматические зоны',
                },
                {
                  title: 'Редактор ГМР',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[6, 6]}>
        <Col>
          <TooltipedInput
            placeholder="Область"
            value={gmr?.regionName}
            disabled
          />
        </Col>
        <Col>
          <TooltipedInput
            placeholder="Район"
            value={gmr?.districtName}
            disabled
          />
        </Col>
        <Col span={24}>
          <InformationalBlock title="Связанность водовыпусков">
            <CustomCard>
              <Row gutter={8}>
                <Col span={12}>
                  <IrrigationSystemTable gmr={gmr} />
                </Col>
                <Col span={12}>
                  <AddedOutletsTable />
                </Col>
              </Row>
            </CustomCard>
          </InformationalBlock>
        </Col>
      </Row>
    </Layout>
  );
};
