import { CloseOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderDecadeFactCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const {
    value,
    disabled,
    isAdminEdited,
  }: { value: string; disabled: boolean; isAdminEdited: boolean } =
    record[customColumn.dataIndex];

  return value ? (
    <div
      className={`${styles.decadeCell} ${
        disabled && styles.decadeCellDisabled
      } ${isAdminEdited && styles.adminEditedCell}`}
    >
      {value}
    </div>
  ) : (
    <CloseOutlined className={styles.xmark} />
  );
}
