import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CreatingSiteAssociationModal } from '@features/waterUser/waterUserCard/modals/CreatingSiteAssociationModal';
import { WaterUserCardStepperQueryTypes } from '@models/waterUser/enums/waterUserEnums';

import { Documents } from './documents';
import { Employees } from './employees';
import { TotalInformation } from './totalInformation';
import { useCreatingSiteAssociationModal } from '../useCreatingSiteAssociationModal';

import { useTranslation } from 'react-i18next';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const AssociationWaterUserCard = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const currentQueryType = useMemo(
    () =>
      params.get(SectionQueryKey) ||
      WaterUserCardStepperQueryTypes.totalInformation,
    [params],
  );

  const { isModalShown } = useCreatingSiteAssociationModal();

  let Component;
  switch (currentQueryType) {
    case WaterUserCardStepperQueryTypes.totalInformation:
      Component = TotalInformation;
      break;
    case WaterUserCardStepperQueryTypes.employees:
      Component = Employees;
      break;
    case WaterUserCardStepperQueryTypes.document:
      Component = Documents;
      break;
    default:
      Component = TotalInformation;
      break;
  }

  useEffect(() => {
    setHeaderTitle('Карточка водопользователя Ассоциаций');
  }, []);

  return (
    <Row gutter={[0, 16]}>
      <Row gutter={16}>
        <Col>
          <DefaultButton onClick={() => navigate(-1)} className="backBtn">
            <LeftOutlined />
            <Typography.Text>Назад</Typography.Text>
          </DefaultButton>
        </Col>
        <Col>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: t('headers.water-registry-associations'),
              },
              {
                title: t('headers.water-registry-card-associations'),
              },
            ]}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Component />
      {isModalShown && <CreatingSiteAssociationModal />}
    </Row>
  );
};
