import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { Divider, Tabs } from '@features/ui-kit';
import { GeoPortalCardBlockType } from '@models/geoPortal/enums/geoPortalEnum';
import { Button, Col, Input, Row } from 'antd';
import { ChangeEvent, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { MapContext } from '../mapComponent/MapComponentContext';
import styles from './GeoPortalMapNavigation.module.scss';
import { LayerBlock } from './layerBlock';
import { LegendBlock } from './legendBlock';

export const GeoPortalMapNavigation: React.FC = () => {
  const inputRef = useRef(null);

  const { filterKey } = useParams();
  const { setSearchActive, searchValue, setSearchValue, searchActive } =
    useContext(MapContext);

  const options: any[] = [
    {
      key: GeoPortalCardBlockType.Layer,
      label: 'Слои',
      children: <LayerBlock />,
    },
    {
      key: GeoPortalCardBlockType.Legend,
      label: 'Легенда',
      children: <LegendBlock />,
    },
  ];

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (searchActive) {
      if (inputRef.current) {
        (inputRef.current as any).focus();
      }
    }
  }, [searchActive]);

  return (
    <div className={styles.layersLegendBlock}>
      {searchActive ? (
        <Row justify="space-between">
          <Col>
            <Input
              ref={inputRef}
              variant="borderless"
              value={searchValue}
              onChange={onSearchChange}
            />
          </Col>
          <Col>
            <Button
              type="text"
              style={{ marginRight: 5 }}
              icon={<CloseOutlined />}
              onClick={() => {
                setSearchActive(false);
                setSearchValue('');
              }}
            />
          </Col>
          <Divider style={{ marginTop: 8 }} />
        </Row>
      ) : (
        <Tabs
          items={options}
          defaultValue={GeoPortalCardBlockType.Layer}
          tabBarExtraContent={{
            right: (
              <Button
                type="text"
                icon={<SearchOutlined />}
                onClick={() => setSearchActive(true)}
                style={{ marginRight: 5 }}
              />
            ),
          }}
        />
      )}
    </div>
  );
};
