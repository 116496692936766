import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Row, Space, notification } from 'antd';

import { AnnualApplicationAgreementContractDetails } from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementContractDetails';
import { AnnualApplicationAgreementDocumentBlock } from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementDocumentBlock';
import { useAnnualApplicationAgreementModal } from '@features/annualApplication/annualApplicationAgreement/AnnualApplicationAgreementModal/useAnnualApplicationAgreementModal';
import { DangerButton, PrimaryButton } from '@features/ui-kit';
import {
  useGetAnnualContractByIdQuery,
  useSubmitAnnualContractMutation,
  useUpdateAnnualContractStatusMutation,
} from '@store/gisproApi';
import { annualApplicationActions } from '@store/slices';
import { AnnualStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';

import styles from './AnnualApplicationAgreement.module.scss';
import { Routes } from '@enums/routes';

export const AnnualApplicationAgreement: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShowModal } = useAnnualApplicationAgreementModal();

  const { data: annulContract } = useGetAnnualContractByIdQuery({ id });
  const [updateStatus] = useUpdateAnnualContractStatusMutation();

  const [submitAnnualContract] = useSubmitAnnualContractMutation();

  useEffect(() => {
    if (annulContract) {
      dispatch(
        annualApplicationActions.setAnnualApplicationAttachmentsAction(
          annulContract.attachments,
        ),
      );
    }
  }, [annulContract]);

  const onCancelBtn = () => {
    setShowModal(true);
    updateStatus({ id, status: AnnualStatusEnum.CANCELLED })
      .unwrap()
      .then(() => {
        navigate(Routes.AnnualApplicationTable);
        notification.success({
          message: 'Успешно отменили заявку',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Произошла ошибка',
        });
      });
    27;
  };

  const onСonfirmBtn = () => {
    setShowModal(true);
    annulContract?.attachments.length &&
      submitAnnualContract({ id, attachments: annulContract.attachments })
        .unwrap()
        .then(() => {
          navigate(Routes.AnnualApplicationTable);
          notification.success({
            message: 'Успешно подтверждено',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Произошла ошибка',
          });
        });
  };

  const isDisabledConfirmBtn = useMemo(() => {
    return (
      annulContract?.status === AnnualStatusEnum.APPROVED ||
      !annulContract?.attachments?.length
    );
  }, [annulContract]);
  return (
    <div style={{ minHeight: '100%' }}>
      <Row gutter={24} justify="space-between">
        <Col span={16}>
          <AnnualApplicationAgreementContractDetails
            annulContract={annulContract}
          />
        </Col>
        <Col span={8}>
          <AnnualApplicationAgreementDocumentBlock />
        </Col>
      </Row>
      <Row>
        <Col className={styles.footerBtn} span={24}>
          <Row justify="space-between" gutter={16}>
            <Col>
              <DangerButton onClick={onCancelBtn} size="large">
                Отменить
              </DangerButton>
            </Col>
            <Col>
              <Col>
                <PrimaryButton
                  disabled={isDisabledConfirmBtn}
                  onClick={onСonfirmBtn}
                  size="large"
                >
                  {annulContract?.status === AnnualStatusEnum.APPROVED
                    ? 'Подтвержден'
                    : 'Подтвердить'}
                </PrimaryButton>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
