import { Col, Row } from 'antd';

import { WaterUserHouseholdsTable } from '@features/annualApplication/createAnnualApplication/waterUserSelection/WaterUserHouseholdsTable';
import { WaterUserSupplierDetailsCard } from '@features/annualApplication/createAnnualApplication/waterUserSelection/waterUserSupplierDetailsCard';

import { WaterUserDetailCard } from './waterUserDetailCard';
import { WaterUserSelectionTable } from './waterUserSelectionTable';

export const WaterUserSelection = () => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={10}>
        <WaterUserSelectionTable />
      </Col>
      <Col span={14}>
        <Row gutter={8}>
          <Col span={12}>
            <WaterUserDetailCard />
          </Col>
          <Col span={12}>
            <WaterUserSupplierDetailsCard />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <WaterUserHouseholdsTable />
      </Col>
    </Row>
  );
};
