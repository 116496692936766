import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, TablePaginationConfig, Typography } from 'antd';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { SupplyPrintModal } from '@features/wateringPlan/wateringSupplySchedule/modals/supplyPrintModal';
import { useSupplyPrintModal } from '@features/wateringPlan/wateringSupplySchedule/modals/useSupplyPrintModal';
import { mapWateringSupplyScheduleTableData } from '@features/wateringPlan/wateringSupplySchedule/wateringSupplyScheduleTable/mapWateringSupplyScheduleTableData';
import { useWateringSupplyScheduleTableColumns } from '@features/wateringPlan/wateringSupplySchedule/wateringSupplyScheduleTable/useWateringSupplyScheduleTableColumns';
import { useWaterUsersModal } from '@features/waterUser/waterUsersModal/useWaterUsersModal';
import { PrintIcon } from '@icons/PrintIcon';
import { useGetWateringPlanOperationalWaterSupplyQuery } from '@store/gisproApi';

import { useWaterSupplyFilters } from '../wateringSupplyScheduleControls/useWaterSupplyFilters';
import { useReactToPrint } from 'react-to-print';

export const WateringSupplyScheduleTable: React.FC = () => {
  const { filters, setWaterUseWaterSupplyFilters } = useWaterSupplyFilters();
  const {
    data: content = [],
    isLoading: waterSuppliesLoading,
    isFetching,
  } = useGetWateringPlanOperationalWaterSupplyQuery({
    ...filters,
    page: filters.page,
    size: filters.size,
    month: filters.month,
    userType: filters.userType,
  });
  const { t } = useTranslation();
  const { setShowModal } = useSupplyPrintModal();
  const { setShowModal: setShowWaterUsersModal, setOutletId } =
    useWaterUsersModal();

  const printRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'wateringSchedulePrint',
  });

  const { columns } = useMemo(
    () =>
      useWateringSupplyScheduleTableColumns({
        month: t(`months.${filters.month}`),
      }),
    [content],
  );

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterUseWaterSupplyFilters({
          ...filters,
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [columns],
  );

  const onWaterUsersClick = (record: any) => {
    setShowWaterUsersModal(true);
    setOutletId(record.outletId);
  };

  const { data: dataSource } = useMemo(
    () =>
      mapWateringSupplyScheduleTableData({
        data: content,
        month: filters.month,
      }),
    [content],
  );

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>
              Оперативный план водоподачи
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton onClick={handlePrint} icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      <Col span={24} ref={printRef}>
        <TableCustom
          scroll={{ x: 1776 }}
          bordered
          size="small"
          columns={columns}
          dataSource={dataSource}
          loading={isFetching || waterSuppliesLoading}
          onChange={onTableChange}
          actionProps={{
            onWaterUsersClick,
          }}
          // customPagination={rest}
        />
      </Col>
      <SupplyPrintModal />
    </CustomCard>
  );
};
