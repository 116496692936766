import React from 'react';

export type IModalState<T> = {
  isOpen: boolean;
  data: T;
  open: (v?: T) => void;
  close: () => void;
  change: (v?: T) => void;
  props: {
    open: boolean;
    data: T;
    onClose: (e: React.MouseEvent<HTMLElement>) => void;
  }
};

export const useModalState = <T>(initialState: T = (undefined as any)): IModalState<T> => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<T>(initialState);

  return {
    isOpen: open,
    data: state,
    open(data?: T): void {
      setOpen(true);

      if (data) {
        setState(data);
      }
      else if (initialState) {
        setState(initialState);
      }
    },
    close(): void {
      setOpen(false);
    },
    change(data?: T): void {
      if (data) {
        setState(data);
      }
      else if (initialState) {
        setState(initialState);
      }
    },
    props: {
      open,
      data: state,
      onClose: (): void => {
        setOpen(false);
      },
    },
  };
};
