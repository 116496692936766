import React from 'react';

import type { SegmentedProps } from 'antd';
import { Segmented as AntdSegmented } from 'antd';

import styles from './segmented.module.scss';

type CustomSegmentedPropsType = Omit<SegmentedProps, 'ref'>;
export const Segmented: React.FC<CustomSegmentedPropsType> = ({
  className = '',
  ...props
}) => {
  return (
    <AntdSegmented {...props} className={`${styles.segmented} ${className}`} />
  );
};
