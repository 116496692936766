import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { WaterSection } from '@features/waterSection';
import { CreateMainConduitQueryTypes } from '@models/waterObject/enums/mainConduitEnum';
import { waterObjectActions } from '@store/slices';
import { useIrrigationFindByAqueductIdQuery } from '@store/api/irrigationSystemApi';
import { useWaterSectionsControllerFindBySystemObjectIdQuery } from '@store/api/waterSections';
import { PrimaryButton } from '@features/ui-kit';

export const WaterSectionWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mainConduitId, systemId } = useParams<{
    systemId: any;
    mainConduitId: any;
  }>();

  const { data } = useIrrigationFindByAqueductIdQuery({ aqueductId: mainConduitId });

  const { data: waterSections = [], isLoading } =
    useWaterSectionsControllerFindBySystemObjectIdQuery({ id: mainConduitId });

  const onNextButtonClick = () => {
    const path = generatePath(Routes.IrrigationSystemMainConduitRegistration, {
      systemId,
      mainConduitId,
    });
    navigate(`${path}?${SectionQueryKey}=${CreateMainConduitQueryTypes.gts}`);
  };

  useEffect(() => {
    if (data) {
      dispatch(waterObjectActions.setWaterObject(data));
    }
  }, [data]);

  return (
    <>
      <WaterSection />
      <Row justify="end" style={{ marginTop: 16 }}>
        <Col>
          <PrimaryButton
            onClick={onNextButtonClick}
            htmlType="button"
            size="large"
            disabled={waterSections.length === 0}
          >
            Завершить
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );
};
