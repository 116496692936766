import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Card, Col, Form, Row } from 'antd';

import { StatusInfo } from '@features/manageEvents/components';
import { TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { IRegularEventBlock } from '@models/manageEvents/interfaces/regularEventBlock';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetRegularEventByIdQuery,
  useGetRegularEventTemplatesQuery,
  useLazyGetRegularEventTemplateByIdQuery,
} from '@store/gisproApi';
import { getRegularEventBlockSelector } from '@store/selectors';
import { setRegularEventBlockAction } from '@store/slices';
import { debounce, isInteger } from '@utils/utils';

import styles from './RegularEventsCard.module.scss';

export const HeaderRegularEventCard: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const regularEventBlock = useSelector(getRegularEventBlockSelector);
  const onValuesChange = debounce(
    (values: Partial<IRegularEventBlock>) => {
      dispatch(setRegularEventBlockAction(values));
    },
    250,
    {
      maxWait: 1000,
    },
  );

  const { data: regularEvent } = useGetRegularEventByIdQuery(
    id && isInteger(parseInt(id, 10)) ? parseInt(id, 10) : skipToken,
  );

  const { data: templates = [], isLoading: templateIsLoading } =
    useGetRegularEventTemplatesQuery('');

  const [triggerTemplate, { data: template }] =
    useLazyGetRegularEventTemplateByIdQuery();

  const setRegularEventTemplate = (value: string) => {
    triggerTemplate(value).then(({ data }) => {
      dispatch(
        setRegularEventBlockAction({
          ...data,
          id: data?.taskId || null,
        }),
      );
    });
  };

  const templateDisabled = !!regularEventBlock.id;

  useEffect(() => {
    form.setFieldsValue(regularEventBlock);
  }, [regularEventBlock]);

  return (
    <Form<IRegularEventBlock>
      layout="vertical"
      onValuesChange={onValuesChange}
      form={form}
    >
      <Row align="bottom" justify="start" gutter={24}>
        <Col span={4}>
          <Form.Item className={styles.formItem} name="id">
            <TooltipedInput
              className={styles.input}
              placeholder="ID задачи"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item rules={[{ required: true }]} name="title">
            <TooltipedInput
              placeholder="Наименование задачи"
              disabled={!!regularEvent?.id}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="templateId">
            <TooltipedSelect
              placeholder="Загрузить шаблон"
              disabled={templateDisabled}
              loading={templateIsLoading}
              options={templates.map((item: any) => ({
                label: item.templateName,
                value: item.id,
              }))}
              onChange={setRegularEventTemplate}
            />
          </Form.Item>
        </Col>
        <Col span={3} offset={8}>
          <Row justify="end">
            <Form.Item>
              <StatusInfo
                className={styles.status}
                status={regularEventBlock?.status}
                planEndDate={regularEventBlock?.planEndDate}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
