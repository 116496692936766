import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, notification, Row, Tag } from 'antd';

import { Links } from '@enums/links';
import { Routes } from '@enums/routes';
import { useAssociationStatsModal } from '@features/waterUser/associationStatsModal/useAssociationStatsModal';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

import styles from './styles.module.scss';

export function renderControl<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setShowModal: setStatsShowModal } = useAssociationStatsModal();

  return (
    <Row style={{ gap: 5 }} wrap={false}>
      <Col flex="auto">
        <Tag
          icon={<img src={Links.Doc} alt="" width={10} height={10} />}
          color="#3b5999"
          className={styles.controlTag}
          onClick={() =>
            navigate(generatePath(Routes.WaterUserCard, { id: record.id }))
          }
        >
          {t('main.table.card')}
        </Tag>
      </Col>
      <Col flex="1 1 47px">
        <Tag
          icon={<img src={Links.Doc} alt="" width={10} height={10} />}
          color="#3b5999"
          className={styles.controlTag}
          onClick={() => {
            notification.warning({
              message: 'Информация о годовой заявке не указана',
            });
          }}
        >
          {t('main.table.yearly')}
        </Tag>
      </Col>
      {record.type === WaterUserTypes.association && (
        <Col flex="60px">
          <Tag
            icon={<img src={Links.Doc} alt="" width={10} height={10} />}
            color="#3b5999"
            className={styles.controlTag}
            onClick={() => setStatsShowModal(true)}
          >
            {t('main.table.stats')}
          </Tag>
        </Col>
      )}
      <Col flex="auto">
        <Tag
          icon={<img src={Links.Doc} alt="" width={10} height={10} />}
          color="#3b5999"
          className={styles.controlTag}
        >
          {t('main.table.permissions')}
        </Tag>
      </Col>
    </Row>
  );
}
