export const linearObjectCategoriesCodes: string[] = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '10',
  '11',
  '13',
  '14',
  '15',
  '16',
  '18',
  '23',
  '38',
  '39',
  '40',
  '53',
  '61',
  '62',
  '66',
  '69',
  '70',
];
