import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

type ReturnType = {
  columns: TableColumn[];
};

export const useAssociationTableContainerColumns = (): ReturnType => {
  const { t } = useTranslation();

  const location = useLocation();
  const isArchived = location.pathname.includes(Routes.ArchivariusTable);

  // const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
  //   id: EntityTypesEnum.Okmot,
  // });
  //
  // const { data: associationTypes = [] } = useFindDictionaryByTypeIdQuery({
  //   id: EntityTypesEnum.AssociationTypes,
  // });

  const columns: TableColumn[] = [
    {
      title: 'Регистрационный номер',

      dataIndex: 'legalData.regNumber',
      xtype: CustomTableColumnType.String,
      width: 120,
    },
    {
      title: t('main.table.organizationName'),
      dataIndex: 'legalData.name',

      width: 170,
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('main.table.type'),
      dataIndex: 'legalData.associationType.title',
      key: 'associationTypeId',
      width: 200,
      xtype: CustomTableColumnType.String,
    },
    {
      title: t('main.table.organizationAddress'),
      dataIndex: 'legalData.address',

      xtype: CustomTableColumnType.String,
      width: 190,
    },
    {
      title: t('main.table.totalArea-outlets'),
      dataIndex: 'businessActivityId',

      key: 'area',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) =>
        `${record.legalData?.totalArea || 0} / ${
          record.legalData.outletsCount
        }`,
      width: 200,
    },
    {
      title: t('main.table.cabinet/status'),
      dataIndex: 'hasCabin',
      key: 'cabinet',

      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: (props) => (
        <div>
          {props.record.hasCabin ? 'Да' : 'Нет'} /{' '}
          {props.record.active ? 'Актив' : 'Пассив'}
        </div>
      ),
      width: 150,
    },
    {
      title: t('main.table.controls'),
      dataIndex: 'controls',
      key: 'controls',
      align: 'center',
      width: 620,
      xtype: CustomTableColumnType.WaterRegistryControl,
    },
    {
      title: t('main.table.actions'),
      key: 'controls',
      align: 'center',
      dataIndex: 'controls',
      width: 100,
      actions: [
        isArchived
          ? CustomTableAction.UnArchiveWithoutText
          : CustomTableAction.ArchiveWithoutText,
      ],
      xtype: CustomTableColumnType.ActionColumn,
    },
  ];

  return { columns };
};
