import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HeaderState = {
  headerTitle: string;
  returnUrl: string[];
};

const initialState: HeaderState = {
  headerTitle: 'Главная страница',
  returnUrl: [],
};

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState,
  reducers: {
    setHeaderTitleAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        headerTitle: action.payload,
      };
    },
    changeReturnUrl: (state, action: PayloadAction<string | undefined>) => {
      if (!action.payload) {
        state.returnUrl.pop();
      } else {
        state.returnUrl.push(action.payload);
      }
    },
    clearReturnUrl: (state) => {
      return {
        ...state,
        returnUrl: [],
      };
    },
  },
});

export const { setHeaderTitleAction, changeReturnUrl, clearReturnUrl } =
  headerSlice.actions;

export default headerSlice.reducer;
