import { router } from '@router/router';
import { colors } from '@theme/colors';

import { ConfigProvider, theme } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { LoadingPage } from './pages';

import 'dayjs/locale/ru';
import './theme/_colors.scss';

dayjs.extend(utc);
dayjs.extend(isBetween);

export const App: React.FC = () => {
  useEffect(() => {
    document.title = 'ЕИСВ КР';
  }, []);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: colors.primary,
          colorBgLayout: colors.main_color_white_1,
          fontSizeHeading2: 32,
          fontFamily: 'Open Sans',
        },
        components: {
          Layout: {
            siderBg: colors.main_color_white_3,
          },
          Switch: {},
          Segmented: {
            trackBg: colors.main_color_grey_3,
            itemSelectedColor: colors.main_color_blue_1,
          },
          Input: {
            colorPrimaryHover: colors.input_border,
            colorPrimary: colors.input_border,
            controlOutline: colors.transparent,
          },
          Select: {
            colorPrimaryHover: colors.input_border,
            colorPrimary: colors.input_border,
            controlOutline: colors.transparent,
          },
          Statistic: {
            contentFontSize: 40,
          },
          Tag: {
            'magenta-1': colors.magenta1,
            'magenta-3': colors.magenta3,
            'magenta-7': colors.magenta7,
            'geekblue-1': colors.geekblue1,
            'geekblue-3': colors.geekblue3,
            'geekblue-7': colors.geekblue7,
          },
          Typography: {
            fontSizeHeading1: 64,
            fontSizeHeading2: 24,
            fontSizeHeading3: 20,
            fontSizeHeading4: 16,
            colorTextHeading: colors.main_color_grey_1,
          },
          Steps: {
            descriptionMaxWidth: 430,
          },
        },
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Suspense fallback={<LoadingPage />}>
        <RouterProvider router={router} />
      </Suspense>
    </ConfigProvider>
  );
};
