import { Table, Typography } from 'antd';

import styles from './BusinessPlanTableContainer.module.scss';

interface IBusinessPlanSummeryDataProps {
  data: any;
}

export const BusinessPlanSummeryData: React.FC<
  IBusinessPlanSummeryDataProps
> = ({ data }) => {
  const resultArray = [
    {
      name: 'Cум. к-во дней полива за вег. период',
      perSut: 0,
      area: 0,
      litersPerSecond: 0,
    },
    {
      name: 'Cум. водопотребл. за вег. период',
      perSut: 0,
      area: 0,
      litersPerSecond: 0,
    },
    {
      name: 'Cум. водоподача за вег. период',
      perSut: 0,
      area: 0,
      litersPerSecond: 0,
    },
  ];

  data.forEach((item: any) => {
    resultArray.forEach((obj) => {
      obj.perSut += item.days;
      obj.area += item.wateringVolumeTotal;
      obj.litersPerSecond += item.wateringVolume;
    });
  });

  return (
    <>
      {resultArray.map((item) => (
        <Table.Summary.Row key={item.name}>
          <Table.Summary.Cell index={0} colSpan={4}>
            <Typography.Text>
              <strong>{item.name}</strong>
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center" index={1} colSpan={1}>
            <Typography.Text>{item.perSut}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center" index={2} colSpan={1}>
            <Typography.Text>{item.area}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center" index={3} colSpan={3}>
            <Typography.Text>{item.litersPerSecond}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      ))}
    </>
  );
};
