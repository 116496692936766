import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IAnnualContractualWaterSupplyFilters } from '@models/annualApplication/interfaces/annualApplicationFilters';
import { getAnnualContractualWaterSupplyFiltersSelector } from '@store/selectors';
import { annualApplicationActions } from '@store/slices';

type ReturnType = {
  filters: Partial<IAnnualContractualWaterSupplyFilters>;
  setAnnualContractualWaterSupplyFilters: (
    filters: Partial<IAnnualContractualWaterSupplyFilters>,
  ) => void;
};

export const useContractualWaterSupplyTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const initialParams = ['page', 'size'];

  const filters = useSelector(getAnnualContractualWaterSupplyFiltersSelector);

  const setAnnualContractualWaterSupplyFilters = useCallback(
    (newFilters: Partial<IAnnualContractualWaterSupplyFilters>) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);
      dispatch(
        annualApplicationActions.changeAnnualContractualWaterSupplyFilters(
          newFilters as Required<IAnnualContractualWaterSupplyFilters>,
        ),
      );
    },
    [],
  );

  return { filters, setAnnualContractualWaterSupplyFilters };
};
