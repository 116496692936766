import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

export function renderDate<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value: string = record[customColumn.dataIndex];

  if (value) {
    return <div>renderDate</div>;
  }

  return null;
}
