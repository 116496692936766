import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, Row, Space } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { OwnerTypes } from '@enums/ownerTypes';
import { useGetCurrentUser } from '@features/authentication';
import { DashedButton, PrimaryButton, TooltipedSelect } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetOwnerChildsQuery } from '@store/gisproApi';
import { getCaFiltersSelector } from '@store/selectors';
import { setCaFilters } from '@store/slices';

export const WaterUsePlanCAControlBar: React.FC = () => {
  const [form] = Form.useForm();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const regionId = Form.useWatch('regionId', form);
  const caFilters = useSelector(getCaFiltersSelector);
  const districtId = Form.useWatch('districtId', form);
  const ownerId = user?.owner?.id;
  const dispatch = useDispatch();
  const { data: ownerGuvhs, isLoading: guvhsLoading } = useGetOwnerChildsQuery({
    ownerId: String(ownerId),
  });
  const { data: ownerRuvhs, isLoading: ruvhLoading } = useGetOwnerChildsQuery(
    regionId ? { ownerId: regionId } : skipToken,
  );

  const onRegionChange = (v: number, option: any) => {
    form.resetFields(['districtId']);
    dispatch(
      setCaFilters({ regionId: v, districtId, districtName: option?.label }),
    );
  };

  const onDistrictChange = (v: number, option: any) => {
    dispatch(
      setCaFilters({ regionId, districtId: v, districtName: option?.label }),
    );
  };

  return (
    <Row justify="space-between">
      <Col>
        <Form form={form} initialValues={{ ...caFilters }}>
          <Space size={16}>
            <Form.Item name="regionId" initialValue={user?.owner?.region?.id}>
              <TooltipedSelect
                placeholder="Выберите Область"
                options={ownerGuvhs}
                loading={guvhsLoading}
                onChange={onRegionChange}
                popupMatchSelectWidth={130}
                disabled={isDisabledRegion}
              />
            </Form.Item>
            <Form.Item
              name="districtId"
              initialValue={user?.owner?.district?.id}
            >
              <TooltipedSelect
                disabled={isDisabledDistrict || !regionId}
                placeholder="Выберите Район"
                options={ownerRuvhs}
                loading={ruvhLoading}
                popupMatchSelectWidth={170}
                onChange={onDistrictChange}
              />
            </Form.Item>
          </Space>
        </Form>
      </Col>
      <Col>
        {regionId && (
          <Row gutter={20}>
            <Col>
              <DashedButton colorType="green">На согласовании ЦА</DashedButton>
            </Col>
            <Col>
              <PrimaryButton>Изменить статус</PrimaryButton>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
