import {
  CustomModal,
  DefaultButton,
  PicketInput,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { Col, Form, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo } from 'react';
import styles from './ReservoitCreateModal.module.scss';
import {
  shortPicketInputValidator,
} from '@utils/validation/picketInputValidator';
import {
  useCreateSystemObjectMutation,
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { AccountingGroupEnum } from '@enums/accountingGroup';
import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { extractObjectTypes } from '@utils/objectType';

interface IReservoirCreateModalProps {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
}

export const ReservoirCreateModal: FC<IReservoirCreateModalProps> = ({
  setShowModal,
  showModal,
}) => {
  const [form] = Form.useForm();

  const [save, { error, isError, data }] = useCreateSystemObjectMutation();

  const objectGroupId = Form.useWatch('objectGroupId', form);

  const objectCategoryId = Form.useWatch('objectCategoryId', form);

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      objectGroupId
        ? {
            objectGroupId,
          }
        : skipToken,
    );
  const { data: groups, isLoading: groupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const objectGroups = useMemo(() => {
    return groups?.filter((item: IObjectGroup) => {
      return (
        item.code === parseInt(AccountingGroupEnum.Hole, 10) ||
        item.code === parseInt(AccountingGroupEnum.WaterOutlet, 10)
      );
    });
  }, [groups]);

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const onFinish = useCallback((values) => {
    save(values);
  }, []);

  const onCancelButtonClick = () => {
    setShowModal(false);
  };

  const onGroupChange = () => {
    form.setFieldValue('objectCategoryId', null);
    form.setFieldValue('coordinates', '');
  };

  const onCategoryChange = () => {
    form.setFieldValue('typeCategory', null);
  };

  useEffect(() => {
    if (data) {
      setShowModal(false);
      form.resetFields();
    }
  }, [data]);

  return (
    <CustomModal
      open={showModal}
      title="Создать"
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Наименование"
              name="objectName"
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Введите наименование" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Код обьекта"
              name="objectCode"
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Введите код объекта" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              style={{ marginBottom: 10 }}
              name="dateStart"
              label="Год ввода в эксплуатацию"
              rules={[{ required: true }]}
            >
              <YearPicker
                bordered
                placeholder="Выберите год ввода в эксплуатацию"
                className={styles.input}
                disabledDate={(current) =>
                  current && current > dayjs().endOf('year')
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="objectGroupId"
              label="Группа"
              rules={[{ required: true }]}
              style={{ marginBottom: 10 }}
            >
              <TooltipedSelect
                placeholder="Выберите группу"
                allowClear
                loading={groupsIsLoading}
                options={objectGroups}
                onChange={onGroupChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="objectCategoryId"
              label="Категория"
              required
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                placeholder="Выберите категорию"
                allowClear
                disabled={!objectGroupId}
                loading={objectCategoriesIsLoading}
                options={objectCategories}
                onChange={onCategoryChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Тип"
              name="typeCategory"
              rules={[{ required: true }]}
              style={{ marginBottom: 10 }}
            >
              <TooltipedSelect
                placeholder="Выберите тип"
                disabled={!objectCategoryId}
                options={objectTypes}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="start" gutter={20}>
          <Col span={12}>
            <Form.Item
              name="coordinates"
              label="Пикетная координата"
              rules={[
                { required: true },
                shortPicketInputValidator,
              ]}
              style={{ marginBottom: 10 }}
            >
              <PicketInput isLinear={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Form>
    </CustomModal>
  );
};
