import { Tags } from '@constants/tags';
import { LocalStorageKey } from '@enums/localStorageKey';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { LocalStorage } from '@utils/localStorage/localStorage';
import { Mutex } from 'async-mutex';

import { logOut, TokenReceived } from './slices';

// create a new mutex
const mutex = new Mutex();
const baseURL = process.env.REACT_APP_MAIN_BASE_URL || '';

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL.replace(
    'https:',
    // eslint-disable-next-line no-restricted-globals
    location.protocol === 'https:' ? 'https:' : 'http:',
  ),
  prepareHeaders: (headers, { getState }) => {
    const token = LocalStorage.getItem(LocalStorageKey.AccessToken) as string;

    // If we have a token set in state, let's assume that we should be passing it.
    if (token && !mutex.isLocked()) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (
    (result.error && (result.error as any)?.status === 401) ||
    (result.error as any)?.originalStatus === 403
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const token = LocalStorage.getItem(LocalStorageKey.TokenUUID) as string;
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh-token',
            method: 'POST',
            body: {
              token,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: '',
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(TokenReceived(refreshResult.data as any));
          // release must be called once the mutex should be released again.
          release();

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const basicApi = createApi({
  reducerPath: 'basicApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: Object.values(Tags),
  endpoints: () => ({}),
  keepUnusedDataFor: 30,
});
export const api = basicApi;
