import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Col, Form, notification, Row } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';

import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { useWaterUserPermissionModal } from '@features/waterUser/useWaterUserPermissionModal';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindAllByOrganizationIdQuery,
  useSignUpMutation,
} from '@store/gisproApi';
import { mapPhoneNumberToLong } from '@utils/phone';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { passportValidator } from '@utils/validation/passportValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

import { useAccessControllerGetRolesQuery } from '@store/api/accessApi';
import { UserRoles } from '@enums/roles';

type IndividualPermissionsModalForm = {
  login: string;
  password: string;
  pin: string;
  phone: string;
  email: string;
};

export const IndividualPermissionsModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: roles = [] } = useAccessControllerGetRolesQuery();

  const [signUp] = useSignUpMutation();
  const { waterUserId } = useWaterUserId();
  const { organizationId } = useOrganizationId();
  const { isModalShownPhysical, setShowModalPhysical } =
    useWaterUserPermissionModal();

  const { data = {} } = useFindAllByOrganizationIdQuery(
    +waterUserId
      ? {
          id: +waterUserId,
        }
      : skipToken,
  );

  const mappedRoles = useMemo(() => {
    const isAssociation = location.pathname
      .split('/')
      .filter((v) => v)
      .includes('users-table');

    const currentRole = isAssociation
      ? UserRoles.ROLE_WATER_USER
      : UserRoles.ROLE_ASSOCIATION;

    const currentParent = roles.find((item) => item.roleName === currentRole);

    return roles
      .map((item) => ({
        label: item.title,
        value: item.id,
        parent: item.parent?.id ?? null,
      }))
      .filter(
        (item) =>
          item.value === currentParent?.id || item.parent === currentParent?.id,
      );
  }, [roles]);

  const onFinish = (values: any) => {
    const { phone, ...other } = values;

    signUp([
      {
        ...other,
        employeeId: data?.employees?.[0]?.id,
        organizationId,
        isOrganization: true,
      },
    ])
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Доступ успешно выдан',
        });
      })
      .catch((err) => {
        notification.success({
          message: err?.data?.message ?? 'Произошла  ошибка',
        });
      })
      .finally(() => {
        navigate({
          pathname: Routes.WaterRegistryUsers,
          search: `?${SectionQueryKey}=${WaterUserTypes.physical}`,
        });
        setShowModalPhysical(false);
        form.resetFields();
      });
  };

  useEffect(() => {
    const employee = data?.employees?.[0];
    form.setFieldsValue({
      login: employee?.user?.login,
      email: employee?.email,
      phone: employee?.phone,
      inn: employee?.inn,
    });
  }, [waterUserId, data]);

  return (
    <CustomModal
      centered
      title={'Выдача доступов'}
      open={isModalShownPhysical}
      onCancel={() => setShowModalPhysical(false)}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={370}
      footer={
        <Row justify="center">
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              Завершить
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form<IndividualPermissionsModalForm>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Col span={24}>
          <Form.Item
            label={t('authentication.login.placeholders.login')}
            name="login"
            rules={[{ required: true }]}
          >
            <TooltipedInput
              disabled={data?.employees?.[0]?.user?.login}
              placeholder={t('authentication.login.placeholders.login')}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('createIndividualWaterRegistry.modal.pin')}
            name="inn"
            rules={[{ required: true }, { len: 14 }, numbersValidator]}
            style={{ width: '100%' }}
          >
            <TooltipedInput disabled placeholder="ПИН" size="large" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('authentication.login.placeholders.password')}
            name="password"
            rules={[{ required: true }, passportValidator]}
            style={{ width: '100%' }}
          >
            <TooltipedInput
              placeholder={t('authentication.login.placeholders.password')}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t(
              'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.phone',
            )}
            name="phone"
            rules={[
              {
                required: true,
              },
              phoneNumberValidator,
            ]}
            normalize={mapPhoneNumberToLong}
            style={{ width: '100%' }}
          >
            <TooltipedInput
              placeholder="Номер телефона"
              size="large"
              disabled
              addonBefore="+996"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t(
              'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.email',
            )}
            name="email"
            rules={[{ required: true, type: 'email' }]}
            style={{ width: '100%' }}
          >
            <TooltipedInput disabled placeholder="Email" size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[{ required: true }]}
            label="Роль"
            name="role"
            style={{ width: '100%' }}
          >
            <TooltipedSelect allowClear options={mappedRoles} />
          </Form.Item>
        </Col>
      </Form>
    </CustomModal>
  );
};
