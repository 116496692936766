import { CloseOutlined } from '@ant-design/icons';
import { CustomModal, DangerButton, PrimaryButton } from '@features/ui-kit';
import styles from './RevokeAccessModal.module.scss';
import { Col, Row } from 'antd';
import { FC } from 'react';
interface IDeleteHydraulicModalProps {
  onCancel: any;
  revokeAccess: any;
}

export const RevokeAccessModal: FC<IDeleteHydraulicModalProps> = ({
  onCancel,
  revokeAccess,
}) => {
  const Footer = (
    <Row gutter={10} justify="center">
      <Col>
        <PrimaryButton type="default" size="large" onClick={onCancel}>
          Нет
        </PrimaryButton>
      </Col>
      <Col>
        <PrimaryButton type="primary" size="large" onClick={revokeAccess}>
          Да, хочу!
        </PrimaryButton>
      </Col>
    </Row>
  );

  return (
    <CustomModal
      open
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={370}
      onCancel={onCancel}
      footer={Footer}
      title="Потверждение"
      centered
    >
      <Row className={styles.modalContent}>
        <Col span={24} className={styles.modalContentTitle}>
          <p>Вы действительно хотите отозвать доступ?</p>
        </Col>
      </Row>
    </CustomModal>
  );
};
