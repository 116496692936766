import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { Routes } from '@enums/routes';
import { useAssignExecutorModal } from '@features/manageEvents/useAssignExecutorModal';
import { useCreateTemplateModal } from '@features/manageEvents/useCreateTemplateModal';
import {
  CustomCard,
  DefaultButton,
  PrimaryButton,
  PriorityGroup,
  TooltipedDatePicker,
  TooltipedSelect,
} from '@features/ui-kit';
import { AddExecutorButton } from '@features/ui-kit/buttons/AddExecutorButton';
import {
  BindingTypes,
  TaskStatusEnum,
} from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateOtherTaskBlockMutation,
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListQuery,
  useFindAllIrrigationSystemObjectsListWithFiltersQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindDictionaryByTypeIdQuery,
  useGetHydroFacilityAllQuery,
  useGetOtherTaskByIdQuery,
  useGetReferencesByParentIdQuery,
  useSaveOtherTaskBlockMutation,
} from '@store/gisproApi';
import { getOtherTaskBlockSelector } from '@store/selectors';
import { setOtherTaskBlockAction } from '@store/slices';
import {
  validatePlanEndDate,
  validatePlanStartDate,
} from '@utils/validation/deadlineValidation';
import dayjs from 'dayjs';
import { clone } from 'lodash'; // TODO: change lodash to native implementation
import { isEmpty, isInteger, toInteger } from '@utils/utils';
import { useGetCurrentUser } from '@features/authentication';

import styles from './OtherTasksCard.module.scss';
import { EntityTypesEnum } from '@enums/entityTypes';

export const CreateOtherTasks: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: any }>();
  const dispatch = useDispatch();
  const otherTaskBlock = useSelector(getOtherTaskBlockSelector);
  const [bindType, setBindType] = useState<BindingTypes | string>('');
  const { setShow } = useCreateTemplateModal();
  const navigate = useNavigate();
  const [createOtherTask] = useCreateOtherTaskBlockMutation();
  const [saveOtherTask] = useSaveOtherTaskBlockMutation();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const regionId = Form.useWatch('regionId', form);
  const districtId = Form.useWatch('districtId', form);
  const hydroFacilityId = Form.useWatch('hydroFacilityId', form);
  const systemId = Form.useWatch('systemId', form);
  const { data: otherTask, refetch } = useGetOtherTaskByIdQuery(
    id ? id : skipToken,
  );
  const { setShow: setShowAssignExecutorModalAction } =
    useAssignExecutorModal();

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId
        ? {
            hydroFacilityId,
          }
        : skipToken,
    );
  // const { data: objectsList, isLoading: objectsListIsLoading } =
  //   useFindAllIrrigationSystemObjectsListQuery();
  const { data: objectsList, isLoading: objectsListIsLoading } =
    useFindAllIrrigationSystemObjectsListWithFiltersQuery(
      systemId
        ? {
            irrigationSystemId: systemId,
            size: 10000,
          }
        : skipToken,
    );

  const onFinish = (values: any) => {
    const { districtId, regionId, hydroFacilityId, ...newValues } = values;

    saveOtherTask({
      ...newValues,
      id: otherTask?.id || undefined,
      title: otherTaskBlock.title || otherTask?.title,
      executorId:
        toInteger(otherTaskBlock?.executor?.id) ||
        toInteger(otherTask?.executor?.id),
      systemObjectId: values.systemObjectId || null,
      systemId: toInteger(values.systemId) || otherTaskBlock.system?.id,
      isTemplate: false,
      isInternal: false,
      isArchived: false,
    })
      .unwrap()
      .then((data: any) => {
        navigate(
          generatePath(Routes.OtherTask, {
            id: data.id,
          }),
        );
        notification.success({
          message: 'Вы успешно сохранили задачу',
        });
      })
      .catch((err) => {
        console.log('error', err);
        notification.error({
          message: 'Не удалось сохранить задачу',
        });
      });
  };

  const createTaskHandle = () => {
    if (otherTask?.id) {
      createOtherTask(otherTask.id)
        .unwrap()
        .then((data: any) => {
          refetch();
          notification.success({
            message: 'Вы успешно создали задачу',
          });
        })
        .catch((err) => {
          notification.error({
            message: 'Не удалось создать задачу',
          });
        });
    }
  };

  const createTemplate = () => {
    const values = form.getFieldsValue();
    dispatch(setOtherTaskBlockAction(values));
    setShow(true);
  };

  const isSaveDisabled = useMemo(
    () =>
      isEmpty(otherTaskBlock.executor) &&
      otherTask &&
      isEmpty(otherTask?.executor),
    [otherTaskBlock, otherTask],
  );

  const isEditingDisabled = useMemo(
    () =>
      (!otherTask?.id && !otherTaskBlock.title) ||
      (otherTask && otherTask.status !== TaskStatusEnum.draft),
    [otherTaskBlock, otherTask],
  );

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.Region,
    });

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    isInteger(regionId) ? regionId : skipToken,
  );

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery(
    regionId || districtId
      ? {
          region: regionId,
          district: districtId,
        }
      : skipToken,
  );

  const otherTaskBlockValid = useMemo(() => {
    if (otherTaskBlock?.executor?.id === undefined) {
      return false;
    }
    return true;
  }, [otherTaskBlock?.executor?.id]);

  useEffect(() => {
    const setFormFields = (data: any) => {
      const dataClone = clone(data);
      dataClone.hydroFacilityId = toInteger(
        dataClone?.system.hydroFacility?.id,
      );
      dataClone.systemId = dataClone.system?.id;
      dataClone.executorId = toInteger(dataClone.executor?.id);
      dataClone.systemObjectId = toInteger(dataClone.systemObject?.id);
      dataClone.planStartDate = dayjs(dataClone.planStartDate);
      dataClone.planEndDate = dayjs(dataClone.planEndDate);
      form.setFieldsValue(dataClone);
    };
    if (otherTask) {
      setFormFields(otherTask);
    } else if (otherTaskBlock.isTemplate) {
      setFormFields(otherTaskBlock);
    }
    if (otherTask && otherTask.system && otherTask.systemObject) {
      setBindType(BindingTypes.OBJECT);
    } else if (otherTask && otherTask.system) {
      setBindType(BindingTypes.SYSTEM);
    }
    if (otherTaskBlockValid) {
      form.resetFields(['executorId']);
    }
  }, [otherTask, otherTaskBlock, otherTaskBlockValid, form]);

  const handleChangeHydroFacility = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const handleChangeRegion = () => {
    form.resetFields(['districtId', 'systemId']);
  };

  const handleChangeDisctrict = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <CustomCard>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className={styles.form}
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4}>Создание задачи</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton
                  onClick={createTemplate}
                  disabled={isSaveDisabled || isEditingDisabled}
                >
                  Создать шаблон
                </DefaultButton>
              </Col>
              <Col>
                <DefaultButton
                  disabled={isSaveDisabled || isEditingDisabled}
                  htmlType="submit"
                >
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  disabled={
                    !otherTaskBlock?.id || isSaveDisabled || isEditingDisabled
                  }
                  onClick={createTaskHandle}
                >
                  Создать
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <PriorityGroup
          disabled={isEditingDisabled}
          rules={[
            {
              required: true,
              message: 'Заполните поле',
            },
          ]}
        />
        <Form.Item
          name="executorId"
          rules={[
            {
              required: !otherTaskBlockValid,
              message: 'Выберите исполнителя',
            },
          ]}
        >
          <AddExecutorButton
            setShow={() => setShowAssignExecutorModalAction(true)}
            cardBlockType={otherTask?.executor || otherTaskBlock?.executor}
            disabled={isEditingDisabled}
          />
        </Form.Item>
        <Form.Item
          name="systemId"
          rules={[
            {
              required: true,
              message: 'Заполните поле',
            },
          ]}
        >
          <Row gutter={5} style={{ marginTop: 20 }}>
            <Col span={12}>
              <PrimaryButton
                onClick={() => setBindType(BindingTypes.OBJECT)}
                className={styles.bindButton}
                disabled={isEditingDisabled}
              >
                + Привязать объект
              </PrimaryButton>
            </Col>
            <Col span={12}>
              <PrimaryButton
                onClick={() => setBindType(BindingTypes.SYSTEM)}
                className={styles.bindButton}
                disabled={isEditingDisabled}
              >
                + Привязать систему
              </PrimaryButton>
            </Col>
          </Row>
        </Form.Item>

        {bindType === BindingTypes.OBJECT && (
          <Row gutter={5}>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                name="regionId"
                required
                label="Область"
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  loading={regionIsLoading}
                  onChange={handleChangeRegion}
                  options={regions.map((item) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  placeholder="Область"
                  disabled={isDisabledRegion || isEditingDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                name="districtId"
                required
                label="Район"
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  disabled={
                    isDisabledDistrict || !regionId || isEditingDisabled
                  }
                  onChange={handleChangeDisctrict}
                  options={districtByParentId.map((item: any) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  placeholder="Район"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="hydroFacilityId"
                label="Гидроучасток"
                required
              >
                <TooltipedSelect
                  options={hydroFacilities}
                  onChange={handleChangeHydroFacility}
                  placeholder="Гидроучасток"
                  size="large"
                  disabled={!districtId || !regionId || isEditingDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.hiddenSelects}
                name="systemId"
                required
                label="Система"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TooltipedSelect
                  options={systems}
                  placeholder="Система"
                  disabled={
                    !districtId || isEditingDisabled || !hydroFacilityId
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.hiddenSelects}
                name="systemObjectId"
                required
                label="Объект"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TooltipedSelect
                  loading={objectsListIsLoading}
                  options={objectsList?.content?.map((item) => ({
                    ...item,
                    label: item.objectName,
                    value: item.objectId,
                  }))}
                  placeholder="Объект"
                  disabled={isEditingDisabled || !systemId}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {bindType === BindingTypes.SYSTEM && (
          <>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                name="regionId"
                required
                label="Область"
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  loading={regionIsLoading}
                  onChange={handleChangeRegion}
                  options={regions.map((item) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  placeholder="Область"
                  disabled={isDisabledRegion || isEditingDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                name="districtId"
                required
                label="Район"
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  disabled={
                    isDisabledDistrict || !regionId || isEditingDisabled
                  }
                  onChange={handleChangeDisctrict}
                  options={districtByParentId.map((item: any) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  placeholder="Район"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className={styles.selectGroup__item}
                rules={[{ required: true }]}
                name="hydroFacilityId"
                label="Гидроучасток"
                required
              >
                <TooltipedSelect
                  options={hydroFacilities}
                  onChange={handleChangeHydroFacility}
                  placeholder="Гидроучасток"
                  size="large"
                  disabled={!districtId || !regionId || isEditingDisabled}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                className={styles.hiddenSelects}
                name="systemId"
                label="Система"
                required
              >
                <TooltipedSelect
                  options={systems}
                  placeholder="Система"
                  disabled={
                    !districtId || isEditingDisabled || !hydroFacilityId
                  }
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <div className={styles.deadlinesForm}>
          <Typography.Text className={styles.deadlinesFormTitle}>
            Установить сроки
          </Typography.Text>
          <Row justify="start" gutter={5}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validatePlanStartDate({
                        _,
                        value,
                        form,
                        type: 'planEndDate',
                      }),
                  },
                ]}
                name="planStartDate"
                label="Начало"
                style={{ marginBottom: 10 }}
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Начало"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) =>
                      validatePlanEndDate({
                        _,
                        value,
                        form,
                        type: 'planStartDate',
                      }),
                  },
                ]}
                name="planEndDate"
                required
                label="Конец"
                style={{ marginBottom: 10 }}
              >
                <TooltipedDatePicker
                  disabled={isEditingDisabled}
                  placeholder="Конец"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item
          name="taskNotation"
          label="Комментарии / примечания"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea
            placeholder="Комментарии / примечания"
            autoSize={{ minRows: 4, maxRows: 15 }}
            disabled={isEditingDisabled}
          />
        </Form.Item>
      </Form>
    </CustomCard>
  );
};
