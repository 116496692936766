export const collapseMockData = [
  // {
  //   key: '1',
  //   label: 'Административно-территориальные данные:',
  //   children: [
  //     { label: 'Область', id: 1, code: '001' },
  //     { label: 'Районы', id: 2, code: '002' },
  //     { label: 'Города/Села', id: 28, code: '030' },
  //     { label: 'Айыл окмоту', id: 22, code: '024' },
  //   ],
  // },
  {
    key: '2',
    label: 'Юридические и организационные атрибуты:',
    children: [
      { label: 'Правовая форма', id: 3, code: '003' },
      // { label: 'Тип организации', id: 4, code: '004' },
      { label: 'Форма организации', id: 12, code: '014' },
      { label: 'Форма собственности', id: 9, code: '011' },
      { label: 'Вид деятельности', id: 16, code: '018' },
      { label: 'Вид деятельности организации', id: 15, code: '017' },
      { label: 'Гос. сотрудник', id: 27, code: '029' },
      { label: 'Водный совет', id: 17, code: '019', parent: '002' },
    ],
  },
  {
    key: '3',
    label: 'Сельскохозяйственные данные:',
    children: [
      { label: 'Сельхозкультуры', id: 6, code: '008' },
      { label: 'Фактический сев сельхозкультур', id: 74, code: '057' },
      { label: 'Урожайность сельхозкультур', id: 75, code: '058' },
      { label: 'Виды поливов', id: 52, code: '055' },
    ],
  },
  {
    key: '4',
    label: 'Водное хозяйство и ирригация:',
    children: [
      // { label: 'Типы ирригационных систем', id: 24, code: '026' },
      { label: 'Бассейны', id: 11, code: '013' },
      {
        label: 'Бассейновое управление водного хозяйства',
        id: 51,
        code: '053',
      },
      { label: 'Регулирование уровня воды', id: 43, code: '045' },
      { label: 'Качество воды', id: 50, code: '052' },
    ],
  },
  {
    key: '5',
    label: 'Типы и классификации объектов:',
    children: [
      { label: 'ГКЭД', id: 5, code: '005' },
      // { label: 'Категория объектов', id: 20, code: '022' },
      // { label: 'Группа объектов', id: 19, code: '021' },
      // { label: 'Типы объектов', id: 21, code: '023' },
      { label: 'Форма канала', id: 49, code: '051' },
    ],
  },
  {
    key: '6',
    label: 'Типы документов и процедуры:',
    children: [
      { label: 'Типы документов', id: 23, code: '025' },
      {
        label: 'Составлены реестры ЗП и протокола собраний ПЗ',
        id: 76,
        code: '059',
      },
    ],
  },
  {
    key: '7',
    label: 'Строительство и инфраструктура:',
    children: [
      { label: 'Тип работ', id: 38, code: '038' },
      { label: 'Категория работ', id: 37, code: '037', parent: '038' },
      { label: 'Вид работ', id: 36, code: '042' },
      { label: 'Направление строительных работ', id: 39, code: '039' },
      { label: 'Ступень развития АВП', id: 73, code: '056' },
      { label: 'Конструкции', id: 41, code: '043' },
      { label: 'Методы управления', id: 42, code: '044' },
      { label: 'Тип мероприятия', id: 40, code: '040' },
    ],
  },
  {
    key: '8',
    label: 'Географические и климатические атрибуты:',
    children: [
      // { label: 'агро климатическая зона', id: 13, code: '015' },
      { label: 'Почва', id: 44, code: '046' },
      { label: 'Тип грунта', id: 29, code: '031' },
    ],
  },
  {
    key: '9',
    label: 'Технические параметры и состояние объектов:',
    children: [
      // { label: 'Статус Эксплуатационного состояния', id: 32, code: '034' },
      // { label: 'Статус технического состояния', id: 33, code: '035' },
      { label: 'Тип измерения', id: 46, code: '048' },
      { label: 'Тип облицовки', id: 31, code: '033' },
      { label: 'Тип покрытий', id: 48, code: '050' },
      { label: 'Тип проводов', id: 45, code: '047' },
      { label: 'Количество полос', id: 47, code: '049' },
    ],
  },
  {
    key: '10',
    label: 'Образовательные и кадровые аспекты:',
    children: [
      { label: 'Образование', id: 18, code: '020' },
      { label: 'Список должностей', id: 14, code: '016' },
    ],
  },
  {
    key: '11',
    label: 'Другие:',
    children: [
      { label: 'Типы ассоциации', id: 25, code: '027' },
      { label: 'Типы хозяйств', id: 26, code: '028' },
      { label: 'Вид участка', id: 34, code: '036' },
      { label: 'Назначение', id: 35, code: '041' },
      { label: 'Режимы заполнения', id: 83, code: '060' },
      { label: 'Режимы сброса', id: 84, code: '061' },
      { label: 'Тип дерева', id: 85, code: '062' },
      { label: 'Водный баланс', id: 86, code: '063' },
    ],
  },
];
