import React from 'react';

import { Tooltip, TooltipProps } from 'antd';
import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';

import styles from './TooltipedTextareaV2.module.scss';

type TooltipedTextareaV2PropsType = TextAreaProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const TooltipedTextareaV2: React.FC<TooltipedTextareaV2PropsType> = ({
  tooltipProps,
  ...props
}) => {
  return (
    <Tooltip {...tooltipProps}>
      <TextArea
        autoSize={{ minRows: 7, maxRows: 20 }}
        className={styles.textarea}
        {...props}
      />
    </Tooltip>
  );
};
