import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { documentFormats } from '@constants/documentFormats';
import {
  CustomCard,
  TableCustom,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useConstructionBlockAttachmentsDeleteMutation,
  useExtraTaskBlockAttachmentsDeleteMutation,
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery, useGetOtherTaskByIdQuery,
  useOperationalBlockAttachmentsDeleteMutation,
} from '@store/gisproApi';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { isInteger } from '@utils/utils';

import { useDocumentTableColumns } from '../../useDocumentTableColumns';
import styles from './DocumentTable.module.scss';

type DocumentTablePropTypes = {
  attachments: IAttachment[] | null;
  type?: 'operational' | 'building' | 'regular' | 'otherTask';
};

export const DocumentTable: FC<DocumentTablePropTypes> = ({
  attachments,
  type,
}) => {
  const { id } = useParams();

  const { columns } = useDocumentTableColumns();
  const [attachmentsConstructionDelete] =
    useConstructionBlockAttachmentsDeleteMutation();
  const [attachmentsExtraTaskDelete] =
    useExtraTaskBlockAttachmentsDeleteMutation();
  const [attachmentsOperationalDelete] =
    useOperationalBlockAttachmentsDeleteMutation();
  const { refetch: refetchConstruction } =
    useFindConstructionBlockByIdQuery(
      type === 'building' && id && isInteger(parseInt(id, 10))
        ? { id: parseInt(id, 10) }
        : skipToken,
    );
  const { refetch: refetchOperational } =
    useFindOperationalBlockByIdQuery(
      type === 'operational' && id && isInteger(parseInt(id, 10))
        ? { id: parseInt(id, 10) }
        : skipToken,
    );
  const { refetch: refetchExtraTask } =
    useGetOtherTaskByIdQuery(
      type === 'otherTask' && id && isInteger(parseInt(id, 10))
        ? id
        : skipToken,
    );
  const deleteItem = (document: IAttachment) => {
    if (type === 'operational') {
      attachmentsOperationalDelete({
        attachments: [document],
        id,
      })
        .unwrap()
        .then(() => {
          refetchOperational();
        });
    }
    if (type === 'building') {
      attachmentsConstructionDelete({
        attachments: [document],
        id,
      })
        .unwrap()
        .then(() => {
          refetchConstruction();
        });
    }
    if (type === 'otherTask') {
      attachmentsExtraTaskDelete({
        attachments: [document],
        id,
      })
        .unwrap()
        .then(() => {
          refetchExtraTask();
        });
    }
  };

  const onHandleWatch = (file: any) => {
    downloadAttachment(file);
  };

  return (
    <CustomCard
      title={
        <Row align="middle" justify="end" gutter={8}>
          <Col span={12}>
            <Typography.Text className={styles.title}>
              Прикрепленные документы
            </Typography.Text>
          </Col>
          <Col span={6}>
            <TooltipedInput size="large" placeholder="Наименование" />
          </Col>
          <Col span={6}>
            <TooltipedSelect
              size="large"
              placeholder="Формат документа"
              options={documentFormats}
            />
          </Col>
        </Row>
      }
    >
      <Col span={24}>
        <TableCustom
          dataSource={attachments || []}
          columns={columns}
          size="small"
          scroll={{ x: 500 }}
          actionProps={{
            deleteItem,
            onHandleWatch,
          }}
          pagination={false}
        />
      </Col>
    </CustomCard>
  );
};
