import { Breadcrumb, Layout } from 'antd';
import styles from './ReservoirList.module.scss';
import { useTranslation } from 'react-i18next';
import { ReservoirListTable } from './reservoirListTable';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { useEffect } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Divider } from '@features/ui-kit';

export const ReservoirList = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  useEffect(() => {
    setHeaderTitle('Водохранилища');
  }, []);
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <HomeOutlined />,
            },
            {
              title: 'Водохранилища',
            },
          ]}
        />
        <Divider style={{ margin: '6px 0 8px' }} />
        <ReservoirListTable />
      </div>
    </Layout>
  );
};
