import { Col, Row } from 'antd';
import { TooltipedDatePicker, TooltipedSelect } from '@features/ui-kit';
import { useGeoPortalMapFilters } from '@features/geoPortal/geoPortalMaps/useGeoPoralFilters';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdQuery,
} from '@store/gisproApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useGetCurrentUser } from '@features/authentication';

export const GeoPortalNavigationContentFilters: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const { setGeoPortalFilters, filters } = useGeoPortalMapFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const regionParam = params.get('region');
  const districtParam = params.get('district');

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    regionParam || skipToken,
  );

  const onRegionChange = (item: any) => {
    if (item) {
      params.set('region', item);
    } else {
      params.delete('region');
    }
    params.delete('district');
    setParams(params);
    setGeoPortalFilters({
      ...filters,
      region: item,
      district: undefined,
      page: 1,
    });
  };

  const onDistrictChange = (item: any) => {
    if (item) {
      params.set('district', item);
    } else {
      params.delete('district');
    }
    setParams(params);
    setGeoPortalFilters({
      ...filters,
      district: item,
      page: 1,
    });
  };

  useEffect(() => {
    setGeoPortalFilters({
      ...filtersParams,
      region: parseInt(user?.owner?.region?.id.toString() ?? ''),
      district: parseInt(user?.owner?.district?.id.toString() ?? ''),
    });

    setParams({
      ...filtersParams,
      region: user?.owner?.region?.id.toString() ?? '',
      district: user?.owner?.district?.id.toString() ?? '',
    });
    // if (!regionParam && filters.region) {
    //   params.set('region', filters.region.toString());
    //   setParams(params);
    // } else if (regionParam) {
    //   setGeoPortalFilters({
    //     ...filters,
    //     region: parseInt(user?.owner?.region?.id.toString() ?? ''),
    //   });
    // }
    // if (!districtParam && filters.district) {
    //   params.set('district', filters.district.toString());
    //   setParams(params);
    // } else if (districtParam) {
    //   setGeoPortalFilters({
    //     ...filters,
    //     district: parseInt(user?.owner?.district?.id.toString() ?? ''),
    //   });
    // }
  }, []);

  return (
    <Row gutter={16}>
      <Col>
        <TooltipedSelect
          popupMatchSelectWidth={false}
          options={regions}
          placeholder="Выберите область"
          onChange={onRegionChange}
          allowClear
          value={regionParam ? parseInt(regionParam) : undefined}
          disabled={isDisabledRegion}
        />
      </Col>
      <Col>
        <TooltipedSelect
          popupMatchSelectWidth={false}
          options={districtByParentId}
          placeholder="Выберите район"
          onChange={onDistrictChange}
          allowClear
          value={districtParam ? parseInt(districtParam) : undefined}
          disabled={isDisabledDistrict}
        />
      </Col>
    </Row>
  );
};
