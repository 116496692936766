import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const WhatsAppIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3111_94622)">
        <path
          d="M0.426036 9.8809C0.425571 11.5613 0.864641 13.2021 1.69952 14.6483L0.346191 19.5895L5.40294 18.2636C6.80156 19.025 8.36863 19.424 9.96107 19.4241H9.96526C15.2222 19.4241 19.5015 15.1463 19.5038 9.88842C19.5048 7.34059 18.5135 4.94477 16.7124 3.14229C14.9116 1.33997 12.5167 0.346866 9.96487 0.345703C4.70728 0.345703 0.428284 4.62322 0.426114 9.8809"
          fill="url(#paint0_linear_3111_94622)"
        />
        <path
          d="M0.0853871 9.87721C0.0848445 11.6181 0.539651 13.3175 1.4043 14.8155L0.00244141 19.9339L5.2405 18.5605C6.68376 19.3474 8.30872 19.7622 9.96221 19.7629H9.96647C15.4121 19.7629 19.8451 15.3312 19.8474 9.88512C19.8483 7.24574 18.8214 4.7638 16.9559 2.89674C15.0903 1.02992 12.6097 0.00108527 9.96647 0C4.51996 0 0.0875577 4.43101 0.0853871 9.87721ZM3.20484 14.5575L3.00926 14.2471C2.18709 12.9398 1.75314 11.4291 1.75376 9.87783C1.75546 5.35109 5.4395 1.66822 9.96957 1.66822C12.1634 1.66915 14.2251 2.52434 15.7758 4.07597C17.3264 5.62775 18.1797 7.69054 18.1791 9.8845C18.1771 14.4112 14.493 18.0946 9.96647 18.0946H9.96322C8.48934 18.0938 7.04384 17.698 5.78322 16.95L5.48322 16.7721L2.37484 17.5871L3.20484 14.5575Z"
          fill="url(#paint1_linear_3111_94622)"
        />
        <path
          d="M7.49696 5.74874C7.312 5.33765 7.11735 5.32936 6.94146 5.32215C6.79743 5.31595 6.63278 5.31641 6.46828 5.31641C6.30363 5.31641 6.03611 5.37835 5.80999 5.62525C5.58363 5.87238 4.9458 6.46959 4.9458 7.68424C4.9458 8.89897 5.83053 10.0728 5.95386 10.2377C6.07735 10.4023 7.66185 12.9747 10.1713 13.9643C12.2569 14.7867 12.6813 14.6232 13.1339 14.5819C13.5867 14.5408 14.5947 13.9849 14.8004 13.4084C15.0062 12.8319 15.0062 12.3378 14.9445 12.2346C14.8828 12.1317 14.7181 12.0699 14.4712 11.9465C14.2243 11.823 13.0105 11.2257 12.7842 11.1433C12.5578 11.061 12.3932 11.0199 12.2286 11.2671C12.0639 11.5139 11.5911 12.0699 11.447 12.2346C11.3031 12.3996 11.159 12.4201 10.9122 12.2966C10.6651 12.1728 9.86999 11.9124 8.92665 11.0714C8.1927 10.417 7.6972 9.60882 7.55317 9.36161C7.40913 9.11479 7.53774 8.98099 7.66154 8.85796C7.77247 8.74734 7.90851 8.56967 8.03208 8.42556C8.15518 8.28138 8.19627 8.17851 8.27859 8.01386C8.36099 7.84905 8.31975 7.70486 8.25813 7.58138C8.19627 7.45789 7.7165 6.23688 7.49696 5.74874Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3111_94622"
          x1="958.226"
          y1="1924.73"
          x2="958.226"
          y2="0.345703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FAF38" />
          <stop offset="1" stopColor="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3111_94622"
          x1="992.251"
          y1="1993.39"
          x2="992.251"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_3111_94622">
          <rect width="19.85" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
