import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, notification, Row, Typography } from 'antd';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import { DocumentColumns } from '@features/layout/irrigationSystemCard/mocks';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  StatusWrapper,
  TableCustom,
  TooltipedSelect,
} from '@features/ui-kit';
import { CopyIcon } from '@icons/CopyIcon';
import { PasteIcon } from '@icons/PasteIcon';
import { PrintIcon } from '@icons/PrintIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { IHydrometricWorkPlan } from '@models/waterUsePlan/interfaces/waterUsePlan';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetHydrometricWorkPlanByCaQuery,
  useGetHydrometricWorkPlanByGuvhQuery,
  useGetHydrometricWorkPlanQuery,
  useUpdateHydrometricWorkPlanMutation,
  useUpdateStatusWaterUsePlanMutation,
} from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setUpdateStatus } from '@store/slices';
import { get } from '@utils/utils';

import { useHydroWorkPlanColumns } from './useHydroWorkPlanColumns';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const HydroWorkPlan: React.FC = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const hasEditPermission = useHasUserPermission(ACCESS.ANNUAL_CARD_EDIT);
  const { data: hydroPlan, isLoading: hydroPlanLoading } =
    useGetHydrometricWorkPlanQuery(
      userRole === UserRoles.ROLE_RUVH ? undefined : skipToken,
    );
  const { data: hydroPlanByGuvh, isLoading: hydroPlanByGuvhLoading } =
    useGetHydrometricWorkPlanByGuvhQuery(
      userRole === UserRoles.ROLE_GUVH ? {} : skipToken,
    );
  const { data: hydroPlanByCa, isLoading: hydroPlanByCaLoading } =
    useGetHydrometricWorkPlanByCaQuery(
      userRole === UserRoles.ROLE_CA ? {} : skipToken,
      {},
    );
  const [disabelBtns, setDisableBtns] = useState<any>({
    calculate: false,
    save: false,
  });
  const dispatch = useDispatch();
  const { columns } = useHydroWorkPlanColumns(userRole, hasEditPermission);
  const [hydroData, setHydroData] = useState<IHydrometricWorkPlan[]>([]);
  const [updateStatus] = useUpdateStatusWaterUsePlanMutation();
  const [updateHydroWork] = useUpdateHydrometricWorkPlanMutation();
  const ruvhTables = useSelector(getRuvhTablesInfoSelector);
  const tableStatus = ruvhTables.find(
    (table) => table.type === PlanType.HydrometricWorksPlan,
  )?.status;
  const getCellKey = (
    record: JsonRecord,
    column: TableColumn,
    index: number | undefined,
  ) => {
    if (column.editableDecimalCell) {
      return column.dataIndex;
    }
    return -1;
  };

  const onEditDecimalSaveClick = (
    record: JsonRecord,
    column: TableColumn,
    value: any,
  ) => {
    const currentValue = get(record, column.dataIndex);
    updateHydroWork({
      [column.dataIndex]: value,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        setDisableBtns({ save: true, calculate: true });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  const onFinish = () => {
    updateStatus({
      type: PlanType.HydrometricWorksPlan,
    }).then((resp) => {
      setDisableBtns({ save: true, calculate: true });
      if ('data' in resp) {
        const status = resp.data.status
          ? PlanStatusEnum.Approved
          : PlanStatusEnum.Editable;
        dispatch(
          setUpdateStatus({
            status,
            type: PlanType.HydrometricWorksPlan,
          }),
        );
      }
    });
  };
  const hydroPlanData = hydroPlan ? [hydroPlan] : [];
  const dataSource: any = useMemo(() => {
    if (hydroPlanByCa?.byDistrictDtos) {
      return hydroPlanByCa.byDistrictDtos;
    }
    if (hydroPlanByGuvh?.byDistrictDtos) {
      return hydroPlanByGuvh.byDistrictDtos;
    }
    if (hydroPlan) {
      return [hydroPlan];
    }
    return undefined;
  }, [
    hydroPlanByCa?.byDistrictDtos,
    hydroPlanByGuvh?.byDistrictDtos,
    hydroPlan,
  ]);

  return (
    <Col span={24}>
      <InformationalBlock>
        <CustomCard
          title={
            <Row justify="space-between">
              <Col span={12}>
                {userRole === UserRoles.ROLE_RUVH ? (
                  <StatusWrapper status={tableStatus}>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      План гидрометрических работ
                    </Typography.Title>
                  </StatusWrapper>
                ) : (
                  <Typography.Title style={{ marginBottom: 0 }} level={4}>
                    План гидрометрических работ
                  </Typography.Title>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={10} justify="end">
                  {userRole === UserRoles.ROLE_RUVH && (
                    <Row gutter={10}>
                      <Col>
                        <TooltipedSelect placeholder="Год" />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<CopyIcon />} />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<PasteIcon />} />
                      </Col>
                      {hasEditPermission ? (
                        <Col>
                          <PrimaryButton
                            disabled={!disabelBtns.save}
                            onClick={onFinish}
                            htmlType="submit"
                          >
                            Сохранить
                          </PrimaryButton>
                        </Col>
                      ) : null}
                    </Row>
                  )}
                  <Col>
                    <PrimaryButton icon={<PrintIcon />} />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <TableCustom
            bordered
            loading={
              hydroPlanLoading || hydroPlanByGuvhLoading || hydroPlanByCaLoading
            }
            dataSource={dataSource}
            scroll={{ y: 'calc(100vh - 461px)' }}
            size="small"
            cellKey={getCellKey}
            columns={columns}
            actionProps={{
              onEditDecimalSaveClick,
            }}
          />
        </CustomCard>
      </InformationalBlock>
    </Col>
  );
};
