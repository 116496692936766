import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const IrrigationFundOperationalBlockMonitoring: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="IrrigationFundOperationalBlockMonitoring"
        src={getKibanaUrl('Ирригационный фонд: Мониторинг Эксплуатационный блок')}
        height="2860"
        width="100%"
      />
    </div>
  );
};
