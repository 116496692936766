/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';

import { Button, ButtonProps, Col, Form, Row, Space } from 'antd';

import Icon from '@ant-design/icons';
import { Links } from '@enums/links';
import { TelegramAppIcon } from '@icons/TelegramAppIcon';
import { WhatsAppIcon } from '@icons/WhatsAppIcon';

import styles from './buttons.module.scss';

type WhatsAppButtonPropTypes = ButtonProps & {
  btnName: string | string[];
  initialValue?: boolean;
};

export const WhatsAppInputButton: React.FC<WhatsAppButtonPropTypes> = ({
  btnName,
  initialValue = false,
  ...props
}) => {
  const form = Form.useFormInstance();
  const selected = initialValue || form?.getFieldValue(btnName);
  useEffect(() => {
    if (form) {
      form.setFieldValue(btnName, selected || false);
    }
  }, []);

  return (
    <Button
      {...props}
      className={`${styles.appInputButton} ${
        selected ? styles.whatsAppInputButtonSelected : ''
      }`}
      onClick={() => {
        form.setFieldValue(btnName, !selected);
      }}
    >
      <Row gutter={8}>
        <WhatsAppIcon />
        <Col span={20}>
          {!selected
            ? 'Нажмите чтобы добавить WhatsApp'
            : 'Да, у меня есть WhatsApp!'}
        </Col>
      </Row>
    </Button>
  );
};
