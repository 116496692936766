interface DataType {
  title: string;
  dataIndex: string;
  key: string;
  align: string;
  width: number;
  editable: boolean;
}
export const useMineralizationDataTableColumns = () => {
  const columns = [
    {
      title: 'Вид точки',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 135,
    },
    {
      title: 'Наименование точки',
      dataIndex: 'pointName',
      key: 'pointName',
      align: 'center',
      width: 135,
    },
    {
      title: 'Г/л',
      dataIndex: 'gl',
      key: 'gl',
      align: 'center',
      width: 135,
      editable: true,
    },
    {
      title: 'Р/н',
      dataIndex: 'ph',
      key: 'ph',
      align: 'center',
      width: 135,
      editable: true,
    },
    {
      title: 'Электро проводность, ms',
      dataIndex: 'ms',
      key: 'ms',
      align: 'center',
      width: 135,
      editable: true,
    },
  ];

  return { columns };
};
