import { renderBudget } from '@features/ui-kit-v2/customTableV2/cell/renderer/renderBudget';
import { renderDeadline } from '@features/ui-kit-v2/customTableV2/cell/renderer/renderDeadline';
import { renderStatus } from '@features/ui-kit-v2/customTableV2/cell/renderer/renderStatus';
import { renderBoolean } from '@features/ui-kit/customTable/cell/renderer/renderBoolean';
import { renderDecimalEdit } from '@features/ui-kit/customTable/cell/renderer/renderDecimalEdit';
import { renderMethods } from '@features/ui-kit/customTable/cell/renderer/renderMethods';
import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { renderCommonReference } from '@features/ui-kit/customTable/cell/renderer/renderCommonReference';
import { renderObjectCategory } from '@features/ui-kit/customTable/cell/renderer/renderObjectCategory';
import { renderObjectGroup } from '@features/ui-kit/customTable/cell/renderer/renderObjectGroup';
import { renderTechnicalStatus } from '@features/ui-kit/customTable/cell/renderer/renderTechnicalStatus';
import { renderWaterRegistryControl } from '@features/ui-kit/customTable/cell/renderer/renderWaterRegistryControl';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderAction } from './renderAction';
import { renderCheck } from './renderCheck';
import { renderControl } from './renderControls';
import { renderDate } from './renderDate';
import { renderDecadeCell } from './renderDecadeCell';
import { renderGeo } from './renderGeo';
import { renderGeoPortalActions } from './renderGeoPortalActions';
import { renderIrrigationControl } from './renderIrrigationControl';
import { renderNumber } from './renderNumber';
import { renderSelect } from './renderSelect';
import { renderSelectTag } from './renderSelectTag';
import { renderString } from './renderString';
import { renderTag } from './renderTag';
import { renderWaterAccountingActionButtons } from './renderWaterAccountingActionButtons';
import { renderWaterAccountingValues } from './renderWaterAccountingValues';
import { renderWateringPlanModalControl } from './renderWateringPlanModalControl';
import { renderOperationalStatus } from '@features/ui-kit/customTable/cell/renderer/renderOperationalStatus';

export class RendererFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { xtype } = props.customColumn;

    let renderer = renderString;

    switch (xtype) {
      case CustomTableColumnType.ActionColumn:
        renderer = renderAction;
        break;
      case CustomTableColumnType.MethodsColumn:
        renderer = renderMethods;
        break;
      case CustomTableColumnType.String:
        renderer = renderString;
        break;
      case CustomTableColumnType.BooleanColumn:
        renderer = renderBoolean;
        break;
      case CustomTableColumnType.NumberColumn:
        renderer = renderNumber;
        break;
      case CustomTableColumnType.DateColumn:
        renderer = renderDate;
        break;
      case CustomTableColumnType.CheckColumn:
        renderer = renderCheck;
        break;
      case CustomTableColumnType.SelectColumn:
        renderer = renderSelect;
        break;
      case CustomTableColumnType.SelectColumntag:
        renderer = renderSelectTag;
        break;
      case CustomTableColumnType.TagColumn:
        renderer = renderTag;
        break;
      case CustomTableColumnType.GeoPortalActions:
        renderer = renderGeoPortalActions;
        break;
      case CustomTableColumnType.WaterRegistryControl:
        renderer = renderWaterRegistryControl;
        break;
      case CustomTableColumnType.DecadeCell:
        renderer = renderDecadeCell;
        break;
      case CustomTableColumnType.DecimalEdit:
        renderer = renderDecimalEdit;
        break;
      case CustomTableColumnType.Geo:
        renderer = renderGeo;
        break;
      case CustomTableColumnType.Control:
        renderer = renderControl;
        break;
      case CustomTableColumnType.IrrigationControl:
        renderer = renderIrrigationControl;
        break;
      case CustomTableColumnType.WaterAccountingActionButtons:
        renderer = renderWaterAccountingActionButtons;
        break;
      case CustomTableColumnType.WaterAccountingValues:
        renderer = renderWaterAccountingValues;
        break;
      case CustomTableColumnType.WateringPlanModalControl:
        renderer = renderWateringPlanModalControl;
        break;
      case CustomTableColumnType.CommonReference:
        renderer = renderCommonReference;
        break;
      case CustomTableColumnType.ObjectCategory:
        renderer = renderObjectCategory;
        break;
      case CustomTableColumnType.ObjectGroup:
        renderer = renderObjectGroup;
        break;
      case CustomTableColumnType.TechnicalStatus:
        renderer = renderTechnicalStatus;
        break;
      case CustomTableColumnType.Status:
        renderer = renderStatus;
        break;
      case CustomTableColumnType.Budget:
        renderer = renderBudget;
        break;
      case CustomTableColumnType.Deadline:
        renderer = renderDeadline;
        break;
      case CustomTableColumnType.OperationalStatus:
        renderer = renderOperationalStatus;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
