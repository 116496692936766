import { Col, Row } from 'antd';
import { IAttachment } from '@models/attachments/interfaces/attachment';

import {
  useSystemObjectControllerDeleteSystemObjectDocumentMutation,
  useSystemObjectControllerFindDocumentsSystemObjectIdQuery,
  useSystemObjectControllerUpdateSystemObjectDocumentMutation,
} from '@store/api/systemObjectApi';
import { useParams } from 'react-router-dom';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { DocumentTable, UploadDocumentBlock } from '@components/index';
import { InformationalBlock } from '@features/ui-kit';
import { PhotoRecordingComponent } from '../photoRecordingComponent';

export const DocumentsComponent: React.FC = () => {
  const { id } = useParams<{ id: any }>();

  const { data: attachments = [] } =
    useSystemObjectControllerFindDocumentsSystemObjectIdQuery({
      id,
    });
  const [update] =
    useSystemObjectControllerUpdateSystemObjectDocumentMutation();
  const [remove] =
    useSystemObjectControllerDeleteSystemObjectDocumentMutation();

  const onLoad = (attachment: IAttachment) => {
    update({
      id,
      documentId: attachment.attachmentId,
    });
  };
  const deleteItem = (attachment: IAttachment) => {
    remove({
      id,
      documentId: attachment.attachmentId,
    });
  };

  const onWatch = (attachment: IAttachment) => {
    downloadAttachment(attachment);
  };

  return (
    <>
      <InformationalBlock title="Докуметы">
        <Row gutter={8}>
          <Col span={14}>
            <DocumentTable
              deleteItem={deleteItem}
              onHandleWatch={onWatch}
              documents={attachments}
            />
          </Col>
          <Col span={10}>
            <UploadDocumentBlock onLoad={onLoad} />
          </Col>
        </Row>
      </InformationalBlock>
      <InformationalBlock title="Фотофиксация">
        <PhotoRecordingComponent />
      </InformationalBlock>
    </>
  );
};
