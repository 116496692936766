import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import React, { SyntheticEvent } from 'react';

import { Button, Col, notification, Row } from 'antd';

import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { CustomTableAction } from '@enums/table';
import { ArchiveIcon } from '@icons/ArchiveIcon';
import { BlueEditIcon } from '@icons/BlueEditIcon';
import { DeleteIcon } from '@icons/DeleteIcon';
import { EditIcon } from '@icons/EditIcon';
import { GreyConfirmIcon } from '@icons/GreyConfirmIcon';
import { WatchIcon } from '@icons/WatchIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { DangerButton, PrimaryButton } from '../../../buttons';
import styles from './styles.module.scss';
import { PrintIcon } from '@icons/PrintIcon';

export function renderAction<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps: {
    onHandleGis,
    onHandleCard,
    onHandleAnnualApplication,
    onHandleConstructor,
    onHandleMainConduit,
    onArchiveClick,
    onHandleEdit,
    onHandleWatch,
    onHandleSubmit,
    onWatch,
    deleteItem,
    linkOutlet,
    irrigationMode,
    onWaterUsersClick,
    onBalanceSheetClick,
    onWatchCheck,
    getIsDisabled,
    getIsDisabledOnText,
    onEditCheck,
    onText,
    onTextCheck,
    onHandleAccess,
    onUnArchiveClick,
  },
}: TableRendererProps<RecordType>) {
  const { actions = [], dataIndex, actionsAccess } = customColumn;

  const onActionClick = () => {
    notification.warning({ message: 'Функция находится в разработке' });
  };

  return (
    <Row
      className="actions-wrapper"
      justify="center"
      align="middle"
      wrap={false}
      gutter={8}
    >
      {actions.map((action, index) => {
        if (actionsAccess) {
          if (!useHasUserPermission(actionsAccess[index])) {
            return null;
          }
        }
        if (action === CustomTableAction.IrrigationMode) {
          return (
            <Col key={action}>
              <PrimaryButton
                onClick={() => irrigationMode && irrigationMode(record)}
              >
                Режимы орошения
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.LinkOutlet) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                onClick={() => linkOutlet && linkOutlet(record)}
              >
                Сязанность В/в
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.Edit) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <Button
                key={action}
                onClick={() => onHandleEdit && onHandleEdit(record)}
                type="link"
                icon={<EditIcon />}
                // disabled
                className={`${styles.base} ${styles.editCustom}`}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.EditCheck) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <Button
                key={action}
                onClick={() => onEditCheck?.(record)}
                type="link"
                icon={<BlueEditIcon />}
                // disabled
                className={`${styles.base} ${styles.editCustom}`}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.EditWithoutText) {
          return (
            <Col key={action}>
              <PrimaryButton
                size="small"
                key={action}
                style={{ borderRadius: 7 }}
                onClick={() => onHandleEdit && onHandleEdit(record)}
                icon={<EditIcon />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.DeleteWithoutText) {
          return (
            <Col key={action}>
              <DangerButton
                size="small"
                key={action}
                style={{ borderRadius: 7 }}
                onClick={() => deleteItem && deleteItem(record)}
                icon={<DeleteIcon />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.ArchiveWithoutText) {
          return (
            <Col key={action}>
              <PrimaryButton
                size="small"
                style={{ borderRadius: 7, backgroundColor: '#FA8C16' }}
                key={action}
                icon={<ArchiveIcon />}
                onClick={() => onArchiveClick && onArchiveClick(record)}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.UnArchiveWithoutText) {
          return (
            <Col key={action}>
              <PrimaryButton
                size="small"
                style={{ borderRadius: 7, backgroundColor: '#FA8C16' }}
                key={action}
                icon={<ArchiveIcon />}
                onClick={() => onUnArchiveClick && onUnArchiveClick(record)}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.WatchWithoutText) {
          return (
            <Col key={action}>
              <PrimaryButton
                size="small"
                key={action}
                style={{ borderRadius: 7 }}
                onClick={() => onHandleWatch && onHandleWatch(record)}
                icon={<WatchIcon />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.SubmitWithoutText) {
          return (
            <Col key={action}>
              <PrimaryButton
                size="small"
                key={action}
                disabled={getIsDisabled?.(record)}
                style={{ borderRadius: 7 }}
                onClick={() => onHandleSubmit && onHandleSubmit(record)}
                icon={<CheckOutlined />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.Card) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                onClick={() => onHandleCard && onHandleCard(record)}
              >
                Карточка
              </PrimaryButton>
            </Col>
          );
        }
        if (action === CustomTableAction.GrantingAccess) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                onClick={() => onHandleAccess && onHandleAccess(record)}
              >
                Выдача доступа
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.AnnualApplication) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                style={{ background: '#1677FF' }}
                onClick={() =>
                  onHandleAnnualApplication && onHandleAnnualApplication(record)
                }
              >
                Годовая заявка
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.Gis && !record.isArchived) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                style={{ background: '#52C41A' }}
                onClick={() => onHandleGis && onHandleGis(record)}
              >
                ГИС
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.MainConduit && !record.isArchived) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                style={{ background: '#B250FF' }}
                onClick={() =>
                  onHandleMainConduit && onHandleMainConduit(record)
                }
              >
                Создание водовода
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.Constructor && !record.isArchived) {
          return (
            <Col key={action}>
              <PrimaryButton
                key={action}
                style={{ background: '#13C2C2' }}
                onClick={() =>
                  onHandleConstructor && onHandleConstructor(record)
                }
              >
                Конструктор
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.Watch) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <PrimaryButton
                key={action}
                onClick={() => onWatch?.(record)}
                icon={<EyeOutlined />}
                className={`${styles.base} ${styles.watchCustom}`}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.WatchCheck) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <PrimaryButton
                key={action}
                onClick={() => onWatchCheck?.(record)}
                className={`${styles.base} ${styles.watchCustom}`}
                disabled={getIsDisabled?.(record)}
                icon={<EyeOutlined />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.Text) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <PrimaryButton
                key={action}
                disabled={getIsDisabledOnText?.(record)}
                onClick={() => onText?.(record)}
                className={`${styles.base} ${styles.textCustom}`}
              >
                ОК
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.TextCheck) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <PrimaryButton
                key={action}
                disabled={
                  getIsDisabled?.(record) || getIsDisabledOnText?.(record)
                }
                onClick={() => onTextCheck?.(record)}
                className={`${styles.base} ${styles.textCustom}`}
              >
                ОК
              </PrimaryButton>
            </Col>
          );
        }

        if (action === CustomTableAction.Confirm) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <Button
                key={action}
                className={`${styles.base} ${styles.actionBtn}`}
                type="link"
                icon={<GreyConfirmIcon />}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.Print) {
          return (
            <Col key={action} className={styles.baseWrapper}>
              <PrimaryButton
                key={action}
                type="link"
                onClick={onActionClick}
                icon={<PrintIcon />}
                className={`${styles.base} ${styles.printCustom}`}
              />
            </Col>
          );
        }

        if (action === CustomTableAction.WaterUsers) {
          return (
            <Col span={24} key={action}>
              <Row gutter={[0, 4]}>
                <Col span={24}>{record[dataIndex] || '-'}</Col>
                <Col span={24}>
                  <PrimaryButton
                    style={{ borderRadius: 7, height: 30 }}
                    onClick={() => onWaterUsersClick?.(record)}
                  >
                    Водопользователи
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          );
        }

        if (action === CustomTableAction.BalanceSheet) {
          return (
            <Col span={24} key={action}>
              <Row gutter={[0, 4]}>
                <Col span={24}>{record[dataIndex] || '-'}</Col>
                <Col span={24}>
                  <PrimaryButton onClick={() => onBalanceSheetClick?.(record)}>
                    Балансовая ведомость
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          );
        }
        return null;
      })}
    </Row>
  );
}
