import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IAgroClimaticZoneFilters } from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { getAgroClimaticZoneFiltersSelector } from '@store/selectors';
import { agroClimaticZoneActions } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IAgroClimaticZoneFilters>;
  setAgroClimaticZoneTableFilters: (filters: IAgroClimaticZoneFilters) => void;
};

export const useAgroClimaticZoneTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getAgroClimaticZoneFiltersSelector);

  const setAgroClimaticZoneTableFilters = useCallback(
    (newFilters: IAgroClimaticZoneFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      const values: IAgroClimaticZoneFilters = pickBy(
        newFilters,
        (v) => !isEmpty(v) || isInteger(v),
      );

      setParams(params);
      dispatch(
        agroClimaticZoneActions.changeAgroClimaticZoneFilters(
          values as IAgroClimaticZoneFilters,
        ),
      );
    },
    [],
  );

  return { filters, setAgroClimaticZoneTableFilters };
};
