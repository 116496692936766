import { createSelector } from '@reduxjs/toolkit';
import { HeaderState } from '@store/slices/header';

export const headerSateSelector = (state: {
  headerSlice: HeaderState;
}): HeaderState => state.headerSlice;

export const getHeaderTitleSelector = createSelector(
  headerSateSelector,
  (headerState) => {
    return headerState.headerTitle;
  },
);

export const getReturnUrl = createSelector(
  headerSateSelector,
  (headerState) => {
    return headerState.returnUrl.at(-1);
  },
);
