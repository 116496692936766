import { useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { CustomModal, DangerButton, PrimaryButton } from '@features/ui-kit';
import { Row, Col, Typography, Divider, Form } from 'antd';
import {
  useUpdateBankDetailsMutation,
  useUpdateContactsMutation,
  useUpdateLegalEntityMainInfoMutation,
} from '@store/api/waterUsersApi';
import { IModalState } from '@utils/modalHook/modalHook';
import { BankDetails, ContactsInfo, MainInfo } from './forms';
import { TotalInfoModalTypes } from './totalInfoModal.models';

type TotalInfoModalProps = {
  modalState: IModalState<TotalInfoModalTypes>;
  dataSource: any;
};

const TITLES: Record<TotalInfoModalTypes, string> = {
  main: 'Основные данные',
  bank: 'Банковские реквизиты',
  contacts: 'Контактные данные',
  passport: 'Паспортные данные',
};

export const TotalInfoModal = ({
  modalState,
  dataSource,
}: TotalInfoModalProps) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { isOpen, data, close } = modalState;
  const [updateContacts] = useUpdateContactsMutation();
  const [updateBankInfo] = useUpdateBankDetailsMutation();
  const [updateMainInfo] = useUpdateLegalEntityMainInfoMutation();

  const isMain = data === 'main';
  const isBank = data === 'bank';
  const isContacts = data === 'contacts';

  const onCancel = () => {
    close();
  };

  const onSubmit = () => {
    if (!id) {
      return;
    }
    const data = { id, body: form.getFieldsValue() };

    if (isMain) {
      updateMainInfo(data);
    }
    if (isBank) {
      updateBankInfo(data);
    }
    if (isContacts) {
      updateContacts(data);
    }
    close();
  };

  const Footer = (
    <Row gutter={10} justify="center">
      <Col>
        <PrimaryButton type="primary" size="large" onClick={onCancel}>
          Отмена
        </PrimaryButton>
      </Col>
      <Col>
        <PrimaryButton type="primary" size="large" onClick={onSubmit}>
          Изменить
        </PrimaryButton>
      </Col>
    </Row>
  );
  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  return (
    <CustomModal
      centered
      open={isOpen}
      onCancel={onCancel}
      width={830}
      title={TITLES[data]}
      footer={Footer}
      closeIcon={CloseIcon}
    >
      {isMain && <MainInfo data={dataSource} form={form} onCancel={onCancel} />}
      {isBank && (
        <BankDetails data={dataSource} form={form} onCancel={onCancel} />
      )}
      {isContacts && (
        <ContactsInfo data={dataSource} form={form} onCancel={onCancel} />
      )}
    </CustomModal>
  );
};
