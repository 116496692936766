import { Tags } from '@constants/tags';
import { api } from '../basicApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    materialsControllerFindAll: build.query<
      MaterialsControllerFindAllApiResponse,
      MaterialsControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/materials/list` }),
    }),
    materialsControllerGetMaterial: build.query<
      MaterialsControllerGetMaterialApiResponse,
      MaterialsControllerGetMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/material/${queryArg.code}`,
      }),
      keepUnusedDataFor: 0,
    }),
    materialsControllerGetMaterialsByCategory: build.query<
      MaterialsControllerGetMaterialsByCategoryApiResponse,
      MaterialsControllerGetMaterialsByCategoryApiArg
    >({
      query: ({ category, params }) => ({
        url: `/api/v1/materials/list/${category}`,
        params,
      }),
      providesTags: [Tags.Materials],
    }),
    materialsControllerCreate: build.mutation<
      MaterialsControllerCreateApiResponse,
      MaterialsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/create`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.Materials],
    }),
    materialsControllerUpdate: build.mutation<
      MaterialsControllerUpdateApiResponse,
      MaterialsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/update/${queryArg.code}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.Materials],
    }),
    materialsControllerDelete: build.mutation<
      MaterialsControllerDeleteApiResponse,
      MaterialsControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/delete/${queryArg.code}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.Materials],
    }),
    materialsCategoryControllerFindAll: build.query<
      MaterialsCategoryControllerFindAllApiResponse,
      MaterialsCategoryControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/materials/category/list` }),
      providesTags: [Tags.MaterialsCategory],
    }),
    materialsCategoryControllerCreate: build.mutation<
      MaterialsCategoryControllerCreateApiResponse,
      MaterialsCategoryControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/category/create`,
        method: 'POST',
        body: queryArg.materialsCategoryCreateDto,
      }),
      invalidatesTags: [Tags.MaterialsCategory],
    }),
    materialsCategoryControllerUpdate: build.mutation<
      MaterialsCategoryControllerUpdateApiResponse,
      MaterialsCategoryControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/category/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.materialsCategoryCreateDto,
      }),
      invalidatesTags: [Tags.MaterialsCategory],
    }),
    materialsCategoryControllerDelete: build.mutation<
      MaterialsCategoryControllerDeleteApiResponse,
      MaterialsCategoryControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/materials/category/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.MaterialsCategory],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type MaterialsControllerFindAllApiResponse =
  /** status 200  */ PageableResultMaterialsDto;
export type MaterialsControllerFindAllApiArg = void;
export type MaterialsControllerGetMaterialApiResponse =
  /** status 200  */ MaterialsDto[];
export type MaterialsControllerGetMaterialApiArg = {
  code: string;
};
export type MaterialsControllerGetMaterialsByCategoryApiResponse =
  /** status 200  */ PageableResultMaterialsDto;
export type MaterialsControllerGetMaterialsByCategoryApiArg = {
  category: number;
  params: any;
};
export type MaterialsControllerCreateApiResponse =
  /** status 200  */ MaterialsDto[];
export type MaterialsControllerCreateApiArg = {
  body: MaterialsCreateDto[];
};
export type MaterialsControllerUpdateApiResponse =
  /** status 200  */ UpdateResult;
export type MaterialsControllerUpdateApiArg = {
  code: string;
  body: MaterialsUpdateDto[];
};
export type MaterialsControllerDeleteApiResponse =
  /** status 200  */ DeleteResult;
export type MaterialsControllerDeleteApiArg = {
  code: string;
};
export type MaterialsCategoryControllerFindAllApiResponse =
  /** status 200  */ MaterialsCategoryDto[];
export type MaterialsCategoryControllerFindAllApiArg = void;
export type MaterialsCategoryControllerCreateApiResponse =
  /** status 200  */ MaterialsCategoryDto;
export type MaterialsCategoryControllerCreateApiArg = {
  materialsCategoryCreateDto: MaterialsCategoryCreateDto;
};
export type MaterialsCategoryControllerUpdateApiResponse =
  /** status 200  */ UpdateResult;
export type MaterialsCategoryControllerUpdateApiArg = {
  id: string;
  materialsCategoryCreateDto: MaterialsCategoryCreateDto;
};
export type MaterialsCategoryControllerDeleteApiResponse =
  /** status 200  */ DeleteResult;
export type MaterialsCategoryControllerDeleteApiArg = {
  id: number;
};
export type MaterialsCategoryDto = {
  id: number;
  title: string;
};
export type MaterialsDto = {
  id: number;
  /** Код */
  code: string;
  /** Название */
  title: string;
  /** Содержание */
  content: string;
  /** Категория */
  category: MaterialsCategoryDto;
  /** Дата создания */
  createdAt: string;
  /** Дата обновления */
  updatedAt: string;
  /** Язык */
  lang: string;
};
export type PageableResultMaterialsDto = {
  content: MaterialsDto[];
  pageable: any;
  totalElements: number;
};
export type MaterialsCreateDto = {
  /** Код */
  code: string;
  /** Название */
  title: string;
  /** Содержание */
  content: string;
  /** Категория */
  category: number;
  /** Язык */
  lang: string;
};
export type UpdateResult = {
  affected: number;
  raw: object;
};
export type MaterialsUpdateDto = {
  /** Название */
  code: string;
  /** Название */
  title: string;
  /** Содержание */
  content: string;
  /** Категория */
  category: number;
  /** Язык */
  lang: string;
};
export type DeleteResult = {
  affected: number;
  raw: object;
};
export type MaterialsCategoryCreateDto = {
  /** Название */
  title: string;
};
export const {
  useMaterialsControllerFindAllQuery,
  useMaterialsControllerGetMaterialQuery,
  useMaterialsControllerGetMaterialsByCategoryQuery,
  useMaterialsControllerCreateMutation,
  useMaterialsControllerUpdateMutation,
  useMaterialsControllerDeleteMutation,
  useMaterialsCategoryControllerFindAllQuery,
  useMaterialsCategoryControllerCreateMutation,
  useMaterialsCategoryControllerUpdateMutation,
  useMaterialsCategoryControllerDeleteMutation,
} = injectedRtkApi;
