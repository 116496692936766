import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Col, Divider, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { CloseOutlined } from '@ant-design/icons';
import {
  ColorBlock,
  CustomModal,
  DangerButton,
  ManageEventsPath,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
  useFindConstructionBlockByIdQuery,
} from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { getBuildingBlockSelector } from '@store/selectors';
import { setObjectSystemBuildingBlock } from '@store/slices';
import { extractObjectTypes } from '@utils/objectType';
import { clone } from 'lodash'; // TODO: change lodash to native implementation
import { isEmpty, isInteger, toInteger } from '@utils/utils';

interface IAddObjectModalProps {
  show: boolean;
  setShow: (openModal: boolean) => void;
}

export const AddObjectModal: React.FC<IAddObjectModalProps> = ({
  show,
  setShow,
}) => {
  const [form] = useForm();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const buildingBlockState = useSelector(getBuildingBlockSelector);
  const { id } = useParams();
  const { data: buildingBlock } = useFindConstructionBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const { data: groups = [], isLoading: groupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const elementColor = ManageEventsPath(location.pathname);
  const objectGroupId = Form.useWatch('objectGroupId', form);
  const objectCategoryId = Form.useWatch('objectCategoryId', form);

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      objectGroupId
        ? {
          objectGroupId,
        }
        : skipToken,
    );

  const closeModal = () => {
    setShow(false);
  };

  const onFinish = (values: any) => {
    dispatch(setObjectSystemBuildingBlock(values));
    setShow(false);
  };

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );
  useEffect(() => {
    if (!isEmpty(buildingBlock) || buildingBlockState.constructionObject) {
      const dataClone = clone(buildingBlock || buildingBlockState);
      dataClone.systemObjectId = toInteger(dataClone.constructionObject?.id);

      form.setFieldsValue({
        name: dataClone?.constructionObject?.name,
        code: dataClone?.constructionObject?.code,
        objectGroupId:
          toInteger(dataClone.constructionObject?.objectGroup?.id) ||
          toInteger(dataClone.constructionObject?.objectGroupId),
        objectCategoryId:
          toInteger(dataClone.constructionObject?.objectCategory?.id) ||
          toInteger(dataClone.constructionObject?.objectCategoryId),
        typeCategory: dataClone.constructionObject?.typeCategory,
        geoJson: dataClone.constructionObject?.geoJson,
      });
    }
  }, [buildingBlock, buildingBlockState]);

  return (
    <CustomModal
      open={show}
      title={'Объект'}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      onCancel={closeModal}
      footer={
        <ColorBlock colorType={elementColor}>
          <Row justify="center">
            <Form.Item>
              <PrimaryButton size="large" onClick={() => form.submit()}>
                Добавить объект
              </PrimaryButton>
            </Form.Item>
          </Row>
        </ColorBlock>
      }
    >
      <ColorBlock colorType={elementColor}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Наименование объекта"
            required
            name="name"
            rules={[{ required: true }]}
          >
            <TooltipedInput size="large" placeholder="Наименование объекта" />
          </Form.Item>
          <Form.Item
            label="Код объекта"
            required
            name="code"
            rules={[{ required: true }]}
          >
            <TooltipedInput size="large" placeholder="Код объекта" />
          </Form.Item>
          <Form.Item
            label="Группа"
            required
            name="objectGroupId"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={groupsIsLoading}
              placeholder="Группа"
              options={groups}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Категория объекта"
            required
            name="objectCategoryId"
          >
            <TooltipedSelect
              size="large"
              disabled={!objectGroupId}
              placeholder="Категория объекта"
              loading={objectCategoriesIsLoading}
              options={objectCategories}
            />
          </Form.Item>
          <Form.Item
            label="Тип объекта"
            required
            name="typeCategory"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              disabled={!objectCategoryId}
              placeholder="Тип объекта"
              options={objectTypes}
            />
          </Form.Item>
        </Form>
      </ColorBlock>
    </CustomModal >
  );
};
