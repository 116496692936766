import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { Col, Row } from 'antd';


import { TooltipedSquaredInputV2 } from '../inputV2';
import styles from './PicketInputV2.module.scss';

interface PicketInputPropTypes {
  isLinear: boolean;
  value?: any;
  onChange?: (value: string) => void;
}

export const mapAnyPicketToLong = (picket: string): string =>
  [...picket.matchAll(/([0-9])/g)]
    .slice(0, 3)
    .map((m) => m[0])
    .join('')
    .toUpperCase();

enum PicketInputV2Keys {
  first = 0,
  second = 1,
  third = 2,
  fourth = 3,
}

export const PicketInputV2: FC<PicketInputPropTypes> = ({
  isLinear,
  onChange,
  value = '',
}) => {
  const onSingleInputChange = (
    event: SyntheticEvent<HTMLInputElement>,
    key: PicketInputV2Keys,
  ) => {
    const matched = mapAnyPicketToLong(event.currentTarget?.value);

    let combinedValue = '';
    if (key === PicketInputV2Keys.first) {
      combinedValue = `${matched}+${value.split(/[:+]/)[1] || ''}:${value
        .split(/[:+]/)
        .slice(2)
        .join('+')}`;
    }
    if (key === PicketInputV2Keys.second) {
      combinedValue = `${value.split(/[:+]/)[0] || ''}+${matched}:${value
        .split(/[:+]/)
        .slice(2)
        .join('+')}`;
    }
    if (key === PicketInputV2Keys.third) {
      combinedValue = `${value
        .split(/[:+]/)
        .slice(0, 2)
        .join('+')}:${matched}+${value.split(/[:+]/)[3] || ''}`;
    }

    if (key === PicketInputV2Keys.fourth) {
      const parts = value.split(/[:+]/);
      parts[3] = matched;
      combinedValue = `${parts.slice(0, 2).join('+')}:${parts
        .slice(2)
        .join('+')}`;
    }
    if (!isLinear) {
      const [firstPart, secondPart] = combinedValue.split(':');
      combinedValue = firstPart;
    }

    onChange?.(combinedValue);
  };

  return (
    <Row gutter={5}>
      <Col flex="20% 1 1">
        <TooltipedSquaredInputV2
          value={value.split(/[:+]/)[PicketInputV2Keys.first]}
          onChange={(e) => onSingleInputChange(e, PicketInputV2Keys.first)}
        />
      </Col>
      <div className={styles.divider}>+</div>
      <Col flex="20% 1 1">
        <TooltipedSquaredInputV2
          value={value.split(/[:+]/)[PicketInputV2Keys.second]}
          onChange={(e) => onSingleInputChange(e, PicketInputV2Keys.second)}
        />
      </Col>
      {isLinear && (
        <>
          <div className={styles.divider}>:</div>
          <Col flex="20% 1 1">
            <TooltipedSquaredInputV2
              value={value.split(/[:+]/)[PicketInputV2Keys.third]}
              onChange={(e) => onSingleInputChange(e, PicketInputV2Keys.third)}
            />
          </Col>
          <div className={styles.divider}>+</div>
          <Col flex="20% 1 1">
            <TooltipedSquaredInputV2
              value={value.split(/[:+]/)[PicketInputV2Keys.fourth]}
              onChange={(e) => onSingleInputChange(e, PicketInputV2Keys.fourth)}
            />
          </Col>
        </>
      )}
    </Row>
  );
};
