import { IBalanceSheetItem } from '@models/waterAccounting/interfaces/waterAccounting';
import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { roundBigInteger } from '@utils/roundBigInteger';

export const waterLogAccountingBalanceSheetDataMapper = (
  data?: IBalanceSheetItem[],
) => {
  if (!data) return [];
  const filterdData = data.map((item, index) => {
    let totalVal = 0;
    const days = item.days.reduce((acc: any, el) => {
      const val = el.value !== null && !isNaN(Number(el.value)) ? el.value : null;
      acc[el.day] = {
        ...el,
        valueM3:
          val !== null ? roundBigInteger(val * 0.864, 100) : null,
      };
      totalVal += val !== null ? formatDecimal(el.value) : 0;
      return acc;
    }, {});

    totalVal = formatDecimal(totalVal);
    return {
      ...item,
      index: index + 1,
      days,
      sum: {
        value: totalVal !== 0 ? roundBigInteger(totalVal, 100) : 0,
        valueM3: totalVal !== 0 ? roundBigInteger(totalVal * 0.864, 100) : 0,
      },
    };
  });
  return filterdData;
};
