import { createBrowserRouter } from 'react-router-dom';

import { ACCESS, Permission } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { ProtectedRoute } from '@features/authentication';
import {
  IrrigationSystemBaseLayout,
  MonitoringSystemLayout,
} from '@features/layout';
import { GeoPortalLayout } from '@features/layout/geoPortalLayout/GeoPortalLayout';
import { MainLayout } from '@features/layout/mainLayout/MainLayout';
import { AuthenticationPage, LoginPage } from '@pages/authentication';
import {
  ActsInvoicesForPaymentsTablePage,
  AgroClimaticZoneCardPage,
  AgroClimaticZoneTablePage,
  AnnualApplicationTablePage,
  AnnualPlanTablePage,
  ArchivariusPage,
  ContractualWaterSupplyTablePage,
  CreateAnnualApplicationPage,
  CreateBuildingCardPage,
  CreateMainConduitPage,
  CreateOperationalBlockPage,
  CreateOtherTasksBlockPage,
  CreateRegularEventsBlockPage,
  CreateWaterObjectPage,
  GeoPortalCardPage,
  GeoPortalPage,
  IrrigationEditorModePage,
  IrrigationSystemCreateStepperPage,
  IrrigationSystemTablePage,
  IrrigationSystemWaterObjectPage,
  LinkedOutletPage,
  ManageEventsGanttDiagramPage,
  ManageEventsPage,
  ManageEventsSchedulePage,
  TariffCardPage,
  TariffPage,
  WaterObjectCardPage,
  WaterQualityCardPage,
  WaterQualityPage,
  WaterRegistryAssociationsPage,
} from '@pages/dashboard';
import { AnnualApplicationActualStructurePage } from '@pages/dashboard/annualApplication/AnnualApplicationActualStructurePage';
import { AnnualApplicationAgreementPage } from '@pages/dashboard/annualApplication/AnnualApplicationAgreementPage';
import { AnnualApplicationExistedCalculationsPage } from '@pages/dashboard/annualApplication/AnnualApplicationExistedCalculationsPage';
import { ConclusionContractsTablePage } from '@pages/dashboard/annualApplication/ConclusionContractsTablePage';
import { WaterSupplyTablePage } from '@pages/dashboard/annualApplication/WaterSupplyTablePage';
import { AssociationIndicatorReportsPage } from '@pages/dashboard/associationIndicatorReports';
import { BusinessPlanTablePage } from '@pages/dashboard/businessPlan';
import { IrrigationSystemCardPage } from '@pages/dashboard/irrigationRegistry';
import { HydroCardPage } from '@pages/dashboard/monitoring/demoMonitoring/HydroCardPage';
import { MonitoringPage } from '@pages/dashboard/monitoring/demoMonitoring/MonitoringPage';
import { ReservoirMonitoringPage } from '@pages/dashboard/monitoring/reservoirMonitoring/ReservoirMonitoringPage';
import {
  AssociationWaterUserMasterRegistrationPage,
  IndividualWaterUserMasterRegistrationPage,
  LegalWaterUserMasterRegistrationPage,
  WaterRegistryUsersPage,
} from '@pages/dashboard/waterRegistry';
import { WaterUserCardPage } from '@pages/dashboard/waterUser';
import { DecadeApplicationPage } from '@pages/decadeApplication/DecadeApplicationPage';
import { ErrorPage } from '@pages/error';
import { HomePage } from '@pages/home/HomePage';
import { HydraulicSectionEditorPage } from '@pages/hydraulicSection/HydraulicSectionEditorPage';
import { SpprPage } from '@pages/sppr/SpprPage';
import { VodkhozReportPage } from '@pages/vodkhozReport/VodkhozReportPage';
import { WaterAccountingBalanceSheetPage } from '@pages/waterAccounting/WaterAccountingBalanceSheetPage';
import { WaterAccountingPage } from '@pages/waterAccounting/WaterAccountingPage';
import { WateringPlanPage } from '@pages/wateringPlan/WateringPlanPage';
import { WaterUsePlanPage } from '@pages/waterUsePlan/WaterUsePlanPage';
import {
  BuildingWorksAndEvents,
  OperationalWorksAndEvents,
} from '@pages/worksAndEvents';
import { ReferenceBookPage } from '@pages/referenceBook/ReferenceBookPage';
import { ReservoirListPage } from '@pages/dashboard/monitoring/reservoirList/ReservoirListPage';
import { ReservoirMonitoringCardPage } from '@pages/dashboard/monitoring/reservoirMonitoringCard/ReservoirMonitoringCardPage';
import { AnnualApplicationViewPage } from '@pages/dashboard/annualApplication/AnnualApplicationViewPage';
import { ProfilePage } from '@pages/profile/ProfilePage';
import { GeoPortalManagementPage } from '@pages/dashboard/administration/GeoPortalManagementPage';
import { InstructionsManagementPage } from '@pages/dashboard/administration/InstructionsManagementPage';
import { NewsManagementPage } from '@pages/dashboard/administration/NewsManagementPage';
import { ReferenceMaterialsPage } from '@pages/dashboard/administration/ReferenceMaterialsPage';
import { RolesAndRightsManagementPage } from '@pages/dashboard/administration/RolesAndRightsManagementPage';
import { EmployeesPage } from '@pages/dashboard/administration/EmployeesPage';
import { CreateIrrigationReservoirPage } from '@pages/dashboard/irrigationReservoir';
import { GeoPortalArchiveManagementPage } from '@pages/dashboard/administration/GeoPortalArchiveManagementPage';

export const router = createBrowserRouter([
  {
    index: true,
    path: Routes.Landing,
    element: (
      <ProtectedRoute
        permissions={[ACCESS.NEVER]}
        redirectPath={Routes.WaterRegistryUsers}
      >
        <div />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    element: (
      <ProtectedRoute
        permissions={[ACCESS.IS_UNAUTHENTICATED]}
        redirectPath={Routes.WaterRegistryUsers}
      >
        <AuthenticationPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Routes.Login,
        element: <LoginPage />,
      },
    ],
  },

  // Geo Portal Layout
  {
    element: (
      <ProtectedRoute permissions={[ACCESS.IS_AUTHENTICATED]}>
        <GeoPortalLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Routes.geoPortal,
        children: [
          {
            path: Routes.geoPortalTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.GEOPORTAL_VIEW]}
              >
                <GeoPortalPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.geoPortalMap,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.GEOPORTAL_VIEW]}
              >
                <GeoPortalCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  // Main Layout
  {
    element: (
      <ProtectedRoute permissions={[ACCESS.IS_AUTHENTICATED]}>
        <MainLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      // Home Page
      {
        path: Routes.Home,
        index: true,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.MAIN_VIEW]}
          >
            <HomePage />
          </ProtectedRoute>
        ),
      },

      // 2-TP Vodkhoz Report
      {
        path: Routes.vodkgozReport,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[
              ACCESS.IS_AUTHENTICATED,
              ACCESS.REPORT_2TP_ENTRYDATA_VIEW,
            ]}
          >
            <VodkhozReportPage />
          </ProtectedRoute>
        ),
      },

      // Sppr
      {
        path: Routes.sppr,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.SPPR_VIEW]}
          >
            <SpprPage />
          </ProtectedRoute>
        ),
      },

      // water registry
      {
        path: Routes.WaterRegistry,
        children: [
          {
            path: Routes.WaterRegistryUsers,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WATER_USER_VIEW]}
              >
                <WaterRegistryUsersPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterRegistryAssociations,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.WATER_USER_ASSOCIATION_VIEW,
                ]}
              >
                <WaterRegistryAssociationsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterRegistryMasterRegistrationIndividual,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.WATER_USER_CREATE,
                ]}
              >
                <IndividualWaterUserMasterRegistrationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterRegistryMasterRegistrationLegal,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.WATER_USER_CREATE,
                ]}
              >
                <LegalWaterUserMasterRegistrationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterRegistryMasterRegistrationAssociation,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.WATER_USER_ASSOCIATION_CREATE,
                ]}
              >
                <AssociationWaterUserMasterRegistrationPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // water registry card
      {
        path: Routes.WaterUser,
        children: [
          {
            path: Routes.WaterUserCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.WATER_USER_CARD_VIEW,
                ]}
              >
                <WaterUserCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Hydro Module
      {
        path: Routes.HydraulicSection,
        children: [
          {
            path: Routes.HydraulicSectionEditor,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.HYDRO_FACILITY_VIEW,
                ]}
              >
                <HydraulicSectionEditorPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Agro Climatic Zone
      {
        path: Routes.AgroClimaticZone,
        children: [
          {
            path: Routes.AgroClimaticZoneTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.AGROCLIMATIC_ZONE_VIEW,
                ]}
              >
                <AgroClimaticZoneTablePage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AgroClimaticZoneCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.AGROCLIMATIC_ZONE_VIEW,
                ]}
              >
                <AgroClimaticZoneCardPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.LinkedOutlet,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.AGROCLIMATIC_ZONE_VIEW,
                ]}
              >
                <LinkedOutletPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationModeEditor,
            element: (
              <ProtectedRoute
                redirectPath={Routes.Home}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.AGROCLIMATIC_ZONE_VIEW,
                ]}
              >
                <IrrigationEditorModePage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: Routes.AnnualApplication,
        children: [
          {
            path: Routes.AnnualApplicationTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <AnnualApplicationTablePage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <CreateAnnualApplicationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationCardView,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <AnnualApplicationViewPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationCardAgreement,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <AnnualApplicationAgreementPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationCardCalculations,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <AnnualApplicationExistedCalculationsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationCardActualStructure,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <AnnualApplicationActualStructurePage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationContractualWaterSupplyTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <ContractualWaterSupplyTablePage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationWaterSupplyTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <WaterSupplyTablePage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.AnnualApplicationConclusionContractsTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_PLANNING_VIEW,
                ]}
              >
                <ConclusionContractsTablePage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Water Accounting
      {
        path: Routes.WaterAccounting,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[
              ACCESS.IS_AUTHENTICATED,
              ACCESS.WATER_ACCOUNTING_HOUSEHOLD_VIEW,
            ]}
          >
            <WaterAccountingPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.WaterAccountingBalanceSheet,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[
              ACCESS.IS_AUTHENTICATED,
              ACCESS.WATER_ACCOUNTING_HOUSEHOLD_VIEW,
            ]}
          >
            <WaterAccountingBalanceSheetPage />
          </ProtectedRoute>
        ),
      },

      // Water Use
      {
        path: Routes.WateringPlan,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WATER_USE_VIEW]}
          >
            <WateringPlanPage />
          </ProtectedRoute>
        ),
      },

      // Decade Application
      {
        path: Routes.DecadeApplication,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.DECADE_REQUEST_VIEW]}
          >
            <DecadeApplicationPage />
          </ProtectedRoute>
        ),
      },

      // Water Use Plan
      {
        path: Routes.WaterUsePlan,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.ANNUAL_PLANNING_VIEW]}
          >
            <WaterUsePlanPage />
          </ProtectedRoute>
        ),
      },
      // irrigation system
      {
        path: Routes.IrrigationSystem,
        element: (
          <ProtectedRoute permissions={[ACCESS.IS_AUTHENTICATED]}>
            <IrrigationSystemBaseLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: Routes.IrrigationReservoir,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_CREATE,
                ]}
              >
                <CreateIrrigationReservoirPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationSystemCreateStepper,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_CREATE,
                ]}
              >
                <IrrigationSystemCreateStepperPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationSystemWaterObjectsRegistration,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_CREATE,
                ]}
              >
                <IrrigationSystemWaterObjectPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationSystemMainConduitRegistration,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_CREATE,
                ]}
              >
                <CreateMainConduitPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationSystemCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_VIEW,
                ]}
              >
                <IrrigationSystemCardPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.IrrigationSystemTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_VIEW,
                ]}
              >
                <IrrigationSystemTablePage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // water registry card
      {
        path: Routes.WaterUser,
        children: [
          {
            path: Routes.WaterUserCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryTable}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_VIEW,
                ]}
              >
                <WaterUserCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // invoice payment
      {
        path: Routes.ActsInvoicesForPayments,
        children: [
          {
            path: Routes.ActsInvoicesForPaymentsTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ACT_PAYMENT_CARD_VIEW,
                ]}
              >
                <ActsInvoicesForPaymentsTablePage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // decade app
      {
        path: Routes.DecadeApplication,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.DECADE_REQUEST_VIEW]}
          >
            <DecadeApplicationPage />
          </ProtectedRoute>
        ),
      },

      // Manage Events
      {
        path: Routes.ManageEvents,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <ManageEventsPage />
          </ProtectedRoute>
        ),
      },

      // Manage Events Schedule
      {
        path: Routes.ManageEventsSchedule,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <ManageEventsSchedulePage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.WorksAndEventsBuilding,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <BuildingWorksAndEvents />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.WorksAndEventsOperational,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <OperationalWorksAndEvents />
          </ProtectedRoute>
        ),
      },
      // Manage Events Create Cards
      {
        path: Routes.OperationalBlock,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <CreateOperationalBlockPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.BuildingBlock,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <CreateBuildingCardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.RegularEvent,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <CreateRegularEventsBlockPage />
          </ProtectedRoute>
        ),
      },

      {
        path: Routes.OtherTask,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <CreateOtherTasksBlockPage />
          </ProtectedRoute>
        ),
      },

      // Other Tasks Gantt Diagram
      {
        path: Routes.ManageEventsGanttDiagram,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.WORK_MANAGEMENT_VIEW]}
          >
            <ManageEventsGanttDiagramPage />
          </ProtectedRoute>
        ),
      },

      // Water Object
      {
        path: Routes.WaterObject,
        children: [
          {
            path: Routes.WaterObjectCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_VIEW,
                ]}
              >
                <WaterObjectCardPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterObjectCreate,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.IRRIGATION_SYSTEM_CREATE,
                ]}
              >
                <CreateWaterObjectPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Demo Monitoring
      {
        path: Routes.DemoMonitoring,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.MONITORING_RUVH_VIEW]}
          >
            <MonitoringPage />
          </ProtectedRoute>
        ),
        children: [
          {
            path: Routes.DemoMonitoringSection,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.MONITORING_RUVH_VIEW,
                ]}
              >
                <MonitoringSystemLayout />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.DemoMonitoringHydroCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.MONITORING_RUVH_VIEW,
                ]}
              >
                <HydroCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Reservoir Monitoring
      {
        path: Routes.ReservoirTable,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.RESERVOIRS_VIEW]}
          >
            <ReservoirListPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.ReservoirMonitoring,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.RESERVOIRS_VIEW]}
          >
            <ReservoirMonitoringPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.ReservoirMonitoringCard,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.RESERVOIRS_VIEW]}
          >
            <ReservoirMonitoringCardPage />
          </ProtectedRoute>
        ),
      },

      // Annual Plan
      {
        path: Routes.AnnualPlan,
        children: [
          {
            path: Routes.AnnualPlanTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.ANNUAL_REQUEST_VIEW,
                ]}
              >
                <AnnualPlanTablePage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Annual Application

      // Business Plan
      {
        path: Routes.BusinessPlan,
        children: [
          {
            path: Routes.BusinessPlanTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[ACCESS.IS_AUTHENTICATED]}
              >
                <BusinessPlanTablePage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Archivarius
      {
        path: Routes.Archivarius,
        children: [
          {
            path: Routes.ArchivariusTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[ACCESS.IS_AUTHENTICATED]}
              >
                <ArchivariusPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Tariff
      {
        path: Routes.Tariff,
        children: [
          {
            path: Routes.TariffTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.TARIFF_VIEW]}
              >
                <TariffPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.TariffCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.TARIFF_VIEW]}
              >
                <TariffCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },

      // Water Quality
      {
        path: Routes.WaterQuality,
        children: [
          {
            path: Routes.WaterQualityTable,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.QUALITY_INDICATORS_VIEW,
                ]}
              >
                <WaterQualityPage />
              </ProtectedRoute>
            ),
          },
          {
            path: Routes.WaterQualityCard,
            element: (
              <ProtectedRoute
                redirectPath={Routes.WaterRegistryUsers}
                permissions={[
                  ACCESS.IS_AUTHENTICATED,
                  ACCESS.QUALITY_INDICATORS_VIEW,
                ]}
              >
                <WaterQualityCardPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Association Stats Page
      {
        path: Routes.AssociationIndicatorReports,
        element: (
          <ProtectedRoute
            redirectPath={Routes.WaterRegistryUsers}
            permissions={[
              ACCESS.IS_AUTHENTICATED,
              // TODO access
            ]}
          >
            <AssociationIndicatorReportsPage />
          </ProtectedRoute>
        ),
      },
      // Reference Book Page
      {
        path: Routes.ReferenceBook,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.REFERENCES_VIEW]}
          >
            <ReferenceBookPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.Profile,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED]}
          >
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.ProfileById,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.OWNERS_VIEW]}
          >
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      //administration
      {
        path: Routes.GeoPortalManagement,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.GEO_PORTAL_VIEW]}
          >
            <GeoPortalManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.GeoPortalArchiveManagement,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.GEO_PORTAL_VIEW]}
          >
            <GeoPortalArchiveManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.InstructionsManagement,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.INSTRUCTIONS_VIEW]}
          >
            <InstructionsManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.NewsManagement,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.NEWS_VIEW]}
          >
            <NewsManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.ReferenceMaterials,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[
              ACCESS.IS_AUTHENTICATED,
              ACCESS.REFERENCE_MATERIALS_VIEW,
            ]}
          >
            <ReferenceMaterialsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.RolesAndRightsManagement,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.ROLES_VIEW]}
          >
            <RolesAndRightsManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.Employees,
        element: (
          <ProtectedRoute
            redirectPath={Routes.Home}
            permissions={[ACCESS.IS_AUTHENTICATED, ACCESS.OWNERS_VIEW]}
          >
            <EmployeesPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
