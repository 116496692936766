import { SpprMenuKey } from '@pages/sppr/types/spprTypes';

export const SEARCH_PARAMS_KEY = 'MenuType';
export const TAB_PARAMS_KEY = 'tab';

export const MAIN_MENU_BUTTONS = [
  {
    label: 'Карты',
    key: SpprMenuKey.map,
  },
  {
    label: 'Водопользование',
    key: SpprMenuKey.waterOutlet,
  },
  {
    label: 'Ирригационный фонд',
    key: SpprMenuKey.irrigationFund,
  },
  {
    label: 'Качественные показатели',
    key: SpprMenuKey.qualitativeIndicators,
  },
  {
    label: 'Водохранилища',
    key: SpprMenuKey.reservoirs,
  },
  {
    label: 'Мониторинг тарифов',
    key: SpprMenuKey.tariffMonitoring,
  },
  {
    label: 'Балансовый водоучет',
    key: SpprMenuKey.balanceSheetAccounting,
  },
  {
    label: 'Балансовый учет (по системам)',
    key: SpprMenuKey.balanceSheetAccountingSystem,
  },
];

export const IRRIGATION_FUND_MENU_BUTTONS = [
  {
    label: 'Общие данные',
    key: SpprMenuKey.irrigationFundMainData,
  },
  {
    label: 'Эксплуатация (общий дашборд)',
    key: SpprMenuKey.irrigationFundOperationalBlockMonitoring,
  },
  {
    label: 'Эксплуатационный блок список',
    key: SpprMenuKey.irrigationFundOperationalBlock,
  },
  {
    label: 'Строительство (Общий дашборд)',
    key: SpprMenuKey.irrigationFundConstructionMonitoring,
  },
  {
    label: 'Строительство список',
    key: SpprMenuKey.irrigationFundConstruction,
  },
];

export const WATER_OUTLET_MENU_BUTTONS = [
  { label: 'Общие данные', key: SpprMenuKey.waterOutletMainData },
  {
    label: 'Хоз. Договорная водоподача Республика',
    key: SpprMenuKey.waterOutletAgreementRepublic,
  },
  {
    label: 'Хоз. Договорная водоподача системы',
    key: SpprMenuKey.waterOutletAgreementSystem,
  },
  { label: 'Посевная план/факт', key: SpprMenuKey.waterOutletSovingFact },
  // { label: 'Посевная Факт', key: SpprMenuKey.waterOutletSovingPlan },
];

export const QUALITATIVE_INDICATORS_BUTTONS = [
  {
    label: 'Минерализация грунтовых вод',
    key: SpprMenuKey.qualitativeIndicatorsGroundwaterMineralization,
  },
  {
    label: 'Глубина грунтовых вод',
    key: SpprMenuKey.qualitativeIndicatorsGroundwaterDepth,
  },
  {
    label: 'Состояние мелиорации',
    key: SpprMenuKey.qualitativeIndicatorsReclamationState,
  },
];
