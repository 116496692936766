import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const DownloadIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5502_135467)">
        <path
          d="M13.5625 8.5751V12.2501C13.5625 12.5982 13.4242 12.932 13.1781 13.1782C12.9319 13.4243 12.5981 13.5626 12.25 13.5626H1.75C1.4019 13.5626 1.06806 13.4243 0.821922 13.1782C0.575781 12.932 0.4375 12.5982 0.4375 12.2501V8.5751C0.4375 8.36624 0.520469 8.16594 0.668153 8.01825C0.815838 7.87057 1.01614 7.7876 1.225 7.7876C1.43386 7.7876 1.63416 7.87057 1.78185 8.01825C1.92953 8.16594 2.0125 8.36624 2.0125 8.5751V11.9876H11.9875V8.5751C11.9875 8.36624 12.0705 8.16594 12.2182 8.01825C12.3658 7.87057 12.5661 7.7876 12.775 7.7876C12.9839 7.7876 13.1842 7.87057 13.3318 8.01825C13.4795 8.16594 13.5625 8.36624 13.5625 8.5751Z"
          fill="#4B60D8"
        />
        <path
          d="M7.78884 6.6713L9.06918 5.39227L9.06787 5.3903C9.21581 5.24236 9.41646 5.15925 9.62568 5.15925C9.8349 5.15925 10.0356 5.24236 10.1835 5.3903C10.3314 5.53824 10.4146 5.73889 10.4146 5.94812C10.4146 6.05171 10.3941 6.15429 10.3545 6.25C10.3149 6.34571 10.2567 6.43268 10.1835 6.50593L7.5585 9.13093C7.48534 9.20434 7.3984 9.2626 7.30268 9.30234C7.20696 9.34209 7.10433 9.36255 7.00068 9.36255C6.89704 9.36255 6.79441 9.34209 6.69869 9.30234C6.60297 9.2626 6.51603 9.20434 6.44287 9.13093L3.81787 6.50593C3.66993 6.35799 3.58682 6.15734 3.58682 5.94812C3.58682 5.7389 3.66993 5.53824 3.81787 5.3903C3.96581 5.24236 4.16646 5.15925 4.37568 5.15925C4.5849 5.15925 4.78556 5.24236 4.9335 5.3903L6.21384 6.6713L6.21384 1.22443C6.21384 1.01557 6.29681 0.815267 6.44449 0.667582C6.59218 0.519897 6.79248 0.436928 7.00134 0.436928C7.2102 0.436928 7.4105 0.519897 7.55819 0.667582C7.70587 0.815267 7.78884 1.01557 7.78884 1.22443L7.78884 6.6713Z"
          fill="#4B60D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_5502_135467">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
