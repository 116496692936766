import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, notification, Row } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { DangerButton, DefaultButton, PrimaryButton } from '@features/ui-kit';
import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import {
  CreateIndividualWaterRegistryQueryTypes,
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import {
  saveLegalPersonApiArg,
  useWaterUserCreateMutation,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import { clearEconomiesAction, clearFormDataAction } from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { ContractDetails, DocumentsBlock } from '../fields';
import { useActionSelect } from '../useActionSelect';
import styles from './styles.module.scss';
import { DocumentTypes } from '@enums/documentTypes';
import { IAttachment } from '@models/attachments/interfaces/attachment';

export const IndividualMasterRegistrationThirdStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentWaterRegistry = useSelector(getFormData);
  const [save] = useWaterUserCreateMutation();
  const { setShowModal: setShowActionSelectModal } = useActionSelect();
  const { setUserId } = useWaterUserId();
  const { setOrgId } = useOrganizationId();

  const onFinish = () => {
    try {
      const data = currentWaterRegistry[WaterUserTypes.physical];
      const { employees, facilities, attachmentResponseDtos, ...otherProps } =
        data;
      save({
        employees,
        legalData: otherProps,
        region: data.facilities[0].region,
        district: data.facilities[0].district,
        type: WaterUserEnum.INDIVIDUAL,
        attachmentsList: attachmentResponseDtos,
        facilities,
      } as saveLegalPersonApiArg)
        .then((response: any) => {
          if (response.error) {
            notification.error({
              message: 'Не удалось создать водопользователя',
            });
            return;
          } else {
            setShowActionSelectModal(true);
            setUserId(response?.id || response?.data?.id);
            setOrgId(response?.owner?.id || response?.data?.owner?.id);
            dispatch(clearEconomiesAction());
            dispatch(
              clearFormDataAction({
                waterRegistryUserType: WaterUserTypes.physical,
              }),
            );
            notification.success({
              message: 'Водопользователь успешно создан',
            });
          }
        })
        .catch((err) => {
          console.log('error', err);
          notification.error({
            message: 'Не удалось создать водопользователя',
          });
        });
    } catch (error) {
      console.log('error', error);
      notification.error({ message: 'Не удалось создать водопользователя' });
    }
  };

  const onFinishFailed = (err: ValidateErrorEntity) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
    dispatch(clearEconomiesAction());
    navigate(Routes.WaterRegistryUsers);
  };

  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationIndividual}?${SectionQueryKey}=${CreateIndividualWaterRegistryQueryTypes.create}`,
    );
  };

  if (!currentWaterRegistry?.physical?.district) {
    onBackButtonClick();
  }

  const documents: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.physical].attachmentResponseDtos ||
      [],
    [currentWaterRegistry],
  );

  const attachments = documents?.filter(
    (att) => att.type === DocumentTypes.Agreement,
  );

  const validateAttachments = useMemo(() => {
    return attachments?.some((att) => att.type === DocumentTypes.Agreement);
  }, [attachments]);

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <Row gutter={[30, 30]}>
        <Col span={16}>
          <ContractDetails />
        </Col>
        <Col span={8}>
          <DocumentsBlock userType={WaterUserTypes.physical} />
        </Col>
      </Row>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <DangerButton htmlType="submit" onClick={onCancelButtonClick}>
            {t('createIndividualWaterRegistry.buttons.cancel')}
          </DangerButton>
        </Col>

        <Col span={4}>
          <Row gutter={16} justify="end">
            <Col>
              <DefaultButton
                type="default"
                htmlType="submit"
                onClick={onBackButtonClick}
                size="large"
              >
                {t('createIndividualWaterRegistry.buttons.back')}
              </DefaultButton>
            </Col>
            <Col>
              <PrimaryButton
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!validateAttachments}
              >
                {t('createLegalWaterRegistry.buttons.next')}
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
