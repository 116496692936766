import { Col, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';
import * as React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import {
  TooltipedInput,
  TooltipedSelect,
  DirectionRadio,
} from '@features/ui-kit';

import styles from './PropertyBlock.module.scss';

type PropertyBlockProps = {
  label: string;
  options?: any[];
  type: 'radio' | 'select' | 'input';
  value?: any;
  onChange?: (value: any) => void;
  icon?:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
};

export const PropertyBlock: React.FC<PropertyBlockProps> = ({
  icon,
  label,
  options,
  type,
  value,
  onChange,
}) => {
  const onEventTargetValueChange = (event: any) => {
    if (onChange) {
      onChange(event?.target?.value || event);
    }
  };

  let Renderer;

  switch (type) {
    case 'input':
      Renderer = (
        <TooltipedInput
          onChange={onEventTargetValueChange}
          type="number"
          style={{ borderRadius: 6, border: '1px solid rgba(0, 0, 0, 0.06)' }}
          value={value}
          size="large"
        />
      );
      break;
    case 'select':
      Renderer = (
        <TooltipedSelect
          className={styles.select}
          onChange={onEventTargetValueChange}
          popupMatchSelectWidth={false}
          options={options}
          style={{ borderRadius: 6, border: '1px solid rgba(0, 0, 0, 0.06)' }}
          value={value}
          size="large"
        />
      );
      break;
    case 'radio':
      Renderer = (
        <DirectionRadio onChange={onEventTargetValueChange} value={value} />
      );
      break;
    default:
      Renderer = null;
      break;
  }

  return (
    <Row justify="space-between" align="middle" className={styles.infoItem}>
      <Col span={14}>
        <Space size={8}>
          <Icon className={styles.icon} component={icon} />
          <Typography.Text className={styles.text}>{label}</Typography.Text>
        </Space>
      </Col>
      <Col span={10}>{Renderer}</Col>
    </Row>
  );
};
