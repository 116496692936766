export function getTreeFromFlatData({
  flatData,
  getKey = (node: any) => node.id,
  getParentKey = (node: any) => node.parentId,
  rootKey = '0',
}: any) {
  if (!flatData) {
    return [];
  }

  const childrenToParents: any = {};
  flatData.forEach((child: any) => {
    const parentKey = getParentKey(child);

    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child);
    } else {
      childrenToParents[parentKey] = [child];
    }
  });

  if (!(rootKey in childrenToParents)) {
    return [];
  }

  const trav = (parent: any) => {
    const parentKey = getKey(parent);
    if (parentKey in childrenToParents) {
      return {
        ...parent,
        children: childrenToParents[parentKey].map((child: any) => trav(child)),
      };
    }

    return { ...parent };
  };

  return childrenToParents[rootKey].map((child: any) => trav(child));
}
