import { ACCESS } from '@enums/permisson';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useReservoirListTableColumns = (isArchived = false) => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'name',
      title: 'Наименование',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'objectGroup.groupName',
      title: 'Группа',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'objectCategory.groupTitle',
      title: 'Категория',
      xtype: CustomTableColumnType.String,
    },
    {
      dataIndex: 'typeCategory',
      title: 'Тип',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: !isArchived
        ? [
            CustomTableAction.EditWithoutText,
            CustomTableAction.WatchWithoutText,
            CustomTableAction.ArchiveWithoutText,
          ]
        : [CustomTableAction.Watch, CustomTableAction.UnArchiveWithoutText],
      key: 'actions',
      actionsAccess: !isArchived
        ? [
            ACCESS.RESERVOIRS_CARD_VIEW,
            ACCESS.RESERVOIRS_CARD_EDIT,
            ACCESS.RESERVOIRS_CARD_ARCHIVE,
          ]
        : [ACCESS.RESERVOIRS_CARD_VIEW, ACCESS.RESERVOIRS_CARD_ARCHIVE],
    },
  ];

  return { columns };
};
