import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Col, Row, Space } from 'antd';

import {
  ChannelQueryKey,
  DecadeDayQueryKey,
  DecadeQueryKey,
  HydroFacilityQueryKey,
  IrrigationSystemQueryKey,
  MonthQueryKey,
  OutletQueryKey,
  YearQueryKey,
} from '@constants/queryKeys';
import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { PrimaryButton, Segmented, TooltipedSelect } from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { IDecadesOptions } from '@models/decade/interfaces/decade';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';
import { generateDecadesDaysOptions } from '@utils/generateDecadesDaysOptions';
import { generateDecadesOptions } from '@utils/generateDecadesOptions';
import { getDaysInMonth } from '@utils/getDaysInMonth';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';
import { useWaterAccountingFilters } from '@features/waterAccounting/useWaterAccountingFilters';
import { useGetCurrentUser } from '@features/authentication';

export const BalanceSheetAccountingControls = () => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const { user } = useGetCurrentUser();

  const [filterState, setFilterState] = useState<any>({});
  const [decadesOptions, setDecadesOptions] = useState<IDecadesOptions[]>([]);
  const [decadeDaysOptions, setDecadeDaysOptions] = useState<IDecadesOptions[]>(
    [],
  );

  const { setFilters, filters } = useWaterAccountingFilters('balance');

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      filterState.hydroFacilityId
        ? {
            hydroFacilityId: filterState.hydroFacilityId,
          }
        : skipToken,
    );
  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    filterState.irrigationSystemId
      ? { id: filterState.irrigationSystemId }
      : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    filterState.channelId ? { id: filterState.channelId } : skipToken,
  );

  const monthOptions = useMemo(
    () =>
      Object.values(MonthEnums).map((monthEnum) => ({
        label: t(`months.${monthEnum}`),
        value: monthEnum,
      })),
    [MonthEnums],
  );

  const onFilterChange = (key: string, value: number) => {
    setFilterState((state: any) => ({
      ...state,
      [key]: value,
    }));
  };

  const changeMonthHandle = (v: any) => {
    setFilters({ ...filters, [MonthQueryKey]: v });
    params.set(MonthQueryKey, v as unknown as string);
    setParams(params);
  };

  const changeDecadeHandle = (v: any) => {
    setFilters({ ...filters, [DecadeQueryKey]: v });
    params.set(DecadeQueryKey, v as unknown as string);
    setParams(params);
  };

  const changeDecadeDayHandle = (v: any) => {
    setFilters({ ...filters, [DecadeDayQueryKey]: v });
    params.set(DecadeDayQueryKey, v as unknown as string);
    setParams(params);
  };
  const onFilterButtonClick = () => {
    if (filterState.hydroFacilityId) {
      params.set(HydroFacilityQueryKey, filterState.hydroFacilityId);
    }
    if (filterState.irrigationSystemId) {
      params.set(IrrigationSystemQueryKey, filterState.irrigationSystemId);
    }
    if (filterState.channelId) {
      params.set(ChannelQueryKey, filterState.irrigationSystemId);
    }
    if (filterState.outletId) {
      params.set(OutletQueryKey, filterState.outletId);
    }
    setParams(params);
    setFilters({
      ...filters,
      ...filterState,
    });
  };

  const onResetFilterButtonClick = () => {
    setFilters({
      ...filters,
      year: dayjs().year(),
      channelId: undefined,
      hydroFacilityId: undefined,
      outletId: undefined,
      irrigationSystemId: undefined,
    });
    params.delete(HydroFacilityQueryKey);
    params.delete(IrrigationSystemQueryKey);
    params.delete(ChannelQueryKey);
    params.delete(OutletQueryKey);
    setParams(params);
    setFilterState({});
  };

  useEffect(() => {
    if (filters.month && filters.year) {
      const days = getDaysInMonth(filters.month, dayjs(filters.year));
      const decades = generateDecadesOptions(days);
      setDecadesOptions(decades);
    }
  }, [filters]);

  useEffect(() => {
    const decadesDays = generateDecadesDaysOptions(
      filters.decade,
      decadesOptions,
    );
    setDecadeDaysOptions(decadesDays);
  }, [decadesOptions]);

  useEffect(() => {
    const selectedDecade = params.get(DecadeQueryKey) || DecadesEnum.First;
    const selectedDecadeDay =
      params.get(DecadeDayQueryKey) || DaysInDecadeEnum.FirstDaysPart;
    const selectedMonth = params.get(MonthQueryKey) || MonthEnums.January;
    const selectedYear = params.get(YearQueryKey) || dayjs().year();
    const newFilters = {
      ...filters,
      month: selectedMonth as any,
      decade: selectedDecade as any,
      decadeDays: selectedDecadeDay as any,
      year: selectedYear,
    };

    const selectedHydroFacilityId = params.get(HydroFacilityQueryKey);
    if (selectedHydroFacilityId) {
      newFilters.hydroFacilityId = parseInt(selectedHydroFacilityId);
    }
    const selectedIrrigationSystemId = params.get(IrrigationSystemQueryKey);
    if (selectedIrrigationSystemId) {
      newFilters.irrigationSystemId = parseInt(selectedIrrigationSystemId);
    }
    const selectedChannelId = params.get(ChannelQueryKey);
    if (selectedChannelId) {
      newFilters.channelId = parseInt(selectedChannelId);
    }
    const selectedOutletId = params.get(OutletQueryKey);
    if (selectedOutletId) {
      newFilters.outletId = parseInt(selectedOutletId);
    }

    setFilters(newFilters);
    setFilterState(newFilters);
  }, []);

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Space size={8}>
          <YearPicker
            allowClear={false}
            placeholder="Год"
            defaultValue={dayjs()}
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
            onChange={(date: Dayjs | null) =>
              onFilterChange('year', date ? date.year() : dayjs().year())
            }
          />
          <TooltipedSelect
            popupMatchSelectWidth={300}
            options={hydroFacilities}
            onChange={(v: number) => onFilterChange('hydroFacilityId', v)}
            placeholder="Гидроучасток"
            value={filterState.hydroFacilityId}
            allowClear
          />
          <TooltipedSelect
            popupMatchSelectWidth={300}
            options={systems}
            onChange={(v: number) => onFilterChange('irrigationSystemId', v)}
            placeholder="Оросительная система"
            value={filterState.irrigationSystemId}
            allowClear
            disabled={!filterState.hydroFacilityId}
          />
          <TooltipedSelect
            popupMatchSelectWidth={300}
            options={channels}
            onChange={(v: number) => onFilterChange('channelId', v)}
            placeholder="Канал"
            value={filterState.channelId}
            allowClear
            disabled={!filterState.irrigationSystemId}
          />
          <TooltipedSelect
            popupMatchSelectWidth={300}
            onChange={(v: number) => onFilterChange('outletId', v)}
            options={outlets}
            placeholder="Водовыпуск"
            value={filterState.outletId}
            allowClear
            disabled={!filterState.channelId}
          />
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Segmented
          options={monthOptions}
          onChange={changeMonthHandle}
          value={filters.month}
        />
      </Col>

      <Col span={24}>
        <Segmented
          options={decadesOptions}
          onChange={changeDecadeHandle}
          value={toInteger(filters.decade)}
        />
      </Col>

      <Col span={24}>
        <Segmented
          options={decadeDaysOptions}
          onChange={changeDecadeDayHandle}
          value={toInteger(filters.decadeDays)}
        />
      </Col>
    </Row>
  );
};
