import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';
import {
  CustomCard,
  DefaultButton,
  DocsList,
  Mask,
  TooltipedInput,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import { UploadFileIcon } from '@icons/UploadFileIcon';

export const AddAccountant: FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [show, setShow] = useState(false);
  const [defaultDocType, setDefaultDocType] = useState('');

  const attachmentDtos =
    Form.useWatch(['accountant', 'attachmentsList'], form) || [];
  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue(['accountant', 'attachmentsList']) || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue(
      ['accountant', 'attachmentsList'],
      updatedAttachments,
    );
    setDefaultDocType('');
  };

  return (
    <>
      <CustomCard
        title={
          <Row>
            <Col span={24}>
              <Typography.Title level={4}>Бухгалтер</Typography.Title>
              <Divider className={styles.divider} />
            </Col>
          </Row>
        }
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label={t(
                'createLegalWaterRegistry.secondStep.placeholders.lastName',
              )}
              name={['accountant', 'lastName']}
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Фамилия" size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t(
                'createLegalWaterRegistry.secondStep.placeholders.firstName',
              )}
              name={['accountant', 'firstName']}
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Имя" size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t(
                'createLegalWaterRegistry.secondStep.placeholders.middleName',
              )}
              name={['accountant', 'middleName']}
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Имя" size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('createIndividualWaterRegistry.modal.pin')}
              name={['accountant', 'inn']}
              rules={[{ required: true }, { len: 14 }, numbersValidator]}
            >
              <TooltipedInput maxLength={14} placeholder="ПИН" size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t(
                'createLegalWaterRegistry.secondStep.placeholders.phone',
              )}
              name={['accountant', 'phone']}
              rules={[
                {
                  required: true,
                },
                phoneNumberValidator,
              ]}
              normalize={mapPhoneNumberToLong}
            >
              <TooltipedInput
                size="large"
                addonBefore="+996"
                placeholder="Номер телефона"
                onChange={changeCursorPhoneNumberInput}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t(
                'createLegalWaterRegistry.secondStep.placeholders.email',
              )}
              name={['accountant', 'email']}
              rules={[{ required: true, type: 'email' }]}
            >
              <TooltipedInput placeholder="E-mail" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row justify="end" gutter={[0, 16]}>
              <Col className={styles.docsList} span={24}>
                <Typography.Title level={4}>
                  Прикрепленные документы:
                </Typography.Title>
                {attachmentDtos?.length ? (
                  <DocsList documents={attachmentDtos} />
                ) : (
                  <Mask maskText="Нет прикрепленных документов" />
                )}
              </Col>
              <Col>
                <Row gutter={16}>
                  <Col>
                    <Form.Item name={['accountant', 'attachmentsList']}>
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        onClick={() => {
                          setDefaultDocType('паспорт');
                          setShow(true);
                        }}
                      >
                        {t(
                          'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                        )}
                        *
                      </DefaultButton>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={['accountant', 'attachmentsList']}>
                      <DefaultButton
                        type="default"
                        onClick={() => setShow(true)}
                        icon={<UploadFileIcon />}
                      >
                        {t(
                          'createLegalWaterRegistry.secondStep.placeholders.another',
                        )}
                      </DefaultButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomCard>
      <UploadModal
        setShow={setShow}
        show={show}
        onLoad={onAttachmentUpload}
        preSelectedDocType={defaultDocType}
      />
    </>
  );
};
