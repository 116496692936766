import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SourceState = {
  isCreateSourceModalOpen: boolean;
  selectedRegionId: number | null;
  selectedBassinId: number | null;
};

const initialState: SourceState = {
  isCreateSourceModalOpen: false,
  selectedBassinId: null,
  selectedRegionId: null,
};

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    setShowCreateSourceModalAction: (state, action: PayloadAction<boolean>) => {
      state.isCreateSourceModalOpen = action.payload;
      return state;
    },
    setSelectedRegionIdAction: (state, action: PayloadAction<number>) => {
      state.selectedRegionId = action.payload;
      return state;
    },
    setSelectedBassinIdAction: (state, action: PayloadAction<number>) => {
      state.selectedBassinId = action.payload;
      return state;
    },
  },
});

export const {
  setShowCreateSourceModalAction,
  setSelectedBassinIdAction,
  setSelectedRegionIdAction,
} = sourceSlice.actions;

export default sourceSlice.reducer;
