import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';

export const extractObjectTypes = (
  objectCategories: Array<ObjectCategory>,
  objectCategoryId: number,
) => {
  const objectCategory = objectCategories.find(
    (item: ObjectCategory) => item.categoryId === objectCategoryId,
  );
  if (objectCategory) {
    const types = objectCategory?.objectTypes?.jsonArrayColumn;
    return types.map((item: { name: string }) => ({
      ...item,
      label: item.name,
      value: item.name,
    }));
  }
  return [];
};
