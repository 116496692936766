import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { CreateButton, SectionTitle } from '@features/ui-kit-v2';
import { IAnnualPlanFilters } from '@models/annualPlan/enums/AnnualPlan';
import { isEmpty } from '@utils/utils';

import { AnnualPlanYearlyTableContainer } from './annualPlanYearlyTableContainer/AnnualPlanYearlyTableContainer';
import { AnnualPlanYearlyTableFilters } from './annualPlanYearlyTableFilters/AnnualPlanYearlyTableFilters';
import { useAnnualPlanYearlyTableFilters } from './useAnnualPlanYearlyTableFilters';

export const AnnualPlanYearlyTable = () => {
  const [params] = useSearchParams();
  const { setAnnualPlanFilters } = useAnnualPlanYearlyTableFilters();

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setAnnualPlanFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
          } as unknown as IAnnualPlanFilters)
        : (newFilters as unknown as IAnnualPlanFilters),
    );
  }, []);

  return (
    <>
      <Row gutter={12} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={20}>
          <SectionTitle background="cyan" title="Годовой план" />
        </Col>
        <Col span={4}>
          <CreateButton iconType="print">Печать</CreateButton>
        </Col>
      </Row>
      <AnnualPlanYearlyTableFilters />
      <AnnualPlanYearlyTableContainer />
    </>
  );
};
