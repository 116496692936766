import { createSelector } from '@reduxjs/toolkit';
import { IManageEventsState } from '@store/slices';

type WithManageEventsState = {
  manageEvents: IManageEventsState;
};

export const manageEventsStateSelector = (
  state: WithManageEventsState,
): IManageEventsState => state.manageEvents;

export const getIsShowSubmitImplementationTermModalShownSelector =
  createSelector(manageEventsStateSelector, (manageEventsState) => {
    return manageEventsState.isShowSubmitImplementationTermModalShown;
  });

export const getSelectedImplementationTermSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.selectedImplementationTerm;
  },
);

export const getOperationalBlockSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.operationalBlock;
  },
);

export const getBuildingBlockSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.buildingBlock;
  },
);
export const getRegularEventBlockSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.regularEventBlock;
  },
);

export const getOtherTaskBlockSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.otherTaskBlock;
  },
);

export const getIsShowCreateTemplateModalSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.isShowCreateTemplateModal;
  },
);

export const getIsShowAttachDocumentWithoutNotationModalSelector =
  createSelector(manageEventsStateSelector, (manageEventsState) => {
    return manageEventsState.isShowAttachDocumentWithoutNotationModal;
  });

export const getIsShowAttachDocumentModalSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.isShowAttachDocumentModal;
  },
);

export const getIsShowDocumentTypeAndFormatModalSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.isShowDocumentTypeAndFormatModal;
  },
);

export const getIsShowAssignExecutorModalSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.isShowAssignExecutorModal;
  },
);

export const getDocTypeSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.docType;
  },
);

export const getCurrentTableOptionsSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.tableOptions;
  },
);

export const getCurrentGanttOptionsSelector = createSelector(
  manageEventsStateSelector,
  (manageEventsState) => {
    return manageEventsState.ganttOptions;
  },
);
