import { FC, useMemo, useState } from 'react';

import { Col, Form, Row } from 'antd';

import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';

import { useWaterObjectModals } from '../../modals/useWaterObjectModals';
import { useWearSumTableColumns } from './useWearSumTableColumns';
import { PrimaryButton, TableCustom } from '@features/ui-kit';
import { useDispatch } from 'react-redux';
import { waterObjectActions } from '@store/slices';
import { IWearDto } from '@models/waterObject/interfaces/wearDto';

export const WearSumTable: FC = () => {
  const form = Form.useFormInstance();
  const [dataSource, setDataSource] = useState<any>({});
  const systemObject = form.getFieldsValue(true);
  const { columns } = useWearSumTableColumns();
  const { setShowModal } = useWaterObjectModals(WaterMainModalTypes.WEAR_SUM);
  const onHandleEdit = (val: any) => {
    setShowModal(true, val);
  };

  const deleteItem = (val: any) => {
    const values = form.getFieldsValue(true);
    const currentWorks = values.wearDtos || [];
    const newDataSource = {
      wearDtos: currentWorks.reduce((acc: IWearDto[], work: IWearDto) => {
        if (val?.customId && work?.customId === val?.customId) {
          console.log('val', val);
          console.log('work', work);
          return acc;
        }

        if (val?.id && work?.id === val?.id) {
          acc.push({ ...work, deleted: true });
        } else {
          acc.push(work);
        }

        return acc;
      }, []),
    };

    form.setFieldsValue(newDataSource);

    setDataSource(newDataSource);
  };

  const filteredDataSource = useMemo(() => {
    const newSystemObject = form.getFieldsValue(true);
    return newSystemObject?.wearDtos?.filter((item: any) => !item.deleted);
  }, [dataSource, systemObject]);

  return (
    <Row gutter={[0, 10]} style={{ height: '100%' }}>
      <PrimaryButton onClick={() => setShowModal(true)}>Добавить</PrimaryButton>
      <Col>
        <TableCustom
          bordered
          columns={columns}
          dataSource={filteredDataSource || []}
          pagination={false}
          scroll={{ y: 200 }}
          actionProps={{
            onHandleEdit,
            deleteItem,
          }}
        />
      </Col>
    </Row>
  );
};
