import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, notification, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  AttachDocumentModal,
  AttachDocumentWithoutDescriptionModal,
  CreateTemplateModal,
} from '@features/manageEvents';
import { DocumentsCard } from '@features/manageEvents/cards/operationalCard/DocumentsCard';
import { RealizationCard } from '@features/manageEvents/cards/operationalCard/RealizationCard';
import { AssignExecutorModal } from '@features/manageEvents/modals/AssignExecutorModal';
import { SubmitImplementationTermModal } from '@features/manageEvents/modals/submitImplementationTerm/SubmitImplementationTermModal';
import {
  ColorBlock,
  DangerButton,
  DefaultButton,
  Divider,
  ElementColor,
  InformationalBlock,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindOperationalBlockByIdQuery,
  useOperationalBlockAttachmentsUploadMutation,
  useSaveTaskOperationalBlockMutation,
} from '@store/gisproApi';
import { getOperationalBlockSelector } from '@store/selectors';
import {
  addDocumentToOperationalBlockAction,
  clearOperationalBlock,
  setOperationalBlockAction,
} from '@store/slices';
import { toInteger, isInteger } from '@utils/utils';

import styles from './CreateOperationalCardLayout.module.scss';
import {
  CloseOperationalReportCard,
  CreateOperationalCard,
  CreateReportOperationalCard,
  HeaderOperationalCard,
} from '@features/manageEvents/cards/operationalCard';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const CreateOperationalCardLayout: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const operationalBlock = useSelector(getOperationalBlockSelector);
  const [saveOperationalBlock] = useSaveTaskOperationalBlockMutation();
  const [attachmentsUpload] = useOperationalBlockAttachmentsUploadMutation();

  const { data: operational } = useFindOperationalBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const attachDocument = useCallback(
    (file: IAttachment) => {
      if (id && isInteger(parseInt(id, 10))) {
        attachmentsUpload({
          attachments: [{ ...file, id: file.attachmentId }],
          id,
        })
          .unwrap()
          .then(() => {
            dispatch(addDocumentToOperationalBlockAction(file));
          });
      }
    },
    [id],
  );

  const { setHeaderTitle } = useHeaderTitle();

  const onCancelClick = () => {
    navigate(Routes.ManageEvents);
  };

  const saveTemplate = (values: any) => {
    const {
      categoryId,
      regionId,
      inspectionDate,
      priority,
      systemObjectId,
      taskDescription,
      title,
      executor,
      attachments,
      systemId,
      districtId,
      workTypeId,
    } = operationalBlock;
    saveOperationalBlock({
      categoryId,
      regionId,
      inspectionDate,
      priority,
      systemObjectId,
      taskDescription,
      title,
      ...values,
      isTemplate: true,
      executorId: toInteger(executor?.id),
      attachments: attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      systemId: toInteger(systemId),
      districtId: toInteger(districtId),
      workTypeId: toInteger(workTypeId),
    })
      .unwrap()
      .then((response: any) => {
        notification.success({
          message: 'Успешно добавлено в шаблоны',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Не удалось добавить в шаблоны',
        });
      });
  };

  useEffect(() => {
    setHeaderTitle('Карточка эксплуатационного блока');
    return () => {
      dispatch(clearOperationalBlock());
    };
  }, [id]);

  useEffect(() => {
    if (operational) {
      dispatch(setOperationalBlockAction(operational));
    }
  }, [operational]);

  return (
    <ColorBlock colorType={ElementColor.default}>
      <Layout>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={16}>
              <Col>
                <DefaultButton onClick={() => navigate(-1)} className="backBtn">
                  <LeftOutlined />
                  <Typography.Text>Назад</Typography.Text>
                </DefaultButton>
              </Col>
              <Col>
                <Breadcrumb
                  className="breadcrumb"
                  items={[
                    {
                      title: <HomeOutlined />,
                    },
                    {
                      title: 'Карточка эксплуатационного блока',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider style={{ margin: '6px 0 8px' }} />
          </Col>
          <Col className={styles.divider} span={24}>
            <HeaderOperationalCard />
            <Divider />
          </Col>
          <Col span={24}>
            <InformationalBlock>
              <Row gutter={10}>
                <Col span={12} className={styles.cardWrapper}>
                  <CreateOperationalCard />
                  <DocumentsCard />
                </Col>
                <Col span={12} className={styles.cardWrapper}>
                  <CreateReportOperationalCard />
                  <RealizationCard />
                  <CloseOperationalReportCard />
                </Col>
              </Row>
            </InformationalBlock>
          </Col>
          <Col span={24}>
            <DangerButton size="large" onClick={onCancelClick}>
              Отмена
            </DangerButton>
          </Col>
        </Row>
      </Layout>
      <AttachDocumentModal onLoad={attachDocument} />
      <CreateTemplateModal save={saveTemplate} />
      <AttachDocumentWithoutDescriptionModal onLoad={attachDocument} />
      <AssignExecutorModal type={ManageEventsWorkTypes.OPERATIONAL} />
      <SubmitImplementationTermModal />
    </ColorBlock>
  );
};
