import React from 'react';

import { Radio, RadioGroupProps } from 'antd';

type IRadioChangeEventProps = RadioGroupProps & {
  groupButtons: Array<{
    label: string;
    value: string;
  }>;
};
export const RadioGroup: React.FC<IRadioChangeEventProps> = ({
  groupButtons,
  ...props
}) => {
  return (
    <Radio.Group {...props}>
      {groupButtons.map((item) => (
        <Radio key={item.value} value={item.value}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
