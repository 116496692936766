import { toInteger } from '@utils/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, notification, Row, Typography } from 'antd';

import { MonthIndex } from '@constants/month';
import { MonthEnums } from '@enums/month';
import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { PrintIcon } from '@icons/PrintIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetWateringPlanDecadalRequestQuery,
  useSaveWateringPlanDecadalRequestMutation,
  useSendRequestToDecadalMutation,
} from '@store/gisproApi';
import { getDecadeAppFiltersSelector } from '@store/selectors/decadeApp';
import dayjs from 'dayjs';
import { get } from '@utils/utils';
import { useDecadeApplicationPrintModal } from '@features/decadaApp/modals/useDecadeApplicationPrintModal';
import {
  useDecadeApplicationTableColumns
} from '@features/decadaApp/decadeApplicationTable/useDecadeApplicationTableColumns';
import {
  mapDecadeApplicationTableData
} from '@features/decadaApp/decadeApplicationTable/mapDecadeApplicationTableData';
import { DecadeApplicationPrintModal } from '@features/decadaApp/modals/decadeApplicationPrintModal';

export const DecadeApplicationTable = () => {
  const { t } = useTranslation();
  const filters = useSelector(getDecadeAppFiltersSelector);
  const { setShowModal } = useDecadeApplicationPrintModal();
  const [sendToRequest] = useSendRequestToDecadalMutation();

  const [saveWateringPlan] = useSaveWateringPlanDecadalRequestMutation();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetWateringPlanDecadalRequestQuery(
    filters.month && filters.year
      ? {
          month: filters.month,
          year: dayjs(filters.year).get('years'),
        }
      : skipToken,
  );
  const { columns } = useMemo(
    () =>
      useDecadeApplicationTableColumns({
        month: t(`months.${filters.month || MonthEnums.January}`),
      }),
    [filters.month],
  );

  const { data: dataSource } = useMemo(
    () =>
      mapDecadeApplicationTableData({
        data,
        month: MonthIndex[filters.month || 0] + 1,
      }),
    [data],
  );

  const getCellKey = (record: JsonRecord, column: TableColumn) => {
    if (column.editableCell && column.decimalIndex) {
      const decadeId = get(record, ['decadeDtos', column.decimalIndex[1], 'id']);

      return `${decadeId}-${column.dataIndex}`;
    }
    return -1;
  };
  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const { decimalIndex, dataIndex } = column;
    const slicedDecimalIndex = decimalIndex?.slice(0, -1);
    const getId = get(record, [...(slicedDecimalIndex || []), 'id']);
    const getValue = get(record, [...(decimalIndex || []), dataIndex]);
    saveWateringPlan([{ id: getId, value: getValue }])
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
    console.log('column', column);
  };

  const onEditSaveClick = (record: any) => {
    console.log('record', record);
  };

  const onHeaderActionClick = (customColumn: TableColumn) => {
    const { children } = customColumn;

    if (children) {
      const decimalIndex = children[0]?.decimalIndex?.[1];

      if (decimalIndex !== undefined) {
        const numericIds = data.flatMap((obj) =>
          obj.decadeDtos
            .filter((item) => {
              return (
                typeof item.id === 'number' &&
                item.decade === toInteger(decimalIndex)
              );
            })
            .map((item) => item.id),
        );

        sendToRequest(numericIds);
      }
    }
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>
              Оперативный план водополива
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton
              onClick={() => setShowModal(true)}
              icon={<PrintIcon />}
            />
          </Col>
        </Row>
      }
    >
      <TableCustom
        bordered
        size="small"
        columns={columns}
        dataSource={dataSource}
        rowKey={(row) => row.index}
        loading={isLoading || isFetching}
        cellKey={getCellKey}
        actionProps={{
          onEditDecimalSaveClick,
          onEditSaveClick,
          onHeaderActionClick,
        }}
      />

      <DecadeApplicationPrintModal />
    </CustomCard>
  );
};
