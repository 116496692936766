import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Radio, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import {
  PrimaryButtonV2,
  SectionTitle,
  TooltipedDatePickerV2,
  TooltipedYearPickerV2,
} from '@features/ui-kit-v2';
import { IAssociationIndicatorReports } from '@models/associationIndicatorReports/interfaces/associationIndicatorReports';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateAssociationInitialDataMutation,
  useFindAllByOrganizationIdQuery,
  useUpdateAssociationInitialDataMutation,
} from '@store/gisproApi';
import dayjs from 'dayjs';
import { toInteger } from '@utils/utils';

import { AreasAndLands } from './areasAndLands';
import styles from './EnteringReportingIndicatorsForm.module.scss';
import { FinancialIndicators } from './financialIndicators';
import { GeneralIndicators } from './generalIndicators';

export const EnteringReportingIndicatorsForm: React.FC = () => {
  const [form] = useForm();
  const { associationId } = useParams();

  const [createInitialData] = useCreateAssociationInitialDataMutation();
  const [updateInitialData] = useUpdateAssociationInitialDataMutation();
  const { data: association } = useFindAllByOrganizationIdQuery(
    associationId
      ? {
          id: toInteger(associationId),
        }
      : skipToken,
  );
  const onFinish = (values: IAssociationIndicatorReports) => {
    const { associationId: id, ...restValues } = values;
    createInitialData({
      associationId: toInteger(association?.organizationId),
      ...restValues,
    });
  };

  const year = Form.useWatch('year', form);
  const quarter = Form.useWatch('quarter', form);

  useEffect(() => {
    form.setFieldsValue(association?.initialDataAssociation);
  }, [associationId]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <SectionTitle title="Ввод основных показателей" background="cyan" />
      <Space size="large" direction="vertical" className={styles.yearInfo}>
        <Row>
          <Col>
            <Form.Item name="year">
              <TooltipedYearPickerV2
                disabledDate={(current) =>
                  current && current > dayjs().endOf('year')
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name="quarter">
              <Radio.Group disabled={!year}>
                <Radio.Button value={1}>1 квартал</Radio.Button>
                <Radio.Button value={2}>2 квартал</Radio.Button>
                <Radio.Button value={3}>3 квартал</Radio.Button>
                <Radio.Button value={4}>4 квартал</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Space>
      <Space
        size="large"
        direction="vertical"
        className={!quarter ? styles.disabled : ''}
      >
        <GeneralIndicators />
        <FinancialIndicators />
        <AreasAndLands />
      </Space>
      <Row justify="end" className={styles.sbmBtn}>
        <Form.Item>
          <PrimaryButtonV2 htmlType="submit" title="Сохранить" />
        </Form.Item>
      </Row>
    </Form>
  );
};
