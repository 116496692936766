import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const ToolsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4486_157491)">
        <path
          d="M10.3333 12.1835L12.3908 10.1259L18.2074 15.9425L16.1499 18L10.3333 12.1835ZM13.9391 7.16626C15.8113 7.16626 17.3343 5.64325 17.3343 3.77101C17.3343 3.20837 17.1791 2.68453 16.9366 2.2189L14.3174 4.83809L12.872 3.39268L15.4912 0.773495C15.0256 0.530978 14.5017 0.375766 13.9391 0.375766C12.0668 0.375766 10.5438 1.89878 10.5438 3.77101C10.5438 4.16874 10.6214 4.54707 10.7476 4.89629L8.95292 6.69092L7.22619 4.9642L7.91494 4.27545L6.54715 2.90765L8.60369 0.851101C8.05803 0.306114 7.31835 0 6.54715 0C5.77594 0 5.03626 0.306114 4.4906 0.851101L1.05655 4.28515L2.42435 5.65295H-0.31125L-1 6.3417L2.43405 9.77575L3.1228 9.087V6.3417L4.4906 7.7095L5.17935 7.02075L6.90607 8.74747L-0.282148 15.9357L1.7744 17.9922L12.8138 6.96254C13.163 7.08865 13.5414 7.16626 13.9391 7.16626Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4486_157491">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
