import { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Col, Divider, Row } from 'antd';

import { CustomModal, PrimaryButton } from '@features/ui-kit';
import {
  IRRIGATION_FUND_MENU_BUTTONS,
  MAIN_MENU_BUTTONS,
  QUALITATIVE_INDICATORS_BUTTONS,
  SEARCH_PARAMS_KEY,
  WATER_OUTLET_MENU_BUTTONS,
} from '@pages/sppr/constants';
import { SpprMenuKey } from '@pages/sppr/types/spprTypes';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';

interface ISpprMenuProps {
  open: boolean;
  onCancel(): void;
}

export const SpprMenu: FC<ISpprMenuProps> = ({ open, onCancel }) => {
  const [params, setParams] = useSearchParams();
  const [currentType, setCurrentType] = useState<SpprMenuKey | null>(null);

  const handleButtonClick = (type: SpprMenuKey) => {
    setCurrentType(type);
    if (
      type !== SpprMenuKey.irrigationFund &&
      type !== SpprMenuKey.waterOutlet &&
      type !== SpprMenuKey.qualitativeIndicators
    ) {
      onCancel();
    }
  };

  const dynamicOptions = (arr: any[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.PLANNING_SYSTEM_MAP_VIEW,
      ACCESS.PLANNING_SYSTEM_WATER_USE_VIEW,
      ACCESS.PLANNING_SYSTEM_QUALITY_VIEW,
      ACCESS.PLANNING_SYSTEM_RESERVOIRS_VIEW,
      ACCESS.PLANNING_SYSTEM_MONITORING_VIEW,
      ACCESS.PLANNING_SYSTEM_BALANCE_WATER_VIEW,
      ACCESS.PLANNING_SYSTEM_BALANCE_SHEET_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  const menuButtons = useMemo(() => {
    if (currentType === SpprMenuKey.irrigationFund) {
      return IRRIGATION_FUND_MENU_BUTTONS;
    }
    if (currentType === SpprMenuKey.qualitativeIndicators) {
      return QUALITATIVE_INDICATORS_BUTTONS;
    }
    if (currentType === SpprMenuKey.waterOutlet) {
      return WATER_OUTLET_MENU_BUTTONS;
    }
    if (currentType) {
      params.set(SEARCH_PARAMS_KEY, currentType);
      setParams(params);
    }
    return MAIN_MENU_BUTTONS;
  }, [currentType]);

  const menuConfig = useMemo(() => {
    let title = 'Меню';
    let span = 6;
    if (currentType === SpprMenuKey.irrigationFund) {
      title += ': Ирригационный фонд';
      span = 8;
    }
    if (currentType === SpprMenuKey.qualitativeIndicators) {
      title += ': Качественные показатели';
      span = 8;
    }
    if (currentType === SpprMenuKey.waterOutlet) {
      title += ': Водопользование';
      span = 8;
    }
    return { title, span };
  }, [currentType]);

  return (
    <CustomModal
      width={1024}
      footer={null}
      open={open}
      onCancel={onCancel}
      title={menuConfig.title}
    >
      <Divider />
      <Button
        disabled={menuConfig.title === 'Меню'}
        style={{ marginBottom: 10 }}
        onClick={() => setCurrentType(null)}
      >
        &lt; Назад
      </Button>
      <Row justify="center" gutter={[8, 8]}>
        {dynamicOptions(menuButtons).map((btn) => (
          <Col key={btn.key} span={menuConfig.span}>
            <PrimaryButton
              style={{ width: '100%', height: 200 }}
              onClick={() => handleButtonClick(btn.key)}
            >
              {btn.label}
            </PrimaryButton>
          </Col>
        ))}
      </Row>
    </CustomModal>
  );
};
