import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { Card, Col, Layout, Row } from 'antd';

import { Links } from '@enums/links';
import { CarouselComponent } from '@features/authentication';

import styles from './AuthenticationLayout.module.css';

export const AuthenticationLayout: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Layout className={styles.layout}>
      <Row>
        <Col span={10}>
          <div className={styles.title}>
            <img src={Links.Logo} alt={t('logo-placeholder') || ''} />
          </div>
          <div className={styles.modal}>
            <Card bodyStyle={{ padding: 0 }} className={styles.card}>
              <div className={styles.titleBlock}>
                <div className={styles.headerBlock}>
                  <h2 className={styles.header}>
                    {t(`authentication.${pathname.replace('/', '')}.header`)}
                  </h2>
                </div>
                <div className={styles.headerBlock}>
                  <span className={styles.subheader}>
                    {t(`authentication.${pathname.replace('/', '')}.subheader`)}
                  </span>
                </div>
              </div>
              <Outlet />
            </Card>
          </div>
        </Col>
        <Col span={14}>
          <CarouselComponent />
        </Col>
      </Row>
    </Layout>
  );
};
