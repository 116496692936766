import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDocTypeSelector,
  getIsShowAttachDocumentModalSelector,
} from '@store/selectors';
import {
  setDocTypeAction,
  setShowAttachDocumentModalAction,
} from '@store/slices';

export const useAttachDocumentModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsShowAttachDocumentModalSelector);
  const docType = useSelector(getDocTypeSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(setShowAttachDocumentModalAction(state));
  }, []);

  const setDocType = useCallback((documentType: string) => {
    dispatch(setDocTypeAction(documentType));
  }, []);

  return { isShow, setShow, docType, setDocType };
};
