import React from 'react';
import { useSelector } from 'react-redux';

import { DocumentTable } from '@features/manageEvents';
import { getOtherTaskBlockSelector } from '@store/selectors';

export const DocumentsCard = () => {
  const otherTaskBlock = useSelector(getOtherTaskBlockSelector);

  return (
    <DocumentTable
      type="otherTask"
      attachments={otherTaskBlock?.attachments || []}
    />
  );
};
