import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useFindAllIrrigationSystemsOwnersQuery,
  useFindDictionaryByTypeIdQuery,
  useUpdateIrrigationSystemMutation,
} from '@store/gisproApi';
import { Col, Divider, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

interface IPassportDetailsModalProps {
  setShowModal: (value: boolean) => void;
  systemData: any;
}

export const PassportDetailsModal: FC<IPassportDetailsModalProps> = ({
  setShowModal,
  systemData,
}) => {
  const [updateIrrigationSystem] = useUpdateIrrigationSystemMutation();
  const { data: systemTypes = [], isLoading: systemTypesIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.IrrigationSystemsType,
    });

  const [form] = Form.useForm();

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: any) => {
    systemData?.systemId &&
      updateIrrigationSystem({ ...values, systemId: systemData?.systemId });

    setShowModal(false);
  };

  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const initialValues = {
    name: systemData?.name,
    regNum: systemData?.regNum,
    systemTypeId: systemData?.systemTypeId,
    dateDesign: dayjs(systemData?.dateDesign),
    residualValue: systemData?.residualValue,
    wearSum: systemData?.wearSum,
    builtProject: systemData?.builtProject,
  };
  return (
    <CustomModal
      centered
      open
      onCancel={onCancel}
      width={830}
      title={'Паспортные данные'}
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={'Наименование системы'}
              rules={[{ required: true }]}
            >
              <TooltipedInput placeholder="Наименование системы" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="regNum"
              label="Регистрационный номер"
              rules={[{ required: true }]}
            >
              <TooltipedInput
                placeholder="Регистрационный номер"
                disabled
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="systemTypeId" label="Тип системы">
              <TooltipedSelect
                size="large"
                placeholder="Тип системы"
                loading={systemTypesIsLoading}
                options={systemTypes}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="residualValue"
              label="Остаточная балансовая стоим. (сом)"
            >
              <TooltipedInput
                placeholder="Остаточная балансовая стоим. (сом)"
                size="large"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="dateDesign" label="Год ввода в эксплуатацию">
              <TooltipedDatePicker format="YYYY" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="builtProject" label="Построен по проекту">
              <TooltipedInput placeholder="Построен по проекту" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="wearSum" label="Сумма износа (сом)">
              <TooltipedInput
                type="number"
                placeholder="Сумма износа (сом)"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
