import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { CustomCard, DefaultButton, StatisticCard } from '@features/ui-kit';
import { PeopleStar } from '@icons/PeopleStar';
import { SchemeIcon } from '@icons/SchemeIcon';
import { TwoPeople } from '@icons/TwoPeople';
import { WaterUserIcon } from '@icons/WaterUserIcon';
import { useGetWaterUsersCounterQuery } from '@store/gisproApi';

import styles from './WaterUserCard.module.scss';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { saveUrl } from '@utils/returnUrl';

export const WaterUserCard: React.FC = () => {
  const { data } = useGetWaterUsersCounterQuery({});
  const navigate = useNavigate();
  const saveAction = saveUrl();

  const hasPermission = useHasUserPermission(ACCESS.WATER_USER_VIEW);

  const clickGoto = useCallback(() => {
    saveAction();
    navigate(Routes.WaterRegistryUsers);
  }, []);

  return (
    <CustomCard
      title={
        <Row align="middle" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Реестр водопользователей
            </Typography.Title>
          </Col>
          {hasPermission ? (
            <Col>
              <DefaultButton onClick={clickGoto}>Перейти</DefaultButton>
            </Col>
          ) : null}
        </Row>
      }
    >
      <Row gutter={10} className={styles.cardStats}>
        <Col span={6}>
          <StatisticCard
            title="Всего (шт)"
            count={data?.total || 0}
            icon={<WaterUserIcon />}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            title="Ассоциации (шт)"
            count={data?.associations || 0}
            icon={<SchemeIcon />}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            title="Водопользователи ассоциаций (шт)"
            count={data?.associationWaterUsers || 0}
            icon={<PeopleStar />}
          />
        </Col>
        <Col span={6}>
          <StatisticCard
            title="Другие (шт)"
            count={data?.others || 0}
            icon={<TwoPeople />}
          />
        </Col>
      </Row>
    </CustomCard>
  );
};
