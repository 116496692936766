import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDocTypeSelector,
  getIsShowAttachDocumentWithoutNotationModalSelector,
} from '@store/selectors';
import {
  setDocTypeAction,
  setShowAttachDocumentWithoutNotationModalAction,
} from '@store/slices';

export const useAttachDocumentWithoutNotationModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(
    getIsShowAttachDocumentWithoutNotationModalSelector,
  );
  const docType = useSelector(getDocTypeSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(setShowAttachDocumentWithoutNotationModalAction(state));
  }, []);

  const setDocType = useCallback((documentType: string) => {
    dispatch(setDocTypeAction(documentType));
  }, []);

  return { isShow, setShow, docType, setDocType };
};
