import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { skipToken } from '@reduxjs/toolkit/query';
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFindAllByOrganizationIdQuery } from '@store/gisproApi';
import { Routes } from '@enums/routes';
import { Collapse, Row, Space, Typography } from 'antd';
import { CardDataItem } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/cardData/CardDataItem';
import styles from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/cardData/CardData.module.scss';
import { waterUserConvert } from '@constants/waterUser';
import { PrimaryButton } from '@features/ui-kit';
import { CardIcon } from '@icons/CardIcon';

export const WaterUserInfo = () => {
  const navigate = useNavigate();
  const { currentAttribute } = useContext(MapContext);

  const { data: waterUser } =
    useFindAllByOrganizationIdQuery(currentAttribute ? { id: currentAttribute.id } : skipToken);

  const toShowCard = () => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(waterUser?.id),
      }),
    );
  };

  const collapseOutlets = waterUser?.outlets?.map((outlet: any) => {
    return {
      key: outlet.id,
      label: outlet.name,
      children: (
        <Space size={5} direction="vertical">
          <CardDataItem title="Наименование водовыпуска" value={outlet?.name} />
          <CardDataItem
            title="Оросительная система"
            value={outlet?.irrigationSystem?.name || '-'}
          />
          <CardDataItem title="Канал" value={outlet?.sections?.systemObject?.name || '-'} />
          <CardDataItem title="Общая площадь Га" value={outlet?.totalArea || '-'} />
          <CardDataItem title="КПД" value={outlet.kpd || '-'} />
        </Space>
      ),
    };
  });

  return (
    <>
      <Space size={8} direction="vertical" className={styles.content}>
        <CardDataItem
          title="Наименование организации:"
          value={waterUser?.legalData?.name ?? '-'}
        />
        <CardDataItem
          title="Вид деятельности организации:"
          value={waterUser?.legalData.businessActivity?.title ?? '-'}
        />
        <CardDataItem
          title="Тип ассоциации (АВП итд):"
          value={waterUser?.legalData.associationType?.title ?? '-'}
        />
        <Typography.Text>Водовыпуски</Typography.Text>
        <Collapse bordered={false} items={collapseOutlets} />
      </Space>
      <Row justify="center" className={styles.buttonWrapper}>
        <PrimaryButton onClick={toShowCard} icon={<CardIcon />}>
          Карточка
        </PrimaryButton>
      </Row>
    </>
  );
}
