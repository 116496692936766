import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Button, Col, Layout, Row } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { HomeOutlined } from '@ant-design/icons';
import { SectionQueryKey } from '@constants/queryKeys';
import { TariffTable } from '@features/tariff';
import { TariffMonitoringTable } from '@features/tariff/tariffTables/tariffMonitoringTable';
import { Divider, Tabs } from '@features/ui-kit';
import { CustomSegmented } from '@features/ui-kit-v2';
import { TariffTableQueryTypes } from '@models/tariff/enums/tariffStatusEnum';
import { t } from 'i18next';

import styles from './TariffLayout.module.scss';

interface optionsItem {
  key: string;
  label: string;
}

export const TariffLayout: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const queryKey = useMemo(
    () => params.get(SectionQueryKey) || TariffTableQueryTypes.tariffSchedule,
    [params],
  );

  const options: optionsItem[] = [
    {
      key: TariffTableQueryTypes.tariffSchedule,
      label: 'Тарифная сетка',
    },
    // {
    //   key: TariffTableQueryTypes.tariffMonitoring,
    //   label: 'Мониторинг тарифов',
    // },
  ];

  let Component;
  switch (queryKey) {
    case TariffTableQueryTypes.tariffSchedule:
      Component = TariffTable;
      break;
    case TariffTableQueryTypes.tariffMonitoring:
      Component = TariffMonitoringTable;
      break;
    default:
      Component = TariffTable;
      break;
  }

  const onTableTypeChange = (value: SegmentedValue) => {
    const searchParams = new URLSearchParams({
      [SectionQueryKey]: value as string,
      page: '1',
      size: '10',
    });
    setSearchParams(searchParams);
  };

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('Тариф'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      {/*<Tabs*/}
      {/*  items={options}*/}
      {/*  defaultValue={TariffTableQueryTypes.tariffSchedule}*/}
      {/*  onChange={onTableTypeChange}*/}
      {/*/>*/}
      <Component />
    </Layout>
  );
};
