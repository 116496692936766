import { TableCustom } from '@features/ui-kit';
import { TableCustomV2 } from '@features/ui-kit-v2';
import {
  useCreateMonitoringPointsModal
} from '@features/waterQuality/tables/monitoringPointsTable/createMonitoringPointsModal/useCreateMonitoringPointsModal';
import { useMonitoringPointsTableFilters } from '@features/waterQuality/tables/monitoringPointsTable/useMonitoringPointsTableFilters';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindAllLegalPersonQuery,
  useGetPointsQuery,
} from '@store/gisproApi';
import { useDispatch } from 'react-redux';

import { useMonitoringPointsTableColumns } from './useMonitoringPointsTableColumns';

export const MonitoringPointsTableContainer: React.FC = () => {
  const { columns } = useMonitoringPointsTableColumns();
  const { filters } = useMonitoringPointsTableFilters();
  const dispatch = useDispatch();
  const { isModalShown, setShowModal, gtsPoint, setGtsPoint } = useCreateMonitoringPointsModal();

  const onHandleEdit = (val: any) => {
    setGtsPoint(val);
    setShowModal(true);
  };

  const {
    data: content = [],
    isLoading,
    isFetching,
  } = useGetPointsQuery(filters as any);
  return (
    <TableCustom
      loading={isLoading || isFetching}
      dataSource={content}
      columns={columns}
      size="small"
      // onChange={onTableChange}
      // totalCount={totalElements}
      // customPagination={rest}
      actionProps={{
        onHandleEdit: onHandleEdit,
      }}
    />
  );
};
