import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { AppDispatch } from '@store/index';
import {
  getShowMainWaterConstuctorStatsSelector,
  getShowMainWaterSizeSelector,
  getShowMainWaterTechStatsSelector,
  getShowMainWaterWearSumSelector,
  getShowMainWaterWorkMarkSelector,
  getShowMainWaterWorkVolumeSelector,
  getInitialValuesWearSumSelector,
  getInitialValuesWorkMarkSelector,
} from '@store/selectors/waterObject';
import { waterObjectActions } from '@store/slices';

export const useWaterObjectModals = (type: WaterMainModalTypes) => {
  let isModalShown;
  let initialValues;

  switch (type) {
    case WaterMainModalTypes.VOLUME:
      isModalShown = useSelector(getShowMainWaterWorkVolumeSelector);
      break;
    case WaterMainModalTypes.MARK:
      isModalShown = useSelector(getShowMainWaterWorkMarkSelector);
      initialValues = useSelector(getInitialValuesWorkMarkSelector);
      break;
    case WaterMainModalTypes.WEAR_SUM:
      isModalShown = useSelector(getShowMainWaterWearSumSelector);
      initialValues = useSelector(getInitialValuesWearSumSelector);
      break;
    case WaterMainModalTypes.CONSTRUCTOR_STATS:
      isModalShown = useSelector(getShowMainWaterConstuctorStatsSelector);
      break;
    case WaterMainModalTypes.SIZE:
      isModalShown = useSelector(getShowMainWaterSizeSelector);
      break;
    case WaterMainModalTypes.TECH_STATS:
      isModalShown = useSelector(getShowMainWaterTechStatsSelector);
      break;
    default:
      isModalShown = useSelector(getShowMainWaterWorkVolumeSelector);
      break;
  }
  const dispatch = useDispatch<AppDispatch>();

  const setShowModal = useCallback(
    (val: boolean, initialValues: any = null) => {
      switch (type) {
        case WaterMainModalTypes.VOLUME:
          dispatch(waterObjectActions.setShowWorkVolume(val));
          break;
        case WaterMainModalTypes.MARK:
          dispatch(waterObjectActions.setInitialValuesWorkMark(initialValues));
          dispatch(waterObjectActions.setShowWorkMark(val));
          break;
        case WaterMainModalTypes.WEAR_SUM:
          dispatch(waterObjectActions.setInitialValuesWearSum(initialValues));
          dispatch(waterObjectActions.setShowWearSum(val));
          break;
        case WaterMainModalTypes.CONSTRUCTOR_STATS:
          dispatch(waterObjectActions.setShowConstructorStats(val));
          break;
        case WaterMainModalTypes.SIZE:
          dispatch(waterObjectActions.setShowSize(val));
          break;
        case WaterMainModalTypes.TECH_STATS:
          dispatch(waterObjectActions.setShowTechStats(val));
          break;
        default:
          dispatch(waterObjectActions.setShowWearSum(val));
          break;
      }
    },
    [type],
  );

  return {
    isShowModal: isModalShown,
    setShowModal,
    initialValues,
  };
};
