export const waterUsePlanTranslate: Record<string, string> = {
  planForTotalIntake: 'Водозабор из источников орош. всего',
  planOfTotalWaterSupply: 'Водоподача всего',
  planWaterSupplyForIrrigation: 'Водоподача на орошение',
  planTotalHectareIrrigation: 'Гектарополивы всего',
  planFirstWatering: 'Первый полив',
  planContractualWaterSupply: 'Хоздоговорная водоподача',
  planMoistureChargingIrrigationWs: 'Водоподача на влагозаряд. поливы',
  planMoistureChargingIrrigationHectares: 'Гектарополивы на влагозаряд. поливы',
  planForTotalIntakeGov: 'Водозабор в межхоз. сеть',
  planOfTotalWaterSupplyGov: 'Водоподача в межхоз. сеть',
  kpd: 'КПД межхоз сети',
};
