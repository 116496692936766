import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ArchivariusState = {
  archivariusIrrigationSystemFilters: Partial<IrrigationSystemFilters>;
  archivariusWaterUserFilters: Partial<IWaterRegistryFilters>;
  confirmArchiveId: number | null;
  isShowConfirmArchiveModal: boolean;
};

const initialState: ArchivariusState = {
  archivariusIrrigationSystemFilters: {
    page: 1,
    size: 10,
    isArchived: true,
  },
  archivariusWaterUserFilters: {
    page: 1,
    size: 10,
    isArchived: true,
  },
  confirmArchiveId: null,
  isShowConfirmArchiveModal: false,
};

const archivariusSlice = createSlice({
  name: 'irrigationRegistry',
  initialState,
  reducers: {
    changeArchivariusIrrigationSystemFilters: (
      state,
      action: PayloadAction<IrrigationSystemFilters>,
    ) => {
      return {
        ...state,
        archivariusIrrigationSystemFilters: action.payload,
      };
    },
    changeArchivariusWaterUserFilters: (
      state,
      action: PayloadAction<IWaterRegistryFilters>,
    ) => {
      return {
        ...state,
        archivariusWaterUserFilters: action.payload,
      };
    },
    setIdForConfirmModal: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        confirmArchiveId: action.payload,
      };
    },
    setShowConfirmArchiveModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowConfirmArchiveModal: action.payload,
      };
    },
  },
});

export const {
  changeArchivariusIrrigationSystemFilters,
  changeArchivariusWaterUserFilters,
  setIdForConfirmModal,
  setShowConfirmArchiveModal,
} = archivariusSlice.actions;

export default archivariusSlice.reducer;
