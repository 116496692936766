import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Form, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { TooltipedInput, TooltipedPassword } from '@features/ui-kit/input';
import { TooltipedSwitch } from '@features/ui-kit/switch';
import { AppDispatch } from '@store/index';
import { getAuthErrorSelector, getAuthFailedSelector } from '@store/selectors';
import { getProfile, signIn } from '@store/slices/authentication';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import styles from './Login.module.css';

type LoginFormTypes = {
  login: string;
  password: string;
  rememberme: boolean;
};

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const authError = useSelector(getAuthErrorSelector);
  const authFailed = useSelector(getAuthFailedSelector);

  const onFinish = useCallback(
    (values: LoginFormTypes) => {
      dispatch(signIn(values)).then((res: any) => {
        if (!res.error) {
          dispatch(getProfile({ login: values.login }));
          navigate(Routes.Home);
        }
      });
    },
    [dispatch],
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<LoginFormTypes>) => {
      console.log('Failed:', errorInfo);
    },
    [],
  );

  return (
    <div>
      <Form<LoginFormTypes>
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label={t('authentication.login.placeholders.login')}
          name="login"
          validateStatus={authFailed ? 'error' : ''}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TooltipedInput
            disabled={false}
            allowClear
            placeholder={t('authentication.login.placeholders.login')}
          />
        </Form.Item>

        <Form.Item
          label={t('authentication.login.placeholders.password')}
          name="password"
          validateStatus={authFailed ? 'error' : ''}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TooltipedPassword
            allowClear
            visibilityToggle
            placeholder={t('authentication.login.placeholders.password')}
          />
        </Form.Item>

        <Form.Item
          label={t('authentication.login.placeholders.rememberme')}
          name="rememberme"
          valuePropName="checked"
        >
          <TooltipedSwitch />
        </Form.Item>

        <Form.Item>
          <div className={styles.buttonsContainer}>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Войти
            </Button>
          </div>
        </Form.Item>
        {authFailed && (
          <Typography.Text type="danger">{authError}</Typography.Text>
        )}
      </Form>
    </div>
  );
};
