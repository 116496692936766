import { EntityTypesEnum } from '@enums/entityTypes';
import { useGetCurrentUser } from '@features/authentication';
import { CustomModal, DefaultButton, PrimaryButton, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreatePointMutation,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetPointTypeQuery,
  useGetReferencesByParentIdAndTypeQuery,
} from '@store/gisproApi';

import { Col, Form, Row } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React, { useEffect } from 'react';

import styles from './CreateMonitoringPointsModal.module.scss';
import { useCreateMonitoringPointsModal } from './useCreateMonitoringPointsModal';

export const CreateMonitoringPointsModal: React.FC = () => {
  const [form] = useForm();
  const regionIdWatch = useWatch('regionId', form);
  const districtIdWatch = useWatch('districtId', form);
  const { isModalShown, setShowModal, gtsPoint, setGtsPoint } = useCreateMonitoringPointsModal();
  const [createPoint] = useCreatePointMutation();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionIdWatch
      ? {
        parentId: parseInt(regionIdWatch, 10),
      }
      : skipToken,
  );

  const { data: pointType = [] } = useGetPointTypeQuery();

  const { data: okmots = [] } = useGetReferencesByParentIdAndTypeQuery(
    regionIdWatch
      ? {
        typeId: EntityTypesEnum.Okmot,
        parentId: parseInt(districtIdWatch, 10),
      }
      : skipToken,
  );

  const onFinish = () => {
    createPoint({
      ...form.getFieldsValue(),
      id: gtsPoint?.id,
    });
    form.resetFields();
    setGtsPoint(null);
    setShowModal(false);
  };

  const onRegionChange = () => {
    form.resetFields(['districtId', 'okmotId']);
  };

  const onDistrictChange = () => {
    form.resetFields(['okmotId']);
  };

  const onCloseClick = () => {
    setShowModal(false);
    form.resetFields();
    setGtsPoint(null);
  };

  useEffect(() => {
    if (gtsPoint) {
      form.setFieldsValue(gtsPoint);
    } else {
      form.resetFields();
    }
  }, [gtsPoint]);

  return (
    <CustomModal
      title={gtsPoint ? 'Редактирование точки мониторинга' : 'Создание точки мониторинга'}
      centered
      open={isModalShown}
      onCancel={onCloseClick}
      width={900}
      footer={
        <Row gutter={30} justify="end" className={styles.marginTop}>
          <Col>
            <DefaultButton onClick={onCloseClick}>Отмена</DefaultButton>
          </Col>
          <Col>
            <PrimaryButton
              htmlType="submit"
              onClick={() => form.submit()}
            >Сохранить</PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
        className={styles.form}
      >
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              label="Область"
              name="regionId"
              rules={[{ required: true }]}
              initialValue={user?.owner?.region?.id}
            >
              <TooltipedSelect
                options={regions}
                onChange={onRegionChange}
                disabled={isDisabledRegion}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Район"
              name="districtId"
              rules={[{ required: true }]}
              initialValue={user?.owner?.district?.id}
            >
              <TooltipedSelect
                options={districts}
                disabled={isDisabledDistrict || !regionIdWatch}
                onChange={onDistrictChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Айыл окмоту"
              name="okmotId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                options={okmots}
                disabled={!districtIdWatch}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              rules={[{ required: true }]}
              label="Вид точки"
              name="type"
            >
              <TooltipedSelect options={pointType}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[{ required: true }]}
              label="Наименование точки"
              name="pointName"
            >
              <TooltipedInput/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
