import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const IrrigationFundConstructionMonitoring: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="IrrigationFundConstructionMonitoring"
        src={getKibanaUrl('Ирригационный фонд: Мониторинг Строительство')}
        height="2400"
        width="100%"
      />
    </div>
  );
};
