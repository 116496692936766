import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { DefaultButton, Divider, Tabs } from '@features/ui-kit';
import { SegmentedValue } from 'antd/es/segmented';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Breadcrumb, Col, notification, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import {
  BuildingTable,
  ManageEventsOperationalTable,
  OtherTasksTable,
} from '@features/manageEvents';
import { ManageEventsWorkTypesFilter } from '@models/manageEvents/enums/manageEventsEnum';

import { useDispatch } from 'react-redux';
import { saveTableOptions } from '@store/slices';
import { ArchiveConfirmModal } from '@features/archiveConfirmModal';

import { useSelector } from 'react-redux';
import {
  getConfirmArchiveId,
  getCurrentTableOptionsSelector,
} from '@store/selectors';
import {
  useToArchiveBuildingBlockMutation,
  useToArchiveExtraTaskMutation,
  useToArchiveOperationalBlockMutation,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { ACCESS } from '@enums/permisson';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

interface optionsItem {
  key: string;
  label: string;
}

export const ManageEventScheduleLayout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { setHeaderTitle } = useHeaderTitle();
  const [title, setTitle] = useState<string>('');
  const dispatch = useDispatch();
  const confirmArchiveId = useSelector(getConfirmArchiveId);

  const [toAcrchiveOperationalBlock] = useToArchiveOperationalBlockMutation();
  const [toAcrchiveBuildingBlock] = useToArchiveBuildingBlockMutation();
  const [toAcrchiveExtraTask] = useToArchiveExtraTaskMutation();

  const onSubmitArchive: Record<ManageEventsWorkTypesFilter, any> = {
    [ManageEventsWorkTypesFilter.OPERATIONAL]: () => {
      toAcrchiveOperationalBlock(
        confirmArchiveId ? { id: confirmArchiveId } : skipToken,
      )
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Успешно добавлен в архив',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Не удалось добавить в архив',
          });
        });
    },
    [ManageEventsWorkTypesFilter.BUILDING]: () => {
      toAcrchiveBuildingBlock(
        confirmArchiveId ? { id: confirmArchiveId } : skipToken,
      )
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Успешно добавлен в архив',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Не удалось добавить в архив',
          });
        });
    },
    [ManageEventsWorkTypesFilter.OTHER_TASKS]: () => {
      toAcrchiveExtraTask(
        confirmArchiveId ? { id: confirmArchiveId } : skipToken,
      )
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Успешно добавлен в архив',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Не удалось добавить в архив',
          });
        });
    },
    [ManageEventsWorkTypesFilter.REGULAR_EVENTS]: () => {
      () => {};
    },
  };

  const queryKey: ManageEventsWorkTypesFilter = useMemo(() => {
    const paramValue = params.get(
      SectionQueryKey,
    ) as ManageEventsWorkTypesFilter;
    return paramValue || ManageEventsWorkTypesFilter.OPERATIONAL;
  }, [params]);

  const tableOptions = useSelector(getCurrentTableOptionsSelector);

  const setTitleChange = () => {
    let newTitle = '';
    const currentUrl = window.location.href;
    switch (true) {
      case currentUrl.includes('operational'):
        newTitle = 'Эксплуатационный блок ';
        break;
      case currentUrl.includes('regular-events'):
        newTitle = 'Регулярные мероприятия';
        break;
      case currentUrl.includes('building'):
        newTitle = 'Строительные работы';
        break;
      case currentUrl.includes('other-tasks'):
        newTitle = 'Прочие задачи';
        break;
      default:
        newTitle = 'Эксплуатационный блок';
    }
    setHeaderTitle(newTitle);
    setTitle(newTitle);
  };

  let Component;
  switch (queryKey) {
    case ManageEventsWorkTypesFilter.OPERATIONAL:
      Component = ManageEventsOperationalTable;
      break;
    case ManageEventsWorkTypesFilter.BUILDING:
      Component = BuildingTable;
      break;
    case ManageEventsWorkTypesFilter.OTHER_TASKS:
      Component = OtherTasksTable;
      break;
    default:
      Component = ManageEventsOperationalTable;
  }
  useEffect(() => {
    setTitleChange();
  }, [location]);

  const onChange = useCallback((value: SegmentedValue) => {
    dispatch(
      saveTableOptions({
        ...(tableOptions?.status ? { status: tableOptions?.status } : {}),
      }),
    );

    setSearchParams({
      [SectionQueryKey]: value as string,
    });
  }, []);

  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.WORK_MANAGEMENT_OPERATIONAL_CREATE,
      ACCESS.WORK_MANAGEMENT_CONSTRUCTION_CREATE,
      ACCESS.WORK_MANAGEMENT_OTHERTASK_CREATE,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  const navButtons = [
    {
      key: ManageEventsWorkTypesFilter.OPERATIONAL,
      label: t('operational-block'),
    },
    {
      key: ManageEventsWorkTypesFilter.BUILDING,
      label: t('constructionWorks'),
    },
    {
      key: ManageEventsWorkTypesFilter.OTHER_TASKS,
      label: t('otherTasks-block'),
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col>
          <DefaultButton
            onClick={() => navigate(Routes.ManageEvents)}
            className="backBtn"
          >
            <LeftOutlined />
            <Typography.Text>Назад</Typography.Text>
          </DefaultButton>
        </Col>
        <Col>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: t('headers.manage-events'),
                onClick: () => {
                  navigate(generatePath(Routes.ManageEvents));
                },
              },
              {
                title,
              },
            ]}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        activeKey={queryKey}
        items={dynamicOptions(navButtons)}
        onChange={onChange}
      />
      <Component title={title} />
      <ArchiveConfirmModal submit={onSubmitArchive[queryKey]} />
    </>
  );
};

// ocalhost:3000/irrigation-system/table?page=1&size=10&regionId=1&districtId=14
//http://localhost:3000/archivarius/table?section=irrigationSystem&page=1&size=10&isArchived=true
