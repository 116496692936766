import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  WhatsAppInputButton,
} from '@features/ui-kit';
import { Row, Col, Typography, Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUpdateContactsMutation } from '@store/api/waterUsersApi';
import { useContactsModal } from '../useContactsModal';
import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { TelegramInputButton } from '@features/ui-kit/buttons/TelegramInputButton';
import { contactsDto } from '@store/models/waterUsersModel';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import { mapPhoneNumberToLong } from '@utils/phone';

interface IContactsModalProps {
  data: any;
}

export const ContactsModal: FC<IContactsModalProps> = ({ data }) => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const { isModalShown, setShowModal } = useContactsModal();
  const [updateContacts] = useUpdateContactsMutation();

  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: contactsDto) => {
    updateContacts({
      id,
      body: {
        ...values,
        hasWhatsapp: values.hasWhatsapp ? '1' : '0',
        hasTelegram: values.hasTelegram ? '1' : '0',
      },
    });
    setShowModal(false);
  };

  const initialValues = {
    phone: data?.legalData?.phone,
    additionalPhone: data?.legalData?.additionalPhone,
    email: data?.legalData?.email,
    hasWhatsapp: data?.employees?.[0]?.hasWhatsapp,
    hasTelegram: data?.employees?.[0]?.hasTelegram,
  };

  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  return (
    <CustomModal
      centered
      open={isModalShown}
      onCancel={onCancel}
      width={830}
      title="Контактные данные"
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="phone"
              label={t(
                'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.phone',
              )}
              rules={[{ required: true }, phoneNumberValidator]}
              normalize={mapPhoneNumberToLong}
            >
              <TooltipedInput placeholder="Номер телефона" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="additionalPhone"
              label={t(
                'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.additionalPhone',
              )}
              rules={[{ required: true }, phoneNumberValidator]}
              normalize={mapPhoneNumberToLong}
            >
              <TooltipedInput placeholder="Доп. номер телефона" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="email"
              label={t(
                'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.email',
              )}
              rules={[{ required: true, type: 'email' }]}
            >
              <TooltipedInput placeholder="Почта" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="hasTelegram"
              label="Telegram"
              rules={[{ required: true }]}
            >
              <TelegramInputButton size="large" btnName="hasTelegram" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="hasWhatsapp"
              label="WhatsApp"
              rules={[{ required: true }]}
            >
              <WhatsAppInputButton size="large" btnName="hasWhatsapp" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
