import React, { SyntheticEvent, useCallback, useEffect } from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  TableCustomActionProps,
  TableCustomMethodProps,
} from '@models/table/interfaces/tableCustomActionProps';

import styles from './EditableCell.module.scss';
import { EditorFactory } from './editor';
import { RendererFactory } from './renderer';

export interface EditableCellProps<RecordType>
  extends React.HTMLAttributes<HTMLElement> {
  customColumn?: TableColumn;
  record: RecordType;
  actionProps: TableCustomActionProps<RecordType>;
  methodsProps: TableCustomMethodProps<RecordType>[];
}

export function EditableCell<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps,
  methodsProps,
  ...restProps
}: EditableCellProps<RecordType>): React.ReactElement {
  const isEditingCell = actionProps?.getEditingCell?.(record, customColumn!);
  const editableCell =
    typeof customColumn?.editableCell === 'function'
      ? customColumn?.editableCell(record)
      : customColumn?.editableCell;

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      if (isEditingCell) {
        e.stopPropagation();
      }
      if (typeof customColumn?.editableCell === 'function') {
        const isEditable = customColumn?.editableCell(record);
        if (isEditable) {
          actionProps?.onEditCellClick?.(record, customColumn);
        }
      } else if (customColumn?.editableCell && !isEditingCell) {
        actionProps?.onEditCellClick?.(record, customColumn);
      }
    },
    [isEditingCell, customColumn],
  );

  useEffect(() => {
    if (customColumn?.xtype === CustomTableColumnType.SelectColumn) {
      actionProps?.onEditCellClick?.(record, customColumn);
    }
  }, []);

  if (!customColumn) return <td {...restProps} />;

  if (isEditingCell && editableCell) {
    const editValidator = actionProps.editValidators?.[customColumn.dataIndex];
    const editorElement =
      customColumn.xtype === CustomTableColumnType.RenderCustom
        ? customColumn.renderCustomEditor!({ customColumn, record })
        : EditorFactory.createEditor({
            customColumn,
            record,
            editValidator,
            actionProps,
          });
    return (
      <td
        onClick={onClick}
        {...restProps}
        className={`${styles.editableCell} ${restProps.className}`}
      >
        {editorElement}
      </td>
    );
  }

  const renderProps = {
    customColumn,
    record,
    isEditingCell,
    methodsProps,
    actionProps,
  };

  const renderedElement =
    customColumn.xtype === CustomTableColumnType.RenderCustom
      ? customColumn.renderCustom!(renderProps)
      : RendererFactory.createRenderer(renderProps);

  return (
    <td
      onClick={onClick}
      {...restProps}
      className={`${
        customColumn.editableCell && editableCell ? styles.editableCell : ''
      } ${restProps.className}`}
    >
      {renderedElement}
    </td>
  );
}
