import React, { FC } from 'react';

import { Col, Form, Row, Space } from 'antd';

import {
  CustomCard,
  InformationalBlock,
  SectionTitle,
  TooltipedInput,
} from '@features/ui-kit';

import styles from './CommonCharacteristicsFields.module.scss';

export const CommonCharacteristicsFields: FC = () => {
  return (
    <InformationalBlock>
      <div className={styles.commonCharacteristic}>
        <SectionTitle title="Общие характеристики" />
        <Space
          direction="vertical"
          size="large"
          className={styles.commonWrapper}
        >
          <Row justify="space-between" gutter={8}>
            <Col span={16}>
              <CustomCard
                withoutBottomPadding
                title="Характеристики ирригационной системы"
                className={styles.cardForm}
              >
                <div className={styles.characteristicForm}>
                  <Row>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="areaPlanting"
                            label="Общ. орошаемая площадь (Га)"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="amountStructures"
                            label="Колличество сооружений всего, шт"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Из них автоматические, шт"
                            name="waterMeasurementDevicesAuto"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Кол.связанных гидротех. сооружений"
                            name="lengthInnerChannels"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Водомерные устройства всего, шт"
                            name="waterMeasurementDevices"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CustomCard>
            </Col>
            <Col span={8}>
              <CustomCard
                title="Земельный фонд"
                withoutBottomPadding
                className={styles.cardForm}
              >
                <div className={styles.areaFond}>
                  <Row>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="area"
                            label="Всего (Га)"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="areaOfFields"
                            label="Под насаждения (Га)"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="additionalConstructions"
                            label="Под вспом. постройки (Га)"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="otherObjects"
                            label="Прочие объекты (Га)"
                            rules={[{ required: true }]}
                            style={{ marginBottom: 10 }}
                          >
                            <TooltipedInput size="large" type="number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </CustomCard>
            </Col>
          </Row>
        </Space>
      </div>
    </InformationalBlock>
  );
};
