export enum WaterObjectTypes {
  mainConduitType = 'mainConduitType',
  gvsType = 'gvsType',
  reservoirsType = 'reservoirsType',
  bridgesType = 'bridgesType',
  collectorType = 'collectorType',
  damType = 'damType',
  electricalSubstationType = 'electricalSubstationType',
  forestsBeltsType = 'forestsBeltsType',
  holeType = 'holeType',
  hydropostType = 'hydropostType',
  measuringInstrumentsType = 'measuringInstrumentsType',
  otherPipelinesType = 'otherPipelinesType',
  outletsType = 'outletsType',
  pondsType = 'pondsType',
  powerLinesType = 'powerLinesType',
  providingAuxiliaryType = 'providingAuxiliaryType',
  pumpType = 'pumpType',
  roadsType = 'roadsType',
  spillwayStructureType = 'spillwayStructureType',
  weirType = 'weirType',
  wellType = 'wellType',
}

export enum WaterObjectStepperQueryTypes {
  constructor = 'constructor',
  gtsConstructor = 'gtsConstructor',
}

export enum WaterObjectRelatedObjectsSegmentedQueryTypes {
  hydropost = 'Гидропосты',
  outlet = 'Водовыпуски',
  hydroStructures = 'Гидротехнические сооружения',
  wasteFacilities = 'Сбросные сооружения',
  KDS = 'КДС',
  reservoir = 'Резервуары',
}

export enum WaterObjectDataSetTypes {
  'Polygon' = 'Полигональный',
  'LineString' = 'Линейный',
  'Point' = 'Точечный',
}
