import { FC } from 'react';

import { Col, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { CustomCard, DangerButton, DirectionRadio, TooltipedInput } from '@features/ui-kit';
import { useReferenceControllerFindAllObjectCategoryQuery } from '@store/api/referenceApi';
import { SystemObject } from '@models/systemObject/systemObject';

type WaterObjectPreviewCardPropTypes = {
  item: SystemObject;
  onRemoveConnection: ((item: any) => void) | null;
};

export const WaterObjectPreviewCard: FC<WaterObjectPreviewCardPropTypes> = ({
  item,
  onRemoveConnection,
}) => {
  const { data: objectCategories = [] } = useReferenceControllerFindAllObjectCategoryQuery();
  const currentObjectCategory = objectCategories.find(
    (category: ObjectCategory) =>
      category.categoryId == item.objectCategoryId,
  );

  return (
    <Col span={24}>
      <CustomCard title={item.objectName} dashed>
        <Row gutter={15} align="middle">
          <Col span={6}>
            <Typography.Text>Пикетная координата</Typography.Text>
            <TooltipedInput size="large" disabled value={item.coordinates} />
          </Col>
          <Col span={6}>
            <Typography.Text>Наименование объекта</Typography.Text>
            <TooltipedInput size="large" disabled value={item.objectName} />
          </Col>
          <Col span={5}>
            <Typography.Text>Категория</Typography.Text>
            <TooltipedInput
              disabled
              size="large"
              value={currentObjectCategory?.groupTitle}
            />
          </Col>
          <Col span={5}>
            <Typography.Text>Направление</Typography.Text>
            <DirectionRadio value={item.direction} />
          </Col>
          <Col span={2}>
            <DangerButton
              style={{ marginTop: 20 }}
              onClick={() => onRemoveConnection?.(item)}
              icon={<CloseOutlined />}
            />
          </Col>
        </Row>
      </CustomCard>
    </Col>
  );
};
