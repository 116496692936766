import { api } from '../basicApi';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    waterSectionsControllerSaveWaterSections: build.mutation<
      WaterSectionsControllerSaveWaterSectionsApiResponse,
      WaterSectionsControllerSaveWaterSectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/water-sections/create`,
        method: 'POST',
        body: queryArg.waterSectionsDto,
      }),
      invalidatesTags: ['WaterSections'],
    }),
    waterSectionsControllerUpdateWaterSections: build.mutation<
      WaterSectionsControllerUpdateWaterSectionsApiResponse,
      WaterSectionsControllerUpdateWaterSectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/water-sections/update`,
        method: 'PUT',
        body: queryArg.waterSectionsDto,
      }),
      invalidatesTags: ['WaterSections'],
    }),
    waterSectionsControllerFindAll: build.query<
      WaterSectionsControllerFindAllApiResponse,
      WaterSectionsControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/water-sections/all` }),
      providesTags: ['WaterSections'],
    }),
    waterSectionsControllerFindBySystemObjectId: build.query<
      WaterSectionsControllerFindBySystemObjectIdApiResponse,
      WaterSectionsControllerFindBySystemObjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/water-sections/system-object/${queryArg.id}`,
      }),
      providesTags: ['WaterSections'],
    }),
    waterSectionsControllerDeleteWaterSection: build.mutation<
      WaterSectionsControllerDeleteWaterSectionApiResponse,
      WaterSectionsControllerDeleteWaterSectionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/water-sections/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WaterSections'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type WaterSectionsControllerSaveWaterSectionsApiResponse = unknown;
export type WaterSectionsControllerSaveWaterSectionsApiArg = {
  waterSectionsDto: WaterSectionsDto;
};
export type WaterSectionsControllerUpdateWaterSectionsApiResponse = unknown;
export type WaterSectionsControllerUpdateWaterSectionsApiArg = {
  waterSectionsDto: WaterSectionsDto;
};
export type WaterSectionsControllerFindAllApiResponse = unknown;
export type WaterSectionsControllerFindAllApiArg = void;
export type WaterSectionsControllerFindBySystemObjectIdApiResponse = Array<IWaterSection>;
export type WaterSectionsControllerFindBySystemObjectIdApiArg = {
  id: number;
};
export type WaterSectionsControllerDeleteWaterSectionApiResponse = unknown;
export type WaterSectionsControllerDeleteWaterSectionApiArg = {
  id: number;
};
export type WaterSectionsDto = any;
export const {
  useWaterSectionsControllerSaveWaterSectionsMutation,
  useWaterSectionsControllerUpdateWaterSectionsMutation,
  useWaterSectionsControllerFindAllQuery,
  useWaterSectionsControllerFindBySystemObjectIdQuery,
  useWaterSectionsControllerDeleteWaterSectionMutation,
} = injectedRtkApi;
