import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';

import { useCreateWaterSectionModal } from './useCreateWaterSectionModal';
import {
  CustomCard,
  CustomModal,
  DefaultButton,
  PicketInput,
  PrimaryButton,
  PropertyBlock,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import {
  useWaterSectionsControllerSaveWaterSectionsMutation,
  useWaterSectionsControllerUpdateWaterSectionsMutation,
} from '@store/api/waterSections';
import Icon from '@ant-design/icons';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { TechCharIcon } from '@icons/TechCharIcon';
import { TechCharBlueIcon } from '@icons/TechCharBlueIcon';
import { getSelectedWaterSectionSelector } from '@store/selectors/waterObject';
import { useDispatch, useSelector } from 'react-redux';
import { waterObjectActions } from '@store/slices';
import { useTranslation } from 'react-i18next';

export const CreateWaterSectionModal: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isModalShown, setShowModal } = useCreateWaterSectionModal();
  const selectedWaterSection = useSelector(getSelectedWaterSectionSelector);

  const [create] = useWaterSectionsControllerSaveWaterSectionsMutation();
  const [update] = useWaterSectionsControllerUpdateWaterSectionsMutation();

  const { id, mainConduitId } = useParams<{
    id: any;
    mainConduitId: any;
  }>();

  const { data: sectionTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.SectionType,
  });

  const { data: operationalStatuses = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.OperationalStatusEnum,
    });

  const { data: technicalStatuses = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.TechnicalStatusEnum,
    });

  const { data: formTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  const { data: facingTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: soilTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionSoilTypes,
  });

  const onClose = () => {
    form.resetFields();
    setShowModal(false);
    dispatch(waterObjectActions.setSelectedWaterSectionAction(null));
  };

  const onFinish = async (values: any) => {
    try {
      const waterSection: IWaterSection = await form.validateFields();
      if (id || mainConduitId) {
        if (selectedWaterSection) {
          update({
            waterSectionsDto: {
              ...selectedWaterSection,
              ...waterSection,
              systemObjectId: id || mainConduitId,
            },
          });
        } else {
          create({
            waterSectionsDto: {
              ...waterSection,
              systemObjectId: id || mainConduitId,
            },
          });
        }
      }
      onClose();
    } catch (err) {
      console.log('Err: ', err);
    }
  };

  useEffect(() => {
    if (selectedWaterSection) {
      form.setFieldsValue(selectedWaterSection);
    }
  }, [selectedWaterSection]);

  const changePicket = (value: any) => {
    const [picket1, picket2] = value.split(':');
    const [picket1_1, picket1_2] = picket1.split('+');
    const [picket2_1, picket2_2] = picket2.split('+');
    if (picket1_1 && picket1_2 && picket2_1 && picket2_2) {
      const lengthMeters =
        (Number(picket2_1) * 100 +
          Number(picket2_2) -
          (Number(picket1_1) * 100 + Number(picket1_2))) /
        1000;
      form.setFieldsValue({ lengthMeters });
    }
  };

  const normalizeValue = (val: number, prev: number) => {
    if (
      (prev === undefined && val > 1) ||
      val < 0 ||
      String(val).includes('-')
    ) {
      return '';
    }

    if (
      val > 1 ||
      val < 0 ||
      String(val).length > 4 ||
      String(val).includes('-')
    ) {
      return prev;
    }
    return val;
  };

  return (
    <CustomModal
      centered
      destroyOnClose
      footer={
        <Row justify="center" style={{ marginTop: 16 }}>
          <Col>
            <Space size={16}>
              <DefaultButton onClick={onClose} size="large">
                Отмена
              </DefaultButton>
              <PrimaryButton onClick={onFinish} htmlType="submit" size="large">
                Сохранить
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      }
      onCancel={onClose}
      title="Создание водоводного участка"
      open={isModalShown}
      width={1410}
    >
      <Form<IWaterSection> form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={5}>
            <Form.Item
              label="Код (Наименование)"
              name="name"
              rules={[{ required: true }]}
            >
              <TooltipedInput size="large" placeholder="Введите данные" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Вид участка"
              name="typeSectionId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                placeholder="Выберите вид"
                options={sectionTypes}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              label="Протяженность в пикетах*"
              name="coordinates"
              rules={[{ required: true }]}
            >
              <PicketInput
                size="large"
                isLinear={true}
                onChange={changePicket}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              label="Статус Тех. состояния"
              name="technicalStatusId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                placeholder="Выберите статус"
                options={technicalStatuses}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              label="Статус Экспл. состояния"
              name="operationalStatusId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                placeholder="Выберите статус"
                options={operationalStatuses}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <CustomCard
              light
              title={
                <Row align="middle">
                  <Col>
                    <Icon component={SizesIcon} />
                  </Col>
                  <Col>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      Размеры и габариты
                    </Typography.Title>
                  </Col>
                </Row>
              }
            >
              <Col span={24}>
                <Form.Item name="widthMeters">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Ширина, м (B)"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="bottomWidthMeters">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Ширина по дну, м (b)"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="heightMeters">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Высота, м (h)"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="wallHeightMeters">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Высота строения, м (H стр.)"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="lengthMeters">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Протяженность, км (L)"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="areaHectares">
                  <PropertyBlock
                    icon={SizesIcon}
                    label="Подвешенная площадь, Га"
                    type="input"
                  />
                </Form.Item>
              </Col>
            </CustomCard>
          </Col>

          <Col span={12}>
            <CustomCard
              light
              title={
                <Row align="middle">
                  <Col>
                    <Icon component={ConstructionIcon} />
                  </Col>
                  <Col>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      Конструктивные характеристики
                    </Typography.Title>
                  </Col>
                </Row>
              }
            >
              <Col span={24}>
                <Form.Item name="formId">
                  <PropertyBlock
                    icon={ConstructionIcon}
                    options={formTypes}
                    label="Форма"
                    type="select"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="facingId">
                  <PropertyBlock
                    icon={ConstructionIcon}
                    label="Облицовка"
                    options={facingTypes}
                    type="select"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="direction"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, введите Направление',
                    },
                  ]}
                >
                  <PropertyBlock
                    icon={ConstructionIcon}
                    label="Направление*"
                    type="radio"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="bottomSlopeAngle">
                  <PropertyBlock
                    icon={ConstructionIcon}
                    label="Угол наклона дна"
                    type="input"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="soilId">
                  <PropertyBlock
                    icon={ConstructionIcon}
                    label="Грунт"
                    options={soilTypes}
                    type="select"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="sedimentLevelMeters">
                  <PropertyBlock
                    icon={ConstructionIcon}
                    label="Уровень наносов"
                    type="input"
                  />
                </Form.Item>
              </Col>
            </CustomCard>
          </Col>

          <Col span={24}>
            <CustomCard
              light
              title={
                <Row align="middle">
                  <Col>
                    <Icon component={TechCharIcon} />
                  </Col>
                  <Col>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      Технические характеристики
                    </Typography.Title>
                  </Col>
                </Row>
              }
            >
              <Row gutter={[48, 16]}>
                <Col span={12}>
                  <Form.Item name="maxFlowRate">
                    <PropertyBlock
                      icon={TechCharBlueIcon}
                      label="Макс. расход, м3/с (в нач. уч.)"
                      type="input"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="permeability">
                    <PropertyBlock
                      icon={TechCharBlueIcon}
                      label="Степень проницаемости"
                      options={[
                        { value: '1', label: 'Низкая' },
                        { value: '2', label: 'Средняя' },
                        { value: '3', label: 'Высокая' },
                      ]}
                      type="select"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="flowRate">
                    <PropertyBlock
                      icon={TechCharBlueIcon}
                      label="Скорость потока, м3/с"
                      type="input"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="efficiency"
                    normalize={(val: number) =>
                      normalizeValue(val, form.getFieldValue('efficiency'))
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          'Пожалуйста, введите корректный КПД (значения от 0 до 1)',
                      },
                    ]}
                  >
                    <PropertyBlock
                      icon={TechCharBlueIcon}
                      label="КПД*"
                      type="input"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
