export const colors = {
  transparent: 'transparent',

  // background
  background: '#f9f9fb',

  // blue
  main_color_blue_1: '#4B60D8',

  // Dark theme
  primary: '#5E72E4',

  // buttons
  button_blue_color: '#3182CE',
  button_white_color: '#FFFFFF',

  // input
  input_border: 'rgb(114,114,114)',

  main_color_dark_1: '#100F0F',
  main_color_dark_2: '#1B1B1B',
  main_color_dark_3: '#242424',
  main_color_red: 'rgba(255, 18, 37, 0.7)',
  main_color_blue: '#3182CE',
  positive_color: '#3C8618',
  positive_color_hover: '#4f872f',
  positive_color_active: '#2e5e17',
  segment_colors: 'rgba(0, 0, 0, 0.04)',

  darkColor: '#000000',

  row_selected: 'rgba(255, 255, 255, 0.2)',

  text_white: 'rgba(255, 255, 255, 0.85)',
  text_tertiary: 'rgba(255, 255, 255, 0.45)',

  icon: 'rgba(0, 0, 0, 0.45)',
  icon_color: 'rgba(0, 0, 0, 0.45)',

  magenta1: '#291321',
  magenta3: '#551C3B',
  magenta7: '#FF1225',
  geekblue1: '#131629',
  geekblue3: '#1c2755',
  geekblue7: '#FFFFFF',

  // white
  main_color_white_1: '#F8F9FE',
  main_color_white_2: '#FFF',
  main_color_white_3: 'rgba(255, 255, 255, 1)',

  // grey
  main_color_grey_1: 'rgba(0, 0, 0, 0.88)',
  main_color_grey_2: 'rgba(0, 0, 0, 0.5)',
  main_color_grey_3: 'rgba(75, 96, 216, 0.06)',
};
