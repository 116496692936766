import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Row, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import {
  ConnectBlock,
  CustomCard,
  DocsList,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
  Tabs,
} from '@features/ui-kit';
import { useCreatingSiteModal } from '@features/waterUser/waterUserCard/useCreatingSiteModal';
import { useMainInfoModal } from '@features/waterUser/waterUserCard/useMainInfoModal';
import { useTotalInformationColumns } from '@features/waterUser/waterUserCard/useTotalInformationColumns';
import { useContactsModal } from '@features/waterUser/waterUserCard/useContactsModal';
import { useBankDetailsModal } from '@features/waterUser/waterUserCard/useBankDetailsModal';
import { EditIcon } from '@icons/EditIcon';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useDeleteFacilityMutation,
  useFindAllByOrganizationIdQuery,
  useGetFacilitiesByOrgIdQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import styles from '../PhyicalWaterUserCard.module.scss';
import { MainInfoModal } from '../../modals/MainInfoModal';
import { ContactsModal } from '../../modals/ContactsModal';
import { BankDetailsModal } from '../../modals/BankDetailsModal';

export const TotalInformation = () => {
  const { id } = useParams<{ id: string }>();
  const { facilitiesColumns } = useTotalInformationColumns();
  const { t } = useTranslation();
  const [activeAttachments, setActiveAttachments] = useState('agreement');
  const { setShowModal, setInitialValues } = useCreatingSiteModal();
  const {
    isModalShown: isShowMainInfoModal,
    setShowModal: setShowMainInfoModal,
  } = useMainInfoModal();
  const {
    isModalShown: isShowContactsModal,
    setShowModal: setShowContactsModal,
  } = useContactsModal();
  const {
    isModalShown: isShowBankDetailsModal,
    setShowModal: setShowBankDetailsModal,
  } = useBankDetailsModal();

  const { data } = useFindAllByOrganizationIdQuery(
    id
      ? {
          id: toInteger(id),
        }
      : skipToken,
  );

  const { data: facilities = [] } = useGetFacilitiesByOrgIdQuery(
    id
      ? {
          orgId: Number(id),
        }
      : skipToken,
  );

  const [deleteFacility] = useDeleteFacilityMutation();
  const contactInformation = [
    {
      label: 'Номер телефона',
      value: data?.legalData?.phone || '-',
    },
    {
      label: 'Доп. номер телефона',
      value: data?.legalData?.additionalPhone || '-',
    },
    { label: 'E-mail', value: data?.legalData?.email || '-' },
    { messenger: 'whatsapp', value: data?.employees?.[0]?.hasWhatsapp },
    { messenger: 'telegram', value: data?.employees?.[0]?.hasTelegram },
  ];
  const gender: any = {
    F: 'Женский',
    M: 'Мужской',
    '-': '-',
  }
  const basicData = [
    {
      label: 'Ф.И.О.',
      value: data?.legalData?.name || '-',
    },
    {
      label: 'Вид хоз. деятельности',
      value: data?.legalData?.economicActivity?.title || '-',
    },
    { label: 'ИНН', value: data?.legalData?.inn || '-' },
    { label: '№ Паспорта', value: data?.legalData?.passportNumber || '-' },
    {
      label: 'Дата рождения',
      value:
        data?.legalData?.dateBirth || data?.employees?.[0]?.dateBirth || '-',
    },
    {
      label: 'Пол',
      value: gender?.[(data?.legalData?.gender || data?.employees?.[0]?.gender || '-')] ?? '-',
    },
    { label: 'Адрес проживания', value: data?.legalData?.address || '-' },
  ];
  const bankDetails = [
    {
      label: 'Название банка',
      value: data?.legalData?.bank?.parent?.name || '-',
    },
    {
      label: 'Название филиала',
      value: data?.legalData?.bank?.name || '-',
    },
    {
      label: 'Полный адрес банка',
      value: data?.legalData?.bank?.address || '-',
    },
    { label: 'БИК банка', value: data?.legalData?.bank?.bik || '-' },
    {
      label: 'Расчетный счет водопользователя',
      value: data?.legalData?.accountNumber || '-',
    },
  ];

  const attachments: any = {
    agreement: data?.attachmentsList?.filter(
      (att: any) => [DocumentTypes.Agreement, DocumentTypes.Contract].includes(att.type),
    ),
    passport: data?.attachmentsList?.filter(
      (att: any) => att.type === DocumentTypes.Passport,
    ),
  };

  const changeActiveAttachments = (key: string) => {
    setActiveAttachments(key);
  };

  const options = [
    {
      label: 'Соглашение',
      key: 'agreement',
    },
    {
      label: 'Паспорт',
      key: 'passport',
    },
  ];

  const onHandleEdit = (record: any) => {
    console.log('item', record);
    setInitialValues(record);
    setShowModal(true);
  };

  const onHandleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = (record: any) => {
    deleteFacility({ id: record.facilitiesId });
  };
  return (
    <InformationalBlock>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <ConnectBlock
            connectData={basicData}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>Основные данные</Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={data?.isArchived}
                    onClick={() => setShowMainInfoModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <ConnectBlock
            connectData={contactInformation}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Контактые данные
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={data?.isArchived}
                    onClick={() => setShowContactsModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <ConnectBlock
            connectData={bankDetails}
            title={
              <Row justify="space-between" align="top">
                <Col span={21}>
                  <Typography.Title level={4}>
                    Банковские реквизиты
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    disabled={data?.isArchived}
                    onClick={() => setShowBankDetailsModal(true)}
                    icon={<EditIcon />}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
        <Col span={6}>
          <CustomCard
            title={
              <Row justify="space-between" align="middle">
                <Col span={24}>
                  <Typography.Title level={4}>
                    Прикрепленные документы
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
              </Row>
            }
          >
            <Row>
              <Col span={24}>
                <Tabs onChange={changeActiveAttachments} items={options} />
              </Col>
              <Col className={styles.docsList} span={24}>
                <DocsList documents={attachments[activeAttachments]} />
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={24}>
          <CustomCard
            title={
              <Row justify="space-between">
                <Col span={21}>
                  <Typography.Title level={4}>
                    {t(
                      'createIndividualWaterRegistry.secondStep.economyDetails.title',
                    )}
                  </Typography.Title>
                  <Divider className={styles.divider} />
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={onHandleCreate}
                    type="primary"
                    htmlType="button"
                    disabled={data?.isArchived}
                  >
                    Добавить участок
                  </PrimaryButton>
                </Col>
              </Row>
            }
          >
            <TableCustom
              dataSource={facilities || []}
              columns={facilitiesColumns}
              pagination={false}
              bordered
              actionProps={{
                onHandleEdit,
                deleteItem,
              }}
            />
          </CustomCard>
        </Col>
      </Row>
      {isShowMainInfoModal && <MainInfoModal data={data} />}
      {isShowContactsModal && <ContactsModal data={data} />}
      {isShowBankDetailsModal && <BankDetailsModal data={data} />}
    </InformationalBlock>
  );
};
