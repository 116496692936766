import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BlueWatchIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.08833 6.12793C5.71802 5.45624 6.47878 4.92085 7.3236 4.55484C8.16841 4.18884 9.07931 4 10 4C10.9207 4 11.8316 4.18884 12.6764 4.55484C13.5212 4.92085 14.282 5.45624 14.9117 6.12793L17.0833 8.58376C17.9167 9.41709 18.9367 10.2504 20 10.2504C18.9367 10.2504 17.9167 11.0838 17.0833 11.9171L14.9117 14.3729C14.282 15.0446 13.5212 15.58 12.6764 15.946C11.8316 16.312 10.9207 16.5009 10 16.5009C9.07931 16.5009 8.16841 16.312 7.3236 15.946C6.47878 15.58 5.71802 15.0446 5.08833 14.3729L2.91667 11.9171C2.08333 11.0838 1.06333 10.2504 0 10.2504C1.06333 10.2504 2.08333 9.41709 2.91667 8.58376L5.08833 6.12793Z"
        stroke="#3A36DB"
      />
      <path
        d="M7.91602 10.2503C7.91602 9.69779 8.13551 9.16789 8.52621 8.77719C8.91691 8.38649 9.44681 8.16699 9.99935 8.16699C10.5519 8.16699 11.0818 8.38649 11.4725 8.77719C11.8632 9.16789 12.0827 9.69779 12.0827 10.2503C12.0827 10.8029 11.8632 11.3328 11.4725 11.7235C11.0818 12.1142 10.5519 12.3337 9.99935 12.3337C9.44681 12.3337 8.91691 12.1142 8.52621 11.7235C8.13551 11.3328 7.91602 10.8029 7.91602 10.2503Z"
        stroke="#3A36DB"
      />
    </svg>
  );
};
