import { IFacility } from '@models/facility/interfaces/facility';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import {
  EmployeeTypesEnum,
  MainWaterUserTypes,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EmployeeType = {
  employeeId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  positionEmployee: string;
};

export type EconomyType = {
  totalArea?: any;
  economieId: number;
  name: string;
  regionId: number;
  districtId: number;
  okmotId: number;
  systemId: number;
  channelId: number;
  outletId: number;
  outlet?: number;
  area: string;
  kpd: number;
  agroZoneId: number;
  additionalAddress: string;
  gisAddress: string;
  renter?: any;
};

export type WaterRegistryState = {
  isLoading: boolean;
  formData: any;
  formErrors: any;
  isShowWaterUserModal: boolean;
  isShowAddBankModal: boolean;
  isShowEditEmployeeModal: boolean;
  isShowWaterUserDataModal: boolean;
  isShowAddBranchModal: boolean;
  isShowAddEmployeeModal: boolean;
  isShowActionSelectModal: boolean;
  isShowIndividualPermissionsModal: boolean;
  isShowLegalPermissionsModal: boolean;
  isShowAssociationPermissionsModal: boolean;
  isShowAssociationStatsModal: boolean;
  isShowWaterUsersModal: boolean;
  selectedBankId: number | null;
  employees: EmployeeType[];
  economies: {
    [WaterUserTypes.association]: EconomyType[],
    [WaterUserTypes.physical]: EconomyType[],
    [WaterUserTypes.legal]: EconomyType[],
  }
  currentEmployeeType: EmployeeTypesEnum | null;
  selectedEconomyIndex: number | null;
  waterId: string;
  organizationId: string;
  tableCardPath: string;
  activeEmployeesId: number | null;
  waterRegistryFilters: Partial<IWaterRegistryFilters>;
  isShowConfirmArchiveModal: boolean;
  outletIdModal: null | number;
  creatingSiteAssociationModal: boolean;
  legalEntityMainInfoModal: boolean;
  creatingSiteModal: boolean;
  mainInfoModal: boolean;
  contactsModal: boolean;
  backDetails: boolean;
  initialValuesSiteModal: IFacility | null;
  initialValuesSiteAssociationModal: any;
};

const initialState: WaterRegistryState = {
  isLoading: false,
  formData: {
    [WaterUserTypes.association]: {},
    [WaterUserTypes.physical]: {},
    [WaterUserTypes.legal]: {},
  },
  legalEntityMainInfoModal: false,
  creatingSiteAssociationModal: false,
  creatingSiteModal: false,
  mainInfoModal: false,
  contactsModal: false,
  backDetails: false,
  formErrors: null,
  isShowAddBankModal: false,
  isShowWaterUserModal: false,
  isShowWaterUserDataModal: false,
  isShowAddEmployeeModal: false,
  isShowEditEmployeeModal: false,
  isShowAddBranchModal: false,
  isShowActionSelectModal: false,
  isShowIndividualPermissionsModal: false,
  isShowLegalPermissionsModal: false,
  isShowAssociationPermissionsModal: false,
  isShowAssociationStatsModal: false,
  selectedBankId: null,
  employees: [],
  activeEmployeesId: null,
  economies: {
    [WaterUserTypes.association]: [],
    [WaterUserTypes.physical]: [],
    [WaterUserTypes.legal]: [],
  },
  currentEmployeeType: null,
  selectedEconomyIndex: null,
  waterId: '',
  organizationId: '',
  tableCardPath: '',
  waterRegistryFilters: {
    page: 1,
    size: 10,
  },
  isShowConfirmArchiveModal: false,
  isShowWaterUsersModal: false,
  outletIdModal: null,
  initialValuesSiteModal: null,
  initialValuesSiteAssociationModal: null,
};

const waterRegistrySlice = createSlice({
  name: 'waterRegistry',
  initialState,
  reducers: {
    changeWaterRegistryFilters: (
      state,
      action: PayloadAction<IWaterRegistryFilters>,
    ) => {
      return {
        ...state,
        waterRegistryFilters: action.payload,
      };
    },

    setShowAddBranchModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowAddBranchModal: action.payload,
      };
    },
    setShowAddBankModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowAddBankModal: action.payload,
      };
    },
    setCreatingSiteAssociationModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        creatingSiteAssociationModal: action.payload,
      };
    },
    setCreatingSiteModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        creatingSiteModal: action.payload,
      };
    },
    setInitialValuesSiteModal: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        initialValuesSiteModal: action.payload,
      };
    },
    setInitialValuesSiteAssociationModal: (
      state,
      action: PayloadAction<any>,
    ) => {
      return {
        ...state,
        initialValuesSiteAssociationModal: action.payload,
      };
    },
    setMainInfoModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        mainInfoModal: action.payload,
      };
    },
    setLegalEntityMainInfoModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        legalEntityMainInfoModal: action.payload,
      };
    },
    setContactsModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        contactsModal: action.payload,
      };
    },
    setBankDetailsModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        backDetails: action.payload,
      };
    },
    setShowWaterUserDataModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWaterUserDataModal: action.payload,
      };
    },
    setShowWaterUserModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWaterUserModal: action.payload,
      };
    },
    setSelectedBank: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        selectedBankId: action.payload,
      };
    },
    addAttachmentToWaterRegistryUserAction: (
      state,
      action: PayloadAction<{
        attachment: any;
        waterRegistryUserType: WaterUserTypes;
      }>,
    ) => {
      const formData =
        state.formData[action.payload.waterRegistryUserType] || {};
      const currentAttachmentList = formData?.attachmentResponseDtos || [];

      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.waterRegistryUserType]: {
            ...formData,
            attachmentResponseDtos: [
              ...currentAttachmentList,
              action.payload.attachment,
            ],
          },
        },
      };
    },
    setFormData: (
      state,
      action: PayloadAction<{
        data: any;
        waterRegistryUserType: WaterUserTypes;
      }>,
    ) => {
      const waterRegistryUserData =
        state.formData[action.payload.waterRegistryUserType];
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.waterRegistryUserType]: {
            ...waterRegistryUserData,
            ...action.payload.data,
          },
        },
      };
    },
    clearFormDataAction: (
      state,
      action: PayloadAction<{
        waterRegistryUserType: WaterUserTypes;
      }>,
    ) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.waterRegistryUserType]: {},
        },
      };
    },

    // Economies
    saveOrEditEconomyAction: (
      state,
      action: PayloadAction<{ values: EconomyType; index: number | null, waterUserType: MainWaterUserTypes }>,
    ) => {
      let copy = state.economies[action.payload.waterUserType];
      if (action.payload.index !== null && copy[action.payload.index]) {
        copy[action.payload.index] = action.payload.values;
      } else {
        copy = [...copy, action.payload.values];
      }

      return {
        ...state,
        economies: { ...state.economies, [action.payload.waterUserType]: copy },
      };
    },
    setSelectedEconomyIndexAction: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      return {
        ...state,
        selectedEconomyIndex: action.payload,
      };
    },
    removeEconomyAction: (state: any, action: PayloadAction<number>) => {
      const copy = state.economies.filter((item: EconomyType) => {
        return item.economieId !== action.payload;
      });

      return {
        ...state,
        economies: copy,
      };
    },
    clearEconomiesAction: (state) => {
      return {
        ...state,
        economies: {
          [WaterUserTypes.association]: [],
          [WaterUserTypes.physical]: [],
          [WaterUserTypes.legal]: [],
        },
      };
    },

    // empployees
    setCurrentEmployeeTypeAction: (
      state,
      action: PayloadAction<EmployeeTypesEnum>,
    ) => {
      return {
        ...state,
        currentEmployeeType: action.payload,
      };
    },
    removeEmployeeAction: (state, action: PayloadAction<number>) => {
      const copy = state.employees.filter(
        (item) => item.employeeId !== action.payload,
      );

      return {
        ...state,
        employees: copy,
      };
    },

    editEmployeeAction: (state, { payload }: PayloadAction<any>) => {
      const copy = state.employees.map((item) => {
        const res = item.employeeId === payload.id ? payload.employee : item;
        return { employeeId: payload.id, ...res };
      });
      return {
        ...state,
        employees: copy,
      };
    },

    setActiveEmployeeIdAction: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      return {
        ...state,
        activeEmployeesId: action.payload,
      };
    },
    addEmployeeAction: (state, action: PayloadAction<EmployeeType>) => {
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };
    },
    clearEmployeesAction: (state) => {
      return {
        ...state,
        employees: [],
      };
    },
    setShowAddEmployeeModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowAddEmployeeModal: action.payload,
      };
    },
    setShowEditEmployeeModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowEditEmployeeModal: action.payload,
      };
    },
    setShowActionSelectModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowActionSelectModal: action.payload,
      };
    },
    setWaterUserId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        waterId: action.payload,
      };
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        organizationId: action.payload,
      };
    },
    setShowIndividualPermissionsModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowIndividualPermissionsModal: action.payload,
      };
    },
    setShowLegalPermissionsModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowLegalPermissionsModal: action.payload,
      };
    },
    setShowAssociationPermissionsModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAssociationPermissionsModal: action.payload,
      };
    },
    setShowAssociationStatsModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowAssociationStatsModal: action.payload,
      };
    },
    setShowWaterUsersModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWaterUsersModal: action.payload,
      };
    },
    setOutletIdModal: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        outletIdModal: action.payload,
      };
    },
    setTableCardPath: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tableCardPath: action.payload,
      };
    },
    // Modal Confirm Archive
    setShowConfirmArchiveModalWaterUser: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowConfirmArchiveModal: action.payload,
      };
    },
  },
});

export const {
  changeWaterRegistryFilters,
  setLegalEntityMainInfoModal,
  setMainInfoModal,
  setContactsModal,
  setBankDetailsModal,
  setShowAddBankModal,
  setShowAddBranchModal,
  setSelectedBank,
  setFormData,
  addEmployeeAction,
  saveOrEditEconomyAction,
  setSelectedEconomyIndexAction,
  removeEconomyAction,
  removeEmployeeAction,
  setActiveEmployeeIdAction,
  editEmployeeAction,
  setShowEditEmployeeModal,
  setShowAddEmployeeModal,
  setCurrentEmployeeTypeAction,
  setShowActionSelectModalAction,
  setShowWaterUserDataModal,
  setShowIndividualPermissionsModal,
  setShowLegalPermissionsModal,
  setShowAssociationPermissionsModal,
  setShowAssociationStatsModal,
  clearFormDataAction,
  setShowWaterUserModal,
  addAttachmentToWaterRegistryUserAction,
  clearEmployeesAction,
  clearEconomiesAction,
  setWaterUserId,
  setOrganizationId,
  setTableCardPath,
  setShowWaterUsersModal,
  setOutletIdModal,
  setCreatingSiteAssociationModal,
  setCreatingSiteModal,
  setInitialValuesSiteModal,
  setInitialValuesSiteAssociationModal,
  setShowConfirmArchiveModalWaterUser,
} = waterRegistrySlice.actions;

export default waterRegistrySlice.reducer;
