import { IWaterUseFilters } from '@models/waterUse/interfaces/waterUse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WaterUseState = {
  waterUseFilters: Partial<IWaterUseFilters>;
};

const initialState: WaterUseState = {
  waterUseFilters: {},
};

const waterUseSlice = createSlice({
  name: 'waterUse',
  initialState,
  reducers: {
    changeWaterUseFilters: (state, action: PayloadAction<IWaterUseFilters>) => {
      return {
        ...state,
        waterUseFilters: action.payload,
      };
    },
  },
});

export const { changeWaterUseFilters } = waterUseSlice.actions;
export default waterUseSlice.reducer;
