import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { DocumentTypes } from '@enums/documentTypes';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  DangerButton,
  DefaultButton,
  DocsList,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  CreateIndividualWaterRegistryQueryTypes,
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { getFormData } from '@store/selectors';
import {
  addAttachmentToWaterRegistryUserAction,
  clearEconomiesAction,
  clearFormDataAction,
  setFormData,
} from '@store/slices';
import dayjs from 'dayjs';

import { BankDetails, ContactDetails, IndividualMainInfo } from '../fields';
import styles from './styles.module.scss';

type IndividualMasterRegistrationFirstStepFormTypes = {
  employees: {
    lastName: string;
    firstName: string;
    middleName: string;
    inn: string;
    passportNumber: string;
    dateBirth: string | Date;
    gender: string;
    address: string;
    phone: string;
    additionalPhone: string;
    email: string;
    hasWhatsapp: boolean;
    hasTelegram: boolean;
  };
  businessActivityId: number;
  bankId: number;
  bankAddress: string;
  branchName: string;
  bankBik: string;
  usersAccountDetails: string;
};

export const IndividualMasterRegistrationFirstStep: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);

  const documents: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.physical].attachmentResponseDtos ||
      [],
    [currentWaterRegistry],
  );

  const attachments = documents?.filter(
    (att) => att.type === DocumentTypes.Passport,
  );

  const validateAttachments = useMemo(() => {
    return attachments?.some((att) => att.type === DocumentTypes.Passport);
  }, [attachments]);

  const onFinish = ({
    employees,
    ...otherProps
  }: IndividualMasterRegistrationFirstStepFormTypes) => {
    console.log('employees', employees);
    dispatch(
      setFormData({
        data: {
          ...otherProps,
          employees: [
            {
              ...employees,
              dateBirth: dayjs(employees.dateBirth).format('YYYY-MM-DD'),
            },
          ],
        },
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationIndividual}?${SectionQueryKey}=${CreateIndividualWaterRegistryQueryTypes.create}`,
    );
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.physical,
      }),
    );
    dispatch(clearEconomiesAction());
    navigate(Routes.WaterRegistryUsers);
  };

  useEffect(() => {
    if (
      Object.values(currentWaterRegistry[WaterUserTypes.physical]).length > 1
    ) {
      form.setFieldsValue(currentWaterRegistry[WaterUserTypes.physical]);
      form.setFieldValue(
        'employees',
        currentWaterRegistry[WaterUserTypes.physical].employees[0],
      );
      form.setFieldValue(
        ['employees', 'dateBirth'],
        dayjs(
          currentWaterRegistry[WaterUserTypes.physical].employees[0]?.dateBirth,
        ),
      );
    }
  }, [currentWaterRegistry]);

  return (
    <Form<IndividualMasterRegistrationFirstStepFormTypes>
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row justify="space-between" gutter={[0, 16]}>
        <Col>
          <InformationalBlock title="Данные водопользователя">
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <IndividualMainInfo />
              </Col>
              <Col span={24}>
                <ContactDetails />
              </Col>
              <Col span={24}>
                <BankDetails />
              </Col>
            </Row>
          </InformationalBlock>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Form.Item>
                <DangerButton size="large" onClick={onCancelButtonClick}>
                  {t('createIndividualWaterRegistry.buttons.cancel')}
                </DangerButton>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Row justify="end">
                <Space size="large">
                  <Form.Item>
                    <PrimaryButton
                      type="primary"
                      htmlType="submit"
                      disabled={!validateAttachments}
                      size="large"
                    >
                      {t('createLegalWaterRegistry.buttons.next')}
                    </PrimaryButton>
                  </Form.Item>
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
