import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { CreateButton, SectionTitle } from '@features/ui-kit-v2';
import { IAnnualPlanFilters } from '@models/annualPlan/enums/AnnualPlan';
import { isEmpty } from '@utils/utils';

import { useAnnualPlanYearlyTableFilters } from '../annualPlanYearlyTable/useAnnualPlanYearlyTableFilters';
import { AnnualPlanQuarterlyTableContainer } from './annualPlanQuarterlyTableContainer/AnnualPlanQuarterlyTableContainer';
import { AnnualPlanQuarterlyTableFilters } from './annualPlanQuarterlyTableFilters/AnnualPlanQuarterlyTableFilters';
import { useGetCurrentUser } from '@features/authentication';

export const AnnualPlanQuarterlyTable = () => {
  const [params] = useSearchParams();
  const { setAnnualPlanFilters } = useAnnualPlanYearlyTableFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setAnnualPlanFilters(
      isEmpty(newFilters)
        ? ({
            page: 1,
            size: 10,
            districtId: user?.owner?.district?.id.toString(),
            regionId: user?.owner?.region?.id.toString(),
          } as unknown as IAnnualPlanFilters)
        : (newFilters as unknown as IAnnualPlanFilters),
    );
  }, []);

  return (
    <>
      <Row gutter={12} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={20}>
          <SectionTitle background="cyan" title="Годовой план" />
        </Col>
        <Col span={4}>
          <CreateButton iconType="print">Печать</CreateButton>
        </Col>
      </Row>
      <AnnualPlanQuarterlyTableFilters />
      <AnnualPlanQuarterlyTableContainer />
    </>
  );
};
