import { Tag } from 'antd';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

import styles from './documents.module.scss';

export const useDocumentsColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование документа',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'type',
      key: 'type',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return <Tag className={styles.tag}>{record.type}</Tag>;
      },
    },
    {
      title: 'Просмотр',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText],
    },
  ];
  return { columns };
};
