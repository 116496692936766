import React, { useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import {
  ColorBlock,
  CustomModal,
  DefaultButton,
  ManageEventsPath,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetEmployeeOwnerListByIdQuery,
  useGetEmployeeOwnerListQuery,
} from '@store/gisproApi';
import { generateFio } from '@utils/generateFio/generateFio';

import { useAssignExecutorModal } from '../useAssignExecutorModal';
import { useLocation } from 'react-router-dom';

type formValuesType = {
  organizationId: string;
  executorId: string;
};

type AssignExecutorModalPropsType = {
  type: ManageEventsWorkTypes;
};

export const AssignExecutorModal: React.FC<AssignExecutorModalPropsType> = ({
  type,
}) => {
  const [form] = useForm();
  const location = useLocation();
  const elementColor = ManageEventsPath(location.pathname);
  const { isShow, setShow, setExecutorInfo } = useAssignExecutorModal(type);
  const [organizationId, setOrganizationId] = useState<number>(0);

  const { data: employeeOwnerList, isLoading: organizationsIsLoading } =
    useGetEmployeeOwnerListQuery();
  const { data: ownerList, isLoading: executorIsLoading } =
    useGetEmployeeOwnerListByIdQuery(organizationId || skipToken);

  const onClose = () => {
    setShow(false);
  };

  const handleChangeOrganization = (value: number) => {
    setOrganizationId(value);
    form.resetFields(['executorId']);
  };

  const onFinish = (values: formValuesType) => {
    const executorItem = ownerList.find(
      (item: any) => item.id === values.executorId,
    );
    if (executorItem) {
      const executorInfo = {
        id: executorItem.id,
        fio: executorItem.firstName,
        ownerId: values.organizationId,
        organization: employeeOwnerList.find(
          (item: any) => item.id === values.organizationId,
        ).name,
      };
      setExecutorInfo(executorInfo);
    } else {
      setExecutorInfo({
        id: values.executorId,
        fio: values.executorId,
        organization: values.organizationId,
      });
    }
    setShow(false);
  };

  return (
    <CustomModal
      centered
      title="Исполнитель"
      open={isShow}
      onCancel={onClose}
      width={650}
      footer={false}
    >
      <ColorBlock colorType={elementColor}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Исполнитель" required>
            <TooltipedInput size="large" disabled placeholder="Исполнитель" />
          </Form.Item>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="organizationId" label="Организация" required>
                <TooltipedSelect
                  size="large"
                  loading={organizationsIsLoading}
                  options={employeeOwnerList
                    ?.filter((item: any) => item.type === 3)
                    .map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  onChange={handleChangeOrganization}
                  placeholder="Организация"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="executorId" label="Сотрудник" required>
                <TooltipedSelect
                  size="large"
                  loading={executorIsLoading}
                  options={ownerList?.map((item: any) => ({
                    value: item.id,
                    label: generateFio(item),
                  }))}
                  placeholder="Сотрудник"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify="end">
            <Col>
              <DefaultButton size="large" onClick={onClose}>
                Отмена
              </DefaultButton>
            </Col>
            <Col>
              <PrimaryButton size="large" htmlType="submit">
                Назначить
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </ColorBlock>
    </CustomModal>
  );
};
