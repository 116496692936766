import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const WaterUserIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="37"
      height="23"
      viewBox="0 0 37 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2345_50935)">
        <path
          d="M18 0C19.3924 0 20.7277 0.559481 21.7123 1.55536C22.6969 2.55125 23.25 3.90195 23.25 5.31034C23.25 6.71874 22.6969 8.06944 21.7123 9.06532C20.7277 10.0612 19.3924 10.6207 18 10.6207C16.6076 10.6207 15.2723 10.0612 14.2877 9.06532C13.3031 8.06944 12.75 6.71874 12.75 5.31034C12.75 3.90195 13.3031 2.55125 14.2877 1.55536C15.2723 0.559481 16.6076 0 18 0ZM7.5 3.7931C8.34 3.7931 9.12 4.02069 9.795 4.43035C9.57 6.6 10.2 8.75448 11.49 10.4386C10.74 11.8952 9.24 12.8966 7.5 12.8966C6.30653 12.8966 5.16193 12.417 4.31802 11.5634C3.47411 10.7098 3 9.55202 3 8.34483C3 7.13764 3.47411 5.97989 4.31802 5.12627C5.16193 4.27266 6.30653 3.7931 7.5 3.7931ZM28.5 3.7931C29.6935 3.7931 30.8381 4.27266 31.682 5.12627C32.5259 5.97989 33 7.13764 33 8.34483C33 9.55202 32.5259 10.7098 31.682 11.5634C30.8381 12.417 29.6935 12.8966 28.5 12.8966C26.76 12.8966 25.26 11.8952 24.51 10.4386C25.8178 8.73084 26.4249 6.57906 26.205 4.43035C26.88 4.02069 27.66 3.7931 28.5 3.7931ZM8.25 19.3448C8.25 16.2041 12.615 13.6552 18 13.6552C23.385 13.6552 27.75 16.2041 27.75 19.3448V22H8.25V19.3448ZM0 22V19.7241C0 17.6152 2.835 15.84 6.675 15.3241C5.79 16.3559 5.25 17.7821 5.25 19.3448V22H0ZM36 22H30.75V19.3448C30.75 17.7821 30.21 16.3559 29.325 15.3241C33.165 15.84 36 17.6152 36 19.7241V22Z"
          fill="#4B60D8"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2345_50935">
          <rect width="37" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
