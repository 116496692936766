import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IrrigationSystemFilters } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { getIrrigationSystemFiltersSelector } from '@store/selectors';
import { changeIrrigationSystemFilters } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IrrigationSystemFilters>;
  setIrrigationSystemFilters: (
    filters: Partial<IrrigationSystemFilters>,
  ) => void;
};

export const useIrrigationSystemFilters = (isArchived = false): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getIrrigationSystemFiltersSelector);

  const setIrrigationSystemFilters = useCallback(
    (newFilters: Partial<IrrigationSystemFilters>) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        changeIrrigationSystemFilters({
          ...values,
          isArchived,
        } as IrrigationSystemFilters),
      );
    },
    [],
  );

  return { filters, setIrrigationSystemFilters };
};
