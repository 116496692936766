import React, { FC, useEffect } from 'react';

import { Breadcrumb, Col, Layout, Row } from 'antd';
import { Divider, InformationalBlock } from '@features/ui-kit';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  ReferenceBookTable,
  ReferenceBookCollapse,
} from '@features/referenceBook';

import styles from './ReferenceBookLayout.module.scss';

export const ReferenceBookLayout: FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle(t('headers.referenceBook'));
  }, []);

  return (
    <Layout className={styles.layout}>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.referenceBook'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <InformationalBlock>
        <Row gutter={8}>
          <Col span={8}>
            <ReferenceBookCollapse />
          </Col>
          <Col span={16}>
            <ReferenceBookTable />
          </Col>
        </Row>
      </InformationalBlock>
    </Layout>
  );
};
