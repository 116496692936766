import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionPenIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.25C7 11.9278 7.26116 11.6667 7.58333 11.6667H12.25C12.5722 11.6667 12.8333 11.9278 12.8333 12.25C12.8333 12.5722 12.5722 12.8333 12.25 12.8333H7.58333C7.26116 12.8333 7 12.5722 7 12.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1178 4.72495C13.0568 3.78221 13.0169 2.54421 12.3158 1.78606C11.9738 1.41638 11.4872 1.18025 10.936 1.16723C10.3828 1.15416 9.80958 1.36573 9.28067 1.80823C9.26714 1.81955 9.25413 1.83147 9.2417 1.84397L1.67661 9.43925C1.34992 9.7672 1.1665 10.2113 1.1665 10.6742V11.6621C1.1665 12.3046 1.68682 12.8333 2.3354 12.8333H3.31472C3.78005 12.8333 4.22623 12.648 4.55461 12.3183L12.1178 4.72495ZM10.0373 3.37921C9.80952 3.15141 9.44015 3.15141 9.21236 3.37921C8.98457 3.60702 8.98457 3.97636 9.21236 4.20417L9.7957 4.7875C10.0235 5.01531 10.3929 5.01531 10.6206 4.7875C10.8484 4.55969 10.8484 4.19035 10.6206 3.96254L10.0373 3.37921Z"
        fill="white"
      />
    </svg>
  );
};
