import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  IrrigationSystem,
  IrrigationSystemFilters,
} from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import concat from 'lodash/concat'; // TODO: change lodash to native implementation
import isEqual from 'lodash/isEqual';

export type IrrigationRegistryState = {
  irrigationSystem: IrrigationSystem | null;
  objectAttachments: any;
  documents: IAttachment[];
  isShowCreateIrrigationSystemActionModal: boolean;
  isShowWorkNotationModal: boolean;
  isShowCreateWaterObjectModal: boolean;
  isShowObjectsIrrigationSystemActionModal: boolean;
  createWaterObjectList: Array<Partial<IWaterObject>>;
  selectedWorkNotationIndex: number | null;
  irrigationSystemFilters: Partial<IrrigationSystemFilters>;
  isShowConfirmArchiveModal: boolean;
  irrigationSystemId: number | null;
};

const initialState: IrrigationRegistryState = {
  irrigationSystem: null,
  documents: [],
  objectAttachments: [],
  createWaterObjectList: [],
  isShowCreateIrrigationSystemActionModal: false,
  isShowObjectsIrrigationSystemActionModal: false,
  isShowCreateWaterObjectModal: false,
  isShowWorkNotationModal: false,
  selectedWorkNotationIndex: null,
  irrigationSystemFilters: {
    page: 1,
    size: 10,
  },
  isShowConfirmArchiveModal: false,
  irrigationSystemId: null,
};

const irrigationRegistrySlice = createSlice({
  name: 'irrigationRegistry',
  initialState,
  reducers: {
    changeIrrigationSystemFilters: (
      state,
      action: PayloadAction<IrrigationSystemFilters>,
    ) => {
      return {
        ...state,
        irrigationSystemFilters: action.payload,
      };
    },
    changeIrrigationSystemAction: (
      state,
      action: PayloadAction<IrrigationSystem>,
    ) => {
      return {
        ...state,
        irrigationSystem: {
          ...state.irrigationSystem,
          ...action.payload,
        },
      };
    },
    addAttachmentToIrrigationSystemAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentIds = concat(
        state.irrigationSystem?.attachmentIds || [],
        action.payload,
      );

      return {
        ...state,
        irrigationSystem: {
          ...(state.irrigationSystem || ({} as IrrigationSystem)),
          attachmentIds,
        },
      };
    },
    removeAttachmentFromIrrigationSystemAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const currentAttachmentIds = state.irrigationSystem?.attachmentIds || [];
      const attachmentIds: IAttachment[] = currentAttachmentIds.filter(
        (item) => {
          return !isEqual(item, action.payload);
        },
      );

      return {
        ...state,
        irrigationSystem: {
          ...((state.irrigationSystem || {}) as IrrigationSystem),
          attachmentIds,
        },
      };
    },
    clearIrrigationSystemAction: (state) => {
      return {
        ...state,
        irrigationSystem: null,
      };
    },
    setShowCreateIrrigationSystemActionModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowCreateIrrigationSystemActionModal: action.payload,
      };
    },
    setShowWorkNotationModalAction: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowWorkNotationModal: action.payload,
      };
    },
    setShowObjectsIrrigationSystemActionModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowObjectsIrrigationSystemActionModal: action.payload,
      };
    },
    setObjectDocuments: (state, action: PayloadAction<IAttachment>) => {
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };
    },
    addObjectDocumentsAction: (state, action: PayloadAction<IAttachment>) => {
      const documents = concat(state.documents, action.payload);
      return {
        ...state,
        documents,
      };
    },
    removeObjectDocuments: (state, action: PayloadAction<IAttachment>) => {
      return {
        ...state,
        documents: state.documents.filter((item) => {
          return !isEqual(item, action.payload);
        }),
      };
    },
    clearObjectDocuments: (state) => {
      return {
        ...state,
        documents: [],
      };
    },
    setShowCreateWaterObjectModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShowCreateWaterObjectModal: action.payload,
      };
    },
    addWaterObjectToCreateWaterObjectListAction: (
      state,
      action: PayloadAction<Partial<IWaterObject>>,
    ) => {
      const updatedList = concat(state.createWaterObjectList, action.payload);
      return {
        ...state,
        createWaterObjectList: updatedList,
      };
    },
    editWaterObjectListAction: (
      state,
      action: PayloadAction<Partial<IWaterObject>>,
    ) => {
      const updatedList = state.createWaterObjectList.map((item) =>
        item?.id === action?.payload?.id
          ? { ...item, ...action?.payload }
          : item,
      );
      return {
        ...state,
        createWaterObjectList: updatedList,
      };
    },
    removeWaterObjectToCreateWaterObjectListAction: (
      state,
      action: PayloadAction<number>,
    ) => {
      const updatedList = state.createWaterObjectList.filter(
        (_, index) => index !== action.payload,
      );

      return {
        ...state,
        createWaterObjectList: updatedList,
      };
    },
    clearWaterObjectToCreateWaterObjectListAction: (state) => {
      return {
        ...state,
        createWaterObjectList: [],
      };
    },
    setSelectedWorkNotationIndexAction: (
      state,
      action: PayloadAction<number>,
    ) => {
      return {
        ...state,
        selectedWorkNotationIndex: action.payload,
      };
    },
  },
});

export const {
  changeIrrigationSystemFilters,
  addObjectDocumentsAction,
  changeIrrigationSystemAction,
  addAttachmentToIrrigationSystemAction,
  removeAttachmentFromIrrigationSystemAction,
  clearIrrigationSystemAction,
  setShowCreateIrrigationSystemActionModalAction,
  setShowObjectsIrrigationSystemActionModalAction,
  setShowWorkNotationModalAction,
  setSelectedWorkNotationIndexAction,
  removeObjectDocuments,
  clearObjectDocuments,
  setObjectDocuments,
  setShowCreateWaterObjectModal,
  addWaterObjectToCreateWaterObjectListAction,
  editWaterObjectListAction,
  removeWaterObjectToCreateWaterObjectListAction,
  clearWaterObjectToCreateWaterObjectListAction,
} = irrigationRegistrySlice.actions;

export default irrigationRegistrySlice.reducer;
