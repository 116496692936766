import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Divider, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { IPlantingDataDto } from '@models/annualApplication/interfaces/annualApplication';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useGetPlantingListQuery } from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import {
  getSelectedFacilityIdSelector,
  getSelectedPlantingData,
  getSelectedWaterUserTypeSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';
import { toInteger } from '@utils/utils';

import { normalizeValue } from './normalizeValue';
import styles from './PlannedStructureCreateModal.module.scss';
import { usePlannedStructureModal } from './usePlannedStructureModal';

const pattern = /^(0(.[0-9]{1,2})?|1(.0{1,2})?|1)$/;

export const PlannedStructureCreateModal: React.FC = () => {
  const { isModalShow, setShowModal } = usePlannedStructureModal();
  const [form] = useForm();
  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector(getSelectedFacilityIdSelector);
  const selectedPlanting = useSelector(getSelectedPlantingData);
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);

  const isAssociation = toInteger(waterUserType) === WaterUserEnum.ASSOCIATION;

  const { data: plantingList = [] } = useGetPlantingListQuery();

  const onCancel = () => {
    setShowModal(false);
    dispatch(annualApplicationActions.setSelectedPlantingData(null));
  };

  const onFinish = (values: IPlantingDataDto) => {
    try {
      let plantingData = [];
      if (isAssociation) {
        plantingData = data?.find(
          (item) => item.id === (values.outletId as number),
        );
      } else {
        plantingData = data?.find(
          (item) => item.facilitiesId === (values.facilityId as number),
        );
      }
      dispatch(
        annualApplicationActions.addPlantingData({
          ...plantingData,
          ...values,
        }),
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      setShowModal(false);
      form.resetFields();
      dispatch(annualApplicationActions.setSelectedPlantingData(null));
    }
  };

  useEffect(() => {
    if (selectedPlanting) {
      form.setFieldsValue(selectedPlanting);
    }
  }, [selectedPlanting]);

  return (
    <CustomModal
      open={isModalShow}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={370}
      onCancel={onCancel}
      footer={<div />}
      title="Создание сева"
      centered
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ktp: 0.67,
          kvh: 0.85,
        }}
        className={styles.form}
      >
        <Form.Item
          name={isAssociation ? 'outletId' : 'facilityId'}
          label={
            isAssociation
              ? 'Наименование водовыпуска'
              : 'Наименование хозяйства'
          }
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            placeholder={
              isAssociation ? 'Выберите водовыпуск' : 'Выберите хозяйство'
            }
            options={(data || []).map((item) => ({
              ...item,
              label: item.name,
              value: isAssociation ? item.id : item.facilitiesId,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="foodPlantId"
          label="Наименование сельхозкультуры"
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            placeholder="Выберите сельхозкультуру"
            options={plantingList}
          />
        </Form.Item>
        <Form.Item
          name="totalArea"
          label="Площадь (ГА)"
          rules={[{ required: true }]}
        >
          <TooltipedInput placeholder="Введите площадь" type="number" />
        </Form.Item>
        <Form.Item
          name="ktp"
          label="КТП"
          rules={[{ required: true }, { pattern, message: '' }]}
          normalize={normalizeValue}
        >
          <TooltipedInput placeholder="Введите КТП" type="number" />
        </Form.Item>
        <Form.Item
          name="kvh"
          label="КВХ"
          normalize={normalizeValue}
          rules={[{ required: true }, { pattern, message: '' }]}
        >
          <TooltipedInput placeholder="Введите КВХ" type="number" />
        </Form.Item>
        <Form.Item className={styles.createButton}>
          <PrimaryButton size="large" htmlType="submit" title="Создать">
            Создать
          </PrimaryButton>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
