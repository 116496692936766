import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { colors } from '@theme/colors';

export const GeoPortalLayout: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: colors.background }}>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
