import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { EmployeeTypesEnum } from '@models/waterUser/enums/waterUserEnums';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { setActiveEmployeeIdAction } from '@store/slices';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

import { useEditEmployee } from '../../useEditEmployee';
import styles from './EditEmployeeModal.module.scss';

type AddEmployeeType = {
  positionEmployee: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  employeeId: number;
};

export const EditEmployeeModal: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const {
    isModalShown,
    currentEmployeeType,
    setShowModal,
    editEmployee,
    activeEmployeesId,
    employees,
  } = useEditEmployee();

  const { data: positionsList = [], isLoading: positionsListIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.PositionsList,
    });

  const onEditClick = useCallback(() => {
    form
      .validateFields()
      .then((employee: AddEmployeeType) => {
        if (activeEmployeesId !== null) {
          editEmployee(employee, activeEmployeesId);
        }
        dispatch(setActiveEmployeeIdAction(null));
        setShowModal(false);
        form.resetFields();
      })
      .catch((e) => {
        console.log('Validate errors', e);
      });
  }, [currentEmployeeType, activeEmployeesId]);

  const onCancelClick = useCallback(() => {
    form.resetFields();
    dispatch(setActiveEmployeeIdAction(null));
    setShowModal(false);
  }, []);

  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue('attachmentResponseDtos') || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue('attachmentResponseDtos', updatedAttachments);
  };

  useEffect(() => {
    if (activeEmployeesId !== null) {
      form.setFieldsValue(
        employees.find(
          (employee) => employee?.employeeId === activeEmployeesId,
        ),
      );
    }
  }, [activeEmployeesId]);

  return (
    <CustomModal
      centered
      title={t('editEmployee.title')}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      open={isModalShown}
      onCancel={onCancelClick}
      width={370}
      footer={
        <Row justify="center">
          <Col>
            <PrimaryButton size="large" htmlType="submit" onClick={onEditClick}>
              {t('editEmployee.buttons.edit')}
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form<AddEmployeeType> form={form} autoComplete="off" layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.role')}
              name="positionEmployee"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                placeholder="Выберите Должность"
                loading={positionsListIsLoading}
                options={positionsList}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.lastName')}
              name="lastName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Фамилию" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.firstName')}
              name="firstName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Имя" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.middleName')}
              name="middleName"
              rules={[{ required: true }, lettersValidator]}
            >
              <TooltipedInput placeholder="Введите Отчество" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('createIndividualWaterRegistry.modal.pin')}
              name="inn"
              rules={[{ required: true }, { len: 14 }, numbersValidator]}
            >
              <TooltipedInput maxLength={14} placeholder="ПИН" size="large" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.phone')}
              name="phone"
              rules={[{ required: true }, phoneNumberValidator]}
              normalize={mapPhoneNumberToLong}
            >
              <TooltipedInput
                size="large"
                placeholder="Введите Номер телефона"
                addonBefore="+996"
                onChange={changeCursorPhoneNumberInput}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t('addEmployee.placeholders.email')}
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <TooltipedInput placeholder="Email" size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <UploadModal setShow={setShow} show={show} onLoad={onAttachmentUpload} />
    </CustomModal>
  );
};
