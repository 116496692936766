import { CustomCard, DangerButton, PrimaryButton } from '@features/ui-kit';
import { MenuTab } from '@features/ui-kit/menuTab/MenuTab';
import {
  useMaterialsCategoryControllerDeleteMutation,
  useMaterialsCategoryControllerFindAllQuery,
} from '@store/api/materialsApi';
import { Col, Menu, notification, Row, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { ReferenceMaterialCategoryModal } from '../referenceMaterialsCategoryModal/ReferenceMaterialsCategoryModal';
import { EditIcon } from '@icons/EditIcon';
import { DeleteIcon } from '@icons/DeleteIcon';
import { useSearchParams } from 'react-router-dom';
import styles from './ReferenceMaterialsList.module.scss';
export const ReferenceMaterialsList = () => {
  const { data } = useMaterialsCategoryControllerFindAllQuery();
  const [isModalShow, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [params, setSearchParams] = useSearchParams();

  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const [deleteMaterialsCategory] =
    useMaterialsCategoryControllerDeleteMutation();
  const handleEdit = (e: any, val: any) => {
    e.stopPropagation();
    setInitialValues(val);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = (e: any, { id }: { id: number }) => {
    e.stopPropagation();
    deleteMaterialsCategory({ id }).unwrap().then((data) => {
      if (data.affected > 0) {
        notification.success({
          message: 'Категория успешно удалена',
        });
      } else {
        notification.error({
          message: 'Произошла ошибка при удалении категории',
        });
      }
    }).catch((error) => {
      notification.error({
        message: error?.data?.message ?? 'Произошла ошибка при удалении категории',
      });
    });
  };

  const changeContentHandle = (value: any) => {
    const updatedSearchParams = {
      categoryId: value.key as string,
    };
    setSearchParams(updatedSearchParams);
  };

  const categories = useMemo(
    () =>
      data?.map((item) => ({
        key: item.id,
        label: (
          <Row align="middle">
            <Col span={20}>
              <Typography.Text>{item.title} </Typography.Text>
            </Col>
            <Col>
              <PrimaryButton
                size="small"
                key={item.id}
                style={{ borderRadius: 7 }}
                onClick={(e) => handleEdit(e, item)}
                icon={<EditIcon />}
              />
            </Col>
            <Col>
              <DangerButton
                size="small"
                style={{ borderRadius: 7 }}
                onClick={(e) => deleteItem(e, item)}
                icon={<DeleteIcon />}
              />
            </Col>
          </Row>
        ),
      })),
    [data],
  );
  return (
    <CustomCard
      title={
        <Row justify="space-between" align="middle">
          <Col>Категорий</Col>
          <Col>
            <PrimaryButton onClick={handleCreate}>
              Создать категорию
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Menu
        mode="inline"
        items={categories}
        defaultSelectedKeys={filtersParams?.categoryId}
        onClick={changeContentHandle}
        className={styles.menu}
      />
      {isModalShow && (
        <ReferenceMaterialCategoryModal
          isModalShow={isModalShow}
          setShowModal={setShowModal}
          initialValues={initialValues}
        />
      )}
    </CustomCard>
  );
};
