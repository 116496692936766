import { useState } from 'react';

import { Col, Form, Row } from 'antd';

import {
  CustomModalV2,
  PrimaryButtonV2,
  TooltipedInputV2,
} from '@features/ui-kit-v2';

export const AddReseroirDataModal = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const onClose = () => {
    setShowModal(false);
  };
  return (
    <CustomModalV2
      footer={false}
      title="Введите объем воды водохранилища"
      centered
      open={showModal}
      width={600}
      onCancel={onClose}
    >
      <Form layout="vertical">
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Form.Item
              label="Наименование водохранилища: 12.12.2024"
              rules={[{ required: true }]}
            >
              <TooltipedInputV2 suffix="m3" type="number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={15} style={{ justifyContent: 'end' }}>
              <Col>
                <PrimaryButtonV2 title="Отмена" />
              </Col>
              <Col>
                <PrimaryButtonV2 title="Сохранить" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModalV2>
  );
};
