import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const NavGISIcon: React.FC<IconProps> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.1635 3.069C2 3.39 2 3.81 2 4.65V5.1105L15.4925 3.8835C15.4782 3.5175 15.44 3.273 15.3365 3.069C15.1927 2.78677 14.9632 2.55731 14.681 2.4135C14.36 2.25 13.94 2.25 13.1 2.25H4.4C3.56 2.25 3.14 2.25 2.819 2.4135C2.53677 2.55731 2.30731 2.78677 2.1635 3.069ZM15.5 5.3895L11.8512 5.721L13.7308 15.7463C14.1748 15.7365 14.453 15.7027 14.681 15.5865C14.9632 15.4427 15.1927 15.2132 15.3365 14.931C15.5 14.61 15.5 14.19 15.5 13.35V5.3895ZM12.206 15.75L10.3505 5.8575L2 6.6165V13.35C2 14.19 2 14.61 2.1635 14.931C2.30731 15.2132 2.53677 15.4427 2.819 15.5865C3.14 15.75 3.56 15.75 4.4 15.75H12.206ZM9.125 11.2725C9.125 12.891 7.52 14.028 6.82025 14.4473C6.72359 14.5055 6.61287 14.5363 6.5 14.5363C6.38713 14.5363 6.27641 14.5055 6.17975 14.4473C5.48 14.028 3.875 12.891 3.875 11.2718C3.875 9.684 5.147 8.625 6.5 8.625C7.90025 8.625 9.125 9.684 9.125 11.2725Z"
            fill="#61C63E" />
      <path
        d="M6.5 12C6.91421 12 7.25 11.6642 7.25 11.25C7.25 10.8358 6.91421 10.5 6.5 10.5C6.08579 10.5 5.75 10.8358 5.75 11.25C5.75 11.6642 6.08579 12 6.5 12Z"
        fill="#61C63E" />
    </svg>
  );
};
