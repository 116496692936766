import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Layout, Row } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import {
  AnnualPlanQuarterlyTable,
  AnnualPlanYearlyTable,
} from '@features/annualPlan';
import { CustomSegmented } from '@features/ui-kit-v2';
import { AnnualPlanTableQueryTypes } from '@models/annualPlan/interfaces/annualPlanEnum';

import styles from './AnnualPlanTableLayout.module.scss';
import { useGetCurrentUser } from '@features/authentication';

const options: SegmentedLabeledOption[] = [
  {
    value: AnnualPlanTableQueryTypes.quarterly,
    label: 'Квартальный',
  },
  {
    value: AnnualPlanTableQueryTypes.yearly,
    label: 'Годовой',
  },
];

export const AnnualPlanTableLayout = () => {
  const [params, setSearchParams] = useSearchParams();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const queryKey = useMemo(
    () => params.get('viewType') || AnnualPlanTableQueryTypes.quarterly,
    [params],
  );

  let Component;

  switch (queryKey) {
    case AnnualPlanTableQueryTypes.quarterly:
      Component = AnnualPlanQuarterlyTable;
      break;
    case AnnualPlanTableQueryTypes.yearly:
      Component = AnnualPlanYearlyTable;
      break;
    default:
      Component = AnnualPlanQuarterlyTable;
  }

  const onTableTypeChange = (value: SegmentedValue) => {
    const searchParams = new URLSearchParams({
      viewType: value as string,
      regionId: user?.owner?.region?.id.toString() || '',
      districtId: user?.owner?.district?.id.toString() || '',
    });
    setSearchParams(searchParams);
  };

  return (
    <Layout>
      <Row className={styles.controls}>
        <CustomSegmented
          options={options}
          colorType="primary"
          defaultValue={AnnualPlanTableQueryTypes.quarterly}
          onChange={onTableTypeChange}
          queryParams={{
            queryKey: 'viewType',
            queryType: AnnualPlanTableQueryTypes,
          }}
        />
      </Row>
      <div>
        <Component />
      </div>
    </Layout>
  );
};
