import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Space } from 'antd';

import { CustomModalV2, PrimaryButtonV2 } from '@features/ui-kit-v2';

import { useActionSelect } from '../../useActionSelect';

type PropsType = {
  onPermissionsOpen: () => void;
  onComplete: () => void;
  onViewCard?: () => void;
  onAnnualRequest?: () => void;
};
export const LegalSelectModal: React.FC<PropsType> = ({
  onPermissionsOpen,
  onComplete,
  onViewCard,
  onAnnualRequest,
}) => {
  const { t } = useTranslation();
  const { isModalShown } = useActionSelect();

  return (
    <CustomModalV2
      centered
      title="Выбор действия"
      open={isModalShown}
      width={435}
      closeIcon={null}
      footer={<div />}
    >
      <Row justify="center">
        <Space size="large" direction="vertical">
          <PrimaryButtonV2
            style={{ width: 350 }}
            type="primary"
            onClick={onViewCard}
            title="Просмотр карточки"
          />
          <PrimaryButtonV2
            onClick={onPermissionsOpen}
            style={{ width: 350 }}
            type="primary"
            title="Выдать доступы"
          />
          <Row>
            <PrimaryButtonV2
              style={{ width: 350, whiteSpace: 'break-spaces' }}
              type="primary"
              title="Годовая заявка"
              onClick={onAnnualRequest}
            />
          </Row>
          <PrimaryButtonV2
            style={{ width: 350 }}
            type="primary"
            onClick={onComplete}
            title="Завершить"
          />
          <PrimaryButtonV2
            style={{ width: 350, whiteSpace: 'break-spaces' }}
            type="primary"
            title="Перейти на главную страницу"
            onClick={onComplete}
          />
        </Space>
      </Row>
    </CustomModalV2>
  );
};
