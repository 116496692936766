import React from 'react';
import { useParams } from 'react-router-dom';

import { Form, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '@store/gisproApi';

import styles from './descriptionTask.module.scss';

export const DescriptionTask = () => {
  const { id } = useParams();

  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );
  return (
    <CustomCard title="Описание задачи">
      <div className={styles.row}>
        <div className={styles.rowItem}>
          <Form.Item label="Код задачи">
            <Typography.Text strong>
              {constructionBlock && constructionBlock.taskId}
            </Typography.Text>
          </Form.Item>
        </div>
        <div className={styles.rowItem}>
          <Form.Item label="Дата:">
            <Typography.Text strong>
              {constructionBlock && constructionBlock.inspectionDate}
            </Typography.Text>
          </Form.Item>
        </div>
        <div className={styles.rowItem}>
          <Form.Item label="Сроки (план):">
            <Typography.Text strong>
              {constructionBlock &&
                `${constructionBlock.planStartDate || '-'} ${
                  constructionBlock.planEndDate || '-'
                }`}
            </Typography.Text>
          </Form.Item>
        </div>
        <div className={styles.rowItem}>
          <Form.Item label="Сроки (факт):">
            <Typography.Text strong>
              {constructionBlock &&
                `${constructionBlock.factStartDate || '-'} ${
                  constructionBlock.factEndDate || '-'
                }`}
            </Typography.Text>
          </Form.Item>
        </div>
      </div>
    </CustomCard>
  );
};
