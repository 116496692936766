import { createSelector } from '@reduxjs/toolkit';
import { ReservoirMonitoringState } from '@store/slices/reservoirMonitoring/reservoirMonitoringSlice';

type WithReservoirMonitoringState = {
  reservoirMonitoring: ReservoirMonitoringState;
};

export const reservoirMonitoringStateSelector = (
  state: WithReservoirMonitoringState,
): ReservoirMonitoringState => state.reservoirMonitoring;

export const getReservoirMonitoringFiltersSelector = createSelector(
  reservoirMonitoringStateSelector,
  (reservoirMonitoring) => {
    return reservoirMonitoring.reservoirMonitoringFilters;
  },
);
