export const mapPhoneNumberToLong = (phoneNumber: string): string =>
  [...phoneNumber.matchAll(/([0-9])/gi)]
    .slice(0, 9)
    .map((m, i) => {
      if (i === 3) {
        return `-${m[0]}`;
      }
      if (i === 5) {
        return `-${m[0]}`;
      }
      if (i === 7) {
        return `-${m[0]}`;
      }
      return m[0];
    })
    .join('');
