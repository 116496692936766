import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderAction } from './renderAction';
import { renderBudget } from './renderBudget';
import { renderCheck } from './renderCheck';
import { renderDate } from './renderDate';
import { renderDeadline } from './renderDeadline';
import { renderDecadeFactCell } from './renderDecadeFactCell';
import { renderDecadePlanCell } from './renderDecadePlanCell';
import { renderGeo } from './renderGeo';
import { renderIrrigationControl } from './renderIrrigationControl';
import { renderNumber } from './renderNumber';
import { renderQuantity } from './renderQuantity';
import { renderSelect } from './renderSelect';
import { renderSelectTag } from './renderSelectTag';
import { renderStatus } from './renderStatus';
import { renderString } from './renderString';
import { renderTag } from './renderTag';
import { renderWaterRegistryControl } from './renderWaterRegistryControl';

/**
 * deprecated
 */
export class RendererFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { xtype } = props.customColumn;
    let renderer = renderString;

    switch (xtype) {
      case CustomTableColumnType.ActionColumn:
        renderer = renderAction;
        break;
      case CustomTableColumnType.String:
        renderer = renderString;
        break;
      case CustomTableColumnType.NumberColumn:
        renderer = renderNumber;
        break;
      case CustomTableColumnType.DateColumn:
        renderer = renderDate;
        break;
      case CustomTableColumnType.CheckColumn:
        renderer = renderCheck;
        break;
      case CustomTableColumnType.SelectColumn:
        renderer = renderSelect;
        break;
      case CustomTableColumnType.SelectColumntag:
        renderer = renderSelectTag;
        break;
      case CustomTableColumnType.TagColumn:
        renderer = renderTag;
        break;
      case CustomTableColumnType.DecadeEditableCell:
        renderer = renderDecadePlanCell;
        break;
      case CustomTableColumnType.DecadeCell:
        renderer = renderDecadeFactCell;
        break;
      case CustomTableColumnType.Geo:
        renderer = renderGeo;
        break;
      case CustomTableColumnType.IrrigationControl:
        renderer = renderIrrigationControl;
        break;
      case CustomTableColumnType.WaterRegistryControl:
        renderer = renderWaterRegistryControl;
        break;
      case CustomTableColumnType.Quentity:
        renderer = renderQuantity;
        break;
      case CustomTableColumnType.Status:
        renderer = renderStatus;
        break;
      case CustomTableColumnType.Budget:
        renderer = renderBudget;
        break;
      case CustomTableColumnType.Deadline:
        renderer = renderDeadline;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
