import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@store/index';
import { getShowCreateCollectionPointModalSelector } from '@store/selectors';
import { setShowCreateCollectionPointModal } from '@store/slices';

export const useCreatePointCollectionModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isModalShow = useSelector(getShowCreateCollectionPointModalSelector);

  const setShowModal = (isShow: boolean) => {
    dispatch(setShowCreateCollectionPointModal(isShow));
  };

  return { isModalShow, setShowModal };
};
