import React, { useCallback, useState } from 'react';

import { Button, Table } from 'antd';

import styles from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/EditableTable/EditableTable.module.scss';

import { EditableCell } from './EditableCell';
import { EditableRow } from './EditableRow';

interface EditableTableProps {
  dataSource: Array<{ [key: string]: string | number }>;
  defaultColumns: any;
  setData: (newData: Array<{ [key: string]: string | number }>) => void;
  isLoading?: boolean;
}

export const EditableTable: React.FC<EditableTableProps> = ({
  dataSource,
  defaultColumns,
  setData,
  isLoading,
}) => {
  const handleSave = useCallback(
    (row: { [name: string]: string | number }) => {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.id === item.id);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setData(newData);
    },
    [dataSource],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumns = useCallback(() => {
    return defaultColumns.map((col: { [key: string]: string | number }) => {
      if (!col.editable) {
        return {
          ...col,
          onCell: (record: { [key: string]: string | number }) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
      }
      if (Array.isArray(col.children)) {
        return {
          ...col,
          children: col.children.map(
            (item: { [key: string]: string | number }, index) => {
              if (item.editable) {
                return {
                  ...item,
                  onCell: (record: { [key: string]: string | number }) => ({
                    record,
                    editable: col.editable,
                    dataIndex: item.dataIndex,
                    title: col.title,
                    index: `${item.dataIndex}${index + 1}`,
                    handleSave,
                  }),
                };
              }
              return item;
            },
          ),
        };
      }
      return {
        ...col,
        onCell: (record: { [key: string]: string | number }) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  }, [dataSource]);
  return (
    <Table
      components={components}
      bordered
      loading={isLoading}
      dataSource={dataSource}
      columns={getColumns()}
      size="small"
      pagination={false}
      className={styles.table}
    />
  );
};
