import {
  typeWaterLogAccountingBalanceSheetProps
} from '@features/waterAccounting/waterLogAccounting/waterLogAccountingBalanceSheet/WaterLogAccountingBalanceSheet';
import { useParams } from 'react-router-dom';

import { notification, Row, Typography } from 'antd';

import { DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useGetWaterAccountingBalanceSheetQuery,
  useSaveWaterAccountingBalanceSheetMutation,
} from '@store/gisproApi';
import { get } from '@utils/utils';

import { useWaterLogAccoutingBalanceSheetTableColumns } from './useWaterLogAccoutingBalanceSheetTableColumns';
import { waterLogAccountingBalanceSheetDataMapper } from './waterLogAccountingBalanceSheetDataMapper';
import { WaterLogAccountingBalanceSheetSummary } from './WaterLogAccountingBalanceSheetSummary';
import { useMemo } from 'react';

export const WaterLogAccountingBalanceSheetTable: React.FC<typeWaterLogAccountingBalanceSheetProps> = ({ balanceSheet, refetch }) => {
  const { outletId, year, decade, month } = useParams();
  const [saveBalanceSheet] = useSaveWaterAccountingBalanceSheetMutation();
  const { columns } = useWaterLogAccoutingBalanceSheetTableColumns({
    month: month as MonthEnums,
    decade: decade as DecadesEnum,
    year: year || '2024',
  });

  const getCellKey = (record: JsonRecord, column: TableColumn) => {
    const uniqueId = [record.year, record.month, record.decade, ...(column.decimalIndex || []), column.dataIndex].join('-');
    return uniqueId;
  };

  const dataSource = useMemo(
    () => waterLogAccountingBalanceSheetDataMapper(balanceSheet?.items),
    [balanceSheet],
  );

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const { decimalIndex, dataIndex } = column;

    const getId = get(record, [...(decimalIndex || []), 'id']);
    const getValue = get(record, [...(decimalIndex || []), dataIndex]);

    saveBalanceSheet([{ id: getId, value: getValue }])
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  return (
    <CustomCard
      style={{ width: '100%' }}
    >
      <TableCustom
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => [record.associationId, record.year, record.month, record.decade].join('-')}
        scroll={{ x: 1770, y: 550 }}
        cellKey={getCellKey}
        actionProps={{
          onEditDecimalSaveClick,
        }}
        summary={(data: any) => (
          <WaterLogAccountingBalanceSheetSummary
            data={balanceSheet}
            waterUserData={data}
          />
        )}
      />
    </CustomCard>
  );
};
