import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Card, Col, Row, Space, Typography } from 'antd';

import { Links } from '@enums/links';
import { Routes } from '@enums/routes';
import { getDashboardtCardItem } from '@features/manageEvents/mappers/getDashboardtCardItem';
import { useOperationalBlockEventListQuery } from '@store/gisproApi';
import { saveTableOptions } from '@store/slices';
import { get } from '@utils/utils';

import styles from './dashboard.module.scss';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { CubeIcon } from '@icons/CubeIcon';
import { StatusType } from '@enums/statusType';
import { ACCESS } from '@enums/permisson';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { saveUrl } from '@utils/returnUrl';

export const OperationalCard: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useOperationalBlockEventListQuery({});
  const defaultValue = 0;

  const saveAction = saveUrl();

  const hasListPermission = useHasUserPermission(
    ACCESS.WORK_MANAGEMENT_OPERATIONAL_LIST_VIEW,
  );

  const dispatch = useDispatch();

  const handleLinkClick = (item: number | string | null) => {
    let optionsToSend: { [key: string]: number | string } = {};

    if (typeof item === 'number') {
      optionsToSend = { categoryId: item };
    } else if (typeof item === 'string') {
      optionsToSend = { status: item };
    } else {
      optionsToSend = {};
    }

    dispatch(saveTableOptions(optionsToSend));
  };

  const operationalElements = {
    byCategory: [
      {
        count: get(data, 'currentWork.totalCount', defaultValue),
        label: t('manageEvents.operationalBlock.currentRepairWork'),
        icon: Links.Wheel,
        categoryId: 2792,
      },
      {
        count: get(data, 'capitalWork.totalCount', defaultValue),
        label: t('manageEvents.operationalBlock.majorRepairWork'),
        icon: Links.Tools,
        categoryId: 2793,
      },
      {
        count: get(data, 'emergencyWork.totalCount', defaultValue),
        label: t('manageEvents.operationalBlock.emergencyWork'),
        icon: Links.Warn,
        categoryId: 2794,
      },
      {
        count: get(data, 'rehabilitationWork.totalCount', defaultValue),
        label: t('manageEvents.operationalBlock.rehabilitation'),
        icon: Links.Settings,
        categoryId: 2795,
      },
      {
        count: get(data, 'reconstructionWork.totalCount', defaultValue),
        label: t('manageEvents.operationalBlock.reconstruction'),
        icon: Links.Gaika,
        categoryId: 2796,
      },
    ],
    byStatus: [
      {
        count: get(data, 'status.onPlanned', defaultValue),
        label: t('manageEvents.preparatoryStage'),

        status: StatusType.OnPreparation,
      },
      {
        count: get(data, 'status.onRealization', defaultValue),
        label: t('manageEvents.onSale'),

        status: StatusType.OnRealization,
      },
      {
        count: get(data, 'status.onControl', defaultValue),
        label: t('manageEvents.onControl'),

        status: StatusType.OnControl,
      },
      {
        count: get(data, 'status.completed', defaultValue),
        label: t('manageEvents.completedOnTime'),

        status: StatusType.Completed,
      },
      {
        count: get(data, 'status.expired', defaultValue),
        label: t('manageEvents.overdue'),
        status: StatusType.CompletedNotOnTime,
      },
    ],
    moneySums: [
      {
        count: get(data, 'finance.totalBudget', defaultValue),
        label: t('manageEvents.financialBlock.totalPlanned'),
        icon: Links.List,
      },
      {
        count: get(data, 'finance.realization', defaultValue),
        label: t('manageEvents.financialBlock.soldForAmount'),
        icon: Links.Dollar,
      },
      {
        count: get(data, 'finance.remains', defaultValue),
        label: t('manageEvents.financialBlock.remainder'),
        icon: Links.Remainder,
      },
      {
        count: get(data, 'finance.onRealization', defaultValue),
        label: t('manageEvents.financialBlock.inImplementation'),
        icon: Links.Implementation,
      },
    ],
    total: [
      {
        count: get(data, 'currentWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'currentWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.currentRepairWork'),
        icon: Links.Wheel,
      },
      {
        count: get(data, 'capitalWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'capitalWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.majorRepairWork'),
        icon: Links.Tools,
      },
      {
        count: get(data, 'emergencyWork.totalPlanBudget', defaultValue),
        secondCount: get(data, 'emergencyWork.totalFactBudget', defaultValue),
        label: t('manageEvents.operationalBlock.emergencyWork'),
        icon: Links.Warn,
      },
      {
        count: get(data, 'rehabilitationWork.totalPlanBudget', defaultValue),
        secondCount: get(
          data,
          'rehabilitationWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.operationalBlock.rehabilitation'),
        icon: Links.Settings,
      },
      {
        count: get(data, 'reconstructionWork.totalPlanBudget', defaultValue),
        secondCount: get(
          data,
          'reconstructionWork.totalFactBudget',
          defaultValue,
        ),
        label: t('manageEvents.operationalBlock.reconstruction'),
        icon: Links.Gaika,
      },
    ],
  };
  return (
    <div className={styles.container}>
      <InformationalBlock
        title={
          <Row justify="space-between" align="middle">
            <Row align="middle" gutter={8}>
              <Col style={{ display: 'flex' }}>
                <CubeIcon />
              </Col>
              <Col>
                <Link
                  to={`${Routes.ManageEventsSchedule}/?section=operational`}
                  className={styles.cardTitle}
                >
                  {t('operational-block')}
                </Link>
              </Col>
            </Row>
            <Row gutter={8} align="middle">
              <Col>
                {hasListPermission ? (
                  <Row onClick={saveAction} gutter={8} align="middle">
                    <Col>
                      <Link
                        to={`${Routes.ManageEventsSchedule}/?section=operational`}
                        onClick={() => {
                          handleLinkClick(null);
                        }}
                      >
                        <PrimaryButton className={styles.btn}>
                          {t('list')}
                        </PrimaryButton>
                      </Link>
                    </Col>

                    <Col>
                      <Link
                        // onClick={saveAction}
                        to={`${Routes.ManageEventsGanttDiagram}/?section=operationalBlock`}
                      >
                        <PrimaryButton className={styles.btn}>
                          {t('ganttChart')}
                        </PrimaryButton>
                      </Link>
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col>
                <div className={styles.taskCount}>
                  {t('totalTasks')}: {data?.totalCount}
                </div>
              </Col>
            </Row>
          </Row>
        }
      >
        <Row gutter={8}>
          <Col span={12}>
            <CustomCard title="Общие данные">
              <Row justify="space-between" gutter={32}>
                <Col span={12}>
                  <Space direction="vertical" className={styles.section}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          {t('byCategory')}:
                        </Typography.Text>
                      </Col>
                      <Col span={5} className={styles.countTitle}>
                        Кол-во:
                      </Col>
                    </Row>
                    <Divider withoutMargin />
                    {operationalElements.byCategory.map((item) => (
                      <Link
                        key={item.categoryId}
                        to={`${Routes.ManageEventsSchedule}/?type=operational`}
                        onClick={() => handleLinkClick(item.categoryId)}
                      >
                        <Space direction="vertical" className={styles.section}>
                          {getDashboardtCardItem([item])}
                        </Space>
                      </Link>
                    ))}
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" className={styles.section}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          {t('byStatus')}:
                        </Typography.Text>
                      </Col>
                      <Col span={5} className={styles.countTitle}>
                        Кол-во:
                      </Col>
                    </Row>
                    <Divider withoutMargin />
                    {operationalElements.byStatus.map((item) => (
                      <Link
                        key={item.status}
                        to={`${Routes.ManageEventsSchedule}/?section=operational`}
                        onClick={() => handleLinkClick(item.status)}
                      >
                        <Space direction="vertical" className={styles.section}>
                          {getDashboardtCardItem([item])}
                        </Space>
                      </Link>
                    ))}
                  </Space>
                </Col>
              </Row>
            </CustomCard>
          </Col>
          <Col span={12}>
            <CustomCard title="Финансовые данные">
              <Row justify="space-between" gutter={32}>
                <Col span={12}>
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          По наименованиям:
                        </Typography.Text>
                      </Col>
                      <Col span={5} className={styles.countTitle}>
                        Сом:
                      </Col>
                    </Row>
                    <Divider withoutMargin />

                    <Space direction="vertical" className={styles.section}>
                      {getDashboardtCardItem(
                        operationalElements.moneySums as any,
                      )}
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" className={styles.section}>
                    <Row>
                      <Col flex={1}>
                        <Typography.Text className={styles.typeTitle}>
                          По категориям:
                        </Typography.Text>
                      </Col>
                      <Col span={5} className={styles.countTitle}>
                        План (сом):
                      </Col>
                      <Col span={5} className={styles.countTitle}>
                        Факт (сом):
                      </Col>
                    </Row>
                    <Divider withoutMargin />
                    <Space direction="vertical" style={{ display: 'flex' }}>
                      {getDashboardtCardItem(operationalElements.total as any)}
                    </Space>
                  </Space>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>
      </InformationalBlock>
    </div>
  );
};
