import { Table, Typography } from 'antd';

import {
  IAnnualApplicationCalculations,
  IQuarter,
} from '@models/annualApplication/interfaces/annualApplication';

import styles from '../tables.module.scss';

interface IEstimatedFinancialPlanSummary {
  data: readonly IQuarter[];
}

export const EstimatedFinancialPlanSummary: React.FC<
  IEstimatedFinancialPlanSummary
> = ({ data }) => {
  const summaryFn = (dataArg: readonly IQuarter[]) => {
    const summaryData = dataArg.reduce(
      (acc, el) => {
        acc.totalArea += el.hectaresIrrigation;
        acc.totalPrice += el.waterSupplyCost;
        acc.totalWaterSupplyPerMeters += el.waterSupplyVolumeCubicMeters;
        acc.totalTarif += el.waterTariffForOneCubicMeter;
        return acc;
      },
      {
        totalPrice: 0,
        totalArea: 0,
        totalTarif: 0,
        totalWaterSupplyPerMeters: 0,
      },
    );
    return summaryData;
  };
  const generalData = summaryFn(data);
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} align="left">
        <Typography.Text className={styles.totalLabel} strong>
          Итого:
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} align="left">
        <Typography.Text>{generalData.totalArea.toFixed(2)}</Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} align="left">
        <Typography.Text>
          {generalData.totalWaterSupplyPerMeters.toFixed(2)}
        </Typography.Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} align="left">
        <Typography.Text />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4} align="left">
        <Typography.Text>{generalData.totalPrice.toFixed(2)}</Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
