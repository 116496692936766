import { Layout } from 'antd';

import { GeoPortalCard } from '@features/geoPortal';
import { GeoPortalMap } from '@features/geoPortal/new/geoPortalMap/GeoPortalMap';
import { MapStore } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';

export const GeoPortalCardLayout: React.FC = () => {
  return (
    <Layout style={{ height: '100%' }}>
      <MapStore>
        <GeoPortalMap />
      </MapStore>
    </Layout>
  );
};
