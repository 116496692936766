import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { DocumentTypes } from '@enums/documentTypes';
import {
  CustomCard,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  UploadButtonSmall,
} from '@features/ui-kit';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { IOtherTask } from '@models/manageEvents/interfaces/otherTask';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateOtherTaskReportMutation,
  useGetOtherTaskByIdQuery,
  useSaveOtherTaskReportMutation,
} from '@store/gisproApi';
import { getOtherTaskBlockSelector } from '@store/selectors';
import dayjs from 'dayjs';
import { clone } from 'lodash'; // TODO: change lodash to native implementation
import { toInteger } from '@utils/utils';

import styles from './OtherTasksCard.module.scss';
import { useAttachDocumentModal } from '@features/manageEvents';

export const OtherReport: React.FC = () => {
  const [form] = Form.useForm();
  const otherTaskBlock = useSelector(getOtherTaskBlockSelector);

  const { setShow: setAttachmentDocumentShow, setDocType } =
    useAttachDocumentModal();
  const { id } = useParams();

  const [createOtherTaskReport] = useCreateOtherTaskReportMutation();
  const [saveOtherTaskBlockReport] = useSaveOtherTaskReportMutation();
  const { data: otherTask, refetch } = useGetOtherTaskByIdQuery(
    id || skipToken,
  );

  const createReportHandle = () => {
    if (otherTask?.id) {
      createOtherTaskReport(otherTask?.id)
        .unwrap()
        .then(() => {
          refetch();
          notification.success({
            message: 'Вы успешно создали отчет',
          });
        })
        .catch((err) => {
          console.log('err is ', err);
          notification.error({
            message: 'Не удалось создать отчет',
          });
        });
    }
  };

  const onFinish = (values: any) => {
    saveOtherTaskBlockReport({
      ...values,
      id: otherTask?.id,
      attachments: otherTaskBlock.attachments?.map((item) => {
        return {
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          type: item.type,
          extension: item.extension,
          name: item.name,
          description: '',
        };
      }),
    })
      .unwrap()
      .then(() => {
        refetch();
        notification.success({
          message: 'Вы успешно сохранили отчет',
        });
      })
      .catch((err) => {
        console.log('err is ', err);
        notification.error({
          message: 'Не удалось сохранить отчет',
        });
      });
  };

  const isEditingDisabled = useMemo(
    () =>
      !otherTask?.id ||
      (otherTask?.status !== TaskStatusEnum.onRealization &&
        otherTask?.status !== TaskStatusEnum.onControl),
    [otherTask],
  );

  const onAddDocument = useCallback((docType?: string) => {
    if (docType) {
      setDocType(docType);
    }
    setAttachmentDocumentShow(true);
  }, []);

  const otherTaskAttachments = !otherTask?.attachments.some(
    (att) => att.type === DocumentTypes.Document,
  )
    ? otherTaskBlock?.attachments
    : otherTask?.attachments;

  const validateAttachments = useMemo(() => {
    const hasAct = otherTaskAttachments?.some(
      (att) => att.type === DocumentTypes.Document,
    );
    return hasAct;
  }, [otherTaskAttachments]);

  const isSaveDisabled = useMemo(
    () =>
      otherTask?.status !== TaskStatusEnum.onControl,
    [otherTask],
  );

  useEffect(() => {
    if (otherTask) {
      const dataClone = clone(otherTask);
      if (dataClone.factStartDate) {
        dataClone.factStartDate = dayjs(dataClone.factStartDate);
      }
      if (dataClone.factEndDate) {
        dataClone.factEndDate = dayjs(dataClone.factEndDate);
      }
      form.setFieldsValue(dataClone);
    }
  }, [otherTask]);

  console.log('isSaveDisabled', isSaveDisabled);
  console.log('validateAttachments', validateAttachments);

  return (
    <CustomCard overlay={isEditingDisabled}>
      <Form<IOtherTask>
        onFinish={onFinish}
        form={form}
        className={styles.table}
        layout="vertical"
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4}>Закрытие отчета</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={20} justify="end">
              <Col>
                <DefaultButton
                  disabled={!validateAttachments}
                  htmlType="submit"
                >
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  disabled={otherTask?.status !== TaskStatusEnum.onControl}
                  onClick={createReportHandle}
                >
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.deadlinesForm2}>
          <Typography.Text className={styles.deadlinesFormTitle}>
            Фактические сроки реализации
          </Typography.Text>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const { factEndDate } = form.getFieldsValue([
                        'factEndDate',
                      ]);
                      if (value && factEndDate && value >= factEndDate) {
                        const error = new Error(
                          'Начальная дата должна быть меньше конечной даты',
                        );

                        return Promise.reject(error);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                required
                name="factStartDate"
                label="Начало"
                style={{ marginBottom: 10 }}
              >
                <TooltipedDatePicker placeholder="Начало" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const { factStartDate } = form.getFieldsValue([
                        'factStartDate',
                      ]);
                      if (value && factStartDate && value <= factStartDate) {
                        const error = new Error(
                          'Конечная дата должна быть больше начальной даты',
                        );

                        return Promise.reject(error);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                name="factEndDate"
                required
                label="Конец"
                style={{ marginBottom: 10 }}
              >
                <TooltipedDatePicker placeholder="Конец" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item name="reportNotation" label="Комментарии / примечания">
          <TextArea
            placeholder="Комментарии / примечания"
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        </Form.Item>
        <Row>
          <Col onClick={() => onAddDocument(DocumentTypes.Document)} span={24}>
            <UploadButtonSmall>Прикрепить документ*</UploadButtonSmall>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  );
};
