import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import { useAddHydraulicSectionModal } from '@features/hydraulicSection/hydraulicSectionEditor/modals/useAddHydraulicSectionModal';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCreateHydraulicSectionMutation,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useToArchiveWaterUserMutation,
} from '@store/gisproApi';

import styles from './AddHydraulicSectionModal.module.scss';
import { useGetCurrentUser } from '@features/authentication';

export const AddHydraulicSectionModal: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const regionId = Form.useWatch('regionId', form);
  const { setShowModal, isModalShown } = useAddHydraulicSectionModal();
  const [createHydroFacility] = useCreateHydraulicSectionMutation();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionId
      ? {
          parentId: parseInt(regionId, 10),
        }
      : skipToken,
  );
  const onCancel = () => {
    setShowModal(false);
  };

  const onCreate = () => {
    createHydroFacility(form.getFieldsValue());
    setShowModal(false);
  };
  return (
    <CustomModal
      open={isModalShown}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={445}
      onCancel={onCancel}
      footer={false}
      title={'Добавить гидроучасток'}
      centered
    >
      <Form form={form} layout="vertical">
        <Row gutter={[0, 48]}>
          <Col span={24}>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                label="Область"
                name="regionId"
                initialValue={user?.owner?.region?.id}
              >
                <TooltipedSelect
                  disabled={isDisabledRegion}
                  size="large"
                  options={regions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                label="Район"
                name="districtId"
                initialValue={user?.owner?.district?.id}
              >
                <TooltipedSelect
                  size="large"
                  options={districts}
                  disabled={isDisabledDistrict || !regionId}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                label="Наименование Гидроучастка"
                name="name"
              >
                <TooltipedInput size="large" />
              </Form.Item>
            </Col>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={16} className={styles.modalBtn}>
              <Col>
                <DefaultButton size="large" onClick={onCancel}>
                  Отмена
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton size="large" onClick={onCreate}>
                  Добавить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
