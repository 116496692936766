import {
  IByDistrictDto,
  IWaterUsePlanByGuvhDto,
  IWaterUsePlanDataDto,
  PlanItemDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import { addRowIndexies } from '@utils/addRowIndexies/addRowIndexies';
import { set } from '@utils/utils';

export const irrigationWaterSupplyPlanDataMapper = (
  obj?: IWaterUsePlanDataDto | IByDistrictDto,
) => {
  if (!obj) return undefined;
  const { bySystemDtos, totals, totalsBySystemType } = obj;
  const districtId = 'districtId' in obj ? obj.districtId : '';
  const districtName = 'districtName' in obj ? obj.districtName : '';

  const systemsData = bySystemDtos.reduce((acc: any[], el, index) => {
    const { systems, systemTypeName, systemTypeId } = el;
    const totalBySystem = totalsBySystemType.find(
      (totalSystem) => totalSystem.systemTypeId === systemTypeId,
    );

    const total = {
      systemTypeName: `Итого "${systemTypeName}"`,
      planItemDtos: totalBySystem?.planItemDtos,
      systemTypeId: `total${index}`,
      systemId: `total${index}`,
    };
    const allTotals = {
      systemTypeName: 'Всего Итого',
      planItemDtos: totals,
      systemTypeId: `total${index + 1}`,
      systemId: `total${index + 2}`,
    };
    if ('districtId' in obj) {
      set(total, 'districtId', obj.districtId);
      set(total, 'districtName', obj.districtName);
      set(allTotals, 'districtId', obj.districtId);
      set(allTotals, 'districtName', obj.districtName);
    }
    const mappedSystems = systems.map((system) => {
      return {
        ...system,
        districtId,
        districtName,
      };
    });
    const totalArr = Array(3).fill(total);

    const allTotalArr = Array(3).fill(allTotals);
    const dataArr = [...mappedSystems, ...mappedSystems, ...mappedSystems]
      .slice()
      .sort((a, b) => a.systemId - b.systemId);
    return [...acc, ...dataArr, ...totalArr, ...allTotalArr];
  }, []);

  return systemsData;
};
