import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsShowEnterMorningValuesModal,
  getSelectedMorningRecrod,
} from '@store/selectors';
import {
  setSelectedMorningRecordAction,
  setShowEnterMorningValuesModalAction,
} from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (isShow: boolean) => void;
  selectedMorningRecord: any;
  setSelectedMorningRecord: (record: any) => void;
};

export const useEnterMonitoringValuesMorningModal = (): ReturnType => {
  const dispatch = useDispatch();

  const isModalShown = useSelector(getIsShowEnterMorningValuesModal);
  const selectedMorningRecord = useSelector(getSelectedMorningRecrod);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(setShowEnterMorningValuesModalAction(value));
  }, []);

  const setSelectedMorningRecord = useCallback((record: any) => {
    dispatch(setSelectedMorningRecordAction(record));
  }, []);

  return {
    setSelectedMorningRecord,
    selectedMorningRecord,
    isModalShown,
    setShowModal,
  };
};
