import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmployeeTypesEnum } from '@models/waterUser/enums/waterUserEnums';
import {
  getCurrentEmployeeTypeSelector,
  getEditEmployeeValueSelector,
  getEmployeesSelector,
  getShowEditEmployeeModalSelector,
} from '@store/selectors';
import {
  editEmployeeAction,
  removeEmployeeAction,
  setActiveEmployeeIdAction,
  setShowEditEmployeeModal,
} from '@store/slices/waterRegistry';

type Employee = {
  firstName: string;
  lastName: string;
  middleName: string;
  positionEmployee: string;
  employeeId: number;
};

type ReturnType = {
  isModalShown: boolean;
  employees: Array<Employee>;
  activeEmployeesId: number | null;
  setShowModal: (showModal: boolean) => void;
  editEmployeeId: (id: number) => void;
  removeEmployee: (ind: number) => void;
  editEmployee: (values: Employee, id: number) => void;
  currentEmployeeType: EmployeeTypesEnum | null;
};

export function useEditEmployee(): ReturnType {
  const dispatch = useDispatch();
  const employees = useSelector(getEmployeesSelector);
  const isModalShown = useSelector(getShowEditEmployeeModalSelector);
  const activeEmployeesId = useSelector(getEditEmployeeValueSelector);
  const currentEmployeeType = useSelector(getCurrentEmployeeTypeSelector);

  const removeEmployee = useCallback((ind: number) => {
    dispatch(removeEmployeeAction(ind));
  }, []);

  const editEmployee = useCallback((employee: Employee, id: number) => {
    dispatch(editEmployeeAction({ employee, id }));
  }, []);

  const editEmployeeId = useCallback((index: number) => {
    dispatch(setActiveEmployeeIdAction(index));
    dispatch(setShowEditEmployeeModal(true));
  }, []);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setShowEditEmployeeModal(showModal));
  }, []);

  return {
    isModalShown,
    editEmployee,
    employees,
    removeEmployee,
    setShowModal,
    activeEmployeesId,
    currentEmployeeType,
    editEmployeeId,
  };
}
