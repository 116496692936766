import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const WaterOutletMainData: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="WaterOutletMainData"
        src={getKibanaUrl('Водопользование: Общие данные')}
        height="2130"
        width="100%"
      />
    </div>
  );
};
