import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, notification, Row, Typography } from 'antd';

import {
  associationBooleanOptions,
  associationRank,
  pzTableOptions,
} from '@constants/associationStats';
import { Routes } from '@enums/routes';
import {
  CustomModalV2,
  PrimaryButtonV2,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateAssociationStatsMutation,
  useFindAllByOrganizationIdQuery,
  useUpdateAssociationStatsMutation,
} from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { toInteger } from '@utils/utils';

import styles from './AssociationStatsModal.module.scss';
import { useAssociationStatsModal } from './useAssociationStatsModal';

type AssociationStatsModalState = {
  foundersCount: string;
  privateLegalsCount: string;
  govLegalsCount: string;
  individualsCount: string;
  developmentPhase: string;
  membersCount: string;
  membersWomenCount: string;
  hasCard: string;
  reorganizationFinished: string;
  zonalRepresentativesCount: string;
  zonalRepresentativesWomenCount: string;
  registers: string;
};

export const AssociationStatsModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isShowModal, setShowModal } = useAssociationStatsModal();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { waterUserId } = useWaterUserId();
  const [save] = useUpdateAssociationStatsMutation();
  const [createStats] = useCreateAssociationStatsMutation();
  const { data: association } = useFindAllByOrganizationIdQuery(
    waterUserId
      ? {
          id: toInteger(waterUserId),
        }
      : skipToken,
  );
  const onClickEnter = async (values: any) => {
    try {
      const associationData = {
        ...values,
        associationId: waterUserId,
        finishedPz: values.finishedPz === 'true',
        pzScheme: values.pzScheme === 'true',
        directorsCount: toInteger(values.directorsCount),
        legalPersonCount: toInteger(values.legalPersonCount),
        stateLegalPersonCount: toInteger(values.stateLegalPersonCount),
        physicalPerson: toInteger(values.physicalPerson),
        employeeCount: toInteger(values.employeeCount),
        employeeFemaleCount: toInteger(values.employeeFemaleCount),
        zoneAgentsCount: toInteger(values.zoneAgentsCount),
        zoneAgentsFemaleCount: toInteger(values.zoneAgentsFemaleCount),
      };

      const saveFunction = association?.initialDataAssociation
        ? save(associationData)
        : createStats(associationData);

      const res = await saveFunction;
      navigate(Routes.WaterRegistryUsers);
      setShowModal(false);
    } catch (error) {
      notification.error({
        message: 'Не удалось сохранить',
      });
    }
  };

  const closeAssociationStats = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (association?.initialDataAssociation) {
      form.setFieldsValue({
        ...association?.initialDataAssociation,
        finishedPz: String(
          association?.initialDataAssociation.finishedPz || '',
        ),
        pzScheme: String(association?.initialDataAssociation.pzScheme || ''),
      });
    } else {
      form.resetFields();
    }
  }, [association, waterUserId]);

  return (
    <CustomModalV2
      centered
      title={t('Основные показатели Ассоциации')}
      open={isShowModal}
      onCancel={closeAssociationStats}
      width={840}
      footer={<div />}
    >
      <Form<AssociationStatsModalState>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onClickEnter}
        style={{ padding: '20px' }}
        className={styles.associationForm}
      >
        <Row gutter={[30, 30]}>
          <Col span={12}>
            <Row>
              <Form.Item
                label={t('createAssociationWaterRegistry.modal.foundersCount')}
                name="directorsCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Typography.Text className={styles.label}>Из них:</Typography.Text>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.privateLegalsCount',
                )}
                name="legalPersonCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t('createAssociationWaterRegistry.modal.govLegalsCount')}
                name="stateLegalPersonCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.individualsCount',
                )}
                name="physicalPerson"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.developmentPhase',
                )}
                name="developmentPhase"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedSelectV2 allowClear options={associationRank} />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t('createAssociationWaterRegistry.modal.membersCount')}
                name="employeeCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.membersWomenCount',
                )}
                name="employeeFemaleCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Form.Item
                label={t('createAssociationWaterRegistry.modal.hasCard')}
                name="pzScheme"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedSelectV2
                  options={associationBooleanOptions}
                  allowClear
                />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.reorganizationFinished',
                )}
                name="finishedPz"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedSelectV2
                  allowClear
                  options={associationBooleanOptions}
                />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.zonalRepresentativesCount',
                )}
                name="zoneAgentsCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 allowClear type="number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createAssociationWaterRegistry.modal.zonalRepresentativesWomenCount',
                )}
                name="zoneAgentsFemaleCount"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInputV2 type="number" allowClear />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t('createAssociationWaterRegistry.modal.registers')}
                name="pzTableEnum"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedSelectV2 allowClear options={pzTableOptions} />
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Form.Item>
              <PrimaryButtonV2
                type="primary"
                htmlType="submit"
                title="Завершить"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModalV2>
  );
};
