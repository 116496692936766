import React, { useEffect } from 'react';

import { Col, Divider, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  AnnualPlantingPlanTypeEnum,
  IPlantingDataDto,
} from '@models/annualApplication/interfaces/annualApplication';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import {
  useAddAnnualContractPlantingPlanMutation,
  useGetAnnualContractByIdQuery,
  useGetPlantingListQuery,
  useUpdateAnnualContractPlantingPlanMutation,
} from '@store/gisproApi';

import styles from './ActualStructureCreateModal.module.scss';
import { useActualStructureCreateModal } from '@features/annualApplication/actualStructure/actualStructureCreateModal/useActualStructureCreateModal';
import { normalizeValue } from './normalizeValue';
import { useParams } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy'; // TODO: change lodash to native implementation

const pattern = /^(0(.[0-9]{1,2})?|1(.0{1,2})?|1)$/;

export const ActualStructureCreateModal: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const {
    isModalShow,
    setShowModal,
    setSelectedActualStructure,
    selectedActualStructure,
  } = useActualStructureCreateModal();
  const [form] = useForm();
  const [save, { error, isError }] = useAddAnnualContractPlantingPlanMutation();
  const [update, { error: updateError, isError: updateIsError }] =
    useUpdateAnnualContractPlantingPlanMutation();

  const { data: annulContractItem } = useGetAnnualContractByIdQuery({ id });

  const isAssociation =
    annulContractItem?.waterUser?.type === WaterUserEnum.ASSOCIATION;

  const { data: plantingList = [] } = useGetPlantingListQuery();

  const facilities = uniqBy(
    (annulContractItem?.rawAnnualPlantingPlans || [])
      .filter((item) => item.type === AnnualPlantingPlanTypeEnum.PLANNED)
      .map((item) => item.facility)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      })),
    'id',
  );
  const outlets = uniqBy(
    (annulContractItem?.rawAnnualPlantingPlans || [])
      .filter((item) => item.type === AnnualPlantingPlanTypeEnum.PLANNED)
      .map((item) => item.outlet)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      })),
    'id',
  );

  const onCancel = () => {
    setShowModal(false);
  };

  const onFinish = (values: IPlantingDataDto) => {
    let promise = new Promise((resolve) => resolve);
    if (selectedActualStructure) {
      values.facilitiesId = facilities.map((item) => item.value);
      promise = update({ id: selectedActualStructure.id, plantingPlan: values }).unwrap();
    } else {
      promise = save({ annualPlanId: id, plantingPlan: [values] }).unwrap();
    }
    if (promise) {
      promise.then(() => {
        setSelectedActualStructure(null);
        setShowModal(false);
        form.resetFields();
      });
    }
  };

  useEffect(() => {
    if (selectedActualStructure) {
      form.setFieldsValue(selectedActualStructure);
      if (isAssociation) {
        form.setFieldValue('outletId', selectedActualStructure.outlet.id);
      } else {
        form.setFieldValue('facilityId', selectedActualStructure.facility.id);
      }
    } else {
      form.resetFields();
    }
  }, [selectedActualStructure]);

  return (
    <CustomModal
      open={isModalShow}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={370}
      onCancel={onCancel}
      footer={<div />}
      title={selectedActualStructure ? 'Обновление сева' : 'Создание сева'}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ktp: 0.67,
          kvh: 0.85,
        }}
        className={styles.form}
      >
        <Form.Item
          name={isAssociation ? 'outletId' : 'facilityId'}
          label={
            isAssociation
              ? 'Наименование водовыпуска'
              : 'Наименование хозяйства'
          }
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            placeholder={
              isAssociation ? 'Выберите водовыпуск' : 'Выберите хозяйство'
            }
            options={isAssociation ? outlets : facilities}
          />
        </Form.Item>
        <Form.Item
          name="foodPlantId"
          label="Наименование сельхозкультуры"
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            placeholder="Выберите сельхозкультуру"
            options={plantingList}
          />
        </Form.Item>
        <Form.Item
          name="totalArea"
          label="Площадь (ГА)"
          rules={[{ required: true }]}
        >
          <TooltipedInput placeholder="Введите площадь" type="number" />
        </Form.Item>
        <Form.Item
          name="ktp"
          label="КТП"
          rules={[{ required: true }, { pattern, message: '' }]}
          normalize={normalizeValue}
        >
          <TooltipedInput placeholder="Введите КТП" type="number" />
        </Form.Item>
        <Form.Item
          name="kvh"
          label="КВХ"
          normalize={normalizeValue}
          rules={[{ required: true }, { pattern, message: '' }]}
        >
          <TooltipedInput placeholder="Введите КВХ" type="number" />
        </Form.Item>
        <Form.Item className={styles.createButton}>
          <PrimaryButton size="large" htmlType="submit">
            {selectedActualStructure ? 'Обновить' : 'Создать'}
          </PrimaryButton>
        </Form.Item>
      </Form>
      {isError && (
        <Typography.Text type="danger">
          {(error as any).status} {JSON.stringify((error as any).data)}
        </Typography.Text>
      )}
      {updateIsError && (
        <Typography.Text type="danger">
          {(updateError as any).status}{' '}
          {JSON.stringify((updateError as any).data)}
        </Typography.Text>
      )}
    </CustomModal>
  );
};
