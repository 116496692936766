import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function renderGreyCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  return (
    <div className={styles.greyCell}>
      {record[customColumn.dataIndex] || customColumn.title}
    </div>
  );
}
