import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWaterUsersModalTableColumns = () => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: 'ФИО/Наименование водопользователя',
      dataIndex: 'name',
      align: 'center',
      width: 220,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      align: 'center',
      width: 220,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => t(`userTypesEnum.${record?.type}`),
    },
    {
      width: 350,
      title: 'Меню контроля',
      align: 'center',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.WateringPlanModalControl,
    },
  ];

  return { columns };
};
