import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Upload } from 'antd';
import { DraggerProps } from 'antd/es/upload';

import { useUploadFilesMutation } from '@store/gisproApi';

import { attachFileUrl } from '../utils';
import styles from './UploadDraggerV2.module.scss';
import { PrimaryButton } from '@features/ui-kit/buttons';

type UploadDraggerV2PropTypes = DraggerProps & {
  customRequest?: (file: any) => void;
  onLoad?: (file: any) => void;
  docType?: string;
  docFormat?: string;
};

export const UploadDraggerV2: FC<UploadDraggerV2PropTypes> = ({
  onLoad,
  docType,
  docFormat,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [uploadDocument] = useUploadFilesMutation();

  const customRequestUploader = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,

            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);
    try {
      const response: any = await uploadDocument(formData);
      onLoad?.(attachFileUrl(response.data));
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };
  return (
    <Upload.Dragger
      showUploadList={false}
      className={styles.dragger}
      customRequest={customRequestUploader}
      accept={docFormat}
      {...props}
    >
      <div className={styles.uploadLabel}>
        Перетащите файл сюда или нажмите что бы загрузить
      </div>
      <PrimaryButton disabled={props.disabled}>
        {t('customUploader.button')}
      </PrimaryButton>
    </Upload.Dragger>
  );
};
