import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWearSumTableColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Дата',
      dataIndex: 'year',
      xtype: CustomTableColumnType.String,
      align: 'left',
      key: 'year',
    },
    {
      title: 'Стоимость',
      dataIndex: 'wearSum',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'left',
      key: 'cost',
      renderCustom: ({ record }) => <span>{record.wearSum} сом</span>,
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
      key: 'actions',
      align: 'center',
      width: 100,
    },
  ];

  return { columns };
};
