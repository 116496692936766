import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWaterUserIdSelector } from '@store/selectors';
import { setWaterUserId } from '@store/slices';

type ReturnType = {
  waterUserId: string;
  setUserId: (userId: string) => void;
};

export function useWaterUserId(): ReturnType {
  const dispatch = useDispatch();
  const waterUserId = useSelector(getWaterUserIdSelector);

  const setUserId = useCallback((userId: string) => {
    dispatch(setWaterUserId(userId));
  }, []);

  return {
    waterUserId,
    setUserId,
  };
}
