import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { IndividualWaterUserMasterRegistrationLayout } from '@features/layout/waterRegistry';
import {
  AddBankModal,
  AddBranchModal,
  IndividualSelectModal,
  useActionSelect,
  useAddBank,
  useAddBranch,
  useWaterUserPermissionModal,
} from '@features/waterRegistry/masterRegistration';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { IndividualPermissionsModal } from '@features/waterUser/individualPermissionsModal/InidividualPermissionsModal';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { useGetCurrentUser } from '@features/authentication';

export const IndividualWaterUserMasterRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const { setShowModal: setShowAddBankModal } = useAddBank();
  const { setShowModal: setShowAddBranchModal } = useAddBranch();
  const { setShowModal: setShowActionSelectModal } = useActionSelect();
  const { setShowModalPhysical } = useWaterUserPermissionModal();
  const { waterUserId, setUserId } = useWaterUserId();
  const { user } = useGetCurrentUser();

  const onComplete = () => {
    navigate({
      pathname: Routes.WaterRegistryUsers,
      search: `?${SectionQueryKey}=${WaterUserTypes.physical}`,
    });
    setShowActionSelectModal(false);
  };

  const onViewCard = () => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: waterUserId,
      }),
    );
    setUserId('');
    setShowActionSelectModal(false);
  };

  const onAnnualRequest = () => {
    const urlParams = new URLSearchParams({
      page: 1,
      size: 10,
      regionId: user?.owner?.region?.id || null,
      districtId: user?.owner?.district?.id || null,
    } as any);

    navigate({
      pathname: `${Routes.AnnualApplication}/table`,
      search: urlParams.toString(),
    });
    setShowActionSelectModal(false);
  };

  return (
    <div>
      <IndividualWaterUserMasterRegistrationLayout />
      <AddBankModal onClose={() => setShowAddBankModal(false)} />
      <AddBranchModal onClose={() => setShowAddBranchModal(false)} />
      <IndividualSelectModal
        onPermissionsOpen={() => {
          setShowModalPhysical(true);
        }}
        onComplete={onComplete}
        onViewCard={onViewCard}
        onAnnualRequest={onAnnualRequest}
      />
      <IndividualPermissionsModal />
    </div>
  );
};
