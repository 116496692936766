import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const CubeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 8.82976L0 5.35976V13.0898C0.00434807 13.4121 0.100805 13.7265 0.277991 13.9958C0.455177 14.2651 0.705699 14.4782 1 14.6098L8.19 17.9998H8.25V8.82976ZM9 7.49976L17.51 3.92976C17.3668 3.77064 17.1934 3.64149 17 3.54976L9.8 0.179763C9.54992 0.0613968 9.27668 0 9 0C8.72332 0 8.45008 0.0613968 8.2 0.179763L1 3.54976C0.806561 3.64149 0.633222 3.77064 0.49 3.92976L9 7.49976ZM9.75 8.82976V17.9998H9.8L17 14.6098C17.2928 14.4789 17.5423 14.2674 17.7193 14C17.8964 13.7326 17.9938 13.4204 18 13.0998V5.35976L9.75 8.82976Z"
        fill="white"
      />
    </svg>
  );
};
