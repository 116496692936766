import { useParams } from 'react-router-dom';

import { useSubmitImplementationTermModal } from '@features/manageEvents/modals/submitImplementationTerm/useSubmitImplementationTermModal';
import { TableCustom } from '@features/ui-kit';
import { ImplementationTermStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { IImplementationTerm } from '@models/manageEvents/interfaces/operationalBlock';

import { useRealizationTableColumns } from './useRealizationTableColumns';

type RealizationTablePropTypes = {
  implementationTerms: IImplementationTerm[];
};

export const RealizationTable: React.FC<RealizationTablePropTypes> = ({
  implementationTerms = [],
}) => {
  const { columns } = useRealizationTableColumns();
  const { setSelectedImplementationTerm, setShow } =
    useSubmitImplementationTermModal();

  const onHandleSubmit = (row: IImplementationTerm) => {
    setSelectedImplementationTerm(row);
    setShow(true);
  };

  const getIsDisabled = (row: IImplementationTerm) => {
    return row.status === ImplementationTermStatusEnum.Done;
  };

  return (
    <TableCustom
      size="small"
      columns={columns}
      scroll={{ y: 500 }}
      dataSource={implementationTerms}
      actionProps={{
        onHandleSubmit,
        getIsDisabled,
      }}
    />
  );
};
