import { Collapse } from 'antd';
import { ItemType } from 'rc-collapse/es/interface';
import * as React from 'react';
import { CollapseProps } from 'antd/es/collapse/Collapse';

import styles from './CollapseMenu.module.scss';

type CollapseMenuProps = CollapseProps;

type CollapseMenuItemProps = {
  item: ItemType,
}

export const CollapseMenu: React.FC<CollapseMenuProps> = ({ ...rest }) => {
  return (
    <Collapse className={styles.collapseMenu} bordered={false} {...rest} />
  )
}

export const CollapseMenuItem: React.FC<CollapseMenuItemProps> = ({ item }) => {
  return (
    <div>
      {item.label}
    </div>
  )
}
