import { Col, Form, notification, Row, Space } from 'antd';

import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { IWearDto } from '@models/waterObject/interfaces/wearDto';

import { useWaterObjectModals } from '../useWaterObjectModals';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  YearPicker,
} from '@features/ui-kit';
import dayjs from 'dayjs';
import { generateTimestampId } from '@utils/generateTimestampId/generateTimestampId';

export const WearSumModal: React.FC = () => {
  const outerFrom = Form.useFormInstance();
  const [form] = Form.useForm();
  const { isShowModal, setShowModal, initialValues } = useWaterObjectModals(
    WaterMainModalTypes.WEAR_SUM,
  );
  const onClose = () => {
    setShowModal(false, null);
    form.resetFields();
  };

  const onFinish = (data: IWearDto) => {
    try {
      const values = outerFrom.getFieldsValue(true);
      const currentWorks = values.wearDtos || [];
      if (initialValues) {
        outerFrom.setFieldsValue({
          wearDtos: currentWorks.map((work: IWearDto) =>
            work?.id === initialValues?.id ||
            (initialValues?.customId &&
              work?.customId === initialValues?.customId)
              ? { ...work, ...data, year: dayjs(data?.year).format('YYYY') }
              : work,
          ),
        });
      } else {
        outerFrom.setFieldsValue({
          wearDtos: [
            ...currentWorks,
            {
              ...data,
              year: dayjs(data.year).format('YYYY'),
              customId: generateTimestampId(),
            },
          ],
        });
      }
      onClose();
      notification.success({
        message: `Вы успешно ${
          initialValues ? 'изменили' : 'добавили'
        } сумму износа`,
      });
    } catch (err) {
      console.log('Err: ', err);
    }
  };

  const formInitialValues = {
    ...initialValues,
    year: initialValues?.year ? dayjs(initialValues?.year, 'YYYY') : null,
  };

  return (
    <CustomModal
      centered
      footer={false}
      onCancel={onClose}
      title="Сумма износа"
      open={isShowModal}
    >
      <Form<IWearDto>
        onFinish={onFinish}
        initialValues={formInitialValues}
        layout="vertical"
        form={form}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item rules={[{ required: true }]} label="Год" name="year">
              <YearPicker placeholder="Выберите год" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              label="Стоимость (сом)"
              name="wearSum"
            >
              <TooltipedInput placeholder="Введите сумму" type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Space size={16}>
              <DefaultButton onClick={() => setShowModal(false)} size="large">
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit" size="large">
                {initialValues ? 'Изменить' : 'Добавить'}
              </PrimaryButton>
            </Space>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
