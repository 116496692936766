import { FC, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { DefaultButton, Divider } from '@features/ui-kit';
import { CustomSteps } from '@features/ui-kit/customSteps';
import { CreateIrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';

import { CreateIrrigationSystemActionSelect } from './createIrrigationSystemActionSelect';
import styles from './CreateStepper.module.scss';
import { CreateStepperFirstStep } from './createStepperFirstStep';
import { CreateStepperSecondStep } from './createStepperSecondStep';
import { CreateStepperThirdStep } from './createStepperThirdStep';
import { useTranslation } from 'react-i18next';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Row, Typography } from 'antd';
import { Routes } from '@enums/routes';

export const CreateStepper: FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryKey = useMemo(() => params.get(SectionQueryKey), [params]);

  const steps = [
    {
      value: CreateIrrigationSystemQueryTypes.totalInformation,
      title: 'Общие данные и характеристики ',
    },
    {
      value: CreateIrrigationSystemQueryTypes.works,
      title: 'Проведение работ',
    },
    {
      value: CreateIrrigationSystemQueryTypes.document,
      title: 'Документы',
    },
  ];

  let Component;
  switch (queryKey) {
    case CreateIrrigationSystemQueryTypes.totalInformation:
      Component = CreateStepperFirstStep;
      break;
    case CreateIrrigationSystemQueryTypes.works:
      Component = CreateStepperSecondStep;
      break;
    case CreateIrrigationSystemQueryTypes.document:
      Component = CreateStepperThirdStep;
      break;
    default:
      Component = CreateStepperFirstStep;
      break;
  }

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.IrrigationSystemTable)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: t('headers.irrigation-system'),
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <div className={styles.progress}>
        <CustomSteps
          steps={steps}
          queryParams={{
            queryKey: SectionQueryKey,
            queryType: CreateIrrigationSystemQueryTypes,
          }}
        />
      </div>
      <Divider />

      <div className={styles.info}>
        <Component />
      </div>
      <CreateIrrigationSystemActionSelect />
    </>
  );
};
