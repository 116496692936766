import React from 'react';

import { Layout, Spin } from 'antd';

import styles from './LoadingLayout.module.scss';

export const LoadingLayout: React.FC = () => {
  return (
    <Layout className={styles.layout}>
      <Spin size="large" />
    </Layout>
  );
};
