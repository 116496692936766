import { type JsonRecord } from '@models/jsonRecord';
import { type TableColumn } from '@models/table/interfaces/tableColumn';

import { type GetCellKey } from './TableCustom';

export function getCellKeyValue<RecordType extends JsonRecord>(
  record: RecordType,
  column: TableColumn,
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>,
): React.Key {
  let key;

  if (typeof cellKey === 'string') {
    key = record[cellKey];
  }
  if (typeof cellKey === 'function') {
    key = cellKey(record, column);
  }
  return key;
}
