import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IWaterObjectFilters } from '@models/waterObject/interfaces/waterObject';
import { getWaterObjectFiltersSelector } from '@store/selectors/waterObject';
import { waterObjectActions } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IWaterObjectFilters>;
  setWaterObjectFilters: (filters: IWaterObjectFilters) => void;
};

export const useWaterObjectFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getWaterObjectFiltersSelector);

  const setWaterObjectFilters = useCallback(
    (newFilters: IWaterObjectFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        waterObjectActions.changeWaterObjectFilters(
          values as IWaterObjectFilters,
        ),
      );
    },
    [],
  );

  return { filters, setWaterObjectFilters };
};
