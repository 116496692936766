import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { CustomModalV2, EditableStatsCard } from '@features/ui-kit-v2';
import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { getSelectedWaterSectionSelector } from '@store/selectors/waterObject';

import { useWaterObjectModals } from '../useWaterObjectModals';
import { CustomModal, PropertyBlock } from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';

export const SizeModal: React.FC = () => {
  const [form] = useForm();
  const waterSection = useSelector(getSelectedWaterSectionSelector);
  const { isShowModal, setShowModal } = useWaterObjectModals(
    WaterMainModalTypes.SIZE,
  );

  useEffect(() => {
    if (isShowModal) {
      form.setFieldsValue(waterSection);
    }
  }, [isShowModal]);

  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  return (
    <CustomModal
      centered
      footer={false}
      onCancel={onClose}
      title="Размеры и габариты"
      open={isShowModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="widthMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Ширина (В)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="bottomWidthMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Ширина по дну (b)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="heightMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Высота (h)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="wallHeightMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Высота строения (Н стр.)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="lengthMeters">
          <PropertyBlock
            icon={SizesIcon}
            label="Протяженность (L)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="areaHectares">
          <PropertyBlock
            icon={SizesIcon}
            label="Площадь"
            type="input"
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
