export const geIconByType = (layerName: any) => {
  //Маркеры

  if (layerName === 'geonode:seleotvod') {
    //Селеотвод
    return '/icons/geonode_seleotvod.svg';
  }

  if (layerName === 'geonode:distribution_wells') {
    //Распределительные колодцы
    return '/icons/geonode_distribution_wells.svg';
  }

  if (layerName === 'geonode:plotina') {
    //Плотина
    return '/icons/geonode_plotina.svg';
  }

  if (layerName === 'geonode:sandgravielovka') {
    //Пескогравиловка
    return '/icons/geonode_sandgravielovka.svg';
  }

  if (layerName === 'geonode:blocking_structure') {
    //Перегораживающие сооружение
    return '/icons/geonode_blocking_structure.svg';
  }

  if (layerName === 'geonode:sewage_treatment_plant') {
    //Очистное сооружение
    return '/icons/geonode_sewage_treatment_plant.svg';
  }

  if (layerName === 'geonode:water_sump') {
    //Отстойник
    return '/icons/geonode_water_sump.svg';
  }

  if (layerName === 'geonode:collector_connected_water_object') {
    //Коллектор (связанный с водным объектом)
    return '/icons/geonode_collector_connected_water_object.svg';
  }

  if (layerName === 'geonode:collector') {
    //Коллектор открытый (канализация)
    return '/icons/geonode_collector.svg';
  }

  if (layerName === 'geonode:waste_collector') {
    //Коллектор
    return '/icons/geonode_waste_collector.svg';
  }

  if (layerName === 'geonode:livestock_access') {
    //Доступ скота
    return '/icons/geonode_livestock_access.svg';
  }

  if (layerName === 'geonode:dam') {
    //Дамба
    return '/icons/geonode_dam.svg';
  }

  if (layerName === 'geonode:plunger') {
    //Вантуз
    return '/icons/marker1.svg';
  }

  if (layerName === 'geonode:inter_section_spillway_collector') {
    //Меж-участковый водосбросный коллектор
    return '/icons/marker2.svg';
  }

  if (layerName === 'geonode:drainage_wells') {
    //Дренажные колодцы
    return '/icons/marker3.svg';
  }

  if (layerName === 'geonode:waste_wells') {
    //Сбросные колодцы
    return '/icons/marker4.svg';
  }

  if (layerName === 'geonode:distribution_node') {
    //Узел распределения
    return '/icons/marker5.svg';
  }

  if (layerName === 'geonode:pumping_station') {
    //Насосная станция
    return '/icons/marker6.svg';
  }

  if (layerName === 'geonode:drainage_pumps') {
    //Дренажные насосы
    return '/icons/marker8.svg';
  }

  if (layerName === 'geonode:inter_farm_spillway_collector') {
    //Межхозяйственный водосбросный коллектор
    return '/icons/marker9.svg';
  }

  if (layerName === 'geonode:other_measuring_instruments') {
    //Другие средства измерения
    return '/icons/marker14.svg';
  }

  if (layerName === 'geonode:place_for_draining_water') {
    //Место для сливки
    return '/icons/marker15.svg';
  }

  if (layerName === 'geonode:meliration_hydropost') {
    //geonode:hydropost
    return '/icons/marker16.svg';
  }

  if (layerName === 'geonode:water_section') {
    //Водораздел (вододелитель)
    return '/icons/marker21.svg';
  }

  if (layerName === 'geonode:household_water_collector') {
    //Хозяйственный водосбросный коллектор
    return '/icons/marker22.svg';
  }

  if (layerName === 'geonode:spillway') {
    //Водосброс
    return '/icons/marker24.svg';
  }

  if (layerName === 'geonode:hydroelectric_power_station') {
    //Гидроэлектростанция
    return '/icons/marker25.svg';
  }

  if (layerName === 'geonode:gateway_controller') {
    //Шлюз– регулятор
    return '/icons/marker26.svg';
  }

  if (layerName === 'geonode:main_water_collector') {
    //Главный водосбросный коллектор
    return '/icons/marker27.svg';
  }

  if (layerName === 'geonode:bridge') {
    //Мост
    return '/icons/marker28.svg';
  }

  if (layerName === 'geonode:drop') {
    //Перепад
    return '/icons/marker29.svg';
  }

  if (layerName === 'geonode:main_water_intake_structure') {
    //Головное водозаборное сооружение (узел)
    return '/icons/marker34.svg';
  }

  if (layerName === 'geonode:monitoring:system_object') {
    // Водозаборные сооружения
    return '/icons/marker34.svg';
  }

  // if (layerName === 'geonode:bridge') {
  //   return '/icons/marker36.svg';
  // }

  if (layerName === 'geonode:double_drop') {
    //Двойник с перепадом
    return '/icons/marker38.svg';
  }

  if (layerName === 'geonode:water_support') {
    //Водо-подпор (подпорная стенка)
    return '/icons/marker40.svg';
  }

  if (layerName === 'geonode:fence_node') {
    //Узел забора
    return '/icons/marker41.svg';
  }

  if (layerName === 'geonode:anti_filtration_facility') {
    //Противофильтрационное сооружение
    return '/icons/marker45.svg';
  }

  if (layerName === 'geonode:monitoring:outlets') {
    //Водовыпуск
    return '/icons/marker46.svg';
  }

  if (layerName === 'geonode:water_hammer_extinguisher') {
    //Гаситель гидроудара
    return '/icons/marker47.svg';
  }

  if (layerName === 'geonode:vertical_drainage_well') {
    //Скважина (дренаж)
    return '/icons/marker52.svg';
  }

  if (layerName === 'geonode:electrical_substation') {
    //Электрическая подстанция
    return '/icons/marker33.svg';
  }

  if (layerName === 'geonode:meliration_well') {
    //Скважина
    return '/icons/marker53.svg';
  }

  if (layerName === 'geonode:section_water_collector') {
    //Участковый водосбросный коллектор
    return '/icons/marker11.svg';
  }

  if (layerName === 'geonode:meliration_other') {
    //Другие
    return '/icons/geonode_meliration_other.svg';
  }

  //Линии

  if (layerName === 'geonode:tubular_crossing') {
    //Трубчатый переезд
    return '/icons/geonode_tubular_crossingLegend.svg';
  }

  if (layerName === 'geonode:drainage_pipes_drains') {
    //Дренажные трубы (дрены)
    return '/icons/geonode_drainage_pipes_drainsLegend.svg';
  }

  if (layerName === 'geonode:spillway_channel_drainage') {
    //Водосбросный канал (дренаж)
    return '/icons/geonode_spillway_channel_drainageLegend.svg';
  }

  if (layerName === 'geonode:duker') {
    //Межхозяйственный водосбросный канал
    return '/icons/geonode_duker.svg';
  }

  if (layerName === 'geonode:inter_farm_spillway_channel') {
    //Межхозяйственный водосбросный канал
    return '/icons/geonode_inter_farm_spillway_channel.svg';
  }

  if (layerName === 'geonode:onfarm_channel') {
    // Внутрихозяйственный канал (распределители различных порядков)
    return '/icons/geonode_onfarm_channel.svg';
  }

  if (layerName === 'geonode:interfarm_channels') {
    //Межхозяйственные каналы
    return '/icons/geonode_interfarm_channels.svg';
  }

  if (layerName === 'geonode:economic_channel') {
    //Хозяйственный канал
    return '/icons/geonode_economic_channel.svg';
  }

  if (layerName === 'geonode:quickness_stream') {
    //Быстроток
    return '/icons/geonode_quickness_stream.svg';
  }

  if (layerName === 'geonode:economic_spillway_channel') {
    //Хозяйственный водосбросный канал
    return '/icons/geonode_economic_spillway_channel.svg';
  }

  if (layerName === 'geonode:culvert') {
    //Водопропускная труба
    return '/icons/geonode_culvert.svg';
  }

  if (layerName === 'geonode:main_spillway') {
    //Главный водосбросный канал
    return '/icons/geonode_main_spillway.svg';
  }

  if (layerName === 'geonode:ditches') {
    //Канавы
    return '/icons/geonode_ditches.svg';
  }

  if (layerName === 'geonode:inter_stage_spillway_channel') {
    //Меж-участковый водосбросный канал
    return '/icons/geonode_inter_stage_spillway_channel.svg';
  }

  if (layerName === 'geonode:shallow_spillway_channels') {
    //Мелкие водосбросные каналы
    return '/icons/geonode_shallow_spillway_channels.svg';
  }

  if (layerName === 'geonode:protective_channel_lovchyi') {
    //Оградительный канал (ловчий)
    return '/icons/geonode_protective_channel_lovchyi.svg';
  }

  if (layerName === 'geonode:protective_channel_nagornyi_lovchyi') {
    //Оградительный канал (нагорно-ловчий)
    return '/icons/geonode_protective_channel_nagornyi_lovchyi.svg';
  }

  if (layerName === 'geonode:protective_channel_nagornyi') {
    //Оградительный канал (нагорный)
    return '/icons/geonode_protective_channel_nagornyi.svg';
  }

  if (layerName === 'geonode:protective_channel') {
    //Оградительный канал
    return '/icons/geonode_protective_channel.svg';
  }

  if (layerName === 'geonode:pipeline_sewerage') {
    //Трубопровод (Канализация)
    return '/icons/geonode_pipeline_sewerage.svg';
  }

  if (layerName === 'geonode:pipelines') {
    //Трубопроводы
    return '/icons/geonode_pipelines.svg';
  }

  if (layerName === 'geonode:tunnel') {
    //Туннель
    return '/icons/geonode_tunnel.svg';
  }

  if (layerName === 'geonode:precinct_spillway_channel') {
    //Участковый водосбросный канал
    return '/icons/geonode_precinct_spillway_channel.svg';
  }

  if (layerName === 'geonode:roads') {
    //Дорога
    return '/icons/geonode_roads.svg';
  }

  if (layerName === 'geonode:aqueduct') {
    //Акведук
    return '/icons/geonode_aqueduct.svg';
  }

  if (layerName === 'geonode:magistral_irrigation_channels') {
    //Магистральные оросительные каналы
    return '/icons/geonode_magistral_irrigation_channels.svg';
  }

  if (layerName === 'geonode:seleduk') {
    //Селедук
    return '/icons/geonode_seleduk.svg';
  }

  // if (layerName === 'geonode:forest_belts') {
  //   return '/icons/geonode_forest_belts.svg';
  // }

  //полигоны

  if (layerName === 'geonode:storage facilities') {
    //Селе-хранилища
    return '/icons/geonode_storage_facilities.png';
  }

  if (layerName === 'geonode:pond') {
    //Пруд
    return '/icons/geonode_pond.png';
  }

  if (layerName === 'geonode:forest_belts') {
    //Лесополоса
    return '/icons/geonode_forest_belts.png';
  }

  if (layerName === 'geonode:reservoirs') {
    //Водохранилища
    return '/icons/geonode_pond.png';
  }

  if (layerName === 'geonode:daily_regulation_pool') {
    //Бассейн суточного регулирования (БСР)
    return '/icons/geonode_pond.png';
  }

  if (layerName === 'geonode:decade_regulation_pool') {
    //Бассейн декадного регулирования (БДР)
    return '/icons/geonode_pond.png';
  }

  if (layerName === 'geonode:pressure_pool') {
    //Напорный бассейн
    return '/icons/geonode_pressure_pool.svg';
  }

  if (layerName === 'geonode:pond') {
    //Пруд
    return '/icons/geonode_pond.png';
  }

  // if (layerName === 'geonode:sewage_treatment_plant') {
  //   return '/icons/geonode_sewage_treatment_plant.svg';
  // }

  if (layerName === 'geonode:sedimentary pool') {
    //Осадочной бассейн есть
    return '/icons/geonode_sedimentary_pool.svg';
  }

  // if (layerName === 'geonode:water_sump') {
  //   return '/icons/geonode_water_sump.svg';
  // }

  // if (layerName === 'geonode:sandgravielovka') {
  //   return '/icons/geonode_sandgravielovka.svg';
  // }

  if (layerName === 'geonode:reservoir_others') {
    //Резервуар
    return '/icons/geonode_reservoir_others.svg';
  }

  return '/icons/no-image.svg';
};
