import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  IBuildingBlock,
  ObjectSystem,
} from '@models/manageEvents/interfaces/buildingBlock';
import {
  IImplementationTerm,
  IOperationalBlock,
} from '@models/manageEvents/interfaces/operationalBlock';
import { IOtherTask } from '@models/manageEvents/interfaces/otherTask';
import { IRegularEventBlock } from '@models/manageEvents/interfaces/regularEventBlock';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import concat from 'lodash/concat'; // TODO: change lodash to native implementation

export interface ITableOptions {
  categoryId?: number;
  page?: number;
  limit?: number;
  q?: string;
  systemObjectId?: number;
  eventStartDate?: string;
  eventEndDate?: string;
  budgetStart?: number;
  budgetEnd?: number;
  status?: string;
}
export interface IGanttOptions {
  page?: number;
  limit?: number;
  type?: string;
  priority?: string;
}
export type IManageEventsState = {
  isShowSubmitImplementationTermModalShown: boolean;
  selectedImplementationTerm: IImplementationTerm | null;
  operationalBlock: Partial<IOperationalBlock>;
  buildingBlock: Partial<IBuildingBlock>;
  regularEventBlock: Partial<IRegularEventBlock>;
  otherTaskBlock: Partial<IOtherTask>;
  docType: string;
  isShowAttachDocumentWithoutNotationModal: boolean;
  isShowCreateTemplateModal: boolean;
  isShowAttachDocumentModal: boolean;
  isShowDocumentTypeAndFormatModal: boolean;
  isShowAssignExecutorModal: boolean;
  tableOptions: ITableOptions;
  ganttOptions: IGanttOptions;
};

const initialState: IManageEventsState = {
  selectedImplementationTerm: null,
  isShowSubmitImplementationTermModalShown: false,
  operationalBlock: {},
  buildingBlock: {},
  regularEventBlock: {},
  otherTaskBlock: {},
  docType: '',
  isShowAttachDocumentWithoutNotationModal: false,
  isShowCreateTemplateModal: false,
  isShowAttachDocumentModal: false,
  isShowDocumentTypeAndFormatModal: false,
  isShowAssignExecutorModal: false,
  tableOptions: {
    page: 1,
    limit: 10,
  },
  ganttOptions: {
    page: 1,
    limit: 10,
  },
};

const manageEventsSlice = createSlice({
  name: 'manageEvents',
  initialState,
  reducers: {
    setSubmitImplementationTermModalShownAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowSubmitImplementationTermModalShown: action.payload,
      };
    },
    setSelectedImplementationTermAction: (
      state,
      action: PayloadAction<IImplementationTerm | null>,
    ) => {
      return {
        ...state,
        selectedImplementationTerm: action.payload,
      };
    },
    setOperationalBlockAction: (
      state,
      action: PayloadAction<Partial<IOperationalBlock>>,
    ) => {
      return {
        ...state,
        operationalBlock: {
          ...state.operationalBlock,
          ...action.payload,
        },
      };
    },
    setRegularEventBlockAction: (
      state,
      action: PayloadAction<Partial<IRegularEventBlock>>,
    ) => {
      return {
        ...state,
        regularEventBlock: {
          ...state.regularEventBlock,
          ...action.payload,
        },
      };
    },
    setOtherTaskBlockAction: (
      state,
      action: PayloadAction<Partial<IOtherTask>>,
    ) => {
      return {
        ...state,
        otherTaskBlock: {
          ...state.otherTaskBlock,
          ...action.payload,
        },
      };
    },
    setBuildingBlockAction: (
      state,
      action: PayloadAction<Partial<IBuildingBlock>>,
    ) => {
      return {
        ...state,
        buildingBlock: {
          ...state.buildingBlock,
          ...action.payload,
        },
      };
    },
    addDocumentToOperationalBlockAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentsCopy: any = concat(
        state.operationalBlock.attachments || [],
        action.payload,
      );
      return {
        ...state,
        operationalBlock: {
          ...state.operationalBlock,
          attachments: attachmentsCopy,
        },
      };
    },
    addDocumentToBuildingBlockAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentsCopy: any = concat(
        state.buildingBlock.attachments || [],
        action.payload,
      );
      return {
        ...state,
        buildingBlock: {
          ...state.buildingBlock,
          attachments: attachmentsCopy,
        },
      };
    },
    setObjectSystemBuildingBlock: (
      state,
      action: PayloadAction<ObjectSystem>,
    ) => {
      return {
        ...state,
        buildingBlock: {
          ...state.buildingBlock,
          constructionObject: action.payload,
        },
      };
    },
    setShowCreateTemplateModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowCreateTemplateModal: action.payload,
      };
    },
    setShowAttachDocumentModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAttachDocumentModal: action.payload,
      };
    },
    setShowAttachDocumentWithoutNotationModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAttachDocumentWithoutNotationModal: action.payload,
      };
    },
    setDocTypeAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        docType: action.payload,
      };
    },
    saveTableOptions: (state, { payload }) => {
      return {
        ...state,
        tableOptions: payload,
      };
    },
    clearBuildingBlock: (state) => {
      return {
        ...state,
        buildingBlock: {},
      };
    },
    clearOtherTaskBlock: (state) => {
      return {
        ...state,
        otherTaskBlock: {},
      };
    },
    clearOperationalBlock: (state) => {
      return {
        ...state,
        operationalBlock: {},
      };
    },
    clearRegularEventBlock: (state) => {
      return {
        ...state,
        regularEventBlock: {},
      };
    },
    setShowDocumentTypeAndFormatModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowDocumentTypeAndFormatModal: action.payload,
      };
    },
    setShowAssignExecutorModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAssignExecutorModal: action.payload,
      };
    },
    addDocumentToRegularEventAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentsCopy: any = concat(
        state.regularEventBlock.attachments || [],
        action.payload,
      );
      return {
        ...state,
        regularEventBlock: {
          ...state.regularEventBlock,
          attachments: attachmentsCopy,
        },
      };
    },
    addDocumentToOtherTaskAction: (
      state,
      action: PayloadAction<IAttachment>,
    ) => {
      const attachmentsCopy: any = concat(
        state.otherTaskBlock.attachments || [],
        action.payload,
      );
      return {
        ...state,
        otherTaskBlock: {
          ...state.otherTaskBlock,
          attachments: attachmentsCopy,
        },
      };
    },
    saveGanttOptions: (state, { payload }) => {
      return {
        ...state,
        ganttOptions: Object.keys(payload).length
          ? payload
          : initialState.ganttOptions,
      };
    },
    addExecutorToOperationalBlockAction: (state, action) => {
      return {
        ...state,
        operationalBlock: {
          ...state.operationalBlock,
          executor: action.payload,
        },
      };
    },
    addExecutorToRegularEventBlockAction: (state, action) => {
      return {
        ...state,
        regularEventBlock: {
          ...state.regularEventBlock,
          executor: action.payload,
        },
      };
    },
    addExecutorToOtherTaskBlockAction: (state, action) => {
      return {
        ...state,
        otherTaskBlock: {
          ...state.otherTaskBlock,
          executor: action.payload,
        },
      };
    },
    addExecutorToBuildingBlockAction: (state, action) => {
      return {
        ...state,
        buildingBlock: {
          ...state.buildingBlock,
          executor: action.payload,
        },
      };
    },
  },
});

export const {
  setSubmitImplementationTermModalShownAction,
  setSelectedImplementationTermAction,
  setDocTypeAction,
  setOperationalBlockAction,
  setRegularEventBlockAction,
  setOtherTaskBlockAction,
  setShowCreateTemplateModalAction,
  setShowAttachDocumentModalAction,
  setShowDocumentTypeAndFormatModalAction,
  setShowAssignExecutorModalAction,
  addDocumentToOperationalBlockAction,
  saveGanttOptions,
  setBuildingBlockAction,
  addDocumentToBuildingBlockAction,
  setShowAttachDocumentWithoutNotationModalAction,
  setObjectSystemBuildingBlock,
  clearBuildingBlock,
  clearOperationalBlock,
  saveTableOptions,
  addDocumentToRegularEventAction,
  addDocumentToOtherTaskAction,
  addExecutorToOperationalBlockAction,
  addExecutorToRegularEventBlockAction,
  addExecutorToOtherTaskBlockAction,
  addExecutorToBuildingBlockAction,
  clearRegularEventBlock,
  clearOtherTaskBlock,
} = manageEventsSlice.actions;

export default manageEventsSlice.reducer;
