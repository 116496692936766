import { Col, Row, Space } from 'antd';

import { InformationalBlock } from '@features/ui-kit';

import { ActionCard } from './actionCard/ActionCard';
import { FacilitiesCard } from './facilitiesCard/FacilitiesCard';
import { TotalInformationCard } from './totalInformationCard/TotalInformationCard';
import styles from './WaterUserMonitoring.module.scss';

export const WaterUserMonitoring: React.FC = () => {
  return (
    <InformationalBlock title="Водопользователи">
      <Space direction="vertical" className={styles.wrapper}>
        <TotalInformationCard />
        <ActionCard />
        <FacilitiesCard />
      </Space>
    </InformationalBlock>
  );
};
