import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { useActualStructureTableColumns } from '@features/annualApplication/actualStructure/actualStructureTable/useActualStructureTableColumns';
import {
  CustomCard,
  TableCustom,
} from '@features/ui-kit';
import { AnnualPlantingPlanTypeEnum, IPlantingDataDto } from '@models/annualApplication/interfaces/annualApplication';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDeleteAnnualContractPlantingPlanMutation, useGetAnnualContractByIdQuery } from '@store/gisproApi';
import { getSelectedWaterUserTypeSelector } from '@store/selectors';
import { useMemo } from 'react';
import {
  useActualStructureCreateModal
} from '@features/annualApplication/actualStructure/actualStructureCreateModal/useActualStructureCreateModal';

interface IColumns {
  legal: TableColumn[];
  association: TableColumn[];
}
export const ActualStructureTable: React.FC = () => {
  const { id } = useParams<{ id: any }>();

  const { data: annulContract } = useGetAnnualContractByIdQuery(
    id ? { id } : skipToken,
  );
  const { setShowModal, setSelectedActualStructure } = useActualStructureCreateModal();
  const [deleteRecord, { error, isError }] = useDeleteAnnualContractPlantingPlanMutation();
  const columns = useActualStructureTableColumns();
  const waterUserType = useSelector(getSelectedWaterUserTypeSelector);
  const dataSource = useMemo(() => (annulContract?.rawAnnualPlantingPlans || [])
    .filter(item => item.type === AnnualPlantingPlanTypeEnum.ACTUAL), [annulContract]);
  const onHandleEdit = (record: IPlantingDataDto) => {
    setSelectedActualStructure(record);
    setShowModal(true);
  };

  const deleteItem = (record: IPlantingDataDto) => {
    deleteRecord({ id: record.id })
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>Список фактической структуры сева</Typography.Title>
          </Col>
        </Row>
      }
    >
      <TableCustom
        bordered
        size="middle"
        columns={columns[waterUserType as keyof IColumns] || columns.legal}
        dataSource={dataSource}
        pagination={false}
        actionProps={{
          onHandleEdit,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
