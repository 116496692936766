import { Button, ButtonProps } from 'antd';

import Icon from '@ant-design/icons';
import { ResetIcon } from '@icons/ResetIcon';

import styles from './buttons.module.scss';

type ResetFilterButtonV2PropTypes = ButtonProps;

const ResetIconComponent = () => <ResetIcon />;

export const ResetFilterButtonV2: React.FC<ResetFilterButtonV2PropTypes> = ({
  ...rest
}) => {
  return (
    <Button className={styles.filterButton} {...rest}>
      <Icon component={ResetIconComponent} />
    </Button>
  );
};
