import styles from '@features/ui-kit/customTable/cell/editor/styles.module.scss';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { formatDecimal } from '@utils/formatNumber/formatNumber';
import { InputNumber } from 'antd';
import { set, get } from '@utils/utils';
import React, { useRef } from 'react';

export function renderDecimalEdit<RecordType extends JsonRecord>({
                                                                   record,
                                                                   actionProps,
                                                                   customColumn,
                                                                 }: TableRendererProps<RecordType>) {
  const {
    key,
    decimalIndex,
    dataIndex,
    dynamicIndex,
    editableDecimalCell,
    decimalClassName,
  } = customColumn;
  const inputRef = useRef<any>(null);
  const [className, setClassName] = React.useState<string>('');
  let currentIndex = dataIndex;
  if (dynamicIndex) {
    currentIndex = dynamicIndex(record) ?? dataIndex;
  }
  const indexValue = [...(decimalIndex || []), currentIndex];
  const defaultValue = get(record, indexValue, '-');

  const editable = (() => {
    let editable = typeof editableDecimalCell === 'boolean' ? editableDecimalCell : true;
    if (typeof editableDecimalCell === 'function') {
      editable = editableDecimalCell(record);
    }
    return editable;
  })();
  if (!editable) {
    return <div>{isNaN(defaultValue) ? 0 : formatDecimal(defaultValue ?? 0)}</div>;
  }
  const additionalClassName = (() => {
    if (!decimalClassName) return '';
    return styles[typeof decimalClassName === 'function' ? decimalClassName(record) : decimalClassName];
  })();

  const onPressEnter = (() => {
    const newRecord = { record };
    set(newRecord, ['record', ...indexValue], inputRef.current.value);
    setClassName('');
    actionProps?.onEditDecimalSaveClick?.(newRecord.record, customColumn, inputRef.current.value);
  });

  const onChange = () => {
    // set({ record }, ['record', ...indexValue], inputRef.current.value);
    // console.log(indexValue, inputRef.current.value)
    setClassName(inputRef.current.value === defaultValue ? '' : 'changed');
  };
  return (
    <InputNumber
      ref={inputRef}
      defaultValue={defaultValue}
      parser={(value) => value?.replace(',', '.') as unknown as number}
      className={[styles.decimalEdit, additionalClassName, styles[className]].join(' ')}
      variant="borderless"
      onChange={onChange}
      onPressEnter={onPressEnter}
    />
  );

}
