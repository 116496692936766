import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, notification, Row, Typography } from 'antd';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  StatusWrapper,
  TableCustom,
  TooltipedSelect,
} from '@features/ui-kit';
import { CopyIcon } from '@icons/CopyIcon';
import { PasteIcon } from '@icons/PasteIcon';
import { PrintIcon } from '@icons/PrintIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCalculateWaterUsePlanMutation,
  useGetContractualPlanByGuvhQuery,
  useGetContractualPlanQuery,
  useUpdateContractualPlanMutation,
  useUpdateStatusWaterUsePlanMutation,
} from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setUpdateStatus } from '@store/slices';
import { addRowIndexiesMapper } from '@utils/addRowIndexies/finalArrRowIndexies';
import { get } from '@utils/utils';

import { contractualWaterSupplyPlanDataMapper } from './contractualWaterSupplyPlanDataMapper';
import { useContractualDataQuery } from './useContractualDataQuery';
import { useContractualWaterSupplyPlanColumns } from './useContractualWaterSupplyPlanColumns';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const ContractualWaterSupplyPlan: React.FC = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const hasPermission = useHasUserPermission(ACCESS.ANNUAL_CARD_EDIT);

  const { columns } = useContractualWaterSupplyPlanColumns(
    userRole,
    hasPermission,
  );
  const dispatch = useDispatch();
  const [disabelBtns, setDisableBtns] = useState<any>({
    calculate: false,
    save: false,
  });
  const { data: contractualPlan, isLoading } = useContractualDataQuery(
    userRole as UserRoles,
  );
  const [updateContractual] = useUpdateContractualPlanMutation();
  const [updateStatus] = useUpdateStatusWaterUsePlanMutation();
  const ruvhTables = useSelector(getRuvhTablesInfoSelector);
  const tableStatus = ruvhTables.find(
    (table) => table.type === PlanType.ContractualWaterSupplyPlan,
  )?.status;

  const getCellKey = (
    record: JsonRecord,
    column: TableColumn,
    index: number | undefined,
  ) => {
    if (column.editableCell && column.decimalIndex) {
      return get(record, [...(column.decimalIndex || []), 'id']);
    }
    return -1;
  };
  const onFinish = () => {
    updateStatus({
      type: PlanType.ContractualWaterSupplyPlan,
    }).then((resp) => {
      setDisableBtns({ save: false, calculate: true });
      if ('data' in resp) {
        const status = resp.data.status
          ? PlanStatusEnum.Approved
          : PlanStatusEnum.Editable;

        dispatch(
          setUpdateStatus({
            status,
            type: PlanType.ContractualWaterSupplyPlan,
          }),
        );
      }
    });
  };

  const mappedData = useMemo(() => {
    let data;
    if (contractualPlan) {
      if ('byDistrictDtos' in contractualPlan) {
        data = contractualPlan.byDistrictDtos.map((item: any) => {
          return contractualWaterSupplyPlanDataMapper(item);
        });
      } else {
        data = contractualWaterSupplyPlanDataMapper(contractualPlan);
      }
    }
    return addRowIndexiesMapper((data || [])?.flat(), 2);
  }, [contractualPlan]);
  console.log('mappedData', mappedData);

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const item = get(record, [...(column.decimalIndex || [])]);
    const getId = item.id.split('-')[0];
    updateContractual({
      raws: [{ id: getId, volume: item.volume }],
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        setDisableBtns({ save: true, calculate: true });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  useEffect(() => {
    if (
      contractualPlan &&
      'status' in contractualPlan &&
      contractualPlan.status
    ) {
      dispatch(
        setUpdateStatus({
          type: PlanType.ContractualWaterSupplyPlan,
          status: contractualPlan.status,
        }),
      );
    }
  }, [contractualPlan]);

  return (
    <Col span={24}>
      <InformationalBlock>
        <CustomCard
          title={
            <Row justify="space-between">
              <Col span={12}>
                {userRole === UserRoles.ROLE_RUVH ? (
                  <StatusWrapper status={tableStatus}>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      План хоздоговорной водоподачи
                    </Typography.Title>
                  </StatusWrapper>
                ) : (
                  <Typography.Title style={{ marginBottom: 0 }} level={4}>
                    План хоздоговорной водоподачи
                  </Typography.Title>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={10} justify="end">
                  {userRole === UserRoles.ROLE_RUVH && (
                    <Row gutter={10}>
                      <Col>
                        <TooltipedSelect placeholder="Год" />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<CopyIcon />} />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<PasteIcon />} />
                      </Col>
                      {hasPermission ? (
                        <Col>
                          <PrimaryButton
                            disabled={!disabelBtns.save}
                            onClick={onFinish}
                            htmlType="submit"
                          >
                            Сохранить
                          </PrimaryButton>
                        </Col>
                      ) : null}
                    </Row>
                  )}
                  <Col>
                    <PrimaryButton icon={<PrintIcon />} />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <TableCustom
            bordered
            dataSource={mappedData || []}
            scroll={{ y: 'calc(100vh - 461px)', x: 3000 }}
            size="small"
            cellKey={getCellKey}
            columns={columns}
            loading={isLoading}
            actionProps={{ onEditDecimalSaveClick }}
          />
        </CustomCard>
      </InformationalBlock>
    </Col>
  );
};
