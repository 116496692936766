import { InformationalBlock, TableCustom } from '@features/ui-kit';
import { useGetGovSummaryWaterUsePlanQuery } from '@store/gisproApi';

import { useWaterUseRuvhGovSummaryColumns } from './useWaterUseRuvhGovSummaryColumns';
import { waterUsePlanRuvhGovSummaryDataMapper } from './waterUsePlanRuvhGovSummaryDataMapper';

export const WaterUsePlanRuvhGovSummary: React.FC = () => {
  const { columns } = useWaterUseRuvhGovSummaryColumns();
  const { data: govSummary = [], isLoading: govSummaryLoading } =
    useGetGovSummaryWaterUsePlanQuery();
  return (
    <div>
      {govSummary?.map((item) => {
        const dataSource = waterUsePlanRuvhGovSummaryDataMapper(item.items);
        return (
          <InformationalBlock title={item.title} key={item.title}>
            <TableCustom
              columns={columns}
              dataSource={dataSource}
              loading={govSummaryLoading}
              bordered
              pagination={false}
            />
          </InformationalBlock>
        );
      })}
    </div>
  );
};
