import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Row, Table, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { IEstimateAndFinancialPlanTotal } from '@models/annualApplication/interfaces/annualApplication';

import { EstimatedFinancialPlanSummary } from './EstimatedFinancialPlanSummary';
import { useEstimatedFinancialPlanColumns } from './useEstimatedFinancialPlanColumns';

type EstimatedFinancialPlanTablePropTypes = {
  estimateAndFinancialPlanTotal?: IEstimateAndFinancialPlanTotal;
};

export const EstimatedFinancialPlanTable: React.FC<
  EstimatedFinancialPlanTablePropTypes
> = ({ estimateAndFinancialPlanTotal }) => {
  const { t } = useTranslation();

  const { columns } = useEstimatedFinancialPlanColumns();
  const dataSource = Object.entries(estimateAndFinancialPlanTotal || {}).map(
    ([key, value]) => ({
      ...value,
      quarterName: t(`quarters.${key}`),
    }),
  );

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>
                Сметно-финансовый план
              </Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Row>
          }
        >
          <Table
            size="small"
            columns={columns}
            bordered
            dataSource={dataSource}
            // eslint-disable-next-line react/no-unstable-nested-components
            summary={(summData) => (
              <EstimatedFinancialPlanSummary data={summData} />
            )}
            pagination={false}
          />
        </CustomCard>
      </Col>
    </Row>
  );
};
