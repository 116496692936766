import { DefaultButton, PrimaryButton } from '@features/ui-kit';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';

import {
  CustomModalV2,
  DefaultButtonV2,
  PrimaryButtonV2,
} from '@features/ui-kit-v2';
import { BigQuestionMarkIcon } from '@icons/BigQuestionMarkIcon';
import { BigSuccessIcon } from '@icons/BigSuccessIcon';
import { ActsInvoicesForPaymentsStatusEnum } from '@models/actsInvoicesForPayments/enums/actsInvoicesForPaymentsEnum';
import { useActToBillMutation } from '@store/gisproApi';

import styles from './ConfirmActModal.module.scss';
import { useConfirmActModal } from './useConfirmActModal';

export const ConfirmActModal: FC = () => {
  const [success, setSuccess] = useState(false);
  const { isModalShown, setShowModal, selectedActsInvoicesForPayment } =
    useConfirmActModal();
  const { t } = useTranslation();
  const [save, { data }] = useActToBillMutation();

  const onClose = () => {
    setShowModal(false);
  };

  const onConfirmAct = () => {
    if (selectedActsInvoicesForPayment) {
      save(selectedActsInvoicesForPayment);
    }
  };

  useEffect(() => {
    if (data && data.status === ActsInvoicesForPaymentsStatusEnum.billCreated) {
      setSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedActsInvoicesForPayment &&
      (selectedActsInvoicesForPayment.status ===
        ActsInvoicesForPaymentsStatusEnum.billCreated ||
        selectedActsInvoicesForPayment.status ===
          ActsInvoicesForPaymentsStatusEnum.paid)
    ) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [selectedActsInvoicesForPayment]);

  const confirmAction = (
    <>
      <Row justify="center">
        <Col span={5}>
          <BigQuestionMarkIcon />
        </Col>
      </Row>
      <Row justify="center" className={styles.marginTop}>
        <Col span={20}>
          <Typography.Title level={3} className={styles.alignCenter}>
            {t('invoicesPayment.modal.actConfirm.title')}
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="center" className={styles.marginTop}>
        <Col span={20}>
          <Typography.Paragraph className={styles.alignCenter}>
            {t('invoicesPayment.modal.actConfirm.text')}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row gutter={30} justify="space-between">
        <Col>
          <DefaultButton
            onClick={onClose}
            title={t('invoicesPayment.modal.actConfirm.cancel')}
          />
        </Col>
        <Col>
          <PrimaryButton
            htmlType="submit"
            onClick={onConfirmAct}
            title={t('invoicesPayment.modal.actConfirm.confirm')}
          />
        </Col>
      </Row>
    </>
  );

  const successAction = (
    <>
      <Row justify="center">
        <Col span={5}>
          <BigSuccessIcon />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Typography.Title level={3} className={styles.alignCenter}>
            {t('invoicesPayment.modal.actSuccess.title')}
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="center" className={styles.marginTop}>
        <Col span={20}>
          <Typography.Paragraph className={styles.alignCenter}>
            {t('invoicesPayment.modal.actSuccess.text')}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row gutter={30} justify="space-between" className={styles.marginTop}>
        <Col>
          <DefaultButtonV2
            htmlType="submit"
            onClick={onClose}
            title={t('invoicesPayment.modal.actSuccess.cancel')}
          />
        </Col>
        <Col>
          <PrimaryButtonV2
            htmlType="submit"
            onClick={onClose}
            title={t('invoicesPayment.modal.actSuccess.confirm')}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <CustomModalV2
      centered
      open={isModalShown}
      onCancel={onClose}
      width={512}
      closeIcon={null}
      footer={false}
    >
      {!success ? confirmAction : successAction}
    </CustomModalV2>
  );
};
