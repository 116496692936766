import React, { useEffect, useRef } from 'react';

import { Form, Input, InputRef } from 'antd';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export const EditDecadePlanCell: React.FC<TableEditorProps> = ({
  customColumn,
  editValidator,
  record,
}) => {
  const inputRef = useRef<InputRef>(null);
  const { dataIndex } = customColumn;
  const validationRules = editValidator?.rules ?? [];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <Form.Item
      name={[dataIndex, 'value']}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: '',
        },
        ...validationRules,
      ]}
    >
      <Input
        ref={inputRef}
        bordered={false}
        className={styles.editDecadePlaneCellInput}
      />
    </Form.Item>
  );
};
