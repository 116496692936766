import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedSelect,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { ITariffFilters } from '@models/tariff/interfaces/tariff';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllRuvhOrganizationsQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';

import styles from './TariffMonitoringTableFilters.module.scss';
import { useTariffMonitoringTableFilters } from './useTariffMonitoringTableFilters';
import { useGetCurrentUser } from '@features/authentication';

export const TariffMonitoringTableFilters = () => {
  const [filtersState, setFiltersState] = useState<Partial<ITariffFilters>>({});
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { filters, setTariffMonitoringFilters } =
    useTariffMonitoringTableFilters();
  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId
      ? {
          parentId: parseInt(filtersState.regionId, 10),
        }
      : skipToken,
  );

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: organizations = [] } = useFindAllRuvhOrganizationsQuery();

  const onRegionChange = (v: string) => {
    setFiltersState((state) => ({
      ...state,
      districtId: null,
      regionId: v,
    }));
  };

  const onFilterChange = (filterKey: keyof ITariffFilters, v: string) => {
    setFiltersState((state) => ({
      ...state,
      [filterKey]: v,
    }));
  };

  const onFilterButtonClick = () => {
    setTariffMonitoringFilters(filtersState as ITariffFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      organizationId: null,
      year: null,
      // page: 1,
      // size: 10,
    });
    setTariffMonitoringFilters({
      regionId: user?.owner?.region?.id.toString() || null,
      districtId: user?.owner?.district?.id.toString() || null,
      organizationId: null,
      year: null,
      isActive: true,
      // page: 1,
      // size: 10,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Row align="middle" gutter={16} className={styles.selectWrapper}>
        <Col span={5}>
          <TooltipedSelect
            placeholder="Область"
            value={
              regions.find(
                (item) => item.id === toInteger(filtersState.regionId),
              )?.label
            }
            onChange={(v: string) => onRegionChange(v)}
            options={regions}
            allowClear
            disabled={isDisabledRegion}
          />
        </Col>
        <Col span={5}>
          <TooltipedSelect
            placeholder="Район"
            options={districts}
            disabled={!filtersState.regionId || isDisabledDistrict}
            value={
              districts.find(
                (item) => item.id === toInteger(filtersState.districtId),
              )?.label
            }
            onChange={(v: string) => onFilterChange('districtId', v)}
            allowClear
          />
        </Col>
        <Col span={5}>
          <TooltipedSelect
            bordered={false}
            placeholder="Организация"
            options={organizations.map((item) => ({
              label: item.organizationName,
              value: item.organizationId,
            }))}
            value={
              organizations.find(
                (item) =>
                  item.organizationId ===
                  toInteger(filtersState.organizationId),
              )?.organizationName
            }
            onChange={(v: string) => onFilterChange('organizationId', v)}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedDatePicker
            bordered
            value={filtersState.year ? dayjs(filtersState.year) : undefined}
            onChange={(value: Dayjs | null) =>
              onFilterChange('year', String(value?.year()))
            }
            // clearIcon={false}
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
          />
        </Col>
        <Col span={4} flex="0 0 100px" className={styles.filterCol}>
          <Row className={styles.filterBtns} wrap={false}>
            <PrimaryButton
              onClick={onFilterButtonClick}
              icon={<FilterIcon />}
            />
            <PrimaryButton
              onClick={onResetFilterButtonClick}
              icon={<ResetFilterIcon />}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <PrimaryButton>Создать тариф</PrimaryButton>
        </Col>
      </Row>
    </Row>
  );
};
