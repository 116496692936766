import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Divider, Row, Space, Typography } from 'antd';

import { Links } from '@enums/links';
import { Routes } from '@enums/routes';
import { CustomModalV2, NavigateButtonV2 } from '@features/ui-kit-v2';
import { getCurrentIrrigationSystemSelector } from '@store/selectors';
import { clearIrrigationSystemAction } from '@store/slices';

import styles from './CreateIrrigationSystemActionSelect.module.scss';
import { useCreateIrrigationSystemActionSelect } from './useCreateIrrigationSystemActionSelect';
import { CustomModal } from '@features/ui-kit';
import { NavigateButton } from '@features/ui-kit/buttons/NavigateButton';
import { PipeIcon } from '@icons/PipeIcon';
import Icon from '@ant-design/icons/lib/components/Icon';
import { HomeIcon } from '@icons/HomeIcon';

export const CreateIrrigationSystemActionSelect: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isModalShown, setShowModal } =
    useCreateIrrigationSystemActionSelect();
  const currentIrrigationSystem = useSelector(
    getCurrentIrrigationSystemSelector,
  );

  const onFinish = useCallback(() => {
    setShowModal(false);
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  }, []);

  const onContinue = useCallback(() => {
    setShowModal(false);
    navigate(
      generatePath(Routes.IrrigationSystemMainConduitRegistration, {
        systemId: currentIrrigationSystem?.systemId || '0',
        mainConduitId: 'none',
      }),
    );
  }, [currentIrrigationSystem]);

  return (
    <CustomModal
      centered
      title={'Выбор действия'}
      open={isModalShown}
      onCancel={() => setShowModal(false)}
      width={400}
      footer={<span />}
    >
      <Row justify="center">
        <Space
          size="large"
          direction="vertical"
          className={styles.actionButtons}
        >
          <Row>
            <NavigateButton
              icon={<Icon className={styles.pipeIcon} component={PipeIcon} />}
              onClick={onContinue}
              title="Создать главного водовода"
            />
          </Row>

          <Row>
            <NavigateButton
              icon={<Icon className={styles.homeIcon} component={HomeIcon} />}
              onClick={onFinish}
              title="Завершить"
            />
          </Row>
        </Space>
      </Row>
    </CustomModal>
  );
};
