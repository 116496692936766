import { UserRoles } from '@enums/roles';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useHydroWorkPlanColumns = (
  userRole: UserRoles = UserRoles.ROLE_RUVH,
  canEdit: boolean = false,
) => {
  const isShowRole =
    userRole === UserRoles.ROLE_CA || userRole === UserRoles.ROLE_GUVH;

  const columns: TableColumn[] = [
    {
      title: 'Наименование РУВХ, БУВХ',
      dataIndex: 'districtName',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'asds',
      title: 'Общее кол-во г/постов',
      xtype: CustomTableColumnType.String,
      align: 'center',
      children: [
        {
          dataIndex: 'total',
          title: 'Всего',
          xtype: CustomTableColumnType.DecimalEdit,
          align: 'center',
          editableDecimalCell: () => {
            if (!canEdit) {
              return false;
            }
            return !isShowRole;
          },
        },
        {
          dataIndex: 'equipped',
          title: 'Оборудовано',
          xtype: CustomTableColumnType.DecimalEdit,
          align: 'center',
          editableDecimalCell: () => {
            if (!canEdit) {
              return false;
            }
            return !isShowRole;
          },
        },
      ],
    },
    {
      dataIndex: 'shouldWork',
      title: 'Должно работать г/постов',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'leveling',
      title: 'Нивелирование постовых знаков',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'taring',
      title: 'Тарирование',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'waterLevelMonitoring',
      title: 'Наблюдение за уровнем воды',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'waterFlowMeasurement',
      title: 'Измерение расходов воды',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'scheduledInspection',
      title: 'Плановая инспекция',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'studyingChannelEfficiency',
      title: 'Изучение КПД, каналов, км',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      editableDecimalCell: () => {
        if (!canEdit) {
          return false;
        }
        return !isShowRole;
      },
    },
    {
      dataIndex: 'asds',
      title: 'Подлежит на 2017 год',
      xtype: CustomTableColumnType.DecimalEdit,
      align: 'center',
      children: [
        {
          dataIndex: 'certification',
          title: 'Аттестация',
          xtype: CustomTableColumnType.DecimalEdit,
          align: 'center',
          editableDecimalCell: () => {
            if (!canEdit) {
              return false;
            }
            return !isShowRole;
          },
        },
        {
          dataIndex: 'verification',
          title: 'Проверка',
          xtype: CustomTableColumnType.DecimalEdit,
          align: 'center',
          editableDecimalCell: () => {
            if (!canEdit) {
              return false;
            }
            return !isShowRole;
          },
        },
      ],
    },
  ];

  return { columns };
};
