import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@store/index';
import {
  getSelectedActualStructureSelector,
  getShowCreateActualStructureModalSelector,
} from '@store/selectors';
import { annualApplicationActions } from '@store/slices';
import { IPlantingDataDto } from '@models/annualApplication/interfaces/annualApplication';

export const useActualStructureCreateModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isModalShow = useSelector(getShowCreateActualStructureModalSelector);
  const selectedActualStructure = useSelector(getSelectedActualStructureSelector);

  const setSelectedActualStructure = (item: IPlantingDataDto | null) => {
    dispatch(
      annualApplicationActions.setSelectedActualStructureAction(item),
    );
  }

  const setShowModal = (isShow: boolean) => {
    dispatch(
      annualApplicationActions.setShowCreateActualStructureModal(isShow),
    );
  };

  return { isModalShow, setShowModal, setSelectedActualStructure, selectedActualStructure };
};
