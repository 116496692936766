import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Row, Space } from 'antd';

import { Routes } from '@enums/routes';
import {
  DefaultButtonV2,
  SectionTitle,
  TablePreviewV2,
} from '@features/ui-kit-v2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllByOrganizationIdQuery } from '@store/gisproApi';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { toInteger } from '@utils/utils';

import { useDocumentColumns } from '../../useDocumentColumns';

export const Documents = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { columns } = useDocumentColumns();

  const { data: physical } = useFindAllByOrganizationIdQuery(
    id
      ? {
          id: toInteger(id),
        }
      : skipToken,
  );

  const onWatch = (file: any) => {
    const url = `${process.env.REACT_APP_MAIN_BASE_URL}/api/v1/attachment/download?path=${file.filePath}`;
    downloadAttachment({
      ...file,
      originName: file.name,
      fileUrl: url,
    });
  };

  const onBackButtonClick = () => {
    navigate(Routes.WaterRegistryUsers);
  };

  return (
    <Space direction="vertical" size={20} style={{ width: '100%' }}>
      <Row gutter={15}>
        <Col span={24}>
          <SectionTitle background="cyan" title={t('waterUser.documents.h1')} />
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <TablePreviewV2
            dataSource={physical?.attachmentResponseDtos || []}
            columns={columns}
            pagination={false}
            actionProps={{
              onWatch,
            }}
          />
        </Col>
      </Row>
      <Row gutter={30} justify="space-between">
        <Col span={3}>
          <DefaultButtonV2 onClick={onBackButtonClick} title="Назад" />
        </Col>
      </Row>
    </Space>
  );
};
