import { months, monthsTranslate } from '@constants/month';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useWaterUsePlanCATableColumns = () => {
  const columns: TableColumn[] = [
    {
      title: 'Наименование района',
      dataIndex: 'name',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'id',
      align: 'left',
      width: 40,
    },
    {
      title: 'Система',
      dataIndex: 'system',
      xtype: CustomTableColumnType.String,
      key: 'code',
      align: 'left',
      width: 100,
    },
    {
      title: 'Показатели',
      dataIndex: 'indicators',
      xtype: CustomTableColumnType.String,
      key: 'type',
      align: 'left',
      width: 100,
    },
    {
      title: 'Ед. изм.',
      dataIndex: 'unitChange',
      xtype: CustomTableColumnType.String,
      key: 'picket',
      align: 'left',
      width: 100,
    },
    ...months.map((month): any => ({
      title: month,
      dataIndex: monthsTranslate[month],
      xtype: CustomTableColumnType.String,
      key: monthsTranslate[month],
      align: 'center',
      children: [
        {
          dataIndex: '1',
          title: 'I',
          align: 'center',
          width: 50,
        },
        {
          dataIndex: '2',
          title: 'II',
          align: 'center',
          width: 50,
        },
        {
          dataIndex: '3',
          title: 'III',
          align: 'center',
          width: 50,
        },
      ],
    })),
  ];

  return { columns };
};
