import { Divider } from 'antd';

import styles from './WaterUsePlanGuvhView.module.scss';
import { WaterUsePlanGuvhViewControlBar } from './waterUsePlanGuvhViewControlBar/waterUsePlanGuvhViewControlBar';
import { WaterUsePlanGuvhViewTable } from './waterUsePlanGuvhViewTable';

export const WaterUsePlanGuvhView: React.FC = () => {
  return (
    <>
      <WaterUsePlanGuvhViewControlBar />
      <Divider className={styles.divider} />
      <WaterUsePlanGuvhViewTable />
    </>
  );
};
