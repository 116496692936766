import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import {
  TooltipedInput,
  TooltipedSelect,
  PrimaryButton,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { IAgroClimaticZoneHydroModule } from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHydroModuleFilters } from '@features/agroClimaticZone/agroClimaticZoneCard/hydroModuleTable/useHydroModuleFilters';

export const HydroModuleTableFilters = () => {
  const [filtersState, setFiltersState] = useState<
    Partial<IAgroClimaticZoneHydroModule>
  >({});
  const { filters, setHydroModuleFilters } = useHydroModuleFilters();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    filtersState.regionId ? { parentId: filtersState.regionId } : skipToken,
  );

  const onFilterButtonClick = () => {
    setHydroModuleFilters(filtersState);
  };

  const onFilterChange = (
    filterKey: keyof IAgroClimaticZoneHydroModule,
    value: string | number,
  ) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: value,
    };
    setFiltersState(updatedFilters);
  };

  const onRegionChange = (value: number) => {
    setFiltersState((state) => ({
      ...state,
      districtId: undefined,
      regionId: value,
    }));
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({});
    setHydroModuleFilters({
      grmName: '',
      districtId: undefined,
      regionId: undefined,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row align="middle" gutter={16}>
      <Col span={4}>
        <TooltipedInput
          size="middle"
          prefix
          onChange={({ target }) => onFilterChange('grmName', target.value)}
          placeholder="Наименование"
          value={filtersState.grmName}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="middle"
          value={filtersState?.regionId && Number(filtersState.regionId)}
          onChange={(value: number) => onRegionChange(value)}
          allowClear
          placeholder="Область"
          options={regions.map((region) => ({
            label: region.label,
            value: region.id,
          }))}
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          size="middle"
          allowClear
          placeholder="Район"
          options={districts.map((district) => ({
            label: district.label,
            value: district.id,
          }))}
          disabled={!filtersState.regionId}
          value={filtersState?.districtId && Number(filtersState.districtId)}
          onChange={(value) => onFilterChange('districtId', value)}
        />
      </Col>
      <Col span={2}>
        <Row wrap={false} gutter={10}>
          <Col>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
          </Col>
          <Col>
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
