import React, { useRef } from 'react';

import { Button, Carousel as CarouselAntd } from 'antd';
import { CarouselProps, CarouselRef } from 'antd/es/carousel';

import { Links } from '@enums/links';

import { CarouselItem } from './CarouselItem';
import styles from './FadeCarousel.module.scss';

type FadeCarouselPropsType = CarouselProps & {
  items: Array<{
    header: string;
    text: string;
    imgPath: string;
  }>;
};

export const FadeCarousel: React.FC<FadeCarouselPropsType> = ({ items }) => {
  const slider = useRef(null);
  const slides = items.map((item) => (
    <CarouselItem item={item} key={item.imgPath} />
  ));

  return (
    <>
      <Button
        onClick={() => slider.current && (slider.current as CarouselRef).prev()}
        className={`${styles.carouselArrow} ${styles.prev}`}
      >
        <img src={Links.ArrowLeft} alt="" />
      </Button>
      <CarouselAntd ref={slider} effect="fade">
        {slides}
      </CarouselAntd>
      <Button
        onClick={() => slider.current && (slider.current as CarouselRef).next()}
        className={`${styles.carouselArrow} ${styles.next}`}
      >
        <img src={Links.ArrowRight} alt="" />
      </Button>
    </>
  );
};
