import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';

export const EditString: React.FC<TableEditorProps> = React.memo(
  ({ customColumn, editValidator }) => {
    const { t } = useTranslation();
    const { dataIndex, title } = customColumn;
    const validationRules = editValidator?.rules;

    return (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={
          validationRules ?? [
            {
              required: true,
              message:
                t('customComponents.table.cellEditor.enterValue', {
                  value: title,
                }) || '',
            },
          ]
        }
      >
        <Input />
      </Form.Item>
    );
  },
);
