export const AuthApi = {
  Login: '/api/v1/auth/authenticate',
  Profile: '/api/v1/users',
  Register: '/api/v1/auth/register',
  LoginByGoogle: '/api/v1/auth/loginByGoogle',
  RefreshToken: '/api/v1/auth/refresh-token',
};

export const AttachmentApi = {
  Upload: '/rest/attachment/upload',
};

export const IrrigationSystemApi = {
  getById: '/rest/irrigation-system/system',
};

export const geoNodeParams = {
  outputFormat: 'application/json',
  service: 'WFS',
  version: '1.1.0',
  request: 'GetFeature',
};
