import React, { FC, useEffect } from 'react';

import { Col, Divider, Form, Row, Space } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { useGetCurrentUser } from '@features/authentication';
import { useCreateSourceModal } from '@features/source';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  SectionTitle,
  TooltipedInput,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllIrrigationSystemsOwnersQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetAllBasinQuery,
  useGetSourcesQuery,
} from '@store/gisproApi';
import dayjs from 'dayjs';

import styles from './CommonDataFields.module.scss';

export const CommonDataFields: FC = () => {
  const form = Form.useFormInstance();
  const { setShowModal, setSelectedBassinId, setSelectedRegionId } =
    useCreateSourceModal();
  const { user, isDisabledRegion, isDisabledDistrict } = useGetCurrentUser();

  const bassinId = Form.useWatch('bassinId', form);
  const regionId = Form.useWatch('regionId', form);

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });

  const { data: district, isLoading: districtIsLoading } =
    useFindReferencesByParentIdQuery(
      regionId ? { parentId: regionId } : skipToken,
    );

  const { data: bassins = [], isLoading: bassinsIsLoading } =
    useGetAllBasinQuery(regionId ? { regionId } : skipToken);

  const { data: sources = [], isLoading: sourcesIsLoading } =
    useGetSourcesQuery({ regionId, basinId: bassinId });

  const { data: systemTypes = [], isLoading: systemTypesIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.IrrigationSystemsType,
    });

  const { data: owners = [], isLoading: ownersIsLoading } =
    useFindAllIrrigationSystemsOwnersQuery();

  const onAddSourceClick = () => {
    setSelectedBassinId(bassinId);
    setSelectedRegionId(regionId);

    setShowModal(true);
  };

  const onBassinChange = () => {
    form.setFieldValue('baseId', null);
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        regionId: user?.owner?.region?.id,
        districtId: user?.owner?.district?.id,
      });
    }
  }, [user]);

  return (
    <InformationalBlock>
      <Space
        size="large"
        direction="vertical"
        style={{ display: 'flex', gap: 8 }}
        className={styles.root}
      >
        <SectionTitle title="Общие данные" />
        <CustomCard title="Данные системы" withoutBottomPadding>
          <Row>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="name"
                    label="Наименования системы"
                    required
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Регистрационный номер"
                    name="regNum"
                    required
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Тип системы"
                    name="systemTypeId"
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      size="large"
                      loading={systemTypesIsLoading}
                      options={systemTypes}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={8}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Область"
                        name="regionId"
                        rules={[{ required: true }]}
                      >
                        <TooltipedSelect
                          size="large"
                          loading={regionIsLoading}
                          options={regions}
                          disabled={isDisabledRegion}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Район"
                        name="districtId"
                        rules={[{ required: true }]}
                      >
                        <TooltipedSelect
                          size="large"
                          disabled={isDisabledDistrict || !regionId}
                          loading={districtIsLoading}
                          options={district}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Бассейн"
                        name="bassinId"
                        rules={[{ required: true }]}
                      >
                        <TooltipedSelect
                          size="large"
                          onBlur={onBassinChange}
                          loading={bassinsIsLoading}
                          options={bassins}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Источник"
                        name="baseId"
                        rules={[{ required: true }]}
                      >
                        <TooltipedSelect
                          loading={sourcesIsLoading}
                          options={sources}
                          size="large"
                          disabled={!bassinId}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <PrimaryButton
                                  disabled={!bassinId || !regionId}
                                  onClick={onAddSourceClick}
                                >
                                  Добавить источник
                                </PrimaryButton>
                              </Space>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Построена по проекту"
                        required
                        name="builtProject"
                        rules={[{ required: true }]}
                      >
                        <TooltipedInput size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="residualValue"
                    label="Остаточная балансовая стоймость на момент регистрации (сом)"
                    required
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" type="number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Сумма износа (сом)"
                    name="wearSum"
                    required
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput size="large" type="number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Год ввода в эксплуатацию"
                    rules={[{ required: true }]}
                    name="dateStart"
                  >
                    <YearPicker
                      size="large"
                      style={{ width: '100%' }}
                      disabledDate={(current) =>
                        current && current > dayjs().endOf('year')
                      }
                      allowClear
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </CustomCard>
      </Space>
    </InformationalBlock>
  );
};
