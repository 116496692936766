export enum GeoPortalTableQueryTypes {
  interactiveMap = 'interactiveMap',
  maps = 'maps',
}
export enum GeoPortalMapQueryTypes {
  Quality = 'qualityIndicator',
  IrrigationSystems = 'irrigationSystems',
  WaterUser = 'waterUser',
  WaterAccounting = 'waterAccounting',
  Monitoring = 'monitoring',
  Archives = 'archives',
  Analitics = 'analitics',
}
export enum GeoPortalViewType {
  List = 'List',
  Block = 'Block',
}

export enum GeoPortalCardBlockType {
  Layer = 'layer',
  Legend = 'legend',
}

export enum GeoPortalCardDataType {
  CardData = 'cardData',
  OperationalData = 'operData',
}

export enum informationalMonitoringType {
  Important = 'important',
  Instruction = 'instruction',
}

export enum DataGeoJsonType {
  SystemObject = 'systemobject',
  Facility = 'facility',
  Association = 'association',
}

export enum GeoAttributeTypeEnum {
  SYSTEM_OBJECT = 'system_object',
  WATER_USER = 'water_user',
  FACILITY = 'facility',
  M_HYDROPOST = 'm_hydropost',
  M_OUTLET = 'm_outlet',
  M_CHANNELS = 'm_channels',
}
