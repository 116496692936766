import { useParams } from 'react-router-dom';

import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFindAllByOrganizationIdQuery } from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { AssociationWaterUserCard } from './associationWaterUserCard';
import { LegalWaterUserCard } from './legalWaterUserCard';
import { PhyicalWaterUserCard } from './phyicalWaterUserCard';

export const WaterUserCard = () => {
  const { id: waterUserId } = useParams();
  const { data: waterUser } = useFindAllByOrganizationIdQuery(
    waterUserId
      ? {
          id: toInteger(waterUserId),
        }
      : skipToken,
  );
  const currentWaterUserType = waterUser?.type;

  let Component;
  switch (currentWaterUserType) {
    case WaterUserEnum.INDIVIDUAL:
      Component = PhyicalWaterUserCard;
      break;
    case WaterUserEnum.LEGAL:
      Component = LegalWaterUserCard;
      break;
    case WaterUserEnum.ASSOCIATION:
      Component = AssociationWaterUserCard;
      break;
    default:
      Component = PhyicalWaterUserCard;
      break;
  }

  return <Component />;
};
