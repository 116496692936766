import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getContactsModalSelector } from '@store/selectors';
import { setContactsModal } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export function useContactsModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getContactsModalSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    console.log('showModal: ', showModal);
    dispatch(setContactsModal(showModal));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
}
