import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedSelect,
} from '@features/ui-kit';
import { usePrintModal } from '@features/waterUsePlan/waterUsePlanRuvh/waterUsePlanRuvhModal/usePrintModal';
import { PrintIcon } from '@icons/PrintIcon';

import styles from './PrintModal.module.scss';

export const PrintModal: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setShowModal, isModalShown } = usePrintModal();
  const onCancel = () => {
    setShowModal(false);
  };
  return (
    <CustomModal
      open={isModalShown}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={445}
      onCancel={onCancel}
      footer={false}
      title={'Печать'}
      centered
    >
      <Form form={form} layout="vertical">
        <Row gutter={[0, 48]}>
          <Col span={24}>
            <Col span={24}>
              <Form.Item
                label="Год"
                name="year"
                style={{ marginTop: 24, marginBottom: 16 }}
                required
                className={styles.datePiker}
              >
                <TooltipedDatePicker placeholder="Выберите год" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Месяц"
                name="month"
                style={{ marginTop: 24, marginBottom: 16 }}
                required
              >
                <TooltipedSelect placeholder="Выберите месяц" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Декада"
                name="decad"
                style={{ marginTop: 24, marginBottom: 16 }}
                required
              >
                <TooltipedSelect placeholder="Выберите декаду" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Водопользователь"
                name="decad"
                style={{ marginTop: 24, marginBottom: 16 }}
                required
              >
                <TooltipedSelect
                  placeholder="Выберите водопользователя"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={16} className={styles.modalBtn}>
              <Col>
                <DefaultButton size="large" onClick={onCancel}>
                  Отмена
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  size="large"
                  onClick={onCancel}
                  icon={<PrintIcon />}
                >
                  Печать
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
