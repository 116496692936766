import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  CustomModal,
  PrimaryButton,
  TelegramButton,
  TooltipedInput,
  WhatsAppButton,
} from '@features/ui-kit';
import {
  CustomModalV2,
  PrimaryButtonV2,
  TooltipedInputV2,
} from '@features/ui-kit-v2';
import { useWaterUserPermissionModal } from '@features/waterRegistry/masterRegistration';
import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';
import { useSignUpMutation } from '@store/gisproApi';
import { mapPhoneNumberToLong } from '@utils/phone';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { passportValidator } from '@utils/validation/passportValidator';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

import styles from './LegalPermissionsModal.module.scss';

type IndividualPermissionsModalForm = {
  login: string;
  password: string;
  pin: string;
  phone: string;
  email: string;
};

export const LegalPermissionsModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [signUp] = useSignUpMutation();
  const { waterUserId } = useWaterUserId();
  const { organizationId } = useOrganizationId();
  const { isModalShownLegal, setShowModalLegal } =
    useWaterUserPermissionModal();

  const onFinish = () => {
    signUp([
      {
        ...form.getFieldsValue(),
        waterUserId,
        organizationId,
        isOrganization: true,
      },
    ]);
    navigate({
      pathname: Routes.WaterRegistryUsers,
      search: `?${SectionQueryKey}=${WaterUserTypes.physical}`,
    });
    setShowModalLegal(false);
  };

  return (
    <CustomModal
      centered
      title="Выдача доступов"
      open={isModalShownLegal}
      onCancel={() => setShowModalLegal(false)}
      width={840}
      footer={
        <Row justify="end">
          <Col>
            <PrimaryButton
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              title="Завершить"
            />
          </Col>
        </Row>
      }
    >
      <Form<IndividualPermissionsModalForm>
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ padding: '20px' }}
        onFinish={onFinish}
      >
        <Row gutter={[30, 30]}>
          <Col span={12}>
            <Row>
              <Form.Item
                label={t('authentication.login.placeholders.login')}
                name="login"
                rules={[{ required: true }]}
                style={{ width: '100%' }}
              >
                <TooltipedInput allowClear />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t('authentication.login.placeholders.password')}
                name="password"
                rules={[{ required: true }, passportValidator]}
                style={{ width: '100%' }}
              >
                <TooltipedInput allowClear />
              </Form.Item>
            </Row>
            <Row className={styles.messengerBtn}>
              <Space size="large">
                <TelegramButton name="" />
                <WhatsAppButton name="" />
              </Space>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Form.Item
                label={t('createIndividualWaterRegistry.modal.pin')}
                name="inn"
                rules={[{ required: true }, { len: 14 }, numbersValidator]}
                style={{ width: '100%' }}
              >
                <TooltipedInput maxLength={14} allowClear />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.phone',
                )}
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                  phoneNumberValidator,
                ]}
                normalize={mapPhoneNumberToLong}
                style={{ width: '100%' }}
              >
                <TooltipedInput prefix="+996" allowClear />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.email',
                )}
                name="email"
                rules={[{ required: true, type: 'email' }]}
                style={{ width: '100%' }}
              >
                <TooltipedInput allowClear />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
