import { Button, Row, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Links } from '@enums/links';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

import styles from './BuildingTable.module.scss';
import { ACCESS } from '@enums/permisson';

export interface BuildingDataType {
  id: number;
  name: string;
  object: string;
  objectCategory: string;
  system: string;
  direction: string;
  budget: {
    plan: number;
    fact: number;
  };
  deadline: {
    start: {
      plan: string;
      fact: string;
    };
    end: {
      plan: string;
      fact: string;
    };
  };
  status: string;
}
export const useBuildingTableColumns = (isArchived = false) => {
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Направление',
      align: 'center',
      dataIndex: 'direction',
      xtype: CustomTableColumnType.RenderCustom,
      width: 150,
      key: 'direction',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.direction?.title || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Объект',
      align: 'center',
      dataIndex: 'constructionObject',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'constructionObject',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.constructionObject?.name || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Категория объекта',
      align: 'center',
      dataIndex: 'constructionObject',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'constructionObject',
      width: 150,
      renderCustom: ({ record }) => {
        return (
          <div>
            <span>{record?.constructionObject?.typeCategory || ''}</span>
          </div>
        );
      },
    },
    {
      title: 'Система',
      align: 'center',
      dataIndex: 'system',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'system',
      renderCustom: ({ record }) => (
        <div>
          <span>{record?.system?.name || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Сроки',
      align: 'center',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 250,
      xtype: CustomTableColumnType.String,
      deadlineTime: 'start',
      className: styles.deadlineColumn,
      children: [
        {
          title: 'Начало',
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          deadlineTime: 'start',
          xtype: CustomTableColumnType.Deadline,
        },
        {
          title: 'Конец',
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          deadlineTime: 'end',
          xtype: CustomTableColumnType.Deadline,
        },
      ],
    },
    {
      title: 'Бюджет',
      align: 'center',
      dataIndex: 'budget',
      key: 'budget',
      className: styles.budgetColumn,
      width: 295,
      xtype: CustomTableColumnType.Budget,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: 'Действия',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: !isArchived
        ? [CustomTableAction.Watch, CustomTableAction.ArchiveWithoutText]
        : [CustomTableAction.Watch, CustomTableAction.UnArchiveWithoutText],
      actionsAccess: [
        ACCESS.WORK_MANAGEMENT_CONSTRUCTION_CARD_EDIT,
        ACCESS.WORK_MANAGEMENT_CONSTRUCTION_ARCHIVE,
      ],
    },
  ];

  return { columns };
};
