import { ACCESS, Permission } from '@enums/permisson';
import { AuthenticationUser } from '@models/authentication/interfaces/user';
import { createSelector } from '@reduxjs/toolkit';

type AuthenticationState = {
  user: AuthenticationUser | null;
  authFailed: boolean;
  authError: string;
  isLoading: boolean;
  permissions: ACCESS[];
  isDisabledRegion: boolean;
  isDisabledDistrict: boolean;
};

type WithAuthenticationState = {
  authentication: AuthenticationState;
};

export const authentionStateSelector = (
  state: WithAuthenticationState,
): AuthenticationState => state.authentication;

export const getCurrentUserSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.user;
  },
);

export const getAuthLoadingSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.isLoading;
  },
);

export const getAuthErrorSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.authError;
  },
);

export const getAuthFailedSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.authFailed;
  },
);

export const getCurrentUserPermissionsSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.permissions;
  },
);

export const getIsDisabledRegionSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.isDisabledRegion;
  },
);

export const getIsDisabledDistrictSelector = createSelector(
  authentionStateSelector,
  (authenticationState) => {
    return authenticationState.isDisabledDistrict;
  },
);
