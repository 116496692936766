import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { notification, TablePaginationConfig } from 'antd';

import { Routes } from '@enums/routes';
import { TableCustom } from '@features/ui-kit';
import { useWaterUserPermissionModal } from '@features/waterRegistry';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { useWaterRegistryFilters } from '@features/waterUser/waterUserTable/useWaterRegistryFilters';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllWaterUserQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import { useAllTableContainerColumns } from './useAllTableContainerColumns';
import { IWaterUserV2 } from '@models/waterUser/interfaces/waterUser';
import { useGetCurrentUser } from '@features/authentication/useGetCurrentUser';
import { JsonRecord } from '@models/jsonRecord';
import { useArchiveConfirmModal } from '@features/archiveConfirmModal';
import { saveUrl } from '@utils/returnUrl';

interface IAllTableContainerProps {
  isArchived?: boolean;
}

export const AllTableContainer: React.FC<IAllTableContainerProps> = ({
  isArchived = false,
}) => {
  const navigate = useNavigate();

  const { filters, setWaterRegistryFilters } = useWaterRegistryFilters();
  const { columns } = useAllTableContainerColumns();
  const { setShowModal, setConfirmArchiveId } = useArchiveConfirmModal();

  const { user } = useGetCurrentUser();
  const { setUserId } = useWaterUserId();
  const saveAction = saveUrl();

  const { setShowModalAssociation, setShowModalPhysical } =
    useWaterUserPermissionModal();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading,
    isFetching,
  } = useGetAllWaterUserQuery(
    isEmpty(filters)
      ? skipToken
      : {
          ...filters,
          userType:
            filters.userType === WaterUserEnum.INDIVIDUAL ||
            filters.userType === WaterUserEnum.LEGAL
              ? filters.userType
              : [WaterUserEnum.INDIVIDUAL, WaterUserEnum.LEGAL],
        },
  );

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterRegistryFilters({
          ...(filters as IWaterRegistryFilters),
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const onHandleEdit = useCallback((record: IWaterUserV2) => {
    navigate(generatePath(Routes.WaterUserCard, { id: String(record.id) }));
  }, []);

  const onArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };
  const onUnArchiveClick = (record: JsonRecord) => {
    setConfirmArchiveId(record.id);
    setShowModal(true);
  };

  const showGis = useCallback((record: IWaterUserV2) => {
    const url = generatePath(Routes.geoPortalMap, {
      filterKey: `map:water-user:${String(record.id)}`,
    });
    window.open(url, '_blank');
  }, []);

  const showAssociationStats = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.AssociationIndicatorReports, {
        associationId: String(record.id),
      }),
    );
  }, []);

  const showWaterUserCard = useCallback((record: IWaterUserV2) => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record.id),
      }),
    );
    saveAction();
  }, []);

  const showPermissions = useCallback((record: IWaterUserV2) => {
    setUserId(String(record.id));
    switch (record.type) {
      case WaterUserEnum.INDIVIDUAL:
        setShowModalPhysical(true);
        break;
      case WaterUserEnum.LEGAL:
        setShowModalAssociation(true);
        break;
      case WaterUserEnum.ASSOCIATION:
        setShowModalAssociation(true);
        break;
      default:
        notification.error({ message: 'Неизвестный тип пользователя' });
    }
  }, []);

  const showAnnualApplication = useCallback((record: IWaterUserV2) => {
    const urlParams = new URLSearchParams({
      page: 1,
      size: 10,
      regionId: record?.region || user?.owner?.region?.id || null,
      districtId: record?.district || user?.owner?.district?.id || null,
      organizationName: record?.legalData?.name,
    } as any);

    navigate({
      pathname: Routes.AnnualApplicationTable,
      search: urlParams.toString(),
    });
    saveAction();
  }, []);

  return (
    <>
      <TableCustom
        bordered
        loading={isLoading || isFetching}
        dataSource={content}
        columns={columns}
        size="small"
        onChange={onTableChange}
        totalCount={totalElements}
        customPagination={rest}
        rowKey={(item: any) => item.id}
        actionProps={{
          onHandleEdit,
          onArchiveClick,
          showAssociationStats,
          showGis,
          showWaterUserCard,
          showAnnualApplication,
          showPermissions,
          onUnArchiveClick,
        }}
      />
    </>
  );
};
