import { IWaterUserV2 } from '@models/waterUser/interfaces/waterUser';
import { Dayjs } from 'dayjs';
import { IFacility } from '@models/facility/interfaces/facility';
import { ISystemObject } from '@models/waterObject/interfaces/waterObject';

export interface IQuarter {
  quarterName: string;
  waterSupplyVolume: number;
  waterTariffForOneCubicMeter: number;
  waterSupplyCost: number;
  hectaresIrrigation: number;
  waterSupplyVolumeCubicMeters: number;
}

interface IrrigationStandard {
  id: number;
  startDay: string;
  startMonth: string;
  startDate: string;
  endDate: string;
  endDay: string;
  endMonth: string;
  irrigationStandard: number;
  ordinateIrrigationStandard: number;
  coverField: number;
  agroclimaticZoneId: number;
  foodPlant: {
    id: number;
    name: string;
    code: string;
  };
  irrigationType: {
    id: number;
    title: string;
    code: string;
    parentId: number | null;
    books: any; // Типизация для books не предоставлена
  };
  hydromoduleOrdinate: number;
  hectaresIrrigation: number;
}

export interface IPlantingDataCalculatedDto {
  facilityId: number;
  foodPlantId: number;
  totalArea: string;
  ktp: number;
  kvh: number;
  area: number;
  facilitiesId: number;
  outletId: number;
  outletName: string;
  systemId: number;
  systemName: string;
  channelId: number;
  channelName: string;
  okmotId: number;
  okmotName: string;
  agroClimatiqueZoneId: number;
  agroClimatiqueZoneName: string;
  kpd: number;
  regionId: number;
  regionName: string;
  districtId: number;
  districtName: string;
  name: string;
  id: number;
  code: string;
  type: Type;
  irrigationStandards: IrrigationStandard[];
}

interface Type {
  id: number;
  name: string;
  code: string;
}

export interface IDecadeDto {
  decade: number;
  waterSupplyVolumeLitres: number;
  hectaresIrrigation: number | null;
  waterConsumptionVolume: number | null;
  waterSupplyVolume: number | null;
  waterSupplyVolumeIncrement: number;
  waterConsumptionVolumeIncrement: number;
}
export interface IMonthDto {
  month: string;
  decadeDtos: IDecadeDto[];
}

export enum AnnualPlantingPlanTypeEnum {
  PLANNED = 'planned',
  ACTUAL = 'actual'
}

export interface IPlantingDataDto {
  id?: number;
  facility: IFacility;
  outlet: ISystemObject;
  facilityId: number;
  facilitiesId: number[];
  type: AnnualPlantingPlanTypeEnum;
  outletId: number;
  foodPlantId: number;
  totalArea: number;
  ktp: number;
  kvh: number;
}

export interface ICalculationsByPlantingData {
  waterUserName: string;
  waterSupplyAndIrrigationPlan: IMonthDto[];
  costEstimateAndFinancialPlanCalculation: IPlantingDataCalculatedDto[];
  facilityId: number;
  foodPlantId: number;
  totalArea: string;
  ktp: number;
  kvh: number;
  area: number;
  facilitiesId: number;
  outletId: number;
  outletName: string;
  systemId: number;
  systemName: string;
  channelId: number;
  hydroFacilityName: string;
  channelName: string;
  okmotId: number;
  okmotName: string;
  agroClimatiqueZoneId: number;
  agroClimatiqueZoneName: string;
  kpd: number;
  regionId: number;
  regionName: string;
  districtId: number;
  districtName: string;
  name: string;
}

export interface IAnnualApplication {
  id: number;
  status: string;
  createdAt: string;
  attachments: any[];
  ruvh: IWaterUserV2;
  owner: IWaterUserV2;
  waterUser: IWaterUserV2;
  rawAnnualPlantingPlans: IPlantingDataDto[];
  annualPlantingPlan: IPlantingDataDto[];
}

export interface IAnnualApplicationCalculations {
  calculationsByPlantingData: Array<ICalculationsByPlantingData>;
  estimateAndFinancialPlanTotal: IEstimateAndFinancialPlanTotal;
}

export interface IEstimateAndFinancialPlanTotal {
  firstAndFourthQuarter: IQuarter;
  secondAndThirdQuarter: IQuarter;
}
