import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { isEmpty, isInteger, pickBy } from '@utils/utils';
import {getVodokhozWaterUserFilterSelector} from "@store/selectors/vodokhozReport";
import {changeWaterUserFilters} from "@store/slices/vodokhozReport";

interface IVodokhozWaterUserFilter {
  year: number,
  search?: string,
  page?: number,
  size?: number,
}

type ReturnType = {
  filters: IVodokhozWaterUserFilter;
  setWaterUserFilters: (filters: IVodokhozWaterUserFilter) => void;
};

export const useWaterUserFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getVodokhozWaterUserFilterSelector);

  const setWaterUserFilters = useCallback(
    (newFilters: IVodokhozWaterUserFilter) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        changeWaterUserFilters({
          ...values,
        } as IVodokhozWaterUserFilter),
      );
    },
    [],
  );

  return { filters, setWaterUserFilters };
};
