import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Layout } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';

import { DictionaryZoneTableContainer } from './dictionaryZoneTableContainer';
import { DictionaryZoneTableFilters } from './dictionaryZoneTableFilters';
import { saveUrl } from '@utils/returnUrl';

export const DictionaryZoneTable = () => {
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeaderTitle();

  const saveAction = saveUrl();

  const onCreateZone = () => {
    navigate(generatePath(Routes.WaterQualityCard, { id: 'new' }));
    saveAction();
  };

  const hasPermission = useHasUserPermission(ACCESS.WATER_QUALITY_ZONE_CREATE);

  useEffect(() => {
    setHeaderTitle('Качественные показатели воды');
  }, []);

  return (
    <Layout>
      <DictionaryZoneTableFilters />
      <Divider />
      <InformationalBlock>
        <CustomCard
          title="Справочник зон"
          extra={
            hasPermission ? (
              <PrimaryButton onClick={onCreateZone}>Создать зону</PrimaryButton>
            ) : null
          }
        >
          <DictionaryZoneTableContainer />
        </CustomCard>
      </InformationalBlock>
    </Layout>
  );
};
