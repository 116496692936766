import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '@store/gisproApi';

import styles from './passportData.module.scss';

export const PassportData = () => {
  const { id } = useParams();

  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );
  return (
    <Form layout="vertical" className={styles.container}>
      <CustomCard title="Данные водного объекта">
        <Row justify="space-between">
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Объект:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.systemObject?.name}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Система:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.system?.name}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Район:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.district?.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Область:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.region?.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Задача:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock?.taskDescription}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Категория работ:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.category?.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Тип работ:">
              <Typography.Text strong>
                {constructionBlock && constructionBlock.workType?.title}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Row>
      </CustomCard>
    </Form>
  );
};
