import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { DocumentTypes } from '@enums/documentTypes';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  DangerButton,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  CreateLegalWaterRegistryQueryTypes,
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import { getFormData } from '@store/selectors';
import {
  clearEconomiesAction,
  clearFormDataAction,
  setFormData,
} from '@store/slices';
import dayjs from 'dayjs';

import {
  ExtendedBankDetails,
  LegalContactDetails,
  LegalMainInfo,
} from '../fields';
import styles from './styles.module.scss';

type LegalFirstStepFormTypes = {
  organizationName: string;
  organizationFormId: number;
  ownershipFormId: string;
  activityType: string;
  economicActivityType: string;
  registerDate: string | Date;
  organizationInn: string;
  regNumber: string;
  okpo: string;
  organizationAddress: string;
  regulation: string;
  phone: string;
  fax: string;
  email: string;
  bankId: number;
  bankAddress: string;
  branchName: string;
  bankBik: string;
  correspondentAccount: string;
  organizationAccount: string;
};

export const LegalMasterRegistrationFirstStep: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWaterRegistry = useSelector(getFormData);

  const documents: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.legal].attachmentResponseDtos || [],
    [currentWaterRegistry],
  );

  const attachments = documents?.filter(
    (att) => att.type === DocumentTypes.Regulation,
  );

  const validateAttachments = useMemo(() => {
    return attachments?.some((att) => att.type === DocumentTypes.Regulation);
  }, [attachments]);

  const onFinish = (values: LegalFirstStepFormTypes) => {
    dispatch(
      setFormData({
        data: {
          ...values,
          registerDate: dayjs(values.registerDate).format('YYYY-MM-DD'),
        },
        waterRegistryUserType: WaterUserTypes.legal,
      }),
    );
    navigate(
      `${Routes.WaterRegistryMasterRegistrationLegal}?${SectionQueryKey}=${CreateLegalWaterRegistryQueryTypes.responsiblePersons}`,
    );
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.legal,
      }),
    );
    dispatch(clearEconomiesAction());
    navigate(Routes.WaterRegistryUsers);
  };

  useEffect(() => {
    if (Object.values(currentWaterRegistry[WaterUserTypes.legal]).length > 1) {
      form.setFieldsValue(currentWaterRegistry[WaterUserTypes.legal]);
      form.setFieldValue(
        'registerDate',
        dayjs(currentWaterRegistry[WaterUserTypes.legal].registerDate),
      );
    }
  }, [currentWaterRegistry]);

  return (
    <Form<LegalFirstStepFormTypes>
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row justify="space-between" gutter={[0, 16]}>
        <Col>
          <InformationalBlock title="Данные водопользователя">
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <LegalMainInfo />
              </Col>
              <Col span={24}>
                <LegalContactDetails />
              </Col>
              <Col span={24}>
                <ExtendedBankDetails />
              </Col>
            </Row>
          </InformationalBlock>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Form.Item>
                <DangerButton size="large" onClick={onCancelButtonClick}>
                  {t('createIndividualWaterRegistry.buttons.cancel')}
                </DangerButton>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Row justify="end">
                <Space size="large">
                  <Form.Item>
                    <PrimaryButton
                      type="primary"
                      htmlType="submit"
                      disabled={!validateAttachments}
                      size="large"
                    >
                      {t('createLegalWaterRegistry.buttons.next')}
                    </PrimaryButton>
                  </Form.Item>
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
