import { FC, useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'antd';
import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useFindAllObjectCategoriesByObjectGroupIdQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { SearchOutlined } from '@ant-design/icons';
import { IWaterObjectFilters } from '@models/waterObject/interfaces/waterObject';
import { extractObjectTypes } from '@utils/objectType';
import { ObjectGroupCodesEnum } from '@enums/objectGroupCodes';
import { useSearchParams } from 'react-router-dom';
import { IrrigationSystemTableQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

export const IrrigationReservoirTableFilters: FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const [filtersState, setFiltersState] = useState<
    Partial<IWaterObjectFilters>
  >({});

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery({
      objectGroupId: ObjectGroupCodesEnum.Reservoir,
    });

  const onFilterChange = (filterKey: keyof IWaterObjectFilters, v: string) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  useEffect(() => {
    setFiltersState(filtersParams as any);
  }, [filtersParams]);

  const onFilterButtonClick = () => {
    setSearchParams(
      pickBy(
        {
          ...filtersParams,
          ...filtersState,
        },
        (v) => !isEmpty(v) || isInteger(v),
      ),
    );
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      code: null,
      groupId: null,
      name: null,
      typeObjectId: null,
      categoryId: null,
      page: 1,
      size: 10,
    });

    setSearchParams({
      section: IrrigationSystemTableQueryTypes.reservoirs,
      page: '1',
      size: '10',
    });
  };

  const onCategoryChange = (v: number) => {
    setFiltersState((state) => ({
      ...state,
      categoryId: v,
      typeObjectId: null,
      page: 1,
    }));
  };

  console.log(filtersState.categoryId);

  const objectTypes = useMemo(
    () =>
      filtersState.categoryId
        ? extractObjectTypes(objectCategories, +filtersState.categoryId)
        : [],
    [objectCategories, filtersState],
  );

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Row gutter={16}>
      <Col span={4}>
        <TooltipedSearch
          value={filtersState.code || ''}
          onChange={({ target }) => onFilterChange('code', target.value)}
          placeholder="Код водного объекта"
        // className={styles.tooltiped}
        />
      </Col>
      <Col span={4}>
        <TooltipedSearch
          value={filtersState.name || ''}
          onChange={({ target }) => onFilterChange('name', target.value)}
          placeholder="Наименование объекта"
        // className={styles.tooltiped}
        />
      </Col>
      {/* <Col span={4}>
        <TooltipedSelect
          placeholder="Группа"
          options={objectGroups}
          loading={objectGroupsIsLoading}
          value={
            objectGroups.find(
              (item) => item.id === toInteger(filtersState.groupId),
            )?.label
          }
          onChange={(v: number) => onGroupChange(v)}
          allowClear
        />
      </Col> */}
      <Col span={4}>
        <TooltipedSelect
          placeholder="Категория"
          showSearch
          options={objectCategories}
          loading={objectCategoriesIsLoading}
          filterOption={filterOption}
          value={
            objectCategories.find(
              (item) => item.categoryId === toInteger(filtersState.categoryId),
            )?.label
          }
          onChange={(v: number) => onCategoryChange(v)}
          allowClear
        />
      </Col>
      <Col span={4}>
        <TooltipedSelect
          placeholder="Тип"
          disabled={!filtersState.categoryId}
          options={objectTypes}
          value={filtersState.typeObjectId}
          onChange={(v: string) => onFilterChange('typeObjectId', v)}
          allowClear
        />
      </Col>
      <Col span={2}>
        <Row gutter={8} wrap={false}>
          <Col>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
          </Col>
          <Col>
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
