import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Form, Row, Space, notification } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import {
  DefaultButtonV2,
  PrimaryButtonV2,
  UploadModalV2,
} from '@features/ui-kit-v2';
import { useOrganizationId } from '@features/waterRegistry/masterRegistration/useOrganizationId';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  CreateLegalWaterRegistryQueryTypes,
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';
import {
  saveLegalPersonApiArg,
  useSaveLegalsWaterUserMutation,
  useWaterUserCreateMutation,
} from '@store/gisproApi';
import { getFormData } from '@store/selectors';
import {
  addAttachmentToWaterRegistryUserAction,
  clearEmployeesAction,
  clearFormDataAction,
} from '@store/slices';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { ContractDetails, DocumentsBlock } from '../fields';
import { useActionSelect } from '../useActionSelect';
import styles from './styles.module.scss';
import { DocumentTypes } from '@enums/documentTypes';

export const LegalMasterRegistrationFourthStep: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState<Array<IAttachment>>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentWaterRegistry = useSelector(getFormData);
  const [save] = useWaterUserCreateMutation();
  const { setShowModal: setShowActionSelectModal } = useActionSelect();
  const { setUserId, waterUserId } = useWaterUserId();
  const { setOrgId } = useOrganizationId();

  const onFinish = () => {
    const data = currentWaterRegistry[WaterUserTypes.legal];
    const {
      employees,
      attachmentResponseDtos,
      facilities,
      initialEmployees,
      ...otherProps
    } = data;

    save({
      region: data.facilities[0].region,
      district: data.facilities[0].district,
      legalData: otherProps,
      employees,
      facilities,
      type: WaterUserEnum.LEGAL,
      attachmentsList: attachmentResponseDtos,
    } as saveLegalPersonApiArg)
      .unwrap()
      .then((res: any) => {
        if (res.error) {
          notification.error({
            message: 'Не удалось создать водопользователя',
          });
          return;
        } else {
          setShowActionSelectModal(true);
          dispatch(
            clearFormDataAction({
              waterRegistryUserType: WaterUserTypes.legal,
            }),
          );
          dispatch(clearEmployeesAction());
          setUserId(res?.id || res?.data?.id);
          setOrgId(res?.owner?.id || res?.data?.owner?.id);
          dispatch(
            clearFormDataAction({
              waterRegistryUserType: WaterUserTypes.physical,
            }),
          );
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
        // dispatch(
        //   clearFormDataAction({
        //     waterRegistryUserType: WaterUserTypes.physical,
        //   }),
        // );
        // dispatch(clearEmployeesAction());
      });
  };

  const onFinishFailed = (err: ValidateErrorEntity) => {
    console.log('onFinishFailed: ', err);
  };

  const onCancelButtonClick = () => {
    dispatch(
      clearFormDataAction({
        waterRegistryUserType: WaterUserTypes.legal,
      }),
    );
    navigate(Routes.WaterRegistryUsers);
  };
  const onBackButtonClick = () => {
    navigate(
      `${Routes.WaterRegistryMasterRegistrationLegal}?${SectionQueryKey}=${CreateLegalWaterRegistryQueryTypes.create}`,
    );
  };

  const onAttachmentUpload = useCallback(
    (file: IAttachment) => {
      setDocuments([...documents, file]);
      dispatch(
        addAttachmentToWaterRegistryUserAction({
          attachment: file,
          waterRegistryUserType: WaterUserTypes.legal,
        }),
      );
    },
    [documents],
  );

  const newDocuments: Array<IAttachment> = useMemo(
    () =>
      currentWaterRegistry[WaterUserTypes.legal].attachmentResponseDtos ||
      [],
    [currentWaterRegistry],
  );

  const attachments = newDocuments?.filter(
    (att) => att.type === DocumentTypes.Agreement,
  );

  const validateAttachments = useMemo(() => {
    return attachments?.some((att) => att.type === DocumentTypes.Agreement);
  }, [attachments]);

  return (
    <Form
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <Row gutter={[30, 30]}>
        <Col span={16}>
          <ContractDetails />
        </Col>
        <Col span={8}>
          <DocumentsBlock userType={WaterUserTypes.legal} />
        </Col>
        <Form.Item name="test" />
        <UploadModalV2
          onLoad={onAttachmentUpload}
          setShow={setShow}
          show={show}
        />
      </Row>

      <Row justify="space-between" className={styles.footer}>
        <Col span={4}>
          <Form.Item>
            <DefaultButtonV2
              type="default"
              htmlType="submit"
              onClick={onCancelButtonClick}
              title={t('createIndividualWaterRegistry.buttons.cancel')}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Row justify="end">
            <Space size="large">
              <Form.Item>
                <DefaultButtonV2
                  type="default"
                  htmlType="submit"
                  onClick={onBackButtonClick}
                  title={t('createIndividualWaterRegistry.buttons.back')}
                />
              </Form.Item>
              <Form.Item>
                <PrimaryButtonV2
                  type="primary"
                  htmlType="submit"
                  disabled={!validateAttachments}
                  title={t('createLegalWaterRegistry.buttons.next')}
                />
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
