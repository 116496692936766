import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBankDetailsModalSelector } from '@store/selectors';
import { setBankDetailsModal } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
};

export function useBankDetailsModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getBankDetailsModalSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setBankDetailsModal(showModal));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
}
