/* eslint-disable no-underscore-dangle */
import { translateRegions } from '@constants/regions';
import L, { control, Layer } from 'leaflet';

import regionData from '../../data/geoBoundaries-KGZ-ADM1_simplified.json';
import districtData from '../../data/geoBoundaries-KGZ-ADM2_simplified.json';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-polylinedecorator';
import 'leaflet-polylineoffset';
import { getMapMarker } from '@utils/map/getMapMarker';
import { getStyleLine } from '@utils/map/Line/getStyleLine';
import { getStylePolygon } from '@utils/map/Polygon/getStylePolygon';
import 'leaflet.pattern';
import { ArgLayer, ArgLayerPolygon } from '@utils/map/Line/types';
import layers = control.layers;

interface EventFunction {
  (...args: any[]): void;
}

interface CustomLayerType extends Layer {
  customId: string;
  layerName: string;
}

export interface ZoneIndicatorType {
  clearGl: string;
  decade: number;
  filterDate: null;
  ftu: string;
  gl: string;
  headHeight: string;
  id: number;
  month: number;
  ms: string;
  ph: string;
  point: { id: null };
  quarter: string;
  reclamationCondition: string;
  toTheBottom: string;
  toTheWater: string;
  type: string;
  year: number;
  zone: { id: number };
}

export enum MapEvents {
  init = 'map.init',
  districtClick = 'district.click',
  districtHover = 'district.hover',
  districtUnhover = 'district.unhover',
  objectClick = 'object.click',
  objectHover = 'object.hover',
  objectUnhover = 'object.unhover',
}
export enum LayerType {
  feature = 'Feature',
  featureCollection = 'FeatureCollection',
}

export enum ReclamationStatesEnum {
  GOOD = 'Хорошее мелиоративное состояние',
  SATISFACTORY = 'Удовлетворительное состояние',
  UNACCEPTABLE_DEPTH = 'Недопустимая глубина грунтовых вод',
  SOIL_SALINIZATION = 'Засоление почв',
  UD_SS = 'Недопустимая глубина грунтовых вод и засоление',
}

/* eslint-disable @typescript-eslint/no-shadow */
class MapService {
  private map: L.Map | undefined;

  private mapRef: HTMLElement | null = null;

  private pane: any;

  private layerCountries: any;

  private currentEdit = '';

  private newLayer: Layer | null = null;

  // private currentFeaturePosition: any = null;

  private layers: Record<string, any> = {};

  private layersData: Record<string, any> = {};

  private center: [number, number] = [41.2044, 74.7661]; // KG

  private listeners: Record<string, EventFunction[]> = {};

  private popups: any[] = [];

  private lines: CustomLayerType[] = [];

  private defaultStyle = {
    main: {
      fillColor: '#2f29c6',
      weight: 1,
      opacity: 1,
      zIndex: 500,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.04,
      className: 'layer',
    },
    map: {
      // fillColor: '#2f29c6',
      fillColor: 'transparent',
      weight: 1,
      opacity: 1,
      zIndex: 500,
      color: 'black',
      dashArray: '3',
      // fillOpacity: 0.04,
      className: 'layer',
    },
  };

  private highlightStyle = {
    main: {
      weight: 3,
      zIndex: 1000,
      color: 'white',
      dashArray: '',
      fillOpacity: 0.1,
      className: 'layer',
    },
    map: {
      weight: 3,
      zIndex: 1000,
      color: 'black',
      dashArray: '',
      // fillOpacity: 0.1,
      className: 'layer',
    },
  };

  private mode: 'edit' | 'view' = 'view';

  private targetZoom = 15;

  getLeaflet(): any {
    return L;
  }
  getMap(): any {
    return this.map;
  }

  init(
    mapRef: HTMLElement,
    center: [number, number] | null = null,
    tile: false | string = 'openstreetmap',
    labels = false,
    scrollWheelZoom = true,
  ) {
    if (!mapRef) {
      return;
    }
    this.center = center || this.center;
    this.mapRef = mapRef;
    if (!this.map) {
      this.map = L.map(this.mapRef, {
        attributionControl: false,
        minZoom: 7,
        scrollWheelZoom,
      }).setView(this.center, 7);
      this.tileLayer(tile);
      this.districtLayer(labels ? regionData : districtData, labels);
      this.emit(MapEvents.init);
      this.map?.pm.disableGlobalEditMode();
      // Событие изменения уровня зума
      this.map.on('zoomend', () => {
        this.zoom();
      });
      // this.addLayer(debugJson);
    }
  }

  zoom() {
    if (!this.map) return;
    const currentZoom = this.map.getZoom();
    // Отображение popup, если текущий уровень зума соответствует нужному
    if (currentZoom >= this.targetZoom) {
      for (const popup of this.popups) {
        popup.openTooltip();
      }
    } else {
      for (const popup of this.popups) {
        popup.closeTooltip();
      }
    }
  }

  subscribe(eventType: MapEvents, listener: EventFunction) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }
    this.listeners[eventType].push(listener);
  }

  unsubscribe(eventType: MapEvents, listener: EventFunction) {
    if (!this.listeners[eventType]) {
      return;
    }
    this.listeners[eventType] = this.listeners[eventType].filter((l) => {
      return l !== listener;
    });
  }

  emit(eventType: string, ...args: any[]) {
    if (!this.listeners[eventType]) {
      return;
    }
    this.listeners[eventType].forEach((listener) => listener(...args));
  }

  addWMSLayer(layer: any) {
    if (!this.map) return;
    const params = new URLSearchParams(layer);
    const options: any = {
      layers: ' ',
      transparent: true,
      format: 'image/png',
      version: '1.3.0',
    };
    params.forEach((value, key) => {
      const name = key.toLowerCase();
      if (options[name]) {
        options[name] = value;
      }
    });
    const geoNodeLayer = L.tileLayer.wms(layer, options);
    geoNodeLayer.addTo(this.map);
    return geoNodeLayer;
  }

  removeWMSLayer(layer: any) {
    if (!this.map) return;
    this.map.removeLayer(layer);
  }

  addLayer(data: any, save = true): string | undefined | string[] {
    if (this.map) {
      let id: string | string[] | undefined;
      if (data.type === LayerType.featureCollection) {
        const ids: string[] = [];
        data.features.forEach((feature: any) => {
          const layerId = this.addLayer(feature, save);
          if (layerId) {
            if (Array.isArray(layerId)) {
              ids.push(...layerId);
            } else {
              ids.push(layerId);
            }
          }
        });
        id = ids;
      } else if (data.type === LayerType.feature) {
        // пустой объект
        if (data.geometry.coordinates) {
          const name = data?.geometry?.layerName;

          const statusColor: Record<string, string> = {
            '1': 'red',
            '2': 'rgb(255, 123, 0)',
            '3': 'rgb(255, 208, 0)',
            '4': 'rgb(60, 255, 0)',
            '5': 'rgb(10, 167, 23)',
          };
          const status = data?.properties?.technicalStatus?.title
            ?.toString()
            .split(' ')[0];
          const markerIcon = getMapMarker(name);
          if (!this.layers[data.id]) {
            const pane = L.geoJSON(data, {
              style: {
                color: status ? statusColor[status] : 'blue',
                opacity: 1,
                weight: 1,
              },
              pointToLayer: (feature, latlng) => {
                return L.marker(latlng, {
                  icon: L.icon({
                    iconUrl: markerIcon,
                    iconSize: [30, 30],
                    popupAnchor: [14, 0],
                    iconAnchor: [15, 15],
                  }),
                });
              },
              pane: 'overlayPane',
              onEachFeature: (feature: any, layer: any) => {
                if (!feature.id) {
                  return null;
                }
                const popupContent = `
              <div>
                  <div class="popupHeader">${
                    feature.properties?.name || ''
                  }</div>
                  <div class="popupContent">
                  <p class="popupText">${
                    feature.properties?.popupText ?? '-'
                  }</p>
                  </div>
              </div>
          `;
                const clickLayer = () => {
                  layer.on('click', (e: { target: any }) => {
                    const layer = e.target;
                    console.log('target', layer);
                    const feature = layer?.feature;
                    this.emit(MapEvents.objectClick, feature);
                  });
                };

                const mouseoverLayer = () => {
                  layer.on('mouseover', (e: any) => {
                    const layer = e.target;

                    const feature = layer?.feature;
                    tooltip.openTooltip(e.latlng);
                    this.emit(MapEvents.objectHover, feature);
                  });
                };

                const mouseoutLayer = () => {
                  layer.on('mouseout', (e: { target: any }) => {
                    const layer = e.target;

                    const feature = layer?.feature;
                    if (this.map && this.map.getZoom() < this.targetZoom) {
                      tooltip.closeTooltip();
                    }
                    this.emit(MapEvents.objectUnhover, feature);
                  });
                };

                const tooltip = layer.bindTooltip(popupContent, {
                  permanent: true,
                  direction: 'top',
                });

                this.popups.push(tooltip);
                // layer.bindPopup(feature.properties.name_wo);
                clickLayer();
                mouseoverLayer();
                mouseoutLayer();

                if (feature?.geometry?.type === 'LineString') {
                  const argLine: ArgLayer = {
                    name,
                    L,
                    layer,
                    feature,
                  };

                  const customLayer: CustomLayerType = getStyleLine(argLine);

                  customLayer.addTo(this.map as any);

                  customLayer.customId = layer?.feature?.id;
                  // this.lines.push(customLayer);
                }

                if (feature?.geometry?.type === 'Polygon') {
                  const argPolygon: ArgLayerPolygon = {
                    name,
                    L,
                    layer,
                    map: this.map,
                    indicator: data?.properties?.indicator,
                  };

                  const customLayer = getStylePolygon(argPolygon);

                  customLayer.addTo(this.map);

                  customLayer.customId = layer?.feature?.id;

                  // this.lines.push(customLayer);
                }

                //для событий
                //  click
                // mouseover
                // mouseout
                // const markerIcons = document.querySelectorAll(
                //   'img.leaflet-marker-icon',
                // );

                // // Проходимся по каждому элементу
                // markerIcons.forEach(function (icon) {
                //   // Проверяем, соответствует ли атрибут src заданному значению
                //   if (icon.getAttribute('src') === '/icons/test.svg') {
                //     // Добавляем свойство z-index: 399
                //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //     //@ts-expect-error
                //     icon.style.zIndex = '399';
                //   }
                // });
              },
            });
            this.pane.addLayer(pane);
            this.zoom();
            this.layers[data.id] = pane;
          }
        }
        if (save) {
          this.layersData[data.id] = JSON.parse(JSON.stringify(data));
        }
        id = data.id;
      }
      return id;
    }
    return undefined;
  }

  hideLayer(id: string) {
    if (this.map && this.layers[id]) {
      this.map.removeLayer(this.layers[id]);
    }
  }

  showLayer(id: string) {
    if (this.map && this.layers[id]) {
      this.layers[id].addTo(this.map);
    }
  }

  removeLayer(id: string | string[]) {
    if (Array.isArray(id)) {
      id.forEach((i) => {
        this.removeLayer(i);
      });
    } else if (this.map && this.layers[id]) {
      this.map.removeLayer(this.layers[id]);

      this.map.eachLayer((layer: any) => {
        if (layer?.customId === id) {
          this.map?.removeLayer(layer);
        }
      });

      // const customLayer = this.lines.find((item) => item.customId === id);
      //
      // if (customLayer) {
      //   this.map.removeLayer(customLayer as Layer);
      //   this.lines = this.lines.filter((item) => item.customId !== id);
      //
      //   this.map.eachLayer((layer: any) => {
      //     if (layer?.customId === id) {
      //       this.map?.removeLayer(layer);
      //     }
      //   });
      // }

      delete this.layers[id];
    }
  }

  removeAllLayers() {
    console.log('toggle', layers());
    if (this.map) {
      Object.keys(this.layers).forEach((id) => {
        this.removeLayer(id);
      });
    }
  }

  editFeature(id: string, layerType = 'Polygon') {
    const layer = this.layers[id];
    console.log('this.layers', this.layers);
    if (this.currentEdit) {
      this.cancelEditFeature(this.currentEdit);
    }
    if (layer) {
      // this.currentFeaturePosition = layer.toGeoJSON();
      this.currentEdit = id;

      this.map?.fitBounds(layer.getBounds());
      layer.pm.enable({
        allowSelfIntersection: false,
      });
    } else {
      this.resetFeature(id, layerType);
    }
  }

  cancelEditFeature(id: string) {
    if (!this.map) return;
    if (this.newLayer) {
      this.map.removeLayer(this.newLayer);
    }
    this.map.pm.disableDraw();
    console.log('cancelEditFeature', id);

    if (!this.map) return;
    if (this.currentEdit === id) {
      this.currentEdit = '';
    }
    const layer = this.layers[id];
    console.log('layerrrr', layer);
    if (layer) {
      this.map.removeLayer(layer);
      this.layers[id] = null;
    }
    if (this?.layersData[id]?.geometry?.coordinates) {
      console.log('aaaaa', this.layersData[id]);
      this.addLayer(this.layersData[id], false);
    }
  }

  getViewFeature(id: string) {
    const layer = this.layers[id];

    console.log(layer);

    if (layer) {
      this.map?.fitBounds(layer.getBounds(), {
        maxZoom: 11,
      });
    }
  }

  getViewCustomFeature(coordinate: any[], code: string, type: string) {
    const coordinateMutation: number[][] =
      type === 'Polygon'
        ? (coordinate[0] as number[][])
        : (coordinate[0][0] as number[][]);

    const flattenedCoordinates: [number, number][] = coordinateMutation.map(
      (coordinate: number[]) => [coordinate[1], coordinate[0]],
    );

    const wellBounds = L.latLngBounds(flattenedCoordinates as any);

    const zoom = code[1] === '1' ? 11 : 13;

    this.map?.fitBounds(wellBounds, {
      maxZoom: zoom,
    });
  }

  resetFeature(id: string, layerType = 'Polygon') {
    return new Promise((resolve) => {
      if (!this.map) return;
      if (this.newLayer) {
        this.map.removeLayer(this.newLayer);
      }
      let layer: any;
      // if (this.layers[id]) {
      //   const geojson = this.layers[id].toGeoJSON();
      //   this.currentFeaturePosition = geojson;
      // }
      if (this.layers[id]) {
        this.map.removeLayer(this.layers[id]);
      }
      this.layers[id] = null;
      const createdLayer = (e: any) => {
        if (!this.map) return;
        layer = e.layer;
        this.map.pm.disableDraw();
        this.map.off('pm:create', createdLayer);
        if (layer) {
          const data = layer.toGeoJSON();
          if (data) {
            const paneId = this.addLayer({ ...data, id }, false);
            console.log('paneId', paneId);
            // const geojson = this.layers[id].toGeoJSON();
            // this.currentFeaturePosition = geojson;
          }
          this.map.removeLayer(layer);
          resolve(id);
          return;
        }
        resolve(null);
      };
      this.map.on('pm:create', createdLayer);
      this.map.on('pm:drawstart', (e) => {
        console.log('pm:drawstart', e);
        this.newLayer = e.workingLayer;
      });
      const { type } = this.layersData[id]?.geometry || { type: layerType };
      console.log('type', type);
      const types: { [key: string]: string } = {
        Point: 'Marker',
        Polygon: 'Polygon',
        LineString: 'Line',
      };
      this.map.pm.enableDraw(types[type], {
        continueDrawing: false,
        markerStyle: {
          icon: L.icon({
            iconUrl: '/icons/markerPin.svg',
            iconSize: [20, 20],
            popupAnchor: [14, 0],
          }),
        },
        tooltips: false,
      });
    });
  }

  submitFeature(id: string): any | null {
    if (this.map) {
      const layer = this.layers[id];

      if (layer) {
        const geojson = layer.toGeoJSON();
        this.layersData[id] = geojson?.features?.[0];
        // this.currentFeaturePosition = geojson;
        layer.pm.disable();
        return this.layersData[id];
      }
    }
    return null;
  }

  private toolbarEditable() {
    if (this.map) {
      this.map.pm.addControls({
        position: 'topleft',
        rotateMode: true,
        dragMode: true,
        drawMarker: true,
        drawCircle: true,
        drawCircleMarker: true,
        drawRectangle: true,
        drawPolygon: true,
        drawPolyline: true,
        editMode: true,
        cutPolygon: true,
        removalMode: true,
        drawText: true,
      });
    }
  }

  private layerLegends(
    feature: any,
    layer: any,
    type: 'mineralization' | 'depth' | 'melioration',
  ) {
    if (!this.map || !feature.properties || !feature.properties.Показ) return;

    const pokaz = feature.properties.Показ;
    switch (type) {
      case 'mineralization':
        if (pokaz <= 1) {
          layer.setStyle({ color: 'hsl(254, 85%, 70%)' });
        } else if (pokaz <= 2) {
          layer.setStyle({ color: 'hsl(185, 88%, 80%)' });
        } else if (pokaz <= 3) {
          layer.setStyle({ color: 'hsl(71, 90%, 80%)' });
        } else if (pokaz <= 4) {
          layer.setStyle({ color: 'hsl(328, 100%, 80%)' });
        } else {
          layer.setStyle({ color: 'purple' });
        }
        break;
      case 'melioration':
        if (pokaz <= 1) {
          layer.setStyle({ color: 'hsl(110, 48%, 68%)' });
        } else if (pokaz <= 2) {
          layer.setStyle({ color: 'hsl(59, 100%, 78%)' });
        } else if (pokaz <= 3) {
          layer.setStyle({ color: 'hsl(5, 68%, 64%)' });
        } else if (pokaz <= 4) {
          layer.setStyle({ color: 'hsl(0, 0%, 80%)' });
        } else if (pokaz <= 5) {
          layer.setStyle({ color: 'hsl(0, 0%, 99%)' });
        } else {
          layer.setStyle({ color: 'black' });
        }
        break;

      case 'depth':
        if (pokaz <= 1) {
          layer.setStyle({ color: 'hsl(195, 90%, 80%)' });
        } else if (pokaz <= 2) {
          layer.setStyle({ color: 'hsl(87, 42%, 74%)' });
        } else if (pokaz <= 3) {
          layer.setStyle({ color: 'hsl(25, 64%, 70%)' });
        } else if (pokaz <= 4) {
          layer.setStyle({ color: 'hsl(59, 100%, 80%)' });
        } else if (pokaz <= 5) {
          layer.setStyle({ color: 'hsl(281, 53%, 77%)' });
        } else if (pokaz <= 6) {
          layer.setStyle({ color: 'hsl(22, 84%, 77%)' });
        } else {
          layer.setStyle({ color: 'grey' });
        }
        break;

      default:
        break;
    }
  }

  private districtLayer(data: any | any[] | undefined, labels = false) {
    if (!this.map) return;
    // let randomTime = 0;
    this.layerCountries = L.geoJSON(data, {
      onEachFeature: (feature: any, layer: any) => {
        layer.on({
          click: (e: { target: any }) => {
            const layer = e.target;
            const id = layer?.feature?.properties?.ShapeID;
            this.emit(MapEvents.districtClick, id);
          },
          mouseover: (e: { target: any }) => {
            // layer?._path?.classList.remove('layer-animated');
            // layer?._path?.classList.remove('layer-started');
            const { target } = e;
            // target.setStyle(this.highlightStyle[labels ? 'main' : 'map']);
            const id = layer?.feature?.properties?.ShapeID;
            this.emit(MapEvents.districtHover, id);
          },
          mouseout: (e: { target: any }) => {
            const layer = e.target;
            // layer.setStyle(this.defaultStyle[labels ? 'main' : 'map']);
            const id = layer?.feature?.properties?.ShapeID;
            this.emit(MapEvents.districtUnhover, id);
          },
        });
        if (labels && this.map) {
          const regionName =
            translateRegions[feature.properties.shapeName.split(' ')[0]];
          let positionForRegions: L.PointExpression;
          switch (regionName) {
            case 'Чуй':
              positionForRegions = [90, 20];
              break;
            case 'Жалал-Абад':
              positionForRegions = [4, 40];
              break;
            case 'Баткен':
              positionForRegions = [8, 5];
              break;
            case 'Иссык-Куль':
              positionForRegions = [30, 20];
              break;
            case 'Ош':
              positionForRegions = [10, 10];
              break;
            default:
              positionForRegions = [50, 20];
              break;
          }
          const label = L.divIcon({
            className: 'custom-label',
            html: `<div style="font-size: 18px; color: #000">${regionName}</div>`,
            iconAnchor: positionForRegions,
          });
          const center = layer.getBounds().getCenter();
          L.marker(center, { icon: label }).addTo(this.map);
        }
        //
        // setTimeout(() => {
        //   layer?._path?.classList.add('layer-animated');
        // }, randomTime);
        // randomTime += 100;
      },
      style: {
        ...this.defaultStyle[labels ? 'main' : 'map'],
        // className: 'layer-started',
      },
    });
    this.pane.addLayer(this.layerCountries);
  }

  private tileLayer(type: string | boolean = 'openstreetmap') {
    if (!this.map) return;
    if (type !== false) {
      const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      L.tileLayer(osmUrl, {
        attribution: '',
      }).addTo(this.map);
    }
    this.pane = L.layerGroup([], {
      pane: 'overlayPane',
    }).addTo(this.map);
  }
}
export { MapService };
