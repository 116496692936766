import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Col,
  notification,
  Row,
  TablePaginationConfig,
  Typography,
} from 'antd';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { SchedulePrintModal } from '@features/wateringPlan/wateringSchedule/modals/schedulePrintModal';
import { useSchedulePrintModal } from '@features/wateringPlan/wateringSchedule/modals/useSchedulePrintModal';
import { mapWateringScheduleTableData } from '@features/wateringPlan/wateringSchedule/wateringScheduleTable/mapWateringScheduleTableData';
import { useWateringScheduleTableColumns } from '@features/wateringPlan/wateringSchedule/wateringScheduleTable/useWateringScheduleTableColumns';
import { useWaterUsersModal } from '@features/waterUser/waterUsersModal/useWaterUsersModal';
import { PrintIcon } from '@icons/PrintIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useGetWateringPlanOperationalWateringQuery,
  useSaveOperationWateringPlanDecadalRequestMutation,
  useSendOperationalPlanRequestMutation,
} from '@store/gisproApi';
import { get, toInteger } from '@utils/utils';

import { useWateringFilters } from '../wateringScheduleControls/useWateringFilters';
import { useReactToPrint } from 'react-to-print';

export const WateringScheduleTable: React.FC = () => {
  const { t } = useTranslation();
  const printRef = useRef<any>(null);
  const { setShowModal } = useSchedulePrintModal();
  const { filters, setWaterUseWateringFilters } = useWateringFilters();
  const { setShowModal: setShowWaterUsersModal, setOutletId } =
    useWaterUsersModal();

  const [sendToRequest] = useSendOperationalPlanRequestMutation();
  const [saveOperational] =
    useSaveOperationWateringPlanDecadalRequestMutation();
  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isLoading: wateringIsLoading,
    isFetching,
  } = useGetWateringPlanOperationalWateringQuery({
    ...filters,
    page: filters.page,
    size: filters.size,
    month: filters.month,
    userType: filters.userType,
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'wateringSchedulePrint',
    onBeforeGetContent: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Ок') {
          item.style.display = 'none';
        }
      });
    },
    onAfterPrint: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Ок') {
          item.style.display = 'block';
        }
      });
    },
  });

  const { columns } = useMemo(
    () =>
      useWateringScheduleTableColumns({
        month: t(`months.${filters.month}`),
        userType: filters.userType,
      }),
    [content],
  );

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setWaterUseWateringFilters({
          ...filters,
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [rest, columns],
  );

  const { data: dataSource } = useMemo(
    () =>
      mapWateringScheduleTableData({
        data: content,
        month: filters.month,
      }),
    [content],
  );

  const getCellKey = (
    record: JsonRecord,
    column: TableColumn,
    index: number | undefined,
  ) => {
    if (column.editableCell && column.decadeIndex) {
      const decadeId = get(record, ['decadeDtos', column.decadeIndex[1], 'id']);

      return `${decadeId}-${column.dataIndex}`;
    }
    return -1;
  };

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const { decimalIndex, dataIndex } = column;
    const slicedDecadeIndex = decimalIndex?.slice(0, -1);
    const getId = get(record, [...(slicedDecadeIndex || []), 'id']);
    const getValue = get(record, [...(decimalIndex || []), dataIndex]);
    const getStatus = get(record, [...(slicedDecadeIndex || []), 'status']);
    if (getStatus === 'edit' || getStatus === 'new') {
      saveOperational([{ id: getId, value: getValue }])
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Вы успешно обновили значение',
          });
        })
        .catch(({ data }) => {
          notification.error({
            message: data?.message ?? 'Не удалось изменить значение',
          });
        });
    }
  };

  const onHeaderActionClick = (customColumn: TableColumn) => {
    const { children } = customColumn;

    if (children) {
      const decimalIndex = children[0]?.decimalIndex?.[1];

      if (decimalIndex !== undefined) {
        const numericIds = content.flatMap((obj: any) =>
          obj.decadeDtos
            .filter((item: any) => {
              return (
                typeof item.id === 'number' &&
                item.decade === toInteger(decimalIndex)
              );
            })
            .map((item: any) => item.id),
        );

        sendToRequest(numericIds);
      }
    }
  };
  const onWaterUsersClick = (record: any) => {
    setShowWaterUsersModal(true);
    setOutletId(record.outletId);
  };
  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>
              Оперативный план водополива
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton onClick={handlePrint} icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      <Col span={24} ref={printRef}>
        <TableCustom
          scroll={{ x: 1776 }}
          bordered
          size="small"
          columns={columns}
          dataSource={dataSource}
          cellKey={getCellKey}
          actionProps={{
            onEditDecimalSaveClick,
            onHeaderActionClick,
            onWaterUsersClick,
          }}
          onChange={onTableChange}
          customPagination={rest}
        />
      </Col>
      <SchedulePrintModal />
    </CustomCard>
  );
};
