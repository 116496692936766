import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Col, Row } from 'antd';

import { ConclusionContractsTableContainer } from '@features/annualApplication/conclusionСontractsTable/conclusionСontractsTableContainer';
import { ConclusionContractsTableFilters } from '@features/annualApplication/conclusionСontractsTable/conclusionСontractsTableFilters';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { SectionTitle } from '@features/ui-kit-v2';

import styles from './ConclusionContractsTable.module.scss';
import { Divider } from '@features/ui-kit';
import { HomeOutlined } from '@ant-design/icons';

export const ConclusionContractsTable = () => {
  const { t } = useTranslation();

  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle('Заключение договоров');
  }, []);

  return (
    <div className={styles.wrapper}>
      {/* <Row gutter={12} justify="space-between">
        <Col span={24}>
          <SectionTitle
            background="cyan"
            title={t('annualApplication.table.conclusionContracts')}
          />
        </Col>
      </Row> */}
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('annualApplication.table.conclusionContracts'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <ConclusionContractsTableFilters />
        </Col>
        <Col span={24}>
          <ConclusionContractsTableContainer />
        </Col>
      </Row>
    </div>
  );
};
