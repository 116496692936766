import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getBusinessPlanFiltersSelector } from '@store/selectors/businessPlan';
import { changeBusinessPlanFilters } from '@store/slices/businessPlan';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<any>;
  setBusinessPlanFilters: (filters: any) => void;
};

export const useBusinessPlanTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getBusinessPlanFiltersSelector);

  const setBusinessPlanFilters = useCallback((newFilters: any) => {
    Object.entries(newFilters).forEach(([key, value]: any) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    setParams(params);

    const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

    dispatch(changeBusinessPlanFilters(values as any));
  }, []);

  return { filters, setBusinessPlanFilters };
};
