import { SectionQueryKey } from '@constants/queryKeys';
import { CustomSteps } from '@features/ui-kit/customSteps';
import { WaterQualityCardStep } from '@models/waterQuality/interfaces/waterQuality';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetZoneQuery } from '@store/gisproApi';
import { getWaterQualityCardSelector } from '@store/selectors';
import { setWaterQualityCard } from '@store/slices';
import { Breadcrumb, Col, Row, Typography } from 'antd';

import { isEmpty } from '@utils/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { WaterQualityCardFirstStep } from './waterQualityCardFirstStep';
import { WaterQualityCardSecondStep } from './waterQualityCardSecondStep';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const WaterQualityCard: React.FC = () => {
  const [params] = useSearchParams();
  const waterQualityCard = useSelector(getWaterQualityCardSelector);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const steps = [
    {
      value: WaterQualityCardStep.RegisterMonitoringZone,
      title: id ? 'Общие данные' : 'Регистрация зоны мониторинга',
    },
    {
      value: WaterQualityCardStep.CreateCollectionPoint,
      title: 'Создание точки сбора показателей',
      disabled: isEmpty(waterQualityCard),
    },
    // {
    //   key: WaterQualityCardStep.Gis,
    //   label: 'ГИС',
    //   disabled: isEmpty(waterQualityCard),
    // },
  ];
  const { data: zoneData } = useGetZoneQuery(id ? { id } : skipToken);
  useEffect(() => {
    dispatch(setWaterQualityCard(zoneData || {}));
  }, [zoneData]);

  const waterQualityRegistryStep =
    params.get(SectionQueryKey) || WaterQualityCardStep.RegisterMonitoringZone;

  let Step;

  switch (waterQualityRegistryStep) {
    case WaterQualityCardStep.RegisterMonitoringZone:
      Step = WaterQualityCardFirstStep;
      break;
    case WaterQualityCardStep.CreateCollectionPoint:
      Step = WaterQualityCardSecondStep;
      break;
    // case WaterQualityCardStep.Gis:
    //   Step = WaterQualityCardFirstStep;
    //   break;
    default:
      Step = WaterQualityCardFirstStep;
      break;
  }
  return (
    <div>
      <Row gutter={16}>
        <Col>
          <DefaultButton
            onClick={() => navigate(Routes.WaterQualityTable)}
            className="backBtn"
          >
            <LeftOutlined />
            <Typography.Text>Назад</Typography.Text>
          </DefaultButton>
        </Col>
        <Col>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: waterQualityCard.zoneId
                  ? 'Редактирование зоны'
                  : 'Создание зоны',
              },
            ]}
          />
        </Col>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[15, 8]}>
        <Col span={24}>
          <CustomSteps
            steps={steps}
            queryParams={{
              queryKey: SectionQueryKey,
              queryType: WaterQualityCardStep,
            }}
          />
        </Col>
        <Col span={24}>
          <Step />
        </Col>
      </Row>
    </div>
  );
};
