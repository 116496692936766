import { IBasin } from '@models/basin/interfaces/basin';
import { IrrigationSystem } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { Owner } from '@models/owners/interfaces/owner';
import { ISource } from '@models/source/interfaces/source';
import { SystemObject } from '@models/systemObject/systemObject';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';
import { api } from '../basicApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    irrigationGetAll: build.query<
      IrrigationGetAllApiResponse,
      IrrigationGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/all` }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindSystemById: build.query<
      IrrigationFindSystemByIdApiResponse,
      IrrigationFindSystemByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system/${queryArg.id}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindSystemSystemInfoById: build.query<
      IrrigationFindSystemSystemInfoByIdApiResponse,
      IrrigationFindSystemSystemInfoByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system/system-info/${queryArg.id}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindSystemSystemObjectsById: build.query<
      IrrigationFindSystemSystemObjectsByIdApiResponse,
      IrrigationFindSystemSystemObjectsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system/system-objects/${queryArg.id}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindSystemWaterUsersById: build.query<
      IrrigationFindSystemWaterUsersByIdApiResponse,
      IrrigationFindSystemWaterUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system/water-users/${queryArg.id}`,
        params: {
          code: queryArg.code,
          name: queryArg.name,
          userType: queryArg.userType,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllAqueductsBySystemId: build.query<
      IrrigationFindAllAqueductsBySystemIdApiResponse,
      IrrigationFindAllAqueductsBySystemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/aqueduct-list/${queryArg.systemId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetAqueducts: build.query<
      IrrigationFindConstructorAqueductsBySystemIdApiResponse,
      IrrigationFindConstructorAqueductsBySystemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/constructor/aqueduct-list/${queryArg.systemId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetAllSystemPage: build.query<
      IrrigationGetAllSystemPageApiResponse,
      IrrigationGetAllSystemPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/list`,
        params: {
          name: queryArg.name,
          districtId: queryArg.districtId,
          regionId: queryArg.regionId,
          regNum: queryArg.regNum,
          systemTypeId: queryArg.systemTypeId,
          irrigationSystemSourceId: queryArg.irrigationSystemSourceId,
          basinId: queryArg.basinId,
          isArchived: queryArg.isArchived,
          ruvhId: queryArg.ruvhId,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindByAqueductId: build.query<
      IrrigationFindByAqueductIdApiResponse,
      IrrigationFindByAqueductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/aqueduct/${queryArg.aqueductId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetAllBasin: build.query<
      IrrigationGetAllBasinApiResponse,
      IrrigationGetAllBasinApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-all-basin`,
        params: { region_id: queryArg?.regionId },
      }),
      providesTags: ['IrrigationSystem'],
      transformResponse: (response: Array<IBasin>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    irrigationGetAllSourceType: build.query<
      IrrigationGetAllSourceTypeApiResponse,
      IrrigationGetAllSourceTypeApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/get-all-source-type` }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsHydroposts: build.query<
      IrrigationFindAllObjectsHydropostsApiResponse,
      IrrigationFindAllObjectsHydropostsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-all-hydroposts`,
        params: {
          districtId: queryArg.districtId,
          regionId: queryArg.regionId,
          systemId: queryArg.systemId,
          typeCategory: queryArg.typeCategory,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindOutletHydroposts: build.query<
      IrrigationFindOutletHydropostsApiResponse,
      IrrigationFindOutletHydropostsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-outlet-hydroposts`,
        params: { outletId: queryArg.outletId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsVodovod: build.query<
      IrrigationFindAllObjectsVodovodApiResponse,
      IrrigationFindAllObjectsVodovodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-all-vodovod`,
        params: {
          systemObjectId: queryArg.systemObjectId,
          groupId: queryArg.groupId,
          categoryId: queryArg.categoryId,
          typeObjectId: queryArg.typeObjectId,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetAllWaterIntakePoint: build.query<
      IrrigationGetAllWaterIntakePointApiResponse,
      IrrigationGetAllWaterIntakePointApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-all-water-intake-point`,
        params: { regionId: queryArg?.regionId, basinId: queryArg?.basinId },
      }),
      providesTags: ['IrrigationSystem'],
      transformResponse: (response: Array<ISource>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    irrigationGetAllOwner: build.query<
      IrrigationGetAllOwnerApiResponse,
      IrrigationGetAllOwnerApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/get-owners` }),
      providesTags: ['IrrigationSystem'],
      transformResponse: (response: Array<Owner>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
    }),
    irrigationCreate: build.mutation<
      IrrigationCreateApiResponse,
      IrrigationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create`,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveAqueduct: build.mutation<
      IrrigationSaveAqueductApiResponse,
      IrrigationSaveAqueductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/aqueduct`,
        method: 'POST',
        body: queryArg.systemObjectDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveAqueductToAqueduct: build.mutation<
      IrrigationSaveAqueductToAqueductApiResponse,
      IrrigationSaveAqueductToAqueductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/aqueduct-to-aqueduct`,
        method: 'POST',
        body: queryArg.aqueducts,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveAqueductObjectWithSections: build.mutation<
      IrrigationSaveAqueductObjectWithSectionsApiResponse,
      IrrigationSaveAqueductObjectWithSectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/aqueduct-objects-section-constructor`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveAqueductWithAqueducts: build.mutation<
      IrrigationSaveAqueductWithAqueductsApiResponse,
      IrrigationSaveAqueductWithAqueductsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/aqueduct-gts-constructor`,
        method: 'POST',
        body: queryArg.systemObjectAqueducts,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationDeleteAqueductObjectsRelationWithSections:
      build.mutation<
        IrrigationDeleteAqueductObjectsRelationWithSectionsApiResponse,
        IrrigationDeleteAqueductObjectsRelationWithSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/irrigation-system/delete/aqueduct-objects-with-sections`,
          method: 'POST',
          body: queryArg.aqueductObjectsWithSections,
        }),
        invalidatesTags: ['IrrigationSystem'],
      }),
    irrigationFindGeneralAqueductBySystemId: build.query<
      IrrigationFindGeneralAqueductBySystemIdApiResponse,
      IrrigationFindGeneralAqueductBySystemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/general-aqueduct/${queryArg.systemId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsBySectionId: build.query<
      IrrigationFindAllObjectsBySectionIdApiResponse,
      IrrigationFindAllObjectsBySectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/find-all-objects-by-sectionId/${queryArg.sectionId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsByAqueductId: build.query<
      IrrigationFindAllObjectsByAqueductIdApiResponse,
      IrrigationFindAllObjectsByAqueductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/find-all-objects-by-aqueduct/${queryArg.aqueductId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsByAqueductSections: build.query<
      IrrigationFindAllObjectsByAqueductSectionsApiResponse,
      IrrigationFindAllObjectsByAqueductSectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/find-all-objects-by-aqueduct-sections/${queryArg.aqueductId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindByAqueductSections: build.query<
      IrrigationFindByAqueductSectionsApiResponse,
      IrrigationFindByAqueductSectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/find-by-aqueduct-objects-sections/${queryArg.aqueductId}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationSaveAttachments: build.mutation<
      IrrigationSaveAttachmentsApiResponse,
      IrrigationSaveAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/attachments`,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveObjects: build.mutation<
      IrrigationSaveObjectsApiResponse,
      IrrigationSaveObjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/objects`,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveObjectsTree: build.mutation<
      IrrigationSaveObjectsTreeApiResponse,
      IrrigationSaveObjectsTreeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/objects-tree`,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSaveTreeGeonode: build.mutation<
      IrrigationSaveTreeGeonodeApiResponse,
      IrrigationSaveTreeGeonodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/tree-geonode`,
        method: 'POST',
        body: queryArg.irrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsPage: build.query<
      IrrigationFindAllObjectsPageApiResponse,
      IrrigationFindAllObjectsPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/object-list`,
        params: {
          groupId: queryArg.groupId,
          categoryId: queryArg.categoryId,
          typeObjectId: queryArg.typeObjectId,
          code: queryArg.code,
          name: queryArg.name,
          irrigationSystemId: queryArg.irrigationSystemId,
          channelId: queryArg.channelId,
          regionId: queryArg.regionId,
          districtId: queryArg.districtId,
          isArchived: queryArg.isArchived,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetAllSystemList: build.query<
      IrrigationGetAllSystemListApiResponse,
      IrrigationGetAllSystemListApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/system-lists` }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjects: build.query<
      IrrigationFindAllObjectsApiResponse,
      IrrigationFindAllObjectsApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/system-object-list` }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsSources: build.query<
      IrrigationFindAllObjectsSourcesApiResponse,
      IrrigationFindAllObjectsSourcesApiArg
    >({
      query: () => ({ url: `/api/v1/irrigation-system/object-list/source` }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsOutlets: build.query<
      IrrigationFindAllObjectsOutletsApiResponse,
      IrrigationFindAllObjectsOutletsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/object-list/outlets`,
        params: {
          districtId: queryArg.districtId,
          regionId: queryArg.regionId,
          systemId: queryArg.systemId,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindAllObjectsChannels: build.query<
      IrrigationFindAllObjectsChannelsApiResponse,
      IrrigationFindAllObjectsChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/object-list/channels`,
        params: {
          districtId: queryArg.districtId,
          regionId: queryArg.regionId,
          systemId: queryArg.systemId,
        },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationFindObjectById: build.query<
      IrrigationFindObjectByIdApiResponse,
      IrrigationFindObjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system/object/${queryArg.id}`,
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationUpdateSystem: build.mutation<
      IrrigationUpdateSystemApiResponse,
      IrrigationUpdateSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/update/system`,
        method: 'POST',
        body: queryArg.updateIrrigationSystemDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationSetSystemGeo: build.mutation<
      IrrigationSetSystemGeoApiResponse,
      IrrigationSetSystemGeoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/add-geonode`,
        method: 'POST',
        body: queryArg.systemGeonodeDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationCompareObjectsInGeonode: build.query<
      IrrigationCompareObjectsInGeonodeApiResponse,
      IrrigationCompareObjectsInGeonodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/objects-compare`,
        params: { systemId: queryArg.systemId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetRivers: build.query<
      IrrigationGetRiversApiResponse,
      IrrigationGetRiversApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-rivers`,
        params: { region_id: queryArg.regionId, basin_id: queryArg.basinId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetLakes: build.query<
      IrrigationGetLakesApiResponse,
      IrrigationGetLakesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-lakes`,
        params: { region_id: queryArg.regionId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationGetOthers: build.query<
      IrrigationGetOthersApiResponse,
      IrrigationGetOthersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/get-others`,
        params: { region_id: queryArg.regionId, basin_id: queryArg.basinId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
    irrigationSaveSource: build.mutation<
      IrrigationSaveSourceApiResponse,
      IrrigationSaveSourceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/create/water-intake-point`,
        method: 'POST',
        body: queryArg.waterIntakePointDto,
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationArchiveSystemObject: build.mutation<
      IrrigationArchiveSystemObjectApiResponse,
      IrrigationArchiveSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/system-object/archive/${queryArg.id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationArchiveIrrigation: build.mutation<
      IrrigationArchiveIrrigationApiResponse,
      IrrigationArchiveIrrigationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/irrigation/archive/${queryArg.id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['IrrigationSystem'],
    }),
    irrigationGetCounters: build.query<
      IrrigationGetCountersApiResponse,
      IrrigationGetCountersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/irrigation-system/counters`,
        params: { districtId: queryArg.districtId },
      }),
      providesTags: ['IrrigationSystem'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type IrrigationGetAllApiResponse = unknown;
export type IrrigationGetAllApiArg = void;
export type IrrigationFindSystemByIdApiResponse = IrrigationSystem;
export type IrrigationFindSystemByIdApiArg = {
  id: number;
};
export type IrrigationFindSystemSystemInfoByIdApiResponse =
  unknown;
export type IrrigationFindSystemSystemInfoByIdApiArg = {
  id: number;
};
export type IrrigationFindSystemSystemObjectsByIdApiResponse =
  unknown;
export type IrrigationFindSystemSystemObjectsByIdApiArg = {
  id: number;
};
export type IrrigationFindSystemWaterUsersByIdApiResponse =
  unknown;
export type IrrigationFindSystemWaterUsersByIdApiArg = {
  id: number;
  code?: string;
  name?: string;
  userType?: number;
};
export type IrrigationFindConstructorAqueductsBySystemIdApiResponse =
  any[]; // TODO: type
export type IrrigationFindConstructorAqueductsBySystemIdApiArg =
  {
    systemId: number;
  };
export type IrrigationFindAllAqueductsBySystemIdApiResponse =
  SystemObject[];
export type IrrigationFindAllAqueductsBySystemIdApiArg = {
  systemId: number;
};
export type IrrigationGetAllSystemPageApiResponse = unknown;
export type IrrigationGetAllSystemPageApiArg = {
  name: string;
  districtId: number;
  regionId: number;
  regNum: string;
  systemTypeId: number;
  irrigationSystemSourceId: number;
  basinId: number;
  isArchived: boolean;
  ruvhId: number;
};
export type IrrigationFindByAqueductIdApiResponse = SystemObject;
export type IrrigationFindByAqueductIdApiArg = {
  aqueductId: number;
};
export type IrrigationGetAllBasinApiResponse = Array<IBasin>;
export type IrrigationGetAllBasinApiArg = {
  regionId: number;
} | void;
export type IrrigationGetAllSourceTypeApiResponse = unknown;
export type IrrigationGetAllSourceTypeApiArg = void;
export type IrrigationFindAllObjectsHydropostsApiResponse =
  unknown;
export type IrrigationFindAllObjectsHydropostsApiArg = {
  districtId?: number;
  regionId?: number;
  systemId?: number;
  typeCategory?: string;
};
export type IrrigationFindOutletHydropostsApiResponse = unknown;
export type IrrigationFindOutletHydropostsApiArg = {
  outletId?: number;
};
export type IrrigationFindAllObjectsVodovodApiResponse =
  unknown;
export type IrrigationFindAllObjectsVodovodApiArg = {
  systemObjectId: number;
  groupId: number;
  categoryId: number;
  typeObjectId: number;
};
export type IrrigationGetAllWaterIntakePointApiResponse = Array<ISource>;
export type IrrigationGetAllWaterIntakePointApiArg = {
  regionId?: number;
  basinId: number;
} | void;
export type IrrigationGetAllOwnerApiResponse = Array<Owner>;
export type IrrigationGetAllOwnerApiArg = void;
export type IrrigationCreateApiResponse = unknown;
export type IrrigationCreateApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type IrrigationSaveAqueductApiResponse = unknown;
export type IrrigationSaveAqueductApiArg = {
  systemObjectDto: SystemObjectDto;
};
export type IrrigationSaveAqueductToAqueductApiResponse =
  unknown;
export type IrrigationSaveAqueductToAqueductApiArg = {
  aqueducts: AqueductToAqueductDto[];
};
export type IrrigationSaveAqueductObjectWithSectionsApiResponse =
  unknown;
export type IrrigationSaveAqueductObjectWithSectionsApiArg = {
  body: any[];
};
export type IrrigationSaveAqueductWithAqueductsApiResponse =
  unknown;
export type IrrigationSaveAqueductWithAqueductsApiArg = {
  systemObjectAqueducts: SystemObjectAqueducts;
};
export type IrrigationDeleteAqueductObjectsRelationWithSectionsApiResponse =
  unknown;
export type IrrigationDeleteAqueductObjectsRelationWithSectionsApiArg =
  {
    aqueductObjectsWithSections: AqueductObjectsWithSections;
  };
export type IrrigationFindGeneralAqueductBySystemIdApiResponse = SystemObject;
export type IrrigationFindGeneralAqueductBySystemIdApiArg = {
  systemId: string;
};
export type IrrigationFindAllObjectsBySectionIdApiResponse =
  unknown;
export type IrrigationFindAllObjectsBySectionIdApiArg = {
  sectionId: string;
};
export type IrrigationFindAllObjectsByAqueductIdApiResponse = SystemObject[];
export type IrrigationFindAllObjectsByAqueductIdApiArg = {
  aqueductId: string;
};
export type IrrigationFindAllObjectsByAqueductSectionsApiResponse = SystemObject[];
export type IrrigationFindAllObjectsByAqueductSectionsApiArg = {
  aqueductId: string;
};
export type IrrigationFindByAqueductSectionsApiResponse = IWaterSection[];
export type IrrigationFindByAqueductSectionsApiArg = {
  aqueductId: string;
};
export type IrrigationSaveAttachmentsApiResponse = unknown;
export type IrrigationSaveAttachmentsApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type IrrigationSaveObjectsApiResponse = unknown;
export type IrrigationSaveObjectsApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type IrrigationSaveObjectsTreeApiResponse = unknown;
export type IrrigationSaveObjectsTreeApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type IrrigationSaveTreeGeonodeApiResponse = unknown;
export type IrrigationSaveTreeGeonodeApiArg = {
  irrigationSystemDto: IrrigationSystemDto;
};
export type IrrigationFindAllObjectsPageApiResponse = unknown;
export type IrrigationFindAllObjectsPageApiArg = {
  groupId: number;
  categoryId: number;
  typeObjectId: number;
  code: string;
  name: string;
  irrigationSystemId: number;
  channelId: number;
  regionId: number;
  districtId: number;
  isArchived: boolean;
};
export type IrrigationGetAllSystemListApiResponse = unknown;
export type IrrigationGetAllSystemListApiArg = void;
export type IrrigationFindAllObjectsApiResponse = unknown;
export type IrrigationFindAllObjectsApiArg = void;
export type IrrigationFindAllObjectsSourcesApiResponse =
  unknown;
export type IrrigationFindAllObjectsSourcesApiArg = void;
export type IrrigationFindAllObjectsOutletsApiResponse =
  unknown;
export type IrrigationFindAllObjectsOutletsApiArg = {
  districtId: number;
  regionId: number;
  systemId: number;
};
export type IrrigationFindAllObjectsChannelsApiResponse =
  unknown;
export type IrrigationFindAllObjectsChannelsApiArg = {
  districtId: number;
  regionId: number;
  systemId: number;
};
export type IrrigationFindObjectByIdApiResponse = unknown;
export type IrrigationFindObjectByIdApiArg = {
  id: number;
};
export type IrrigationUpdateSystemApiResponse = unknown;
export type IrrigationUpdateSystemApiArg = {
  updateIrrigationSystemDto: UpdateIrrigationSystemDto;
};
export type IrrigationSetSystemGeoApiResponse = unknown;
export type IrrigationSetSystemGeoApiArg = {
  systemGeonodeDto: SystemGeonodeDto;
};
export type IrrigationCompareObjectsInGeonodeApiResponse =
  unknown;
export type IrrigationCompareObjectsInGeonodeApiArg = {
  systemId: number;
};
export type IrrigationGetRiversApiResponse = unknown;
export type IrrigationGetRiversApiArg = {
  regionId: number;
  basinId: number;
};
export type IrrigationGetLakesApiResponse = unknown;
export type IrrigationGetLakesApiArg = {
  regionId: number;
};
export type IrrigationGetOthersApiResponse = unknown;
export type IrrigationGetOthersApiArg = {
  regionId: number;
  basinId: number;
};
export type IrrigationSaveSourceApiResponse = unknown;
export type IrrigationSaveSourceApiArg = {
  waterIntakePointDto: WaterIntakePointDto;
};
export type IrrigationArchiveSystemObjectApiResponse = unknown;
export type IrrigationArchiveSystemObjectApiArg = {
  id: number;
};
export type IrrigationArchiveIrrigationApiResponse = unknown;
export type IrrigationArchiveIrrigationApiArg = {
  id: number;
};
export type IrrigationGetCountersApiResponse = unknown;
export type IrrigationGetCountersApiArg = {
  districtId: string;
};
export type IrrigationSystemDto = any;
export type Geojson = any;
export type SystemObjectStatusDto = any;
export type SystemObjectDto = {
  id: number;
  code: string;
  name: string;
  objectId: number;
  objectName: string;
  aqueductId: number;
  systemId: number;
  objectCode: string;
  coordinates: string;
  dateStart: string;
  isGeneral: boolean;
  geoJson: Geojson;
  attachmentIds: string[];
  direction: string;
  isSource: boolean;
  basinId: number;
  basinName: string;
  channelId: number;
  channelName: string;
  source: number;
  builtProject: string;
  lengthKm: string;
  lengthPickets: string;
  carryingAmount: string;
  gtsObject: SystemObjectDto;
  gvsObject: SystemObjectDto;
  totalArea: string;
  numberOfStructures: string;
  collectedWater: string;
  dischargedWater: string;
  characteristicsOfConstruction: string;
  operatingCharacteristics: string;
  technicalStatusName: string;
  operationalStatusName: string;
  technicalStatusId: number;
  operationalStatusId: number;
  irrigationSystems: number;
  waterSections: string[];
  aqueductObjectsChildrens: string[];
  ownerId: number;
  ownerName: string;
  appointmentId: number;
  parentId: number;
  districtId: number;
  districtName: string;
  baseName: string;
  systemName: string;
  regionId: number;
  regionName: string;
  typeObjectId: number;
  groupObjectId: number;
  categoryObjectId: number;
  objectCategoryId: number;
  objectCategoryName: string;
  status: SystemObjectStatusDto;
  typeCategory: string;
  workDtos: string[];
  wearDtos: string[];
  objectGroupId: number;
  objectGroupName: string;
  isArchived: boolean;
  hydropostId: number;
  usefulVolume: number | null;
  minVolume: number | null;
  maxVolume: number | null;
};
export type AqueductToAqueductDto = {
  id: number;
  parentId: number;
  waterIntakeId: number;
};
export type SystemObjectAqueducts = any;
export type AqueductObjectsWithSections = any;
export type UpdateIrrigationSystemDto = {
  systemId: number;
  name: string;
  regNum: string;
  systemTypeId: number;
  dateDesign: string;
  baseId: number;
  residualValue: string;
  wearSum: string;
  basinId: number;
  amountStructures: string;
  waterMeasurementDevices: string;
  waterMeasurementDevicesAuto: string;
  area: string;
  areaOfFields: string;
  areaPlanting: string;
  additionalConstructions: string;
  otherObjects: string;
  attachmentIds: string[];
};
export type SystemGeonodeDto = any;
export type WaterIntakePointDto = any;
export const {
  useIrrigationGetAllQuery,
  useIrrigationFindSystemByIdQuery,
  useIrrigationFindSystemSystemInfoByIdQuery,
  useIrrigationFindSystemSystemObjectsByIdQuery,
  useIrrigationFindSystemWaterUsersByIdQuery,
  useIrrigationGetAqueductsQuery,
  useIrrigationFindAllAqueductsBySystemIdQuery,
  useIrrigationGetAllSystemPageQuery,
  useIrrigationFindByAqueductIdQuery,
  useIrrigationGetAllBasinQuery,
  useIrrigationGetAllSourceTypeQuery,
  useIrrigationFindAllObjectsHydropostsQuery,
  useIrrigationFindOutletHydropostsQuery,
  useIrrigationFindAllObjectsVodovodQuery,
  useIrrigationGetAllWaterIntakePointQuery,
  useIrrigationGetAllOwnerQuery,
  useIrrigationCreateMutation,
  useIrrigationSaveAqueductMutation,
  useIrrigationSaveAqueductToAqueductMutation,
  useIrrigationSaveAqueductObjectWithSectionsMutation,
  useIrrigationSaveAqueductWithAqueductsMutation,
  useIrrigationDeleteAqueductObjectsRelationWithSectionsMutation,
  useIrrigationFindGeneralAqueductBySystemIdQuery,
  useIrrigationFindAllObjectsBySectionIdQuery,
  useIrrigationFindAllObjectsByAqueductIdQuery,
  useIrrigationFindAllObjectsByAqueductSectionsQuery,
  useIrrigationFindByAqueductSectionsQuery,
  useIrrigationSaveAttachmentsMutation,
  useIrrigationSaveObjectsMutation,
  useIrrigationSaveObjectsTreeMutation,
  useIrrigationSaveTreeGeonodeMutation,
  useIrrigationFindAllObjectsPageQuery,
  useIrrigationGetAllSystemListQuery,
  useIrrigationFindAllObjectsQuery,
  useIrrigationFindAllObjectsSourcesQuery,
  useIrrigationFindAllObjectsOutletsQuery,
  useIrrigationFindAllObjectsChannelsQuery,
  useIrrigationFindObjectByIdQuery,
  useIrrigationUpdateSystemMutation,
  useIrrigationSetSystemGeoMutation,
  useIrrigationCompareObjectsInGeonodeQuery,
  useIrrigationGetRiversQuery,
  useIrrigationGetLakesQuery,
  useIrrigationGetOthersQuery,
  useIrrigationSaveSourceMutation,
  useIrrigationArchiveSystemObjectMutation,
  useIrrigationArchiveIrrigationMutation,
  useIrrigationGetCountersQuery,
} = injectedRtkApi;
