import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHydroModuleFiltersSelector } from '@store/selectors';
import { agroClimaticZoneActions } from '@store/slices';
import pickBy from 'lodash/pickBy';
import { IAgroClimaticZoneHydroModule } from '@models/hydroModule/interfaces/agroClimaticZoneHydroModule';

type ReturnType = {
  filters: Partial<IAgroClimaticZoneHydroModule>;
  setHydroModuleFilters: (
    filters: Partial<IAgroClimaticZoneHydroModule>,
  ) => void;
};

export const useHydroModuleFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getHydroModuleFiltersSelector);

  const setHydroModuleFilters = useCallback(
    (newFilters: Partial<IAgroClimaticZoneHydroModule>) => {
      const newParams = new URLSearchParams(params.toString());

      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });

      setParams(newParams);

      const filterValues = pickBy(
        newFilters,
        (v) => v !== undefined && v !== null && v !== '',
      );
      dispatch(
        agroClimaticZoneActions.changeHydroModuleFilters(filterValues as any),
      );
    },
    [dispatch, params, setParams],
  );

  return { filters, setHydroModuleFilters };
};
