import { Card, Col, Row, Table, Typography } from 'antd';

import { TableCustom } from '@features/ui-kit';
import { IPlantingDataCalculatedDto } from '@models/annualApplication/interfaces/annualApplication';

import { CalculationEstimatedFinancialSummary } from './CalculationEstimatedFinancialSummary';
import styles from './CalculationEstimatedFinancialTable.module.scss';
import { useCalculationEstimatedFinancialColumns } from './useCalculationEstimatedFinancialColumns';

type CalculationEstimatedFinancialTablePropTypes = {
  item: IPlantingDataCalculatedDto;
};

export const CalculationEstimatedFinancialTable: React.FC<
  CalculationEstimatedFinancialTablePropTypes
> = ({ item }) => {
  const { columns } = useCalculationEstimatedFinancialColumns();
  const dataSourceWithIndex = (item.irrigationStandards || [])?.map(
    (standard, index) => ({
      ...standard,
      index: index + 1,
    }),
  );

  return (
    <TableCustom
      className={styles.planting}
      size="small"
      key={item.name}
      // eslint-disable-next-line react/no-unstable-nested-components
      title={() => (
        <Row>
          <Col span={12}>
            <Typography.Text strong>{item.name}</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>Площадь:</Typography.Text> {item.totalArea},
            <Typography.Text strong> КТП:</Typography.Text> {item.ktp},
            <Typography.Text strong> КВХ:</Typography.Text> {item.kvh}
          </Col>
        </Row>
      )}
      columns={columns}
      bordered
      dataSource={dataSourceWithIndex}
      // eslint-disable-next-line react/no-unstable-nested-components
      summary={(summData) => {
        if (summData) {
          return <CalculationEstimatedFinancialSummary data={summData} />;
        }
        return false;
      }}
      pagination={false}
    />
  );
};
