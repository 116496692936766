import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { Statuses } from '@enums/table/customTableStatus';
import { CalculatedProgress, CustomCard } from '@features/ui-kit';
import {
  TaskPrioritiesTranslateEnum,
  TaskStatusEnum,
} from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindConstructionBlockByIdQuery,
  useFindOperationalBlockByIdQuery,
} from '@store/gisproApi';

import styles from './commonInfo.module.scss';

export const CommonInfo = () => {
  const { id } = useParams();

  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );

  return (
    <Row gutter={16}>
      <Col span={6}>
        <CustomCard title="Статус">
          <div className={styles.status}>
            {constructionBlock &&
              Statuses[constructionBlock.status as keyof typeof Statuses]}
          </div>
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Бюджет (сом)">
          <div className={styles.budget}>
            <div className={styles.budgetRow}>
              <div className={styles.budgetRowTitle}>План:</div>
              <div>{constructionBlock && constructionBlock.planBudget}</div>
            </div>
            <div className={styles.budgetRow}>
              <div className={styles.budgetRowTitle}>Факт:</div>
              <div>{constructionBlock && constructionBlock.factBudget}</div>
            </div>
          </div>
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Прогресс">
          <CalculatedProgress
            tasksArray={
              constructionBlock && constructionBlock?.implementationTerms
            }
          />
        </CustomCard>
      </Col>
      <Col span={6}>
        <CustomCard title="Приоритет">
          <div
            className={`${styles.priority} ${
              constructionBlock && styles[constructionBlock.priority]
            }`}
          >
            {constructionBlock &&
              TaskPrioritiesTranslateEnum[
                constructionBlock.priority as keyof typeof TaskPrioritiesTranslateEnum
              ]}
          </div>
        </CustomCard>
      </Col>
    </Row>
  );
};
