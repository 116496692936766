import React, { useRef } from 'react';

import { Select, SelectProps, Tooltip, TooltipProps } from 'antd';

import { Links } from '@enums/links';

import styles from './TooltipedSelectV2.module.scss';

type TooltipedSelectV2PropsType = SelectProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
  showSearch?: boolean;
  suffixIcon?: React.ReactNode;
};

export const TooltipedSelectV2: React.FC<TooltipedSelectV2PropsType> = ({
  tooltipProps,
  showSearch = true,
  suffixIcon = <img src={Links.ArrowDown} alt="" />,
  ...props
}) => {
  const ref = useRef(null);

  return (
    <Tooltip {...tooltipProps}>
      <Select
        {...props}
        ref={ref}
        className={styles.select}
        showSearch={showSearch}
        filterOption={(input, option) =>
          ((option?.label ?? '') as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        suffixIcon={suffixIcon}
      />
    </Tooltip>
  );
};
