import { Form } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TablePreviewV2 } from '@features/ui-kit-v2';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { getCreateWaterObjectList } from '@store/selectors';
import { removeWaterObjectToCreateWaterObjectListAction } from '@store/slices';

import { useCreateWaterObjectTablePreviewColumns } from './useCreateWaterObjectTablePreviewColumns';
import { CustomCard, TableCustom } from '@features/ui-kit';

interface ICreateWaterObjectTablePreviewProps {
  setEditId: (value: string) => void;
  setDocuments: (value: any) => void;
}
export const CreateWaterObjectTablePreview: FC<
  ICreateWaterObjectTablePreviewProps
> = ({ setEditId, setDocuments }) => {
  const form = Form.useFormInstance();
  const { columns } = useCreateWaterObjectTablePreviewColumns();
  const dispatch = useDispatch();

  const waterObjects: Array<Partial<IWaterObject>> = useSelector(
    getCreateWaterObjectList,
  );

  const deleteItem = useCallback((item: Partial<IWaterObject>) => {
    dispatch(removeWaterObjectToCreateWaterObjectListAction(item.index!));
  }, []);

  const onHandleEdit = useCallback((waterObject: IWaterObject) => {
    setEditId(waterObject?.id || '');
    setDocuments(waterObject?.attachmentIds);
    console.log('waterObject', waterObject);
    form.setFieldsValue({
      ...waterObject,
      dateStart: waterObject.dateStart ? dayjs(waterObject.dateStart) : null,
    });
    form.setFieldValue('objectGroupId', waterObject.objectGroupId);
    form.setFieldValue('objectCategoryId', waterObject.objectCategoryId);
    form.setFieldValue('typeCategory', waterObject.typeCategory);
  }, []);

  return (
    <CustomCard title="Таблица объектов">
      <TableCustom
        bordered
        columns={columns}
        dataSource={waterObjects}
        pagination={false}
        actionProps={{
          onHandleEdit,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
