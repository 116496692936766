import { useSearchParams } from 'react-router-dom';

import { TabsProps } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Tabs } from '@features/ui-kit';
import { IrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';

const tabs: TabsProps['items'] = [
  {
    key: IrrigationSystemQueryTypes.totalInformation,
    label: 'Общие данные',
  },
  {
    key: IrrigationSystemQueryTypes.systemBlock,
    label: 'Системные показатели',
  },
  {
    key: IrrigationSystemQueryTypes.objects,
    label: 'Объекты и структура',
  },
  {
    key: IrrigationSystemQueryTypes.waterUsers,
    label: 'Водопользователи',
  },
  {
    key: IrrigationSystemQueryTypes.operationalBlock,
    label: 'Эксплуатационный блок',
  },
  {
    key: IrrigationSystemQueryTypes.document,
    label: 'Документы',
  },
];

export const IrrigationSystemCardControlBlock: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultTab =
    searchParams.get(SectionQueryKey) ||
    IrrigationSystemQueryTypes.totalInformation;

  const handleChangeTab = (tab: string) => {
    setSearchParams({ [SectionQueryKey]: tab });
  };

  return (
    <Tabs
      items={tabs}
      defaultActiveKey={defaultTab}
      onChange={handleChangeTab}
    />
  );
};
