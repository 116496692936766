import { Layout } from 'antd';

import { LinkedOutlet } from '@features/agroClimaticZone/agroClimaticZoneCard/hydroModuleTable';

export const LinkedOutletLayout: React.FC = () => {
  return (
    <Layout>
      <LinkedOutlet />
    </Layout>
  );
};
