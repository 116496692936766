import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { PrimaryButton } from '../../../buttons';
import { Divider } from '../../../divider';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';

export const renderWaterAccountingActionButtons = <
  RecordType extends JsonRecord,
>({
  record,
  customColumn,
  actionProps: { onEnterMorningValuesClick, onEnterEveningValuesClick },
}: TableRendererProps<RecordType>) => {
  const { actionsAccess } = customColumn;

  if (!actionsAccess?.length) {
    return (
      <>
        <PrimaryButton
          style={{ width: '100%', borderRadius: 7, height: 30 }}
          size="small"
          onClick={() => onEnterMorningValuesClick?.(record, customColumn)}
        >
          Утро
        </PrimaryButton>
        <Divider style={{ margin: '7px 0' }} />
        <PrimaryButton
          style={{ width: '100%', borderRadius: 7, height: 30 }}
          size="small"
          onClick={() => onEnterEveningValuesClick?.(record, customColumn)}
        >
          Вечер
        </PrimaryButton>
      </>
    );
  }

  return (
    <>
      {useHasUserPermission(actionsAccess[0]) ? (
        <>
          <PrimaryButton
            style={{ width: '100%', borderRadius: 7, height: 30 }}
            size="small"
            onClick={() => onEnterMorningValuesClick?.(record, customColumn)}
          >
            Утро
          </PrimaryButton>
          <Divider style={{ margin: '7px 0' }} />
          <PrimaryButton
            style={{ width: '100%', borderRadius: 7, height: 30 }}
            size="small"
            onClick={() => onEnterEveningValuesClick?.(record, customColumn)}
          >
            Вечер
          </PrimaryButton>
        </>
      ) : null}
    </>
  );
};
