import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import { renderCountCell } from './renderCountCell';
import { renderDecadeCell } from './renderDecadeCell';
import { renderDecadeEditableCell } from './renderDecadeEditableCell';
import { renderGreyCell } from './renderGreyCell';
import { renderPriceEditableCell } from './renderPriceEditableCell';
import { renderQuantity } from './renderQuantity';
import { renderString } from './renderString';
import { renderWaterUserCell } from './renderWaterUserCell';

export class RendererCellFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { xtype } = props.customColumn;
    let renderer = renderString;

    switch (xtype) {
      case CustomTableColumnType.PriceEditableCell:
        renderer = renderPriceEditableCell;
        break;
      case CustomTableColumnType.DecadeEditableCell:
        renderer = renderDecadeEditableCell;
        break;
      case CustomTableColumnType.DecadeCell:
        renderer = renderDecadeCell;
        break;
      case CustomTableColumnType.WaterUser:
        renderer = renderWaterUserCell;
        break;
      case CustomTableColumnType.Quentity:
        renderer = renderQuantity;
        break;
      case CustomTableColumnType.Count:
        renderer = renderCountCell;
        break;
      case CustomTableColumnType.GreyCell:
        renderer = renderGreyCell;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
