import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { Statuses } from '@enums/table/customTableStatus';
import {
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useFindAllIrrigationSystemObjectsListChannelsQuery,
  useFindDictionaryByTypeIdQuery,
} from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { getCurrentTableOptionsSelector } from '@store/selectors';
import { saveTableOptions } from '@store/slices';
import dayjs, { Dayjs } from 'dayjs';

import styles from './IrrigationalSystemCardOperationalControl.module.scss';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { useSearchParams } from 'react-router-dom';
import { IrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';

export const IrrigationalSystemCardOperationalControl: React.FC = () => {
  const [form] = Form.useForm();
  const statusTypes = Object.entries(Statuses).map(([value, label]) => ({
    value,
    label,
  }));
  const { data: objectTypes = [] } =
    useFindAllIrrigationSystemObjectsListChannelsQuery({});

  const { data: workCategories = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Directions,
  });
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  const onFilterButtonClick = () => {
    const filterValues: any = Object.fromEntries(
      Object.entries(form.getFieldsValue()).filter(
        ([key, value]) => value != null,
      ),
    );
    const currentSearchParams = Object.fromEntries(params.entries());
    const updatedSearchParams = {
      ...currentSearchParams,
      ...filterValues,
    };
    setSearchParams(updatedSearchParams);
  };

  const onResetFilterButtonClick = () => {
    form.setFieldsValue({
      q: null,
      systemObjectId: null,
      categoryId: null,
      eventStartDate: undefined,
      eventEndDate: undefined,
      budgetStart: null,
      budgetEnd: null,
      status: null,
    });
    const currentSearchParams = Object.fromEntries(params.entries());
    const updatedSearchParams = {
      section: IrrigationSystemQueryTypes.operationalBlock,
      page: '1',
      limit: '10',
    };
    setSearchParams(updatedSearchParams);
  };

  const initialValues = {
    ...filtersParams,
    systemObjectId:
      filtersParams?.systemObjectId && Number(filtersParams?.systemObjectId),
    categoryId: filtersParams?.categoryId && Number(filtersParams?.categoryId),
    eventStartDate: filtersParams?.eventStartDate
      ? dayjs(filtersParams?.eventStartDate)
      : undefined,
    eventEndDate: filtersParams?.eventEndDate
      ? dayjs(filtersParams?.eventEndDate)
      : undefined,
  };
  return (
    <Form
      className={styles.form}
      form={form}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={16} align={'middle'} wrap={false}>
        <Col>
          <Form.Item name="q">
            <TooltipedSearch placeholder="Наименование" size="large" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="systemObjectId">
            <TooltipedSelect
              size="large"
              allowClear
              options={objectTypes.map((item) => ({
                ...item,
                label: item.objectName,
                value: item.objectId,
              }))}
              placeholder="Объект"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="categoryId">
            <TooltipedSelect
              size="large"
              placeholder="Категория"
              options={workCategories.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col>
          <Row wrap={false} gutter={8} align="middle">
            <Col>Сроки:</Col>
            <Row gutter={8} wrap={false}>
              <Col>
                <Form.Item
                  name="eventStartDate"
                  // normalize={(date: Dayjs) =>
                  //   date?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                  // }
                >
                  <TooltipedDatePicker size="large" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="eventEndDate"
                  // normalize={(date: Dayjs) =>
                  //   date?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                  // }
                >
                  <TooltipedDatePicker size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col>
          <Row wrap={false} gutter={8} align="middle">
            <Col>Сумма:</Col>
            <Row wrap={false} gutter={8}>
              <Col>
                <Form.Item name="budgetStart">
                  <TooltipedInput
                    size="large"
                    type="number"
                    placeholder="План"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="budgetEnd">
                  <TooltipedInput
                    size="large"
                    type="number"
                    placeholder="Факт"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Col>

        <Col>
          <Form.Item name="status">
            <TooltipedSelect
              size="large"
              allowClear
              options={statusTypes.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              placeholder="Статус"
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Row wrap={false} gutter={8} align={'middle'}>
            <Col>
              <PrimaryButton
                icon={<FilterIcon />}
                onClick={onFilterButtonClick}
              />
            </Col>
            <Col>
              <PrimaryButton
                icon={<ResetFilterIcon />}
                onClick={onResetFilterButtonClick}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
