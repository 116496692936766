import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Space, Typography } from 'antd';

import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { AddReseroirDataModal } from '@features/waterObject/waterObjectCard/shared/modals/addReservoirDataModal/AddReseroirDataModal';
import { IReservoirDataByMonthFilters } from '@models/reservoir/interfaces/reservoir';
import { isEmpty } from '@utils/utils';

import styles from './ReservoirMonitoring.module.scss';
import { ReservoirMonitoringFilters } from './reservoirMonitoringFilters/ReservoirMonitoringFilters';
import { ReservoirMonitoringTable } from './reservoirMonitoringTable/ReservoirMonitoringTable';
import { useReservoirMonitoringFilters } from './useReservoirMonitoringFilters';
import { DefaultButton, Divider } from '@features/ui-kit';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const ReservoirMonitoring: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();

  const { setReservoirMonitoringFilters } = useReservoirMonitoringFilters();

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setReservoirMonitoringFilters(
      isEmpty(newFilters)
        ? ({
            month: 1,
          } as unknown as IReservoirDataByMonthFilters)
        : ({
            ...newFilters,
            month: 1,
          } as unknown as IReservoirDataByMonthFilters),
    );
  }, []);

  useEffect(() => {
    setHeaderTitle('Мониторинг водохранилищ');
  }, []);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.ReservoirTable)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'План водопользования',
                },
                {
                  title: 'Просмотр',
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
          <ReservoirMonitoringFilters />

          <ReservoirMonitoringTable />
          <AddReseroirDataModal />
        </Space>
      </div>
    </Layout>
  );
};
