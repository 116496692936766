import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, notification } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { SectionQueryKey } from '@constants/queryKeys';
import { ACCESS } from '@enums/permisson';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { IrrigationSystemTable } from '@features/irrigationSystem';
import { Divider, Tabs } from '@features/ui-kit';
import { WaterObjectTable } from '@features/waterObject';
import { IrrigationSystemTableQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import {
  useToArchiveIrrigationSystemMutation,
  useToArchiveIrrigationSystemObjectMutation,
} from '@store/gisproApi';
import { getConfirmArchiveId } from '@store/selectors';
import { toInteger } from '@utils/utils';

import { useGetCurrentUser } from '@features/authentication';
import { IrrigationWaterReservoir } from '../waterReservoir';
import { ArchiveConfirmModal } from '@features/archiveConfirmModal';
import { validUserPermissions } from '@utils/validateUserPermissions';
import { HomeOutlined } from '@ant-design/icons';

interface optionsItem {
  key: string;
  label: string;
}

const options: optionsItem[] = [
  {
    key: IrrigationSystemTableQueryTypes.irrigationSystem,
    label: 'Ирригационные системы',
  },
  {
    key: IrrigationSystemTableQueryTypes.waterObjects,
    label: 'Гидротехнические сооружения и прочее',
  },
  {
    key: IrrigationSystemTableQueryTypes.reservoirs,
    label: 'Водохранилища',
  },
];

export const IrrigationSystemTableLayout: FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const [params, setSearchParams] = useSearchParams();
  const [toArchive] = useToArchiveIrrigationSystemMutation();

  const [toArchiveSystemObject] = useToArchiveIrrigationSystemObjectMutation();

  const confirmArchiveId = useSelector(getConfirmArchiveId);
  useEffect(() => {
    setHeaderTitle(t('headers.irrigation-system'));
  }, []);
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const queryKey = useMemo(
    () =>
      params.get(SectionQueryKey) ||
      IrrigationSystemTableQueryTypes.irrigationSystem,
    [params],
  );

  const onSubmitArchive = () => {
    const archiveAction =
      queryKey === IrrigationSystemTableQueryTypes.irrigationSystem
        ? toArchive({ id: toInteger(confirmArchiveId) })
        : toArchiveSystemObject({ id: toInteger(confirmArchiveId) });

    archiveAction
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Успешно добавлен в архив',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Не удалось добавить в архив',
        });
      });
  };

  const dynamicOptions = (arr: optionsItem[]) => {
    const tabPermissions = validUserPermissions([
      ACCESS.IRRIGATION_SYSTEM_VIEW,
      ACCESS.IRRIGATION_SYSTEM_OBJECTS_VIEW,
      ACCESS.RESERVOIRS_VIEW,
    ]);

    const validTabPermissions = arr.filter((_, idx) => tabPermissions[idx]);

    return validTabPermissions;
  };

  const onTableTypeChange = (value: SegmentedValue) => {
    const searchParams = new URLSearchParams({
      [SectionQueryKey]: value as string,
      page: '1',
      size: '10',
      ...(IrrigationSystemTableQueryTypes.irrigationSystem === value
        ? {
            regionId: user?.owner?.region?.id.toString() || '',
            districtId: user?.owner?.district?.id.toString() || '',
          }
        : {}),
    });
    setSearchParams(searchParams);
  };

  let Component;
  switch (queryKey) {
    case IrrigationSystemTableQueryTypes.irrigationSystem:
      Component = IrrigationSystemTable;
      break;
    case IrrigationSystemTableQueryTypes.waterObjects:
      Component = WaterObjectTable;
      break;
    case IrrigationSystemTableQueryTypes.reservoirs:
      Component = IrrigationWaterReservoir;
      break;
    default:
      Component = IrrigationSystemTable;
      onTableTypeChange(IrrigationSystemTableQueryTypes.irrigationSystem);
      break;
  }
  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.irrigation-system'),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Tabs
        items={dynamicOptions(options)}
        defaultActiveKey={
          params.get(SectionQueryKey) ||
          IrrigationSystemTableQueryTypes.irrigationSystem
        }
        onChange={onTableTypeChange}
      />
      <Component />
      <ArchiveConfirmModal submit={onSubmitArchive} />
    </>
  );
};
