import {
  IDecadeDto,
  IMonthDto,
} from '@models/annualApplication/interfaces/annualApplication';
import { set } from '@utils/utils';

interface DecadeData {
  decade1: number;
  decade2: number;
  decade3: number;
}

interface MonthData {
  [month: string]: DecadeData;
}

interface IEconomicPlan {
  facilityName: string;
  okmotName: string;
  districtName: string;
  regionName: string;
  organizationName: string;
  outletName: string;
  channelName: string;
  systemName: string;
  contractId: null;
  plantingDataCalculatedDtos: any;
  quarterDtos: any;
  monthDtos: IMonthDto[];
  quarterArea: null;
  waterSupplyQuarter: null;
  priceQuarter: null;
}

interface ParameterResult {
  parameter: string;
  months: MonthData;
}

const parameters: string[] = [
  'totalArea',
  'totalAreaIncrement',
  'waterSupplyLiterPerSeconds',
  'waterSupplyThousandsPerMeter',
  'waterSupplyThousandsPerMeterIncrement',
];
export const mapBusinessPlanData = (data: any) => {
  const { plantingDataCalculatedDtos, monthDtos } = data;

  if (plantingDataCalculatedDtos && monthDtos) {
    const dataSource = plantingDataCalculatedDtos.flatMap((item: any) =>
      item.irrigationStandardDtos.flatMap((standardDtos: any) => {
        return {
          ...standardDtos,
          wateringVolumeTotal: item.wateringVolumeTotal,
        };
      }),
    );

    const res: ParameterResult[] = dataSource.map((item: any) => {
      const parameterObj: ParameterResult = {
        parameter: '',
        months: {},
      };

      parameters.forEach((parameter) => {
        monthDtos?.forEach((monthData: any) => {
          const { monthName, decadesDtos } = monthData;

          set(parameterObj, ['months', monthName], {});

          decadesDtos.forEach((decade: any) => {
            const decadeNumber = `decade${decade.decadeNumber}`;
            const parameterValue = decade[parameter as keyof IDecadeDto] || '-';
            set(
              parameterObj,
              ['months', monthName, decadeNumber],
              parameterValue,
            );
          });
        });
      });

      return { ...item, ...parameterObj };
    });

    return res;
  }

  return data;
};
