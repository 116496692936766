import { vodokhozReportState } from '@store/slices/vodokhozReport';
import { createSelector } from '@reduxjs/toolkit';

type WithVodokhozReportState = {
  vodokhozReport: vodokhozReportState;
};

export const vodokhozReportStateSelector = (
  state: WithVodokhozReportState,
): vodokhozReportState => state.vodokhozReport;

export const getVodokhozWaterUserFilterSelector = createSelector(
  vodokhozReportStateSelector,
  (vodokhozeState) => {
    return vodokhozeState.waterUserFilter;
  },
);

export const getConsolidatedReportFilterSelector = createSelector(
  vodokhozReportStateSelector,
  (vodokhozeState) => {
    return vodokhozeState.consolidatedReportFilter;
  },
);

export const getMagazineFiltersSelector = createSelector(
  vodokhozReportStateSelector,
  (vodokhozState) => vodokhozState.magazineFilters,
);
