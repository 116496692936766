import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Space } from 'antd';

import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import { useHeaderTitle } from '@features/header/useHeaderTitle';

import { HomeMonitoring } from './mainHome/monitoring/HomeMonitoring';
import { WaterUserMonitoring } from './mainHome/waterUserMonitoring/WaterUserMonitoring';
import { InformationMonitoring } from './secondHome/information/InformationMonitoring';
import { SecondHomeMonitoring } from './secondHome/monitoring/SecondHomeMonitoring';

const HomeComponent = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();
  const { user } = useGetCurrentUser();

  useEffect(() => {
    setHeaderTitle(t('headers.homePage'));
  }, []);

  const isWaterUser = user?.role?.roleName === UserRoles.ROLE_WATER_USER;

  return (
    <Space size="large" direction="vertical" style={{ display: 'flex' }}>
      {isWaterUser ? (
        <Row gutter={24}>
          <Col span={16}>
            <HomeMonitoring />
          </Col>
          <Col span={8}>
            <WaterUserMonitoring />
          </Col>
        </Row>
      ) : (
        <Row gutter={24}>
          <Col span={18}>
            <SecondHomeMonitoring />
          </Col>
          <Col span={6}>
            <InformationMonitoring />
          </Col>
        </Row>
      )}
    </Space>
  );
};

export const Home = memo(HomeComponent);
