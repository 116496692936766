import { useEffect } from 'react';

import { Breadcrumb, Col, Divider, Row, Space } from 'antd';

import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { AddHydraulicSectionModal } from '@features/hydraulicSection/hydraulicSectionEditor/modals/addHydraulicSectionModal';
import { InformationalBlock } from '@features/ui-kit';

import { AddedOutletTable } from './addedOutletTable/AddedOutletTable';
import { HydraulicSectionMenu } from './hydraulicSectionMenu/HydraulicSectionMenu';
import { LinkOutletTable } from './linkOutletTable/LinkOutletTable';
import { HomeOutlined } from '@ant-design/icons';

export const HydraulicSectionEditor = () => {
  const { setHeaderTitle } = useHeaderTitle();

  useEffect(() => {
    setHeaderTitle('Справочник гидроучастков');
  }, []);
  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'Редактор гидроучастков',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <InformationalBlock title="Редактор гидроучастков">
        <Row gutter={8}>
          <Col span={8}>
            <HydraulicSectionMenu />
          </Col>
          <Col span={16}>
            <Space size={8} direction="vertical" style={{ display: 'flex' }}>
              <LinkOutletTable />
              <AddedOutletTable />
            </Space>
          </Col>
        </Row>
      </InformationalBlock>
      <AddHydraulicSectionModal />
    </>
  );
};
