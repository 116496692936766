import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, InputNumber } from 'antd';

import { TableEditorProps } from '@models/table/interfaces/tableColumn';

export const EditNumber: React.FC<TableEditorProps> = React.memo(
  ({ customColumn }) => {
    const { t } = useTranslation();
    const { dataIndex, title } = customColumn;

    return (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message:
              t('customComponents.table.cellEditor.enterValue', {
                value: title,
              }) || '',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
    );
  },
);
