import { DefaultOptionType } from 'antd/es/select';

export const associationStats = {
  false: 'Нет',
  true: 'Да',
};

export const pzTableOptions: DefaultOptionType[] = [
  {
    label: 'Да',
    value: 'YES',
  },
  {
    label: 'Нет',
    value: 'NO',
  },
  {
    label: 'Общее собрание',
    value: 'COMMON',
  },
  {
    label: 'Не работает',
    value: 'NOT_WORKING',
  },
];

export const associationRank: DefaultOptionType[] = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
];

export const associationBooleanOptions: DefaultOptionType[] = [
  { label: 'Да', value: 'true' },
  { label: 'Нет', value: 'false' },
];
