import { SegmentedValue } from 'antd/es/segmented';

export const generateDecadesDaysOptions = (
  v: SegmentedValue,
  options: { label: string; value: number }[],
) => {
  const decade = options.find(
    (option) => option.value === parseInt(v as string, 10),
  );
  if (decade) {
    const match = decade.label.match(/\((\d+)-(\d+)\)/) ?? '';
    const start = parseInt(match[1], 10);
    const end = parseInt(match[2], 10);

    const decadeDays = end - start + 1;
    const pairs = [];
    let value = 1;
    for (let i = 1; i <= decadeDays; i += 2) {
      const pairStart = i;
      const pairEnd = Math.min(i + 1, decadeDays);
      pairs.push({
        label:
          pairStart === pairEnd
            ? `${pairStart} день декады`
            : `${pairStart}-${pairEnd} день декады`,
        value,
      });
      value += 1;
    }
    return pairs;
  }
  return [];
};
