import type { FC } from 'react';
import { memo } from 'react';
import { useDrop } from 'react-dnd';

import styles from './WaterObjectGvsBin.module.scss';

export interface WaterObjectGvsBinPropTypes {
  accept: string[];
  lastDroppedItem?: any;
  onDrop: (item: any) => void;
}

export const WaterObjectGvsBin: FC<WaterObjectGvsBinPropTypes> = memo(
  function WaterObjectGvsBin({ accept, lastDroppedItem, onDrop }) {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = isOver && canDrop;

    let backgroundColor = 'transparent';
    if (isActive) {
      backgroundColor = '#c7d0f9';
    } else if (canDrop) {
      backgroundColor = '#c7d0f9';
    }

    return (
      <div
        ref={drop}
        className={styles.drop}
        style={{ backgroundColor }}
        data-testid="dustbin"
      >
        Прикрепить ГВС (зона прикрепления ГВС)
      </div>
    );
  },
);
