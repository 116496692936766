import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { TableCustomActionProps, TableCustomMethodProps } from '@models/table/interfaces/tableCustomActionProps';

import { RendererFactory } from './renderer';

export interface RenderCellProps<RecordType>
  extends React.HTMLAttributes<HTMLElement> {
  customColumn?: TableColumn;
  record: RecordType;
  actionProps: TableCustomActionProps<RecordType>;
  methodsProps: TableCustomMethodProps<RecordType>[];
}

export function RenderCell<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps,
  methodsProps,
  ...restProps
}: RenderCellProps<RecordType>): React.ReactElement {
  if (!customColumn) return <td {...restProps} />;

  const renderProps = {
    customColumn,
    record,
    actionProps,
    methodsProps,
  };

  const renderedElement =
    customColumn.xtype === CustomTableColumnType.RenderCustom
      ? customColumn.renderCustom!(renderProps)
      : RendererFactory.createRenderer(renderProps);

  return <td {...restProps}>{renderedElement}</td>;
}
