import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, notification, Row, Space } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';

import { HomeOutlined } from '@ant-design/icons';
import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  DashedButton,
  Divider,
  PrimaryButton,
  Segmented,
} from '@features/ui-kit';
import {
  PlanStatusEnum,
  WaterUsePlanDataViewTypes,
} from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { useApprovePlanMutation } from '@store/gisproApi';
import {
  getRuvhStatusSelector,
  getRuvhTablesInfoSelector,
} from '@store/selectors';
import { setRuvhStatus } from '@store/slices';

import { RuvhTablesTabs } from '../shared/ruvhTablesTabs';
import styles from './WaterUsePlanRuvh.module.scss';
import { WaterUsePlanRuvhSummaryTable } from './waterUsePlanRuvhSummaryTables';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

const options: SegmentedLabeledOption[] = [
  {
    value: WaterUsePlanDataViewTypes.Tables,
    label: 'Таблица ввода',
  },
  {
    value: WaterUsePlanDataViewTypes.View,
    label: 'Сводная таблица',
  },
];

export const WaterUsePlanRuvh: React.FC = () => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const [toApprove] = useApprovePlanMutation();
  const dispatch = useDispatch();
  const { setHeaderTitle } = useHeaderTitle();
  const ruvhStatus = useSelector(getRuvhStatusSelector);
  const ruvhTablesInfoSelector = useSelector(getRuvhTablesInfoSelector);
  useEffect(() => {
    setHeaderTitle(
      t('headers.waterUsePlan', { user: 'РУВХ Чуйский район', year: 2024 }),
    );
  }, []);

  const hasPermission = useHasUserPermission(ACCESS.ANNUAL_RECONCILIATION);

  const isCanSendToApprove = ruvhTablesInfoSelector.every(
    (el) => el.status === PlanStatusEnum.Approved,
  );

  const approveToGUVH = () => {
    toApprove()
      .unwrap()
      .then((res) => {
        dispatch(setRuvhStatus('На согласовании ГУВХ'));
        notification.success({
          message: 'Успешно сдан отчет',
        });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось сдать отчет',
        });
      });
  };

  const waterUsePlanRuvhType =
    params.get(SectionQueryKey) || WaterUsePlanDataViewTypes.Tables;

  const onChangeValue = useCallback(
    (value: SegmentedValue) => {
      const updatedSearchParams = {
        ...params,
        [SectionQueryKey]: value as string,
      };
      setSearchParams(updatedSearchParams);
    },

    [params],
  );

  let Component;
  switch (waterUsePlanRuvhType) {
    case WaterUsePlanDataViewTypes.Tables:
      Component = RuvhTablesTabs;
      break;
    case WaterUsePlanDataViewTypes.View:
      Component = WaterUsePlanRuvhSummaryTable;
      break;
    default:
      Component = RuvhTablesTabs;
      break;
  }
  const defaultValue =
    params.get(SectionQueryKey) || WaterUsePlanDataViewTypes.Tables;

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: t('headers.waterUsePlan', {
              user: 'РУВХ Чуйский район',
              year: 2024,
            }),
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row className={styles.controlBar} justify="space-between">
        <Col span={4}>
          <Segmented
            defaultValue={defaultValue}
            options={options}
            onChange={onChangeValue}
          />
        </Col>
        <Col>
          <Space size={8}>
            <DashedButton size="large">
              {isCanSendToApprove ? 'На согласовании ГУВХ' : 'Проект РУВХ'}
            </DashedButton>
            {hasPermission ? (
              <PrimaryButton
                size="large"
                disabled={!isCanSendToApprove}
                onClick={approveToGUVH}
              >
                Отправить на согласование
              </PrimaryButton>
            ) : null}
          </Space>
        </Col>
        <Col span={24}>
          <Divider className={styles.divider} />
          <Component />
        </Col>
      </Row>
    </>
  );
};
