import { IAttachment } from '@models/attachments/interfaces/attachment';

const baseUrl = process.env.REACT_APP_MAIN_BASE_URL;

export const attachFileUrl = (attachment: IAttachment) => {
  return {
    ...attachment,
    fileUrl: `${baseUrl}/api/v1/attachment/download?path=${
      attachment.filePath || attachment.path
    }`,
  };
};
