import { Statuses } from '@enums/table/customTableStatus';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderStatus<RecordType extends JsonRecord>({
  customColumn,
  record,
}: TableRendererProps<RecordType>) {
  const status: keyof typeof Statuses = record[customColumn.dataIndex];
  console.log('status', status);
  const statusClassName = styles[status];

  return (
    <div className={`${styles.status} ${statusClassName}`}>
      {Statuses[status]}
    </div>
  );
}
