import { useParams } from 'react-router-dom';

import MonitoringSchedule from './MonitoringSchedule';

interface IMonitoringSystemLayoutProps {
  type?: string;
}

export const MonitoringSystemLayout: React.FC<
  IMonitoringSystemLayoutProps
> = () => {
  const { section } = useParams();

  return (
    <div>
      <MonitoringSchedule />
    </div>
  );
};
