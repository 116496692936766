import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { CustomCard, InformationalBlock, TableCustom } from '@features/ui-kit';
import {
  TotalWaterSupplyPlan,
  WaterUsePlanRuvhGeneralSummary,
} from '@features/waterUsePlan/shared/ruvhTables';
import { skipToken } from '@reduxjs/toolkit/query';
import { getGuvhFiltersSelector } from '@store/selectors';

import { useWaterUsePlanGuvhViewTableColumns } from './useWaterUsePlanGuvhViewTableColumns';
import styles from './WaterUsePlanGuvhViewTable.module.scss';

export const WaterUsePlanGuvhViewTable = () => {
  const { columns } = useWaterUsePlanGuvhViewTableColumns();
  const guvhFilters = useSelector(getGuvhFiltersSelector);
  if (!guvhFilters) {
    return <div className={styles.empty}>Выберите область</div>;
  }
  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <InformationalBlock title={`Район "${guvhFilters.districtName}"`}>
          <Row gutter={[0, 8]}>
            <Col>
              <CustomCard
                title={
                  <Row className={styles.tableHeader} justify="space-between">
                    <Col>
                      <Typography.Title
                        style={{ marginBottom: 0, color: '#3F4778' }}
                        level={4}
                      >
                        Сводная таблица
                      </Typography.Title>
                    </Col>
                  </Row>
                }
              >
                <WaterUsePlanRuvhGeneralSummary />
              </CustomCard>
            </Col>
            <TotalWaterSupplyPlan />
          </Row>
        </InformationalBlock>
      </Col>
    </Row>
  );
};
