import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOutletIdModal, getShowWaterUsersModal } from '@store/selectors';
import { setOutletIdModal, setShowWaterUsersModal } from '@store/slices';

type ReturnType = {
  isModalShown: boolean;
  setOutletId: (val: number) => void;
  setShowModal: (val: boolean) => void;
  outletIdModal: number | null;
};

export const useWaterUsersModal = (): ReturnType => {
  const isModalShown = useSelector(getShowWaterUsersModal);
  const dispatch = useDispatch();
  const outletIdModal = useSelector(getOutletIdModal);
  const setOutletId = useCallback((val: number) => {
    dispatch(setOutletIdModal(val));
  }, []);

  const setShowModal = useCallback((val: boolean) => {
    dispatch(setShowWaterUsersModal(val));
  }, []);
  return {
    outletIdModal,
    isModalShown,
    setShowModal,
    setOutletId,
  };
};
