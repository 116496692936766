import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { IAttachment } from '@models/attachments/interfaces/attachment';
import { getDocumentSelector } from '@store/selectors/mainConduit';
import { addDocumentAction, removeDocumentAction } from '@store/slices';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

import { DocumentTable } from '@components/documentTable/DocumentTable';
import { UploadDocumentBlock } from '@components/uploadDocumentBlock/UploadDocumentBlock';
import { isEqual, remove } from 'lodash'; // TODO: change lodash to native implementation

interface IDocumentUploaderProps {
  documents: any;
  setDocuments: any;
}
export const DocumentUploader: FC<IDocumentUploaderProps> = ({
  documents,
  setDocuments,
}) => {
  const deleteItem = useCallback((document: IAttachment) => {
    const filterDocuments = remove(
      documents,
      (item) => !isEqual(item, document),
    );
    setDocuments(filterDocuments);
  }, []);

  const onLoad = useCallback(
    (document: IAttachment) => {
      console.log('documents, document', documents, document);
      setDocuments([...documents, document]);
    },
    [documents],
  );

  const onWatch = useCallback(
    (document: IAttachment) => downloadAttachment(document),
    [],
  );

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <DocumentTable
            deleteItem={deleteItem}
            onHandleWatch={onWatch}
            documents={documents}
            showControls={false}
          />
        </Col>
        <Col span={12}>
          <UploadDocumentBlock onLoad={onLoad} />
        </Col>
      </Row>
    </>
  );
};
