import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import { RussianMonthIndexies } from '@enums/month';
import {
  CustomModalV2,
  PrimaryButtonV2,
  TableSheetCustom,
} from '@features/ui-kit-v2';
import { IActsInvoicesForPayments } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useUpdateDecadeActOfMonthMutation } from '@store/gisproApi';
import { toInteger, get } from '@utils/utils';

import { decadeDataMapper } from './decadeDataMapper';
import styles from './EditActModal.module.scss';
import { useEditActModal } from './useEditActModal';
import { useEditActModalColumns } from './useEditActModalColumns';

export const EditActModal: FC = () => {
  const [editingCellKey, setEditingCellKey] = useState<React.Key | null>();
  const { t } = useTranslation();
  const {
    isModalShown,
    setShowModal,
    selectedActsInvoicesForPayment,
    setSelectedActsInvoicesForPayment,
  } = useEditActModal();
  const [save] = useUpdateDecadeActOfMonthMutation();

  const monthIndex: number =
    RussianMonthIndexies[selectedActsInvoicesForPayment?.month || ''];

  const { columns } = useEditActModalColumns({
    monthId: monthIndex,
  });

  const decadeData = useMemo(
    () =>
      selectedActsInvoicesForPayment &&
      decadeDataMapper(selectedActsInvoicesForPayment),
    [selectedActsInvoicesForPayment],
  );

  const getEditingCell = useCallback(
    (record: any, column: TableColumn): boolean => {
      const waterAccountingDecadeId = get(record, [
        'months',
        column.dataIndex,
        'decadeDtos',
        column.parentDataIndex!,
        'waterAccountingDecadeId',
      ]);
      return waterAccountingDecadeId === editingCellKey;
    },
    [editingCellKey],
  );

  const onEditCellClick = useCallback(
    (record: any, column: TableColumn) => {
      const waterAccountingDecadeId = get(record, [
        'months',
        column.dataIndex,
        'decadeDtos',
        column.parentDataIndex!,
        'waterAccountingDecadeId',
      ]);
      if (waterAccountingDecadeId) {
        setEditingCellKey(waterAccountingDecadeId);
      }
    },
    [editingCellKey],
  );

  const onCellSaveClick = useCallback(
    (record: any, column: TableColumn, decadeValue: string) => {
      const waterAccountingDecadeId = get(record, [
        'months',
        column.dataIndex,
        'decadeDtos',
        column.parentDataIndex!,
        'waterAccountingDecadeId',
      ]);
      const decadeNumber = waterAccountingDecadeId.split('-')[1];
      const updatedActInvoicesForPayment = {
        ...(selectedActsInvoicesForPayment as IActsInvoicesForPayments),
        [decadeNumber]: toInteger(decadeValue),
      };
      save(updatedActInvoicesForPayment);
      setEditingCellKey(null);
      setSelectedActsInvoicesForPayment(updatedActInvoicesForPayment);
    },
    [selectedActsInvoicesForPayment],
  );

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <CustomModalV2
      destroyOnClose
      centered
      open={isModalShown}
      onCancel={onClose}
      width={900}
      title="Редактирование акта"
      footer={false}
    >
      <Row>
        <Col span={24}>
          <TableSheetCustom
            dataSource={decadeData as any}
            columns={columns}
            actionProps={{
              editingCellKey,
              onEditCellClick,
              getEditingCell,
              onCellSaveClick,
            }}
            pagination={false}
          />
        </Col>
      </Row>

      <Row gutter={30} justify="end" className={styles.marginTop}>
        <Col>
          <PrimaryButtonV2
            htmlType="submit"
            onClick={onClose}
            title={t('invoicesPayment.modal.editAct.confirm')}
          />
        </Col>
      </Row>
    </CustomModalV2>
  );
};
