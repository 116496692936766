import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Layout, Row } from 'antd';

import { Routes } from '@enums/routes';
import {
  AttachDocumentModal,
  CreateTemplateModal,
} from '@features/manageEvents';
import {
  CreateRegularEventCard,
  HeaderRegularEventCard,
} from '@features/manageEvents/cards/regularEventsCard';
import { AssignExecutorModal } from '@features/manageEvents/modals/AssignExecutorModal';
import { DefaultButtonV2 } from '@features/ui-kit-v2';
import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import {
  useCreateTaskRegularEventBlockMutation,
  useLazyFindRegularEventByIdQuery,
} from '@store/gisproApi';
import { getRegularEventBlockSelector } from '@store/selectors';
import {
  addDocumentToRegularEventAction,
  clearRegularEventBlock,
  setRegularEventBlockAction,
} from '@store/slices';
import { toInteger, isInteger } from '@utils/utils';

import styles from './CreateOperationalCardLayout.module.scss';

export const CreateRegularEventsCardLayout: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [trigger, { data }] = useLazyFindRegularEventByIdQuery();
  const regularEventBlock = useSelector(getRegularEventBlockSelector);
  const [saveRegularEvent] = useCreateTaskRegularEventBlockMutation();

  useEffect(() => {
    const isIdInt = isInteger(toInteger(id));
    if (id && isIdInt) {
      trigger({ id: toInteger(id) });
    }
    return () => {
      dispatch(clearRegularEventBlock());
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      dispatch(setRegularEventBlockAction(data));
    }
  }, [data]);

  const attachDocument = useCallback((file: any) => {
    dispatch(addDocumentToRegularEventAction(file));
  }, []);

  const onCancelClick = () => {
    navigate(Routes.ManageEvents);
  };

  const saveTemplate = (values: any) => {
    const {
      categoryId,
      inspectionDate,
      planDates,
      priority,
      regionId,
      systemObjectId,
      title,
    } = regularEventBlock;
    saveRegularEvent({
      ...values,
      isTemplate: true,
      title,
      categoryId,
      inspectionDate,
      planDates,
      priority,
      regionId,
      systemObjectId,
      systemId: toInteger(regularEventBlock.systemId),
      districtId: toInteger(regularEventBlock.districtId),
      workTypeId: toInteger(regularEventBlock.workTypeId),
      executorId: toInteger(regularEventBlock?.executor?.id),
      attachments: regularEventBlock.attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
    })
      .then(({ response }: any) => {
        navigate(generatePath(Routes.RegularEvent, { id: response.id }));
        dispatch(setRegularEventBlockAction(response));
      })
      .catch((err) => {
        console.log('err is ', err);
      });
  };

  return (
    <>
      <Layout className={styles.operationalCardLayout}>
        <HeaderRegularEventCard />
        <Row gutter={50}>
          <Col span={24}>
            <CreateRegularEventCard />
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <DefaultButtonV2 onClick={onCancelClick} title="Отмена" />
          <DefaultButtonV2 onClick={onCancelClick} title="Назад" />
        </Row>
      </Layout>
      <AttachDocumentModal onLoad={attachDocument} />
      <CreateTemplateModal save={saveTemplate} />
      <AssignExecutorModal type={ManageEventsWorkTypes.REGULAR_EVENTS} />
    </>
  );
};
