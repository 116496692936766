import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';

import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';

import {
  CreateAgroClimaticZoneModal,
  useCreateAgroClimaticZoneModal,
} from '../modals';
import { AgroClimaticZoneTableContainer } from './agroClimaticZoneTableContainer';
import { useAgroClimaticZoneTableFilters } from './useAgroClimaticZoneTableFilters';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';
import { AgroClimaticZoneTableFilters } from './agroClimaticZoneTableFilters';
import { HomeOutlined } from '@ant-design/icons';

export const AgroClimaticZoneTable = () => {
  const { setAgroClimaticZoneTableFilters } = useAgroClimaticZoneTableFilters();
  const [params, setSearchParams] = useSearchParams();
  const { setShow } = useCreateAgroClimaticZoneModal();
  const { setHeaderTitle } = useHeaderTitle();

  const hasPermission = useHasUserPermission(ACCESS.AGROCLIMATIC_ZONE_CREATE);

  const onCreateButtonClick = () => {
    setShow(true);
  };

  useEffect(() => {
    setAgroClimaticZoneTableFilters({
      page: 1,
      size: 10,
    });
    setSearchParams({
      page: '1',
      size: '10',
    });
    setHeaderTitle('Агроклиматические зоны');
  }, []);

  return (
    <Layout>
      <Breadcrumb
        className="breadcrumb"
        items={[
          {
            title: <HomeOutlined />,
          },
          {
            title: 'Агроклиматические зоны',
          },
        ]}
      />
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row align="middle" justify="space-between" style={{ marginTop: '10px' }}>
        <Col span={17}>
          <AgroClimaticZoneTableFilters />
        </Col>
        <Col>
          {hasPermission ? (
            <PrimaryButton onClick={onCreateButtonClick}>
              Создать агроклиматическую зону
            </PrimaryButton>
          ) : null}
        </Col>
      </Row>
      <Divider />
      <InformationalBlock>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Title level={4}>
                  Таблица Агроклиматических зон
                </Typography.Title>
              </Col>
            </Row>
          }
        >
          <AgroClimaticZoneTableContainer />
        </CustomCard>
      </InformationalBlock>
      <CreateAgroClimaticZoneModal />
    </Layout>
  );
};
