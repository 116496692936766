import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { isEmpty, isInteger, pickBy } from '@utils/utils';
import {getMagazineFiltersSelector} from "@store/selectors/vodokhozReport";
import {changeMagazineFilters} from "@store/slices/vodokhozReport";

export interface IVodokhozMagazineFilters {
  year?: number | null | string;
  district?: number | string | null;
  region?: number | string | null;
  name?: string | null;
  status?: string | null;
}

type ReturnType = {
  filters: IVodokhozMagazineFilters;
  setWaterUserFilters: (filters: IVodokhozMagazineFilters) => void;
};

export const useJournalFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getMagazineFiltersSelector);

  const setWaterUserFilters = useCallback(
    (newFilters: IVodokhozMagazineFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        changeMagazineFilters({
          ...values,
        } as IVodokhozMagazineFilters),
      );
    },
    [],
  );

  return { filters, setWaterUserFilters };
};
