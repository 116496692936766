import { Button, Row } from 'antd';

import { CustomTableAction } from '@enums/table';
import { BlueArchiveIcon } from '@icons/BlueArchiveIcon';
import { BlueConfirmIcon } from '@icons/BlueConfirmIcon';
import { BlueDeleteIcon } from '@icons/BlueDeleteIcon';
import { BlueEditIcon } from '@icons/BlueEditIcon';
import { BluePrintIcon } from '@icons/BluePrintIcon';
import { BlueWatchBigIcon } from '@icons/BlueWatchBigIcon';
import { GreyConfirmIcon } from '@icons/GreyConfirmIcon';
import { GreyPrintIcon } from '@icons/GreyPrintIcon';
import { GreyWatchBigIcon } from '@icons/GreyWatchBigIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';
import { BlueWatchIcon } from '@icons/BlueWatchIcon';

export function renderAction<RecordType extends JsonRecord>({
  customColumn,
  actionProps,
  record,
}: TableRendererProps<RecordType>) {
  const { actions = [] } = customColumn;

  return (
    <Row
      className={styles.actionWrapper}
      justify="center"
      align="middle"
      wrap={false}
    >
      {actions.map((action) => {
        if (action === CustomTableAction.Edit) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onHandleEdit?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueEditIcon />}
            />
          );
        }
        if (action === CustomTableAction.EditCheck) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onEditCheck?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueEditIcon />}
            />
          );
        }

        if (action === CustomTableAction.Print) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onPrint?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BluePrintIcon />}
            />
          );
        }
        if (action === CustomTableAction.PrintCheck) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onPrintCheck?.(record)}
              className={styles.actionBtn}
              type="link"
              disabled={actionProps?.getIsDisabled?.(record)}
              icon={
                actionProps?.getIsDisabled?.(record) ? (
                  <GreyPrintIcon />
                ) : (
                  <BluePrintIcon />
                )
              }
            />
          );
        }
        if (action === CustomTableAction.Delete) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.deleteItem?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueDeleteIcon />}
            />
          );
        }
        if (action === CustomTableAction.Archive) {
          return (
            <Row key={action} align="middle">
              <Button
                className={styles.actionBtn}
                onClick={() => actionProps?.onArchiveClick?.(record)}
                type="link"
                icon={<BlueArchiveIcon />}
              />
            </Row>
          );
        }
        if (action === CustomTableAction.UnArchive) {
          return (
            <Row key={action} align="middle">
              <Button
                className={styles.actionBtn}
                onClick={() => actionProps?.onArchiveClick?.(record)}
                type="link"
                icon={<BlueWatchIcon />}
              />
            </Row>
          );
        }
        if (action === CustomTableAction.Watch) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onWatch?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueWatchBigIcon />}
            />
          );
        }
        if (action === CustomTableAction.WatchCheck) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onWatchCheck?.(record)}
              className={styles.actionBtn}
              type="link"
              disabled={actionProps?.getIsDisabled?.(record)}
              icon={
                actionProps?.getIsDisabled?.(record) ? (
                  <GreyWatchBigIcon />
                ) : (
                  <BlueWatchBigIcon />
                )
              }
            />
          );
        }
        if (action === CustomTableAction.Confirm) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onConfirm?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueConfirmIcon />}
            />
          );
        }
        if (action === CustomTableAction.ConfirmCheck) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onConfirmCheck?.(record)}
              className={styles.actionBtn}
              type="link"
              disabled={actionProps?.getIsDisabled?.(record)}
              icon={
                actionProps?.getIsDisabled?.(record) ? (
                  <GreyConfirmIcon />
                ) : (
                  <BlueConfirmIcon />
                )
              }
            />
          );
        }
        return null;
      })}
    </Row>
  );
}
