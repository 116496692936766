import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { useGetWaterAccountingHydropostFactsQuery } from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { useEnterMonitoringValuesEveningModal } from '../../enterMonitoringValuesModals/enterMonitoringValuesEveningModal/useEnterMonitoringValuesEveningModal';
import { useEnterMonitoringValuesMorningModal } from '../../enterMonitoringValuesModals/enterMonitoringValuesMorningModal/useEnterMonitoringValuesMorningModal';
import { useWaterAccountingFilters } from '../../useWaterAccountingFilters';
import { mapBalanceSheetAccountingTableData } from './mapBalanceSheetAccountingTableData';
import { useBalanceSheetAccountingTableColumns } from './useBalanceSheetAccountingTableColumns';
import { Col, Row, Typography } from 'antd';
import { PrintIcon } from '@icons/PrintIcon';
import { PageQueryKey } from '@constants/queryKeys';
import { useReactToPrint } from 'react-to-print';

export const BalanceSheetAccountingTable = () => {
  const printRef = useRef<any>();
  const { filters } = useWaterAccountingFilters('balance');
  const { setShowModal: setShowModalEvening, setSelectedEveningRecord } =
    useEnterMonitoringValuesEveningModal();
  const { setShowModal: setShowModalMorning, setSelectedMorningRecord } =
    useEnterMonitoringValuesMorningModal();
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(params.get(PageQueryKey) || 1);
  const addValue = (toInteger(filters.decade) - 1) * 10;

  const decadeDaysValue = [
    toInteger(filters.decadeDays) * 2 - 1 + addValue,
    toInteger(filters.decadeDays) * 2 + addValue,
  ];

  const {
    data: { content: quentityData = [], totalElements, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetWaterAccountingHydropostFactsQuery({
    page,
    month: filters.month,
    decade: filters.decade as any,
    decadeDays: decadeDaysValue as any,
    year: filters.year,
    filter: JSON.stringify(filters),
  });

  const { columns } = useBalanceSheetAccountingTableColumns({
    month: filters.month,
    decade: filters.decade as any,
    decadeDay: filters.decadeDays as any,
    year: String(filters.year),
  });

  const { data } = useMemo(
    () => mapBalanceSheetAccountingTableData(quentityData),
    [quentityData, filters],
  );

  const onEnterEveningValuesClick = useCallback((record: any, column: any) => {
    setShowModalEvening(true);
    setSelectedEveningRecord({
      ...record,
      columnIndex: column.title,
      day: column.dataIndex,
    });
  }, []);

  const onEnterMorningValuesClick = useCallback((record: any, column: any) => {
    setShowModalMorning(true);
    setSelectedMorningRecord({
      ...record,
      columnIndex: column.title,
      day: column.dataIndex,
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'balanceSheetPrint',
  });

  const onTableChange = (pagination: any) => {
    params.set(PageQueryKey, pagination.current);
    setParams(params);
    setPage(pagination.current);
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>
              Таблица балансового водоучета
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton onClick={handlePrint} icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      <Col span={24} ref={printRef}>
        <TableCustom
          bordered
          size="small"
          columns={columns}
          dataSource={data}
          loading={isLoading || isFetching}
          totalCount={totalElements}
          customPagination={rest}
          onChange={onTableChange}
          actionProps={{
            onEnterEveningValuesClick,
            onEnterMorningValuesClick,
          }}
        />
      </Col>
    </CustomCard>
  );
};
