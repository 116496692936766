import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

export function renderBoolean<RecordType extends JsonRecord>({
                                                              record,
                                                              customColumn,
                                                            }: TableRendererProps<RecordType>) {
  const value = get(record, customColumn.dataIndex) as any;
  return value ? 'Да' : 'Нет';
}
