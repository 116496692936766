import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import styles from './TariffTableContainer.module.scss';
import { ACCESS } from '@enums/permisson';

export const useTariffTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'averageAnnualTariff',
      title: 'Средний тариф годовой сом/м3',
      xtype: CustomTableColumnType.String,
      width: 495,
      className: styles.columns,
    },
    {
      dataIndex: 'createDate',
      title: 'Дата создания',
      xtype: CustomTableColumnType.String,
      width: 495,
      className: styles.columns,
    },
    {
      dataIndex: 'doc',
      title: 'Подтверждающий документ',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => (
        <a onClick={() => downloadAttachment(record.doc)}>{record.doc.name}</a>
      ),
    },
    {
      dataIndex: 'active',
      title: 'Действия',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText, CustomTableAction.Print],
      actionsAccess: [ACCESS.TARIFF_CARD_VIEW, ACCESS.TARIFF_CARD_PRINT],
      width: 93,
    },
    {
      dataIndex: 'isActive',
      title: 'Статус',
      xtype: CustomTableColumnType.TagColumn,
      width: 186,
    },
  ];
  return { columns };
};
