export const getMapMarker = (type: string): string => {
  switch (type) {
    case 'geonode:seleotvod': //Селеотвод
      return '/icons/geonode_seleotvod.svg';
    case 'geonode:distribution_wells': //Распределительные колодцы
      return '/icons/geonode_distribution_wells.svg';
    case 'geonode:plotina': //Плотина
      return '/icons/geonode_plotina.svg';
    case 'geonode:sandgravielovka': //Пескогравиловка
      return '/icons/geonode_sandgravielovka.svg';
    case 'geonode:blocking_structure': //Перегораживающие сооружение
      return '/icons/geonode_blocking_structure.svg';
    case 'geonode:sewage_treatment_plant': //Очистное сооружение
      return '/icons/geonode_sewage_treatment_plant.svg';
    case 'geonode:water_sump': //Отстойник
      return '/icons/geonode_water_sump.svg';
    case 'geonode:collector_connected_water_object': //Коллектор (связанный с водным объектом)
      return '/icons/geonode_collector_connected_water_object.svg';
    case 'geonode:collector': //Коллектор открытый (канализация)
      return '/icons/geonode_collector.svg';
    case 'geonode:waste_collector': //Коллектор
      return '/icons/geonode_waste_collector.svg';
    case 'geonode:livestock_access': //Доступ скота
      return '/icons/geonode_livestock_access.svg';
    case 'geonode:dam': //Дамба
      return '/icons/geonode_dam.svg';
    case 'geonode:monitoring:system_object': //Водозаборные сооружения
      return '/icons/marker34.svg';
    case 'geonode:plunger': //Вантуз
      return '/icons/marker1.svg';
    case 'geonode:inter_section_spillway_collector': // Меж-участковый водосбросный коллектор
      return '/icons/marker2.svg';
    case 'geonode:drainage_wells': //Дренажные колодцы
      return '/icons/marker3.svg';
    case 'geonode:waste_wells': //Сбросные колодцы
      return '/icons/marker4.svg';
    case 'geonode:distribution_node': //Узел распределения
      return '/icons/marker5.svg';
    case 'geonode:pumping_station': //Насосная станция
      return '/icons/marker6.svg';
    // case 'marker7':
    //   return '/icons/marker7.svg'; //Гидрант
    case 'geonode:drainage_pumps': //Дренажные насосы
      return '/icons/marker8.svg';
    case 'geonode:inter_farm_spillway_collector': //Меж-хозяйственный водосбросный коллектор
      return '/icons/marker9.svg';
    // case 'marker12':
    //   return '/icons/marker12.svg'; //Отвод воды в обе стороны ---
    // case 'marker13':
    //   return '/icons/marker13.svg'; //Водопойный пункт ---
    case 'geonode:other_measuring_instruments': //Другие средства измерения (водомер и т.д
      return '/icons/marker14.svg';
    case 'geonode:place_for_draining_water': //Место для сливки
      return '/icons/marker15.svg';
    case 'geonode:meliration_hydropost': //Гидропост
      return '/icons/marker16.svg';
    // case 'marker17':
    //   return '/icons/marker17.svg'; //Фонтаны ---
    // case 'marker18':
    //   return '/icons/marker18.svg'; //Скважины без вышек ---
    // case 'marker19':
    //   return '/icons/marker19.svg'; //Отвод воды в одну сторону ---
    // case 'geonode:duker':
    //   return '/icons/marker20.svg'; //Дюкер
    case 'geonode:water_section': //Водорапределитель
      return '/icons/marker21.svg';
    case 'geonode:household_water_collector': //Хозяйственный водосбросный коллектор
      return '/icons/marker22.svg';
    // case 'marker23':
    //   return '/icons/marker23.svg'; //Мониторинговые точки ---
    case 'geonode:spillway': //Водосброс
      return '/icons/marker24.svg';
    case 'geonode:hydroelectric_power_station': //Гидроэлектростанции (ГЭС)
      return '/icons/marker25.svg';
    case 'geonode:gateway_controller': //Шлюзы
      return '/icons/marker26.svg';
    case 'geonode:main_water_collector': //Главный водосбросный коллектор
      return '/icons/marker27.svg';
    case 'geonode:bridge': //Мост
      return '/icons/marker28.svg';
    case 'geonode:drop': //Перепад
      return '/icons/marker29.svg';
    case 'geonode:main_water_intake_structure': //Головное водозаборное сооружение
      return '/icons/marker34.svg';
    // case 'marker35':
    //   return '/icons/marker35.svg'; //Поворотные точки ---
    // case 'marker36':
    //   return '/icons/marker36.svg'; //Мосты каменные, бетонные, железобетонные ---
    // case 'marker37':
    //   return '/icons/marker37.svg'; //Ворота (затворы) шлюзов ---
    case 'geonode:double_drop':
      return '/icons/marker38.svg'; //Двойник с перепадом
    // case 'marker39':
    //   return '/icons/marker39.svg'; //Купки ---
    case 'geonode:water_support': //Подпорное сооружение
      return '/icons/marker40.svg';
    case 'geonode:fence_node': //Узел забора
      return '/icons/marker41.svg';
    // case 'marker42':
    //   return '/icons/marker42.svg'; //Направление течения ---
    // case 'marker43':
    //   return '/icons/marker43.svg'; //Ворота шлюза под мостом ---
    // case 'marker44':
    //   return '/icons/marker44.svg'; //Дефект ---
    case 'geonode:anti_filtration_facility': //Противофильтрационнное сооружение
      return '/icons/marker45.svg';
    case 'geonode:monitoring:outlets': //Водовыпуск
      return '/icons/marker46.svg';
    case 'geonode:water_hammer_extinguisher': //Гаситель гидроудара
      return '/icons/marker47.svg';
    // case 'marker48':
    //   return '/icons/marker48.svg'; //Ключи,родник ---
    // case 'marker49':
    //   return '/icons/marker49.svg'; //Колонки питьевые ---
    // case 'marker50':
    //   return '/icons/marker50.svg'; //Населенный пункт ---
    // case 'marker51':
    //   return '/icons/marker51.svg'; //Отметки глубин ---
    case 'geonode:vertical_drainage_well':
      return '/icons/marker52.svg'; //Скважины(дренаж) ---
    case 'geonode:meliration_well':
      return '/icons/marker53.svg'; //Скважины ---
    // case 'geonode:drainage_pipes_drains':
    //   return '/icons/marker54.svg'; //Трубы ---
    case 'geonode:electrical_substation': //Электро ---
      return '/icons/marker33.svg';
    case 'geonode:section_water_collector': //Участковый водосбросный коллектор +++
      return '/icons/marker11.svg';
    case 'geonode:meliration_other': //другие
      return '/icons/geonode_meliration_other.svg';
    default:
      return '/icons/markerPin.svg';
  }
};
