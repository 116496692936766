import { IAnnualPlan } from '@models/annualPlan/enums/AnnualPlan';

interface generatedItem {
  [key: string]: string | generatedItem[] | number | undefined;
  dataIndexes?: generatedItem[];
}

export const mapAnnualPlanQuarterlyColumns = (data: Array<IAnnualPlan>) => {
  return data?.reduce<generatedItem[]>((acc, curr) => {
    const array: generatedItem[] = [];
    let item: generatedItem = {};
    (curr.planQuarterDtoList || []).forEach((planQuarterDto) => {
      const quaterName = planQuarterDto.quarterTypeNumber;
      let existedQuarter = acc.find(
        (accChild) =>
          accChild.quarterTypeNumber === planQuarterDto.quarterTypeNumber,
      );
      item = {
        quaterName: String(quaterName),
        quarterTypeNumber: planQuarterDto.quarterTypeNumber,
        dataIndexes: [],
      };
      if (quaterName) {
        (planQuarterDto.threeMonths || []).forEach((month) => {
          const monthName = Object.keys(month.planByMonths)[0];
          if (monthName && item.dataIndexes) {
            item.dataIndexes.push({
              title: monthName,
              dataIndex: monthName,
            });
          } else {
            existedQuarter = {};
          }
        });
        if (item.dataIndexes) {
          item.dataIndexes.push({
            title: 'total',
            dataIndex: `${quaterName}QuarterTotalValue`,
          });
          item.dataIndexes.push({
            title: 'cumulativeTotal',
            dataIndex: `${quaterName}QuarterCumulativeTotalValue`,
          });
        }
      }
      if (!existedQuarter) {
        array.push(item);
      }
    });
    return [...acc, ...array];
  }, []);
};
