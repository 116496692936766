import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GreyWatchBigIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
    >
      <path
        d="M25.0195 8C25.0195 8 21.4195 15 13.0195 15C4.61953 15 1.01953 8 1.01953 8C1.01953 8 4.61953 1 13.0195 1C21.4195 1 25.0195 8 25.0195 8Z"
        stroke="grey"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.0219 11.5C15.0101 11.5 16.6219 9.933 16.6219 8C16.6219 6.067 15.0101 4.5 13.0219 4.5C11.0336 4.5 9.42188 6.067 9.42188 8C9.42188 9.933 11.0336 11.5 13.0219 11.5Z"
        stroke="grey"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
