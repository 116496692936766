import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomCard, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { OpenStreetMap } from '@features/ui-kit/openStreetMap';
import styles from '@features/waterRegistry/masterRegistration/fields/styles.module.scss';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetReferencesByParentIdAndTypeQuery,
  useGetReferencesByParentIdQuery,
} from '@store/gisproApi';
import { useGetCurrentUser } from '@features/authentication';

export const AddressDetails: FC = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const region = Form.useWatch('region', form);
  const district = Form.useWatch('district', form);

  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.Region });
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    region || skipToken,
  );

  const { data: ocmotByParentId = [] } = useGetReferencesByParentIdAndTypeQuery(
    district
      ? {
          parentId: district,
          typeId: EntityTypesEnum.Okmot,
        }
      : skipToken,
  );

  const { data: waterCouncil = [], isLoading: waterCouncilIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.WaterCouncil,
    });

  const onRegionChange = () => {
    form.setFieldsValue({
      district: null,
      okmotId: null,
    });
  };

  const onDistrictChange = () => {
    form.setFieldsValue({
      okmotId: null,
    });
  };

  const changeLocale = (locale: any) => {
    form.setFieldValue('gisAddress', `${locale.lat} ${locale.lng}`);
  };

  return (
    <CustomCard
      title={
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>Данные участка</Typography.Title>
            <Divider className={styles.divider} />
          </Col>
        </Row>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="region"
            label={t(
              'createAssociationWaterRegistry.secondStep.addressDetails.placeholders.region',
            )}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={regionIsLoading}
              options={regions}
              onChange={onRegionChange}
              allowClear
              placeholder="Область"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="district"
            label={t(
              'createAssociationWaterRegistry.secondStep.addressDetails.placeholders.district',
            )}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              placeholder="Район"
              onChange={onDistrictChange}
              disabled={!region}
              options={districtByParentId}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="waterCouncilId"
            label={t(
              'createAssociationWaterRegistry.secondStep.addressDetails.placeholders.waterCouncil',
            )}
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              loading={waterCouncilIsLoading}
              options={waterCouncil}
              allowClear
              placeholder="Водный совет"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="gisAddress"
            label={t(
              'createAssociationWaterRegistry.secondStep.addressDetails.placeholders.gisAddress',
            )}
          >
            <TooltipedInput
              disabled
              size="large"
              placeholder="Область, район, улица"
            />
          </Form.Item>
        </Col>
        <Col className={styles.openStreetMap} span={24}>
          <OpenStreetMap changeLocale={changeLocale} />
        </Col>
      </Row>
    </CustomCard>
  );
};
