import { FC, useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'antd';

import {
  PrimaryButton,
  TooltipedSearch,
  TooltipedSelect,
} from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { IWaterObjectFilters } from '@models/waterObject/interfaces/waterObject';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
} from '@store/gisproApi';
import { extractObjectTypes } from '@utils/objectType';
import { toInteger } from '@utils/utils';

import { useWaterObjectFilters } from '../useWaterObjectFitlers';
import styles from './WaterObjectFilters.module.scss';
import { IObjectGroup } from '@models/objectGroup/interfaces/objectGroup';
import { ObjectGroupCodesEnum } from '@enums/objectGroupCodes';

interface IWaterObjectTableFiltersProps {
  onCreateButtonClick: () => void;
  isCanCreateIrrigationSystemObject: boolean;
  isArchived?: boolean;
}

export const WaterObjectTableFilters: FC<IWaterObjectTableFiltersProps> = ({
  isCanCreateIrrigationSystemObject,
  onCreateButtonClick,
  isArchived = false,
}) => {
  const [filtersState, setFiltersState] = useState<
    Partial<IWaterObjectFilters>
  >({});
  const { filters, setWaterObjectFilters } = useWaterObjectFilters();

  const { data: groups = [], isLoading: objectGroupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      filtersState.groupId
        ? {
            objectGroupId: String(filtersState.groupId),
          }
        : skipToken,
    );

  const objectTypes = useMemo(
    () =>
      filtersState.categoryId
        ? extractObjectTypes(objectCategories, filtersState.categoryId)
        : [],
    [objectCategories, filtersState],
  );

  const objectGroups = useMemo(() => {
    return groups?.filter(
      (item: IObjectGroup) =>
        item.code !== parseInt(ObjectGroupCodesEnum.Reservoir, 10),
    );
  }, [groups]);

  const onFilterButtonClick = () => {
    setWaterObjectFilters(filtersState as IWaterObjectFilters);
  };

  const onFilterChange = (filterKey: keyof IWaterObjectFilters, v: string) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
      page: 1,
    };
    setFiltersState(updatedFilters);
  };

  const onGroupChange = (v: number) => {
    setFiltersState((state) => ({
      ...state,
      groupId: v,
      categoryId: null,
      typeObjectId: null,
      page: 1,
    }));
  };

  const onCategoryChange = (v: number) => {
    setFiltersState((state) => ({
      ...state,
      categoryId: v,
      typeObjectId: null,
      page: 1,
    }));
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      code: null,
      groupId: null,
      name: null,
      typeObjectId: null,
      categoryId: null,
      page: 1,
      size: 10,
    });
    setWaterObjectFilters({
      code: null,
      groupId: null,
      name: null,
      typeObjectId: null,
      categoryId: null,
      page: 1,
      size: 10,
    });
  };

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Row justify="space-between">
      <Row gutter={16} align="middle" style={{ width: '70%' }}>
        <Col span={5}>
          <TooltipedSearch
            value={filtersState.code || ''}
            onChange={({ target }) => onFilterChange('code', target.value)}
            placeholder="Код водного объекта"
            className={styles.tooltiped}
          />
        </Col>
        <Col span={5}>
          <TooltipedSearch
            value={filtersState.name || ''}
            onChange={({ target }) => onFilterChange('name', target.value)}
            placeholder="Наименование объекта"
            className={styles.tooltiped}
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            placeholder="Группа"
            options={objectGroups}
            loading={objectGroupsIsLoading}
            value={
              objectGroups.find(
                (item) => item.id === toInteger(filtersState.groupId),
              )?.label
            }
            onChange={(v: number) => onGroupChange(v)}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            placeholder="Категория"
            showSearch
            options={objectCategories}
            disabled={!filtersState.groupId}
            loading={objectCategoriesIsLoading}
            filterOption={filterOption}
            value={
              objectCategories.find(
                (item) =>
                  item.categoryId === toInteger(filtersState.categoryId),
              )?.label
            }
            onChange={(v: number) => onCategoryChange(v)}
            allowClear
          />
        </Col>
        <Col span={4}>
          <TooltipedSelect
            placeholder="Тип"
            disabled={!filtersState.categoryId}
            options={objectTypes}
            value={filtersState.typeObjectId}
            onChange={(v: string) => onFilterChange('typeObjectId', v)}
            allowClear
          />
        </Col>
        <Col span={2} flex="0 0 100px" className={styles.filterCol}>
          <Row className={styles.filterBtns} wrap={false}>
            <PrimaryButton
              icon={<FilterIcon />}
              onClick={onFilterButtonClick}
            />
            <PrimaryButton
              icon={<ResetFilterIcon />}
              onClick={onResetFilterButtonClick}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        {isCanCreateIrrigationSystemObject && !isArchived && (
          <Col flex="auto">
            <PrimaryButton onClick={onCreateButtonClick} size="large">
              Создать объект
            </PrimaryButton>
          </Col>
        )}
      </Row>
    </Row>
  );
};
