import { roundBigInteger } from '@utils/roundBigInteger';
import { set } from '@utils/utils';

export const reducedArrToObj = (arr: any[], indicatorType: any) => {
  const result = arr.reduce((acc: any, el) => {
    acc[`${el.month}-${el.decade}`] = {
      ...el,
      volumeOfExpenses: roundBigInteger(el.volumeOfExpenses, 1000),
      cumulativeTotal: roundBigInteger(el.cumulativeTotal, 1000),
      id: `${el.id}-${indicatorType}`,
    };
    return acc;
  }, {});
  return result;
};

const getCountForCurrentField = (
  arr: any[],
  currentField: any,
  fieldId: string,
  startIndex: number,
) => {
  let addValue = 0;

  if (fieldId === 'districtId') {
    addValue = startIndex;
  }
  return (
    arr.filter((item) => {
      return item[fieldId] === currentField;
    }).length + addValue
  );
};

export const addRowIndexies = (
  data: any[],
  groupField: string,
  rowIndexField: string,
  indicatorCount = 3,
) => {
  let currentFieldId: any = null;
  let startIndex = 0;
  let endIndex = 0;

  const indicatorTypes =
    indicatorCount === 3
      ? ['volumeOfExpenses', 'volume', 'cumulativeTotal']
      : ['volume', 'cumulativeTotal'];

  return data.reduce((acc: any[], curr: any, index: number, arr: any[]) => {
    if (curr[groupField] !== currentFieldId) {
      currentFieldId = curr[groupField];
      startIndex = index;
      endIndex = getCountForCurrentField(
        arr,
        currentFieldId,
        groupField,
        startIndex,
      );
    }
    const planItemDtos = Array.isArray(curr.planItemDtos)
      ? reducedArrToObj(
          curr.planItemDtos,
          indicatorTypes[index % indicatorCount],
        )
      : curr.planItemDtos;

    const extendedCurr = {
      ...curr,
      planItemDtos,
      [rowIndexField]: {
        start: startIndex,
        end: endIndex,
      },
    };
    if (indicatorCount !== 0) {
      set(
        extendedCurr,
        'indicatorType',
        indicatorTypes[index % indicatorCount],
      );
    }

    acc.push(extendedCurr);
    return acc;
  }, []);
};
