import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { TableCustomV2 } from '@features/ui-kit-v2';
import {
  useFindAllHydropostsQuery,
  useGetAllMeasurementsQuery,
  useGetHydropostsByTypeQuery,
} from '@store/gisproApi';

import { useMonitoringColumns } from './useMonitoringColumns';

interface IFilterOptions {
  sys_id: number;
  start_date: number;
  end_date: number;
  period: number;
}

interface IMainMonitoringTableProps {
  filters: IFilterOptions;
}

export const MainMonitoringTable: React.FC<IMainMonitoringTableProps> = ({
  filters,
}) => {
  const navigate = useNavigate();
  const { columns } = useMonitoringColumns();
  const { data: table = [] } = useFindAllHydropostsQuery();
  const { section } = useParams();
  const { data: type = [] } = useGetHydropostsByTypeQuery();
  const { data: measure = [] } = useGetAllMeasurementsQuery({
    id: Number(section),
    start_date: String(filters.start_date),
    end_date: String(filters.end_date),
  });

  const updateMonitoringTable = table
    .filter((item) => section === String(item.system_id))
    .map((item) => {
      const findItemByHpName = measure.find(
        (arr2Item) => arr2Item.hp_name === item.display_name,
      );
      const findItemByHpType = type.find(
        (typeItem) => typeItem.id === item.hp_type_id,
      );
      const updatedFlow = findItemByHpName?.flow
        ? Number(findItemByHpName.flow).toFixed(3)
        : undefined;

      return {
        ...item,
        flow: updatedFlow,
        hp_type_name: findItemByHpType?.display_name,
      };
    });

  const onWatch = (objectId: any) => {
    navigate(
      generatePath(Routes.DemoMonitoringHydroCard, {
        id: String(objectId.id),
      }),
    );
  };

  return (
    <TableCustomV2
      columns={columns}
      dataSource={updateMonitoringTable}
      actionProps={{ onWatch }}
    />
  );
};
