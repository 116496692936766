import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

export const WaterOutletAgreementRepublic: FC = () => {
  const [params, setParams] = useSearchParams();

  let frame;
  switch (params.get('tab')) {
    case 'diagram':
      frame = (
        <iframe
          title="WaterOutletAgreementRepublic"
          src={getKibanaUrl('Водопользование: Хоз. Договорная водоподача Республика 2')}
          height="3530"
          width="100%"
        />
      );
      break;

    case 'table':
      frame = (
        <iframe
          title="WaterOutletAgreementRepublic"
          src={getKibanaUrl('Водопользование: Хоз. Договорная водоподача Республика')}
          height="1100"
          width="100%"
        />
      );
      break;

    default:
      frame = (
        <iframe
          title="WaterOutletAgreementRepublic"
          src={getKibanaUrl('Водопользование: Хоз. Договорная водоподача Республика 2')}
          height="3530"
          width="100%"
        />
      );
  }
  return <div style={{ height: '100%', width: '100%' }}>{frame}</div>;
};
