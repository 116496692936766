import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import { getIsShowAssignExecutorModalSelector } from '@store/selectors';
import {
  addExecutorToBuildingBlockAction,
  addExecutorToOperationalBlockAction,
  addExecutorToOtherTaskBlockAction,
  addExecutorToRegularEventBlockAction,
  setShowAssignExecutorModalAction,
} from '@store/slices';

type executorInfoType = {
  id: string;
  fio: string;
  organization: string;
};

export const useAssignExecutorModal = (type?: ManageEventsWorkTypes) => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsShowAssignExecutorModalSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(setShowAssignExecutorModalAction(state));
  }, []);

  const setExecutorInfo = useCallback((executorInfo: executorInfoType) => {
    switch (type) {
      case ManageEventsWorkTypes.OPERATIONAL:
        dispatch(addExecutorToOperationalBlockAction(executorInfo));
        break;
      case ManageEventsWorkTypes.BUILDING:
        dispatch(addExecutorToBuildingBlockAction(executorInfo));
        break;
      case ManageEventsWorkTypes.REGULAR_EVENTS:
        dispatch(addExecutorToRegularEventBlockAction(executorInfo));
        break;
      case ManageEventsWorkTypes.OTHER_TASKS:
        dispatch(addExecutorToOtherTaskBlockAction(executorInfo));
        break;
      default:
        break;
    }
  }, []);

  return { isShow, setShow, setExecutorInfo };
};
