import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsPowerlineComponent: FC = () => {
  const { data: wireTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WireType,
  });

  const { data: сonstructionTypes = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.СonstructionType,
    });

  // TODO: add types of trees
  // const { data: typesOfTrees = [] } = useReferenceControllerFindBycodeQuery({
  //   id: EntityTypesEnum.Types,
  // });

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <CustomCard title="Системные показатели">
            <CustomCard title={null} dashed>
              <Row justify="space-between" gutter={16}>
                <Col span={6} style={{ marginTop: 10 }}>
                  <Form.Item name={['status', 'length']}>
                    <PropertyBlock
                      icon={SizesIcon}
                      label="Длина, м"
                      type="input"
                    />
                  </Form.Item>
                </Col>

                <Col span={6} style={{ marginTop: 10 }}>
                  <Form.Item name="direction">
                    <PropertyBlock
                      icon={ConstructionIcon}
                      label="Направление"
                      type="radio"
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item
                    name={['status', 'maximumPower']}
                    label="Макс. мощность"
                  >
                    <TooltipedInput
                      size="large"
                      placeholder="Введите мощность"
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item name={['status', 'voltage']} label="Напряжение">
                    <TooltipedInput
                      size="large"
                      placeholder="Введите напряжение"
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item
                    name={['status', 'wireTypeId']}
                    label="Тип проводов"
                  >
                    <TooltipedSelect
                      size="large"
                      options={wireTypes}
                      placeholder="Выберите тип"
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item
                    name={['status', 'constructionId']}
                    label="Конструкция"
                  >
                    <TooltipedSelect
                      size="large"
                      options={сonstructionTypes}
                      placeholder="Выберите тип"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </CustomCard>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
