import { useDispatch } from 'react-redux';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { waterObjectActions } from '@store/slices';
import { PrimaryButton } from '@features/ui-kit';

export const useWaterSectionTableColumns = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data: sectionTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.SectionType,
  });

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'id',
      width: 40,
    },
    {
      title: 'Код (Наименование)',
      dataIndex: 'name',
      xtype: CustomTableColumnType.String,
      key: 'code',
      width: 290,
    },
    {
      title: 'Вид участка',
      dataIndex: 'typeSectionId',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'type',
      width: 150,
      renderCustom: ({ record }) => (
        <span>
          {sectionTypes.find((section) => section.id === record.typeSectionId)
            ?.title || '-'}
        </span>
      ),
    },
    {
      title: 'Пикетные координаты',
      dataIndex: 'coordinates',
      xtype: CustomTableColumnType.String,
      key: 'picket',
    },
    {
      title: 'Размеры и габариты',
      dataIndex: 'size',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'size',
      renderCustom: ({ record }) => (
        <PrimaryButton
          onClick={() => {
            dispatch(waterObjectActions.setSelectedWaterSectionAction(record));
            dispatch(waterObjectActions.setShowSize(true));
          }}
        >
          Размеры и габариты
        </PrimaryButton>
      ),
    },
    {
      title: 'Технические  характеристики',
      dataIndex: 'techStats',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'techStats',
      renderCustom: ({ record }) => (
        <PrimaryButton
          onClick={() => {
            dispatch(waterObjectActions.setSelectedWaterSectionAction(record));
            dispatch(waterObjectActions.setShowTechStats(true));
          }}
        >
          Технические характеристики
        </PrimaryButton>
      ),
    },
    {
      title: 'Конструктивные характеристики',
      dataIndex: 'constructorStats',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'constructorStats',
      renderCustom: ({ record }) => (
        <PrimaryButton
          onClick={() => {
            dispatch(waterObjectActions.setSelectedWaterSectionAction(record));
            dispatch(waterObjectActions.setShowConstructorStats(true));
          }}
        >
          Конструктивные характеристики
        </PrimaryButton>
      ),
    },
    {
      title: 'Статус Тех.состояния',
      dataIndex: 'technicalStatusId',
      xtype: CustomTableColumnType.TechnicalStatus,
      key: 'techStatus',
      width: 140,
    },
    {
      title: 'Статус Эксп.состояния',
      dataIndex: 'operationalStatusId',
      xtype: CustomTableColumnType.OperationalStatus,
      key: 'expoStatus',
      width: 140,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];

  return { columns };
};
