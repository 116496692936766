import { Tags } from '@constants/tags';
import { basicApi as api } from '../basicApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    accessControllerGetSystemModules: build.query<
      AccessControllerGetSystemModulesApiResponse,
      AccessControllerGetSystemModulesApiArg
    >({
      query: (params) => ({ url: `/api/v1/access/system_modules`, params }),
    }),
    accessControllerGetRoles: build.query<
      AccessControllerGetRolesApiResponse,
      AccessControllerGetRolesApiArg
    >({
      query: () => ({ url: `/api/v1/access/roles` }),
      providesTags: [Tags.AccessList],
    }),
    accessControllerCreateRole: build.mutation<
      AccessControllerCreateRoleApiResponse,
      AccessControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/roles`,
        method: 'POST',
        body: queryArg.accessCreateRoleDto,
      }),
      invalidatesTags: [Tags.AccessList],
    }),
    accessControllerGetRoleById: build.query<
      AccessControllerGetRoleByIdApiResponse,
      AccessControllerGetRoleByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/permissions/role/${queryArg.id}`,
      }),
      providesTags: [Tags.PermissionsList],
    }),
    accessControllerUpdatePermissionsRole: build.mutation<
      AccessControllerUpdatePermissionsRoleApiResponse,
      AccessControllerUpdatePermissionsRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/permissions/role/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.PermissionsList],
    }),
    accessControllerGetUserById: build.query<
      AccessControllerGetUserByIdApiResponse,
      AccessControllerGetUserByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/permissions/users/${queryArg.id}`,
      }),
      providesTags: [Tags.PermissionsList],
    }),
    accessControllerUpdateRole: build.mutation<
      AccessControllerUpdateRoleApiResponse,
      AccessControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/roles/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.accessCreateRoleDto,
      }),
      invalidatesTags: [Tags.AccessList],
    }),
    accessControllerDeleteRole: build.mutation<
      AccessControllerDeleteRoleApiResponse,
      AccessControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/roles/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.AccessList],
    }),
    accessControllerUpdateUser: build.mutation<
      AccessControllerUpdateUserApiResponse,
      AccessControllerUpdateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/access/permissions/user/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [Tags.PermissionsList],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AccessControllerGetSystemModulesApiResponse =
  /** status 200  */ AccessSystemModulesDto[];
export type AccessControllerGetSystemModulesApiArg = {
  role?: string;
};
export type AccessControllerGetRolesApiResponse =
  /** status 200  */ AccessGroupDto[];
export type AccessControllerGetRolesApiArg = void;
export type AccessControllerCreateRoleApiResponse =
  /** status 200  */ AccessRoleDto;
export type AccessControllerCreateRoleApiArg = {
  accessCreateRoleDto: AccessCreateRoleDto;
};
export type AccessControllerGetRoleByIdApiResponse =
  /** status 200  */ AccessPermissionsDto[];
export type AccessControllerGetRoleByIdApiArg = {
  id: number;
};
export type AccessControllerUpdatePermissionsRoleApiResponse =
  /** status 200  */ AccessStatsDto;
export type AccessControllerUpdatePermissionsRoleApiArg = {
  id: number;
  body: AccessDto[];
};
export type AccessControllerGetUserByIdApiResponse =
  /** status 200  */ AccessPermissionsDto[];
export type AccessControllerGetUserByIdApiArg = {
  id: number;
};
export type AccessControllerUpdateRoleApiResponse =
  /** status 200  */ AccessResultDto;
export type AccessControllerUpdateRoleApiArg = {
  id: number;
  accessCreateRoleDto: AccessCreateRoleDto;
};
export type AccessControllerDeleteRoleApiResponse =
  /** status 200  */ AccessResultDto;
export type AccessControllerDeleteRoleApiArg = {
  id: number;
};
export type AccessControllerUpdateUserApiResponse =
  /** status 200  */ AccessStatsDto;
export type AccessControllerUpdateUserApiArg = {
  id: number;
  body: AccessDto[];
};
export type AccessSystemModulesDto = {
  id: number;
  name: string;
  title: string;
  actions: { name: string; title: string; defaultValue: boolean }[];
};
export type AccessGroupDto = {
  id: number;
  systemModules: {
    id?: number;
  };
  privileges: string;
  role: {
    id?: number;
  };
  roleName: string;
  title: string;
  parent: any;
  readonly?: boolean;
};
export type AccessRoleDto = {
  id: number;
  roleName: string;
  title: string;
  readonly: boolean;
  parent?: { id: number };
};
export type AccessCreateRoleDto = {
  roleName: string;
  title: string;
  parent?: number;
};
export type AccessPermissionsDto = {
  moduleId: number;
  actions: AccessDto[];
};
export type AccessStatsDto = {
  added: number;
  updated: number;
  deleted: number;
};
export type AccessDto = {
  module: number;
  action: string;
  access: boolean;
};
export type AccessResultDto = {
  affected: number;
};
export const {
  useAccessControllerGetSystemModulesQuery,
  useAccessControllerGetRolesQuery,
  useAccessControllerCreateRoleMutation,
  useAccessControllerGetRoleByIdQuery,
  useAccessControllerUpdatePermissionsRoleMutation,
  useAccessControllerGetUserByIdQuery,
  useAccessControllerUpdateRoleMutation,
  useAccessControllerDeleteRoleMutation,
  useAccessControllerUpdateUserMutation,
} = injectedRtkApi;
