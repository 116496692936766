import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const Reservoirs: FC = () => {
  return (
    <div style={{ height: 800 }}>
      <iframe
        title="Reservoirs"
        src={getKibanaUrl('80fce650-e02a-11ee-9315-a9a8136220bc')}
        height="2550"
        width="100%"
      />
    </div>
  );
};
