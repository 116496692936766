import { Layout } from 'antd';
import { DecadeApplication } from '@features/decadaApp/DecadeApplication';

export const DecadeApplicationLayout: React.FC = () => {
  return (
    <Layout>
      <DecadeApplication />
    </Layout>
  );
};
