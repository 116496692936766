import { Col, Row, Space, Typography } from 'antd';
import { CustomCard, TooltipedSelect, DocsUploader } from '@features/ui-kit';
import { documentFormats } from '@constants/documentFormats';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { useState } from 'react';

type UploadDocumentBlockProps = {
  onLoad: (attachment: IAttachment) => void;
  irrigationSystemId?: string;
};

export const UploadDocumentBlock: React.FC<UploadDocumentBlockProps> = ({
  onLoad,
  irrigationSystemId,
}) => {
  const [format, setFormat] = useState();
  const [type, setType] = useState();

  const { data: documentTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.DocumentTypes,
  });

  const disabled = !format || !type;
  const docType = documentTypes.find((item) => item.id === type)?.title;
  const docFormat = documentFormats
    .find((item) => item.value === format)
    ?.fullFormat.replace(/\*\./g, '.')
    .replace(/;/g, ',');

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>Документ</Typography.Title>
          </Col>
          <Col>
            <Space size={8}>
              <TooltipedSelect
                popupMatchSelectWidth={false}
                options={documentTypes}
                placeholder="Тип"
                value={type}
                onChange={(v) => setType(v)}
              />
              <TooltipedSelect
                placeholder="Формат"
                value={format}
                onChange={(v) => setFormat(v)}
                options={documentFormats}
              />
            </Space>
          </Col>
        </Row>
      }
    >
      <DocsUploader
        title={null}
        disabled={disabled}
        accept={docFormat}
        docType={docType}
        onLoad={onLoad}
        irrigationSystemId={irrigationSystemId}
      />
    </CustomCard>
  );
};
