import { FC, useEffect, useState } from 'react';
import { Col, Flex, Row } from 'antd';
import { PrimaryButton, YearPicker } from '@features/ui-kit';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import dayjs, { Dayjs } from 'dayjs';
import { useConsolidatedReportFilter } from '../../useConsolidatedReportFilters';
import { useSearchParams } from 'react-router-dom';

export const ConsolidatedReportFilters: FC = () => {
  const [filtersState, setFiltersState] = useState<Partial<any>>({});
  const { filters, setConsolidatedReportFilter } =
    useConsolidatedReportFilter();

  const [params] = useSearchParams();

  const onFilterButtonClick = () => {
    setConsolidatedReportFilter(filtersState as any);
  };

  const onFilterChange = (filterKey: string, v: string) => {
    const updatedFilters = {
      ...filtersState,
      [filterKey]: v,
    };
    setFiltersState(updatedFilters);
  };

  const onResetFilterButtonClick = () => {
    setFiltersState({
      year: null,
    });
    setConsolidatedReportFilter({
      year: null,
    });
  };
  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setConsolidatedReportFilter({ year: newFilters?.year || null });
  }, []);

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  return (
    <Row justify="space-between" gutter={10}>
      <Col>
        <YearPicker
          placeholder="Год"
          allowClear
          value={filtersState.year ? dayjs(filtersState.year) : null}
          disabledDate={(current) => current && current > dayjs().endOf('year')}
          defaultValue={dayjs()}
          onChange={(value: Dayjs | null) =>
            onFilterChange('year', String(value?.year()))
          }
        />
      </Col>
      <Col>
        <Flex gap="small">
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Flex>
      </Col>
    </Row>
  );
};
