import { IAnnualPlan } from '@models/annualPlan/enums/AnnualPlan';

type generatedItem = Record<string, string>;

export const mapAnnualPlanYearlyColumns = (data: Array<IAnnualPlan>) => {
  return data?.reduce<generatedItem[]>((acc, curr) => {
    const array: Array<generatedItem> = [];
    (curr.planQuarterDtoList || []).forEach((planQuarterDto) => {
      (planQuarterDto.threeMonths || []).forEach((month) => {
        const monthName = Object.keys(month.planByMonths)[0];
        if (monthName) {
          const item = {
            monthName,
            firstDataIndex: `${monthName}FirstQuarterValue`,
            secondDataIndex: `${monthName}SecondQuarterValue`,
            thirdDataIndex: `${monthName}ThirdQuarterValue`,
            quarterTotalDataIndex: `${monthName}QuarterTotalValue`,
            totalAreaDataIndex: `${monthName}TotalGrowth`,
          };
          array.push(item);
        }
      });
    });
    return [...acc, ...array];
  }, []);
};
