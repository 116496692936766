import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionReverseIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_73_9032)">
        <path
          d="M1.21163 7.72916C1.18185 7.49028 1.1665 7.24696 1.1665 6.99999C1.1665 3.77833 3.77817 1.16666 6.99984 1.16666C8.82319 1.16666 10.4511 2.00321 11.5208 3.31344M11.5208 3.31344V1.16666M11.5208 3.31344V3.35411L9.33332 3.35416M12.788 6.27082C12.8179 6.5097 12.8332 6.75302 12.8332 6.99999C12.8332 10.2217 10.2215 12.8333 6.99984 12.8333C5.25757 12.8333 3.69372 12.0695 2.62484 10.8584M2.62484 10.8584V10.6458H4.81234M2.62484 10.8584V12.8333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_73_9032">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
