import {
  ISummaryGeneralPlan,
  IWaterUsePlanDataDto,
  PlanItemDto,
} from '@models/waterUsePlan/interfaces/waterUsePlan';
import {
  addRowIndexies,
  reducedArrToObj,
} from '@utils/addRowIndexies/addRowIndexies';
import { round } from 'lodash'; // TODO: change lodash to native implementation

export const waterUseRuvhGeneralSummaryDataMapper = (
  obj?: Record<string, ISummaryGeneralPlan[]>,
) => {
  if (!obj) return undefined;
  const mappedItems = Object.entries(obj).map(([key, value]) => {
    const planItemDtos = value.reduce((acc: any, el) => {
      const { month, decade } = el;
      acc[`${month}-${decade}`] = {
        ...el,
        volume: round(+el.volume, 2),
        volumeOfExpenses: round(+el.volumeOfExpenses, 2),
        cumulativeTotal: round(+el.cumulativeTotal, 2),
      };
      return acc;
    }, {});
    return {
      planItemDtos,
      planType: key,
    };
  });

  const filledArr = [...mappedItems, ...mappedItems, ...mappedItems]
    .filter((el) => el.planType !== 'kpd')
    .sort((a, b) => b.planType.localeCompare(a.planType));

  const withPlanTypeRowIndexies = addRowIndexies(
    filledArr,
    'planType',
    'planTypeRowIndex',
  );

  return withPlanTypeRowIndexies;
};
