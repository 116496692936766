import { IDecadeApplicationFilters } from '@models/decade/interfaces/decade';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DecadeAppState = {
  decadeApplicationFilters: Partial<IDecadeApplicationFilters>;
  isShowDecadeApplicationPrintModal: boolean;
  selectedDecade: number | string;
  selectedDecadeDay: number | string;
};

const initialState: DecadeAppState = {
  decadeApplicationFilters: {
    page: 1,
    size: 10,
  },
  isShowDecadeApplicationPrintModal: false,
  selectedDecade: 1,
  selectedDecadeDay: 1,
};

const decadeAppSlice = createSlice({
  name: 'decadeApp',
  initialState,
  reducers: {
    setDecadeAppFilterAction: (state, action) => {
      return {
        ...state,
        selectedDecade: action.payload,
      };
    },
    setDecadeDayAppFilterAction: (state, action) => {
      return {
        ...state,
        selectedDecadeDay: action.payload,
      };
    },
    changeDecadeApplicationFilters: (
      state,
      action: PayloadAction<IDecadeApplicationFilters>,
    ) => {
      return {
        ...state,
        decadeApplicationFilters: action.payload,
      };
    },
    setShowDecadeApplicationPrintModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowDecadeApplicationPrintModal: action.payload,
      };
    },
  },
});

export const {
  setDecadeAppFilterAction,
  setDecadeDayAppFilterAction,
  changeDecadeApplicationFilters,
  setShowDecadeApplicationPrintModal,
} = decadeAppSlice.actions;

export default decadeAppSlice.reducer;
