import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { isEmpty, isInteger, pickBy } from '@utils/utils';
import { getConsolidatedReportFilterSelector } from '@store/selectors/vodokhozReport';
import { changeConsolidatedReportFilter } from '@store/slices/vodokhozReport';

interface IConsolidatedReportFilter {
  year: any;
}

type ReturnType = {
  filters: Partial<IConsolidatedReportFilter>;
  setConsolidatedReportFilter: (filters: IConsolidatedReportFilter) => void;
};

export const useConsolidatedReportFilter = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getConsolidatedReportFilterSelector);

  const setConsolidatedReportFilter = useCallback(
    (newFilters: IConsolidatedReportFilter) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(
        changeConsolidatedReportFilter({
          ...values,
        } as IConsolidatedReportFilter),
      );
    },
    [],
  );

  return { filters, setConsolidatedReportFilter };
};
