import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreateHydraulicSectionModalShownSelector } from '@store/selectors/hydraulicSection';
import { setShowIsCreateHydraulicSectionModalAction } from '@store/slices';

export const useCreateHydraulicSectionModal = () => {
  const dispatch = useDispatch();
  const isShow = useSelector(getIsCreateHydraulicSectionModalShownSelector);

  const setShow = useCallback((state: boolean) => {
    dispatch(setShowIsCreateHydraulicSectionModalAction(state));
  }, []);

  return { isShow, setShow };
};
