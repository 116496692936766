import { DefaultButton, Divider } from '@features/ui-kit';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import {
  ComputingAnnual,
  CreateAnnualApplication,
  PlannedStructure,
} from '@features/annualApplication';
import { PlannedStructureCreateModal } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/PlannedStructureCreateModal';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { CustomSteps } from '@features/ui-kit/customSteps/CustomSteps';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';

import { AnnualApplicationAgreement } from '@features/annualApplication/annualApplicationAgreement';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const CreateAnnualApplicationLayout: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setHeaderTitle } = useHeaderTitle();

  const segment = useMemo(
    () => searchParams.get(SectionQueryKey),
    [searchParams],
  );
  let Component;

  const steps = [
    {
      value: AnnualApplicationQueryTypes.totalInformation,
      title: 'Общие данные',
    },
    {
      value: AnnualApplicationQueryTypes.plannedStructure,
      title: 'Плановая структура сева',
    },
    {
      value: AnnualApplicationQueryTypes.computing,
      title: 'Расчеты',
    },
    {
      value: AnnualApplicationQueryTypes.agreement,
      title: 'Договор',
    },
  ];

  switch (segment) {
    case AnnualApplicationQueryTypes.totalInformation:
      Component = CreateAnnualApplication;
      break;
    case AnnualApplicationQueryTypes.plannedStructure:
      Component = PlannedStructure;
      break;
    case AnnualApplicationQueryTypes.computing:
      Component = ComputingAnnual;
      break;
    case AnnualApplicationQueryTypes.agreement:
      Component = AnnualApplicationAgreement;
      break;
    default:
      Component = CreateAnnualApplication;
      break;
  }

  useEffect(() => {
    setHeaderTitle(t('annualApplication.table.homeTitle'));
  }, []);

  return (
    <>
      <Layout>
        <Row gutter={[0, 16]}>
          <Row gutter={16}>
            <Col>
              <DefaultButton
                onClick={() => navigate(Routes.AnnualApplicationTable)}
                className="backBtn"
              >
                <LeftOutlined />
                <Typography.Text>Назад</Typography.Text>
              </DefaultButton>
            </Col>
            <Col>
              <Breadcrumb
                className="breadcrumb"
                items={[
                  {
                    title: <HomeOutlined />,
                  },
                  {
                    title: t('annualApplication.table.homeTitle'),
                  },
                ]}
              />
            </Col>
          </Row>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <CustomSteps
          queryParams={{
            queryType: AnnualApplicationQueryTypes,
            queryKey: SectionQueryKey,
          }}
          steps={steps}
        />
        <Divider />
        <Component />
      </Layout>
      <PlannedStructureCreateModal />
    </>
  );
};
