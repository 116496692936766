import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Tag, TagProps } from 'antd';

import style from './TagsWithProgress.module.scss';

export interface TagsType {
  title: string;
  status: TagProps['color'];
  path?: string;
  active?: any;
}

interface TagsWithProgressProps {
  tags: TagsType[];
}

export const TagsWithProgress = ({ tags }: TagsWithProgressProps) => {
  const navigate = useNavigate();
  const onClick = (item: TagsType) => {
    if (item.path) {
      navigate(item.path);
    }
    if (item.active) {
      item.active();
    }
  };
  return (
    <div className={style.tag_blcoks}>
      {tags.map((item) => (
        <Tag
          key={item.title}
          color={item.status}
          className={style.tag}
          bordered={false}
          onClick={() => onClick(item)}
        >
          <span className={style.text}>{item.title}</span>
        </Tag>
      ))}
    </div>
  );
};
