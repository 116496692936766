import { Statuses } from '@enums/table/customTableStatus';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { get } from '@utils/utils';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { EntityTypesEnum } from '@enums/entityTypes';

export function renderOperationalStatus<RecordType extends JsonRecord>({
  customColumn,
  record,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation(undefined, { keyPrefix: 'operationalStatuses' });
  const { data: statuses = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.OperationalStatusEnum,
  });
  const id: number = get(record, customColumn.dataIndex, 0);
  const currentStatus = statuses?.find((status) => status.id === id);

  if (!currentStatus) {
    return <div>-</div>;
  }

  const statusClassName = styles[`operationalStatus_${currentStatus.code}`];
  return (
    <div className={`${styles.technicalStatus} ${statusClassName}`}>
      {t(currentStatus.code)}
    </div>
  );
}
