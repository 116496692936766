import { createSelector } from '@reduxjs/toolkit';
import { WaterRegistryState } from '@store/slices';

type WithWaterRegistryState = {
  waterRegistry: WaterRegistryState;
};

export const waterRegistryStateSelector = (
  state: WithWaterRegistryState,
): WaterRegistryState => state.waterRegistry;

export const getFormLoadingSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isLoading;
  },
);

export const getFormData = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.formData;
  },
);

export const getShowAddBankModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowAddBankModal;
  },
);

export const getSelectedEconomyIndexSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.selectedEconomyIndex;
  },
);

export const getShowWaterUserDataModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowWaterUserDataModal;
  },
);

export const getShowWaterUserModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowWaterUserModal;
  },
);

export const getShowActionSelectSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowActionSelectModal;
  },
);

export const getWaterUserIdSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.waterId;
  },
);

export const getOrganizationIdSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.organizationId;
  },
);

export const getShowAddBranchModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowAddBranchModal;
  },
);

export const getCreatingSiteAssociationModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.creatingSiteAssociationModal;
  },
);

export const getInitialValuesSiteAssociationModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.initialValuesSiteAssociationModal;
  },
);

export const getCreatingSiteModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.creatingSiteModal;
  },
);

export const getInitialValuesSiteModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.initialValuesSiteModal;
  },
);

export const getMainInfoModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.mainInfoModal;
  },
);

export const getLegalEntityMainInfoModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.legalEntityMainInfoModal;
  },
);

export const getContactsModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.contactsModal;
  },
);

export const getBankDetailsModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.backDetails;
  },
);

export const getSelectedBankSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.selectedBankId;
  },
);

export const getTableCardPathSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.tableCardPath;
  },
);

export const getEmployeesSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.employees;
  },
);

export const getEconomiesSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.economies;
  },
);

export const getShowAddEmployeeModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowAddEmployeeModal;
  },
);

export const getShowEditEmployeeModalSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowEditEmployeeModal;
  },
);

export const getCurrentEmployeeTypeSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.currentEmployeeType;
  },
);

export const getEditEmployeeValueSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.activeEmployeesId;
  },
);

export const getWaterRegistryFiltersSelector = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.waterRegistryFilters;
  },
);

export const getShowIndividualPermissionsModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowIndividualPermissionsModal;
  },
);
export const getShowLegalPermissionsModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowLegalPermissionsModal;
  },
);
export const getShowAssociationPermissionsModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowAssociationPermissionsModal;
  },
);

export const getShowWaterUsersModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowWaterUsersModal;
  },
);

export const getOutletIdModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.outletIdModal;
  },
);

export const getShowAssociationStatsModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowAssociationStatsModal;
  },
);

export const getShowConfirmArchiveWaterUserModal = createSelector(
  waterRegistryStateSelector,
  (waterRegistryState) => {
    return waterRegistryState.isShowConfirmArchiveModal;
  },
);
