import { useTranslation } from 'react-i18next';

import { Col, Tag, Typography } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';

import styles from './styles.module.scss';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';

export function renderWaterRegistryControl<RecordType extends JsonRecord>({
  customColumn,
  actionProps,
  record,
}: TableRendererProps<RecordType>) {
  const { actionsAccess } = customColumn;

  const { t } = useTranslation();
  let tags = [
    {
      title: 'Карточка',
      action: actionProps?.showWaterUserCard,
      status: 'rgba(75, 96, 216, 1)',
    },
  ];

  if (!record.isArchived) {
    tags = [
      ...tags,
      {
        title: 'Годовая заявка',
        action: actionProps?.showAnnualApplication,
        status: 'rgba(51, 167, 233, 1)',
      },
      {
        title: 'ГИС',
        action: actionProps?.showGis,
        status: 'rgba(99, 221, 56, 1)',
      },
      {
        title: 'Выдача доступа',
        action: actionProps?.showPermissions,
        status: 'rgba(231, 189, 40, 1)',
      },
    ];
  }

  if (
    (record.type === WaterUserEnum.ASSOCIATION ||
      record?.legalData?.typeOrganization === WaterUserEnum.ASSOCIATION) &&
    !record.isArchived
  ) {
    tags = [
      ...tags,
      {
        title: 'Осн. показатели ассоц.',
        action: actionProps?.showAssociationStats,
        status: 'rgba(236, 87, 141, 1)',
      },
    ];
  }

  if (!actionsAccess?.length) {
    return (
      <div className={styles.tags}>
        {tags.map((item) => (
          <Col key={item.title}>
            <Tag
              key={item.title}
              color={item.status}
              bordered={false}
              className={styles.tag}
              onClick={() => item.action?.(record)}
            >
              <Typography.Text style={{ margin: '8px 0' }}>
                {item.title}
              </Typography.Text>
            </Tag>
          </Col>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.tag}>
      {tags.map((item, idx) => {
        return useHasUserPermission(actionsAccess[idx]) ? (
          <Col key={item.title}>
            <Tag
              key={item.title}
              color={item.status}
              bordered={false}
              className={styles.tag}
              onClick={() => item.action?.(record)}
            >
              <Typography.Text style={{ margin: '8px 0' }}>
                {item.title}
              </Typography.Text>
            </Tag>
          </Col>
        ) : null;
      })}
    </div>
  );
}
