import { TooltipedInput } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindAllByOrganizationIdQuery } from '@store/gisproApi';
import { mapPhoneNumberToLong } from '@utils/phone';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';
import { Row, Col, Form, FormInstance } from 'antd';
import { toInteger } from '@utils/utils';
import { useParams } from 'react-router-dom';
import { Employee } from '../totalInfoModal.models';

type ContactsInfoProps = {
  form: FormInstance;
  onCancel(): void;
  data: any;
};

export const ContactsInfo = ({ form, onCancel, data }: ContactsInfoProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: queryData } = useFindAllByOrganizationIdQuery(
    id
      ? {
        id: toInteger(id),
      }
      : skipToken,
  );

  const findDirectorData = () => {
    if (queryData?.legalData?.email === "" && queryData?.legalData?.phone === "") {
      const director = queryData.employees.find(
        (employee: Employee) => employee.positionEmployee.title === "Директор"
      );
      console.log(director, 'director');
      if (director) {
        return {
          email: director.email,
          phone: director.phone
        };
      }
    }
    return null;
  };

  const directorData = findDirectorData();

  const initialValues = {
    phone: data?.legalData?.phone || (directorData && directorData.phone) || '-',
    email: data?.legalData?.email || (directorData && directorData.email) || '-',
  };
  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onCancel}
      initialValues={initialValues}
    >
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            name="phone"
            label="Номер телефона"
            rules={[{ required: true }, phoneNumberValidator]}
            normalize={mapPhoneNumberToLong}
          >
            <TooltipedInput placeholder="Введите Номер телефона" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            name="email"
            label="Почта"
            rules={[{ required: true, type: 'email' }]}
          >
            <TooltipedInput placeholder="Введите Email" size="large" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
