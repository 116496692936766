import React, { forwardRef } from 'react';

import { Input, Tooltip, TooltipProps } from 'antd';
import { InputProps } from 'antd/es/input';

import styles from './Input.module.scss';

type TooltipedInputPropsType = InputProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
  ref?: any;
};

export const TooltipedInputV2 = forwardRef<
  HTMLInputElement,
  TooltipedInputPropsType
>((props, ref) => {
  return (
    <Tooltip title={props.tooltipTitle}>
      <Input ref={ref} {...props} className={styles.input} />
    </Tooltip>
  );
});
