import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableEditorProps } from '@models/table/interfaces/tableColumn';

import { EditDate } from './EditDate';
import { EditDecadeCell } from './EditDecadeCell';
import { EditNumber } from './EditNumber';
import { EditSelect } from './EditSelect';
import { EditSelectTag } from './EditSelectTag';
import { EditString } from './EditString';

export class EditorFactory {
  static createEditor<RecordType extends JsonRecord>({
    customColumn,
    record,
    editValidator,
    actionProps,
  }: TableEditorProps): React.ReactNode {
    const { xtype } = customColumn;
    let Editor: React.FC<TableEditorProps> = EditString;

    switch (xtype) {
      case CustomTableColumnType.String:
        Editor = EditString;
        break;
      case CustomTableColumnType.NumberColumn:
        Editor = EditNumber;
        break;
      case CustomTableColumnType.DateColumn:
        Editor = EditDate;
        break;
      case CustomTableColumnType.SelectColumn:
        Editor = EditSelect;
        break;
      case CustomTableColumnType.SelectColumntag:
        Editor = EditSelectTag;
        break;
      case CustomTableColumnType.DecadeCell:
        Editor = EditDecadeCell;
        break;
      default:
        break;
    }

    return (
      <Editor
        customColumn={customColumn}
        record={record}
        editValidator={editValidator}
        actionProps={actionProps}
      />
    );
  }
}
