import { useCallback } from 'react';
import { Chart } from 'react-google-charts';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Space, Typography } from 'antd';

import { Routes } from '@enums/routes';
import {
  CustomCard,
  CustomPieChart,
  DefaultButton,
  InformationalBlock,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  useBalanceAccountingIrrigationQuery,
  useGetAllStatusWaterUsePlanQuery,
} from '@store/gisproApi';

import styles from './WaterSupplyCard.module.scss';

export const WaterSupplyCard: React.FC = () => {
  const navigate = useNavigate();
  const { data: balanceAccounting } = useBalanceAccountingIrrigationQuery({
    id: 18,
  });

  const clickGoto = useCallback(() => {
    navigate(`${Routes.WaterAccounting}?tab=waterLogAccounting`);
  }, []);
  const clickGotoMonitoring = useCallback(() => {
    navigate(`${Routes.DemoMonitoring}`);
  }, []);

  const data = [
    [{ type: 'date', label: 'Day' }, ' ', ' '],
    [new Date(2014, 0), -0.5, 5.7],
    [new Date(2014, 1), 0.4, 8.7],
    [new Date(2014, 2), 0.5, 12],
    [new Date(2014, 3), 2.9, 15.3],
    [new Date(2014, 4), 6.3, 18.6],
    [new Date(2014, 5), 9, 20.9],
    [new Date(2014, 6), 10.6, 19.8],
    [new Date(2014, 7), 10.3, 16.6],
    [new Date(2014, 8), 7.4, 13.3],
    [new Date(2014, 9), 4.4, 9.9],
    [new Date(2014, 10), 1.1, 6.6],
    [new Date(2014, 11), -0.2, 4.5],
  ];
  console.log(balanceAccounting);
  const options = {
    chart: {
      title: ' ',
      subtitle: ' ',
    },
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={20}>
      <CustomCard
        title={
          <Row align="middle" gutter={16}>
            <Col flex="1 1 auto" className={styles.cardTitle}>
              <Typography.Title
                style={{ marginBottom: 0, color: '#3F4778' }}
                level={4}
              >
                Балансовый учет (датчики)
              </Typography.Title>
            </Col>
            <Col>
              <Row justify="end" gutter={8}>
                <Col>
                  <DefaultButton onClick={clickGotoMonitoring}>Перейти</DefaultButton>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <iframe
              title="Балансовый учёт"
              src="https://demo.wiskg.tech/app/dashboards#/view/b3511630-7649-11ee-9996-65749f62dc8a?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))&show-time-filter=true"
              height="550"
              width="100%"
            />
          </Col>
        </Row>
      </CustomCard>
      <CustomCard
        title={
          <Row align="middle" gutter={16}>
            <Col flex="1 1 auto" className={styles.cardTitle}>
              <Typography.Title
                style={{ marginBottom: 0, color: '#3F4778' }}
                level={4}
              >
                Балансовый учет (Специалисты)
              </Typography.Title>
            </Col>
            <Col>
              <Row justify="end" gutter={8}>
                <Col>
                  <DefaultButton onClick={clickGoto}>Перейти</DefaultButton>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <iframe
              title="Балансовый учёт"
              src="https://demo.wiskg.tech/app/dashboards#/view/ccf91250-eaa6-11ee-b48d-157c259f5a27?embed=true&_g=()&show-time-filter=true"
              height="740"
              width="100%"
            />
          </Col>
        </Row>
      </CustomCard>
    </Space>
  );
};
