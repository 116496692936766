import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsCreateSourceModalOpenSelector,
  getSelectedBassinIdSelector,
  getSelectedRegionIdSelector,
} from '@store/selectors/source';
import {
  setSelectedBassinIdAction,
  setSelectedRegionIdAction,
  setShowCreateSourceModalAction,
} from '@store/slices/source';

type ReturnType = {
  isModalShown: boolean;
  selectedBassinId: number | null;
  selectedRegionId: number | null;
  setShowModal: (value: boolean) => void;
  setSelectedRegionId: (value: number) => void;
  setSelectedBassinId: (value: number) => void;
};

export const useCreateSourceModal = (): ReturnType => {
  const dispatch = useDispatch();

  const selectedBassinId = useSelector(getSelectedBassinIdSelector);
  const selectedRegionId = useSelector(getSelectedRegionIdSelector);
  const isModalShown = useSelector(getIsCreateSourceModalOpenSelector);

  const setShowModal = useCallback((value: boolean) => {
    dispatch(setShowCreateSourceModalAction(value));
  }, []);

  const setSelectedRegionId = useCallback((value: number) => {
    dispatch(setSelectedRegionIdAction(value));
  }, []);

  const setSelectedBassinId = useCallback((value: number) => {
    dispatch(setSelectedBassinIdAction(value));
  }, []);

  return {
    isModalShown,
    setShowModal,
    selectedBassinId,
    selectedRegionId,
    setSelectedBassinId,
    setSelectedRegionId,
  };
};
