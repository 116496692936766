import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';

import { Col, Row } from 'antd/lib';

import { useGetObjectCategoriesQuery } from '@store/gisproApi';
import { SystemObject } from '@models/systemObject/systemObject';
import { DirectionRadio, TooltipedInput } from '@features/ui-kit';
import { Typography, Divider } from 'antd';

export interface WaterObjectAccordionItemPropTypes extends SystemObject {
  type: string;
  isDropped: boolean;
}

export const WaterObjectAccordionItem: FC<WaterObjectAccordionItemPropTypes> = function WaterObjectAccordionItem({
    objectId,
    objectName,
    type,
    coordinates,
    direction,
    objectCategoryId,
    isDropped,
    ...rest
}) {
  const [directionValue, setDirectionValue] = useState('');

  const { data: objectCategories = [] } = useGetObjectCategoriesQuery();

  const currentObjectCategory = useMemo(
    () =>
      objectCategories.find((item) => item.categoryId === objectCategoryId),
    [objectCategoryId],
  );

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: {
        name: objectName,
        coordinates,
        direction: directionValue,
        systemObjectId: objectId,
      },
      canDrag: () => !!coordinates && !!directionValue,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [objectName, type, coordinates, directionValue],
  );

  useEffect(() => {
    if (direction) {
      setDirectionValue(direction);
    }
  }, [direction]);

  if (isDropped) {
    return <div />;
  }

  return (
    <div ref={drag} style={{ opacity, marginBottom: 10, cursor: 'pointer' }} data-testid="box">
      <Row gutter={15}>
        <Col span={6}>
          <Typography.Text>Пикетная координата</Typography.Text>
          <TooltipedInput size="large" disabled value={coordinates} />
        </Col>
        <Col span={6}>
          <Typography.Text>Наименование объекта</Typography.Text>
          <TooltipedInput size="large" disabled value={objectName} />
        </Col>
        <Col span={6}>
          <Typography.Text>Категория</Typography.Text>
          <TooltipedInput
            disabled
            size="large"
            value={currentObjectCategory?.groupTitle}
          />
        </Col>
        <Col span={6}>
          <Typography.Text>Направление</Typography.Text>
          <DirectionRadio
            value={directionValue}
            onChange={(e: any) => setDirectionValue(e.target.value)}
          />
        </Col>
      </Row>
      <Divider orientationMargin={0} style={{ marginTop: 8, marginBottom: 0 }} />
    </div>
  );
};
