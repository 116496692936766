import { FC } from 'react';

import {
  CreateStepper,
  MainConduitCreateStepperActionSelectModal,
} from '@features/mainConduit';

export const CreateMainConduitLayout: FC = () => {
  return (
    <>
      <CreateStepper />
      <MainConduitCreateStepperActionSelectModal />
    </>
  );
};
