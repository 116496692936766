export enum SpprMenuKey {
  map = 'map',
  commonSystemUnit = 'commonSystemUnit',
  waterOutlet = 'waterOutlet',
  waterOutletMainData = 'waterOutletMainData',
  waterOutletAgreementRepublic = 'waterOutletAgreementRepublic',
  waterOutletAgreementSystem = 'waterOutletAgreementSystem',
  waterOutletSovingFact = 'waterOutletSovingFact',
  waterOutletSovingPlan = 'waterOutletSovingPlan',

  irrigationFund = 'irrigationFund',
  irrigationFundMainData = 'irrigationFundMainData',
  irrigationFundOperationalBlock = 'irrigationFundOperationalBlock',
  irrigationFundOperationalBlockMonitoring = 'irrigationFundOperationalBlockMonitoring',
  irrigationFundConstruction = 'irrigationFundConstruction',
  irrigationFundConstructionMonitoring = 'irrigationFundConstructionMonitoring',

  qualitativeIndicators = 'qualitativeIndicators',
  qualitativeIndicatorsGroundwaterMineralization = 'qualitativeIndicatorsGroundwaterMineralization',
  qualitativeIndicatorsGroundwaterDepth = 'qualitativeIndicatorsGroundwaterDepth',
  qualitativeIndicatorsReclamationState = 'qualitativeIndicatorsReclamationState',

  reservoirs = 'reservoirs',
  tariffMonitoring = 'tariffMonitoring',
  balanceSheetAccounting = 'balanceSheetAccounting',
  balanceSheetAccountingSystem = 'balanceSheetAccountingSystem',
}
