import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Space } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { MonthEnums } from '@enums/month';
import { Divider, PrimaryButton, Segmented } from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import {
  IDecadeApplicationFilters,
  IDecadesOptions,
} from '@models/decade/interfaces/decade';
import {
  getSelectedDecade,
  getSelectedDecadeDay,
} from '@store/selectors/decadeApp';
import {
  changeDecadeApplicationFilters,
  setDecadeAppFilterAction,
  setDecadeDayAppFilterAction,
} from '@store/slices';
import { generateDecadesDaysOptions } from '@utils/generateDecadesDaysOptions';
import { generateDecadesOptions } from '@utils/generateDecadesOptions';
import { getDaysInMonth } from '@utils/getDaysInMonth';
import dayjs, { Dayjs } from 'dayjs';

import styles from './DecadeApplicationControls.module.scss';

export const DecadeApplicationControls = () => {
  const [filterState, setFilterState] = useState<IDecadeApplicationFilters>({
    month: MonthEnums.January,
    year: dayjs().format(),
    waterUser: null,
  });

  const [decadesOptions, setDecadesOptions] = useState<IDecadesOptions[]>([]);
  const [decadeDaysOptions, setDecadeDaysOptions] = useState<IDecadesOptions[]>(
    [],
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedDecade = useSelector(getSelectedDecade);
  const selectedDecadeDay = useSelector(getSelectedDecadeDay);

  useEffect(() => {
    if (filterState.month && filterState.year) {
      const days = getDaysInMonth(filterState.month, dayjs(filterState.year));
      const decades = generateDecadesOptions(days);
      setDecadesOptions(decades);
    }
  }, [filterState.month, filterState.year]);

  useEffect(() => {
    const decadesDays = generateDecadesDaysOptions(
      selectedDecade,
      decadesOptions,
    );
    setDecadeDaysOptions(decadesDays);
  }, [selectedDecade]);

  const onFilterButtonClickSegment = () => {
    dispatch(changeDecadeApplicationFilters(filterState));
  };

  useEffect(() => {
    onFilterButtonClickSegment();
  }, [filterState.month]);

  const changeMonthHandle = (v: SegmentedValue) => {
    setFilterState((state) => ({
      ...state,
      month: v as MonthEnums,
    }));
  };

  const monthOptions = useMemo(
    () =>
      Object.values(MonthEnums).map((month) => ({
        label: t(`months.${month}`),
        value: month,
      })),
    [MonthEnums],
  );

  const changeDecadeHandle = (v: SegmentedValue) => {
    dispatch(setDecadeAppFilterAction(v));
  };

  const changeDecadeDayHandle = (v: SegmentedValue) => {
    dispatch(setDecadeDayAppFilterAction(v));
  };

  const onYearHandle = (v: Dayjs | null) => {
    setFilterState((state) => ({
      ...state,
      year: v?.toString(),
    }));
  };

  const onFilterButtonClick = () => {
    dispatch(changeDecadeApplicationFilters(filterState));
  };

  const onResetFilterButtonClick = () => {
    setFilterState((state) => ({
      ...state,
      year: dayjs().format(),
      month: MonthEnums.January,
    }));
    dispatch(
      changeDecadeApplicationFilters({
        ...filterState,
        waterUser: null,
        year: null,
        page: 1,
        size: 10,
      }),
    );
  };

  return (
    <Row gutter={[0, 8]} className={styles.controls}>
      <Col span={24}>
        <Space>
          <YearPicker
            allowClear
            onChange={onYearHandle}
            defaultValue={dayjs()}
            disabledDate={(current) =>
              current && current > dayjs().endOf('year')
            }
            value={dayjs(filterState.year)}
          />
          <PrimaryButton icon={<FilterIcon />} onClick={onFilterButtonClick} />
          <PrimaryButton
            icon={<ResetFilterIcon />}
            onClick={onResetFilterButtonClick}
          />
        </Space>
      </Col>
      <Col>
        <Divider />
      </Col>
      <Col span={24}>
        <Segmented
          onChange={changeMonthHandle}
          options={monthOptions}
          defaultValue={MonthEnums.January}
          value={filterState.month?.toString()}
        />
      </Col>
      {/* <Col span={24}>
        <Segmented
          options={decadesOptions}
          onChange={changeDecadeHandle}
          defaultValue={selectedDecade}
        />
      </Col>
      <Col span={24}>
        <Segmented
          options={decadeDaysOptions}
          onChange={changeDecadeDayHandle}
          defaultValue={selectedDecadeDay}
        />
      </Col> */}
    </Row>
  );
};
