import { FormInstance } from 'antd';

interface IValidateTotalWeightProps {
  _: any;
  value: any;
  form: FormInstance<any>;
}

export const validateTotalWeight = ({
  _,
  value,
  form,
}: IValidateTotalWeightProps) => {
  const weights =
    form
      .getFieldValue('implementationTerms')
      ?.map((item: any) => item.weight) || [];
  const totalWeight = weights?.reduce(
    (acc: number, cur: number) => +acc + (+cur || 0),
    0,
  );
  if (totalWeight !== 100) {
    return Promise.reject(new Error('Сумма всех весов должна быть равна 100'));
  }
  return Promise.resolve();
};
