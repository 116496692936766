import { CloseOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function renderPriceEditableCell<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const { dataIndex } = customColumn;

  const value = record[dataIndex];

  return String(value) ? (
    <div className={`${styles.priceCell}`}>{value}</div>
  ) : (
    <div className={styles.xMarkWrapper}>
      <CloseOutlined className={styles.xmark} />
    </div>
  );
}
