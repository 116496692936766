import { Typography } from 'antd';

import { SectionTitle } from '../sectionTitle';
import styles from './InformationalBlock.module.scss';

type InformationalBlockPropTypes = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
};

export const InformationalBlock: React.FC<InformationalBlockPropTypes> = ({
  title = '',
  children,
}) => {
  return (
    <div className={styles.mainBlock}>
      {title && <SectionTitle title={title} />}
      <div className={styles.content}>{children}</div>
    </div>
  );
};
