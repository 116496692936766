import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const CloudUpload: React.FC<IconProps> = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 20H7C5.48 20 4.18333 19.4767 3.11 18.43C2.03667 17.3767 1.5 16.0933 1.5 14.58C1.5 13.28 1.89 12.12 2.67 11.1C3.45667 10.08 4.48333 9.43 5.75 9.15C6.17 7.61667 7.00333 6.37667 8.25 5.43C9.50333 4.47667 10.92 4 12.5 4C14.4533 4 16.1067 4.68 17.46 6.04C18.82 7.39333 19.5 9.04667 19.5 11C20.6533 11.1333 21.6067 11.6333 22.36 12.5C23.12 13.3533 23.5 14.3533 23.5 15.5C23.5 16.7533 23.0633 17.8167 22.19 18.69C21.3167 19.5633 20.2533 20 19 20H13.5V12.85L15.1 14.4L16.5 13L12.5 9L8.5 13L9.9 14.4L11.5 12.85V20Z"
        fill="#4B60D8"
        fillOpacity="0.5"
      />
    </svg>
  );
};
