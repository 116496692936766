import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { Routes } from '@enums/routes';
import { CreateButton, SectionTitle } from '@features/ui-kit-v2';
import { useCreateMonitoringPointsModal } from '@features/waterQuality/tables/monitoringPointsTable/createMonitoringPointsModal/useCreateMonitoringPointsModal';

import { CreateMonitoringPointsModal } from './createMonitoringPointsModal';
import { MonitoringPointsTableContainer } from './monitoringPointsTableContainer';
import { DisctionaryGtsTableFilters } from './monitoringPointsTableTableFilters';
import { useEffect } from 'react';
import { isEmpty } from '@utils/utils';
import { useMonitoringPointsTableFilters } from './useMonitoringPointsTableFilters';
import { useGetCurrentUser } from '@features/authentication';

export const MonitoringPointsTable = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { setShowModal } = useCreateMonitoringPointsModal();
  const { setPointsTableFilters } = useMonitoringPointsTableFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const onCreateZone = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    setPointsTableFilters(
      isEmpty(newFilters)
        ? ({
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as any)
        : ({
            ...newFilters,
            regionId: user?.owner?.region?.id.toString() || null,
            districtId: user?.owner?.district?.id.toString() || null,
          } as unknown as any),
    );
  }, []);

  return (
    <>
      <DisctionaryGtsTableFilters />
      <Divider />
      <InformationalBlock>
        <CustomCard
          title="Точки мониторинга"
          bordered={false}
          extra={
            <PrimaryButton onClick={onCreateZone}>Создать точку</PrimaryButton>
          }
        >
          <MonitoringPointsTableContainer />
        </CustomCard>
      </InformationalBlock>

      <CreateMonitoringPointsModal />
    </>
  );
};
