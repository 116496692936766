import { IAttachment } from '@models/attachments/interfaces/attachment';
import { api } from '../basicApi';
export const addTagTypes = ['admin.geoPortalArchive'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      geoPortalArchiveList: build.query<
        GeoPortalArchiveListApiResponse,
        GeoPortalArchiveListApiArg
      >({
        query: (params) => ({
          url: `/api/v1/geo-archive`,
          params,
        }),
        providesTags: ['admin.geoPortalArchive'],
      }),
      geoPortalArchiveCreate: build.mutation<
        GeoPortalArchiveCreateApiResponse,
        GeoPortalArchiveCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/geo-archive`,
          method: 'POST',
          body: queryArg.geoPortalArchiveDto,
        }),
        invalidatesTags: ['admin.geoPortalArchive'],
      }),
      geoPortalArchiveUpdate: build.mutation<
        GeoPortalArchiveUpdateApiResponse,
        GeoPortalArchiveUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/geo-archive/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.geoPortalArchiveDto,
        }),
        invalidatesTags: ['admin.geoPortalArchive'],
      }),
      geoPortalArchiveDelete: build.mutation<
        GeoPortalArchiveDeleteApiResponse,
        GeoPortalArchiveDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/geo-archive/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['admin.geoPortalArchive'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GeoPortalArchiveLatestApiResponse =
  /** status 200  */ GeoPortalArchiveEntityDto[];
export type GeoPortalArchiveLatestApiArg = void;
export type GeoPortalArchiveListApiResponse =
  /** status 200  */ GeoPortalArchiveEntityDto[];
export type GeoPortalArchiveListApiArg = {
  name?: string | null;
  districtId?: number | null;
  regionId?: number | null;
};
export type GeoPortalArchiveCreateApiResponse =
  /** status 200  */ GeoPortalArchiveDto;
export type GeoPortalArchiveCreateApiArg = {
  geoPortalArchiveDto: GeoPortalArchiveDto;
};
export type GeoPortalArchiveUpdateApiResponse =
  /** status 200  */ GeoPortalArchiveDto;
export type GeoPortalArchiveUpdateApiArg = {
  id: number;
  geoPortalArchiveDto: GeoPortalArchiveDto;
};
export type GeoPortalArchiveDeleteApiResponse = /** status 200  */ UpdateResult;
export type GeoPortalArchiveDeleteApiArg = {
  id: number;
};
export type GeoPortalArchiveEntityDto = {
  /** Идентификатор */
  id: string;
  /** Заголовок */
  title: string;
  /** Содержание */
  content: string;
  /** Дата создания */
  createdAt: any;
};
export type GeoPortalArchiveDto = {
  regionId: number;
  districtId: number;
  /** Заголовок */
  name: string;
  /** Содержание */
  attachmentsList: any;
};
export type UpdateResult = {
  affected: number;
  raw: object;
};
export const {
  useGeoPortalArchiveListQuery,
  useGeoPortalArchiveCreateMutation,
  useGeoPortalArchiveUpdateMutation,
  useGeoPortalArchiveDeleteMutation,
} = injectedRtkApi;
