import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const ArchiveIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1665 3.66667C1.1665 3.11658 1.1665 2.84183 1.33742 2.67092C1.50834 2.5 1.78309 2.5 2.33317 2.5H11.6665C12.2166 2.5 12.4913 2.5 12.6623 2.67092C12.8332 2.84183 12.8332 3.11658 12.8332 3.66667C12.8332 4.21675 12.8332 4.4915 12.6623 4.66242C12.4913 4.83333 12.2166 4.83333 11.6665 4.83333H2.33317C1.78309 4.83333 1.50834 4.83333 1.33742 4.66242C1.1665 4.4915 1.1665 4.21675 1.1665 3.66667Z"
        fill="white" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.7068 5.7121C11.789 5.7121 11.8736 5.7121 11.9582 5.71094V8.3371C11.9582 10.5369 11.9582 11.637 11.2745 12.3201C10.5914 13.0038 9.49125 13.0038 7.2915 13.0038H6.70817C4.50842 13.0038 3.40825 13.0038 2.72517 12.3201C2.0415 11.637 2.0415 10.5369 2.0415 8.3371V5.71094C2.12609 5.7121 2.21067 5.7121 2.29292 5.7121H11.7068ZM5.24984 7.75377C5.24984 7.48194 5.24984 7.34602 5.29417 7.23869C5.35337 7.09582 5.46689 6.98231 5.60975 6.9231C5.71709 6.87877 5.853 6.87877 6.12484 6.87877H7.87484C8.14667 6.87877 8.28259 6.87877 8.38992 6.9231C8.53257 6.98243 8.64585 7.09593 8.70492 7.23869C8.74984 7.34602 8.74984 7.48194 8.74984 7.75377C8.74984 8.0256 8.74984 8.16152 8.7055 8.26885C8.64617 8.4115 8.53268 8.52479 8.38992 8.58385C8.28259 8.62877 8.14667 8.62877 7.87484 8.62877H6.12484C5.853 8.62877 5.71709 8.62877 5.60975 8.58444C5.46711 8.52511 5.35382 8.41161 5.29475 8.26885C5.24984 8.16152 5.24984 8.0256 5.24984 7.75377Z"
            fill="white" />
    </svg>
  );
};
