import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { StatusInfo } from '@features/manageEvents/components';
import { TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { IOtherTask } from '@models/manageEvents/interfaces/otherTask';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetExtraTaskTemplatesQuery,
  useGetOtherTaskByIdQuery,
  useLazyGetExtraTaskTemplateByIdQuery,
} from '@store/gisproApi';
import { setOtherTaskBlockAction } from '@store/slices';
import { debounce, isInteger, toInteger } from '@utils/utils';

import styles from './OtherTasksCard.module.scss';

export const OtherTaskHeader: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: templates = [], isLoading: templateIsLoading } =
    useGetExtraTaskTemplatesQuery('');

  const { data: extraTask } = useGetOtherTaskByIdQuery(
    id && isInteger(toInteger(id)) ? id : skipToken,
  );

  const [triggerTemplate, { data: template }] =
    useLazyGetExtraTaskTemplateByIdQuery();

  const setOtherTaskTemplate = (value: string) => {
    triggerTemplate(value).then(({ data }) => {
      dispatch(
        setOtherTaskBlockAction({
          ...data,
          id: data?.taskId || null,
        }),
      );

      form.setFieldsValue({ ...data, id: data?.taskId || null });
    });
  };

  const templateDisabled = !!extraTask?.id;

  const onValuesChange = debounce(
    (values: Partial<IOtherTask>) => {
      dispatch(setOtherTaskBlockAction(values));
    },
    250,
    {
      maxWait: 1000,
    },
  );

  useEffect(() => {
    if (extraTask) {
      form.setFieldsValue(extraTask);
    }
  }, [extraTask]);

  return (
    <Form
      layout="vertical"
      onValuesChange={onValuesChange}
      form={form}
      className={styles.form}
    >
      <Row align="bottom" justify="start" gutter={24}>
        <Col span={4}>
          <Form.Item className={styles.formItem} name="id">
            <TooltipedInput
              size="large"
              className={styles.input}
              placeholder="ID задачи"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item rules={[{ required: true }]} name="title">
            <TooltipedInput
              size="large"
              placeholder="Наименование задачи"
              disabled={!!extraTask?.id}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="templateId">
            <TooltipedSelect
              size="large"
              placeholder="Загрузить шаблон"
              disabled={templateDisabled}
              loading={templateIsLoading}
              options={templates.map((item: any) => ({
                label: item.templateName,
                value: item.id,
              }))}
              onChange={setOtherTaskTemplate}
            />
          </Form.Item>
        </Col>
        <Col span={3} offset={8}>
          <Row justify="end">
            <Form.Item>
              <StatusInfo
                className={styles.status}
                status={extraTask?.status}
                planEndDate={extraTask?.planEndDate}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
