export enum ArchivariusTableQueryTypes {
  irrigationSystem = 'irrigationSystem',
  waterUser = 'waterUser',
  waterObjects = 'waterObjects',
  associations = 'associations',
  manageEventsOperational = 'manageEventsOperational',
  building = 'building',
  otherTasks = 'otherTasks',
  reservoir = 'reservoir'
}
