import { Button, Space } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function rendererDecadeWithActionHeaderCell<
  RecordType extends JsonRecord,
>({ record, customColumn, actionProps }: TableRendererProps<RecordType>) {
  const { title } = customColumn;

  const isSent =
    actionProps.getIsSent && actionProps.getIsSent(customColumn.dataIndex);

  const onClick = () => {
    if (
      // !isSent &&
      actionProps.sendDecadeInfo
    ) {
      actionProps.sendDecadeInfo(record, customColumn);
    }
  };

  return (
    <Space direction="vertical" className={styles.decadeBlock} size={5}>
      <span className={styles.decadeTitle}>{title}</span>
      <Button
        className={`${styles.sendButton} ${isSent ? '' : styles.purpleButton}`}
        onClick={onClick}
      >
        {isSent ? 'Подтверждено' : 'Подтвердить'}
      </Button>
    </Space>
  );
}
