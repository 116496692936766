import { FC } from 'react';

import { Col, Row, Typography, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import Icon from '@ant-design/icons';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { PipeIcon } from '@icons/PipeIcon';
import { WearSumTable } from '@features/waterObject/waterObjectCard/shared/tables';

export const SystemIndicatorsMainConduitComponent: FC = () => {
  const { data: waterQualityTypes = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.WaterQualityType,
    });

  return (
    <>
      <Row gutter={8} style={{ marginTop: 8 }}>
        <Col span={12}>
          <CustomCard title="Системные показатели">
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CustomCard title={null} dashed>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'factIrrigatedArea']}
                            label="Фактическая орошаемая площадь (Га)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'suspendedSquare']}
                            label="Подвешанная площадь (Га)"
                          >
                            <TooltipedInput size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'factConnectedWaterUsersCount']}
                            label="Фактическое количество связанных водопользователей (Штук)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'factCountConnectedGtsObjects']}
                            label="Фактическое количество связанных ГТС (Штук)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </CustomCard>
                  </Col>
                  <Col span={24}>
                    <CustomCard dashed title="Протяженность">
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'linedSection']}
                            label="Облицованной секции (м)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'earthSection']}
                            label="Земляной секции (м)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={['status', 'totalLength']}
                            label="Общая протяженность (м)"
                          >
                            <TooltipedInput size="large" disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </CustomCard>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Макс. расход (м3)"
                        name={['status', 'maxFlowRate']}
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Пропускная способность (л/с)"
                        name={['status', 'bandwidth']}
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Скорость потока (м3)"
                        name={['status', 'flowRateSpeed']}
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="direction">
                        <PropertyBlock
                          icon={ConstructionIcon}
                          label="Направление"
                          type="radio"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={['status', 'averageKpd']}
                        label="КПД (Средний из системы)"
                      >
                        <TooltipedInput size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={['status', 'manualKpd']}
                        label="КПД (Ручной ввод)"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Забираемой воды" name="collectedWater">
                        <TooltipedSelect
                          size="large"
                          placeholder="Выберите качество"
                          options={waterQualityTypes}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Сбрасываемой воды"
                        name="dischargedWater"
                      >
                        <TooltipedSelect
                          size="large"
                          placeholder="Выберите качество"
                          options={waterQualityTypes}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
