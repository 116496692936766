export const generateDecadesOptions = (daysInMonth: number) => {
  const daysPerDecade1 = 10;
  const daysPerDecade2 = 10;
  const remainingDays = daysInMonth - daysPerDecade1 - daysPerDecade2;
  let start = 1;
  let end = 0;
  const calculatedDecades = [];

  for (let i = 0; i < 3; i += 1) {
    if (i < 2) {
      end = start + daysPerDecade1 - 1;
    } else {
      end = Math.min(start + remainingDays - 1, daysInMonth);
    }
    calculatedDecades.push({
      label: `Декада №${i + 1} (${start}-${end})`,
      value: i + 1,
    });
    start = end + 1;
  }

  return calculatedDecades;
};
