import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Flex, Form, notification, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { EntityTypesEnum } from '@enums/entityTypes';
import { normalizeValue } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/normalizeValue';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  CustomModal,
  DangerButton,
  DefaultButton,
  DocsList,
  Mask,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
// import { OpenStreetMapV2 } from '@features/ui-kit-v2';
import { useCreatingSiteModal } from '@features/waterUser/waterUserCard/useCreatingSiteModal';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCreateFacilityMutation,
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
  useGetHydroFacilityAllQuery,
  useGetReferencesByParentIdAndTypeQuery,
  useUpdateFacilityMutation,
} from '@store/gisproApi';
import { useParams } from 'react-router-dom';
import { RadioGroup } from '@features/ui-kit/radioGroup';

import styles from './modals.module.scss';
import { UploadModal } from '@features/ui-kit/upload/uploadModal';
import { numbersValidator } from '@utils/validation/numbersValidator';
import { lettersValidator } from '@utils/validation/lettersValidator';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { UploadFileIcon } from '@icons/UploadFileIcon';

type IndividualPermissionsModalForm = {
  login: string;
  password: string;
  pin: string;
  phone: string;
  email: string;
};

export const CreatingSiteModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { id } = useParams();

  const { isModalShown, setShowModal, getInitialValues } =
    useCreatingSiteModal();
  const [isRenter, setIsRenter] = useState(getInitialValues?.isRenter || false);
  const [defaultDocType, setDefaultDocType] = useState('');
  const [show, setShow] = useState(false);
  const irrigationSystem = Form.useWatch('systemId', form);
  const hydroFacilityId = Form.useWatch('hydroFacilityId', form);
  const channel = Form.useWatch('channelId', form);
  const region = Form.useWatch('regionId', form);
  const district = Form.useWatch('districtId', form);

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    region
      ? {
          parentId: parseInt(region, 10),
        }
      : skipToken,
  );
  const { data: okmots = [] } = useFindReferencesByParentIdQuery(
    district
      ? {
          parentId: parseInt(district, 10),
        }
      : skipToken,
  );

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: Number(region),
    district: Number(district),
  });

  const { data: irrigationSystems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId ? { hydroFacilityId } : skipToken,
    );

  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    irrigationSystem ? { id: irrigationSystem } : skipToken,
  );

  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    channel ? { id: channel } : skipToken,
  );

  const attachmentDtos =
    Form.useWatch(['renter', 'attachmentList'], form) || [];

  const [createFacility] = useCreateFacilityMutation();

  const [updateFacility] = useUpdateFacilityMutation();

  const renterHasDocument = useMemo(() => {
    if (isRenter) {
      return !(attachmentDtos || []).some(
        (item: IAttachment) => item.type === 'документ',
      );
    }
    return false;
  }, [isRenter, attachmentDtos]);

  const onFinish = (values: any) => {
    if (!getInitialValues) {
      createFacility({
        ...values,
        waterUserId: Number(id),
        coordinates_x: 0,
        coordinates_y: 0,
        isRenter,
      })
        .then((data: any) => {
          if (data?.error) {
            notification.error({
              message: 'Ошибка при создании участка',
              description:
                data?.error?.data?.message ?? 'Обратитесь к администратору',
            });
            return;
          } else {
            notification.success({
              message: 'Успешно',
              description: 'Участок успешно создан',
            });
            setShowModal(false);
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Ошибка',
            description: 'Ошибка при создании участка',
          });
        });
    } else {
      updateFacility({
        ...values,
        waterUserId: Number(id),
        coordinates_x: 0,
        coordinates_y: 0,
        facilitiesId: getInitialValues.facilitiesId,
        isRenter,
      })
        .then((data: any) => {
          if (data?.error) {
            notification.error({
              message: 'Ошибка при обновлении участка',
              description:
                data?.error?.data?.message ?? 'Обратитесь к администратору',
            });
            return;
          } else {
            notification.success({
              message: 'Успешно',
              description: 'Участок успешно обновлен',
            });
            setShowModal(false);
          }
        })
        .catch((error) => {
          console.log('error', error);
          notification.error({
            message: 'Ошибка',
            description: 'Ошибка при обновлении участка',
          });
        });
    }
  };

  const onHydroFacilityChange = () => {
    form.setFieldsValue({
      systemId: undefined,
      channelId: undefined,
      outletId: undefined,
    });
  };

  const onIrrigationSystemChange = () => {
    form.setFieldsValue({
      channelId: undefined,
      outletId: undefined,
    });
  };

  const changeLocale = (locale: any) => {
    form.setFieldValue('gisAddress', `${locale.lat} ${locale.lng}`);
  };

  const onChannelChange = () => {
    form.setFieldsValue({
      outletId: undefined,
    });
  };

  const onCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onSubmit = () => {
    form.submit();
  };

  const onAttachmentUpload = (file: any) => {
    const currentAttchments =
      form.getFieldValue(['renter', 'attachmentList']) || [];
    const updatedAttachments = [...currentAttchments, file];
    form.setFieldValue(['renter', 'attachmentList'], updatedAttachments);
    setDefaultDocType('');
  };

  const initialValues = {
    regionId: user?.owner?.district?.parentId,
    districtId: user?.owner?.district?.id,
    ...getInitialValues,
  };
  if (initialValues?.renter?.dateOfBirth) {
    initialValues.renter = {
      ...initialValues.renter,
      dateOfBirth: dayjs(initialValues.renter.dateOfBirth),
    };
  }

  return (
    <CustomModal
      centered
      title={getInitialValues ? 'Редактировать участок' : 'Создание участка'}
      open={isModalShown}
      onCancel={onCancel}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={830}
      footer={
        <Row gutter={10} justify="center">
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              onClick={onCancel}
            >
              Отмена
            </PrimaryButton>
          </Col>
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              disabled={renterHasDocument}
              onClick={onSubmit}
            >
              {getInitialValues ? 'Изменить' : 'Добавить'}
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      <Form<IndividualPermissionsModalForm>
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row justify="space-between" style={{ marginBottom: 8 }}>
          <Col>
            <RadioGroup
              defaultValue="owner"
              groupButtons={[
                {
                  label: 'Собственник',
                  value: 'owner',
                },
                {
                  label: 'Арендатор',
                  value: 'renter',
                },
              ]}
              value={isRenter ? 'renter' : 'owner'}
              onChange={(e) => setIsRenter(e.target.value === 'renter')}
            />
          </Col>
        </Row>
        {isRenter && (
          <>
            <CustomCard title="Данные арендодателя участка" dashed>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Фамилия"
                    name={['renter', 'lastName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput
                      placeholder="Введите фамилию"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Имя"
                    name={['renter', 'firstName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput placeholder="Введите имя" size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Отчество"
                    name={['renter', 'middleName']}
                    rules={[{ required: true }, lettersValidator]}
                  >
                    <TooltipedInput
                      placeholder="Введите отчество"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="ИНН/ПИН"
                    name={['renter', 'inn']}
                    rules={[{ required: true }, { len: 14 }, numbersValidator]}
                  >
                    <TooltipedInput
                      type="number"
                      maxLength={14}
                      placeholder="Введите ИНН/ПИН"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="№ Паспорта"
                    name={['renter', 'passportNumber']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите № Паспорта"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Дата рождения"
                    name={['renter', 'dateOfBirth']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedDatePicker
                      disabledDate={(current) =>
                        current && current > dayjs().endOf('day')
                      }
                      placeholder="Выберите дату рождения"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Пол"
                    name={['renter', 'gender']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      options={[
                        { value: 'm', label: 'Муж' },
                        { value: 'f', label: 'Жен' },
                      ]}
                      placeholder="Выберите пол"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Адрес проживания"
                    name={['renter', 'address']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      placeholder="Введите адрес проживания"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" gutter={[0, 16]}>
                <Col className={styles.docsList} span={24}>
                  <Typography.Title level={4}>
                    Прикрепленные документы:
                  </Typography.Title>
                  {attachmentDtos?.length ? (
                    <DocsList documents={attachmentDtos} />
                  ) : (
                    <Mask maskText="Нет прикрепленных документов" />
                  )}
                </Col>
                <Col span={6}>
                  <Flex gap="middle">
                    <Form.Item name={['renter', 'attachmentList']}>
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        onClick={() => {
                          setDefaultDocType('паспорт');
                          setShow(true);
                        }}
                      >
                        {t(
                          'createIndividualWaterRegistry.firstStep.mainInfoBlock.buttons.attachPassport',
                        )}
                      </DefaultButton>
                    </Form.Item>
                    <Form.Item name={['renter', 'attachmentList']}>
                      <DefaultButton
                        icon={<UploadFileIcon />}
                        onClick={() => {
                          setDefaultDocType('документ');
                          setShow(true);
                        }}
                      >
                        Прикрепить документ*
                      </DefaultButton>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
            </CustomCard>
            <Divider />
          </>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.areaName',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedInput
                    placeholder="Наименование участка"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="regionId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
                  )}
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.region?.id}
                >
                  <TooltipedSelect
                    placeholder="Область"
                    size="large"
                    options={regions}
                    disabled={isDisabledRegion}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="districtId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
                  )}
                  rules={[{ required: true }]}
                  initialValue={user?.owner?.district?.id}
                >
                  <TooltipedSelect
                    options={districts}
                    placeholder="Район"
                    size="large"
                    disabled={isDisabledDistrict || !region}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="okmotId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.okmot',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    placeholder="Айыл окмоту"
                    size="large"
                    options={okmots}
                    disabled={!district}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="kpd"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.efficiency',
                  )}
                  rules={[{ required: true }]}
                  normalize={normalizeValue}
                >
                  <TooltipedInput
                    placeholder="КПД"
                    size="large"
                    type="number"
                    max={1}
                    min={0}
                    step={0.01}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="hydroFacilityId"
                  label="Гидроучасток"
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    options={hydroFacilities}
                    placeholder="Гидроучасток"
                    allowClear
                    onChange={onHydroFacilityChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="systemId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.irrigationSystem',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    placeholder="Оросительная система"
                    options={irrigationSystems}
                    disabled={!hydroFacilityId}
                    allowClear
                    onChange={onIrrigationSystemChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="channelId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.channel',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    placeholder="Канал"
                    options={channels}
                    disabled={!hydroFacilityId || !irrigationSystem}
                    allowClear
                    onChange={onChannelChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="outletId"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.waterOutlet',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    placeholder="Водовыпуск"
                    size="large"
                    disabled={!hydroFacilityId || !irrigationSystem || !channel}
                    options={outlets}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="area"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.totalArea',
                  )}
                  rules={[{ required: true }]}
                >
                  <TooltipedInput
                    placeholder="Общая площадь"
                    size="large"
                    type="number"
                    min={0}
                    max={999999}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* <Col span={24}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="gisAddress"
                  label={t(
                    'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.gisAddress',
                  )}
                >
                  <TooltipedInput size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <OpenStreetMapV2 changeLocale={changeLocale} />
              </Col>
            </Row>
          </Col> */}
        </Row>
        <UploadModal
          setShow={setShow}
          show={show}
          onLoad={onAttachmentUpload}
          preSelectedDocType={defaultDocType}
        />
      </Form>
    </CustomModal>
  );
};
