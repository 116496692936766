import { basicApi as api } from '../basicApi';
import { type IAttachment } from '@models/attachments/interfaces/attachment';
import { SystemObject } from '@models/systemObject/systemObject';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    systemObjectControllerSaveSystemObject: build.mutation<
      SystemObjectControllerSaveSystemObjectApiResponse,
      SystemObjectControllerSaveSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/create`,
        method: 'POST',
        body: queryArg.systemObjectDto,
      }),
    }),
    systemObjectControllerSaveSystemObjectList: build.mutation<
      SystemObjectControllerSaveSystemObjectListApiResponse,
      SystemObjectControllerSaveSystemObjectListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/create-list`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    systemObjectControllerUpdateSystemObject: build.mutation<
      SystemObjectControllerUpdateSystemObjectApiResponse,
      SystemObjectControllerUpdateSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/update`,
        method: 'PUT',
        body: queryArg.systemObjectDto,
      }),
      invalidatesTags: ['SystemObject'],
    }),
    systemObjectControllerFindAllLinked: build.query<
      SystemObjectControllerFindAllLinkedApiResponse,
      SystemObjectControllerFindAllLinkedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/linked/${queryArg.id}`,
      }),
    }),
    systemObjectControllerFindAllObjectsOutletsByAssociation: build.query<
      SystemObjectControllerFindAllObjectsOutletsByAssociationApiResponse,
      SystemObjectControllerFindAllObjectsOutletsByAssociationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/outlets/by-association/${queryArg.associationId}`,
      }),
    }),
    systemObjectControllerFindAll: build.query<
      SystemObjectControllerFindAllApiResponse,
      SystemObjectControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/system-objects/all` }),
      transformResponse: (response: Array<any>) =>
        response.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      providesTags: ['SystemObject'],
    }),
    systemObjectControllerFindBySystemId: build.query<
      SystemObjectControllerFindBySystemIdApiResponse,
      SystemObjectControllerFindBySystemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/system/${queryArg.id}`,
      }),
      providesTags: ['SystemObject'],
    }),
    systemObjectControllerFindBySystemObjectId: build.query<
      SystemObjectControllerFindBySystemObjectIdApiResponse,
      SystemObjectControllerFindBySystemObjectIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/system-objects/${queryArg.id}` }),
      providesTags: ['SystemObject'],
    }),
    systemObjectControllerUpdateSystemObjectStatus: build.mutation<
      SystemObjectControllerUpdateSystemObjectStatusApiResponse,
      SystemObjectControllerUpdateSystemObjectStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/status/update/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.systemObjectStatusUpdateDto,
      }),
    }),
    systemObjectControllerDeleteSystemObject: build.mutation<
      SystemObjectControllerDeleteSystemObjectApiResponse,
      SystemObjectControllerDeleteSystemObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    systemObjectControllerFindDocumentsSystemObjectId: build.query<
      SystemObjectControllerFindDocumentsSystemObjectIdApiResponse,
      SystemObjectControllerFindDocumentsSystemObjectIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/system-objects/documents/${queryArg.id}` }),
      providesTags: ['SystemObjectDocument'],
    }),
    systemObjectControllerUpdateSystemObjectDocument: build.mutation<
      SystemObjectControllerUpdateSystemObjectDocumentApiResponse,
      SystemObjectControllerUpdateSystemObjectDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/${queryArg.id}/document/${queryArg.documentId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['SystemObjectDocument'],
    }),
    systemObjectControllerDeleteSystemObjectDocument: build.mutation<
      SystemObjectControllerDeleteSystemObjectDocumentApiResponse,
      SystemObjectControllerDeleteSystemObjectDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/system-objects/${queryArg.id}/document/${queryArg.documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SystemObjectDocument'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SystemObjectControllerSaveSystemObjectApiResponse = unknown;
export type SystemObjectControllerSaveSystemObjectApiArg = {
  systemObjectDto: SystemObject;
};
export type SystemObjectControllerSaveSystemObjectListApiResponse = unknown;
export type SystemObjectControllerSaveSystemObjectListApiArg = {
  body: SystemObject[];
};
export type SystemObjectControllerUpdateSystemObjectApiResponse = unknown;
export type SystemObjectControllerUpdateSystemObjectApiArg = {
  systemObjectDto: SystemObject;
};
export type SystemObjectControllerFindAllLinkedApiResponse =  Array<{
  title: string;
  items: Array<SystemObject>;
}>;
export type SystemObjectControllerFindAllLinkedApiArg = {
  /** Идентификатор объекта */
  id: number;
};
export type SystemObjectControllerFindAllObjectsOutletsByAssociationApiResponse =
  unknown;
export type SystemObjectControllerFindAllObjectsOutletsByAssociationApiArg = {
  associationId: number;
};
export type SystemObjectControllerFindAllApiResponse = Array<{ id: number, name: string, label: string, value: number }>;
export type SystemObjectControllerFindAllApiArg = void;
export type SystemObjectControllerFindBySystemIdApiResponse = unknown;
export type SystemObjectControllerFindBySystemIdApiArg = {
  id: number;
};
export type SystemObjectControllerFindBySystemObjectIdApiResponse = SystemObject;
export type SystemObjectControllerFindBySystemObjectIdApiArg = {
  id: number;
};
export type SystemObjectControllerFindDocumentsSystemObjectIdApiResponse = Array<IAttachment>;
export type SystemObjectControllerFindDocumentsSystemObjectIdApiArg = {
  id: number;
};
export type SystemObjectControllerUpdateSystemObjectStatusApiResponse = unknown;
export type SystemObjectControllerUpdateSystemObjectStatusApiArg = {
  id: number;
  systemObjectStatusUpdateDto: SystemObjectStatusUpdateDto;
};
export type SystemObjectControllerUpdateSystemObjectDocumentApiResponse = unknown;
export type SystemObjectControllerUpdateSystemObjectDocumentApiArg = {
  id: number;
  documentId: number;
};
export type SystemObjectControllerDeleteSystemObjectDocumentApiResponse = unknown;
export type SystemObjectControllerDeleteSystemObjectDocumentApiArg = {
  id: number;
  documentId: number;
};
export type SystemObjectControllerDeleteSystemObjectApiResponse = unknown;
export type SystemObjectControllerDeleteSystemObjectApiArg = {
  id: number;
};
export type Geojson = any;
export type SystemObjectStatusDto = any;
export type SystemObjectStatusUpdateDto = any;
export const {
  useSystemObjectControllerSaveSystemObjectMutation,
  useSystemObjectControllerSaveSystemObjectListMutation,
  useSystemObjectControllerUpdateSystemObjectMutation,
  useSystemObjectControllerFindAllLinkedQuery,
  useSystemObjectControllerFindAllObjectsOutletsByAssociationQuery,
  useSystemObjectControllerFindAllQuery,
  useSystemObjectControllerFindBySystemIdQuery,
  useSystemObjectControllerFindBySystemObjectIdQuery,
  useSystemObjectControllerUpdateSystemObjectStatusMutation,
  useSystemObjectControllerDeleteSystemObjectMutation,
  useSystemObjectControllerFindDocumentsSystemObjectIdQuery,
  useSystemObjectControllerUpdateSystemObjectDocumentMutation,
  useSystemObjectControllerDeleteSystemObjectDocumentMutation,
} = injectedRtkApi;
