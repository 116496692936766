import { useSelector } from 'react-redux';

import { Row, Space, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { useGetWaterUserDataQuery } from '@store/gisproApi';
import { getCurrentUserSelector } from '@store/selectors';

import styles from './totalInformationCard.module.scss';

export const TotalInformationCard: React.FC = () => {
  const currentUser = useSelector(getCurrentUserSelector);

  const { data } = useGetWaterUserDataQuery(currentUser?.id);

  return (
    <Space direction="vertical" size={8} style={{ width: '100%' }}>
      <CustomCard title="Основные данные">
        <Space size={4} direction="vertical" className={styles.wrapper}>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Наименование водопользователя:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.organizationName}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Вид хозяйственной деятельности:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.organizationTypeName}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Тип водопользователя:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.organizationTypeName}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Область, район, айыл аймак, адрес:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.address}, {data?.okmotName}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Номер телефона, E-mail:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.phone}, {data?.email}
            </Typography.Text>
          </Row>
        </Space>
      </CustomCard>
      <CustomCard title="Банковские реквизиты">
        <Space size={4} direction="vertical" className={styles.wrapper}>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Наименование банка:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.bankName}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Адрес банка:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.bankAddress}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              БИК банка:
            </Typography.Text>
            <Typography.Text className={styles.propName}>
              {data?.bikBank}
            </Typography.Text>
          </Row>
          <Row className={styles.properties}>
            <Typography.Text className={styles.prop}>
              Расчетный счет:
            </Typography.Text>
            <Typography.Text className={styles.propName}>ds</Typography.Text>
          </Row>
        </Space>
      </CustomCard>
    </Space>
  );
};
