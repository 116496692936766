import { CustomCard, TableCustom } from '@features/ui-kit';
import { useWaterUserFilters } from '@features/vodokhozReport/entryData/waterUser/waterUserFilters';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useGet2tpDrainageQuery,
  useUpdate2tpDrainageMutation,
} from '@store/gisproApi';
import { notification, Space } from 'antd';
import React, { useMemo } from 'react';
import { commonColumns, waterWrainageColumns } from './columns';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const WaterDrainageTable: React.FC<{
  report: any;
  refetch: () => void;
}> = ({ report, refetch }) => {
  const { filters } = useWaterUserFilters();
  const [update] = useUpdate2tpDrainageMutation();
  const { data, refetch: refetchData } = useGet2tpDrainageQuery(
    filters.year ? filters : skipToken,
  );

  const hasPermission = useHasUserPermission(ACCESS.REPORT_2TP_CARD_EDIT);

  const dataSource = useMemo(() => {
    if (!data) return [];
    const waterDrainageDataEntryMap = new Map<number, any>();

    Object.keys(data).forEach((key) => {
      const item = data[key];

      if (data.status === null) {
        return [data];
      }

      if (!waterDrainageDataEntryMap.has(item?.status)) {
        waterDrainageDataEntryMap.set(item.status, {
          ...data,
          status: item?.status,
          statusDrainage: report?.statusDrainage,
        });
      } else {
        const currentWaterManagement = waterDrainageDataEntryMap.get(
          item?.status,
        );
        waterDrainageDataEntryMap.set(item?.status, {
          ...currentWaterManagement,
          ...data,
          status: item?.status,
          statusDrainage: report?.statusDrainage,
          year: report?.year,
        });
      }
    });

    return Array.from(waterDrainageDataEntryMap.values());
  }, [data, report, filters]);

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const currentWaterDrainage = {
      withoutCleaningStandard: record?.withoutCleaningStandard,
      withoutCleaningPolluted: record?.withoutCleaningPolluted,
      afterCleaningBiological: record?.afterCleaningBiological,
      afterCleaningPhysicalChemical: record?.afterCleaningPhysicalChemical,
      afterCleaningMechanical: record?.afterCleaningMechanical,
      undergroundTransportLosses: record?.undergroundTransportLosses,
      undergroundDischargedWithoutUse: record?.undergroundDischargedWithoutUse,
      undergroundDischargedAfterUse: record?.undergroundDischargedAfterUse,
      undergroundTransferredOutside: record?.undergroundTransferredOutside,
      surfaceTransportLosses: record?.surfaceTransportLosses,
      surfaceDischargedWithoutUse: record?.surfaceDischargedWithoutUse,
      surfaceDischargedAfterUse: record?.surfaceDischargedAfterUse,
      surfaceTransferredOutside: record?.surfaceTransferredOutside,
      notEnoughPurified: record?.notEnoughPurified,
      allowedDrainage: record?.allowedDrainage,
    };

    update({
      body: currentWaterDrainage,
      year: filters?.year,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        refetch();
        refetchData();
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };

  return (
    <Space size={5} direction="vertical">
      <CustomCard title="Ввод данных по отведению вод">
        <TableCustom
          bordered
          columns={waterWrainageColumns(hasPermission)}
          rowKey={(record) => record.year}
          dataSource={dataSource}
          actionProps={{
            onEditDecimalSaveClick,
          }}
        />
      </CustomCard>

      <CustomCard title="Ввод общих данных">
        <TableCustom
          bordered
          columns={commonColumns(hasPermission)}
          rowKey={(record) => record.year}
          dataSource={dataSource}
          actionProps={{
            onEditDecimalSaveClick,
          }}
        />
      </CustomCard>
    </Space>
  );
};
