import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const BlueConfirmIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M17.7973 0H2.24175C1.01953 0 0.0195312 1 0.0195312 2.22222V17.7778C0.0195312 19 1.01953 20 2.24175 20H17.7973C19.0195 20 20.0195 19 20.0195 17.7778V2.22222C20.0195 1 19.0195 0 17.7973 0ZM17.7973 17.7778H2.24175V2.22222H17.7973V17.7778ZM16.6751 6.66667L15.1084 5.08889L7.7862 12.4111L4.91953 9.55556L3.34175 11.1222L7.7862 15.5556L16.6751 6.66667Z"
        fill="#3A36DB"
      />
    </svg>
  );
};
