import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Row,
  Typography,
  notification,
} from 'antd';

import { CaretRightOutlined } from '@ant-design/icons';
import { useAddHydraulicSectionModal } from '@features/hydraulicSection/hydraulicSectionEditor/modals/useAddHydraulicSectionModal';
import { CustomCard, PrimaryButton } from '@features/ui-kit';
import { DangerDeleteIcon } from '@icons/DangerDeleteIcon';
import { FolderIcon } from '@icons/FolderIcon';
import { LinkedIcon } from '@icons/LinkedIcon';
import {
  useDeleteHydroFacilityMutation,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';
import { getHydroFacilityIdSelector } from '@store/selectors/hydraulicSection';
import { setHydroFacilityIdAction } from '@store/slices';
import { groupByRegionAndDistrict } from '@utils/grouping/groupByRegionAndDistrict';

import styles from './HydraulicSectionMenu.module.scss';
import { useGetCurrentUser } from '@features/authentication';
import { DeleteHydraulicModal } from '../modals/deleteHydraulicModal/DeleteHydraulicModal';

interface IPanelsItem {
  key: string;
  icon: any;
  label: string;
  children: any;
}

export const HydraulicSectionMenu: React.FC = () => {
  const { user } = useGetCurrentUser();
  const { data = [], isLoading } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });

  const [deleteHydroFacility] = useDeleteHydroFacilityMutation();
  const { setShowModal } = useAddHydraulicSectionModal();
  const hydroFacilityId = useSelector(getHydroFacilityIdSelector);
  const dispatch = useDispatch();
  const [deleteModalProps, setDeleteModalProps] = useState({
    id: null,
    show: false,
  });
  const items = groupByRegionAndDistrict(data as any);

  const deleteHydraulic = () => {
    deleteModalProps?.id &&
      deleteHydroFacility({ id: deleteModalProps?.id })
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Гидроучасток успешно удален',
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Произошла ошибка',
          });
        })
        .finally(() => {
          onCancelDeleteModal();
        });
  };
  const setHydroFacilityId = (id: number) => {
    dispatch(setHydroFacilityIdAction(id));
  };

  const onCancelDeleteModal = () => {
    setDeleteModalProps({ id: null, show: false });
  };

  const arrowIcon = (isActive: boolean) => {
    return <CaretRightOutlined rotate={isActive ? 90 : 0} />;
  };

  const renderHeader = (item: IPanelsItem) => (
    <Row gutter={4} align="middle">
      {item.icon && <Col>{item.icon}</Col>}
      <Col>
        <Typography.Text>{item.label}</Typography.Text>
      </Col>
    </Row>
  );

  const renderNestedPanels = (panel: Array<IPanelsItem>) => {
    return panel.map((item) => {
      return (
        <Collapse.Panel key={item.key} header={renderHeader(item)}>
          <Row align="middle">
            {item.children &&
              item.children.map((panelChild: any) =>
                panelChild?.children ? (
                  <Col key={item.key} span={24}>
                    <Collapse
                      expandIcon={({ isActive }) => arrowIcon(!!isActive)}
                      bordered={false}
                    >
                      {renderNestedPanels(item.children)}
                    </Collapse>
                  </Col>
                ) : (
                  <Col
                    className={`${styles.collapseName} ${
                      panelChild.key === hydroFacilityId && styles.selected
                    }`}
                    key={panelChild.key}
                    span={24}
                    onClick={() => setHydroFacilityId(panelChild.key)}
                  >
                    <Collapse.Panel
                      key={panelChild.key}
                      header={
                        <Row>
                          <Col className={styles.collapseDeleteBtn}>
                            <DangerDeleteIcon />
                          </Col>
                          <Col>{renderHeader(panelChild)}</Col>
                        </Row>
                      }
                    />
                  </Col>
                ),
              )}
          </Row>
        </Collapse.Panel>
      );
    });
  };

  const collapseItemsRecursively = (
    payloads: any[],
  ): CollapseProps['items'] => {
    return payloads?.map((item) => {
      const children = item.children
        ? collapseItemsRecursively(item.children)
        : [];
      const hasChildren = children && children.length > 0;

      return {
        ...item,
        key: item.id,
        label: !item.value ? (
          <Row align="middle" style={{ gap: 7 }}>
            <FolderIcon />
            {item.label}
          </Row>
        ) : (
          <button
            type="button"
            className={`${styles.collapseName} ${
              hydroFacilityId === item.value && styles.selected
            }`}
            onClick={() => setHydroFacilityId(item.value)}
          >
            <Row style={{ gap: 7 }} align="middle">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteModalProps({ id: item.id, show: true });
                }}
              >
                <DangerDeleteIcon />
              </div>

              <LinkedIcon />
              {item.name}
            </Row>
          </button>
        ),
        children: hasChildren ? (
          <Collapse
            bordered={false}
            accordion
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                rotate={isActive ? 90 : 0}
                style={{ opacity: 0.7 }}
              />
            )}
          >
            {children.map((child: any) => (
              <Collapse.Panel
                showArrow={!child?.value as any}
                key={child.key as string}
                header={
                  <Row style={{ gap: 7 }} align="middle">
                    {child.label}
                  </Row>
                }
              >
                {child.children}
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : null,
      };
    });
  };

  const collapsedItems = collapseItemsRecursively(items);
  return (
    <CustomCard
      title={
        <Row justify="space-between" gutter={16}>
          <Col>
            <Typography.Title level={5}>
              Список районов/гидроучастков
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col>
            <PrimaryButton onClick={() => setShowModal(true)}>
              Добавить гидроучасток
            </PrimaryButton>
          </Col>
        </Row>
      }
    >
      {deleteModalProps?.show && (
        <DeleteHydraulicModal
          onCancel={onCancelDeleteModal}
          deleteHydraulic={deleteHydraulic}
        />
      )}
      <div className={styles.collapse}>
        <Collapse
          items={collapsedItems}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              style={{ opacity: 0.7 }}
            />
          )}
        />
      </div>
    </CustomCard>
  );
};
