import React, { useCallback, useEffect } from 'react';

import { Col, Form, Row, Space, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import {
  ColorBlock,
  CustomModal,
  DangerButton,
  DefaultButton,
  Divider,
  PrimaryButton,
  TimePicker,
  TooltipedInput,
  WaterAccountingPath,
} from '@features/ui-kit';
import { WaterAccountingTableTypes } from '@models/waterAccounting/enums/waterAccountingEnums';
import {
  UpdateWaterAccountingOutletFactsApiArg,
  useUpdateWaterAccountingHydropostFactsMutation,
  useUpdateWaterAccountingOutletFactsMutation,
} from '@store/gisproApi';
import dayjs from 'dayjs';

import { useEnterMonitoringValuesMorningModal } from './useEnterMonitoringValuesMorningModal';
import { useSearchParams } from 'react-router-dom';

interface IEnterMonitoringValuesMorningModalProps {
  type: WaterAccountingTableTypes;
}

export const EnterMonitoringValuesMorningModal: React.FC<
  IEnterMonitoringValuesMorningModalProps
> = ({ type }) => {
  const { isModalShown, setShowModal, selectedMorningRecord } =
    useEnterMonitoringValuesMorningModal();
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const elementColor = WaterAccountingPath(params.get('tab'));
  const [
    updateHydropost,
    {
      isError: isUpdateHydropostError,
      error: updateHydropostError,
      data: hydropostData,
    },
  ] = useUpdateWaterAccountingHydropostFactsMutation();
  const [
    updateOutlet,
    {
      isError: isUpdateOutletError,
      error: updateOutletError,
      data: outletData,
    },
  ] = useUpdateWaterAccountingOutletFactsMutation();
  let isError;
  let error;
  let payload;
  const update = (data: any) => {
    if (type === WaterAccountingTableTypes.EconomicWaterAccounting) {
      isError = isUpdateOutletError;
      error = updateOutletError;
      payload = outletData;
      updateOutlet(data);
    } else {
      isError = isUpdateHydropostError;
      error = updateHydropostError;
      payload = hydropostData;
      updateHydropost(data);
    }
    form.resetFields();
    setShowModal(false);
  };
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onFinish = useCallback(
    (values: UpdateWaterAccountingOutletFactsApiArg) => {
      update([
        {
          id: selectedMorningRecord[`${selectedMorningRecord.day}.id`],
          ...values,
          dayTime: 'morning',
        },
      ]);
    },
    [selectedMorningRecord],
  );

  useEffect(() => {
    onClose();
  }, [payload]);

  useEffect(() => {
    if (selectedMorningRecord) {
      form.setFieldsValue({
        time: selectedMorningRecord[`${selectedMorningRecord.day}.timeMorning`]
          ? dayjs(
            selectedMorningRecord[`${selectedMorningRecord.day}.timeMorning`],
          )
          : null,
        level:
          selectedMorningRecord[`${selectedMorningRecord.day}.heightMorning`],
        correctionLevel:
          selectedMorningRecord[`${selectedMorningRecord.day}.levelMorning`],
        waste:
          selectedMorningRecord[`${selectedMorningRecord.day}.flowRateMorning`],
      });
    }
  }, [selectedMorningRecord]);

  return (
    <CustomModal
      destroyOnClose
      centered
      open={isModalShown}
      onCancel={onClose}
      width={370}
      footer={false}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      title={'Утро'}
    >
      <ColorBlock colorType={elementColor}>
        <Form form={form} onFinish={onFinish} layout="vertical" >
          <Row gutter={10}>
            <Col span={24}>
              <Row align="bottom">
                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true }]}
                    name="time"
                    label="Отчет по рейке"
                  >
                    <TimePicker size="large" placeholder="Время" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item rules={[{ required: true }]} name="level">
                    <TooltipedInput size="large" type="number" placeholder="см" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                name="correctionLevel"
                label="Уровень с учетом поправки"
              >
                <TooltipedInput size="large" type="number" placeholder="см" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item rules={[{ required: true }]} name="waste" label="Расход">
                <TooltipedInput size="large" type="number" placeholder="л/сек" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Space size={16}>
              <DefaultButton onClick={onClose}>Отменить</DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Row>
          {isError && (
            <Typography.Text type="danger">
              {(error as any).status} {JSON.stringify((error as any).data)}
            </Typography.Text>
          )}
        </Form>
      </ColorBlock>
    </CustomModal>
  );
};
