import { CustomCard, InformationalBlock } from '@features/ui-kit';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Col } from 'antd';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import { Row } from 'antd/lib';

import { CustomSegmented, SectionTitle } from '@features/ui-kit-v2';
import { useEnteringDataTablesFilters } from '@features/waterQuality/tables/enteringDataTable/EnteringDataTables/useEnteringDataTablesFilters';
import {
  IWaterQualityFilters,
  ViewTypeMonitoringZone,
} from '@models/waterQuality/interfaces/waterQuality';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { setEnteringDataTablesFilters } from '@store/slices';
import { isEmpty } from '@utils/utils';

import styles from './EnteringDataTable.module.scss';
import {
  GroundWaterDataTable,
  MineralizationDataTable,
  ReclamationSoilDataTable,
} from './EnteringDataTables';
import { useGetCurrentUser } from '@features/authentication';

const monitoringZones: SegmentedLabeledOption[] = [
  {
    value: ViewTypeMonitoringZone.Reclamation,
    label: 'Мелиоративное состояние почв',
  },
  {
    value: ViewTypeMonitoringZone.GroundWater,
    label: 'Глубина залегания УГВ',
  },
  {
    value: ViewTypeMonitoringZone.LaboratoryResearch,
    label: 'Лабораторным исследования',
  },
];

export const EnteringDataTable = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch();
  const { filters, setDictionaryTableFilters } = useEnteringDataTablesFilters();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const segment = useMemo(
    () => searchParams.get(ViewTypeMonitoringZone.MonitoringZone),
    [searchParams],
  );

  let Component;
  switch (segment) {
    case ViewTypeMonitoringZone.Reclamation:
      Component = ReclamationSoilDataTable;
      break;
    case ViewTypeMonitoringZone.GroundWater:
      Component = GroundWaterDataTable;
      break;
    case ViewTypeMonitoringZone.LaboratoryResearch:
      Component = MineralizationDataTable;
      break;
    default:
      Component = ReclamationSoilDataTable;
      break;
  }

  useEffect(() => {
    const newFilters = Object.fromEntries([...params]);
    dispatch(
      setEnteringDataTablesFilters(
        isEmpty(newFilters)
          ? {
              regionId: user?.owner?.region?.id.toString() || null,
              districtId: user?.owner?.district?.id.toString() || null,
            }
          : (newFilters as unknown as IWaterQualityFilters),
      ),
    );
  }, []);

  const onEnteringDataTypeChange = useCallback(
    (value: SegmentedValue) => {
      setParams({
        regionId: user?.owner?.region?.id.toString() || '',
        districtId: user?.owner?.district?.id.toString() || '',
      });
      setDictionaryTableFilters({
        monitoringZone: value,
        regionId: user?.owner?.region?.id || null,
        districtId: user?.owner?.district?.id || null,
        year: null,
        quarter: null,
        pointId: null,
        zoneId: null,
        monthNumber: null,
      } as unknown as IWaterRegistryFilters);
    },
    [filters],
  );

  return (
    <InformationalBlock>
      <CustomCard
        title="Таблица ввода данных"
        bordered={false}
        extra={
          <CustomSegmented
            colorType="primary"
            options={monitoringZones}
            queryParams={{
              queryKey: ViewTypeMonitoringZone.MonitoringZone,
              queryType: ViewTypeMonitoringZone,
            }}
            onChange={onEnteringDataTypeChange}
          />
        }
      >
        <Component />
      </CustomCard>
    </InformationalBlock>
  );
};
