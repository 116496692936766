import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCreatingSiteAssociationModalSelector,
  getCreatingSiteModalSelector,
  getInitialValuesSiteModalSelector,
  getShowActionSelectSelector,
} from '@store/selectors';
import {
  setCreatingSiteAssociationModal,
  setCreatingSiteModal,
  setInitialValuesSiteModal,
  setShowActionSelectModalAction,
} from '@store/slices';
import { IFacility } from '@models/facility/interfaces/facility';

type ReturnType = {
  isModalShown: boolean;
  setShowModal: (showModal: boolean) => void;
  setInitialValues: (values: IFacility | null) => void;
  getInitialValues: IFacility | null;
};

export function useCreatingSiteModal(): ReturnType {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getCreatingSiteModalSelector);

  const getInitialValues = useSelector(getInitialValuesSiteModalSelector);

  const setShowModal = useCallback((showModal: boolean) => {
    dispatch(setCreatingSiteModal(showModal));
  }, []);

  const setInitialValues = useCallback((values: IFacility | null) => {
    dispatch(setInitialValuesSiteModal(values));
  }, []);

  return {
    isModalShown,
    setShowModal,
    setInitialValues,
    getInitialValues,
  };
}
