import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Col,
  Divider,
  Form,
  message,
  notification,
  Row,
  Space,
  Typography,
  Upload,
} from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { documentFormats } from '@constants/documentFormats';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedSelect,
} from '@features/ui-kit';
import { PrimaryButtonV2 } from '@features/ui-kit-v2/buttonsV2';
import { DeleteIcon } from '@icons/DeleteIcon';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import {
  useFindDictionaryByTypeIdQuery,
  useUploadFilesMutation,
} from '@store/gisproApi';
import { normalizeFile } from '@utils/attachments/normalizeFile';

import styles from './UploadModal.module.scss';

type DocumentUploadModalPropTypes = {
  onLoad: (doc: IAttachment) => void;
  show: boolean;
  setShow: (val: boolean) => void;
  preSelectedDocType?: string;
  defaultDocType?: string;
};

export const UploadModal: React.FC<DocumentUploadModalPropTypes> = ({
  onLoad,
  setShow,
  show,
  preSelectedDocType,
  defaultDocType = 'устав',
}) => {
  const [api] = notification.useNotification();

  const openNotification = () => {
    api.success({
      message: 'Документ успешно загружен!',
      placement: 'topRight',
    });
  };

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const docFormat = Form.useWatch('docFormat', form);
  const docType = Form.useWatch('docType', form) || defaultDocType;

  const { data: documentTypes = [], isLoading: documentTypesLoading } =
    useFindDictionaryByTypeIdQuery({ id: EntityTypesEnum.DocumentTypes });

  const [uploadDocument] = useUploadFilesMutation();

  const getFilePath = (file: any) => {
    if (file.extension === 'png') {
      return {
        ...file,
        filePath: `${process.env.REACT_APP_MAIN_BASE_URL}/api/v1/attachment/download?path=${file.filePath}`,
      };
    }
    return file;
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    } else {
      form.setFieldValue('docType', preSelectedDocType);
    }
  }, [show]);

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,
            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);

    try {
      const response: any = await uploadDocument(formData);
      openNotification();
      form.resetFields();
      onLoad(getFilePath(response.data) as IAttachment);
      onSuccess();
      setShow(false);
    } catch (error) {
      onError(error);
    }
  };

  const beforeUpload = (file: any) => {
    const limit = file.size / 1024 / 1024 <= 5;
    if (!limit) {
      message.error('File must be smaller than 2MB!');
    }
    return limit;
  };

  return (
    <CustomModal
      centered
      title={'Прикрепление документов'}
      open={show}
      onCancel={() => setShow(false)}
      width={370}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      footer={false}
    >
      <Form form={form} autoComplete="off" layout="vertical">
        <Row justify="center" gutter={[0, 48]}>
          <Col>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Form.Item
                  name="docType"
                  label={t('irrigationForm.chooseDocumentType')}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    showSearch={false}
                    disabled={!!preSelectedDocType}
                    loading={documentTypesLoading}
                    defaultValue={{
                      value: defaultDocType,
                      label: defaultDocType,
                    }}
                    options={documentTypes}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="docFormat"
                  label={t('common.placeholders.docFormat')}
                  rules={[{ required: true }]}
                >
                  <TooltipedSelect
                    size="large"
                    showSearch={false}
                    options={documentFormats}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className={styles.modalBtn}>
            <Form.Item
              valuePropName="file"
              getValueFromEvent={normalizeFile}
              name="file"
            >
              <Upload
                name="file"
                accept={docFormat}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
                showUploadList={false}
              >
                <PrimaryButton
                  size="large"
                  type="primary"
                  disabled={!docType || !docFormat}
                >
                  {t('common.buttons.load')}
                </PrimaryButton>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
