import { TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindAllBanksQuery, useFindAllFilialQuery } from '@store/gisproApi';
import { Row, Col, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

type BankDetailsProps = {
  form: FormInstance;
  onCancel(): void;
  data: any;
};

export const BankDetails = ({ form, onCancel, data }: BankDetailsProps) => {
  const parentBankId = Form.useWatch('parentBankId', form);
  const { data: banks = [], isLoading: bankIsLoading } = useFindAllBanksQuery();
  const { data: filials = [], isLoading: filialIsLoading } =
    useFindAllFilialQuery(parentBankId ? { bankId: parentBankId } : skipToken);
  const { t } = useTranslation();

  const initialValues = {
    bankId: data?.legalData?.bank?.id,
    accountNumber: data?.legalData?.accountNumber,
    parentBankId: data?.legalData?.bank?.parent?.id,
    organizationAccount: data?.legalData?.organizationAccount,
  };

  const handleChangeBank = () => {
    form.setFieldValue('bankId', null);
  };
  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onCancel}
      initialValues={initialValues}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label={t(
              'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.bank',
            )}
            name="parentBankId"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              allowClear
              options={banks.map((item) => ({
                label: item.bankName,
                value: item.id,
              }))}
              onChange={handleChangeBank}
              loading={bankIsLoading}
              placeholder="Выберите банк"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="accountNumber"
            label={t(
              'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.usersAccountDetails',
            )}
            rules={[{ required: true }]}
          >
            <TooltipedInput
              placeholder="Введите расчетный счет"
              type="number"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t(
              'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.branchName',
            )}
            name="bankId"
            rules={[{ required: true }]}
          >
            <TooltipedSelect
              size="large"
              options={filials?.map((item) => ({
                label: item.bankName,
                value: item.id,
              }))}
              disabled={!parentBankId}
              loading={filialIsLoading}
              allowClear
              placeholder="Введите название филиала"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="organizationAccount"
            label={t(
              'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.organizationAccount',
            )}
            rules={[{ required: true }]}
          >
            <TooltipedInput
              placeholder="Введите корреспондентский счет"
              type="number"
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
