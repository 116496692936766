import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { CustomCard, TableCustom } from '@features/ui-kit';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import {
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemObjectsListOutletsQuery,
  useFindDictionaryByTypeIdQuery,
} from '@store/gisproApi';

import { useAddEconomy } from '../useAddEconomy';
import { EconomyType } from '@store/slices';
import { MainWaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

interface OutletConnectionTableProps {
  economies: EconomyType[];
  waterUserType: MainWaterUserTypes;
}

export const OutletConnectionTable: FC<OutletConnectionTableProps> = ({ economies, waterUserType }) => {
  const { t } = useTranslation();
  const {
    removeEconomy,
    setSelectedEconomyIndex,
  } = useAddEconomy();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: district = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: okmots = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Okmot,
  });

  const { data: { content: irrigationSystemsTypes = [] } = {} } =
    useFindAllIrrigationSystemListQuery({});

  const { data: outlets = [], isLoading: outletsIsLoading } =
    useFindAllIrrigationSystemObjectsListOutletsQuery({});

  const columns: TableColumn[] = [
    {
      title: '№ п / п',
      dataIndex: 'economieId',
      xtype: CustomTableColumnType.NumberColumn,
      key: 'economieId',
      align: 'center',
    },
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'name',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'name',
      align: 'center',
      renderCustom: ({ record }) => {
        return outlets.find((item) => item.objectId === record.outlet)
          ?.objectName;
      },
    },
    {
      title: t('ecomoniesTable.irrigationSystem'),
      dataIndex: 'irrigationSystem',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {irrigationSystemsTypes.find(
              (item) => item.systemId === record.irrigationSystem,
            )?.name || '-'}
          </div>
        );
      },
    },
    {
      title: t('ecomoniesTable.address'),
      dataIndex: 'address',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => {
        return (
          <div>
            {regions.find((item) => item.id === record.region)?.title || '-'},
            {district.find((item) => item.id === record.district)?.title || '-'}
            ,{okmots.find((item) => item.id === record.okmot)?.title || '-'}
          </div>
        );
      },
    },
    {
      title: t('ecomoniesTable.area'),
      dataIndex: 'totalArea',
      xtype: CustomTableColumnType.String,
      key: 'area',
      align: 'center',
    },
    {
      title: t('createLegalWaterRegistry.secondStep.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];
  return (
    <Space size="large" direction="vertical" style={{ display: 'flex' }}>
      <CustomCard title="Список водовыпусков">
        <TableCustom
          bordered
          dataSource={economies}
          columns={columns}
          actionProps={{
            deleteItem: (record) => removeEconomy(record.economieId),
            onHandleEdit: (record) =>
              setSelectedEconomyIndex(record.economieId, waterUserType),
          }}
        />
      </CustomCard>
    </Space>
  );
};
