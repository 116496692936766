import React, { useState } from 'react';

import { Layout } from 'antd';

import { GeoPortalNavigation } from '@features/geoPortal/new/geoPortalNavigation/GeoPortalNavigation';
import { Sidebar } from '@features/sidebar/Sidebar';

export const GeoPortalLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <Layout.Sider
        width={190}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        theme="light"
        style={{
          position: 'fixed',
          top: 0,
          width: 60,
          maxWidth: 60,
          bottom: 0,
          zIndex: 401,
          overflow: 'auto',
        }}
      >
        <Sidebar setCollapsed={setCollapsed} collapsed={collapsed} />
      </Layout.Sider>
      <Layout style={{ padding: '23px 128px 0px 128px' }}>
        <GeoPortalNavigation />
      </Layout>
    </>
  );
};
