import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ITariffFilters } from '@models/tariff/interfaces/tariff';
import { getTariffTableFiltersSelector } from '@store/selectors/tariff';
import { changeTariffFilters } from '@store/slices/tariff/tariffSlice';
import { isEmpty, pickBy, isInteger } from '@utils/utils';

export const useTariffTableFilters = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getTariffTableFiltersSelector);

  const setTariffFilters = useCallback((newFilters: ITariffFilters) => {
    Object.entries(newFilters).forEach(([key, value]: any) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    setParams(params);

    const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

    dispatch(changeTariffFilters(values as ITariffFilters));
  }, []);

  return { filters, setTariffFilters };
};
