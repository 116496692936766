import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import myStyles from './AnnualApplicationTableContainer.module.scss';
import { ACCESS } from '@enums/permisson';

export const translateStatuses: { [key: string]: string } = {
  APPROVED: 'Одобрено',
  GENERATED: 'Создано',
  REJECTED: 'Отклонено',
  CANCELLED: 'Отменено',
};
export const useAnnualApplicationTableColumns = () => {
  const { t } = useTranslation();

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return myStyles.statusApproved;
      case 'GENERATED':
        return myStyles.statusPending;
      case 'CANCELLED':
      case 'REJECTED':
        return myStyles.statusRejected;
      default:
        return '';
    }
  };

  const columns: TableColumn[] = [
    {
      title: t('annualApplication.columns.id'),
      dataIndex: 'id',
      key: 'id',
      width: 40,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Тип водопользователя',
      dataIndex: 'facilityName',
      width: 100,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => (
        <span>{t(`userTypesEnum.${record.waterUser?.type}`)}</span>
      ),
    },
    {
      title: 'ФИО/Наименование водопользователя',
      dataIndex: 'agroclimaticZoneName',
      width: 50,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => (
        <span>{record.waterUser?.legalData?.name}</span>
      ),
    },
    {
      title: 'Регистрационный номер водопользователя',
      dataIndex: 'organizationName',
      width: 100,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }: any) => <span>{record.waterUser?.code}</span>,
    },
    {
      title: 'Наименование хозяйства',
      dataIndex: 'rawAnnualPlantingPlans.0.facility.name',
      xtype: CustomTableColumnType.String,
      width: 100,
    },
    {
      title: 'Дата создания заявки',
      dataIndex: 'createdAt',
      xtype: CustomTableColumnType.DateColumn,
      width: 50,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      xtype: CustomTableColumnType.RenderCustom,
      width: 20,
      renderCustom: ({ record }: any) => (
        <span className={getStatusClassName(record.status)}>
          {translateStatuses?.[record?.status] ?? record.status}
        </span>
      ),
    },
    {
      width: 30,
      title: 'Действия',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText],
      actionsAccess: [ACCESS.ANNUAL_CARD_VIEW],
    },
  ];

  return { columns };
};
