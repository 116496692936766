import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { useGetAllOperationalQuery } from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { Tabs } from '@features/ui-kit';
import { InfoBlock } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/systemObjectInfo/infoBlock/InfoBlock';
import { OperationalBlock } from '@features/geoPortal/new/geoPortalMap/geoPortalMapObjectDetails/systemObjectInfo/operationalBlock/OperationalBlock';
import { useContext } from 'react';
import { useSystemObjectControllerFindBySystemObjectIdQuery } from '@store/api/systemObjectApi';
import { useParams } from 'react-router-dom';

export const SystemObjectInfo = () => {
  const { currentAttribute } = useContext(MapContext);
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;

  const { data: waterObject } =
    useSystemObjectControllerFindBySystemObjectIdQuery(
      nameParamArchive !== 'archive' && currentAttribute
        ? { id: currentAttribute.id }
        : skipToken,
    );

  const { data: operationalBlocks, isLoading } = useGetAllOperationalQuery({
    systemObjectId: currentAttribute?.id,
  });

  const options = [
    {
      key: 'info',
      label: 'Данные карточки',
      children: (
        <InfoBlock
          waterObject={
            nameParamArchive === 'archive'
              ? currentAttribute?.systemObject
              : waterObject
          }
        />
      ),
    },
    {
      key: 'operational',
      label: 'Экспл. данные',
      children: (
        <OperationalBlock
          waterObject={
            nameParamArchive === 'archive'
              ? currentAttribute?.systemObject
              : waterObject
          }
          operational={operationalBlocks}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: 5 }}>
      <Tabs items={options} defaultValue="info" />
    </div>
  );
};
