import { getKibanaUrl } from '@store/kibana';
import { FC } from 'react';

export const IrrigationFundMainData: FC = () => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="IrrigationFundMainData"
        src={getKibanaUrl('Ирригационный фонд')}
        height="1300"
        width="100%"
      />
    </div>
  );
};
