import { Col, Form, Row } from 'antd';

import { WorkMarkTable } from '../../tables';
import {
  CustomCard,
  SystemObjectPreview,
  TooltipedTextarea,
} from '@features/ui-kit';

export const OtherIndicatorsComponent: React.FC = () => {
  return (
    <CustomCard title="Прочие показатели" style={{ marginTop: 8 }}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="characteristicsOfConstruction"
            label="Характеристика, особенности конструкции сооружения"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TooltipedTextarea rows={4} />
          </Form.Item>
          <Form.Item
            name="operatingCharacteristics"
            label="Характеристика, особенности эксплуатации"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TooltipedTextarea rows={4} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <WorkMarkTable />
        </Col>
      </Row>
    </CustomCard>
  );
};
