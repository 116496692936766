import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Space, Typography } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  BuildingCard,
  OperationalCard,
  OtherTasksCard,
} from '@features/manageEvents';
import { Divider, PrimaryButton } from '@features/ui-kit';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { saveUrl } from '@utils/returnUrl';
import { HomeOutlined } from '@ant-design/icons';
import styles from './ManageEventsLayout.module.scss';

export const ManageEventsLayout: React.FC = () => {
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const saveAction = saveUrl();

  const isCanCreateOperational = useHasUserPermission(
    ACCESS.WORK_MANAGEMENT_OPERATIONAL_CREATE,
  );
  const isCanCreateBuilding = useHasUserPermission(
    ACCESS.WORK_MANAGEMENT_CONSTRUCTION_CREATE,
  );
  const isCanCreateOtherTask = useHasUserPermission(
    ACCESS.WORK_MANAGEMENT_OTHERTASK_CREATE,
  );

  const oneOfPermissions =
    isCanCreateOperational || isCanCreateBuilding || isCanCreateOtherTask;

  useEffect(() => {
    setHeaderTitle('Управление работами и мероприятиями');
  }, []);

  return (
    <Layout>
      <div className={styles.breadcrumbTitle}>
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <HomeOutlined />,
            },
            {
              title: t('headers.manage-events'),
            },
          ]}
        />
        {oneOfPermissions && (
          <Space size={4}>
            <Typography.Text>Создать:</Typography.Text>
            {isCanCreateOperational && (
              <Link onClick={saveAction} to={Routes.OperationalBlock}>
                <PrimaryButton>Эксплуатационный блок</PrimaryButton>
              </Link>
            )}
            {isCanCreateBuilding && (
              <Link onClick={saveAction} to={Routes.BuildingBlock}>
                <PrimaryButton>Строительство</PrimaryButton>
              </Link>
            )}
            {isCanCreateOtherTask && (
              <Link onClick={saveAction} to={Routes.OtherTask}>
                <PrimaryButton>Прочие задачи</PrimaryButton>
              </Link>
            )}
          </Space>
        )}
      </div>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={8}>
        <Col span={24}>
          <OperationalCard />
        </Col>
        <Col span={24}>
          <BuildingCard />
        </Col>
        <Col span={24}>
          <OtherTasksCard />
        </Col>
      </Row>
    </Layout>
  );
};
