import { ILayerDto } from '@models/geoPortal/interfaces/GeoPortal';
import Icon from '@ant-design/icons';
import { LayerIcon } from '@icons/LayerIcon';

import styles from './LayerBlock.module.scss';
import { Checkbox, Col, Row } from 'antd';

type LayerItemProps = {
  index: number;
  item: ILayerDto & {
    checked: boolean;
  };
  onClick: (v: ILayerDto) => void;
  onShow: (e: any, v: ILayerDto, index: number) => void;
  loadingLayerData: boolean;
};

// ${item.selected ? styles.layerItemSelected : ''
export const LayerItem: React.FC<LayerItemProps> = ({
  item,
  onClick,
  onShow,
  index,
  loadingLayerData,
}) => {
  return (
    <Row
      className={`${styles.layerItem}`}
      gutter={2}
      onClick={() => onClick(item)}
    >
      <Col span={2} />
      <Col span={2}>
        <Checkbox
          onClick={(e) => onShow(e, item, index)}
          checked={item.checked}
          disabled={!loadingLayerData}
        />
      </Col>
      <Col span={2} style={{ marginTop: 2 }}>
        <Icon component={LayerIcon} />
      </Col>
      <Col className={styles.layerText} span={16}>
        {item.title} ({item.count})
      </Col>
    </Row>
  );
};
