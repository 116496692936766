import React, { useRef } from 'react';

import { Switch, SwitchProps, Tooltip, TooltipProps } from 'antd';

type TooltipedSwitchPropsType = SwitchProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const TooltipedSwitch: React.FC<TooltipedSwitchPropsType> = ({
  tooltipProps,
  ...props
}) => {
  const ref = useRef(null);

  return (
    <Tooltip {...tooltipProps}>
      <Switch {...props} ref={ref} />
    </Tooltip>
  );
};
