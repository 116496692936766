import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { Row, Col, Typography, Divider, Form } from 'antd';
import { useUpdateBankDetailsMutation } from '@store/api/waterUsersApi';
import { useBankDetailsModal } from '../useBankDetailsModal';
import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { useFindAllBanksQuery, useFindAllFilialQuery } from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { numbersValidator } from '@utils/validation/numbersValidator';

interface IBankDetailsModalProps {
  data: any;
}

export const BankDetailsModal: FC<IBankDetailsModalProps> = ({ data }) => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const { isModalShown, setShowModal } = useBankDetailsModal();
  const [updateBankInfo] = useUpdateBankDetailsMutation();
  const parentBankId = Form.useWatch('parentBankId', form);
  const { data: banks = [], isLoading: bankIsLoading } = useFindAllBanksQuery();
  const { data: filials = [], isLoading: filialIsLoading } =
    useFindAllFilialQuery(parentBankId ? { bankId: parentBankId } : skipToken);
  const onCancel = () => {
    setShowModal(false);
  };

  const onSubmit = (values: any) => {
    updateBankInfo({
      id,
      body: {
        bankId: values.bankId,
        accountNumber: values.accountNumber,
      },
    });
    setShowModal(false);
  };

  const initialValues = {
    bankId: data?.legalData?.bank?.id,
    accountNumber: data?.legalData?.accountNumber,
    parentBankId: data?.legalData?.bank?.parent?.id,
  };

  const CloseIcon = (
    <div>
      <DangerButton icon={<CloseOutlined />} />
    </div>
  );

  const handleChangeBank = () => {
    form.setFieldValue('bankId', null);
  };

  return (
    <CustomModal
      centered
      open={isModalShown}
      onCancel={onCancel}
      width={830}
      title={'Банковские реквизиты'}
      footer={false}
      closeIcon={CloseIcon}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={t(
                'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.bank',
              )}
              name="parentBankId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                allowClear
                options={banks.map((item) => ({
                  label: item.bankName,
                  value: item.id,
                }))}
                onChange={handleChangeBank}
                loading={bankIsLoading}
                placeholder="Выберите банк"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="accountNumber"
              label={t(
                'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.usersAccountDetails',
              )}
              rules={[{ required: true }, numbersValidator]}
            >
              <TooltipedInput
                placeholder="Введите расчетный счет"
                type="number"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t(
                'createIndividualWaterRegistry.firstStep.bankDetails.placeholders.branchName',
              )}
              name="bankId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                options={filials?.map((item) => ({
                  label: item.bankName,
                  value: item.id,
                }))}
                disabled={!parentBankId}
                loading={filialIsLoading}
                allowClear
                placeholder="Введите название филиала"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="center">
          <Col>
            <DefaultButton size="large" onClick={onCancel}>
              Отмена
            </DefaultButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="submit">
              Изменить
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
