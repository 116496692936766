import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row } from 'antd';

import { ACCESS } from '@enums/permisson';
import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
  Tabs,
} from '@features/ui-kit';
import { AssociationTableFilters } from '@features/waterUser/waterUserTable/associationTable/associationTableFilters';
import { ArchivariusTableQueryTypes } from '@models/archivarius/enums/archivariusEnum';
import { WaterUserEnum } from '@models/waterUser/enums/waterUserEnums';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';

import { AssociationPermissionsModal } from '../associationPermissionsModal';
import { AssociationStatsModal } from '../associationStatsModal';
import { IndividualPermissionsModal } from '../individualPermissionsModal';
import { LegalPermissionsModal } from '../legalPermissionsModal';
import { AssociationTable } from './associationTable';
import { useWaterRegistryFilters } from './useWaterRegistryFilters';
import { saveUrl } from '@utils/returnUrl';
import { HomeOutlined } from '@ant-design/icons';

const options = [
  {
    key: `${WaterUserEnum.ASSOCIATION}`,
    label: 'Ассоциации',
  },
];

interface IWaterAssociationsTableProps {
  isArchived?: boolean;
}

export const WaterAssociationsTable: React.FC<IWaterAssociationsTableProps> = ({
  isArchived = false,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { setHeaderTitle } = useHeaderTitle();

  const saveAction = saveUrl();

  const navigate = useNavigate();
  const { setWaterRegistryFilters } = useWaterRegistryFilters();
  const [activeTab, setActiveTab] = useState(3);

  //TODO: надо убрать
  let Component;
  let ComponentFilters;
  switch (activeTab) {
    case WaterUserEnum.ASSOCIATION:
      Component = AssociationTable;
      ComponentFilters = AssociationTableFilters;
      break;
    default:
      Component = AssociationTable;
      ComponentFilters = AssociationTableFilters;
      break;
  }

  const addAssociation = () => {
    navigate(Routes.WaterRegistryMasterRegistrationAssociation);
    saveAction();
  };

  const changeContentHandle = (v: string) => {
    setActiveTab(parseInt(v, 10));
    setWaterRegistryFilters({
      page: 1,
      size: 10,
      userType: parseInt(v, 10),
      isArchived,
    });
  };

  useEffect(() => {
    setWaterRegistryFilters({
      page: 1,
      size: 10,
      isArchived,
    });
    setSearchParams({
      page: '1',
      size: '10',
      isArchived: isArchived.toString(),
      ...(isArchived
        ? { section: ArchivariusTableQueryTypes.associations }
        : {}),
    });
    setHeaderTitle(t('headers.water-registry-associations'));
  }, [activeTab]);

  const isCanCreateUserPermission = useHasUserPermission(
    ACCESS.WATER_USER_ASSOCIATION_CREATE,
  );

  return (
    <div>
      {!isArchived && (
        <>
          <Breadcrumb
            className="breadcrumb"
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: t('headers.water-registry-associations'),
              },
            ]}
          />
          <Divider style={{ margin: '6px 0 8px' }} />
          <Tabs items={options} onChange={changeContentHandle} />
        </>
      )}
      <Row gutter={10} align="middle" justify="space-between">
        <Col span={17}>
          <ComponentFilters />
        </Col>
        {isCanCreateUserPermission && !isArchived && (
          <Col>
            <Row gutter={8}>
              <Col>
                <PrimaryButton onClick={addAssociation}>
                  Добавить ассоциацию
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Divider />
      <InformationalBlock title="Реестр водопользователей">
        <CustomCard withoutBottomPadding>
          <Component />
        </CustomCard>
      </InformationalBlock>
      <AssociationStatsModal />
      <AssociationPermissionsModal />
      <IndividualPermissionsModal />
      <LegalPermissionsModal />
    </div>
  );
};
