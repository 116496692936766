import { FC } from 'react';

import { Col, Row, Typography, Form } from 'antd';

import { CustomCard, TooltipedInput, TooltipedSelect } from '@features/ui-kit';
import Icon from '@ant-design/icons';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { WearSumTable } from '@features/waterObject/waterObjectCard/shared/tables';
import {
  useReferenceControllerFindAllObjectCategoryQuery,
  useReferenceControllerFindBycodeQuery,
} from '@store/api/referenceApi';
import { EntityTypesEnum } from '@enums/entityTypes';
import { ObjectCategory } from '@models/objectCategory/interfaces/objectCategory';
import { useGetIrrigationSystemObjectsAndStructureByIdQuery } from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';

export const SystemIndicatorsGvsComponent: FC = () => {
  const form = Form.useFormInstance();
  const irrigationSystemId = form.getFieldValue('irrigationSystems');
  const { data: waterQualityTypes = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.WaterQualityType,
    });

  const { data: objectCategories = [] } =
    useReferenceControllerFindAllObjectCategoryQuery();
  const currentObjectCategory = objectCategories.find(
    (category: ObjectCategory) => category.code == 55, //code гидропоста
  );

  const {
    data: { content = [], totalElements = 0, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetIrrigationSystemObjectsAndStructureByIdQuery(
    irrigationSystemId && currentObjectCategory?.categoryId
      ? {
          irrigationSystemId: irrigationSystemId,
          params: {
            objectCategoryId: currentObjectCategory?.categoryId,
            limit: 10000,
            size: 10000,
          },
        }
      : skipToken,
  );

  console.log('irrigationSystemId', irrigationSystemId);
  console.log('data', content);
  console.log(
    'currentObjectCategory?.categoryId',
    currentObjectCategory?.categoryId,
  );
  return (
    <>
      <Row gutter={8} style={{ marginTop: 8 }}>
        <Col span={12}>
          <CustomCard title="Системные показатели">
            <Row justify="space-between" gutter={16}>
              <Col span={12}>
                <CustomCard light title="Показатели качества воды">
                  <Col span={24}>
                    <Form.Item label="Забираемой воды" name="collectedWater">
                      <TooltipedSelect
                        size="large"
                        placeholder="Выберите качество"
                        options={waterQualityTypes}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Сбрасываемой воды" name="dischargedWater">
                      <TooltipedSelect
                        size="large"
                        placeholder="Выберите качество"
                        options={waterQualityTypes}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="direction">
                      <PropertyBlock
                        icon={ConstructionIcon}
                        label="Направление"
                        type="radio"
                      />
                    </Form.Item>
                  </Col>
                </CustomCard>
              </Col>

              <Col span={12}>
                <CustomCard light title={'Конструктивные характеристики'}>
                  <Col span={24}>
                    <Form.Item name={['status', 'irrigatedArea']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Орошаемая площадь (Всего)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'outletsIrrigatedArea']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Орошаемая площадь (По водовыпускам)"
                        type="input"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name={['status', 'performanceSec']}>
                      <PropertyBlock
                        icon={SizesIcon}
                        label="Производительность"
                        type="input"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Гидропост" name="hydropostId">
                      <TooltipedSelect
                        size="large"
                        options={(content || []).map((item) => ({
                          ...item,
                          label: item.name,
                          value: item.id,
                        }))}
                        placeholder="Выберите гидропост"
                      />
                    </Form.Item>
                  </Col>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
