import React, { FC } from 'react';

import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib';

import { Links } from '@enums/links';
import { Dayjs } from 'dayjs';
import { get } from '@utils/utils';
import { Rule } from 'rc-field-form/lib/interface';

import { TooltipedDatePicker } from '../datePicker';
import styles from './DatesFormList.module.scss';

export const DatesFormList: FC = () => {
  const form = Form.useFormInstance();

  const ruleStart = (name: number): Rule[] => [
    {
      required: true,
      validator: (_, value) => {
        const { planDates } = form.getFieldsValue();
        const planEndDate = get(planDates, [name, 'planEndDate']);
        if (value && planEndDate && value >= planEndDate) {
          const error = new Error(
            'Начальная дата должна быть меньше конечной даты',
          );

          return Promise.reject(error);
        }
        if (!value) {
          return Promise.reject(new Error('Заполните поле'));
        }
        return Promise.resolve();
      },
    },
  ];
  const ruleEnd = (name: number): Rule[] => [
    {
      required: true,
      validator: (_, value) => {
        const { planDates } = form.getFieldsValue();
        const planStartDateValue = get(planDates, [name, 'planStartDate']);
        if (value && planStartDateValue && value <= planStartDateValue) {
          const error = new Error(
            'Конечная дата должна быть больше начальной даты',
          );

          return Promise.reject(error);
        }
        if (!value) {
          return Promise.reject(new Error('Заполните поле'));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <Form.List name="planDates">
      {(fields) => {
        console.log(fields);
        return (
          <Row className={`${styles.dateGroup}`} gutter={16}>
            {fields.map(({ key, name }) => (
              <Col key={key} className={styles.dateGroupItems} span={24}>
                <Form.Item
                  rules={ruleStart(name)}
                  name={[name, 'planStartDate']}
                  label="Начало"
                  style={{ marginBottom: 10 }}
                >
                  <TooltipedDatePicker placeholder="Начало" />
                </Form.Item>
                <Form.Item
                  rules={ruleEnd(name)}
                  name={[name, 'planEndDate']}
                  required
                  label="Конец"
                  style={{ marginBottom: 10 }}
                >
                  <TooltipedDatePicker placeholder="Конец" />
                </Form.Item>
              </Col>
            ))}
          </Row>
        );
      }}
    </Form.List>
  );
};
