import React from 'react';

import { Button, ButtonProps } from 'antd';

import styles from './buttons.module.scss';

type AddExecutorButtonPropsType = ButtonProps & {
  cardBlockType?: any;
  setShow: () => void;
};

export const AddExecutorButton: React.FC<AddExecutorButtonPropsType> = ({
  cardBlockType,
  setShow,
  disabled,
}) => {
  const {
    fio,
    organization,
    id,
    lastName,
    firstName,
    middleName,
    owner,
    ...other
  } = cardBlockType ?? {};
  let buttonText = 'Назначить исполнителя';

  if (fio) {
    buttonText = `${organization}, ${fio}`;
  }
  if (owner?.name) {
    buttonText = `Исполнитель: ${owner?.name}, ${lastName} ${firstName} ${middleName}`;
  }
  return (
    <Button
      disabled={disabled}
      type="primary"
      onClick={setShow}
      className={styles.addExecutor}
    >
      {buttonText}
    </Button>
  );
};
