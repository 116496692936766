import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Col, Row } from 'antd';

import Icon from '@ant-design/icons';
import { GeoPortalActionCloseIcon } from '@icons/GeoPortalActionCloseIcon';
import { GeoPortalActionHideIcon } from '@icons/GeoPortalActionHideIcon';
import { GeoPortalActionPenIcon } from '@icons/GeoPortalActionPenIcon';
import { GeoPortalActionReverseIcon } from '@icons/GeoPortalActionReverseIcon';
import { GeoPortalActionShowIcon } from '@icons/GeoPortalActionShowIcon';
import { GeoPortalActionSquaredPlusIcon } from '@icons/GeoPortalActionSquaredPlusIcon';
import { GeoPortalActionSubmitIcon } from '@icons/GeoPortalActionSubmitIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import { getCurrentFeatureIdSelector } from '@store/selectors';
import { get } from '@utils/utils';

import styles from './styles.module.scss';

export const renderGeoPortalActions = <RecordType extends JsonRecord>({
  record,
  customColumn,
  actionProps,
}: TableRendererProps<RecordType>) => {
  const featureId = useSelector(getCurrentFeatureIdSelector);
  // const [visible, setVisible] = useState<boolean>(false);
  // const [isEdit, setIsEdit] = useState<boolean>(false);
  const attributeName = get(record, customColumn.dataIndex);
  const {
    onEditAttributeClick,
    onSubmitUpdateFeatureClick,
    onCancelUpdateFeatureClick,
    getViewFeature,
    resetFeature,
  } = actionProps;

  const editFeature = () => {
    onEditAttributeClick?.(record);
  };

  const isEdit = featureId === record.id;
  return (
    <Row align="middle">
      <div className={styles.geoPortalActionsWrapper}>
        <Button
          icon={<Icon component={GeoPortalActionShowIcon} />}
          type="primary"
          className={styles.hideBtn}
          // onClick={() => setVisible(!visible)}
        />
        <Row className={styles.wrapper} align="middle">
          <div className={styles.shownGeoActions}>
            <Button
              icon={<Icon component={GeoPortalActionSquaredPlusIcon} />}
              className={styles.actionBtn}
              onClick={() => getViewFeature?.(record)}
            />
            <Button
              icon={<Icon component={GeoPortalActionReverseIcon} />}
              className={styles.actionBtn}
              disabled={!isEdit}
              onClick={() => resetFeature?.(record)}
            />
            <Button
              icon={<Icon component={GeoPortalActionPenIcon} />}
              className={styles.actionBtn}
              onClick={() => editFeature()}
            />
          </div>
          {isEdit && (
            <Row gutter={8}>
              <Col>
                <Button
                  type="primary"
                  className={styles.moveBtn}
                  icon={<Icon component={GeoPortalActionSubmitIcon} />}
                  onClick={() => onSubmitUpdateFeatureClick?.(record)}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={styles.moveBtn}
                  icon={<Icon component={GeoPortalActionCloseIcon} />}
                  onClick={() => onCancelUpdateFeatureClick?.(record)}
                />
              </Col>
            </Row>
          )}
        </Row>
      </div>
      <span className={styles.label}>{attributeName}</span>
    </Row>
  );
};
