import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Space, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { CreateIrrigationSystemQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { IrrigationSystem } from '@models/irrigationSystem/interfaces/IrrigationSystem';
import { getCurrentIrrigationSystemSelector } from '@store/selectors/irrigationRegistry';
import {
  changeIrrigationSystemAction,
  clearIrrigationSystemAction,
} from '@store/slices';

import { useCreateIrrigationSystemActionSelect } from '../createIrrigationSystemActionSelect';
import { DocumentFields } from './documentFields';
import { DangerButton, DefaultButton, PrimaryButton } from '@features/ui-kit';
import { useIrrigationCreateMutation } from '@store/api/irrigationSystemApi';

export const CreateStepperThirdStep: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentIrrigationSystem = useSelector(
    getCurrentIrrigationSystemSelector,
  );
  const [saveSystem, { data, isError, error }] =
    useIrrigationCreateMutation();
  const { setShowModal } = useCreateIrrigationSystemActionSelect();
  const dispatch = useDispatch();

  const onFinish = useCallback(() => {
    saveSystem({ irrigationSystemDto: currentIrrigationSystem || {} });
  }, [currentIrrigationSystem]);

  const onCancelButtonClick = () => {
    dispatch(clearIrrigationSystemAction());
    navigate(Routes.IrrigationSystemTable);
  };

  const onPreviousButtonClick = () => {
    navigate(
      `${Routes.IrrigationSystemCreateStepper}?${SectionQueryKey}=${CreateIrrigationSystemQueryTypes.works}`,
    );
  };

  const nextButtonDisabled = !(currentIrrigationSystem?.attachmentIds || [])
    .length;

  useEffect(() => {
    if (data) {
      dispatch(
        changeIrrigationSystemAction(data as unknown as IrrigationSystem),
      );
      setShowModal(true);
    }
  }, [data]);

  return (
    <div>
      <DocumentFields />

      <Row
        justify="space-between"
        align="middle"
        style={{ marginTop: 16 }}
      >
        <Col>
          <DangerButton
            htmlType="button"
            onClick={onCancelButtonClick}
            size="large"
          >
            Отмена
          </DangerButton>
        </Col>
        <Col>
          <Space size={16}>
            <DefaultButton
              htmlType="button"
              onClick={onPreviousButtonClick}
              size="large"
            >
              Назад
            </DefaultButton>
            <PrimaryButton
              disabled={nextButtonDisabled}
              onClick={onFinish}
              htmlType="submit"
              size="large"
            >
              {t('createIndividualWaterRegistry.buttons.next')}
            </PrimaryButton>
          </Space>
        </Col>
      </Row>
      {isError && (
        <Typography.Text type="danger">
          {(error as any).status} {JSON.stringify((error as any).data)}
        </Typography.Text>
      )}
    </div>
  );
};
