import React, { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';
import {
  CustomModal,
  DangerButton,
  TableCustom,
  TooltipedInput,
} from '@features/ui-kit';
import { useWaterUsersModal } from '@features/waterUser/waterUsersModal/useWaterUsersModal';
import { useWaterUsersModalTableColumns } from '@features/waterUser/waterUsersModal/useWaterUsersModalTableColumns';
import { IAnnualApplicationCalculations } from '@models/annualApplication/interfaces/annualApplication';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetWaterUsersByOutletQuery } from '@store/gisproApi';

import { saveUrl } from '@utils/returnUrl';

export const WaterUsersModal: React.FC = () => {
  const { isModalShown, setShowModal, outletIdModal } = useWaterUsersModal();
  const { columns } = useWaterUsersModalTableColumns();
  const navigate = useNavigate();
  const saveAction = saveUrl();

  const { data, isLoading, isFetching }: any = useGetWaterUsersByOutletQuery(
    outletIdModal ? { outletId: outletIdModal } : skipToken,
  );

  const showWaterUserCard = useCallback((record: any) => {
    setShowModal(false);
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(record.id),
      }),
    );
    saveAction();
  }, []);

  const showAnnualApplication = useCallback((record: any) => {
    setShowModal(false);
    navigate(
      generatePath(
        Routes.AnnualApplicationTable + '?organizationName=' + record.name,
      ),
    );
    saveAction();
  }, []);

  const sendDecadeInfo = useCallback((record: any) => {
    setShowModal(false);
    navigate(
      generatePath(Routes.DecadeApplication, {
        id: String(record.id),
      }),
    );
  }, []);

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <CustomModal
      open={isModalShown}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
      width={860}
      style={{ minWidth: '45vw' }}
      onCancel={onCancel}
      footer={<div />}
      title="Водопользователи"
      centered
    >
      <Form layout="vertical">
        <Row gutter={[16, 32]} align="middle">
          <Col span={10}>
            <Form.Item label="Водовыпуск">
              <TooltipedInput
                readOnly
                placeholder={data?.outlet.name}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Канал">
              <TooltipedInput
                readOnly
                placeholder={data?.outlet.channelName}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <TableCustom<IAnnualApplicationCalculations>
              loading={isLoading || isFetching}
              dataSource={data?.users || []}
              columns={columns}
              bordered
              scroll={{ y: 450 }}
              size="small"
              actionProps={{
                showWaterUserCard,
                showAnnualApplication,
                sendDecadeInfo,
              }}
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
