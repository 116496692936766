import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Layout, Row, Typography } from 'antd';

import { Routes } from '@enums/routes';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  AttachDocumentModal,
  AttachDocumentWithoutDescriptionModal,
  CreateTemplateModal,
} from '@features/manageEvents';
import { DocumentsCard } from '@features/manageEvents/cards/buildingCard/DocumentsCard';
import { RealizationCard } from '@features/manageEvents/cards/buildingCard/RealizationCard';
import { AssignExecutorModal } from '@features/manageEvents/modals/AssignExecutorModal';
import { SubmitImplementationTermModal } from '@features/manageEvents/modals/submitImplementationTerm/SubmitImplementationTermModal';
import {
  ColorBlock,
  DangerButton,
  DefaultButton,
  Divider,
  ElementColor,
  InformationalBlock,
} from '@features/ui-kit';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { ManageEventsWorkTypes } from '@models/manageEvents/enums/manageEventsEnum';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useConstructionBlockAttachmentsUploadMutation,
  useFindConstructionBlockByIdQuery,
  useSaveTaskBuildingBlockMutation,
} from '@store/gisproApi';
import { AppDispatch } from '@store/index';
import { getBuildingBlockSelector } from '@store/selectors';
import {
  addDocumentToBuildingBlockAction,
  clearBuildingBlock,
  setBuildingBlockAction,
} from '@store/slices';
import { toInteger, isInteger } from '@utils/utils';

import styles from './CreateOperationalCardLayout.module.scss';
import {
  CloseBuildingReportCard,
  CreateBuildingTaskCard,
  HeaderBuildingCard,
  ReportBuildingCard,
} from '@features/manageEvents/cards/buildingCard';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const CreateBuildingCardLayout: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [saveBuildingBlock] = useSaveTaskBuildingBlockMutation();
  const buildingBlock = useSelector(getBuildingBlockSelector);
  const [attachmentsUpload] = useConstructionBlockAttachmentsUploadMutation();
  const { data: constructionBlock } = useFindConstructionBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );

  const { setHeaderTitle } = useHeaderTitle();

  const attachDocument = useCallback(
    (file: IAttachment) => {
      if (id && isInteger(parseInt(id, 10))) {
        attachmentsUpload({
          attachments: [{ ...file, id: file.attachmentId }],
          id,
        })
          .unwrap()
          .then(() => {
            dispatch(addDocumentToBuildingBlockAction(file));
          });
      }
    },
    [id],
  );

  useEffect(() => {
    return () => {
      dispatch(clearBuildingBlock());
    };
  }, [id]);

  const saveTemplate = (values: any) => {
    const {
      directionId,
      districtId,
      executor,
      systemId,
      constructionObject,
      regionId,
      attachments,
      priority,
      taskDescription,
      title,
    } = buildingBlock;
    saveBuildingBlock({
      title,
      regionId,
      directionId,
      priority,
      taskDescription,
      ...values,
      isTemplate: true,
      attachments: attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      systemId: toInteger(systemId),
      executorId: toInteger(executor?.id),
      districtId: toInteger(districtId),
      constructionObject: {
        id: toInteger(constructionObject?.id),
        name: constructionObject?.name,
        code: constructionObject?.code,
        objectCategoryId: toInteger(constructionObject?.objectCategoryId),
        objectGroupId: toInteger(constructionObject?.objectGroupId),
        typeCategory: constructionObject?.typeCategory,
        geoJson: constructionObject?.geoJson,
      },
    })
      .then((response: any) => {
        navigate(generatePath(Routes.BuildingBlock, { id: response.id }));
        dispatch(setBuildingBlockAction(response));
      })
      .catch((err) => {
        console.log('err is ', err);
      });
  };

  useEffect(() => {
    setHeaderTitle('Карточка строительных работ');
  }, []);

  useEffect(() => {
    if (constructionBlock) {
      dispatch(setBuildingBlockAction(constructionBlock));
    }
  }, [constructionBlock]);

  return (
    <ColorBlock colorType={ElementColor.aqua}>
      <Layout>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={16}>
              <Col>
                <DefaultButton onClick={() => navigate(-1)} className="backBtn">
                  <LeftOutlined />
                  <Typography.Text>Назад</Typography.Text>
                </DefaultButton>
              </Col>
              <Col>
                <Breadcrumb
                  className="breadcrumb"
                  items={[
                    {
                      title: <HomeOutlined />,
                    },
                    {
                      title: 'Карточка строительных работ',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider style={{ margin: '6px 0 8px' }} />
          </Col>

          <Col className={styles.divider} span={24}>
            <HeaderBuildingCard />
            <Divider />
          </Col>
          <Col span={24}>
            <InformationalBlock>
              <Row gutter={8}>
                <Col span={12} className={styles.cardWrapper}>
                  <CreateBuildingTaskCard />
                  <DocumentsCard />
                </Col>
                <Col span={12} className={styles.cardWrapper}>
                  <ReportBuildingCard />
                  <RealizationCard />
                  <CloseBuildingReportCard />
                </Col>
              </Row>
            </InformationalBlock>
          </Col>
          <Col>
            <DangerButton
              size="large"
              onClick={() => navigate(Routes.ManageEvents)}
            >
              Отмена
            </DangerButton>
          </Col>
        </Row>
      </Layout>
      <AttachDocumentModal onLoad={attachDocument} />
      <AttachDocumentWithoutDescriptionModal onLoad={attachDocument} />
      <CreateTemplateModal save={saveTemplate} />
      <AssignExecutorModal type={ManageEventsWorkTypes.BUILDING} />
      <SubmitImplementationTermModal />
    </ColorBlock>
  );
};
