import { Radio, RadioProps } from 'antd';
import styles from './DirectionRadio.module.scss';
import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons';

type DirectionRadioProps = RadioProps;

export const DirectionRadio: React.FC<DirectionRadioProps> = (props) => {
  return (
    <Radio.Group className={styles.radioGroup} {...props}>
      <Radio.Button value="влево">
        <ArrowLeftOutlined color="#1b254b" />
      </Radio.Button>
      <Radio.Button value="вверх">
        <ArrowUpOutlined color="#1b254b" />
      </Radio.Button>
      <Radio.Button value="вправо">
        <ArrowRightOutlined color="#1b254b" />
      </Radio.Button>
    </Radio.Group>
  )
}
