import { memo, useState } from 'react';

import { Button, Popover, Row } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { JsonRecord } from '@models/jsonRecord';
import { ICreateReservoirData } from '@models/reservoir/interfaces/reservoir';
import dayjs from 'dayjs';

import { ReservoirMonitoringPopoverContent } from '../reservoirMonitoringPopoverContent/ReservoirMonitoringPopoverContent';

interface IRenderDateWithActionProps {
  value: string;
  record: any;
  index?: number;
  action: (values: ICreateReservoirData) => void;
  getLastExistingCurrentVolumeDate: () => JsonRecord | null;
}

export const RenderDateWithActionComponent: React.FC<
  IRenderDateWithActionProps
> = ({ value, record, index, action, getLastExistingCurrentVolumeDate }) => {
  const [open, setOpen] = useState<boolean>(false);
  const lastExistingCurrentVolumeDate = getLastExistingCurrentVolumeDate();

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onAction = (values: ICreateReservoirData) => {
    action({
      ...values,
      reservoirId: record?.reservoirId,
      createAt: dayjs(value).format('YYYY-MM-DD'),
    });
    hide();
  };

  const isDate = dayjs(value).isValid();

  const isEditDisabled = !record?.currentVolume;
  const isPastDate = dayjs(lastExistingCurrentVolumeDate?.name).isAfter(
    dayjs(value),
  );

  return (
    <Row align="middle" justify="center">
      {value}
      <Popover
        content={
          <ReservoirMonitoringPopoverContent hide={hide} action={onAction} />
        }
        open={open}
        onOpenChange={handleOpenChange}
        trigger="click"
        placement="right"
      >
        {isDate && (
          <Button shape="circle" type="text" icon={<EditOutlined />} />
        )}
      </Popover>
    </Row>
  );
};

export const RenderDateWithAction = memo(RenderDateWithActionComponent);
