import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { useGetPlantingListQuery } from '@store/gisproApi';

export const useActualStructureTableColumns = () => {
  const { data: plantingList = [] } = useGetPlantingListQuery();

  const legal: TableColumn[] = [
    {
      title: 'Хозяйство',
      dataIndex: 'name',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Водовыпуск',
      dataIndex: 'outletName',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Сельхозкультура',
      dataIndex: 'foodPlantId',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return plantingList.find((item) => item.id === record.foodPlantId)
          ?.name;
      },
    },
    {
      title: 'Тип сельхозкультуры',
      dataIndex: 'foodPlantTypeId',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return plantingList.find((item) => item.id === record.foodPlantId)?.type
          ?.name;
      },
    },
    {
      title: 'Площадь Га',
      dataIndex: 'totalArea',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'КТП',
      dataIndex: 'ktp',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'КВХ',
      dataIndex: 'kvh',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      width: 100,
      title: 'Действия',
      align: 'left',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];

  const association: TableColumn[] = [
    {
      title: 'Наименование водовыпуска',
      dataIndex: 'foodPlantId',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Наименование сельхозкультуры',
      dataIndex: 'totalArea',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Тип сельхозкультуры',
      dataIndex: 'ktp',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Площадь Га',
      dataIndex: 'totalArea',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'КТП',
      dataIndex: 'ktp',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'КВХ',
      dataIndex: 'kvh',
      align: 'left',
      width: 100,
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      width: 100,
      title: 'Действия',
      align: 'left',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [
        CustomTableAction.EditWithoutText,
        CustomTableAction.DeleteWithoutText,
      ],
    },
  ];
  return { legal, association };
};
