import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFindOperationalBlockByIdQuery } from '@store/gisproApi';

import styles from './passportData.module.scss';

export const PassportData = () => {
  const { id } = useParams();

  const { data: operationalBlock } = useFindOperationalBlockByIdQuery(
    id ? { id: parseInt(id, 10) } : skipToken,
  );
  return (
    <Form layout="vertical" className={styles.container}>
      <CustomCard title="Данные водного объекта">
        <Row justify="space-between">
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Объект:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.systemObject.name}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Система:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.system.name}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Район:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.district.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Область:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.region.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Задача:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.taskDescription}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Категория работ:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.category.title}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col className={styles.rowItem} span={3}>
            <Form.Item label="Тип работ:">
              <Typography.Text strong>
                {operationalBlock && operationalBlock.workType.title}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Row>
        {/* <div className={styles.row}> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/*  <div className={styles.rowItem} /> */}
        {/* </div> */}
      </CustomCard>
    </Form>
  );
};
