import { FC } from 'react';

import { Layout } from 'antd';

import { AnnualWaterSupplyTable } from '@features/annualApplication/annualWaterSupplyTable';

export const WaterSupplyTableLayout: FC = () => {
  return (
    <Layout>
      <AnnualWaterSupplyTable />
    </Layout>
  );
};
