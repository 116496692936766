import { FC } from 'react';

import { Col, Row, Space, Typography } from 'antd';

import { attachFileUrl } from '@features/ui-kit/upload/utils/attachFileUrl';
import { EyeIcon } from '@icons/EyeIcon';
import { PdfFileIcon } from '@icons/PdfFileIcon';
import { IAttachment } from '@models/attachments/interfaces/attachment';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';

import styles from './DocsList.module.scss';

interface IDocsListProps {
  documents: IAttachment[];
  title?: string;
}

export const DocsList: FC<IDocsListProps> = ({ documents = [], title }) => {
  const onWatch = (document: any) => {
    downloadAttachment(document);
  };

  return (
    <Row gutter={[0, 8]} className={styles.docCard}>
      {title && (
        <Col className={styles.docCardTitle} span={24}>
          <Typography.Title level={5}>{title}</Typography.Title>
        </Col>
      )}
      <Col span={24}>
        {documents.length ? (
          <Space size={8} style={{ display: 'flex' }} direction="vertical">
            {documents.map((document) => (
              <Row
                align="middle"
                justify="space-between"
                className={styles.docItem}
                key={document.attachmentId}
                onClick={() => onWatch(attachFileUrl(document))}
              >
                <Col>
                  <Space size={8}>
                    <PdfFileIcon />
                    <Typography.Text>
                      {document.originName || document.name}
                    </Typography.Text>
                  </Space>
                </Col>
                <Col>
                  <EyeIcon />
                </Col>
              </Row>
            ))}
          </Space>
        ) : (
          <div>Нет закрепленных документов</div>
        )}
      </Col>
    </Row>
  );
};
