import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useProfileEmployeeListTableColumns = (dataSource: any = []) => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'id',
      xtype: CustomTableColumnType.String,
      key: 'id',
      align: 'left',
      width: 100,
    },
    {
      title: 'ФИО',
      dataIndex: 'firstName',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'name',
      align: 'left',
      renderCustom: ({ record }) => {
        return (
          <div>
            {record.firstName || ''} {record.lastName || ''}{' '}
            {record.middleName || ''}
          </div>
        );
      },
    },
    {
      title: 'Должность',
      dataIndex: 'positionEmployee.title',
      xtype: CustomTableColumnType.String,
      key: 'id',
      align: 'left',
      width: 100,
    },
    {
      title: 'Дата рождения',
      dataIndex: 'dateBirth',
      xtype: CustomTableColumnType.String,
      key: 'regionTitle',
      align: 'left',
    },
    {
      title: 'Номер',
      dataIndex: 'phone',
      xtype: CustomTableColumnType.String,
      key: 'regionTitle',
      align: 'left',
    },
    {
      title: 'Логин',
      dataIndex: 'users.0.login',
      xtype: CustomTableColumnType.String,
      key: 'users.0.login',
      align: 'left',
    },
    {
      title: 'Меню контроля',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.GrantingAccess],
    },
    {
      title: 'Действия',
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions:
        dataSource?.length !== 1
          ? [
              CustomTableAction.EditWithoutText,
              CustomTableAction.DeleteWithoutText,
            ]
          : [CustomTableAction.EditWithoutText],
    },
  ];
  return { columns };
};
