import { Col, Row } from 'antd';

import { InformationalBlock } from '@features/ui-kit';

import { IrrigationFondCard } from './irrigationFondCard/IrrigationFondCard';
import styles from './SecondHomeMonitoring.module.scss';
import { WaterSupplyAgreementCard } from './waterSupplyAgreementCard/WaterSupplyAgreementCard';
import { WaterSupplyCard } from './waterSupplyCard/WaterSupplyCard';
import { WaterUserCard } from './waterUserCard/WaterUserCard';

export const SecondHomeMonitoring: React.FC = () => {
  return (
    <div className={styles.homeMonitoring}>
      <InformationalBlock title="Мониторинг">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <WaterUserCard />
          </Col>

          <Col span={12}>
            <IrrigationFondCard />
          </Col>
          <Col span={24}>
            <WaterSupplyCard />
          </Col>
          <Col span={24}>
            <WaterSupplyAgreementCard />
          </Col>
        </Row>
      </InformationalBlock>
    </div>
  );
};
