import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Col, Divider, Form, notification, Row, Typography } from 'antd';

import { DocumentTypes } from '@enums/documentTypes';
import { EntityTypesEnum } from '@enums/entityTypes';
import { Routes } from '@enums/routes';
import { AddObjectModal } from '@features/manageEvents/modals';
import { DocumentTable } from '@features/manageEvents/tables';
import { useAssignExecutorModal } from '@features/manageEvents/useAssignExecutorModal';
import { useAttachDocumentModal } from '@features/manageEvents/useAttachDocumentModal';
import { useCreateTemplateModal } from '@features/manageEvents/useCreateTemplateModal';
import {
  CustomCard,
  DefaultButton,
  PrimaryButton,
  PriorityGroup,
  TooltipedInput,
  TooltipedSelect,
  TooltipedTextarea,
  UploadButtonSmall,
} from '@features/ui-kit';
import { AddExecutorButton } from '@features/ui-kit/buttons/AddExecutorButton';
import { TaskStatusEnum } from '@models/manageEvents/enums/manageEventsEnum';
import { IBuildingBlock } from '@models/manageEvents/interfaces/buildingBlock';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateTaskBuildingBlockMutation,
  useFindAllIrrigationSystemListQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllObjectCategoriesByObjectGroupIdQuery,
  useFindAllObjectGroupsQuery,
  useFindConstructionBlockByIdQuery,
  useFindDictionaryByTypeIdQuery,
  useGetHydroFacilityAllQuery,
  useGetReferencesByParentIdQuery,
  useSaveTaskBuildingBlockMutation,
} from '@store/gisproApi';
import { getBuildingBlockSelector } from '@store/selectors';
import {
  setBuildingBlockAction,
  setObjectSystemBuildingBlock,
} from '@store/slices';
import { cloneDeep } from 'lodash'; // TODO: change lodash to native implementation
import { isEmpty, isInteger, toInteger } from '@utils/utils';

import styles from './BuildingCard.module.scss';
import { useGetCurrentUser } from '@features/authentication';
import { extractObjectTypes } from '@utils/objectType';

export const CreateBuildingTaskCard: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: buildingBlock, refetch } = useFindConstructionBlockByIdQuery(
    id && isInteger(parseInt(id, 10)) ? { id: parseInt(id, 10) } : skipToken,
  );
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const buildingBlockState = useSelector(getBuildingBlockSelector);
  const [createTaskBuilding] = useCreateTaskBuildingBlockMutation();
  const [saveBuildingBlock] = useSaveTaskBuildingBlockMutation();
  const navigate = useNavigate();
  const { setShow } = useCreateTemplateModal();
  const [openModalObject, setOpenModalObject] = useState<boolean>(false);
  const { setShow: setAttachmentDocumnetShow, setDocType } =
    useAttachDocumentModal();
  const { setShow: setShowAssignExecutorModalAction } =
    useAssignExecutorModal();
  const { data: regions = [], isLoading: regionIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.Region,
    });

  const { data: directions = [], isLoading: directionIsLoading } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.WaterObjectDirection,
    });

  const regionId = Form.useWatch('regionId', form);
  const districtId = Form.useWatch('districtId', form);
  const hydroFacilityId = Form.useWatch('hydroFacilityId', form);
  const objectGroupId = Form.useWatch(
    ['constructionObject', 'objectGroupId'],
    form,
  );
  const objectCategoryId = Form.useWatch(
    ['constructionObject', 'objectCategoryId'],
    form,
  );

  const { data: districtByParentId = [] } = useGetReferencesByParentIdQuery(
    isInteger(regionId) ? regionId : skipToken,
  );

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery(
    regionId || districtId
      ? {
          region: regionId,
          district: districtId,
        }
      : skipToken,
  );

  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId
        ? {
            hydroFacilityId,
          }
        : skipToken,
    );

  const { data: groups = [], isLoading: groupsIsLoading } =
    useFindAllObjectGroupsQuery();

  const { data: objectCategories = [], isLoading: objectCategoriesIsLoading } =
    useFindAllObjectCategoriesByObjectGroupIdQuery(
      objectGroupId
        ? {
            objectGroupId,
          }
        : skipToken,
    );

  const objectTypes = useMemo(
    () => extractObjectTypes(objectCategories, objectCategoryId),
    [objectCategories, objectCategoryId],
  );

  const isEditingDisabled = useMemo(
    () =>
      (!buildingBlockState.title && !buildingBlock?.id) ||
      (buildingBlock && buildingBlock?.status !== TaskStatusEnum.draft),

    [buildingBlockState, buildingBlock],
  );

  const handleChangeHydroFacility = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const createTaskHandle = () => {
    if (buildingBlock?.id) {
      createTaskBuilding(buildingBlock?.id)
        .unwrap()
        .then((data: any) => {
          refetch();
          notification.success({
            message: 'Вы успешно создали задачу',
          });
        })
        .catch((err) => {
          notification.error({
            message: 'Не удалось создать задачу',
          });
        });
    }
  };

  const createTemplate = () => {
    const values = form.getFieldsValue();
    dispatch(
      setBuildingBlockAction(values as unknown as Partial<IBuildingBlock>),
    );
    dispatch(setObjectSystemBuildingBlock(values?.constructionObject));
    setShow(true);
  };

  const onFinish = ({ hydroFacilityId, ...values }: any) => {
    saveBuildingBlock({
      ...values,
      isTemplate: false,
      id: buildingBlock?.id || undefined,
      executorId: toInteger(
        buildingBlockState?.executor?.id || buildingBlock?.executor?.id,
      ),
      districtId: toInteger(values.districtId),
      // constructionObject,
      title: buildingBlockState.title || buildingBlock?.title,
      attachments: buildingBlockState.attachments?.map((item) => {
        return {
          type: item.type,
          extension: item.extension,
          name: item.name,
          attachmentId: toInteger(item.attachmentId),
          filePath: item.filePath,
          description: item.description || '',
        };
      }),
      systemId: toInteger(values.systemId),
    })
      .unwrap()
      .then((data: any) => {
        navigate(generatePath(Routes.BuildingBlock, { id: data.id }));
        setBuildingBlockAction(data);
        notification.success({
          message: 'Вы успешно сохранили задачу',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Не удалось сохранить задачу',
        });
      });
  };

  const isSaveDisabled = useMemo(() => {
    const isStateEmpty = (state: any) =>
      isEmpty(state.constructionObject) || isEmpty(state.executor);

    return (
      (isStateEmpty(buildingBlockState) && isStateEmpty(buildingBlock || {})) ||
      (buildingBlock && buildingBlock?.status !== TaskStatusEnum.draft)
    );
  }, [buildingBlock, buildingBlockState]);

  useEffect(() => {
    const setFormFields = (data: any) => {
      const dataClone = cloneDeep(data);
      dataClone.directionId = toInteger(dataClone.direction?.id);
      dataClone.regionId = toInteger(dataClone.region?.id);
      dataClone.districtId = toInteger(dataClone.district?.id);
      dataClone.systemId = dataClone.system?.id;
      dataClone.hydroFacilityId = toInteger(
        dataClone?.system.hydroFacility?.id,
      );
      dataClone.hydroFacilityId = toInteger(
        dataClone?.system.hydroFacility?.id,
      );
      if (dataClone.constructionObject) {
        dataClone.constructionObject.objectGroupId = toInteger(
          dataClone?.constructionObject.objectGroup?.id,
        );
        dataClone.constructionObject.objectCategoryId = toInteger(
          dataClone?.constructionObject?.objectCategory?.id,
        );
      }
      form.setFieldsValue(dataClone);
    };
    if (buildingBlock) {
      setFormFields(buildingBlock);
    } else if (buildingBlockState.isTemplate) {
      setFormFields(buildingBlockState);
    }
    form.setFieldsValue({
      ...(buildingBlockState || buildingBlock),
    });
  }, [buildingBlock, buildingBlockState]);

  const handleChangeRegion = () => {
    form.resetFields(['districtId', 'systemId']);
  };
  const handleChangeDisctrict = () => {
    form.resetFields(['systemId', 'systemObjectId']);
  };

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <CustomCard>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ ...buildingBlock }}
        className={styles.form}
      >
        <Row justify="start" gutter={20}>
          <Col flex={10}>
            <Typography.Title level={4} className={styles.title}>
              Создание задачи
            </Typography.Title>
            <Divider className={styles.divider} />
          </Col>
          <Col flex={2}>
            <Row gutter={8} justify="end">
              <Col>
                <DefaultButton
                  onClick={createTemplate}
                  disabled={isSaveDisabled}
                >
                  Создать шаблон
                </DefaultButton>
              </Col>
              <Col>
                <DefaultButton htmlType="submit" disabled={isSaveDisabled}>
                  Сохранить
                </DefaultButton>
              </Col>
              <Col>
                <PrimaryButton
                  onClick={createTaskHandle}
                  disabled={
                    !buildingBlock?.id ||
                    isSaveDisabled ||
                    buildingBlock.status !== TaskStatusEnum.draft
                  }
                >
                  Завершить
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.cardBody}>
          <PriorityGroup
            rules={[{ required: true }]}
            disabled={isEditingDisabled}
          />
          <AddExecutorButton
            setShow={() => setShowAssignExecutorModalAction(true)}
            cardBlockType={
              buildingBlock?.executor || buildingBlockState.executor
            }
            disabled={isEditingDisabled}
          />
          <Form.Item
            rules={[{ required: true }]}
            name="directionId"
            label="Направление"
            required
            style={{ marginTop: 20 }}
          >
            <TooltipedSelect
              options={directions.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              placeholder="Направление"
              disabled={isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="regionId"
            required
            label="Область"
            initialValue={user?.owner?.region?.id}
          >
            <TooltipedSelect
              loading={regionIsLoading}
              onChange={handleChangeRegion}
              options={regions.map((item) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
              placeholder="Область"
              disabled={isDisabledRegion || isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="districtId"
            required
            label="Район"
            initialValue={user?.owner?.district?.id}
          >
            <TooltipedSelect
              disabled={isDisabledDistrict || !regionId || isEditingDisabled}
              onChange={handleChangeDisctrict}
              options={districtByParentId.map((item: any) => ({
                ...item,
                label: item.title,
                value: item.id,
              }))}
              placeholder="Район"
            />
          </Form.Item>
          <Form.Item
            className={styles.selectGroup__item}
            rules={[{ required: true }]}
            name="hydroFacilityId"
            label="Гидроучасток"
            required
          >
            <TooltipedSelect
              options={hydroFacilities}
              onChange={handleChangeHydroFacility}
              placeholder="Гидроучасток"
              size="large"
              disabled={!districtId || !regionId || isEditingDisabled}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="systemId"
            required
            label="Система"
          >
            <TooltipedSelect
              disabled={!districtId || isEditingDisabled || !hydroFacilityId}
              options={systems}
              placeholder="Система"
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          {!isEmpty(
            buildingBlockState?.constructionObject ||
              buildingBlock?.constructionObject,
          ) ? (
            <div className={styles.objectForm}>
              <Row justify="space-between">
                <Typography.Text className={styles.title}>
                  Объект
                </Typography.Text>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Наименование объекта"
                    required
                    name={['constructionObject', 'name']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      disabled={isEditingDisabled}
                      size="large"
                      placeholder="Наименование объекта"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Код объекта"
                    required
                    name={['constructionObject', 'code']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedInput
                      disabled={isEditingDisabled}
                      size="large"
                      placeholder="Код объекта"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Группа"
                    required
                    name={['constructionObject', 'objectGroupId']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      disabled={isEditingDisabled}
                      size="large"
                      placeholder="Группа"
                      options={groups}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true }]}
                    label="Категория объекта"
                    required
                    name={['constructionObject', 'objectCategoryId']}
                  >
                    <TooltipedSelect
                      disabled={isEditingDisabled}
                      size="large"
                      placeholder="Категория объекта"
                      options={objectCategories}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Тип объекта"
                    required
                    name={['constructionObject', 'typeCategory']}
                    rules={[{ required: true }]}
                  >
                    <TooltipedSelect
                      disabled={isEditingDisabled}
                      size="large"
                      placeholder="Тип объекта"
                      options={objectTypes}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ) : (
            <UploadButtonSmall
              className={styles.uploadObject}
              onClick={() => setOpenModalObject(true)}
              disabled={isEditingDisabled}
            >
              Добавить объект*
            </UploadButtonSmall>
          )}

          <Form.Item
            name="taskDescription"
            label="Описание целей и задачи"
            rules={[{ required: true }]}
          >
            <TooltipedTextarea
              className=""
              placeholder="Описание задачи"
              autoSize={{ minRows: 3, maxRows: 10 }}
              disabled={isEditingDisabled}
            />
          </Form.Item>
        </div>
        <AddObjectModal setShow={setOpenModalObject} show={openModalObject} />
      </Form>
    </CustomCard>
  );
};
