import React from 'react';

import { Col, Divider, Row, Space, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import { ICalculationsByPlantingData } from '@models/annualApplication/interfaces/annualApplication';

import { CalculationEstimatedFinancialTable } from './calculationEstimatedFinancialTable/CalculationEstimatedFinancialTable';

type CalculationEstimatedFinancialPropTypes = {
  data: ICalculationsByPlantingData;
};

export const CalculationEstimatedFinancial: React.FC<
  CalculationEstimatedFinancialPropTypes
> = ({ data }) => {
  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <CustomCard
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4}>
                Расчет сметно-финансового плана по поливам
              </Typography.Title>
              <Divider style={{ margin: '0' }} />
            </Row>
          }
        >
          <Col span={24}>
            <Space size={8} direction="vertical" style={{ display: 'flex' }}>
              {data.costEstimateAndFinancialPlanCalculation.map((item) => (
                <CalculationEstimatedFinancialTable
                  item={item}
                  key={item.foodPlantId}
                />
              ))}
            </Space>
          </Col>
        </CustomCard>
      </Col>
    </Row>
  );
};
