import { useTranslation } from 'react-i18next';

import { Col, Row, Tag, Typography } from 'antd';

import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';
import {
  WaterUserEnum,
  WaterUserTypes,
} from '@models/waterUser/enums/waterUserEnums';

import styles from './styles.module.scss';

export function renderWateringPlanModalControl<RecordType extends JsonRecord>({
  customColumn,
  actionProps,
  record,
}: TableRendererProps<RecordType>) {
  const { t } = useTranslation();

  const tags = [
    {
      title: 'Карточка',
      action: actionProps?.showWaterUserCard,
      status: 'rgba(47, 84, 235, 1)',
    },
    {
      title: 'Годовая заявка',
      action: actionProps?.showAnnualApplication,
      status: 'rgba(22, 119, 255, 1)',
    },
    // {
    //   title: 'Декадная заявка',
    //   action: actionProps?.showPermissions,
    //   status: 'rgba(224, 88, 226, 1)',
    // },
  ];

  return (
    <Row gutter={[4, 0]}>
      <>
        {tags.map((item) => (
          <Col key={item.title}>
            <Tag
              key={item.title}
              color={item.status}
              bordered={false}
              style={{ marginInlineEnd: 0 }}
              className={styles.tag}
              onClick={() => item.action?.(record)}
            >
              <Typography.Text style={{ margin: '8px 0' }}>
                {item.title}
              </Typography.Text>
            </Tag>
          </Col>
        ))}
      </>
    </Row>
  );
}
