import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Upload } from 'antd';
import { DraggerProps } from 'antd/es/upload';

import { useUploadFilesMutation } from '@store/gisproApi';

import { PrimaryButton } from '@features/ui-kit/buttons';
import { attachFileUrl } from '../utils/attachFileUrl';
import styles from './PhotoUploader.module.scss';
import { CloudUpload } from '@icons/CloudUpload';

type PhotoUploaderProps = DraggerProps & {
  customRequest?: (file: any) => void;
  onLoad?: (file: any) => void;
  docType?: string;
  docFormat?: string;
};

export const PhotoUploader: FC<PhotoUploaderProps> = ({
  onLoad,
  docType,
  docFormat,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [uploadDocument] = useUploadFilesMutation();

  const customRequestUploader = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append(
      'dto',
      new Blob(
        [
          JSON.stringify({
            type: docType,

            originName: file.name,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    formData.append('file', file);
    try {
      const response: any = await uploadDocument(formData);
      onLoad?.(attachFileUrl(response.data));
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };
  return (
    <Upload.Dragger
      showUploadList={false}
      className={styles.dragger}
      customRequest={customRequestUploader}
      accept={docFormat}
      {...props}
    >
      <p className={styles.uploadHint}>
        <CloudUpload />
        <span>Перетащите файлы сюда или нажмите чтобы загрузить</span>
      </p>
      <Button
        type="primary"
        style={{
          fontSize: 15,
          marginTop: 20,
        }}
        size="large"
        className="buttonHint"
        disabled={props.disabled}
      >
        {t('customUploader.button')}
      </Button>
    </Upload.Dragger>
  );
};
