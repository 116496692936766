import { MONTH_DAYS, ONE_DAY } from '@constants/month';
import dayjs, { Dayjs } from 'dayjs';
import { toInteger } from '@utils/utils';

export interface IConvertToTimeStampArgs {
  values: {
    year: number;
    interval: string;
    period?: '1' | '7' | '30';
  };
  type: 'start' | 'end';
}

export const convertToTimeStamp = (args: IConvertToTimeStampArgs): number => {
  const {
    values: { interval, year, period },
    type,
  } = args;

  let baseDate: Dayjs;
  switch (period) {
    case '1':
      baseDate = dayjs(interval);
      break;
    case '7':
      baseDate = dayjs(interval);
      break;
    case '30':
      baseDate = dayjs().date(ONE_DAY).year(year).month(toInteger(interval));
      break;
    default:
      baseDate = dayjs().date(ONE_DAY).year(year).month(toInteger(interval));
      break;
  }

  const startDate = baseDate.unix();

  return type === 'start'
    ? startDate
    : baseDate.add(toInteger(period) || MONTH_DAYS, 'days').unix();
};
