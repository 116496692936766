import { FC, useMemo } from 'react';

import { Col, Row, Table } from 'antd';

import { ConclusionContractsTableMapper } from '@features/annualApplication/conclusionСontractsTable/conclusionСontractsTableContainer/ConclusionContractsTableMapper';
import { ConclusionContractsTableSummary } from '@features/annualApplication/conclusionСontractsTable/conclusionСontractsTableContainer/ConclusionСontractsTableSummary';
import { useConclusionContractsTableColumns } from '@features/annualApplication/conclusionСontractsTable/conclusionСontractsTableContainer/useConclusionСontractsTableColumns';
import { useConclusionContractsTableFilters } from '@features/annualApplication/conclusionСontractsTable/useConclusionContractsTableFilters';
import { ReservoirMonitoringMask } from '@features/monitoring/reservoirMonitoring/reservoirMonitoringMask/ReservoirMonitoringMask';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetConcludingAgreementQuery } from '@store/gisproApi';

import styles from './ConclusionСontractsTableContainer.module.scss';

export const ConclusionContractsTableContainer: FC = () => {
  const { columns } = useConclusionContractsTableColumns();

  const { filters } = useConclusionContractsTableFilters();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetConcludingAgreementQuery(
    filters.region && filters.startMonth && filters.endMonth && filters.year
      ? {
          region: filters.region || '',
          district: filters.district || '',
          startMonth: filters.startMonth || '',
          endMonth: filters.endMonth || '',
          year: filters.year || '',
        }
      : skipToken,
  );

  const isTableShow = useMemo(
    () =>
      Boolean(
        filters.region &&
          filters.startMonth &&
          filters.endMonth &&
          filters.year,
      ),
    [filters],
  );

  return (
    <Row justify="space-between" gutter={[0, 10]} className={styles.table}>
      {!isTableShow ? (
        <ReservoirMonitoringMask maskText="Для начала работы выберите область, район и даты" />
      ) : (
        <Row className={styles.table}>
          <Col span={24}>
            <Table
              size="small"
              dataSource={data || []}
              loading={isLoading || isFetching}
              columns={columns}
              bordered
              scroll={{ x: 500 }}
              pagination={false}
              summary={() => (
                <ConclusionContractsTableSummary
                  columns={columns}
                  data={data}
                />
              )}
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};
