import { ColumnsType } from 'antd/es/table';

import { Links } from '@enums/links';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { ACCESS } from '@enums/permisson';

export interface OtherTasksDataType {
  id: number;
  name: string;
  boss: string;
  executor: string;
  start: string;
  end: string;
  status: string;
  issue: string;
}

export const useOtherTasksTableColumns = (isArchived = false) => {
  const columns: TableColumn[] = [
    {
      title: '№',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
    },
    {
      title: 'Наименование',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Кем назначена',
      align: 'center',
      dataIndex: 'author.name',
      xtype: CustomTableColumnType.String,
      key: 'author.name',
    },
    {
      title: 'Исполнитель',
      align: 'center',
      dataIndex: 'executor.id',
      xtype: CustomTableColumnType.RenderCustom,
      key: 'executor.id',
      renderCustom: ({ record }) => (
        <div>
          <span>{record.executor.firstName || ''}</span>
          <span>{record.executor.middleName || ''}</span>
          <span>{record.executor.lastName || ''}</span>
        </div>
      ),
    },
    {
      title: 'Источник',
      align: 'center',
      dataIndex: 'system.name',
      xtype: CustomTableColumnType.String,
      key: 'system.name',
    },
    {
      title: 'Начало',
      align: 'center',
      dataIndex: 'planStartDate',
      key: 'planStartDate',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Конец',
      align: 'center',
      dataIndex: 'planEndDate',
      key: 'planEndDate',
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Статус',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      xtype: CustomTableColumnType.Status,
    },
    {
      title: 'Действия',
      align: 'center',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: !isArchived
        ? [CustomTableAction.Watch, CustomTableAction.ArchiveWithoutText]
        : [CustomTableAction.Watch, CustomTableAction.UnArchiveWithoutText],
      actionsAccess: [
        ACCESS.WORK_MANAGEMENT_OTHERTASK_CARD_EDIT,
        ACCESS.WORK_MANAGEMENT_OTHERTASK_ARCHIVE,
      ],
    },
  ];

  return { columns };
};
