import React, { FC } from 'react';

import { Table, Typography } from 'antd';

import { months, monthsTranslate, monthsTranslateU } from '@constants/month';
import { roundBigInteger } from '@utils/roundBigInteger';

interface ITotalKpdSummaryProps {
  data: any;
}

export const TotalKpdSummary: FC<ITotalKpdSummaryProps> = ({ data }) => {
  const kpdData = data?.kpd || [];
  const decades = [1, 2, 3];
  const renderCellValue = (month: string, decade: number) => {
    const monthTranslate = monthsTranslate[month];
    const foundData = kpdData.find(
      (item: any) =>
        item.month?.toUpperCase() === monthTranslate && item.decade === decade,
    );
    return foundData ? roundBigInteger(foundData.value, 1000) || 0 : 0;
  };

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} align="left">
          <Typography.Text strong>КПД межхоз сети</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="left" />
        <Table.Summary.Cell index={2} align="left">
          %
        </Table.Summary.Cell>
        {months.map((month, index) => (
          <React.Fragment key={month}>
            {decades.map((decade) => (
              <Table.Summary.Cell
                key={`${month}-${decade}`}
                index={index * 3 + decade + 2}
              >
                {renderCellValue(month, decade)}
              </Table.Summary.Cell>
            ))}
          </React.Fragment>
        ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
};
