import { Employees } from '@features/administration';
import { Layout } from 'antd';

export const EmployeesLayout: React.FC = () => {
  return (
    <Layout>
      <Employees />
    </Layout>
  );
};
