import { Divider as AntdDivider, DividerProps } from 'antd';

import styles from './Divider.module.scss';

type DividerPropTypes = DividerProps & {
  withoutMargin?: boolean;
};

export const Divider: React.FC<DividerPropTypes> = (props) => {
  return (
    <AntdDivider
      {...props}
      className={`${styles.divider} ${
        props?.withoutMargin && styles.withoutMargin
      }`}
    />
  );
};
