import { FC, SyntheticEvent } from 'react';

import { Col, Row } from 'antd';

import { TooltipedInput } from '../input';
import styles from './PicketInput.module.scss';

interface PicketInputPropTypes {
  isLinear: boolean;
  value?: any;
  onChange?: (value: string) => void;
  size?: 'small' | 'middle' | 'large';
  disabled?: boolean;
}

export const mapAnyPicketToLong = (picket: string): string =>
  [...picket.matchAll(/([0-9])/g)]
    .slice(0, 3)
    .map((m) => m[0])
    .join('')
    .toUpperCase();

enum PicketInputKeys {
  first = 0,
  second = 1,
  third = 2,
  fourth = 3,
}

export const PicketInput: FC<PicketInputPropTypes> = ({
  isLinear,
  onChange,
  value = '',
  size = 'middle',
  disabled = false,
}) => {
  const onSingleInputChange = (
    event: SyntheticEvent<HTMLInputElement>,
    key: PicketInputKeys,
  ) => {
    const matched = mapAnyPicketToLong(event.currentTarget?.value);

    let combinedValue = '';
    if (key === PicketInputKeys.first) {
      combinedValue = `${matched}+${value.split(/[:+]/)[1] || ''}:${value
        .split(/[:+]/)
        .slice(2)
        .join('+')}`;
    }
    if (key === PicketInputKeys.second) {
      combinedValue = `${value.split(/[:+]/)[0] || ''}+${matched}:${value
        .split(/[:+]/)
        .slice(2)
        .join('+')}`;
    }
    if (key === PicketInputKeys.third) {
      combinedValue = `${value
        .split(/[:+]/)
        .slice(0, 2)
        .join('+')}:${matched}+${value.split(/[:+]/)[3] || ''}`;
    }

    if (key === PicketInputKeys.fourth) {
      const parts = value.split(/[:+]/);
      parts[3] = matched;
      combinedValue = `${parts.slice(0, 2).join('+')}:${parts
        .slice(2)
        .join('+')}`;
    }
    if (!isLinear) {
      const [firstPart, secondPart] = combinedValue.split(':');
      combinedValue = firstPart;
    }

    onChange?.(combinedValue);
  };

  return (
    <Row gutter={2}>
      <Col flex="22% 1 1">
        <TooltipedInput
          disabled={disabled}
          size={size}
          value={value?.split(/[:+]/)[PicketInputKeys.first]}
          onChange={(e) => onSingleInputChange(e, PicketInputKeys.first)}
        />
      </Col>
      <div className={styles.divider}>+</div>
      <Col flex="22% 1 1">
        <TooltipedInput
          disabled={disabled}
          size={size}
          value={value?.split(/[:+]/)[PicketInputKeys.second]}
          onChange={(e) => onSingleInputChange(e, PicketInputKeys.second)}
        />
      </Col>
      {isLinear && (
        <>
          <div className={styles.divider}>:</div>
          <Col flex="22% 1 1">
            <TooltipedInput
              disabled={disabled}
              size={size}
              value={value?.split(/[:+]/)[PicketInputKeys.third]}
              onChange={(e) => onSingleInputChange(e, PicketInputKeys.third)}
            />
          </Col>
          <div className={styles.divider}>+</div>
          <Col flex="22% 1 1">
            <TooltipedInput
              disabled={disabled}
              size={size}
              value={value?.split(/[:+]/)[PicketInputKeys.fourth]}
              onChange={(e) => onSingleInputChange(e, PicketInputKeys.fourth)}
            />
          </Col>
        </>
      )}
    </Row>
  );
};
