import { MonthIndex } from '@constants/month';
import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { ACCESS } from '@enums/permisson';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

type ReturnType = {
  columns: TableColumn[];
};

export const useEconomicWaterAccountingTableColumns = ({
  month = MonthEnums.January,
  decade = DecadesEnum.First,
  decadeDay = DaysInDecadeEnum.FirstDaysPart,
  year,
}: {
  month: MonthEnums;
  decade: DecadesEnum;
  decadeDay: DaysInDecadeEnum;
  year: string;
}): ReturnType => {
  const day = (parseInt(decade, 10) - 1) * 10 + parseInt(decadeDay, 10) * 2;
  const dateStart = dayjs()
    .year(parseInt(year, 10))
    .month(MonthIndex[month])
    .date(day - 1)
    .format('YYYY-MM-DD');
  const dateEnd = dayjs()
    .year(parseInt(year, 10))
    .month(MonthIndex[month])
    .date(day)
    .format('YYYY-MM-DD');

  const dateColumns: TableColumn[] = [
    { date: dateStart, day: day - 1 },
    { date: dateEnd, day },
  ]
    .map((date) => {
      return [
        {
          title: date.date,
          dataIndex: `${date.day}`,
          xtype: CustomTableColumnType.WaterAccountingActionButtons,
          actions: [
            CustomTableAction.EnterMorningValues,
            CustomTableAction.EnterEveningValues,
          ],
          actionsAccess: [ACCESS.WATER_ACCOUNTING_HOUSEHOLD_DATETIME_SAVE],
        },
        {
          title: 'Отчет по рейке (время)',
          dataIndex: `${date.day}.time`,
          xtype: CustomTableColumnType.WaterAccountingValues,
        },
        {
          title: 'Отчет по рейке (см)',
          dataIndex: `${date.day}.height`,
          xtype: CustomTableColumnType.WaterAccountingValues,
        },
        {
          title: 'Уровень с учетом поправки',
          dataIndex: `${date.day}.level`,
          xtype: CustomTableColumnType.WaterAccountingValues,
        },
        {
          title: 'Расход л/сек',
          dataIndex: `${date.day}.flowRate`,
          xtype: CustomTableColumnType.WaterAccountingValues,
        },
        {
          title: 'Средний показатель',
          dataIndex: `${date.day}.average`,
          xtype: CustomTableColumnType.String,
        },
      ];
    })
    .flat();

  const columns = [
    {
      title: '№',
      dataIndex: 'index',
      width: 50,
      xtype: CustomTableColumnType.String,
    },
    {
      title: 'Ирригационная система / Гидропост / Водовыпуск',
      dataIndex: 'name',
      key: 'name',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WaterUsers],
      width: 180,
    },
    ...dateColumns,
  ];

  return { columns };
};
