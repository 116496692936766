import { PasteIcon } from '@icons/PasteIcon';
import { NewsEntityDto } from '@store/api/newsApi';

import { Button, Typography } from 'antd';
import dayjs from 'dayjs';

import styles from './ImportantList.module.scss';

export const ImportantItem: React.FC<NewsEntityDto> = ({
                                                         content,
                                                         title,
                                                         createdAt,
                                                       }) => {
  return (
    <div className={styles.item}>
      <div className={styles.textBlock}>
        <Typography.Title
          style={{ marginBottom: 0, color: '#3F4778' }}
          level={4}
        >
          {title}
        </Typography.Title>
        <Typography.Text>{content}</Typography.Text>
      </div>
      <p className={styles.date}>
        {dayjs(createdAt).format('DD.MM.YYYY')}
      </p>
      <Button icon={<PasteIcon/>} className={styles.copyBtn} type="link"/>
    </div>
  );
};
