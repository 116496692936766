import { List, ListItem, TooltipedInput } from '@features/ui-kit';
import { AttributeItem } from '@features/geoPortal/new/geoPortalMap/geoPortalMapAttributes/attributesList/attributeItem/AttributeItem';
import {
  GeoAttribute,
  ILayerDto,
} from '@models/geoPortal/interfaces/GeoPortal';
import {
  useMapControllerGetDataQuery,
  useMapControllerSearchQuery,
} from '@store/api/geoPortalApi';
import { useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { debounce } from '@utils/utils';

type AttributesListProps = {
  layer: ILayerDto | null;
};

export const AttributesList: React.FC<AttributesListProps> = ({ layer }) => {
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;
  const [filterValue, setFilterValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const paramData = searchParams.get('startDate');
  const {
    setCurrentLayer,
    focusedAttribute,
    editableAttribute,
    onPointClick,
    onSaveClick,
    onReloadClick,
    onCancelClick,
    onEditClick,
    openDetails,
    searchActive,
    searchValue,
    isPreviewMap,
    groupArchiveLayer,
  } = useContext(MapContext);
  const {
    data,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
  } = useMapControllerGetDataQuery(
    nameParamArchive !== 'archive' && layer
      ? {
          layerName: layer?.layerName,
          mapName: filterKey,
          startDate: paramData,
        }
      : skipToken,
  );

  const {
    data: searchLayers,
    isLoading: isLoadingSearch,
    isFetching: isFetchingSearch,
  } = useMapControllerSearchQuery(
    filterKey && searchActive && searchValue && nameParamArchive !== 'archive'
      ? {
          mapName: filterKey,
          query: searchValue,
        }
      : skipToken,
  );

  let dataMutation = data;
  let searchLayersMutation = searchLayers;

  if (nameParamArchive === 'archive') {
    dataMutation = layer?.attributeList;

    const groupArchiveLayerMutation = groupArchiveLayer
      .map((item) => item.layers)
      .flat(1)
      .map((item) => item.attributeList)
      .flat(1);

    searchLayersMutation = groupArchiveLayerMutation.filter((item) =>
      item.name.startsWith(searchValue),
    );
  }

  useEffect(() => {
    if (layer && dataMutation) {
      setCurrentLayer(layer, dataMutation);
    }
  }, [dataMutation]);

  const dataSource = searchActive ? searchLayersMutation : dataMutation;

  const onChange = (e: any) => {
    setFilterValue(e.target.value);
  };
  const debounceOnChange = debounce(onChange, 250);

  const filterDataSource = useMemo(() => {
    if (!filterValue) return dataSource;
    const lowercasedFilter = filterValue.toLowerCase();
    const keysToFilter = [
      'name',
      'hydroFacilityName',
      'irrigationSystemName',
      'baseName',
      'associationTypeName',
      'businessActivityName',
    ];

    return dataSource?.filter((item): any =>
      keysToFilter.some((key) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        item[key]?.toLowerCase().includes(lowercasedFilter),
      ),
    );
  }, [filterValue, dataSource]);

  return useMemo(
    () => (
      <>
        <TooltipedInput
          style={{ marginBottom: '10px' }}
          onChange={debounceOnChange}
          placeholder="Поиск"
        />
        <List
          style={{ overflow: 'auto', flex: '1' }}
          itemLayout="horizontal"
          loading={
            isLoadingData ||
            isLoadingSearch ||
            isFetchingData ||
            isFetchingSearch
          }
          dataSource={filterDataSource || []}
          renderItem={(item, index) => (
            <ListItem key={index}>
              <AttributeItem
                filterKey={filterKey}
                isPreviewMap={isPreviewMap}
                attribute={item as unknown as GeoAttribute}
                layer={layer}
                index={index + 1}
                editableAttribute={editableAttribute}
                focusedAttribute={focusedAttribute}
                onReloadClick={onReloadClick}
                onEditClick={onEditClick}
                onPointClick={onPointClick}
                onSaveClick={onSaveClick}
                onCancelClick={onCancelClick}
                openDetails={openDetails}
              />
            </ListItem>
          )}
        />
      </>
    ),
    [
      filterDataSource,
      editableAttribute,
      focusedAttribute,
      isPreviewMap,
      layer,
      data,
      searchLayers,
    ],
  );
};
