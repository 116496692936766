import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { normalizeValue } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/normalizeValue';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsOutletsComponent: FC = () => {
  const { data: managementMethods = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.ControlMethodTypes,
    });

  const { data: facings = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: formTypes = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  // TODO: add types of trees
  // const { data: typesOfTrees = [] } = useReferenceControllerFindBycodeQuery({
  //   id: EntityTypesEnum.Types,
  // });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col>
          <CustomCard title="Системные показатели" style={{ marginTop: 8 }}>
            <Row gutter={[16, 16]}>
              <Col span={9}>
                <CustomCard title={null} dashed>
                  <Row gutter={16} align="bottom">
                    <Col span={8}>
                      <Form.Item
                        name={['status', 'bandwidth']}
                        label="Пропускная способность (м3/с)"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name={['status', 'maxWaterLevel']}
                        label="Макс. уровень воды"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name={['status', 'minWaterLevel']}
                        label="Мин. уровень воды"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
              <Col span={15}>
                <CustomCard title={null} dashed>
                  <Row gutter={16} align="bottom">
                    <Col span={5}>
                      <Form.Item
                        name={['status', 'facingId']}
                        label="Облицовка"
                      >
                        <TooltipedSelect
                          options={facings}
                          size="large"
                          placeholder="Выберите облицовку"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item name={['status', 'formId']} label="Форма">
                        <TooltipedSelect
                          size="large"
                          options={formTypes}
                          placeholder="Выберите форму"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        name={['status', 'managementMethodsId']}
                        label="Методы управления"
                      >
                        <TooltipedSelect
                          size="large"
                          options={managementMethods}
                          placeholder="Выберите метод"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="direction">
                        <PropertyBlock
                          icon={ConstructionIcon}
                          label="Направление"
                          type="radio"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>

              <Col span={24}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={5}>
                      <Form.Item name={['status', 'widthW']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Ширина, м (B)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item name={['status', 'bottomWidthb']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Ширина по дну (b) (м)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item name={['status', 'heightH']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Высота (h) (м)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item name={['status', 'constructionHeight']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Высота строения (H стр.) (м)"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        name={['status', 'lengthL']}
                        label="Протяженность (м)"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите протяженность"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        name={['status', 'diameterD']}
                        label="Диаметр (м)"
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={['status', 'manualKpd']}
                        label="КПД"
                        rules={[{ required: true }]}
                        normalize={normalizeValue}
                      >
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
