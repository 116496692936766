import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import {
  CustomModal,
  DangerButton,
  PrimaryButton,
  TooltipedInput,
} from '@features/ui-kit';
import { DeleteIcon } from '@icons/DeleteIcon';
import { BankDto } from '@store/gisproApi';

import { useAddBank } from '../../useAddBank';
import styles from './AddBankModal.module.scss';

type PropsType = {
  onClose: () => void;
};

export const AddBankModal: React.FC<PropsType> = ({ onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { addBank, isModalShown } = useAddBank();

  const onAddBankClick = useCallback(() => {
    try {
      const values: BankDto = form.getFieldsValue();
      addBank(values);
      onClose();
      form.resetFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }, [form]);

  return (
    <CustomModal
      centered
      title={t('addBank.title')}
      open={isModalShown}
      onCancel={onClose}
      width={370}
      footer={false}
      closeIcon={
        <div>
          <DangerButton icon={<CloseOutlined />} />
        </div>
      }
    >
      <Form<BankDto>
        form={form}
        autoComplete="off"
        layout="vertical"
        className={styles.form}
      >
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label={t('addBank.placeholders.bankName')}
              name="bankName"
              required
            >
              <TooltipedInput placeholder="Наименование банка*" size="large" />
            </Form.Item>
          </Col>
          <Col>
            <PrimaryButton
              type="primary"
              size="large"
              htmlType="submit"
              onClick={onAddBankClick}
            >
              {t('addBank.buttons.save')}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};
