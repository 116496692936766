import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import { SectionQueryKey } from '@constants/queryKeys';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import { DefaultButton, Divider } from '@features/ui-kit';
import { CustomSteps } from '@features/ui-kit/customSteps';
import {
  IndividualMasterRegistrationFirstStep,
  IndividualMasterRegistrationSecondStep,
  IndividualMasterRegistrationThirdStep,
} from '@features/waterRegistry';
import { AnnualApplicationQueryTypes } from '@models/annualApplication/enums/annualApplicationEnum';
import { CreateIndividualWaterRegistryQueryTypes } from '@models/waterUser/enums/waterUserEnums';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const IndividualWaterUserMasterRegistrationLayout: FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { setHeaderTitle } = useHeaderTitle();

  const queryKey = useMemo(() => params.get(SectionQueryKey), [params]);

  const steps = [
    {
      value: CreateIndividualWaterRegistryQueryTypes.waterUserInfo,
      title: 'Данные водопользователя',
    },
    {
      value: CreateIndividualWaterRegistryQueryTypes.create,
      title: 'Создание хозяйства',
    },
    {
      value: CreateIndividualWaterRegistryQueryTypes.agreement,
      title: 'Соглашение',
    },
  ];

  let Component;
  switch (queryKey) {
    case CreateIndividualWaterRegistryQueryTypes.waterUserInfo:
      Component = IndividualMasterRegistrationFirstStep;
      break;
    case CreateIndividualWaterRegistryQueryTypes.create:
      Component = IndividualMasterRegistrationSecondStep;
      break;
    case CreateIndividualWaterRegistryQueryTypes.agreement:
      Component = IndividualMasterRegistrationThirdStep;
      break;
    default:
      Component = IndividualMasterRegistrationFirstStep;
      break;
  }

  useEffect(() => {
    setHeaderTitle('Регистрация водопользователя Физ. лица');
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={16}>
          <Col>
            <DefaultButton
              onClick={() => navigate(Routes.WaterRegistryUsers)}
              className="backBtn"
            >
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Регистрация водопользователя Физ. лица',
                },
              ]}
            />
          </Col>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
        <CustomSteps
          queryParams={{
            queryType: AnnualApplicationQueryTypes,
            queryKey: SectionQueryKey,
          }}
          steps={steps}
        />
        <Divider />
        <Component />
      </Col>
    </Row>
  );
};
