import { FC } from 'react';

import { Col, Row, Typography, Divider } from 'antd';

import { CustomCard,  } from '@features/ui-kit';

type MainConduitInfoPropTypes = {
  mainConduitInfo: {
    systemName?: string,
    objectName?: string,
    districtName?: string,
    baseName?: string,
  }
}

export const MainConduitInfo: FC<MainConduitInfoPropTypes> = ({ mainConduitInfo }) => {
  return (
      <CustomCard title="Информация">
        <Row>
          <Col span={5}>
            <div>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Наименование системы:</Typography.Text>
            </div>
            <div>
              <Typography.Text>{mainConduitInfo?.systemName}</Typography.Text>
            </div>
          </Col>

          <Col span={1}>
            <Divider style={{ height: '2.9em' }} orientationMargin={0} type="vertical"/>
          </Col>

          <Col span={5}>
            <div>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Наименование водовода:</Typography.Text>
            </div>
            <div>
              <Typography.Text>{mainConduitInfo?.objectName}</Typography.Text>
            </div>
          </Col>

          <Col span={1}>
            <Divider style={{ height: '2.9em' }} orientationMargin={0} type="vertical"/>
          </Col>

          <Col span={5}>
            <div>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Район:</Typography.Text>
            </div>
            <div>
              <Typography.Text>{mainConduitInfo?.districtName}</Typography.Text>
            </div>
          </Col>

          <Col span={1}>
            <Divider style={{ height: '2.9em' }} orientationMargin={0} type="vertical"/>
          </Col>

          <Col span={5}>
            <div>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.6)" }}>Источник:</Typography.Text>
            </div>
            <div>
              <Typography.Text>{mainConduitInfo?.baseName}</Typography.Text>
            </div>
          </Col>
        </Row>
      </CustomCard>
  );
};
