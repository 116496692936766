import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SectionQueryKey } from '@constants/queryKeys';
import { Routes } from '@enums/routes';
import { AssociationWaterUserMasterRegistrationLayout } from '@features/layout/waterRegistry';
import {
  AddBankModal,
  AddBranchModal,
  AddEmployeeModal,
  AssociationSelectModal,
  EditEmployeeModal,
  useActionSelect,
  useAddBank,
  useAddBranch,
  useWaterUserPermissionModal,
} from '@features/waterRegistry/masterRegistration';
import { useWaterUserId } from '@features/waterRegistry/masterRegistration/useWaterUserId';
import { AssociationPermissionsModal } from '@features/waterUser/associationPermissionsModal';
import { AssociationStatsModal } from '@features/waterUser/associationStatsModal';
import { WaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

export const AssociationWaterUserMasterRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const { setShowModal: setShowAddBankModal } = useAddBank();
  const { setShowModal: setShowAddBranchModal } = useAddBranch();
  const { setShowModal: setShowActionSelectModal } = useActionSelect();
  const { waterUserId, setUserId } = useWaterUserId();
  const { setShowModalAssociation } = useWaterUserPermissionModal();

  const onComplete = () => {
    navigate({
      pathname: Routes.WaterRegistryUsers,
      search: `?${SectionQueryKey}=${WaterUserTypes.association}`,
    });
    setShowActionSelectModal(false);
  };
  const onViewCard = () => {
    navigate(
      generatePath(Routes.WaterUserCard, {
        id: String(waterUserId),
      }),
    );
    setUserId('');
    setShowActionSelectModal(false);
  };

  const onAssociationStatsOpenHandle = () => {
    setShowActionSelectModal(false);
  };

  return (
    <div>
      <AssociationWaterUserMasterRegistrationLayout />
      <AddEmployeeModal />
      <EditEmployeeModal />
      <AddBankModal onClose={() => setShowAddBankModal(false)} />
      <AddBranchModal onClose={() => setShowAddBranchModal(false)} />
      <AssociationSelectModal
        onPermissionsOpen={() => {
          setShowModalAssociation(true);
        }}
        onAssociationStatsOpen={onAssociationStatsOpenHandle}
        onComplete={onComplete}
        onViewCard={onViewCard}
      />
      <AssociationPermissionsModal />
      <AssociationStatsModal />
    </div>
  );
};
