import { createSelector } from '@reduxjs/toolkit';
import { WaterAccountingState } from '@store/slices';

type WithWaterAccountingState = {
  waterAccounting: WaterAccountingState;
};

export const waterAccountingStateSelector = (
  state: WithWaterAccountingState,
): WaterAccountingState => state.waterAccounting;

export const getShowAccountingEditSelector = createSelector(
  waterAccountingStateSelector,
  (waterAccountingState) => {
    return waterAccountingState.isShowQuentityEdit;
  },
);

export const getWaterUseFiltersSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.waterSupplyFilters;
  },
);

export const getMonthFilterSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.selectedMonth;
  },
);

export const getDecadeFilterSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.selectedDecade;
  },
);

export const getDecadeDayFilterSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.selectedDecadeDay;
  },
);

export const getIsShowEnterMorningValuesModal = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.isShowEnterMorningValuesModal;
  },
);

export const getIsShowEnterEveningValuesModal = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.isShowEnterEveningValuesModal;
  },
);

export const getSelectedMorningRecrod = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.selectedMorningRecord;
  },
);

export const getSelectedEveningRecrod = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.selectedEveningRecord;
  },
);

export const getShowWaterLogPrintSelector = createSelector(
  waterAccountingStateSelector,
  (decadeAppState) => {
    return decadeAppState.isShowWaterLogPrintModal;
  },
);

export const getShowBalanceSheetPrintSelector = createSelector(
  waterAccountingStateSelector,
  (decadeAppState) => {
    return decadeAppState.isShowBalanceSheetPrintModal;
  },
);

export const getShowEconomicWaterPrintSelector = createSelector(
  waterAccountingStateSelector,
  (decadeAppState) => {
    return decadeAppState.isShowEconomicWaterPrintModal;
  },
);

export const getWaterAccountingBalanceSheetFiltersSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.waterAccountingBalanceSheetFilters;
  },
);

export const getWaterAccountingWaterLogFiltersSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.waterAccountingWaterLogFilters;
  },
);
export const getWaterAccountingEconomicFiltersSelector = createSelector(
  waterAccountingStateSelector,
  (watterAccountingState) => {
    return watterAccountingState.waterAccountingEconomicFilters;
  },
);
