import { DecadeStartDate } from '@constants/decades';
import { MonthIndex } from '@constants/month';
import { DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { formatDecimal } from '@utils/formatNumber/formatNumber';
import dayjs from 'dayjs';

type ReturnType = {
  data: any;
};

export const mapWaterLogAccnountingTable = (data: any) => {
  const dataSource = data?.map((item: any, index: number) => {
    // TODO: refactor this
    const res: any = {
      name: item.name,
      index: index + 1,
    };
    item.days?.map((dayArr: any) => {
      res[`${dayArr.day}.day`] = dayArr.day;
      res[`${dayArr.day}.id`] = dayArr.id;
      res[`${dayArr.day}.average`] = formatDecimal(dayArr.average);
      res[`${dayArr.day}.flowRate`] = formatDecimal(dayArr.average);
      res.outletId = item.outletId;
      res.totalFactFlowRate = formatDecimal(
        (res.totalFactFlowRate || 0) + +dayArr.average,
      );
      res.totalFactStock = formatDecimal(res.totalFactFlowRate * 0.864);
      return res;
    });
    item.days?.map((dayArr: any) => {
      res.averageFactFlowRate = formatDecimal(
        res.totalFactFlowRate / item.days.length,
      );
      res.averageFactStock = formatDecimal(
        res.totalFactStock / item.days.length,
      );
      return res;
    });
    return res;
  });

  return { data: dataSource };
};
