import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { DefaultButton, Divider } from '@features/ui-kit';
import {
  Breadcrumb as AntdBreadcrumb,
  BreadcrumbProps,
  Flex,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';
import { useSelector } from 'react-redux';
import { getReturnUrl } from '@store/selectors/header';
import { clearUrl } from '@utils/returnUrl';

type BreadcrumbPropTypes = BreadcrumbProps & {
  returnButton?: boolean;
  hideDivider?: boolean;
};

export const Breadcrumb: React.FC<BreadcrumbPropTypes> = (props) => {
  const navigate = useNavigate();
  const returnUrl = useSelector(getReturnUrl);
  const clearAction = clearUrl();

  const hasReturnUrl = props.returnButton || returnUrl;

  const items = [
    {
      title: <HomeOutlined />,
    },
    ...(props.items || []),
  ];
  const gotoBack = () => {
    if (!returnUrl) {
      navigate(-1);
      return;
    }

    navigate(returnUrl);
    clearAction();
  };
  return (
    <>
      <Flex>
        <Space size={16}>
          {hasReturnUrl ? (
            <DefaultButton onClick={gotoBack} className={styles.backBtn}>
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          ) : null}
          <AntdBreadcrumb className={styles.breadcrumb} items={items} />
        </Space>
        <div style={{ flex: 1 }}></div>
        {props.children}
      </Flex>
      {props.hideDivider ? null : <Divider style={{ margin: '6px 0 8px' }} />}
    </>
  );
};
