import { useCallback, useEffect } from 'react';

import { Col, Form, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { ICreateHydraulicSection } from '@models/hydraulicSection/interfaces/hydraulicSection';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateHydraulicSectionMutation,
  useFindDictionaryByTypeIdQuery,
  useFindReferencesByParentIdQuery,
} from '@store/gisproApi';

import { useCreateHydraulicSectionModal } from './useCreateHydraulicSectionModal';
import { useGetCurrentUser } from '@features/authentication';

export const CreateHydraulicSectionModal: React.FC = () => {
  const [form] = useForm();
  const { isShow, setShow } = useCreateHydraulicSectionModal();
  const [save, { data, error, isError }] = useCreateHydraulicSectionMutation();
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const regionId = Form.useWatch('regionId', form);

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });

  const { data: districts = [] } = useFindReferencesByParentIdQuery(
    regionId ? { parentId: parseInt(regionId, 10) } : skipToken,
  );

  const onCancel = () => {
    setShow(false);
  };

  const onFinish = useCallback((values: ICreateHydraulicSection) => {
    save(values);
  }, []);

  useEffect(() => {
    if (data) {
      onCancel();
    }
  }, [data]);

  return (
    <CustomModal
      footer={null}
      title="Создать гидроучасток"
      open={isShow}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          initialValue={user?.owner?.region?.id}
          name="regionId"
          label="Область"
          rules={[{ required: true }]}
        >
          <TooltipedSelect disabled={isDisabledRegion} options={regions} />
        </Form.Item>
        <Form.Item
          initialValue={user?.owner?.district?.id}
          name="districtId"
          label="Район"
          rules={[{ required: true }]}
        >
          <TooltipedSelect
            disabled={isDisabledDistrict || !regionId}
            options={districts}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Наименование гидроучастка"
          rules={[{ required: true }]}
        >
          <TooltipedInput />
        </Form.Item>
        <Row justify="end">
          <Col>
            <Space size={8}>
              <DefaultButton title="" onClick={onCancel}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>
        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Form>
    </CustomModal>
  );
};
