import { useTranslation } from 'react-i18next';

import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { ACCESS } from '@enums/permisson';
import styles from './AgroClimaticZoneTableContainer.module.scss';

export const useAgroClimaticZoneTableColumns = () => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      title: t('agroClimaticZone.columns.id'),
      dataIndex: 'id',
      key: 'id',
      xtype: CustomTableColumnType.NumberColumn,
      width: 47,
      className: styles.columns,
    },
    {
      title: t('agroClimaticZone.columns.name'),
      dataIndex: 'name',
      key: 'name',
      xtype: CustomTableColumnType.String,
      width: 1585,
      className: styles.columns,
    },
    {
      title: t('agroClimaticZone.columns.actions'),
      dataIndex: 'actAction',
      key: 'actAction',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText],
      actionsAccess: [ACCESS.AGROCLIMATIC_ZONE_VIEW],
      width: 144,
    },
  ];

  return { columns };
};
