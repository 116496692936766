import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { Breadcrumb, Col, Form, Row, Typography, notification } from 'antd';

import { Routes } from '@enums/routes';
import {
  DangerButton,
  DefaultButton,
  Divider,
  InformationalBlock,
  PrimaryButton,
} from '@features/ui-kit';
import { IWaterObject } from '@models/waterObject/interfaces/waterObject';
import { useCreateSystemObjectListMutation } from '@store/gisproApi';
import { getCreateWaterObjectList } from '@store/selectors';
import {
  addWaterObjectToCreateWaterObjectListAction,
  clearWaterObjectToCreateWaterObjectListAction,
  editWaterObjectListAction,
} from '@store/slices';
import dayjs from 'dayjs';
import { toInteger } from '@utils/utils';

import { CreateWaterObjectTablePreview } from './createWaterObjectTablePreview';
import { IrrigationSystemInfo } from './irrigationSystemInfo';
import { WaterObjectInformation } from './waterObjectInformation';
import { DocumentUploader } from './documentUploader/documentUploader';
import { useEffect, useState } from 'react';
import { IrrigationSystemTableQueryTypes } from '@models/irrigationSystem/enums/irrigationSystemEnum';
import { generateTimestampId } from '@utils/generateTimestampId/generateTimestampId';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

export const CreateWaterObject = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [editId, setEditId] = useState('');
  const [saveWaterObjectList] = useCreateSystemObjectListMutation();
  const waterObjects: Array<Partial<IWaterObject>> = useSelector(
    getCreateWaterObjectList,
  );

  useEffect(() => {
    return () => {
      dispatch(clearWaterObjectToCreateWaterObjectListAction());
    };
  }, []);

  const onClose = () => {
    dispatch(clearWaterObjectToCreateWaterObjectListAction());

    const queryParams = new URLSearchParams({
      section: IrrigationSystemTableQueryTypes.waterObjects,
    }).toString();
    navigate(generatePath(`${Routes.IrrigationSystemTable}?${queryParams}`));
  };

  const onFinish = () => {
    try {
      if (!waterObjects.length) {
        return;
      }
      const updatedWaterObjects: Array<IWaterObject> = (
        waterObjects as Array<IWaterObject>
      ).map((item) => {
        const { index, id, ...waterObject } = item;
        return {
          ...waterObject,
          systemId: toInteger(waterObject.systemId),
          irrigationSystems: toInteger(waterObject.systemId),
          dateStart: dayjs(waterObject.dateStart).format('YYYY-MM-DD'),
        };
      });
      saveWaterObjectList(updatedWaterObjects).then(() => {
        dispatch(clearWaterObjectToCreateWaterObjectListAction());
        onClose();
        notification.success({
          message: 'Обьекты успешно созданы',
        });
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка',
      });
    }
  };

  const onSaveObject = async () => {
    try {
      const values: IWaterObject = await form.validateFields();
      if (editId) {
        dispatch(
          editWaterObjectListAction({
            ...values,
            attachmentIds: documents,
            index: waterObjects.length,
            systemId: toInteger(values.systemId),
            dateStart: dayjs(values.dateStart).utc().format('YYYY-MM-DD'),
            id: editId,
          }),
        );
      } else {
        dispatch(
          addWaterObjectToCreateWaterObjectListAction({
            ...values,
            attachmentIds: documents,
            index: waterObjects.length,
            systemId: toInteger(values.systemId),
            dateStart: dayjs(values.dateStart).utc().format('YYYY-MM-DD'),
            id: generateTimestampId(),
          }),
        );
      }
      setDocuments([]);
      form.resetFields();
      setEditId('');
      form.setFieldsValue({
        regionId: values.regionId,
        districtId: values.districtId,
        source: values.source,
        pool: values.pool,
        systemId: values.systemId,
      });
    } catch (err) {
      console.error('Err', err);
    }
  };

  return (
    <Row justify="space-between" style={{ display: 'flex' }}>
      <Col span={24}>
        <Row gutter={[0, 16]}>
          <Row gutter={16}>
            <Col>
              <DefaultButton onClick={() => navigate(-1)} className="backBtn">
                <LeftOutlined />
                <Typography.Text>Назад</Typography.Text>
              </DefaultButton>
            </Col>
            <Col>
              <Breadcrumb
                className="breadcrumb"
                items={[
                  {
                    title: <HomeOutlined />,
                  },
                  {
                    title: 'План водопользования',
                  },
                  {
                    title: 'Просмотр',
                  },
                ]}
              />
            </Col>
          </Row>
        </Row>
        <Divider style={{ margin: '6px 0 8px' }} />
      </Col>

      <Col span={24}>
        <InformationalBlock title="Создать объект">
          <Form<IWaterObject> layout="vertical" form={form}>
            <Row gutter={8}>
              <Col span={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <IrrigationSystemInfo />
                  </Col>
                  <Col span={24}>
                    <CreateWaterObjectTablePreview
                      setDocuments={setDocuments}
                      setEditId={setEditId}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <WaterObjectInformation />
                  </Col>
                  <DocumentUploader
                    documents={documents}
                    setDocuments={setDocuments}
                  />
                  <Col span={24}>
                    <Row gutter={15}>
                      <PrimaryButton block onClick={onSaveObject}>
                        {editId ? 'Изменить обьект' : 'Сохранить обьект'}
                      </PrimaryButton>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </InformationalBlock>
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <DangerButton size="large" onClick={onClose} htmlType="button">
              Отмена
            </DangerButton>
          </Col>
          <Col>
            <PrimaryButton size="large" htmlType="button" onClick={onFinish}>
              Сохранить
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
