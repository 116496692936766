import React from 'react';

interface Region {
  id: number;
  title: string;
  code: string;
  parentId: number | null;
  books: null;
}

interface District {
  id: number;
  title: string;
  code: string;
  parentId: number;
  books: null;
}

interface HydroStation {
  id: number;
  name: string;
  region: Region;
  district: District;
}

export function groupByRegionAndDistrict(hydroStations: HydroStation[]): any[] {
  return hydroStations.reduce((acc: any, station) => {
    const { region, district } = station;
    let regionIndex = acc.findIndex((item: any) => item?.id === region.id);
    if (regionIndex < 0) {
      /* eslint-disable no-param-reassign */
      acc = [
        ...acc,
        {
          ...region,
          label: region.title,
          children: [],
        },
      ];
      regionIndex = acc.length - 1;
    }
    let districtIndex = acc[regionIndex].children.findIndex((item: any) => {
      return item?.id === district.id;
    });
    if (districtIndex < 0) {
      if (!acc[regionIndex].children) {
        acc[regionIndex].children = [];
      }
      acc[regionIndex].children.push({
        ...district,
        label: district.title,
        children: [],
      });
      districtIndex = acc[regionIndex].children.length - 1;
    }
    acc[regionIndex].children[districtIndex].children.push({
      ...station,
      label: station.name,
    });
    return acc;
  }, []);
}
