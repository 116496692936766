import React from 'react';

import { Col, Divider, Modal, ModalProps, Row, Typography } from 'antd';

import styles from './CustomModal.module.scss';
import { DangerButton } from '../buttons';
import { CloseOutlined } from '@ant-design/icons';

type PropsType = ModalProps & { subtitle?: string };

export const CustomModal: React.FC<PropsType> = (props) => {
  const { subtitle, children } = props;
  const Title =
    typeof props?.title === 'string' ? (
      <Row gutter={16}>
        <Col flex={1}>
          <Typography.Title level={4}>{props?.title}</Typography.Title>
          <Divider className={styles.divider} />
        </Col>
        <Col>
          <DangerButton
            onClick={props.onCancel}
            className={styles.closeButton}
            icon={<CloseOutlined />}
          />
        </Col>
      </Row>
    ) : (
      props?.title
    );

  return (
    <Modal
      {...props}
      title={Title}
      className={`${styles.modal} ${props?.className}`}
      closeIcon={false}
    >
      <div
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '4px',
        }}
      >
        {children}
      </div>
    </Modal>
  );
};
