import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { WaterMainModalTypes } from '@models/waterMain/enums/modalTypeEnum';
import { getSelectedWaterSectionSelector } from '@store/selectors/waterObject';

import { useWaterObjectModals } from '../useWaterObjectModals';
import { CustomModal, PropertyBlock } from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';

export const TechStatsModal: React.FC = () => {
  const [form] = useForm();
  const waterSection = useSelector(getSelectedWaterSectionSelector);

  const { isShowModal, setShowModal } = useWaterObjectModals(
    WaterMainModalTypes.TECH_STATS,
  );

  useEffect(() => {
    if (isShowModal) {
      form.setFieldsValue(waterSection);
    }
  }, [isShowModal]);

  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  return (
    <CustomModal
      centered
      footer={false}
      onCancel={onClose}
      title="Технические характеристики"
      open={isShowModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="maxFlowRate">
          <PropertyBlock
            icon={SizesIcon}
            label="Максимальный расход (в начале участка)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="flowRate">
          <PropertyBlock
            icon={SizesIcon}
            label="Скорость потока (м3)"
            type="input"
          />
        </Form.Item>

        <Form.Item name="permeability">
          <PropertyBlock icon={SizesIcon} label="Проницаемость" type="input" />
        </Form.Item>

        <Form.Item name="efficiency">
          <PropertyBlock icon={SizesIcon} label="КПД" type="input" />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};
