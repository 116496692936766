import { TableProps } from 'antd';

import { JsonRecord } from '@models/jsonRecord';

import { getKeyValue } from './getKeyValue';

type PropsType<RecordType> = {
  children: RecordType[];
  keyValue: React.Key;
  rowKey: TableProps<RecordType>['rowKey'];
};

export function dataTreeFind<RecordType extends JsonRecord>({
  children,
  keyValue,
  rowKey,
}: PropsType<RecordType>): RecordType | null {
  const item: RecordType | null = children.reduce(
    (prev: RecordType | null, current) => {
      if (prev) {
        return prev;
      }
      if (keyValue === getKeyValue(current, rowKey)) {
        return current;
      }
      if (current.children) {
        return dataTreeFind({
          children: current.children,
          keyValue,
          rowKey,
        });
      }

      return null;
    },
    null,
  );

  return item;
}
