import { FC, useRef } from 'react';

import { Input, Tooltip, TooltipProps } from 'antd';
import { InputProps } from 'antd/es/input';

import styles from './Input.module.scss';

type TooltipedSquaredInputV2PropsType = InputProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
};

export const TooltipedSquaredInputV2: FC<TooltipedSquaredInputV2PropsType> = ({
  tooltipProps,
  ...props
}) => {
  const ref = useRef(null);

  return (
    <Tooltip {...tooltipProps}>
      <Input {...props} ref={ref} className={styles.squaredInput} />
    </Tooltip>
  );
};
