import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IWaterObjectFilters } from '@models/waterObject/interfaces/waterObject';
import { getMonitoringPointsTableFilters } from '@store/selectors';
import { setMonitoringPointsTableFilters } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<any>;
  setPointsTableFilters: (filters: any) => void;
};

export const useMonitoringPointsTableFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getMonitoringPointsTableFilters);

  const setPointsTableFilters = useCallback(
    (newFilters: IWaterObjectFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);

      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));

      dispatch(setMonitoringPointsTableFilters(values as any));
    },
    [],
  );

  return { filters, setPointsTableFilters };
};
