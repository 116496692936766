import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getReferenceBookIdSelector } from '@store/selectors';
import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { Col, Row } from 'antd';
import styles from './ReferenceBookTable.module.scss';
import { useReferenceBookTableColumns } from './useReferenceBookTableColumns';
import { ReferenceBookCreateModal } from '../referenceBookCreateModal';
import {
  useDeleteCommonReferenceMutation,
  useFindObjectGroupByCodeQuery,
  useGetAllCommonReferencesListQuery,
} from '@store/gisproApi';
import { skipToken } from '@reduxjs/toolkit/query';

export const ReferenceBookTable: FC = () => {
  const item = useSelector(getReferenceBookIdSelector);
  const { data: group, isFetching } = useGetAllCommonReferencesListQuery(
    item?.code
      ? {
          typeCode: item?.code,
        }
      : skipToken,
  );

  const [deleteReference] = useDeleteCommonReferenceMutation();

  const [isModalShow, setShowModal] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const { columns } = useReferenceBookTableColumns(item?.parent);

  const handleEdit = (val: any) => {
    setInitialValues(val);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = ({ id }: { id: number }) => {
    deleteReference({ id });
  };

  return (
    <div className={styles.wrapper}>
      <CustomCard
        title={
          <Row justify="space-between" align="middle">
            <Col className={styles.title}>Таблица</Col>
            <Col>
              <PrimaryButton onClick={handleCreate}>Создать</PrimaryButton>
            </Col>
          </Row>
        }
      >
        <TableCustom
          className={styles.table}
          bordered
          size="small"
          columns={columns}
          dataSource={group || []}
          loading={isFetching}
          actionProps={{
            onHandleEdit: (val) => handleEdit(val),
            deleteItem,
          }}
        />

        {isModalShow && (
          <ReferenceBookCreateModal
            isModalShow={isModalShow}
            setShowModal={setShowModal}
            initialValues={initialValues}
          />
        )}
      </CustomCard>
    </div>
  );
};
