import { useGetGmrZoneListQuery } from '@store/gisproApi';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Typography } from 'antd';

import {
  CustomCard,
  DefaultButton,
  Divider,
  InformationalBlock,
  TooltipedInput,
} from '@features/ui-kit';
import { agroClimaticZoneActions } from '@store/slices';

import { CreateWateringRateModal } from './createWateringRateModal';
import { PlantingTable } from './plantingTable';
import { WateringRateEditor } from './wateringRateEditor';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';
import { Routes } from '@enums/routes';

export const IrrigationModeEditor: FC = () => {
  const { hydroModuleDistrictId, id } = useParams<{
    hydroModuleDistrictId: any;
    id: any;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: gmrs = [] } = useGetGmrZoneListQuery({
    agroclimaticzoneId: id,
  });
  const gmr = gmrs.find((item) => item.gmrId === Number(hydroModuleDistrictId));

  useEffect(() => {
    return () => {
      dispatch(agroClimaticZoneActions.setSelectedPlantingIdAction(null));
    };
  }, []);

  return (
    <>
      <Row gutter={[0, 16]}>
        <Row gutter={16}>
          <Col>
            <DefaultButton onClick={() => navigate(-1)} className="backBtn">
              <LeftOutlined />
              <Typography.Text>Назад</Typography.Text>
            </DefaultButton>
          </Col>
          <Col>
            <Breadcrumb
              className="breadcrumb"
              items={[
                {
                  title: <HomeOutlined />,
                },
                {
                  title: 'Агроклиматические зоны',
                },
                {
                  title: 'Редактор ГМР',
                },
              ]}
            />
          </Col>
        </Row>
      </Row>
      <Divider style={{ margin: '6px 0 8px' }} />
      <Row gutter={[6, 6]}>
        <Col>
          <TooltipedInput
            placeholder="Область"
            value={gmr?.regionName}
            disabled
          />
        </Col>
        <Col>
          <TooltipedInput
            placeholder="Район"
            value={gmr?.districtName}
            disabled
          />
        </Col>
        <Col span={24}>
          <InformationalBlock title="Режимы орошения">
            <CustomCard>
              <Row gutter={8}>
                <Col span={12}>
                  <PlantingTable />
                </Col>
                <Col span={12}>
                  <WateringRateEditor />
                </Col>
              </Row>
            </CustomCard>
          </InformationalBlock>
        </Col>
      </Row>
      <CreateWateringRateModal />
    </>
  );
};
