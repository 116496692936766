import { IrrigationSystemCardObjectTableContainer } from '@features/irrigationSystemCard/tables/irrigationSystemCardObjectTable/irrigationSystemCardObjectTableContainer/IrrigationSystemCardObjectTableContainer';
import { IrrigationSystemCardObjectTableControls } from '@features/irrigationSystemCard/tables/irrigationSystemCardObjectTable/irrigationSystemCardObjectTableControls/IrrigationSystemCardObjectTableControls';
import { Divider } from '@features/ui-kit';

export const IrrigationSystemCardObjectTable: React.FC = () => {
  return (
    <>
      <IrrigationSystemCardObjectTableControls />
      <Divider />
      <IrrigationSystemCardObjectTableContainer />
    </>
  );
};
