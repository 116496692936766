import { Layout } from 'antd';

import { IrrigationModeEditor } from '@features/agroClimaticZone/agroClimaticZoneCard/hydroModuleTable';

export const IrrigationEditorModeLayout: React.FC = () => {
  return (
    <Layout>
      <IrrigationModeEditor />
    </Layout>
  );
};
