import { useGetCurrentUser } from '@features/authentication';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Space } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';

import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { PrimaryButton, Segmented, TooltipedSelect } from '@features/ui-kit';
import { YearPicker } from '@features/ui-kit/yearPicker';
import { FilterIcon } from '@icons/FilterIcon';
import { ResetFilterIcon } from '@icons/ResetFilterIcon';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';
import dayjs, { Dayjs } from 'dayjs';

import { useWaterAccountingFilters } from '../../useWaterAccountingFilters';
import {
  ChannelQueryKey,
  DecadeDayQueryKey,
  DecadeQueryKey,
  HydroFacilityQueryKey,
  IrrigationSystemQueryKey,
  MonthQueryKey,
  OutletQueryKey,
  YearQueryKey,
} from '@constants/queryKeys';
import { useSearchParams } from 'react-router-dom';

export const WaterLogAccountingControls = () => {
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const { setFilters, filters } = useWaterAccountingFilters('waterLog');
  const [filterState, setFilterState] = useState<any>({});
  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: user?.owner?.region?.id,
    district: user?.owner?.district?.id,
  });
  const { data: systems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      filterState.hydroFacilityId
        ? {
            hydroFacilityId: filterState.hydroFacilityId,
          }
        : skipToken,
    );
  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    filterState.irrigationSystemId
      ? { id: filterState.irrigationSystemId }
      : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    filterState.channelId ? { id: filterState.channelId } : skipToken,
  );

  const monthOptions = useMemo(
    () =>
      Object.values(MonthEnums).map((month) => ({
        label: t(`months.${month}`),
        value: month,
      })),
    [MonthEnums],
  );

  const decadesOptions = useMemo(
    () =>
      Object.values(DecadesEnum).map((decade) => ({
        label: t(`decades.${decade}`),
        value: String(decade),
      })),
    [DecadesEnum],
  );

  const onFilterChange = (key: string, value: number) => {
    setFilterState((state: any) => ({
      ...state,
      [key]: value,
    }));
  };

  const changeMonthHandle = (v: any) => {
    setFilters({ ...filters, [MonthQueryKey]: v });
    params.set(MonthQueryKey, v as unknown as string);
    setParams(params);
  };

  const changeDecadeHandle = (v: any) => {
    setFilters({ ...filters, [DecadeQueryKey]: v });
    params.set(DecadeQueryKey, v as unknown as string);
    setParams(params);
  };

  const onFilterButtonClick = () => {
    if (filterState.hydroFacilityId) {
      params.set(HydroFacilityQueryKey, filterState.hydroFacilityId);
    }
    if (filterState.irrigationSystemId) {
      params.set(IrrigationSystemQueryKey, filterState.irrigationSystemId);
    }
    if (filterState.channelId) {
      params.set(ChannelQueryKey, filterState.irrigationSystemId);
    }
    if (filterState.outletId) {
      params.set(OutletQueryKey, filterState.outletId);
    }
    setParams(params);
    setFilters({
      ...filters,
      ...filterState,
    });
  };

  const onResetFilterButtonClick = () => {
    setFilters({
      ...filters,
      year: dayjs().year(),
      channelId: undefined,
      hydroFacilityId: undefined,
      outletId: undefined,
      irrigationSystemId: undefined,
    });
    params.delete(HydroFacilityQueryKey);
    params.delete(IrrigationSystemQueryKey);
    params.delete(ChannelQueryKey);
    params.delete(OutletQueryKey);
    setParams(params);
    setFilterState({});
  };

  useEffect(() => {
    const selectedDecade = params.get(DecadeQueryKey) || DecadesEnum.First;
    const selectedDecadeDay =
      params.get(DecadeDayQueryKey) || DaysInDecadeEnum.FirstDaysPart;
    const selectedMonth = params.get(MonthQueryKey) || MonthEnums.January;
    const selectedYear = params.get(YearQueryKey) || dayjs().year();
    const newFilters = {
      ...filters,
      month: selectedMonth as any,
      decade: selectedDecade as any,
      decadeDays: selectedDecadeDay as any,
      year: selectedYear,
    };

    const selectedHydroFacilityId = params.get(HydroFacilityQueryKey);
    if (selectedHydroFacilityId) {
      newFilters.hydroFacilityId = parseInt(selectedHydroFacilityId);
    }
    const selectedIrrigationSystemId = params.get(IrrigationSystemQueryKey);
    if (selectedIrrigationSystemId) {
      newFilters.irrigationSystemId = parseInt(selectedIrrigationSystemId);
    }
    const selectedChannelId = params.get(ChannelQueryKey);
    if (selectedChannelId) {
      newFilters.channelId = parseInt(selectedChannelId);
    }
    const selectedOutletId = params.get(OutletQueryKey);
    if (selectedOutletId) {
      newFilters.outletId = parseInt(selectedOutletId);
    }

    setFilters(newFilters);
    setFilterState(newFilters);
  }, []);

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Space>
              <YearPicker
                allowClear={false}
                placeholder="Год"
                defaultValue={dayjs()}
                disabledDate={(current) =>
                  current && current > dayjs().endOf('year')
                }
                onChange={(date: Dayjs | null) =>
                  onFilterChange('year', date ? date.year() : dayjs().year())
                }
              />
              <TooltipedSelect
                popupMatchSelectWidth={300}
                options={hydroFacilities}
                onChange={(v: number) => onFilterChange('hydroFacilityId', v)}
                placeholder="Гидроучасток"
                value={filterState.hydroFacilityId}
                allowClear
              />
              <TooltipedSelect
                popupMatchSelectWidth={300}
                options={systems}
                onChange={(v: number) =>
                  onFilterChange('irrigationSystemId', v)
                }
                placeholder="Оросительная система"
                allowClear
                value={filterState.irrigationSystemId}
                disabled={!filterState.hydroFacilityId}
              />
              <TooltipedSelect
                popupMatchSelectWidth={300}
                options={channels}
                onChange={(v: number) => onFilterChange('channelId', v)}
                placeholder="Канал"
                allowClear
                value={filterState.channelId}
                disabled={!filterState.irrigationSystemId}
              />
              <TooltipedSelect
                popupMatchSelectWidth={300}
                onChange={(v: number) => onFilterChange('outletId', v)}
                options={outlets}
                placeholder="Водовыпуск"
                allowClear
                value={filterState.outletId}
                disabled={!filterState.channelId}
              />
              <PrimaryButton
                icon={<FilterIcon />}
                onClick={onFilterButtonClick}
              />
              <PrimaryButton
                icon={<ResetFilterIcon />}
                onClick={onResetFilterButtonClick}
              />
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Segmented
          options={monthOptions}
          onChange={changeMonthHandle}
          value={filters.month}
        />
      </Col>

      <Col span={24}>
        <Segmented
          options={decadesOptions}
          onChange={changeDecadeHandle}
          value={filters.decade}
        />
      </Col>
    </Row>
  );
};
