import React, { useEffect } from 'react';
import { InstructionTable } from './instructionTable';
import { Segmented } from '@features/ui-kit';
import { SegmentedValue } from 'antd/es/segmented';
import { useSearchParams } from 'react-router-dom';
import { langOptions, langsEnum } from '@constants/langOptions';

export const InstructionsManagement = () => {
  const [params, setSearchParams] = useSearchParams();

  const changeContentHandle = (value: SegmentedValue) => {
    const currentSearchParams = Object.fromEntries(params.entries());
    const updatedSearchParams = {
      ...currentSearchParams,
      lang: value as string,
    };
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    changeContentHandle(langsEnum.RU);
  }, []);

  return (
    <div>
      <Segmented
        options={langOptions}
        onChange={changeContentHandle}
        defaultValue={Object.fromEntries(params.entries()).lang || langsEnum.RU}
      />
      <InstructionTable />
    </div>
  );
};
