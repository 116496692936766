import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const TechCharBlueIcon: React.FC<IconProps> = () => {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.5 2.25184H7.6275C7.78245 1.81269 8.0698 1.43241 8.44995 1.16343C8.8301 0.894447 9.28432 0.75 9.75 0.75C10.2157 0.75 10.6699 0.894447 11.0501 1.16343C11.4302 1.43241 11.7175 1.81269 11.8725 2.25184H13.5C13.6989 2.25184 13.8897 2.33086 14.0303 2.47151C14.171 2.61216 14.25 2.80293 14.25 3.00184C14.25 3.20075 14.171 3.39152 14.0303 3.53217C13.8897 3.67282 13.6989 3.75184 13.5 3.75184H11.8725C11.7175 4.19099 11.4302 4.57126 11.0501 4.84025C10.6699 5.10923 10.2157 5.25368 9.75 5.25368C9.28432 5.25368 8.8301 5.10923 8.44995 4.84025C8.0698 4.57126 7.78245 4.19099 7.6275 3.75184H1.5C1.30109 3.75184 1.11032 3.67282 0.96967 3.53217C0.829018 3.39152 0.75 3.20075 0.75 3.00184C0.75 2.80293 0.829018 2.61216 0.96967 2.47151C1.11032 2.33086 1.30109 2.25184 1.5 2.25184ZM1.5 8.25184H3.1275C3.28245 7.81269 3.5698 7.43241 3.94995 7.16343C4.3301 6.89445 4.78432 6.75 5.25 6.75C5.71568 6.75 6.1699 6.89445 6.55005 7.16343C6.9302 7.43241 7.21755 7.81269 7.3725 8.25184H13.5C13.6989 8.25184 13.8897 8.33086 14.0303 8.47151C14.171 8.61216 14.25 8.80293 14.25 9.00184C14.25 9.20075 14.171 9.39152 14.0303 9.53217C13.8897 9.67282 13.6989 9.75184 13.5 9.75184H7.3725C7.21755 10.191 6.9302 10.5713 6.55005 10.8402C6.1699 11.1092 5.71568 11.2537 5.25 11.2537C4.78432 11.2537 4.3301 11.1092 3.94995 10.8402C3.5698 10.5713 3.28245 10.191 3.1275 9.75184H1.5C1.30109 9.75184 1.11032 9.67282 0.96967 9.53217C0.829018 9.39152 0.75 9.20075 0.75 9.00184C0.75 8.80293 0.829018 8.61216 0.96967 8.47151C1.11032 8.33086 1.30109 8.25184 1.5 8.25184Z"
            fill="#4B60D8" />
    </svg>
  );
};

