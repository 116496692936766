import { createSelector } from '@reduxjs/toolkit';
import { TariffState } from '@store/slices/tariff/tariffSlice';

type WithTariffState = {
  tariff: TariffState;
};

export const tariffStateSelector = (state: WithTariffState): TariffState =>
  state.tariff;

export const getTariffMonitoringTableFiltersSelector = createSelector(
  tariffStateSelector,
  (tariffState) => {
    return tariffState.tariffMonitoringTableFilters;
  },
);

export const getTariffTableFiltersSelector = createSelector(
  tariffStateSelector,
  (tariffState) => {
    return tariffState.tariffTableFilters;
  },
);

export const getTariffDocumentFiltersSelector = createSelector(
  tariffStateSelector,
  (tariffState) => {
    return tariffState.document;
  },
);
