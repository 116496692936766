import {
  typeWaterLogAccountingBalanceSheetProps
} from '@features/waterAccounting/waterLogAccounting/waterLogAccountingBalanceSheet/WaterLogAccountingBalanceSheet';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Form, notification, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Col, Row } from 'antd/lib';
import { Typography } from 'antd';

import { MonthEnums } from '@enums/month';
import { DefaultButton, PrimaryButton, TooltipedInput } from '@features/ui-kit';
import {
  useGetWaterAccountingBalanceSheetQuery,
  useLazyApproveWaterAccountingBalanceSheetQuery,
  useLazyCancelWaterAccountingBalanceSheetQuery,
  useLazySaveToSystemWaterAccountingBalanceSheetQuery,
} from '@store/gisproApi';
import { toInteger } from '@utils/utils';

export const WaterLogAccountingBalanceSheetFilters: React.FC<typeWaterLogAccountingBalanceSheetProps> = ({ balanceSheet, refetch }) => {
  const { outletId, year, decade, month } = useParams();
  const [form] = useForm();
  const [disabled, setDisabled] = useState<boolean>(false);

  const [toggleApprove, setToggleApprove] = useState<boolean>(true);
  const [triggerApprove, { isLoading: isApproveLoading }] = useLazyApproveWaterAccountingBalanceSheetQuery();
  const [triggerCancel, { isLoading: isCancelLoading }] = useLazyCancelWaterAccountingBalanceSheetQuery();
  const [trigger, { isLoading }] =
    useLazySaveToSystemWaterAccountingBalanceSheetQuery();

  const saveToSystem = () => {
    trigger({
      year: toInteger(year),
      outletId: toInteger(outletId),
      month: month as MonthEnums,
      decade: toInteger(decade),
    })
      .unwrap()
      .then(() => {
        notification.success({ message: 'Сохранено' });
        refetch();
      })
      .catch(({ data }) => {
        notification.error({ message: data?.message ?? 'Не удалось сохранить' });
      });
  };

  const approve = () => {
    if (toggleApprove) {
      triggerApprove({
        year: toInteger(year),
        outletId: toInteger(outletId),
        month: month as MonthEnums,
        decade: toInteger(decade),
      })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Подтверждено' });
          setToggleApprove((prev) => !prev);
          refetch();
        })
        .catch(({ data }) => {
          notification.error({ message: data?.message ?? 'Не удалось подтвердить' });
        });
    } else {
      triggerCancel({
        year: toInteger(year),
        outletId: toInteger(outletId),
        month: month as MonthEnums,
        decade: toInteger(decade),
      })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Отменено' });
          setToggleApprove((prev) => !prev);
          refetch();
        })
        .catch(() => {
          notification.error({ message: 'Не удалось отменить' });
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      outletName: balanceSheet?.outletName,
      ...balanceSheet,
      decade,
      month,
    });
    // если все заблокированы значения, то тогда меняем текст на Отменить
    const isBlocked = !balanceSheet?.items?.[0]?.days?.find((day: any) => !day.isBlocked);
    setToggleApprove(!isBlocked);
    // если есть хотя бы одна не сохраненная запись, то блокируем кнопки
    const isSaved = balanceSheet?.items?.[0]?.days?.find((day: any) => !day.isSaved);
    setDisabled(isSaved);
  }, [balanceSheet, form]);

  return (
    <Flex justify="flex-end">
      <Space align="center">
        <DefaultButton loading={isLoading} onClick={saveToSystem} disabled={!toggleApprove}>
          Сохранить
        </DefaultButton>
        <PrimaryButton loading={isApproveLoading || isCancelLoading} onClick={approve} disabled={disabled}>
          {toggleApprove ? 'Подтвердить' : 'Отменить  '}
        </PrimaryButton>
      </Space>
    </Flex>
  );
};
