import React, { useRef } from 'react';

import { DatePicker, DatePickerProps, Tooltip, TooltipProps } from 'antd';

import styles from './YearPicker.module.scss';

type TooltipedDatePickerV2PropsType = DatePickerProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
  borderless?: boolean;
};

export const TooltipedYearPickerV2: React.FC<
  TooltipedDatePickerV2PropsType
> = ({ tooltipProps, borderless = false, ...props }) => {
  const ref = useRef(null);

  return (
    <Tooltip {...tooltipProps}>
      <DatePicker.YearPicker
        {...props}
        ref={ref}
        className={`${styles.datePicker} ${
          borderless ? styles.borderless : ''
        }`}
        allowClear={false}
      />
    </Tooltip>
  );
};
