import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import { Col, Row, TablePaginationConfig, Typography } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGeoPortalManagementTableColumns } from './useGeoPortalManagementTableColumns';
import { GeoPortalManagementTableModal } from '../geoPortalManagementModal';
import { useSearchParams } from 'react-router-dom';
import {
  useGeoLayersControllerDeleteMutation,
  useGeoLayersControllerFindAllQuery,
} from '@store/api/geoLayersApi';
export const GeoPortalManagementTable: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitle();
  const [params, setSearchParams] = useSearchParams();
  const filtersParams: any = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );
  console.log('filtersParams', filtersParams);
  const {
    data: { content = [], totalElements, ...rest } = {},
    isLoading,
    isFetching,
  } = useGeoLayersControllerFindAllQuery(filtersParams);
  const { columns } = useGeoPortalManagementTableColumns();

  const [deleteGeoLayers] = useGeoLayersControllerDeleteMutation();

  const [isModalShow, setShowModal] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const handleEdit = (val: any) => {
    setInitialValues(val);
    setShowModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = ({ id }: { id: string }) => {
    deleteGeoLayers({ id });
  };

  useEffect(() => {
    setHeaderTitle('Управление WMS сервисами');
  }, []);

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        const currentSearchParams = Object.fromEntries(params.entries());
        const updatedSearchParams = {
          ...currentSearchParams,
          page: newPagination.current.toString(),
          size: newPagination.pageSize.toString(),
        };
        setSearchParams(updatedSearchParams);
      }
    },
    [rest, columns],
  );

  return (
    <>
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>
                  WMS сервисы
                </Typography.Title>
              </Col>
              <Col>
                <PrimaryButton onClick={handleCreate}>
                  Добавить WMS сервис
                </PrimaryButton>
              </Col>
            </Row>
          }
        >
          <TableCustom
            loading={isLoading || isFetching}
            dataSource={content as any[]}
            bordered
            columns={columns}
            size="small"
            totalCount={totalElements}
            customPagination={rest}
            onChange={onTableChange}
            actionProps={{
              onHandleEdit: (val) => handleEdit(val),
              deleteItem,
            }}
          />
          {isModalShow && (
            <GeoPortalManagementTableModal
              isModalShow={isModalShow}
              setShowModal={setShowModal}
              initialValues={initialValues}
            />
          )}
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
