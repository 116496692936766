import { DecadeStartDate } from '@constants/decades';
import { MonthIndex } from '@constants/month';
import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import dayjs from 'dayjs';

type ReturnType = {
  columns: any;
};

export const useWaterLogAccountingTableColumns = ({
  month,
  decade,
  year,
  decadeDay,
}: {
  month: MonthEnums;
  decade: DecadesEnum;
  decadeDay: number[];
  year: string;
}): ReturnType => {
  const dateColumns: any = decadeDay
    .map((day) => {
      const dateStart = dayjs()
        .year(parseInt(year, 10))
        .month(MonthIndex[month])
        .date(day)
        .format('YYYY-MM-DD');
      return [
        {
          title: dateStart,
          dataIndex: 'day',
          width: 200,
          xtype: CustomTableColumnType.String,
          children: [
            {
              title: 'Расход л/с',
              dataIndex: `${day}.flowRate`,
              xtype: CustomTableColumnType.DecadeCell,
              width: 100,
            },
            {
              title: 'Cток тыс. м3',
              dataIndex: `${day}.flowRate`,
              xtype: CustomTableColumnType.RenderCustom,
              renderCustom: ({ record }: any) => (
                <div>{(+record[`${day}.flowRate`] / 1000).toFixed(2)}</div>
              ),
              width: 100,
            },
          ],
        },
      ];
    })
    .flat();

  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      width: 50,
      xtype: CustomTableColumnType.String,
      fixed: 'left',
    },
    {
      title: 'Наименование водовыпуска/Гидпропоста(коммерч.)',
      dataIndex: 'name',
      key: 'outletName',
      width: 200,
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.BalanceSheet],
      fixed: 'left',
    },
    {
      title: 'Общие показатели за декаду',
      dataIndex: 'shadow-border',
      width: 400,
      xtype: CustomTableColumnType.String,
      className: 'general',
      // style: { borderRight: '3px solid black' },
      fixed: 'left',
      children: [
        {
          title: 'Всего Расход л/с',
          dataIndex: 'totalFactFlowRate',
          xtype: CustomTableColumnType.DecadeCell,
          width: 100,
          fixed: 'left',
        },
        {
          title: 'Всего сток тыс. м3',
          dataIndex: 'totalFactStock',
          xtype: CustomTableColumnType.DecadeCell,
          width: 100,
          fixed: 'left',
        },
        {
          title: 'Средний Расход л/с',
          dataIndex: 'averageFactFlowRate',
          xtype: CustomTableColumnType.DecadeCell,
          width: 100,
          fixed: 'left',
        },
        {
          title: 'Средний сток тыс. м3',
          dataIndex: 'averageFactStock',
          xtype: CustomTableColumnType.DecadeCell,
          width: 100,
          fixed: 'left',
        },
      ],
    },
    ...dateColumns,
  ];
  return { columns };
};
