import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useIrrigationSystemCardObjectColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№',
      dataIndex: 'index',
      xtype: CustomTableColumnType.NumberColumn,
      width: 60,
      key: 'index',
    },
    {
      title: 'Код водного объекта',
      dataIndex: 'objectCode',
      xtype: CustomTableColumnType.String,
      key: 'objectCode',
    },
    {
      title: 'Наименование объекта',
      dataIndex: 'objectName',
      xtype: CustomTableColumnType.String,
      key: 'objectName',
    },
    {
      title: 'Группа',
      dataIndex: 'groupObjectId',
      xtype: CustomTableColumnType.ObjectGroup,
      key: 'groupObjectId',
    },
    {
      title: 'Категория',
      dataIndex: 'objectCategoryId',
      xtype: CustomTableColumnType.ObjectCategory,
      key: 'categoryObjectId',
    },
    {
      title: 'Тип',
      dataIndex: 'typeCategory',
      xtype: CustomTableColumnType.String,
      key: 'typeCategory',
    },
    {
      title: 'Год ввода в эксплуатацию',
      dataIndex: 'dateStart',
      xtype: CustomTableColumnType.DateColumn,
      format: 'YYYY',
      key: 'dateStart',
    },
    {
      title: 'Тех. состояние',
      dataIndex: 'technicalStatusId',
      xtype: CustomTableColumnType.TechnicalStatus,
      // xtype: CustomTableColumnType.String,
      key: 'technicalStatusEnum',
    },
    {
      title: 'Экспл. состояние',
      dataIndex: 'operationalStatusId',
      xtype: CustomTableColumnType.OperationalStatus,
      // xtype: CustomTableColumnType.String,
      key: 'operationalStatusEnum',
    },
    {
      title: '...',
      dataIndex: 'actions',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.WatchWithoutText],
      key: 'actions',
    },
  ];

  return { columns };
};
