import { Col, Row, Typography } from 'antd';

import { CustomCard } from '@features/ui-kit';
import {
  IAnnualApplicationCalculations,
  ICalculationsByPlantingData,
} from '@models/annualApplication/interfaces/annualApplication';

import styles from './WaterAndHectareSupplyTable.module.scss';

type ApplicationFormPropTypes = {
  data: ICalculationsByPlantingData;
};

export const ApplicationForm: React.FC<ApplicationFormPropTypes> = ({
  data,
}) => {
  return (
    <CustomCard
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4}>Форма заявки</Typography.Title>
        </Row>
      }
    >
      <Row className={styles.formBlock}>
        <Col className={styles.formBlockHeader} span={24}>
          <Row>
            <Col span={3}>
              <Typography.Text>Водопользователь</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>Хозяйство</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>Область</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>Район</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>Аймак</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>Водовыпуск</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>Канал</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>Оросительная система</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>Гидроучасток</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col className={styles.formBlockBody} span={24}>
          <Row justify="center">
            <Col span={3}>
              <Typography.Text>{data.waterUserName}</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>{data.name}</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>{data.regionName}</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>{data.districtName}</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>{data.okmotName}</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>{data.outletName}</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>{data.channelName}</Typography.Text>
            </Col>
            <Col span={3}>
              <Typography.Text>{data.systemName}</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>{data.hydroFacilityName}</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomCard>
  );
};
