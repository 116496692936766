import { AuthApi } from '@constants/api';
import { LocalStorageKey } from '@enums/localStorageKey';
import { UserRoles } from '@enums/roles';
import { AuthenticationUser } from '@models/authentication/interfaces/user';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import $api from '@utils/api/axios';
import { LocalStorage } from '@utils/localStorage/localStorage';

interface AuthState {
  isLoading: boolean;
  user: null | AuthenticationUser;
  authFailed: boolean;
  authError: string;
  permissions: string[];
  isDisabledRegion: boolean;
  isDisabledDistrict: boolean;
}

interface IPriviligies {
  action: string;
}

interface IPermission {
  moduleName: string;
  moduleNameRussian: string;
  permissions: {
    [key: string]: boolean;
  };
}

const userFromStorage = LocalStorage.getItem(LocalStorageKey.User)
  ? JSON.parse(LocalStorage.getItem(LocalStorageKey.User) as string)
  : null;

const permissionsFromStorage: string[] = LocalStorage.getItem(
  LocalStorageKey.Permission,
)
  ? JSON.parse(LocalStorage.getItem(LocalStorageKey.Permission) as string)
  : null;

const getRegionAndDistrictDisabled = (user: null | AuthenticationUser) => {
  switch (user?.role?.roleName) {
    case UserRoles.ROLE_RUVH:
    case UserRoles.ROLE_ASSOCIATION:
    case UserRoles.ROLE_WATER_USER:
      return { disabledRegion: true, disabledDistrict: true };
    case UserRoles.ROLE_GUVH:
    case UserRoles.ROLE_BUVH:
      return { disabledRegion: true, disabledDistrict: false };
    default:
      return { disabledRegion: false, disabledDistrict: false };
  }
};
const initialState: AuthState = {
  user: userFromStorage,
  isLoading: false,
  authFailed: false,
  isDisabledRegion:
    getRegionAndDistrictDisabled(userFromStorage).disabledRegion,
  isDisabledDistrict:
    getRegionAndDistrictDisabled(userFromStorage).disabledDistrict,
  authError: '',
  permissions: permissionsFromStorage || [],
};

export const TokenReceived = createAsyncThunk(
  'TokenReceived',
  async function (
    props: {
      accessToken: string;
      token: string;
      permissions: IPermission[];
    },
    { rejectWithValue },
  ) {
    try {
      LocalStorage.setItem(LocalStorageKey.AccessToken, props.accessToken);
      LocalStorage.setItem(LocalStorageKey.TokenUUID, props.token);
      LocalStorage.setItem(
        LocalStorageKey.Permission,
        JSON.stringify(props.permissions),
      );
      return props;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const getProfile = createAsyncThunk(
  'GetProfile',
  async function (
    props: {
      login: string;
    },
    { rejectWithValue },
  ) {
    try {
      const { data } = await $api.get(`${AuthApi.Profile}/${props.login}`);

      LocalStorage.setItem(LocalStorageKey.User, JSON.stringify(data));

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const signIn = createAsyncThunk(
  'SignIn',
  async function (
    props: {
      login: string;
      password: string;
      rememberme: boolean;
    },
    { rejectWithValue, dispatch },
  ) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const { data } = await $api.post(
        AuthApi.Login,
        {
          login: props.login,
          password: props.password,
        },
        config,
      );
      if (data) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        const permissions = (data.permissions || [])
          .map((per: IPermission) => {
            return Object.keys(per.permissions).map((key) =>
              per.permissions[key]
                ? `${per.moduleName}_${key.toUpperCase()}`
                : '',
            );
          })
          .flat()
          .filter((v: string) => v !== '');
        LocalStorage.setItem(
          LocalStorageKey.Permission,
          JSON.stringify(permissions),
        );
        LocalStorage.setItem(LocalStorageKey.TokenUUID, data.token);
      }

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logOut: (state) => {
      localStorage.clear();
      return {
        ...state,
        user: null,
        isDisabledRegion: true,
        isDisabledDistrict: true,
        permissions: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state: AuthState, { payload }) => {
      const permissions = (payload.permissions || [])
        .map((per: IPermission) => {
          return Object.keys(per.permissions).map((key) =>
            per.permissions[key]
              ? `${per.moduleName}_${key.toUpperCase()}`
              : '',
          );
        })
        .flat()
        .filter((v: string) => v !== '');

      return {
        ...state,
        isLoading: false,
        user: payload,
        authError: '',
        authFailed: false,
        permissions,
      };
    });
    builder.addCase(signIn.rejected, (state: AuthState, { payload }) => {
      return {
        ...state,
        isLoading: false,
        user: null,
        authError: payload as string,
        authFailed: true,
      };
    });
    builder.addCase(getProfile.fulfilled, (state: AuthState, { payload }) => {
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          ...payload,
        },
        isDisabledRegion: getRegionAndDistrictDisabled(payload).disabledRegion,
        isDisabledDistrict:
          getRegionAndDistrictDisabled(payload).disabledDistrict,
      };
    });
    builder.addCase(getProfile.rejected, (state: AuthState) => {
      return {
        ...state,
        isLoading: false,
        user: null,
      };
    });
  },
});

export const { logOut } = authenticationSlice.actions;
export default authenticationSlice.reducer;
