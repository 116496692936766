import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardProps, Col, Row, notification } from 'antd';

import { TelegramInputButton } from '@features/ui-kit/buttons/TelegramInputButton';
import { WhatsAppInputButton } from '@features/ui-kit/buttons/WhatsAppInputButton';
import { CopyIcon } from '@icons/CopyIcon';
import { CopySolidIcon } from '@icons/CopySolidIcon';
import { ViewMode } from 'gantt-task-react';

import styles from './ConnectBlock.module.scss';

interface IConnectData {
  value: string | boolean | number | undefined;
  label?: string;
  path?: string;
  messenger?: string;
}

type CustomCardPropsType = CardProps & {
  props?: CardProps;
  connectData: IConnectData[];
};

export const ConnectBlock: React.FC<CustomCardPropsType> = ({
  connectData,
  ...props
}) => {
  const copyText = async (text: any) => {
    try {
      if (text) {
        await navigator.clipboard.writeText(text);
      }
    } catch (err) {
      notification.error({
        message: 'Не удалось скопировать',
      });
    }
  };
  return (
    <Card className={styles.card} {...props}>
      {connectData.map((item) => (
        <Row key={item.label}>
          {!item?.messenger && (
            <Col className={styles.infoBlock} span={24}>
              <Row gutter={[0, 4]} justify="space-between">
                <Col className={styles.label}>{item.label}:</Col>
                <Col span={10} className={styles.value}>
                  {item.path ? (
                    <Link to={item.path}>{item.value || '-'}</Link>
                  ) : (
                    <div>{item.value || '-'}</div>
                  )}
                  <div
                    className={styles.copyOutlinedButton}
                    onClick={() => copyText(item?.value)}
                  >
                    <CopySolidIcon />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {item.messenger && (
            <Col className={styles.messenger} span={24}>
              <Row gutter={[0, 4]}>
                <Col span={24}>
                  {item.messenger === 'whatsapp' && (
                    <WhatsAppInputButton
                      disabled
                      initialValue={!!item.value}
                      size="large"
                      btnName="hasWhatsapp"
                    />
                  )}
                </Col>
                <Col span={24}>
                  {item.messenger === 'telegram' && (
                    <TelegramInputButton
                      disabled
                      initialValue={!!item.value}
                      size="large"
                      btnName="hasTelegram"
                    />
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      ))}
    </Card>
  );
};
