import { DecadesEnum } from '@enums/decade';

export const decades = [
  {
    value: '1',
    label: 'I декада',
  },
  {
    value: '2',
    label: 'II декада',
  },
  {
    value: '3',
    label: 'III декада',
  },
];
export const DecadeStartDate = {
  [DecadesEnum.First]: 1,
  [DecadesEnum.Second]: 11,
  [DecadesEnum.Third]: 21,
};
