import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { IActsInvoicesForPaymentsFilters } from '@models/actsInvoicesForPayments/interfaces/actsInvoicesForPayments';
import { getActsInvoicesForPaymentsFiltersSelector } from '@store/selectors';
import { actsInvoicesForPaymentsActions } from '@store/slices';
import { isEmpty, isInteger, pickBy } from '@utils/utils';

type ReturnType = {
  filters: Partial<IActsInvoicesForPaymentsFilters>;
  setActsInvoicesForPaymentsFilters: (
    filters: IActsInvoicesForPaymentsFilters,
  ) => void;
};

export const useActsInvoicesForPaymentsFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();

  const filters = useSelector(getActsInvoicesForPaymentsFiltersSelector);

  const setActsInvoicesForPaymentsFilters = useCallback(
    (newFilters: IActsInvoicesForPaymentsFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      setParams(params);
      const values = pickBy(newFilters, (v) => !isEmpty(v) || isInteger(v));
      dispatch(
        actsInvoicesForPaymentsActions.changeActsInvoicesForPaymentsFiltersAction(
          values as IActsInvoicesForPaymentsFilters,
        ),
      );
    },
    [],
  );

  return { filters, setActsInvoicesForPaymentsFilters };
};
