import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreateWaterSectionModalShownSelector } from '@store/selectors/waterObject';
import { waterObjectActions } from '@store/slices';

export const useCreateWaterSectionModal = () => {
  const dispatch = useDispatch();
  const isModalShown = useSelector(getIsCreateWaterSectionModalShownSelector);

  const setShowModal = useCallback((val: boolean) => {
    dispatch(waterObjectActions.setShowCreateWaterSectionModalAction(val));
  }, []);

  return {
    isModalShown,
    setShowModal,
  };
};
