import { Chart } from 'react-google-charts';

import { Row } from 'antd';

import styles from './CustomPieChart.module.scss';

const options = {
  legend: 'none',
  chartArea: {
    width: 417,
    height: 417,
  },
  pieSliceText: 'none',
  height: 500,
  width: 480,
  title: '',
  slices: {
    0: { color: '#F4F0FD' },
    1: { color: '#E5DAFB' },
    2: { color: '#5E3FBE' },
    3: { color: '#CBB6F8' },
  },
};

type CustomPieChartPropTypes = {
  data: Array<Array<string | number>>;
};

const colorsArray = ['#F4F0FD', '#E5DAFB', '#5E3FBE', '#CBB6F8'];

export const CustomPieChart: React.FC<CustomPieChartPropTypes> = ({ data }) => {
  return (
    <div>
      <Chart
        chartType="PieChart"
        data={[['', ''], ...data]}
        options={options}
      />
      <Row>
        {data.map((item, index) => {
          return (
            <Row key={String(index + 1)} className={styles.squareBlock}>
              <div
                className={styles.square}
                style={{ backgroundColor: colorsArray[index] }}
              />
              <div className={styles.text} style={{ color: '#5E3FBE' }}>
                {item[1]}
              </div>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};
