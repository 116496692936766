import { SaveOutlined } from '@ant-design/icons';
import styles from '@features/geoPortal/new/geoPortalMap/GeoPortalMap.module.scss';
import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { GeoAttribute } from '@models/geoPortal/interfaces/GeoPortal';
import { ISystemObjectMutationType } from '@models/waterObject/interfaces/waterObject';
import { useLazyMapControllerGetDataQuery } from '@store/api/geoPortalApi';
import { useLazyGetSystemObjectByIdQuery } from '@store/gisproApi';
import { getArrayDimension } from '@utils/map/saveMap';
import { Badge, Button, Progress } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const SaveMap = () => {
  const { filterKey } = useParams<{ filterKey: any }>();
  const { showedLayers, currentGroup } = useContext(MapContext);
  const [downloadNumberLayer, setDownloadNumberLayer] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const [fetchTriggerGeoData] = useLazyMapControllerGetDataQuery();
  const [fetchTriggerById] = useLazyGetSystemObjectByIdQuery();
  const [dataLayer, setDataLayer] = useState<ISystemObjectMutationType[]>([]);
  const [layerKey, setLayerKey] = useState<string[]>([]);
  const [attLength, setAttLength] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramData = searchParams.get('startDate');

  const createAttList = async () => {
    const results = (await Promise.all(
      layerKey.map((item) => {
        return fetchTriggerGeoData(
          {
            layerName: item,
            mapName: filterKey as string,
            startDate: paramData,
          },
          true,
        )
          .unwrap()
          .then((data) => {
            return data.map((itemData) => ({
              ...itemData,
              layerName: item,
            }));
          });
      }),
    )) as GeoAttribute[][];

    const resultMutation = results
      .map((item, index) =>
        item.filter((itemData) => itemData.coordinates.length !== 0),
      )
      .flat(1) as GeoAttribute[];

    setAttLength(resultMutation.length);

    return resultMutation;
  };

  const setSaveLayer = async () => {
    setIsProgress(true);

    if (layerKey.length !== 0 || filterKey) {
      const resultMutation = await createAttList();

      const resultLayer: ISystemObjectMutationType[] = [];

      const totalRequests = resultMutation.flat(1).length;

      let completedRequests = 0;

      for (const attributeItem of resultMutation) {
        let type = '';

        if (getArrayDimension(attributeItem.coordinates) === 1) {
          type = 'Point';
        } else if (getArrayDimension(attributeItem.coordinates) === 2) {
          type = 'LineString';
        } else if (getArrayDimension(attributeItem.coordinates) === 3) {
          type = 'Polygon';
        }

        await fetchTriggerById({ id: attributeItem.id.toString() }).then(
          ({ data }) => {
            completedRequests += 1;
            const percentage = (completedRequests / totalRequests) * 100;
            setDownloadNumberLayer(Math.floor(percentage));
            resultLayer.push({
              ...data,
              baseNameAtt: attributeItem.baseName,
              irrigationSystemNameAtt: attributeItem.irrigationSystemName,
              operationalStatusAtt: attributeItem.operationalStatus,
              technicalStatusAtt: attributeItem.technicalStatus,
              coordinate: attributeItem.coordinates,
              datasetType: type,
              layerName: attributeItem.layerName,
              extra: attributeItem.extra,
              geoId: attributeItem.geoId,
              objectType: attributeItem.objectType,
              piket: attributeItem.piket,
              popupText: attributeItem.popupText,
              type: attributeItem.type,
              id: attributeItem.id,
            });
          },
        );
      }

      console.log(resultLayer);

      setDataLayer(resultLayer);
    }
  };

  const toggleCloseSaveLayer = () => {
    setIsProgress(false);
    setDataLayer([]);
    setDownloadNumberLayer(0);
  };

  const setConvertGeoJson = () => {
    if (dataLayer.length) {
      console.log(dataLayer);

      const features = dataLayer.map((item) => {
        const { coordinate, datasetType, ...res } = item;

        return {
          type: 'Feature',
          groupName: item.objectGroupName,
          layerTitle: item.objectCategoryName,
          geometry: {
            type: item.datasetType,
            coordinates: item.coordinate,
          },
          properties: res,
        };
      });

      const geoJson = {
        type: 'FeatureCollection',
        features: features.flat(),
      };

      const jsonData = JSON.stringify(geoJson);

      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${Date.now()}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toggleCloseSaveLayer();
    }
  };

  useEffect(() => {
    const legendList = Object.keys(showedLayers).filter(
      (key) => showedLayers[key] !== null && key.split(':')[0] === 'geonode',
    );

    if (currentGroup.name !== '' && !legendList.includes(currentGroup.name)) {
      legendList.push(currentGroup.name);
    }

    setLayerKey(legendList);
  }, [currentGroup, showedLayers]);

  useEffect(() => {
    void createAttList();
  }, [layerKey]);

  return (
    <div>
      <div
        className={`${styles.saveButton} ${
          attLength !== 0 ? styles.active : ''
        }`}
      >
        <Badge count={attLength}>
          <Button
            type="primary"
            onClick={setSaveLayer}
            icon={<SaveOutlined />}
          />
        </Badge>
      </div>

      <div className={`${styles.bac} ${isProgress ? styles.activeBac : ''}`}>
        <Progress type="circle" percent={downloadNumberLayer} size={200} />
        <div className={styles.sectionButton}>
          {downloadNumberLayer === 100 && (
            <Button type="dashed" onClick={toggleCloseSaveLayer}>
              Отмена
            </Button>
          )}
          <Button
            type="primary"
            disabled={downloadNumberLayer !== 100}
            onClick={setConvertGeoJson}
          >
            Скачать
          </Button>
        </div>
      </div>
    </div>
  );
};
