import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Space } from 'antd';

import {
  CustomCard,
  TelegramButton,
  TooltipedInput,
  WhatsAppButton,
  WhatsAppInputButton,
} from '@features/ui-kit';
import { TelegramInputButton } from '@features/ui-kit/buttons/TelegramInputButton';
import {
  changeCursorPhoneNumberInput,
  mapPhoneNumberToLong,
} from '@utils/phone';
import { phoneNumberValidator } from '@utils/validation/phoneNumberValidation';

export const ContactDetails: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <CustomCard title="Контактные данные" withoutBottomPadding>
          <Row justify="space-between" gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.phone',
                )}
                name={['employees', 'phone']}
                rules={[
                  {
                    required: true,
                  },
                  phoneNumberValidator,
                ]}
                normalize={mapPhoneNumberToLong}
              >
                <TooltipedInput
                  size="large"
                  placeholder="Введите номер телефона"
                  addonBefore="+996"
                  onChange={changeCursorPhoneNumberInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.email',
                )}
                name={['employees', 'email']}
                rules={[{ required: true, type: 'email' }]}
              >
                <TooltipedInput placeholder="Введите E-mail" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t(
                  'createIndividualWaterRegistry.firstStep.contactDetails.placeholders.additionalPhone',
                )}
                name={['employees', 'additionalPhone']}
                rules={[phoneNumberValidator]}
                normalize={mapPhoneNumberToLong}
              >
                <TooltipedInput
                  placeholder="Введите доп. номер телефона"
                  size="large"
                  addonBefore="+996"
                  onChange={changeCursorPhoneNumberInput}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="WhatsApp" name={['employees', 'hasWhatsapp']}>
                <WhatsAppInputButton
                  size="large"
                  btnName={['employees', 'hasWhatsapp']}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Telegram" name={['employees', 'hasTelegram']}>
                <TelegramInputButton
                  size="large"
                  btnName={['employees', 'hasTelegram']}
                />
              </Form.Item>
            </Col>
          </Row>
        </CustomCard>
      </Col>
    </Row>
  );
};
