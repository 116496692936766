import { AccessModal } from '@features/administration/accessManagement/accessModal';
import { useAccessTableColumns } from '@features/administration/accessManagement/accessTable/useAccessTableColumns';
import { RoleModal } from '@features/administration/accessManagement/roleModal';
import { useHeaderTitle } from '@features/header/useHeaderTitle';
import {
  CustomCard,
  Divider,
  InformationalBlock,
  PrimaryButton,
  TableCustom,
} from '@features/ui-kit';
import {
  useAccessControllerDeleteRoleMutation,
  useAccessControllerGetRolesQuery,
} from '@store/api/accessApi';

import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

export const AccessTable: React.FC = () => {
  const { setHeaderTitle } = useHeaderTitle();

  const {
    data: roles = [],
    isLoading,
    isFetching,
  } = useAccessControllerGetRolesQuery();

  const { columns } = useAccessTableColumns();

  const [deleteEntity] = useAccessControllerDeleteRoleMutation();

  const [isModalShow, setShowModal] = useState(false);
  const [isAccessShow, setAccessModal] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const handleEdit = (val: any) => {
    setInitialValues(val);
    setShowModal(true);
  };
  const handleAccess = (val: any) => {
    setInitialValues(val);
    setAccessModal(true);
  };

  const handleCreate = () => {
    setInitialValues(null);
    setShowModal(true);
  };

  const deleteItem = ({ id }: { id: number }) => {
    deleteEntity({ id });
  };

  useEffect(() => {
    setHeaderTitle(t('headers.access_management'));
  }, []);

  return (
    <>
      <Divider />
      <InformationalBlock>
        <CustomCard
          style={{ padding: '8px 16px' }}
          title={
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>Список ролей</Typography.Title>
              </Col>
              <Col>
                <PrimaryButton onClick={handleCreate}>
                  Добавить роль
                </PrimaryButton>
              </Col>
            </Row>
          }
        >
          <TableCustom
            scroll={{ x: 1750 }}
            loading={isLoading || isFetching}
            dataSource={roles}
            bordered
            columns={columns}
            size="small"
            methodsProps={[handleAccess, handleEdit, deleteItem]}
          />
          {isModalShow && (
            <RoleModal
              isModalShow={isModalShow}
              setShowModal={setShowModal}
              initialValues={initialValues}
            />
          )}
          {isAccessShow && (
            <AccessModal
              isModalShow={isAccessShow}
              setShowModal={setAccessModal}
              initialValues={initialValues}
            />
          )}
        </CustomCard>
      </InformationalBlock>
    </>
  );
};
