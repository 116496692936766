import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { SummaryCardColor } from '@enums/summaryCardColor';
import { CustomCard, SummaryCard, TooltipedSelect } from '@features/ui-kit';
import { useGetWaterManagementCountersQuery } from '@store/gisproApi';
import { getCurrentUserSelector } from '@store/selectors';

import styles from './AgreementCard.module.scss';
import { formatNumber } from '@utils/formatNumber/formatNumber';

export const AgreementCard: React.FC = () => {
  const currentUser = useSelector(getCurrentUserSelector);

  const id = currentUser?.organization ? 'organizationId' : 'ruvh';

  const { data } = useGetWaterManagementCountersQuery({
    [id]: currentUser?.organization
      ? currentUser?.organization.id
      : currentUser?.owner?.id,
  });

  return (
    <CustomCard
      title={
        <Row align="middle" justify="space-between" gutter={16}>
          <Col flex="1 1 auto" className={styles.cardTitle}>
            <Typography.Title
              style={{ marginBottom: 0, color: '#3F4778' }}
              level={4}
            >
              Договор
            </Typography.Title>
          </Col>
          <Col>
            <Row justify="end" gutter={8}>
              <Col>
                <TooltipedSelect placeholder="Год" />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Row className={styles.statisticsBlock} gutter={14}>
        <Col span={6}>
          <SummaryCard
            sum={formatNumber(data?.plan) || 0}
            size="large"
            title="План"
            sumSubtitle="сом"
            subtitle="Год"
            color={SummaryCardColor.Orange}
          />
        </Col>
        <Col span={6}>
          <SummaryCard
            sum={formatNumber(data?.agreementSubmitted) || 0}
            title="Подано"
            sumSubtitle="сом"
            subtitle="Год"
            size="large"
          />
        </Col>
        <Col span={6}>
          <SummaryCard
            sum={formatNumber(data?.agreementPaid) || 0}
            size="large"
            sumSubtitle="сом"
            title="Оплачено"
            subtitle="Год"
            color={SummaryCardColor.Blue}
          />
        </Col>
        <Col span={6}>
          <SummaryCard
            size="large"
            sum={formatNumber(data?.agreementDebt) || 0}
            title="Задолженность"
            sumSubtitle="сом"
            subtitle="Год"
            color={SummaryCardColor.Red}
          />
        </Col>
      </Row>
    </CustomCard>
  );
};
