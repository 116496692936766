import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPointConfirmIcon: React.FC<IconProps> = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3ZM12.1409 6.41629C12.5286 6.02296 12.524 5.38982 12.1307 5.00211C11.7374 4.61441 11.1042 4.61896 10.7165 5.01228L6.93203 8.85161L5.29292 7.1442C4.91045 6.74579 4.27741 6.73287 3.879 7.11535C3.48059 7.49782 3.46767 8.13086 3.85015 8.52927L6.20117 10.9782C6.38815 11.173 6.64595 11.2839 6.91595 11.2857C7.18594 11.2875 7.44519 11.18 7.63473 10.9877L12.1409 6.41629Z" fill="black" fill-opacity="0.6"/>
    </svg>
  )
}

