import { useDispatch } from 'react-redux';

import { CustomCard, TableCustom } from '@features/ui-kit';
import { useGetPlantingListQuery } from '@store/gisproApi';
import { agroClimaticZoneActions } from '@store/slices';

import { usePlantingTableColumns } from './usePlantingTableColumns';

export const PlantingTable = () => {
  const dispatch = useDispatch();
  const { columns } = usePlantingTableColumns();
  const { data: plantingList = [] } = useGetPlantingListQuery();

  const onChange = (selectedRowKey: React.Key[]) => {
    dispatch(
      agroClimaticZoneActions.setSelectedPlantingIdAction(
        selectedRowKey[0] as number,
      ),
    );
  };
  return (
    <CustomCard title="Список сельхозкультур">
      <TableCustom
        size="small"
        rowSelection={{
          type: 'radio',
          onChange,
        }}
        rowKey={(item) => item.id}
        columns={columns}
        scroll={{ y: 550 }}
        dataSource={plantingList}
        bordered
        pagination={false}
      />
    </CustomCard>
  );
};
