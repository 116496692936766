import { useParams } from 'react-router-dom';

import { Alert, Spin } from 'antd';
import { useContext } from 'react';
import { MapContext } from '@features/geoPortal/new/geoPortalMap/mapComponent/MapComponentContext';
import { geIconByType } from '@utils/map/geIconByType';
import { useMapControllerGetLayersQuery } from '@store/api/geoPortalApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { MutationLayerListType } from '@models/geoPortal/interfaces/GeoPortal';

const mineralizationLegends = [
  {
    label: '0 - 1.0 г/л',
    legend: 'orange',
  },
  {
    label: '1.0 - 3.0 г/л',
    legend: 'green',
  },
  {
    label: '3.0 - 5.0 г/л',
    legend: 'pink',
  },
  {
    label: 'более 5.0 г/л',
    legend: 'purple',
  },
];

const meliorationLegends = [
  {
    label: 'хорошее мелиоративное состояние',
    legend: 'green',
  },
  {
    label: 'удовлетворительное состояние',
    legend: 'yellow',
  },
  {
    label: 'недопустимая глубина грунт. вод',
    legend: 'red',
  },
  {
    label: 'засоление почв',
    legend: 'grey',
  },
  {
    label: 'недопустимая глубина + засоление',
    legend: 'black',
  },
];

const depthLength = [
  {
    label: '0 - 1.0 м',
    legend: 'cyan',
  },
  {
    label: '1.0 - 1.5 м',
    legend: 'green',
  },
  {
    label: '1.5 - 2.0 м',
    legend: 'orange',
  },
  {
    label: '2.0 - 3.0 м',
    legend: 'yellow',
  },
  {
    label: '3.0 - 5.0 м',
    legend: 'purple',
  },
  {
    label: 'более 5.0 м',
    legend: 'grey',
  },
];

export const LegendBlock: React.FC = () => {
  const { filterKey } = useParams<{ filterKey: any }>();
  const nameParamArchive = filterKey.split(':')[1] as string;
  const { showedLayers, currentGroup, groupArchiveLayer } =
    useContext(MapContext);
  let legends;
  switch (filterKey) {
    case 'mineralization':
      legends = mineralizationLegends;
      break;
    case 'melioration':
      legends = meliorationLegends;
      break;
    case 'depth':
      legends = depthLength;
      break;
    default:
      break;
  }

  const { data, isLoading } = useMapControllerGetLayersQuery(
    nameParamArchive !== 'archive' && filterKey
      ? { mapName: filterKey }
      : skipToken,
  );

  let dataMutation = data;

  if (nameParamArchive === 'archive') {
    dataMutation = groupArchiveLayer;
  }

  const mutationLayerList = dataMutation
    ?.map((item) => item.layers)
    .flat(1)
    .map((item) => ({
      type: item.layerName,
      title: item.title,
    })) as MutationLayerListType[];

  const legendList = Object.keys(showedLayers).filter((key) => {
    if (showedLayers[key] !== null) {
      return key;
    }
  });

  const getTitleByLayer = (layerType: string) => {
    const titleByLayer = mutationLayerList.find(
      (type) => type.type === layerType,
    );

    if (titleByLayer) {
      return titleByLayer.title;
    }

    return 'нет названия';
  };

  const checkMeliration_zone =
    legendList.some((item) => item === 'geonode:meliration_zone') ||
    currentGroup?.name === 'geonode:meliration_zone';

  return (
    <div>
      {!isLoading ? (
        <>
          {currentGroup.name === '' && legendList.length === 0 && (
            <Alert
              description="Выберите обьект"
              type="info"
              showIcon
              style={{ marginRight: 10 }}
            />
          )}

          {legendList.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  fontSize: 14,
                  border: '1px solid #4b60d8',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src={geIconByType(item)}
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>{getTitleByLayer(item)}</p>
              </div>
            );
          })}

          {currentGroup.name !== '' &&
            !legendList.includes(currentGroup.name) && (
              <div
                style={{
                  fontSize: 14,
                  border: '1px solid #4b60d8',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src={geIconByType(currentGroup.name)}
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>{getTitleByLayer(currentGroup.name)}</p>
              </div>
            )}

          {checkMeliration_zone && (
            <div>
              <div
                style={{
                  fontSize: 14,
                  border: '1px solid #4b60d8',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src="/icons/goodReclamationCondition.png"
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>Хорошее мелиоративное состояние</p>
              </div>

              <div
                style={{
                  fontSize: 14,
                  border: '1px solid green',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src="/icons/satisfactoryReclamationCondition.png"
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>Удовлетворительное состояние</p>
              </div>
              <div
                style={{
                  fontSize: 14,
                  border: '1px solid green',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src="/icons/UnacceptableGroundwaterDepth.png"
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>Недопустимая глубина грунтовых вод</p>
              </div>
              <div
                style={{
                  fontSize: 14,
                  border: '1px solid green',
                  marginBottom: 10,
                  width: '100%',
                  padding: 5,
                  marginRight: 5,
                }}
              >
                <img
                  src="/icons/soilSalinity.png"
                  alt="marker1"
                  style={{ marginRight: 4 }}
                />
                <p>Засоление почв</p>
              </div>
            </div>
          )}
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};
