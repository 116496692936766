import { CustomTableAction, CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useDocumentColumns = () => {
  const columns: TableColumn[] = [
    {
      title: '№ / ID',
      dataIndex: 'attachmentId',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Формат',
      dataIndex: 'extension',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      title: 'Наименование',
      dataIndex: 'originName',
      xtype: CustomTableColumnType.RenderCustom,
      align: 'center',
      renderCustom: ({ record }) => <div>{record.originName || '-'}</div>,
    },
    {
      title: '...',
      dataIndex: 'email',
      xtype: CustomTableColumnType.ActionColumn,
      actions: [CustomTableAction.Watch],
      align: 'center',
    },
  ];

  return { columns };
};
