import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Routes } from '@enums/routes';
import { IWaterRegistryFilters } from '@models/waterRegistry/interfaces/waterRegistryFilters';
import { getWaterRegistryFiltersSelector } from '@store/selectors';
import { changeWaterRegistryFilters } from '@store/slices';
import { isEmpty, pickBy, isInteger } from '@utils/utils';

type ReturnType = {
  filters: Partial<IWaterRegistryFilters>;
  setWaterRegistryFilters: (filters: IWaterRegistryFilters) => void;
};

export const useWaterRegistryFilters = (): ReturnType => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const location = useLocation();
  const isArchived = location.pathname.includes(Routes.ArchivariusTable);
  const filters = useSelector(getWaterRegistryFiltersSelector);

  const setWaterRegistryFilters = useCallback(
    (newFilters: IWaterRegistryFilters) => {
      Object.entries(newFilters).forEach(([key, value]: any) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      setParams(params);
      const { section, ...filterValues } = newFilters;
      const values = pickBy(filterValues, (v) => !isEmpty(v) || isInteger(v));

      dispatch(changeWaterRegistryFilters({ ...values, isArchived } as any));
    },
    [],
  );

  return { filters, setWaterRegistryFilters };
};
