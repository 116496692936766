import { useDispatch, useSelector } from 'react-redux';

import { IWaterAccountingFilters } from '@models/waterAccounting/interfaces/waterAccounting';
import {
  getWaterAccountingBalanceSheetFiltersSelector,
  getWaterAccountingEconomicFiltersSelector,
  getWaterAccountingWaterLogFiltersSelector,
} from '@store/selectors';
import {
  setWaterAccountingBalanceSheetFiltersAction,
  setWaterAccountingEconomicFiltersAction,
  setWaterAccountingWaterLogFiltersAction,
} from '@store/slices';

type ReturnType = {
  filters: IWaterAccountingFilters;
  setFilters: (filters: IWaterAccountingFilters) => void;
};

const filterSelectors = {
  balance: {
    selector: getWaterAccountingBalanceSheetFiltersSelector,
    action: setWaterAccountingBalanceSheetFiltersAction,
  },
  economic: {
    selector: getWaterAccountingEconomicFiltersSelector,
    action: setWaterAccountingEconomicFiltersAction,
  },
  waterLog: {
    selector: getWaterAccountingWaterLogFiltersSelector,
    action: setWaterAccountingWaterLogFiltersAction,
  },
};

export const useWaterAccountingFilters = (
  type: 'economic' | 'balance' | 'waterLog' = 'economic',
): ReturnType => {
  const { selector, action } = filterSelectors[type];
  const filters = useSelector(selector);
  const dispatch = useDispatch();

  const setFilters = (newFilters: IWaterAccountingFilters) => {
    dispatch(action(newFilters));
  };

  return { filters, setFilters };
};
