import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Form, Row, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import { normalizeValue } from '@features/annualApplication/plannedStructure/plannedStructureCreateModal/normalizeValue';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  PrimaryButton,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindAllChannelsByIrrigationSystemIdQuery,
  useFindAllIrrigationSystemsByHydroFacilityIdQuery,
  useFindAllOutletsByChannelIdQuery,
  useFindDictionaryByTypeIdQuery,
  useGetHydroFacilityAllQuery,
} from '@store/gisproApi';

import { useAddEconomy } from '../useAddEconomy';
import styles from './styles.module.scss';
import { MainWaterUserTypes } from '@models/waterUser/enums/waterUserEnums';

type EconomyDetailsFormTypes = {
  name: string;
  regionId: number;
  districtId: number;
  okmotId: number;
  systemId: number;
  channelId: number;
  outletId: number;
  area: string;
  kpd: number;
  agroZoneId: number;
  additionalAddress: string;
  gisAddress: string;
};

interface OutletConnectionProps {
  waterUserType: MainWaterUserTypes;
}

export const OutletConnection: React.FC<OutletConnectionProps> = ({ waterUserType }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { isDisabledRegion, isDisabledDistrict, user } = useGetCurrentUser();

  const irrigationSystem = Form.useWatch('irrigationSystem', form);
  const hydroFacilityId = Form.useWatch('hydroFacility', form);
  const channel = Form.useWatch('channel', form);
  const regionId = Form.useWatch('region', form);
  const districtId = Form.useWatch('district', form);
  const { saveOrEditEconomy, economies, selectedEconomyIndex } =
    useAddEconomy();

  const { data: regions = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.Region,
  });
  const { data: districts = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.District,
  });

  const { data: hydroFacilities = [] } = useGetHydroFacilityAllQuery({
    region: regionId,
    district: districtId,
  });
  const { data: irrigationSystems = [] } =
    useFindAllIrrigationSystemsByHydroFacilityIdQuery(
      hydroFacilityId ? { hydroFacilityId } : skipToken,
    );
  const { data: channels = [] } = useFindAllChannelsByIrrigationSystemIdQuery(
    irrigationSystem ? { id: irrigationSystem } : skipToken,
  );
  const { data: outlets = [] } = useFindAllOutletsByChannelIdQuery(
    channel ? { id: channel } : skipToken,
  );

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then((values: EconomyDetailsFormTypes) => {
        saveOrEditEconomy(
          {
            ...values,
            economieId:
              economies.association.length > 0
                ? economies.association[economies.association.length - 1].economieId + 1
                : 1,
          },
          selectedEconomyIndex,
          waterUserType,
        );
        const filteredValues = Object.keys(form.getFieldsValue()).reduce(
          (acc: any, key) => {
            if (['district', 'region'].includes(key)) {
              acc[key] = form.getFieldValue(key);
            } else {
              acc[key] = '';
            }
            return acc;
          },
          {},
        );
        form.setFieldsValue(filteredValues);
      })
      .catch((e) => {
        console.log('validateErrors: ', e);
      });
  }, [selectedEconomyIndex, economies]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        region: user.owner?.region?.id,
        district: user.owner?.district?.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (selectedEconomyIndex !== null && economies.association[selectedEconomyIndex]) {
      form.setFieldsValue(economies.association[selectedEconomyIndex]);
    }
  }, [economies, selectedEconomyIndex]);

  const onHydroFacilityChange = () => {
    form.setFieldsValue({
      irrigationSystem: undefined,
      channel: undefined,
      outlet: undefined,
    });
  };

  const onIrrigationSystemChange = () => {
    form.setFieldsValue({
      channel: undefined,
      outlet: undefined,
    });
  };

  const onChannelChange = () => {
    form.setFieldsValue({
      outlet: undefined,
    });
  };

  return (
    <Form<EconomyDetailsFormTypes> layout="vertical" form={form}>
      <CustomCard
        title={
          <Row justify="space-between">
            <Col span={21}>
              <Typography.Title level={4}>Выбор водовыпуска</Typography.Title>
              <Divider className={styles.divider} />
            </Col>
            <Col span={3}>
              <PrimaryButton type="primary" htmlType="button" onClick={onSave}>
                Добавить водовыпуск
              </PrimaryButton>
            </Col>
          </Row>
        }
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="region"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.region',
              )}
              rules={[{ required: true }]}
              initialValue={user?.owner?.region?.id}
            >
              <TooltipedSelect
                placeholder="Область"
                size="large"
                disabled={isDisabledRegion}
                options={regions}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="district"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.district',
              )}
              rules={[{ required: true }]}
              initialValue={user?.owner?.district?.id}
            >
              <TooltipedSelect
                placeholder="Район"
                size="large"
                disabled={isDisabledDistrict || !regionId}
                options={districts}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="hydroFacility"
              label="Гидроучасток"
              rules={[{ required: true }]}
            >
              <TooltipedSelect
                size="large"
                options={hydroFacilities}
                onChange={onHydroFacilityChange}
                placeholder="Гидроучасток"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="irrigationSystem"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.irrigationSystem',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility']}
            >
              <TooltipedSelect
                size="large"
                placeholder="Оросительная система"
                options={irrigationSystems}
                onChange={onIrrigationSystemChange}
                disabled={!hydroFacilityId}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="channel"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.channel',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility', 'irrigationSystem']}
            >
              <TooltipedSelect
                size="large"
                placeholder="Канал"
                options={channels}
                disabled={!irrigationSystem}
                onChange={onChannelChange}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="outlet"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.waterOutlet',
              )}
              rules={[{ required: true }]}
              dependencies={['hydroFacility', 'irrigationSystem', 'channel']}
            >
              <TooltipedSelect
                placeholder="Водовыпуск"
                size="large"
                options={outlets}
                disabled={!channel}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="totalArea"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.totalArea',
              )}
              rules={[{ required: true }]}
            >
              <TooltipedInput
                placeholder="Общая площадь"
                size="large"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="kpd"
              label={t(
                'createIndividualWaterRegistry.secondStep.economyDetails.placeholders.efficiency',
              )}
              rules={[{ required: true }]}
              normalize={normalizeValue}
            >
              <TooltipedInput placeholder="КПД" size="large" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </CustomCard>
    </Form>
  );
};
