import React, { useCallback, useMemo, useRef, useState } from 'react';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { useWaterUsersModal } from '@features/waterUser/waterUsersModal/useWaterUsersModal';
import { useGetWaterAccountingOutletFactsQuery } from '@store/gisproApi';
import { toInteger } from '@utils/utils';

import { useEnterMonitoringValuesEveningModal } from '../../enterMonitoringValuesModals/enterMonitoringValuesEveningModal/useEnterMonitoringValuesEveningModal';
import { useEnterMonitoringValuesMorningModal } from '../../enterMonitoringValuesModals/enterMonitoringValuesMorningModal/useEnterMonitoringValuesMorningModal';
import { useWaterAccountingFilters } from '../../useWaterAccountingFilters';
import { mapEconomicWaterAccountingTableData } from './mapEconomicWaterAccountingTableData';
import { useEconomicWaterAccountingTableColumns } from './useEconomicWaterAccountingTableColumns';
import { useSearchParams } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import { PrintIcon } from '@icons/PrintIcon';
import { PageQueryKey } from '@constants/queryKeys';
import { useReactToPrint } from 'react-to-print';

export const EconomicWaterAccountingTable = () => {
  const { filters } = useWaterAccountingFilters('economic');
  const { setShowModal, setOutletId } = useWaterUsersModal();
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(params.get(PageQueryKey) || 1);
  const printRef = useRef<any>();
  const addValue = (toInteger(filters.decade) - 1) * 10;

  const decadeDaysValue = [
    toInteger(filters.decadeDays) * 2 - 1 + addValue,
    toInteger(filters.decadeDays) * 2 + addValue,
  ];

  const { setShowModal: setShowModalEvening, setSelectedEveningRecord } =
    useEnterMonitoringValuesEveningModal();

  const { setShowModal: setShowModalMorning, setSelectedMorningRecord } =
    useEnterMonitoringValuesMorningModal();

  const {
    data: { content: quentityData = [], totalElements = 0, ...rest } = {},
    isFetching,
    isLoading,
  } = useGetWaterAccountingOutletFactsQuery({
    page,
    month: filters.month as any,
    decade: filters.decade as any,
    decadeDays: decadeDaysValue as any,
    year: filters.year,
    filter: JSON.stringify(filters),
  });

  const { columns = [] } = useEconomicWaterAccountingTableColumns({
    month: filters.month,
    decade: filters.decade as any,
    decadeDay: filters.decadeDays as any,
    year: String(filters.year),
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'economicWaterAccountingPrint',
    onBeforeGetContent: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Водопользователи') {
          item.style.display = 'none';
        }
      });
    },
    onAfterPrint: () => {
      const lastChildArrya = [...printRef.current.lastChild];
      lastChildArrya.forEach((item: any) => {
        if (item.innerText === 'Водопользователи') {
          item.style.display = 'block';
        }
      });
    },
  });

  const { data } = useMemo(
    () => mapEconomicWaterAccountingTableData(quentityData),
    [quentityData],
  );

  const onEnterEveningValuesClick = (record: any, column: any) => {
    setShowModalEvening(true);
    setSelectedEveningRecord({
      ...record,
      columnIndex: column.title,
      day: column.dataIndex,
    });
  };

  const onWaterUsersClick = (record: any) => {
    setShowModal(true);
    setOutletId(record.outletId);
  };

  const onEnterMorningValuesClick = (record: any, column: any) => {
    setShowModalMorning(true);
    setSelectedMorningRecord({
      ...record,
      columnIndex: column.title,
      day: column.dataIndex,
    });
  };

  const onTableChange = (pagination: any) => {
    setPage(pagination.current);
    params.set(PageQueryKey, pagination.current);
    setParams(params);
  };

  return (
    <CustomCard
      title={
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>
              Таблица хоз. договорного водоучета
            </Typography.Title>
          </Col>
          <Col>
            <PrimaryButton onClick={handlePrint} icon={<PrintIcon />} />
          </Col>
        </Row>
      }
    >
      <Col span={24} ref={printRef}>
        <TableCustom
          bordered
          size="small"
          columns={columns}
          dataSource={data}
          loading={isLoading || isFetching}
          totalCount={totalElements}
          customPagination={rest}
          onChange={onTableChange}
          rowKey={(record) => record.outletId}
          actionProps={{
            onWaterUsersClick,
            onEnterEveningValuesClick,
            onEnterMorningValuesClick,
          }}
        />
      </Col>
    </CustomCard>
  );
};
