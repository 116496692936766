import { Button, Row } from 'antd';

import { Links } from '@enums/links';
import { CustomTableAction } from '@enums/table';
import { BlueDeleteIcon } from '@icons/BlueDeleteIcon';
import { BlueWatchIcon } from '@icons/BlueWatchIcon';
import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './styles.module.scss';

export function renderAction<RecordType extends JsonRecord>({
  customColumn,
  actionProps,
  record,
}: TableRendererProps<RecordType>) {
  const { actions = [] } = customColumn;

  return (
    <Row
      className={styles.actionWrapper}
      justify="center"
      align="middle"
      wrap={false}
    >
      {actions.map((action) => {
        if (action === CustomTableAction.Edit) {
          return (
            <Button
              key={action}
              // onClick={onActionClick}
              className={styles.actionBtn}
              type="link"
              icon={<img src={Links.Edits} alt="" />}
            />
          );
        }
        if (action === CustomTableAction.BlueEdit) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onEditRowClick?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<img src={Links.BlueEdit} alt="" />}
            />
          );
        }
        if (action === CustomTableAction.Delete) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.deleteItem?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueDeleteIcon />}
            />
          );
        }
        if (action === CustomTableAction.Archive) {
          return (
            <Row key={action} align="middle">
              <Button
                // onClick={onActionClick}
                className={styles.actionBtn}
                type="link"
                icon={<img src={Links.Archive} alt="" />}
              />
            </Row>
          );
        }
        if (action === CustomTableAction.UnArchive) {
          return (
            <Row key={action} align="middle">
              <Button
                // onClick={onActionClick}
                className={styles.actionBtn}
                type="link"
                icon={<img src={Links.AettingsIcon} alt="" />}
              />
            </Row>
          );
        }
        if (action === CustomTableAction.Watch) {
          return (
            <Button
              key={action}
              onClick={() => actionProps?.onWatch?.(record)}
              className={styles.actionBtn}
              type="link"
              icon={<BlueWatchIcon />}
            />
          );
        }
        return null;
      })}
    </Row>
  );
}
