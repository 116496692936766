import { changeReturnUrl } from '@store/slices/header';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const saveUrl = () => {
  const locaton = useLocation();

  const dispatch = useDispatch();

  return () => {
    dispatch(changeReturnUrl(`${locaton.pathname}${locaton.search}`));
  };
};
