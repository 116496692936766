export enum PlanType {
  TotalWaterIntakeFromIrrigationSources = 'План суммарного водозабора из источников орошения',
  FirstIrrigationPlan = 'План первого полива',
  TotalWaterSupplyToAllAllocationPoints = 'План суммарной водоподачи в точка выдела всего',
  IrrigationWaterSupplyPlan = 'План водоподачи на орошение',
  MoistureChargingIrrigationPlanWaterSupply = 'План влагозарядковых поливов(водоподача)',
  MoistureChargingIrrigationPlanHectareIrrigation = 'План влагозарядковых поливов(гектарополивы)',
  TotalHectareIrrigationPlan = 'План гектарополивов всего',
  ContractualWaterSupplyPlan = 'План хоздоговорной водоподачи',
  SowingPlan = 'План посева сельхозкультур на орошаемых землях',
  HydrometricWorksPlan = 'План гидрометрических работ',
}
