import React, { useRef } from 'react';

import { DatePicker, DatePickerProps, Tooltip, TooltipProps } from 'antd';

import styles from './DatePicker.module.scss';

type TooltipedDatePickerPropsType = DatePickerProps & {
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
  allowClear?: boolean;
};

const DATE_FORMAT = 'YYYY-MM-DD';

export const TooltipedDatePicker: React.FC<TooltipedDatePickerPropsType> = ({
  tooltipProps,
  allowClear = false,
  ...props
}) => {
  const ref = useRef(null);

  const format: string = props?.format ? props.format.toString() : DATE_FORMAT;
  return (
    <Tooltip {...tooltipProps}>
      <DatePicker
        placeholder={format.toLowerCase()}
        {...props}
        ref={ref}
        format={{
          format: 'YYYY-MM-DD',
          type: 'mask',
        }}
        className={styles.datePicker}
        allowClear={allowClear}
      />
    </Tooltip>
  );
};
