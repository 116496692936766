export const getArrayDimension = (
  arr: number[] | number[][] | number[][][],
) => {
  if (!Array.isArray(arr)) {
    return 0;
  }

  let maxDepth = 1;
  for (const element of arr) {
    if (Array.isArray(element)) {
      const depth = getArrayDimension(element) + 1;
      if (depth > maxDepth) {
        maxDepth = depth;
      }
    }
  }

  return maxDepth;
};
