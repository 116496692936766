export enum ManageEventsWorkTypes {
  OPERATIONAL = 'operational',
  REGULAR_EVENTS = 'regular-events',
  OTHER_TASKS = 'other-tasks',
  BUILDING = 'building',
}
export enum ManageEventsWorkTypesFilter {
  OPERATIONAL = 'operational',
  REGULAR_EVENTS = 'regular-events',
  OTHER_TASKS = 'other-tasks',
  BUILDING = 'building',
}

export enum WorkEventManagementQueryTypes {
  all = 'all',
  operationalBlock = 'operationalBlock',
  extraTask = 'extraTask',
  constructionBlock = 'constructionBlock',
}

export enum TaskPrioritiesEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export enum TaskPrioritiesTranslateEnum {
  high = 'Высокий',
  medium = 'Средний',
  low = 'Обычный',
}

export enum TaskStatusEnum {
  draft = 'draft',
  onPlanned = 'onPlanned',
  onPreparation = 'onPreparation',
  onRealization = 'onRealization',
  onControl = 'onControl',
  completed = 'completed',
  expired = 'expired',
  completedNotOnTime = 'completedNotOnTime',
}

export enum BindingTypes {
  OBJECT = 'object',
  SYSTEM = 'system',
}

export enum ImplementationTermTranslateStatusEnum {
  InProgress = 'Не реализовано',
  Done = 'Реализовано',
}

export enum ImplementationTermStatusEnum {
  InProgress = 'InProgress',
  Done = 'Done',
}
