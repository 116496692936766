import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HydraulicSectionState = {
  isCreateHydraulicSectionModalShown: boolean;
  isShowAddHydraulicSectionModal: boolean;
  hydroFacilityId: number | null;
};

const initialState: HydraulicSectionState = {
  isCreateHydraulicSectionModalShown: false,
  isShowAddHydraulicSectionModal: false,
  hydroFacilityId: null,
};

const hydraulicSectionSlice = createSlice({
  name: 'hydraulicSection',
  initialState,
  reducers: {
    setShowIsCreateHydraulicSectionModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isCreateHydraulicSectionModalShown: action.payload,
      };
    },
    setShowAddHydraulicSectionModalAction: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isShowAddHydraulicSectionModal: action.payload,
      };
    },
    setHydroFacilityIdAction: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        hydroFacilityId: action.payload,
      };
    },
  },
});

export const {
  setShowIsCreateHydraulicSectionModalAction,
  setShowAddHydraulicSectionModalAction,
  setHydroFacilityIdAction,
} = hydraulicSectionSlice.actions;

export default hydraulicSectionSlice.reducer;
