import { TableCustom } from '@features/ui-kit';
import { FC, useEffect, useMemo } from 'react';

import { Col, Row, Space, Table, Typography } from 'antd';

import { ContractualWaterSupplyTableSummary } from '@features/annualApplication/contractualWaterSupplyTable/contractualWaterSupplyTableContainer/ContractualWaterSupplyTableSummary';
import { useContractualWaterSupplyTableFilters } from '@features/annualApplication/contractualWaterSupplyTable/useContractualWaterSupplyTableFilters';
import { ReservoirMonitoringMask } from '@features/monitoring/reservoirMonitoring/reservoirMonitoringMask/ReservoirMonitoringMask';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetContractualWaterSupplyQuery } from '@store/gisproApi';
import { isEmpty } from '@utils/utils';

import styles from './ContractualWaterSupplyTableContainer.module.scss';
import { useContractualWaterSupplyTableColumns } from './useContractualWaterSupplyTableColumns';

export const ContractualWaterSupplyTableContainer: FC = () => {
  const { filters } = useContractualWaterSupplyTableFilters();

  const {
    data = { reports: [], typeNames: [] },
    isLoading,
    isFetching,
  } = useGetContractualWaterSupplyQuery(
    filters?.district && filters?.startDate && filters?.endDate
      ? {
          district: filters.district || '',
          startDate: filters.startDate || '',
          endDate: filters.endDate || '',
        }
      : skipToken,
  );

  const { columns } = useContractualWaterSupplyTableColumns(
    data?.typeNames || [],
  );
  const isTableShow = useMemo(
    () => Boolean(filters?.district && filters?.startDate && filters?.endDate),
    [filters],
  );

  return (
    <Row className={styles.table}>
      <Col span={24}>
        {!isTableShow ? (
          <ReservoirMonitoringMask maskText="Для начала работы выберите район и даты" />
        ) : (
          <TableCustom
            loading={isLoading || isFetching}
            dataSource={data?.reports ?? []}
            columns={columns}
            size="small"
            bordered
            rowKey="id"
            pagination={false}
            scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
            summary={(summData: any) => {
              if (isEmpty(summData)) return;
              return (
                <ContractualWaterSupplyTableSummary
                  columns={columns}
                  data={summData}
                />
              );
            }}
          />
        )}
      </Col>
    </Row>
  );
};
