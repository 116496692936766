import React from 'react';

import { CustomTableColumnType } from '@enums/table';
import { JsonRecord } from '@models/jsonRecord';
import { TableEditorProps } from '@models/table/interfaces/tableColumn';

import { EditDecadeCell } from './EditDecadeCell';
import { EditPriceCell } from './EditPriceCell';
import { EditString } from './EditString';

export class EditorFactory {
  static createEditor<RecordType extends JsonRecord>({
    customColumn,
    record,
    editValidator,
    actionProps,
  }: TableEditorProps): React.ReactNode {
    const { xtype } = customColumn;
    let Editor: React.FC<TableEditorProps> = EditString;

    switch (xtype) {
      case CustomTableColumnType.DecadeEditableCell:
        Editor = EditDecadeCell;
        break;
      case CustomTableColumnType.PriceEditableCell:
        Editor = EditPriceCell;
        break;
      default:
        break;
    }

    return (
      <Editor
        actionProps={actionProps}
        customColumn={customColumn}
        record={record}
        editValidator={editValidator}
      />
    );
  }
}
