import { Col, Form, Row, Space } from 'antd';

import {
  associationBooleanOptions,
  associationRank,
} from '@constants/associationStats';
import { EntityTypesEnum } from '@enums/entityTypes';
import {
  SectionTitle,
  TooltipedInputV2,
  TooltipedSelectV2,
} from '@features/ui-kit-v2';
import { useFindDictionaryByTypeIdQuery } from '@store/gisproApi';

import styles from '../EnteringReportingIndicatorsForm.module.scss';

export const GeneralIndicators: React.FC = () => {
  const { data: developmentStages = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.DevelopmentStageAVP,
  });

  const { data: registersProtocolMeetings = [] } =
    useFindDictionaryByTypeIdQuery({
      id: EntityTypesEnum.RegistersProtocolMeetingsCompiled,
    });

  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      <SectionTitle title="Общие показатели" background="cyan" />
      <Row gutter={15}>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            label="Общая площадь охвата АВП (Га)"
            required
            name="irrigatedAreaCoveredAvp"
          >
            <TooltipedInputV2 suffix="Га" type="number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Кол-во АВП на стадии регистрации АВП"
            required
            rules={[{ required: true }]}
            name="numberAvpRegistrationStageCount"
          >
            <TooltipedInputV2 type="number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Намечается создать АВП *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="plannedCreateAvp"
              >
                <TooltipedInputV2 placeholder="Кол-во" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="approximateIrrigatedArea"
              >
                <TooltipedInputV2 placeholder="Общ. орошаемая площадь тыс. Га" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Количество учредителей *</p>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item
                required
                name="stateLegalPersonCount"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Част. юр лица" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="legalPersonCount"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Гос. юр лица" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="physicalPerson"
                rules={[{ required: true }]}
              >
                <TooltipedInputV2 type="number" placeholder="Физ лица" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Количество сотрудников АВП *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="employeeMaleCount"
              >
                <TooltipedInputV2 placeholder="Мужчины" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="employeeFemaleCount"
              >
                <TooltipedInputV2 placeholder="Женщины" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <p className={styles.label}>Количество членов АВП *</p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="avpMembersMale"
              >
                <TooltipedInputV2 placeholder="Мужчины" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="avpMembersfemale"
              >
                <TooltipedInputV2 placeholder="Женщины" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="developmentStageAvpId"
            label="Степень развития АВП"
          >
            <TooltipedSelectV2 options={developmentStages} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="reorganizationOfPzCompleted"
            label="Завершена реорганизация ПЗ"
          >
            <TooltipedSelectV2 options={associationBooleanOptions} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="pzScheme"
            label="Составлена карта (схема) ПЗ"
          >
            <TooltipedSelectV2 options={associationBooleanOptions} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            name="registersProtocolMeetingsCompiledId"
            label="Составлены реестры ЗП и протокола сообраний ПЗ"
          >
            <TooltipedSelectV2 options={registersProtocolMeetings} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <p className={styles.label}>
            Количество зональных представителей (ЗП) после реорганизации, чел *
          </p>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="zoneAgentsCount"
              >
                <TooltipedInputV2 placeholder="Мужчины" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="zoneAgentsFemaleCount"
              >
                <TooltipedInputV2 placeholder="Женщины" type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Space>
  );
};
