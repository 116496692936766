import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Space, Typography } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';
import {
  CustomModal,
  DefaultButton,
  PrimaryButton,
  TooltipedDatePicker,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import {
  IAgroClimaticZone,
  IPlantingListItem,
} from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useFindDictionaryByTypeIdQuery,
  useGetAgroClimaticZoneByIdQuery,
  useGetGmrPlantingListQuery,
  useUpdateAgroClimaticZoneMutation,
  useUpdateGmrPlantingListMutation,
} from '@store/gisproApi';
import { getSelectedPlantingIdSelector } from '@store/selectors';
import { normalizePercents } from '@utils/validation/normalizePercents';
import dayjs from 'dayjs';
import { isInteger, toInteger } from '@utils/utils';

import { useCreateWateringRateModal } from './useCreateWateringRateModal';

export const CreateWateringRateModal: FC = () => {
  const [form] = Form.useForm();
  const { setShowModal, isModalShown, initialValues, setValues } =
    useCreateWateringRateModal();
  const { hydroModuleDistrictId } = useParams<{
    hydroModuleDistrictId: any;
  }>();

  const { data: agroClimaticZone } = useGetAgroClimaticZoneByIdQuery(
    hydroModuleDistrictId && isInteger(toInteger(hydroModuleDistrictId))
      ? { id: hydroModuleDistrictId as unknown as number }
      : skipToken,
  );
  const [update, { error, isError }] = useUpdateGmrPlantingListMutation();
  const { data: irrigationTypes = [] } = useFindDictionaryByTypeIdQuery({
    id: EntityTypesEnum.IrrigationTypes,
  });

  const selectedPlantingId = useSelector(getSelectedPlantingIdSelector);

  const onCancelButtonClick = () => {
    form.resetFields();
    setShowModal(false);
    setValues(null);
  };

  const startDate = Form.useWatch('startDate', form);

  const onFinish = useCallback(
    (values: IPlantingListItem) => {
      if (selectedPlantingId && hydroModuleDistrictId) {
        const plantingListItem = {
          ...values,
          foodPlantId: selectedPlantingId,
        };
        update({
          gmrId: parseInt(hydroModuleDistrictId, 10),
          plantingListDtos: [plantingListItem],
        }).then(() => {
          form.resetFields();
          setShowModal(false);
          setValues(null);
        });
      }
    },
    [agroClimaticZone, hydroModuleDistrictId, selectedPlantingId],
  );

  useEffect(() => {
    if (initialValues?.id) {
      const startDate = initialValues.startDate
        ? dayjs(initialValues.startDate)
        : null;
      let endDate = initialValues.endDate ? dayjs(initialValues.endDate) : null;
      if (endDate && startDate && endDate < startDate) {
        endDate = startDate;
      }
      form.setFieldsValue({
        ...initialValues,
        startDate,
        endDate,
      });
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <CustomModal
      open={isModalShown}
      title={
        initialValues?.id ? 'Изменение нормы полива' : 'Создание нормы полива'
      }
      footer={false}
      onCancel={onCancelButtonClick}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={8}>
          {initialValues?.id ? (
            <Col span={24}>
              <Form.Item name="id" label="Номер полива">
                <TooltipedInput disabled type="number" />
              </Form.Item>
            </Col>
          ) : null}
          <Col span={24}>
            <Form.Item
              label="Норма полива (м3/Га)"
              name="irrigationStandard"
              rules={[{ required: true }]}
            >
              <TooltipedInput type="number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Охват поля (%)"
              name="coverField"
              normalize={normalizePercents}
              rules={[{ required: true }]}
            >
              <TooltipedInput type="number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Вид полива"
              name="irrigationTypeId"
              rules={[{ required: true }]}
            >
              <TooltipedSelect options={irrigationTypes} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Дата начала"
              name="startDate"
              rules={[{ required: true }]}
            >
              <TooltipedDatePicker
                disabledDate={(current) => {
                  if (!initialValues?.disabledDateStart) {
                    return false;
                  }
                  return (
                    current <
                    dayjs(initialValues?.disabledDateStart).add(1, 'day')
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Дата окончания"
              name="endDate"
              rules={[{ required: true }]}
            >
              <TooltipedDatePicker
                disabled={!startDate}
                disabledDate={(current) => {
                  if (!initialValues?.disabledDateEnd) {
                    return current < dayjs(form?.getFieldValue('startDate'));
                  }
                  return (
                    current < dayjs(form?.getFieldValue('startDate')) ||
                    current >
                      dayjs(initialValues?.disabledDateEnd).subtract(1, 'day')
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Space>
              <DefaultButton onClick={onCancelButtonClick}>
                Отмена
              </DefaultButton>
              <PrimaryButton htmlType="submit">Сохранить</PrimaryButton>
            </Space>
          </Col>
        </Row>

        {isError && (
          <Typography.Text type="danger">
            {(error as any).status} {JSON.stringify((error as any).data)}
          </Typography.Text>
        )}
      </Form>
    </CustomModal>
  );
};
