import { JsonRecord } from '@models/jsonRecord';
import { TableRendererProps } from '@models/table/interfaces/tableColumn';

import styles from './renderer.module.scss';

export function rendererDecadeHeaderCell<RecordType extends JsonRecord>({
  record,
  customColumn,
  actionProps,
}: TableRendererProps<RecordType>) {
  return (
    <div className={styles.decade}>
      <span className={styles.decadeTitle}>{customColumn.title}</span>
    </div>
  );
}
