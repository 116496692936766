import { SVGProps } from 'react';

type IconProps = SVGProps<any>;

export const GeoPortalActionSquaredPlusIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_73_9035)">
        <path
          d="M4.7644 12.1641H1.83224V9.16227C1.83224 8.96557 1.67267 8.80878 1.4789 8.80878H0.35334C0.156724 8.80878 0 8.96842 0 9.16227V13.082C0 13.5866 0.410331 14 0.917545 14H4.7644C4.96101 14 5.11774 13.8403 5.11774 13.6465V12.5233C5.11774 12.3209 4.96101 12.1641 4.7644 12.1641Z"
          fill="white"
        />
        <path
          d="M10.2842 7.56304V6.43414C10.2842 6.23744 10.1246 6.08065 9.93087 6.08065H7.91626V4.06232C7.91626 3.86562 7.75669 3.70883 7.56292 3.70883H6.43451C6.23789 3.70883 6.08117 3.86847 6.08117 4.06232V6.0778H4.06086C3.86424 6.0778 3.70752 6.23744 3.70752 6.43129V7.56019C3.70752 7.75689 3.86709 7.91368 4.06086 7.91368H6.07547V9.93485C6.07547 10.1316 6.23504 10.2883 6.42881 10.2883H7.55722C7.75384 10.2883 7.91056 10.1287 7.91056 9.93485V7.91938H9.93087C10.1275 7.91938 10.2842 7.75974 10.2842 7.56304Z"
          fill="white"
        />
        <path
          d="M0.352772 4.92323H1.47548C1.6721 4.92323 1.82882 4.76359 1.82882 4.56974V1.83303H4.76098C4.9576 1.83303 5.11432 1.67339 5.11432 1.47954V0.353492C5.11432 0.156791 4.95475 0 4.76098 0H0.914127C0.409762 0 -0.00341797 0.410507 -0.00341797 0.917939V4.56974C-0.000568449 4.76644 0.159005 4.92323 0.352772 4.92323Z"
          fill="white"
        />
        <path
          d="M13.6377 8.80591H12.515C12.3184 8.80591 12.1617 8.96555 12.1617 9.1594V12.1612H9.35773C9.16112 12.1612 9.00439 12.3209 9.00439 12.5147V13.6379C9.00439 13.8346 9.16397 13.9914 9.35773 13.9914H13.0792C13.5836 13.9914 13.9968 13.5809 13.9968 13.0735V9.16225C13.9911 8.96555 13.8315 8.80591 13.6377 8.80591Z"
          fill="white"
        />
        <path
          d="M13.0734 0H9.35481C9.15819 0 9.00146 0.159642 9.00146 0.353492V1.47669C9.00146 1.67339 9.16104 1.83018 9.35481 1.83018H12.1587V4.56689C12.1587 4.76359 12.3183 4.92038 12.5121 4.92038H13.6348C13.8314 4.92038 13.9881 4.76074 13.9881 4.56689V0.917939C13.991 0.410507 13.5806 0 13.0734 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_73_9035">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
