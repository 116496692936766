import { Col, Row, Space, Typography } from 'antd';
import { TooltipedSelectV2 } from '@features/ui-kit-v2';
import Icon from '@ant-design/icons';
import * as React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Divider, TooltipedInput } from '@features/ui-kit';

import styles from './BigPropertyBlock.module.scss';

type BigPropertyBlockProps = {
  label: string;
  options?: any[];
  type: 'select' | 'input';
  suffix?: string;
  value?: any;
  onChange?: (value: any) => void;
  icon?:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
};

export const BigPropertyBlock: React.FC<BigPropertyBlockProps> = ({
  icon,
  label,
  options,
  type,
  suffix,
  value,
  onChange,
}) => {
  const onValueChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };
  const onEventTargetValueChange = (event: any) => {
    if (onChange) {
      onChange(event?.target?.value || event);
    }
  };

  let Renderer;

  switch (type) {
    case 'input':
      Renderer = (
        <TooltipedInput
          onChange={onValueChange}
          type="number"
          style={{ borderRadius: 6 }}
          value={value}
          size="large"
          suffix={suffix}
        />
      );
      break;
    case 'select':
      Renderer = (
        <TooltipedSelectV2
          onChange={onEventTargetValueChange}
          popupMatchSelectWidth={false}
          options={options}
          value={value}
        />
      );
      break;
    default:
      Renderer = null;
      break;
  }

  return (
    <Row justify="space-between" align="middle" className={styles.infoItem}>
      <Col span={15}>
        <Space size={8}>
          <Icon component={icon} />
          <Typography.Text>{label}</Typography.Text>
        </Space>
      </Col>
      <Col span={1}>
        <Divider style={{ fontSize: 54, margin: 0 }} type="vertical" />
      </Col>
      <Col span={8}>{Renderer}</Col>
    </Row>
  );
};
