import { FC, useEffect, useState, memo, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { AccountingGroupEnum } from '@enums/accountingGroup';
import { IWaterSection } from '@models/waterObject/interfaces/waterSection';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  setSelectedWaterSectionIdAction,
  waterObjectActions,
} from '@store/slices';

import { useMainConduitCreateStepperActionSelectModal } from '@features/mainConduit';
import { WaterObjectCollapse } from './waterObjectsAccordion';
import { mapAccordionItems } from './waterObjectsAccordion/mapAccordionItems';
import { InformationalBlock, Segmented, PrimaryButton } from '@features/ui-kit';
import { SegmentedValue } from 'antd/es/segmented';
import { MainConduitInfo } from '@features/mainConduit/createStepper/createGtsObjects/mainConduitInfo';
import {
  useIrrigationFindAllObjectsByAqueductIdQuery,
  useIrrigationFindByAqueductIdQuery,
  useIrrigationFindByAqueductSectionsQuery,
  useIrrigationFindGeneralAqueductBySystemIdQuery,
} from '@store/api/irrigationSystemApi';
import {
  useReferenceControllerFindAllObjectCategoryQuery,
  useReferenceControllerFindObjectGroupByCodeQuery,
} from '@store/api/referenceApi';
import { WaterObjectBinContainer } from '@features/mainConduit/createStepper/mainConduitConstruction/waterObjectBinContainer';

export const MainConduitContsruction: FC = () => {
  const [activeKey, setActiveKey] = useState<SegmentedValue>();

  const dispatch = useDispatch();
  const { setShowModal } = useMainConduitCreateStepperActionSelectModal();
  const { mainConduitId, systemId } = useParams<{
    mainConduitId: any;
    systemId: any;
  }>();

  const { data: objectCategories = [] } =
    useReferenceControllerFindAllObjectCategoryQuery();

  const { data: mainConduit } = useIrrigationFindByAqueductIdQuery({
    aqueductId: mainConduitId,
  });

  const { data: waterSections = [] } = useIrrigationFindByAqueductSectionsQuery(
    {
      aqueductId: mainConduitId,
    },
  );

  const {
    data: waterObjects = [],
    isLoading: waterObjectsIsLoading,
    isFetching: waterObjectsIsFetching,
  } = useIrrigationFindAllObjectsByAqueductIdQuery({
    aqueductId: mainConduitId,
  });

  const { data: gvsGroup } = useReferenceControllerFindObjectGroupByCodeQuery({
    code: AccountingGroupEnum.Gvs,
  });

  const { data: generalAqueduct } =
    useIrrigationFindGeneralAqueductBySystemIdQuery(
      mainConduit?.isGeneral ? { systemId } : skipToken,
    );

  const onFinish = () => {
    dispatch(waterObjectActions.setWaterObject({}));
    dispatch(setSelectedWaterSectionIdAction(null));
    setShowModal(true);
  };

  const items = useMemo(() => {
    const result = waterSections.map((item: IWaterSection) => ({
      label: item.name || 'default',
      value: String(item.id),
    }));

    if (mainConduit?.isGeneral) {
      result.unshift({
        label: 'ГВС',
        value: 'gvs',
      });
    }

    return result;
  }, [waterSections, mainConduit]);

  const onTabChange = (activeKey: SegmentedValue) => {
    setActiveKey(activeKey);
  };

  const collapsedItems = useMemo(() => {
    if (waterObjects.length && gvsGroup) {
      return mapAccordionItems(
        waterObjects,
        waterSections,
        objectCategories,
        generalAqueduct!,
        gvsGroup,
      );
    }
    return [];
  }, [
    waterObjects,
    waterSections,
    objectCategories,
    generalAqueduct,
    gvsGroup,
  ]);

  const defaultActiveKey = useMemo(() => {
    if (waterSections.length) {
      return String(waterSections[0]?.id);
    }
  }, [waterSections]);

  useEffect(() => {
    if (mainConduit?.isGeneral) {
      setActiveKey('gvs');
    }
  }, [mainConduit]);

  return (
    <>
      <Segmented
        options={items as any}
        onChange={onTabChange}
        style={{ marginBottom: 16 }}
        defaultValue={defaultActiveKey}
      />
      <InformationalBlock title="Привязка объектов (ГТС)">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <MainConduitInfo
              mainConduitInfo={{
                baseName: mainConduit?.baseName,
                districtName: mainConduit?.districtName,
                objectName: mainConduit?.objectName,
                systemName: mainConduit?.systemName,
              }}
            />
          </Col>

          <Col span={24}>
            <DndProvider backend={HTML5Backend}>
              <Row gutter={8}>
                <Col span={12}>
                  <div style={{ overflow: 'hidden', clear: 'both' }}>
                    <WaterObjectCollapse
                      waterObjectsIsLoading={
                        waterObjectsIsLoading || waterObjectsIsFetching
                      }
                      collapsedItems={collapsedItems}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div style={{ overflow: 'hidden', clear: 'both' }}>
                    <WaterObjectBinContainer
                      activeKey={activeKey as unknown as string}
                    />
                  </div>
                </Col>
              </Row>
            </DndProvider>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <PrimaryButton
              size="large"
              onClick={onFinish}
              disabled={collapsedItems.length > 0}
            >
              Завершить
            </PrimaryButton>
          </Col>
        </Row>
      </InformationalBlock>
    </>
  );
};
