import { MonthIndex } from '@constants/month';
import { DaysInDecadeEnum, DecadesEnum } from '@enums/decade';
import { MonthEnums } from '@enums/month';
import { IOutletDays } from '@models/outlet/interfaces/outlet';
import dayjs from 'dayjs';

type ReturnType = {
  data: any;
};

export const mapEconomicWaterAccountingTableData = (data: any): ReturnType => {
  const dataSource = data?.map((item: any, index: number) => {
    const res: any = {
      name: item.name,
      index: index + 1,
    };
    res.outletId = item.outletId;
    item.days?.map((dayArr: any) => {
      res[`${dayArr.day}.day`] = dayArr.day;
      res[`${dayArr.day}.id`] = dayArr.id;
      res[`${dayArr.day}.average`] = dayArr.average;
      Object.keys(dayArr.evening).forEach((key) => {
        res[`${dayArr.day}.${key}Evening`] = dayArr.evening[key];
      });
      Object.keys(dayArr.morning).forEach((key) => {
        res[`${dayArr.day}.${key}Morning`] = dayArr.morning[key];
      });
      return res;
    });
    return res;
  });

  return { data: dataSource };
};
