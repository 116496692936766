import React, { useCallback, useState } from 'react';

import { Button, TablePaginationConfig, Typography } from 'antd';

import { CustomCard, List, ListItem, TableCustom } from '@features/ui-kit';
import { JsonRecord } from '@models/jsonRecord';

import styles from './Instructions.module.scss';
import { useInstructionsControllerFindAllQuery } from '@store/api/instructionsApi';
import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { downloadAttachment } from '@utils/attachments/downloadAttachment';
import { NewsIcon } from '@icons/NewsIcon';
import { CloudDownloadOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { UploadFileIcon } from '@icons/UploadFileIcon';
import { DownloadIcon } from '@icons/DownloadIcon';

export const Instructions: React.FC = () => {
  const [filterParams, setFilterParams] = useState({
    page: 1,
    size: 10,
    lang: 'ru',
  });
  const arr = [
    {
      text: 'Скачать инструкцию',
    },
  ];

  const handleDownload = () => {
    const fileURL = '/application_instructions.pdf';

    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', 'Свод описание интерфейсов ЕИСВ.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const {
    data: { content = [], totalElements, ...rest } = {},
    isLoading,
    isFetching,
  } = useInstructionsControllerFindAllQuery(filterParams);

  const onTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      if (newPagination.pageSize && newPagination.current) {
        setFilterParams({
          ...filterParams,
          page: newPagination.current,
          size: newPagination.pageSize,
        });
      }
    },
    [filterParams],
  );
  const columns: TableColumn[] = [
    {
      dataIndex: 'title',
      title: '',
      xtype: CustomTableColumnType.RenderCustom,
      renderCustom: ({ record }) => {
        return (
          <div
            className={styles.tableRow}
            onClick={() => downloadAttachment(record?.attachments[0])}
          >
            <Icon component={NewsIcon} />
            <div className={styles.title}>{record?.title}</div>
            <Icon component={DownloadIcon} />
          </div>
        );
      },
    },
  ];
  return (
    <div className={styles.container}>
      <CustomCard>
        <TableCustom
          loading={isLoading || isFetching}
          dataSource={content as any[]}
          columns={columns}
          size="small"
          totalCount={totalElements}
          customPagination={rest}
          className={styles.table}
          onChange={onTableChange}
        />
      </CustomCard>
    </div>
  );
};
