import { CustomTableColumnType } from '@enums/table';
import { TableColumn } from '@models/table/interfaces/tableColumn';

export const useAddedOutletTableColumns = () => {
  const columns: TableColumn[] = [
    {
      dataIndex: 'systemName',
      title: 'Наименование системы',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'channelName',
      title: 'Наименование канала',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
    {
      dataIndex: 'name',
      title: 'Наименование водовыпуска',
      xtype: CustomTableColumnType.String,
      align: 'center',
    },
  ];

  return { columns };
};
