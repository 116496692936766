import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@store/index';
import {
  setIdForConfirmModal,
  setShowConfirmArchiveModal,
} from '@store/slices/archivarius';
import { getShowConfirmArchive } from '@store/selectors';

export const useArchiveConfirmModal = () => {
  const isModalShown = useSelector(getShowConfirmArchive);
  const dispatch = useDispatch<AppDispatch>();

  const setConfirmArchiveId = (id: number) => {
    dispatch(setIdForConfirmModal(id));
  };

  const setShowModal = (showModal: boolean) => {
    dispatch(setShowConfirmArchiveModal(showModal));
  };

  return { isModalShown, setShowModal, setConfirmArchiveId };
};
