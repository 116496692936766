import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Form, notification, Row, Space, Typography } from 'antd';

import { PlanType } from '@enums/annualPlan';
import { UserRoles } from '@enums/roles';
import { useGetCurrentUser } from '@features/authentication';
import {
  CustomCard,
  InformationalBlock,
  PrimaryButton,
  StatusWrapper,
  TableCustom,
  TooltipedSelect,
  YearPicker,
} from '@features/ui-kit';
import { PrintModal } from '@features/waterUsePlan/waterUsePlanRuvh/waterUsePlanRuvhModal/printModal';
import { usePrintModal } from '@features/waterUsePlan/waterUsePlanRuvh/waterUsePlanRuvhModal/usePrintModal';
import { CopyIcon } from '@icons/CopyIcon';
import { PasteIcon } from '@icons/PasteIcon';
import { PrintIcon } from '@icons/PrintIcon';
import { UserRole } from '@models/authentication/interfaces/user';
import { JsonRecord } from '@models/jsonRecord';
import { TableColumn } from '@models/table/interfaces/tableColumn';
import { PlanStatusEnum } from '@models/waterUsePlan/enums/WaterUsePlanEnums';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useCalculateWaterUsePlanMutation,
  useGetWaterUsePlanByGuvhQuery,
  useGetWaterUsePlanQuery,
  useUpdateStatusWaterUsePlanMutation,
  useUpdateWaterUsePlanMutation,
} from '@store/gisproApi';
import { getRuvhTablesInfoSelector } from '@store/selectors';
import { setUpdateStatus } from '@store/slices';
import { addRowIndexiesMapper } from '@utils/addRowIndexies/finalArrRowIndexies';
import dayjs from 'dayjs';
import { get } from '@utils/utils';

import { useWaterUsePlanDataQuery } from '../useWaterUsePlanDataQuery';
import { useWaterChargingIrrigationPlanWaterSupplyTableColumns } from './useWaterChargingIrrigationPlanWaterSupplyTableColumns';
import {
  waterChargingIrrigationPlanWaterSupplyDataMapper,
  waterSupplyDataMapper,
} from './waterChargingIrrigationPlanWaterSupplyDataMapper';
import { useHasUserPermission } from '@utils/authentication/useHasUserPermission';
import { ACCESS } from '@enums/permisson';

export const WaterChargingIrrigationPlanWaterSupply: React.FC = () => {
  const { user } = useGetCurrentUser();
  const userRole = useMemo(() => user?.role?.roleName, [user]);
  const hasPermission = useHasUserPermission(ACCESS.ANNUAL_CARD_EDIT);
  const [disabelBtns, setDisableBtns] = useState<any>({
    calculate: false,
    save: false,
  });
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { columns } = useWaterChargingIrrigationPlanWaterSupplyTableColumns(
    userRole,
    hasPermission,
  );
  const { setShowModal } = usePrintModal();
  const [updatePlan, { isLoading: updateLoading }] =
    useUpdateWaterUsePlanMutation();
  const [updateStatus] = useUpdateStatusWaterUsePlanMutation();
  const { data: waterChargingPlan, isLoading } = useWaterUsePlanDataQuery(
    userRole as UserRoles,
    PlanType.MoistureChargingIrrigationPlanWaterSupply,
  );

  const ruvhTables = useSelector(getRuvhTablesInfoSelector);
  const tableStatus = ruvhTables.find(
    (table) =>
      table.type === PlanType.MoistureChargingIrrigationPlanWaterSupply,
  )?.status;
  const getCellKey = (
    record: JsonRecord,
    column: TableColumn,
    index: number | undefined,
  ) => {
    if (column.editableCell && column.decimalIndex) {
      return get(record, [...(column.decimalIndex || []), 'id']);
    }
    return -1;
  };

  const onFinish = () => {
    updateStatus({
      type: PlanType.MoistureChargingIrrigationPlanWaterSupply,
    }).then((resp) => {
      setDisableBtns({ save: false, calculate: true });
      if ('data' in resp) {
        const status = resp.data.status
          ? PlanStatusEnum.Approved
          : PlanStatusEnum.Editable;

        dispatch(
          setUpdateStatus({
            status,
            type: PlanType.MoistureChargingIrrigationPlanWaterSupply,
          }),
        );
      }
    });
  };

  const onEditDecimalSaveClick = (record: JsonRecord, column: TableColumn) => {
    const item = get(record, [...(column.decimalIndex || [])]);
    const getId = item.id.split('-')[0];

    updatePlan({
      raws: [{ id: getId, volume: item.volume }],
      type: PlanType.MoistureChargingIrrigationPlanWaterSupply,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Вы успешно обновили значение',
        });
        setDisableBtns({ save: true, calculate: true });
      })
      .catch(({ data }) => {
        notification.error({
          message: data?.message ?? 'Не удалось изменить значение',
        });
      });
  };
  const mappedDataSource = useMemo(() => {
    let data;
    if (waterChargingPlan) {
      if ('byDistrictDtos' in waterChargingPlan) {
        data = waterChargingPlan.byDistrictDtos.map((item: any) => {
          return waterSupplyDataMapper(item);
        });
      } else {
        data = waterSupplyDataMapper(waterChargingPlan);
      }
    }
    return addRowIndexiesMapper((data || [])?.flat(), 3);
  }, [waterChargingPlan]);
  console.log('mappedData', mappedDataSource);

  useEffect(() => {
    if (
      waterChargingPlan &&
      'status' in waterChargingPlan &&
      waterChargingPlan.status
    ) {
      dispatch(
        setUpdateStatus({
          type: PlanType.MoistureChargingIrrigationPlanWaterSupply,
          status: waterChargingPlan.status,
        }),
      );
    }
  }, [waterChargingPlan]);
  console.log('columns', columns);
  return (
    <Col span={24}>
      <InformationalBlock>
        <CustomCard
          title={
            <Row justify="space-between">
              <Col span={12}>
                {userRole === UserRoles.ROLE_RUVH ? (
                  <StatusWrapper status={tableStatus}>
                    <Typography.Title style={{ marginBottom: 0 }} level={4}>
                      План влагозарядковых поливов (Водоподача)
                    </Typography.Title>
                  </StatusWrapper>
                ) : (
                  <Typography.Title style={{ marginBottom: 0 }} level={4}>
                    План влагозарядковых поливов (Водоподача)
                  </Typography.Title>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={10} justify="end">
                  {userRole === UserRoles.ROLE_RUVH && (
                    <Row gutter={10}>
                      <Col>
                        <Form form={form} initialValues={{ year: dayjs() }}>
                          <Form.Item name="year">
                            <YearPicker
                              disabledDate={(current) =>
                                current && current > dayjs().endOf('year')
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                      <Col>
                        <PrimaryButton icon={<CopyIcon />} />
                      </Col>
                      <Col>
                        <PrimaryButton icon={<PasteIcon />} />
                      </Col>
                      {hasPermission ? (
                        <Col>
                          <PrimaryButton
                            disabled={!disabelBtns.save}
                            onClick={onFinish}
                            htmlType="submit"
                          >
                            Сохранить
                          </PrimaryButton>
                        </Col>
                      ) : null}
                    </Row>
                  )}
                  <Col>
                    <PrimaryButton
                      onClick={() => setShowModal(true)}
                      icon={<PrintIcon />}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          {userRole === UserRoles.ROLE_RUVH ? (
            <Space size={10} direction="vertical">
              {!(waterChargingPlan && 'byDistrictDtos' in waterChargingPlan) &&
                waterChargingPlan?.bySystemDtos?.map((el) => {
                  const totalBySystem =
                    waterChargingPlan.totalsBySystemType?.find(
                      (totalSystem) =>
                        totalSystem.systemTypeId === el.systemTypeId,
                    );

                  const mappedEl: any = {
                    ...el,
                    totalsBySystemType: totalBySystem,
                  };
                  const mappedData =
                    waterChargingIrrigationPlanWaterSupplyDataMapper(mappedEl);

                  return (
                    <TableCustom
                      key={el.systemTypeName}
                      bordered
                      loading={isLoading}
                      dataSource={mappedData}
                      scroll={{ y: 1750 }}
                      size="small"
                      rowKey={(row) => row.index}
                      cellKey={getCellKey}
                      columns={columns}
                      actionProps={{
                        onEditDecimalSaveClick,
                      }}
                    />
                  );
                })}
            </Space>
          ) : (
            <TableCustom columns={columns} dataSource={mappedDataSource} />
          )}
        </CustomCard>
      </InformationalBlock>
      <PrintModal />
    </Col>
  );
};
