import { FC } from 'react';

import { Col, Row, Form } from 'antd';

import { EntityTypesEnum } from '@enums/entityTypes';

import {
  BigPropertyBlock,
  CustomCard,
  TooltipedInput,
  TooltipedSelect,
} from '@features/ui-kit';
import { SizesIcon } from '@icons/SizesIcon';
import { ConstructionIcon } from '@icons/ConstructionIcon';
import { PropertyBlock } from '@features/ui-kit/propertyBlock/PropertyBlock';
import { useReferenceControllerFindBycodeQuery } from '@store/api/referenceApi';
import { WearSumTable } from '../../tables';

export const SystemIndicatorsWellComponent: FC = () => {
  const { data: waterqualityTypes = [] } =
    useReferenceControllerFindBycodeQuery({
      id: EntityTypesEnum.WaterQualityType,
    });

  const { data: facings = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFacingTypes,
  });

  const { data: forms = [] } = useReferenceControllerFindBycodeQuery({
    id: EntityTypesEnum.WaterSectionFormTypes,
  });

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <CustomCard title="Системные показатели">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item name={['status', 'depthM']}>
                        <PropertyBlock
                          icon={SizesIcon}
                          label="Глубина, м"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name={['status', 'widthW']}>
                        <BigPropertyBlock
                          icon={SizesIcon}
                          label="Ширина, м"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name={['status', 'diameterD']}>
                        <BigPropertyBlock
                          icon={SizesIcon}
                          label="Диаметр, м"
                          type="input"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name="direction">
                        <PropertyBlock
                          icon={ConstructionIcon}
                          label="Направление"
                          type="radio"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
              <Col span={24}>
                <CustomCard title={null} dashed>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item name={['status', 'volume']} label="Объем">
                        <TooltipedInput
                          size="large"
                          placeholder="Введите данные"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name={['status', 'waterQualityId']}
                        label="Качество воды"
                      >
                        <TooltipedSelect
                          options={waterqualityTypes}
                          size="large"
                          placeholder="Выберите тип"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name={['status', 'formId']} label="Форма">
                        <TooltipedSelect
                          options={forms}
                          size="large"
                          placeholder="Выберите тип"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name={['status', 'facingId']}
                        label="Облицовка"
                      >
                        <TooltipedSelect
                          options={facings}
                          size="large"
                          placeholder="Выберите тип"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </CustomCard>
              </Col>
            </Row>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard title="Сумма износа">
            <WearSumTable />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
