import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import { CustomCard, PrimaryButton, TableCustom } from '@features/ui-kit';
import { IPlantingListItem } from '@models/agroClimaticZone/interfaces/agroClimaticZone';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetAgroClimaticZoneByIdQuery,
  useGetGmrPlantingListQuery,
  useUpdateGmrPlantingListMutation,
} from '@store/gisproApi';
import { getSelectedPlantingIdSelector } from '@store/selectors/agroClimaticZone';

import { useCreateWateringRateModal } from '../createWateringRateModal';
import { useWateringRateEditorColumns } from './useWateringRateEditorColumns';

export const WateringRateEditor: FC = () => {
  const { hydroModuleDistrictId, id } = useParams<{
    hydroModuleDistrictId: any;
    id: any;
  }>();
  const { columns } = useWateringRateEditorColumns();
  const { setShowModal, setValues } = useCreateWateringRateModal();

  const { data: plantingListDtos } = useGetGmrPlantingListQuery(
    hydroModuleDistrictId ? { gmrId: hydroModuleDistrictId } : skipToken,
  );
  const [update, { error, isError }] = useUpdateGmrPlantingListMutation();

  const selectedPlantingId = useSelector(getSelectedPlantingIdSelector);

  const plantingList = useMemo<IPlantingListItem[]>(
    () =>
      (plantingListDtos || [])
        .filter(
          (item: IPlantingListItem) => item.foodPlantId === selectedPlantingId,
        )
        .sort(
          (a: IPlantingListItem, b: IPlantingListItem) =>
            (a.id ?? 0) - (b.id ?? 0),
        ),
    [plantingListDtos, selectedPlantingId],
  );

  const onHandleEdit = (val: any) => {
    const currentIndex = plantingList?.findIndex(
      (item) => item?.id === val?.id,
    );
    setValues({
      ...val,
      disabledDateStart: plantingList[currentIndex - 1]?.endDate,
      disabledDateEnd: plantingList[currentIndex + 1]?.startDate,
    });
    setShowModal(true);
  };

  const deleteItem = (val: any) => {
    update({
      gmrId: parseInt(hydroModuleDistrictId, 10),
      plantingListDtos: [{ id: val.id, deleted: true }],
    });
  };

  const onCreateNewPlantingItem = () => {
    const lastPlanWateringIndex = plantingList.length - 1;
    setValues({
      disabledDateStart: plantingList[lastPlanWateringIndex]?.endDate,
    });
    setShowModal(true);
  };

  return (
    <CustomCard
      title={
        <>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Title level={4}>
                Норма полива сельхозструктуры
              </Typography.Title>
            </Col>
            <Col>
              <PrimaryButton
                disabled={!selectedPlantingId}
                onClick={onCreateNewPlantingItem}
              >
                Создать норму
              </PrimaryButton>
            </Col>
          </Row>

          <Typography.Text>
            <b>
              Оросительная норма м3/Га:{' '}
              {plantingList?.reduce(
                (acc, item) => acc + Number(item.irrigationStandard),
                0,
              )}
            </b>
          </Typography.Text>
        </>
      }
    >
      <TableCustom
        dataSource={plantingList}
        columns={columns}
        scroll={{ y: 450 }}
        bordered
        actionProps={{
          onHandleEdit,
          deleteItem,
        }}
      />
    </CustomCard>
  );
};
