import { ReactElement } from 'react';

import { Button, ButtonProps } from 'antd';

import { UploadIcon } from '@icons/UploadIcon';

import styles from './buttons.module.scss';

type UploadButtonSmallProps = ButtonProps & {
  hasIcon?: boolean;
  icon?: ReactElement;
  direction?: 'left' | 'right';
};

export const UploadButtonSmall: React.FC<UploadButtonSmallProps> = ({
  children,
  hasIcon,
  icon = <UploadIcon />,
  direction,
  ...rest
}) => {
  return (
    <Button className={styles.uploadButtonSmall} icon={icon} {...rest}>
      <span className={styles.uploadButtonSmall__label}>{children}</span>
    </Button>
  );
};
