import { ACCESS, Permission } from '@enums/permisson';
import { AuthenticationUser } from '@models/authentication/interfaces/user';

import { validatePermission } from './validatePermission';

type PropsType = {
  permissionsRequired: ACCESS[];
  user: AuthenticationUser | null;
  userPermissions: string[];
};

export const validatePermissions = ({
  permissionsRequired,
  user,
  userPermissions = [],
}: PropsType): boolean => {
  const isAccessGranted = permissionsRequired.reduce((previous, current) => {
    if (!previous) {
      return previous;
    }

    return validatePermission({
      permissionRequired: current,
      permissionsGranted: userPermissions,
      user,
    });
  }, true);

  return isAccessGranted;
};
